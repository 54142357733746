import { normaliseDate } from "@/app/misc/helpers";
import { TrelloAttachment } from "@/lib/service/trello.types";
import { cn, getFileExtension } from "@/lib/utils";
import { Text } from "../../../../../components";
import { text } from "@/app/styles";
import { LinkedLabel } from "@/app/components/label/linked-label";
import { DocumentIcon } from "@/app/components/icon";

interface AttachmentListProps {
  total: number;
  displayAttachments: TrelloAttachment[];
  route?: string;
  container?: string;
  contentContainer?: string;
  attachmentContainer?: string;
  size?: "md" | "lg";
}

export const AttachmentList = ({
  total,
  displayAttachments,
  route = "",
  container,
  contentContainer,
  attachmentContainer,
  size = "lg",
}: AttachmentListProps) => {
  if (total === 0) return null;

  return (
    <div className={cn("flex flex-col pt-6", container)}>
      {route && (
        <LinkedLabel label={"Attachments"} href={route}>
          <Text
            text={"Show All"}
            weight={"light"}
            className={`${text.body} ml-2 text-primary`}
          />
          <Text
            text={String(total)}
            type={"subtitle"}
            className={`${text.titleText} text-primary`}
          />
        </LinkedLabel>
      )}
      <div className={cn("grid grid-cols-2", contentContainer)}>
        {displayAttachments.map(({ fileName, date, name }, i) => (
          <div
            key={i}
            className={cn(
              "flex cursor-pointer flex-col gap-y-1.5 rounded-md border border-neutral-200 p-1",
              attachmentContainer
            )}
          >
            <div className={"flex items-start"}>
              <DocumentIcon
                ext={getFileExtension(fileName)}
                className={cn(
                  size === "lg"
                    ? "min-h-12 w-12 min-w-12"
                    : "min-h-6 w-6 min-w-6"
                )}
              />
              <div className={"overflow-hidden"}>
                <Text
                  text={name}
                  noOfLines={size === "lg" ? 1 : 2}
                  className={`${
                    size === "lg" ? text.body : text.message
                  } text-left`}
                  weight={"light"}
                />
                <Text
                  text={`on ${normaliseDate(date)}`}
                  className={`${
                    size === "lg" ? text.message : text.small
                  } text-left`}
                  weight="light"
                  color={"text-neutral-400"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
