import { Text } from "@/app/components";
import { shared } from "./styles";
import { EmptyState } from "../../../../../components/label";
import { Badges } from "@/app/types";

export interface BaseCampProps {
  baseCamp?: Badges;
  className?: string;
}

export const BaseCamp = ({
  baseCamp,
  className = "flex flex-col justify-start gap-y-2 py-6",
}: BaseCampProps) => {
  return (
    <div className={`${className}`}>
      <Text
        text={"Basecamp"}
        weight={"semibold"}
        className={shared.subTitleText}
      />
      {baseCamp ? (
        <Text
          text={baseCamp?.extra?.map(({ name }) => name).join(",") as string}
          weight={"light"}
          className={`${shared.body}`}
        />
      ) : (
        <EmptyState className={shared.body} />
      )}
    </div>
  );
};
