import { useMemo } from "react";

import { parseISO, format } from "date-fns";

import { cn } from "@/lib/utils";

import { Icon, Popover, Text, TooltipInfo } from "@/app/components";

import { NotAvailable } from "./not-available";

const InfoTextClass = "text-xs text-black font-normal leading-4";

export const ExcitementScore = ({ stats, velocity, iconClassName, showAll }: any) => {
  const infoText = useMemo(() => {
    const formattedLastResponseTime = stats.lastResponseTime
      ? format(parseISO(stats.lastResponseTime || ""), "dd/MM/yyyy")
      : "";
    const formattedPreviousResponseTime = stats.previousResponseTime
      ? format(parseISO(stats.previousResponseTime || ""), "dd/MM/yyyy")
      : "";

    if (stats.isOld) {
      return <p className={InfoTextClass}>This insight is older than 6 months</p>;
    }

    if (stats.decrease) {
      return (
        <p className={InfoTextClass}>
          The latest average excitement score of <span className="font-[700]">{stats.excitement.toFixed(1)}</span> (
          {formattedLastResponseTime}) represents a decrease of&nbsp;
          <span className="font-semibold text-red-500">{stats.decrease}</span> over the company’s previous score&nbsp;(
          {formattedPreviousResponseTime})
        </p>
      );
    }

    if (stats.increase) {
      return (
        <p className={InfoTextClass}>
          The latest average excitement score of <span className="font-[700]">{stats.excitement.toFixed(1)}</span>
          &nbsp;({formattedLastResponseTime}) represents an increase of&nbsp;
          <span className="font-semibold text-green-700">{stats.increase}</span> over the company’s previous
          score&nbsp;(
          {formattedPreviousResponseTime})
        </p>
      );
    }

    return <span />;
  }, [stats]);

  return stats.isEmpty ? (
    <>
      <NotAvailable
        message={showAll ? "N/A" : "No Data"}
        textStyle={cn("lg:text-[12px] 2xl:text-[14px]", showAll ? "text-black" : "text-neutral-300")}
        tooltipContainer="max-w-[200px]"
        isSad={showAll && stats.isSad}
        tooltipInfo={<TooltipInfo warning text="It looks like we haven’t filled in a sentiment form" />}
      />
    </>
  ) : (
    <Popover
      containerClassName="flex"
      childrenContent={
        <div className="w-[250px] max-w-[250px]">
          {stats.members.map(({ name, score }) => (
            <div key={name} className="mb-1 flex w-full items-center justify-between px-1">
              <Text text={name} className="text-xs text-black" />
              <Text text={score} className="text-xs text-black" />
            </div>
          ))}
          <TooltipInfo renderText={infoText} alert={!!stats.decrease} success={!!stats.increase} info={stats.isOld} />
        </div>
      }
    >
      <div className="flex items-baseline text-left font-[500] text-black">
        <span className="text-xss font-medium leading-4 sm:mr-0.5 md:text-sm lg:text-[12px] 2xl:text-[14px]">
          {stats.excitement.toFixed(1)}
        </span>
        {velocity}
        {stats.isOld && showAll && <Icon type="SandClock" className={cn("inline-block size-4", iconClassName)} />}
      </div>
    </Popover>
  );
};
