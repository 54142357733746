import { Switch } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Text } from "@/app/components";

export type BaseToggleProps = {
  leftLabel?: string;
  rightLabel?: string;
  containerClassName?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  size?: "sm" | "md" | "lg";
};

export function BaseToggle({
  leftLabel,
  rightLabel,
  value = false,
  containerClassName,
  onChange,
  disabled,
  size = "lg",
}: BaseToggleProps) {
  return (
    <Switch.Group as="div" className={cn("flex cursor-pointer items-center", containerClassName)}>
      <Switch
        checked={value}
        disabled={disabled}
        onChange={onChange}
        className={cn(
          value ? "bg-gradient-to-r from-primary-400 to-secondary-400" : "bg-neutral-300",
          disabled ? "cursor-not-allowed bg-neutral-400" : "cursor-pointer",
          "relative inline-flex shrink-0 cursor-pointer items-center  border-transparent p-1 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-offset-2",
          size === "sm" && "w-7 p-0.5 rounded-xs",
          size === "md" && "w-10 rounded-sm",
          size === "lg" && "w-14 rounded-[10px]",
        )}
      >
        {leftLabel && value && (
          <Switch.Label as="span" className="absolute mx-1 align-middle">
            <Text color={"text-white"} text={leftLabel} className="text-xs font-[500]" />
          </Switch.Label>
        )}
        <span
          className={cn(
            "pointer-events-none inline-block bg-white ring-0 transition duration-200 ease-in-out translate-x-0",
            size === "sm" && "size-3 rounded-[4px]",
            size === "md" && "size-4 rounded-xs",
            size === "lg" && "size-5 rounded-sm",
            size === "sm" && value && "translate-x-3",
            size === "md" && value && "translate-x-4",
            size === "lg" && value && "translate-x-7",
          )}
        />
        {rightLabel && !value && (
          <Switch.Label as="span" className="ml-2 align-middle">
            <Text
              color={disabled ? "text-neutral-400" : "text-black"}
              text={rightLabel}
              className="text-xs font-[500]"
            />
          </Switch.Label>
        )}
      </Switch>
    </Switch.Group>
  );
}
