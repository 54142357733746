import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { useMembersMapped } from "@/lib/service/members.ts";

import { ImageWithErrorFallback, TextArea } from "@/app/components";
import { WebSelect } from "@/app/components/input/web-select.tsx";

import { AddCompanyFormSection, AddCompanySectionEmptyState } from "./section.tsx";

const CompanyAssigneesSchema = z.object({
  assignees: z
    .array(
      z.object({
        id: z.string(),
        value: z.string(),
        label: z.string(),
        image: z.string().optional().nullable(),
        initials: z.string().optional().nullable(),
      }),
    )
    .nonempty("Set at least one person to assign this"),
  note: z.string(),
});

type CompanyAssigneesFormData = z.infer<typeof CompanyAssigneesSchema>;

export const useCompanyAssigneesForm = () => {
  return useForm<CompanyAssigneesFormData>({
    resolver: zodResolver(CompanyAssigneesSchema),

    defaultValues: {
      assignees: [],
      note: "",
    },
  });
};

const CompanyAssigneesViewMode = ({ data }: { data: { assignees?: any[]; note: string } }) => {
  if (!data.note && !data.assignees?.length) {
    return <AddCompanySectionEmptyState>No Assignees</AddCompanySectionEmptyState>;
  }

  return (
    <div className="space-y-4">
      {(data.assignees?.length && (
        <div className="flex gap-2">
          {data.assignees?.map((v) => (
            <div key={v.value} className="flex items-center gap-2">
              <ImageWithErrorFallback alt={v.initials} src={v.image} className="size-10 rounded-xs" />
              {v.label}
            </div>
          ))}
        </div>
      )) ||
        "No Assignees"}

      <div>{(data.note && <p>{data.note}</p>) || "No Notes"}</div>
    </div>
  );
};

export const CompanyAssigneesForm = ({ form }: { form: UseFormReturn<CompanyAssigneesFormData> }) => {
  const { control, handleSubmit } = form;
  const { data: allOptions } = useMembersMapped();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AddCompanyFormSection title="Assignees">
      {(editMode) =>
        editMode ? (
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="assignees"
              control={control}
              render={({ field }) => (
                <WebSelect
                  options={allOptions}
                  required
                  defaultOptions={field.value}
                  onChange={field.onChange}
                  multiSelect
                />
              )}
            />

            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextArea
                  containerClassName="w-full"
                  label={"Note"}
                  placeholder={
                    "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
                  }
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </form>
        ) : (
          <CompanyAssigneesViewMode data={form.getValues()} />
        )
      }
    </AddCompanyFormSection>
  );
};
