import { useEffect, useMemo, useState } from "react";

import { EmailFeedback, General } from "@/app/screens/settings/components";
import { useLgMemberSuspenseQuery } from "@/app/service/lgMembers.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export const useDashboardSettings = () => {
  const { user, updateUser } = authStore;

  const SettingRoute = useMemo(
    () => ({
      General,
      EmailFeedback,
    }),
    [],
  );
  const { data: lgMemberData } = useLgMemberSuspenseQuery({
    variables: { id: user?.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    updateUser(lgMemberData?.lgMember);
  }, [lgMemberData]);

  const [activeTab, setActiveTab] = useState<keyof typeof SettingRoute>("General");

  const name = (user && [user.firstName, user.lastName].filter(Boolean).join(" ")) || user?.initials || "Unknown"; // or "My account" or w.e :)
  const TabComponent = SettingRoute[activeTab];
  const tabs = Object.keys(SettingRoute) as Array<keyof typeof SettingRoute>;

  return { name, activeTab, tabs, TabComponent, setActiveTab };
};
