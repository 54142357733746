import { action, computed, makeObservable, observable } from "mobx";

import { InputMaybe, LgCompaniesOrderBy } from "@/gql/graphql.ts";

export type MultiSelectCategories =
  | "Companies"
  | "Target Fund"
  | "Countries"
  | "Focus Cities"
  | "Continent"
  | "Sectors"
  | "Last Round"
  | "Pipeline"
  // | "Signal"
  | "Phoenix Court Group Funds"
  | "Led By"
  | "Prospect Stage"
  | "Supported By"
  | "Basecamp Funds"
  | "Tagged In"
  | "Investment Type"
  | "Top Investors"
  | "Last Round Announced"
  | "Last Sentiment Added"
  | "Number of Basecamp Investors"
  | "Include Exits & Write Offs"
  | "Assigned To";

export type RangeCategories =
  | "Last Round Amount"
  | "Latest Excitement Score"
  | "Total Raised"
  | "Total Cost"
  | "Total NAV"
  | "NAV as a % of Fund"
  | "First Dollar to Follow-On";

export type TagCategory =
  | "New Palo Alto"
  | "UK"
  | "EMEA"
  | "Previously Met"
  | "Portfolio Company"
  | "Basecamp"
  | "Excitement Exists"
  | "Added By Me"
  | "Unicorn"
  | "Futurecorn"
  | "Top Investor"
  | "Hidden By Me"
  | "Sharing Allowed"
  | "Raising in 6 Month"
  | "follow";

export type FilterCategories = "multiselect" | "range" | "tag" | "search" | "ordering";

export type MultiSelect =
  | Record<MultiSelectCategories, Array<{ value: string; label: string }>>
  | Record<RangeCategories, Array<{ value: string; label: string }>>
  | Record<string, null>;

export type RangeSelect = Record<RangeCategories, [number, number] | null>;

export type Choice = {
  multiselect: Record<MultiSelectCategories, Array<string> | null>;
  range: RangeSelect;
  tag: Record<TagCategory, boolean | null>;
  search: string;
  ordering: LgCompaniesOrderBy[];
};

export const defaultChoice: Choice = {
  tag: {
    "New Palo Alto": null,
    UK: null,
    EMEA: null,
    "Portfolio Company": null,
    Basecamp: null,
    "Previously Met": null,
    "Excitement Exists": null,
    "Added By Me": null,
    follow: null,
    "Hidden By Me": null,
    Unicorn: null,
    Futurecorn: null,
    "Top Investor": null,
    "Sharing Allowed": null,
    "Raising in 6 Month": null,
  },
  multiselect: {
    Companies: [],
    "Target Fund": [],
    Countries: [],
    "Focus Cities": [],
    Continent: [],
    Sectors: [],
    "Prospect Stage": [],
    "Last Round": [],
    // Signal: [],
    Pipeline: [],
    "Basecamp Funds": [],
    "Phoenix Court Group Funds": [],
    "Led By": [],
    "Supported By": [],
    "Tagged In": [],
    "Assigned To": [],
    "Last Round Announced": [],
    "Last Sentiment Added": [],
    "Number of Basecamp Investors": [],
    "Investment Type": [],
    "Top Investors": [],
    "Include Exits & Write Offs": [],
  },
  range: {
    "Last Round Amount": null,
    "Total Raised": null,
    "Total Cost": null,
    "Total NAV": null,
    "First Dollar to Follow-On": null,
    "Latest Excitement Score": null,
    "NAV as a % of Fund": null,
  },
  search: "",
  ordering: [],
};

export const defaultChoiceOption: Record<"tag", Record<TagCategory, TagOptions>> &
  Record<"multiselect", Record<MultiSelectCategories, MultiSelectOptions | TagOptions>> &
  Record<"range", Record<RangeCategories, DateOptions | NumberOptions>> = {
  tag: {
    "New Palo Alto": "Is",
    UK: "Is",
    EMEA: "Is",
    "Portfolio Company": "Is",
    Basecamp: "Is",
    "Previously Met": "Is",
    "Excitement Exists": "Is",
    "Added By Me": "Is",
    follow: "Is",
    "Hidden By Me": "Is",
    Unicorn: "Is",
    Futurecorn: "Is",
    "Top Investor": "Is",
    "Sharing Allowed": "Is",
    "Raising in 6 Month": "Is",
  },
  multiselect: {
    Companies: "Is any of",
    "Target Fund": "Is any of",
    Countries: "Is any of",
    "Focus Cities": "Is any of",
    Continent: "Is any of",
    Sectors: "Is any of",
    "Last Round": "Is any of",
    // Signal: "Is any of",
    Pipeline: "Is any of",
    "Basecamp Funds": "Is any of",
    "Phoenix Court Group Funds": "Is any of",
    "Prospect Stage": "Is any of",
    "Led By": "Is any of",
    "Supported By": "Is any of",
    "Tagged In": "Is any of",
    "Assigned To": "Is any of",
    "Investment Type": "Is any of",
    "Top Investors": "Is any of",
    "Last Round Announced": "Is",
    "Last Sentiment Added": "Is",
    "Include Exits & Write Offs": "Is",
    "Number of Basecamp Investors": "Is",
  },
  range: {
    "Last Round Amount": "Between",
    "Total Raised": "Between",
    "Total NAV": "Between",
    "Total Cost": "Between",
    "First Dollar to Follow-On": "Between",
    "Latest Excitement Score": "Between",
    "NAV as a % of Fund": "Between",
  },
};

export const defaultFilterRangeOptions = {
  "Last Round Amount": { min: 0, max: 250, step: 5 },
  "Total Raised": { min: 0, max: 250, step: 5 },
  "Total NAV": { min: 0, max: 250, step: 0.5 },
  "Total Cost": { min: 0, max: 50, step: 0.5 },
  "Latest Excitement Score": {
    min: 0,
    max: 10,
    step: 0.5,
    denomination: "",
    currency: "",
  },
  "NAV as a % of Fund": {
    min: 0,
    max: 15000,
    step: 1,
    denomination: "%",
    currency: "",
  },
  "First Dollar to Follow-On": {
    min: 1,
    max: 100,
    step: 0.5,
    denomination: "",
    currency: "1:",
  },
};

export class FilterStore {
  static defaultChoice = structuredClone(defaultChoice);
  static defaultChoiceOption = structuredClone(defaultChoiceOption);

  public isHydrated = false;
  constructor() {
    makeObservable(this, {
      initialFilters: observable,
      choice: observable,
      choiceOption: observable,
      ordering: observable,
      clearSearch: action,
      changeChoice: action,
      changeChoiceOption: action,
      changeAllChoice: action,
      defaultOrdering: observable,
      mapChoice: computed,
      filtersActive: computed,
      tagKeys: computed,
    });
  }

  multiSelectData: MultiSelect & any = {};

  defaultOrdering?: any = null;
  ordering?: any = null;

  initialFilters?: Partial<Choice> = {};

  currentFilters = structuredClone([]);

  // this is the main choice object that is used to store the filters
  choice: Choice = structuredClone(FilterStore.defaultChoice);

  // this is the previous choice object that is used to store the previous filters
  prevChoice: Choice = structuredClone<Choice>(FilterStore.defaultChoice);

  // this is the choice option (and, or, not) object that is used to store the choice options
  choiceOption = structuredClone(FilterStore.defaultChoiceOption);

  changeChoice({
    category,
    label,
    value,
  }: {
    label?: RangeCategories | MultiSelectCategories | TagCategory;
    category: FilterCategories;
    value: string | [number, number] | boolean | null;
  }) {
    if (category === "search" && typeof value === "string") {
      this.choice.search = value;
    }

    if (!label || !category) return;
    if (category === "multiselect" && typeof value === "string") {
      const valueIndex = this.choice[category][label]?.findIndex((e: string) => e === value);
      if (valueIndex === -1) {
        this.choice[category][label]?.push(value);
      } else {
        this.choice[category][label]?.splice(valueIndex, 1);
      }
    }

    if (category === "range" && typeof value === "object" && value) {
      if (this.choice[category][label] === null) {
        this.choice[category][label] = value.map((e) => (Number.isNaN(e) ? 0 : e));
        return;
      }

      this.choice[category][label] = this.choice[category][label]?.map((e: number, i: number) =>
        isNaN(value[i]) ? e : value[i],
      );
    }

    if (category === "tag" && (typeof value === "boolean" || value === null)) {
      this.choice[category][label] = value;
    }
  }

  changeAllChoice({
    category,
    label,
    action = "deselect",
    data = [],
  }: {
    label: RangeCategories | MultiSelectCategories | TagCategory;
    category: FilterCategories;
    action?: "select" | "deselect";
    data?: string[] | number[];
  }) {
    if (category === "multiselect") {
      this.choice[category][label] = action === "deselect" ? [] : data;
    }
    if (category === "range") {
      this.choice[category][label] = action === "deselect" ? null : data;
    }
    if (category === "tag") {
      this.choice[category][label] = action === "deselect" ? null : false;
    }
  }

  changeChoiceOption({
    label,
    category,
    value,
  }: {
    category: FilterCategories;
    value: MultiSelectOptions | TagOptions | DateOptions;
    label?: MultiSelectCategories | TagCategory | RangeCategories;
  }) {
    if (!label || !category) return;
    if (category === "multiselect") {
      this.choiceOption.multiselect[label] = value as MultiSelectOptions;
    }
    if (category === "tag") {
      this.choiceOption.tag[label] = value as TagOptions;
    }
  }
  getChoice({
    label,
    category,
  }: {
    label: RangeCategories | MultiSelectCategories | TagCategory;
    category: FilterCategories;
  }) {
    return this.choice[category]?.[label];
  }

  getChoiceOption({
    label,
    category,
  }: {
    label: RangeCategories | MultiSelectCategories | TagCategory;
    category: FilterCategories;
  }) {
    return this.choiceOption[category]?.[label];
  }

  defaultChoices({
    category,
    label,
    value,
  }: {
    label?: RangeCategories | MultiSelectCategories | TagCategory;
    category: FilterCategories;
    value?: string | [number, number];
  }) {
    if (!label || !category) return;

    if (category === "multiselect") {
      return this.choice[category][label]?.findIndex((e: string) => e === value) !== -1;
    }
    if (category === "range" || category === "tag") {
      return this.choice?.[category]?.[label] || [];
    }
  }

  get multiselectKeys(): Array<MultiSelectCategories> {
    return Object.keys(this.choice.multiselect) as unknown as Array<MultiSelectCategories>;
  }

  get rangeKeys(): Array<RangeCategories> {
    return Object.keys(this.choice.range) as unknown as Array<RangeCategories>;
  }

  get tagKeys(): Array<TagCategory> {
    return Object.keys(this.choice.tag) as unknown as Array<TagCategory>;
  }

  clearSearch() {
    this.choice.search = "";
  }
  clearAll() {
    this.choice = JSON.parse(JSON.stringify(FilterStore.defaultChoice));
  }

  createChoiceCopy() {
    this.prevChoice = JSON.parse(JSON.stringify(this.choice));
  }
  get mapChoice() {
    return Object.entries({
      isNpa: this.choice.tag["New Palo Alto"],
      isUk: this.choice.tag["UK"],
      isEmea: this.choice.tag["EMEA"],
      isOpCompany: this.choice.tag["Portfolio Company"],
      isBasecampFunded: this.choice.tag.Basecamp,
      isTrCompany: this.choice.tag["Previously Met"],
      latestExcitement: this.choice.tag["Excitement Exists"],
      addedByMe: this.choice.tag["Added By Me"],
      isUnicorn: this.choice.tag["Unicorn"],
      isFuturecorn: this.choice.tag["Futurecorn"],
      hasTopInvestor: this.choice.tag["Top Investor"],
      sharingAllowed: this.choice.tag["Sharing Allowed"],
      follow: this.choice.tag["follow"],
      hidden: this.choice.tag["Hidden By Me"],
      raising: this.choice.tag["Raising in 6 Month"],

      targetFund: this.choice.multiselect["Target Fund"],
      companies: this.choice.multiselect["Companies"],
      country: this.choice.multiselect.Countries,
      city: this.choice.multiselect["Focus Cities"],
      continent: this.choice.multiselect.Continent,
      industries: this.choice.multiselect.Sectors,
      stage: this.choice.multiselect["Last Round"],
      // signal: this.choice.multiselect.Signal,
      pipeline: this.choice.multiselect.Pipeline,
      basecampFunds: this.choice.multiselect["Basecamp Funds"],
      pcgFunds: this.choice.multiselect["Phoenix Court Group Funds"],
      ledBy: this.choice.multiselect["Led By"],
      supportedBy: this.choice.multiselect["Supported By"],
      taggedIn: this.choice.multiselect["Tagged In"],
      assignedTo: this.choice.multiselect["Assigned To"],
      trelloColumn: this.choice.multiselect["Prospect Stage"],
      monthsFromLastFunding: this.choice.multiselect["Last Round Announced"],
      monthsFromLastSentiment: this.choice.multiselect["Last Sentiment Added"],
      investmentType: this.choice.multiselect["Investment Type"],
      topInvestors: this.choice.multiselect["Top Investors"],
      exitsAndWriteOffs: this.choice.multiselect["Include Exits & Write Offs"],
      noBasecampInvestors: this.choice.multiselect["Number of Basecamp Investors"],

      lastFundingDate: this.choice.range["Last Round Amount"],
      totalFunding: this.choice.range["Total Raised"],

      navInvested: this.choice.range["Total NAV"],
      totalInvested: this.choice.range["Total Cost"],
      navPercentageOfFund: this.choice.range["NAV as a % of Fund"],
      firstInvestedDollar: this.choice.range["First Dollar to Follow-On"],
      latestExcitementScore: this.choice.range["Latest Excitement Score"],

      search: this.choice.search,
    }).filter(([_, value]) => {
      return value !== null || (value as any)?.length;
    });
  }

  get orderChoiceForApi(): InputMaybe<LgCompaniesOrderBy | LgCompaniesOrderBy[]> | undefined {
    return [
      this.choice.search ? "NAME_FUZZY_ASC" : null,
      this.ordering ? `${this.ordering.value}_${this.ordering.dir}` : null,
    ].filter(Boolean) as InputMaybe<LgCompaniesOrderBy | LgCompaniesOrderBy[]> | undefined;
  }
  get filtersActive() {
    return this.mapChoice.reduce((acc, [key, value]) => {
      if (key === "ordering") return acc;
      if (typeof value === "object") return value?.filter(Boolean).length ? value.filter(Boolean).length + acc : acc;
      if (typeof value === "boolean") return acc + 1;

      return acc;
    }, 0);
  }
}

export const opportunitiesSort = [
  // {
  //   value: "DEFAULT",
  //   label: "Default",
  //   defaultDirection: "ASC",
  // },
  {
    value: "LATEST_EXCITEMENT",
    label: "Excitement Score",
    defaultDirection: "DESC",
  },
  {
    value: "LATEST_EXCITEMENT_DATE",
    label: "Latest Excitement Date",
    defaultDirection: "DESC",
  },
  {
    value: "DEALROOM_SIGNAL_RATING",
    label: "Dealroom Signal",
    defaultDirection: "DESC",
  },
  {
    value: "TOTAL_FUNDING",
    label: "Total Funding Raised",
    defaultDirection: "DESC",
  },
  {
    value: "LAST_FUNDING_DATE",
    label: "Last Funding Date",
    defaultDirection: "DESC",
  },
];

export const sortDirections = [
  { value: "ASC", label: "Ascending" },
  { value: "DESC", label: "Descending" },
];

export const filterToApiMap: Record<
  string,
  {
    name: MultiSelectCategories | TagCategory | RangeCategories;
    category: FilterCategories;
  }
> = {
  isNpa: { name: "New Palo Alto", category: "tag" },
  isUk: { name: "UK", category: "tag" },
  isEmea: { name: "EMEA", category: "tag" },
  isOpCompany: { name: "Portfolio Company", category: "tag" },
  isBasecampFunded: { name: "Basecamp", category: "tag" },
  isTrCompany: { name: "Previously Met", category: "tag" },
  latestExcitement: { name: "Excitement Exists", category: "tag" },
  addedByMe: { name: "Added By Me", category: "tag" },
  isUnicorn: { name: "Unicorn", category: "tag" },
  isFuturecorn: { name: "Futurecorn", category: "tag" },
  hasTopInvestor: { name: "Top Investor", category: "tag" },
  follow: { name: "follow", category: "tag" },
  hidden: { name: "Hidden By Me", category: "tag" },
  raising: { name: "Raising in 6 Month", category: "tag" },
  sharingAllowed: { name: "Sharing Allowed", category: "tag" },

  targetFund: { name: "Target Fund", category: "multiselect" },
  companies: { name: "Companies", category: "multiselect" },
  country: { name: "Countries", category: "multiselect" },
  city: { name: "Focus Cities", category: "multiselect" },
  continent: { name: "Continent", category: "multiselect" },
  industries: { name: "Sectors", category: "multiselect" },
  stage: { name: "Last Round", category: "multiselect" },
  // signal: { name: "Signal", category: "multiselect" },
  pipeline: { name: "Pipeline", category: "multiselect" },
  trelloColumn: { name: "Prospect Stage", category: "multiselect" },
  basecampFunds: { name: "Basecamp Funds", category: "multiselect" },
  pcgFunds: { name: "Phoenix Court Group Funds", category: "multiselect" },
  ledBy: { name: "Led By", category: "multiselect" },
  supportedBy: { name: "Supported By", category: "multiselect" },
  taggedIn: { name: "Tagged In", category: "multiselect" },
  assignedTo: { name: "Assigned To", category: "multiselect" },
  investmentType: { name: "Investment Type", category: "multiselect" },
  topInvestors: { name: "Top Investors", category: "multiselect" },
  exitsAndWriteOffs: {
    name: "Include Exits & Write Offs",
    category: "multiselect",
  },
  monthsFromLastFunding: {
    name: "Last Round Announced",
    category: "multiselect",
  },
  noBasecampInvestors: {
    name: "Number of Basecamp Investors",
    category: "multiselect",
  },
  monthsFromLastSentiment: {
    name: "Last Sentiment Added",
    category: "multiselect",
  },

  navPercentageOfFund: { name: "NAV as a % of Fund", category: "range" },
  lastFundingDate: { name: "Last Round Amount", category: "range" },
  totalFunding: { name: "Total Raised", category: "range" },
  latestExcitementScore: { name: "Latest Excitement Score", category: "range" },

  navInvested: { name: "Total NAV", category: "range" },
  totalInvested: { name: "Total Cost", category: "range" },
  firstInvestedDollar: {
    name: "First Dollar to Follow-On",
    category: "range",
  },
};

type MultiSelectOptions = "Is any of" | "Is all of" | "Is none of";
type TagOptions = "Is";
type DateOptions = "Before" | "After" | "Between";
type NumberOptions = "Greater than" | "Less than" | "Between";

export const categoryFilterOptions: Record<
  "multiselect" | "date" | "number" | "tag" | "range",
  MultiSelectOptions[] | TagOptions[] | DateOptions[] | NumberOptions[]
> = {
  multiselect: ["Is any of", "Is none of"],
  date: ["Before", "After", "Between"],
  number: ["Greater than", "Less than", "Between"],
  tag: ["Is"],
  range: ["Between"],
};

export const extraOptions: Record<"multiselect" | "range" | "date" | "tag", Array<MultiSelectCategories>> = {
  multiselect: ["Sectors", "Basecamp Funds", "Phoenix Court Group Funds", "Supported By", "Tagged In"],
  range: [],
  date: [],
  tag: [],
};
