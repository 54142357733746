import Sheet from "react-modal-sheet";

import { useBreakpoints } from "@/lib/hooks";

import { Text } from "@/app/components";

interface ModalHeaderWebProps {
  containerClassName?: string;
  title: string;
  subtitle?: string;
}

export const ModalHeader = ({ containerClassName, title, subtitle }: ModalHeaderWebProps) => {
  const { isBigTablet } = useBreakpoints();
  return isBigTablet ? (
    <div className={`flex flex-col lg:mb-6 xl:mb-10 ${containerClassName}`}>
      <Text text={title} type={"h5"} weight={"medium"} />
      <Text text={subtitle} className={"text-[14px] font-[500] text-neutral-500"} />
    </div>
  ) : (
    <Sheet.Header className={`mb-4 flex flex-col items-center justify-center ${containerClassName}`}>
      <Text text={title} type={"subtitle"} />
      <Text
        text={subtitle}
        color={"text-neutral-600"}
        weight={"light"}
        className="text-center text-[14px] font-[500] text-neutral-500"
      />
    </Sheet.Header>
  );
};
