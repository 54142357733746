import { text } from "@/app/styles";
import { Icon, Text } from "../../../../../components";

export const InsightTitle = () => {
  return (
    <div className={"mb-2 flex items-center gap-x-2"}>
      <Icon type={"Insight"} className={text.titleIcon} />
      <Text text={"Insight"} type={"title"} className={text.titleText} />
    </div>
  );
};
