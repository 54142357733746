import { useEffect } from "react";

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { observer } from "mobx-react";

import { metricsStore } from "@/app/screens/metrics-extraction";

import packageJson from "../../../../../../package.json";
import { escapeRegExp } from "../../utils.ts";

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

export const PdfViewer = observer(({ url }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({});

  const quotation = metricsStore.activeQuotations?.[0]?.quotation;
  const quotRegex =
    quotation &&
    new RegExp(
      "(" +
        escapeRegExp(quotation)
          ?.replace(/^[\\. ]+|[\\. ]+$/g, "")
          ?.replace(/\s+/g, "[^\\w]+") +
        ")",
      "gm",
    );
  useEffect(() => {
    quotRegex && defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance.highlight(quotRegex);
  }, [quotation]);

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
      <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} defaultScale={SpecialZoomLevel.PageWidth} />
    </Worker>
  );
});
