import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

type CardHeaderProps = {
  className?: string;
};

export const CardHeader = ({ children, className }: PropsWithChildren<CardHeaderProps>) => {
  return <div className={cn("relative flex w-full items-center", className)}>{children}</div>;
};
