import React, { useState } from "react";
import { Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";
import { useBreakpoints } from "@/lib/hooks";

export const NotificationIcon = React.memo(
  ({
    src,
    alt,
    status,
    className,
  }: {
    src: string;
    alt: string;
    status?: string;
    className?: string;
  }) => {
    const [error, setError] = useState(false);
    const { isBigTablet } = useBreakpoints();
    const ack = status === "SEEN";
    return (
      <div
        className={cn(
          "mx-2 my-0.5 h-8 w-8 lg:h-10 lg:w-10",
          className,
          isBigTablet ? "" : "relative"
        )}
      >
        {!ack && !isBigTablet && (
          <div
            className={
              "absolute right-0 top-0 -m-0.5 h-3 w-3 rounded-full border border-white bg-primary"
            }
          />
        )}
        {!src || error ? (
          <div
            className={
              "flex h-8 w-8 items-center justify-center rounded-sm bg-gradient-to-t from-secondary-400 to-primary-400 lg:h-10 lg:w-10"
            }
          >
            <Text
              text={"N"}
              color={"text-white"}
              type={isBigTablet ? "h5" : "title"}
            />
          </div>
        ) : (
          <img
            src={src}
            alt={alt}
            className={cn(
              "h-8 w-8 rounded-sm object-cover lg:h-10 lg:w-10",
              className
            )}
            onError={() => setError(true)}
          />
        )}
      </div>
    );
  }
);
