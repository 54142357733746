import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from "react";

type PageNavContextData = {
  activeElements: string[];
  handleSectionEnter: (sectionName: string) => void;
  handleSectionLeave: (sectionName: string) => void;
};

const PageNavContext = createContext<PageNavContextData>({
  activeElements: [],
  handleSectionEnter: () => {},
  handleSectionLeave: () => {},
});

const useActiveSections = () => {
  const [currentVisibleElements, setCurrentVisibleElements] = useState<string[]>([]);

  const handleSectionEnter = useCallback((sectionName: string) => {
    setCurrentVisibleElements((prevState) => [...prevState, sectionName]);
  }, []);

  const handleSectionLeave = useCallback((sectionName: string) => {
    setCurrentVisibleElements((prevState) => prevState.filter((el) => el !== sectionName));
  }, []);

  return {
    activeElements: currentVisibleElements,
    handleSectionEnter,
    handleSectionLeave,
  };
};

export const PageNavProvider: FC<PropsWithChildren> = ({ children }) => {
  const activeSections = useActiveSections();

  return <PageNavContext.Provider value={activeSections}>{children}</PageNavContext.Provider>;
};

export const usePageNav = () => {
  const context = useContext(PageNavContext);

  if (!context) {
    throw new Error("usePageNav must be used inside a PageNavProvider");
  }

  return context;
};
