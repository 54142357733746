import React from "react";
import { Controller } from "react-hook-form";

import { Input, TextArea } from "@/app/components";
import { LocationInput } from "@/app/screens/opportunities/components";

import { CompanyStepComponentProps } from "../../add-company-new.types.ts";

export const CompanyDetailsStep = ({ form }: CompanyStepComponentProps<CompanyDetailsFormData>) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <LocationInput
              required={true}
              defaultValue={
                field.value.country
                  ? {
                      value: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                      label: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                    }
                  : undefined
              }
              onChange={(val) => {
                field.onChange(val);
              }}
              label="HQ Location"
            />
          )}
        />
        <Controller
          name="websiteUrl"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              iconLeft={"Continent"}
              placeholder={"Website"}
              label="Company Website"
              error={fieldState.error?.message}
              containerClassName="relative my-4"
              errorClassName="absolute -bottom-4"
              {...field}
            />
          )}
        />
        <Controller
          name="about"
          control={control}
          render={({ field, fieldState }) => (
            <TextArea
              containerClassName="w-full max-h-[230px]"
              label={"Description"}
              placeholder={"Description"}
              onChange={field.onChange}
              value={field.value}
              required
              error={fieldState.error?.message}
            />
          )}
        />
      </form>
    </div>
  );
};
