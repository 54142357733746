import { Text } from "@/app/components/typography/text";

import { Icon } from "../icon";

export interface ToastProps {
  title: string;
  icon?: JSX.Element;
  subTitle?: string;
  visible?: boolean;
  handleClose?: () => void;
}

export const Toast = ({ icon, title, subTitle, visible, handleClose }: ToastProps) => {
  return (
    <div
      style={{ opacity: visible ? 1 : 0 }}
      className="flex w-[425px] items-start rounded-tremor-small bg-white p-4 shadow-toast"
    >
      {icon && <div className="size-4">{icon}</div>}
      <div className="mx-2 w-full">
        <Text text={title} className="mb-2 text-[16px] font-[600] leading-none" />
        <Text text={subTitle} className="text-[14px] font-[500] text-neutral-700" />
      </div>
      {handleClose && (
        <button className="size-4" onClick={handleClose}>
          <Icon type={"X"} className="cursor-pointer text-neutral-500" />
        </button>
      )}
    </div>
  );
};
