import { observer } from "mobx-react";
import { Modal } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";
import { UseAssignFormParams, useAssignForm } from "./hooks/use-assign-form.tsx";
import { ActionButtons, ActionModalHeader } from "../components";

export const ShareWeb = observer((props: UseAssignFormParams) => {
  const { renderForm, isValid, loading, handleSubmit } = useAssignForm(props);

  return (
    <Modal
      isOpen={true}
      handleClose={modalStore.close}
      header={<ActionModalHeader title={props.title || "Assign for Review"} />}
      footer={
        <ActionButtons
          primaryBtnTitle={props.title || "Assign"}
          secondaryBtnTitle="Cancel"
          primaryBtnVariant="gradient"
          isLoading={loading}
          disabled={!isValid}
          handleCancel={modalStore.close}
          handlePrimaryAction={handleSubmit} />
      }
    >
      {renderForm}
    </Modal>
  );
});
