import { useMemo } from "react";

import { isAfter, addMonths, parseISO } from "date-fns";

import { EngagementOverview } from "../engagement-overview";

import { CardBox } from "./card-box";

export const CardEngagement = ({ attachments, comments, previouslyMet, isPortco }) => {
  const lastEngagement = useMemo(
    () =>
      [...(attachments || []), ...(comments || [])].sort(
        (a, b) => parseISO(a.date).getDate() - parseISO(b.date).getDate(),
      )[0],
    [attachments, comments],
  );

  const isEmpty = attachments?.length === 0 && comments?.length === 0;
  const isOld = lastEngagement && isAfter(new Date(), addMonths(parseISO(lastEngagement.date), 6));

  return (
    <CardBox
      old={isOld}
      title="Engagement"
      isSad={isEmpty && (previouslyMet || isPortco)}
      titleClassName="mb-1 2xl:mb-3 lg:mb-1.5"
      containerClassName="mr-4 ml-2 md:mr-0"
    >
      <EngagementOverview comments={comments} attachments={attachments} />
    </CardBox>
  );
};
