import { useState } from "react";

import { toast } from "react-hot-toast";

import { useBreakpoints } from "@/lib/hooks";

import { Button, Icon, ImageWithErrorProfile, Input, Text } from "@/app/components";
import { useGeneralSettings } from "@/app/screens/settings/hooks";
import { useUpdateUserSettingsMutation } from "@/app/service/lgMembers.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export const General = () => {
  const { user } = useGeneralSettings();
  return (
    <div className={"flex size-full flex-col"}>
      {[{ label: "Photo", key: "avatar" }, { label: "Name" }, { label: "Email Address" }].map(({ label }, index) => {
        return (
          <div key={index} className={"flex h-24 w-full items-center border-b border-neutral-200"}>
            <Text key={index} text={label} className={"w-1/3 text-neutral-700"} />
            <div className={"flex w-2/3 items-center justify-between"}>
              {label === "Photo" && <PhotoSettings user={user} />}
              {label === "Name" && <NameSettings user={user} />}
              {label === "Email Address" && (
                <Text text={user?.email} className={"font-[14px] text-neutral-700"} weight="light" />
              )}
            </div>
          </div>
        );
      })}
      <Button
        text={"Logout"}
        iconRight={"LogOut"}
        variant={"black"}
        className={"absolute bottom-10 left-6"}
        onClick={() => authStore.logout()}
      />
    </div>
  );
};

const PhotoSettings = ({ user }) => {
  return (
    <>
      <ImageWithErrorProfile
        src={user?.["avatar"]}
        className={"size-11 rounded-md text-primary"}
        onClick={console.log}
        alt={user?.["firstName"] || "User"}
      />
      <div className="absolute items-center justify-center opacity-0 lg:relative lg:opacity-100">
        <label htmlFor="dropzone-file">
          <div
            className={
              "text-3.5 text-bold flex h-8 w-fit min-w-[114px] cursor-pointer items-center justify-center rounded-md border border-neutral-200 bg-white px-3 py-2 text-black hover:bg-background active:bg-neutral active:text-white disabled:border-neutral-100 disabled:bg-white disabled:text-neutral-200"
            }
          >
            <Text text={"Edit"} />
            <input id="dropzone-file" type="file" className="hidden" />
          </div>
        </label>
      </div>
    </>
  );
};
const NameSettings = ({ user }) => {
  const { isBigTablet } = useBreakpoints();
  const [{ firstName, lastName, edit }, setData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    edit: false,
  });
  const [updateSettings, { loading: updateSettingsLoading }] = useUpdateUserSettingsMutation();

  const cancel = user.firstName === firstName && user.lastName === lastName;

  const editName = () => {
    if (edit && !cancel) {
      updateSettings({
        variables: { input: { general: { firstName, lastName } } },
      })
        .then(() => {
          authStore.updateUser({ firstName, lastName });
          setData((prev) => ({ ...prev, edit: !prev.edit }));
          toast.success(`Your name is now ${firstName} ${lastName}!`);
        })
        .catch(() => {
          toast.error("Failed to update settings");
        });
      return;
    }
    setData((prev) => ({ ...prev, edit: !prev.edit }));
  };

  return (
    <>
      {edit ? (
        <div className={"flex space-x-2"}>
          <Input
            label={"First Name"}
            labelClassName={"-mb-1.5 ml-1.5 lg:ml-0.5 lg:-mb-1 text-[10px] lg:text-[14px]"}
            className={"rounded-xs py-0.5 lg:rounded-sm lg:py-1.5"}
            value={firstName}
            onChange={({ target: { value } }) => {
              setData((prev) => ({ ...prev, firstName: value }));
            }}
          />
          <Input
            label={"Last Name"}
            labelClassName={"-mb-1.5 ml-1.5 lg:ml-0.5 lg:-mb-1 text-[10px] lg:text-[14px]"}
            className={"rounded-xs py-0.5 lg:rounded-sm lg:py-1.5"}
            value={lastName}
            onChange={({ target: { value } }) => {
              setData((prev) => ({ ...prev, lastName: value }));
            }}
          />
        </div>
      ) : (
        <Text text={`${user?.firstName} ${user?.lastName}`} className={"font-sm text-neutral-700"} weight="light" />
      )}
      {!isBigTablet && !edit && (
        <Icon
          type={"Edit"}
          className={"size-4"}
          onClick={() => {
            setData((prev) => ({ ...prev, edit: !prev.edit }));
          }}
        />
      )}
      {isBigTablet && (
        <Button
          text={edit ? (cancel ? "Cancel" : "Save") : "Edit"}
          size={"sm"}
          loading={updateSettingsLoading}
          variant={edit && !cancel ? "gradient" : "outline"}
          onClick={editName}
        />
      )}
      {edit && !isBigTablet && (
        <Button
          text={edit ? (cancel ? "Cancel" : "Save") : "Edit"}
          loading={updateSettingsLoading}
          className={"absolute bottom-10 right-6"}
          variant={edit && !cancel ? "gradient" : "outline"}
          onClick={editName}
        />
      )}
    </>
  );
};
