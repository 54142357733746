import React from "react";

import { useBreakpoints } from "@/lib/hooks";

import { Modal, NativeModal } from "@/app/components";
import { AddCompanyNew } from "@/app/screens/opportunities/add-new/via-wizard/add-company-new.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

export const AddCompanyNewModal = () => {
  const { isBigTablet } = useBreakpoints();

  return isBigTablet ? (
    <Modal isOpen showX={false} handleClose={modalStore.close} bodyClassName="p-0" bodyContentClassName="p-0">
      <AddCompanyNew />
    </Modal>
  ) : (
    <NativeModal isOpen showX={false} handleClose={modalStore.close}>
      <AddCompanyNew />
    </NativeModal>
  );
};
