import React from "react";

import { AddCompanyNewContextProvider } from "./add-company-new-context.tsx";
import { AddCompanyWizard } from "./components/add-company-wizard.tsx";

export const AddCompanyNew = () => {
  return (
    <AddCompanyNewContextProvider>
      <AddCompanyWizard />
    </AddCompanyNewContextProvider>
  );
};
