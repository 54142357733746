import { useMemo } from "react";

import { Text } from "@/app/components";

import { ConfirmHookProps } from "../types";

export const useConfirm = ({ title, subTitle, icon }: ConfirmHookProps) => {
  const renderContent = useMemo(
    () => (
      <>
        {icon}
        <Text className="mb-6 text-center text-[20px] font-[500] leading-none">{title}</Text>
        <Text type="body" className="mb-8 text-[14px] font-[400] text-neutral-700">
          {subTitle}
        </Text>
      </>
    ),
    [title, subTitle, icon],
  );

  return { renderContent };
};
