import { cn, reduceSignalsToFormattedText } from "@/lib/utils";

import { Popover, Text, Icon } from "@/app/components";
import { Signal } from "@/app/types";

export interface SignalProps {
  signals: Signal[];
  iconClassName?: string;
}

export const Signals = ({ signals = [], iconClassName }: SignalProps) => {
  const formattedText = reduceSignalsToFormattedText(signals);
  return (
    <Popover
      containerClassName="outline-none"
      childrenClassName="bg-gradient-to-r from-primary-400 to-secondary-400 text-white border-none shadow-md"
      directionClassName="bg-primary-400 border-none"
      placement="right"
      childrenContent={
        formattedText ? (
          <Text innerHtml={formattedText} type="message" weight="light" className="max-w-[350px] px-0.5 font-[400]" />
        ) : null
      }
    >
      <Icon type="SignalGradient" className={cn("md:h-5 lg:h-3 2xl:h-4", iconClassName)} />
    </Popover>
  );
};
