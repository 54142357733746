import React, { forwardRef } from "react";

import { Text, type TextType } from "@/app/components";

export interface PillProps {
  label: string;
  className?: string;
  pillClassName?: string;
  textType?: TextType;
}

export const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(
  { label, textType, className = "", pillClassName = "text-[10px] font-[500] md:text-[12px]" }: PillProps,
  ref,
) {
  return (
    <div
      ref={ref}
      className={`inline-block overflow-hidden rounded-xs border-0 bg-neutral-100 px-2 py-[3px] text-center outline-none transition-all ${className}`}
    >
      <Text
        text={label}
        type={textType}
        className={`whitespace-nowrap text-neutral-700 group-hover:text-black lg:text-xss 2xl:text-[12px] ${pillClassName}`}
      />
    </div>
  );
});
