import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { AnimatedNumber, AnimatedNumberProps } from "@/app/components/animated-number/animated-number.tsx";

type ChangeIndicatorProps = {
  value: {
    increase?: number;
    decrease?: number;
  };
  options?: {
    container?: {
      className?: string;
    };
    icon?: {
      className?: string;
    };
    text?: {
      prefix?: string;
      suffix?: string;
      format?: string; // todo number format
      className?: string;
    };
    countUp?: AnimatedNumberProps["options"];
  };
};

export const ChangeIndicator = ({ value: { increase, decrease }, options }: ChangeIndicatorProps) => {
  if (increase) {
    return (
      <div className={cn("flex items-center gap-1", options?.container?.className)}>
        <Icon type={"Increase"} className={cn("size-4", "text-green", options?.icon?.className)} />
        <AnimatedNumber
          value={increase}
          className={cn("p-0 font-medium text-green", options?.text?.className)}
          prefix={options?.text?.prefix}
          suffix={options?.text?.suffix}
          options={options?.countUp}
        />
      </div>
    );
  }

  if (decrease) {
    return (
      <div className={cn("flex items-center gap-1", options?.container?.className)}>
        <Icon type={"Decrease"} className={cn("size-4", "text-red")} />
        <AnimatedNumber
          value={decrease}
          className={cn("p-0 font-medium text-red", options?.text?.className)}
          prefix={options?.text?.prefix}
          suffix={options?.text?.suffix}
          options={options?.countUp}
        />
      </div>
    );
  }
};
