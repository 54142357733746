import {
  SearchCompaniesDealroomDocument,
  SearchCompaniesDealroomQuery,
  SearchCompaniesLocalGlobeDocument,
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables,
} from "@/app/service/opportunities.gql.ts";
import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import { useLoadableQuery } from "@apollo/client";
import { DealroomCompanyFilter, LgCompanyFilter } from "@/gql/types.ts";

export const START_QUERY_AFTER = 2;

export const useSearch = (companyType = "lg") => {
  const [loading, toggleLoading] = useState(false);
  const [query, queryRef, { reset }] = useLoadableQuery<
    SearchCompaniesLocalGlobeQuery | SearchCompaniesDealroomQuery,
    SearchCompaniesLocalGlobeQueryVariables
  >(
    companyType === "lg"
      ? SearchCompaniesLocalGlobeDocument
      : SearchCompaniesDealroomDocument,
    {
      fetchPolicy: 'network-only',
    }
  );

  const debounced = useCallback(
    debounce(async (filter: LgCompanyFilter | DealroomCompanyFilter) => {
      try {
        await query({ filter, limit: 12, offset: 0 });
      } finally {
        toggleLoading(false);
      }
    }, 1000),
    []
  );

  const search = useCallback(
    async (filter: { name: { fuzzy: string } }) => {
      toggleLoading(true);
      return debounced(filter);
    },
    [debounced]
  );

  return {
    queryRef,
    loading,
    query,
    search,
    reset,
  };
};
