export type PcgLeads = {
  title: string;
  totalMembers: number;
  pcgMembers: any[];
};

export const usePcgLeads = (
  assignees: any[],
  portCo: boolean
): PcgLeads | null => {
  if (!assignees?.length) {
    return null;
  }

  return {
    title: portCo ? "PCG Leads" : "Previously Met",
    totalMembers: assignees.length,
    pcgMembers: assignees,
  };
};
