import { useCallback } from "react";

import { DateRange } from "react-day-picker";
import Sheet from "react-modal-sheet";

import { Calendar, CalendarProps, Icon, IosTransitionEnter, NativeModal } from "@/app/components";

import "react-day-picker/dist/style.css";

export type NativePickerProps = {
  value?: Date | DateRange | undefined;
  calendarProps?: Partial<CalendarProps>;
  onChange?: (date: Date) => void;
  handleClose: () => void;
  goBack: () => void;
};

export const NativePicker = ({ value, calendarProps, onChange, handleClose, goBack }: NativePickerProps) => {
  const handleValueChange = useCallback(
    (value: Date) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handlePick = useCallback(
    (value: Date = new Date()) => {
      handleValueChange(value);
      goBack();
    },
    [handleValueChange, goBack],
  );

  return (
    <NativeModal isOpen={true} handleClose={handleClose}>
      <IosTransitionEnter type={"enter"}>
        <div className={"flex h-full flex-col"}>
          <Sheet.Header className={"mb-2 mt-5 flex items-center px-4"}>
            <Icon type={"Chevron Left"} height={25} width={25} onClick={goBack} />
          </Sheet.Header>
          <Sheet.Content>
            <Calendar
              selected={value}
              onSelect={handlePick}
              mode="single"
              enableYearNavigation={true}
              {...calendarProps}
            />
          </Sheet.Content>
        </div>
      </IosTransitionEnter>
    </NativeModal>
  );
};
