import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LgMembersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
  before?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
  condition?: Types.InputMaybe<Types.LgMemberCondition>;
  filter?: Types.InputMaybe<Types.LgMemberFilter>;
  first?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  last?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  orderBy?: Types.InputMaybe<Array<Types.LgMembersOrderBy> | Types.LgMembersOrderBy>;
}>;

export type LgMembersQuery = {
  __typename?: "Query";
  lgMembers?: {
    __typename?: "LgMembersConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LgMember";
      avatar?: string | null;
      createdUtc: any;
      email: string;
      firstName: string;
      id: any;
      initials: string;
      isInvestor: boolean;
      lastModifiedUtc: any;
      lastName: string;
      trelloMemberId?: string | null;
    }>;
  } | null;
};

export type LgMemberQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type LgMemberQuery = {
  __typename?: "Query";
  lgMember?: {
    __typename?: "LgMember";
    id: any;
    email: string;
    firstName: string;
    isInvestor: boolean;
    lastName: string;
    avatar?: string | null;
    lgMemberSettingsByUserId: {
      __typename?: "LgMemberSettingsConnection";
      nodes: Array<{ __typename?: "LgMemberSetting"; feedbackEmailTemplate?: any | null }>;
    };
  } | null;
};

export type UpdateUserSettingsMutationVariables = Types.Exact<{
  input: Types.UserSettingsPayloadInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: "Mutation"; updateUserSettings?: any | null };

export const LgMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LgMembers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "before" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "condition" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgMemberCondition" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgMemberFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "first" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgMembersOrderBy" } },
            },
          },
          defaultValue: { kind: "ListValue", values: [{ kind: "EnumValue", value: "PRIMARY_KEY_ASC" }] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMembers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: { kind: "Variable", name: { kind: "Name", value: "before" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: { kind: "Variable", name: { kind: "Name", value: "condition" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "first" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: { kind: "Variable", name: { kind: "Name", value: "last" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "avatar" } },
                      { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "isInvestor" } },
                      { kind: "Field", name: { kind: "Name", value: "lastModifiedUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useLgMembersQuery__
 *
 * To run a query within a React component, call `useLgMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLgMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLgMembersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLgMembersQuery(baseOptions?: Apollo.QueryHookOptions<LgMembersQuery, LgMembersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LgMembersQuery, LgMembersQueryVariables>(LgMembersDocument, options);
}
export function useLgMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LgMembersQuery, LgMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LgMembersQuery, LgMembersQueryVariables>(LgMembersDocument, options);
}
export function useLgMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LgMembersQuery, LgMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LgMembersQuery, LgMembersQueryVariables>(LgMembersDocument, options);
}
export type LgMembersQueryHookResult = ReturnType<typeof useLgMembersQuery>;
export type LgMembersLazyQueryHookResult = ReturnType<typeof useLgMembersLazyQuery>;
export type LgMembersSuspenseQueryHookResult = ReturnType<typeof useLgMembersSuspenseQuery>;
export type LgMembersQueryResult = Apollo.QueryResult<LgMembersQuery, LgMembersQueryVariables>;
export const LgMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LgMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lgMemberSettingsByUserId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "feedbackEmailTemplate" } }],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "isInvestor" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useLgMemberQuery__
 *
 * To run a query within a React component, call `useLgMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useLgMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLgMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLgMemberQuery(
  baseOptions: Apollo.QueryHookOptions<LgMemberQuery, LgMemberQueryVariables> &
    ({ variables: LgMemberQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LgMemberQuery, LgMemberQueryVariables>(LgMemberDocument, options);
}
export function useLgMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LgMemberQuery, LgMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LgMemberQuery, LgMemberQueryVariables>(LgMemberDocument, options);
}
export function useLgMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LgMemberQuery, LgMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LgMemberQuery, LgMemberQueryVariables>(LgMemberDocument, options);
}
export type LgMemberQueryHookResult = ReturnType<typeof useLgMemberQuery>;
export type LgMemberLazyQueryHookResult = ReturnType<typeof useLgMemberLazyQuery>;
export type LgMemberSuspenseQueryHookResult = ReturnType<typeof useLgMemberSuspenseQuery>;
export type LgMemberQueryResult = Apollo.QueryResult<LgMemberQuery, LgMemberQueryVariables>;
export const UpdateUserSettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserSettings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UserSettingsPayloadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserSettings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userSettingsPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
