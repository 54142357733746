import { Action, Entity } from "@/gql/graphql.ts";
import modalStore from "@/app/stores/modal.store";
import { useActionMutation } from "@/app/hooks";

export const useSnooze = (form, companyId: string, onSuccess?: () => void) => {
  const { onSubmit: snooze, loading: snoozeLoading, error: snoozeError } = useActionMutation({ onSuccess });

  const entity = Entity.Company;

  const onSubmit = async () => {
    return snooze({
        action: Action.Snooze,
        entityId: companyId,
        other: form.values().other,
        feedbackEmail: form.values().feedback_email,
        resurface: form.values().resurface,
        reason: form.values().reason,
      });
  };
  return { onSubmit, snoozeLoading, snoozeError };
};
