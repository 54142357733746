import { useNavigate } from "react-router-dom";

import { cn } from "@/lib/utils.ts";

import { Card } from "@/app/components";
import { Routes } from "@/app/constants";

import { DateColumn } from "../../components/email-opportunity/date-column.tsx";
import { DetailsColumn } from "../../components/email-opportunity/details-column.tsx";
import { EmailAuthorColumn } from "../../components/email-opportunity/email-author-column.tsx";
import { EmailOpportunityActions } from "../../components/email-opportunity/email-opportunity-actions.tsx";
import { NotificationStatusComponent } from "../../components/email-opportunity/notification-status.tsx";
import { NotificationStatus } from "../../dashboard.types.ts";

import { useCompaniesForwarded } from "./use-companies-forwarded.ts";

type CompaniesForwardedItemData = ReturnType<typeof useCompaniesForwarded>["items"][number];

export const CompaniesForwardedItem = ({
  notification,
  className,
}: {
  notification: CompaniesForwardedItemData;
  className?: string;
}) => {
  const navigate = useNavigate();
  const title = notification.nzrOpportunityEmailAsReference?.subject ?? "No Subject";
  const author = notification.nzrOpportunityEmailAsReference?.senderEmail ?? "No Author";
  const date = notification.createdUtc;
  const numberOfCompanies = notification.payload.email.no_opps;

  const isCompleted = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED].includes(
    notification.status as NotificationStatus,
  );

  return (
    <Card className={cn("flex items-start justify-between gap-4 md:flex-row md:items-center", className)}>
      <Card.Body className="grid grid-cols-2 items-center gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          <EmailAuthorColumn
            author={author}
            label={title}
            styles={{
              labelStyle: "text-black text-sm font-[600] xl:min-w-[270px] line-clamp-1",
            }}
          />
          <DateColumn
            date={date}
            styles={{ container: "text-xs md:hidden min-w-[80px]", labelStyle: "w-full text-end" }}
          />
        </div>

        <EmailAuthorColumn styles={{ container: "md:hidden" }} label="From" author={author} />
        <DateColumn date={date} label="Date" styles={{ container: "hidden md:flex col-span-2" }} />
        <div className="md:col-span-2">
          <DetailsColumn label="Companies" styles={{ container: "w-fit" }}>
            <span className="w-full text-center">{numberOfCompanies || 0}</span>
          </DetailsColumn>
        </div>
        <div className="md:col-span-2">
          <NotificationStatusComponent status={notification.status as NotificationStatus} />
        </div>
        <EmailOpportunityActions
          className="col-span-2 md:col-span-2 md:w-auto"
          actions={{
            primary: {
              title: isCompleted ? "Viewed" : "Open",
              action: () =>
                navigate(`${Routes.company}/add-via-email/${notification.nzrOpportunityEmailAsReference?.id}`),
            },
          }}
        />
      </Card.Body>
    </Card>
  );
};
