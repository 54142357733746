import {
  InsightTitle,
  Excitement,
  FormDetails,
  EvolutionExcitementValue,
  EvolutionExcitement,
  ExpectedReturn,
  LastExpectedReturn,
} from "@/app/screens/opportunities/opportunity/components/insight";
import { Sentiment } from "@/app/types";
import { EmptyState } from "@/app/components/label";
import { Container } from "@/app/components";

export interface InsightProps {
  sentiments: Sentiment[];
  container?: string;
}

export const Insight = ({ sentiments, container }: InsightProps) => {
  const totalSentimentForms = sentiments.filter(({ responses }) =>
    Boolean(responses?.length)
  ).length;

  const participantsList = sentiments.reduce<string[]>((acc, { analytics }) => {
    if (!analytics?.person) return acc;
    return [...acc, ...Object.keys(analytics.person)];
  }, []);

  const lastExpectedReturn = Object.entries(
    sentiments.find((e) => e?.analytics?.["return_multiple_choice"])?.analytics[
      "return_multiple_choice"
    ] || {}
  ).reduce<ExpectedReturn[]>((acc, e) => {
    return [...acc, { name: e[0], value: e[1] as string }];
  }, []);

  const evolutionOfExcitement = sentiments.reduce<EvolutionExcitementValue[]>(
    (acc, { analytics, createdAt }) => {
      if (!analytics["excitement_opinion_scale"]) return acc;

      return [
        ...acc,
        {
          Excitement: analytics["excitement_opinion_scale"].mean,
          date: createdAt,
        },
      ];
    },
    []
  );

  const noOfParticipants = new Set(participantsList).size;

  let exictement = 0;
  const lastSentimentResponse = sentiments.find((e) => e?.responses?.length);

  if (!lastSentimentResponse)
    return (
      <Container className={container}>
        <InsightTitle />
        <EmptyState className="mb-4 text-left" />
      </Container>
    );

  if (lastSentimentResponse?.analytics?.excitement_opinion_scale) {
    exictement = lastSentimentResponse.analytics.excitement_opinion_scale.mean;
  }

  return (
    <Container className={container}>
      <InsightTitle />
      <Excitement
        excitement={exictement}
        lastSentimentResponse={lastSentimentResponse}
      />
      <FormDetails
        noOfParticipants={noOfParticipants}
        totalSentimentForms={totalSentimentForms}
      />
      <EvolutionExcitement evolutionOfExcitement={evolutionOfExcitement} />
      <LastExpectedReturn lastExpectedReturn={lastExpectedReturn} />
    </Container>
  );
};
