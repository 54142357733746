import { Icon as IconComponent } from "../icon/icon";
import React from "react";

interface NavButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  icon: string;
}

export const NavButton = ({ onClick, icon, ...other }: NavButtonProps) => {
  const Icon = icon;
  return (
    <button
      type="button"
      className={
        "flex h-12 w-12 select-none items-center justify-center rounded-tremor-small border border-tremor-border p-1 text-tremor-content-subtle outline-none transition duration-100"
      }
      {...other}
    >
      <IconComponent onClick={onClick} type={Icon} className="h-7 w-7" />
    </button>
  );
};
