import { ComponentType } from "react";

import { action, makeObservable, observable, runInAction } from "mobx";

export type StoreModalProps = Partial<Record<string, any>>;

export type RenderModal<T extends StoreModalProps> = ComponentType<T>;

export interface OpenModalParams<T extends StoreModalProps> {
  props?: T;
}

export interface ProgressModalParams<T extends StoreModalProps> extends OpenModalParams<T> {
  returnParams?: T;
}

class ModalStore {
  $history: Array<{
    Modal: RenderModal<StoreModalProps>;
    props: Partial<StoreModalProps>;
  }> = [];

  constructor() {
    makeObservable<ModalStore, "$history">(this, {
      $history: observable,
      close: action,
      open: action,
      navigate: action,
      progress: action,
      goBack: action,
    });
  }

  get history() {
    return this.$history;
  }

  open = <T extends StoreModalProps = StoreModalProps>(modal: RenderModal<T>, options: OpenModalParams<T> = {}) => {
    const { props = {} } = options;
    this.$history = [{ Modal: modal as RenderModal<StoreModalProps>, props }];
  };

  navigate = <T extends StoreModalProps>(modal: RenderModal<T>, options: OpenModalParams<T> = {}) => {
    const { props = {} } = options;
    this.$history = [];
    setTimeout(
      () =>
        runInAction(() => {
          this.$history = [{ Modal: modal as RenderModal<StoreModalProps>, props }];
        }),
      300,
    );
  };

  progress = <T extends StoreModalProps>(modal: RenderModal<T>, options: ProgressModalParams<T> = {}) => {
    const { props = {}, returnParams = {} } = options;
    const currentIndex = this.$history.length - 1;

    if (this.$history[currentIndex]) {
      this.$history[currentIndex] = {
        ...this.$history[currentIndex],
        props: { ...this.$history[currentIndex].props, ...returnParams },
      };
      this.$history.push({
        Modal: modal as RenderModal<StoreModalProps>,
        props,
      });
    } else {
      this.open(modal, options);
    }
  };

  goBack = () => {
    if (this.$history.length) {
      this.$history.pop();
    } else {
      this.close();
    }
  };

  close = () => {
    this.$history = [];
  };
}

export default new ModalStore();
