import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { ResponsiveActionMenu } from "../actions-menu";
import { ButtonColorType } from "../button";

export type CardAction = {
  label: string;
  action: () => void;
  variant?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
};

export interface CardActionProps<T extends string> {
  containerClassName?: string;
  availableActionNames: T[];
  actions: Record<T, CardAction>;
}

const actionWrapper = async (e, action: () => void) => {
  e.stopPropagation();
  e.preventDefault();
  return action();
};

export const CardActions = <T extends string>({
  containerClassName,
  actions,
  availableActionNames,
  children,
}: PropsWithChildren<CardActionProps<T>>) => {
  return (
    <div className={cn("flex items-center", containerClassName)}>
      {children}
      <ResponsiveActionMenu
        className="ml-2"
        asyncActionLabels={["Hide", "Unhide", "Follow", "Unfollow"]}
        options={
          actions
            ? availableActionNames
                .slice(0)
                .reverse()
                .map((name) => ({
                  ...actions[name],
                  action: (e) => actionWrapper(e, actions[name].action),
                }))
            : []
        }
      />
    </div>
  );
};
