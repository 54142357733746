import { Suspense, useMemo, useRef } from "react";

import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react";
import Sheet, { SheetRef } from "react-modal-sheet";

import { useBreakpoints } from "@/lib/hooks";
import { useMembersMapped } from "@/lib/service/members.ts";
import { cn } from "@/lib/utils.ts";

import { Loader } from "@/app/components";
import { MobileSelect } from "@/app/screens/modal/components";
import { MobileDate } from "@/app/screens/modal/components/mobile-date.tsx";
import globalModalStore, { ModalRoutesType } from "@/app/screens/modal/global-modal.store.ts";
import {
  allSnoozeOptions,
  FilterListMobile,
  FilterMobile,
  shouldResurface,
  SortMobile,
} from "@/app/screens/modal/opportunities";
import { InfoMobile } from "@/app/screens/modal/opportunities/info-mobile.tsx";
import { RequestSentiment } from "@/app/screens/modal/opportunities/request-sentiment-mobile.tsx";
import { SentimentDialogMobile } from "@/app/screens/modal/opportunities/sentiment-dialog-mobile.tsx";
import { SuccessModal } from "@/app/screens/modal/success.tsx";
import { UnderConstructionMobile } from "@/app/screens/modal/under-construction.tsx";
import { OpportunityActionListDialog } from "@/app/screens/opportunities/opportunity/components/actions-list-dialog-mobile.tsx";
import { stages, industries, targetFund, trelloColumns, formCategories } from "@/data/data.tsx";

const ModalRoutesDictionary: Partial<
  Record<
    ModalRoutesType,
    {
      component: (...args) => JSX.Element;
      sheetParams?: Record<string, string>;
      containerClassName?: string;
      contentClassName?: string;
    }
  >
> = {
  "": {
    component: () => <></>,
  },
  UnderConstruction: { component: UnderConstructionMobile },
  Success: { component: SuccessModal },
  OpportunityAddSignal: { component: InfoMobile },
  OpportunityInfoMobile: {
    component: InfoMobile,
    sheetParams: {
      detent: "content-height",
      rootId: "none",
    },
    containerClassName: "!rounded-t-md bg-background",
    contentClassName: "!rounded-t-md my-10",
  },
  OpportunitySort: { component: SortMobile },
  OpportunityFilter: { component: FilterMobile },
  OpportunityFilterComponent: { component: FilterListMobile },
  OpportunitySentiment: { component: SentimentDialogMobile },
  OpportunityActionsList: {
    component: OpportunityActionListDialog,
    sheetParams: {
      detent: "content-height",
      rootId: "none",
    },
    containerClassName: "!rounded-t-md",
    contentClassName: "!rounded-t-md",
  },
  OpportunitySentimentRequest: { component: RequestSentiment },
};

const FlowRoute = {
  SelectComponent: { component: MobileSelect },
  DateComponent: { component: MobileDate },
};

export const GlobalNativeModal = observer(() => {
  const { isBigTablet } = useBreakpoints();
  const { isOpen, snap, modalType, flow, navState } = globalModalStore.state;
  const ref = useRef<SheetRef>();

  const {
    component: ModalContent,
    sheetParams = {},
    containerClassName,
    contentClassName,
  } = ModalRoutesDictionary[modalType] || {};

  const { flowId, configId } = (flow as any)?.at(-1) || {};
  const { component: FlowComponent } = FlowRoute?.[flowId] || {};

  const { form, detent = "full-height", useCustomLoader, props = {} } = navState;

  const content = useMemo(() => {
    if (!ModalContent) return null;

    if (useCustomLoader) {
      return (
        <AnimatePresence>
          <>
            {!flowId && <ModalContent form={form} />}
            {Boolean(flowId && configId) && <FlowComponent {...(FlowRouteParams[configId] || {})} {...props} />}
          </>
        </AnimatePresence>
      );
    }
    return (
      <Suspense fallback={<Loader />}>
        <AnimatePresence>
          <>
            {!flowId && <ModalContent form={form} />}
            {Boolean(flowId && configId) && <FlowComponent {...(FlowRouteParams[configId] || {})} {...props} />}
          </>
        </AnimatePresence>
      </Suspense>
    );
  }, [useCustomLoader, form, flowId, configId, FlowComponent, props, ModalContent]);
  if (isBigTablet) return null;

  return (
    <>
      <Sheet
        rootId={"root"}
        detent={detent}
        ref={ref}
        isOpen={isOpen}
        onClose={() => globalModalStore.toggleModal()}
        initialSnap={snap}
        {...sheetParams}
      >
        <Sheet.Container className={containerClassName}>
          <Sheet.Content className={cn("mb-4", contentClassName)}>{content}</Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => globalModalStore.toggleModal()} />
      </Sheet>
    </>
  );
});

const FlowRouteParams = {
  AddTrello: {
    getOptions: () => ({ data: trelloColumns }),
    multiselect: false,
    noSearch: false,
  },
  Snooze: {
    getOptions: () => ({ data: allSnoozeOptions }),
    multiselect: false,
  },
  Resurface: {
    getOptions: () => ({ data: shouldResurface }),
    multiselect: false,
  },
  Members: {
    getOptions: useMembersMapped,
    multiselect: true,
    showIcon: true,
  },
  Industries: {
    getOptions: () => ({ data: industries }),
    multiselect: true,
  },
  Meetings: {
    getOptions: () => ({ data: formCategories }),
    multiselect: false,
  },
  Founders: {
    getOptions: () => ({ data: [] }),
    multiselect: false,
    noSearch: false,
  },
  "Last Round Raised": {
    getOptions: () => ({ data: stages }),
    multiselect: false,
  },
  "Target Fund": {
    getOptions: () => ({ data: targetFund }),
    multiselect: false,
  },
  DatePicker: {
    getOptions: () => ({}),
    multiselect: false,
    value: new Date(),
  },
};

export type FlowRouteType = keyof typeof FlowRouteParams;
