import { authStore } from "@/app/stores/auth.store";

import { useDashboardCompanies } from "./use-dashboard-companies";

export const useReviewingCompanies = () => {
  return useDashboardCompanies({
    signalsByCompanyId: {
      some: {
        nzrOpportunities: {
          some: {
            lgMemberByAssignee: {
              id: { in: [authStore.userId] },
            },
            status: { equalTo: "CREATED" },
          },
        },
      },
    },
    isOpCompany: { equalTo: false },
    srcCompaniesByCompanyId: {
      none: {
        trelloCardAsSource: {
          listId: {
            in: [
              "558c7d47afd9cbf4ccc4c969",
              "5bda26969461ac8bbed1d0f8",
              "637646faa0bfe00221715461",
              "59372e2427706549525b33d7",
              "5bda298f418a8f6cd8e10d12",
              "62c6c4f042096b8c33418d79",
              "61559487ab1c8d67d4e06a6d",
              "5626767dccf2aa74870b6bab",
              "5bdae47c6c0728111d122923",
              "63764680c2233703ffa09dd2",
              "561fe5725d03faeba0a20b5e",
              "5d4bfb8eb30b468aa3ada3ff",
              "619b69465c658d509f7de1b0",
            ],
          },
        },
      },
    },
  });
};
