import { TrelloAttachment, TrelloComment } from "@/lib/service/trello.types";
import { cn } from "@/lib/utils";
import { container } from "@/app/styles";
import { EngagementTitle } from "../../../opportunities/opportunity/components/engagement/title";
import { CommentList } from "../../../opportunities/opportunity/components/engagement/comments-list";
import { AttachmentList } from "../../../opportunities/opportunity/components/engagement/attachment-list";
import { Container, EmptyState } from "@/app/components";

export const Engagement = ({ trelloSource, route }) => {
  const attachments: TrelloAttachment[] = trelloSource?.attachments || [];
  const lastTwoAttachments = [...attachments]?.splice(-2);

  const comments: TrelloComment[] = trelloSource?.comments || [];

  if (comments.length === 0 && attachments.length === 0) {
    return (
      <Container>
        <EngagementTitle />
        <EmptyState className="text-left" />
      </Container>
    );
  }

  const lastTwoComments = [...comments]?.splice(-2);

  return (
    <Container
      id="engagement"
      className={cn(container.boxContainer, "border-none p-0")}
    >
      <EngagementTitle />
      <CommentList
        total={comments.length}
        displayComments={lastTwoComments}
        route={`${route}#comments`}
      />
      <AttachmentList
        total={attachments.length}
        displayAttachments={lastTwoAttachments}
        route={`${route}#attachments`}
      />
    </Container>
  );
};
