import React from "react";
import { observer } from "mobx-react";

import {
  MobileListContainer,
  MobileCardContainer,
} from "@/app/screens/opportunities/components";
import { opportunitiesStore } from "@/app/screens/opportunities";

// todo looks like not used and can be removed
export const MobileOpportunitiesList = observer(() => {
  const { listType, currentTab } = opportunitiesStore.state;

  if (!opportunitiesStore.allOpportunities.length) return null;

  return (
    <div
      id={"mobile_opportunities_list"}
      className={"flex h-fit w-full flex-col items-center"}
    >
      {listType === "card" ? (
        <MobileCardContainer
          opportunities={opportunitiesStore.allOpportunities}
          currentTab={currentTab}
        />
      ) : (
        <MobileListContainer
          opportunities={opportunitiesStore.allOpportunities}
          currentTab={currentTab}
        />
      )}
    </div>
  );
});
