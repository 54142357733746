import { LgMembersQuery } from "@/app/service/lgMembers.gql.ts";

type LGMemberToOption = {
  id: string;
  lastName: string;
  firstName: string;
  initials: string;
  avatar?: string | null;
};

export function LGMemberToOptionValue({ id, lastName, firstName, initials, avatar }: LGMemberToOption) {
  return {
    label: `${firstName} ${lastName}`,
    value: id,
    image: avatar,
    id: id,
    initials,
  };
}

export function convertLGUserToAllOptions(data: LgMembersQuery, isTrelloMember = false) {
  if (!data.lgMembers?.nodes) return [];
  return data.lgMembers?.nodes?.map((lgMember) =>
    LGMemberToOptionValue({ ...lgMember, id: isTrelloMember ? lgMember.trelloMemberId : lgMember.id }),
  );
}

export const allSnoozeOptions = [
  { value: "vision", label: "Vision - is not clear or compelling" },
  { value: "market", label: "Market - does not feel big enough" },
  { value: "product", label: "Product - is not compelling and/or defensible" },
  {
    value: "team",
    label: "Team - is not exceptional and/or suited for the opportunity",
  },
  { value: "fundraising", label: "Fundraising - capabilities seem weak" },
  { value: "timing", label: "Timing - feels too early or too late" },
  {
    value: "fit",
    label: "Fit - misaligned with our focus or competitive with a portfolio company",
  },
  { value: "opportunity", label: "Opportunity - feels capped" },
  { value: "return", label: "Return - unlikely to generate venture returns" },
  { value: "other", label: "Other" },
];

export const shouldResurface = [
  { value: "next_signal", label: "On next signal" },
  { value: "custom", label: "Custom" },
  { value: "never", label: "¯\\_(ツ)_/¯" },
  { value: 2, label: "2 months" },
  { value: 4, label: "4 months" },
  { value: 6, label: "6 months" },
  {
    value: 12,
    label: "12 months",
  },
];

export type SnoozeSelect = "Reason for snoozing" | "Resurface opportunity";
