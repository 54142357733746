import { createQuery } from "react-query-kit";
import { service } from "@/lib/service/index.ts";
import { convertLGUserToAllOptions } from "@/app/screens/modal/opportunities";
import { useLgMembersSuspenseQuery } from "@/app/service/lgMembers.gql.ts";
import membersStore from "@/app/stores/members.store.tsx";
import {
  useOpsCompaniesSuspenseQuery,
  useTopInvestorsSuspenseQuery,
} from "@/app/service/opportunities.gql.ts";

export type Member = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  initials: string;
  email: string;
};

type MembersResponse = {
  count: number;
  results: [Member];
};

export const useMembers = createQuery<MembersResponse>({
  primaryKey: "/members/",
  queryFn: ({ queryKey: [primaryKey] }) => {
    return service
      .get(`${primaryKey}`, {
        params: { page: 1, page_size: 100 },
      })
      .then((res) => res.data);
  },
  staleTime: 1000 * 60 * 10, //10 minutes
  suspense: true,
});

export const useMembersMapped = () => {
  const { data, error } = useLgMembersSuspenseQuery({
    variables: { filter: { isInvestor: { equalTo: true } } },
  });

  membersStore.updateLgMembers(data?.lgMembers?.nodes || []);
  return { data: convertLGUserToAllOptions(data) };
};

export const usePcgCompanies = () => {
  const { data } = useOpsCompaniesSuspenseQuery({});
  membersStore.pcgCompanies = data.opsCompanies?.nodes as any;
};

export const useTopInvestors = () => {
  const { data } = useTopInvestorsSuspenseQuery({});
  membersStore.topInvestors = data.lgInvestors?.nodes as any;
};
