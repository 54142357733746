import { Calendar, Icon, IosTransitionEnter } from "@/app/components";
import Sheet from "react-modal-sheet";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { CloseModal } from "@/app/screens/modal/components";
import { observer } from "mobx-react";
import "react-day-picker/dist/style.css";
import { useCallback, useState } from "react";
import { Select } from "@/app/components/input";
import { shouldResurface } from "../opportunities";
import { dateAdd } from "@/app/components/date-picker/utils";
import { format } from "date-fns";

export const MobileDate = observer(() => {
  const {
    navState: { form, fieldId, value, rangeSelect = false, calendarProps = {} },
  } = globalModalStore.state;
  const [showCalendar, toggleCalendar] = useState(!rangeSelect);

  if (!form || !fieldId) return null;
  const field = form.$(fieldId);

  const handlePick = useCallback(
    (value) => {
      field.value = format(value, "yyyy-MM-dd");
      globalModalStore.goBack();
    },
    [field]
  );

  const handleSelect = useCallback(
    (option: string | number) => {
      toggleCalendar(option === "custom");

      if (typeof option === "number") {
        const date = dateAdd({ value: option, type: "months" });
        field.value = format(date, "yyyy-MM-dd");
        globalModalStore.goBack();
      }
      if (typeof option === "string" && option !== "custom") {
        field.value = option;
        globalModalStore.goBack();
      }
    },
    [toggleCalendar, field]
  );

  return (
    <IosTransitionEnter type={"enter"}>
      <CloseModal />
      <div className={"flex h-full flex-col"}>
        <Sheet.Header className={"mb-2 mt-5 flex items-center px-4"}>
          <Icon
            type={"Chevron Left"}
            height={25}
            width={25}
            onClick={() => globalModalStore.goBack()}
          />
        </Sheet.Header>
        <Sheet.Content>
          {rangeSelect && (
            <div className="p-3">
              <Select
                options={shouldResurface}
                label="Select Range"
                className="mb-4 w-full border-tremor-border bg-white px-1 py-2 text-neutral-400 shadow-tremor-input hover:bg-neutral-200"
                optionsClassName="text-black"
                onChange={(option) => handleSelect(option.value)}
              />
            </div>
          )}
          {showCalendar && (
            <Calendar
              selected={value}
              onSelect={handlePick}
              mode="single"
              enableYearNavigation={true}
              {...calendarProps}
            />
          )}
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
});
