import { useEffect, useMemo } from "react";

import { mergeDeep } from "@apollo/client/utilities";

import { FundFragment, LgCompany } from "@/gql/graphql";

import { groupByCompany } from "@/app/screens/investments/components";
import { opportunitiesStore } from "@/app/screens/opportunities";
import filterStore from "@/app/screens/opportunities/filter.store.ts";
import {
  OpsFundsQueryVariables,
  useMeetThePortfolioLazyQuery,
  useOpsFundsLazyQuery,
} from "@/app/service/opportunities.gql.ts";

export type Fund = Omit<FundFragment, "company"> & {
  fundName: string;
  initialEv: number;
  company: LgCompany;
};

const mapOpsFunding = (node: any, allFundsNodes: any, mtpData?: any) => {
  const { fund, company, id, ...rest } = node;
  let subRows =
    company?.id && allFundsNodes
      ? groupByCompany({ id: company.id, funds: allFundsNodes }).map((item) => mapOpsFunding(item, null))
      : [];

  if (subRows.length > 0) {
    const aggRow = {
      totalInvestedCapital: subRows.reduce(
        (accumulator, { totalInvestedCapital }) => accumulator + (totalInvestedCapital || 0),
        0,
      ),
      unrealisedValue: subRows.reduce((accumulator, { unrealisedValue }) => accumulator + (unrealisedValue || 0), 0),
      currentEv: subRows.reduce((accumulator, { currentEv }) => accumulator + (currentEv || 0), 0),
      currentNav: subRows.reduce((accumulator, { currentNav }) => accumulator + (currentNav || 0), 0),
    };

    subRows.push(aggRow);
    subRows = subRows.filter((row) => row.id !== id);
  }
  const mtpFund = mtpData?.[fund?.name] || {};
  const mtpCompany = mtpFund?.[company?.name];
  return {
    ...rest,
    revenue: rest.revenue || (mtpCompany?.revenue ? mtpCompany.revenue / 1_000_000 : undefined),
    cashoutGroup: rest.cashoutGroup || mtpCompany?.runway,
    grossMargins: rest.grossMargins || mtpCompany?.margin,
    id,
    company: allFundsNodes ? company : undefined,
    subRows,
    latestExcitement: company?.srcCompany?.company?.latestExcitement
      ? (company?.srcCompany?.company?.latestExcitement * 1.0).toFixed(2)
      : undefined,
    fundName: fund?.name,
    initialEv: rest.initialInvestment && rest.goingInOwnership ? rest.initialInvestment / rest.goingInOwnership : 0,
  };
};

const filterByTab = (node: any, currentTab: any, mtpData?: any) => {
  if (!["focus_companies", "liquidity"].includes(currentTab.quickView)) return true;
  const { fundName, company, id, subRows, ...rest } = node;

  //get rid of unrealised NAV 0 companies
  if (currentTab.quickView === "liquidity") {
    const lastSentimentResponses = company?.srcCompany?.company?.last3Sentiment || [];
    if (!lastSentimentResponses.length) return false;
    if (!rest.cashoutGroup) return false;
    if (["<6 months", "6-12 months"].includes(rest.cashoutGroup)) return true;
    if (
      lastSentimentResponses.filter(({ excitement }) => {
        return excitement < 7;
      }).length >= 3
    ) {
      return subRows.some(({ unrealisedValue }) => unrealisedValue > 0);
    }
    if (lastSentimentResponses?.[1]?.excitement - lastSentimentResponses?.[0]?.excitement >= 3) {
      return subRows.some(({ unrealisedValue }) => unrealisedValue > 0);
    }
  }

  if (currentTab.quickView === "focus_companies") {
    const mtpFund = mtpData?.[fundName] || {};
    const mtpCompany = mtpFund?.[company?.name];
    return mtpCompany?.navFundSize > 0.1;
  }
  return false;
};

export const useFundsQuery = () => {
  const variables: OpsFundsQueryVariables = {};
  const { currentTab } = opportunitiesStore.state;

  if (filterStore.mapChoiceForApi) {
    const storeFilter = filterStore.mapChoiceForApi;
    const { srcCompaniesByCompanyId, and, or, ...other } = storeFilter;
    const { opsCompanyAsSource } = srcCompaniesByCompanyId?.some || {};
    const { opsCompanyfundsByCompanyId, ...otherOpsCompany } = opsCompanyAsSource || {};

    variables.filter = {
      ...opsCompanyfundsByCompanyId?.some,
      ...mergeDeep(
        Object.keys(other).length !== 0 ? { company: { srcCompany: { company: { ...other } } } } : {},
        Object.keys(otherOpsCompany).length !== 0 ? { company: { ...otherOpsCompany } } : {},
      ),
    };

    if (Object.keys(variables.filter).length === 0) variables.filter = undefined;
  }
  const [fetchMTP, { data: mtpData }] = useMeetThePortfolioLazyQuery({});

  const [fetchFunds, { data: filteredFunds, error, loading }] = useOpsFundsLazyQuery({
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [fetchAllFunds, { data: allFunds, loading: allFundsLoading, error: allFUndsError }] = useOpsFundsLazyQuery({
    variables: undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const fundList = useMemo(() => {
    if (!filteredFunds?.opsCompanyfunds || !allFunds?.opsCompanyfunds) return [];
    const filterFundsNodes = filteredFunds.opsCompanyfunds.edges.map(({ node }) => node);
    const allFundsNodes = allFunds.opsCompanyfunds.edges.map(({ node }) => node);
    const mtp = mtpData?.opsFunds?.nodes.reduce((acc, { name, mtpCompanyfundsByMtpFundId }) => ({
      ...acc,
      [name]: mtpCompanyfundsByMtpFundId.nodes.reduce(
        (acc, company) => ({ ...acc, [company?.companyName || ""]: company }),
        {},
      ),
    }));
    return filterFundsNodes
      .map((node) => mapOpsFunding(node, allFundsNodes, mtp))
      .filter((node) => filterByTab(node, currentTab, mtp));
  }, [filteredFunds, allFunds, mtpData, currentTab]);

  useEffect(() => {
    Promise.all([fetchAllFunds(), fetchFunds(), fetchMTP()]).catch();
  }, [fetchFunds, fetchAllFunds, fetchMTP, currentTab]);

  return {
    fundList,
    error: error && allFUndsError,
    loading: loading && allFundsLoading,
    filters: variables.filter,
  };
};
