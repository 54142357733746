import React, { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { ServerCrash } from "lucide-react";
import { useNavigate } from "react-router";

import { Button } from "@/app/components";
import filterStore from "@/app/screens/opportunities/filter.store.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";

export function ErrorBoundary({ children }) {
  return <Sentry.ErrorBoundary fallback={<ErrorFallback />}>{children}</Sentry.ErrorBoundary>;
}

export function ErrorFallback() {
  const navigate = useNavigate();

  useEffect(() => {
    filterStore.clearAll();
    opportunitiesStore.clearAll();
  }, []);

  return (
    <div className="mx-8 flex h-full flex-1 flex-col items-center justify-start pt-16 text-center">
      <ServerCrash size={64} />
      <span className="mb-4 mt-4 text-lg font-semibold">
        It's not you, it's me.
      </span>
      <span className="text-grey-dark">
        There was an error processing this request. Nazare team has been
        notified and is working on resolving the issue.
      </span>
      <span className="text-grey-dark mb-8 mt-4">
        If the issue persists, contact us at{" "}
        <a href="mailto:developers@localglobe.vc">
          <b>developers@localglobe.vc</b>
        </a>
      </span>
      <Button
        variant={"outline"}
        size={"md"}
        onClick={() => navigate("/")}
        text={"Go Home"}
      />
    </div>
  );
}
