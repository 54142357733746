import { QueryReference, useReadQuery } from "@apollo/client";
import {
  SearchCompaniesDealroomQuery,
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables,
} from "../service/opportunities.gql";

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type LgCompanies = NonNullable<
  SearchCompaniesLocalGlobeQuery["results"]
>;
export type LgEdges = LgCompanies["nodes"];
export type Company = ArrayElement<LgEdges>;

export type SearchRef = QueryReference<
  SearchCompaniesLocalGlobeQuery | SearchCompaniesDealroomQuery,
  SearchCompaniesLocalGlobeQueryVariables
>;

export const useSearchRef = (searchRef: SearchRef) => {
  const { data, error } = useReadQuery(searchRef);
  return {
    result: [...(data?.results?.nodes || [])],
    error,
  };
};
