import { toast } from "react-hot-toast";

import { Icon, ResponsiveModal, Text, TextArea } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";

export const EmailFeedback = ({ company, responses }) => {
  try {
    const res = JSON.parse(responses);
    return (
      <ResponsiveModal
        isOpen={true}
        handleClose={modalStore.close}
        bodyClassName={"min-h-[85dvh] min-w-[70vw]"}
        bodyContentClassName="flex flex-1 flex-col"
      >
        <div className={"flex w-full flex-col items-center"}>
          <Text
            text={`All done! Our AI has generated 2 email templates for  ${company.name}`}
            type="title"
            className={"mb-5 self-center"}
          />
          <Text
            text={"Feel free to copy them to your clipboard or generate new text"}
            color={"text-neutral-700"}
            weight={"medium"}
          />
        </div>
        <div className={"mt-5 flex h-full flex-1 flex-col gap-y-2"}>
          {res.map((text, index) => (
            <TextArea
              key={index}
              onChange={() => null}
              value={text}
              label={`#${index + 1} Template`}
              containerClassName={"w-full h-full flex-grow flex-1 relative"}
              icons={
                <div
                  className={
                    "absolute bottom-8 right-3 flex cursor-pointer select-none items-center space-x-1 rounded-sm border bg-neutral-200 p-0.5 px-1 hover:bg-neutral-100"
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(text).then(() => {
                      toast.success("Copied to clipboard");
                    });
                  }}
                >
                  <Icon type={"Copy"} className={"size-3"} />
                  <Text text={"Copy"} weight={"light"} />
                </div>
              }
              className={"h-full flex-1"}
              showTranscribe={false}
            />
          ))}
        </div>
      </ResponsiveModal>
    );
  } catch (e) {
    return (
      <ResponsiveModal
        isOpen={true}
        handleClose={modalStore.close}
        bodyClassName={"min-h-[45dvh] min-w-[50vw]"}
        bodyContentClassName="flex flex-1 flex-col"
      >
        <div className={"flex w-full flex-col items-center"}>
          <Text
            text={`Hey, our AI system is working hard to get the feedback emails for ${company.name}.`}
            type="title"
            className={"mb-5 self-center"}
          />
          <Text
            text={"Come back in 1 minute to see the results. Feel free to refresh the notification inbox."}
            color={"text-neutral-700"}
            weight={"medium"}
          />
        </div>
      </ResponsiveModal>
    );
  }
};
