import { useMediaQuery } from "react-responsive";

export const useBreakpoints = () => {
  const isSmallPhone = useMediaQuery({ query: "(max-width: 400px)" });
  const isPhone = useMediaQuery({ query: "(min-width: 425px)" });
  const isBigPhone = useMediaQuery({ query: "(min-width: 640px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isBigTablet = useMediaQuery({ query: "(min-width: 976px)" });
  const isLaptop = useMediaQuery({ query: "(min-width: 1160px)" });
  const isSmallLaptop = useMediaQuery({ query: "(min-width: 1440px)" });

  return {
    isSmallPhone,
    isPhone,
    isBigPhone,
    isTablet,
    isBigTablet,
    isLaptop,
    isSmallLaptop,
  };
};

export const useCustomBreakpoints = ({ min, max }: { min?: number; max?: number }): boolean => {
  if (min == null && max == null) {
    throw new Error("useCustomBreakpoints needs min/max value to be specified");
  }

  return useMediaQuery({ minWidth: min, maxWidth: max });
};
