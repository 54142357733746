import { cn } from "@/lib/utils";

import { Text } from "../typography/text";

export const CardLabel = ({ label, className }: { label?: string | null; className?: string }) => {
  if (!label) {
    return null;
  }

  return (
    <Text
      weight={"medium"}
      text={label}
      color={"text-neutral"}
      className={cn(
        "w-fit select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs",
        className,
      )}
    />
  );
};
