import { Fragment, useState } from "react";

import { observer } from "mobx-react";

import { capitalizeWords, cn } from "@/lib/utils.ts";

import { Dropdown, Icon, Popover, Text } from "@/app/components";
import { metricsStore, Metric, standardMetrics } from "@/app/screens/metrics-extraction";
import { getMetricType, MetricLabelType, metricTypes } from "@/app/screens/metrics-extraction/utils.ts";

export const AddAdditional = ({ containerClassName = "" }) => {
  return (
    <div className={cn("flex w-full flex-col overflow-auto rounded-md", containerClassName)}>
      {/*<CreateNewMetric containerClassName={"mb-6"} />*/}
      <StandardMetricsList containerClassName={"gap-y-3 pb-3"} />
    </div>
  );
};

const createNewMetric = (newMetric: Metric, selection: MetricLabelType): Metric => ({
  ...newMetric,
  ...selection.value,
});

const CreateNewMetric = ({ containerClassName = "" }) => {
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [newMetric, setNewMetric] = useState<Partial<Metric> | null>(null);
  const [error, setError] = useState<string | null>(null);
  return (
    <div
      className={cn(
        "flex min-h-12 w-full cursor-pointer select-none items-center justify-center rounded-sm border border-neutral-200 shadow-[1px_4px_6px_0px_rgba(0,0,0,0.04)]",
        actionType === "create" ? "hover:bg-neutral-50 active:bg-neutral-100" : "",
        containerClassName,
      )}
      onClick={() => {
        if (actionType === "create") {
          setActionType("edit");
          setNewMetric({ label: "", type: "string", value: "" });
        }
      }}
    >
      <>
        {actionType === "create" ? (
          <>
            <Icon type={"Add"} className={"mr-2 size-4"} />
            <Text text={"Create new metric"} color={"text-neutral-800"} weight={"semibold"} />
          </>
        ) : null}
        {actionType === "edit" ? (
          <div className={"flex size-full items-center justify-between"}>
            <div className={"flex w-3/4 items-center justify-between"}>
              <input
                autoFocus={true}
                placeholder={error || "Metric name"}
                className={cn("w-3/4 px-3 text-[14px] font-medium 2xl:w-1/2", error ? "placeholder:text-red-300" : "")}
                value={newMetric?.label}
                onChange={({ target: { value: label } }) => {
                  if (error) setError(null);
                  setNewMetric((prev: Metric) => ({ ...prev, label }));
                }}
                onKeyUp={({ key }) => {
                  if (key === "Enter") {
                    try {
                      metricsStore.addMetric({
                        ...newMetric,
                        category: "manual",
                        source: "Manually Added",
                        label: capitalizeWords(newMetric?.label?.trim()),
                      });
                      setNewMetric(null);
                      setActionType("create");
                    } catch (error) {
                      setNewMetric((prevState) => ({ ...prevState, label: "" }));
                      setError(error.message);
                    }
                  }
                  if (key === "Escape" && !newMetric?.label) {
                    setNewMetric(null);
                    setActionType("create");
                  }
                }}
              />
              <Dropdown className={"flex h-full w-1/2 items-center outline-0 2xl:w-2/5"} options={metricTypes}>
                <Dropdown.Button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={"flex h-7 w-fit rounded-xs border border-neutral-200 bg-neutral-100 px-3.5 outline-0"}
                >
                  <Text text={getMetricType(newMetric) || "Metric Type"} weight={"medium"} className={"text-nowrap"} />
                  <Icon type={"Filter Chevron Down"} className={"lg:size-3.5 2xl:size-4"} />
                </Dropdown.Button>
                <Dropdown.Items className={"py-2 outline-0"}>
                  {({ label, value }) => (
                    <Fragment key={label}>
                      <Dropdown.Item
                        className={"h-8"}
                        onClick={() => {
                          setNewMetric((prev: Metric) => createNewMetric(prev, { label, value }));
                        }}
                      >
                        <Text className={cn("truncate text-black")} weight={"medium"} text={label} />
                      </Dropdown.Item>
                    </Fragment>
                  )}
                </Dropdown.Items>
              </Dropdown>
            </div>
            <div className={"flex w-1/4 justify-end space-x-2 pl-4 pr-2"}>
              <div
                className={
                  "flex size-7 items-center justify-center rounded-xs border border-neutral-300 hover:bg-neutral-100 active:bg-neutral-200"
                }
                onClick={() => setActionType("create")}
              >
                <Icon type={"X"} className={"size-4.5"} />
              </div>
              <div
                className={
                  "flex size-7 items-center justify-center rounded-xs bg-gradient-to-r from-primary to-secondary hover:opacity-90 active:opacity-60"
                }
                onClick={() => {
                  if (newMetric?.label) {
                    metricsStore.addMetric({
                      ...newMetric,
                      category: "manual",
                      source: "Manually Added",
                      label: capitalizeWords(newMetric.label.trim()),
                      id: newMetric.label,
                    });
                    setNewMetric(null);
                    setActionType("create");
                  } else {
                    setError("Enter a valid metric");
                  }
                }}
              >
                <Icon type={"Check"} className={"size-6 text-white"} />
              </div>
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

const StandardMetricsList = observer(({ containerClassName = "" }) => {
  return (
    <div className={cn("flex flex-col", containerClassName)}>
      {!!metricsStore.currentMetrics.manual.length && (
        <>
          <Text text={"Manually added"} className={"text-[14px]"} color={"text-neutral-700"} weight={"normal"} />
          {metricsStore.currentMetrics.manual.map(
            ({ label, type, format, formatInfo, active, required, category }: Metric) => (
              <Fragment key={label}>
                <MetricItem
                  label={label}
                  type={getMetricType({ type, format, formatInfo })}
                  active={active}
                  required={required}
                  origin={category}
                />
              </Fragment>
            ),
          )}
        </>
      )}
      <Text text={"Standard"} className={"text-[14px]"} color={"text-neutral-700"} weight={"normal"} />
      {metricsStore.currentMetrics.standard.map(
        ({ label, type, format, formatInfo, active, required, category }: Metric) => (
          <Fragment key={label}>
            <MetricItem
              label={label}
              type={getMetricType({ type, format, formatInfo })}
              active={active}
              required={required}
              origin={category}
            />
          </Fragment>
        ),
      )}
    </div>
  );
});

const MetricItem = ({ label, type, active, required, origin }) => {
  const [newMetric, setNewMetric] = useState<Partial<Metric> | null>(label);
  const [error, setError] = useState<string | null>(null);
  const [edit, setEdit] = useState(false);

  if (edit) {
    return (
      <div
        className={
          "flex min-h-12 w-full items-center justify-between rounded-sm border border-neutral-200 bg-white px-1.5"
        }
      >
        <div className={"flex w-3/4 items-center justify-between"}>
          <input
            autoFocus={true}
            placeholder={error || label}
            className={cn("w-3/4 px-3 text-[14px] font-medium 2xl:w-1/2", error ? "placeholder:text-red-300" : "")}
            onChange={({ target: { value: label } }) => {
              if (error) setError(null);
              setNewMetric((prev: Metric) => ({ ...prev, label }));
            }}
            onKeyUp={({ key }) => {
              if (key === "Enter") {
                try {
                  if (newMetric?.label) {
                    metricsStore.editMetric(label, {
                      ...newMetric,
                      label: capitalizeWords(newMetric?.label?.trim()),
                    });
                    setNewMetric(null);
                    setEdit(false);
                  }
                } catch (error) {
                  setNewMetric((prevState) => ({ ...prevState, label: "" }));
                  setError(error.message);
                }
              }
              if (key === "Escape" && !newMetric?.label) {
                setNewMetric(null);
                setEdit(false);
              }
            }}
          />
          <Dropdown className={"flex h-full w-1/2 items-center outline-0 2xl:w-2/5"} options={metricTypes}>
            <Dropdown.Button
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={"flex h-7 w-fit rounded-xs border border-neutral-200 bg-neutral-100 px-3.5 outline-0"}
            >
              <Text text={getMetricType(newMetric) || "Metric Type"} weight={"medium"} className={"text-nowrap"} />
              <Icon type={"Filter Chevron Down"} className={"lg:size-3.5 2xl:size-4"} />
            </Dropdown.Button>
            <Dropdown.Items className={"outline-0"}>
              {({ label, value }) => (
                <Fragment key={label}>
                  <Dropdown.Item
                    onClick={() => {
                      setNewMetric((prev: Metric) => createNewMetric(prev, { label, value }));
                    }}
                  >
                    <span className={cn("truncate text-black")}>{label}</span>
                  </Dropdown.Item>
                </Fragment>
              )}
            </Dropdown.Items>
          </Dropdown>
        </div>
        <div className={"flex w-1/4 justify-end space-x-2 pl-4 pr-2"}>
          <div
            className={
              "flex size-7 items-center justify-center rounded-xs border border-neutral-300 hover:bg-neutral-100 active:bg-neutral-200"
            }
            onClick={() => setEdit(false)}
          >
            <Icon type={"X"} className={"size-4.5"} />
          </div>
          <div
            className={
              "flex size-7 items-center justify-center rounded-xs bg-gradient-to-r from-primary to-secondary hover:opacity-90 active:opacity-60"
            }
            onClick={() => {
              if (newMetric?.label) {
                metricsStore.editMetric(label, {
                  label: capitalizeWords(newMetric?.label?.trim()),
                });
                setNewMetric(null);
                setEdit(false);
              } else {
                setError("Enter a valid metric");
              }
            }}
          >
            <Icon type={"Check"} className={"size-6 text-white"} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        "flex min-h-12 w-full items-center justify-between rounded-sm border border-neutral-200 bg-neutral-100 pl-4.5 pr-3"
      }
    >
      <div className={"flex w-3/4 items-center justify-between"}>
        <Popover childrenContent={label.length > 20 ? label : null} containerClassName={"mr-7 w-3/5"}>
          <Text
            text={label}
            color={"text-neutral-800"}
            weight={"medium"}
            className={cn(
              "w-full select-none text-nowrap text-start 2xl:text-[16px]",
              label.length > 20 ? "hover:cursor-auto" : "",
            )}
            noOfLines={1}
          />
        </Popover>

        <Text
          text={type}
          color={"text-neutral-400"}
          weight={"medium"}
          className={"w-2/5 select-none lg:text-[12px] 2xl:text-[14px]"}
        />
      </div>
      <div className={"flex items-center justify-end space-x-2.5"}>
        {origin === "manual" && (
          <Icon
            type={"Edit"}
            className={"size-4 cursor-pointer hover:text-neutral-400 2xl:size-4.5"}
            onClick={() => {
              setEdit(true);
            }}
          />
        )}
        {!standardMetrics.some(({ label: defaultLabel }) => defaultLabel === label) && (
          <Icon
            type={"Trash"}
            className={"size-4 cursor-pointer hover:text-neutral-400 2xl:size-4.5"}
            onClick={() => {
              if (required) return;
              metricsStore.deleteMetric(label);
            }}
          />
        )}
        {!required && (
          <Icon
            type={active ? "Sub" : "Add"}
            className={cn(
              "size-7 cursor-pointer select-none rounded-sm bg-neutral-900 p-1 text-white hover:bg-neutral-800",
              required
                ? "bg-neutral-200 text-neutral-600 hover:bg-neutral-200 hover:text-neutral-600 active:bg-neutral-100 active:text-neutral-600"
                : "",
            )}
            onClick={() => {
              if (required) return;
              metricsStore.editMetric(label, { active: !active });
            }}
          />
        )}
      </div>
    </div>
  );
};
