import { BadgeType } from "@/types/badges.types.ts";
import { action, computed, makeObservable, observable } from "mobx";
import { SentimentForm } from "@/lib/service/sentiment.types.ts";

export type NotificationTypes =
  | "new_opportunity"
  | "shared_opportunity"
  | "email_opportunities_list_review"
  | "sentiment_request"
  | "sentiment_request_chaser";

type Company = { name?: string; image?: string };

type Opportunity = {
  type: "new_opportunity" | "shared_opportunity";
  payload: {
    title: string;
    company?: Company;
    date?: string;
    signal?: BadgeType;
    notes?: string;
    image?: string;
  };
};

type EmailOpportunities = {
  type: "email_opportunities_list_review";
  payload: { title: string; email?: string; subject?: string };
};

type SentimentRequest = {
  type: "sentiment_request" | "sentiment_request_chaser";
  payload: {
    title: string;
    meeting?: string;
    company?: Company;
    date?: string;
    signal?: BadgeType;
    image?: string;
  };
};

export type NotificationType = {
  id: string;
  date?: string;
  status?: string;
  ack?: string;
} & (Opportunity | EmailOpportunities | SentimentRequest);

class NotificationStore {
  notifications: Record<string, NotificationType> = {
    // "item-0": {
    //   type: "new_opportunity",
    //   ack: Math.random() > 0.09 ? undefined : "2023-12-04T16:51:42.780Z",
    //   date: "2023-12-04T16:51:42.780Z",
    //   id: "item-0",
    //   payload: {
    //     company: { name: "Synergetic CodeHub", image: "" },
    //     date: "2023-12-04T16:51:42.780Z",
    //     signal: defaultSignal,
    //     image:
    //       "https://media.istockphoto.com/id/1198378223/photo/hand-holding-light-bulb-energy-sources-for-renewable-natural-energy-concept.jpg?s=2048x2048&w=is&k=20&c=0Cf6Hm1oySe7_mO59XU-pR9L_HI4g4RC5z7hlWqhWoA=",
    //     title: "New opportunity surfaced!",
    //   },
    // },
    // "item-1": {
    //   type: "shared_opportunity",
    //   ack: Math.random() > 0.09 ? undefined : "2023-12-04T16:51:42.780Z",
    //   date: "2023-12-04T16:51:42.780Z",
    //   id: "item-1",
    //   payload: {
    //     company: { name: "Synergetic CodeHub", image: "" },
    //     date: "2023-12-04T16:51:42.780Z",
    //     notes: "Hello, Ziv! I really think you should look at this!",
    //     image:
    //       "https://signal-api.nfx.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL2hRQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--bc6286b6ac810f7331a50053e176590cf40a9e48/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBPZ2hxY0djNkUzSmxjMmw2WlY5MGIxOW1hV3hzV3dkcEFsZ0NhUUpZQWc9PSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--f8e22238db523e6e5e5a8ae643921849c4b207bd/0?d=592x592",
    //     title: "Ziv Reichert shared an opportunity",
    //   },
    // },
    // "item-2": {
    //   type: "email_opportunities_list_review",
    //   ack: Math.random() > 0.09 ? undefined : "2023-12-04T16:51:42.780Z",
    //   date: "2023-12-04T16:51:42.780Z",
    //   id: "item-2",
    //   payload: {
    //     title: "Here is a list of opportunities ready for you to review",
    //     subject: "Hi Ziv, here's a list of GPT Jailbreak opportunities",
    //     email: "ziv@localglobe.vc",
    //   },
    // },
    // "item-3": {
    //   type: "sentiment_request",
    //   ack: Math.random() > 0.09 ? undefined : "2023-12-04T16:51:42.780Z",
    //   date: "2023-11-29T16:51:42.780Z",
    //   id: "item-3",
    //   payload: {
    //     date: "2023-12-01T16:51:42.780Z",
    //     meeting: "Investment Presentation",
    //     company: { name: "Synergetic", image: "" },
    //     image:
    //       "https://i.pinimg.com/280x280_RS/a5/bc/87/a5bc87520176c79b905ae29cce62ea47.jpg",
    //     title: "Ben Grol requests your sentiment",
    //   },
    // },
    // "item-4": {
    //   type: "sentiment_request_chaser",
    //   ack: Math.random() > 0.09 ? undefined : "2023-12-04T16:51:42.780Z",
    //   date: "2023-11-02T16:51:42.780Z",
    //   id: "item-4",
    //   payload: {
    //     date: "2023-12-04T16:51:42.780Z",
    //     meeting: "Developing Conviction",
    //     company: { name: "Synergetic", image: "" },
    //     title: "Please make sure to fill this sentiment request",
    //   },
    // },
  };
  constructor() {
    makeObservable(this, {
      notifications: observable,
      markAsRead: action,
      setNotification: action,
      notificationsList: computed,
      notificationNumber: computed,
      outstandingNotificationNumber: computed,
      setStatus: action,
    });
  }

  markAsRead(id?: string) {
    if (id && this.notifications[id]) {
      this.notifications[id].ack = new Date().toISOString();
      return;
    }
    Object.keys(this.notifications).map((key) => {
      this.notifications[key].ack = new Date().toISOString();
    });
  }

  setStatus(id: string, status: string) {
    try {
      this.notifications[id].status = status;
    } catch (e) {
      console.error(e);
    }
  }

  setNotification(sentimentForms: SentimentForm[]) {
    this.notifications = {
      //...this.notifications,
      ...Object.fromEntries(
        sentimentForms.map(({ node: form }: any) => [
          form.id,
          {
            id: form.id,
            type: form.type,
            status: form.status,
            date: form.createdUtc,
            payload: {
              date: form.createdUtc,
              ...form.payload,
            },
          },
        ])
      ),
    };
  }

  get notificationNumber() {
    return Object.values(this.notifications).length;
  }
  get notificationsList() {
    return Object.values(this.notifications);
  }

  get outstandingNotificationNumber() {
    return this.notificationsList.reduce(
      (acc, { ack }) => acc + (ack ? 0 : 1),
      0
    );
  }
}

export default new NotificationStore();
