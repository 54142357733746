import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { TextArea } from "@/app/components";
import { CompaniesFromEmailForm } from "@/app/screens/opportunities/add-new/via-email/use-add-company-from-email-form.ts";

export const CompanyNoteSection = ({ index }: { index: number }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return (
    <div>
      <Controller
        name={`companies.${index}.company.note.note` as const}
        control={control}
        render={({ field }) => (
          <TextArea
            containerClassName="w-full max-h-[400px]"
            placeholder={
              "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
            }
            onChange={field.onChange}
            value={field.value ?? ""}
          />
        )}
      />
    </div>
  );
};
