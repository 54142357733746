import React from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Header, MetricsDashboard, MetricsContentDisplay } from "@/app/screens/metrics-extraction/sections";
import { useMetricsExtraction } from "@/app/screens/metrics-extraction/use-metrics-extraction.tsx";
import { NotAvailableOnMobile } from "@/app/screens/misc/not-available-on-mobile.tsx";

export const MetricsExtractionDashboard = () => {
  const { email } = useMetricsExtraction();
  const { isBigTablet } = useBreakpoints();
  if (!isBigTablet) return <NotAvailableOnMobile />;
  return (
    <div
      className={cn("relative flex h-dvh max-h-screen w-full p-6 2xl:px-8 2xl:py-6", email ? "gap-x-4" : "flex-col")}
    >
      <div className={"flex w-full flex-col"}>
        <Header containerClassName={"my-2 2xl:my-4"} email={email} />
        <MetricsContentDisplay email={email} />
      </div>
      <MetricsDashboard email={email} />
    </div>
  );
};
