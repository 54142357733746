import { useRef, useEffect } from "react";

import { cn } from "@/lib/utils";

import { ProfileImage } from "./profile-image";

export interface MemberCardProps {
  firstname: string;
  lastname: string;
  image?: string | null;
  includeName?: boolean;
  imgClassName?: string;
  className?: string;
  labelClassName?: string;
  setRef?: (current: HTMLDivElement) => void;
}

export const MemberCard = ({
  firstname,
  lastname,
  image,
  includeName = true,
  imgClassName,
  labelClassName,
  className,
  setRef,
}: MemberCardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && setRef) {
      setRef(ref.current);
    }
  }, [ref, setRef]);

  return (
    <div ref={ref} className={cn("text-center", className)}>
      <ProfileImage firstname={firstname} lastname={lastname} image={image} imgClass={imgClassName} />
      {includeName && <p className={cn("text-sm text-black", labelClassName)}>{firstname}</p>}
    </div>
  );
};
