import React from "react";

import { cn } from "@/lib/utils.ts";
import { ChangeIndicator } from "@/app/components/change-indicator/change-indicator.tsx";

import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "./highlights-card.tsx";
import { CompanyDataForHighlights } from "./highlights.types.ts";

type RevenueCardProps = {
  company: CompanyDataForHighlights;
};

const useRevenue = (company: CompanyDataForHighlights) => {
  const data = company?.mtpCompanyfundsByMtpCompanyId
    ? company.mtpCompanyfundsByMtpCompanyId.nodes[company?.mtpCompanyfundsByMtpCompanyId.nodes.length - 1]
    : null;

  const yoyGrowth = data?.yoyGrowth && !isNaN(+data?.yoyGrowth) ? +data?.yoyGrowth * 100 : 0;

  const revenue = data?.revenue && !isNaN(+data?.revenue) ? +data?.revenue / 1e6 : null;

  return {
    revenue,
    changeValue: yoyGrowth
      ? {
          increase: yoyGrowth > 0 ? yoyGrowth : undefined,
          decrease: yoyGrowth < 0 ? yoyGrowth : undefined,
        }
      : null,
    lastUpdated: "2024-06-05", // todo change me
  };
};

const useGrossMargins = (company: CompanyDataForHighlights) => {
  const data = company?.mtpCompanyfundsByMtpCompanyId
    ? company.mtpCompanyfundsByMtpCompanyId.nodes[company?.mtpCompanyfundsByMtpCompanyId.nodes.length - 1]
    : null;

  const grossMargins = data?.margin && !isNaN(+data?.margin) ? +data?.margin * 100 : null;

  return {
    grossMargins,
    lastUpdated: "2024-06-05", // todo change me
  };
};

export const RevenueCard = ({ company }: RevenueCardProps) => {
  const { revenue, changeValue, lastUpdated } = useRevenue(company);
  const { grossMargins } = useGrossMargins(company);

  if (!company.isOpCompany) {
    return null;
  }

  return (
    <HighlightsCard type={"Insight"}>
      {revenue != null || grossMargins != null ? (
        <>
          <div className={"flex justify-between gap-x-3 h-full"}>
            {revenue != null && (
              <div className="flex flex-col items-center justify-between min-w-[150px]">
                <HighlightsCardTitle text={"Revenue"} />

                <div className="flex flex-col items-center justify-center ">
                  <HighlightsCardNumber
                    value={revenue}
                    prefix={<span className="text-currency mr-2">£</span>}
                    suffix="m"
                    options={{ decimals: 2 }}
                    className={cn(!changeValue && "mb-5")}
                  />
                  {changeValue && (
                    <div className="flex gap-1">
                      <ChangeIndicator value={changeValue} options={{ text: { suffix: "%", className: "text-sm" } }} />
                      <span className="text-sm text-neutral-800 font-semibold">YoY</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {grossMargins != null && (
              <div className="flex flex-col items-center justify-between text-center">
                <HighlightsCardTitle text={"Gross Margins"} />

                <div className="flex items-center mb-5">
                  <HighlightsCardNumber value={grossMargins} suffix="%" />
                </div>
              </div>
            )}
          </div>

          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={lastUpdated} />
          </HighlightsCardFooter>
        </>
      ) : (
        <HighlightsCardEmptyState text={"No Revenue/Gross Margins Data"} />
      )}
    </HighlightsCard>
  );
};
