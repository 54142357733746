import React, { useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { useBreakpoints } from "@/lib/hooks";
import { extractFormCategory } from "@/lib/utils.ts";

import { normaliseDate } from "@/app/misc/helpers.ts";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { OpportunityTable, RowListWithSeparator, TableText } from "@/app/screens/opportunities/opportunity/components";

import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";

type SentimentColumn = {
  companyStatus: string;
  peopleFilled: string;
  peopleRemaining: { label?: string }[];
  avgExcitement: string;
  dateAdded: string;
} & { item: any };

const useColumns = () => {
  const { isBigTablet } = useBreakpoints();

  const columns: Array<ColumnDef<SentimentColumn>> = useMemo(
    () => [
      {
        accessorKey: "companyStatus",
        header: "Company Status",
        size: 200,
        cell: ({ renderValue, row: { original } }) => (
          <TableText
            text={renderValue()}
            className={"cursor-pointer"}
            onClick={() => {
              const { item } = original;
              if (item.responses.length === 0) {
                return;
              }
              opportunityModal({
                id: `sentiment_details`,
                platform: window.innerWidth <= 976 ? "mobile" : "web",
                action: "Sentiment",
              });

              opportunitiesStore.setModalState("sentiment_details", {
                form: item,
              });
            }}
          />
        ),
        enableSorting: false,
      },
      {
        accessorKey: "peopleFilled",
        header: "People Filled",
        size: isBigTablet ? 0 : 175,
        cell: (info) => <RowListWithSeparator list={info.renderValue()} />,
      },
      {
        accessorKey: "peopleRemaining",
        header: "People Remaining",
        size: isBigTablet ? 0 : 175,
        cell: (info) => <RowListWithSeparator list={info.renderValue()} />,
      },
      {
        accessorKey: "avgExcitement",
        header: "Avg Excitement",
        size: 175,
        cell: (info) => <TableText text={info.renderValue()} />,
      },
      {
        accessorKey: "dateAdded",
        header: "Date Added",
        size: 150,
        cell: (info) => <TableText text={info.renderValue()} />,
      },
    ],
    [isBigTablet],
  );

  return columns;
};

type SentimentData = NonNullable<OpportunityCompany>["sentiment"]["nodes"];

export const SentimentTable = ({ sentimentData }: { sentimentData: SentimentData }) => {
  const columns = useColumns();

  const tableData: SentimentColumn[] = sentimentData
    .filter(({ responses }) => responses?.length > 0)
    .map((item) => ({
      item,
      companyStatus: extractFormCategory(item),
      peopleFilled: item.responses?.map((item) => ({
        label: typeof item.person === "string" ? item.person : item.person.other,
      })),
      peopleRemaining: item.notFilled?.nodes?.map((item) => ({
        label: item.member?.firstName,
      })),
      avgExcitement: item.analytics?.excitement_opinion_scale?.mean?.toFixed(2),
      dateAdded: normaliseDate(item.createdAt)!,
    }));

  return <OpportunityTable data={tableData} columns={columns} containerClassName={"w-full mt-14"} />;
};
