import { useState } from "react";

import { format } from "date-fns";

import { dateAdd } from "@/app/components/date-picker/utils";

export const useResurface = () => {
  const [resurfaceOption, setResurfaceOption] = useState<string>();

  const handleResurfacePeriodChange = (value: string | number, cb: (arg: string) => void) => {
    if (value === "custom") {
      setResurfaceOption(value);
      cb("");
    }

    if (value !== "custom") {
      setResurfaceOption("");
    }

    if (typeof value === "number") {
      const date = dateAdd({ value, type: "months" });
      cb(format(date, "yyyy-MM-dd"));
    }
    /**/
    if (typeof value === "string" && value !== "custom") {
      cb(value);
    }
    if (value === undefined) {
      cb("");
      setResurfaceOption("");
    }
  };

  return { resurfaceOption, handleResurfacePeriodChange };
};
