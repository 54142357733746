import { cn } from "@/lib/utils";

import { Icon } from "@/app/components";

export interface CardBoxProps {
  children: React.ReactNode;
  subTitle?: string;
  title?: string;
  containerClassName?: string;
  titleClassName?: string;
  liveIconClassName?: string;
  old?: boolean;
  isSad?: boolean;
}

export const CardBox = ({
  children,
  title,
  containerClassName,
  titleClassName,
  liveIconClassName,
  old = false,
  isSad = false,
}: CardBoxProps) => {
  return (
    <div className={cn("relative", containerClassName)}>
      <p
        className={cn(
          "whitespace-nowrap font-[500] text-neutral-500 text-xss md:text-sm lg:text-[12px] 2xl:text-[14px]",
          titleClassName,
        )}
      >
        {title}
        {isSad && <Icon type="SadSmile" className={cn("ml-1 inline-block size-4")} />}
        {old && <Icon type="SandClock" className={cn("ml-1 inline-block size-4", liveIconClassName)} />}
      </p>
      {children}
    </div>
  );
};
