import React from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import { ButtonColorType } from "@/app/components/button";
import modalStore from "@/app/stores/modal.store.tsx";

export type AddCompanyActionDef = {
  label: string;
  action: () => void;
  hidden?: boolean;
  disabled?: boolean;
  buttonProps?: { className?: string; variant?: ButtonColorType };
  loading?: boolean;
};

type AddCompanyStepActionsProps = {
  actions: AddCompanyActionDef[];
};

export const AddCompanyStepActions = ({ actions }: AddCompanyStepActionsProps) => {
  const { isBigTablet } = useBreakpoints();
  const buttonSize = isBigTablet ? "md" : "lg";

  const closeModal = () => {
    modalStore.close();
  };

  return (
    <div className="mt-4 flex flex-col-reverse justify-between gap-2 lg:mt-0 lg:flex-row lg:p-10">
      <Button
        text={"Cancel"}
        variant={"outline"}
        className="w-full rounded-md text-[14px] text-black lg:mb-0 lg:mr-4 lg:w-auto"
        size={buttonSize}
        onClick={closeModal}
      />

      <div className="flex flex-col-reverse gap-2 lg:flex-row">
        {actions.map((actionDef) => (
          <Button
            key={actionDef.label}
            text={actionDef.label}
            variant={actionDef.buttonProps?.variant}
            disabled={actionDef.disabled}
            className={cn(
              "ml-auto w-full rounded-md text-[14px] text-black lg:mb-0 lg:mr-4 lg:w-auto",
              actionDef.hidden && "hidden",
            )}
            size={buttonSize}
            onClick={actionDef.action}
            loading={actionDef.loading}
          />
        ))}
      </div>
    </div>
  );
};
