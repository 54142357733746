import { useState } from "react";

import { BaseToggle, BaseToggleProps } from "./base-toggle";

type ToggleProps = {
  leftLabel?: string;
  rightLabel?: string;
  containerClassName?: string;
  value?: boolean;
  disabled?: boolean;
  setValue?: (value: boolean) => void;
  size: BaseToggleProps["size"];
};
export function Toggle({ value = false, setValue, ...props }: ToggleProps) {
  const [enabled, _setEnabled] = useState(value);

  const setEnabled = (value) => {
    _setEnabled(value);
    setValue?.(value);
  };

  return <BaseToggle value={enabled} onChange={setEnabled} {...props} />;
}
