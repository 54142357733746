import { useSentimentQueryGetQuery } from "../service/sentiment.gql.ts";

export const useGetSentimentQuery = ({ id, userId, skip }: { id: string; userId: string; skip?: boolean }) => {
  const { data, error, loading } = useSentimentQueryGetQuery({
    skip,
    variables: { id, userId },
  });

  if (!data) return { error, loading };

  return { error, loading, sentimentForm: data.nzrSentimentForm };
};
