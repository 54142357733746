import React, { useState } from "react";

import { X } from "lucide-react";

import { Button, Text, TextArea } from "@/app/components";

export type FeedbackFormValue = {
  text: string;
};

const texts = {
  title: "Report Issue",
  header: "Are there any issues you wish to report?",
  inputPlaceholder: "Let us know…",
};

export const FeedbackForm = ({
  onClose,
  onSubmit,
  loading,
}: {
  onClose: () => void;
  onSubmit: (value: FeedbackFormValue) => Promise<void>;
  loading?: boolean;
}) => {
  const [text, setText] = useState<string>("");

  const handleSubmit = async () => {
    if (!text) {
      return;
    }

    await onSubmit({ text });
    handleClose();
  };

  const handleClose = () => {
    setText("");
    onClose();
  };

  return (
    <div className="overflow-hidden text-center">
      <div className="flex items-center justify-between">
        <Text type={"subtitle"} text={texts.title} />
        <X
          className={"size-7 cursor-pointer rounded bg-neutral-100 p-0.5 text-neutral-500 hover:text-neutral-600"}
          onClick={handleClose}
        />
      </div>
      <hr className="mt-5" />

      <Text type={"h5"} className="mt-8" text={texts.header} />

      <TextArea
        className={"mt-8 w-full"}
        placeholder={texts.inputPlaceholder}
        value={text}
        onChange={(text) => setText(text)}
      />

      <Button
        variant="black"
        className="mt-6 w-full rounded-md"
        disabled={!text}
        onClick={handleSubmit}
        loading={loading}
      >
        Submit
      </Button>
    </div>
  );
};
