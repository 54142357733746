import { observer } from "mobx-react";

import modalStore from "@/app/stores/modal.store";

export const GlobalModalProvider = observer(() => {
  const { history } = modalStore;

  if (history.length === 0) return null;

  return history.map(({ Modal, props }, index) => {
    return <Modal key={index} {...props} isOpen={index === history.length - 1} />;
  });
});
