import { Popover, ProfileImage, Tag, Text } from "@/app/components";
import { useSplitList } from "@/app/hooks/use-split-list";
import { Badges } from "@/app/types";
import { cn } from "@/lib/utils";

export interface BasecampListProps {
  basecamp: Badges;
  containerClassName?: string;
  className?: string;
  split?: number;
  label?: string;
  labelClassName?: string;
}

export const BasecampList = ({
  basecamp,
  containerClassName,
  className,
  split = 1,
  label,
  labelClassName,
}: BasecampListProps) => {
  const { main, other } = useSplitList(basecamp.extra as Array<{ name: string; image: string }>, split);

  return (
    <div className={cn("grid w-full", containerClassName)}>
      <Popover
        childrenContent={
          <div className="grid grid-cols-1 gap-1">
            {[...main, ...other].map(({ name, image }) => (
              <div key={`${name}_basecamp_item`} className="flex items-center">
                <ProfileImage
                  key={image}
                  image={`https://${image}`}
                  firstname={name.split(" ")[0]}
                  lastname={name.split(" ")[1]}
                  imgClass={className}
                />
                <Text text={name} className={"ml-1 text-xs text-black"} />
              </div>
            ))}
          </div>
        }
      >
        {label && <Text text={label} className={labelClassName} />}
        {main.map(({ name, image }) => (
          <ProfileImage
            key={image}
            image={`https://${image}`}
            firstname={name.split(" ")[0]}
            lastname={name.split(" ")[1]}
            imgClass={className}
          />
        ))}
        {other.length > 0 && (
          <Tag label={`+${other.length}`} className={cn("ml-1", className)} tagClassName="bg-transparent" />
        )}
      </Popover>
    </div>
  );
};
