import { useMemo } from "react";

import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";
import { CompanyDataSource, CompanySectionData } from "@/app/screens/opportunities/opportunity/use-opportunity.tsx";
import { MnEntry } from "@/app/types";

import { FounderFromManualEntry, FounderFromTeam, FoundersData } from "./founders.types.ts";

const getFoundersFromTeam = (
  company: OpportunityCompany,
): {
  source: CompanyDataSource;
  founders: FounderFromTeam[];
} | null => {
  if (!company?.team || company.team.items.length === 0) {
    return null;
  }

  const teamMembers = company.team.items;

  const filtered = teamMembers.filter((a) => a.isFounder).sort((a) => (a.member?.image ? -1 : 1));

  const lastUpdated = filtered?.sort((a, b) => b.member?.lastUpdatedUtc - a.member?.lastUpdatedUtc)?.[0]?.member
    ?.lastUpdatedUtc;

  return {
    source: {
      name: "Dealroom",
      lastUpdated,
    },
    founders:
      filtered.map((founder) => ({
        id: founder.id,
        image: founder.member?.image,
        name: founder.member?.name ?? "N/A", // todo check if need to filter out empty names
        linkedinUrl: founder.member?.linkedinUrl,
        titles: founder.titles,
        forms: founder.member?.forms.nodes,
      })) ?? [],
  };
};

const getFoundersFromManualEntry = (
  company: OpportunityCompany,
): {
  source: CompanyDataSource;
  founders: FounderFromManualEntry[];
} | null => {
  const manualEntryData: MnEntry | null | undefined = company?.companiesSources.items.find(
    (source) => source.mnEntryAsSource != null,
  )?.mnEntryAsSource;

  if (!manualEntryData) {
    return null;
  }

  return {
    source: {
      name: "Manual Entry",
      lastUpdated: manualEntryData.lastUpdatedUtc,
    },
    founders:
      manualEntryData.data.founders
        ?.filter((founder) => !!founder.url)
        .map((founder) => ({
          name: founder.name,
          linkedinUrl: founder.url,
        })) ?? [],
  };
};

export const useFounders = (company: OpportunityCompany): CompanySectionData<FoundersData> => {
  return useMemo(() => {
    const foundersFromTeam = getFoundersFromTeam(company);
    const manuallyEnteredFounders = getFoundersFromManualEntry(company);

    const dataSource = foundersFromTeam ?? manuallyEnteredFounders;
    const founders = dataSource?.founders ?? [];

    // get unique by linkedin_url and then name to remove duplicates
    const uniqueByLinkedinUrl = new Map(founders.map((founder) => [founder.linkedinUrl, founder]));
    const uniqueByName = new Map(Array.from(uniqueByLinkedinUrl.values()).map((founder) => [founder.name, founder]));

    return {
      source: dataSource?.source,
      data: Array.from(uniqueByName.values()),
    };
  }, [company]);
};
