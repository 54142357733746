import MultiRangeSlider from "multi-range-slider-react";
import "./filters.multirange.css";

type MultiRangeProps = {
  min?: number;
  max?: number;
  step?: number;
  minValue: number;
  disabled: boolean;
  maxValue: number;
  handleInput: (...args) => void;
  containerClassName?: string;
};

export const MultiRange = ({
  min = 0,
  max = 100,
  step = 5,
  minValue,
  disabled = false,
  maxValue,
  handleInput,
  containerClassName,
}: MultiRangeProps) => {
  return (
    <div className={`${containerClassName}`}>
      <MultiRangeSlider
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        stepOnly={true}
        ruler={false}
        style={{
          backgroundColor: "none",
          border: "none",
          boxShadow: "none",
        }}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          if (disabled) return;
          handleInput(e);
        }}
      />
    </div>
  );
};
