export const text = {
  titleText: `text-title cursor-pointer`,
  subTitleText: `text-base cursor-pointer`,
  titleIcon: `h-6 w-6 text-primary xl:h-7 xl:w-7 cursor-pointer`,
  body: `text-[14px] lg:text-[16px] font-normal cursor-pointer`,
  message: `lg:text-[11px] xl:text-[13px] cursor-pointer`,
  small: "text-[9px]",
};

export const container = {
  boxContainer: `border border-gray-300 rounded-md w-full p-6`,
};
