import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const CompanyDetailsSchema = z.object({
  location: z
    .object({
      country: z.string().min(1, "Please enter a location"),
      city: z.string(),
    })
    .required(),
  // websiteUrl: z
  //   .string()
  //   .regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/, { message: "Please enter a valid url" }),
  websiteUrl: z.string().optional().nullable(),
  about: z.string().min(1, "Please enter a company description"),
});

export type CompanyDetailsFormData = z.infer<typeof CompanyDetailsSchema>;

export const useCompanyDetailsForm = () => {
  return useForm<CompanyDetailsFormData>({
    resolver: zodResolver(CompanyDetailsSchema),
    mode: "onChange",
    defaultValues: {
      websiteUrl: "",
      location: {
        country: "",
        city: "",
      },
      about: "",
    },
  });
};

export const updateCompanyDetailsForm = (form: UseFormReturn<CompanyDetailsFormData>, selectedCompany) => {
  let location = {
    country: "",
    city: "",
  };

  if (selectedCompany && "country" in selectedCompany && "city" in selectedCompany) {
    location = {
      country: selectedCompany.country,
      city: selectedCompany.city,
    };
  } else if (selectedCompany && "hqLocations" in selectedCompany) {
    const [hqLocation] = selectedCompany.hqLocations ?? [];

    const city = (hqLocation?.city?.length && hqLocation?.city[0].name) ?? "";
    const country = (hqLocation?.country?.length && hqLocation?.country[0].name) ?? "";

    location = { country, city };
  }

  form.reset({
    location,
    websiteUrl: selectedCompany.websiteUrl ?? "",
    about: selectedCompany.about ?? "",
  });
};
