import { FileUploadFn, FileUploadFnParam } from "@/app/hooks/use-file-upload.ts";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { useUploadCompanyAttachmentMutation } from "@/app/service/opportunities.gql.ts";

const options = {
  messages: {
    success: "Attachment successfully uploaded.",
  },
};

export const useAttachmentUpload = (
  onSuccess?: () => void,
): {
  options: typeof options;
  uploadFn: FileUploadFnParam;
} => {
  const { company } = useOpportunityContext();

  const [upload, { loading, error }] = useUploadCompanyAttachmentMutation();

  const uploadFn: FileUploadFn = async (filePath) => {
    if (!company) {
      throw new Error("Cannot upload attachment without company id");
    }

    const name = filePath.split("/").at(-1);

    if (!name) {
      throw new Error(`Could not get file name from path: ${filePath}`);
    }

    const result = await upload({
      variables: {
        companyId: company.id,
        name: name,
        path: filePath,
      },
    });

    onSuccess?.();

    return result.data?.companyAttachmentUpload;
  };

  return {
    options: options,
    uploadFn: [uploadFn, { loading, error }],
  };
};
