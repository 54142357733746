import { AnimatePresence, motion } from "framer-motion";

const transition = {
  iosEnter: {
    type: "spring",
    stiffness: 300,
    damping: 30,
  },
};

const variants = {
  exit: {
    initial: { x: -300, opacity: 0 },
    enter: { x: 0, opacity: 1, transition: transition["iosEnter"] },
    exit: { x: 300, opacity: 0, transition: transition["iosEnter"] },
  },
  enter: {
    initial: { x: 300, opacity: 0 },
    enter: { x: 0, opacity: 1, transition: transition["iosEnter"] },
    exit: { x: -300, opacity: 0, transition: transition["iosEnter"] },
  },
  static: {
    initial: { x: 0, opacity: 0 },
    enter: { x: 0, opacity: 1, transition: transition["iosEnter"] },
    exit: { x: 0, opacity: 0, transition: transition["iosEnter"] },
  },
};

type TransitionParams = {
  children: React.ReactNode;
  type: "enter" | "exit" | "static";
  className?: string;
};
export const IosTransitionEnter = ({
  children,
  type = "enter",
  className,
}: TransitionParams) => {
  return (
    <>
      <motion.div
        id="modal"
        key="modal"
        initial="initial"
        animate="enter"
        exit="exit"
        className={`h-full ${className}`}
        variants={variants[type]}
      >
        {children}
      </motion.div>
    </>
  );
};
