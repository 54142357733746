import { useNavigate } from "react-router";

import { LgCompany } from "@/gql/types.ts";

import { Card } from "./card";

type WebCardContainerProps = {
  opportunities: (LgCompany & any)[];
  currentTab: string;
};

export function CardList({ opportunities }: WebCardContainerProps) {
  const navigate = useNavigate();

  return (
    <ul role="list" className="grid w-full grid-cols-1 gap-2 md:grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3">
      {opportunities.map((opportunity, index) => (
        <Card
          key={opportunity.id}
          company={opportunity}
          index={index}
          onClick={() => navigate(`/company/${opportunity.id}`)}
        />
      ))}
    </ul>
  );
}
