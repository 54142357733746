import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "@/app/screens/opportunities/add-new/shared/components/form-field.tsx";
import { CompaniesFromEmailForm } from "@/app/screens/opportunities/add-new/via-email/use-add-company-from-email-form.ts";

import { MatchingCompany } from "./matching-company.tsx";

export const MatchingCompanies = ({ index }: { index: number /*company: CompanyRawData | null*/ }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return (
    <div className="flex flex-wrap justify-between gap-3">
      <Controller
        name={`companies.${index}.company.mainData.lgCompany`}
        control={control}
        render={({ field }) => (
          <FormField label="Nazare company" className="flex-1">
            <MatchingCompany
              company={field.value}
              type={"lg"}
              onSelect={(data) => {
                field.onChange(data);
              }}
            />
          </FormField>
        )}
      />

      <Controller
        name={`companies.${index}.company.mainData.dealroomCompany`}
        control={control}
        render={({ field }) => (
          <FormField label="Dealroom company" className="flex-1">
            <MatchingCompany
              company={field.value}
              type={"dealroom"}
              onSelect={(data) => {
                field.onChange(data);
              }}
            />
          </FormField>
        )}
      />
    </div>
  );
};
