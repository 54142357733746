import { motion } from "framer-motion";

export const ProgressCircle = ({
  progress,
  className,
  displayPercentage,
}: {
  progress: number;
  className?: string;
  displayPercentage?: boolean;
}) => {
  const strokeWidth = 10; // Thickness of the circle
  const size = 100; // Default size for calculations (this will be overridden by the container's size)
  const radius = size / 2 - strokeWidth / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg viewBox={`0 0 ${size} ${size}`} preserveAspectRatio="xMidYMid meet" className={className}>
      <circle stroke="lightgrey" fill="transparent" strokeWidth={strokeWidth} r={radius} cx={size / 2} cy={size / 2} />
      <motion.circle
        stroke="currentColor"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        initial={{ strokeDashoffset: circumference }} // Start animation from full circle
        animate={{ strokeDashoffset }} // Animate to the target progress
        transition={{ duration: 1, ease: "easeInOut" }} // Smooth animation
      />
      {displayPercentage && (
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".3em"
          fontSize="20px" // This can be controlled via CSS
          fill="black"
        >
          {`${progress}%`}
        </text>
      )}
    </svg>
  );
};
