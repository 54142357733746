import React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { TextArea } from "@/app/components";

import { AddCompanyFormSection, AddCompanySectionEmptyState } from "./section";

const CompanyNoteSchema = z.object({
  note: z.string().optional(),
  // note: z.string().min(1, "Please enter a note"),
});

type CompanyNoteFormData = z.infer<typeof CompanyNoteSchema>;

export const useCompanyNoteForm = () => {
  return useForm<CompanyNoteFormData>({
    resolver: zodResolver(CompanyNoteSchema),

    defaultValues: {
      note: "",
    },
  });
};

export const CompanyNoteForm = ({ form }: { form: UseFormReturn<{ note: string }> }) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AddCompanyFormSection title="Note">
      {(editMode) =>
        editMode ? (
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextArea
                  containerClassName="w-full"
                  label={"Note"}
                  placeholder={
                    "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
                  }
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </form>
        ) : form.getValues("note") ? (
          <p>{form.getValues("note")}</p>
        ) : (
          <AddCompanySectionEmptyState>No Notes</AddCompanySectionEmptyState>
        )
      }
    </AddCompanyFormSection>
  );
};
