import React, { useLayoutEffect, useRef, useState } from "react";

import Markdown, { Options as MarkdownOptions } from "react-markdown";
import remarkGfm from "remark-gfm";

import { RichTextEditor } from "@/app/components/rich-text-editor";

import { CommentDisplayData, isNazareComment } from "../comments.types.ts";
import { cn } from "@/lib/utils.ts";
import { motion } from "framer-motion";
import { useBreakpoints } from "@/lib/hooks/useBreakpoints.tsx";

const markdownProps: MarkdownOptions = {
  components: {
    a: (props) => <a {...props} target="_blank" className="font-semibold text-neutral-600 underline sm:text-xs" />,
    p: (props) => <p {...props} className={"sm:text-xs"} />,
  },
  remarkPlugins: [remarkGfm],
};

const editorProps = {
  attributes: {
    class: "mb-4 whitespace-pre-wrap break-words text-sm sm:text-xs font-medium text-neutral-700 lg:mb-6",
  },
};

export const CommentBody = ({ comment }: { comment: CommentDisplayData }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isTooBig, setIsTooBig] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);
  const { isLaptop } = useBreakpoints();

  useLayoutEffect(() => {
    const { offsetHeight } = ref.current || {};

    if (offsetHeight! >= 248 && !isLaptop) {
      setIsTooBig(true);
    }
  }, [ref]);

  const getHeight = () => {
    if (ref.current?.offsetHeight! >= 248) {
      return 248;
    }
    return ref.current?.offsetHeight;
  };

  return isNazareComment(comment) ? (
    <RichTextEditor content={comment.metadata.data ?? comment.text} editable={false} editorProps={editorProps} />
  ) : (
    <motion.div
      className={"mb-2"}
      initial={{ height: "min-content" }}
      animate={{
        height: !isReadingMore && !isLaptop ? getHeight() : "auto",
      }}
      transition={{ duration: 0.2, ease: "linear" }}
    >
      <div ref={ref} className={"h-auto overflow-hidden"}>
        <Markdown
          className={cn(
            "whitespace-pre-wrap break-words text-sm font-medium text-neutral-700 overflow-hidden",
            isTooBig && !isReadingMore && "line-clamp-[12]",
          )}
          {...markdownProps}
        >
          {comment.text}
        </Markdown>
      </div>
      {isTooBig && (
        <button onClick={() => setIsReadingMore((prev) => !prev)} className={"text-xs font-bold text-black mt-auto"}>
          {isReadingMore ? "See less" : "See more"}
        </button>
      )}
    </motion.div>
  );
};
