import React, { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";
import { cn } from "@/lib/utils.ts";

import { Button, IconButton, Input } from "@/app/components";
import { CompanyResult, NewCompany } from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

import { AddCompanyFormSection, AddCompanySectionEmptyState } from "./section.tsx";

const FoundersSchema = z.object({
  founders: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        url: z.string().regex(linkedinProfileUrlRegex, "Please enter a valid url"),
      }),
    )
    .nonempty(),
});

type FoundersFormData = z.infer<typeof FoundersSchema>;

export const useFoundersForm = () => {
  return useForm<FoundersFormData>({
    resolver: zodResolver(FoundersSchema),
    mode: "onChange",
    defaultValues: {
      founders: [
        {
          name: "",
          url: "",
        },
      ],
    },
  });
};

const FoundersViewMode = ({ founders }: { founders: { name: string; url: string }[] }) => {
  const filtered = founders.filter((founder) => !!founder.url && !!founder.name);

  if (filtered.length === 0) {
    return (
      <AddCompanySectionEmptyState>
        <div className="w-full rounded-lg bg-white p-4">No Founders</div>
      </AddCompanySectionEmptyState>
    );
  }

  return (
    <div className="flex gap-2 flex-wrap">
      {filtered.map((founder, index) => (
        <div key={founder.url ?? index} className="flex max-w-[350px] grow gap-1 overflow-hidden rounded-md bg-white">
          <div className="flex w-10 items-center justify-center bg-neutral-300 capitalize">
            {founder.name?.split(" ").map((name) => name.slice(0, 1).toUpperCase())}
          </div>
          <div className="flex flex-col gap-1 p-1">
            <span>{founder.name || "No Name Provided"}</span>
            <span>{founder.url || "No LinkedinUrl Provided"}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const FoundersForm = ({
  form,
  company,
}: {
  form: UseFormReturn<FoundersFormData>;
  company: CompanyResult | NewCompany | null;
}) => {
  const { control, handleSubmit, reset } = form;
  const { fields, append, remove, replace } = useFieldArray({
    name: "founders", // unique name for your Field Array
    control,
    rules: { minLength: 1 },
  });

  const handleAddNew = () => {
    append({
      name: "",
      url: "",
    });
  };

  useEffect(() => {
    if (!!company && "team" in company) {
      const founders = company.team.items
        .filter((member) => member.is_founder)
        .map((founder) => ({
          name: founder.name,
          url: founder.linkedin_url,
        }));

      if (founders.length) {
        replace(founders);
      }
    } else {
      reset();
    }
  }, [company]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AddCompanyFormSection title="Founders" elementProps={{ children: { className: "bg-transparent p-0" } }}>
      {(editMode) =>
        editMode ? (
          <form className="flex flex-wrap items-center gap-2" onSubmit={handleSubmit(onSubmit)}>
            {fields.map((fieldArrayItem, index, array) => (
              <Controller
                key={fieldArrayItem.id}
                control={control}
                name={`founders.${index}`}
                render={({ field, fieldState }) => (
                  <>
                    <div
                      key={fieldArrayItem.id}
                      className={cn(
                        "flex min-w-[250px] flex-col gap-1 overflow-hidden",
                        !!fieldState.error && "border-red-500",
                      )}
                    >
                      <Input
                        iconLeft={"User"}
                        placeholder={"Name"}
                        value={field.value.name}
                        onChange={(e) => field.onChange({ ...field.value, name: e.target.value })}
                      />
                      <Input
                        iconLeft={"Linkedin"}
                        placeholder={"LinkedIn Url"}
                        value={field.value.url}
                        onChange={(e) => field.onChange({ ...field.value, url: e.target.value })}
                      />
                    </div>
                    {array.length > 1 && <IconButton icon={"X"} className="size-5" onClick={() => remove(index)} />}
                  </>
                )}
              />
            ))}
            <Button
              text={"Add Founder"}
              iconRight={"AddGradient"}
              size={"md"}
              variant="outline"
              className="rounded-md"
              onClick={handleAddNew}
            />
          </form>
        ) : (
          <FoundersViewMode founders={form.getValues("founders")} />
        )
      }
    </AddCompanyFormSection>
  );
};
