import { useMemo } from "react";

import { format } from "date-fns";
import { Matcher } from "react-day-picker";

import { useSelectDate } from "@/app/hooks";

import { Input } from "./input";

export interface SelectDateSheetProps {
  selected?: Date;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  label?: string;
  matcher?: Matcher | Matcher[];
  maxDate?: Date;
  minDate?: Date;
  from?: Date;
  showOutsideDays?: boolean;
  iconRight?: string;
  onChange?: (date?: Date) => void;
}

export const SelectDateSheet = ({
  selected,
  disabled,
  placeholder,
  showOutsideDays = true,
  iconRight,
  className,
  maxDate,
  minDate,
  from,
  onChange,
}: SelectDateSheetProps) => {
  const { selectDate } = useSelectDate();

  const matcher = useMemo(() => {
    const range: Matcher[] = [];

    if (maxDate) range.push({ after: maxDate });

    if (minDate) range.push({ before: minDate });

    if (from) range.push({ from });

    return range;
  }, [maxDate, minDate, from]);

  return (
    <Input
      containerClassName={className}
      onClick={() =>
        selectDate({
          value: selected,
          onChange,
          calendarProps: {
            showOutsideDays,
            disabled: matcher,
          },
        })
      }
      disabled={disabled}
      value={selected ? format(selected, "yyyy-MM-dd") : ""}
      readOnly={true}
      placeholder={placeholder}
      iconRight={iconRight}
    />
  );
};
