import { useState } from "react";

import { VirtualQueryData } from "@/app/screens/dashboard/dashboard.types.ts";

export const useVirtualQuery = (fetchMore, refetch): VirtualQueryData => {
  const [loadingState, toggleLoading] = useState({
    lessBtn: false,
    moreBtn: false,
  });

  const loadMore = async (queryVariables, cursor?: string | null) => {
    if (!cursor) {
      return;
    }

    try {
      toggleLoading({ moreBtn: true, lessBtn: false });
      await fetchMore({
        variables: {
          ...queryVariables,
          after: cursor,
        },
      });
    } finally {
      toggleLoading({ moreBtn: false, lessBtn: false });
    }
  };

  const loadLess = async () => {
    try {
      toggleLoading({ moreBtn: false, lessBtn: true });
      await refetch();
    } finally {
      toggleLoading({ moreBtn: false, lessBtn: false });
    }
  };

  return {
    loadingLess: loadingState.lessBtn,
    loadingMore: loadingState.moreBtn,
    loadMore,
    loadLess,
  };
};
