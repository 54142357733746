import { useEffect, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType, Input } from "@/app/components";

export const IconToInput = ({
  inputFn,
  onStartFn,
  iconType = "Search",
  iconTypeOnSelected = "SearchPrimary",
  iconClassName = "size-5",
  inputClassName = "",
  containerInputClassname = "",
}: {
  inputFn?: (...args) => void;
  onStartFn?: (...args) => void;
  iconType?: IconType;
  iconTypeOnSelected?: IconType;
  iconClassName?: string;
  inputClassName?: string;
  containerInputClassname?: string;
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    onStartFn(showInput);
  }, [showInput, onStartFn]);

  const closeInput = () => {
    inputFn?.("");
    setInputValue("");
    setShowInput(false);
  };

  return showInput ? (
    <Input
      autoFocus={true}
      iconRight={showInput ? iconTypeOnSelected : iconType}
      iconRightOnClick={closeInput}
      iconRightClassName={iconClassName}
      className={inputClassName}
      containerClassName={containerInputClassname}
      onKeyUp={({ key }) => {
        if (key === "Escape") {
          closeInput();
        }
        if (key === "Backspace" && inputValue.length === 0) {
          closeInput();
        }
      }}
      value={inputValue}
      onChange={({ target: { value } }) => {
        inputFn?.(value);
        setInputValue((prevState) => {
          if (prevState.length && value.length === 0) {
            setShowInput(false);
            inputFn?.("");
            return "";
          }
          return value;
        });
      }}
    />
  ) : (
    <Icon
      type={iconType}
      className={cn("cursor-pointer select-none", iconClassName)}
      onClick={() => setShowInput(true)}
    />
  );
};
