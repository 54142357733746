import { cn } from "@/lib/utils";

import { Text } from "../typography/text";

export const BoxTypeLabel = ({ className, label }: { className?: string; label: string }) => {
  return (
    <div className={"absolute left-4 top-4 flex items-baseline gap-x-2"}>
      <div className={cn("size-3 min-w-3 rounded-[4px] bg-red", className)} />
      <Text weight="medium" className="text-neutral-400" text={label} noOfLines={2} />
    </div>
  );
};
