import React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { cn } from "@/lib/utils.ts";

import { RadioGroups } from "@/app/components";

import { AddCompanyFormSection, AddCompanySectionEmptyState } from "./section";

const CompanyAttributesSchema = z.object({
  introAvailable: z.boolean(),
  activelyRaising: z.boolean(),
  highPriority: z.boolean(),
  ballInOurControl: z.boolean(),
});

type CompanyAttributesFormData = z.infer<typeof CompanyAttributesSchema>;

export const useCompanyAttributesForm = () => {
  return useForm<CompanyAttributesFormData>({
    resolver: zodResolver(CompanyAttributesSchema),

    defaultValues: {
      introAvailable: undefined,
      activelyRaising: undefined,
      highPriority: undefined,
      ballInOurControl: undefined,
    },
  });
};

type Field = { name: keyof CompanyAttributesFormData; label: string };

const CompanyAttributesView = ({ fields, formValues }: { fields: Field[]; formValues: CompanyAttributesFormData }) => {
  if (Object.values(formValues).every((attr) => attr === undefined)) {
    return <AddCompanySectionEmptyState>No Attributes</AddCompanySectionEmptyState>;
  }

  return (
    <div className="flex gap-1">
      {fields.map((field) => (
        <div
          key={field.name}
          className={cn(
            "flex items-center gap-1 rounded-xs px-2 py-1 text-sm text-white",
            formValues[field.name] === undefined && "bg-neutral-500",
            typeof formValues[field.name] === "boolean" && "bg-gradient-to-r from-primary-400 to-secondary-400",
            !formValues[field.name] && "opacity-40",
          )}
        >
          {field.label}
        </div>
      ))}
    </div>
  );
};

export const CompanyAttributesForm = ({ form }: { form: UseFormReturn<CompanyAttributesFormData> }) => {
  const { control, handleSubmit } = form;

  const fields: Field[] = [
    { name: "introAvailable", label: "Intro Available" },
    { name: "activelyRaising", label: "Actively Raising" },
    { name: "highPriority", label: "High Priority" },
    { name: "ballInOurControl", label: "Ball in Our Control" },
  ];

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AddCompanyFormSection title="Attributes">
      {(editMode) =>
        editMode ? (
          <form className="flex w-full gap-2" onSubmit={handleSubmit(onSubmit)}>
            {fields.map((fieldDef) => (
              <Controller
                key={fieldDef.name}
                name={fieldDef.name}
                control={control}
                render={({ field }) => (
                  <div>
                    <span>{fieldDef.label}</span>
                    <RadioGroups
                      onSelected={(op) => field.onChange(op?.value)}
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      className={`flex`}
                      defaultValue={field.value}
                      optionClassName="flex font-semibold cursor-pointer mr-4 py-3"
                      checkedClassName="text-black"
                      labelClassName="inline-block order-last text-[14px] font-normal"
                      checkIconContainer="mt-0 mr-2"
                      iconClassName="h-11 w-11 text-black"
                      withCheck
                    />
                  </div>
                )}
              ></Controller>
            ))}
          </form>
        ) : (
          <CompanyAttributesView fields={fields} formValues={form.getValues()} />
        )
      }
    </AddCompanyFormSection>
  );
};
