import { Button, NativeModal } from "@/app/components";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-selector.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

type SentimentOptions = {
  value: SentimentAttribute;
  label: string;
};

interface SentimentAttributeModalProps {
  onChange: (value: SentimentAttribute) => void;
  options: SentimentOptions[];
}

export const SentimentAttributeModal = ({ onChange, options }: SentimentAttributeModalProps) => {
  return (
    <NativeModal
      isOpen={true}
      handleClose={() => modalStore.close()}
      detent={"content-height"}
      bodyClassName="p-4 pt-0"
    >
      <div className={"flex w-full flex-col gap-6"}>
        <span className={"text-center text-[16px]"}>Select</span>

        <ul>
          {options.map(({ value, label }) => (
            <li key={label} className={"my-3"}>
              <Button
                onClick={() => {
                  onChange(value);
                  modalStore.close();
                }}
                variant={"outline"}
                className={"w-full py-2"}
              >
                {label}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </NativeModal>
  );
};
