import React from "react";

import { Text } from "@/app/components";
import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { CompanyDataForHighlights } from "@/app/screens/opportunities/opportunity/components/highlights/highlights.types.ts";

export const CashProfile = ({ company }: { company: CompanyDataForHighlights }) => {
  const { relativeRunway, companiesSources, employeesLatest } = company;
  const cashoutGroup = companiesSources?.items
    .find((item) => item?.sourceType === "OpsCompany")
    ?.opsCompanyAsSource?.opsCompanyfundsByCompanyId?.nodes?.find(({ cashoutGroup }) => cashoutGroup)?.cashoutGroup;

  const runway =
    cashoutGroup || relativeRunway
      ? String(cashoutGroup || relativeRunway)
          .replace("months", "m")
          .replace(/\s+/g, "")
      : undefined;
  return (
    <HighlightsCard type={"Insight"} className={"group"}>
      <div className={"flex w-full justify-between"}>
        <div className={"flex w-1/2 flex-col items-center justify-center"}>
          <HighlightsCardTitle text={"Runway"} />
          <div className={"flex h-28 items-center justify-center"}>
            {runway ? (
              <Text text={runway} type={"h4"} className={"text-center"} />
            ) : (
              <HighlightsCardEmptyState text={"No Runway Available"} />
            )}
          </div>
          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={new Date().toISOString()} />
          </HighlightsCardFooter>
        </div>
        <div className={"flex w-1/2 flex-col items-center justify-center"}>
          <HighlightsCardTitle text={"FTE"} />
          <div className={"flex h-28 items-center justify-center"}>
            {employeesLatest ? (
              <Text text={String(employeesLatest)} type={"h4"} />
            ) : (
              <HighlightsCardEmptyState text={"No FTE Available"} />
            )}
          </div>
          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={new Date().toISOString()} />
          </HighlightsCardFooter>
        </div>
      </div>
    </HighlightsCard>
  );
};
