import { Icon } from "@/app/components";

import { DetailsColumn, DetailsColumnProps } from "./details-column";

interface EmailAuthoColumnProps extends DetailsColumnProps {
  author?: string;
}

export const EmailAuthorColumn = ({ author, label, styles }: EmailAuthoColumnProps) => {
  return (
    <DetailsColumn styles={styles} label={label}>
      <span className="line-clamp-1 flex items-center gap-2 text-sm font-semibold text-gray-700">
        {author && <Icon type="Mail" className="size-4" />} {author}
      </span>
    </DetailsColumn>
  );
};
