import "@/app/styles/global.css";
import React from "react";
import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@material-tailwind/react";
import { injectStores } from "@mobx-devtools/tools";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerSW } from "virtual:pwa-register";

import { App } from "@/app/router";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { opportunitiesStore, opportunityFilterStore } from "@/app/screens/opportunities";
import { authStore } from "@/app/stores/auth.store.tsx";

injectStores({
  authStore,
  globalModalStore,
  opportunitiesStore,
  opportunityFilterStore,
});

const intervalMS = 60 * 60 * 1000;
const updateSW = registerSW({
  // immediate: true,
  onNeedRefresh: () => {
    console.info("[SW] pwa needs refresh");
  },
  onOfflineReady: () => console.info("[SW] app is offline ready"),
  onRegisteredSW: (swScriptUrl, registration) => {
    registration &&
      setInterval(async () => {
        if (!(!registration.installing && navigator)) return;

        if ("connection" in navigator && !navigator.onLine) return;

        const resp = await fetch(swScriptUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache",
          },
        });

        if (resp?.status === 200) await registration.update();
      }, intervalMS);
  },
  onRegisterError: (error) => {
    console.warn("[SW]", error);
  },
  immediate: true,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
);
