import { useEffect } from "react";

import { observer } from "mobx-react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";

import { useMembersMapped } from "@/lib/service/members.ts";

import { Button, DatePicker } from "@/app/components";
import Form from "@/app/components/forms";
import { Routes } from "@/app/constants";
import { ModalHeader, ModalWebFrame, WebSelect } from "@/app/screens/modal/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { useRequestSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import { formCategories } from "@/data/data.tsx";

export const RequestSentimentWeb = observer(({ form }) => {
  const { data: allMembers } = useMembersMapped();
  const [requestSentiment, { loading }] = useRequestSentimentFormMutation();
  const navigate = useNavigate();
  const { founders }: any = opportunitiesStore.state.extra || {};
  useEffect(() => {
    if (founders) form.$("category").set({ value: "Founder Sentiment", label: "Founder Sentiment" });
  }, [founders]);

  return (
    <ModalWebFrame>
      <div className={"w-full"}>
        <ModalHeader title={"Create a New Sentiment Form"} />
        <div className={"flex w-full flex-col space-y-6"}>
          <Form.Root form={form}>
            <div>
              {founders ? (
                <Form.Field
                  fieldId={"founder"}
                  render={({ onChange, value, label }) => (
                    <div className="flex flex-col items-start">
                      <WebSelect
                        defaultSelected={value}
                        storeCallback={(item) => onChange(item?.[0])}
                        placeholder={"Please select a column..."}
                        allOptions={founders.map(({ name, id, image }) => ({
                          value: name,
                          label: name,
                          image: image ? "https://" + image : null,
                          id,
                        }))}
                        iconLeft={"Search"}
                        label={label}
                        multiple={false}
                        required
                      />
                      <Form.Error />
                    </div>
                  )}
                />
              ) : (
                <Form.Field
                  fieldId={"category"}
                  render={({ onChange, value, label }) => (
                    <div className="flex flex-col items-start">
                      <WebSelect
                        defaultSelected={value}
                        storeCallback={(item) => onChange(item?.[0])}
                        placeholder={"Please select a column..."}
                        allOptions={formCategories}
                        iconLeft={"Search"}
                        label={label}
                        multiple={false}
                        required
                      />
                      <Form.Error />
                    </div>
                  )}
                />
              )}
              <Form.Field
                fieldId="assignees"
                render={({ onChange, value, label }) => (
                  <div className="my-4 flex flex-col items-start">
                    <WebSelect
                      required
                      multiple={true}
                      resultsIcons={true}
                      label={label}
                      allOptions={allMembers}
                      defaultSelected={value}
                      storeCallback={onChange}
                    />
                    <Form.Error />
                  </div>
                )}
              />
              <Form.Field
                fieldId="meetingDate"
                render={({ onChange, value }) => (
                  <div className="mb-4 flex w-full flex-col items-start">
                    <Form.Label className="mb-2" />
                    <DatePicker
                      onChange={(date) => {
                        onChange({ value: date });
                      }}
                      selected={value?.date}
                      maxDate={new Date()}
                      className="w-full"
                      enableYearNavigation
                    />
                    <Form.Error />
                  </div>
                )}
              />
            </div>
          </Form.Root>
        </div>
      </div>
      <div className={"bottom flex w-full flex-1 items-end justify-end space-x-4"}>
        <Button
          text={"Cancel"}
          variant={"outline"}
          size={"lg"}
          className={"w-1/4"}
          onClick={() => globalModalStore.toggleModal()}
        />
        <Button
          text={"Create Form"}
          variant={"primary"}
          disabled={!form?.isValid}
          loading={loading}
          iconRight={"Sentiment"}
          size={"lg"}
          className={"w-1/4"}
          onClick={async () => {
            const { assignees, meetingDate, category, founder: formFounder } = form.values();
            const { company }: any = opportunitiesStore.state.extra || {};
            const founder = formFounder
              ? {
                  id: formFounder.id,
                  name: formFounder.value,
                  image: formFounder.image,
                }
              : {};

            await requestSentiment({
              variables: {
                input: {
                  founder,
                  company: { id: company.id, name: company.name },
                  assignees: Array.from(assignees).map((item: any) => item?.value),
                  meetingDate: meetingDate?.value,
                  category: category?.value,
                },
              },
              onCompleted: (data) => {
                const formId = data.sentimentRequest.id;

                globalModalStore.toggleModal();
                toast((t) => (
                  <div className="flex flex-col items-center gap-2">
                    <span className="text-center">Successfully created sentiment for company</span>
                    <Button
                      size="sm"
                      text={"Complete"}
                      onClick={() => {
                        toast.dismiss(t.id);
                        navigate(`${Routes.forms}/${formId}`);
                      }}
                    />
                  </div>
                ));
              },
            });
          }}
        />
      </div>
    </ModalWebFrame>
  );
});
