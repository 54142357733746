import { Lottie, Modal, Text } from "@/app/components";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { LottieKeys } from "@/app/components/lottie/Lottie";
import modalStore from "@/app/stores/modal.store";

export interface SuccessModalProps {
  label?: string;
  lottie?: LottieKeys;
}

export const SuccessModal = observer(({ label, lottie }: SuccessModalProps) => {
  useEffect(() => {
    const id = setTimeout(() => {
      modalStore.close();
    }, 2500);

    return () => clearTimeout(id);
  }, []);

  return (
    <Modal
      isOpen={true}
      handleClose={modalStore.close}
      bodyContentClassName="flex flex-1 flex-col items-center justify-center "
    >
      <Lottie type={lottie || "completed"} className={"h-52 w-52"} />
      <Text
        type={"h5"}
        color={"text-primary"}
        className={"lg:mt-10"}
        text={label || "Success"}
      />
    </Modal>
  );
});
