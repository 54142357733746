import { PropsWithChildren } from "react";

import { RowData, Table } from "@tanstack/react-table";

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends TableRowData, TValue> {
    align?: "left" | "center" | "right";
    className?: string;
  }
}

export type TableRowData = RowData;

export type TableDef<TData extends TableRowData> = Table<TData>;

export type TableDensity = "normal" | "dense";

export type TableProps<TData extends TableRowData> = PropsWithChildren<{
  className?: string;
  table?: TableDef<TData>;
  options?: {
    density?: TableDensity;
    onRowClick?: (row: TData) => void | null;
  };
}>;

export type TableContextData<TData extends TableRowData> = Pick<TableProps<TData>, "table" | "options">;

type CustomizedTable<TData extends TableRowData> = TableProps<TData> &
  Required<PropsWithChildren> &
  Partial<Pick<TableProps<TData>, "table">>;

export const isCustomTable = <TData extends TableRowData>(
  tableProps: TableProps<TData>,
): tableProps is CustomizedTable<TData> => {
  return "children" in tableProps && tableProps.children != null;
};
