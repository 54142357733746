import { GetCompanyUpdatesQuery } from "@/gql/graphql.ts";

import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { useGetCompanyUpdatesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { useVirtualQuery } from "../../hooks/use-virtual-query";

export const newItemStatuses = [NotificationStatus.UNSEEN];
export const completedStatuses = [NotificationStatus.SEEN, NotificationStatus.COMPLETED, NotificationStatus.DECLINED];

type CompanyNotificationData = NonNullable<GetCompanyUpdatesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useCompanyUpdates = (showArchived: boolean): DashboardSectionData<CompanyNotificationData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 3,
    statuses: showArchived ? completedStatuses : newItemStatuses,
  };

  const { data, loading, fetchMore, refetch } = useGetCompanyUpdatesQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
  });

  const virtualListData = useVirtualQuery(fetchMore, refetch);

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;
    if (virtualListData.loadMore) {
      virtualListData.loadMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !virtualListData.loadingLess,
    loadingLess: virtualListData.loadingLess,
    loadingMore: virtualListData.loadingMore,
    loadMore,
    loadLess: virtualListData.loadLess,
  };
};
