import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import { Icon, ImageWithErrorFallback, Text } from "@/app/components";
import { timeAgo } from "@/app/misc/helpers.ts";

import { Signal } from "@/app/screens/opportunities/components/signal.tsx";

import "react-swipeable-list/dist/styles.css";
import { useEffect, useRef, useState } from "react";
import { Tags } from "@/app/screens/opportunities/components/tags.tsx";
import { useNavigate } from "react-router";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import modalStore from "@/app/stores/modal.store.tsx";
import { AssignModalResponsive } from "@/app/screens/modal";

// todo looks like not used and can be removed
export function MobileListContainer({ opportunities, currentTab }) {
  const currentSwipeProgress = useRef(0);
  const [opportunitiesList, setOpportunities] = useState(opportunities);

  useEffect(() => {
    setOpportunities(opportunities);
  }, [opportunities]);

  const navigate = useNavigate();
  return (
    <>
      {opportunitiesList.length ? (
        <SwipeableList>
          <ul role="list">
            {opportunitiesList.map(
              (
                {
                  id,
                  country,
                  badges,
                  createdUtc,
                  name,
                  image,
                  fundingRounds,
                  industries,
                  tagline,
                },
                index
              ) => {
                const round = fundingRounds?.items?.[0]?.round;
                const signal = badges?.[0];
                return (
                  <SwipeableListItem
                    key={id}
                    fullSwipe={true}
                    onClick={() => navigate(`/company/${id}`)}
                    onSwipeStart={() => {
                      currentSwipeProgress.current = 0;
                    }}
                    onSwipeProgress={(progress) => {
                      currentSwipeProgress.current = progress;
                    }}
                    onSwipeEnd={(dragDirection) => {
                      if (
                        currentSwipeProgress.current >= 44 &&
                        dragDirection === "right"
                      ) {
                        modalStore.open(AssignModalResponsive, {
                          props: { id },
                        });
                      }
                      if (
                        currentSwipeProgress.current >= 44 &&
                        dragDirection === "left"
                      ) {
                        opportunityModal({
                          id,
                          platform: "mobile",
                          action: "Snooze",
                        });
                        setOpportunities((prevState) => {
                          const tempState = [...prevState];
                          tempState.splice(index, 1);
                          return tempState;
                        });
                      }
                    }}
                    leadingActions={<LeadingActionsComponent />}
                    trailingActions={
                      opportunitiesStore.state.currentTab.quickView ===
                      "live" ? (
                        <TrailingActionsComponent />
                      ) : null
                    }
                    destructiveCallbackDelay={3}
                  >
                    <div className={`min-h-[10dvh] w-[98%] border-b p-3`}>
                      <div className={`flex`}>
                        <ImageWithErrorFallback
                          className={
                            "mt-3 h-12 w-12 rounded-sm border border-neutral-100 bg-neutral-200 p-0.5 text-primary shadow-md"
                          }
                          iconClassName={"bg-background rounded-sm"}
                          alt={"Company Logo"}
                          src={image}
                        />

                        <div
                          id={"content"}
                          className={"flex w-full flex-col py-2.5"}
                        >
                          <div className={"mx-3 flex h-[75%] w-full flex-col"}>
                            <div
                              id={"Title | Date"}
                              className={"flex items-baseline justify-between"}
                            >
                              <Text text={name} type={"title"} />
                              {createdUtc && (
                                <Text
                                  text={timeAgo(createdUtc)}
                                  type={"small"}
                                  color={"text-neutral"}
                                />
                              )}
                            </div>
                            <Tags
                              showIcon={false}
                              country={country}
                              industry={industries?.[0] || ""}
                              round={round}
                              containerClassName={"my-2"}
                            />
                            <div id={"1 liner"}>
                              <Text
                                text={tagline}
                                noOfLines={1}
                                type={"message"}
                                weight={"normal"}
                              />
                            </div>
                            {currentTab === "live" && signal && (
                              <Signal
                                containerClassName={"mt-1.5 h-6"}
                                signal={signal}
                                isList
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwipeableListItem>
                );
              }
            )}
          </ul>
        </SwipeableList>
      ) : null}
    </>
  );
}

const LeadingActionsComponent = () => (
  <LeadingActions>
    <SwipeAction onClick={console.info}>
      <div
        className={
          "flex h-full w-full items-center justify-center bg-gradient-to-r from-yellow to-yellow-400 pl-4"
        }
      >
        <Icon type={"Share"} color={"white"} height={20} width={20} />
        <Text
          text={"Share"}
          type={"subtitle"}
          color={"text-white"}
          className={"ml-4 self-center"}
        />
      </div>
    </SwipeAction>
  </LeadingActions>
);

const TrailingActionsComponent = () => (
  <TrailingActions>
    <SwipeAction destructive={true} onClick={console.info}>
      <div
        className={
          "flex h-full w-full items-center justify-center bg-gradient-to-r from-primary to-secondary-400 pr-4"
        }
      >
        <Icon type={"Snooze"} color={"white"} height={20} width={20} />
        <Text
          text={"Move to Pipeline"}
          type={"subtitle"}
          color={"text-white"}
          className={"ml-2  self-center"}
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);
