import React from "react";

import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { BreadCrumbs, ImageWithErrorFallback, Label, Text } from "@/app/components";
import { Routes } from "@/app/constants";
import { CompanySelectModal } from "@/app/screens/metrics-extraction/sections";
import { useMetricsHeader } from "@/app/screens/metrics-extraction/sections/company-header/use-metrics-header.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

export const Header = observer(({ containerClassName, email }) => {
  const { navigate, selectedCompanyId, name, image, websiteUrl, trelloColumn, loading } = useMetricsHeader();
  return (
    <>
      <div className={"flex items-center justify-between"}>
        <div className={"flex gap-x-4"}>
          <BreadCrumbs
            title={"Back"}
            action={() => {
              navigate(-1);
            }}
            elementProps={{
              title: { className: "hidden md:block text-sm" },
              icon: {
                className: "w-4 h-4",
              },
            }}
          />
          <Label description={"BETA"} type={"medium"} />
        </div>
        {email && (
          <Text
            text={"Link to another company"}
            onClick={() => modalStore.open(CompanySelectModal)}
            className={
              "cursor-pointer select-none rounded-sm bg-white px-3 py-2 hover:bg-neutral-50 active:bg-neutral-100"
            }
          />
        )}
      </div>
      <div className={cn("group relative flex items-center", containerClassName)}>
        <ImageWithErrorFallback
          alt={name}
          src={image}
          loading={loading}
          iconClassName={"rounded-md p-2"}
          className={cn(
            "flex shrink-0 rounded-md border border-neutral-300 lg:size-12 2xl:size-14",
            selectedCompanyId === "new" ? "" : "cursor-pointer hover:opacity-80",
          )}
          onClick={() => {
            if (selectedCompanyId === "new") return;
            navigate(`${Routes.company}/${selectedCompanyId}`);
          }}
        />

        <div className={"ml-4 flex flex-col justify-between"}>
          <div>
            <Text
              text={name || "Unknown Company"}
              weight={"semibold"}
              className={cn(
                "truncate lg:text-[18px] 2xl:text-[22px]",
                selectedCompanyId === "new" ? "hover:opacity-100" : "cursor-pointer hover:opacity-80",
              )}
              onClick={() => {
                if (selectedCompanyId === "new") return;
                navigate(`${Routes.company}/${selectedCompanyId}`);
              }}
            />
          </div>
          {(trelloColumn || websiteUrl) && (
            <div className={"flex items-center"}>
              {trelloColumn && (
                <Text text={trelloColumn} weight={"medium"} color={"text-neutral-700"} className="truncate" />
              )}
              {websiteUrl && (
                <>
                  {trelloColumn && <Text text={"•"} className={"mx-3 text-sm lg:text-base"} />}
                  <a href={websiteUrl} target="_blank" rel="noreferrer">
                    <Text text={websiteUrl} weight={"medium"} className="truncate text-linkedin" />
                  </a>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
