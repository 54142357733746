import { LGMember } from "@/app/stores/members.store.tsx";
import { cn } from "@/lib/utils.ts";
import {
  AccessTitle,
  BaseCamp,
  IntroInfo,
  LeadMembers,
} from "../../../opportunities/opportunity/components/access";
import { Badges } from "@/app/types";
import { container } from "@/app/styles";
import { Container } from "@/app/components";

export interface AccessProps {
  className?: string;
  assignees: LGMember[];
  badges: Badges[];
}

export const Access = ({
  assignees,
  badges,
  className = container.boxContainer,
}: AccessProps) => {
  const baseCamp = badges?.find(({ id }) => id === "basecamp_investment");
  const intro = badges?.find(({ id }) => id === "can_source_intro");

  return (
    <Container className={cn(className)}>
      <AccessTitle />
      <div className={`grid grid-cols-3 gap-6`}>
        <LeadMembers
          assignees={assignees}
          className={"col-span-3 mb-4"}
          titleClassName={"items-baseline"}
          detailsClassName={"grid-cols-6 gap-4"}
        />

        <BaseCamp baseCamp={baseCamp} className={"text-left"} />

        <IntroInfo intro={intro} className={"text-left"} />
      </div>
    </Container>
  );
};
