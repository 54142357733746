import { useMemo } from "react";

import { Icon, IconProp } from "./icon";

type DocumentIconType = "jpeg" | "jpg" | "png" | "pdf";

type DocumentIconProps = {
  ext: DocumentIconType | string;
} & Omit<IconProp, "type">;

export const DocumentIcon = ({ ext, ...props }: DocumentIconProps) => {
  const type = useMemo(() => {
    switch (ext) {
      case "jpeg":
      case "jpg":
      case "png":
        return "ImageIcon";
      case "pdf":
        return "PDFIcon";
      default:
        return "DOCIcon";
    }
  }, [ext]);

  return <Icon type={type} {...props} />;
};
