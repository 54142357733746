import React, { ReactNode } from "react";

import CountUp, { CountUpProps } from "react-countup";

import { cn } from "@/lib/utils.ts";

export type AnimatedNumberProps = {
  value: number;
  options?: Omit<CountUpProps, "end">;
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const AnimatedNumber = ({ value, options, className, prefix, suffix }: AnimatedNumberProps) => {
  return (
    <div className={cn("flex justify-self-center", className)}>
      {prefix}
      <CountUp end={value} delay={options?.delay ?? 0} {...options}>
        {({ countUpRef }) => <p ref={countUpRef as any} />}
      </CountUp>
      {suffix}
    </div>
  );
};
