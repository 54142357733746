export const serverUrl =
  import.meta.env.VITE_NAZARE_API_DOMAIN || "http://be.nazare.ro:8000";

import axios from "axios";

export const service = axios.create({
  baseURL: serverUrl + "/api",
  withCredentials: true,
});

service.defaults.headers.common["Content-Type"] = "application/json";

export type PaginationVariables = { page: number; pageSize?: number };
