import { Text } from "app/components";

const items = [
  "Easily search through PCG’s collective network - people and organisations",
  "Apply advanced filters to PCG’s collective network to create bespoke lists of people or organisations (follow-on funding lists, event invite lists, talent, etc)" +
    "Get more insight on people and organisations in our network via 3rd party data enrichment",
  "See connection strength between PCG team members and people/organisations in our network (number of email interactions, meetings, etc)",
  "Automatic sentiment form creation using calendar integration",
  "Network sourcing statistics and attribution",
];

export const NetworkDashboard = ({}) => {
  return (
    <div
      className={
        "flex w-full flex-col items-center justify-center p-8 pt-[10dvh] lg:pt-0"
      }
    >
      <Text
        text={"🚀 Exciting news! Network coming soon!"}
        type={"h5"}
        className={"mb-8 text-center"}
      />
      <Text
        text={
          "Network is on the way to our web app! While it's not quite here yet, here's a glimpse of what you can expect. Stay tuned for updates!"
        }
        type={"subtitle"}
        color={"text-neutral-700"}
        className={"text-center"}
      />
      <div className={"mt-8 space-y-3.5 lg:w-[50%]"}>
        {items.map((item, index) => (
          <div
            className={
              "flex cursor-pointer select-none items-center rounded-md bg-white px-3 py-5 shadow-sm transition-transform duration-200 ease-in-out hover:scale-[1.03] lg:p-6"
            }
          >
            <Text
              text={String(index + 1)}
              className={"mr-2 rounded-sm bg-neutral-100 px-3 py-2"}
            />
            <Text text={item} color={"text-neutral-600"} />
          </div>
        ))}
      </div>
    </div>
  );
};
