import { useCompanyAdditionalDetailsForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-additional-details-form.ts";
import { useCompanyAttributesForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-attributes-form.ts";
import { useCompanyDetailsForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-details-form.ts";
import { useFoundersForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-founders-form.ts";
import { useMainCompanyDataForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-main-data-form.ts";
import { useCompanyNoteForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-note-form.ts";

export const useAddCompanyForms = () => {
  const mainDataForm = useMainCompanyDataForm();
  const foundersForm = useFoundersForm();
  const detailsForm = useCompanyDetailsForm();
  const additionalDetailsForm = useCompanyAdditionalDetailsForm();
  const noteForm = useCompanyNoteForm();
  const attributesForm = useCompanyAttributesForm();

  return {
    mainDataForm,
    foundersForm,
    detailsForm,
    additionalDetailsForm,
    noteForm,
    attributesForm,
  };
};
