import React from "react";

import { Outlet } from "react-router-dom";

import { AppVersion } from "@/app/router/app-version";

export const PublicLayout = () => {
  return (
    <div className="PublicLayout">
      <Outlet />
      <AppVersion className="absolute bottom-5 right-5" />
    </div>
  );
};
