import { NativeModal } from "@/app/components";

import { observer } from "mobx-react";
import modalStore from "@/app/stores/modal.store";
import { ActionButtons, ActionModalHeader } from "../components";
import { UseAssignFormParams, useAssignForm } from "./hooks";

export const ShareMobile = observer((props: UseAssignFormParams) => {
  const { renderForm, isValid, loading, handleSubmit } = useAssignForm(props);

  return (
    <NativeModal
      isOpen={true}
      handleClose={modalStore.close}
      header={<ActionModalHeader title={props.title} />}
      footer={
        <ActionButtons
          primaryBtnTitle={props.title || "Assign"}
          secondaryBtnTitle="Cancel"
          primaryBtnVariant="gradient"
          isLoading={loading}
          disabled={!isValid}
          handleCancel={modalStore.close}
          handlePrimaryAction={handleSubmit} />
      }
    >
      {renderForm}
    </NativeModal>
  );
});
