import { withResponsiveRendering } from "@/app/screens/modal/modal-responsive.hoc.tsx";

import { DatePicker } from "../date-picker/date-picker.tsx";

import { SelectDateSheet } from "./select-date-sheet.tsx";
import { SelectModalSheet } from "./select-modal-sheet.tsx";
import { WebSelect } from "./web-select.tsx";

export { TextArea } from "./textarea.tsx";
export { Input, TW_INPUT_CORE_FOCUS, TW_INPUT_CORE, TW_INPUT_CORE_HOVER } from "./input.tsx";
export * from "./icon-to-input.tsx";
export * from "./select.tsx";
export * from "./switch.tsx";
export * from "./select-date-sheet.tsx";
export * from "./select-modal-sheet.tsx";
export * from "./multi-input.tsx";

export const FormSelect = withResponsiveRendering(WebSelect, SelectModalSheet);
export const FormDatePicker = withResponsiveRendering(DatePicker, SelectDateSheet);
