import { cn } from "@/lib/utils.ts";
import { Text } from "@/app/components";
import { BarListHero } from "@/app/screens/opportunities/opportunity/components/charts.tsx";
import { text } from "@/app/styles";

export interface ExpectedReturn {
  name: string;
  value: string;
}

export interface LastExpectedReturnProps {
  lastExpectedReturn: ExpectedReturn[];
}

export const LastExpectedReturn = ({
  lastExpectedReturn,
}: LastExpectedReturnProps) => {
  return (
    <div className={"flex flex-col justify-between py-2.5"}>
      <Text
        text={"Sentiment Forms"}
        weight={"normal"}
        className={cn(text.subTitleText, "mb-4 text-left")}
      />
      <BarListHero data={lastExpectedReturn} />
    </div>
  );
};
