import z from "zod";

import { getFullName } from "@/lib/utils";

import { authStore } from "@/app/stores/auth.store.tsx";

import { CompanyAdditionalDetailsFormData } from "../shared/forms/use-company-additional-details-form.ts";
import { CompanyAttributesFormData } from "../shared/forms/use-company-attributes-form.ts";

import { CompanyRawData } from "./add-opportunities-via-email.types.ts";
import { CompaniesFromEmailForm, CompanyDataSchema } from "./use-add-company-from-email-form.ts";

export const mapCompany = (
  candidate: CompanyRawData | null,
  source?: string | null,
): CompaniesFromEmailForm["companies"][number] => {
  const company: z.infer<typeof CompanyDataSchema> = {
    mainData: {
      name: candidate?.name ?? "",
      lgCompany: candidate?.lgCompany,
      dealroomCompany: candidate?.dealroomCompany,
    },
    founders: {
      founders: [
        {
          name: "",
          url: "",
        },
      ],
    },
    details: {
      websiteUrl: candidate?.url ?? "",
      location: {
        country: "",
        city: "",
      },
      about: candidate?.description ?? "",
    },
    additionalDetails: {
      industries: [] as unknown as CompanyAdditionalDetailsFormData["industries"],
      round: "",
      fund: "",
      investmentType: "core",
    },
    note: {
      note: "",
    },
    attributes: {
      source: {
        type: "", //todo
        name: source ?? "",
      },
      attributes: {
        introAvailable: false,
        activelyRaising: false,
        highPriority: false,
        ballInOurControl: false,
      },
      assignees: {
        people: authStore.user
          ? [
              {
                id: authStore.user.id!,
                label: getFullName(authStore.user.firstName, authStore.user.lastName),
                value: authStore.user.id!,
                image: authStore.user.avatar,
                initials: authStore.user.initials,
              },
            ]
          : ([] as unknown as CompanyAttributesFormData["assignees"]["people"]),
        note: "",
      },
    },
  };

  return {
    company,
    skip: !!candidate?.lgCompany,
  };
};
