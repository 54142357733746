import { useHotkeys } from "react-hotkeys-hook";
import globalModalStore from "../screens/modal/global-modal.store";
import modalStore from "@/app/stores/modal.store";
import { AddNewResponsiveModal } from "../screens/modal/add-new";
import { Search } from "../screens/modal/search";

export const HotKeysProvider = ({ children }) => {
  useHotkeys("mod+k", () => modalStore.open(Search));
  useHotkeys("ctrl+n", () => modalStore.open(AddNewResponsiveModal));
  useHotkeys("mod+[", () => globalModalStore.toggleNavBar());
  useHotkeys("esc", modalStore.close);

  return <div>{children}</div>;
};
