import React, { useRef, useState } from "react";

import { motion } from "framer-motion";

import { useBreakpoints } from "@/lib/hooks";
import { useSubmitFeedbackMutation } from "@/app/service/opportunities.gql.ts";

import { FeedbackMetadata, useFeedbackFormMetadata } from "./use-feedback-form-metadata.ts";
import { FeedbackForm, FeedbackFormValue } from "./feedback-form.tsx";
import { FeedbackToggleBtn, FeedbackToggleBtnRef } from "./feedback-toggle.tsx";

type FeedbackData = {
  formValue: {
    text: string;
  };
  metadata: FeedbackMetadata;
};

export const Feedback = () => {
  const [submitFeedback, { loading }] = useSubmitFeedbackMutation();
  const [isOpen, setIsOpen] = useState(false);
  const metadata = useFeedbackFormMetadata();
  const { isSmallPhone } = useBreakpoints();
  const toggleRef = useRef<FeedbackToggleBtnRef>(null);

  const handleSubmit = async ({ text }: FeedbackFormValue) => {
    if (!text) {
      return;
    }

    const feedback: FeedbackData = {
      formValue: {
        text,
      },
      metadata,
    };

    await submitFeedback({
      variables: {
        input: {
          feedback,
        },
      },
    });

    toggleRef.current?.setSuccess();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isSmallPhone && (
        <motion.div
          className={`fixed left-0 top-0 z-20 h-screen w-screen bg-neutral-700/90 backdrop-blur`}
          variants={{
            open: {
              translateX: 0,
              opacity: 1,
            },
            closed: {
              translateX: "100%",
              opacity: 0,
            },
          }}
          initial={isOpen}
          animate={isOpen ? "open" : "closed"}
          transition={{ duration: 0.1 }}
          onClick={() => setIsOpen(false)}
        />
      )}
      <motion.div
        className="pointer-events-none fixed right-0 top-1/2 z-30 !mt-0 flex -translate-y-1/2"
        variants={{
          open: {
            transform: "translate(0%, -50%)",
          },
          closed: {
            transform: "translate(100%, -50%)",
          },
        }}
        initial={isOpen}
        animate={isOpen ? "open" : "closed"}
        transition={{ duration: 0.1, easeInOut: true }}
      >
        <div
          className={`pointer-events-auto relative z-30 w-screen rounded-l-lg bg-white/90 px-8 py-6 backdrop-blur sm:w-[400px]`}
          style={{ boxShadow: "4px 4px 24px 0px rgba(0, 0, 0, 0.2)" }}
        >
          <FeedbackForm onClose={handleClose} onSubmit={handleSubmit} loading={loading} />
          <FeedbackToggleBtn isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} ref={toggleRef} />
        </div>
      </motion.div>
    </>
  );
};
