import { authStore } from "@/app/stores/auth.store";

import { useDashboardCompanies } from "./use-dashboard-companies";

export const useFollowingCompanies = () => {
  return useDashboardCompanies({
    nzrUserCompanyTagsByCompanyId: {
      some: {
        userId: {
          equalTo: authStore.userId,
        },
        tags: {
          contains: {
            follow: "1",
          },
        },
      },
    },
  });
};
