import React, { Suspense, useEffect } from "react";

import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { useMembersMapped, usePcgCompanies, useTopInvestors } from "@/lib/service/members.ts";

import { Loader } from "@/app/components";
import { Feedback } from "@/app/components/feedback/feedback.tsx";
import { VerticalNavbar, MobileNavbar } from "@/app/components/navbar";
import { Routes } from "@/app/constants";
import { GlobalModalProvider } from "@/app/providers";
import { ErrorBoundary } from "@/app/screens/misc/error-boundary.tsx";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { GlobalModal } from "@/app/screens/modal/global-modal.tsx";
import { GlobalNativeModal } from "@/app/screens/modal/global-native-modal.tsx";
import { SlideOver } from "@/app/screens/modal/slideover.tsx";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CompleteSentimentModalResponsive } from "../screens/modal/complete-sentiment";
import modalStore from "../stores/modal.store";

const AppLayout = () => {
  usePrivateLayout();
  const { isBigTablet } = useBreakpoints();

  return (
    <>
      <ErrorBoundary>
        <div className={"relative flex flex-col lg:flex-row"}>
          {isBigTablet ? <VerticalNavbar /> : <MobileNavbar />}
          <Suspense fallback={<Loader className="!relative !flex size-full" />}>
            <Outlet />
          </Suspense>
        </div>
        <GlobalNativeModal />
        <GlobalModal />
        <GlobalModalProvider />
        <SlideOver />
        <Feedback />
      </ErrorBoundary>
    </>
  );
};

export const PrivateLayout = observer(() => {
  if (!authStore.user || Object.keys(authStore.user).length == 0) {
    return <Navigate to="/login" state={{ next: location.pathname }} replace />;
  }

  return <AppLayout />;
});

const underConstructionRoutes = [];

const usePrivateLayout = () => {
  useMembersMapped();
  usePcgCompanies();
  useTopInvestors();

  const location = useLocation();
  const { id } = useParams();
  const { isBigTablet } = useBreakpoints();

  const shouldShowForms = location.pathname.startsWith(`${Routes.forms}/`);

  useEffect(() => {
    if (shouldShowForms && id) {
      modalStore.open(CompleteSentimentModalResponsive, {
        props: {
          label: "Complete Sentiment",
          id,
        },
      });
    }
    if (!isBigTablet && underConstructionRoutes.some((route) => location.pathname.includes(route))) {
      globalModalStore.navigate({
        modalType: "UnderConstruction",
        navState: { detent: "content-height" },
      });
    }
  }, [location, id]);
};
