import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { BoxTypeLabel } from "./box-type-label";
import { CardActions } from "./card-actions";
import { CardBody } from "./card-body";
import { CardFooter } from "./card-footer";
import { CardHeader } from "./card-header";
import { CardLabel } from "./card-label";
import { CardScore } from "./card-score";
import { CardTitle } from "./card-title";
import { CompanyImg } from "./company-image";

type CardProps = {
  className?: string;
  onClick?: () => void;
};

const Root = ({ className, children, onClick }: PropsWithChildren<CardProps>) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        "group relative flex select-none flex-col items-center gap-y-2 rounded-base border border-gray-200 bg-white p-4 transition hover:scale-[1.01]",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const Card = Object.assign(Root, {
  BoxType: BoxTypeLabel,
  Label: CardLabel,
  Score: CardScore,
  Title: CardTitle,
  Image: CompanyImg,
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter,
  Actions: CardActions,
});
