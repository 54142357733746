import { PropsWithChildren } from "react";

import { getInitials, getFullName, cn } from "@/lib/utils";

import { ImageWithErrorProfile } from "@/app/components";

export type UserDataForImage = {
  firstName: string;
  lastName: string;
  avatar?: string | null;
};

type UserImageProps = {
  user: UserDataForImage;
  styles?: {
    container?: string;
    image?: string;
  };
};

export const UserImage = ({ user, children, styles = {} }: PropsWithChildren<UserImageProps>) => {
  const { container, image } = styles;
  return (
    <div className={cn("flex items-center", container)}>
      <ImageWithErrorProfile
        src={user.avatar}
        alt={getInitials(getFullName(user.firstName, user.lastName))}
        className={cn("size-6 rounded-sm border border-neutral-200 bg-neutral-100 text-black shrink-0", image)}
        labelClassName="text-xs"
      />
      {children}
    </div>
  );
};
