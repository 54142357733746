import { ActionNames } from "@/app/hooks";
import { getCompanyStatus } from "@/app/misc/helpers";
import { authStore } from "@/app/stores/auth.store";
import { LGMember } from "@/app/stores/members.store";
import { Company } from "@/app/types";

export interface UseAvailableActionParams {
  company: Partial<Company>;
  signalAssignees?: LGMember[];
  defaultActions?: Array<ActionNames>;
  skip?: Array<ActionNames>;
}

export const getAvailableOpportunityActionNames = ({
  company,
  signalAssignees = [],
  defaultActions = [],
  skip = [],
}: UseAvailableActionParams) => {
  if (!company) {
    return [];
  }

  const { inPipeline, hasSignal, isPortfolio, isProspect, stageIsLegals } = getCompanyStatus(company);
  const isAssignee = signalAssignees.some(({ id }) => id === authStore.userId);

  const getActions = () => {
    /*
     * If a company is in Pipeline, HAS a signal and is assigned to user then the action buttons displayed should be:
     *
     * 1) Share
     * 2) Snooze & Track
     * 3) Convert to Prospect
     * 4) Add Sentiment
     */
    if (inPipeline && hasSignal && isAssignee) {
      return [...defaultActions, "convertToProspect", "addSentiment"];
    }

    /*
     * If a company is in Pipeline and it HAS a signal then the action buttons displayed should be:
     *
     * 1) Share
     * 3) Convert to Prospect
     * 4) Add Sentiment
     */
    if (inPipeline && hasSignal) {
      return [...defaultActions, "convertToProspect", "addSentiment"];
    }

    /*
     * If a company is in Pipeline and it has NO signal then the action buttons displayed should be:
     *
     * 1) Share
     * 2) Convert to Prospect
     * 3) Add Sentiment
     */
    if (inPipeline && !hasSignal) {
      return [...defaultActions, "convertToProspect", "addSentiment"];
    }

    /*
     * If a company is in Prospects on legal stage then the action buttons displayed should be:
     *
     * 1) Move to Track & Revisit
     * 2) Convert to Portfolio
     * 3) Move Stage
     * 4) Add Sentiment
     */
    if (isProspect && stageIsLegals && !isPortfolio) {
      return [...defaultActions, "moveToPipeline", "convertToPortfolio", "moveStage", "addSentiment"];
    }

    /*
     * If a company is in Prospects then the action buttons displayed should be:
     *
     * 1) Move to Track & Revisit
     * 2) Move Stage
     * 3) Add Sentiment
     */
    if (isProspect && !isPortfolio) {
      return [...defaultActions, "moveToPipeline", "moveStage", "addSentiment"];
    }

    /*
     * If a company is in Portfolio and not currently in Prospects then the action buttons displayed should be:
     * 1) Convert to Prospect
     * 2) Add Sentiment
     */
    if (!isProspect && isPortfolio) {
      return [...defaultActions, "convertToProspect", "addSentiment"];
    }

    /*
     * If a company is in Portfolio and ALSO currently in Prospects then the action buttons displayed should be:
     * 1) Remove Prospect
     * -> Remove from Prospect view. Will just show up in Portfolio
     * 2) Move Stage
     * 3) Add Sentiment
     */
    if (isProspect && isPortfolio) {
      return [...defaultActions, "removeProspect", "moveStage", "addSentiment"];
    }

    return [...defaultActions];
  };

  return getActions().filter((action) => !skip.includes(action as ActionNames));
};
