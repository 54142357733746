import { print } from "graphql";

import { RefreshTokenDocument } from "@/app/screens/login/login.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export async function refreshTokenCall() {
  const refreshToken = authStore.refreshToken;

  try {
    const response = await fetch(`${import.meta.env.VITE_NAZARE_API_DOMAIN}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
      body: JSON.stringify({
        query: print(RefreshTokenDocument),
        variables: { token: refreshToken },
      }),
    });

    const { data } = await response.json();
    if (data.refreshToken) {
      const result = data.refreshToken;

      authStore.refreshToken = result.refreshToken;
      authStore.accessToken = result.accessToken;
      authStore.expiresAt = result.expiresAt;
      authStore.user = result.user;

      return result.accessToken;
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    // Handle token refresh failure (e.g., redirect to login)
    return null;
  }
}
