import { createContext, useContext } from "react";
import MobxReactForm from "mobx-react-form";
import { observer } from "mobx-react";
import { Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";
import { useBreakpoints } from "@/lib/hooks";

type FormContextType = {
  form: MobxReactForm | null;
};
type FormFieldContextType = {
  fieldId?: string;
  field?: any;
};

const FormContext = createContext<FormContextType>({ form: null });
const FormFieldContext = createContext<FormFieldContextType>({});

const Form = observer(({ form, children, className }: any) => (
  <FormContext.Provider value={{ form }}>
    <div className={cn(className)}>{children}</div>
  </FormContext.Provider>
));

export const FormField = observer(
  ({ fieldId, render }: { fieldId: string; render: any; ctx?: any }) => {
    const { form } = useContext(FormContext);

    const field = form?.$(fieldId);
    return (
      <FormFieldContext.Provider value={{ fieldId, field }}>
        {render({ ...field.bind(), fieldId })}
      </FormFieldContext.Provider>
    );
  }
);

export const FormFieldLabel = ({ className = "" }: { className?: string }) => {
  const {
    field: { label },
  } = useContext(FormFieldContext);
  let required = false,
    labelText = label;
  const { isBigPhone } = useBreakpoints();

  if (label.split(" ").at(-1) === "*") {
    required = true;
    labelText = labelText.replace("*", " ");
  }

  return (
    <div className={`flex leading-6 mb-1.5 ${className}`}>
      <Text
        text={labelText}
        color={"text-neutral-600"}
        weight="light"
        type={"message"}
      />
      {required && (
        <>
          &nbsp;
          <Text
            text={"*"}
            weight="light"
            color={"text-red-400"}
            type={"message"}
          />
        </>
      )}
    </div>
  );
};

export const FormFieldError = observer(() => {
  const { field } = useContext(FormFieldContext);
  const hasError = Boolean(field?.error);

  return (
    <div className={cn("p-2 transition-all", hasError ? "flex" : "hidden")}>
      <Text text={field.error || "empty"} color="text-red-400" type="message" />
    </div>
  );
});

const Root = Form;
const Field = FormField;
const Label = FormFieldLabel;
const Error = FormFieldError;

export default { Root, Field, Label, Error };
