import { Button } from "@/app/components";
import modalStore from "@/app/stores/modal.store";
import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils";
import { useMemo } from "react";

export interface ActionButtonProps {
  multiStep?: boolean;
  disabled?: boolean;
  loading?: boolean;
  stepIndex: number;
  primaryButtonTitle?: string;
  actions: {
    handlePrimaryAction?: () => void;
    handleSecondaryAction?: () => void;
    handleCancel?: () => void;
  };
}

export const ActionButtons = ({
  multiStep,
  disabled,
  loading,
  stepIndex,
  primaryButtonTitle = "Next",
  actions,
}: ActionButtonProps) => {
  const { handlePrimaryAction, handleSecondaryAction, handleCancel } = actions;
  const { isBigTablet } = useBreakpoints();

  const buttons = useMemo(() => {
    if (!multiStep) {
      return (
        <>
          <Button
            text={"Cancel"}
            variant={"outline"}
            className="mb-4 w-full rounded-md text-[14px] text-black lg:mb-0 lg:mr-4 lg:w-auto"
            size={isBigTablet ? "md" : "lg"}
            onClick={modalStore.close}
          />
          <Button
            text={primaryButtonTitle}
            variant={"gradient"}
            disabled={disabled}
            loading={loading}
            className="order-first mb-4 w-full rounded-md text-[14px] lg:order-last lg:mb-0 lg:w-auto"
            size={isBigTablet ? "md" : "lg"}
            onClick={handlePrimaryAction}
          />
        </>
      );
    }

    return (
      <>
        <Button
          text={"Cancel"}
          variant={"outline"}
          className="order-last mb-4 w-full rounded-md text-[14px] text-black lg:order-first lg:mb-0 lg:mr-auto lg:w-auto"
          size={isBigTablet ? "md" : "lg"}
          onClick={handleCancel}
        />
        <Button
          text={"Back"}
          variant={"outline"}
          className="mb-4 w-full rounded-md text-[14px] text-black lg:mb-0 lg:mr-4 lg:w-auto"
          size={isBigTablet ? "md" : "lg"}
          onClick={handleSecondaryAction}
        />
        <Button
          text={primaryButtonTitle}
          variant={"gradient"}
          disabled={disabled}
          loading={loading}
          className="order-first mb-4 w-full rounded-md text-[14px] lg:order-last lg:mb-0 lg:w-auto"
          size={isBigTablet ? "md" : "lg"}
          onClick={handlePrimaryAction}
        />
      </>
    );
  }, [
    multiStep,
    stepIndex,
    isBigTablet,
    primaryButtonTitle,
    handleCancel,
    handleSecondaryAction,
    disabled,
    loading,
    handlePrimaryAction,
  ]);

  return (
    <div
      className={cn(
        "mt-auto flex w-full flex-col items-center justify-center px-4 lg:h-[50px] lg:flex-row lg:justify-end"
      )}
    >
      {buttons}
    </div>
  );
};
