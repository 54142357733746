import { Icon, Text } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers";
import { text } from "@/app/styles";
import { Sentiment } from "@/app/types";

export interface ExcitementProps {
  excitement: number;
  lastSentimentResponse: Sentiment;
  className?: string;
}

export const Excitement = ({ excitement, lastSentimentResponse, className }: ExcitementProps) => {
  return (
    <div className={`mb-4 text-left ${className}`}>
      <div className={"mb-1 flex items-center justify-between gap-2"}>
        <div className={"flex items-center gap-3"}>
          <Text text={"Excitement"} weight={"normal"} className={text.subTitleText} />
          <Text
            text={String(Number(excitement.toFixed(1)))}
            type={"subtitle"}
            color={"text-primary"}
            className={`${text.titleText} text-primary`}
          />
          <div className={"flex items-center gap-1"}>
            <Icon type={"Increase"} className={"size-4.5 text-neutral xl:size-6"} />
            <Text text={"1.0"} weight={"semibold"} color={"text-green"} className={text.body} />
          </div>
        </div>
      </div>
      <Text
        text={lastSentimentResponse.category || ""}
        weight={"light"}
        className={`${text.message} mr-2 inline-block text-left`}
      />
      <Text
        text={normaliseDate(lastSentimentResponse.createdAt)!}
        type={"message"}
        weight="light"
        color={"text-neutral"}
        className={`${text.message} inline-block text-left`}
      />
    </div>
  );
};
