import { useMemo } from "react";

import { LgFileSource } from "@/gql/graphql.ts";

import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { CompanyAttachmentsQuery, useCompanyAttachmentsQuery } from "@/app/service/company-attachments.gql.ts";
import { useGetFileUrlLazyQuery } from "@/app/service/files.gql.ts";

import { AttachmentFileMetadata, AttachmentFileNazare } from "./attachments.types.ts";
import { getFileExtension } from "./attachments.utils";
import { ApolloQueryResult } from "@apollo/client";

type FileData = NonNullable<NonNullable<CompanyAttachmentsQuery["lgCompanyFiles"]>["nodes"][number]["file"]>;

const getUploader = (file: FileData): AttachmentFileNazare["uploadedBy"] => {
  if (file.lgMemberByCreatedBy) {
    return {
      firstName: file.lgMemberByCreatedBy.firstName,
      lastName: file.lgMemberByCreatedBy.lastName,
      avatar: file.lgMemberByCreatedBy.avatar,
      initials: file.lgMemberByCreatedBy.initials,
    };
  }

  if (file.metadata) {
    const metadata: AttachmentFileMetadata = file.metadata;

    const [firstName, lastName] = (metadata.member_uploader.full_name || "Unknown User").split(" ");

    return {
      firstName,
      lastName,
      url: metadata.attachment.idMember ? `https://trello.com/u/${metadata.attachment.idMember}` : undefined,
    };
  }

  return {
    firstName: "Unknown",
    lastName: "User",
  };
};

export const useNazareAttachments = (): [
  AttachmentFileNazare[],
  boolean,
  () => Promise<ApolloQueryResult<CompanyAttachmentsQuery>>,
] => {
  const { company } = useOpportunityContext();

  const {
    data: attachments,
    loading: loadingAttachments,
    refetch,
  } = useCompanyAttachmentsQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company,
  });

  const [getFileUrl] = useGetFileUrlLazyQuery();

  const nazareAttachments: AttachmentFileNazare[] = useMemo(() => {
    if (!attachments?.lgCompanyFiles) {
      return [] as AttachmentFileNazare[];
    }

    return attachments.lgCompanyFiles.nodes
      .map<AttachmentFileNazare | null>(({ file }) => {
        if (!file) {
          return null;
        }

        const uploadedBy = getUploader(file);

        return {
          id: file.id,
          name: file.name,
          date: file.createdUtc,
          type: getFileExtension(file.name),
          path: file.path,
          source: file.source,
          status: file.status,
          uploadedBy,
          getFileUrl: async () => {
            if (file.source === LgFileSource.Nazare) {
              const res = await getFileUrl({ variables: { filePath: file.path } });

              return res.data?.responseGetFilePresignedUrl;
            }

            return file.path;
          },
        };
      })
      .filter((file): file is AttachmentFileNazare => file !== null);
  }, [attachments]);

  return [nazareAttachments, loadingAttachments, refetch];
};
