import { Modal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ConfirmModalActionButtons } from "./components";
import { useConfirm } from "./hooks";
import { ConfirmModalProps } from "./types";

export const ConfirmWeb = ({ title, subTitle, icon, handleConfirm, ...props }: ConfirmModalProps) => {
  const { renderContent } = useConfirm({ title, subTitle, icon });

  const onConfirm = async () => {
    await handleConfirm();
    modalStore.close();
  };
  return (
    <Modal
      isOpen
      showX={false}
      handleClose={modalStore.close}
      bodyClassName="h-auto max-w-[480px]"
      bodyContentClassName="p-0"
      headerContainerClassName="p-0"
      footerContainerClassName="p-0 mb-0"
      footer={<ConfirmModalActionButtons handleCancel={modalStore.close} handleConfirm={onConfirm} {...props} />}
    >
      {renderContent}
    </Modal>
  );
};
