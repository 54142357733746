import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { transformSchema, Field } from "../stores/form";

export interface FormTemplate {
  fields?: Field[];
}

export const useDynamicForm = (arg: FormTemplate | null = {}) => {
  const { fields } = arg || {};
  const zodSchema = fields ? transformSchema(fields) : null;
  const form = useForm({
    resolver: zodResolver(z.object(zodSchema)),
  });

  return { form };
};
