import { SortingFn } from "@tanstack/react-table";

import { Fund } from "@/app/hooks";
import { WithSubRows } from "@/app/screens/investments/components";

export const sortCompanyFn: SortingFn<WithSubRows<Fund>> = (rowA, rowB) => {
  const { name: a = "" } = rowA.original.company || {};
  const { name: b = "" } = rowB.original.company || {};
  if (a < b) {
    return -1;
  }

  if (a > b) return 1;

  return 0;
};

export const sortFunds = (a: Fund, b: Fund) => {
  const defaultOrder: string[] = [
    "LG4",
    "LG5",
    "LG7",
    "LG8",
    "LGX",
    "LT1",
    "LGO",
    "LG11",
    "LT2",
    "LG12",
    "LT3",
    "SLR1",
  ];

  const orderA = defaultOrder.indexOf(a.fundName || "") >= 0 ? defaultOrder.indexOf(a.fundName || "") : 12;
  const orderB = defaultOrder.indexOf(b.fundName || "") >= 0 ? defaultOrder.indexOf(b.fundName || "") : 12;

  return orderA - orderB;
};

export const sortRunway: SortingFn<WithSubRows<Fund>> = (a, b) => {
  const defaultOrder: string[] = [
    ">24 months",
    "24+ months",
    "18-24 months",
    "12-18 months",
    "6-12 months",
    "<6 months",
    "write off",
    "exit",
    "IPO",
  ];

  const valA = a.original.cashoutGroup || "";
  const valB = b.original.cashoutGroup || "";

  const orderA = defaultOrder.indexOf(valA) >= 0 ? defaultOrder.indexOf(valA) : 0;
  const orderB = defaultOrder.indexOf(valB) >= 0 ? defaultOrder.indexOf(valB) : 0;

  return orderA - orderB;
};

export const sortFundFn: SortingFn<WithSubRows<Fund>> = (rowA, rowB) => {
  return sortFunds(rowA.original, rowB.original);
};
