import { ManualOpportunityInput } from "@/gql/graphql.ts";
import { CompanyResult, LgCompanyResult, NewCompany } from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

export type AddOpportunityFormData = ManualOpportunityInput;

export const isLgCompany = (company: CompanyResult | NewCompany): company is LgCompanyResult => {
  return "__typename" in company && company.__typename === "LgCompany";
};

export const isNewCompany = (company: CompanyResult | NewCompany): company is NewCompany => {
  return "isNewCompany" in company && company.isNewCompany;
};
