import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";

export const FoundersSchema = z.object({
  founders: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        url: z.string().regex(linkedinProfileUrlRegex, `Please enter a valid url`),
      }),
    )
    .nonempty(),
});

export type FoundersFormData = z.infer<typeof FoundersSchema>;

export const useFoundersForm = () => {
  return useForm<FoundersFormData>({
    resolver: zodResolver(FoundersSchema),
    mode: "onChange",
    defaultValues: {
      founders: [],
    },
  });
};

export const updateFoundersForm = (form: UseFormReturn<FoundersFormData>, company) => {
  let founders = [
    {
      name: "",
      url: "",
    },
  ];

  if (!!company && "team" in company) {
    const existingFounders =
      company.team?.items
        ?.filter((member) => member.is_founder)
        .map((founder) => ({
          name: founder.name,
          url: founder.linkedin_url,
        })) ?? [];

    if (existingFounders.length) {
      founders = existingFounders;
    }
  }

  form.reset({ founders });
};
