import { useState } from "react";

import toast from "react-hot-toast";

import { UserTag } from "@/gql/graphql.ts";

import { Toast, Icon } from "@/app/components";
import { useTagUserCompanyMutation } from "@/app/service/opportunities.gql.ts";

export interface TagMutationParams {
  defaultSuccessMessage?: string;
  onSuccess?: () => void;
}

export interface TagPayload {
  companyId: string;
  userId: string;
  tag: UserTag;
  value: number;
  successMessage?: string;
}

export const useTagMutation = ({ defaultSuccessMessage = "Action Completed", onSuccess }: TagMutationParams) => {
  const [loading, setLoading] = useState(false);
  const [tagMutation, { error }] = useTagUserCompanyMutation();

  const onSubmit = async ({ companyId, userId, tag, value, successMessage }: TagPayload) => {
    try {
      setLoading(true);
      const response = await tagMutation({
        variables: {
          input: { companyId, userId, tag, priority: value },
        },
        refetchQueries: ["GetCompany", "GetCompanies", "GetDashboardCompanies", "GetUserHighlights"],
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
      });

      if (onSuccess) await onSuccess();

      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          subTitle={successMessage || defaultSuccessMessage}
          handleClose={() => toast.dismiss(t.id)}
        />
      ));

      return response;
    } finally {
      setLoading(false);
    }
  };
  return { onSubmit, loading, error };
};
