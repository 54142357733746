import { SyntheticEvent } from "react";

import { Icon, IconType } from "@/app/components";

type IconButtonProps = {
  icon: IconType;
  containerClassName?: string;
  iconClassName?: string;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
};

export function IconButton({
  icon,
  iconClassName,
  containerClassName,
  ...props
}: IconButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button
      className={`flex flex-col items-center justify-center rounded-xl bg-white ${containerClassName}`}
      {...props}
    >
      <Icon type={icon} className={`${iconClassName}`} />
    </button>
  );
}
