import "regenerator-runtime/runtime";
import { forwardRef, useCallback, useEffect, useState } from "react";

import { Mic } from "lucide-react";
import { toast } from "react-hot-toast";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import "./input.css";
import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Lottie, Modal, ResponsiveModal, Text } from "@/app/components";

type TextareaProps = {
  value: string;
  maxLength?: number;
  label?: string;
  className?: string;
  containerClassName?: string;
  copyOnDoubleClick?: boolean;
  showTranscribe?: boolean;
  placeholder?: string;
  error?: string;
  errorTextClassName?: string;
  disabled?: boolean;
  icons?: React.ReactNode | React.ReactElement | null;
  onChange?: (val: string) => void;
};

const TW_TEXTAREA_TEXT = "text-[14px] placeholder:text-neutral-400";

const TW_TEXTAREA_CORE = "block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-neutral-200 h-12 rounded-md";

const TW_TEXTAREA_CORE_HOVER = "";

const TW_TEXTAREA_CORE_FOCUS = "focus:placeholder:text-neutral-600";
const TW_TEXTAREA_ERROR = "ring-red-500";

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & TextareaProps
>(function TextArea(
  {
    onChange,
    className = "",
    label = "",
    placeholder = "",
    icons = null,
    error = "",
    showTranscribe = true,
    copyOnDoubleClick = false,
    disabled = false,
    containerClassName = "",
    maxLength,
    value = "",
    errorTextClassName,
    ...props
  },
  ref,
) {
  const { isBigTablet } = useBreakpoints();
  const { interimTranscript: transcript, resetTranscript } = useSpeechRecognition({});
  const exceedsLimit = maxLength && String(value).length > maxLength;

  const [listening, setListening] = useState(false);
  const [isUserPaused, setIsUserPaused] = useState(false);
  const startListening = useCallback(() => {
    setListening(true);
    setIsUserPaused(false);
    return SpeechRecognition.startListening({ continuous: true });
  }, []);

  const stopListening = useCallback(() => {
    setListening(false);
    setIsUserPaused(true);
    return SpeechRecognition.stopListening();
  }, []);

  const [internalValue, setInternalValue] = useState(value);
  const [intermediaryTranscript, setIntermediaryTranscript] = useState(internalValue);

  useEffect(() => {
    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);

  useEffect(() => {
    if (transcript && listening) {
      setInternalValue(intermediaryTranscript + transcript + " ");
      onChange && onChange(intermediaryTranscript + transcript + " ");
    }
  }, [listening, transcript, internalValue, intermediaryTranscript]);

  useEffect(() => {
    if (transcript === "") setIsUserPaused(true);
    else setIsUserPaused(false);
  }, [transcript]);

  useEffect(() => {
    if (isUserPaused) setIntermediaryTranscript(internalValue);
  }, [isUserPaused]);

  useEffect(() => {
    if (!listening) setTimeout(() => resetTranscript());
    setIntermediaryTranscript(internalValue);
  }, [listening]);

  if (!isBigTablet && listening) {
    return (
      <Modal
        isOpen={true}
        handleClose={stopListening}
        bodyClassName={"m-0 p-0 min-h-[77dvh]"}
        containerClassName={"z-[9999999]"}
        showX={false}
      >
        <div className={"flex flex-col"}>
          <textarea
            ref={ref}
            onChange={({ target: { value } }) => {
              setInternalValue(value);
              onChange && onChange(value);
            }}
            name={label}
            value={internalValue}
            className={`${TW_TEXTAREA_TEXT} ${TW_TEXTAREA_CORE} ${TW_TEXTAREA_CORE_FOCUS} ${
              disabled ? "" : TW_TEXTAREA_CORE_HOVER
            } ${disabled ? "text-neutral-300" : ""} ${error || exceedsLimit ? TW_TEXTAREA_ERROR : ""} h-auto min-h-[60dvh] rounded-md px-4 pb-12 pt-3`}
          />
          <div className={"relative mt-5 h-20"}>
            <Text text={"Press to Stop"} />
            <div
              className={"absolute inset-x-[29%] -bottom-[70%] flex size-36"}
              onTouchStart={stopListening}
              onMouseDown={stopListening}
            >
              <Mic
                className={cn(
                  "absolute inset-11 z-10 size-14 rounded-sm p-1.5 text-white hover:cursor-pointer hover:bg-primary-100 hover:opacity-90 active:opacity-90",
                  listening ? "bg-gradient-to-r from-primary-400 to-secondary-400" : "bg-neutral-200",
                )}
              />
              {listening && <Lottie type={"recording"} />}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className={`group relative flex flex-col items-start ${containerClassName}`}>
      {label && (
        <div className="flex">
          <Text
            type={"message"}
            weight="light"
            text={label}
            color={disabled ? "text-neutral-400" : "text-neutral-600"}
            className={"mb-2"}
          />
          {props.required && <Text type={"message"} weight="light" text={"*"} color={"text-red"} className={"ml-1"} />}
        </div>
      )}

      <textarea
        id={label}
        ref={ref}
        onDoubleClick={() => {
          if (!copyOnDoubleClick) return;
          navigator.clipboard
            .writeText(internalValue)
            .then(() => {
              document?.getSelection()?.removeAllRanges();
              toast.success("Copied to clipboard");
            })
            .catch();
        }}
        onChange={({ target: { value } }) => {
          setInternalValue(value);
          onChange && onChange(value);
        }}
        name={label}
        value={internalValue}
        disabled={disabled}
        placeholder={placeholder}
        className={`${TW_TEXTAREA_TEXT} ${TW_TEXTAREA_CORE} ${TW_TEXTAREA_CORE_FOCUS} ${
          disabled ? "" : TW_TEXTAREA_CORE_HOVER
        } ${disabled ? "text-neutral-300" : ""} ${error || exceedsLimit ? TW_TEXTAREA_ERROR : ""} h-auto rounded-md px-4 pb-12 pt-3 ${className}
          `}
        {...props}
      />
      {showTranscribe && !disabled && (
        <div
          className={"absolute -left-2.5 bottom-6 flex size-20 lg:opacity-0 lg:group-hover:opacity-100"}
          onTouchStart={startListening}
          onMouseDown={startListening}
          onTouchEnd={stopListening}
          onMouseUp={stopListening}
        >
          <Mic
            className={cn(
              "absolute inset-6 z-10 size-8 rounded-sm p-1.5 text-white hover:cursor-pointer hover:bg-primary-100 hover:opacity-90 active:opacity-90",
              listening ? "bg-gradient-to-r from-primary-400 to-secondary-400" : "bg-neutral-200",
            )}
          />
          {listening && <Lottie type={"recording"} />}
        </div>
      )}

      <div className="flex min-h-6 w-full justify-between">
        <div>
          {icons}
          <Text
            text={exceedsLimit ? "Character Limit Exceeded" : error}
            type={"message"}
            className={cn(disabled || !(error || exceedsLimit) ? "invisible" : "", errorTextClassName)}
            color={"text-red-500"}
          />
        </div>

        {maxLength && (
          <Text type="message" weight="light" className="text-neutral-300">
            <span className={exceedsLimit ? "text-red-500" : ""}>{String(value).length}</span>/{maxLength}
          </Text>
        )}
      </div>
    </div>
  );
});
