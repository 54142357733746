export const dashboardSections = {
  sentimentRequests: {
    id: "sentiment-requests",
    label: "Sentiment forms",
  },
  companiesForwarded: {
    id: "companies-forwarded",
    label: "Companies forwarded",
  },
  companyUpdates: {
    id: "company-updates",
    label: "Company updates",
  },
  notProgressing: {
    id: "not-progressing",
    label: "Close the loop",
  },
  reviewing: {
    id: "reviewing",
    label: "Reviewing",
  },
  following: {
    id: "following",
    label: "Following",
  },
};
