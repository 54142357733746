import React, { useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { useBreakpoints } from "@/lib/hooks";

import { normaliseDate, titleCase } from "@/app/misc/helpers.ts";
import { EmptySection } from "@/app/screens/opportunities/opportunity/components/empty-section.tsx";
import {
  RowListWithSeparator,
  RowWithCurrency,
  TableLabel,
  TableText,
} from "@/app/screens/opportunities/opportunity/components/table-rows.tsx";
import { OpportunityTable } from "@/app/screens/opportunities/opportunity/components/table.tsx";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";

type FundingRoundInvestors = {
  label?: string | null;
  important?: boolean | null;
}[];

type FundingRoundColumn = {
  date?: string | Date;
  round: string;
  amount: number;
  investors: FundingRoundInvestors;
};

const useColumns = () => {
  const { isTablet: slimView } = useBreakpoints();

  const columns: ColumnDef<FundingRoundColumn>[] = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        cell: (info) => <TableText text={info.renderValue()} className={"text-neutral-800"} />,
        size: slimView ? 150 : 100,
      },
      {
        accessorKey: "round",
        header: "Round",
        cell: (info) => <TableLabel label={info.renderValue()} />,
        size: slimView ? 150 : 120,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        cell: (info) => <RowWithCurrency label={info.renderValue()} />,
        size: slimView ? 150 : 100,
      },
      {
        accessorKey: "investors",
        header: "Round Participants",
        cell: (info) => <RowListWithSeparator list={info.renderValue()} />,
        enableSorting: false,
        enableGlobalFilter: true,
        size: slimView ? 0 : 300,
      },
    ],
    [slimView],
  );

  return columns;
};

type FundingRounds = NonNullable<OpportunityCompany>["fundingRounds"]["items"];

export const FundingHistory = ({ fundingRounds }: { fundingRounds: FundingRounds }) => {
  const columns = useColumns();
  const data: FundingRoundColumn[] = fundingRounds.map((round) => ({
    date: normaliseDate(new Date(`${round.year}-${round.month}-01`))?.substring(3),
    round: titleCase(round.round ?? ""),
    amount: round.amountUsdMillion * 1_000_000,
    // transforming into Map to remove duplicates
    investors: Array.from(
      new Map(
        round.investors?.items
          .filter(({ investor }) => !!investor)
          .map(({ investor, lead }) => [
            investor!.name,
            {
              label: investor!.name,
              important: lead,
            },
          ]),
      ).values(),
    ),
  }));
  if (!fundingRounds.length) {
    return <EmptySection text={"No Funding History Available"} />;
  }
  return (
    fundingRounds.length > 0 && (
      <section>
        <OpportunityTable data={data} columns={columns} containerClassName="p-4 md:p-5" />
      </section>
    )
  );
};
