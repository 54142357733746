import React, { PropsWithChildren, ReactNode, SyntheticEvent, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { IconButton } from "@/app/components";

type EditableFieldProps = {
  value: ReactNode;
  className?: string;
  error?: string;
};

export const EditableField = ({ value, children, className, error }: PropsWithChildren<EditableFieldProps>) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEdit = (e?: SyntheticEvent) => {
    e?.preventDefault();
    setEditMode(!editMode);
  };

  return (
    <div className={cn("flex items-center gap-0.5", error && "text-red-400", className)}>
      {editMode ? children : value}
      <IconButton
        icon={editMode ? "Check" : "Pencil"}
        onClick={toggleEdit}
        containerClassName="size-7 !bg-transparent p-1.5 hover:!bg-neutral-200 hover:rounded-sm shrink-0"
      />
    </div>
  );
};
