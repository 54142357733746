import { useState } from "react";

import * as Sentry from "@sentry/react";

import { useUpdateUserSettingsMutation } from "@/app/service/lgMembers.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

const uuid = () => {
  return String(Math.ceil(new Date().getTime() * Math.random()));
};

export const useEmailFeedback = () => {
  const { user } = authStore;
  const [exampleEmails, setExampleEmails] = useState<Array<{ id: string; email: string }>>([
    ...(user?.lgMemberSettingsByUserId?.nodes?.[0]?.feedbackEmailTemplate || []).map((email: string) => ({
      id: uuid(),
      email,
    })),
    { id: uuid(), email: "" },
  ]);

  let disabled = false;
  try {
    disabled =
      JSON.stringify(user?.lgMemberSettingsByUserId?.nodes?.[0]?.feedbackEmailTemplate) ===
      JSON.stringify(exampleEmails.filter(Boolean));
  } catch (err) {
    Sentry.captureException(err);
  }

  const [updateSettings, { loading: updateSettingsLoading }] = useUpdateUserSettingsMutation();
  return { exampleEmails, setExampleEmails, user, updateSettings, updateSettingsLoading, disabled, uuid };
};
