import React from "react";

import { Link } from "react-router-dom";

import { cn } from "@/lib/utils.ts";

import { CompanyImg, Icon } from "@/app/components";

import { CompanyRawData } from "./add-opportunities-via-email.types.ts";

export const AlreadyExistingCompany = ({ company, className }: { company: CompanyRawData; className?: string }) => {
  if (!company.lgCompany) {
    return null;
  }

  return (
    <div className={cn("flex cursor-default items-center gap-3 rounded-md bg-white p-2 shadow-xs", className)}>
      <CompanyImg src={company.lgCompany.image} className="!size-8 rounded-xs lg:!min-w-8" imgClassName="rounded-xs" />
      <span className="line-clamp-2 text-sm font-semibold">{company.lgCompany.name}</span>
      <div className="ml-auto flex items-center gap-1">
        {!!company.lgCompany.websiteUrl && (
          <Link to={company.lgCompany.websiteUrl} target="_blank">
            <Icon
              type="Continent"
              className="size-6 rounded-sm p-1 text-neutral hover:bg-neutral-100 hover:text-neutral-700"
            />
          </Link>
        )}
        <Link to={`/company/${company.lgCompany.id}`} target="_blank">
          <Icon
            type="ExternalLink"
            className="size-6 rounded-sm p-1 text-neutral hover:bg-neutral-100 hover:text-neutral-700"
          />
        </Link>
      </div>
    </div>
  );
};
