import { useEffect, useRef } from "react";

import { useScroll } from "@react-hooks-library/core";
import useInfiniteScroll from "react-infinite-scroll-hook";

import filterStore from "@/app/screens/opportunities/filter.store.ts";
import { opportunitiesStore } from "@/app/screens/opportunities/index.ts";
import { GetCompaniesQueryVariables, useGetCompaniesLazyQuery } from "@/app/service/opportunities.gql.ts";

export const useOpportunitiesDashboard = () => {
  const scrollBox = useRef<HTMLDivElement | null>(null);
  const { currentTab } = opportunitiesStore.state;

  const variables: GetCompaniesQueryVariables = { limit: 20 };

  if (filterStore.mapChoiceForApi) variables.filter = filterStore.mapChoiceForApi;
  if (filterStore.orderChoiceForApi) variables.orderBy = filterStore.orderChoiceForApi;

  const [getCompanies, { data, error, loading: isLoading, fetchMore }] = useGetCompaniesLazyQuery({
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [listRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: data?.lgCompanies?.pageInfo?.hasNextPage || false,
    onLoadMore: () => {
      fetchMore({
        variables: {
          offset: opportunitiesStore.allOpportunities.length,
        },
      });
    },
    disabled: Boolean(error),
    rootMargin: "0px 0px 20px 0px",
  });

  useEffect(() => {
    filterStore.clearSearch();
  }, []);

  useEffect(() => {
    getCompanies().catch();
  }, [getCompanies, currentTab]);

  useEffect(() => {
    opportunitiesStore.setOpportunities(data);
  }, [data]);

  useScroll(scrollBox, ({ scrollY }) => (opportunitiesStore.offset.y = scrollY));

  const totalLength = data?.lgCompanies?.totalCount || 0;
  const responseCount = data?.lgCompanies?.edges?.length || 0;

  return {
    data,
    error,
    listRef,
    fetchMore,
    isLoading,
    scrollBox,
    totalLength,
    responseCount,
    initialLoading: isLoading && !data,
  };
};
