import React, { useMemo } from "react";

import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { differenceInMinutes, format } from "date-fns";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { cn, windowOpen } from "@/lib/utils.ts";

import { IconButton, ProfileImage, ProgressSpinner, Text } from "@/app/components";
import { DocumentIcon } from "@/app/components/icon";
import { ConfirmModal } from "@/app/screens/modal";
import {
  CompanyAttachmentsDocument,
  useDeleteCompanyAttachmentMutation,
} from "@/app/service/company-attachments.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { AttachmentFileNazare } from "./attachments.types";

const isPendingUpload = (data: CellContext<AttachmentFileNazare, any> | AttachmentFileNazare) => {
  let file: AttachmentFileNazare;
  if ("row" in data) {
    file = data.row.original;
  } else {
    file = data;
  }

  return file.status === "pending";
};
const pendingClassName = "opacity-30";

const UploadedByCell = (info: CellContext<AttachmentFileNazare, any>) => {
  const member = info.row.original.uploadedBy;

  if (!member) {
    return null;
  }

  const { firstName, lastName, avatar: image } = member;

  const isPending = isPendingUpload(info);

  const content = (
    <div className={cn("flex items-center gap-2", isPending && pendingClassName)}>
      <ProfileImage
        firstname={firstName}
        lastname={lastName}
        image={image}
        containerClass="!size-7 shrink-0 rounded-xs"
        imgClass="bg-neutral-200 border border-neutral-300"
      />
      <span className="overflow-hidden text-ellipsis text-nowrap">
        {firstName} {lastName}
      </span>
    </div>
  );

  return "url" in member && !!member.url ? (
    <Link to={member.url} target="_blank" className="flex items-center gap-1 overflow-hidden">
      {content}
    </Link>
  ) : (
    <div className="flex items-center gap-1 overflow-hidden">{content}</div>
  );
};

const ActionColumn = ({ file }: { file: AttachmentFileNazare }) => {
  const [deleteNazareFile, { loading: isDeletingFile }] = useDeleteCompanyAttachmentMutation({
    // refetch attachments
    refetchQueries: [CompanyAttachmentsDocument],
  });

  const handleOpen = () => {
    const row = file;

    if (row.status === "pending") {
      return;
    }

    row.getFileUrl().then((url) => {
      if (url) {
        windowOpen(url, "_blank");
      }
    });
  };

  const handleDelete = () => {
    const attachment = file;

    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will delete ${attachment.name}`,
        handleConfirm: () => {
          deleteNazareFile({ variables: { fileId: attachment.id } }).then(() => {
            toast.success("File has been deleted");
          });
        },
      },
    });
  };

  const DeleteBtn = isDeletingFile ? (
    <ProgressSpinner className="size-5" />
  ) : (
    <IconButton
      icon={"Trash"}
      className="size-4 hover:text-neutral-600 active:text-neutral-400"
      onClick={handleDelete}
    />
  );

  const isPending = isPendingUpload(file);
  const displayDeleteBtn = differenceInMinutes(new Date(), new Date(file.date)) > 1;

  if (isPending) {
    return (
      <div className="flex items-center gap-4">
        <div className="rounded-xs bg-yellow-100 px-1.5 py-1 text-xs text-yellow-600">Pending</div>
        {displayDeleteBtn && DeleteBtn}
      </div>
    );
  }

  return (
    <div className="flex justify-center gap-4">
      <IconButton
        icon={"ExternalLink"}
        className="size-5 hover:text-neutral-600 active:text-neutral-400"
        onClick={handleOpen}
      />
      {DeleteBtn}
    </div>
  );
};

const TextColumn = (info: CellContext<AttachmentFileNazare, any>) => {
  const isPending = isPendingUpload(info);

  return <Text className={cn(isPending && pendingClassName)} text={info.getValue()} />;
};

const columnHelper = createColumnHelper<AttachmentFileNazare>();

export const useAttachmentsColumns = () => {
  const { isTablet: largerThanTablet } = useBreakpoints();

  return useMemo(
    () => [
      columnHelper.accessor((row) => (row.date ? new Date(row.date) : null), {
        id: "date",
        header: "Added on",
        cell: (info) => {
          const value = info.getValue();
          const isPending = isPendingUpload(info);

          return value ? (
            <Text className={cn(isPending && pendingClassName)} text={format(new Date(value), "dd LLL YYY HH:mm")} />
          ) : null;
        },
        size: 175,
      }),
      columnHelper.display({
        header: "Added By",
        cell: UploadedByCell,
        enableResizing: false,
        size: 200,
      }),
      columnHelper.accessor("type", {
        header: "Type",
        cell: (info) => {
          const isPending = isPendingUpload(info);
          return (
            <div className={cn("flex items-center gap-x-1", isPending && pendingClassName)}>
              <DocumentIcon ext={info.getValue()} className={cn("size-7")} />
              <Text text={info.getValue().toUpperCase()} weight={"medium"} />
            </div>
          );
        },
        size: 125,
      }),
      columnHelper.accessor("name", {
        header: "Name",
        cell: TextColumn,
        enableSorting: false,
        enableResizing: false,
        size: largerThanTablet ? 0 : 300,
      }),
      columnHelper.display({
        id: "actions",
        size: 135,
        cell: (props) => {
          return <ActionColumn file={props.row.original} />;
        },
      }),
    ],
    [largerThanTablet],
  );
};
