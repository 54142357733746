import { cn } from "@/lib/utils";
import React, { useMemo } from "react";

export interface StepperProps {
  children: JSX.Element[];
  active: number;
  containerClassName?: string;
  hideIndices?: number[];
}

export interface StepProps {
  label?: string;
  index?: number;
  activeStep?: number;
  hide?: boolean;
  onClick?: () => void;
}

export const Step = ({ label, index = 0, activeStep = 0, hide = false, onClick }: StepProps) => {
  return (
    <div
      onClick={onClick}
      className={cn("z-10 flex cursor-pointer items-center bg-white px-2", hide ? "hidden" : "")}
    >
      <div
        className={cn(
          "flex h-6 w-6 items-center justify-center rounded-xs bg-neutral-200",
          activeStep === index
            ? "bg-gradient-to-r from-primary-400 to-secondary-400 text-white"
            : "",
          activeStep > index ? "bg-black text-white" : ""
        )}
      >
        <span
          className={cn(
            "text-[8px] font-semibold",
            index > activeStep ? "text-neutral-400" : "text-white"
          )}
        >
          {index + 1}
        </span>
      </div>
      <p className="ml-1 text-[8px] font-semibold text-neutral-400">{label}</p>
    </div>
  );
};

export const Stepper = ({ children, active, containerClassName, hideIndices = [] }: StepperProps) => {
  const activeConnectorPercentage = useMemo(
    () => Math.round(((active - hideIndices.filter((i) => active >= i).length) / (children.length - hideIndices.length - 1)) * 100).toFixed(),
    [active, children.length]
  );

  return (
    <div className={cn("w-full", containerClassName)}>
      <div className="relative flex w-full items-center justify-between">
        <div className="absolute h-[1px] w-full bg-neutral-200" />
        <div
          style={{ width: `${activeConnectorPercentage}%` }}
          className={cn(
            "absolute h-[1.5px] bg-gradient-to-r from-primary-400 to-secondary-400 transition-[width] duration-300"
          )}
        />
        {children.map((element, index) =>
          React.cloneElement(element, { activeStep: hideIndices.includes(active) ? active - 1 : active, index, hide: hideIndices.includes(index) })
        )}
      </div>
    </div>
  );
};
