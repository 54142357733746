import { ComponentType, useState } from "react";

import { FieldValues, UseFormReturn } from "react-hook-form";

import { CompanyStepComponentProps } from "../add-company-new.types.ts";
import { CompanyAdditionalDetailsStep } from "../components/steps/company-additional-details-step.tsx";
import { CompanyDetailsStep } from "../components/steps/company-details-step.tsx";
import { CompanyFoundersStep } from "../components/steps/company-founders-step.tsx";
import { CompanyMainDataStep } from "../components/steps/company-main-data-step.tsx";
import { CompanyNoteStep } from "../components/steps/company-note-step.tsx";
import { CompanySourceStep } from "../components/steps/company-source-step.tsx";

import { useAddCompanyForms } from "./use-add-company-forms.ts";

type AddCompanyForms = ReturnType<typeof useAddCompanyForms>;

export type StepDefinition<TFormData extends FieldValues = any> = {
  id: string;
  label: string;
  Component: ComponentType<CompanyStepComponentProps<TFormData>>;
  form: UseFormReturn<TFormData>;
  hidden?: boolean;
};

export const useAddCompanySteps = (forms: AddCompanyForms) => {
  const steps: StepDefinition[] = [
    { id: "main-data", label: "Company Name", Component: CompanyMainDataStep, form: forms.mainDataForm },
    { id: "founders", label: "Founders", Component: CompanyFoundersStep, form: forms.foundersForm },
    { id: "details", label: "Company Details", Component: CompanyDetailsStep, form: forms.detailsForm },
    {
      id: "additional-details",
      label: "Company Additional Details",
      Component: CompanyAdditionalDetailsStep,
      form: forms.additionalDetailsForm,
      hidden: true,
    },
    { id: "note", label: "Add a Note", Component: CompanyNoteStep, form: forms.noteForm },
    { id: "source", label: "Source & Assignees", Component: CompanySourceStep, form: forms.attributesForm },
  ];

  const [activeStep, setActiveStep] = useState(steps[0]);
  const activeStepIndex = steps.findIndex((step) => step.id === activeStep.id);

  const next = () => {
    if (activeStepIndex < steps.length - 1) {
      setActiveStep(steps[activeStepIndex + 1]);
    }
  };

  const back = () => {
    if (activeStepIndex > 0) {
      setActiveStep(steps[activeStepIndex - 1]);
    }
  };

  return {
    steps,
    activeStep,
    next,
    back,
    isFirstStep: activeStepIndex === 0,
    isLastStep: activeStepIndex === steps.length - 1,
  };
};
