import React, { PropsWithChildren } from "react";

import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { FieldError } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";

const StatusIcon = ({
  state,
}: {
  state?: {
    invalid: boolean;
    isDirty: boolean;
  };
}) => {
  if (!state) {
    return null;
  }

  if (state.invalid) {
    return <Icon className="size-4 rounded-full bg-red/70 p-0.5 text-white" type="X" />;
  }

  return <Icon className="size-4 rounded-full bg-green/70 p-0.5 text-white" type="Check" />;
};

const animate = {
  unmount: {
    height: "0px",
    transition: { duration: 0.2, times: [0.4, 0, 0.2, 1] },
    overflow: "hidden",
  },
  mount: {
    height: "auto",
    transition: { duration: 0.2, times: [0.4, 0, 0.2, 1] },
    overflow: "visible", // need this for dropdowns to work correctly
  },
};

export const AddOpportunityFormSection = ({
  children,
  label,
  state,
  open,
  onToggle,
}: PropsWithChildren<{
  label: string;
  state?: {
    invalid: boolean;
    isDirty: boolean;
    isTouched: boolean;
    isValidating: boolean;
    error?: FieldError;
  };
  open: boolean;
  onToggle?: () => void;
}>) => {
  return (
    <Accordion open={open} animate={animate}>
      <AccordionHeader
        onClick={onToggle}
        className={cn("group flex w-full items-center justify-between gap-2 hover:border-neutral-400")}
      >
        <span className="text-sm group-hover:text-blue-gray-900">{label}</span>
        {state?.invalid ? (
          <span className="ml-auto text-xs font-semibold text-red-600">Incomplete</span>
        ) : (
          <span className="ml-auto text-xs font-semibold text-green-600">Complete</span>
        )}
      </AccordionHeader>
      <AccordionBody className={cn("")} style={{ overflow: "visible" }}>
        {children}
      </AccordionBody>
    </Accordion>
  );
};
