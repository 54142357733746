import React, { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { Text } from "@/app/components";

export interface TitleWithSignalProps {
  title?: string;
  iconRight?: React.ReactNode;
  styles?: {
    className?: string;
    titleClassName?: string;
  };
}

export const CardTitle = ({ title, iconRight, children, styles = {} }: PropsWithChildren<TitleWithSignalProps>) => {
  const { className, titleClassName } = styles;
  return (
    <div className={cn("flex flex-col justify-center", className)}>
      {title && (
        <div className="flex items-center">
          <Text
            text={title}
            noOfLines={1}
            weight={"semibold"}
            className={cn(
              "mr-2 w-auto font-semibold leading-none text-black md:text-[18px] lg:text-[14px] 2xl:text-[16px]",
              titleClassName,
            )}
          />
          {iconRight}
        </div>
      )}
      {children}
    </div>
  );
};
