import { Icon, IconType } from "@/app/components";
import { cn, windowOpen } from "@/lib/utils.ts";

type SocialMediaIconsProps = {
  icons: Array<{ name: IconType; url?: string | null }>;
  iconClassName?: string;
  containerClassName?: string;
};

export const SocialMediaIcons = ({
  icons,
  iconClassName = "",
  containerClassName = "",
}: SocialMediaIconsProps) => {
  return (
    <div className={cn("flex", containerClassName)}>
      {icons
        .filter((icon) => Boolean(icon?.url))
        .map(({ name, url }, index) => (
          <Icon
            key={index + name}
            type={name}
            onClick={() => windowOpen(url!, "_blank")}
            className={cn(`items-center justify-center`, iconClassName)}
          />
        ))}
    </div>
  );
};
