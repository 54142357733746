import { CSSProperties, useContext } from "react";

import { Cell, Column, Header } from "@tanstack/react-table";

import { TableContext } from "./table-context";
import { TableDensity } from "./table-new.types.ts";

export const useTable = () => {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    throw new Error("useTable should be used inside TableContext.Provider");
  }

  return tableContext;
};

type DensityConfig = Partial<Record<"header" | "headerCell" | "body" | "cell", string>>;

const tableDensityConfigs: Record<TableDensity, DensityConfig> = {
  normal: {
    header: "text-sm",
    headerCell: "px-2 md:px-4",
    body: "text-sm",
    cell: "p-2 md:p-4",
  },
  dense: {
    header: "text-sm",
    headerCell: "px-2 text-center",
    body: "text-sm",
    cell: "px-2 py-2 text-center",
  },
};

export const useTableDensity = (): {
  density: TableDensity;
  config: DensityConfig;
} => {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    throw new Error("useTableDensity should be used inside TableContext.Provider");
  }

  const density = tableContext.options?.density ?? "normal";
  return {
    density,
    config: tableDensityConfigs[density],
  };
};

export const getColumnStyles = (item: Header<any, any> | Cell<any, any>): CSSProperties => {
  const column = item.column;
  const width = column.getSize();

  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-6px 0 12px -12px gray inset"
      : isFirstRightPinnedColumn
        ? "6px 0 12px -12px gray inset"
        : undefined,
    backgroundColor: "white",
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    width: width !== 0 ? width : undefined,
    zIndex: isPinned ? 2 : 0,
  };
};
