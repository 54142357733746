import { Icon, Popover, Text } from "@/app/components";
import { cn } from "@/lib/utils";

interface NotAvailableProps {
  tooltipText?: string;
  tooltipInfo?: React.ReactNode;
  tooltipContainer?: string;
  message?: string;
  textStyle?: string;
  containerStyle?: string;
  isSad?: boolean;
}

export const NotAvailable = ({
  tooltipText,
  tooltipInfo,
  tooltipContainer,
  message = "No Data",
  textStyle,
  containerStyle,
  isSad,
}: NotAvailableProps) => {
  return (
    <Popover
      containerClassName={containerStyle}
      childrenContent={
        (tooltipInfo || tooltipText) && <div className={tooltipContainer}>{tooltipText || tooltipInfo}</div>
      }
    >
      <Text
        text={message}
        className={cn("whitespace-nowrap text-[10px] font-[500] leading-4 text-neutral-300 md:text-sm", textStyle)}
      />
      {isSad && <Icon type="SadSmile" className={cn("ml-1 inline-block h-4 w-4")} />}
    </Popover>
  );
};
