import React, { Fragment, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { colors, Icon, IconType, Text } from "@/app/components";

export type TabBarProps = {
  tabs: Array<{ value: string; label: string; icon?: IconType }>;
  selectedTab: string;
  extra?: string;
  containerClassName?: string;
  onClick: (index: number) => void;
  elementProps?: {
    icon?: {
      className?: string;
    };
  };
};

export const TabBar = ({ tabs, extra = "", onClick, selectedTab, containerClassName, elementProps }: TabBarProps) => {
  return (
    <>
      <nav className={cn("flex w-fit gap-3", containerClassName)} aria-label="Tabs">
        {tabs.map(({ value, label, icon }, index) => (
          <Fragment key={label}>
            <a
              onClick={() => {
                onClick(index);
              }}
              className={cn(
                "flex items-center justify-center rounded-md text-lg font-medium transition duration-100 ease-in-out",
                value === selectedTab ? "text-black" : "text-neutral",
              )}
            >
              {icon ? (
                <Icon
                  type={icon}
                  className={cn(
                    "mx-2 size-5 text-black",
                    value !== selectedTab ? "text-neutral" : "text-black",
                    elementProps?.icon?.className,
                  )}
                />
              ) : (
                <Text
                  className={cn(
                    "cursor-pointer select-none rounded-sm px-2 py-1 transition-all duration-100 ease-in-out",
                    value === selectedTab
                      ? "bg-neutral-900 text-white hover:bg-neutral-800"
                      : "bg-neutral-100 text-[#464646] hover:bg-neutral-200",
                  )}
                  weight={"medium"}
                  text={`${label} ${value === selectedTab && extra ? extra : ""}`}
                />
              )}
            </a>
          </Fragment>
        ))}
      </nav>
    </>
  );
};

type TabIconProps = {
  content: Array<any>;
  onClick: (index: number) => void;
  containerClassName?: string;
  iconClassName?: string;
  selected: number;
};
export const TabIcon = React.memo(({ content, containerClassName, iconClassName, onClick, selected }: TabIconProps) => {
  return (
    <div className={`${containerClassName}`}>
      {content.map(({ icon }, index) => (
        <Icon
          type={icon}
          key={`${icon}+${index}`}
          className={`size-full cursor-pointer transition duration-100 ease-in-out ${iconClassName}`}
          onClick={() => {
            onClick(index);
          }}
          color={selected === index ? colors.primary.DEFAULT : colors.neutral["300"]}
        />
      ))}
    </div>
  );
});
