import { Icon, IconType, Popover, PopoverPlacement, Text } from "@/app/components";

import React, { HTMLInputTypeAttribute, useRef } from "react";

import "./input.css";
import { cn } from "@/lib/utils.ts";

export type InputProps = {
  type?: HTMLInputTypeAttribute;
  label?: string;
  name?: string;
  labelClassName?: string;
  className?: string;
  iconRight?: IconType;
  iconRightOnClick?: () => void;
  iconRightClassName?: string;
  iconRightPopover?: React.ReactNode;
  iconRightPopoverLocation?: PopoverPlacement;
  iconLeft?: IconType;
  iconLeftOnClick?: () => void;
  iconLeftClassName?: string;
  containerClassName?: string;
  pills?: string[];
  placeholder?: string;
  error?: string;
  errorClassName?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, inputRef?: HTMLInputElement) => void;
};

export const TW_INPUT_TEXT = "w-full text-[14px] placeholder:text-neutral-400";

export const TW_INPUT_CORE =
  "w-full outline-none text-left whitespace-nowrap truncate transition duration-100 rounded-tremor-default flex flex-nowrap border py-3 overflow-hidden";

export const TW_INPUT_CORE_HOVER = "lg:hover:ring-neutral-300";

export const TW_INPUT_CORE_FOCUS = "border-neutral-200 text-tremor-content-emphasis";

export function Input({
  name,
  label = "",
  placeholder = "",
  onChange,
  className = "",
  labelClassName = "",
  containerClassName = "",
  iconLeft,
  iconLeftOnClick,
  iconLeftClassName,
  iconRight,
  iconRightOnClick,
  iconRightClassName,
  iconRightPopover,
  iconRightPopoverLocation = "top-end",
  error = "",
  errorClassName,
  type = "text",
  value = undefined,
  disabled = false,
  required = false,
  ...props
}: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={`flex flex-col items-start focus:ring-2 focus:ring-tremor-brand-muted ${containerClassName}`}>
      {label && (
        <div className={"flex pb-2"}>
          <Text
            type={"message"}
            weight="light"
            text={label}
            color={disabled ? "text-neutral-400" : "text-neutral-600"}
            className={`${labelClassName}`}
          />
          {required && (
            <Text type={"message"} weight="light" text={required ? "*" : ""} color={"text-red"} className={"ml-1"} />
          )}
        </div>
      )}
      <div className="relative w-full">
        {iconLeft && (
          <div className={"absolute inset-y-0 left-0 flex items-center pl-4"} onClick={iconLeftOnClick}>
            <Icon
              type={iconLeft}
              className={cn("size-5", disabled ? "text-neutral-300" : "text-primary", iconLeftClassName)}
            />
          </div>
        )}
        <input
          ref={inputRef}
          id={name}
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(event) => onChange(event, inputRef?.current)}
          placeholder={placeholder}
          className={cn(
            `${TW_INPUT_TEXT} ${TW_INPUT_CORE} ${TW_INPUT_CORE_FOCUS}`,
            `${iconLeft ? "pl-11" : "pl-4"}`,
            `${iconRight ? "pr-11" : "pr-4"}`,
            disabled ? "text-neutral-300" : TW_INPUT_CORE_HOVER,
            className,
          )}
          {...props}
        />
        {iconRight && (
          <div className={"z-1 absolute inset-y-0 right-0 flex items-center pr-3.5"} onClick={iconRightOnClick}>
            {iconRightPopover ? (
              <Popover childrenContent={iconRightPopover} placement={iconRightPopoverLocation}>
                <Icon
                  type={iconRight}
                  className={`size-5 ${disabled ? "text-neutral-300" : "text-primary"} ${iconRightClassName}`}
                />
              </Popover>
            ) : (
              <Icon
                type={iconRight}
                className={`size-5 ${disabled ? "text-neutral-300" : "text-primary"} ${iconRightClassName}`}
              />
            )}
          </div>
        )}
      </div>
      {error && (
        <Text
          text={error}
          type={"message"}
          className={cn("ml-4 mt-1", disabled ? "hidden" : "", errorClassName)}
          color={"text-red"}
        />
      )}
    </div>
  );
}
