import React from "react";

import { Menu } from "@headlessui/react";
import { Editor } from "@tiptap/react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "@/app/components";

import { TextStyleOptionToggle, useRichTextEditorActions } from "./use-rich-text-editor-actions";

type RichTextEditorToolbarProps = {
  editor: Editor;
  className?: string;
};

const getPlaceholder = (activeOption: TextStyleOptionToggle): string => {
  if (typeof activeOption.label === "string") {
    return activeOption.label;
  }

  if (React.isValidElement(activeOption.label)) {
    return activeOption.label.props.children as string;
  }

  return "Select";
};

const TextStyleSelector = ({
  options,
  placeholderIcon,
  menuClassName,
  placeholder,
}: {
  options: TextStyleOptionToggle[];
  placeholderIcon: IconType;
  menuClassName?: string;
  placeholder?: string;
}) => {
  const activeOption = options.find((option) => option.active);

  const placeholderLabel = activeOption ? getPlaceholder(activeOption) : placeholder ?? "Select";

  // todo would be good to use Dropdown component
  //  but couldn't use it here as it's customized for small button
  return (
    <Menu as="div" className="relative flex h-full items-center">
      <Menu.Button>
        <div
          className={cn(
            "group relative flex h-8 w-9 items-center rounded-sm px-1 py-2 hover:bg-neutral-300",
            activeOption?.active && "bg-neutral-200",
          )}
        >
          <Icon type={activeOption?.icon ?? placeholderIcon} className="size-full text-neutral-800" />
          <Icon type={"Chevron Down"} className="size-2 shrink-0 text-neutral-800" />
          <div className="invisible absolute -top-2 left-1/2 -translate-x-1/2 -translate-y-full text-nowrap rounded-sm border bg-white px-1 py-0.5 text-sm transition-opacity group-hover:visible">
            {placeholderLabel}
          </div>
        </div>
      </Menu.Button>
      <Menu.Items
        className={cn(
          "max-h-50 absolute left-0 top-9 z-10 flex w-full min-w-[150px] flex-col overflow-y-auto rounded border bg-white shadow-dropdown transition duration-100",
          menuClassName,
        )}
      >
        {options.map((option) => (
          <Menu.Item key={"id" in option ? option.id : option.label}>
            <div
              onClick={option.action}
              className={cn(
                "flex cursor-pointer items-center gap-2 truncate px-3 py-2 text-sm text-black",
                option.active ? "bg-neutral-300" : "hover:bg-neutral-100",
              )}
            >
              <Icon type={option.icon} className="size-5 text-neutral-800" />
              {option.label}
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

const TextTypeButtons = ({ options }: { options: TextStyleOptionToggle[] }) => {
  return (
    <div className="flex items-center gap-2">
      {options.map((option) => (
        <div
          key={"id" in option ? option.id : option.label}
          className={cn("group relative size-8 rounded-sm p-2 hover:bg-neutral-300", option.active && "bg-neutral-200")}
          onClick={option.action}
        >
          <Icon type={option.icon} className="size-full text-neutral-800" />
          <div className="invisible absolute -top-2 left-1/2 -translate-x-1/2 -translate-y-full rounded-sm border bg-white px-1 py-0.5 text-sm transition-opacity group-hover:visible">
            {option.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export const RichTextEditorToolbar = ({ editor, className }: RichTextEditorToolbarProps) => {
  const options = useRichTextEditorActions(editor);

  if (!editor.isEditable) {
    return null;
  }

  return (
    <div className={cn("flex w-full items-center gap-2 rounded-sm bg-white p-1", className)}>
      <TextStyleSelector
        options={options.textType}
        placeholderIcon="Pilcrow"
        menuClassName="min-w-[200px]"
        placeholder="Text Style"
      />
      <TextStyleSelector options={options.listType} placeholderIcon="List" placeholder="List Style" />
      <div className="h-6 border-l"></div>
      <TextTypeButtons options={options.textStyle} />
      {/*<div className="h-6 border-l"></div>*/}
      {/*<TextTypeButtons options={options.textInsert} />*/}
    </div>
  );
};
