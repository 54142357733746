import { observer } from "mobx-react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { metricsStore } from "@/app/screens/metrics-extraction";

import { escapeRegExp } from "../../utils.ts";

export const EmailBody = observer(({ body }: { body?: string }) => {
  const [label, finalBody] = metricsStore.activeQuotations.reduce(
    ([, acc], { quotation, label }) => {
      if (!quotation) return [null, acc];
      const quotregex = new RegExp("(" + escapeRegExp(quotation).trim().replace(/\s+/g, "[^\\w]+") + ")", "gm");

      return [
        label,
        acc.replace(
          quotregex,
          `<p id="${label}" class="rounded-xs bg-gradient-to-r from-primary to-secondary w-fit font-semibold text-white px-1">$1<p/>`,
        ),
      ];
    },
    [null, body || ""],
  );

  if (label) {
    setTimeout(() => document.getElementById(label)?.scrollIntoView(), 150);
  }

  return (
    <ReactMarkdown
      className={"max-h-[67dvh] overflow-auto text-wrap px-3 2xl:max-h-[70dvh]"}
      rehypePlugins={[rehypeRaw]}
    >
      {finalBody}
    </ReactMarkdown>
  );
});
