import { format, startOfWeek, subWeeks } from "date-fns";

import { GetUserHighlightsQuery } from "@/gql/graphql";

import { useGetUserHighlightsQuery } from "@/app/service/dashboard.gql.ts";

import { NotificationStatus } from "../../../dashboard.types";

const parseHighlights = (data?: GetUserHighlightsQuery) => {
  if (data?.lgMember) {
    const { lgMember } = data;
    return {
      follows: lgMember.follows.totalCount,
      currentAdded: lgMember.currentAdded.totalCount,
      previousAdded: lgMember.previousAdded.totalCount,
      currentUpdates: lgMember.currentCompanyUpdates.totalCount,
      previousUpdates: lgMember.previousCompanyUpdates.totalCount,
      pendingFeedback: lgMember.pendingFeedback.totalCount,
      completedSentiment: lgMember.competedSentimentForms.totalCount,
      pendingSentiment: lgMember.pendingSentimentForms.totalCount,
      inReview: lgMember.inReview.totalCount,
    };
  }

  return {
    follows: 0,
    currentAdded: 0,
    previousAdded: 0,
    currentUpdates: 0,
    previousUpdates: 0,
    pendingFeedback: 0,
    completedSentiment: 0,
    pendingSentiment: 0,
    inReview: 0,
  };
};

export const useUserHighlights = (userId: string) => {
  const endDate = startOfWeek(new Date());
  const fromDate = subWeeks(endDate, 1);
  const currentDate = format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-d");

  const { data: highlights } = useGetUserHighlightsQuery({
    variables: {
      userId,
      thisWeek: currentDate,
      previousWeek: fromDate,
      statuses: [NotificationStatus.SEEN, NotificationStatus.UNSEEN],
    },
    fetchPolicy: "network-only",
  });

  return parseHighlights(highlights);
};
