import { cn } from "@/lib/utils";

import { Button, Icon } from "@/app/components";
import { isLgCompany } from "@/app/screens/add-company/add-company-view.types.tsx";
import { CompanyResult, NewCompany } from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

export const ProgressSteps = ({
  forms,
  onCreate,
  selectedCompany,
  loading,
}: {
  forms: {
    label: string;
    isValid: boolean;
  }[];
  onCreate: () => void;
  selectedCompany: CompanyResult | NewCompany | null;
  loading?: boolean;
}) => {
  const isCreateDisabled = forms.some((form) => !form.isValid) || (!!selectedCompany && isLgCompany(selectedCompany));

  return (
    <div className="sticky top-0 flex items-center gap-2 bg-white/80 p-4 shadow-xs backdrop-blur">
      <div className="flex justify-between gap-5">
        {forms.map((form) => (
          <div key={form.label} className="flex items-center gap-1">
            <Icon
              type={form.isValid ? "Check" : "X"}
              className={cn(
                "size-5 rounded-xs text-white",
                form.isValid && "bg-gradient-to-r from-primary-400 to-secondary-400",
                !form.isValid && "bg-neutral-300",
              )}
            />
            <span className="text-sm text-neutral-600">{form.label}</span>
          </div>
        ))}
      </div>
      <Button
        text={"Create"}
        variant={"gradient"}
        className="order-first ml-auto w-full rounded-sm lg:order-last lg:mb-0 lg:w-auto"
        onClick={onCreate}
        disabled={isCreateDisabled || loading}
        loading={loading}
      />
    </div>
  );
};
