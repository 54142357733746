import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const CompanyNoteSchema = z.object({
  note: z.string().optional(),
});

export type CompanyNoteFormData = z.infer<typeof CompanyNoteSchema>;

export const useCompanyNoteForm = () => {
  return useForm<CompanyNoteFormData>({
    resolver: zodResolver(CompanyNoteSchema),

    defaultValues: {
      note: "",
    },
  });
};
