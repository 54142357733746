import { useMemo } from "react";

export const useSplitList = <T>(list: T[], split = 100) => {
  return useMemo(() => {
    if (list?.length < split) return { main: list, other: [] };

    const main = list?.slice(0, split) || [];
    const other = list?.slice(split) || [];

    return { main, other };
  }, [list, split]);
};
