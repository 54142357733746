import React from "react";

import { Controller } from "react-hook-form";

import { TextArea } from "@/app/components";
import { CompanyNoteFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-note-form.ts";

import { CompanyStepComponentProps } from "../../add-company-new.types.ts";

export const CompanyNoteStep = ({ form }: CompanyStepComponentProps<CompanyNoteFormData>) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <TextArea
              containerClassName="w-full max-h-[400px]"
              label={"Note"}
              placeholder={
                "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
              }
              onChange={field.onChange}
              value={field.value ?? ""}
            />
          )}
        />
      </form>
    </div>
  );
};
