import { observer } from "mobx-react";

import { Modal } from "@/app/components";
import { ModalHeader } from "@/app/screens/modal/components";
import modalStore from "@/app/stores/modal.store";

import { CompleteSentimentParams, useCompleteSentiment } from "./hooks/use-complete-sentiment";

export const CompleteSentimentWeb = observer((props: CompleteSentimentParams) => {
  const { title, subTitle, actions, renderForm } = useCompleteSentiment(props);

  return (
    <Modal
      isOpen
      handleClose={modalStore.close}
      header={<ModalHeader title={title} subtitle={subTitle} containerClassName="lg:mb-0 xl:mb-0" />}
      footer={actions}
      bodyContentClassName="pt-0"
    >
      {renderForm}
    </Modal>
  );
});
