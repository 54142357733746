import { Lottie, Text } from "@/app/components";
import { useEffect } from "react";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { observer } from "mobx-react";

export const SuccessModal = observer(() => {
  const {
    navState: { label, lottie },
  } = globalModalStore.state;

  useEffect(() => {
    setTimeout(() => {
      globalModalStore.toggleModal();
    }, 2500);
  }, []);

  return (
    <div className={"flex flex-1 flex-col items-center justify-center "}>
      <Lottie type={lottie || "completed"} className={"h-52 w-52"} />
      <Text
        type={"h5"}
        color={"text-primary"}
        className={"lg:mt-10"}
        text={label || "Success"}
      />
    </div>
  );
});
