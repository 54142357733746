import { forwardRef } from "react";

import { Loader2 } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType, Text } from "@/app/components";

export type ButtonProps = {
  text?: string;
  size?: ButtonSizeType;
  className?: string;
  variant?: ButtonColorType;
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e) => void;
  iconRight?: IconType;
  iconRightClassName?: string;
  iconLeft?: IconType;
  iconLeftClassName?: string;
  labelClassName?: string;
  type?: HTMLButtonElement["type"];
};

const buttonSize = {
  lg: "h-[48px] min-w-[132px] w-fit p-4 text-4 text-semibold",
  md: "h-[40px] min-w-[122px] w-fit px-4 py-3 text-3.5 text-bold",
  sm: "h-[32px] min-w-[114px] w-fit px-3 py-2 text-sm text-bold",
  xs: "h-[32px] w-fit px-3 py-2 text-sm text-bold",
};

const buttonType = {
  primary:
    "text-white bg-black active:bg-neutral-100 active:text-neutral-400 disabled:bg-neutral-100 disabled:text-neutral-400 hover:bg-neutral-800",
  secondary:
    "text-neutral-900 bg-white active:bg-neutral-100 disabled:bg-neutral-100 disabled:text-neutral-400 hover:bg-neutral-300",
  tertiary: "text-black bg-neutral-100 active:bg-neutral-200 disabled:bg-neutral-100 disabled:text-neutral-300",
  danger: "text-white bg-red-500 active:bg-red-400 disabled:bg-red-400 disabled:text-neutral-300",
  outline:
    "text-black border border-neutral-200 bg-white active:bg-neutral active:text-white disabled:bg-white disabled:text-neutral-200 disabled:border-neutral-100 hover:bg-background",
  "outline-black":
    "text-black border border-black bg-white active:bg-black active:text-white disabled:bg-white disabled:text-neutral-100 disabled:border-neutral-100 ",
  black:
    "text-white bg-black active:text-black active:bg-white active:border active:border-black disabled:bg-neutral-100 disabled:text-neutral-200 hover:opacity-80",
  gradient:
    "text-white bg-gradient-to-r from-primary-400 to-secondary-400 disabled:bg-none disabled:bg-neutral-300 disabled:text-white cursor-pointer hover:opacity-90",
  text: "text-black hover:text-neutral-600 active:bg-neutral-300 disabled:pointer-events-none disabled:text-neutral-400",
};

type ButtonSizeType = "sm" | "md" | "lg" | "xs";
export type ButtonColorType = keyof typeof buttonType;

const iconSizeClass = (isLeft: boolean) => ({
  lg: isLeft ? "ml-2" : "mr-2",
  md: isLeft ? "ml-2" : "mr-2",
  sm: isLeft ? "ml-1" : "mr-1",
  xs: isLeft ? "ml-1" : "mr-1",
});

// fixme doesnt work with children+icon; icons are misplaced
// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className = "",
    iconRight,
    iconRightClassName,
    iconLeft,
    iconLeftClassName,
    labelClassName,
    disabled = false,
    variant = "primary",
    loading = false,
    onClick,
    size = "md",
    text,
    children,
    ...props
  },
  ref,
) {
  const iconSize = size === "lg" ? 18 : 16;

  const content = children ?? (
    <>
      {iconRight && (
        <Icon
          type={iconRight}
          width={iconSize}
          height={iconSize}
          className={`${iconRight && iconLeft ? "" : iconSizeClass(!iconRight)[size]} ${iconRightClassName}`}
        />
      )}
      {text && <Text text={text} className={cn("text-nowrap", labelClassName)} />}
      {iconLeft && (
        <Icon
          type={iconLeft}
          width={iconSize}
          height={iconSize}
          className={`${iconRight && iconLeft ? "" : iconSizeClass(Boolean(iconRight))[size]} ${iconLeftClassName}`}
        />
      )}
    </>
  );

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={cn(
        `select-none rounded-sm ${buttonSize[size]} ${buttonType[variant]} flex items-center ${
          iconRight && iconLeft ? "justify-between" : "justify-center"
        }`,
        className,
      )}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <Loader2 className="animate-spin" /> : content}
    </button>
  );
});
