import React, { PropsWithChildren } from "react";

import { cn } from "@/lib/utils.ts";

export const FormField = ({
  label,
  required,
  children,
  errorMessage,
  className,
}: PropsWithChildren<{
  label: string;
  required?: boolean;
  errorMessage?: string;
  className?: string;
}>) => {
  return (
    <div className={cn("relative flex flex-col items-baseline pb-4", className)}>
      <span className="mb-2 text-xs font-medium text-neutral-600">
        {label}
        {!!required && <span className="ml-1 text-red">*</span>}
      </span>
      {children}
      {!!errorMessage && <span className="absolute bottom-0 text-xs  text-red-500">{errorMessage}</span>}
    </div>
  );
};
