import { Text } from "@/app/components";
import { Signal } from "@/app/types";

import { Signals } from "../signals.tsx";

export interface TitleWithSignalProps {
  title: string;
  signals: Signal[];
  isPortco?: boolean;
  showSignals?: boolean;
  showType?: boolean;
  name?: string;
  signalIconStyle?: string;
}

export const TitleWithSignal = ({
  title,
  signals = [],
  showSignals = false,
  isPortco = false,
  showType = true,
  name,
  signalIconStyle,
}: TitleWithSignalProps) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="mb-2 flex items-center">
        <Text
          text={title}
          noOfLines={1}
          weight={"semibold"}
          className={"mr-2 w-auto font-semibold leading-none text-black md:text-[18px] lg:text-[12px] 2xl:text-[14px]"}
        />
        {showSignals && signals.length > 0 && <Signals signals={signals} iconClassName={signalIconStyle} />}
      </div>
      {showType && (
        <div className={"flex leading-none"}>
          <p className="line-clamp-1 flex items-center text-xss font-[500] text-neutral-500 sm:hidden md:text-[14px] lg:text-[12px] 2xl:text-[14px]">
            {isPortco ? "Portfolio Company" : "Company"}
            <span className="text-neutral-400 lg:text-[14px] 2xl:text-[20px]">&nbsp;•&nbsp;</span>
            {name || "Not Reviewed"}
          </p>
        </div>
      )}
    </div>
  );
};
