import { OptionType, RadioGroups, RadioOption } from "../select-controls";

export interface SwitchProps<T extends OptionType> {
  on: RadioOption<T>;
  off: RadioOption<T>;
  defaultValue?: T;
  onChange: (value?: RadioOption<T>) => void;
}

export const Switch = <T extends OptionType>({ on, off, defaultValue, onChange }: SwitchProps<T>) => {
  return (
    <RadioGroups
      onSelected={onChange}
      defaultValue={defaultValue}
      options={[off, on]}
      className={`flex w-auto max-w-[120px] rounded-xs bg-neutral-100 p-1 [&>*:first-child]:rounded-l-xs [&>*:last-child]:rounded-r-xs`}
      optionContainerClassName="overflow-hidden"
      optionClassName="flex font-semibold cursor-pointer px-4 py-2 text-neutral-600 bg-neutral-100"
      checkedClassName="text-white bg-black"
      labelClassName="inline-block order-last text-[12px] font-normal"
      checkIconContainer="mt-0 mr-2"
      iconClassName="h-11 w-11 text-black"
    />
  );
};
