import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, { useRef } from "react";
import "chartjs-adapter-luxon";
import "chart.js/auto";
import { DateTime } from "luxon";
import { extractFormCategory } from "@/lib/utils.ts";
import {
  BoxAndWiskers,
  BoxPlotController,
  Violin,
  ViolinController,
} from "@sgratzl/chartjs-chart-boxplot";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-selector.tsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BoxPlotController,
  BoxAndWiskers,
  Violin,
  ViolinController,
  Legend
);

export const getOptions = (sentimentData, setActiveTooltip, referenceField) => {
  const { min, max } = sentimentData.reduce(
    (acc, form) => {
      if (!form?.analytics?.[referenceField]) {
        return acc;
      }
      const { min, max } = form.analytics[referenceField];
      return {
        min: Math.min(min, acc.min),
        max: Math.max(max, acc.max),
      };
    },
    {
      min: 10,
      max: 0,
    }
  );
  return {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
        text: "Company Growth",
      },
      legend: { display: false },
      tooltip: {
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
        external: (context) => {
          if (context.tooltip.opacity === 0) {
            setActiveTooltip(null);
          } else {
            setActiveTooltip(context.tooltip?.dataPoints?.[0]?.raw?.form_id);
          }
        },
        callbacks: {
          title: (context) => {
            const item = context?.[0];
            const form = sentimentData.find(
              (form) => form.id == item?.raw?.form_id
            );
            return `${extractFormCategory(form)}\n${item?.label}`;
          },

          footer: (context) => {
            const item = context?.[0];
            const form = sentimentData.find(
              (form) => form.id == item?.raw?.form_id
            );

            if (!form || !item) {
              return;
            }

            const people = Object.keys(form.analytics.person)
              .filter((item) => item != "id" && item != "other")
              .join(",");

            return `People: ${people}`;
          },
        },
      },
    },
    parsing: {
      xAxisKey: "created_at",
      yAxisKey: "value",
    },
    spanGaps: true,
    scales: {
      x: {
        display: true,
        position: "left" as const,
      },
      y: {
        display: true,
        min: min - 1,
        max: max + 1,
        ticks: {
          callback: function (val, index) {
            // Hide every 2nd tick label
            return val <= 10 ? (this as any).getLabelForValue(val) : "";
          },
        },
      },
    },
  };
};

export function SentimentHistory({
  sentiment,
  referenceField,
  setActiveTooltip,
}: {
  sentiment: any; // todo
  referenceField: SentimentAttribute;
  setActiveTooltip: any; // todo
}) {
  const ref = useRef();

  const chronologicalForms = [...sentiment]
    .filter(({ responses }) => responses?.length > 0)
    .reverse();
  const labels = chronologicalForms.map((form) =>
    DateTime.fromISO(form.createdAt).toFormat("yyyy-MM-dd")
  );

  const boxFormData = chronologicalForms.map((form) =>
    form?.responses?.map(
      (response) =>
        response.answers.find((answer) => answer.field_id === referenceField)
          ?.value
    )
  );

  const datasets = [
    {
      type: "line" as const,
      label: "Average",
      data: chronologicalForms.map((form) => ({
        value: form?.analytics?.[referenceField]?.mean,
        created_at: DateTime.fromISO(form.createdAt).toFormat("yyyy-MM-dd"),
        form_id: form.id,
      })),
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      pointRadius: 0,
      borderColor: "rgb(228,12,42)",
      backgroundColor: "rgba(235,53,68,0.5)",
    },
    {
      type: "violin" as const,
      label: "",
      data: boxFormData,
      borderColor: "rgb(218,16,39)",
      backgroundColor: "rgba(235,53,68,0.5)",
    },
  ];

  const options = getOptions(
    sentiment,
    setActiveTooltip,
    referenceField
  ) as ChartOptions;

  return (
    <Chart
      ref={ref}
      type="line"
      options={options}
      className={"h-full"}
      data={{
        labels,
        datasets: datasets as any,
      }}
    />
  );
}
