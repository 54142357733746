import { FunctionComponent } from "react";

import { Database, FolderOpen, HomeIcon, LucideIcon, UserIcon } from "lucide-react";

import { Bell, MeetThePortfolioIcon, Network, Opportunity } from "@/app/components/icon/icon.tsx";

type Routes =
  | "portfolio"
  | "prospects"
  | "pipeline"
  | "investments"
  | "inbox"
  | "network"
  | "settings"
  | "home"
  | "fund-overview";

type SidebarItem = {
  name: string;
  current: boolean;
  path: Routes;
  Icon: LucideIcon | FunctionComponent;
  disabled?: boolean;
};

// all tabs
const allTabs: Record<string, SidebarItem> = {
  inbox: {
    name: "Inbox",
    current: false,
    Icon: Bell,
    path: "inbox",
  },
  portfolio: {
    name: "Portfolio",
    current: false,
    Icon: FolderOpen,
    path: "portfolio",
  },

  prospects: {
    name: "Prospects",
    current: false,
    Icon: Database,
    path: "prospects",
  },
  pipeline: {
    name: "Pipeline",
    current: false,
    Icon: Opportunity,
    path: "pipeline",
  },
  network: {
    name: "Network",
    current: false,
    Icon: Network,
    path: "network",
  },
  // disabled as per request from Ben and Ziv
  // investments: {
  //   name: "Investments",
  //   current: false,
  //   Icon: Analysis,
  //   path: "investments",
  //   webOnly: true,
  // },
  fundOverview: {
    name: "Fund Overview",
    current: false,
    Icon: MeetThePortfolioIcon,
    path: "fund-overview",
    // webOnly: true,
  },
  settings: {
    name: "Settings",
    current: false,
    Icon: UserIcon,
    path: "settings",
  },
  home: {
    name: "Home",
    current: true,
    Icon: HomeIcon,
    path: "home",
  },
};

export const tabsDesktop: Array<SidebarItem[]> = [
  [allTabs.home],
  [allTabs.portfolio, allTabs.prospects, allTabs.pipeline],
  [allTabs.network, allTabs.fundOverview],
];

export const tabsMobile: Array<SidebarItem[]> = [
  [allTabs.home],
  [allTabs.portfolio, allTabs.prospects, allTabs.pipeline],
  [allTabs.network, allTabs.fundOverview],
];
