import React from "react";

import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { Button, Icon, Popover, Text } from "@/app/components";
import { Routes } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { standardMetrics } from "@/app/screens/metrics-extraction";
import {
  EmptySection,
  NumberRowWithFormat,
  OpportunityTable,
  RowWithCurrency,
  TableText,
} from "@/app/screens/opportunities/opportunity/components";

function getMetricLabel(s: string) {
  const standardMetricLabel = standardMetrics.find((metric) => metric.id === s)?.label;
  if (standardMetricLabel) return standardMetricLabel;

  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

// currency, number, date, string, multiplier

const useColumns = (company) => {
  const metrics = company.metrics?.nodes || [];
  const allMetrics = metrics.reduce((acc, { data }) => {
    Object.keys(data)
      .filter((key) => key !== "asAtDate")
      .forEach((key) => acc.add(key));
    return acc;
  }, new Set());

  const columns =
    [
      ...new Set(
        metrics.toSorted((a, b) => (a.asAtDate > b.asAtDate ? -1 : 1)).map(({ asAtDate }) => normaliseDate(asAtDate)),
      ),
    ].map((asAtDate) => {
      return {
        id: asAtDate,
        accessorKey: asAtDate,
        header: asAtDate,
        size: 200,
        sort: false,
        cell: (info) => {
          const { note, type, formatInfo, value, format, source } = info.renderValue() || {};
          const { currencySymbol, multiplier } = formatInfo || {};
          return (
            <div className={"flex w-full"}>
              <Popover
                childrenContent={source}
                placement={"top"}
                childrenClassName={"-mt-2"}
                containerClassName={"flex items-start mr-2 text-center"}
              >
                {!value && !type && <TableText text={value} containerClassName={"text-wrap break-all"} />}
                {format === "percentage" && <NumberRowWithFormat label={value} />}
                {format === "currency" && (
                  <RowWithCurrency
                    currency={currencySymbol === "USD" ? "$" : "£"}
                    label={value}
                    unit={value / 1_000_000 > 1 ? "M" : ""}
                  />
                )}
                {(format === "decimal" || format == "integer") && <NumberRowWithFormat label={value} suffix={""} />}
                {type === "string" && <TableText text={value} containerClassName={"text-wrap break-all"} />}
                {type === "boolean" && <TableText text={value} className={"text-nowrap text-neutral-800"} />}
              </Popover>
              {note && (
                <Popover childrenContent={note} placement={"top"} childrenClassName={"-mt-2"}>
                  <Icon
                    type={"NotificationNote"}
                    className={cn("size-4 text-neutral-300", note ? "hover:text-neutral" : "")}
                  />
                </Popover>
              )}
            </div>
          );
        },
      };
    }) || [];

  columns.unshift({
    id: "type",
    accessorKey: "Type",
    header: "Type",
    size: 220,
    cell: (info) => <Text text={info.renderValue()} className={"text-wrap break-words"} />,
  });

  const data = [...allMetrics].map((metric) => {
    return {
      Type: getMetricLabel(metric),
      ...Object.fromEntries(
        metrics
          .toSorted((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map(({ asAtDate, data }) => {
            return [normaliseDate(asAtDate), data[metric] == null ? "N/A" : data[metric]];
          })
          .filter(([_, value]) => value !== "N/A"),
      ),
    };
  });
  return { columns, data };
};

export const MetricsTable = ({ company }) => {
  const { columns, data } = useColumns(company);
  const navigate = useNavigate();
  if (!columns.length || !data.length) {
    return (
      <EmptySection
        text={"No metrics recorded"}
        Action={
          <Button
            text={"Add Metrics"}
            className={"mt-5"}
            onClick={() => navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: company.id } })}
          />
        }
      />
    );
  }
  return (
    <section className={"overflow-auto"}>
      <OpportunityTable
        data={data}
        columns={columns}
        containerClassName="p-4 md:p-5"
        pageSize={7}
        headerClassName={"p-4 w-full"}
        enableColumnPinning={true}
        Header={<Text text={"Metrics"} className={"text-lg"} />}
        ActionButton={
          <Button
            text={"Add Metrics"}
            className={"w-full"}
            onClick={() => navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: company.id } })}
          />
        }
      />
    </section>
  );
};
