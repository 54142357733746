import React, { Suspense, useState } from "react";

import { Link } from "react-router-dom";

import { cn } from "@/lib/utils.ts";

import { CompanyImg, Icon, IconButton as LgIconButton, IconType } from "@/app/components";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components"; // todo should not be there
import { SearchCompaniesDealroomQuery, SearchCompaniesLocalGlobeQuery } from "@/app/service/opportunities.gql.ts";

import { CompanyRawData } from "../../add-opportunities-via-email.types.ts";

type CompanyData = CompanyRawData["lgCompany"] | CompanyRawData["dealroomCompany"];

export type SelectedCompanyResult =
  | NonNullable<SearchCompaniesDealroomQuery["results"]>["nodes"][number]
  | NonNullable<SearchCompaniesLocalGlobeQuery["results"]>["nodes"][number];

type MatchingCompanyProps = {
  company: CompanyData;
  type: "lg" | "dealroom";
  onSelect?: (company: SelectedCompanyResult) => void;
};

const getUrl = (company: CompanyData): string | null => {
  if (company?.__typename === "LgCompany") {
    return `/company/${company.id}`;
  }

  if (company?.__typename === "DealroomCompany") {
    return `https://app.dealroom.co/companies/${company.path}`;
  }

  return null;
};

const IconButton = ({ onClick, icon }: { onClick: () => void; icon: IconType }) => {
  return (
    <LgIconButton
      icon={icon}
      containerClassName={cn("size-8 !bg-transparent p-2 text-neutral hover:text-neutral-800")}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    />
  );
};

const SelectMatchingCompany = ({ type, company, onSelect }: Required<MatchingCompanyProps>) => {
  return (
    <div className="relative z-[1] h-12 w-full">
      <Suspense
        fallback={
          <div className="flex size-full items-center gap-2 rounded-md border pl-4 pr-12">
            <Icon type={"Loader"} className={"size-6 text-primary-500"} />
            Loading...
          </div>
        }
      >
        <SearchCompany
          defaultSelected={company?.name ?? ""}
          companySearchType={type}
          selectCompany={(comp) => onSelect(comp)}
          searchBarClassName={"lg:!h-12 rounded-md"}
        />
      </Suspense>
    </div>
  );
};

export const MatchingCompany = ({ company, type, onSelect }: MatchingCompanyProps) => {
  const [mode, setMode] = useState<"view" | "edit">("view");
  const [selectedCompany, setCompany] = useState<CompanyData | SelectedCompanyResult>(company);

  if (mode === "edit") {
    return (
      <div className="flex w-full items-center gap-2 sm:min-w-[250px]">
        <SelectMatchingCompany
          company={selectedCompany}
          type={type}
          onSelect={(c) => {
            setCompany(c);
            setMode("view");
            onSelect?.(c);
          }}
        />
        <IconButton
          icon="X"
          onClick={() => {
            setMode("view");
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex h-12 w-full items-center gap-1 rounded-sm border bg-neutral-50/80 px-2 py-1 sm:min-w-[250px]">
      <span className="flex h-full flex-1 items-center gap-2 text-sm">
        {!!selectedCompany && "image" in selectedCompany ? (
          <CompanyImg
            src={selectedCompany.image}
            className="!size-8 rounded-xs lg:!min-w-8"
            imgClassName="rounded-xs"
          />
        ) : (
          <Icon type={type === "lg" ? "Info" : "Dealroom"} className="size-5 text-neutral-300" />
        )}
        {selectedCompany ? (
          <Link className="font-semibold underline" to={getUrl(selectedCompany) ?? ""} target="_blank">
            {selectedCompany.name}
          </Link>
        ) : (
          "No company linked"
        )}
      </span>
      <IconButton
        icon={"Pencil"}
        onClick={() => {
          setMode("edit");
        }}
      />
      {!!selectedCompany && (
        <IconButton
          icon={"Unlink"}
          onClick={() => {
            setCompany(null);
          }}
        />
      )}
    </div>
  );
};
