import React from "react";

import { Control, Controller, FieldPath } from "react-hook-form";

import { useMembersMapped } from "@/lib/service/members.ts";

import { TextArea } from "@/app/components";
import { WebSelect } from "@/app/components/input/web-select.tsx";

import { CompanyAttributesFormData } from "../forms/use-company-attributes-form.ts";

import { FormField } from "./form-field.tsx";

type CompanyAssigneesFieldProps<
  TFieldValues extends {
    [key: string]: CompanyAttributesFormData["assignees"];
  },
  TName extends FieldPath<TFieldValues>,
> = {
  control: Control<TFieldValues>;
  name: TName;
};

export const CompanyAssigneesField = <
  TFieldValues extends {
    [key: string]: CompanyAttributesFormData["assignees"];
  },
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
}: CompanyAssigneesFieldProps<TFieldValues, TName>) => {
  const { data: allOptions } = useMembersMapped();

  return (
    <div>
      <Controller
        name={`${name}.people`}
        control={control}
        render={({ field, fieldState }) => (
          <FormField label="Assignees" required errorMessage={fieldState.error?.message}>
            <WebSelect
              options={allOptions}
              required
              defaultOptions={field.value}
              onChange={field.onChange}
              multiSelect
            />
          </FormField>
        )}
      />
      <Controller
        name={`${name}.note`}
        control={control}
        render={({ field, fieldState }) => (
          <FormField label="Share a note with assignees" errorMessage={fieldState.error?.message}>
            <TextArea
              containerClassName="w-full max-h-[130px]"
              placeholder={
                "Write a few lines about this opportunity. Think of this field as the comments section on Trello."
              }
              onChange={field.onChange}
              value={field.value}
            />
          </FormField>
        )}
      />
    </div>
  );
};
