import { Action } from "@/gql/graphql";

import { useActionMutation, useForm } from "@/app/hooks";

import { ConvertToPortfolioForm } from "../components";

export interface ConvertPortfolioParams {
  id: string;
  onSuccess?: () => void;
}

export const usePortfolioForm = ({ id, onSuccess }: ConvertPortfolioParams) => {
  const form = useForm("ConvertToPortfolio");
  const { onSubmit, loading } = useActionMutation({ onSuccess });

  const handleSubmit = () => {
    const { fund, note } = form.values();

    return onSubmit({
      entityId: id,
      action: Action.MoveToPortfolio,
      reason: note,
      fund,
      successMessage: "Successfully converted to portfolio company",
    });
  };

  return {
    render: <ConvertToPortfolioForm form={form} />,
    loading,
    handleSubmit,
  };
};
