import Sheet from "react-modal-sheet";
import Form from "@/app/components/forms";
import { Button, IosTransitionEnter } from "@/app/components";
import { CloseModal, ModalHeader } from "@/app/screens/modal/components";
import { StepperWithContent } from "@/app/components/steps/stepper-with-content.tsx";
import { StepType } from "@/app/screens/modal/opportunities";
import { opportunitiesSteps } from "@/app/screens/modal/opportunities/components/add-opportunity-misc.ts";
import { useAddOpportunity } from "@/app/screens/modal/opportunities/hooks/useAddOpportunity.tsx";
import { observer } from "mobx-react";
import { useLayoutEffect } from "react";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { useBreakpoints } from "@/lib/hooks";

export const AddOpportunityMobile = observer(({ form }) => {
  const {
    index,
    title,
    setStep,
    Component,
    primaryButton,
    primaryAction,
    secondaryButton,
    secondaryAction,
    primaryActionValidation,
  } = useAddOpportunity(form, "mobile");

  const { isPhone } = useBreakpoints();

  const {
    navState: { navStep },
  } = globalModalStore.state;

  useLayoutEffect(() => {
    if (navStep) setStep(navStep);
  }, [navStep, setStep]);

  return (
    <IosTransitionEnter type={"exit"}>
      <div className={"flex h-full flex-grow flex-col bg-background"}>
        <CloseModal />
        <ModalHeader title={title} />
        <div className={"my-4 flex w-full px-4"}>
          <StepperWithContent
            containerClassName={"w-full"}
            activeStep={index}
            setStep={(stepperIndex) => {
              setStep((prevState) => ({
                ...prevState,
                step: Object.keys(opportunitiesSteps())[
                  stepperIndex
                ] as StepType,
                index: stepperIndex,
              }));
            }}
            steps={Object.entries(opportunitiesSteps()).map(
              ([_, { icon, title }]) => ({
                icon,
                text: title,
                disabled:
                  icon === "Opportunity" &&
                  form.values()["stealth"] === "stealth",
              })
            )}
          />
        </div>
        <Sheet.Content className={"mx-5 flex h-full justify-between"}>
          <div className="flex h-full w-full">
            <Form.Root form={form} className={"flex h-full w-full"}>
              <Component form={form} />
            </Form.Root>
          </div>

          <div className={"mb-8 flex w-full space-x-4"}>
            <Button
              size={isPhone ? "md" : "sm"}
              text={secondaryButton.text}
              variant={"outline"}
              className={"w-full"}
              onClick={secondaryAction}
            />
            <Button
              text={primaryButton.text}
              size={isPhone ? "md" : "sm"}
              disabled={primaryActionValidation()}
              variant={"primary"}
              className={"w-full"}
              onClick={primaryAction}
            />
          </div>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
});
