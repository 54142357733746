import { NzrSentimentForm } from "@/gql/types.ts";
import { normaliseDate } from "@/app/misc/helpers.ts";

type ReturnMultipleValue = "<3x" | "3x-5x" | "5x-10x" | ">10x";

type ReturnMultipleChoice = Record<ReturnMultipleValue, number>;

type SentimentAnalyticsData = {
  return_multiple_choice?: ReturnMultipleChoice;
};

export type LatestReturnProfileData = {
  title: string;
  date: string | null;
  totalSentiments: number; // total amount of choices

  multipliers: Record<
    ReturnMultipleValue,
    {
      totalSentiments: number;
      responses: {
        person: string;
        response_id: string;
      }[];
    }
  >;
};

export const useLatestReturnProfile = (
  sentiments: NzrSentimentForm[]
): LatestReturnProfileData | null => {
  const lastReturnMultipleChoiceSentiment = [...(sentiments || [])]?.find(
    ({ analytics }: { analytics?: SentimentAnalyticsData }) =>
      analytics?.["return_multiple_choice"]
  );

  const lastReturnMultipleChoice =
    (lastReturnMultipleChoiceSentiment?.analytics as SentimentAnalyticsData)?.[
      "return_multiple_choice"
    ] ?? ({} as ReturnMultipleChoice);

  // there's no point in getting details if there's no data about "return_multiple_choice"
  if (!Object.keys(lastReturnMultipleChoice).length) {
    return null;
  }

  const totalReturnMultipleChoice = Object.values(
    lastReturnMultipleChoice
  )?.reduce((acc, value) => acc + value, 0);

  const multipliers: LatestReturnProfileData["multipliers"] = {
    "<3x": {
      totalSentiments: lastReturnMultipleChoice["<3x"],
      responses: [],
    },
    "3x-5x": {
      totalSentiments: lastReturnMultipleChoice["3x-5x"],
      responses: [],
    },
    "5x-10x": {
      totalSentiments: lastReturnMultipleChoice["5x-10x"],
      responses: [],
    },
    ">10x": {
      totalSentiments: lastReturnMultipleChoice[">10x"],
      responses: [],
    },
  };

  for (const response of lastReturnMultipleChoiceSentiment?.responses ?? []) {
    const { answers, person, response_id } =
      response as NzrSentimentForm["responses"][number];

    const choiceValue: ReturnMultipleValue | undefined = answers.find(
      ({ field_id }) => field_id === "return_multiple_choice"
    )?.value;

    if (choiceValue && choiceValue in multipliers) {
      multipliers[choiceValue].responses.push({
        person: typeof person === "string" ? person : person.other,
        response_id,
      });
    } else if (choiceValue) {
      console.warn(`Unknown 'multiple' value: ${choiceValue}`);
    }
  }

  return {
    title: "Latest Return Profile",
    date: normaliseDate(lastReturnMultipleChoiceSentiment?.createdAt),
    totalSentiments: totalReturnMultipleChoice,
    multipliers,
  };
};
