import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";

type MobileFabProps = {
  onClick: () => void;
  className?: string;
  containerClassName?: string;
};
export const MobileFab = ({ onClick, className, containerClassName }: MobileFabProps) => {
  return (
    <div className={cn("absolute bottom-5 -ml-5 flex w-full justify-end lg:hidden", containerClassName)}>
      <button
        onClick={onClick}
        className={cn(
          "z-10 size-12 rounded-md bg-gradient-to-l from-secondary-300 to-red p-2 shadow-md transition duration-200 ease-in active:bg-primary-300",
          className,
        )}
      >
        <Icon type={"Add"} className={"text-white"} />
      </button>
    </div>
  );
};
