import React, { useState } from "react";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";
import { getListItemAnimation } from "../../utils.ts";

import { CompanyUpdatesItem } from "./company-updates-item.tsx";
import { useCompanyUpdates } from "./use-company-updates.ts";

export const CompanyUpdatesSection = () => {
  const [showArchived, setShowArchived] = useState(false);
  const data = useCompanyUpdates(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.companyUpdates.id}
      title={dashboardSections.companyUpdates.label}
      count={data.totalCount}
      loading={data.loading}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      emptyPlaceholder={{ enabled: data.totalCount === 0 && !data.loading, message: "No company updates" }}
      loadMoreBtn={{
        enabled: data.pageInfo?.hasNextPage,
        onLoadMore: data.loadMore,
        loading: data.loadingMore,
      }}
      loadLessBtn={{
        enabled: data.pageInfo?.hasPreviousPage,
        onLoadLess: data.loadLess,
        loading: data.loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {data.items.map((item, index) => (
          <CompanyUpdatesItem key={item.id} notification={item} className={getListItemAnimation(index)} />
        ))}
      </div>
    </DashboardSection>
  );
};
