import React, { useState } from "react";

import { useBreakpoints } from "@/lib/hooks";

import { Button, Modal, NativeModal } from "@/app/components";
import { Company } from "@/app/hooks";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import modalStore from "@/app/stores/modal.store.tsx";

export const CompanySelect = () => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const handleApply = () => {
    metricsStore.setCompany(selectedCompany?.id, selectedCompany);
    modalStore.close();
  };

  return (
    <div className="flex items-start gap-4">
      <SearchCompany
        companySearchType="lg"
        selectCompany={(comp) => setSelectedCompany(comp)}
        onReset={() => setSelectedCompany(null)}
      />
      <div className={"flex h-12 items-center lg:h-16"}>
        <Button text={"Apply"} onClick={handleApply} disabled={!selectedCompany} />
      </div>
    </div>
  );
};

export const CompanySelectModal = () => {
  const { isBigTablet } = useBreakpoints();

  return isBigTablet ? (
    <Modal isOpen showX={false} handleClose={modalStore.close} bodyContentClassName="p-0">
      <CompanySelect />
    </Modal>
  ) : (
    <NativeModal isOpen showX={false} handleClose={modalStore.close}>
      <CompanySelect />
    </NativeModal>
  );
};
