import { CompanyCard } from "../components/company-card.tsx";
import { DashboardSection } from "../components/dashboard-section.tsx";
import { dashboardSections } from "../dashboard.config.ts";
import { useFollowingCompanies } from "../hooks/use-following-companies.ts";

export const FollowingSection = () => {
  const { items, totalCount, pageInfo, loading, loadingLess, loadingMore, loadMore, loadLess } =
    useFollowingCompanies();

  return (
    <DashboardSection
      id={dashboardSections.following.id}
      title={dashboardSections.following.label}
      count={totalCount}
      loading={loading}
      emptyPlaceholder={{ enabled: totalCount === 0 && !loading, message: "No companies in following" }}
      loadMoreBtn={{ onLoadMore: loadMore, enabled: !!pageInfo?.hasNextPage, loading: loadingMore }}
      loadLessBtn={{ onLoadLess: loadLess, enabled: pageInfo?.hasPreviousPage, loading: loadingLess }}
    >
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3">
        {items.map((item) => (
          <CompanyCard key={item.id} company={item} />
        ))}
      </div>
    </DashboardSection>
  );
};
