import { toast } from "react-hot-toast";
import { GraphQLErrors, NetworkError } from "@apollo/client/errors";
import { ExecutionResult } from "graphql/index";
import { NextLink, Operation } from "@apollo/client/link/core";
import { authStore } from "@/app/stores/auth.store.tsx";

type GlobalErrorToast = {
  graphQLErrors?: GraphQLErrors;
  networkError?: NetworkError;
  response?: ExecutionResult;
  operation: Operation;
  forward: NextLink;
};

export function graphqlError({
  graphQLErrors,
  networkError,
  operation,
  forward,
}: GlobalErrorToast) {
  const err = graphQLErrors?.[0];

  if (
    err?.extensions?.statusCode === 401 ||
    err?.extensions?.statusCode === 403
  ) {
    toast.error("Login session expired. Please sign in again.");
    authStore.logout();
  }
}
export function globalErrorToast({
  graphQLErrors,
  networkError,
  operation,
  forward,
}: GlobalErrorToast) {
  const err = graphQLErrors?.[0] as any;

  if (err?.extensions?.method && err?.extensions?.responseBody?.message) {
    toast.error(err?.extensions?.responseBody?.message);
    return;
  }

  toast.error("An error occurred. Please try again later.");
}
