import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { cn } from "@/lib/utils.ts";

import { Icon, Text } from "@/app/components";
import { titleCase } from "@/app/misc/helpers.ts";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { SearchMobile } from "@/app/screens/modal/search";
import { opportunityFilterStore } from "@/app/screens/opportunities";
import filterStore from "@/app/screens/opportunities/filter.store.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import modalStore from "@/app/stores/modal.store";

const displaySubNavbarRoutes = ["/prospects", "/pipeline", "/portfolio"];
const displayNavbarRoutes = [...displaySubNavbarRoutes, "/home", "/network", "/forms", "/settings"];

export const MobileNavbar = observer(() => {
  const { pathname } = useLocation();
  if (!displayNavbarRoutes.includes(pathname)) return null;

  return (
    <div className="z-10 fixed flex h-fit w-full flex-col lg:hidden">
      <nav className="flex h-[8dvh] w-full items-center justify-between bg-[#F7F7F7] px-6 pt-3">
        <div className={"flex items-center"}>
          <Icon
            type={"BurgerMenu"}
            width={24}
            height={24}
            className={"mr-3 fill-red"}
            onClick={() => {
              globalModalStore.toggleSlideOver({
                isOpen: true,
                slideOverType: "Settings",
              });
            }}
          />
          <Text text={titleCase(pathname.substring(1))} type={"title"} />
        </div>

        <div className={"flex items-center space-x-3 gap-2"}>
          <Icon
            type="Search"
            width={20}
            height={20}
            className="ml-auto text-black"
            onClick={() => modalStore.open(SearchMobile)}
          />
          {displaySubNavbarRoutes.includes(pathname) && (
            <div className={"relative"}>
              {Boolean(filterStore.filtersActive) && (
                <Text
                  text={String(filterStore.filtersActive)}
                  type={"message"}
                  color={"text-white"}
                  className={
                    "absolute left-0 top-0 -ml-2 -mt-2 flex size-4 select-none items-center justify-center rounded-full bg-primary transition-transform duration-200 ease-in-out"
                  }
                  weight={"light"}
                />
              )}
              <Icon
                type="Filter"
                width={20}
                height={20}
                className="ml-auto text-black"
                onClick={() => {
                  globalModalStore.navigate({
                    modalType: "OpportunityFilter",
                    navState: {},
                  });
                }}
              />
            </div>
          )}
        </div>
      </nav>
      {displaySubNavbarRoutes.includes(pathname) && <MobileSubNavbar />}
    </div>
  );
});

const MobileSubNavbar = observer(() => {
  const itemsCount = opportunitiesStore.state.totalOpportunityCount
    ? `${opportunitiesStore.state.opportunitiesLength} out of ${String(
        opportunitiesStore.state.totalOpportunityCount,
      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    : "";

  return (
    <div className={"w-full bg-[#F7F7F7]"}>
      <nav className={"no-scrollbar flex gap-x-4 ml-6 pr-6 mt-0.5 overflow-auto"}>
        {opportunitiesStore.opportunityTab.map(({ value, label }, index) => {
          const isSelected = opportunitiesStore?.state?.currentTab.quickView === value;
          return (
            <div autoFocus={isSelected} key={index} className={"flex min-w-fit flex-1 items-center text-nowrap"}>
              <Text
                text={label}
                color={isSelected ? "text-black" : "text-neutral-700"}
                className={cn(
                  "rounded-xs px-3 py-2",
                  isSelected ? "bg-white" : "bg-[#EFEFEF]",
                  isSelected && !filterStore.showRestorePreset ? "rounded-l-xs rounded-r-none" : "",
                )}
                onClick={() =>
                  opportunitiesStore.setState({
                    currentTab: {
                      ...opportunitiesStore.state.currentTab,
                      quickView: value,
                    },
                  })
                }
                weight={isSelected ? "semibold" : "light"}
              />
              {isSelected && !filterStore.showRestorePreset && (
                <div className={"group flex h-full items-center rounded-r-xs bg-white pr-3 active:bg-[#F3F3F3]"}>
                  <Icon
                    type={"Reset"}
                    className={"text-gray-400 group-active:text-primary-200"}
                    width={16}
                    height={16}
                    onClick={() => filterStore.currentTabChange()}
                  />
                </div>
              )}
            </div>
          );
        })}
      </nav>
      <div className={"my-0.5 px-6 flex items-center justify-between"}>
        <div
          className={cn("my-2 flex items-center rounded-md active:bg-white")}
          onClick={() => {
            globalModalStore.navigate({
              modalType: "OpportunitySort",
              navState: { detent: "content-height" },
            });
          }}
        >
          <Icon
            type={opportunityFilterStore?.ordering?.dir === "DESC" ? "DownUp" : "UpDown"}
            width={16}
            height={16}
            className={"mr-2 text-black"}
          />
          <Text
            text={opportunityFilterStore?.ordering?.label || "Default"}
            className={"select-none"}
            weight={"light"}
          />
        </div>
        <div className={"items-center justify-center "}>
          <Text text={itemsCount} weight={"light"} className={"select-none text-nowrap text-neutral-400"} />
        </div>
      </div>
    </div>
  );
});
