import Form from "@/app/components/forms";
import { OptionsType, Text, TextArea } from "@/app/components";
import { FormSelect } from "@/app/components/input";
import FormType from "mobx-react-form";

export interface AssignFormParams {
    form: FormType;
    assignees: OptionsType[];
    selectedAssignees?: OptionsType[];
}

export const AssignForm = ({ form, assignees, selectedAssignees = [] }: AssignFormParams) => {
    return (
        <Form.Root form={form} className={""}>
        <div className={"flex w-full flex-col space-y-6"}>
          <Form.Field
            fieldId={"assignees"}
            render={({ onChange, value }) => (
              <div className="flex flex-1 flex-col items-start">
                <Form.Label />
                <FormSelect
                  required
                  multiSelect={true}
                  options={assignees}
                  defaultOptions={value || selectedAssignees}
                  onChange={onChange}
                />
                <Form.Error />
              </div>
            )}
          />
          <Form.Field
            fieldId={"note"}
            render={({ onChange, value }) => (
              <div className={"flex w-full flex-col items-start"}>
                <Form.Label />
                <TextArea
                  containerClassName={"w-full"}
                  className={"h-[20dvh]"}
                  value={value}
                  onChange={onChange}
                  placeholder={
                    "Add any additional context about the company that may be relevant…"
                  }
                />
                <Form.Error />
              </div>
            )}
          />
        </div>
      </Form.Root>
    )
}