import { cn } from "@/lib/utils.ts";

import { type BoldType, type ColorType, type TextType } from "@/app/components";

const styles: Record<TextType, string> = {
  h1: "text-[48px]",
  h2: "text-[40px]",
  h3: "text-[36px]",
  h4: "text-[32px]",
  h5: "text-[24px]",
  title: "text-[20px]",
  subtitle: "text-[18px]",
  lg: "text-[16px]",
  body: "text-[14px]",
  message: "text-[12px]",
  small: "text-[10px]",
};

const boldType: Record<BoldType, string> = {
  thin: "font-light",
  light: "font-medium",
  normal: "font-semibold",
  medium: "font-[500]",
  semibold: "font-bold",
  bold: "font-extrabold",
};

type TextProps = {
  type?: TextType;
  weight?: BoldType;
  text?: string;
  href?: string;
  italic?: boolean;
  align?: "left" | "center" | "right" | "justify";
  color?: `text-${ColorType}`;
  className?: string;
  children?: React.ReactNode;
  noOfLines?: number;
  autoFocus?: boolean;
  onClick?: (...args) => void;
  innerHtml?: string;
  ref?: React.Ref<any>;
};

const lineClampMap: Record<number, string> = {
  0: "", // when value is 0 it means no line clamp
  1: "line-clamp-1",
  2: "line-clamp-2",
  3: "line-clamp-3",
  4: "line-clamp-4",
  5: "line-clamp-5",
  6: "line-clamp-6",
};
export function Text({
  noOfLines = 0, // when value is 0 it means no line clamp
  color,
  weight = "normal",
  className = "",
  type = "body",
  italic = false,
  text = "",
  autoFocus = false,
  children,
  innerHtml,
  ref,
  onClick,
  href,
  ...props
}: TextProps) {
  const fontSize = styles[type];
  const fontWeight = boldType[weight];

  if (innerHtml) {
    return (
      <p
        autoFocus={autoFocus}
        className={cn(
          `${fontSize} ${fontWeight} ${color} ${
            lineClampMap[noOfLines] || ""
          } ${className} ${onClick ? "select-none" : ""}`,
          italic ? "italic" : "",
        )}
        onClick={onClick}
        dangerouslySetInnerHTML={{
          __html:
            String(innerHtml)
              ?.replaceAll("<b>", '<b class="font-semibold">')
              ?.replaceAll("<br/>", ' <b class="font-semibold">•</b> ') || "",
        }}
        {...props}
      />
    );
  }

  return (
    <p
      autoFocus={autoFocus}
      className={cn(
        `${lineClampMap[noOfLines] || ""}`,
        onClick ? "select-none hover:text-neutral-600" : "",
        italic ? "italic" : "",
        fontSize,
        fontWeight,
        className,
        color,
      )}
      onClick={onClick}
      {...props}
    >
      {text || children}
    </p>
  );
}
