import { Icon, Text } from "@/app/components";
import { shared } from "./styles";
import { cn } from "@/lib/utils";

export interface AccessTitleProps {
  iconClassName?: string;
  titleClassName?: string;
  containerClassName?: string;
}

export const AccessTitle = ({
  iconClassName = "",
  titleClassName = "",
  containerClassName = "",
}: AccessTitleProps) => {
  return (
    <h2 className={cn("mb-2 flex  items-center gap-x-2", containerClassName)}>
      <Icon type={"Lock"} className={cn(shared.titleIcon, iconClassName)} />
      <Text
        text={"Access"}
        type={"title"}
        className={cn(shared.titleText, titleClassName)}
      />
    </h2>
  );
};
