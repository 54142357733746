import { cn } from "@/lib/utils";

import { normaliseDate } from "@/app/misc/helpers";

import { DetailsColumn, DetailsColumnProps } from "./details-column";

interface DateColumnProps extends DetailsColumnProps {
  date: string;
}

export const DateColumn = ({ date, label, styles = {} }: DateColumnProps) => {
  const { container, labelStyle, content } = styles;
  return (
    <DetailsColumn label={label} styles={{ container: cn("flex flex-col justify-between", container), content }}>
      <span className={labelStyle}>{normaliseDate(date)}</span>
    </DetailsColumn>
  );
};
