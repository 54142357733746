import { cn } from "@/lib/utils";

import { Button, IconType } from "@/app/components";
import type { ButtonColorType } from "@/app/components/button";

export interface ActionButtonProps {
  primaryBtnIcon?: IconType;
  primaryBtnTitle: string;
  primaryBtnVariant?: ButtonColorType;
  secondaryBtnTitle: string;
  isLoading?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  btnCommonStyle?: string;
  primaryBtnStyle?: string;
  handleCancel: () => void;
  showBack?: boolean;
  backAction?: () => void;
  handlePrimaryAction: () => void;
}

export const ActionButtons = ({
  primaryBtnIcon,
  primaryBtnTitle,
  primaryBtnVariant = "outline",
  secondaryBtnTitle,
  isLoading,
  disabled,
  containerClassName,
  btnCommonStyle = "w-1/2",
  showBack = false,
  primaryBtnStyle,
  handleCancel,
  handlePrimaryAction,
  backAction,
}: ActionButtonProps) => {
  return (
    <div className={"flex"}>
      {showBack && (
        <Button text={"Back"} variant={"outline"} className={cn(btnCommonStyle, "w-1/4")} onClick={backAction} />
      )}
      <div className={cn("bottom flex w-full flex-1 items-end justify-end space-x-4", containerClassName)}>
        <Button
          text={secondaryBtnTitle}
          variant={"outline"}
          className={cn(btnCommonStyle, showBack ? "w-1/3" : "")}
          onClick={handleCancel}
        />
        <Button
          text={primaryBtnTitle}
          variant={primaryBtnVariant}
          disabled={disabled}
          loading={isLoading}
          className={cn(btnCommonStyle, showBack ? "w-1/3" : "", primaryBtnStyle)}
          iconRight={primaryBtnIcon}
          onClick={handlePrimaryAction}
        />
      </div>
    </div>
  );
};
