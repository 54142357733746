import { useMemo } from "react";

import { isAfter, addMonths, parseISO } from "date-fns";

const calculateExcitmentSentiment = (members) => {
  if (members.length > 1) {
    const { mean: previousResponse } = members.slice(0, members.length - 1).reduce(
      (accumulator, { score }, index) => ({
        total: accumulator.total + score,
        mean: (accumulator.total + score) / (index + 1),
      }),
      { total: 0, mean: 0 },
    );

    const { mean: lastResponse } = members.reduce(
      (accumulator, { score }, index) => ({
        total: accumulator.total + score,
        mean: (accumulator.total + score) / (index + 1),
      }),
      { total: 0, mean: 0 },
    );

    if (lastResponse > previousResponse)
      return {
        increase: (Math.round((lastResponse - previousResponse) * 100) / 100).toFixed(1),
      };
    if (lastResponse < previousResponse)
      return {
        decrease: (Math.round((previousResponse - lastResponse) * 100) / 100).toFixed(1),
      };
  }

  return {};
};

export const useExcitementScores = ({ sentiments, isPortco, previouslyMet }) => {
  const stats = useMemo(() => {
    let excitement = 0;
    let lastResponseTime: string | null = null;
    let previousResponseTime: string | null = null;
    let isSad = false;
    let isEmpty = true;
    let members = [];

    const lastSentimentResponse = sentiments.find((e) => e?.responses?.length);
    const previousSentimentResponse = sentiments.find(
      (e) => e.id !== lastSentimentResponse?.id && e.responses?.length > 0,
    );

    if (lastSentimentResponse?.analytics?.excitement_opinion_scale) {
      excitement = lastSentimentResponse.analytics.excitement_opinion_scale.mean;
      lastResponseTime = lastSentimentResponse.createdAt;
    }

    if (previousSentimentResponse) {
      previousResponseTime = previousSentimentResponse.createdAt;
    }

    if (lastSentimentResponse) {
      const { noOfAnswers, people } = lastSentimentResponse.responses?.reduce?.(
        ({ noOfAnswers, people }, { answers, person }) => {
          const excitement = answers.find(({ field_id }) => field_id === "excitement_opinion_scale");
          if (excitement) {
            return {
              noOfAnswers: noOfAnswers + 1,
              people: [...people, { name: person, score: excitement.value }],
            };
          }
          return { noOfAnswers, people };
        },
        { noOfAnswers: 0, people: [] },
      ) || { noOfAnswers: 0, people: [] };

      members = people;
      isEmpty = noOfAnswers.length === 0;
    }

    isSad = (isPortco || previouslyMet) && isEmpty;

    const velocity = calculateExcitmentSentiment(members);

    return {
      ...velocity,
      excitement,
      lastResponseTime,
      previousResponseTime,
      isEmpty,
      isSad,
      members,
      isOld: Boolean(lastResponseTime && isAfter(new Date(), addMonths(parseISO(lastResponseTime), 6))),
    };
  }, [sentiments, previouslyMet, isPortco]);

  const velocity = useMemo(() => {
    let view: JSX.Element | null = null;

    if (stats.decrease) {
      view = <span className="text-ss  font-semibold text-red-500 md:text-xs">&#8601;{stats.decrease}</span>;
    }

    if (stats.increase) {
      view = <span className="text-ss font-semibold text-green-500 md:text-xs">&#8599;{stats.increase}</span>;
    }

    return view;
  }, [stats]);

  return { stats, velocity };
};
