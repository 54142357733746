import { text } from "@/app/styles";
import { Text } from "../../../../../components";

export const FormDetails = ({ noOfParticipants, totalSentimentForms }) => {
  return (
    <div className="">
      <div className={"flex border-t py-2"}>
        <Text
          text={"Number of Participants"}
          weight={"normal"}
          className={text.subTitleText}
        />
        <Text
          text={String(noOfParticipants)}
          type={"subtitle"}
          color={"text-primary"}
          className={`${text.titleText} ml-4 text-primary`}
        />
      </div>
      <div className={"flex border-y py-2"}>
        <Text
          text={"Sentiment Forms"}
          weight={"normal"}
          className={text.subTitleText}
        />
        <Text
          text={String(totalSentimentForms)}
          type={"subtitle"}
          color={"text-primary"}
          className={`${text.titleText} ml-4 text-primary`}
        />
      </div>
    </div>
  );
};
