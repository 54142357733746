import { Company, CompanyFlags, CompanyUserTags } from "../types";

export const getCompanyUserTags = (company: Partial<Company> = {}) => {
  let userTags: CompanyUserTags = {};

  if (company?.userTags?.nodes) {
    userTags = company.userTags.nodes.reduce<CompanyUserTags>((accumulator, { tags, userId }) => {
      if (userId)
        return {
          ...accumulator,
          [userId]: { following: !!Number(tags["follow"]), hidden: !!Number(tags["hide"]) },
        };

      return accumulator;
    }, userTags);
  }

  return userTags;
};

export const getCompanyFlags = (company: Partial<Pick<Company, "companyFlags">> = {}) => {
  let companyFlags: CompanyFlags = {};

  if (company.companyFlags?.items) {
    companyFlags = company.companyFlags.items.reduce<CompanyFlags>(
      (accumulator, companyFlag) => ({ ...accumulator, [companyFlag.flag]: companyFlag }),
      companyFlags,
    );
  }

  return companyFlags;
};
