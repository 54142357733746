import { cn } from "@/lib/utils";

import { NotificationStatus } from "../../dashboard.types";

type NotificationStatusProps = {
  status: NotificationStatus;
  className?: string;
};

export const NotificationStatusComponent = ({ status, className }: NotificationStatusProps) => {
  return (
    <span
      className={cn(
        "rounded-xs px-1.5 py-1 text-sm",
        status === NotificationStatus.COMPLETED && "bg-green-100 text-green-600",
        status === NotificationStatus.DECLINED && "bg-red-100 text-red-600",
        className,
      )}
    >
      {status === NotificationStatus.COMPLETED && "Completed"}
      {status === NotificationStatus.DECLINED && "Declined"}
    </span>
  );
};
