import { useMembersMapped } from "@/lib/service/members.ts";
import { Action } from "@/gql/graphql.ts";
import Form from "mobx-react-form";
import { useActionMutation } from "@/app/hooks";

export const useAddToTrello = ({ form, id, onSuccess }: { form: Form; id: string; onSuccess?: () => void }) => {
  const { data: allOptions } = useMembersMapped();
  const { onSubmit: actionMutation, loading: addToTrelloLoading, error: addToTrelloError } = useActionMutation({ defaultSuccessMessage: "Company moved Successfully"});

  const entityId = id;

  const onSubmit = () => {
    const { trello_column } = form.values();

    actionMutation({
      entityId,
      trelloColumn: trello_column?.value,
      action: Action.AddToTrello,
      }).then(() => {
      if (onSuccess) onSuccess();
    });
  };

  const isValid = !!(form?.isValid);

  return { allOptions, isValid, addToTrelloLoading, addToTrelloError, onSubmit };
};
