import React from "react";

import { differenceInDays, format, isValid } from "date-fns";

import { Popover, Text } from "@/app/components";
import { normaliseDate, timeAgo } from "@/app/misc/helpers.ts";

export const CommentDate = ({ date }: { date: string }) => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }

  return (
    <Popover containerClassName={"outline-none"} childrenContent={format(new Date(date), "dd LLL YYY HH:mm")}>
      <Text type="message" className="ml-2 text-neutral-500 sm:font-light md:font-semibold sm:text-xss md:text-xs">
        {differenceInDays(new Date(), new Date(date)) < 1 ? timeAgo(date) : normaliseDate(date)}
      </Text>
    </Popover>
  );
};

export const EditedDate = ({ date }: { date?: string }) => {
  if (!date || !isValid(new Date(date))) {
    return null;
  }

  return (
    <Popover
      containerClassName={"outline-none"}
      childrenContent={
        differenceInDays(new Date(), new Date(date)) < 1 ? timeAgo(date) : format(new Date(date), "dd LLL YYY HH:mm")
      }
    >
      <Text type="message" className="ml-1 text-neutral-500 sm:text-xss">
        (edited)
      </Text>
    </Popover>
  );
};
