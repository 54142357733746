import { NativeModal, Button, Text } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";
import { observer } from "mobx-react";
import { CommentDisplayData } from "@/app/screens/opportunities/opportunity/components/comments/comments.types.ts";
import { useDeleteComment } from "../hooks";

export const CommentActionsMobile = observer(
  ({ comment, setMode }: { comment: CommentDisplayData; setMode: (mode: "edit") => void }) => {
    const { handleDelete, isDeleting } = useDeleteComment();

    return (
      <NativeModal
        isOpen={true}
        handleClose={() => modalStore.close()}
        detent={"content-height"}
        bodyClassName="p-4 pt-0"
      >
        <div className={"flex w-full flex-col gap-6"}>
          <Text className={"text-center text-[16px]"}>Select</Text>

          <div className={"flex flex-col gap-3"}>
            <Button
              text={"Edit"}
              variant={"outline"}
              className={"w-full py-2"}
              iconRight={"Pencil"}
              disabled={isDeleting}
              onClick={() => {
                setMode("edit");
                modalStore.close();
              }}
            />
            <Button
              onClick={() => handleDelete(comment)}
              text={"Delete"}
              variant={"outline"}
              className={"w-full py-2 text-primary"}
              iconRight={"Trash"}
              iconRightClassName={"text-primary"}
              disabled={isDeleting}
            />
          </div>
        </div>
      </NativeModal>
    );
  },
);
