import { useState, useEffect } from "react";
import browserStorage from "store";

export default (storageKey, initialState) => {
  const [state, setInternalState] = useState(initialState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const storageInBrowser = browserStorage.get(storageKey);

    if (storageInBrowser) {
      setInternalState(storageInBrowser);
    }

    setLoaded(true);
  }, [storageKey]);

  const setState = (newState) => {
    browserStorage.set(storageKey, newState);
    setInternalState(newState);
  };

  return [state, setState, loaded];
};
