import { PropsWithChildren, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { cn } from "@/lib/utils.ts";

import { Icon, RadioGroups } from "@/app/components";
import { WebSelect } from "@/app/components/input/web-select.tsx";
import {
  CompanyResult,
  NewCompany,
} from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";
import { industries, stages, targetFund } from "@/data/data.tsx";

import { EditableField } from "./editable-field";

const badgeStyles = {
  green: "bg-[#D0EDBE]/80 text-[#449B14] border-[#D0EDBE]",
  red: "bg-[#F9D9D6]/80 text-[#D5514D] border-[#F9D9D6]",
  violet: "bg-[#DFDCF3]/80 text-[#5F7CD3] border-[#DFDCF3]",
  blue: "bg-[#CCECF9]/80 text-[#259ECF] border-[#CCECF9]",
  grey: "bg-neutral-300/80 text-neutral-400 border-neutral-300",
  gradient: "bg-gradient-to-r from-primary-400 to-secondary-400 text-white border-primary-400",
};

const investmentTypeOptions = [
  { value: "core", label: "Core" },
  { value: "strategic", label: "Strategic" },
];

const Badge = ({
  children,
  color,
  onRemove,
}: PropsWithChildren<{
  color: keyof typeof badgeStyles;
  onRemove?: () => void;
}>) => {
  return (
    <div
      className={cn("flex w-fit items-center justify-center rounded-sm border px-3 py-1 text-sm", badgeStyles[color])}
    >
      {children}
      {onRemove && <Icon type="X" onClick={onRemove} className="ml-1 size-4.5 cursor-pointer hover:scale-110" />}
    </div>
  );
};

const CompanyBadgesSchema = z.object({
  investmentType: z.enum(["core", "strategic"]),
  industries: z.array(z.string()).nonempty("Add at least one industry"),
  round: z.string().min(1, "Round is required"),
  fund: z.string().min(1, "Fund is required"),
});

type CompanyBadgesFormData = z.infer<typeof CompanyBadgesSchema>;

export const useCompanyBadgesForm = () => {
  return useForm<CompanyBadgesFormData>({
    resolver: zodResolver(CompanyBadgesSchema),

    defaultValues: {
      industries: [],
      round: "",
      fund: "",
    },
  });
};

export const CompanyBadgesForm = ({
  company,
  form,
}: {
  company: CompanyResult | NewCompany | null;
  form: UseFormReturn<CompanyBadgesFormData>;
}) => {
  const { control, setValue, reset, handleSubmit } = form;

  useEffect(() => {
    if (!!company && "industries" in company) {
      setValue(
        "industries",
        company.industries
          .map((industry: string | { name: string }) => (typeof industry === "string" ? industry : industry?.name))
          .filter(Boolean),
      );
    } else {
      reset();
    }
  }, [company]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form className="flex flex-wrap gap-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="investmentType"
        control={control}
        render={({ field }) => (
          <EditableField
            value={
              (field.value && (
                <Badge
                  color="green"
                  onRemove={() => {
                    field.onChange(undefined);
                  }}
                >
                  {investmentTypeOptions.find((option) => option.value === field.value)?.label ?? field.value}
                </Badge>
              )) || <Badge color="grey">Select Investment Type</Badge>
            }
          >
            <RadioGroups
              onSelected={(op) => field.onChange(op?.value)}
              options={investmentTypeOptions}
              defaultValue={field.value}
              className={`flex`}
              optionClassName="flex font-semibold cursor-pointer mr-4 py-3"
              checkedClassName="text-black"
              labelClassName="inline-block order-last text-[14px] font-normal"
              checkIconContainer="mt-0 mr-2"
              iconClassName="h-11 w-11 text-black"
              withCheck
            />
          </EditableField>
        )}
      />

      <Controller
        name="fund"
        control={control}
        render={({ field }) => (
          <EditableField
            value={
              (field.value && (
                <Badge
                  color="red"
                  onRemove={() => {
                    field.onChange(undefined);
                  }}
                >
                  {field.value}
                </Badge>
              )) || <Badge color="grey">Select Fund</Badge>
            }
          >
            <WebSelect
              iconLeft={"LoopGradient"}
              optionsDirection={"bottom"}
              options={targetFund}
              placeholder="Please select..."
              required={true}
              defaultOptions={targetFund.filter((fund) => fund.value === field.value)}
              onChange={(value) => field.onChange(value?.[0]?.value)}
            />
          </EditableField>
        )}
      ></Controller>

      <Controller
        name="round"
        control={control}
        render={({ field }) => (
          <EditableField
            value={
              (field.value && (
                <Badge
                  color="violet"
                  onRemove={() => {
                    field.onChange(undefined);
                  }}
                >
                  {field.value}
                </Badge>
              )) || <Badge color="grey">Select Round</Badge>
            }
          >
            <WebSelect
              iconLeft={"LoopGradient"}
              optionsDirection={"bottom"}
              options={stages}
              defaultOptions={stages.filter((option) => option.value === field.value)}
              placeholder="Please select..."
              required={true}
              onChange={(value) => field.onChange(value?.[0]?.value)}
            />
          </EditableField>
        )}
      ></Controller>

      <Controller
        name="industries"
        control={control}
        render={({ field }) => (
          <EditableField
            value={
              field.value?.length ? (
                field.value.map((val) => (
                  <Badge
                    key={val}
                    color="blue"
                    onRemove={() => {
                      field.value && field.onChange(field.value.filter((value) => value !== val));
                    }}
                  >
                    {val}
                  </Badge>
                ))
              ) : (
                <Badge color="grey">No Industries</Badge>
              )
            }
          >
            <WebSelect
              iconLeft={"LoopGradient"}
              optionsDirection={"bottom"}
              options={industries}
              placeholder="Please select..."
              multiSelect
              required={true}
              defaultOptions={field.value?.map((val) => ({ label: val, value: val }))}
              onChange={(values) => {
                field.onChange(values.map((val) => val.value));
              }}
            />
          </EditableField>
        )}
      ></Controller>
    </form>
  );
};
