import React from "react";

import { TooltipInfo } from "./info";
import { Popover, PopoverPlacement } from "./popover";

type TooltipProps = {
  text: string;
  placement?: PopoverPlacement;
  children: React.ReactNode;
};

export const Tooltip = ({ text, placement, children }: TooltipProps) => {
  return (
    <Popover placement={placement} childrenContent={<TooltipInfo text={text} />}>
      {children}
    </Popover>
  );
};
