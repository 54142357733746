import React from "react";

import { CompanyFoundersFields } from "@/app/screens/opportunities/add-new/shared/components/company-founders-fields.tsx";
import { FoundersFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-founders-form.ts";

import { CompanyStepComponentProps } from "../../add-company-new.types.ts";

export const CompanyFoundersStep = ({ form }: CompanyStepComponentProps<FoundersFormData>) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="h-full">
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <CompanyFoundersFields control={control} name="founders" />
      </form>
    </div>
  );
};
