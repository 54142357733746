import React, { useState } from "react";

import { useNavigate } from "react-router";

import { ManualOpportunityInput } from "@/gql/graphql.ts";

import {
  CompanyAssigneesForm,
  useCompanyAssigneesForm,
} from "@/app/screens/add-company/components/company-assignees-form.tsx";
import { ProgressSteps } from "@/app/screens/add-company/components/progress-steps.tsx";
import { useAddOpportunityMutation } from "@/app/service/opportunities.gql.ts";

import { AddCompanyStatus } from "./components/add-company-status.tsx";
import { CompanyAttributesForm, useCompanyAttributesForm } from "./components/company-attributes-form.tsx";
import { CompanyBadgesForm, useCompanyBadgesForm } from "./components/company-badges-form.tsx";
import { CompanyNoteForm, useCompanyNoteForm } from "./components/company-note-form.tsx";
import { CompanySourceForm, useCompanySourceForm } from "./components/company-source-form.tsx";
import { FoundersForm, useFoundersForm } from "./components/founders-form.tsx";
import { MainCompanyData, useMainCompanyDataForm } from "./components/main-company-data.tsx";
import { CompanyResult, NewCompany } from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

const useForms = () => {
  const mainCompanyDataForm = useMainCompanyDataForm();
  const companyBadgesForm = useCompanyBadgesForm();
  const foundersForm = useFoundersForm();
  const companyNoteForm = useCompanyNoteForm();
  const companySourceForm = useCompanySourceForm();
  const companyAttributesForm = useCompanyAttributesForm();
  const companyAssigneesForm = useCompanyAssigneesForm();

  return {
    mainCompanyDataForm,
    companyBadgesForm,
    foundersForm,
    companyNoteForm,
    companySourceForm,
    companyAttributesForm,
    companyAssigneesForm,
  };
};

export const AddCompanyView = () => {
  const navigate = useNavigate();
  const [addOpportunity, { loading }] = useAddOpportunityMutation();

  const [selectedCompany, setSelectedCompany] = useState<CompanyResult | NewCompany | null>(null);

  const {
    mainCompanyDataForm,
    companyBadgesForm,
    foundersForm,
    companyNoteForm,
    companySourceForm,
    companyAttributesForm,
    companyAssigneesForm,
  } = useForms();

  const handleSelectCompany = (company: CompanyResult | NewCompany | null) => {
    setSelectedCompany(company);
  };

  const handleCreate = () => {
    const { about, name, websiteUrl, location } = mainCompanyDataForm.getValues();
    const { industries, fund, round, investmentType } = companyBadgesForm.getValues();
    const { founders } = foundersForm.getValues();
    const { note } = companyNoteForm.getValues();
    const { name: sourceName, type: sourceType } = companySourceForm.getValues();
    const { introAvailable, highPriority, ballInOurControl, activelyRaising } = companyAttributesForm.getValues();
    const { assignees, note: noteToAssignees } = companyAssigneesForm.getValues();

    const data: ManualOpportunityInput = {
      name: name || "Stealth Company",
      websiteUrl: websiteUrl || "",
      country: location.country || "",
      city: location.city || "",
      about: about ?? "",

      industries: industries ?? [],
      round: round || "",
      targetFund: fund || "",
      investmentType: investmentType,

      founders: founders || [],

      note: note || "",

      source: sourceName || "",
      sourceType: sourceType,

      canIntro: introAvailable,
      highPriority: highPriority,
      ballInControl: ballInOurControl,
      isActivelyFundraising: activelyRaising,

      assignees: assignees.map((assignee) => assignee.id),
      noteToAssignees: noteToAssignees,
    };
    console.log(data);

    addOpportunity({
      variables: {
        input: data,
      },
    }).then(() => {
      navigate("/");
    });
  };

  const formStatuses = [
    { isValid: mainCompanyDataForm.formState.isValid, label: "Main Company Data" },
    { isValid: companyBadgesForm.formState.isValid, label: "Badges" },
    { isValid: foundersForm.formState.isValid, label: "Founders" },
    { isValid: companyNoteForm.formState.isValid, label: "Note" },
    { isValid: companySourceForm.formState.isValid, label: "Source" },
    { isValid: companyAttributesForm.formState.isValid, label: "Attributes" },
    { isValid: companyAssigneesForm.formState.isValid, label: "Assignees" },
  ];

  return (
    <div className="size-full">
      <ProgressSteps forms={formStatuses} onCreate={handleCreate} loading={loading} selectedCompany={selectedCompany} />
      <div className="flex flex-1 flex-col gap-4 p-4">
        <div className="flex flex-wrap justify-between gap-4">
          <MainCompanyData className="flex-1" form={mainCompanyDataForm} onSelectCompany={handleSelectCompany} />
          <AddCompanyStatus selectedCompany={selectedCompany} />
        </div>
        <CompanyBadgesForm form={companyBadgesForm} company={selectedCompany} />
        <FoundersForm form={foundersForm} company={selectedCompany} />
        <CompanyNoteForm form={companyNoteForm} />
        <CompanySourceForm form={companySourceForm} />
        <CompanyAttributesForm form={companyAttributesForm} />
        <CompanyAssigneesForm form={companyAssigneesForm} />
      </div>
    </div>
  );
};
