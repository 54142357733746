import { JumpToTop } from "@/app/components";
import { PageNav, PageNavProvider } from "@/app/components/page-nav";

import { dashboardSections } from "./dashboard.config.ts";
import { CompaniesForwardedSection } from "./sections/companies-forwarded/companies-forwarded-section.tsx";
import { CompanyUpdatesSection } from "./sections/company-updates/company-updates-section.tsx";
import { NotProgressingSection } from "./sections/feedback-email/feedback-template-section.tsx";
import { FollowingSection } from "./sections/following-section.tsx";
import { DashboardHeader } from "./sections/header/dashboard-header.tsx";
import { UserHighlightSection } from "./sections/highlights/user-highlights-section.tsx";
import { ReviewingSection } from "./sections/reviewing-section.tsx";
import { SentimentRequestsSection } from "./sections/sentiment-form-requests/sentiment-requests-section.tsx";

const navLinks = Object.values(dashboardSections);

export const Dashboard = () => {
  return (
    <PageNavProvider>
      <section id="home" className="w-full space-y-3 p-5 pt-20 font-barlow font-medium lg:p-10 lg:pb-32">
        <DashboardHeader />
        <div className="flex flex-wrap-reverse space-x-3 lg:flex-nowrap">
          <div className="w-full space-y-8 md:space-y-12">
            <UserHighlightSection />
            <SentimentRequestsSection />
            <CompaniesForwardedSection />
            <CompanyUpdatesSection />
            <NotProgressingSection />
            <ReviewingSection />
            <FollowingSection />
          </div>
          {/*<Calendar />*/}
        </div>
        <PageNav navLinks={navLinks} />
        <JumpToTop hideOn={[]} />
      </section>
    </PageNavProvider>
  );
};
