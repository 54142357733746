import React from "react";

import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";

import { CompanyImg, Icon, IconButton, Text } from "@/app/components";
import { MainCompanyDataForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-main-data-form.ts";

import { useAddCompanyContext } from "../../add-company-new-context.tsx";
import { CompanyStepComponentProps, isLgCompany, isNewCompany } from "../../add-company-new.types.ts";
import { CompanyResult, NewCompany, SelectCompany } from "../select-company.tsx";

const SelectedCompanyStatus = ({ onReset }) => {
  const { selectedCompany } = useAddCompanyContext();

  return (
    <motion.div
      className="relative mt-4 flex flex-col pb-5"
      variants={{
        hidden: {
          opacity: 0,
          visibility: "hidden",
        },
        visible: {
          opacity: 1,
          visibility: "visible",
        },
      }}
      initial={false}
      transition={{ duration: 0.2, ease: "easeIn" }}
      animate={selectedCompany?.name ? "visible" : "hidden"}
    >
      <p className="mb-2 text-left text-[12px] font-medium text-neutral-600">Selected Company</p>
      <div className="flex items-center justify-between gap-2 rounded-md border px-5 py-4">
        <div className="flex items-center gap-2">
          <CompanyImg src={selectedCompany?.image} />
          {selectedCompany && isLgCompany(selectedCompany) && selectedCompany?.id ? (
            <Link
              to={`/company/${selectedCompany.id}`}
              target="_blank"
              className="font-semibold hover:text-neutral-700"
            >
              {selectedCompany.name}
            </Link>
          ) : (
            <span>{selectedCompany?.name}</span>
          )}
        </div>

        <div className="flex items-center gap-2">
          {selectedCompany?.websiteUrl && (
            <Link
              to={selectedCompany?.websiteUrl}
              target={"_blank"}
              className="ml-auto text-sm font-[400] text-neutral-400"
            >
              {selectedCompany?.websiteUrl}
            </Link>
          )}
          <IconButton icon="X" className="ml-auto size-8 rounded p-1 hover:bg-neutral-100" onClick={onReset} />
        </div>
      </div>
      {selectedCompany && isNewCompany(selectedCompany) && (
        <div className="absolute bottom-0 flex w-full items-center gap-1 text-orange-600">
          <Icon type={"Shield Alert"} className={"size-4"} />
          <p className="text-left text-xs font-medium">a new company will be created</p>
        </div>
      )}
      {selectedCompany && isLgCompany(selectedCompany) && (
        <div className="absolute bottom-0 flex w-full items-center gap-1 text-red">
          <Icon type={"Shield Alert"} className={"size-4"} />
          <p className="text-left text-xs font-medium">This company is already in Nazare.</p>
        </div>
      )}
    </motion.div>
  );
};

export const CompanyMainDataStep = ({ form }: CompanyStepComponentProps<MainCompanyDataForm>) => {
  const { handleSubmit, control, reset } = form;
  const { selectCompany } = useAddCompanyContext();

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleSelectCompany = (company: CompanyResult | NewCompany | null) => {
    // when company is null, it means clear has been clicked
    if (!company) {
      handleReset();
      return;
    }

    const name: string = company.name ?? "";
    form.setValue("name", name, { shouldValidate: true });

    // we don't want to proceed with LgCompany
    const type: string | undefined = isLgCompany(company) ? "LgCompany" : undefined;
    form.setValue("type", type, { shouldValidate: true });

    selectCompany(company);
  };

  const handleReset = () => {
    reset();
    selectCompany(null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="mb-2 flex gap-1">
          <Text type="message" text="Add Company Name" weight="light" className="text-left text-neutral-600" />
          <Text type="message" text="*" weight="light" className="text-left text-red" />
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <SelectCompany
              onSelect={(company) => {
                handleSelectCompany(company);
                field.onChange(company?.name ?? "");
              }}
            />
          )}
        />
      </form>
      <SelectedCompanyStatus onReset={handleReset} />
    </div>
  );
};
