import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { TableNew } from "@/app/components/table-new";
import { pcgOverviewColumns } from "@/app/screens/opportunities/opportunity/components/pcg-overview/pcg-overview-columns.tsx";

export const PcgTable = ({ data, totals }: { data: any; totals: any }) => {
  const columns = pcgOverviewColumns;

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    meta: { totals },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableSortingRemoval: true,
  });

  return <TableNew table={table} options={{ density: "dense" }} />;
};
