import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils";

import { Popover, Text } from "@/app/components";

interface TagsProps {
  containerClassName?: string;
  className?: string;
  badges?: BadgeType[];
}

type BadgeType = {
  id: string;
  title?: string;
  value: string;
  group: BadgeStyleType;
};

type BadgeStyleType = "signal" | "fund" | "location" | "sectors" | "team" | "investors" | "other";

// quick hack for country badge
const getValue = (value: string, id: string): string => {
  if (id === "country") {
    return value
      .split(",")
      .map((v) => v.trim())
      .filter(Boolean)
      .join(", ");
  }
  return value;
};

export const Badges = ({ containerClassName, className, badges }: TagsProps) => {
  const { isBigTablet } = useBreakpoints();

  return (
    <div className={`flex flex-wrap gap-x-1 gap-y-1.5 lg:gap-y-1.5 ${containerClassName}`}>
      {badges
        ?.filter(
          ({ value, group, id }) =>
            group !== "signal" && id !== "basecamp_investment" && id !== "portfolio_company" && value !== "N/A",
        )
        .map(({ value, id, title, group }, index) => {
          return (
            <Popover
              key={id + index}
              placement={"bottom"}
              childrenContent={title ? <Text innerHtml={title} weight={"light"} /> : null}
            >
              <div
                className={cn(
                  `flex w-fit items-center justify-center rounded-sm border px-3 py-1`,
                  title ? "cursor-pointer" : "cursor-default",
                  badgeStyles[group],
                  className,
                )}
              >
                <Text text={getValue(value, id)} weight={"light"} type={isBigTablet ? "body" : "message"} />
              </div>
            </Popover>
          );
        })}
    </div>
  );
};

const badgeStyles: Record<BadgeStyleType, string> = {
  signal: "text-startGradient border-0 mr-1 md:text-[0.95rem] mb-0 py-0",
  fund: "bg-[#D0EDBE]/80 text-[#449B14] border-[#D0EDBE]",
  location: "bg-[#F9D9D6]/80 text-[#D5514D] border-[#F9D9D6]",
  sectors: "bg-[#CCECF9]/80 text-[#259ECF] border-[#CCECF9]",
  team: "bg-[#EBD1B9]/80 text-[#D17C2C] border-[#EBD1B9]",
  investors: "bg-[#DFDCF3]/80 text-[#5F7CD3] border-[#DFDCF3]",
  other: "bg-[#F2E28D]/80 text-[#A3950F] border-[#F2E28D]",
};

/*

MOBILE VERSION

<>
  <div
    onClick={() => {
      globalModalStore.openModal(
        {
          platform: "mobile",
          modalType: "OpportunityInfoMobile",
          navState: {
            mobileInfo: { title: title, info: value },
          },
        },
        2
      );
    }}
    className={cn(
      `my-1 mr-2 flex w-fit cursor-pointer items-center justify-center rounded-md border p-1.5 hover:bg-neutral-100`,
      className
    )}
  >
    <Text
      text={value}
      weight={"light"}
      type={isBigTablet ? "body" : "message"}
    />
  </div>
</>
*/
