import { useState } from "react";

import debounce from "lodash.debounce";

import { FlagCompanyInput, GetCompanyQuery, GetCompanyDocument } from "@/gql/graphql.ts";

import { useFlagCompanyMutation } from "@/app/service/opportunities.gql.ts";

import { apolloClient } from "../initConfig";
import { authStore } from "../stores/auth.store";

export type FlagType = "sharing_allowed" | "6_month_runway";

export interface FlagMutationParams {
  defaultSuccessMessage?: string;
  onSuccess?: () => void;
}

export interface Payload extends FlagCompanyInput {
  flag: FlagType;
}

export const useFlagMutation = ({ onSuccess }: FlagMutationParams) => {
  const [loading, setLoading] = useState(false);
  const [flagMutation, { error }] = useFlagCompanyMutation();

  const onSubmit = async (input: Payload) => {
    let cachedCompany: GetCompanyQuery["lgCompany"] = null;
    try {
      setLoading(true);

      const res = apolloClient.readQuery({
        query: GetCompanyDocument,
        variables: {
          id: input.companyId,
        },
      });

      if (res?.lgCompany) {
        cachedCompany = res.lgCompany;
        const timestamp = new Date().toISOString();
        const updatedCache = {
          ...cachedCompany,
          companyFlags: {
            items: input.toggle
              ? [
                  ...cachedCompany.companyFlags.items,
                  {
                    flag: input.flag,
                    createdAt: timestamp,
                    updatedAt: timestamp,
                    companyId: input.companyId,
                    createdBy: authStore.userId,
                  },
                ]
              : [...cachedCompany.companyFlags.items.filter((item) => item.flag !== input.flag)],
          },
        };

        apolloClient.writeQuery({
          query: GetCompanyDocument,
          data: { lgCompany: updatedCache },
          variables: {
            id: input.companyId,
          },
        });
      }

      const response = await flagMutation({
        variables: {
          input,
        },
        refetchQueries: ["GetCompanies", "GetCompany", "SentimentQueryGet"],
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
      });

      if (onSuccess) await onSuccess();

      return response;
    } catch (ex) {
      apolloClient.writeQuery({
        query: GetCompanyDocument,
        data: { lgCompany: cachedCompany },
        variables: {
          id: input.companyId,
        },
      });

      throw ex;
    } finally {
      setLoading(false);
    }
  };
  return { onSubmit: debounce(onSubmit, 1000), loading, error };
};
