import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpsertMetricsExtractionMutationVariables = Types.Exact<{
  lgCompanyMetricsInput?: Types.InputMaybe<Types.LgCompanyMetricsInput>;
}>;

export type UpsertMetricsExtractionMutation = { __typename?: "Mutation"; upsertMetricsExtraction?: any | null };

export type CompanyDetailsMetricsQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type CompanyDetailsMetricsQuery = {
  __typename?: "Query";
  lgCompany?: {
    __typename?: "LgCompany";
    id: any;
    name: string;
    image: string;
    websiteUrl?: string | null;
    srcCompaniesByCompanyId: {
      __typename?: "SrcCompaniesConnection";
      nodes: Array<{
        __typename?: "SrcCompany";
        trelloCardAsSource?: {
          __typename?: "TrelloCard";
          name: string;
          list?: { __typename?: "TrelloList"; name: string } | null;
        } | null;
      }>;
    };
  } | null;
};

export const UpsertMetricsExtractionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsertMetricsExtraction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lgCompanyMetricsInput" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyMetricsInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertMetricsExtraction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "lgCompanyMetricsInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "lgCompanyMetricsInput" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpsertMetricsExtractionMutationFn = Apollo.MutationFunction<
  UpsertMetricsExtractionMutation,
  UpsertMetricsExtractionMutationVariables
>;

/**
 * __useUpsertMetricsExtractionMutation__
 *
 * To run a mutation, you first call `useUpsertMetricsExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMetricsExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMetricsExtractionMutation, { data, loading, error }] = useUpsertMetricsExtractionMutation({
 *   variables: {
 *      lgCompanyMetricsInput: // value for 'lgCompanyMetricsInput'
 *   },
 * });
 */
export function useUpsertMetricsExtractionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertMetricsExtractionMutation, UpsertMetricsExtractionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertMetricsExtractionMutation, UpsertMetricsExtractionMutationVariables>(
    UpsertMetricsExtractionDocument,
    options,
  );
}
export type UpsertMetricsExtractionMutationHookResult = ReturnType<typeof useUpsertMetricsExtractionMutation>;
export type UpsertMetricsExtractionMutationResult = Apollo.MutationResult<UpsertMetricsExtractionMutation>;
export type UpsertMetricsExtractionMutationOptions = Apollo.BaseMutationOptions<
  UpsertMetricsExtractionMutation,
  UpsertMetricsExtractionMutationVariables
>;
export const CompanyDetailsMetricsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "companyDetailsMetrics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trelloCardAsSource" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "list" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCompanyDetailsMetricsQuery__
 *
 * To run a query within a React component, call `useCompanyDetailsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailsMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyDetailsMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables> &
    ({ variables: CompanyDetailsMetricsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>(
    CompanyDetailsMetricsDocument,
    options,
  );
}
export function useCompanyDetailsMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>(
    CompanyDetailsMetricsDocument,
    options,
  );
}
export function useCompanyDetailsMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>(
    CompanyDetailsMetricsDocument,
    options,
  );
}
export type CompanyDetailsMetricsQueryHookResult = ReturnType<typeof useCompanyDetailsMetricsQuery>;
export type CompanyDetailsMetricsLazyQueryHookResult = ReturnType<typeof useCompanyDetailsMetricsLazyQuery>;
export type CompanyDetailsMetricsSuspenseQueryHookResult = ReturnType<typeof useCompanyDetailsMetricsSuspenseQuery>;
export type CompanyDetailsMetricsQueryResult = Apollo.QueryResult<
  CompanyDetailsMetricsQuery,
  CompanyDetailsMetricsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
