import { cn } from "@/lib/utils.ts";
import { Text } from "@/app/components";
import { useBreakpoints } from "@/lib/hooks";
import modalStore from "@/app/stores/modal.store";
import { SentimentAttributeModal } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-attribute-modal.tsx";
import { Button } from "@/app/components";

export type SentimentAttribute =
  | "excitement_opinion_scale"
  | "vision_opinion_scale"
  | "market_opinion_scale"
  | "product_opinion_scale"
  | "team_opinion_scale"
  | "fundraising_opinion_scale"
  | "timing_opinion_scale"
  | "fit_opinion_scale"
  | "opportunity_opinion_scale";

export const sentimentAttributes: {
  value: SentimentAttribute;
  label: string;
}[] = [
  { label: "Excitement", value: "excitement_opinion_scale" },
  { label: "Vision", value: "vision_opinion_scale" },
  { label: "Market", value: "market_opinion_scale" },
  { label: "Product", value: "product_opinion_scale" },
  { label: "Team", value: "team_opinion_scale" },
  { label: "Fundraising", value: "fundraising_opinion_scale" },
  { label: "Timing", value: "timing_opinion_scale" },
  { label: "Fit", value: "fit_opinion_scale" },
  { label: "Opportunity", value: "opportunity_opinion_scale" },
  // { label: "Expected Return", value: "expected_return" }, // todo disabled in LOC-871 until implemented
];

export type SentimentFieldSelectorProps = {
  value: SentimentAttribute;
  onChange: (value: SentimentAttribute) => void;
};

export const SentimentFieldSelector = ({ onChange, value: selectedValue }: SentimentFieldSelectorProps) => {
  const { isLaptop } = useBreakpoints();

  function getLabelForValue(value: SentimentAttribute): string | undefined {
    const attribute = sentimentAttributes.find((attr) => attr.value === value);
    return attribute?.label;
  }

  if (!isLaptop) {
    return (
      <div>
        <Button
          onClick={() =>
            modalStore.open(SentimentAttributeModal, { props: { options: sentimentAttributes, onChange: onChange } })
          }
          variant={"tertiary"}
          size={"sm"}
          className={"rounded-sm bg-neutral-100 text-[14px] font-[500] text-[#464646] h-9"}
        >
          {getLabelForValue(selectedValue)}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn("mt-2 flex items-center gap-2")}>
      <Text text={"MetricsExtracted:"} color={"text-neutral-600"} className={"ml-2 select-none"} />
      {sentimentAttributes.map(({ label, value }) => (
        <Text
          key={label}
          text={label}
          color={"text-neutral-800"}
          weight={"medium"}
          className={cn(
            "h-full w-fit cursor-pointer select-none rounded-sm px-2.5 py-2",
            value === selectedValue && "bg-neutral-100 hover:text-neutral-800",
          )}
          onClick={() => onChange(value)}
        />
      ))}
    </div>
  );
};
