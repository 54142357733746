import React from "react";

import { useBreakpoints } from "@/lib/hooks";

import { Step, Stepper } from "@/app/components/steps";

import { useAddCompanyContext } from "../add-company-new-context.tsx";
import { StepDefinition } from "../hooks/use-add-company-steps.ts";

import { AddCompanyActionDef, AddCompanyStepActions } from "./add-company-step-actions.tsx";

const ProgressSteps = ({ steps, activeStep }: { steps: StepDefinition[]; activeStep: StepDefinition }) => {
  const activeStepIndex = activeStep.hidden
    ? steps.findIndex((step) => step.id === activeStep.id) - 1 // take previous step if the current one is hidden
    : steps.filter((step) => !step.hidden).findIndex((step) => step.id === activeStep.id);

  return (
    <Stepper active={activeStepIndex > 0 ? activeStepIndex : 0} containerClassName="px-10 py-3 border-b">
      {steps
        .filter((step) => !step.hidden)
        .map(({ label }) => (
          <Step label={label} key={label} />
        ))}
    </Stepper>
  );
};

export const AddCompanyWizard = () => {
  const { steps, submit, isSubmitting } = useAddCompanyContext();
  const { isBigTablet } = useBreakpoints();

  if (!steps) {
    return null;
  }

  const { steps: stepsDef, activeStep, next, back } = steps;

  const actions: AddCompanyActionDef[] = [
    {
      label: "Back",
      disabled: steps.isFirstStep,
      action: back,
      buttonProps: {
        variant: "outline",
      },
    },
    {
      label: "Next",
      hidden: steps.isLastStep,
      disabled: !activeStep.form.formState.isValid,
      action: next,
      buttonProps: {
        variant: "gradient",
      },
    },
    {
      label: "Submit",
      hidden: !steps.isLastStep,
      disabled: !activeStep.form.formState.isValid,
      action: submit,
      loading: isSubmitting,
      buttonProps: {
        variant: "gradient",
      },
    },
  ];

  return (
    <div className="flex size-full flex-col">
      {isBigTablet && <ProgressSteps steps={stepsDef} activeStep={activeStep} />}
      <h1 className="text-center text-xl font-semibold lg:mt-4">{activeStep.label}</h1>
      <div className="mt-7 flex-1 lg:px-10">
        <activeStep.Component form={activeStep.form} />
      </div>
      <AddCompanyStepActions actions={actions} />
    </div>
  );
};
