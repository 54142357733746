import { cn } from "@/lib/utils";

export const EmptyState = ({
  className,
  message,
}: {
  className?: string;
  message?: string;
}) => {
  return <p className={cn(className)}>{message || "No data available"}</p>;
};
