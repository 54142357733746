import { createContext, useContext } from "react";

import posthog from "posthog-js";

import usePersistState from "@/lib/hooks/usePersistState";
import { REFRESH_TOKEN_EXPIRY_LOCAL_KEY, TOKEN_EXPIRY_LOCAL_KEY } from "@/lib/service/auth.ts";

import type { User } from "@/app/stores/auth.store.tsx";

const AuthContext = createContext<AuthContextValue>(null as any);

export type AuthContextValue = {
  user: User;
  setUser: (user: User) => void;

  logout: () => void;
  loaded: boolean;

  tokenExpiration: string;
  setTokenExpiration: (arg0: string) => void;

  refreshTokenExpiration: string;
  setRefreshTokenExpiration: (arg0: string) => void;
};

const AuthProvider = ({ children }) => {
  const [user, setUser, userLoaded] = usePersistState("userAuth", {});
  const [tokenExpiration, setTokenExpiration] = usePersistState(TOKEN_EXPIRY_LOCAL_KEY, null);
  const [refreshTokenExpiration, setRefreshTokenExpiration] = usePersistState(REFRESH_TOKEN_EXPIRY_LOCAL_KEY, null);

  const loaded = userLoaded;

  const value: AuthContextValue = {
    user,
    setUser,
    logout: () => {
      setUser({});
      setTokenExpiration(null);
      setRefreshTokenExpiration(null);
      posthog?.reset();
    },
    loaded,
    tokenExpiration,
    setTokenExpiration,
    refreshTokenExpiration,
    setRefreshTokenExpiration,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error("Must be used with a AuthProvider");

  return context;
}

export { AuthProvider, useAuthContext };
