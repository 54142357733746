import { action, makeObservable, observable } from "mobx";
import { isHydrated, hydrateStore, makePersistable } from "mobx-persist-store";

export const investmentFunds = [
  { label: "LG12", value: "lg12" },
  { label: "LG11", value: "lg11" },
  { label: "LGX", value: "lgx" },
  { label: "LG8", value: "lg8" },
  { label: "LG7", value: "lg7" },
  { label: "LT3", value: "lt3" },
  { label: "LT2", value: "lt2" },
  { label: "LT1", value: "lt1" },
  { label: "SF1", value: "sf1" },
  { label: "BC", value: "BC" },
];

class InvestmentsStore {
  public isHydrated = false;
  public state = {
    activeFund: { label: "LG12", value: "lg12" },
    expanded: {
      lg12: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lg11: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lgx: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lg8: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lg7: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lt3: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lt2: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      lt1: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      sf1: { Focus: false, Emerging: false, Radar: false, Sectors: false },
      BC: { Focus: false, Emerging: false, Radar: false, Sectors: false },
    },
  };

  constructor() {
    makeObservable(this, { state: observable, setState: action });
    makePersistable(
      this,
      {
        stringify: true,
        debugMode: import.meta.env.VITE_MOBX_DEBUG === "true",
        name: "investmentsStore",
        storage: window.localStorage,
        properties: ["state"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration();
  }
  async waitForHydration() {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
  }

  public setState(newState: Partial<typeof this.state>) {
    this.state = { ...this.state, ...newState };
  }
}

export default new InvestmentsStore();
