import React from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, Input, TextArea, Toggle } from "@/app/components";
import Form from "@/app/components/forms";
import { InputWithPills } from "@/app/screens/modal/components";
import { navigateToSelect } from "@/app/screens/modal/opportunities/components/add-opportunity-misc.ts";
import { LocationInput } from "@/app/screens/opportunities/components";

export const CompanyDetailsMobile = () => {
  return (
    <div className={"grid-row grid w-full"}>
      <Form.Field
        fieldId="location"
        render={({ onChange, value }) => (
          <>
            <Form.Label />
            <LocationInput
              iconRight={null}
              defaultValue={
                value && value.country
                  ? {
                      value: `${value.city ? value.city + ", " : ""}${value.country}`,
                      label: `${value.city ? value.city + ", " : ""}${value.country}`,
                    }
                  : undefined
              }
              onChange={(val) => {
                onChange(val);
              }}
            />
            <Form.Error />
          </>
        )}
      />
      <Form.Field
        fieldId="about"
        render={({ onChange, value }) => (
          <>
            <Form.Label className="mt-2" />
            <TextArea
              placeholder={"Add company description here..."}
              onChange={onChange}
              value={value || ""}
              className="mt-0"
            />
            <Form.Error />
          </>
        )}
      />
      <Form.Field
        fieldId="website_url"
        render={({ onChange, value }) => (
          <>
            <Form.Label className="mt-2" />
            <Input
              value={value}
              className={"h-10 py-1"}
              placeholder={"www.domain.com"}
              onChange={({ target: { value } }) => onChange(value)}
            />
            <Form.Error />
          </>
        )}
      />
      <Form.Field
        fieldId={"industries"}
        render={({ onChange, value, fieldId }) => (
          <>
            <Form.Label className="mt-2" />
            <InputWithPills
              containerClassName={`px-2 py-1 h-10 bg-white`}
              selectedOption={value}
              setSelectedOption={onChange}
              inputPosition={"right"}
              onClick={() =>
                navigateToSelect(fieldId, "Industries", {
                  navStep: { step: "company_details", index: 3 },
                  selectedType: "opportunity",
                  isGeneral: false,
                })
              }
            >
              <div className="flex flex-1 items-center justify-end pr-1">
                <Icon
                  type={"Chevron Right"}
                  className={"size-4 text-primary"}
                  onClick={() =>
                    navigateToSelect(fieldId, "Industries", {
                      navStep: { step: "company_details", index: 3 },
                      selectedType: "opportunity",
                      isGeneral: false,
                    })
                  }
                />
              </div>
            </InputWithPills>
            <Form.Error />
          </>
        )}
      />
      <Form.Field
        fieldId="target_fund"
        render={({ onChange, value, fieldId }) => (
          <>
            <Form.Label className="mt-2" />
            <InputWithPills
              containerClassName={`px-2 py-1 h-10 bg-white relative`}
              selectedOption={value}
              setSelectedOption={onChange}
              inputPosition={"right"}
              onClick={() =>
                navigateToSelect(fieldId, "Target Fund", {
                  navStep: { step: "company_details", index: 3 },
                  selectedType: "opportunity",
                  isGeneral: false,
                })
              }
            >
              <div className="flex flex-1 items-center justify-end pr-1">
                <Icon
                  type={"Chevron Right"}
                  className={"size-4 text-primary"}
                  onClick={() =>
                    navigateToSelect(fieldId, "Target Round", {
                      navStep: { step: "company_details", index: 3 },
                      selectedType: "opportunity",
                      isGeneral: false,
                    })
                  }
                />
              </div>
            </InputWithPills>
            <Form.Error />
          </>
        )}
      />
      <Form.Field
        fieldId={"round"}
        render={({ onChange, value, fieldId }) => (
          <>
            <Form.Label className="mt-2" />
            <InputWithPills
              containerClassName={`px-2 py-1 h-10 bg-white relative`}
              selectedOption={value}
              setSelectedOption={onChange}
              inputPosition={"right"}
              onClick={() =>
                navigateToSelect(fieldId, "Last Round Raised", {
                  navStep: { step: "company_details", index: 3 },
                  selectedType: "opportunity",
                  isGeneral: false,
                })
              }
            >
              <div className="flex flex-1 items-center justify-end pr-1">
                <Icon
                  type={"Chevron Right"}
                  className={"size-4 text-primary"}
                  onClick={() =>
                    navigateToSelect(fieldId, "Last Round Raised", {
                      navStep: { step: "company_details", index: 3 },
                      selectedType: "opportunity",
                      isGeneral: false,
                    })
                  }
                />
              </div>
            </InputWithPills>
            <Form.Error />
          </>
        )}
      />
    </div>
  );
};

export const CompanySignalMobile = () => {
  return (
    <div className={cn("w-full space-y-4")}>
      <div className={"flex items-center justify-between"}>
        <Form.Field
          fieldId="source"
          render={({ onChange, value }) => (
            <Input
              label={"Heard about it from"}
              containerClassName={"flex w-full"}
              value={value}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        />
      </div>
      <div className={"grid-row grid gap-5"}>
        <Form.Field
          fieldId={"intro_available"}
          render={({ onChange, value }) => <Toggle rightLabel={"Intro Available"} value={value} setValue={onChange} />}
        />
        {Object.entries({
          is_actively_raising: "Actively Raising",
          high_priority: "High Priority",
        }).map(([key, label]) => (
          <Form.Field
            key={key}
            fieldId={key}
            render={({ onChange, value }) => <Toggle rightLabel={label} value={value} setValue={onChange} />}
          />
        ))}
      </div>
      <Form.Field
        fieldId={"assignees"}
        render={({ onChange, value, fieldId }) => (
          <div className="flex flex-col space-y-2">
            <Form.Label />
            <InputWithPills
              containerClassName={`px-2 py-1 h-10 bg-white relative`}
              selectedOption={value}
              setSelectedOption={onChange}
              inputPosition={"right"}
              onClick={() =>
                navigateToSelect(fieldId, "Members", {
                  navStep: {
                    step: "signal",
                    index: 5,
                  },
                })
              }
            >
              <div className="flex flex-1 items-center justify-end pr-1">
                <Icon
                  type={"Chevron Right"}
                  className={"size-4 text-primary"}
                  onClick={() =>
                    navigateToSelect(fieldId, "Members", {
                      navStep: {
                        step: "signal",
                        index: 5,
                      },
                    })
                  }
                />
              </div>
            </InputWithPills>
            <Form.Error />
          </div>
        )}
      />
      <Form.Field
        fieldId="assignees_notes"
        render={({ onChange, value }) => (
          <TextArea
            className={"w-full"}
            label={"Share a note with assignees"}
            placeholder={"Add context on the opportunity…"}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Form.Field
        fieldId={"auto_accept"}
        render={({ onChange, value }) => (
          <Toggle rightLabel={"Auto-Accept (Add to Trello)"} value={value} setValue={onChange} />
        )}
      />
    </div>
  );
};
