import React from "react";

import { Controller } from "react-hook-form";

import { WebSelect } from "@/app/components/input/web-select.tsx";
import { FormField } from "@/app/screens/opportunities/add-new/shared/components/form-field.tsx";
import { InvestmentTypeField } from "@/app/screens/opportunities/add-new/shared/components/investment-type-field.tsx";
import { CompanyAdditionalDetailsFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-additional-details-form.ts";
import { industries, stages, targetFund } from "@/data/data.tsx";

import { CompanyStepComponentProps } from "../../add-company-new.types.ts";

export const CompanyAdditionalDetailsStep = ({ form }: CompanyStepComponentProps<CompanyAdditionalDetailsFormData>) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="industries"
          control={control}
          render={({ field, fieldState }) => (
            <FormField label="Industries" required errorMessage={fieldState.error?.message}>
              <WebSelect
                iconLeft={"LoopGradient"}
                optionsDirection={"bottom"}
                options={industries}
                placeholder="Please select..."
                multiSelect
                required={true}
                defaultOptions={field.value?.map((val) => ({ label: val, value: val }))}
                onChange={(values) => {
                  field.onChange(values.map((val) => val.value));
                }}
              />
            </FormField>
          )}
        ></Controller>

        <Controller
          name="round"
          control={control}
          render={({ field, fieldState }) => (
            <FormField label="Round" required errorMessage={fieldState.error?.message}>
              <WebSelect
                iconLeft={"LoopGradient"}
                optionsDirection={"bottom"}
                options={stages}
                defaultOptions={stages.filter((option) => option.value === field.value)}
                placeholder="Please select..."
                required={true}
                onChange={(value) => field.onChange(value?.[0]?.value)}
              />
            </FormField>
          )}
        ></Controller>

        <Controller
          name="fund"
          control={control}
          render={({ field, fieldState }) => (
            <FormField label="Target Fund" required errorMessage={fieldState.error?.message}>
              <WebSelect
                iconLeft={"LoopGradient"}
                optionsDirection={"bottom"}
                options={targetFund}
                placeholder="Please select..."
                required={true}
                defaultOptions={targetFund.filter((fund) => fund.value === field.value)}
                onChange={(value) => field.onChange(value?.[0]?.value)}
              />
            </FormField>
          )}
        ></Controller>

        <InvestmentTypeField name="investmentType" control={control} />
      </form>
    </div>
  );
};
