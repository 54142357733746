import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

export const CompanyAttributesSchema = z.object({
  source: z.object({
    name: z.string().min(1, "Source name is required"),
    type: z.string().min(1, "Source type is required"),
  }),
  attributes: z
    .object({
      introAvailable: z.boolean(),
      activelyRaising: z.boolean(),
      highPriority: z.boolean(),
      ballInOurControl: z.boolean(),
    })
    .required(),
  assignees: z
    .object({
      people: z
        .array(
          z.object({
            id: z.string(),
            value: z.string(),
            label: z.string(),
            image: z.string().optional().nullable(),
            initials: z.string().optional().nullable(),
          }),
        )
        .nonempty("Set at least one person to assign this"),
      note: z.string(),
    })
    .required(),
});

export type CompanyAttributesFormData = z.infer<typeof CompanyAttributesSchema>;

export const useCompanyAttributesForm = () => {
  return useForm<CompanyAttributesFormData>({
    resolver: zodResolver(CompanyAttributesSchema),

    defaultValues: {
      source: {
        type: "",
        name: "",
      },
      attributes: {
        introAvailable: undefined,
        activelyRaising: undefined,
        highPriority: undefined,
        ballInOurControl: undefined,
      },
      assignees: {
        people: [],
        note: "",
      },
    },
  });
};
