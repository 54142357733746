import React, { useMemo } from "react";

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { LgCompany } from "@/gql/graphql.ts";

import { Fund } from "@/app/hooks";
import {
  CompanyCellWrapper,
  isFooterRow,
  mapInvestmentColumnToLabel,
  mapInvestmentColumnToValue,
  WithSubRows,
} from "@/app/screens/investments/components";
import {
  sortCompanyFn,
  sortFundFn,
  sortRunway,
} from "@/app/screens/investments/components/funds-table/funds-table-utils.ts";

const fundColumns: Array<any> = [
  "company",
  "fundName",
  "investmentType",
  "totalInvestedCapital",
  "unrealisedValue",
  "currentMultiple",
  "lastRound",
  "currentEv",
  "evAsFundReturner",
  "goingInOwnership",
  "currentOwned",
  "initialEv",
  "revenue",
  "grossMargins",
  "latestExcitement",
  "cashoutGroup",
];

const defaultSort = (key: string) => (rowA, rowB) => {
  if (rowA.parentId || rowB.parentId) {
    return 0;
  }
  return rowA.original[key] - rowB.original[key];
};

const columnHelper = createColumnHelper<WithSubRows<Fund>>();

export const useFundsTableColumns = (aggregated) => {
  return useMemo(() => {
    return fundColumns.map((key) =>
      key === "company"
        ? columnHelper.accessor(key, {
            header: mapInvestmentColumnToLabel(key),
            sortingFn: sortCompanyFn,
            minSize: 200,
            maxSize: 250,
            cell: ({ getValue, row }) =>
              mapInvestmentColumnToValue({
                column: key,
                value: getValue(),
                row,
              }),
          })
        : key === "fundName"
          ? columnHelper.accessor(key, {
              header: mapInvestmentColumnToLabel(key),
              size: 60,
              sortingFn: sortFundFn,
              cell: ({ getValue, row }) =>
                mapInvestmentColumnToValue({
                  column: key,
                  value: getValue(),
                  row,
                }),
              footer: ({ column }) =>
                mapInvestmentColumnToValue({
                  column: key,
                  value: aggregated[column.id],
                  className: "xxl:text-lg sm:text-neutral-400",
                }),
            })
          : key === "cashoutGroup"
            ? columnHelper.accessor(key, {
                header: mapInvestmentColumnToLabel(key),
                sortingFn: sortRunway,
                minSize: 125,
                cell: ({ getValue, row }) =>
                  mapInvestmentColumnToValue({
                    column: key,
                    value: getValue(),
                    row,
                  }),
                footer: ({ column }) =>
                  mapInvestmentColumnToValue({
                    column: key,
                    value: aggregated[column.id],
                    className: "xxl:text-lg sm:text-neutral-400",
                  }),
              })
            : columnHelper.accessor(key, {
                header: mapInvestmentColumnToLabel(key),
                size: 120,
                cell: ({ getValue, row }) =>
                  mapInvestmentColumnToValue({
                    column: key,
                    value: getValue(),
                    row,
                  }),
                sortingFn: defaultSort(key),
                footer: ({ column }) =>
                  mapInvestmentColumnToValue({
                    column: key,
                    value: aggregated[column.id],
                    className: `sm:text-neutral-400 ${
                      ["currentEv", "totalInvestedCapital", "unrealisedValue"].includes(key) ? "min-w-[120px]" : ""
                    }`,
                  }),
              }),
    );
  }, [aggregated]);
};
