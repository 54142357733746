import { ResponsiveModal } from "@/app/components";
import { ConvertPortfolioParams, usePortfolioForm } from "./hooks"
import modalStore from "@/app/stores/modal.store";
import { ActionButtons, ActionModalHeader } from "../components";

export const ConvertToPortfolio = (props: ConvertPortfolioParams) => {
    const { render, handleSubmit, loading } = usePortfolioForm(props);

    return (
        <ResponsiveModal
            isOpen
            handleClose={modalStore.close}
            header={<ActionModalHeader title="Convert to Portfolio" />}
            footer={(
                <ActionButtons
                    primaryBtnTitle="Convert to Portfolio"
                    secondaryBtnTitle="Cancel"
                    primaryBtnVariant="gradient"
                    handlePrimaryAction={handleSubmit}
                    handleCancel={modalStore.close}
                    isLoading={loading}
                />
            )}
        >
            {render}
        </ResponsiveModal>
    )
}