import { ChangeIndicator } from "../change-indicator/change-indicator";

export const ScoreChange = ({ current, previous }) => {
  const diff = current - previous;
  let value = {};

  if (diff > 0) {
    value = { increase: diff };
  }

  if (diff < 0) {
    value = { decrease: diff * -1 };
  }

  return <ChangeIndicator value={value} options={{ container: { className: "gap-2" } }} />;
};
