import { useState } from "react";

import { cn, parseUrl } from "@/lib/utils";

import { Icon } from "../icon";

export interface CompanyImgProps {
  src?: string | null;
  className?: string;
  imgClassName?: string;
  onClick?: (...args) => void;
  size?: number;
}

export const CompanyImg = ({ src, className, imgClassName, size = 8, onClick }: CompanyImgProps) => {
  const [failedToLoad, setFailed] = useState(false);
  const url = parseUrl(src);

  return (
    <div
      className={cn(
        "flex items-center overflow-hidden p-0",
        url ? "rounded-xs border border-neutral-200" : "",
        `size-${size}`,
        className,
      )}
      onClick={onClick}
    >
      {url && !failedToLoad ? (
        <img className={cn("w-full", imgClassName)} src={url} onError={() => setFailed(true)} />
      ) : (
        <Icon type="OpportunityPlaceholder" className={cn("w-full", imgClassName)} />
      )}
    </div>
  );
};
