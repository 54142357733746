import { RefObject, useState } from "react";

import { useDebounceCallback, useResizeObserver } from "usehooks-ts";

export const useResponsiveItemLimit = (
  parentRef: RefObject<HTMLDivElement>,
  itemRefs: RefObject<(HTMLDivElement | null)[]>,
) => {
  const [hiddenItemIndex, setHiddenItemIndex] = useState<number>(-1);
  const [hiddenItemLeftOffset, setHiddenItemLeftOffset] = useState<number>();

  const onResize = useDebounceCallback(() => {
    const parentElBounds = parentRef.current?.getBoundingClientRect();
    if (parentElBounds && itemRefs.current) {
      const itemIndex = itemRefs.current.findIndex((item) => {
        if (!item) {
          return false;
        }

        const itemBounds = item.getBoundingClientRect();

        if (itemBounds.right + 32 > parentElBounds.right) {
          return true;
        }
      });

      setHiddenItemIndex(itemIndex);
      setHiddenItemLeftOffset(itemRefs.current[itemIndex]?.offsetLeft);
    }
  }, 200);

  useResizeObserver({
    ref: parentRef,
    onResize,
  });

  return { hiddenItemIndex, hiddenItemLeftOffset };
};
