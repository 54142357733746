import { AccessOverview } from "../access-overview";

import { CardBox } from "./card-box";

export const CardAccess = ({ assignees, basecamp, previouslyMet, isPortco }) => {
  const isEmpty = assignees.length === 0 && !basecamp;

  return (
    <CardBox
      title="Access"
      containerClassName="w-full col-span-4 sm:col-span-3 md:pl-16 "
      titleClassName="2xl:mb-3 lg:mb-1.5"
      isSad={isEmpty && (previouslyMet || isPortco)}
    >
      <AccessOverview assignees={assignees} basecamp={basecamp} isPortco={isPortco} />
    </CardBox>
  );
};
