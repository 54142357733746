import _ from "lodash";
import {
  ValidationPluginConstructor,
  ValidationPluginInterface,
} from "mobx-react-form/lib/models/ValidatorInterface";

class ZOD implements ValidationPluginInterface {
  promises = [];

  config = null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state = null;

  extend = null;

  validator = null;

  schema = null;

  constructor({
    config = {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state = null,
    promises = [],
  }: ValidationPluginConstructor) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.state = state;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.promises = promises;
    this.extend = config.extend;
    this.validator = config.package || config;
    this.schema = config.schema;
    this.extendValidator();
  }

  extendValidator(): void {
    // extend using "extend" callback
    if (typeof this.extend === "function") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.extend({
        validator: this.validator,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form: this.state.form,
      });
    }
  }

  validate(field): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = this.schema.safeParse(field.state.form.validatedValues);

    if (result.success) return;

    const errors = _.get(result.error.format(), field.path)?._errors || [];

    if (errors.length) field.validationErrorStack = errors;
  }
}

export default (config?: any) => ({
  class: ZOD,
  config,
});
