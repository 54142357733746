import { observer } from "mobx-react";

import { useFundsQuery } from "@/app/hooks";
import { FundsTableNew } from "@/app/screens/investments/components/funds-table/funds-table-new.tsx";

import { SubHeader } from "../opportunities/components";

export const InvestmentDashboard = observer(() => {
  const { fundList, filters } = useFundsQuery();
  const heightDiff = Object.keys(filters || {}).length > 0 ? "126px" : "76px";

  return (
    <section className={"flex h-[100dvh] w-full flex-1 grow flex-col items-center overflow-hidden bg-background"}>
      <SubHeader
        containerClass={"fixed sticky top-0 z-30 hidden bg-background bg-opacity-70 backdrop-blur"}
        canSort={false}
      />
      <div className={"w-full p-2 sm:px-4 lg:px-2 lg:py-0"} style={{ height: `calc(100% - ${heightDiff})` }}>
        <FundsTableNew funds={fundList} />
      </div>
    </section>
  );
});
