import { PropsWithChildren } from "react";

export const Row = ({
  children,
  className,
  onClick,
}: PropsWithChildren<{
  className?: string;
  onClick?: () => void;
}>) => {
  return (
    <tr className={className} onClick={onClick}>
      {children}
    </tr>
  );
};
