import React from "react";

import { cn } from "@/lib/utils";

import { ProgressSpinner } from "@/app/components/progress-spinner.tsx";

export const LoadingOverLay = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        "absolute z-10 flex size-full items-center justify-center rounded-lg bg-white/90 backdrop-blur-sm",
        className,
      )}
    >
      <ProgressSpinner />
    </div>
  );
};
