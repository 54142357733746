import { useEffect } from "react";

import { differenceInDays, format } from "date-fns";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { NzrNotificationsOrderBy } from "@/gql/graphql.ts";

import { useBreakpoints } from "@/lib/hooks";
import type { SentimentForm } from "@/lib/service/sentiment.types.ts";
import { cn } from "@/lib/utils.ts";

import { Button, Icon, ImageWithErrorFallback, Lottie, Text } from "@/app/components";
import { Routes } from "@/app/constants";
import { EmailFeedback } from "@/app/screens/modal";
import { notificationStore } from "@/app/screens/notifications";
import { NotificationIcon } from "@/app/screens/notifications/components/notification-icon.tsx";
import { NotificationTypes } from "@/app/screens/notifications/notification.store.ts";
import {
  useGetNotificationsSuspenseQuery,
  useSetNotificationStatusMutation,
} from "@/app/screens/notifications/notifications.gql.ts";
import { Signal } from "@/app/screens/opportunities/components";
import { useDeclineSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import modalStore from "@/app/stores/modal.store";

import { CompleteSentimentModalResponsive } from "../../modal/complete-sentiment";

interface NotificationCardProps {
  containerClassName?: string;
  selectedTypes: string[];
}

const formatDate = (date?: string | number | Date) => format(new Date(date || new Date()), "d-MM-yyyy");

const rulesForDate = (noOfDays: string, ack?: boolean): any => {
  if (Number(noOfDays) <= 3 || ack) return { color: "text-neutral", type: "normal", emoji: "" };
  if (Number(noOfDays) <= 7) return { type: "medium", color: "text-yellow", emoji: "⏳" };
  return { type: "high", color: "text-red", emoji: "🔥" };
};

const notificationTypes = [
  "metrics_extraction_email",
  "sentiment_request_chaser",
  "sentiment_request",
  "new_opportunity",
  "shared_opportunity",
  "email_opportunity",
  "feedback_email",
];

const isActionable = (type: NotificationTypes, status: string) =>
  notificationTypes.includes(type) && status !== "COMPLETED" && status !== "DECLINED";

const Notification = ({ id, type, date, payload, status, idx, refetch }) => {
  const { isBigTablet } = useBreakpoints();
  const daysDifference = `${differenceInDays(new Date(), new Date(date!))}`;
  const navigate = useNavigate();
  const allIsRead = true;

  const [setNotificationStatus] = useSetNotificationStatusMutation();

  const [declineSentimentForm, { loading: declineLoading }] = useDeclineSentimentFormMutation({
    refetchQueries: ["GetNotifications"],
  });

  const ack = status === "SEEN" || status == "COMPLETED" || status === "DECLINED";

  return (
    <li
      className={cn(
        `${ack ? "bg-background" : "bg-white"} group flex flex-row justify-between px-2 py-3`,
        isActionable(type, status) ? "cursor-pointer" : "",
      )}
      onClick={() => {
        if (status === "COMPLETED" || status === "DECLINED") return;

        if (type === "feedback_email") {
          refetch();
          modalStore.open(EmailFeedback, { props: payload });
        }

        if (type === "sentiment_request") {
          modalStore.open(CompleteSentimentModalResponsive, {
            props: {
              label: "Complete Sentiment",
              id: payload.form.id,
              notificationId: id,
            },
          });
          return;
        }

        if (type === "email_opportunity") {
          navigate(`${Routes.inbox}/add-via-email/${payload.email.id}`, {
            state: { notificationId: id },
          });
          return;
        }

        if (type === "metrics_extraction_email") {
          navigate(`${Routes.metricsExtraction}/${payload.email.id}`);
          return;
        }

        setNotificationStatus({
          variables: { id, status: "SEEN" },
        });
        notificationStore.setStatus(id, "SEEN");

        if (type === "shared_opportunity") {
          navigate(`/company/${payload.company.id}`);
          return;
        }
      }}
      key={idx}
    >
      <div>
        <div id={"title"} className={"flex"}>
          <div className={"flex items-center justify-center lg:mb-5"}>
            {isBigTablet && (
              <div className={cn("bg-primary size-3 rounded-full border border-white", { "bg-white": ack })} />
            )}
            <NotificationIcon src={payload?.image} alt={"NotificationIcon"} status={status} />
          </div>
          <div className={"ml-2 flex w-full flex-col"}>
            <div className={"mb-1 flex w-full justify-between"}>
              <Text text={payload.title} noOfLines={1} type={isBigTablet ? "title" : "body"} />
              {date && (
                <Text
                  text={
                    daysDifference != "0"
                      ? `${rulesForDate(daysDifference, ack).emoji} ${daysDifference} days ago`
                      : "today"
                  }
                  className={"min-w-fit"}
                  noOfLines={1}
                  type={isBigTablet ? "body" : "small"}
                  weight={isBigTablet ? "light" : "normal"}
                  color={rulesForDate(daysDifference, ack).color}
                />
              )}
            </div>
            <div className={"flex"}>
              {type === "email_opportunity" && (
                <div className={"flex flex-col"}>
                  <div className={"flex items-center space-x-1.5"}>
                    <Icon type={"Mail"} className={"text-primary h-4 lg:h-5"} />
                    <Text
                      text={`Parsed on ${formatDate(date)}`}
                      color={"text-neutral-700"}
                      type={isBigTablet ? "body" : "message"}
                      noOfLines={1}
                    />
                  </div>
                </div>
              )}
              {(type === "new_opportunity" || type === "shared_opportunity") && (
                <div className={"flex items-center space-x-0.5 lg:space-x-1.5"}>
                  <ImageWithErrorFallback
                    src={payload.company?.image}
                    alt={""}
                    className={"text-primary size-4 rounded bg-neutral-100 lg:size-5"}
                  />
                  {payload?.company && (
                    <Text
                      text={payload.company?.name || ""}
                      type={isBigTablet ? "body" : "message"}
                      color={"text-neutral-700"}
                      weight={isBigTablet ? "light" : "normal"}
                    />
                  )}
                </div>
              )}
              {(type === "sentiment_request" || type === "sentiment_request_chaser") && (
                <div className={"flex w-full flex-wrap gap-0.5 space-y-0.5 lg:gap-1 lg:space-y-1"}>
                  {payload?.company && (
                    <div
                      className={"flex cursor-pointer items-center justify-center lg:space-x-1.5"}
                      onClick={() => navigate(`/company/${payload.company?.id}`)}
                    >
                      <ImageWithErrorFallback
                        src={payload.company?.image}
                        alt={""}
                        className={"text-primary mx-0.5 size-4 rounded bg-neutral-100 lg:mx-1 lg:size-5"}
                      />
                      <Text
                        text={payload.company?.name || ""}
                        type={isBigTablet ? "body" : "message"}
                        noOfLines={1}
                        color={"text-neutral-700"}
                        weight={isBigTablet ? "light" : "normal"}
                      />
                    </div>
                  )}
                  {payload?.founder && (
                    <div
                      className={"flex cursor-pointer items-center justify-center lg:space-x-1.5"}
                      onClick={() => navigate(`/company/${payload.company?.id}`)}
                    >
                      <Text
                        text={"•"}
                        type={isBigTablet ? "body" : "message"}
                        color={"text-primary"}
                        weight={isBigTablet ? "light" : "normal"}
                      />
                      <ImageWithErrorFallback
                        src={payload.founder?.image}
                        alt={""}
                        className={"text-primary mx-0.5 size-4 rounded bg-neutral-100 lg:mx-1 lg:size-5"}
                      />
                      <Text
                        text={payload.founder?.name || ""}
                        type={isBigTablet ? "body" : "message"}
                        noOfLines={1}
                        color={"text-neutral-700"}
                        weight={isBigTablet ? "light" : "normal"}
                      />
                    </div>
                  )}
                  {payload?.meeting && (
                    <div className={"flex items-center justify-center"}>
                      <Text
                        text={"•"}
                        type={isBigTablet ? "body" : "message"}
                        color={"text-primary"}
                        weight={isBigTablet ? "light" : "normal"}
                      />
                      <ImageWithErrorFallback
                        src={""}
                        icon={"Employees"}
                        alt={""}
                        className={"text-primary mx-0.5 size-4 rounded bg-neutral-100 lg:mx-1 lg:size-5"}
                      />
                      <Text
                        text={payload.meeting || ""}
                        noOfLines={1}
                        weight={isBigTablet ? "light" : "normal"}
                        type={isBigTablet ? "body" : "message"}
                        color={"text-neutral-700"}
                      />
                    </div>
                  )}
                  {payload?.date && (
                    <div className={"flex items-center justify-center"}>
                      <Text text={"•"} type={isBigTablet ? "body" : "message"} color={"text-primary"} />
                      <ImageWithErrorFallback
                        src={""}
                        icon={"Calendar"}
                        alt={""}
                        className={"text-primary mx-0.5 size-4 rounded bg-neutral-100 lg:mx-1 lg:size-5"}
                      />
                      <Text
                        text={`Happened on ${formatDate(payload.date)}`}
                        noOfLines={1}
                        color={"text-neutral-700"}
                        weight={isBigTablet ? "light" : "normal"}
                        type={isBigTablet ? "body" : "message"}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {!isBigTablet && (
              <div className={"mt-2 space-y-2"}>
                {!!payload?.notes && (
                  <div className={"flex h-4 items-center"}>
                    <Icon type={"NotificationNote"} className={"mr-1 h-4"} />
                    <Text text={payload?.notes} noOfLines={1} type={isBigTablet ? "body" : "message"} />
                  </div>
                )}
                {type === "email_opportunity" && (
                  <div>
                    <Text
                      text={`Subject: "${payload.email.subject}"`}
                      type={isBigTablet ? "body" : "message"}
                      noOfLines={1}
                    />
                    {payload.email.source && (
                      <Text
                        text={`Source: "${payload.email.source}"`}
                        type={isBigTablet ? "body" : "message"}
                        noOfLines={1}
                      />
                    )}
                  </div>
                )}
                {payload?.signal && (
                  <div className={"flex"}>
                    <Signal
                      signal={payload.signal}
                      containerClassName={"py-1 px-2"}
                      textClassName={"text-xs lg:text-md"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isBigTablet && (
          <div className={"my-2 space-y-2 lg:mx-5"}>
            {!!payload?.notes && (
              <div className={"flex h-4 items-center"}>
                <Icon type={"NotificationNote"} className={"mr-1 h-4"} />
                <Text text={payload?.notes} noOfLines={1} type={isBigTablet ? "body" : "message"} />
              </div>
            )}
            {type === "email_opportunity" && (
              <div>
                <Text
                  text={`Subject: "${payload.email.subject}"`}
                  type={isBigTablet ? "body" : "message"}
                  noOfLines={1}
                />
                {payload.email.source && (
                  <Text
                    text={`Source: "${payload.email.source}"`}
                    type={isBigTablet ? "body" : "message"}
                    noOfLines={1}
                  />
                )}
              </div>
            )}
            {payload?.signal && (
              <div className={"flex"}>
                <Signal signal={payload.signal} containerClassName={"py-1 px-2"} textClassName={"text-xs lg:text-md"} />
              </div>
            )}
          </div>
        )}
      </div>
      {(status === "COMPLETED" || status === "DECLINED") && (
        <div
          className={
            "text-primary active:text-primary-200 hidden cursor-pointer items-center justify-center space-x-1 pr-4 md:flex"
          }
        >
          <Text
            text={status === "COMPLETED" ? "Completed" : "Declined"}
            color={"text-primary"}
            weight={allIsRead ? "light" : "normal"}
            className={"duration-800 active:text-primary-300 transition ease-in-out"}
          />
          <Icon
            type={status === "COMPLETED" ? "Check" : "X"}
            className={cn("size-5", allIsRead ? "" : "bg-primary rounded-full p-0.5 text-white")}
          />
        </div>
      )}
      {type === "sentiment_request" && isActionable(type, status) && (
        <Button
          size="md"
          variant={"tertiary"}
          className={"hidden text-black group-hover:block"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            declineSentimentForm({
              variables: { input: { formId: payload.form.id } },
              onCompleted: () => {
                notificationStore.setStatus(id, "DECLINED");
              },
            });
          }}
          loading={declineLoading}
          text="Decline"
        />
      )}
    </li>
  );
};

export const NotificationsList = observer(({ containerClassName, selectedTypes }: NotificationCardProps) => {
  const { data, refetch } = useGetNotificationsSuspenseQuery({
    variables: {
      filter: {
        assignee: { equalTo: authStore.user?.id },
        ...(selectedTypes.length > 0 ? { type: { in: selectedTypes } } : {}),
      },
      orderBy: [NzrNotificationsOrderBy.LastUpdatedUtcDesc],
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    notificationStore.setNotification(data?.nzrNotifications?.edges as unknown as SentimentForm[]);
  }, [data]);

  return notificationStore.notificationsList.length ? (
    <div className={`shadow-xs mb-[10vh] flex flex-col rounded-md bg-white p-2 ${containerClassName}`}>
      <ul className={"w-full divide-y divide-neutral-100"}>
        {notificationStore.notificationsList.map(({ id, type, createdUtc, payload, status }: any, i) => (
          <Notification
            id={id}
            key={id}
            type={type}
            date={createdUtc}
            payload={payload}
            status={status}
            idx={i}
            refetch={() =>
              refetch({
                filter: {
                  assignee: { equalTo: authStore.user?.id },
                  ...(selectedTypes.length > 0 ? { type: { in: selectedTypes } } : {}),
                },
                orderBy: [NzrNotificationsOrderBy.LastUpdatedUtcDesc],
              })
            }
          />
        ))}
      </ul>
    </div>
  ) : (
    <div className={"flex h-[70dvh] grow items-center justify-center opacity-60"}>
      <Text className={"mr-[25%] size-1/4"} text={"No results to show"} />
    </div>
  );
});
