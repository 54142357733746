import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const CompanyAdditionalDetailsSchema = z.object({
  investmentType: z.enum(["core", "strategic"]),
  industries: z.array(z.string()).nonempty("Add at least one industry"),
  round: z.string().min(1, "Round is required"),
  fund: z.string().min(1, "Fund is required"),
});

export type CompanyAdditionalDetailsFormData = z.infer<typeof CompanyAdditionalDetailsSchema>;

export const useCompanyAdditionalDetailsForm = () => {
  return useForm<CompanyAdditionalDetailsFormData>({
    resolver: zodResolver(CompanyAdditionalDetailsSchema),
    mode: "onChange",
    defaultValues: {
      industries: [],
      round: "",
      fund: "",
    },
  });
};

export const updateCompanyAdditionalDetailsForm = (form: UseFormReturn<CompanyAdditionalDetailsFormData>, company) => {
  let industries = [];
  if (!!company && "industries" in company) {
    industries =
      company.industries
        ?.map(
          (
            industry:
              | string
              | {
                  name: string;
                },
          ) => (typeof industry === "string" ? industry : industry?.name),
        )
        .filter(Boolean) ?? [];
  }

  form.reset({
    industries,
  });
};
