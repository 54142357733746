import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Input } from "@/app/components";

import { CompaniesFromEmailForm } from "../use-add-company-from-email-form.ts";

export const MainCompanyDataSection = ({ index }: { index: number }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return (
    <Controller
      name={`companies.${index}.company.mainData.name`}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          placeholder={"Name"}
          label="Name"
          required
          error={fieldState.error?.message}
          containerClassName="relative"
          errorClassName="absolute -bottom-4 font-normal"
          {...field}
        />
      )}
    />
  );
};
