import React from "react";

import { useFormContext } from "react-hook-form";

import { CompanyAssigneesField } from "../../shared/components/company-assignees-field.tsx";
import { CompanyAttributeFields } from "../../shared/components/company-attribute-fields.tsx";
import { CompanySourceField } from "../../shared/components/company-source-field.tsx";
import { CompaniesFromEmailForm } from "../../via-email/use-add-company-from-email-form.ts";

export const CompanySourceSection = ({ index }: { index: number }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return (
    <div>
      <CompanySourceField control={control} name={`companies.${index}.company.attributes.source`} />
      <CompanyAttributeFields name={`companies.${index}.company.attributes.attributes`} control={control} />
      <CompanyAssigneesField control={control} name={`companies.${index}.company.attributes.assignees`} />
    </div>
  );
};
