import { useState } from "react";

import { cn } from "@/lib/utils";

import { SearchBar } from "@/app/components";
import { START_QUERY_AFTER, Company, useSearch } from "@/app/hooks";

interface SaerchCompanyProps {
  selectCompany: (company: Company) => void;
  companySearchType?: string;
  defaultSelected?: string;
  additionalSearchFilter?: any;
  onReset?: () => void;
  searchBarClassName?: string;
}

export const SearchCompany = ({
  selectCompany,
  companySearchType = "lg",
  defaultSelected,
  additionalSearchFilter,
  onReset,
  searchBarClassName,
}: SaerchCompanyProps) => {
  const [selected, setSelected] = useState(defaultSelected);
  const { search, queryRef, loading, reset } = useSearch(companySearchType);

  const handleSearch = (query: string) => {
    const shouldQuery = query.length > START_QUERY_AFTER;
    setSelected("");
    if (shouldQuery) {
      search({ name: { fuzzy: query }, ...(additionalSearchFilter || {}) });
    }
    if (!shouldQuery && queryRef) {
      reset();
    }
  };

  const handleSelect = (company: Company) => {
    setSelected(company.name);
    selectCompany(company);
    reset();
  };

  const handleReset = () => {
    setSelected("");
    reset();
    onReset?.();
  };

  return (
    <div className={cn("relative flex w-full flex-col")}>
      <SearchBar
        onQueryChange={handleSearch}
        onReset={handleReset}
        handleSelect={handleSelect}
        className={cn("h-12 w-full lg:h-16", searchBarClassName)}
        selected={selected}
        loading={loading}
        queryRef={queryRef}
      />
    </div>
  );
};
