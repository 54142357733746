import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Input, TextArea } from "@/app/components";
import { LocationInput } from "@/app/screens/opportunities/components";

import { FormField } from "../../shared/components/form-field.tsx";
import { CompaniesFromEmailForm } from "../use-add-company-from-email-form.ts";

export const CompanyDetailsSection = ({ index }: { index: number }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return (
    <div>
      <Controller
        name={`companies.${index}.company.details.websiteUrl`}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            iconLeft={"Continent"}
            placeholder={"Website"}
            label="Company Website"
            error={fieldState.error?.message}
            containerClassName="relative"
            errorClassName="absolute -bottom-4"
            {...field}
          />
        )}
      />
      <Controller
        name={`companies.${index}.company.details.about`}
        control={control}
        render={({ field, fieldState }) => (
          <TextArea
            containerClassName="w-full max-h-[230px] mt-4"
            label={"Description"}
            placeholder={"Description"}
            onChange={field.onChange}
            value={field.value}
            required
            error={fieldState.error?.message}
            errorTextClassName={"font-normal"}
          />
        )}
      />
      <Controller
        name={`companies.${index}.company.details.location`}
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            label={"Location"}
            required
            errorMessage={
              // fixme weird type here
              (fieldState.error as any)?.country?.message ?? (fieldState.error as any)?.city?.message
            }
          >
            <LocationInput
              required={true}
              defaultValue={
                field.value.country
                  ? {
                      value: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                      label: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                    }
                  : undefined
              }
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          </FormField>
        )}
      />
    </div>
  );
};
