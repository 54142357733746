import { useState } from "react";

import { useBreakpoints } from "@/lib/hooks/useBreakpoints.tsx";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";
import { getListItemAnimation } from "../../utils.ts";

import { FeedbackCard } from "./feedback-card.tsx";
import { useFeedbackTemplates } from "./use-feedback-templates.ts";

export const NotProgressingSection = () => {
  const { isBigTablet } = useBreakpoints();
  const [showArchived, setShowArchived] = useState(false);
  const { items, loadMore, loading, pageInfo, totalCount, loadingLess, loadingMore, loadLess } =
    useFeedbackTemplates(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.notProgressing.id}
      title={isBigTablet ? "Companies to close the loop with" : dashboardSections.notProgressing.label}
      count={totalCount}
      loading={loading}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      emptyPlaceholder={{ enabled: totalCount === 0 && !loading, message: "No companies to get back to" }}
      loadMoreBtn={{
        enabled: !!pageInfo?.hasNextPage,
        onLoadMore: loadMore,
        loading: loadingMore,
      }}
      loadLessBtn={{
        enabled: !!pageInfo?.hasPreviousPage,
        onLoadLess: loadLess,
        loading: loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {items.map((item, index) => (
          <FeedbackCard key={item.id} template={item} className={getListItemAnimation(index)} />
        ))}
      </div>
    </DashboardSection>
  );
};
