import { Action } from "@/gql/graphql";

import { useActionMutation, useForm } from "@/app/hooks";
import { useLgMembersSuspenseQuery } from "@/app/service/lgMembers.gql.ts";
import { LGMember } from "@/app/stores/members.store";

import { LGMemberToOptionValue, convertLGUserToAllOptions } from "../../../opportunities";
import { AssignForm } from "../components";

export interface UseAssignFormParams {
  id: string;
  action: Action.Reassign | Action.ChangeAssignees;
  initialAssignees?: LGMember[];
  title?: string;
  onSuccess?: () => void;
}

export const useAssignForm = ({ id, action, initialAssignees = [], onSuccess }: UseAssignFormParams) => {
  const defaultOptions = initialAssignees.map((assignee) => LGMemberToOptionValue(assignee));
  const form = useForm("Share");
  const { data } = useLgMembersSuspenseQuery({
    variables: { filter: { isInvestor: { equalTo: true } } },
  });
  const allMembers = convertLGUserToAllOptions(data, action === Action.ChangeAssignees);

  const { onSubmit, loading } = useActionMutation({ onSuccess });

  const handleSubmit = () => {
    const { assignees, note } = form.values();

    return onSubmit({
      entityId: id,
      action,
      reason: note,
      assignees: [...defaultOptions, ...assignees].map(({ value }) => value),
      successMessage: `Successfully assigned user${assignees.length > 1 ? "s" : ""}`,
    });
  };

  return {
    renderForm: (
      <AssignForm
        form={form}
        assignees={allMembers.filter(({ value }) => value && !defaultOptions.some((option) => option.value === value))}
      />
    ),
    isValid: !!form?.isValid,
    loading,
    handleSubmit,
  };
};
