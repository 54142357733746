import React, { useMemo, useRef } from "react";

import { cn } from "@/lib/utils.ts";
import { Popover, ProfileImage, Tag, Text } from "@/app/components";

import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle
} from "../highlights-card.tsx";
import { InvestmentBasecampFundsData } from "./use-invested-basecamp-funds.ts";
import { useRefDimensions } from "@/lib/hooks";
import { TimesFlaggedData } from "./use-times-flagged.ts";

const useDisplayLimit = () => {
  const membersElRef = useRef(null);

  const dimension = useRefDimensions(membersElRef);

  // 40 = 32(member) + padding and -1 is because we need one more element with "+n" text
  const limit = Math.floor(dimension.width / 40) - 1;

  return {
    limit,
    ref: membersElRef,
  };
};

export const InvestedBasecampFundsCard = ({
  investedBasecampFunds: investedBasecampFunds,
  timesFlagged
}: {
  investedBasecampFunds: InvestmentBasecampFundsData | null;
  timesFlagged: TimesFlaggedData | null;
}) => {
  const { limit, ref } = useDisplayLimit();

  const fundsToDisplay = useMemo(() => {
    return investedBasecampFunds?.funds.slice(0, limit) ?? [];
  }, [investedBasecampFunds?.funds, limit]);

  const { numOfFlagged, isFlagged, source } = useMemo(() => {
    const numOfFlagged = timesFlagged?.sources?.length || 0;
    const isFlagged = numOfFlagged > 0;
    const source = timesFlagged?.sources[0];

    return {
      numOfFlagged,
      isFlagged,
      source,
    }
  }, [timesFlagged?.sources]);

  return (
    <HighlightsCard type={"Access"}>
      {!isFlagged && !investedBasecampFunds ? (
        <div className="h-full flex items-center">
          <HighlightsCardEmptyState
            text={"No Basecamp Funds Invested or Previously Flagged"}
          />
        </div>
      ) :
      (<div className="grid grid-cols-2 h-full gap-4">
        {investedBasecampFunds &&
          <div className="h-full flex flex-col justify-between items-center">
            <HighlightsCardTitle text={String(investedBasecampFunds.title)} className="text-center" />
            <HighlightsCardNumber value={investedBasecampFunds.fundsTotal} />
            <Popover
              containerClassName={"w-full"}
              placement={"bottom"}
              childrenContent={
                <div className="grid grid-cols-1 gap-y-2">
                  {investedBasecampFunds.funds.map(({ name, image }) => (
                    <div
                      key={`${name}_basecamp_item`}
                      className="flex items-center"
                    >
                      <ProfileImage
                        key={image}
                        image={`https://${image}`}
                        firstname={name.split(" ")[0]}
                        lastname={name.split(" ")[1]}
                        imgClass={"h-8 w-8 rounded-sm "}
                      />
                      <Text text={name} className={"ml-1 text-xs text-black"} />
                    </div>
                  ))}
                </div>
              }
            >
              <div className="flex w-full justify-center gap-x-2" ref={ref}>
                {fundsToDisplay.map(({ name, image }) => (
                  <ProfileImage
                    key={image}
                    image={`https://${image}`}
                    firstname={name.split(" ")[0]}
                    lastname={name.split(" ")[1]}
                    imgClass={"h-8 w-8 rounded-sm border border-neutral-100"}
                  />
                ))}
                {fundsToDisplay.length < investedBasecampFunds.fundsTotal && (
                  <Tag
                    label={`+${
                      investedBasecampFunds.fundsTotal - fundsToDisplay.length
                    }`}
                    className={cn("ml-1")}
                    tagClassName="bg-transparent"
                  />
                )}
              </div>
            </Popover>
          </div>
        }
        { isFlagged && !investedBasecampFunds &&
          <HighlightsCardEmptyState
            text={"No Basecamp Funds Invested"}
            textClassName="text-[18px]"
          />
        }
        {isFlagged && (
          <Popover
            containerClassName={"w-full h-full flex flex-col justify-between items-center"}
            placement={"bottom"}
            childrenContent={
              <div>
                <Text text={source?.name} />
              </div>
            }
          >
            <HighlightsCardTitle text={"# Times Flagged"} />
            <HighlightsCardNumber value={numOfFlagged} />
            <HighlightsCardLastUpdated date={source?.date} />
          </Popover>
        )}
        {!isFlagged && investedBasecampFunds && (
          <HighlightsCardEmptyState
            text={"Not Previously Flagged"}
            textClassName="text-[18px]"
          />
        )}
      </div>)}
    </HighlightsCard>
  );
};
