import { Button } from "@/app/components";
import { cn } from "@/lib/utils.ts";

type EmailOpportunityActionProps = {
  actions: {
    primary: {
      title: string;
      loading?: boolean;
      action: () => void;
    };
    secondary?: {
      title: string;
      loading?: boolean;
      action: () => void;
    };
  };
  className?: string;
  buttonProps?: {
    className?: string;
  };
};

export const EmailOpportunityActions = ({ actions, className, buttonProps }: EmailOpportunityActionProps) => {
  const { primary, secondary } = actions;
  return (
    <div className={cn("flex w-full gap-2 md:w-60 md:justify-end", className)}>
      {secondary && (
        <Button
          iconRight="Check"
          variant={"secondary"}
          onClick={secondary.action}
          size="xs"
          loading={secondary.loading}
          className={cn("w-full bg-neutral-100 md:w-auto", buttonProps?.className)}
          text={secondary.title}
        >
          {secondary.title}
        </Button>
      )}
      <Button size="xs" className={cn("w-full md:w-auto", buttonProps?.className)} onClick={primary.action}>
        {primary.title}
      </Button>
    </div>
  );
};
