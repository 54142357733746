import { cn } from "@/lib/utils.ts";
import { Text } from "@/app/components";
import { AreaChartUsageExampleWithCustomTooltip } from "@/app/screens/opportunities/opportunity/components/charts.tsx";
import { EmptyState } from "../../../../../components/label";
import { text } from "@/app/styles";

export interface EvolutionExcitementValue {
  Excitement: string;
  date: string;
}

export interface EvolutionExcitementProps {
  evolutionOfExcitement: EvolutionExcitementValue[];
}

export const EvolutionExcitement = ({
  evolutionOfExcitement,
}: EvolutionExcitementProps) => {
  return (
    <div className={"flex w-full flex-col justify-between border-b py-4"}>
      <Text
        text={"Evolution of Excitement"}
        weight={"normal"}
        className={cn(text.subTitleText, "mb-2.5 text-left")}
      />
      {evolutionOfExcitement.length > 1 ? (
        <AreaChartUsageExampleWithCustomTooltip
          evolutionOfExcitement={evolutionOfExcitement}
        />
      ) : (
        <EmptyState className={"flex items-center justify-center"} />
      )}
    </div>
  );
};
