import { ActionNames, OpportunityAction, useOpportunityActions as useOpportunityActionsHook } from "@/app/hooks";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { getAvailableOpportunityActionNames } from "../../../utils";

const useGenericActions = () => {
  const { company, refresh } = useOpportunityContext();

  return useOpportunityActionsHook({ company, refresh });
};

const useAvailableActionNames = (): Array<ActionNames> => {
  const { company, signalAssignees } = useOpportunityContext();

  if (!company) return [];

  const defaultActions: Array<ActionNames> = ["hide", "follow"];

  return getAvailableOpportunityActionNames({ company, signalAssignees, defaultActions });
};

export const useOpportunityActions = (): OpportunityAction[] => {
  const genericActions = useGenericActions();
  const actionNames = useAvailableActionNames();

  if (!genericActions || !actionNames) {
    return [];
  }

  return actionNames.map((name) => genericActions[name]).filter(Boolean);
};
