import React, { PropsWithChildren, useCallback } from "react";

import { motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { usePageNav } from "@/app/components/page-nav/page-nav-provider.tsx";

const viewportOptions = { amount: 0.2 };

type PageNavSectionProps = {
  id: string;
  className?: string;
  anchorClassName?: string;
};
export const PageNavSection = ({
  id,
  children,
  className,
  anchorClassName,
}: PropsWithChildren<PageNavSectionProps>) => {
  const { handleSectionEnter: onViewEnter, handleSectionLeave: onViewLeave } = usePageNav();

  const handleViewEnter = useCallback(() => {
    onViewEnter?.(id);
  }, [onViewEnter, id]);
  const handleViewLeave = useCallback(() => {
    onViewLeave?.(id);
  }, [onViewLeave, id]);

  return (
    <motion.section
      className={cn("relative", className)}
      onViewportEnter={handleViewEnter}
      onViewportLeave={handleViewLeave}
      viewport={viewportOptions}
    >
      {/*this is as sometimes we have to set offset*/}
      <span id={id} className={cn("absolute size-0", anchorClassName)}></span>
      {children}
    </motion.section>
  );
};
