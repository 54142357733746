import { useExcitementScores } from "@/app/hooks/use-excitement-scores";

import { ExcitementScore } from "../excitement-score";

import { CardBox } from "./card-box";

export const CardExcitement = ({ sentiments, previouslyMet, isPortco }) => {
  const { stats, velocity } = useExcitementScores({
    sentiments,
    previouslyMet,
    isPortco,
  });

  return (
    <CardBox old={stats.isOld} title={"Insight"} isSad={stats.isSad} titleClassName="mb-1 2xl:mb-3 lg:mb-1.5">
      <ExcitementScore stats={stats} velocity={velocity} />
    </CardBox>
  );
};
