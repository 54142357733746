import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { defaultSignal } from "@/app/misc/helpers.ts";
import { BadgeType } from "@/types/badges.types.ts";

type SignalProps = {
  containerClassName?: string;
  textClassName?: string;
  isList?: boolean;
  signal?: BadgeType;
  noLines?: number;
};

export function Signal({
  containerClassName = "",
  textClassName = "",
  signal = defaultSignal as BadgeType,
  noLines = 1,
}: SignalProps) {
  return (
    <div className={cn("flex w-fit items-center px-3 py-1", containerClassName)}>
      <Icon type={"Signal"} className={"mr-1.5 size-6 text-primary lg:size-7 lg:py-1"} />
      <div className={"flex w-full items-center"}>
        <p
          className={cn(
            "text-xs text-neutral-600 md:text-xs lg:text-sm",
            {
              [`line-clamp-${noLines}`]: noLines,
            },
            textClassName,
          )}
          dangerouslySetInnerHTML={{
            __html:
              signal?.title
                ?.replaceAll("<b>", '<b class="text-black font-semibold">')
                ?.replaceAll("<br/>", ' <b class="text-black font-semibold">•</b> ') || "",
          }}
        />

        {/*{!signal && (*/}
        {/*  <>*/}
        {/*    <Text*/}
        {/*      text={"Added by"}*/}
        {/*      color={"text-neutral-600"}*/}
        {/*      weight={"light"}*/}
        {/*      type={textType}*/}
        {/*    />*/}
        {/*    <>&nbsp;</>*/}
        {/*    <Text*/}
        {/*      text={"Jack Simmons"}*/}
        {/*      color={"text-primary"}*/}
        {/*      type={textType}*/}
        {/*      weight={isBigTablet ? "normal" : "semibold"}*/}
        {/*    />*/}
        {/*    <>&nbsp;</>*/}
        {/*    <Text*/}
        {/*      text={"via"}*/}
        {/*      color={"text-neutral-600"}*/}
        {/*      weight={"light"}*/}
        {/*      type={textType}*/}
        {/*    />*/}
        {/*    <>&nbsp;</>*/}
        {/*    <Text*/}
        {/*      text={"James Williams"}*/}
        {/*      color={"text-primary"}*/}
        {/*      weight={isBigTablet ? "normal" : "semibold"}*/}
        {/*      type={textType}*/}
        {/*    />*/}
        {/*    {((isList && isBigPhone) || (isTablet && !isSmallLaptop)) && (*/}
        {/*      <>*/}
        {/*        <>&nbsp;</>*/}
        {/*        <Text*/}
        {/*          text={"on"}*/}
        {/*          color={"text-neutral-600"}*/}
        {/*          weight={"light"}*/}
        {/*          type={isBigTablet ? "body" : "message"}*/}
        {/*        />*/}
        {/*        <>&nbsp;</>*/}
        {/*        <Text*/}
        {/*          text={"20-Dec-2023"}*/}
        {/*          color={"text-primary"}*/}
        {/*          weight={isBigTablet ? "normal" : "semibold"}*/}
        {/*          type={isBigTablet ? "body" : "message"}*/}
        {/*        />*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </div>
  );
}
