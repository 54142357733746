import { getSignalSource } from "@/lib/utils";

import { Signal, SignalSource } from "@/app/types";

export type TimesFlaggedData = { sources: Array<SignalSource> };

export const useTimesFlagged = (signal: Signal): TimesFlaggedData => {
  const source = getSignalSource(signal);

  return { sources: source.name ? [source] : [] };
};
