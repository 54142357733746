import { useMemo } from "react";
import { useForm } from "@/app/hooks";
import modalStore from "@/app/stores/modal.store";
import { trelloColumnsList } from "@/app/misc/filters";
import { MoveTrelloModalParams } from "../types";
import { MoveTrelloForm } from "../components";
import { useAddToTrello } from "./use-add-to-trello";
import { ActionButtons } from "../../components";

export const useMoveTrello = ({ title, id, onSuccess, ...actionProps }: MoveTrelloModalParams) => {
    const form = useForm("AddTrello");
    const { isValid, addToTrelloLoading, onSubmit } = useAddToTrello({ form, id, onSuccess });

    const actionBtns = useMemo(() => (
        <ActionButtons
          isLoading={addToTrelloLoading}
          disabled={!isValid}
          primaryBtnTitle="Move"
          secondaryBtnTitle="Cancel"
          primaryBtnVariant="gradient"
          {...actionProps}
          handleCancel={modalStore.close}
          handlePrimaryAction={onSubmit}
        />
    ), [actionProps, addToTrelloLoading, isValid, onSubmit]);

    const renderForm = useMemo(() => (
        <MoveTrelloForm form={form} trelloColumns={trelloColumnsList} />
    ), [form]);

    return { actionBtns, renderForm };
}