import { Text } from "app/components";

import { cn } from "@/lib/utils.ts";

import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";

export const EmptySection = ({ text, Action }) => {
  return (
    <section
      className={cn(
        "flex h-[200px] w-full flex-col items-center justify-center bg-white",
        sectionBorderRadiusClassName,
      )}
    >
      <Text text={text} type={"title"} color={"text-neutral-300"} />
      {Action ? Action : null}
    </section>
  );
};
