import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, UseFieldArrayReturn, useForm, UseFormReturn } from "react-hook-form";
import z from "zod";

import { CompanyAdditionalDetailsSchema } from "../shared/forms/use-company-additional-details-form.ts";
import { CompanyAttributesSchema } from "../shared/forms/use-company-attributes-form.ts";
import { CompanyDetailsSchema } from "../shared/forms/use-company-details-form.ts";
import { FoundersSchema } from "../shared/forms/use-company-founders-form.ts";
import { MainCompanyDataSchema } from "../shared/forms/use-company-main-data-form.ts";
import { CompanyNoteSchema } from "../shared/forms/use-company-note-form.ts";

const MainCompanyDataWithSources = MainCompanyDataSchema.and(
  z.object({
    dealroomCompany: z
      .object({
        id: z.string(),
        name: z.string().nullable().optional(),
        websiteUrl: z.string().nullable().optional(),
        websiteDomain: z.string().nullable().optional(),
      })
      .passthrough()
      .nullable()
      .optional(),
    lgCompany: z
      .object({
        id: z.string().uuid(),
        name: z.string().nullable().optional(),
        websiteUrl: z.string().nullable().optional(),
        websiteDomain: z.string().nullable().optional(),
      })
      .passthrough()
      .nullable()
      .optional(),
  }),
);

export const CompanyDataSchema = z.object({
  mainData: MainCompanyDataWithSources,
  founders: FoundersSchema,
  details: CompanyDetailsSchema,
  additionalDetails: CompanyAdditionalDetailsSchema,
  note: CompanyNoteSchema,
  attributes: CompanyAttributesSchema,
});

const EnabledCompanySchema = z.object({
  skip: z.literal(false),
  company: CompanyDataSchema,
});

const SkippedCompanySchema = z.object({
  skip: z.literal(true),
  company: z.any(),
});

const CompanyFromEmailSchema = z.discriminatedUnion("skip", [SkippedCompanySchema, EnabledCompanySchema]);

const CompaniesFromEmailSchema = z.object({
  companies: z.array(CompanyFromEmailSchema),
});

export type CompaniesFromEmailForm = z.infer<typeof CompaniesFromEmailSchema>;

export const useAddCompaniesFromEmailForm = (): {
  form: UseFormReturn<CompaniesFromEmailForm>;
  fieldArray: UseFieldArrayReturn<CompaniesFromEmailForm, "companies">;
} => {
  const form = useForm<CompaniesFromEmailForm>({
    resolver: zodResolver(CompaniesFromEmailSchema),
    mode: "onChange",
    defaultValues: {
      companies: [],
    },
  });

  const fieldArray = useFieldArray<CompaniesFromEmailForm, "companies">({
    control: form.control,
    name: "companies",
  });

  return { form, fieldArray };
};
