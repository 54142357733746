import { ReactNode } from "react";

import { Editor } from "@tiptap/react";

import { cn } from "@/lib/utils.ts";

import { IconType } from "@/app/components";

type TextStyleToggleBase = {
  action: () => void;
  active: boolean;
  icon: IconType;
};

export type TextStyleOptionToggle =
  | (TextStyleToggleBase & { label: string })
  | (TextStyleToggleBase & {
      id: string;
      label: ReactNode;
    });

export const useRichTextEditorActions = (editor: Editor) => {
  const textInsertOptions: TextStyleOptionToggle[] = [
    // todo only on bubble menu
    // {
    //   label: "Link",
    //   action: () => {
    //     // todo link input
    //     editor.chain().focus().setLink({ href: "https://example.com" }).run();
    //   },
    //   active: false,
    //   icon: "Link",
    // },
    // {
    //   label: "Mention",
    //   action: () => {
    //     editor.chain().focus().insertContent("@").run();
    //   },
    //   active: false,
    //   icon: "AtSign",
    // },
  ];

  const textStyleOptions: TextStyleOptionToggle[] = [
    {
      label: "Bold",
      action: () => {
        editor.chain().focus().toggleBold().run();
      },
      active: editor.isActive("bold"),
      icon: "Bold",
    },
    {
      label: "Italic",
      action: () => {
        editor.chain().focus().toggleItalic().run();
      },
      active: editor.isActive("italic"),
      icon: "Italic",
    },
    {
      label: "Strikethrough",
      action: () => {
        editor.chain().focus().toggleStrike().run();
      },
      active: editor.isActive("strike"),

      icon: "Strikethrough",
    },
    {
      label: "Code",
      action: () => {
        editor.chain().focus().toggleCode().run();
      },
      active: editor.isActive("code"),
      icon: "Code",
    },
    {
      label: "Quote",
      action: () => {
        editor.chain().focus().toggleBlockquote().run();
      },
      active: editor.isActive("blockquote"),

      icon: "Quote",
    },
  ];

  const textTypeOptions: TextStyleOptionToggle[] = [
    {
      label: "Normal Text",
      action: () => {
        editor.chain().focus().setParagraph().run();
      },
      active: editor.isActive("paragraph"),
      icon: "Pilcrow",
    },
    ...Array.from(Array(5)).map((_, i) => {
      // there should be not more than 5 levels
      const level = (i + 1) as 1 | 2 | 3 | 4 | 5;

      if (level > 5) {
        throw new Error("Heading level should be not > 5");
      }

      return {
        id: `heading${level}`,
        label: (
          <span
            className={cn(
              level === 1 && "text-3xl",
              level === 2 && "text-2xl",
              level === 3 && "text-xl",
              level === 4 && "text-lg",
              level === 5 && "text-base",
            )}
          >{`Heading ${level}`}</span>
        ),
        action: () => {
          editor.chain().focus().toggleHeading({ level }).run();
        },
        active: editor.isActive("heading", { level }),
        icon: `Heading${level}` as IconType,
      };
    }),
  ];

  const listTypeOptions: TextStyleOptionToggle[] = [
    {
      label: "Bullet List",
      action: () => {
        editor.chain().focus().toggleBulletList().run();
      },
      active: editor.isActive("bulletList"),
      icon: "List",
    },
    {
      label: "Numbered List",
      action: () => {
        editor.chain().focus().toggleOrderedList().run();
      },
      active: editor.isActive("orderedList"),
      icon: "ListOrdered",
    },
  ];

  return {
    textType: textTypeOptions,
    listType: listTypeOptions,
    textStyle: textStyleOptions,
    textInsert: textInsertOptions,
  };
};
