import React from "react";

import { Icon } from "@/app/components";

import { isLgCompany, isNewCompany } from "../add-company-view.types.tsx";

import { CompanyResult, NewCompany } from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

export const AddCompanyStatus = ({ selectedCompany }: { selectedCompany: CompanyResult | NewCompany | null }) => {
  return (
    <div>
      {selectedCompany && isLgCompany(selectedCompany) && (
        <div className="flex items-center justify-end gap-1 text-red">
          <Icon type={"Shield Alert"} className={"size-4"} />
          <p className="text-left text-sm font-medium">This company is already in Nazare.</p>
        </div>
      )}
      {selectedCompany && isNewCompany(selectedCompany) && (
        <div className="flex items-center justify-end gap-1 text-orange-600">
          <Icon type={"Shield Alert"} className={"size-4"} />
          <p className="text-left text-sm font-medium">a new company will be created</p>
        </div>
      )}
    </div>
  );
};
