/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any };
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any };
  /** The day, does not include a time. */
  Date: { input: any; output: any };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any };
  FullText: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any };
};

/** API key credentials for the security protocol 'APIKeyHeader' */
export type ApiKeyHeaderInput = {
  apiKey?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Action {
  AddComment = "ADD_COMMENT",
  AddToTrello = "ADD_TO_TRELLO",
  ChangeAssignees = "CHANGE_ASSIGNEES",
  ChangeColumn = "CHANGE_COLUMN",
  MoveToPortfolio = "MOVE_TO_PORTFOLIO",
  Reassign = "REASSIGN",
  Snooze = "SNOOZE",
}

export type ActionPayloadInput = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  entityId: Scalars["String"]["input"];
  feedbackEmail?: InputMaybe<Scalars["String"]["input"]>;
  fund?: InputMaybe<Scalars["String"]["input"]>;
  other?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resurface?: InputMaybe<Scalars["String"]["input"]>;
  trelloColumn?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthResponse = {
  __typename?: "AuthResponse";
  accessToken: Scalars["String"]["output"];
  expiresAt: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
  user?: Maybe<Scalars["JSON"]["output"]>;
};

export type BasecampFundsRelationship = {
  __typename?: "BasecampFundsRelationship";
  c1?: Maybe<Scalars["String"]["output"]>;
  c2?: Maybe<Scalars["String"]["output"]>;
  c3?: Maybe<Scalars["String"]["output"]>;
  c4?: Maybe<Scalars["String"]["output"]>;
  c5?: Maybe<Scalars["String"]["output"]>;
  c6?: Maybe<Scalars["String"]["output"]>;
  c7?: Maybe<Scalars["String"]["output"]>;
  c8?: Maybe<Scalars["String"]["output"]>;
  c9?: Maybe<Scalars["String"]["output"]>;
  c10?: Maybe<Scalars["String"]["output"]>;
  c11?: Maybe<Scalars["String"]["output"]>;
  c12?: Maybe<Scalars["String"]["output"]>;
  c13?: Maybe<Scalars["String"]["output"]>;
};

export type BasecampFundsRelationshipAggregates = {
  __typename?: "BasecampFundsRelationshipAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BasecampFundsRelationshipDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `BasecampFundsRelationship` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BasecampFundsRelationshipCondition = {
  /** Checks for equality with the object’s `c1` field. */
  c1?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c2` field. */
  c2?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c3` field. */
  c3?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c4` field. */
  c4?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c5` field. */
  c5?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c6` field. */
  c6?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c7` field. */
  c7?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c8` field. */
  c8?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c9` field. */
  c9?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c10` field. */
  c10?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c11` field. */
  c11?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c12` field. */
  c12?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `c13` field. */
  c13?: InputMaybe<Scalars["String"]["input"]>;
};

export type BasecampFundsRelationshipDistinctCountAggregates = {
  __typename?: "BasecampFundsRelationshipDistinctCountAggregates";
  /** Distinct count of c1 across the matching connection */
  c1?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c2 across the matching connection */
  c2?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c3 across the matching connection */
  c3?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c4 across the matching connection */
  c4?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c5 across the matching connection */
  c5?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c6 across the matching connection */
  c6?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c7 across the matching connection */
  c7?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c8 across the matching connection */
  c8?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c9 across the matching connection */
  c9?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c10 across the matching connection */
  c10?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c11 across the matching connection */
  c11?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c12 across the matching connection */
  c12?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of c13 across the matching connection */
  c13?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BasecampFundsRelationship` object types. All fields are combined with a logical ‘and.’ */
export type BasecampFundsRelationshipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BasecampFundsRelationshipFilter>>;
  /** Filter by the object’s `c1` field. */
  c1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c2` field. */
  c2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c3` field. */
  c3?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c4` field. */
  c4?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c5` field. */
  c5?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c6` field. */
  c6?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c7` field. */
  c7?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c8` field. */
  c8?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c9` field. */
  c9?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c10` field. */
  c10?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c11` field. */
  c11?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c12` field. */
  c12?: InputMaybe<StringFilter>;
  /** Filter by the object’s `c13` field. */
  c13?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BasecampFundsRelationshipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BasecampFundsRelationshipFilter>>;
};

/** A connection to a list of `BasecampFundsRelationship` values. */
export type BasecampFundsRelationshipsConnection = {
  __typename?: "BasecampFundsRelationshipsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BasecampFundsRelationshipAggregates>;
  /** A list of edges which contains the `BasecampFundsRelationship` and cursor to aid in pagination. */
  edges: Array<BasecampFundsRelationshipsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BasecampFundsRelationshipAggregates>>;
  /** A list of `BasecampFundsRelationship` objects. */
  nodes: Array<BasecampFundsRelationship>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BasecampFundsRelationship` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BasecampFundsRelationship` values. */
export type BasecampFundsRelationshipsConnectionGroupedAggregatesArgs = {
  groupBy: Array<BasecampFundsRelationshipsGroupBy>;
  having?: InputMaybe<BasecampFundsRelationshipsHavingInput>;
};

/** A `BasecampFundsRelationship` edge in the connection. */
export type BasecampFundsRelationshipsEdge = {
  __typename?: "BasecampFundsRelationshipsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BasecampFundsRelationship` at the end of the edge. */
  node: BasecampFundsRelationship;
};

/** Grouping methods for `BasecampFundsRelationship` for usage during aggregation. */
export enum BasecampFundsRelationshipsGroupBy {
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  C7 = "C7",
  C8 = "C8",
  C9 = "C9",
  C10 = "C10",
  C11 = "C11",
  C12 = "C12",
  C13 = "C13",
}

/** Conditions for `BasecampFundsRelationship` aggregates. */
export type BasecampFundsRelationshipsHavingInput = {
  AND?: InputMaybe<Array<BasecampFundsRelationshipsHavingInput>>;
  OR?: InputMaybe<Array<BasecampFundsRelationshipsHavingInput>>;
};

/** Methods to use when ordering `BasecampFundsRelationship`. */
export enum BasecampFundsRelationshipsOrderBy {
  C1Asc = "C1_ASC",
  C1Desc = "C1_DESC",
  C2Asc = "C2_ASC",
  C2Desc = "C2_DESC",
  C3Asc = "C3_ASC",
  C3Desc = "C3_DESC",
  C4Asc = "C4_ASC",
  C4Desc = "C4_DESC",
  C5Asc = "C5_ASC",
  C5Desc = "C5_DESC",
  C6Asc = "C6_ASC",
  C6Desc = "C6_DESC",
  C7Asc = "C7_ASC",
  C7Desc = "C7_DESC",
  C8Asc = "C8_ASC",
  C8Desc = "C8_DESC",
  C9Asc = "C9_ASC",
  C9Desc = "C9_DESC",
  C10Asc = "C10_ASC",
  C10Desc = "C10_DESC",
  C11Asc = "C11_ASC",
  C11Desc = "C11_DESC",
  C12Asc = "C12_ASC",
  C12Desc = "C12_DESC",
  C13Asc = "C13_ASC",
  C13Desc = "C13_DESC",
  Natural = "NATURAL",
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

/** All input for the `calculateValuation` mutation. */
export type CalculateValuationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  kpiSummary?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The output of our `calculateValuation` mutation. */
export type CalculateValuationPayload = {
  __typename?: "CalculateValuationPayload";
  bigFloat?: Maybe<Scalars["BigFloat"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CommentDeleteInput = {
  commentId: Scalars["ID"]["input"];
};

export type CommentInput = {
  companyId: Scalars["ID"]["input"];
  metadata?: InputMaybe<CommentMetadataInput>;
  text: Scalars["String"]["input"];
};

export type CommentMetadataInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
};

export type CommentUpdateInput = {
  commentId: Scalars["ID"]["input"];
  metadata?: InputMaybe<CommentMetadataInput>;
  text: Scalars["String"]["input"];
};

export type CompanyAttachmentDeleteInput = {
  fileId: Scalars["ID"]["input"];
};

export type CompanyAttachmentInput = {
  companyId: Scalars["ID"]["input"];
  filePath: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

/** A connection to a list of `Country` values. */
export type CountriesConnection = {
  __typename?: "CountriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CountryAggregates>;
  /** A list of edges which contains the `Country` and cursor to aid in pagination. */
  edges: Array<CountriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CountryAggregates>>;
  /** A list of `Country` objects. */
  nodes: Array<Country>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Country` values. */
export type CountriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CountryGroupBy>;
  having?: InputMaybe<CountryHavingInput>;
};

/** A `Country` edge in the connection. */
export type CountriesEdge = {
  __typename?: "CountriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Country` at the end of the edge. */
  node: Country;
};

/** Methods to use when ordering `Country`. */
export enum CountriesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Iso3Asc = "ISO3_ASC",
  Iso3Desc = "ISO3_DESC",
  IsoAsc = "ISO_ASC",
  IsoDesc = "ISO_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NicenameAsc = "NICENAME_ASC",
  NicenameDesc = "NICENAME_DESC",
  NumcodeAsc = "NUMCODE_ASC",
  NumcodeDesc = "NUMCODE_DESC",
  PhonecodeAsc = "PHONECODE_ASC",
  PhonecodeDesc = "PHONECODE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Country = Node & {
  __typename?: "Country";
  id: Scalars["Int"]["output"];
  iso: Scalars["String"]["output"];
  iso3?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nicename: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  numcode?: Maybe<Scalars["Int"]["output"]>;
  phonecode: Scalars["Int"]["output"];
};

export type CountryAggregates = {
  __typename?: "CountryAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CountryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CountryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CountryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CountryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CountryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CountryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CountrySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CountryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CountryVarianceSampleAggregates>;
};

export type CountryAverageAggregates = {
  __typename?: "CountryAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A condition to be used against `Country` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CountryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `iso` field. */
  iso?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `iso3` field. */
  iso3?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `nicename` field. */
  nicename?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `numcode` field. */
  numcode?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `phonecode` field. */
  phonecode?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CountryDistinctCountAggregates = {
  __typename?: "CountryDistinctCountAggregates";
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of iso across the matching connection */
  iso?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of iso3 across the matching connection */
  iso3?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of nicename across the matching connection */
  nicename?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Country` object types. All fields are combined with a logical ‘and.’ */
export type CountryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CountryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `iso` field. */
  iso?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iso3` field. */
  iso3?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nicename` field. */
  nicename?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CountryFilter>;
  /** Filter by the object’s `numcode` field. */
  numcode?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CountryFilter>>;
  /** Filter by the object’s `phonecode` field. */
  phonecode?: InputMaybe<IntFilter>;
};

/** Grouping methods for `Country` for usage during aggregation. */
export enum CountryGroupBy {
  Iso = "ISO",
  Iso3 = "ISO3",
  Name = "NAME",
  Nicename = "NICENAME",
  Numcode = "NUMCODE",
  Phonecode = "PHONECODE",
}

export type CountryHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Country` aggregates. */
export type CountryHavingInput = {
  AND?: InputMaybe<Array<CountryHavingInput>>;
  OR?: InputMaybe<Array<CountryHavingInput>>;
  average?: InputMaybe<CountryHavingAverageInput>;
  distinctCount?: InputMaybe<CountryHavingDistinctCountInput>;
  max?: InputMaybe<CountryHavingMaxInput>;
  min?: InputMaybe<CountryHavingMinInput>;
  stddevPopulation?: InputMaybe<CountryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CountryHavingStddevSampleInput>;
  sum?: InputMaybe<CountryHavingSumInput>;
  variancePopulation?: InputMaybe<CountryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CountryHavingVarianceSampleInput>;
};

export type CountryHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  numcode?: InputMaybe<HavingIntFilter>;
  phonecode?: InputMaybe<HavingIntFilter>;
};

export type CountryMaxAggregates = {
  __typename?: "CountryMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of numcode across the matching connection */
  numcode?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["Int"]["output"]>;
};

export type CountryMinAggregates = {
  __typename?: "CountryMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of numcode across the matching connection */
  numcode?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["Int"]["output"]>;
};

export type CountryStddevPopulationAggregates = {
  __typename?: "CountryStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type CountryStddevSampleAggregates = {
  __typename?: "CountryStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type CountrySumAggregates = {
  __typename?: "CountrySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of numcode across the matching connection */
  numcode: Scalars["BigInt"]["output"];
  /** Sum of phonecode across the matching connection */
  phonecode: Scalars["BigInt"]["output"];
};

export type CountryVariancePopulationAggregates = {
  __typename?: "CountryVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type CountryVarianceSampleAggregates = {
  __typename?: "CountryVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of numcode across the matching connection */
  numcode?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of phonecode across the matching connection */
  phonecode?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Date"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Date"]["input"]>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
};

/** A connection to a list of `DealroomCompany` values. */
export type DealroomCompaniesConnection = {
  __typename?: "DealroomCompaniesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomCompanyAggregates>;
  /** A list of edges which contains the `DealroomCompany` and cursor to aid in pagination. */
  edges: Array<DealroomCompaniesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomCompanyAggregates>>;
  /** A list of `DealroomCompany` objects. */
  nodes: Array<DealroomCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomCompany` values. */
export type DealroomCompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomCompanyGroupBy>;
  having?: InputMaybe<DealroomCompanyHavingInput>;
};

/** A `DealroomCompany` edge in the connection. */
export type DealroomCompaniesEdge = {
  __typename?: "DealroomCompaniesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomCompany` at the end of the edge. */
  node: DealroomCompany;
};

/** Methods to use when ordering `DealroomCompany`. */
export enum DealroomCompaniesOrderBy {
  AboutAsc = "ABOUT_ASC",
  AboutDesc = "ABOUT_DESC",
  AngellistUrlAsc = "ANGELLIST_URL_ASC",
  AngellistUrlDesc = "ANGELLIST_URL_DESC",
  AppstoreAppIdAsc = "APPSTORE_APP_ID_ASC",
  AppstoreAppIdDesc = "APPSTORE_APP_ID_DESC",
  App_3MonthsGrowthPercentileAsc = "APP_3_MONTHS_GROWTH_PERCENTILE_ASC",
  App_3MonthsGrowthPercentileDesc = "APP_3_MONTHS_GROWTH_PERCENTILE_DESC",
  App_3MonthsGrowthRelativeAsc = "APP_3_MONTHS_GROWTH_RELATIVE_ASC",
  App_3MonthsGrowthRelativeDesc = "APP_3_MONTHS_GROWTH_RELATIVE_DESC",
  App_3MonthsGrowthUniqueAsc = "APP_3_MONTHS_GROWTH_UNIQUE_ASC",
  App_3MonthsGrowthUniqueDesc = "APP_3_MONTHS_GROWTH_UNIQUE_DESC",
  App_6MonthsGrowthPercentileAsc = "APP_6_MONTHS_GROWTH_PERCENTILE_ASC",
  App_6MonthsGrowthPercentileDesc = "APP_6_MONTHS_GROWTH_PERCENTILE_DESC",
  App_6MonthsGrowthRelativeAsc = "APP_6_MONTHS_GROWTH_RELATIVE_ASC",
  App_6MonthsGrowthRelativeDesc = "APP_6_MONTHS_GROWTH_RELATIVE_DESC",
  App_6MonthsGrowthUniqueAsc = "APP_6_MONTHS_GROWTH_UNIQUE_ASC",
  App_6MonthsGrowthUniqueDesc = "APP_6_MONTHS_GROWTH_UNIQUE_DESC",
  App_12MonthsGrowthPercentileAsc = "APP_12_MONTHS_GROWTH_PERCENTILE_ASC",
  App_12MonthsGrowthPercentileDesc = "APP_12_MONTHS_GROWTH_PERCENTILE_DESC",
  App_12MonthsGrowthRelativeAsc = "APP_12_MONTHS_GROWTH_RELATIVE_ASC",
  App_12MonthsGrowthRelativeDesc = "APP_12_MONTHS_GROWTH_RELATIVE_DESC",
  App_12MonthsGrowthUniqueAsc = "APP_12_MONTHS_GROWTH_UNIQUE_ASC",
  App_12MonthsGrowthUniqueDesc = "APP_12_MONTHS_GROWTH_UNIQUE_DESC",
  AppDownloadsAndroidChartAsc = "APP_DOWNLOADS_ANDROID_CHART_ASC",
  AppDownloadsAndroidChartDesc = "APP_DOWNLOADS_ANDROID_CHART_DESC",
  AppDownloadsAndroidIncrementalChartAsc = "APP_DOWNLOADS_ANDROID_INCREMENTAL_CHART_ASC",
  AppDownloadsAndroidIncrementalChartDesc = "APP_DOWNLOADS_ANDROID_INCREMENTAL_CHART_DESC",
  AppDownloadsIosChartAsc = "APP_DOWNLOADS_IOS_CHART_ASC",
  AppDownloadsIosChartDesc = "APP_DOWNLOADS_IOS_CHART_DESC",
  AppDownloadsIosIncrementalChartAsc = "APP_DOWNLOADS_IOS_INCREMENTAL_CHART_ASC",
  AppDownloadsIosIncrementalChartDesc = "APP_DOWNLOADS_IOS_INCREMENTAL_CHART_DESC",
  ClientFocusAsc = "CLIENT_FOCUS_ASC",
  ClientFocusDesc = "CLIENT_FOCUS_DESC",
  CompanyStatusAsc = "COMPANY_STATUS_ASC",
  CompanyStatusDesc = "COMPANY_STATUS_DESC",
  CoreSideValueAsc = "CORE_SIDE_VALUE_ASC",
  CoreSideValueDesc = "CORE_SIDE_VALUE_DESC",
  CorporateIndustriesAsc = "CORPORATE_INDUSTRIES_ASC",
  CorporateIndustriesDesc = "CORPORATE_INDUSTRIES_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CrunchbaseUrlAsc = "CRUNCHBASE_URL_ASC",
  CrunchbaseUrlDesc = "CRUNCHBASE_URL_DESC",
  DataTypeAsc = "DATA_TYPE_ASC",
  DataTypeDesc = "DATA_TYPE_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdAverageIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdAverageIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdCountAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_COUNT_ASC",
  DealroomCompanyInvestorsByCompanyIdCountDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_COUNT_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdDistinctCountIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMaxIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdMaxIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdMinCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMinCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMinCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMinCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMinExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdMinExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdMinIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMinIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMinInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdMinInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdMinIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdMinIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevPopulationIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdStddevSampleIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdSumCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdSumCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdSumCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdSumCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdSumExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdSumExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdSumIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdSumIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdSumInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdSumInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdSumIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdSumIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdVariancePopulationIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXITED_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXITED_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByCompanyIdVarianceSampleIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_EDITORIAL_DESC",
  DealroomCompanyTeamsByCompanyIdAverageCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdAverageCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdAverageCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdAverageCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdAverageIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdAverageIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdAverageIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdAverageIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdAverageMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdAverageMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdAveragePastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdAveragePastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdAverageTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdAverageTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdAverageYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdAverageYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdAverageYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdAverageYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdCountAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_COUNT_ASC",
  DealroomCompanyTeamsByCompanyIdCountDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_COUNT_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdDistinctCountYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdDistinctCountYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdMaxCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMaxCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMaxCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMaxCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMaxIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMaxIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMaxIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdMaxIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdMaxMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMaxMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMaxPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdMaxPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdMaxTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdMaxTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdMaxYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdMaxYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdMaxYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdMaxYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdMinCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMinCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMinCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMinCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMinIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMinIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMinIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdMinIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdMinMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdMinMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdMinPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdMinPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdMinTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdMinTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdMinYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdMinYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdMinYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdMinYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdStddevPopulationYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSamplePastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSamplePastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdStddevSampleYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdStddevSampleYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdSumCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdSumCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdSumCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdSumCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdSumIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_ID_ASC",
  DealroomCompanyTeamsByCompanyIdSumIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_ID_DESC",
  DealroomCompanyTeamsByCompanyIdSumIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdSumIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdSumMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdSumMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdSumPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdSumPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdSumTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdSumTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdSumYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdSumYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdSumYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdSumYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationPastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationPastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdVariancePopulationYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_START_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSamplePastAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAST_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSamplePastDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAST_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLES_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLES_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_END_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_END_DESC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_START_ASC",
  DealroomCompanyTeamsByCompanyIdVarianceSampleYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_START_DESC",
  DealroomFundingRoundsByCompanyIdAverageAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdAverageAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdAverageAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdAverageAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdAverageAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdAverageAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdAverageAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdAverageAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdAverageCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdAverageCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdAverageCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdAverageCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdAverageIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  DealroomFundingRoundsByCompanyIdAverageIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  DealroomFundingRoundsByCompanyIdAverageIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdAverageIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdAverageMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdAverageMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdAverageRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdAverageRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdAverageValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdAverageValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdAverageValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdAverageValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdAverageValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdAverageValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdAverageYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdAverageYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdCountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_COUNT_ASC",
  DealroomFundingRoundsByCompanyIdCountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_COUNT_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdDistinctCountYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdDistinctCountYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdMaxAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdMaxAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdMaxAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdMaxAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdMaxAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdMaxAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdMaxAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdMaxAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdMaxCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdMaxCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdMaxCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdMaxCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdMaxIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ID_ASC",
  DealroomFundingRoundsByCompanyIdMaxIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ID_DESC",
  DealroomFundingRoundsByCompanyIdMaxIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdMaxIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdMaxMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdMaxMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdMaxRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdMaxRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdMaxValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdMaxValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdMaxValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdMaxValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdMaxValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdMaxValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdMaxYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdMaxYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdMinAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdMinAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdMinAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdMinAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdMinAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdMinAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdMinAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdMinAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdMinCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdMinCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdMinCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdMinCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdMinIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ID_ASC",
  DealroomFundingRoundsByCompanyIdMinIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ID_DESC",
  DealroomFundingRoundsByCompanyIdMinIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdMinIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdMinMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdMinMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdMinRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdMinRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdMinValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdMinValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdMinValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdMinValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdMinValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdMinValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdMinYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdMinYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdStddevPopulationYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdStddevPopulationYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdStddevSampleYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdStddevSampleYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdSumAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdSumAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdSumAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdSumAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdSumAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdSumAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdSumAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdSumAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdSumCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdSumCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdSumCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdSumCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdSumIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ID_ASC",
  DealroomFundingRoundsByCompanyIdSumIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ID_DESC",
  DealroomFundingRoundsByCompanyIdSumIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdSumIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdSumMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdSumMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdSumRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdSumRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdSumValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdSumValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdSumValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdSumValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdSumValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdSumValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdSumYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdSumYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdVariancePopulationYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdVariancePopulationYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountEurMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_EUR_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountEurMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_EUR_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountSourceAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_SOURCE_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountSourceDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_SOURCE_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountUsdMillionAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_USD_MILLION_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleAmountUsdMillionDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_USD_MILLION_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleCompanyIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleCompanyIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleCurrencyAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENCY_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleCurrencyDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENCY_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleIdAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleIdDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleIsVerifiedAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_VERIFIED_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleIsVerifiedDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_VERIFIED_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleMonthAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MONTH_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleMonthDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MONTH_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleRoundAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ROUND_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleRoundDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ROUND_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMaxAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MAX_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMaxDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MAX_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMinAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MIN_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMinDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MIN_DESC",
  DealroomFundingRoundsByCompanyIdVarianceSampleYearAsc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_ASC",
  DealroomFundingRoundsByCompanyIdVarianceSampleYearDesc = "DEALROOM_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_DESC",
  DealroomNewsByCompanyIdAverageAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_AUTHOR_ASC",
  DealroomNewsByCompanyIdAverageAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_AUTHOR_DESC",
  DealroomNewsByCompanyIdAverageCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_CATEGORIES_ASC",
  DealroomNewsByCompanyIdAverageCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_CATEGORIES_DESC",
  DealroomNewsByCompanyIdAverageCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdAverageCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdAverageContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_CONTENT_ASC",
  DealroomNewsByCompanyIdAverageContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_CONTENT_DESC",
  DealroomNewsByCompanyIdAverageFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_FEED_ASC",
  DealroomNewsByCompanyIdAverageFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_FEED_DESC",
  DealroomNewsByCompanyIdAverageIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  DealroomNewsByCompanyIdAverageIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  DealroomNewsByCompanyIdAverageImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_IMAGE_ASC",
  DealroomNewsByCompanyIdAverageImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_IMAGE_DESC",
  DealroomNewsByCompanyIdAverageLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_LINK_ASC",
  DealroomNewsByCompanyIdAverageLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_LINK_DESC",
  DealroomNewsByCompanyIdAveragePubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_PUB_DATE_ASC",
  DealroomNewsByCompanyIdAveragePubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_PUB_DATE_DESC",
  DealroomNewsByCompanyIdAveragePubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdAveragePubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdAverageRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdAverageRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdAverageSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_SUMMARY_ASC",
  DealroomNewsByCompanyIdAverageSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_SUMMARY_DESC",
  DealroomNewsByCompanyIdAverageTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_TITLE_ASC",
  DealroomNewsByCompanyIdAverageTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_AVERAGE_TITLE_DESC",
  DealroomNewsByCompanyIdCountAsc = "DEALROOM_NEWS_BY_COMPANY_ID_COUNT_ASC",
  DealroomNewsByCompanyIdCountDesc = "DEALROOM_NEWS_BY_COMPANY_ID_COUNT_DESC",
  DealroomNewsByCompanyIdDistinctCountAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_AUTHOR_ASC",
  DealroomNewsByCompanyIdDistinctCountAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_AUTHOR_DESC",
  DealroomNewsByCompanyIdDistinctCountCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_CATEGORIES_ASC",
  DealroomNewsByCompanyIdDistinctCountCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_CATEGORIES_DESC",
  DealroomNewsByCompanyIdDistinctCountCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdDistinctCountCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdDistinctCountContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_ASC",
  DealroomNewsByCompanyIdDistinctCountContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_DESC",
  DealroomNewsByCompanyIdDistinctCountFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_FEED_ASC",
  DealroomNewsByCompanyIdDistinctCountFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_FEED_DESC",
  DealroomNewsByCompanyIdDistinctCountIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  DealroomNewsByCompanyIdDistinctCountIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  DealroomNewsByCompanyIdDistinctCountImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_IMAGE_ASC",
  DealroomNewsByCompanyIdDistinctCountImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_IMAGE_DESC",
  DealroomNewsByCompanyIdDistinctCountLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_LINK_ASC",
  DealroomNewsByCompanyIdDistinctCountLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_LINK_DESC",
  DealroomNewsByCompanyIdDistinctCountPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_PUB_DATE_ASC",
  DealroomNewsByCompanyIdDistinctCountPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_PUB_DATE_DESC",
  DealroomNewsByCompanyIdDistinctCountPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdDistinctCountPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdDistinctCountRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdDistinctCountRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdDistinctCountSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_SUMMARY_ASC",
  DealroomNewsByCompanyIdDistinctCountSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_SUMMARY_DESC",
  DealroomNewsByCompanyIdDistinctCountTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_ASC",
  DealroomNewsByCompanyIdDistinctCountTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_DESC",
  DealroomNewsByCompanyIdMaxAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_AUTHOR_ASC",
  DealroomNewsByCompanyIdMaxAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_AUTHOR_DESC",
  DealroomNewsByCompanyIdMaxCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_CATEGORIES_ASC",
  DealroomNewsByCompanyIdMaxCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_CATEGORIES_DESC",
  DealroomNewsByCompanyIdMaxCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdMaxCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdMaxContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_CONTENT_ASC",
  DealroomNewsByCompanyIdMaxContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_CONTENT_DESC",
  DealroomNewsByCompanyIdMaxFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_FEED_ASC",
  DealroomNewsByCompanyIdMaxFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_FEED_DESC",
  DealroomNewsByCompanyIdMaxIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_ID_ASC",
  DealroomNewsByCompanyIdMaxIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_ID_DESC",
  DealroomNewsByCompanyIdMaxImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_IMAGE_ASC",
  DealroomNewsByCompanyIdMaxImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_IMAGE_DESC",
  DealroomNewsByCompanyIdMaxLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_LINK_ASC",
  DealroomNewsByCompanyIdMaxLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_LINK_DESC",
  DealroomNewsByCompanyIdMaxPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_PUB_DATE_ASC",
  DealroomNewsByCompanyIdMaxPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_PUB_DATE_DESC",
  DealroomNewsByCompanyIdMaxPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdMaxPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdMaxRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdMaxRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdMaxSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_SUMMARY_ASC",
  DealroomNewsByCompanyIdMaxSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_SUMMARY_DESC",
  DealroomNewsByCompanyIdMaxTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_TITLE_ASC",
  DealroomNewsByCompanyIdMaxTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MAX_TITLE_DESC",
  DealroomNewsByCompanyIdMinAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_AUTHOR_ASC",
  DealroomNewsByCompanyIdMinAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_AUTHOR_DESC",
  DealroomNewsByCompanyIdMinCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_CATEGORIES_ASC",
  DealroomNewsByCompanyIdMinCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_CATEGORIES_DESC",
  DealroomNewsByCompanyIdMinCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdMinCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdMinContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_CONTENT_ASC",
  DealroomNewsByCompanyIdMinContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_CONTENT_DESC",
  DealroomNewsByCompanyIdMinFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_FEED_ASC",
  DealroomNewsByCompanyIdMinFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_FEED_DESC",
  DealroomNewsByCompanyIdMinIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_ID_ASC",
  DealroomNewsByCompanyIdMinIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_ID_DESC",
  DealroomNewsByCompanyIdMinImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_IMAGE_ASC",
  DealroomNewsByCompanyIdMinImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_IMAGE_DESC",
  DealroomNewsByCompanyIdMinLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_LINK_ASC",
  DealroomNewsByCompanyIdMinLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_LINK_DESC",
  DealroomNewsByCompanyIdMinPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_PUB_DATE_ASC",
  DealroomNewsByCompanyIdMinPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_PUB_DATE_DESC",
  DealroomNewsByCompanyIdMinPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdMinPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdMinRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdMinRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdMinSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_SUMMARY_ASC",
  DealroomNewsByCompanyIdMinSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_SUMMARY_DESC",
  DealroomNewsByCompanyIdMinTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_TITLE_ASC",
  DealroomNewsByCompanyIdMinTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_MIN_TITLE_DESC",
  DealroomNewsByCompanyIdStddevPopulationAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_AUTHOR_ASC",
  DealroomNewsByCompanyIdStddevPopulationAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_AUTHOR_DESC",
  DealroomNewsByCompanyIdStddevPopulationCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_CATEGORIES_ASC",
  DealroomNewsByCompanyIdStddevPopulationCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_CATEGORIES_DESC",
  DealroomNewsByCompanyIdStddevPopulationCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdStddevPopulationCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdStddevPopulationContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_ASC",
  DealroomNewsByCompanyIdStddevPopulationContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_DESC",
  DealroomNewsByCompanyIdStddevPopulationFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_FEED_ASC",
  DealroomNewsByCompanyIdStddevPopulationFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_FEED_DESC",
  DealroomNewsByCompanyIdStddevPopulationIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  DealroomNewsByCompanyIdStddevPopulationIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  DealroomNewsByCompanyIdStddevPopulationImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_IMAGE_ASC",
  DealroomNewsByCompanyIdStddevPopulationImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_IMAGE_DESC",
  DealroomNewsByCompanyIdStddevPopulationLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_LINK_ASC",
  DealroomNewsByCompanyIdStddevPopulationLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_LINK_DESC",
  DealroomNewsByCompanyIdStddevPopulationPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_PUB_DATE_ASC",
  DealroomNewsByCompanyIdStddevPopulationPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_PUB_DATE_DESC",
  DealroomNewsByCompanyIdStddevPopulationPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdStddevPopulationPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdStddevPopulationRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdStddevPopulationRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdStddevPopulationSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_SUMMARY_ASC",
  DealroomNewsByCompanyIdStddevPopulationSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_SUMMARY_DESC",
  DealroomNewsByCompanyIdStddevPopulationTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_ASC",
  DealroomNewsByCompanyIdStddevPopulationTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_DESC",
  DealroomNewsByCompanyIdStddevSampleAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_AUTHOR_ASC",
  DealroomNewsByCompanyIdStddevSampleAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_AUTHOR_DESC",
  DealroomNewsByCompanyIdStddevSampleCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_CATEGORIES_ASC",
  DealroomNewsByCompanyIdStddevSampleCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_CATEGORIES_DESC",
  DealroomNewsByCompanyIdStddevSampleCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdStddevSampleCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdStddevSampleContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_ASC",
  DealroomNewsByCompanyIdStddevSampleContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_DESC",
  DealroomNewsByCompanyIdStddevSampleFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_FEED_ASC",
  DealroomNewsByCompanyIdStddevSampleFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_FEED_DESC",
  DealroomNewsByCompanyIdStddevSampleIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomNewsByCompanyIdStddevSampleIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomNewsByCompanyIdStddevSampleImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_IMAGE_ASC",
  DealroomNewsByCompanyIdStddevSampleImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_IMAGE_DESC",
  DealroomNewsByCompanyIdStddevSampleLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_LINK_ASC",
  DealroomNewsByCompanyIdStddevSampleLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_LINK_DESC",
  DealroomNewsByCompanyIdStddevSamplePubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_PUB_DATE_ASC",
  DealroomNewsByCompanyIdStddevSamplePubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_PUB_DATE_DESC",
  DealroomNewsByCompanyIdStddevSamplePubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdStddevSamplePubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdStddevSampleRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdStddevSampleRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdStddevSampleSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_SUMMARY_ASC",
  DealroomNewsByCompanyIdStddevSampleSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_SUMMARY_DESC",
  DealroomNewsByCompanyIdStddevSampleTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_ASC",
  DealroomNewsByCompanyIdStddevSampleTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_DESC",
  DealroomNewsByCompanyIdSumAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_AUTHOR_ASC",
  DealroomNewsByCompanyIdSumAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_AUTHOR_DESC",
  DealroomNewsByCompanyIdSumCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_CATEGORIES_ASC",
  DealroomNewsByCompanyIdSumCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_CATEGORIES_DESC",
  DealroomNewsByCompanyIdSumCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdSumCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdSumContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_CONTENT_ASC",
  DealroomNewsByCompanyIdSumContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_CONTENT_DESC",
  DealroomNewsByCompanyIdSumFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_FEED_ASC",
  DealroomNewsByCompanyIdSumFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_FEED_DESC",
  DealroomNewsByCompanyIdSumIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_ID_ASC",
  DealroomNewsByCompanyIdSumIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_ID_DESC",
  DealroomNewsByCompanyIdSumImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_IMAGE_ASC",
  DealroomNewsByCompanyIdSumImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_IMAGE_DESC",
  DealroomNewsByCompanyIdSumLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_LINK_ASC",
  DealroomNewsByCompanyIdSumLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_LINK_DESC",
  DealroomNewsByCompanyIdSumPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_PUB_DATE_ASC",
  DealroomNewsByCompanyIdSumPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_PUB_DATE_DESC",
  DealroomNewsByCompanyIdSumPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdSumPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdSumRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdSumRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdSumSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_SUMMARY_ASC",
  DealroomNewsByCompanyIdSumSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_SUMMARY_DESC",
  DealroomNewsByCompanyIdSumTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_TITLE_ASC",
  DealroomNewsByCompanyIdSumTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_SUM_TITLE_DESC",
  DealroomNewsByCompanyIdVariancePopulationAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_AUTHOR_ASC",
  DealroomNewsByCompanyIdVariancePopulationAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_AUTHOR_DESC",
  DealroomNewsByCompanyIdVariancePopulationCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_CATEGORIES_ASC",
  DealroomNewsByCompanyIdVariancePopulationCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_CATEGORIES_DESC",
  DealroomNewsByCompanyIdVariancePopulationCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdVariancePopulationCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdVariancePopulationContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_ASC",
  DealroomNewsByCompanyIdVariancePopulationContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_DESC",
  DealroomNewsByCompanyIdVariancePopulationFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_FEED_ASC",
  DealroomNewsByCompanyIdVariancePopulationFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_FEED_DESC",
  DealroomNewsByCompanyIdVariancePopulationIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomNewsByCompanyIdVariancePopulationIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomNewsByCompanyIdVariancePopulationImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_IMAGE_ASC",
  DealroomNewsByCompanyIdVariancePopulationImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_IMAGE_DESC",
  DealroomNewsByCompanyIdVariancePopulationLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_LINK_ASC",
  DealroomNewsByCompanyIdVariancePopulationLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_LINK_DESC",
  DealroomNewsByCompanyIdVariancePopulationPubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_PUB_DATE_ASC",
  DealroomNewsByCompanyIdVariancePopulationPubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_PUB_DATE_DESC",
  DealroomNewsByCompanyIdVariancePopulationPubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdVariancePopulationPubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdVariancePopulationRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdVariancePopulationRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdVariancePopulationSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_SUMMARY_ASC",
  DealroomNewsByCompanyIdVariancePopulationSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_SUMMARY_DESC",
  DealroomNewsByCompanyIdVariancePopulationTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_ASC",
  DealroomNewsByCompanyIdVariancePopulationTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_DESC",
  DealroomNewsByCompanyIdVarianceSampleAuthorAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_AUTHOR_ASC",
  DealroomNewsByCompanyIdVarianceSampleAuthorDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_AUTHOR_DESC",
  DealroomNewsByCompanyIdVarianceSampleCategoriesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_CATEGORIES_ASC",
  DealroomNewsByCompanyIdVarianceSampleCategoriesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_CATEGORIES_DESC",
  DealroomNewsByCompanyIdVarianceSampleCompanyIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomNewsByCompanyIdVarianceSampleCompanyIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomNewsByCompanyIdVarianceSampleContentAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_ASC",
  DealroomNewsByCompanyIdVarianceSampleContentDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_DESC",
  DealroomNewsByCompanyIdVarianceSampleFeedAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_FEED_ASC",
  DealroomNewsByCompanyIdVarianceSampleFeedDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_FEED_DESC",
  DealroomNewsByCompanyIdVarianceSampleIdAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomNewsByCompanyIdVarianceSampleIdDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomNewsByCompanyIdVarianceSampleImageAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMAGE_ASC",
  DealroomNewsByCompanyIdVarianceSampleImageDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMAGE_DESC",
  DealroomNewsByCompanyIdVarianceSampleLinkAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINK_ASC",
  DealroomNewsByCompanyIdVarianceSampleLinkDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINK_DESC",
  DealroomNewsByCompanyIdVarianceSamplePubDateAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_PUB_DATE_ASC",
  DealroomNewsByCompanyIdVarianceSamplePubDateDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_PUB_DATE_DESC",
  DealroomNewsByCompanyIdVarianceSamplePubDateUtcAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_PUB_DATE_UTC_ASC",
  DealroomNewsByCompanyIdVarianceSamplePubDateUtcDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_PUB_DATE_UTC_DESC",
  DealroomNewsByCompanyIdVarianceSampleRelatedCompaniesAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_RELATED_COMPANIES_ASC",
  DealroomNewsByCompanyIdVarianceSampleRelatedCompaniesDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_RELATED_COMPANIES_DESC",
  DealroomNewsByCompanyIdVarianceSampleSummaryAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_SUMMARY_ASC",
  DealroomNewsByCompanyIdVarianceSampleSummaryDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_SUMMARY_DESC",
  DealroomNewsByCompanyIdVarianceSampleTitleAsc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_ASC",
  DealroomNewsByCompanyIdVarianceSampleTitleDesc = "DEALROOM_NEWS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_DESC",
  DealroomSignalAsc = "DEALROOM_SIGNAL_ASC",
  DealroomSignalDesc = "DEALROOM_SIGNAL_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  DeliveryMethodAsc = "DELIVERY_METHOD_ASC",
  DeliveryMethodDesc = "DELIVERY_METHOD_DESC",
  EmployeesAsc = "EMPLOYEES_ASC",
  EmployeesChartAsc = "EMPLOYEES_CHART_ASC",
  EmployeesChartDesc = "EMPLOYEES_CHART_DESC",
  EmployeesDesc = "EMPLOYEES_DESC",
  EmployeesLatestAsc = "EMPLOYEES_LATEST_ASC",
  EmployeesLatestDesc = "EMPLOYEES_LATEST_DESC",
  Employee_3MonthsGrowthDeltaAsc = "EMPLOYEE_3_MONTHS_GROWTH_DELTA_ASC",
  Employee_3MonthsGrowthDeltaDesc = "EMPLOYEE_3_MONTHS_GROWTH_DELTA_DESC",
  Employee_3MonthsGrowthPercentileAsc = "EMPLOYEE_3_MONTHS_GROWTH_PERCENTILE_ASC",
  Employee_3MonthsGrowthPercentileDesc = "EMPLOYEE_3_MONTHS_GROWTH_PERCENTILE_DESC",
  Employee_3MonthsGrowthRelativeAsc = "EMPLOYEE_3_MONTHS_GROWTH_RELATIVE_ASC",
  Employee_3MonthsGrowthRelativeDesc = "EMPLOYEE_3_MONTHS_GROWTH_RELATIVE_DESC",
  Employee_3MonthsGrowthUniqueAsc = "EMPLOYEE_3_MONTHS_GROWTH_UNIQUE_ASC",
  Employee_3MonthsGrowthUniqueDesc = "EMPLOYEE_3_MONTHS_GROWTH_UNIQUE_DESC",
  Employee_6MonthsGrowthDeltaAsc = "EMPLOYEE_6_MONTHS_GROWTH_DELTA_ASC",
  Employee_6MonthsGrowthDeltaDesc = "EMPLOYEE_6_MONTHS_GROWTH_DELTA_DESC",
  Employee_6MonthsGrowthPercentileAsc = "EMPLOYEE_6_MONTHS_GROWTH_PERCENTILE_ASC",
  Employee_6MonthsGrowthPercentileDesc = "EMPLOYEE_6_MONTHS_GROWTH_PERCENTILE_DESC",
  Employee_6MonthsGrowthRelativeAsc = "EMPLOYEE_6_MONTHS_GROWTH_RELATIVE_ASC",
  Employee_6MonthsGrowthRelativeDesc = "EMPLOYEE_6_MONTHS_GROWTH_RELATIVE_DESC",
  Employee_6MonthsGrowthUniqueAsc = "EMPLOYEE_6_MONTHS_GROWTH_UNIQUE_ASC",
  Employee_6MonthsGrowthUniqueDesc = "EMPLOYEE_6_MONTHS_GROWTH_UNIQUE_DESC",
  Employee_12MonthsGrowthDeltaAsc = "EMPLOYEE_12_MONTHS_GROWTH_DELTA_ASC",
  Employee_12MonthsGrowthDeltaDesc = "EMPLOYEE_12_MONTHS_GROWTH_DELTA_DESC",
  Employee_12MonthsGrowthPercentileAsc = "EMPLOYEE_12_MONTHS_GROWTH_PERCENTILE_ASC",
  Employee_12MonthsGrowthPercentileDesc = "EMPLOYEE_12_MONTHS_GROWTH_PERCENTILE_DESC",
  Employee_12MonthsGrowthRelativeAsc = "EMPLOYEE_12_MONTHS_GROWTH_RELATIVE_ASC",
  Employee_12MonthsGrowthRelativeDesc = "EMPLOYEE_12_MONTHS_GROWTH_RELATIVE_DESC",
  Employee_12MonthsGrowthUniqueAsc = "EMPLOYEE_12_MONTHS_GROWTH_UNIQUE_ASC",
  Employee_12MonthsGrowthUniqueDesc = "EMPLOYEE_12_MONTHS_GROWTH_UNIQUE_DESC",
  FacebookLikesChartAsc = "FACEBOOK_LIKES_CHART_ASC",
  FacebookLikesChartDesc = "FACEBOOK_LIKES_CHART_DESC",
  FacebookUrlAsc = "FACEBOOK_URL_ASC",
  FacebookUrlDesc = "FACEBOOK_URL_DESC",
  FullTextAsc = "FULL_TEXT_ASC",
  FullTextDesc = "FULL_TEXT_DESC",
  FullTextRankAsc = "FULL_TEXT_RANK_ASC",
  FullTextRankDesc = "FULL_TEXT_RANK_DESC",
  FundingsAsc = "FUNDINGS_ASC",
  FundingsDesc = "FUNDINGS_DESC",
  GoogleUrlAsc = "GOOGLE_URL_ASC",
  GoogleUrlDesc = "GOOGLE_URL_DESC",
  GrowthStageAsc = "GROWTH_STAGE_ASC",
  GrowthStageDesc = "GROWTH_STAGE_DESC",
  HasPromisingFounderAsc = "HAS_PROMISING_FOUNDER_ASC",
  HasPromisingFounderDesc = "HAS_PROMISING_FOUNDER_DESC",
  HasStrongFounderAsc = "HAS_STRONG_FOUNDER_ASC",
  HasStrongFounderDesc = "HAS_STRONG_FOUNDER_DESC",
  HasSuperFounderAsc = "HAS_SUPER_FOUNDER_ASC",
  HasSuperFounderDesc = "HAS_SUPER_FOUNDER_DESC",
  HqLocationsAsc = "HQ_LOCATIONS_ASC",
  HqLocationsDesc = "HQ_LOCATIONS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IncomeStreamsAsc = "INCOME_STREAMS_ASC",
  IncomeStreamsDesc = "INCOME_STREAMS_DESC",
  IndustriesAsc = "INDUSTRIES_ASC",
  IndustriesDesc = "INDUSTRIES_DESC",
  InvestorsAsc = "INVESTORS_ASC",
  InvestorsDesc = "INVESTORS_DESC",
  JobOpeningsAsc = "JOB_OPENINGS_ASC",
  JobOpeningsDesc = "JOB_OPENINGS_DESC",
  KpiSummaryAsc = "KPI_SUMMARY_ASC",
  KpiSummaryDesc = "KPI_SUMMARY_DESC",
  LastFundingAsc = "LAST_FUNDING_ASC",
  LastFundingDateAsc = "LAST_FUNDING_DATE_ASC",
  LastFundingDateDesc = "LAST_FUNDING_DATE_DESC",
  LastFundingDesc = "LAST_FUNDING_DESC",
  LastFundingSourceAsc = "LAST_FUNDING_SOURCE_ASC",
  LastFundingSourceDesc = "LAST_FUNDING_SOURCE_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LaunchMonthAsc = "LAUNCH_MONTH_ASC",
  LaunchMonthDesc = "LAUNCH_MONTH_DESC",
  LaunchYearAsc = "LAUNCH_YEAR_ASC",
  LaunchYearDesc = "LAUNCH_YEAR_DESC",
  LegalEntitiesAsc = "LEGAL_ENTITIES_ASC",
  LegalEntitiesDesc = "LEGAL_ENTITIES_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  NameFuzzyAsc = "NAME_FUZZY_ASC",
  NameFuzzyDesc = "NAME_FUZZY_DESC",
  Natural = "NATURAL",
  NzrOpportunityEmailCandidatesAverageDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesAverageDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesAverageDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesAverageDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesAverageEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesAverageEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesAverageIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_ID_ASC",
  NzrOpportunityEmailCandidatesAverageIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_ID_DESC",
  NzrOpportunityEmailCandidatesAverageLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesAverageLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesAverageNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_NAME_ASC",
  NzrOpportunityEmailCandidatesAverageNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_NAME_DESC",
  NzrOpportunityEmailCandidatesAverageUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_URL_ASC",
  NzrOpportunityEmailCandidatesAverageUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_URL_DESC",
  NzrOpportunityEmailCandidatesCountAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_COUNT_ASC",
  NzrOpportunityEmailCandidatesCountDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_COUNT_DESC",
  NzrOpportunityEmailCandidatesDistinctCountDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesDistinctCountDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesDistinctCountEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_NAME_ASC",
  NzrOpportunityEmailCandidatesDistinctCountNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_NAME_DESC",
  NzrOpportunityEmailCandidatesDistinctCountUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_URL_ASC",
  NzrOpportunityEmailCandidatesDistinctCountUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_URL_DESC",
  NzrOpportunityEmailCandidatesMaxDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMaxDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMaxDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesMaxDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesMaxEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesMaxEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesMaxIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_ID_ASC",
  NzrOpportunityEmailCandidatesMaxIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_ID_DESC",
  NzrOpportunityEmailCandidatesMaxLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMaxLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMaxNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_NAME_ASC",
  NzrOpportunityEmailCandidatesMaxNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_NAME_DESC",
  NzrOpportunityEmailCandidatesMaxUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_URL_ASC",
  NzrOpportunityEmailCandidatesMaxUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_URL_DESC",
  NzrOpportunityEmailCandidatesMinDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMinDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMinDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesMinDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesMinEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesMinEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesMinIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_ID_ASC",
  NzrOpportunityEmailCandidatesMinIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_ID_DESC",
  NzrOpportunityEmailCandidatesMinLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMinLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMinNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_NAME_ASC",
  NzrOpportunityEmailCandidatesMinNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_NAME_DESC",
  NzrOpportunityEmailCandidatesMinUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_URL_ASC",
  NzrOpportunityEmailCandidatesMinUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_URL_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesStddevSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesStddevSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesStddevSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesStddevSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesStddevSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesStddevSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_URL_DESC",
  NzrOpportunityEmailCandidatesSumDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesSumDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesSumDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesSumDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesSumEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesSumEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesSumIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_ID_ASC",
  NzrOpportunityEmailCandidatesSumIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_ID_DESC",
  NzrOpportunityEmailCandidatesSumLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesSumLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesSumNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_NAME_ASC",
  NzrOpportunityEmailCandidatesSumNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_NAME_DESC",
  NzrOpportunityEmailCandidatesSumUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_URL_ASC",
  NzrOpportunityEmailCandidatesSumUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_URL_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_URL_DESC",
  OwnershipsAsc = "OWNERSHIPS_ASC",
  OwnershipsDesc = "OWNERSHIPS_DESC",
  PatentsCountAsc = "PATENTS_COUNT_ASC",
  PatentsCountDesc = "PATENTS_COUNT_DESC",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PlaymarketAppIdAsc = "PLAYMARKET_APP_ID_ASC",
  PlaymarketAppIdDesc = "PLAYMARKET_APP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RevenuesAsc = "REVENUES_ASC",
  RevenuesDesc = "REVENUES_DESC",
  ServiceIndustriesAsc = "SERVICE_INDUSTRIES_ASC",
  ServiceIndustriesDesc = "SERVICE_INDUSTRIES_DESC",
  SubIndustriesAsc = "SUB_INDUSTRIES_ASC",
  SubIndustriesDesc = "SUB_INDUSTRIES_DESC",
  SustainableDevelopmentGoalsAsc = "SUSTAINABLE_DEVELOPMENT_GOALS_ASC",
  SustainableDevelopmentGoalsDesc = "SUSTAINABLE_DEVELOPMENT_GOALS_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TeamAsc = "TEAM_ASC",
  TeamDesc = "TEAM_DESC",
  TechnologiesAsc = "TECHNOLOGIES_ASC",
  TechnologiesDesc = "TECHNOLOGIES_DESC",
  TechStackPredictleadsAsc = "TECH_STACK_PREDICTLEADS_ASC",
  TechStackPredictleadsDesc = "TECH_STACK_PREDICTLEADS_DESC",
  TotalFundingAsc = "TOTAL_FUNDING_ASC",
  TotalFundingDesc = "TOTAL_FUNDING_DESC",
  TotalFundingSourceAsc = "TOTAL_FUNDING_SOURCE_ASC",
  TotalFundingSourceDesc = "TOTAL_FUNDING_SOURCE_DESC",
  TwitterFavoritesChartAsc = "TWITTER_FAVORITES_CHART_ASC",
  TwitterFavoritesChartDesc = "TWITTER_FAVORITES_CHART_DESC",
  TwitterFollowersChartAsc = "TWITTER_FOLLOWERS_CHART_ASC",
  TwitterFollowersChartDesc = "TWITTER_FOLLOWERS_CHART_DESC",
  TwitterTweetsChartAsc = "TWITTER_TWEETS_CHART_ASC",
  TwitterTweetsChartDesc = "TWITTER_TWEETS_CHART_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
  WebsiteDomainFullAsc = "WEBSITE_DOMAIN_FULL_ASC",
  WebsiteDomainFullDesc = "WEBSITE_DOMAIN_FULL_DESC",
  WebsiteDomainFullFuzzyAsc = "WEBSITE_DOMAIN_FULL_FUZZY_ASC",
  WebsiteDomainFullFuzzyDesc = "WEBSITE_DOMAIN_FULL_FUZZY_DESC",
  WebsiteTraffic_3MonthsGrowthDeltaAsc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_DELTA_ASC",
  WebsiteTraffic_3MonthsGrowthDeltaDesc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_DELTA_DESC",
  WebsiteTraffic_3MonthsGrowthPercentileAsc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_PERCENTILE_ASC",
  WebsiteTraffic_3MonthsGrowthPercentileDesc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_PERCENTILE_DESC",
  WebsiteTraffic_3MonthsGrowthRelativeAsc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_RELATIVE_ASC",
  WebsiteTraffic_3MonthsGrowthRelativeDesc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_RELATIVE_DESC",
  WebsiteTraffic_3MonthsGrowthUniqueAsc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_UNIQUE_ASC",
  WebsiteTraffic_3MonthsGrowthUniqueDesc = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_UNIQUE_DESC",
  WebsiteTraffic_6MonthsGrowthDeltaAsc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_DELTA_ASC",
  WebsiteTraffic_6MonthsGrowthDeltaDesc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_DELTA_DESC",
  WebsiteTraffic_6MonthsGrowthPercentileAsc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_PERCENTILE_ASC",
  WebsiteTraffic_6MonthsGrowthPercentileDesc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_PERCENTILE_DESC",
  WebsiteTraffic_6MonthsGrowthRelativeAsc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_RELATIVE_ASC",
  WebsiteTraffic_6MonthsGrowthRelativeDesc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_RELATIVE_DESC",
  WebsiteTraffic_6MonthsGrowthUniqueAsc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_UNIQUE_ASC",
  WebsiteTraffic_6MonthsGrowthUniqueDesc = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_UNIQUE_DESC",
  WebsiteTraffic_12MonthsGrowthDeltaAsc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_DELTA_ASC",
  WebsiteTraffic_12MonthsGrowthDeltaDesc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_DELTA_DESC",
  WebsiteTraffic_12MonthsGrowthPercentileAsc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_PERCENTILE_ASC",
  WebsiteTraffic_12MonthsGrowthPercentileDesc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_PERCENTILE_DESC",
  WebsiteTraffic_12MonthsGrowthRelativeAsc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_RELATIVE_ASC",
  WebsiteTraffic_12MonthsGrowthRelativeDesc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_RELATIVE_DESC",
  WebsiteTraffic_12MonthsGrowthUniqueAsc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_UNIQUE_ASC",
  WebsiteTraffic_12MonthsGrowthUniqueDesc = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_UNIQUE_DESC",
  WebsiteTrafficEstimatesChartAsc = "WEBSITE_TRAFFIC_ESTIMATES_CHART_ASC",
  WebsiteTrafficEstimatesChartDesc = "WEBSITE_TRAFFIC_ESTIMATES_CHART_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type DealroomCompany = Node & {
  __typename?: "DealroomCompany";
  about?: Maybe<Scalars["String"]["output"]>;
  angellistUrl?: Maybe<Scalars["String"]["output"]>;
  app3MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  app3MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  app3MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  app6MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  app6MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  app6MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  app12MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  app12MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  app12MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  appDownloadsAndroidChart?: Maybe<Scalars["JSON"]["output"]>;
  appDownloadsAndroidIncrementalChart?: Maybe<Scalars["JSON"]["output"]>;
  appDownloadsIosChart?: Maybe<Scalars["JSON"]["output"]>;
  appDownloadsIosIncrementalChart?: Maybe<Scalars["JSON"]["output"]>;
  appstoreAppId?: Maybe<Scalars["String"]["output"]>;
  clientFocus?: Maybe<Scalars["JSON"]["output"]>;
  companyStatus?: Maybe<Scalars["String"]["output"]>;
  coreSideValue?: Maybe<Scalars["String"]["output"]>;
  corporateIndustries?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  crunchbaseUrl?: Maybe<Scalars["String"]["output"]>;
  dataType?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompanyInvestor`. */
  dealroomCompanyInvestorsByCompanyId: DealroomCompanyInvestorsConnection;
  /** Reads and enables pagination through a set of `DealroomCompanyTeam`. */
  dealroomCompanyTeamsByCompanyId: DealroomCompanyTeamsConnection;
  /** Reads and enables pagination through a set of `DealroomFounder`. */
  dealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberId: DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyConnection;
  /** Reads and enables pagination through a set of `DealroomFundingRound`. */
  dealroomFundingRoundsByCompanyId: DealroomFundingRoundsConnection;
  /** Reads and enables pagination through a set of `DealroomInvestor`. */
  dealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorId: DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection;
  /** Reads and enables pagination through a set of `DealroomNews`. */
  dealroomNewsByCompanyId: DealroomNewsConnection;
  dealroomSignal?: Maybe<Scalars["JSON"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  deliveryMethod?: Maybe<Scalars["String"]["output"]>;
  employee3MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  employee3MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  employee3MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  employee3MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  employee6MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  employee6MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  employee6MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  employee6MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  employee12MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  employee12MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  employee12MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  employee12MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  employees?: Maybe<Scalars["String"]["output"]>;
  employeesChart?: Maybe<Scalars["JSON"]["output"]>;
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  facebookLikesChart?: Maybe<Scalars["JSON"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  fullText?: Maybe<Scalars["FullText"]["output"]>;
  /** Full-text search ranking when filtered by `fullText`. */
  fullTextRank?: Maybe<Scalars["Float"]["output"]>;
  fundings?: Maybe<Scalars["JSON"]["output"]>;
  googleUrl?: Maybe<Scalars["String"]["output"]>;
  growthStage?: Maybe<Scalars["String"]["output"]>;
  hasPromisingFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasStrongFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasSuperFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hqLocations?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["BigInt"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  incomeStreams?: Maybe<Scalars["JSON"]["output"]>;
  industries?: Maybe<Scalars["JSON"]["output"]>;
  investors?: Maybe<Scalars["JSON"]["output"]>;
  jobOpenings?: Maybe<Scalars["Int"]["output"]>;
  kpiSummary?: Maybe<Scalars["JSON"]["output"]>;
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  lastFundingDate?: Maybe<Scalars["String"]["output"]>;
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  legalEntities?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyId: DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyConnection;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Fuzzy-text search ranking when filtered by `name`. */
  nameFuzzyScore?: Maybe<Scalars["Float"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
  /** Reads and enables pagination through a set of `NzrOpportunityEmail`. */
  nzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailId: DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyConnection;
  ownerships?: Maybe<Scalars["JSON"]["output"]>;
  patentsCount?: Maybe<Scalars["Int"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  playmarketAppId?: Maybe<Scalars["String"]["output"]>;
  revenues?: Maybe<Scalars["JSON"]["output"]>;
  serviceIndustries?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads through a `SrcCompany`. */
  srcCompany?: Maybe<SrcCompany>;
  subIndustries?: Maybe<Scalars["JSON"]["output"]>;
  sustainableDevelopmentGoals?: Maybe<Scalars["JSON"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
  team?: Maybe<Scalars["JSON"]["output"]>;
  techStackPredictleads?: Maybe<Scalars["JSON"]["output"]>;
  technologies?: Maybe<Scalars["JSON"]["output"]>;
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  twitterFavoritesChart?: Maybe<Scalars["JSON"]["output"]>;
  twitterFollowersChart?: Maybe<Scalars["JSON"]["output"]>;
  twitterTweetsChart?: Maybe<Scalars["JSON"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteDomainFull?: Maybe<Scalars["String"]["output"]>;
  /** Fuzzy-text search ranking when filtered by `websiteDomainFull`. */
  websiteDomainFullFuzzyScore?: Maybe<Scalars["Float"]["output"]>;
  websiteTraffic3MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic3MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic3MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic3MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic6MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic6MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic6MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic6MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic12MonthsGrowthDelta?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic12MonthsGrowthPercentile?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic12MonthsGrowthRelative?: Maybe<Scalars["String"]["output"]>;
  websiteTraffic12MonthsGrowthUnique?: Maybe<Scalars["String"]["output"]>;
  websiteTrafficEstimatesChart?: Maybe<Scalars["JSON"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type DealroomCompanyDealroomCompanyInvestorsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyInvestorCondition>;
  filter?: InputMaybe<DealroomCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyInvestorsOrderBy>>;
};

export type DealroomCompanyDealroomCompanyTeamsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyTeamCondition>;
  filter?: InputMaybe<DealroomCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyTeamsOrderBy>>;
};

export type DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFounderCondition>;
  filter?: InputMaybe<DealroomFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFoundersOrderBy>>;
};

export type DealroomCompanyDealroomFundingRoundsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundCondition>;
  filter?: InputMaybe<DealroomFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundsOrderBy>>;
};

export type DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomInvestorCondition>;
  filter?: InputMaybe<DealroomInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomInvestorsOrderBy>>;
};

export type DealroomCompanyDealroomNewsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomNewsCondition>;
  filter?: InputMaybe<DealroomNewsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomNewsOrderBy>>;
};

export type DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type DealroomCompanyNzrOpportunityEmailCandidatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
  filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
};

export type DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCondition>;
  filter?: InputMaybe<NzrOpportunityEmailFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailsOrderBy>>;
};

export type DealroomCompanySrcCompanyArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type DealroomCompanyAggregates = {
  __typename?: "DealroomCompanyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomCompanyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomCompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomCompanyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomCompanyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomCompanyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomCompanyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomCompanySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomCompanyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomCompanyVarianceSampleAggregates>;
};

export type DealroomCompanyAverageAggregates = {
  __typename?: "DealroomCompanyAverageAggregates";
  /** Mean average of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomCompany` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealroomCompanyCondition = {
  /** Checks for equality with the object’s `about` field. */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app3MonthsGrowthPercentile` field. */
  app3MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app3MonthsGrowthRelative` field. */
  app3MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app3MonthsGrowthUnique` field. */
  app3MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app6MonthsGrowthPercentile` field. */
  app6MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app6MonthsGrowthRelative` field. */
  app6MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app6MonthsGrowthUnique` field. */
  app6MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app12MonthsGrowthPercentile` field. */
  app12MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app12MonthsGrowthRelative` field. */
  app12MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `app12MonthsGrowthUnique` field. */
  app12MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `appDownloadsAndroidChart` field. */
  appDownloadsAndroidChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `appDownloadsAndroidIncrementalChart` field. */
  appDownloadsAndroidIncrementalChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `appDownloadsIosChart` field. */
  appDownloadsIosChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `appDownloadsIosIncrementalChart` field. */
  appDownloadsIosIncrementalChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `coreSideValue` field. */
  coreSideValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `corporateIndustries` field. */
  corporateIndustries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dataType` field. */
  dataType?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignal` field. */
  dealroomSignal?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `deliveryMethod` field. */
  deliveryMethod?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee3MonthsGrowthDelta` field. */
  employee3MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee3MonthsGrowthPercentile` field. */
  employee3MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee3MonthsGrowthRelative` field. */
  employee3MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee3MonthsGrowthUnique` field. */
  employee3MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee6MonthsGrowthDelta` field. */
  employee6MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee6MonthsGrowthPercentile` field. */
  employee6MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee6MonthsGrowthRelative` field. */
  employee6MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee6MonthsGrowthUnique` field. */
  employee6MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee12MonthsGrowthDelta` field. */
  employee12MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee12MonthsGrowthPercentile` field. */
  employee12MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee12MonthsGrowthRelative` field. */
  employee12MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employee12MonthsGrowthUnique` field. */
  employee12MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employees` field. */
  employees?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `facebookLikesChart` field. */
  facebookLikesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fullText` field. */
  fullText?: InputMaybe<Scalars["FullText"]["input"]>;
  /** Checks for equality with the object’s `fundings` field. */
  fundings?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `growthStage` field. */
  growthStage?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `incomeStreams` field. */
  incomeStreams?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `industries` field. */
  industries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investors` field. */
  investors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `jobOpenings` field. */
  jobOpenings?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `kpiSummary` field. */
  kpiSummary?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `launchYear` field. */
  launchYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `legalEntities` field. */
  legalEntities?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ownerships` field. */
  ownerships?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `patentsCount` field. */
  patentsCount?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revenues` field. */
  revenues?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `serviceIndustries` field. */
  serviceIndustries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `subIndustries` field. */
  subIndustries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `sustainableDevelopmentGoals` field. */
  sustainableDevelopmentGoals?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `team` field. */
  team?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `technologies` field. */
  technologies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `twitterFavoritesChart` field. */
  twitterFavoritesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `twitterFollowersChart` field. */
  twitterFollowersChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `twitterTweetsChart` field. */
  twitterTweetsChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteDomainFull` field. */
  websiteDomainFull?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic3MonthsGrowthDelta` field. */
  websiteTraffic3MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic3MonthsGrowthPercentile` field. */
  websiteTraffic3MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic3MonthsGrowthRelative` field. */
  websiteTraffic3MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic3MonthsGrowthUnique` field. */
  websiteTraffic3MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic6MonthsGrowthDelta` field. */
  websiteTraffic6MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic6MonthsGrowthPercentile` field. */
  websiteTraffic6MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic6MonthsGrowthRelative` field. */
  websiteTraffic6MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic6MonthsGrowthUnique` field. */
  websiteTraffic6MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic12MonthsGrowthDelta` field. */
  websiteTraffic12MonthsGrowthDelta?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic12MonthsGrowthPercentile` field. */
  websiteTraffic12MonthsGrowthPercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic12MonthsGrowthRelative` field. */
  websiteTraffic12MonthsGrowthRelative?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTraffic12MonthsGrowthUnique` field. */
  websiteTraffic12MonthsGrowthUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTrafficEstimatesChart` field. */
  websiteTrafficEstimatesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DealroomFounder` values, with data from `DealroomCompanyTeam`. */
export type DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyConnection = {
  __typename?: "DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomFounderAggregates>;
  /** A list of edges which contains the `DealroomFounder`, info from the `DealroomCompanyTeam`, and the cursor to aid in pagination. */
  edges: Array<DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomFounderAggregates>>;
  /** A list of `DealroomFounder` objects. */
  nodes: Array<DealroomFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomFounder` values, with data from `DealroomCompanyTeam`. */
export type DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomFounderGroupBy>;
    having?: InputMaybe<DealroomFounderHavingInput>;
  };

/** A `DealroomFounder` edge in the connection, with data from `DealroomCompanyTeam`. */
export type DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyEdge = {
  __typename?: "DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompanyTeam`. */
  dealroomCompanyTeamsByMemberId: DealroomCompanyTeamsConnection;
  /** The `DealroomFounder` at the end of the edge. */
  node: DealroomFounder;
};

/** A `DealroomFounder` edge in the connection, with data from `DealroomCompanyTeam`. */
export type DealroomCompanyDealroomFoundersByDealroomCompanyTeamCompanyIdAndMemberIdManyToManyEdgeDealroomCompanyTeamsByMemberIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomCompanyTeamCondition>;
    filter?: InputMaybe<DealroomCompanyTeamFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomCompanyTeamsOrderBy>>;
  };

/** A connection to a list of `DealroomInvestor` values, with data from `DealroomCompanyInvestor`. */
export type DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection = {
  __typename?: "DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomInvestorAggregates>;
  /** A list of edges which contains the `DealroomInvestor`, info from the `DealroomCompanyInvestor`, and the cursor to aid in pagination. */
  edges: Array<DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomInvestorAggregates>>;
  /** A list of `DealroomInvestor` objects. */
  nodes: Array<DealroomInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomInvestor` values, with data from `DealroomCompanyInvestor`. */
export type DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomInvestorGroupBy>;
    having?: InputMaybe<DealroomInvestorHavingInput>;
  };

/** A `DealroomInvestor` edge in the connection, with data from `DealroomCompanyInvestor`. */
export type DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge = {
  __typename?: "DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompanyInvestor`. */
  dealroomCompanyInvestorsByInvestorId: DealroomCompanyInvestorsConnection;
  /** The `DealroomInvestor` at the end of the edge. */
  node: DealroomInvestor;
};

/** A `DealroomInvestor` edge in the connection, with data from `DealroomCompanyInvestor`. */
export type DealroomCompanyDealroomInvestorsByDealroomCompanyInvestorCompanyIdAndInvestorIdManyToManyEdgeDealroomCompanyInvestorsByInvestorIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomCompanyInvestorCondition>;
    filter?: InputMaybe<DealroomCompanyInvestorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomCompanyInvestorsOrderBy>>;
  };

export type DealroomCompanyDistinctCountAggregates = {
  __typename?: "DealroomCompanyDistinctCountAggregates";
  /** Distinct count of about across the matching connection */
  about?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of angellistUrl across the matching connection */
  angellistUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app3MonthsGrowthPercentile across the matching connection */
  app3MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app3MonthsGrowthRelative across the matching connection */
  app3MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app3MonthsGrowthUnique across the matching connection */
  app3MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app6MonthsGrowthPercentile across the matching connection */
  app6MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app6MonthsGrowthRelative across the matching connection */
  app6MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app6MonthsGrowthUnique across the matching connection */
  app6MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app12MonthsGrowthPercentile across the matching connection */
  app12MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app12MonthsGrowthRelative across the matching connection */
  app12MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of app12MonthsGrowthUnique across the matching connection */
  app12MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appDownloadsAndroidChart across the matching connection */
  appDownloadsAndroidChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appDownloadsAndroidIncrementalChart across the matching connection */
  appDownloadsAndroidIncrementalChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appDownloadsIosChart across the matching connection */
  appDownloadsIosChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appDownloadsIosIncrementalChart across the matching connection */
  appDownloadsIosIncrementalChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appstoreAppId across the matching connection */
  appstoreAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of clientFocus across the matching connection */
  clientFocus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyStatus across the matching connection */
  companyStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of coreSideValue across the matching connection */
  coreSideValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of corporateIndustries across the matching connection */
  corporateIndustries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dataType across the matching connection */
  dataType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignal across the matching connection */
  dealroomSignal?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deliveryMethod across the matching connection */
  deliveryMethod?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee3MonthsGrowthDelta across the matching connection */
  employee3MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee3MonthsGrowthPercentile across the matching connection */
  employee3MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee3MonthsGrowthRelative across the matching connection */
  employee3MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee3MonthsGrowthUnique across the matching connection */
  employee3MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee6MonthsGrowthDelta across the matching connection */
  employee6MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee6MonthsGrowthPercentile across the matching connection */
  employee6MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee6MonthsGrowthRelative across the matching connection */
  employee6MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee6MonthsGrowthUnique across the matching connection */
  employee6MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee12MonthsGrowthDelta across the matching connection */
  employee12MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee12MonthsGrowthPercentile across the matching connection */
  employee12MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee12MonthsGrowthRelative across the matching connection */
  employee12MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employee12MonthsGrowthUnique across the matching connection */
  employee12MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employees across the matching connection */
  employees?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesChart across the matching connection */
  employeesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookLikesChart across the matching connection */
  facebookLikesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fullText across the matching connection */
  fullText?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundings across the matching connection */
  fundings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleUrl across the matching connection */
  googleUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of growthStage across the matching connection */
  growthStage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasPromisingFounder across the matching connection */
  hasPromisingFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasStrongFounder across the matching connection */
  hasStrongFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasSuperFounder across the matching connection */
  hasSuperFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqLocations across the matching connection */
  hqLocations?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of incomeStreams across the matching connection */
  incomeStreams?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of industries across the matching connection */
  industries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investors across the matching connection */
  investors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of kpiSummary across the matching connection */
  kpiSummary?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingDate across the matching connection */
  lastFundingDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of legalEntities across the matching connection */
  legalEntities?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ownerships across the matching connection */
  ownerships?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of playmarketAppId across the matching connection */
  playmarketAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revenues across the matching connection */
  revenues?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of serviceIndustries across the matching connection */
  serviceIndustries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subIndustries across the matching connection */
  subIndustries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sustainableDevelopmentGoals across the matching connection */
  sustainableDevelopmentGoals?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of team across the matching connection */
  team?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of techStackPredictleads across the matching connection */
  techStackPredictleads?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of technologies across the matching connection */
  technologies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterFavoritesChart across the matching connection */
  twitterFavoritesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterFollowersChart across the matching connection */
  twitterFollowersChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterTweetsChart across the matching connection */
  twitterTweetsChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteDomainFull across the matching connection */
  websiteDomainFull?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic3MonthsGrowthDelta across the matching connection */
  websiteTraffic3MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic3MonthsGrowthPercentile across the matching connection */
  websiteTraffic3MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic3MonthsGrowthRelative across the matching connection */
  websiteTraffic3MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic3MonthsGrowthUnique across the matching connection */
  websiteTraffic3MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic6MonthsGrowthDelta across the matching connection */
  websiteTraffic6MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic6MonthsGrowthPercentile across the matching connection */
  websiteTraffic6MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic6MonthsGrowthRelative across the matching connection */
  websiteTraffic6MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic6MonthsGrowthUnique across the matching connection */
  websiteTraffic6MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic12MonthsGrowthDelta across the matching connection */
  websiteTraffic12MonthsGrowthDelta?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic12MonthsGrowthPercentile across the matching connection */
  websiteTraffic12MonthsGrowthPercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic12MonthsGrowthRelative across the matching connection */
  websiteTraffic12MonthsGrowthRelative?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTraffic12MonthsGrowthUnique across the matching connection */
  websiteTraffic12MonthsGrowthUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTrafficEstimatesChart across the matching connection */
  websiteTrafficEstimatesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomCompany` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyFilter = {
  /** Filter by the object’s `about` field. */
  about?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomCompanyFilter>>;
  /** Filter by the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app3MonthsGrowthPercentile` field. */
  app3MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app3MonthsGrowthRelative` field. */
  app3MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app3MonthsGrowthUnique` field. */
  app3MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app6MonthsGrowthPercentile` field. */
  app6MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app6MonthsGrowthRelative` field. */
  app6MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app6MonthsGrowthUnique` field. */
  app6MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app12MonthsGrowthPercentile` field. */
  app12MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app12MonthsGrowthRelative` field. */
  app12MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `app12MonthsGrowthUnique` field. */
  app12MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `appDownloadsAndroidChart` field. */
  appDownloadsAndroidChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `appDownloadsAndroidIncrementalChart` field. */
  appDownloadsAndroidIncrementalChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `appDownloadsIosChart` field. */
  appDownloadsIosChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `appDownloadsIosIncrementalChart` field. */
  appDownloadsIosIncrementalChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coreSideValue` field. */
  coreSideValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `corporateIndustries` field. */
  corporateIndustries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataType` field. */
  dataType?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dealroomCompanyInvestorsByCompanyId` relation. */
  dealroomCompanyInvestorsByCompanyId?: InputMaybe<DealroomCompanyToManyDealroomCompanyInvestorFilter>;
  /** Some related `dealroomCompanyInvestorsByCompanyId` exist. */
  dealroomCompanyInvestorsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomCompanyTeamsByCompanyId` relation. */
  dealroomCompanyTeamsByCompanyId?: InputMaybe<DealroomCompanyToManyDealroomCompanyTeamFilter>;
  /** Some related `dealroomCompanyTeamsByCompanyId` exist. */
  dealroomCompanyTeamsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomFundingRoundsByCompanyId` relation. */
  dealroomFundingRoundsByCompanyId?: InputMaybe<DealroomCompanyToManyDealroomFundingRoundFilter>;
  /** Some related `dealroomFundingRoundsByCompanyId` exist. */
  dealroomFundingRoundsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomNewsByCompanyId` relation. */
  dealroomNewsByCompanyId?: InputMaybe<DealroomCompanyToManyDealroomNewsFilter>;
  /** Some related `dealroomNewsByCompanyId` exist. */
  dealroomNewsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomSignal` field. */
  dealroomSignal?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `deliveryMethod` field. */
  deliveryMethod?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee3MonthsGrowthDelta` field. */
  employee3MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee3MonthsGrowthPercentile` field. */
  employee3MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee3MonthsGrowthRelative` field. */
  employee3MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee3MonthsGrowthUnique` field. */
  employee3MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee6MonthsGrowthDelta` field. */
  employee6MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee6MonthsGrowthPercentile` field. */
  employee6MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee6MonthsGrowthRelative` field. */
  employee6MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee6MonthsGrowthUnique` field. */
  employee6MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee12MonthsGrowthDelta` field. */
  employee12MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee12MonthsGrowthPercentile` field. */
  employee12MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee12MonthsGrowthRelative` field. */
  employee12MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employee12MonthsGrowthUnique` field. */
  employee12MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employees` field. */
  employees?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<IntFilter>;
  /** Filter by the object’s `facebookLikesChart` field. */
  facebookLikesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullText` field. */
  fullText?: InputMaybe<FullTextFilter>;
  /** Filter by the object’s `fundings` field. */
  fundings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `growthStage` field. */
  growthStage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `incomeStreams` field. */
  incomeStreams?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `industries` field. */
  industries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investors` field. */
  investors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jobOpenings` field. */
  jobOpenings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `kpiSummary` field. */
  kpiSummary?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `launchYear` field. */
  launchYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `legalEntities` field. */
  legalEntities?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomCompanyFilter>;
  /** Filter by the object’s `nzrOpportunityEmailCandidates` relation. */
  nzrOpportunityEmailCandidates?: InputMaybe<DealroomCompanyToManyNzrOpportunityEmailCandidateFilter>;
  /** Some related `nzrOpportunityEmailCandidates` exist. */
  nzrOpportunityEmailCandidatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomCompanyFilter>>;
  /** Filter by the object’s `ownerships` field. */
  ownerships?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `patentsCount` field. */
  patentsCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revenues` field. */
  revenues?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `serviceIndustries` field. */
  serviceIndustries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `srcCompany` polymorphic relation. */
  srcCompany?: InputMaybe<SrcCompanyFilter>;
  /** Filter for if the object’s `srcCompany`               polymorphic relation exist. */
  srcCompanyExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `subIndustries` field. */
  subIndustries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `sustainableDevelopmentGoals` field. */
  sustainableDevelopmentGoals?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `team` field. */
  team?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `technologies` field. */
  technologies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `twitterFavoritesChart` field. */
  twitterFavoritesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `twitterFollowersChart` field. */
  twitterFollowersChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `twitterTweetsChart` field. */
  twitterTweetsChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteDomainFull` field. */
  websiteDomainFull?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic3MonthsGrowthDelta` field. */
  websiteTraffic3MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic3MonthsGrowthPercentile` field. */
  websiteTraffic3MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic3MonthsGrowthRelative` field. */
  websiteTraffic3MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic3MonthsGrowthUnique` field. */
  websiteTraffic3MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic6MonthsGrowthDelta` field. */
  websiteTraffic6MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic6MonthsGrowthPercentile` field. */
  websiteTraffic6MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic6MonthsGrowthRelative` field. */
  websiteTraffic6MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic6MonthsGrowthUnique` field. */
  websiteTraffic6MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic12MonthsGrowthDelta` field. */
  websiteTraffic12MonthsGrowthDelta?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic12MonthsGrowthPercentile` field. */
  websiteTraffic12MonthsGrowthPercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic12MonthsGrowthRelative` field. */
  websiteTraffic12MonthsGrowthRelative?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTraffic12MonthsGrowthUnique` field. */
  websiteTraffic12MonthsGrowthUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTrafficEstimatesChart` field. */
  websiteTrafficEstimatesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DealroomCompany` for usage during aggregation. */
export enum DealroomCompanyGroupBy {
  About = "ABOUT",
  AngellistUrl = "ANGELLIST_URL",
  AppstoreAppId = "APPSTORE_APP_ID",
  App_3MonthsGrowthPercentile = "APP_3_MONTHS_GROWTH_PERCENTILE",
  App_3MonthsGrowthRelative = "APP_3_MONTHS_GROWTH_RELATIVE",
  App_3MonthsGrowthUnique = "APP_3_MONTHS_GROWTH_UNIQUE",
  App_6MonthsGrowthPercentile = "APP_6_MONTHS_GROWTH_PERCENTILE",
  App_6MonthsGrowthRelative = "APP_6_MONTHS_GROWTH_RELATIVE",
  App_6MonthsGrowthUnique = "APP_6_MONTHS_GROWTH_UNIQUE",
  App_12MonthsGrowthPercentile = "APP_12_MONTHS_GROWTH_PERCENTILE",
  App_12MonthsGrowthRelative = "APP_12_MONTHS_GROWTH_RELATIVE",
  App_12MonthsGrowthUnique = "APP_12_MONTHS_GROWTH_UNIQUE",
  AppDownloadsAndroidChart = "APP_DOWNLOADS_ANDROID_CHART",
  AppDownloadsAndroidIncrementalChart = "APP_DOWNLOADS_ANDROID_INCREMENTAL_CHART",
  AppDownloadsIosChart = "APP_DOWNLOADS_IOS_CHART",
  AppDownloadsIosIncrementalChart = "APP_DOWNLOADS_IOS_INCREMENTAL_CHART",
  ClientFocus = "CLIENT_FOCUS",
  CompanyStatus = "COMPANY_STATUS",
  CoreSideValue = "CORE_SIDE_VALUE",
  CorporateIndustries = "CORPORATE_INDUSTRIES",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  CrunchbaseUrl = "CRUNCHBASE_URL",
  DataType = "DATA_TYPE",
  DealroomSignal = "DEALROOM_SIGNAL",
  Deleted = "DELETED",
  DeliveryMethod = "DELIVERY_METHOD",
  Employees = "EMPLOYEES",
  EmployeesChart = "EMPLOYEES_CHART",
  EmployeesLatest = "EMPLOYEES_LATEST",
  Employee_3MonthsGrowthDelta = "EMPLOYEE_3_MONTHS_GROWTH_DELTA",
  Employee_3MonthsGrowthPercentile = "EMPLOYEE_3_MONTHS_GROWTH_PERCENTILE",
  Employee_3MonthsGrowthRelative = "EMPLOYEE_3_MONTHS_GROWTH_RELATIVE",
  Employee_3MonthsGrowthUnique = "EMPLOYEE_3_MONTHS_GROWTH_UNIQUE",
  Employee_6MonthsGrowthDelta = "EMPLOYEE_6_MONTHS_GROWTH_DELTA",
  Employee_6MonthsGrowthPercentile = "EMPLOYEE_6_MONTHS_GROWTH_PERCENTILE",
  Employee_6MonthsGrowthRelative = "EMPLOYEE_6_MONTHS_GROWTH_RELATIVE",
  Employee_6MonthsGrowthUnique = "EMPLOYEE_6_MONTHS_GROWTH_UNIQUE",
  Employee_12MonthsGrowthDelta = "EMPLOYEE_12_MONTHS_GROWTH_DELTA",
  Employee_12MonthsGrowthPercentile = "EMPLOYEE_12_MONTHS_GROWTH_PERCENTILE",
  Employee_12MonthsGrowthRelative = "EMPLOYEE_12_MONTHS_GROWTH_RELATIVE",
  Employee_12MonthsGrowthUnique = "EMPLOYEE_12_MONTHS_GROWTH_UNIQUE",
  FacebookLikesChart = "FACEBOOK_LIKES_CHART",
  FacebookUrl = "FACEBOOK_URL",
  FullText = "FULL_TEXT",
  Fundings = "FUNDINGS",
  GoogleUrl = "GOOGLE_URL",
  GrowthStage = "GROWTH_STAGE",
  HasPromisingFounder = "HAS_PROMISING_FOUNDER",
  HasStrongFounder = "HAS_STRONG_FOUNDER",
  HasSuperFounder = "HAS_SUPER_FOUNDER",
  HqLocations = "HQ_LOCATIONS",
  Image = "IMAGE",
  IncomeStreams = "INCOME_STREAMS",
  Industries = "INDUSTRIES",
  Investors = "INVESTORS",
  JobOpenings = "JOB_OPENINGS",
  KpiSummary = "KPI_SUMMARY",
  LastFunding = "LAST_FUNDING",
  LastFundingDate = "LAST_FUNDING_DATE",
  LastFundingSource = "LAST_FUNDING_SOURCE",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LaunchMonth = "LAUNCH_MONTH",
  LaunchYear = "LAUNCH_YEAR",
  LegalEntities = "LEGAL_ENTITIES",
  LinkedinUrl = "LINKEDIN_URL",
  Name = "NAME",
  Ownerships = "OWNERSHIPS",
  PatentsCount = "PATENTS_COUNT",
  Path = "PATH",
  PlaymarketAppId = "PLAYMARKET_APP_ID",
  Revenues = "REVENUES",
  ServiceIndustries = "SERVICE_INDUSTRIES",
  SubIndustries = "SUB_INDUSTRIES",
  SustainableDevelopmentGoals = "SUSTAINABLE_DEVELOPMENT_GOALS",
  Tagline = "TAGLINE",
  Tags = "TAGS",
  Team = "TEAM",
  Technologies = "TECHNOLOGIES",
  TechStackPredictleads = "TECH_STACK_PREDICTLEADS",
  TotalFunding = "TOTAL_FUNDING",
  TotalFundingSource = "TOTAL_FUNDING_SOURCE",
  TwitterFavoritesChart = "TWITTER_FAVORITES_CHART",
  TwitterFollowersChart = "TWITTER_FOLLOWERS_CHART",
  TwitterTweetsChart = "TWITTER_TWEETS_CHART",
  TwitterUrl = "TWITTER_URL",
  Type = "TYPE",
  Url = "URL",
  WebsiteDomainFull = "WEBSITE_DOMAIN_FULL",
  WebsiteTraffic_3MonthsGrowthDelta = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_DELTA",
  WebsiteTraffic_3MonthsGrowthPercentile = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_PERCENTILE",
  WebsiteTraffic_3MonthsGrowthRelative = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_RELATIVE",
  WebsiteTraffic_3MonthsGrowthUnique = "WEBSITE_TRAFFIC_3_MONTHS_GROWTH_UNIQUE",
  WebsiteTraffic_6MonthsGrowthDelta = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_DELTA",
  WebsiteTraffic_6MonthsGrowthPercentile = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_PERCENTILE",
  WebsiteTraffic_6MonthsGrowthRelative = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_RELATIVE",
  WebsiteTraffic_6MonthsGrowthUnique = "WEBSITE_TRAFFIC_6_MONTHS_GROWTH_UNIQUE",
  WebsiteTraffic_12MonthsGrowthDelta = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_DELTA",
  WebsiteTraffic_12MonthsGrowthPercentile = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_PERCENTILE",
  WebsiteTraffic_12MonthsGrowthRelative = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_RELATIVE",
  WebsiteTraffic_12MonthsGrowthUnique = "WEBSITE_TRAFFIC_12_MONTHS_GROWTH_UNIQUE",
  WebsiteTrafficEstimatesChart = "WEBSITE_TRAFFIC_ESTIMATES_CHART",
  WebsiteUrl = "WEBSITE_URL",
}

export type DealroomCompanyHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

/** Conditions for `DealroomCompany` aggregates. */
export type DealroomCompanyHavingInput = {
  AND?: InputMaybe<Array<DealroomCompanyHavingInput>>;
  OR?: InputMaybe<Array<DealroomCompanyHavingInput>>;
  average?: InputMaybe<DealroomCompanyHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomCompanyHavingDistinctCountInput>;
  max?: InputMaybe<DealroomCompanyHavingMaxInput>;
  min?: InputMaybe<DealroomCompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomCompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomCompanyHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomCompanyHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomCompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomCompanyHavingVarianceSampleInput>;
};

export type DealroomCompanyHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  jobOpenings?: InputMaybe<HavingIntFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  patentsCount?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomCompanyInvestor = Node & {
  __typename?: "DealroomCompanyInvestor";
  /** Reads a single `DealroomCompany` that is related to this `DealroomCompanyInvestor`. */
  company?: Maybe<DealroomCompany>;
  companyId: Scalars["BigInt"]["output"];
  compositeId: Scalars["String"]["output"];
  exited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Reads a single `DealroomInvestor` that is related to this `DealroomCompanyInvestor`. */
  investor?: Maybe<DealroomInvestor>;
  investorId: Scalars["BigInt"]["output"];
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type DealroomCompanyInvestorAggregates = {
  __typename?: "DealroomCompanyInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomCompanyInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomCompanyInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomCompanyInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomCompanyInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomCompanyInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomCompanyInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomCompanyInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomCompanyInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomCompanyInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DealroomCompanyInvestor` object types. */
export type DealroomCompanyInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `DealroomCompanyInvestor` objects. */
  average?: InputMaybe<DealroomCompanyInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DealroomCompanyInvestor` objects. */
  distinctCount?: InputMaybe<DealroomCompanyInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DealroomCompanyInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** Maximum aggregate over matching `DealroomCompanyInvestor` objects. */
  max?: InputMaybe<DealroomCompanyInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `DealroomCompanyInvestor` objects. */
  min?: InputMaybe<DealroomCompanyInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DealroomCompanyInvestor` objects. */
  stddevPopulation?: InputMaybe<DealroomCompanyInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DealroomCompanyInvestor` objects. */
  stddevSample?: InputMaybe<DealroomCompanyInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DealroomCompanyInvestor` objects. */
  sum?: InputMaybe<DealroomCompanyInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `DealroomCompanyInvestor` objects. */
  variancePopulation?: InputMaybe<DealroomCompanyInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DealroomCompanyInvestor` objects. */
  varianceSample?: InputMaybe<DealroomCompanyInvestorVarianceSampleAggregateFilter>;
};

export type DealroomCompanyInvestorAverageAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorAverageAggregates = {
  __typename?: "DealroomCompanyInvestorAverageAggregates";
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomCompanyInvestor` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DealroomCompanyInvestorCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `exited` field. */
  exited?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `investorId` field. */
  investorId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DealroomCompanyInvestorDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  compositeId?: InputMaybe<BigIntFilter>;
  exited?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
  isEditorial?: InputMaybe<BigIntFilter>;
};

export type DealroomCompanyInvestorDistinctCountAggregates = {
  __typename?: "DealroomCompanyInvestorDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of exited across the matching connection */
  exited?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isEditorial across the matching connection */
  isEditorial?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomCompanyInvestorFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<DealroomCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `exited` field. */
  exited?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `investor` relation. */
  investor?: InputMaybe<DealroomInvestorFilter>;
  /** Filter by the object’s `investorId` field. */
  investorId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomCompanyInvestorFilter>>;
};

/** Grouping methods for `DealroomCompanyInvestor` for usage during aggregation. */
export enum DealroomCompanyInvestorGroupBy {
  CompanyId = "COMPANY_ID",
  Exited = "EXITED",
  InvestorId = "INVESTOR_ID",
  IsEditorial = "IS_EDITORIAL",
}

export type DealroomCompanyInvestorHavingAverageInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingDistinctCountInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DealroomCompanyInvestor` aggregates. */
export type DealroomCompanyInvestorHavingInput = {
  AND?: InputMaybe<Array<DealroomCompanyInvestorHavingInput>>;
  OR?: InputMaybe<Array<DealroomCompanyInvestorHavingInput>>;
  average?: InputMaybe<DealroomCompanyInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomCompanyInvestorHavingDistinctCountInput>;
  max?: InputMaybe<DealroomCompanyInvestorHavingMaxInput>;
  min?: InputMaybe<DealroomCompanyInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomCompanyInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomCompanyInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomCompanyInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomCompanyInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomCompanyInvestorHavingVarianceSampleInput>;
};

export type DealroomCompanyInvestorHavingMaxInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingMinInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingStddevPopulationInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingStddevSampleInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingSumInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingVariancePopulationInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorHavingVarianceSampleInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomCompanyInvestorMaxAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
};

export type DealroomCompanyInvestorMaxAggregates = {
  __typename?: "DealroomCompanyInvestorMaxAggregates";
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomCompanyInvestorMinAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
};

export type DealroomCompanyInvestorMinAggregates = {
  __typename?: "DealroomCompanyInvestorMinAggregates";
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomCompanyInvestorStddevPopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorStddevPopulationAggregates = {
  __typename?: "DealroomCompanyInvestorStddevPopulationAggregates";
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyInvestorStddevSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorStddevSampleAggregates = {
  __typename?: "DealroomCompanyInvestorStddevSampleAggregates";
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyInvestorSumAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorSumAggregates = {
  __typename?: "DealroomCompanyInvestorSumAggregates";
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigFloat"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of investorId across the matching connection */
  investorId: Scalars["BigFloat"]["output"];
};

export type DealroomCompanyInvestorVariancePopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorVariancePopulationAggregates = {
  __typename?: "DealroomCompanyInvestorVariancePopulationAggregates";
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyInvestorVarianceSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyInvestorVarianceSampleAggregates = {
  __typename?: "DealroomCompanyInvestorVarianceSampleAggregates";
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomCompanyInvestor` values. */
export type DealroomCompanyInvestorsConnection = {
  __typename?: "DealroomCompanyInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomCompanyInvestorAggregates>;
  /** A list of edges which contains the `DealroomCompanyInvestor` and cursor to aid in pagination. */
  edges: Array<DealroomCompanyInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomCompanyInvestorAggregates>>;
  /** A list of `DealroomCompanyInvestor` objects. */
  nodes: Array<DealroomCompanyInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomCompanyInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomCompanyInvestor` values. */
export type DealroomCompanyInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomCompanyInvestorGroupBy>;
  having?: InputMaybe<DealroomCompanyInvestorHavingInput>;
};

/** A `DealroomCompanyInvestor` edge in the connection. */
export type DealroomCompanyInvestorsEdge = {
  __typename?: "DealroomCompanyInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomCompanyInvestor` at the end of the edge. */
  node: DealroomCompanyInvestor;
};

/** Methods to use when ordering `DealroomCompanyInvestor`. */
export enum DealroomCompanyInvestorsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  ExitedAsc = "EXITED_ASC",
  ExitedDesc = "EXITED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvestorIdAsc = "INVESTOR_ID_ASC",
  InvestorIdDesc = "INVESTOR_ID_DESC",
  IsEditorialAsc = "IS_EDITORIAL_ASC",
  IsEditorialDesc = "IS_EDITORIAL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A connection to a list of `LgCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyConnection =
  {
    __typename?: "DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<LgCompanyAggregates>;
    /** A list of edges which contains the `LgCompany`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
    edges: Array<DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
    /** A list of `LgCompany` objects. */
    nodes: Array<LgCompany>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `LgCompany` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `LgCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgCompanyGroupBy>;
    having?: InputMaybe<LgCompanyHavingInput>;
  };

/** A `LgCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyEdge = {
  __typename?: "DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
};

/** A `LgCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyLgCompaniesByNzrOpportunityEmailCandidateDealroomCompanyIdAndLgCompanyIdManyToManyEdgeNzrOpportunityEmailCandidatesArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

export type DealroomCompanyMaxAggregates = {
  __typename?: "DealroomCompanyMaxAggregates";
  /** Maximum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyMinAggregates = {
  __typename?: "DealroomCompanyMinAggregates";
  /** Minimum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `NzrOpportunityEmail` values, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyConnection =
  {
    __typename?: "DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<NzrOpportunityEmailAggregates>;
    /** A list of edges which contains the `NzrOpportunityEmail`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
    edges: Array<DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<NzrOpportunityEmailAggregates>>;
    /** A list of `NzrOpportunityEmail` objects. */
    nodes: Array<NzrOpportunityEmail>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `NzrOpportunityEmail` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `NzrOpportunityEmail` values, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<NzrOpportunityEmailGroupBy>;
    having?: InputMaybe<NzrOpportunityEmailHavingInput>;
  };

/** A `NzrOpportunityEmail` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyEdge =
  {
    __typename?: "DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyEdge";
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars["Cursor"]["output"]>;
    /** The `NzrOpportunityEmail` at the end of the edge. */
    node: NzrOpportunityEmail;
    /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
    nzrOpportunityEmailCandidatesByEmailId: NzrOpportunityEmailCandidatesConnection;
  };

/** A `NzrOpportunityEmail` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type DealroomCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateDealroomCompanyIdAndEmailIdManyToManyEdgeNzrOpportunityEmailCandidatesByEmailIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

export type DealroomCompanyStddevPopulationAggregates = {
  __typename?: "DealroomCompanyStddevPopulationAggregates";
  /** Population standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyStddevSampleAggregates = {
  __typename?: "DealroomCompanyStddevSampleAggregates";
  /** Sample standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanySumAggregates = {
  __typename?: "DealroomCompanySumAggregates";
  /** Sum of employeesLatest across the matching connection */
  employeesLatest: Scalars["BigInt"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of jobOpenings across the matching connection */
  jobOpenings: Scalars["BigInt"]["output"];
  /** Sum of lastFunding across the matching connection */
  lastFunding: Scalars["BigFloat"]["output"];
  /** Sum of lastFundingSource across the matching connection */
  lastFundingSource: Scalars["BigFloat"]["output"];
  /** Sum of launchMonth across the matching connection */
  launchMonth: Scalars["BigInt"]["output"];
  /** Sum of launchYear across the matching connection */
  launchYear: Scalars["BigInt"]["output"];
  /** Sum of patentsCount across the matching connection */
  patentsCount: Scalars["BigInt"]["output"];
  /** Sum of totalFunding across the matching connection */
  totalFunding: Scalars["BigFloat"]["output"];
  /** Sum of totalFundingSource across the matching connection */
  totalFundingSource: Scalars["BigFloat"]["output"];
};

export type DealroomCompanyTeam = Node & {
  __typename?: "DealroomCompanyTeam";
  /** Reads a single `DealroomCompany` that is related to this `DealroomCompanyTeam`. */
  company?: Maybe<DealroomCompany>;
  companyId: Scalars["BigInt"]["output"];
  compositeId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `DealroomFounder` that is related to this `DealroomCompanyTeam`. */
  member?: Maybe<DealroomFounder>;
  memberId: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  past?: Maybe<Scalars["Boolean"]["output"]>;
  titles?: Maybe<Scalars["JSON"]["output"]>;
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomCompanyTeamAggregates = {
  __typename?: "DealroomCompanyTeamAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomCompanyTeamAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomCompanyTeamDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomCompanyTeamMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomCompanyTeamMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomCompanyTeamStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomCompanyTeamStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomCompanyTeamSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomCompanyTeamVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomCompanyTeamVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DealroomCompanyTeam` object types. */
export type DealroomCompanyTeamAggregatesFilter = {
  /** Mean average aggregate over matching `DealroomCompanyTeam` objects. */
  average?: InputMaybe<DealroomCompanyTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DealroomCompanyTeam` objects. */
  distinctCount?: InputMaybe<DealroomCompanyTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DealroomCompanyTeam` object to be included within the aggregate. */
  filter?: InputMaybe<DealroomCompanyTeamFilter>;
  /** Maximum aggregate over matching `DealroomCompanyTeam` objects. */
  max?: InputMaybe<DealroomCompanyTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `DealroomCompanyTeam` objects. */
  min?: InputMaybe<DealroomCompanyTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DealroomCompanyTeam` objects. */
  stddevPopulation?: InputMaybe<DealroomCompanyTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DealroomCompanyTeam` objects. */
  stddevSample?: InputMaybe<DealroomCompanyTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DealroomCompanyTeam` objects. */
  sum?: InputMaybe<DealroomCompanyTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `DealroomCompanyTeam` objects. */
  variancePopulation?: InputMaybe<DealroomCompanyTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DealroomCompanyTeam` objects. */
  varianceSample?: InputMaybe<DealroomCompanyTeamVarianceSampleAggregateFilter>;
};

export type DealroomCompanyTeamAverageAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyTeamAverageAggregates = {
  __typename?: "DealroomCompanyTeamAverageAggregates";
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomCompanyTeam` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DealroomCompanyTeamCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isFounder` field. */
  isFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `past` field. */
  past?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `titles` field. */
  titles?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `yearEnd` field. */
  yearEnd?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `yearStart` field. */
  yearStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DealroomCompanyTeamDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  compositeId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isFounder?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  past?: InputMaybe<BigIntFilter>;
  titles?: InputMaybe<BigIntFilter>;
  yearEnd?: InputMaybe<BigIntFilter>;
  yearStart?: InputMaybe<BigIntFilter>;
};

export type DealroomCompanyTeamDistinctCountAggregates = {
  __typename?: "DealroomCompanyTeamDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFounder across the matching connection */
  isFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of past across the matching connection */
  past?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of titles across the matching connection */
  titles?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyTeamFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomCompanyTeamFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<DealroomCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isFounder` field. */
  isFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<DealroomFounderFilter>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomCompanyTeamFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomCompanyTeamFilter>>;
  /** Filter by the object’s `past` field. */
  past?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `titles` field. */
  titles?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `yearEnd` field. */
  yearEnd?: InputMaybe<IntFilter>;
  /** Filter by the object’s `yearStart` field. */
  yearStart?: InputMaybe<IntFilter>;
};

/** Grouping methods for `DealroomCompanyTeam` for usage during aggregation. */
export enum DealroomCompanyTeamGroupBy {
  CompanyId = "COMPANY_ID",
  IsFounder = "IS_FOUNDER",
  MemberId = "MEMBER_ID",
  Past = "PAST",
  Titles = "TITLES",
  YearEnd = "YEAR_END",
  YearStart = "YEAR_START",
}

export type DealroomCompanyTeamHavingAverageInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingDistinctCountInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DealroomCompanyTeam` aggregates. */
export type DealroomCompanyTeamHavingInput = {
  AND?: InputMaybe<Array<DealroomCompanyTeamHavingInput>>;
  OR?: InputMaybe<Array<DealroomCompanyTeamHavingInput>>;
  average?: InputMaybe<DealroomCompanyTeamHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomCompanyTeamHavingDistinctCountInput>;
  max?: InputMaybe<DealroomCompanyTeamHavingMaxInput>;
  min?: InputMaybe<DealroomCompanyTeamHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomCompanyTeamHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomCompanyTeamHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomCompanyTeamHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomCompanyTeamHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomCompanyTeamHavingVarianceSampleInput>;
};

export type DealroomCompanyTeamHavingMaxInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingMinInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingStddevPopulationInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingStddevSampleInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingSumInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingVariancePopulationInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamHavingVarianceSampleInput = {
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  memberId?: InputMaybe<HavingBigintFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type DealroomCompanyTeamMaxAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  yearEnd?: InputMaybe<IntFilter>;
  yearStart?: InputMaybe<IntFilter>;
};

export type DealroomCompanyTeamMaxAggregates = {
  __typename?: "DealroomCompanyTeamMaxAggregates";
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomCompanyTeamMinAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  yearEnd?: InputMaybe<IntFilter>;
  yearStart?: InputMaybe<IntFilter>;
};

export type DealroomCompanyTeamMinAggregates = {
  __typename?: "DealroomCompanyTeamMinAggregates";
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomCompanyTeamStddevPopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyTeamStddevPopulationAggregates = {
  __typename?: "DealroomCompanyTeamStddevPopulationAggregates";
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyTeamStddevSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyTeamStddevSampleAggregates = {
  __typename?: "DealroomCompanyTeamStddevSampleAggregates";
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyTeamSumAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigIntFilter>;
  yearStart?: InputMaybe<BigIntFilter>;
};

export type DealroomCompanyTeamSumAggregates = {
  __typename?: "DealroomCompanyTeamSumAggregates";
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigFloat"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of memberId across the matching connection */
  memberId: Scalars["BigFloat"]["output"];
  /** Sum of yearEnd across the matching connection */
  yearEnd: Scalars["BigInt"]["output"];
  /** Sum of yearStart across the matching connection */
  yearStart: Scalars["BigInt"]["output"];
};

export type DealroomCompanyTeamVariancePopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyTeamVariancePopulationAggregates = {
  __typename?: "DealroomCompanyTeamVariancePopulationAggregates";
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyTeamVarianceSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  memberId?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type DealroomCompanyTeamVarianceSampleAggregates = {
  __typename?: "DealroomCompanyTeamVarianceSampleAggregates";
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomCompanyTeam` values. */
export type DealroomCompanyTeamsConnection = {
  __typename?: "DealroomCompanyTeamsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomCompanyTeamAggregates>;
  /** A list of edges which contains the `DealroomCompanyTeam` and cursor to aid in pagination. */
  edges: Array<DealroomCompanyTeamsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomCompanyTeamAggregates>>;
  /** A list of `DealroomCompanyTeam` objects. */
  nodes: Array<DealroomCompanyTeam>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomCompanyTeam` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomCompanyTeam` values. */
export type DealroomCompanyTeamsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomCompanyTeamGroupBy>;
  having?: InputMaybe<DealroomCompanyTeamHavingInput>;
};

/** A `DealroomCompanyTeam` edge in the connection. */
export type DealroomCompanyTeamsEdge = {
  __typename?: "DealroomCompanyTeamsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomCompanyTeam` at the end of the edge. */
  node: DealroomCompanyTeam;
};

/** Methods to use when ordering `DealroomCompanyTeam`. */
export enum DealroomCompanyTeamsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFounderAsc = "IS_FOUNDER_ASC",
  IsFounderDesc = "IS_FOUNDER_DESC",
  MemberIdAsc = "MEMBER_ID_ASC",
  MemberIdDesc = "MEMBER_ID_DESC",
  Natural = "NATURAL",
  PastAsc = "PAST_ASC",
  PastDesc = "PAST_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TitlesAsc = "TITLES_ASC",
  TitlesDesc = "TITLES_DESC",
  YearEndAsc = "YEAR_END_ASC",
  YearEndDesc = "YEAR_END_DESC",
  YearStartAsc = "YEAR_START_ASC",
  YearStartDesc = "YEAR_START_DESC",
}

/** A filter to be used against many `DealroomCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyToManyDealroomCompanyInvestorFilter = {
  /** Aggregates across related `DealroomCompanyInvestor` match the filter criteria. */
  aggregates?: InputMaybe<DealroomCompanyInvestorAggregatesFilter>;
  /** Every related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** No related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** Some related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomCompanyInvestorFilter>;
};

/** A filter to be used against many `DealroomCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyToManyDealroomCompanyTeamFilter = {
  /** Aggregates across related `DealroomCompanyTeam` match the filter criteria. */
  aggregates?: InputMaybe<DealroomCompanyTeamAggregatesFilter>;
  /** Every related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomCompanyTeamFilter>;
  /** No related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomCompanyTeamFilter>;
  /** Some related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomCompanyTeamFilter>;
};

/** A filter to be used against many `DealroomFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyToManyDealroomFundingRoundFilter = {
  /** Aggregates across related `DealroomFundingRound` match the filter criteria. */
  aggregates?: InputMaybe<DealroomFundingRoundAggregatesFilter>;
  /** Every related `DealroomFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomFundingRoundFilter>;
  /** No related `DealroomFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomFundingRoundFilter>;
  /** Some related `DealroomFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomFundingRoundFilter>;
};

/** A filter to be used against many `DealroomNews` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyToManyDealroomNewsFilter = {
  /** Aggregates across related `DealroomNews` match the filter criteria. */
  aggregates?: InputMaybe<DealroomNewsAggregatesFilter>;
  /** Every related `DealroomNews` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomNewsFilter>;
  /** No related `DealroomNews` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomNewsFilter>;
  /** Some related `DealroomNews` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomNewsFilter>;
};

/** A filter to be used against many `NzrOpportunityEmailCandidate` object types. All fields are combined with a logical ‘and.’ */
export type DealroomCompanyToManyNzrOpportunityEmailCandidateFilter = {
  /** Aggregates across related `NzrOpportunityEmailCandidate` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityEmailCandidateAggregatesFilter>;
  /** Every related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** No related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** Some related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
};

export type DealroomCompanyVariancePopulationAggregates = {
  __typename?: "DealroomCompanyVariancePopulationAggregates";
  /** Population variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomCompanyVarianceSampleAggregates = {
  __typename?: "DealroomCompanyVarianceSampleAggregates";
  /** Sample variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of jobOpenings across the matching connection */
  jobOpenings?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of patentsCount across the matching connection */
  patentsCount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFounder = Node & {
  __typename?: "DealroomFounder";
  angellistUrl?: Maybe<Scalars["String"]["output"]>;
  appstoreAppId?: Maybe<Scalars["String"]["output"]>;
  backgrounds?: Maybe<Scalars["JSON"]["output"]>;
  companies?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  crunchbaseUrl?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompany`. */
  dealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyId: DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `DealroomCompanyTeam`. */
  dealroomCompanyTeamsByMemberId: DealroomCompanyTeamsConnection;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  founderScore?: Maybe<Scalars["Int"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  googleUrl?: Maybe<Scalars["String"]["output"]>;
  hqLocations?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["BigInt"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isPromisingFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isSerialFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isStrongFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isSuperFounder?: Maybe<Scalars["Boolean"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  path?: Maybe<Scalars["String"]["output"]>;
  playmarketAppId?: Maybe<Scalars["String"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  universities?: Maybe<Scalars["JSON"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyCondition>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompaniesOrderBy>>;
};

export type DealroomFounderDealroomCompanyTeamsByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyTeamCondition>;
  filter?: InputMaybe<DealroomCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyTeamsOrderBy>>;
};

export type DealroomFounderAggregates = {
  __typename?: "DealroomFounderAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomFounderAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomFounderDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomFounderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomFounderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomFounderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomFounderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomFounderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomFounderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomFounderVarianceSampleAggregates>;
};

export type DealroomFounderAverageAggregates = {
  __typename?: "DealroomFounderAverageAggregates";
  /** Mean average of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomFounder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealroomFounderCondition = {
  /** Checks for equality with the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `backgrounds` field. */
  backgrounds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companies` field. */
  companies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `foundedCompaniesTotalFunding` field. */
  foundedCompaniesTotalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `founderScore` field. */
  founderScore?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isFounder` field. */
  isFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isPromisingFounder` field. */
  isPromisingFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isSerialFounder` field. */
  isSerialFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isStrongFounder` field. */
  isStrongFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isSuperFounder` field. */
  isSuperFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `universities` field. */
  universities?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DealroomCompany` values, with data from `DealroomCompanyTeam`. */
export type DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyConnection = {
  __typename?: "DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomCompanyAggregates>;
  /** A list of edges which contains the `DealroomCompany`, info from the `DealroomCompanyTeam`, and the cursor to aid in pagination. */
  edges: Array<DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomCompanyAggregates>>;
  /** A list of `DealroomCompany` objects. */
  nodes: Array<DealroomCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomCompany` values, with data from `DealroomCompanyTeam`. */
export type DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomCompanyGroupBy>;
    having?: InputMaybe<DealroomCompanyHavingInput>;
  };

/** A `DealroomCompany` edge in the connection, with data from `DealroomCompanyTeam`. */
export type DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyEdge = {
  __typename?: "DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompanyTeam`. */
  dealroomCompanyTeamsByCompanyId: DealroomCompanyTeamsConnection;
  /** The `DealroomCompany` at the end of the edge. */
  node: DealroomCompany;
};

/** A `DealroomCompany` edge in the connection, with data from `DealroomCompanyTeam`. */
export type DealroomFounderDealroomCompaniesByDealroomCompanyTeamMemberIdAndCompanyIdManyToManyEdgeDealroomCompanyTeamsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomCompanyTeamCondition>;
    filter?: InputMaybe<DealroomCompanyTeamFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomCompanyTeamsOrderBy>>;
  };

export type DealroomFounderDistinctCountAggregates = {
  __typename?: "DealroomFounderDistinctCountAggregates";
  /** Distinct count of angellistUrl across the matching connection */
  angellistUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appstoreAppId across the matching connection */
  appstoreAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of backgrounds across the matching connection */
  backgrounds?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companies across the matching connection */
  companies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of gender across the matching connection */
  gender?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleUrl across the matching connection */
  googleUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqLocations across the matching connection */
  hqLocations?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFounder across the matching connection */
  isFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isPromisingFounder across the matching connection */
  isPromisingFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isSerialFounder across the matching connection */
  isSerialFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isStrongFounder across the matching connection */
  isStrongFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isSuperFounder across the matching connection */
  isSuperFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of playmarketAppId across the matching connection */
  playmarketAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of universities across the matching connection */
  universities?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomFounder` object types. All fields are combined with a logical ‘and.’ */
export type DealroomFounderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomFounderFilter>>;
  /** Filter by the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `backgrounds` field. */
  backgrounds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `companies` field. */
  companies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealroomCompanyTeamsByMemberId` relation. */
  dealroomCompanyTeamsByMemberId?: InputMaybe<DealroomFounderToManyDealroomCompanyTeamFilter>;
  /** Some related `dealroomCompanyTeamsByMemberId` exist. */
  dealroomCompanyTeamsByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `foundedCompaniesTotalFunding` field. */
  foundedCompaniesTotalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `founderScore` field. */
  founderScore?: InputMaybe<IntFilter>;
  /** Filter by the object’s `gender` field. */
  gender?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isFounder` field. */
  isFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPromisingFounder` field. */
  isPromisingFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSerialFounder` field. */
  isSerialFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isStrongFounder` field. */
  isStrongFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSuperFounder` field. */
  isSuperFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomFounderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomFounderFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `universities` field. */
  universities?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DealroomFounder` for usage during aggregation. */
export enum DealroomFounderGroupBy {
  AngellistUrl = "ANGELLIST_URL",
  AppstoreAppId = "APPSTORE_APP_ID",
  Backgrounds = "BACKGROUNDS",
  Companies = "COMPANIES",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  CrunchbaseUrl = "CRUNCHBASE_URL",
  Deleted = "DELETED",
  FacebookUrl = "FACEBOOK_URL",
  FoundedCompaniesTotalFunding = "FOUNDED_COMPANIES_TOTAL_FUNDING",
  FounderScore = "FOUNDER_SCORE",
  Gender = "GENDER",
  GoogleUrl = "GOOGLE_URL",
  HqLocations = "HQ_LOCATIONS",
  Image = "IMAGE",
  IsFounder = "IS_FOUNDER",
  IsPromisingFounder = "IS_PROMISING_FOUNDER",
  IsSerialFounder = "IS_SERIAL_FOUNDER",
  IsStrongFounder = "IS_STRONG_FOUNDER",
  IsSuperFounder = "IS_SUPER_FOUNDER",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LinkedinUrl = "LINKEDIN_URL",
  Name = "NAME",
  Path = "PATH",
  PlaymarketAppId = "PLAYMARKET_APP_ID",
  Tagline = "TAGLINE",
  TwitterUrl = "TWITTER_URL",
  Type = "TYPE",
  Universities = "UNIVERSITIES",
  Url = "URL",
  WebsiteUrl = "WEBSITE_URL",
}

export type DealroomFounderHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DealroomFounder` aggregates. */
export type DealroomFounderHavingInput = {
  AND?: InputMaybe<Array<DealroomFounderHavingInput>>;
  OR?: InputMaybe<Array<DealroomFounderHavingInput>>;
  average?: InputMaybe<DealroomFounderHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomFounderHavingDistinctCountInput>;
  max?: InputMaybe<DealroomFounderHavingMaxInput>;
  min?: InputMaybe<DealroomFounderHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomFounderHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomFounderHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomFounderHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomFounderHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomFounderHavingVarianceSampleInput>;
};

export type DealroomFounderHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomFounderMaxAggregates = {
  __typename?: "DealroomFounderMaxAggregates";
  /** Maximum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomFounderMinAggregates = {
  __typename?: "DealroomFounderMinAggregates";
  /** Minimum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomFounderStddevPopulationAggregates = {
  __typename?: "DealroomFounderStddevPopulationAggregates";
  /** Population standard deviation of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFounderStddevSampleAggregates = {
  __typename?: "DealroomFounderStddevSampleAggregates";
  /** Sample standard deviation of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFounderSumAggregates = {
  __typename?: "DealroomFounderSumAggregates";
  /** Sum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding: Scalars["BigFloat"]["output"];
  /** Sum of founderScore across the matching connection */
  founderScore: Scalars["BigInt"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `DealroomCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type DealroomFounderToManyDealroomCompanyTeamFilter = {
  /** Aggregates across related `DealroomCompanyTeam` match the filter criteria. */
  aggregates?: InputMaybe<DealroomCompanyTeamAggregatesFilter>;
  /** Every related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomCompanyTeamFilter>;
  /** No related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomCompanyTeamFilter>;
  /** Some related `DealroomCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomCompanyTeamFilter>;
};

export type DealroomFounderVariancePopulationAggregates = {
  __typename?: "DealroomFounderVariancePopulationAggregates";
  /** Population variance of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFounderVarianceSampleAggregates = {
  __typename?: "DealroomFounderVarianceSampleAggregates";
  /** Sample variance of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomFounder` values. */
export type DealroomFoundersConnection = {
  __typename?: "DealroomFoundersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomFounderAggregates>;
  /** A list of edges which contains the `DealroomFounder` and cursor to aid in pagination. */
  edges: Array<DealroomFoundersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomFounderAggregates>>;
  /** A list of `DealroomFounder` objects. */
  nodes: Array<DealroomFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomFounder` values. */
export type DealroomFoundersConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomFounderGroupBy>;
  having?: InputMaybe<DealroomFounderHavingInput>;
};

/** A `DealroomFounder` edge in the connection. */
export type DealroomFoundersEdge = {
  __typename?: "DealroomFoundersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomFounder` at the end of the edge. */
  node: DealroomFounder;
};

/** Methods to use when ordering `DealroomFounder`. */
export enum DealroomFoundersOrderBy {
  AngellistUrlAsc = "ANGELLIST_URL_ASC",
  AngellistUrlDesc = "ANGELLIST_URL_DESC",
  AppstoreAppIdAsc = "APPSTORE_APP_ID_ASC",
  AppstoreAppIdDesc = "APPSTORE_APP_ID_DESC",
  BackgroundsAsc = "BACKGROUNDS_ASC",
  BackgroundsDesc = "BACKGROUNDS_DESC",
  CompaniesAsc = "COMPANIES_ASC",
  CompaniesDesc = "COMPANIES_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CrunchbaseUrlAsc = "CRUNCHBASE_URL_ASC",
  CrunchbaseUrlDesc = "CRUNCHBASE_URL_DESC",
  DealroomCompanyTeamsByMemberIdAverageCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdAverageCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdAverageCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdAverageCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdAverageIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_ID_ASC",
  DealroomCompanyTeamsByMemberIdAverageIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_ID_DESC",
  DealroomCompanyTeamsByMemberIdAverageIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdAverageIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdAverageMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdAverageMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdAveragePastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_PAST_ASC",
  DealroomCompanyTeamsByMemberIdAveragePastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_PAST_DESC",
  DealroomCompanyTeamsByMemberIdAverageTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdAverageTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdAverageYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdAverageYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdAverageYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdAverageYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdCountAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_COUNT_ASC",
  DealroomCompanyTeamsByMemberIdCountDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_COUNT_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_PAST_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_PAST_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdDistinctCountYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdDistinctCountYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdMaxCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdMaxCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdMaxCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdMaxCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdMaxIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_ID_ASC",
  DealroomCompanyTeamsByMemberIdMaxIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_ID_DESC",
  DealroomCompanyTeamsByMemberIdMaxIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdMaxIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdMaxMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdMaxMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdMaxPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_PAST_ASC",
  DealroomCompanyTeamsByMemberIdMaxPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_PAST_DESC",
  DealroomCompanyTeamsByMemberIdMaxTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdMaxTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdMaxYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdMaxYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdMaxYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdMaxYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdMinCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdMinCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdMinCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdMinCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdMinIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_ID_ASC",
  DealroomCompanyTeamsByMemberIdMinIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_ID_DESC",
  DealroomCompanyTeamsByMemberIdMinIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdMinIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdMinMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdMinMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdMinPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_PAST_ASC",
  DealroomCompanyTeamsByMemberIdMinPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_PAST_DESC",
  DealroomCompanyTeamsByMemberIdMinTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdMinTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdMinYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdMinYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdMinYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdMinYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_PAST_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_PAST_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdStddevPopulationYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdStddevPopulationYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdStddevSamplePastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_PAST_ASC",
  DealroomCompanyTeamsByMemberIdStddevSamplePastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_PAST_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdStddevSampleYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdStddevSampleYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdSumCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdSumCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdSumCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdSumCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdSumIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_ID_ASC",
  DealroomCompanyTeamsByMemberIdSumIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_ID_DESC",
  DealroomCompanyTeamsByMemberIdSumIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdSumIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdSumMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdSumMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdSumPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_PAST_ASC",
  DealroomCompanyTeamsByMemberIdSumPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_PAST_DESC",
  DealroomCompanyTeamsByMemberIdSumTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdSumTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdSumYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdSumYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdSumYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdSumYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationPastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_PAST_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationPastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_PAST_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdVariancePopulationYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdVariancePopulationYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_START_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleCompanyIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleCompanyIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleCompositeIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleCompositeIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleIsFounderAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_FOUNDER_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleIsFounderDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_FOUNDER_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleMemberIdAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleMemberIdDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSamplePastAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_PAST_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSamplePastDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_PAST_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleTitlesAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_TITLES_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleTitlesDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_TITLES_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleYearEndAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_END_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleYearEndDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_END_DESC",
  DealroomCompanyTeamsByMemberIdVarianceSampleYearStartAsc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_START_ASC",
  DealroomCompanyTeamsByMemberIdVarianceSampleYearStartDesc = "DEALROOM_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_START_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  FacebookUrlAsc = "FACEBOOK_URL_ASC",
  FacebookUrlDesc = "FACEBOOK_URL_DESC",
  FoundedCompaniesTotalFundingAsc = "FOUNDED_COMPANIES_TOTAL_FUNDING_ASC",
  FoundedCompaniesTotalFundingDesc = "FOUNDED_COMPANIES_TOTAL_FUNDING_DESC",
  FounderScoreAsc = "FOUNDER_SCORE_ASC",
  FounderScoreDesc = "FOUNDER_SCORE_DESC",
  GenderAsc = "GENDER_ASC",
  GenderDesc = "GENDER_DESC",
  GoogleUrlAsc = "GOOGLE_URL_ASC",
  GoogleUrlDesc = "GOOGLE_URL_DESC",
  HqLocationsAsc = "HQ_LOCATIONS_ASC",
  HqLocationsDesc = "HQ_LOCATIONS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IsFounderAsc = "IS_FOUNDER_ASC",
  IsFounderDesc = "IS_FOUNDER_DESC",
  IsPromisingFounderAsc = "IS_PROMISING_FOUNDER_ASC",
  IsPromisingFounderDesc = "IS_PROMISING_FOUNDER_DESC",
  IsSerialFounderAsc = "IS_SERIAL_FOUNDER_ASC",
  IsSerialFounderDesc = "IS_SERIAL_FOUNDER_DESC",
  IsStrongFounderAsc = "IS_STRONG_FOUNDER_ASC",
  IsStrongFounderDesc = "IS_STRONG_FOUNDER_DESC",
  IsSuperFounderAsc = "IS_SUPER_FOUNDER_ASC",
  IsSuperFounderDesc = "IS_SUPER_FOUNDER_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PlaymarketAppIdAsc = "PLAYMARKET_APP_ID_ASC",
  PlaymarketAppIdDesc = "PLAYMARKET_APP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UniversitiesAsc = "UNIVERSITIES_ASC",
  UniversitiesDesc = "UNIVERSITIES_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type DealroomFundingRound = Node & {
  __typename?: "DealroomFundingRound";
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Reads a single `DealroomCompany` that is related to this `DealroomFundingRound`. */
  company?: Maybe<DealroomCompany>;
  companyId: Scalars["BigInt"]["output"];
  currency?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomFundingRoundInvestor`. */
  dealroomFundingRoundInvestorsByFundingRoundId: DealroomFundingRoundInvestorsConnection;
  /** Reads and enables pagination through a set of `DealroomInvestor`. */
  dealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorId: DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection;
  id: Scalars["Int"]["output"];
  isVerified?: Maybe<Scalars["Boolean"]["output"]>;
  month?: Maybe<Scalars["Int"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  round?: Maybe<Scalars["String"]["output"]>;
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomFundingRoundDealroomFundingRoundInvestorsByFundingRoundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundInvestorCondition>;
  filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundInvestorsOrderBy>>;
};

export type DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomInvestorCondition>;
  filter?: InputMaybe<DealroomInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomInvestorsOrderBy>>;
};

export type DealroomFundingRoundAggregates = {
  __typename?: "DealroomFundingRoundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomFundingRoundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomFundingRoundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomFundingRoundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomFundingRoundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomFundingRoundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomFundingRoundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomFundingRoundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomFundingRoundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomFundingRoundVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DealroomFundingRound` object types. */
export type DealroomFundingRoundAggregatesFilter = {
  /** Mean average aggregate over matching `DealroomFundingRound` objects. */
  average?: InputMaybe<DealroomFundingRoundAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DealroomFundingRound` objects. */
  distinctCount?: InputMaybe<DealroomFundingRoundDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DealroomFundingRound` object to be included within the aggregate. */
  filter?: InputMaybe<DealroomFundingRoundFilter>;
  /** Maximum aggregate over matching `DealroomFundingRound` objects. */
  max?: InputMaybe<DealroomFundingRoundMaxAggregateFilter>;
  /** Minimum aggregate over matching `DealroomFundingRound` objects. */
  min?: InputMaybe<DealroomFundingRoundMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DealroomFundingRound` objects. */
  stddevPopulation?: InputMaybe<DealroomFundingRoundStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DealroomFundingRound` objects. */
  stddevSample?: InputMaybe<DealroomFundingRoundStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DealroomFundingRound` objects. */
  sum?: InputMaybe<DealroomFundingRoundSumAggregateFilter>;
  /** Population variance aggregate over matching `DealroomFundingRound` objects. */
  variancePopulation?: InputMaybe<DealroomFundingRoundVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DealroomFundingRound` objects. */
  varianceSample?: InputMaybe<DealroomFundingRoundVarianceSampleAggregateFilter>;
};

export type DealroomFundingRoundAverageAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundAverageAggregates = {
  __typename?: "DealroomFundingRoundAverageAggregates";
  /** Mean average of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomFundingRound` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DealroomFundingRoundCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountEurMillion` field. */
  amountEurMillion?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountSource` field. */
  amountSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `month` field. */
  month?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `round` field. */
  round?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `valuation` field. */
  valuation?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `valuationGeneratedMax` field. */
  valuationGeneratedMax?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `valuationGeneratedMin` field. */
  valuationGeneratedMin?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `year` field. */
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A connection to a list of `DealroomInvestor` values, with data from `DealroomFundingRoundInvestor`. */
export type DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection =
  {
    __typename?: "DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<DealroomInvestorAggregates>;
    /** A list of edges which contains the `DealroomInvestor`, info from the `DealroomFundingRoundInvestor`, and the cursor to aid in pagination. */
    edges: Array<DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<DealroomInvestorAggregates>>;
    /** A list of `DealroomInvestor` objects. */
    nodes: Array<DealroomInvestor>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `DealroomInvestor` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `DealroomInvestor` values, with data from `DealroomFundingRoundInvestor`. */
export type DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomInvestorGroupBy>;
    having?: InputMaybe<DealroomInvestorHavingInput>;
  };

/** A `DealroomInvestor` edge in the connection, with data from `DealroomFundingRoundInvestor`. */
export type DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge =
  {
    __typename?: "DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge";
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars["Cursor"]["output"]>;
    /** Reads and enables pagination through a set of `DealroomFundingRoundInvestor`. */
    dealroomFundingRoundInvestorsByInvestorId: DealroomFundingRoundInvestorsConnection;
    /** The `DealroomInvestor` at the end of the edge. */
    node: DealroomInvestor;
  };

/** A `DealroomInvestor` edge in the connection, with data from `DealroomFundingRoundInvestor`. */
export type DealroomFundingRoundDealroomInvestorsByDealroomFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdgeDealroomFundingRoundInvestorsByInvestorIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomFundingRoundInvestorCondition>;
    filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomFundingRoundInvestorsOrderBy>>;
  };

export type DealroomFundingRoundDistinctCountAggregateFilter = {
  amount?: InputMaybe<BigIntFilter>;
  amountEurMillion?: InputMaybe<BigIntFilter>;
  amountSource?: InputMaybe<BigIntFilter>;
  amountUsdMillion?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  currency?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isVerified?: InputMaybe<BigIntFilter>;
  month?: InputMaybe<BigIntFilter>;
  round?: InputMaybe<BigIntFilter>;
  valuation?: InputMaybe<BigIntFilter>;
  valuationGeneratedMax?: InputMaybe<BigIntFilter>;
  valuationGeneratedMin?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<BigIntFilter>;
};

export type DealroomFundingRoundDistinctCountAggregates = {
  __typename?: "DealroomFundingRoundDistinctCountAggregates";
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currency across the matching connection */
  currency?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isVerified across the matching connection */
  isVerified?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of month across the matching connection */
  month?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of round across the matching connection */
  round?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type DealroomFundingRoundFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountEurMillion` field. */
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountSource` field. */
  amountSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomFundingRoundFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<DealroomCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealroomFundingRoundInvestorsByFundingRoundId` relation. */
  dealroomFundingRoundInvestorsByFundingRoundId?: InputMaybe<DealroomFundingRoundToManyDealroomFundingRoundInvestorFilter>;
  /** Some related `dealroomFundingRoundInvestorsByFundingRoundId` exist. */
  dealroomFundingRoundInvestorsByFundingRoundIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `month` field. */
  month?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomFundingRoundFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomFundingRoundFilter>>;
  /** Filter by the object’s `round` field. */
  round?: InputMaybe<StringFilter>;
  /** Filter by the object’s `valuation` field. */
  valuation?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `valuationGeneratedMax` field. */
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `valuationGeneratedMin` field. */
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `year` field. */
  year?: InputMaybe<IntFilter>;
};

/** Grouping methods for `DealroomFundingRound` for usage during aggregation. */
export enum DealroomFundingRoundGroupBy {
  Amount = "AMOUNT",
  AmountEurMillion = "AMOUNT_EUR_MILLION",
  AmountSource = "AMOUNT_SOURCE",
  AmountUsdMillion = "AMOUNT_USD_MILLION",
  CompanyId = "COMPANY_ID",
  Currency = "CURRENCY",
  IsVerified = "IS_VERIFIED",
  Month = "MONTH",
  Round = "ROUND",
  Valuation = "VALUATION",
  ValuationGeneratedMax = "VALUATION_GENERATED_MAX",
  ValuationGeneratedMin = "VALUATION_GENERATED_MIN",
  Year = "YEAR",
}

export type DealroomFundingRoundHavingAverageInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingDistinctCountInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DealroomFundingRound` aggregates. */
export type DealroomFundingRoundHavingInput = {
  AND?: InputMaybe<Array<DealroomFundingRoundHavingInput>>;
  OR?: InputMaybe<Array<DealroomFundingRoundHavingInput>>;
  average?: InputMaybe<DealroomFundingRoundHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomFundingRoundHavingDistinctCountInput>;
  max?: InputMaybe<DealroomFundingRoundHavingMaxInput>;
  min?: InputMaybe<DealroomFundingRoundHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomFundingRoundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomFundingRoundHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomFundingRoundHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomFundingRoundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomFundingRoundHavingVarianceSampleInput>;
};

export type DealroomFundingRoundHavingMaxInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingMinInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingStddevPopulationInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingStddevSampleInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingSumInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingVariancePopulationInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundHavingVarianceSampleInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type DealroomFundingRoundInvestor = Node & {
  __typename?: "DealroomFundingRoundInvestor";
  compositeId: Scalars["String"]["output"];
  /** Reads a single `DealroomFundingRound` that is related to this `DealroomFundingRoundInvestor`. */
  fundingRound?: Maybe<DealroomFundingRound>;
  fundingRoundId?: Maybe<Scalars["BigInt"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Reads a single `DealroomInvestor` that is related to this `DealroomFundingRoundInvestor`. */
  investor?: Maybe<DealroomInvestor>;
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
  lead?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type DealroomFundingRoundInvestorAggregates = {
  __typename?: "DealroomFundingRoundInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomFundingRoundInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomFundingRoundInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomFundingRoundInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomFundingRoundInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomFundingRoundInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomFundingRoundInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomFundingRoundInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomFundingRoundInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomFundingRoundInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DealroomFundingRoundInvestor` object types. */
export type DealroomFundingRoundInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `DealroomFundingRoundInvestor` objects. */
  average?: InputMaybe<DealroomFundingRoundInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DealroomFundingRoundInvestor` objects. */
  distinctCount?: InputMaybe<DealroomFundingRoundInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DealroomFundingRoundInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** Maximum aggregate over matching `DealroomFundingRoundInvestor` objects. */
  max?: InputMaybe<DealroomFundingRoundInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `DealroomFundingRoundInvestor` objects. */
  min?: InputMaybe<DealroomFundingRoundInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DealroomFundingRoundInvestor` objects. */
  stddevPopulation?: InputMaybe<DealroomFundingRoundInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DealroomFundingRoundInvestor` objects. */
  stddevSample?: InputMaybe<DealroomFundingRoundInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DealroomFundingRoundInvestor` objects. */
  sum?: InputMaybe<DealroomFundingRoundInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `DealroomFundingRoundInvestor` objects. */
  variancePopulation?: InputMaybe<DealroomFundingRoundInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DealroomFundingRoundInvestor` objects. */
  varianceSample?: InputMaybe<DealroomFundingRoundInvestorVarianceSampleAggregateFilter>;
};

export type DealroomFundingRoundInvestorAverageAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorAverageAggregates = {
  __typename?: "DealroomFundingRoundInvestorAverageAggregates";
  /** Mean average of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomFundingRoundInvestor` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DealroomFundingRoundInvestorCondition = {
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `investorId` field. */
  investorId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `lead` field. */
  lead?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DealroomFundingRoundInvestorDistinctCountAggregateFilter = {
  compositeId?: InputMaybe<BigIntFilter>;
  fundingRoundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
  lead?: InputMaybe<BigIntFilter>;
};

export type DealroomFundingRoundInvestorDistinctCountAggregates = {
  __typename?: "DealroomFundingRoundInvestorDistinctCountAggregates";
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lead across the matching connection */
  lead?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomFundingRoundInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomFundingRoundInvestorFilter>>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fundingRound` relation. */
  fundingRound?: InputMaybe<DealroomFundingRoundFilter>;
  /** A related `fundingRound` exists. */
  fundingRoundExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `investor` relation. */
  investor?: InputMaybe<DealroomInvestorFilter>;
  /** A related `investor` exists. */
  investorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `investorId` field. */
  investorId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lead` field. */
  lead?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomFundingRoundInvestorFilter>>;
};

/** Grouping methods for `DealroomFundingRoundInvestor` for usage during aggregation. */
export enum DealroomFundingRoundInvestorGroupBy {
  FundingRoundId = "FUNDING_ROUND_ID",
  InvestorId = "INVESTOR_ID",
  Lead = "LEAD",
}

export type DealroomFundingRoundInvestorHavingAverageInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingDistinctCountInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DealroomFundingRoundInvestor` aggregates. */
export type DealroomFundingRoundInvestorHavingInput = {
  AND?: InputMaybe<Array<DealroomFundingRoundInvestorHavingInput>>;
  OR?: InputMaybe<Array<DealroomFundingRoundInvestorHavingInput>>;
  average?: InputMaybe<DealroomFundingRoundInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomFundingRoundInvestorHavingDistinctCountInput>;
  max?: InputMaybe<DealroomFundingRoundInvestorHavingMaxInput>;
  min?: InputMaybe<DealroomFundingRoundInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomFundingRoundInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomFundingRoundInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomFundingRoundInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomFundingRoundInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomFundingRoundInvestorHavingVarianceSampleInput>;
};

export type DealroomFundingRoundInvestorHavingMaxInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingMinInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingStddevPopulationInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingStddevSampleInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingSumInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingVariancePopulationInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorHavingVarianceSampleInput = {
  fundingRoundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingIntFilter>;
  investorId?: InputMaybe<HavingBigintFilter>;
};

export type DealroomFundingRoundInvestorMaxAggregateFilter = {
  fundingRoundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
};

export type DealroomFundingRoundInvestorMaxAggregates = {
  __typename?: "DealroomFundingRoundInvestorMaxAggregates";
  /** Maximum of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomFundingRoundInvestorMinAggregateFilter = {
  fundingRoundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
};

export type DealroomFundingRoundInvestorMinAggregates = {
  __typename?: "DealroomFundingRoundInvestorMinAggregates";
  /** Minimum of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
};

export type DealroomFundingRoundInvestorStddevPopulationAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorStddevPopulationAggregates = {
  __typename?: "DealroomFundingRoundInvestorStddevPopulationAggregates";
  /** Population standard deviation of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundInvestorStddevSampleAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorStddevSampleAggregates = {
  __typename?: "DealroomFundingRoundInvestorStddevSampleAggregates";
  /** Sample standard deviation of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundInvestorSumAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorSumAggregates = {
  __typename?: "DealroomFundingRoundInvestorSumAggregates";
  /** Sum of fundingRoundId across the matching connection */
  fundingRoundId: Scalars["BigFloat"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of investorId across the matching connection */
  investorId: Scalars["BigFloat"]["output"];
};

export type DealroomFundingRoundInvestorVariancePopulationAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorVariancePopulationAggregates = {
  __typename?: "DealroomFundingRoundInvestorVariancePopulationAggregates";
  /** Population variance of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundInvestorVarianceSampleAggregateFilter = {
  fundingRoundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  investorId?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundInvestorVarianceSampleAggregates = {
  __typename?: "DealroomFundingRoundInvestorVarianceSampleAggregates";
  /** Sample variance of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomFundingRoundInvestor` values. */
export type DealroomFundingRoundInvestorsConnection = {
  __typename?: "DealroomFundingRoundInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomFundingRoundInvestorAggregates>;
  /** A list of edges which contains the `DealroomFundingRoundInvestor` and cursor to aid in pagination. */
  edges: Array<DealroomFundingRoundInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomFundingRoundInvestorAggregates>>;
  /** A list of `DealroomFundingRoundInvestor` objects. */
  nodes: Array<DealroomFundingRoundInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomFundingRoundInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomFundingRoundInvestor` values. */
export type DealroomFundingRoundInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomFundingRoundInvestorGroupBy>;
  having?: InputMaybe<DealroomFundingRoundInvestorHavingInput>;
};

/** A `DealroomFundingRoundInvestor` edge in the connection. */
export type DealroomFundingRoundInvestorsEdge = {
  __typename?: "DealroomFundingRoundInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomFundingRoundInvestor` at the end of the edge. */
  node: DealroomFundingRoundInvestor;
};

/** Methods to use when ordering `DealroomFundingRoundInvestor`. */
export enum DealroomFundingRoundInvestorsOrderBy {
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  FundingRoundIdAsc = "FUNDING_ROUND_ID_ASC",
  FundingRoundIdDesc = "FUNDING_ROUND_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvestorIdAsc = "INVESTOR_ID_ASC",
  InvestorIdDesc = "INVESTOR_ID_DESC",
  LeadAsc = "LEAD_ASC",
  LeadDesc = "LEAD_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type DealroomFundingRoundMaxAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  month?: InputMaybe<IntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<IntFilter>;
};

export type DealroomFundingRoundMaxAggregates = {
  __typename?: "DealroomFundingRoundMaxAggregates";
  /** Maximum of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of month across the matching connection */
  month?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomFundingRoundMinAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<IntFilter>;
  month?: InputMaybe<IntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<IntFilter>;
};

export type DealroomFundingRoundMinAggregates = {
  __typename?: "DealroomFundingRoundMinAggregates";
  /** Minimum of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of month across the matching connection */
  month?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomFundingRoundStddevPopulationAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundStddevPopulationAggregates = {
  __typename?: "DealroomFundingRoundStddevPopulationAggregates";
  /** Population standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundStddevSampleAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundStddevSampleAggregates = {
  __typename?: "DealroomFundingRoundStddevSampleAggregates";
  /** Sample standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundSumAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  month?: InputMaybe<BigIntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigIntFilter>;
};

export type DealroomFundingRoundSumAggregates = {
  __typename?: "DealroomFundingRoundSumAggregates";
  /** Sum of amount across the matching connection */
  amount: Scalars["BigFloat"]["output"];
  /** Sum of amountEurMillion across the matching connection */
  amountEurMillion: Scalars["BigFloat"]["output"];
  /** Sum of amountSource across the matching connection */
  amountSource: Scalars["BigFloat"]["output"];
  /** Sum of amountUsdMillion across the matching connection */
  amountUsdMillion: Scalars["BigFloat"]["output"];
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigFloat"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of month across the matching connection */
  month: Scalars["BigInt"]["output"];
  /** Sum of valuation across the matching connection */
  valuation: Scalars["BigFloat"]["output"];
  /** Sum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax: Scalars["BigFloat"]["output"];
  /** Sum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin: Scalars["BigFloat"]["output"];
  /** Sum of year across the matching connection */
  year: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `DealroomFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomFundingRoundToManyDealroomFundingRoundInvestorFilter = {
  /** Aggregates across related `DealroomFundingRoundInvestor` match the filter criteria. */
  aggregates?: InputMaybe<DealroomFundingRoundInvestorAggregatesFilter>;
  /** Every related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** No related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** Some related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomFundingRoundInvestorFilter>;
};

export type DealroomFundingRoundVariancePopulationAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundVariancePopulationAggregates = {
  __typename?: "DealroomFundingRoundVariancePopulationAggregates";
  /** Population variance of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomFundingRoundVarianceSampleAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type DealroomFundingRoundVarianceSampleAggregates = {
  __typename?: "DealroomFundingRoundVarianceSampleAggregates";
  /** Sample variance of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomFundingRound` values. */
export type DealroomFundingRoundsConnection = {
  __typename?: "DealroomFundingRoundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomFundingRoundAggregates>;
  /** A list of edges which contains the `DealroomFundingRound` and cursor to aid in pagination. */
  edges: Array<DealroomFundingRoundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomFundingRoundAggregates>>;
  /** A list of `DealroomFundingRound` objects. */
  nodes: Array<DealroomFundingRound>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomFundingRound` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomFundingRound` values. */
export type DealroomFundingRoundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomFundingRoundGroupBy>;
  having?: InputMaybe<DealroomFundingRoundHavingInput>;
};

/** A `DealroomFundingRound` edge in the connection. */
export type DealroomFundingRoundsEdge = {
  __typename?: "DealroomFundingRoundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomFundingRound` at the end of the edge. */
  node: DealroomFundingRound;
};

/** Methods to use when ordering `DealroomFundingRound`. */
export enum DealroomFundingRoundsOrderBy {
  AmountAsc = "AMOUNT_ASC",
  AmountDesc = "AMOUNT_DESC",
  AmountEurMillionAsc = "AMOUNT_EUR_MILLION_ASC",
  AmountEurMillionDesc = "AMOUNT_EUR_MILLION_DESC",
  AmountSourceAsc = "AMOUNT_SOURCE_ASC",
  AmountSourceDesc = "AMOUNT_SOURCE_DESC",
  AmountUsdMillionAsc = "AMOUNT_USD_MILLION_ASC",
  AmountUsdMillionDesc = "AMOUNT_USD_MILLION_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdAverageLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdCountAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_COUNT_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdCountDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_COUNT_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdDistinctCountLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMaxLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdMinLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevPopulationLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdStddevSampleLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdSumLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVariancePopulationLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_LEAD_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_LEAD_ASC",
  DealroomFundingRoundInvestorsByFundingRoundIdVarianceSampleLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_LEAD_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsVerifiedAsc = "IS_VERIFIED_ASC",
  IsVerifiedDesc = "IS_VERIFIED_DESC",
  MonthAsc = "MONTH_ASC",
  MonthDesc = "MONTH_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RoundAsc = "ROUND_ASC",
  RoundDesc = "ROUND_DESC",
  ValuationAsc = "VALUATION_ASC",
  ValuationDesc = "VALUATION_DESC",
  ValuationGeneratedMaxAsc = "VALUATION_GENERATED_MAX_ASC",
  ValuationGeneratedMaxDesc = "VALUATION_GENERATED_MAX_DESC",
  ValuationGeneratedMinAsc = "VALUATION_GENERATED_MIN_ASC",
  ValuationGeneratedMinDesc = "VALUATION_GENERATED_MIN_DESC",
  YearAsc = "YEAR_ASC",
  YearDesc = "YEAR_DESC",
}

export type DealroomInvestor = Node & {
  __typename?: "DealroomInvestor";
  about?: Maybe<Scalars["String"]["output"]>;
  acceleratorProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  acceleratorProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  angellistUrl?: Maybe<Scalars["String"]["output"]>;
  appstoreAppId?: Maybe<Scalars["String"]["output"]>;
  clientFocus?: Maybe<Scalars["JSON"]["output"]>;
  coInvestors?: Maybe<Scalars["JSON"]["output"]>;
  countryExperience?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  crunchbaseUrl?: Maybe<Scalars["String"]["output"]>;
  dealSize?: Maybe<Scalars["String"]["output"]>;
  dealStructure?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompany`. */
  dealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyId: DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `DealroomCompanyInvestor`. */
  dealroomCompanyInvestorsByInvestorId: DealroomCompanyInvestorsConnection;
  /** Reads and enables pagination through a set of `DealroomFundingRoundInvestor`. */
  dealroomFundingRoundInvestorsByInvestorId: DealroomFundingRoundInvestorsConnection;
  /** Reads and enables pagination through a set of `DealroomFundingRound`. */
  dealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundId: DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection;
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  emeaSeedProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  emeaSeedProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  emeaSeriesaProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  emeaSeriesaProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  employees?: Maybe<Scalars["String"]["output"]>;
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  europeSeedProminencePercentile?: Maybe<Scalars["String"]["output"]>;
  europeSeedProminenceUnique?: Maybe<Scalars["String"]["output"]>;
  europeSeriesaProminencePercentile?: Maybe<Scalars["String"]["output"]>;
  europeSeriesaProminenceUnique?: Maybe<Scalars["String"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  fundings?: Maybe<Scalars["JSON"]["output"]>;
  fundsUnderManagement?: Maybe<Scalars["JSON"]["output"]>;
  globalSeedProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  globalSeedProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  globalSeriesaProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  globalSeriesaProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  googleUrl?: Maybe<Scalars["String"]["output"]>;
  hqLocations?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["BigInt"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  industryExperience?: Maybe<Scalars["JSON"]["output"]>;
  investmentStages?: Maybe<Scalars["JSON"]["output"]>;
  investments?: Maybe<Scalars["JSON"]["output"]>;
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  investorType?: Maybe<Scalars["String"]["output"]>;
  investorsEmeaSeedProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsEmeaSeedProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  investorsEmeaSeriesaProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsEmeaSeriesaProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  investorsGlobalSeedProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsGlobalSeedProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  investorsGlobalSeriesaProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsGlobalSeriesaProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  investorsMultistageEmeaProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsMultistageEmeaProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  investorsMultistageGlobalProminence2022Percentile?: Maybe<Scalars["String"]["output"]>;
  investorsMultistageGlobalProminence2022Unique?: Maybe<Scalars["String"]["output"]>;
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  multistageEmeaProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  multistageEmeaProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  multistageGlobalProminence2021Percentile?: Maybe<Scalars["String"]["output"]>;
  multistageGlobalProminence2021Unique?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  path?: Maybe<Scalars["String"]["output"]>;
  playmarketAppId?: Maybe<Scalars["String"]["output"]>;
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  subTypes?: Maybe<Scalars["String"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
  team?: Maybe<Scalars["JSON"]["output"]>;
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyCondition>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompaniesOrderBy>>;
};

export type DealroomInvestorDealroomCompanyInvestorsByInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyInvestorCondition>;
  filter?: InputMaybe<DealroomCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyInvestorsOrderBy>>;
};

export type DealroomInvestorDealroomFundingRoundInvestorsByInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundInvestorCondition>;
  filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundInvestorsOrderBy>>;
};

export type DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundCondition>;
  filter?: InputMaybe<DealroomFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundsOrderBy>>;
};

export type DealroomInvestorAggregates = {
  __typename?: "DealroomInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomInvestorVarianceSampleAggregates>;
};

export type DealroomInvestorAverageAggregates = {
  __typename?: "DealroomInvestorAverageAggregates";
  /** Mean average of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomInvestor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealroomInvestorCondition = {
  /** Checks for equality with the object’s `about` field. */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `acceleratorProminence2021Percentile` field. */
  acceleratorProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `acceleratorProminence2021Unique` field. */
  acceleratorProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `countryExperience` field. */
  countryExperience?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealSize` field. */
  dealSize?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealStructure` field. */
  dealStructure?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `dutchInvestmentsNum` field. */
  dutchInvestmentsNum?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `emeaSeedProminence2021Percentile` field. */
  emeaSeedProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `emeaSeedProminence2021Unique` field. */
  emeaSeedProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `emeaSeriesaProminence2021Percentile` field. */
  emeaSeriesaProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `emeaSeriesaProminence2021Unique` field. */
  emeaSeriesaProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employees` field. */
  employees?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `europeSeedProminencePercentile` field. */
  europeSeedProminencePercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `europeSeedProminenceUnique` field. */
  europeSeedProminenceUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `europeSeriesaProminencePercentile` field. */
  europeSeriesaProminencePercentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `europeSeriesaProminenceUnique` field. */
  europeSeriesaProminenceUnique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fundings` field. */
  fundings?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `fundsUnderManagement` field. */
  fundsUnderManagement?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `globalSeedProminence2021Percentile` field. */
  globalSeedProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `globalSeedProminence2021Unique` field. */
  globalSeedProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `globalSeriesaProminence2021Percentile` field. */
  globalSeriesaProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `globalSeriesaProminence2021Unique` field. */
  globalSeriesaProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `industryExperience` field. */
  industryExperience?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investmentStages` field. */
  investmentStages?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investments` field. */
  investments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investmentsNum` field. */
  investmentsNum?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `investorType` field. */
  investorType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsEmeaSeedProminence2022Percentile` field. */
  investorsEmeaSeedProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsEmeaSeedProminence2022Unique` field. */
  investorsEmeaSeedProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsEmeaSeriesaProminence2022Percentile` field. */
  investorsEmeaSeriesaProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsEmeaSeriesaProminence2022Unique` field. */
  investorsEmeaSeriesaProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsGlobalSeedProminence2022Percentile` field. */
  investorsGlobalSeedProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsGlobalSeedProminence2022Unique` field. */
  investorsGlobalSeedProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsGlobalSeriesaProminence2022Percentile` field. */
  investorsGlobalSeriesaProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsGlobalSeriesaProminence2022Unique` field. */
  investorsGlobalSeriesaProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsMultistageEmeaProminence2022Percentile` field. */
  investorsMultistageEmeaProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsMultistageEmeaProminence2022Unique` field. */
  investorsMultistageEmeaProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsMultistageGlobalProminence2022Percentile` field. */
  investorsMultistageGlobalProminence2022Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investorsMultistageGlobalProminence2022Unique` field. */
  investorsMultistageGlobalProminence2022Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `launchYear` field. */
  launchYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `multistageEmeaProminence2021Percentile` field. */
  multistageEmeaProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `multistageEmeaProminence2021Unique` field. */
  multistageEmeaProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `multistageGlobalProminence2021Percentile` field. */
  multistageGlobalProminence2021Percentile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `multistageGlobalProminence2021Unique` field. */
  multistageGlobalProminence2021Unique?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recentFunding` field. */
  recentFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `recentFundingSource` field. */
  recentFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `subTypes` field. */
  subTypes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `team` field. */
  team?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `topInvestmentsNum` field. */
  topInvestmentsNum?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DealroomCompany` values, with data from `DealroomCompanyInvestor`. */
export type DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection = {
  __typename?: "DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomCompanyAggregates>;
  /** A list of edges which contains the `DealroomCompany`, info from the `DealroomCompanyInvestor`, and the cursor to aid in pagination. */
  edges: Array<DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomCompanyAggregates>>;
  /** A list of `DealroomCompany` objects. */
  nodes: Array<DealroomCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomCompany` values, with data from `DealroomCompanyInvestor`. */
export type DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomCompanyGroupBy>;
    having?: InputMaybe<DealroomCompanyHavingInput>;
  };

/** A `DealroomCompany` edge in the connection, with data from `DealroomCompanyInvestor`. */
export type DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge = {
  __typename?: "DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompanyInvestor`. */
  dealroomCompanyInvestorsByCompanyId: DealroomCompanyInvestorsConnection;
  /** The `DealroomCompany` at the end of the edge. */
  node: DealroomCompany;
};

/** A `DealroomCompany` edge in the connection, with data from `DealroomCompanyInvestor`. */
export type DealroomInvestorDealroomCompaniesByDealroomCompanyInvestorInvestorIdAndCompanyIdManyToManyEdgeDealroomCompanyInvestorsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomCompanyInvestorCondition>;
    filter?: InputMaybe<DealroomCompanyInvestorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomCompanyInvestorsOrderBy>>;
  };

/** A connection to a list of `DealroomFundingRound` values, with data from `DealroomFundingRoundInvestor`. */
export type DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection =
  {
    __typename?: "DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<DealroomFundingRoundAggregates>;
    /** A list of edges which contains the `DealroomFundingRound`, info from the `DealroomFundingRoundInvestor`, and the cursor to aid in pagination. */
    edges: Array<DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<DealroomFundingRoundAggregates>>;
    /** A list of `DealroomFundingRound` objects. */
    nodes: Array<DealroomFundingRound>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `DealroomFundingRound` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `DealroomFundingRound` values, with data from `DealroomFundingRoundInvestor`. */
export type DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomFundingRoundGroupBy>;
    having?: InputMaybe<DealroomFundingRoundHavingInput>;
  };

/** A `DealroomFundingRound` edge in the connection, with data from `DealroomFundingRoundInvestor`. */
export type DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge =
  {
    __typename?: "DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge";
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars["Cursor"]["output"]>;
    /** Reads and enables pagination through a set of `DealroomFundingRoundInvestor`. */
    dealroomFundingRoundInvestorsByFundingRoundId: DealroomFundingRoundInvestorsConnection;
    /** The `DealroomFundingRound` at the end of the edge. */
    node: DealroomFundingRound;
  };

/** A `DealroomFundingRound` edge in the connection, with data from `DealroomFundingRoundInvestor`. */
export type DealroomInvestorDealroomFundingRoundsByDealroomFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdgeDealroomFundingRoundInvestorsByFundingRoundIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DealroomFundingRoundInvestorCondition>;
    filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DealroomFundingRoundInvestorsOrderBy>>;
  };

export type DealroomInvestorDistinctCountAggregates = {
  __typename?: "DealroomInvestorDistinctCountAggregates";
  /** Distinct count of about across the matching connection */
  about?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of acceleratorProminence2021Percentile across the matching connection */
  acceleratorProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of acceleratorProminence2021Unique across the matching connection */
  acceleratorProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of angellistUrl across the matching connection */
  angellistUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appstoreAppId across the matching connection */
  appstoreAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of clientFocus across the matching connection */
  clientFocus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of coInvestors across the matching connection */
  coInvestors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of countryExperience across the matching connection */
  countryExperience?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealSize across the matching connection */
  dealSize?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealStructure across the matching connection */
  dealStructure?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emeaSeedProminence2021Percentile across the matching connection */
  emeaSeedProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emeaSeedProminence2021Unique across the matching connection */
  emeaSeedProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emeaSeriesaProminence2021Percentile across the matching connection */
  emeaSeriesaProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emeaSeriesaProminence2021Unique across the matching connection */
  emeaSeriesaProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employees across the matching connection */
  employees?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of europeSeedProminencePercentile across the matching connection */
  europeSeedProminencePercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of europeSeedProminenceUnique across the matching connection */
  europeSeedProminenceUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of europeSeriesaProminencePercentile across the matching connection */
  europeSeriesaProminencePercentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of europeSeriesaProminenceUnique across the matching connection */
  europeSeriesaProminenceUnique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundings across the matching connection */
  fundings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundsUnderManagement across the matching connection */
  fundsUnderManagement?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of globalSeedProminence2021Percentile across the matching connection */
  globalSeedProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of globalSeedProminence2021Unique across the matching connection */
  globalSeedProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of globalSeriesaProminence2021Percentile across the matching connection */
  globalSeriesaProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of globalSeriesaProminence2021Unique across the matching connection */
  globalSeriesaProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleUrl across the matching connection */
  googleUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqLocations across the matching connection */
  hqLocations?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of industryExperience across the matching connection */
  industryExperience?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentStages across the matching connection */
  investmentStages?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investments across the matching connection */
  investments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorType across the matching connection */
  investorType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsEmeaSeedProminence2022Percentile across the matching connection */
  investorsEmeaSeedProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsEmeaSeedProminence2022Unique across the matching connection */
  investorsEmeaSeedProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsEmeaSeriesaProminence2022Percentile across the matching connection */
  investorsEmeaSeriesaProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsEmeaSeriesaProminence2022Unique across the matching connection */
  investorsEmeaSeriesaProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsGlobalSeedProminence2022Percentile across the matching connection */
  investorsGlobalSeedProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsGlobalSeedProminence2022Unique across the matching connection */
  investorsGlobalSeedProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsGlobalSeriesaProminence2022Percentile across the matching connection */
  investorsGlobalSeriesaProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsGlobalSeriesaProminence2022Unique across the matching connection */
  investorsGlobalSeriesaProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsMultistageEmeaProminence2022Percentile across the matching connection */
  investorsMultistageEmeaProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsMultistageEmeaProminence2022Unique across the matching connection */
  investorsMultistageEmeaProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsMultistageGlobalProminence2022Percentile across the matching connection */
  investorsMultistageGlobalProminence2022Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorsMultistageGlobalProminence2022Unique across the matching connection */
  investorsMultistageGlobalProminence2022Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isEditorial across the matching connection */
  isEditorial?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of multistageEmeaProminence2021Percentile across the matching connection */
  multistageEmeaProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of multistageEmeaProminence2021Unique across the matching connection */
  multistageEmeaProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of multistageGlobalProminence2021Percentile across the matching connection */
  multistageGlobalProminence2021Percentile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of multistageGlobalProminence2021Unique across the matching connection */
  multistageGlobalProminence2021Unique?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of playmarketAppId across the matching connection */
  playmarketAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subTypes across the matching connection */
  subTypes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of team across the matching connection */
  team?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomInvestorFilter = {
  /** Filter by the object’s `about` field. */
  about?: InputMaybe<StringFilter>;
  /** Filter by the object’s `acceleratorProminence2021Percentile` field. */
  acceleratorProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `acceleratorProminence2021Unique` field. */
  acceleratorProminence2021Unique?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomInvestorFilter>>;
  /** Filter by the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `countryExperience` field. */
  countryExperience?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealSize` field. */
  dealSize?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealStructure` field. */
  dealStructure?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dealroomCompanyInvestorsByInvestorId` relation. */
  dealroomCompanyInvestorsByInvestorId?: InputMaybe<DealroomInvestorToManyDealroomCompanyInvestorFilter>;
  /** Some related `dealroomCompanyInvestorsByInvestorId` exist. */
  dealroomCompanyInvestorsByInvestorIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomFundingRoundInvestorsByInvestorId` relation. */
  dealroomFundingRoundInvestorsByInvestorId?: InputMaybe<DealroomInvestorToManyDealroomFundingRoundInvestorFilter>;
  /** Some related `dealroomFundingRoundInvestorsByInvestorId` exist. */
  dealroomFundingRoundInvestorsByInvestorIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dutchInvestmentsNum` field. */
  dutchInvestmentsNum?: InputMaybe<IntFilter>;
  /** Filter by the object’s `emeaSeedProminence2021Percentile` field. */
  emeaSeedProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emeaSeedProminence2021Unique` field. */
  emeaSeedProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emeaSeriesaProminence2021Percentile` field. */
  emeaSeriesaProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emeaSeriesaProminence2021Unique` field. */
  emeaSeriesaProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employees` field. */
  employees?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<IntFilter>;
  /** Filter by the object’s `europeSeedProminencePercentile` field. */
  europeSeedProminencePercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `europeSeedProminenceUnique` field. */
  europeSeedProminenceUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `europeSeriesaProminencePercentile` field. */
  europeSeriesaProminencePercentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `europeSeriesaProminenceUnique` field. */
  europeSeriesaProminenceUnique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fundings` field. */
  fundings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `fundsUnderManagement` field. */
  fundsUnderManagement?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `globalSeedProminence2021Percentile` field. */
  globalSeedProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `globalSeedProminence2021Unique` field. */
  globalSeedProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `globalSeriesaProminence2021Percentile` field. */
  globalSeriesaProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `globalSeriesaProminence2021Unique` field. */
  globalSeriesaProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `industryExperience` field. */
  industryExperience?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investmentStages` field. */
  investmentStages?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investments` field. */
  investments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investmentsNum` field. */
  investmentsNum?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `investorType` field. */
  investorType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsEmeaSeedProminence2022Percentile` field. */
  investorsEmeaSeedProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsEmeaSeedProminence2022Unique` field. */
  investorsEmeaSeedProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsEmeaSeriesaProminence2022Percentile` field. */
  investorsEmeaSeriesaProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsEmeaSeriesaProminence2022Unique` field. */
  investorsEmeaSeriesaProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsGlobalSeedProminence2022Percentile` field. */
  investorsGlobalSeedProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsGlobalSeedProminence2022Unique` field. */
  investorsGlobalSeedProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsGlobalSeriesaProminence2022Percentile` field. */
  investorsGlobalSeriesaProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsGlobalSeriesaProminence2022Unique` field. */
  investorsGlobalSeriesaProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsMultistageEmeaProminence2022Percentile` field. */
  investorsMultistageEmeaProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsMultistageEmeaProminence2022Unique` field. */
  investorsMultistageEmeaProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsMultistageGlobalProminence2022Percentile` field. */
  investorsMultistageGlobalProminence2022Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investorsMultistageGlobalProminence2022Unique` field. */
  investorsMultistageGlobalProminence2022Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `launchYear` field. */
  launchYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `multistageEmeaProminence2021Percentile` field. */
  multistageEmeaProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `multistageEmeaProminence2021Unique` field. */
  multistageEmeaProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `multistageGlobalProminence2021Percentile` field. */
  multistageGlobalProminence2021Percentile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `multistageGlobalProminence2021Unique` field. */
  multistageGlobalProminence2021Unique?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomInvestorFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recentFunding` field. */
  recentFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `recentFundingSource` field. */
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `subTypes` field. */
  subTypes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `team` field. */
  team?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `topInvestmentsNum` field. */
  topInvestmentsNum?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DealroomInvestor` for usage during aggregation. */
export enum DealroomInvestorGroupBy {
  About = "ABOUT",
  AcceleratorProminence_2021Percentile = "ACCELERATOR_PROMINENCE_2021_PERCENTILE",
  AcceleratorProminence_2021Unique = "ACCELERATOR_PROMINENCE_2021_UNIQUE",
  AngellistUrl = "ANGELLIST_URL",
  AppstoreAppId = "APPSTORE_APP_ID",
  ClientFocus = "CLIENT_FOCUS",
  CountryExperience = "COUNTRY_EXPERIENCE",
  CoInvestors = "CO_INVESTORS",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  CrunchbaseUrl = "CRUNCHBASE_URL",
  DealSize = "DEAL_SIZE",
  DealStructure = "DEAL_STRUCTURE",
  DutchInvestmentsNum = "DUTCH_INVESTMENTS_NUM",
  EmeaSeedProminence_2021Percentile = "EMEA_SEED_PROMINENCE_2021_PERCENTILE",
  EmeaSeedProminence_2021Unique = "EMEA_SEED_PROMINENCE_2021_UNIQUE",
  EmeaSeriesaProminence_2021Percentile = "EMEA_SERIESA_PROMINENCE_2021_PERCENTILE",
  EmeaSeriesaProminence_2021Unique = "EMEA_SERIESA_PROMINENCE_2021_UNIQUE",
  Employees = "EMPLOYEES",
  EmployeesLatest = "EMPLOYEES_LATEST",
  EuropeSeedProminencePercentile = "EUROPE_SEED_PROMINENCE_PERCENTILE",
  EuropeSeedProminenceUnique = "EUROPE_SEED_PROMINENCE_UNIQUE",
  EuropeSeriesaProminencePercentile = "EUROPE_SERIESA_PROMINENCE_PERCENTILE",
  EuropeSeriesaProminenceUnique = "EUROPE_SERIESA_PROMINENCE_UNIQUE",
  FacebookUrl = "FACEBOOK_URL",
  Fundings = "FUNDINGS",
  FundsUnderManagement = "FUNDS_UNDER_MANAGEMENT",
  GlobalSeedProminence_2021Percentile = "GLOBAL_SEED_PROMINENCE_2021_PERCENTILE",
  GlobalSeedProminence_2021Unique = "GLOBAL_SEED_PROMINENCE_2021_UNIQUE",
  GlobalSeriesaProminence_2021Percentile = "GLOBAL_SERIESA_PROMINENCE_2021_PERCENTILE",
  GlobalSeriesaProminence_2021Unique = "GLOBAL_SERIESA_PROMINENCE_2021_UNIQUE",
  GoogleUrl = "GOOGLE_URL",
  HqLocations = "HQ_LOCATIONS",
  Image = "IMAGE",
  IndustryExperience = "INDUSTRY_EXPERIENCE",
  Investments = "INVESTMENTS",
  InvestmentsNum = "INVESTMENTS_NUM",
  InvestmentStages = "INVESTMENT_STAGES",
  InvestorsEmeaSeedProminence_2022Percentile = "INVESTORS_EMEA_SEED_PROMINENCE_2022_PERCENTILE",
  InvestorsEmeaSeedProminence_2022Unique = "INVESTORS_EMEA_SEED_PROMINENCE_2022_UNIQUE",
  InvestorsEmeaSeriesaProminence_2022Percentile = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_PERCENTILE",
  InvestorsEmeaSeriesaProminence_2022Unique = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_UNIQUE",
  InvestorsGlobalSeedProminence_2022Percentile = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_PERCENTILE",
  InvestorsGlobalSeedProminence_2022Unique = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_UNIQUE",
  InvestorsGlobalSeriesaProminence_2022Percentile = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_PERCENTILE",
  InvestorsGlobalSeriesaProminence_2022Unique = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_UNIQUE",
  InvestorsMultistageEmeaProminence_2022Percentile = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_PERCENTILE",
  InvestorsMultistageEmeaProminence_2022Unique = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_UNIQUE",
  InvestorsMultistageGlobalProminence_2022Percentile = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_PERCENTILE",
  InvestorsMultistageGlobalProminence_2022Unique = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_UNIQUE",
  InvestorType = "INVESTOR_TYPE",
  IsEditorial = "IS_EDITORIAL",
  LastFunding = "LAST_FUNDING",
  LastFundingSource = "LAST_FUNDING_SOURCE",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LaunchMonth = "LAUNCH_MONTH",
  LaunchYear = "LAUNCH_YEAR",
  LinkedinUrl = "LINKEDIN_URL",
  MultistageEmeaProminence_2021Percentile = "MULTISTAGE_EMEA_PROMINENCE_2021_PERCENTILE",
  MultistageEmeaProminence_2021Unique = "MULTISTAGE_EMEA_PROMINENCE_2021_UNIQUE",
  MultistageGlobalProminence_2021Percentile = "MULTISTAGE_GLOBAL_PROMINENCE_2021_PERCENTILE",
  MultistageGlobalProminence_2021Unique = "MULTISTAGE_GLOBAL_PROMINENCE_2021_UNIQUE",
  Name = "NAME",
  Path = "PATH",
  PlaymarketAppId = "PLAYMARKET_APP_ID",
  RecentFunding = "RECENT_FUNDING",
  RecentFundingSource = "RECENT_FUNDING_SOURCE",
  SubTypes = "SUB_TYPES",
  Tagline = "TAGLINE",
  Tags = "TAGS",
  Team = "TEAM",
  TopInvestmentsNum = "TOP_INVESTMENTS_NUM",
  TotalFunding = "TOTAL_FUNDING",
  TotalFundingSource = "TOTAL_FUNDING_SOURCE",
  TwitterUrl = "TWITTER_URL",
  Url = "URL",
  WebsiteUrl = "WEBSITE_URL",
}

export type DealroomInvestorHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

/** Conditions for `DealroomInvestor` aggregates. */
export type DealroomInvestorHavingInput = {
  AND?: InputMaybe<Array<DealroomInvestorHavingInput>>;
  OR?: InputMaybe<Array<DealroomInvestorHavingInput>>;
  average?: InputMaybe<DealroomInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomInvestorHavingDistinctCountInput>;
  max?: InputMaybe<DealroomInvestorHavingMaxInput>;
  min?: InputMaybe<DealroomInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomInvestorHavingVarianceSampleInput>;
};

export type DealroomInvestorHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type DealroomInvestorMaxAggregates = {
  __typename?: "DealroomInvestorMaxAggregates";
  /** Maximum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomInvestorMinAggregates = {
  __typename?: "DealroomInvestorMinAggregates";
  /** Minimum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomInvestorStddevPopulationAggregates = {
  __typename?: "DealroomInvestorStddevPopulationAggregates";
  /** Population standard deviation of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomInvestorStddevSampleAggregates = {
  __typename?: "DealroomInvestorStddevSampleAggregates";
  /** Sample standard deviation of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomInvestorSumAggregates = {
  __typename?: "DealroomInvestorSumAggregates";
  /** Sum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum: Scalars["BigInt"]["output"];
  /** Sum of employeesLatest across the matching connection */
  employeesLatest: Scalars["BigInt"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of investmentsNum across the matching connection */
  investmentsNum: Scalars["BigFloat"]["output"];
  /** Sum of lastFunding across the matching connection */
  lastFunding: Scalars["BigFloat"]["output"];
  /** Sum of lastFundingSource across the matching connection */
  lastFundingSource: Scalars["BigFloat"]["output"];
  /** Sum of launchMonth across the matching connection */
  launchMonth: Scalars["BigInt"]["output"];
  /** Sum of launchYear across the matching connection */
  launchYear: Scalars["BigInt"]["output"];
  /** Sum of recentFunding across the matching connection */
  recentFunding: Scalars["BigFloat"]["output"];
  /** Sum of recentFundingSource across the matching connection */
  recentFundingSource: Scalars["BigFloat"]["output"];
  /** Sum of topInvestmentsNum across the matching connection */
  topInvestmentsNum: Scalars["BigInt"]["output"];
  /** Sum of totalFunding across the matching connection */
  totalFunding: Scalars["BigFloat"]["output"];
  /** Sum of totalFundingSource across the matching connection */
  totalFundingSource: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `DealroomCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomInvestorToManyDealroomCompanyInvestorFilter = {
  /** Aggregates across related `DealroomCompanyInvestor` match the filter criteria. */
  aggregates?: InputMaybe<DealroomCompanyInvestorAggregatesFilter>;
  /** Every related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** No related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomCompanyInvestorFilter>;
  /** Some related `DealroomCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomCompanyInvestorFilter>;
};

/** A filter to be used against many `DealroomFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type DealroomInvestorToManyDealroomFundingRoundInvestorFilter = {
  /** Aggregates across related `DealroomFundingRoundInvestor` match the filter criteria. */
  aggregates?: InputMaybe<DealroomFundingRoundInvestorAggregatesFilter>;
  /** Every related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** No related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  /** Some related `DealroomFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DealroomFundingRoundInvestorFilter>;
};

export type DealroomInvestorVariancePopulationAggregates = {
  __typename?: "DealroomInvestorVariancePopulationAggregates";
  /** Population variance of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomInvestorVarianceSampleAggregates = {
  __typename?: "DealroomInvestorVarianceSampleAggregates";
  /** Sample variance of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomInvestor` values. */
export type DealroomInvestorsConnection = {
  __typename?: "DealroomInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomInvestorAggregates>;
  /** A list of edges which contains the `DealroomInvestor` and cursor to aid in pagination. */
  edges: Array<DealroomInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomInvestorAggregates>>;
  /** A list of `DealroomInvestor` objects. */
  nodes: Array<DealroomInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomInvestor` values. */
export type DealroomInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomInvestorGroupBy>;
  having?: InputMaybe<DealroomInvestorHavingInput>;
};

/** A `DealroomInvestor` edge in the connection. */
export type DealroomInvestorsEdge = {
  __typename?: "DealroomInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomInvestor` at the end of the edge. */
  node: DealroomInvestor;
};

/** Methods to use when ordering `DealroomInvestor`. */
export enum DealroomInvestorsOrderBy {
  AboutAsc = "ABOUT_ASC",
  AboutDesc = "ABOUT_DESC",
  AcceleratorProminence_2021PercentileAsc = "ACCELERATOR_PROMINENCE_2021_PERCENTILE_ASC",
  AcceleratorProminence_2021PercentileDesc = "ACCELERATOR_PROMINENCE_2021_PERCENTILE_DESC",
  AcceleratorProminence_2021UniqueAsc = "ACCELERATOR_PROMINENCE_2021_UNIQUE_ASC",
  AcceleratorProminence_2021UniqueDesc = "ACCELERATOR_PROMINENCE_2021_UNIQUE_DESC",
  AngellistUrlAsc = "ANGELLIST_URL_ASC",
  AngellistUrlDesc = "ANGELLIST_URL_DESC",
  AppstoreAppIdAsc = "APPSTORE_APP_ID_ASC",
  AppstoreAppIdDesc = "APPSTORE_APP_ID_DESC",
  ClientFocusAsc = "CLIENT_FOCUS_ASC",
  ClientFocusDesc = "CLIENT_FOCUS_DESC",
  CountryExperienceAsc = "COUNTRY_EXPERIENCE_ASC",
  CountryExperienceDesc = "COUNTRY_EXPERIENCE_DESC",
  CoInvestorsAsc = "CO_INVESTORS_ASC",
  CoInvestorsDesc = "CO_INVESTORS_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CrunchbaseUrlAsc = "CRUNCHBASE_URL_ASC",
  CrunchbaseUrlDesc = "CRUNCHBASE_URL_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdAverageIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdAverageIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdCountAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_COUNT_ASC",
  DealroomCompanyInvestorsByInvestorIdCountDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_COUNT_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdDistinctCountIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMaxIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdMaxIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdMinCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMinCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMinCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMinCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMinExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdMinExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdMinIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMinIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMinInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdMinInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdMinIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdMinIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevPopulationIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdStddevSampleIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdSumCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdSumCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdSumCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdSumCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdSumExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdSumExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdSumIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdSumIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdSumInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdSumInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdSumIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdSumIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdVariancePopulationIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_IS_EDITORIAL_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleCompanyIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleCompanyIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleCompositeIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleCompositeIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleExitedAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_EXITED_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleExitedDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_EXITED_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleInvestorIdAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleInvestorIdDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleIsEditorialAsc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_IS_EDITORIAL_ASC",
  DealroomCompanyInvestorsByInvestorIdVarianceSampleIsEditorialDesc = "DEALROOM_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_IS_EDITORIAL_DESC",
  DealroomFundingRoundInvestorsByInvestorIdAverageCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdAverageCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdAverageFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdAverageFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdAverageIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdAverageIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdAverageInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdAverageInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdAverageLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdAverageLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdCountAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_COUNT_ASC",
  DealroomFundingRoundInvestorsByInvestorIdCountDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_COUNT_DESC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdDistinctCountLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMaxCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMaxCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMaxFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMaxFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMaxIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMaxIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMaxInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMaxInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMaxLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMaxLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMinCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMinCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMinFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMinFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMinIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMinIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMinInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMinInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdMinLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdMinLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevPopulationLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdStddevSampleLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdSumCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdSumCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdSumFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdSumFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdSumIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdSumIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdSumInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdSumInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdSumLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdSumLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVariancePopulationLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_LEAD_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleCompositeIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleCompositeIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleFundingRoundIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleFundingRoundIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleInvestorIdAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleInvestorIdDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleLeadAsc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_LEAD_ASC",
  DealroomFundingRoundInvestorsByInvestorIdVarianceSampleLeadDesc = "DEALROOM_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_LEAD_DESC",
  DealSizeAsc = "DEAL_SIZE_ASC",
  DealSizeDesc = "DEAL_SIZE_DESC",
  DealStructureAsc = "DEAL_STRUCTURE_ASC",
  DealStructureDesc = "DEAL_STRUCTURE_DESC",
  DutchInvestmentsNumAsc = "DUTCH_INVESTMENTS_NUM_ASC",
  DutchInvestmentsNumDesc = "DUTCH_INVESTMENTS_NUM_DESC",
  EmeaSeedProminence_2021PercentileAsc = "EMEA_SEED_PROMINENCE_2021_PERCENTILE_ASC",
  EmeaSeedProminence_2021PercentileDesc = "EMEA_SEED_PROMINENCE_2021_PERCENTILE_DESC",
  EmeaSeedProminence_2021UniqueAsc = "EMEA_SEED_PROMINENCE_2021_UNIQUE_ASC",
  EmeaSeedProminence_2021UniqueDesc = "EMEA_SEED_PROMINENCE_2021_UNIQUE_DESC",
  EmeaSeriesaProminence_2021PercentileAsc = "EMEA_SERIESA_PROMINENCE_2021_PERCENTILE_ASC",
  EmeaSeriesaProminence_2021PercentileDesc = "EMEA_SERIESA_PROMINENCE_2021_PERCENTILE_DESC",
  EmeaSeriesaProminence_2021UniqueAsc = "EMEA_SERIESA_PROMINENCE_2021_UNIQUE_ASC",
  EmeaSeriesaProminence_2021UniqueDesc = "EMEA_SERIESA_PROMINENCE_2021_UNIQUE_DESC",
  EmployeesAsc = "EMPLOYEES_ASC",
  EmployeesDesc = "EMPLOYEES_DESC",
  EmployeesLatestAsc = "EMPLOYEES_LATEST_ASC",
  EmployeesLatestDesc = "EMPLOYEES_LATEST_DESC",
  EuropeSeedProminencePercentileAsc = "EUROPE_SEED_PROMINENCE_PERCENTILE_ASC",
  EuropeSeedProminencePercentileDesc = "EUROPE_SEED_PROMINENCE_PERCENTILE_DESC",
  EuropeSeedProminenceUniqueAsc = "EUROPE_SEED_PROMINENCE_UNIQUE_ASC",
  EuropeSeedProminenceUniqueDesc = "EUROPE_SEED_PROMINENCE_UNIQUE_DESC",
  EuropeSeriesaProminencePercentileAsc = "EUROPE_SERIESA_PROMINENCE_PERCENTILE_ASC",
  EuropeSeriesaProminencePercentileDesc = "EUROPE_SERIESA_PROMINENCE_PERCENTILE_DESC",
  EuropeSeriesaProminenceUniqueAsc = "EUROPE_SERIESA_PROMINENCE_UNIQUE_ASC",
  EuropeSeriesaProminenceUniqueDesc = "EUROPE_SERIESA_PROMINENCE_UNIQUE_DESC",
  FacebookUrlAsc = "FACEBOOK_URL_ASC",
  FacebookUrlDesc = "FACEBOOK_URL_DESC",
  FundingsAsc = "FUNDINGS_ASC",
  FundingsDesc = "FUNDINGS_DESC",
  FundsUnderManagementAsc = "FUNDS_UNDER_MANAGEMENT_ASC",
  FundsUnderManagementDesc = "FUNDS_UNDER_MANAGEMENT_DESC",
  GlobalSeedProminence_2021PercentileAsc = "GLOBAL_SEED_PROMINENCE_2021_PERCENTILE_ASC",
  GlobalSeedProminence_2021PercentileDesc = "GLOBAL_SEED_PROMINENCE_2021_PERCENTILE_DESC",
  GlobalSeedProminence_2021UniqueAsc = "GLOBAL_SEED_PROMINENCE_2021_UNIQUE_ASC",
  GlobalSeedProminence_2021UniqueDesc = "GLOBAL_SEED_PROMINENCE_2021_UNIQUE_DESC",
  GlobalSeriesaProminence_2021PercentileAsc = "GLOBAL_SERIESA_PROMINENCE_2021_PERCENTILE_ASC",
  GlobalSeriesaProminence_2021PercentileDesc = "GLOBAL_SERIESA_PROMINENCE_2021_PERCENTILE_DESC",
  GlobalSeriesaProminence_2021UniqueAsc = "GLOBAL_SERIESA_PROMINENCE_2021_UNIQUE_ASC",
  GlobalSeriesaProminence_2021UniqueDesc = "GLOBAL_SERIESA_PROMINENCE_2021_UNIQUE_DESC",
  GoogleUrlAsc = "GOOGLE_URL_ASC",
  GoogleUrlDesc = "GOOGLE_URL_DESC",
  HqLocationsAsc = "HQ_LOCATIONS_ASC",
  HqLocationsDesc = "HQ_LOCATIONS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IndustryExperienceAsc = "INDUSTRY_EXPERIENCE_ASC",
  IndustryExperienceDesc = "INDUSTRY_EXPERIENCE_DESC",
  InvestmentsAsc = "INVESTMENTS_ASC",
  InvestmentsDesc = "INVESTMENTS_DESC",
  InvestmentsNumAsc = "INVESTMENTS_NUM_ASC",
  InvestmentsNumDesc = "INVESTMENTS_NUM_DESC",
  InvestmentStagesAsc = "INVESTMENT_STAGES_ASC",
  InvestmentStagesDesc = "INVESTMENT_STAGES_DESC",
  InvestorsEmeaSeedProminence_2022PercentileAsc = "INVESTORS_EMEA_SEED_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsEmeaSeedProminence_2022PercentileDesc = "INVESTORS_EMEA_SEED_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsEmeaSeedProminence_2022UniqueAsc = "INVESTORS_EMEA_SEED_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsEmeaSeedProminence_2022UniqueDesc = "INVESTORS_EMEA_SEED_PROMINENCE_2022_UNIQUE_DESC",
  InvestorsEmeaSeriesaProminence_2022PercentileAsc = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsEmeaSeriesaProminence_2022PercentileDesc = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsEmeaSeriesaProminence_2022UniqueAsc = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsEmeaSeriesaProminence_2022UniqueDesc = "INVESTORS_EMEA_SERIESA_PROMINENCE_2022_UNIQUE_DESC",
  InvestorsGlobalSeedProminence_2022PercentileAsc = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsGlobalSeedProminence_2022PercentileDesc = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsGlobalSeedProminence_2022UniqueAsc = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsGlobalSeedProminence_2022UniqueDesc = "INVESTORS_GLOBAL_SEED_PROMINENCE_2022_UNIQUE_DESC",
  InvestorsGlobalSeriesaProminence_2022PercentileAsc = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsGlobalSeriesaProminence_2022PercentileDesc = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsGlobalSeriesaProminence_2022UniqueAsc = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsGlobalSeriesaProminence_2022UniqueDesc = "INVESTORS_GLOBAL_SERIESA_PROMINENCE_2022_UNIQUE_DESC",
  InvestorsMultistageEmeaProminence_2022PercentileAsc = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsMultistageEmeaProminence_2022PercentileDesc = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsMultistageEmeaProminence_2022UniqueAsc = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsMultistageEmeaProminence_2022UniqueDesc = "INVESTORS_MULTISTAGE_EMEA_PROMINENCE_2022_UNIQUE_DESC",
  InvestorsMultistageGlobalProminence_2022PercentileAsc = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_PERCENTILE_ASC",
  InvestorsMultistageGlobalProminence_2022PercentileDesc = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_PERCENTILE_DESC",
  InvestorsMultistageGlobalProminence_2022UniqueAsc = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_UNIQUE_ASC",
  InvestorsMultistageGlobalProminence_2022UniqueDesc = "INVESTORS_MULTISTAGE_GLOBAL_PROMINENCE_2022_UNIQUE_DESC",
  InvestorTypeAsc = "INVESTOR_TYPE_ASC",
  InvestorTypeDesc = "INVESTOR_TYPE_DESC",
  IsEditorialAsc = "IS_EDITORIAL_ASC",
  IsEditorialDesc = "IS_EDITORIAL_DESC",
  LastFundingAsc = "LAST_FUNDING_ASC",
  LastFundingDesc = "LAST_FUNDING_DESC",
  LastFundingSourceAsc = "LAST_FUNDING_SOURCE_ASC",
  LastFundingSourceDesc = "LAST_FUNDING_SOURCE_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LaunchMonthAsc = "LAUNCH_MONTH_ASC",
  LaunchMonthDesc = "LAUNCH_MONTH_DESC",
  LaunchYearAsc = "LAUNCH_YEAR_ASC",
  LaunchYearDesc = "LAUNCH_YEAR_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  MultistageEmeaProminence_2021PercentileAsc = "MULTISTAGE_EMEA_PROMINENCE_2021_PERCENTILE_ASC",
  MultistageEmeaProminence_2021PercentileDesc = "MULTISTAGE_EMEA_PROMINENCE_2021_PERCENTILE_DESC",
  MultistageEmeaProminence_2021UniqueAsc = "MULTISTAGE_EMEA_PROMINENCE_2021_UNIQUE_ASC",
  MultistageEmeaProminence_2021UniqueDesc = "MULTISTAGE_EMEA_PROMINENCE_2021_UNIQUE_DESC",
  MultistageGlobalProminence_2021PercentileAsc = "MULTISTAGE_GLOBAL_PROMINENCE_2021_PERCENTILE_ASC",
  MultistageGlobalProminence_2021PercentileDesc = "MULTISTAGE_GLOBAL_PROMINENCE_2021_PERCENTILE_DESC",
  MultistageGlobalProminence_2021UniqueAsc = "MULTISTAGE_GLOBAL_PROMINENCE_2021_UNIQUE_ASC",
  MultistageGlobalProminence_2021UniqueDesc = "MULTISTAGE_GLOBAL_PROMINENCE_2021_UNIQUE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PlaymarketAppIdAsc = "PLAYMARKET_APP_ID_ASC",
  PlaymarketAppIdDesc = "PLAYMARKET_APP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecentFundingAsc = "RECENT_FUNDING_ASC",
  RecentFundingDesc = "RECENT_FUNDING_DESC",
  RecentFundingSourceAsc = "RECENT_FUNDING_SOURCE_ASC",
  RecentFundingSourceDesc = "RECENT_FUNDING_SOURCE_DESC",
  SubTypesAsc = "SUB_TYPES_ASC",
  SubTypesDesc = "SUB_TYPES_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TeamAsc = "TEAM_ASC",
  TeamDesc = "TEAM_DESC",
  TopInvestmentsNumAsc = "TOP_INVESTMENTS_NUM_ASC",
  TopInvestmentsNumDesc = "TOP_INVESTMENTS_NUM_DESC",
  TotalFundingAsc = "TOTAL_FUNDING_ASC",
  TotalFundingDesc = "TOTAL_FUNDING_DESC",
  TotalFundingSourceAsc = "TOTAL_FUNDING_SOURCE_ASC",
  TotalFundingSourceDesc = "TOTAL_FUNDING_SOURCE_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type DealroomNews = Node & {
  __typename?: "DealroomNews";
  author?: Maybe<Scalars["JSON"]["output"]>;
  categories?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `DealroomCompany` that is related to this `DealroomNews`. */
  company?: Maybe<DealroomCompany>;
  companyId?: Maybe<Scalars["Int"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  feed?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  pubDate?: Maybe<Scalars["Datetime"]["output"]>;
  pubDateUtc?: Maybe<Scalars["Datetime"]["output"]>;
  relatedCompanies?: Maybe<Scalars["JSON"]["output"]>;
  summary?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DealroomNewsAggregates = {
  __typename?: "DealroomNewsAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomNewsAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomNewsDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomNewsMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomNewsMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomNewsStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomNewsStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomNewsSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomNewsVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomNewsVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DealroomNews` object types. */
export type DealroomNewsAggregatesFilter = {
  /** Mean average aggregate over matching `DealroomNews` objects. */
  average?: InputMaybe<DealroomNewsAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DealroomNews` objects. */
  distinctCount?: InputMaybe<DealroomNewsDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DealroomNews` object to be included within the aggregate. */
  filter?: InputMaybe<DealroomNewsFilter>;
  /** Maximum aggregate over matching `DealroomNews` objects. */
  max?: InputMaybe<DealroomNewsMaxAggregateFilter>;
  /** Minimum aggregate over matching `DealroomNews` objects. */
  min?: InputMaybe<DealroomNewsMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DealroomNews` objects. */
  stddevPopulation?: InputMaybe<DealroomNewsStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DealroomNews` objects. */
  stddevSample?: InputMaybe<DealroomNewsStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DealroomNews` objects. */
  sum?: InputMaybe<DealroomNewsSumAggregateFilter>;
  /** Population variance aggregate over matching `DealroomNews` objects. */
  variancePopulation?: InputMaybe<DealroomNewsVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DealroomNews` objects. */
  varianceSample?: InputMaybe<DealroomNewsVarianceSampleAggregateFilter>;
};

export type DealroomNewsAverageAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
};

export type DealroomNewsAverageAggregates = {
  __typename?: "DealroomNewsAverageAggregates";
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomNews` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealroomNewsCondition = {
  /** Checks for equality with the object’s `author` field. */
  author?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `categories` field. */
  categories?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `feed` field. */
  feed?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `link` field. */
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `pubDate` field. */
  pubDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `pubDateUtc` field. */
  pubDateUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `relatedCompanies` field. */
  relatedCompanies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `summary` field. */
  summary?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DealroomNews` values. */
export type DealroomNewsConnection = {
  __typename?: "DealroomNewsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomNewsAggregates>;
  /** A list of edges which contains the `DealroomNews` and cursor to aid in pagination. */
  edges: Array<DealroomNewsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomNewsAggregates>>;
  /** A list of `DealroomNews` objects. */
  nodes: Array<DealroomNews>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomNews` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomNews` values. */
export type DealroomNewsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomNewsGroupBy>;
  having?: InputMaybe<DealroomNewsHavingInput>;
};

/** A connection to a list of `DealroomNewsCopy` values. */
export type DealroomNewsCopiesConnection = {
  __typename?: "DealroomNewsCopiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DealroomNewsCopyAggregates>;
  /** A list of edges which contains the `DealroomNewsCopy` and cursor to aid in pagination. */
  edges: Array<DealroomNewsCopiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DealroomNewsCopyAggregates>>;
  /** A list of `DealroomNewsCopy` objects. */
  nodes: Array<DealroomNewsCopy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealroomNewsCopy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DealroomNewsCopy` values. */
export type DealroomNewsCopiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<DealroomNewsCopyGroupBy>;
  having?: InputMaybe<DealroomNewsCopyHavingInput>;
};

/** A `DealroomNewsCopy` edge in the connection. */
export type DealroomNewsCopiesEdge = {
  __typename?: "DealroomNewsCopiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomNewsCopy` at the end of the edge. */
  node: DealroomNewsCopy;
};

/** Methods to use when ordering `DealroomNewsCopy`. */
export enum DealroomNewsCopiesOrderBy {
  AuthorAsc = "AUTHOR_ASC",
  AuthorDesc = "AUTHOR_DESC",
  CategoriesAsc = "CATEGORIES_ASC",
  CategoriesDesc = "CATEGORIES_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  FeedAsc = "FEED_ASC",
  FeedDesc = "FEED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  LinkAsc = "LINK_ASC",
  LinkDesc = "LINK_DESC",
  Natural = "NATURAL",
  PubDateAsc = "PUB_DATE_ASC",
  PubDateDesc = "PUB_DATE_DESC",
  PubDateUtcAsc = "PUB_DATE_UTC_ASC",
  PubDateUtcDesc = "PUB_DATE_UTC_DESC",
  RelatedCompaniesAsc = "RELATED_COMPANIES_ASC",
  RelatedCompaniesDesc = "RELATED_COMPANIES_DESC",
  SummaryAsc = "SUMMARY_ASC",
  SummaryDesc = "SUMMARY_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
}

export type DealroomNewsCopy = {
  __typename?: "DealroomNewsCopy";
  author?: Maybe<Scalars["JSON"]["output"]>;
  categories?: Maybe<Scalars["JSON"]["output"]>;
  companyId?: Maybe<Scalars["Int"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  feed?: Maybe<Scalars["JSON"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  pubDate?: Maybe<Scalars["Datetime"]["output"]>;
  pubDateUtc?: Maybe<Scalars["Datetime"]["output"]>;
  relatedCompanies?: Maybe<Scalars["JSON"]["output"]>;
  summary?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DealroomNewsCopyAggregates = {
  __typename?: "DealroomNewsCopyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DealroomNewsCopyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DealroomNewsCopyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DealroomNewsCopyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DealroomNewsCopyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DealroomNewsCopyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DealroomNewsCopyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DealroomNewsCopySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DealroomNewsCopyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DealroomNewsCopyVarianceSampleAggregates>;
};

export type DealroomNewsCopyAverageAggregates = {
  __typename?: "DealroomNewsCopyAverageAggregates";
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DealroomNewsCopy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealroomNewsCopyCondition = {
  /** Checks for equality with the object’s `author` field. */
  author?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `categories` field. */
  categories?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `feed` field. */
  feed?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `link` field. */
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `pubDate` field. */
  pubDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `pubDateUtc` field. */
  pubDateUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `relatedCompanies` field. */
  relatedCompanies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `summary` field. */
  summary?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type DealroomNewsCopyDistinctCountAggregates = {
  __typename?: "DealroomNewsCopyDistinctCountAggregates";
  /** Distinct count of author across the matching connection */
  author?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of categories across the matching connection */
  categories?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of feed across the matching connection */
  feed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of link across the matching connection */
  link?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pubDate across the matching connection */
  pubDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pubDateUtc across the matching connection */
  pubDateUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of relatedCompanies across the matching connection */
  relatedCompanies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of summary across the matching connection */
  summary?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DealroomNewsCopy` object types. All fields are combined with a logical ‘and.’ */
export type DealroomNewsCopyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomNewsCopyFilter>>;
  /** Filter by the object’s `author` field. */
  author?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `categories` field. */
  categories?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feed` field. */
  feed?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `link` field. */
  link?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomNewsCopyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomNewsCopyFilter>>;
  /** Filter by the object’s `pubDate` field. */
  pubDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `pubDateUtc` field. */
  pubDateUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `relatedCompanies` field. */
  relatedCompanies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `summary` field. */
  summary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DealroomNewsCopy` for usage during aggregation. */
export enum DealroomNewsCopyGroupBy {
  Author = "AUTHOR",
  Categories = "CATEGORIES",
  CompanyId = "COMPANY_ID",
  Content = "CONTENT",
  Feed = "FEED",
  Id = "ID",
  Image = "IMAGE",
  Link = "LINK",
  PubDate = "PUB_DATE",
  PubDateTruncatedToDay = "PUB_DATE_TRUNCATED_TO_DAY",
  PubDateTruncatedToHour = "PUB_DATE_TRUNCATED_TO_HOUR",
  PubDateUtc = "PUB_DATE_UTC",
  PubDateUtcTruncatedToDay = "PUB_DATE_UTC_TRUNCATED_TO_DAY",
  PubDateUtcTruncatedToHour = "PUB_DATE_UTC_TRUNCATED_TO_HOUR",
  RelatedCompanies = "RELATED_COMPANIES",
  Summary = "SUMMARY",
  Title = "TITLE",
}

export type DealroomNewsCopyHavingAverageInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingDistinctCountInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DealroomNewsCopy` aggregates. */
export type DealroomNewsCopyHavingInput = {
  AND?: InputMaybe<Array<DealroomNewsCopyHavingInput>>;
  OR?: InputMaybe<Array<DealroomNewsCopyHavingInput>>;
  average?: InputMaybe<DealroomNewsCopyHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomNewsCopyHavingDistinctCountInput>;
  max?: InputMaybe<DealroomNewsCopyHavingMaxInput>;
  min?: InputMaybe<DealroomNewsCopyHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomNewsCopyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomNewsCopyHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomNewsCopyHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomNewsCopyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomNewsCopyHavingVarianceSampleInput>;
};

export type DealroomNewsCopyHavingMaxInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingMinInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingStddevPopulationInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingStddevSampleInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingSumInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingVariancePopulationInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyHavingVarianceSampleInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsCopyMaxAggregates = {
  __typename?: "DealroomNewsCopyMaxAggregates";
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomNewsCopyMinAggregates = {
  __typename?: "DealroomNewsCopyMinAggregates";
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomNewsCopyStddevPopulationAggregates = {
  __typename?: "DealroomNewsCopyStddevPopulationAggregates";
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsCopyStddevSampleAggregates = {
  __typename?: "DealroomNewsCopyStddevSampleAggregates";
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsCopySumAggregates = {
  __typename?: "DealroomNewsCopySumAggregates";
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigInt"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type DealroomNewsCopyVariancePopulationAggregates = {
  __typename?: "DealroomNewsCopyVariancePopulationAggregates";
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsCopyVarianceSampleAggregates = {
  __typename?: "DealroomNewsCopyVarianceSampleAggregates";
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsDistinctCountAggregateFilter = {
  author?: InputMaybe<BigIntFilter>;
  categories?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  content?: InputMaybe<BigIntFilter>;
  feed?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<BigIntFilter>;
  link?: InputMaybe<BigIntFilter>;
  pubDate?: InputMaybe<BigIntFilter>;
  pubDateUtc?: InputMaybe<BigIntFilter>;
  relatedCompanies?: InputMaybe<BigIntFilter>;
  summary?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
};

export type DealroomNewsDistinctCountAggregates = {
  __typename?: "DealroomNewsDistinctCountAggregates";
  /** Distinct count of author across the matching connection */
  author?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of categories across the matching connection */
  categories?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of feed across the matching connection */
  feed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of link across the matching connection */
  link?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pubDate across the matching connection */
  pubDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pubDateUtc across the matching connection */
  pubDateUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of relatedCompanies across the matching connection */
  relatedCompanies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of summary across the matching connection */
  summary?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A `DealroomNews` edge in the connection. */
export type DealroomNewsEdge = {
  __typename?: "DealroomNewsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomNews` at the end of the edge. */
  node: DealroomNews;
};

/** A filter to be used against `DealroomNews` object types. All fields are combined with a logical ‘and.’ */
export type DealroomNewsFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DealroomNewsFilter>>;
  /** Filter by the object’s `author` field. */
  author?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `categories` field. */
  categories?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<DealroomCompanyFilter>;
  /** A related `company` exists. */
  companyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feed` field. */
  feed?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `link` field. */
  link?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DealroomNewsFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DealroomNewsFilter>>;
  /** Filter by the object’s `pubDate` field. */
  pubDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `pubDateUtc` field. */
  pubDateUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `relatedCompanies` field. */
  relatedCompanies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `summary` field. */
  summary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DealroomNews` for usage during aggregation. */
export enum DealroomNewsGroupBy {
  Author = "AUTHOR",
  Categories = "CATEGORIES",
  CompanyId = "COMPANY_ID",
  Content = "CONTENT",
  Feed = "FEED",
  Image = "IMAGE",
  Link = "LINK",
  PubDate = "PUB_DATE",
  PubDateTruncatedToDay = "PUB_DATE_TRUNCATED_TO_DAY",
  PubDateTruncatedToHour = "PUB_DATE_TRUNCATED_TO_HOUR",
  PubDateUtc = "PUB_DATE_UTC",
  PubDateUtcTruncatedToDay = "PUB_DATE_UTC_TRUNCATED_TO_DAY",
  PubDateUtcTruncatedToHour = "PUB_DATE_UTC_TRUNCATED_TO_HOUR",
  RelatedCompanies = "RELATED_COMPANIES",
  Summary = "SUMMARY",
  Title = "TITLE",
}

export type DealroomNewsHavingAverageInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingDistinctCountInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DealroomNews` aggregates. */
export type DealroomNewsHavingInput = {
  AND?: InputMaybe<Array<DealroomNewsHavingInput>>;
  OR?: InputMaybe<Array<DealroomNewsHavingInput>>;
  average?: InputMaybe<DealroomNewsHavingAverageInput>;
  distinctCount?: InputMaybe<DealroomNewsHavingDistinctCountInput>;
  max?: InputMaybe<DealroomNewsHavingMaxInput>;
  min?: InputMaybe<DealroomNewsHavingMinInput>;
  stddevPopulation?: InputMaybe<DealroomNewsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DealroomNewsHavingStddevSampleInput>;
  sum?: InputMaybe<DealroomNewsHavingSumInput>;
  variancePopulation?: InputMaybe<DealroomNewsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DealroomNewsHavingVarianceSampleInput>;
};

export type DealroomNewsHavingMaxInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingMinInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingStddevPopulationInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingStddevSampleInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingSumInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingVariancePopulationInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsHavingVarianceSampleInput = {
  companyId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  pubDate?: InputMaybe<HavingDatetimeFilter>;
  pubDateUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type DealroomNewsMaxAggregateFilter = {
  companyId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type DealroomNewsMaxAggregates = {
  __typename?: "DealroomNewsMaxAggregates";
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type DealroomNewsMinAggregateFilter = {
  companyId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type DealroomNewsMinAggregates = {
  __typename?: "DealroomNewsMinAggregates";
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** Methods to use when ordering `DealroomNews`. */
export enum DealroomNewsOrderBy {
  AuthorAsc = "AUTHOR_ASC",
  AuthorDesc = "AUTHOR_DESC",
  CategoriesAsc = "CATEGORIES_ASC",
  CategoriesDesc = "CATEGORIES_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  FeedAsc = "FEED_ASC",
  FeedDesc = "FEED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  LinkAsc = "LINK_ASC",
  LinkDesc = "LINK_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PubDateAsc = "PUB_DATE_ASC",
  PubDateDesc = "PUB_DATE_DESC",
  PubDateUtcAsc = "PUB_DATE_UTC_ASC",
  PubDateUtcDesc = "PUB_DATE_UTC_DESC",
  RelatedCompaniesAsc = "RELATED_COMPANIES_ASC",
  RelatedCompaniesDesc = "RELATED_COMPANIES_DESC",
  SummaryAsc = "SUMMARY_ASC",
  SummaryDesc = "SUMMARY_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
}

export type DealroomNewsStddevPopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
};

export type DealroomNewsStddevPopulationAggregates = {
  __typename?: "DealroomNewsStddevPopulationAggregates";
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsStddevSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
};

export type DealroomNewsStddevSampleAggregates = {
  __typename?: "DealroomNewsStddevSampleAggregates";
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsSumAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
};

export type DealroomNewsSumAggregates = {
  __typename?: "DealroomNewsSumAggregates";
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigInt"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type DealroomNewsVariancePopulationAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
};

export type DealroomNewsVariancePopulationAggregates = {
  __typename?: "DealroomNewsVariancePopulationAggregates";
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DealroomNewsVarianceSampleAggregateFilter = {
  companyId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
};

export type DealroomNewsVarianceSampleAggregates = {
  __typename?: "DealroomNewsVarianceSampleAggregates";
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DeclineSentimentInput = {
  formId: Scalars["String"]["input"];
};

export enum EmailOpportunityAction {
  Accept = "ACCEPT",
  Decline = "DECLINE",
}

export type EmailOpportunityInput = {
  /** An enumeration. */
  action: EmailOpportunityAction;
  companyId?: InputMaybe<Scalars["String"]["input"]>;
  emailOpportunityId: Scalars["String"]["input"];
  opportunities?: InputMaybe<Array<InputMaybe<ManualOpportunityInput>>>;
};

export enum Entity {
  Company = "COMPANY",
  Opportunity = "OPPORTUNITY",
  Signal = "SIGNAL",
}

export type FillSentimentInput = {
  answers: Scalars["JSON"]["input"];
  formId: Scalars["String"]["input"];
};

export type FlagCompanyInput = {
  companyId: Scalars["String"]["input"];
  flag: Scalars["String"]["input"];
  toggle: Scalars["Boolean"]["input"];
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

/** A filter to be used against FullText fields. All fields are combined with a logical ‘and.’ */
export type FullTextFilter = {
  /** Performs a full text search on the field. */
  matches?: InputMaybe<Scalars["String"]["input"]>;
};

export type GeneralSettingsInput = {
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleAuthCodeInput = {
  code: Scalars["String"]["input"];
};

/** A connection to a list of `Gpt4Category` values. */
export type Gpt4CategoriesConnection = {
  __typename?: "Gpt4CategoriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt4CategoryAggregates>;
  /** A list of edges which contains the `Gpt4Category` and cursor to aid in pagination. */
  edges: Array<Gpt4CategoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt4CategoryAggregates>>;
  /** A list of `Gpt4Category` objects. */
  nodes: Array<Gpt4Category>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt4Category` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt4Category` values. */
export type Gpt4CategoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt4CategoriesGroupBy>;
  having?: InputMaybe<Gpt4CategoriesHavingInput>;
};

/** A `Gpt4Category` edge in the connection. */
export type Gpt4CategoriesEdge = {
  __typename?: "Gpt4CategoriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt4Category` at the end of the edge. */
  node: Gpt4Category;
};

/** Grouping methods for `Gpt4Category` for usage during aggregation. */
export enum Gpt4CategoriesGroupBy {
  Data = "DATA",
}

/** Conditions for `Gpt4Category` aggregates. */
export type Gpt4CategoriesHavingInput = {
  AND?: InputMaybe<Array<Gpt4CategoriesHavingInput>>;
  OR?: InputMaybe<Array<Gpt4CategoriesHavingInput>>;
};

/** Methods to use when ordering `Gpt4Category`. */
export enum Gpt4CategoriesOrderBy {
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  Natural = "NATURAL",
}

export type Gpt4CategoriesTmp = {
  __typename?: "Gpt4CategoriesTmp";
  categories?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type Gpt4CategoriesTmp1 = {
  __typename?: "Gpt4CategoriesTmp1";
  category?: Maybe<Scalars["JSON"]["output"]>;
  categoryId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type Gpt4CategoriesTmp1Aggregates = {
  __typename?: "Gpt4CategoriesTmp1Aggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<Gpt4CategoriesTmp1AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt4CategoriesTmp1DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<Gpt4CategoriesTmp1MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<Gpt4CategoriesTmp1MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<Gpt4CategoriesTmp1StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<Gpt4CategoriesTmp1StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<Gpt4CategoriesTmp1SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<Gpt4CategoriesTmp1VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<Gpt4CategoriesTmp1VarianceSampleAggregates>;
};

export type Gpt4CategoriesTmp1AverageAggregates = {
  __typename?: "Gpt4CategoriesTmp1AverageAggregates";
  /** Mean average of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `Gpt4CategoriesTmp1` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Gpt4CategoriesTmp1Condition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `categoryId` field. */
  categoryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt4CategoriesTmp1DistinctCountAggregates = {
  __typename?: "Gpt4CategoriesTmp1DistinctCountAggregates";
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt4CategoriesTmp1` object types. All fields are combined with a logical ‘and.’ */
export type Gpt4CategoriesTmp1Filter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt4CategoriesTmp1Filter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `categoryId` field. */
  categoryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt4CategoriesTmp1Filter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt4CategoriesTmp1Filter>>;
};

/** Grouping methods for `Gpt4CategoriesTmp1` for usage during aggregation. */
export enum Gpt4CategoriesTmp1GroupBy {
  Category = "CATEGORY",
  CategoryId = "CATEGORY_ID",
  Id = "ID",
}

export type Gpt4CategoriesTmp1HavingAverageInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingDistinctCountInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Gpt4CategoriesTmp1` aggregates. */
export type Gpt4CategoriesTmp1HavingInput = {
  AND?: InputMaybe<Array<Gpt4CategoriesTmp1HavingInput>>;
  OR?: InputMaybe<Array<Gpt4CategoriesTmp1HavingInput>>;
  average?: InputMaybe<Gpt4CategoriesTmp1HavingAverageInput>;
  distinctCount?: InputMaybe<Gpt4CategoriesTmp1HavingDistinctCountInput>;
  max?: InputMaybe<Gpt4CategoriesTmp1HavingMaxInput>;
  min?: InputMaybe<Gpt4CategoriesTmp1HavingMinInput>;
  stddevPopulation?: InputMaybe<Gpt4CategoriesTmp1HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<Gpt4CategoriesTmp1HavingStddevSampleInput>;
  sum?: InputMaybe<Gpt4CategoriesTmp1HavingSumInput>;
  variancePopulation?: InputMaybe<Gpt4CategoriesTmp1HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<Gpt4CategoriesTmp1HavingVarianceSampleInput>;
};

export type Gpt4CategoriesTmp1HavingMaxInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingMinInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingStddevPopulationInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingStddevSampleInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingSumInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingVariancePopulationInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1HavingVarianceSampleInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4CategoriesTmp1MaxAggregates = {
  __typename?: "Gpt4CategoriesTmp1MaxAggregates";
  /** Maximum of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt4CategoriesTmp1MinAggregates = {
  __typename?: "Gpt4CategoriesTmp1MinAggregates";
  /** Minimum of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt4CategoriesTmp1StddevPopulationAggregates = {
  __typename?: "Gpt4CategoriesTmp1StddevPopulationAggregates";
  /** Population standard deviation of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4CategoriesTmp1StddevSampleAggregates = {
  __typename?: "Gpt4CategoriesTmp1StddevSampleAggregates";
  /** Sample standard deviation of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4CategoriesTmp1SumAggregates = {
  __typename?: "Gpt4CategoriesTmp1SumAggregates";
  /** Sum of categoryId across the matching connection */
  categoryId: Scalars["BigInt"]["output"];
};

export type Gpt4CategoriesTmp1VariancePopulationAggregates = {
  __typename?: "Gpt4CategoriesTmp1VariancePopulationAggregates";
  /** Population variance of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4CategoriesTmp1VarianceSampleAggregates = {
  __typename?: "Gpt4CategoriesTmp1VarianceSampleAggregates";
  /** Sample variance of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `Gpt4CategoriesTmp1` values. */
export type Gpt4CategoriesTmp1sConnection = {
  __typename?: "Gpt4CategoriesTmp1sConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt4CategoriesTmp1Aggregates>;
  /** A list of edges which contains the `Gpt4CategoriesTmp1` and cursor to aid in pagination. */
  edges: Array<Gpt4CategoriesTmp1sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt4CategoriesTmp1Aggregates>>;
  /** A list of `Gpt4CategoriesTmp1` objects. */
  nodes: Array<Gpt4CategoriesTmp1>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt4CategoriesTmp1` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt4CategoriesTmp1` values. */
export type Gpt4CategoriesTmp1sConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt4CategoriesTmp1GroupBy>;
  having?: InputMaybe<Gpt4CategoriesTmp1HavingInput>;
};

/** A `Gpt4CategoriesTmp1` edge in the connection. */
export type Gpt4CategoriesTmp1sEdge = {
  __typename?: "Gpt4CategoriesTmp1sEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt4CategoriesTmp1` at the end of the edge. */
  node: Gpt4CategoriesTmp1;
};

/** Methods to use when ordering `Gpt4CategoriesTmp1`. */
export enum Gpt4CategoriesTmp1sOrderBy {
  CategoryAsc = "CATEGORY_ASC",
  CategoryDesc = "CATEGORY_DESC",
  CategoryIdAsc = "CATEGORY_ID_ASC",
  CategoryIdDesc = "CATEGORY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
}

export type Gpt4CategoriesTmpAggregates = {
  __typename?: "Gpt4CategoriesTmpAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt4CategoriesTmpDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `Gpt4CategoriesTmp` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Gpt4CategoriesTmpCondition = {
  /** Checks for equality with the object’s `categories` field. */
  categories?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt4CategoriesTmpDistinctCountAggregates = {
  __typename?: "Gpt4CategoriesTmpDistinctCountAggregates";
  /** Distinct count of categories across the matching connection */
  categories?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt4CategoriesTmp` object types. All fields are combined with a logical ‘and.’ */
export type Gpt4CategoriesTmpFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt4CategoriesTmpFilter>>;
  /** Filter by the object’s `categories` field. */
  categories?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt4CategoriesTmpFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt4CategoriesTmpFilter>>;
};

/** Grouping methods for `Gpt4CategoriesTmp` for usage during aggregation. */
export enum Gpt4CategoriesTmpGroupBy {
  Categories = "CATEGORIES",
  Id = "ID",
}

/** Conditions for `Gpt4CategoriesTmp` aggregates. */
export type Gpt4CategoriesTmpHavingInput = {
  AND?: InputMaybe<Array<Gpt4CategoriesTmpHavingInput>>;
  OR?: InputMaybe<Array<Gpt4CategoriesTmpHavingInput>>;
};

/** A connection to a list of `Gpt4CategoriesTmp` values. */
export type Gpt4CategoriesTmpsConnection = {
  __typename?: "Gpt4CategoriesTmpsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt4CategoriesTmpAggregates>;
  /** A list of edges which contains the `Gpt4CategoriesTmp` and cursor to aid in pagination. */
  edges: Array<Gpt4CategoriesTmpsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt4CategoriesTmpAggregates>>;
  /** A list of `Gpt4CategoriesTmp` objects. */
  nodes: Array<Gpt4CategoriesTmp>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt4CategoriesTmp` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt4CategoriesTmp` values. */
export type Gpt4CategoriesTmpsConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt4CategoriesTmpGroupBy>;
  having?: InputMaybe<Gpt4CategoriesTmpHavingInput>;
};

/** A `Gpt4CategoriesTmp` edge in the connection. */
export type Gpt4CategoriesTmpsEdge = {
  __typename?: "Gpt4CategoriesTmpsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt4CategoriesTmp` at the end of the edge. */
  node: Gpt4CategoriesTmp;
};

/** Methods to use when ordering `Gpt4CategoriesTmp`. */
export enum Gpt4CategoriesTmpsOrderBy {
  CategoriesAsc = "CATEGORIES_ASC",
  CategoriesDesc = "CATEGORIES_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
}

export type Gpt4Category = {
  __typename?: "Gpt4Category";
  data?: Maybe<Scalars["String"]["output"]>;
};

export type Gpt4CategoryAggregates = {
  __typename?: "Gpt4CategoryAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt4CategoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `Gpt4Category` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Gpt4CategoryCondition = {
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt4CategoryDistinctCountAggregates = {
  __typename?: "Gpt4CategoryDistinctCountAggregates";
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt4Category` object types. All fields are combined with a logical ‘and.’ */
export type Gpt4CategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt4CategoryFilter>>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt4CategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt4CategoryFilter>>;
};

export type Gpt4Etl1 = {
  __typename?: "Gpt4Etl1";
  catId?: Maybe<Scalars["Int"]["output"]>;
  trelloId?: Maybe<Scalars["String"]["output"]>;
};

export type Gpt4Etl1Aggregates = {
  __typename?: "Gpt4Etl1Aggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<Gpt4Etl1AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt4Etl1DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<Gpt4Etl1MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<Gpt4Etl1MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<Gpt4Etl1StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<Gpt4Etl1StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<Gpt4Etl1SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<Gpt4Etl1VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<Gpt4Etl1VarianceSampleAggregates>;
};

export type Gpt4Etl1AverageAggregates = {
  __typename?: "Gpt4Etl1AverageAggregates";
  /** Mean average of catId across the matching connection */
  catId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `Gpt4Etl1` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type Gpt4Etl1Condition = {
  /** Checks for equality with the object’s `catId` field. */
  catId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt4Etl1DistinctCountAggregates = {
  __typename?: "Gpt4Etl1DistinctCountAggregates";
  /** Distinct count of catId across the matching connection */
  catId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt4Etl1` object types. All fields are combined with a logical ‘and.’ */
export type Gpt4Etl1Filter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt4Etl1Filter>>;
  /** Filter by the object’s `catId` field. */
  catId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt4Etl1Filter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt4Etl1Filter>>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Gpt4Etl1` for usage during aggregation. */
export enum Gpt4Etl1GroupBy {
  CatId = "CAT_ID",
  TrelloId = "TRELLO_ID",
}

export type Gpt4Etl1HavingAverageInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingDistinctCountInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Gpt4Etl1` aggregates. */
export type Gpt4Etl1HavingInput = {
  AND?: InputMaybe<Array<Gpt4Etl1HavingInput>>;
  OR?: InputMaybe<Array<Gpt4Etl1HavingInput>>;
  average?: InputMaybe<Gpt4Etl1HavingAverageInput>;
  distinctCount?: InputMaybe<Gpt4Etl1HavingDistinctCountInput>;
  max?: InputMaybe<Gpt4Etl1HavingMaxInput>;
  min?: InputMaybe<Gpt4Etl1HavingMinInput>;
  stddevPopulation?: InputMaybe<Gpt4Etl1HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<Gpt4Etl1HavingStddevSampleInput>;
  sum?: InputMaybe<Gpt4Etl1HavingSumInput>;
  variancePopulation?: InputMaybe<Gpt4Etl1HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<Gpt4Etl1HavingVarianceSampleInput>;
};

export type Gpt4Etl1HavingMaxInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingMinInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingStddevPopulationInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingStddevSampleInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingSumInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingVariancePopulationInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1HavingVarianceSampleInput = {
  catId?: InputMaybe<HavingIntFilter>;
};

export type Gpt4Etl1MaxAggregates = {
  __typename?: "Gpt4Etl1MaxAggregates";
  /** Maximum of catId across the matching connection */
  catId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt4Etl1MinAggregates = {
  __typename?: "Gpt4Etl1MinAggregates";
  /** Minimum of catId across the matching connection */
  catId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt4Etl1StddevPopulationAggregates = {
  __typename?: "Gpt4Etl1StddevPopulationAggregates";
  /** Population standard deviation of catId across the matching connection */
  catId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4Etl1StddevSampleAggregates = {
  __typename?: "Gpt4Etl1StddevSampleAggregates";
  /** Sample standard deviation of catId across the matching connection */
  catId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4Etl1SumAggregates = {
  __typename?: "Gpt4Etl1SumAggregates";
  /** Sum of catId across the matching connection */
  catId: Scalars["BigInt"]["output"];
};

export type Gpt4Etl1VariancePopulationAggregates = {
  __typename?: "Gpt4Etl1VariancePopulationAggregates";
  /** Population variance of catId across the matching connection */
  catId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt4Etl1VarianceSampleAggregates = {
  __typename?: "Gpt4Etl1VarianceSampleAggregates";
  /** Sample variance of catId across the matching connection */
  catId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `Gpt4Etl1` values. */
export type Gpt4Etl1sConnection = {
  __typename?: "Gpt4Etl1sConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt4Etl1Aggregates>;
  /** A list of edges which contains the `Gpt4Etl1` and cursor to aid in pagination. */
  edges: Array<Gpt4Etl1sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt4Etl1Aggregates>>;
  /** A list of `Gpt4Etl1` objects. */
  nodes: Array<Gpt4Etl1>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt4Etl1` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt4Etl1` values. */
export type Gpt4Etl1sConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt4Etl1GroupBy>;
  having?: InputMaybe<Gpt4Etl1HavingInput>;
};

/** A `Gpt4Etl1` edge in the connection. */
export type Gpt4Etl1sEdge = {
  __typename?: "Gpt4Etl1sEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt4Etl1` at the end of the edge. */
  node: Gpt4Etl1;
};

/** Methods to use when ordering `Gpt4Etl1`. */
export enum Gpt4Etl1sOrderBy {
  CatIdAsc = "CAT_ID_ASC",
  CatIdDesc = "CAT_ID_DESC",
  Natural = "NATURAL",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
}

export type Gpt41CatFinal = {
  __typename?: "Gpt41CatFinal";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type Gpt41CatFinalAggregates = {
  __typename?: "Gpt41CatFinalAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt41CatFinalDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `Gpt41CatFinal` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Gpt41CatFinalCondition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt41CatFinalDistinctCountAggregates = {
  __typename?: "Gpt41CatFinalDistinctCountAggregates";
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt41CatFinal` object types. All fields are combined with a logical ‘and.’ */
export type Gpt41CatFinalFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt41CatFinalFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt41CatFinalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt41CatFinalFilter>>;
};

/** Grouping methods for `Gpt41CatFinal` for usage during aggregation. */
export enum Gpt41CatFinalGroupBy {
  Category = "CATEGORY",
  Id = "ID",
}

/** Conditions for `Gpt41CatFinal` aggregates. */
export type Gpt41CatFinalHavingInput = {
  AND?: InputMaybe<Array<Gpt41CatFinalHavingInput>>;
  OR?: InputMaybe<Array<Gpt41CatFinalHavingInput>>;
};

/** A connection to a list of `Gpt41CatFinal` values. */
export type Gpt41CatFinalsConnection = {
  __typename?: "Gpt41CatFinalsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt41CatFinalAggregates>;
  /** A list of edges which contains the `Gpt41CatFinal` and cursor to aid in pagination. */
  edges: Array<Gpt41CatFinalsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt41CatFinalAggregates>>;
  /** A list of `Gpt41CatFinal` objects. */
  nodes: Array<Gpt41CatFinal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt41CatFinal` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt41CatFinal` values. */
export type Gpt41CatFinalsConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt41CatFinalGroupBy>;
  having?: InputMaybe<Gpt41CatFinalHavingInput>;
};

/** A `Gpt41CatFinal` edge in the connection. */
export type Gpt41CatFinalsEdge = {
  __typename?: "Gpt41CatFinalsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt41CatFinal` at the end of the edge. */
  node: Gpt41CatFinal;
};

/** Methods to use when ordering `Gpt41CatFinal`. */
export enum Gpt41CatFinalsOrderBy {
  CategoryAsc = "CATEGORY_ASC",
  CategoryDesc = "CATEGORY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
}

/** A connection to a list of `Gpt41Category` values. */
export type Gpt41CategoriesConnection = {
  __typename?: "Gpt41CategoriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<Gpt41CategoryAggregates>;
  /** A list of edges which contains the `Gpt41Category` and cursor to aid in pagination. */
  edges: Array<Gpt41CategoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<Gpt41CategoryAggregates>>;
  /** A list of `Gpt41Category` objects. */
  nodes: Array<Gpt41Category>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gpt41Category` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Gpt41Category` values. */
export type Gpt41CategoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<Gpt41CategoryGroupBy>;
  having?: InputMaybe<Gpt41CategoryHavingInput>;
};

/** A `Gpt41Category` edge in the connection. */
export type Gpt41CategoriesEdge = {
  __typename?: "Gpt41CategoriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Gpt41Category` at the end of the edge. */
  node: Gpt41Category;
};

/** Methods to use when ordering `Gpt41Category`. */
export enum Gpt41CategoriesOrderBy {
  CategoryIdAsc = "CATEGORY_ID_ASC",
  CategoryIdDesc = "CATEGORY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NewCategoryAsc = "NEW_CATEGORY_ASC",
  NewCategoryDesc = "NEW_CATEGORY_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Gpt41Category = Node & {
  __typename?: "Gpt41Category";
  /** Reads a single `LgCategory` that is related to this `Gpt41Category`. */
  category?: Maybe<LgCategory>;
  categoryId: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  newCategory?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type Gpt41CategoryAggregates = {
  __typename?: "Gpt41CategoryAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<Gpt41CategoryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<Gpt41CategoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<Gpt41CategoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<Gpt41CategoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<Gpt41CategoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<Gpt41CategoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<Gpt41CategorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<Gpt41CategoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<Gpt41CategoryVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Gpt41Category` object types. */
export type Gpt41CategoryAggregatesFilter = {
  /** Mean average aggregate over matching `Gpt41Category` objects. */
  average?: InputMaybe<Gpt41CategoryAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Gpt41Category` objects. */
  distinctCount?: InputMaybe<Gpt41CategoryDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Gpt41Category` object to be included within the aggregate. */
  filter?: InputMaybe<Gpt41CategoryFilter>;
  /** Maximum aggregate over matching `Gpt41Category` objects. */
  max?: InputMaybe<Gpt41CategoryMaxAggregateFilter>;
  /** Minimum aggregate over matching `Gpt41Category` objects. */
  min?: InputMaybe<Gpt41CategoryMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Gpt41Category` objects. */
  stddevPopulation?: InputMaybe<Gpt41CategoryStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Gpt41Category` objects. */
  stddevSample?: InputMaybe<Gpt41CategoryStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Gpt41Category` objects. */
  sum?: InputMaybe<Gpt41CategorySumAggregateFilter>;
  /** Population variance aggregate over matching `Gpt41Category` objects. */
  variancePopulation?: InputMaybe<Gpt41CategoryVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Gpt41Category` objects. */
  varianceSample?: InputMaybe<Gpt41CategoryVarianceSampleAggregateFilter>;
};

export type Gpt41CategoryAverageAggregateFilter = {
  categoryId?: InputMaybe<BigFloatFilter>;
};

export type Gpt41CategoryAverageAggregates = {
  __typename?: "Gpt41CategoryAverageAggregates";
  /** Mean average of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `Gpt41Category` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Gpt41CategoryCondition = {
  /** Checks for equality with the object’s `categoryId` field. */
  categoryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `newCategory` field. */
  newCategory?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gpt41CategoryDistinctCountAggregateFilter = {
  categoryId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  newCategory?: InputMaybe<BigIntFilter>;
};

export type Gpt41CategoryDistinctCountAggregates = {
  __typename?: "Gpt41CategoryDistinctCountAggregates";
  /** Distinct count of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of newCategory across the matching connection */
  newCategory?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Gpt41Category` object types. All fields are combined with a logical ‘and.’ */
export type Gpt41CategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Gpt41CategoryFilter>>;
  /** Filter by the object’s `category` relation. */
  category?: InputMaybe<LgCategoryFilter>;
  /** Filter by the object’s `categoryId` field. */
  categoryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `newCategory` field. */
  newCategory?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Gpt41CategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Gpt41CategoryFilter>>;
};

/** Grouping methods for `Gpt41Category` for usage during aggregation. */
export enum Gpt41CategoryGroupBy {
  CategoryId = "CATEGORY_ID",
  Name = "NAME",
  NewCategory = "NEW_CATEGORY",
}

export type Gpt41CategoryHavingAverageInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingDistinctCountInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Gpt41Category` aggregates. */
export type Gpt41CategoryHavingInput = {
  AND?: InputMaybe<Array<Gpt41CategoryHavingInput>>;
  OR?: InputMaybe<Array<Gpt41CategoryHavingInput>>;
  average?: InputMaybe<Gpt41CategoryHavingAverageInput>;
  distinctCount?: InputMaybe<Gpt41CategoryHavingDistinctCountInput>;
  max?: InputMaybe<Gpt41CategoryHavingMaxInput>;
  min?: InputMaybe<Gpt41CategoryHavingMinInput>;
  stddevPopulation?: InputMaybe<Gpt41CategoryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<Gpt41CategoryHavingStddevSampleInput>;
  sum?: InputMaybe<Gpt41CategoryHavingSumInput>;
  variancePopulation?: InputMaybe<Gpt41CategoryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<Gpt41CategoryHavingVarianceSampleInput>;
};

export type Gpt41CategoryHavingMaxInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingMinInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingStddevPopulationInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingStddevSampleInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingSumInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingVariancePopulationInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryHavingVarianceSampleInput = {
  categoryId?: InputMaybe<HavingIntFilter>;
};

export type Gpt41CategoryMaxAggregateFilter = {
  categoryId?: InputMaybe<IntFilter>;
};

export type Gpt41CategoryMaxAggregates = {
  __typename?: "Gpt41CategoryMaxAggregates";
  /** Maximum of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt41CategoryMinAggregateFilter = {
  categoryId?: InputMaybe<IntFilter>;
};

export type Gpt41CategoryMinAggregates = {
  __typename?: "Gpt41CategoryMinAggregates";
  /** Minimum of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type Gpt41CategoryStddevPopulationAggregateFilter = {
  categoryId?: InputMaybe<BigFloatFilter>;
};

export type Gpt41CategoryStddevPopulationAggregates = {
  __typename?: "Gpt41CategoryStddevPopulationAggregates";
  /** Population standard deviation of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt41CategoryStddevSampleAggregateFilter = {
  categoryId?: InputMaybe<BigFloatFilter>;
};

export type Gpt41CategoryStddevSampleAggregates = {
  __typename?: "Gpt41CategoryStddevSampleAggregates";
  /** Sample standard deviation of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt41CategorySumAggregateFilter = {
  categoryId?: InputMaybe<BigIntFilter>;
};

export type Gpt41CategorySumAggregates = {
  __typename?: "Gpt41CategorySumAggregates";
  /** Sum of categoryId across the matching connection */
  categoryId: Scalars["BigInt"]["output"];
};

export type Gpt41CategoryVariancePopulationAggregateFilter = {
  categoryId?: InputMaybe<BigFloatFilter>;
};

export type Gpt41CategoryVariancePopulationAggregates = {
  __typename?: "Gpt41CategoryVariancePopulationAggregates";
  /** Population variance of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type Gpt41CategoryVarianceSampleAggregateFilter = {
  categoryId?: InputMaybe<BigFloatFilter>;
};

export type Gpt41CategoryVarianceSampleAggregates = {
  __typename?: "Gpt41CategoryVarianceSampleAggregates";
  /** Sample variance of categoryId across the matching connection */
  categoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type HavingBigfloatFilter = {
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Health = {
  __typename?: "Health";
  status: Scalars["String"]["output"];
  version: Scalars["String"]["output"];
};

/** All input for the `importAllLinkedEntities` mutation. */
export type ImportAllLinkedEntitiesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  entIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
};

/** The output of our `importAllLinkedEntities` mutation. */
export type ImportAllLinkedEntitiesPayload = {
  __typename?: "ImportAllLinkedEntitiesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  json?: Maybe<Scalars["JSON"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Indicates whether deleted items should be included in the results or not. */
export enum IncludeDeletedOption {
  /** Only include deleted items (i.e. exclude non-deleted items). */
  Exclusively = "EXCLUSIVELY",
  /** If there is a parent GraphQL record and it is deleted then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = "INHERIT",
  /** Exclude deleted items. */
  No = "NO",
  /** Include deleted items. */
  Yes = "YES",
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: "Interval";
  /** A quantity of days. */
  days?: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars["Int"]["output"]>;
  /** A quantity of months. */
  months?: Maybe<Scalars["Int"]["output"]>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars["Float"]["output"]>;
  /** A quantity of years. */
  years?: Maybe<Scalars["Int"]["output"]>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars["Int"]["input"]>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars["Float"]["input"]>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars["Int"]["input"]>;
};

/** All input for the `isFuturecorn` mutation. */
export type IsFuturecornInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  dealroom?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The output of our `isFuturecorn` mutation. */
export type IsFuturecornPayload = {
  __typename?: "IsFuturecornPayload";
  boolean?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

/** A connection to a list of `LgCategory` values. */
export type LgCategoriesConnection = {
  __typename?: "LgCategoriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCategoryAggregates>;
  /** A list of edges which contains the `LgCategory` and cursor to aid in pagination. */
  edges: Array<LgCategoriesEdge>;
  /** A list of `LgCategory` objects. */
  nodes: Array<LgCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCategory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `LgCategory` edge in the connection. */
export type LgCategoriesEdge = {
  __typename?: "LgCategoriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCategory` at the end of the edge. */
  node: LgCategory;
};

/** Methods to use when ordering `LgCategory`. */
export enum LgCategoriesOrderBy {
  Gpt41CategoriesByCategoryIdAverageCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdAverageCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdAverageIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_ID_ASC",
  Gpt41CategoriesByCategoryIdAverageIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_ID_DESC",
  Gpt41CategoriesByCategoryIdAverageNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_NAME_ASC",
  Gpt41CategoriesByCategoryIdAverageNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_NAME_DESC",
  Gpt41CategoriesByCategoryIdAverageNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdAverageNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_AVERAGE_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdCountAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_COUNT_ASC",
  Gpt41CategoriesByCategoryIdCountDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_COUNT_DESC",
  Gpt41CategoriesByCategoryIdDistinctCountCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdDistinctCountCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdDistinctCountIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_ID_ASC",
  Gpt41CategoriesByCategoryIdDistinctCountIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_ID_DESC",
  Gpt41CategoriesByCategoryIdDistinctCountNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_NAME_ASC",
  Gpt41CategoriesByCategoryIdDistinctCountNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_NAME_DESC",
  Gpt41CategoriesByCategoryIdDistinctCountNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdDistinctCountNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_DISTINCT_COUNT_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdMaxCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdMaxCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdMaxIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_ID_ASC",
  Gpt41CategoriesByCategoryIdMaxIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_ID_DESC",
  Gpt41CategoriesByCategoryIdMaxNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_NAME_ASC",
  Gpt41CategoriesByCategoryIdMaxNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_NAME_DESC",
  Gpt41CategoriesByCategoryIdMaxNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdMaxNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MAX_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdMinCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdMinCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdMinIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_ID_ASC",
  Gpt41CategoriesByCategoryIdMinIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_ID_DESC",
  Gpt41CategoriesByCategoryIdMinNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_NAME_ASC",
  Gpt41CategoriesByCategoryIdMinNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_NAME_DESC",
  Gpt41CategoriesByCategoryIdMinNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdMinNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_MIN_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdStddevPopulationCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdStddevPopulationCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdStddevPopulationIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_ID_ASC",
  Gpt41CategoriesByCategoryIdStddevPopulationIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_ID_DESC",
  Gpt41CategoriesByCategoryIdStddevPopulationNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_NAME_ASC",
  Gpt41CategoriesByCategoryIdStddevPopulationNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_NAME_DESC",
  Gpt41CategoriesByCategoryIdStddevPopulationNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdStddevPopulationNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_POPULATION_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdStddevSampleCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdStddevSampleCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdStddevSampleIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_ID_ASC",
  Gpt41CategoriesByCategoryIdStddevSampleIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_ID_DESC",
  Gpt41CategoriesByCategoryIdStddevSampleNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_NAME_ASC",
  Gpt41CategoriesByCategoryIdStddevSampleNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_NAME_DESC",
  Gpt41CategoriesByCategoryIdStddevSampleNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdStddevSampleNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_STDDEV_SAMPLE_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdSumCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdSumCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdSumIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_ID_ASC",
  Gpt41CategoriesByCategoryIdSumIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_ID_DESC",
  Gpt41CategoriesByCategoryIdSumNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_NAME_ASC",
  Gpt41CategoriesByCategoryIdSumNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_NAME_DESC",
  Gpt41CategoriesByCategoryIdSumNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdSumNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_SUM_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdVariancePopulationCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdVariancePopulationCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdVariancePopulationIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_ID_ASC",
  Gpt41CategoriesByCategoryIdVariancePopulationIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_ID_DESC",
  Gpt41CategoriesByCategoryIdVariancePopulationNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_NAME_ASC",
  Gpt41CategoriesByCategoryIdVariancePopulationNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_NAME_DESC",
  Gpt41CategoriesByCategoryIdVariancePopulationNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdVariancePopulationNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_POPULATION_NEW_CATEGORY_DESC",
  Gpt41CategoriesByCategoryIdVarianceSampleCategoryIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_CATEGORY_ID_ASC",
  Gpt41CategoriesByCategoryIdVarianceSampleCategoryIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_CATEGORY_ID_DESC",
  Gpt41CategoriesByCategoryIdVarianceSampleIdAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_ID_ASC",
  Gpt41CategoriesByCategoryIdVarianceSampleIdDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_ID_DESC",
  Gpt41CategoriesByCategoryIdVarianceSampleNameAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_NAME_ASC",
  Gpt41CategoriesByCategoryIdVarianceSampleNameDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_NAME_DESC",
  Gpt41CategoriesByCategoryIdVarianceSampleNewCategoryAsc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_NEW_CATEGORY_ASC",
  Gpt41CategoriesByCategoryIdVarianceSampleNewCategoryDesc = "GPT41_CATEGORIES_BY_CATEGORY_ID_VARIANCE_SAMPLE_NEW_CATEGORY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type LgCategory = Node & {
  __typename?: "LgCategory";
  /** Reads and enables pagination through a set of `Gpt41Category`. */
  gpt41CategoriesByCategoryId: Gpt41CategoriesConnection;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type LgCategoryGpt41CategoriesByCategoryIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt41CategoryCondition>;
  filter?: InputMaybe<Gpt41CategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt41CategoriesOrderBy>>;
};

export type LgCategoryAggregates = {
  __typename?: "LgCategoryAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCategoryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCategoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCategoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCategoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCategoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCategoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCategorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCategoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCategoryVarianceSampleAggregates>;
};

export type LgCategoryAverageAggregates = {
  __typename?: "LgCategoryAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgCategory` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LgCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgCategoryDistinctCountAggregates = {
  __typename?: "LgCategoryDistinctCountAggregates";
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCategory` object types. All fields are combined with a logical ‘and.’ */
export type LgCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCategoryFilter>>;
  /** Filter by the object’s `gpt41CategoriesByCategoryId` relation. */
  gpt41CategoriesByCategoryId?: InputMaybe<LgCategoryToManyGpt41CategoryFilter>;
  /** Some related `gpt41CategoriesByCategoryId` exist. */
  gpt41CategoriesByCategoryIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCategoryFilter>>;
};

export type LgCategoryMaxAggregates = {
  __typename?: "LgCategoryMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCategoryMinAggregates = {
  __typename?: "LgCategoryMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCategoryStddevPopulationAggregates = {
  __typename?: "LgCategoryStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCategoryStddevSampleAggregates = {
  __typename?: "LgCategoryStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCategorySumAggregates = {
  __typename?: "LgCategorySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `Gpt41Category` object types. All fields are combined with a logical ‘and.’ */
export type LgCategoryToManyGpt41CategoryFilter = {
  /** Aggregates across related `Gpt41Category` match the filter criteria. */
  aggregates?: InputMaybe<Gpt41CategoryAggregatesFilter>;
  /** Every related `Gpt41Category` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<Gpt41CategoryFilter>;
  /** No related `Gpt41Category` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<Gpt41CategoryFilter>;
  /** Some related `Gpt41Category` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<Gpt41CategoryFilter>;
};

export type LgCategoryVariancePopulationAggregates = {
  __typename?: "LgCategoryVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCategoryVarianceSampleAggregates = {
  __typename?: "LgCategoryVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgCompany` values. */
export type LgCompaniesConnection = {
  __typename?: "LgCompaniesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany` and cursor to aid in pagination. */
  edges: Array<LgCompaniesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values. */
export type LgCompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection. */
export type LgCompaniesEdge = {
  __typename?: "LgCompaniesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** Methods to use when ordering `LgCompany`. */
export enum LgCompaniesOrderBy {
  AboutAsc = "ABOUT_ASC",
  AboutDesc = "ABOUT_DESC",
  AddressAsc = "ADDRESS_ASC",
  AddressDesc = "ADDRESS_DESC",
  AmountAsc = "AMOUNT_ASC",
  AmountDesc = "AMOUNT_DESC",
  AmountUsdMillionAsc = "AMOUNT_USD_MILLION_ASC",
  AmountUsdMillionDesc = "AMOUNT_USD_MILLION_DESC",
  CalculationStatusAsc = "CALCULATION_STATUS_ASC",
  CalculationStatusDesc = "CALCULATION_STATUS_DESC",
  CardLastActivityAsc = "CARD_LAST_ACTIVITY_ASC",
  CardLastActivityDesc = "CARD_LAST_ACTIVITY_DESC",
  CardNameAsc = "CARD_NAME_ASC",
  CardNameDesc = "CARD_NAME_DESC",
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyStatusAsc = "COMPANY_STATUS_ASC",
  CompanyStatusDesc = "COMPANY_STATUS_DESC",
  ContinentAsc = "CONTINENT_ASC",
  ContinentDesc = "CONTINENT_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  DealroomPathAsc = "DEALROOM_PATH_ASC",
  DealroomPathDesc = "DEALROOM_PATH_DESC",
  DealroomSignalCompletenessAsc = "DEALROOM_SIGNAL_COMPLETENESS_ASC",
  DealroomSignalCompletenessDesc = "DEALROOM_SIGNAL_COMPLETENESS_DESC",
  DealroomSignalGrowthRateAsc = "DEALROOM_SIGNAL_GROWTH_RATE_ASC",
  DealroomSignalGrowthRateDesc = "DEALROOM_SIGNAL_GROWTH_RATE_DESC",
  DealroomSignalRatingAsc = "DEALROOM_SIGNAL_RATING_ASC",
  DealroomSignalRatingDesc = "DEALROOM_SIGNAL_RATING_DESC",
  DealroomSignalTeamStrengthAsc = "DEALROOM_SIGNAL_TEAM_STRENGTH_ASC",
  DealroomSignalTeamStrengthDesc = "DEALROOM_SIGNAL_TEAM_STRENGTH_DESC",
  DealroomSignalTimingAsc = "DEALROOM_SIGNAL_TIMING_ASC",
  DealroomSignalTimingDesc = "DEALROOM_SIGNAL_TIMING_DESC",
  DealroomUrlAsc = "DEALROOM_URL_ASC",
  DealroomUrlDesc = "DEALROOM_URL_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  DraftAsc = "DRAFT_ASC",
  DraftDesc = "DRAFT_DESC",
  EmployeesAsc = "EMPLOYEES_ASC",
  EmployeesChartAsc = "EMPLOYEES_CHART_ASC",
  EmployeesChartDesc = "EMPLOYEES_CHART_DESC",
  EmployeesDesc = "EMPLOYEES_DESC",
  EmployeesLatestAsc = "EMPLOYEES_LATEST_ASC",
  EmployeesLatestDesc = "EMPLOYEES_LATEST_DESC",
  FullPipelineAsc = "FULL_PIPELINE_ASC",
  FullPipelineDesc = "FULL_PIPELINE_DESC",
  FundingDateAsc = "FUNDING_DATE_ASC",
  FundingDateDesc = "FUNDING_DATE_DESC",
  FundingRoundsAsc = "FUNDING_ROUNDS_ASC",
  FundingRoundsDesc = "FUNDING_ROUNDS_DESC",
  GrowthChartAsc = "GROWTH_CHART_ASC",
  GrowthChartDesc = "GROWTH_CHART_DESC",
  HasPromisingFounderAsc = "HAS_PROMISING_FOUNDER_ASC",
  HasPromisingFounderDesc = "HAS_PROMISING_FOUNDER_DESC",
  HasStrongFounderAsc = "HAS_STRONG_FOUNDER_ASC",
  HasStrongFounderDesc = "HAS_STRONG_FOUNDER_DESC",
  HasSuperFounderAsc = "HAS_SUPER_FOUNDER_ASC",
  HasSuperFounderDesc = "HAS_SUPER_FOUNDER_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IndustriesAsc = "INDUSTRIES_ASC",
  IndustriesDesc = "INDUSTRIES_DESC",
  InvestorsAsc = "INVESTORS_ASC",
  InvestorsDesc = "INVESTORS_DESC",
  IsActivelyFundraisingAsc = "IS_ACTIVELY_FUNDRAISING_ASC",
  IsActivelyFundraisingDesc = "IS_ACTIVELY_FUNDRAISING_DESC",
  IsBasecampFundedAsc = "IS_BASECAMP_FUNDED_ASC",
  IsBasecampFundedDesc = "IS_BASECAMP_FUNDED_DESC",
  IsFoundingLocationAsc = "IS_FOUNDING_LOCATION_ASC",
  IsFoundingLocationDesc = "IS_FOUNDING_LOCATION_DESC",
  IsNpaAsc = "IS_NPA_ASC",
  IsNpaDesc = "IS_NPA_DESC",
  IsOpCompanyAsc = "IS_OP_COMPANY_ASC",
  IsOpCompanyDesc = "IS_OP_COMPANY_DESC",
  IsTrCompanyAsc = "IS_TR_COMPANY_ASC",
  IsTrCompanyDesc = "IS_TR_COMPANY_DESC",
  Last_3SentimentAsc = "LAST_3_SENTIMENT_ASC",
  Last_3SentimentDesc = "LAST_3_SENTIMENT_DESC",
  LastCalculatedUtcAsc = "LAST_CALCULATED_UTC_ASC",
  LastCalculatedUtcDesc = "LAST_CALCULATED_UTC_DESC",
  LastFundingDateAsc = "LAST_FUNDING_DATE_ASC",
  LastFundingDateDesc = "LAST_FUNDING_DATE_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LatestExcitementAsc = "LATEST_EXCITEMENT_ASC",
  LatestExcitementDateAsc = "LATEST_EXCITEMENT_DATE_ASC",
  LatestExcitementDateDesc = "LATEST_EXCITEMENT_DATE_DESC",
  LatestExcitementDesc = "LATEST_EXCITEMENT_DESC",
  LaunchYearAsc = "LAUNCH_YEAR_ASC",
  LaunchYearDesc = "LAUNCH_YEAR_DESC",
  LgCompanyCommentsByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdAverageCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdAverageCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdAverageCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdAverageCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdAverageIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyCommentsByCompanyIdAverageIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyCommentsByCompanyIdAverageLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdAverageLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdAverageMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_METADATA_ASC",
  LgCompanyCommentsByCompanyIdAverageMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_METADATA_DESC",
  LgCompanyCommentsByCompanyIdAverageTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_TEXT_ASC",
  LgCompanyCommentsByCompanyIdAverageTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_AVERAGE_TEXT_DESC",
  LgCompanyCommentsByCompanyIdCountAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyCommentsByCompanyIdCountDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_METADATA_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_METADATA_DESC",
  LgCompanyCommentsByCompanyIdDistinctCountTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_TEXT_ASC",
  LgCompanyCommentsByCompanyIdDistinctCountTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_DISTINCT_COUNT_TEXT_DESC",
  LgCompanyCommentsByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdMaxCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdMaxCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdMaxCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdMaxCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdMaxIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyCommentsByCompanyIdMaxIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyCommentsByCompanyIdMaxLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdMaxLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdMaxMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_METADATA_ASC",
  LgCompanyCommentsByCompanyIdMaxMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_METADATA_DESC",
  LgCompanyCommentsByCompanyIdMaxTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_TEXT_ASC",
  LgCompanyCommentsByCompanyIdMaxTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MAX_TEXT_DESC",
  LgCompanyCommentsByCompanyIdMinCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdMinCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdMinCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdMinCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdMinCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdMinCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdMinIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyCommentsByCompanyIdMinIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyCommentsByCompanyIdMinLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdMinLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdMinMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_METADATA_ASC",
  LgCompanyCommentsByCompanyIdMinMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_METADATA_DESC",
  LgCompanyCommentsByCompanyIdMinTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_TEXT_ASC",
  LgCompanyCommentsByCompanyIdMinTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_MIN_TEXT_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_METADATA_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_METADATA_DESC",
  LgCompanyCommentsByCompanyIdStddevPopulationTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_TEXT_ASC",
  LgCompanyCommentsByCompanyIdStddevPopulationTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_POPULATION_TEXT_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_METADATA_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_METADATA_DESC",
  LgCompanyCommentsByCompanyIdStddevSampleTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_TEXT_ASC",
  LgCompanyCommentsByCompanyIdStddevSampleTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_STDDEV_SAMPLE_TEXT_DESC",
  LgCompanyCommentsByCompanyIdSumCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdSumCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdSumCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdSumCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdSumCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdSumCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdSumIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyCommentsByCompanyIdSumIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyCommentsByCompanyIdSumLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdSumLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdSumMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_METADATA_ASC",
  LgCompanyCommentsByCompanyIdSumMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_METADATA_DESC",
  LgCompanyCommentsByCompanyIdSumTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_TEXT_ASC",
  LgCompanyCommentsByCompanyIdSumTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_SUM_TEXT_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_METADATA_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_METADATA_DESC",
  LgCompanyCommentsByCompanyIdVariancePopulationTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_TEXT_ASC",
  LgCompanyCommentsByCompanyIdVariancePopulationTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_POPULATION_TEXT_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleCreatedByAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleCreatedByDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleMetadataAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_METADATA_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleMetadataDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_METADATA_DESC",
  LgCompanyCommentsByCompanyIdVarianceSampleTextAsc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TEXT_ASC",
  LgCompanyCommentsByCompanyIdVarianceSampleTextDesc = "LG_COMPANY_COMMENTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TEXT_DESC",
  LgCompanyFilesByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdAverageFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdAverageFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdAverageIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyFilesByCompanyIdAverageIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyFilesByCompanyIdCountAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyFilesByCompanyIdCountDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyFilesByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdDistinctCountFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdDistinctCountFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdDistinctCountIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyFilesByCompanyIdDistinctCountIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyFilesByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdMaxFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdMaxFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdMaxIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyFilesByCompanyIdMaxIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyFilesByCompanyIdMinCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdMinCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdMinFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdMinFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdMinIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyFilesByCompanyIdMinIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyFilesByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdStddevPopulationFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdStddevPopulationFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyFilesByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyFilesByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdStddevSampleFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdStddevSampleFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdStddevSampleIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyFilesByCompanyIdStddevSampleIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyFilesByCompanyIdSumCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdSumCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdSumFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdSumFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdSumIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyFilesByCompanyIdSumIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyFilesByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdVariancePopulationFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdVariancePopulationFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyFilesByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyFilesByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFilesByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFilesByCompanyIdVarianceSampleFileIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_FILE_ID_ASC",
  LgCompanyFilesByCompanyIdVarianceSampleFileIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_FILE_ID_DESC",
  LgCompanyFilesByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyFilesByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_FILES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyFlagsByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdAverageCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdAverageCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdAverageCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdAverageCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdAverageDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdAverageDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdAverageFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_FLAG_ASC",
  LgCompanyFlagsByCompanyIdAverageFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_FLAG_DESC",
  LgCompanyFlagsByCompanyIdAverageIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyFlagsByCompanyIdAverageIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyFlagsByCompanyIdAverageUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdAverageUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_AVERAGE_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdCountAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyFlagsByCompanyIdCountDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_FLAG_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_FLAG_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyFlagsByCompanyIdDistinctCountUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdDistinctCountUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdMaxCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdMaxCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdMaxCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdMaxCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdMaxDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdMaxDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdMaxFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_FLAG_ASC",
  LgCompanyFlagsByCompanyIdMaxFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_FLAG_DESC",
  LgCompanyFlagsByCompanyIdMaxIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyFlagsByCompanyIdMaxIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyFlagsByCompanyIdMaxUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdMaxUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MAX_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdMinCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdMinCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdMinCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdMinCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdMinCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdMinCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdMinDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdMinDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdMinFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_FLAG_ASC",
  LgCompanyFlagsByCompanyIdMinFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_FLAG_DESC",
  LgCompanyFlagsByCompanyIdMinIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyFlagsByCompanyIdMinIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyFlagsByCompanyIdMinUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdMinUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_MIN_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_FLAG_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_FLAG_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyFlagsByCompanyIdStddevPopulationUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevPopulationUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_FLAG_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_FLAG_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyFlagsByCompanyIdStddevSampleUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdStddevSampleUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdSumCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdSumCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdSumCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdSumCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdSumCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdSumCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdSumDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdSumDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdSumFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_FLAG_ASC",
  LgCompanyFlagsByCompanyIdSumFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_FLAG_DESC",
  LgCompanyFlagsByCompanyIdSumIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyFlagsByCompanyIdSumIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyFlagsByCompanyIdSumUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdSumUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_SUM_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_FLAG_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_FLAG_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyFlagsByCompanyIdVariancePopulationUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdVariancePopulationUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleCreatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleCreatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleCreatedByAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleCreatedByDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleDeletedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_AT_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleDeletedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_AT_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleFlagAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_FLAG_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleFlagDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_FLAG_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyFlagsByCompanyIdVarianceSampleUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  LgCompanyFlagsByCompanyIdVarianceSampleUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  LgCompanyInvestorsByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdAverageCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdAverageCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdAverageDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdAverageDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdAverageExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdAverageExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdAverageIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyInvestorsByCompanyIdAverageIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyInvestorsByCompanyIdAverageInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdAverageInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdAverageIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdAverageIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_AVERAGE_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdCountAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyInvestorsByCompanyIdCountDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdDistinctCountIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdDistinctCountIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_DISTINCT_COUNT_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdMaxCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdMaxCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdMaxDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdMaxDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdMaxExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdMaxExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdMaxIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyInvestorsByCompanyIdMaxIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyInvestorsByCompanyIdMaxInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdMaxInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdMaxIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdMaxIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MAX_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdMinCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdMinCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdMinCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdMinCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdMinDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdMinDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdMinExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdMinExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdMinIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyInvestorsByCompanyIdMinIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyInvestorsByCompanyIdMinInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdMinInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdMinIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdMinIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_MIN_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevPopulationIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdStddevPopulationIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_POPULATION_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdStddevSampleIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdStddevSampleIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdSumCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdSumCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdSumCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdSumCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdSumDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdSumDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdSumExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdSumExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdSumIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyInvestorsByCompanyIdSumIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyInvestorsByCompanyIdSumInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdSumInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdSumIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdSumIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_SUM_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdVariancePopulationIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdVariancePopulationIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleDeletedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleDeletedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleExitedAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXITED_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleExitedDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXITED_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByCompanyIdVarianceSampleIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByCompanyIdVarianceSampleIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_EDITORIAL_DESC",
  LgCompanyMetricsByCompanyIdAverageArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_ASC",
  LgCompanyMetricsByCompanyIdAverageArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_DESC",
  LgCompanyMetricsByCompanyIdAverageArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdAverageArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdAverageArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdAverageAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdAverageBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdAverageBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdAverageCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdAverageCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdAverageCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdAverageCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdAverageCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdAverageCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdAverageCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdAverageCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdAverageDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DATA_ASC",
  LgCompanyMetricsByCompanyIdAverageDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DATA_DESC",
  LgCompanyMetricsByCompanyIdAverageDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdAverageDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdAverageDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdAverageDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdAverageEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdAverageEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdAverageFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FTE_ASC",
  LgCompanyMetricsByCompanyIdAverageFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FTE_DESC",
  LgCompanyMetricsByCompanyIdAverageFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdAverageFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdAverageGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdAverageGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdAverageHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdAverageHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdAverageIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyMetricsByCompanyIdAverageIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyMetricsByCompanyIdAverageImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdAverageImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdAverageImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdAverageIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdAverageLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdAverageLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdAverageLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdAverageLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdAverageLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAverageOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAverageOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdAverageOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdAveragePathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdAveragePathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdAveragePathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdAveragePathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdAveragePotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdAveragePotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdAverageReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdAverageReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdAverageSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdAverageSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdAverageStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_STATUS_ASC",
  LgCompanyMetricsByCompanyIdAverageStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_STATUS_DESC",
  LgCompanyMetricsByCompanyIdAverageUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdAverageUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_AVERAGE_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdCountAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyMetricsByCompanyIdCountDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DATA_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DATA_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FTE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FTE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_DESC",
  LgCompanyMetricsByCompanyIdDistinctCountUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdDistinctCountUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdMaxArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_ASC",
  LgCompanyMetricsByCompanyIdMaxArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_DESC",
  LgCompanyMetricsByCompanyIdMaxArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMaxArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMaxArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdMaxAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdMaxBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdMaxBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdMaxCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdMaxCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdMaxCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdMaxCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdMaxCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdMaxCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdMaxCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdMaxCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdMaxDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DATA_ASC",
  LgCompanyMetricsByCompanyIdMaxDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DATA_DESC",
  LgCompanyMetricsByCompanyIdMaxDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdMaxDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdMaxDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdMaxDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdMaxEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdMaxEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdMaxFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FTE_ASC",
  LgCompanyMetricsByCompanyIdMaxFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FTE_DESC",
  LgCompanyMetricsByCompanyIdMaxFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdMaxFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdMaxGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMaxGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMaxHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdMaxHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdMaxIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyMetricsByCompanyIdMaxIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyMetricsByCompanyIdMaxImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdMaxImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdMaxImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdMaxIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdMaxLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdMaxLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdMaxLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdMaxLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdMaxLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMaxOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMaxPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdMaxPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdMaxPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMaxPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMaxPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdMaxPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdMaxReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdMaxReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdMaxSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdMaxSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdMaxStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_STATUS_ASC",
  LgCompanyMetricsByCompanyIdMaxStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_STATUS_DESC",
  LgCompanyMetricsByCompanyIdMaxUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdMaxUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MAX_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdMinArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_ASC",
  LgCompanyMetricsByCompanyIdMinArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_DESC",
  LgCompanyMetricsByCompanyIdMinArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMinArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMinArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdMinAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdMinBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdMinBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdMinCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdMinCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdMinCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdMinCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdMinCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdMinCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdMinCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdMinCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdMinCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdMinCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdMinDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DATA_ASC",
  LgCompanyMetricsByCompanyIdMinDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DATA_DESC",
  LgCompanyMetricsByCompanyIdMinDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdMinDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdMinDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdMinDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdMinEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdMinEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdMinFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FTE_ASC",
  LgCompanyMetricsByCompanyIdMinFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FTE_DESC",
  LgCompanyMetricsByCompanyIdMinFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdMinFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdMinGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMinGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMinHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdMinHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdMinIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyMetricsByCompanyIdMinIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyMetricsByCompanyIdMinImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdMinImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdMinImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdMinIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdMinLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdMinLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdMinLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdMinLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdMinLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdMinOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdMinPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdMinPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdMinPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdMinPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdMinPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdMinPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdMinReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdMinReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdMinSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdMinSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdMinStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_STATUS_ASC",
  LgCompanyMetricsByCompanyIdMinStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_STATUS_DESC",
  LgCompanyMetricsByCompanyIdMinUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdMinUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_MIN_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DATA_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DATA_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FTE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FTE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_DESC",
  LgCompanyMetricsByCompanyIdStddevPopulationUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdStddevPopulationUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DATA_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DATA_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FTE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FTE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdStddevSamplePathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdStddevSamplePathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdStddevSamplePathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdStddevSamplePathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdStddevSamplePotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdStddevSamplePotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_DESC",
  LgCompanyMetricsByCompanyIdStddevSampleUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdStddevSampleUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdSumArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_ASC",
  LgCompanyMetricsByCompanyIdSumArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_DESC",
  LgCompanyMetricsByCompanyIdSumArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdSumArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdSumArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdSumAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdSumBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdSumBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdSumCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdSumCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdSumCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdSumCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdSumCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdSumCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdSumCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdSumCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdSumCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdSumCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdSumDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DATA_ASC",
  LgCompanyMetricsByCompanyIdSumDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DATA_DESC",
  LgCompanyMetricsByCompanyIdSumDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdSumDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdSumDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdSumDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdSumEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdSumEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdSumFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FTE_ASC",
  LgCompanyMetricsByCompanyIdSumFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FTE_DESC",
  LgCompanyMetricsByCompanyIdSumFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdSumFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdSumGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdSumGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdSumHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdSumHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdSumIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyMetricsByCompanyIdSumIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyMetricsByCompanyIdSumImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdSumImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdSumImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdSumIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdSumLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdSumLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdSumLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdSumLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdSumLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdSumOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdSumPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdSumPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdSumPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdSumPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdSumPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdSumPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdSumReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdSumReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdSumSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdSumSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdSumStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_STATUS_ASC",
  LgCompanyMetricsByCompanyIdSumStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_STATUS_DESC",
  LgCompanyMetricsByCompanyIdSumUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdSumUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_SUM_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DATA_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DATA_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FTE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FTE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationPathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationPathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationPathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationPathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationPotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationPotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_DESC",
  LgCompanyMetricsByCompanyIdVariancePopulationUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdVariancePopulationUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrGrowthNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_GROWTH_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrGrowthNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_GROWTH_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrGrowthPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_GROWTH_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrGrowthPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_GROWTH_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleAsAtDateAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_AS_AT_DATE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleAsAtDateDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_AS_AT_DATE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleBuyRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_BUY_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleBuyRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_BUY_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashflowPositiveAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHFLOW_POSITIVE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashflowPositiveDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHFLOW_POSITIVE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashRunwayMonthsAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASH_RUNWAY_MONTHS_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashRunwayMonthsDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASH_RUNWAY_MONTHS_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashRunwayNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASH_RUNWAY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCashRunwayNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASH_RUNWAY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCloseEnoughAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CLOSE_ENOUGH_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCloseEnoughDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CLOSE_ENOUGH_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleCreatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleCreatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleDataAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DATA_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleDataDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DATA_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleDiscountToValuationQ1Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DISCOUNT_TO_VALUATION_Q1_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleDiscountToValuationQ1Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DISCOUNT_TO_VALUATION_Q1_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleDiscountToValuationQ4Asc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DISCOUNT_TO_VALUATION_Q4_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleDiscountToValuationQ4Desc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_DISCOUNT_TO_VALUATION_Q4_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleEnoughEyesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENOUGH_EYES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleEnoughEyesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENOUGH_EYES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleFteAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FTE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleFteDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FTE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleFteNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FTE_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleFteNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FTE_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleFullyFundedToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FULLY_FUNDED_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleFullyFundedToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_FULLY_FUNDED_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleGrossMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleGrossMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleGrossMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleGrossMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleHowCanWeSupportAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_HOW_CAN_WE_SUPPORT_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleHowCanWeSupportDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_HOW_CAN_WE_SUPPORT_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleImpliedArrAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMPLIED_ARR_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleImpliedArrDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMPLIED_ARR_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleImpliedArrNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMPLIED_ARR_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleImpliedArrNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IMPLIED_ARR_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleIsHoldingCorrectValueAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_HOLDING_CORRECT_VALUE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleIsHoldingCorrectValueDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_HOLDING_CORRECT_VALUE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleLastUpatedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPATED_BY_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleLastUpatedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPATED_BY_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleLatestPostMoneyAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_POST_MONEY_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleLatestPostMoneyDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_POST_MONEY_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleLatestPostMoneyNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_POST_MONEY_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleLatestPostMoneyNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_POST_MONEY_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleOperatingMarginNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_OPERATING_MARGIN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleOperatingMarginNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_OPERATING_MARGIN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleOperatingMarginPercentageAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_OPERATING_MARGIN_PERCENTAGE_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleOperatingMarginPercentageDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_OPERATING_MARGIN_PERCENTAGE_DESC",
  LgCompanyMetricsByCompanyIdVarianceSamplePathToBreakevenAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_PATH_TO_BREAKEVEN_ASC",
  LgCompanyMetricsByCompanyIdVarianceSamplePathToBreakevenDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_PATH_TO_BREAKEVEN_DESC",
  LgCompanyMetricsByCompanyIdVarianceSamplePathToBrekevenNotesAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_PATH_TO_BREKEVEN_NOTES_ASC",
  LgCompanyMetricsByCompanyIdVarianceSamplePathToBrekevenNotesDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_PATH_TO_BREKEVEN_NOTES_DESC",
  LgCompanyMetricsByCompanyIdVarianceSamplePotentialValueDriverAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_POTENTIAL_VALUE_DRIVER_ASC",
  LgCompanyMetricsByCompanyIdVarianceSamplePotentialValueDriverDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_POTENTIAL_VALUE_DRIVER_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleReviewedByAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_BY_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleReviewedByDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_BY_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleSellRecommendationAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_SELL_RECOMMENDATION_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleSellRecommendationDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_SELL_RECOMMENDATION_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleStatusAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleStatusDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_DESC",
  LgCompanyMetricsByCompanyIdVarianceSampleUpdatedAtAsc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  LgCompanyMetricsByCompanyIdVarianceSampleUpdatedAtDesc = "LG_COMPANY_METRICS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  LgCompanyNotesByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdAverageCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdAverageCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdAverageCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdAverageCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdAverageDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_DATA_ASC",
  LgCompanyNotesByCompanyIdAverageDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_DATA_DESC",
  LgCompanyNotesByCompanyIdAverageIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyNotesByCompanyIdAverageIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyNotesByCompanyIdAverageLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdAverageLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdAverageTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_TYPE_ASC",
  LgCompanyNotesByCompanyIdAverageTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_AVERAGE_TYPE_DESC",
  LgCompanyNotesByCompanyIdCountAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyNotesByCompanyIdCountDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyNotesByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdDistinctCountCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdDistinctCountCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdDistinctCountCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdDistinctCountCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdDistinctCountDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_DATA_ASC",
  LgCompanyNotesByCompanyIdDistinctCountDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_DATA_DESC",
  LgCompanyNotesByCompanyIdDistinctCountIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyNotesByCompanyIdDistinctCountIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyNotesByCompanyIdDistinctCountLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdDistinctCountLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdDistinctCountTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_ASC",
  LgCompanyNotesByCompanyIdDistinctCountTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_DESC",
  LgCompanyNotesByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdMaxCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdMaxCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdMaxCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdMaxCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdMaxDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_DATA_ASC",
  LgCompanyNotesByCompanyIdMaxDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_DATA_DESC",
  LgCompanyNotesByCompanyIdMaxIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyNotesByCompanyIdMaxIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyNotesByCompanyIdMaxLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdMaxLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdMaxTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_TYPE_ASC",
  LgCompanyNotesByCompanyIdMaxTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MAX_TYPE_DESC",
  LgCompanyNotesByCompanyIdMinCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdMinCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdMinCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdMinCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdMinCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdMinCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdMinDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_DATA_ASC",
  LgCompanyNotesByCompanyIdMinDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_DATA_DESC",
  LgCompanyNotesByCompanyIdMinIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyNotesByCompanyIdMinIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyNotesByCompanyIdMinLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdMinLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdMinTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_TYPE_ASC",
  LgCompanyNotesByCompanyIdMinTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_MIN_TYPE_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_DATA_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_DATA_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdStddevPopulationTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_ASC",
  LgCompanyNotesByCompanyIdStddevPopulationTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_DESC",
  LgCompanyNotesByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdStddevSampleCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdStddevSampleCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdStddevSampleCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdStddevSampleCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdStddevSampleDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_DATA_ASC",
  LgCompanyNotesByCompanyIdStddevSampleDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_DATA_DESC",
  LgCompanyNotesByCompanyIdStddevSampleIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyNotesByCompanyIdStddevSampleIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyNotesByCompanyIdStddevSampleLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdStddevSampleLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdStddevSampleTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_ASC",
  LgCompanyNotesByCompanyIdStddevSampleTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_DESC",
  LgCompanyNotesByCompanyIdSumCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdSumCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdSumCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdSumCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdSumCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdSumCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdSumDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_DATA_ASC",
  LgCompanyNotesByCompanyIdSumDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_DATA_DESC",
  LgCompanyNotesByCompanyIdSumIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyNotesByCompanyIdSumIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyNotesByCompanyIdSumLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdSumLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdSumTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_TYPE_ASC",
  LgCompanyNotesByCompanyIdSumTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_SUM_TYPE_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_DATA_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_DATA_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdVariancePopulationTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_ASC",
  LgCompanyNotesByCompanyIdVariancePopulationTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleCreatedByAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleCreatedByDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleCreatedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleCreatedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleDataAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_DATA_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleDataDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_DATA_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCompanyIdVarianceSampleTypeAsc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_ASC",
  LgCompanyNotesByCompanyIdVarianceSampleTypeDesc = "LG_COMPANY_NOTES_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_DESC",
  LgCompanySignalDateAsc = "LG_COMPANY_SIGNAL_DATE_ASC",
  LgCompanySignalDateDesc = "LG_COMPANY_SIGNAL_DATE_DESC",
  LgCompanyTeamsByCompanyIdAverageCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdAverageCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdAverageCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdAverageCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdAverageDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_DELETED_ASC",
  LgCompanyTeamsByCompanyIdAverageDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_DELETED_DESC",
  LgCompanyTeamsByCompanyIdAverageIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgCompanyTeamsByCompanyIdAverageIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgCompanyTeamsByCompanyIdAverageIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdAverageIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdAverageMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdAverageMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdAveragePastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_PAST_ASC",
  LgCompanyTeamsByCompanyIdAveragePastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_PAST_DESC",
  LgCompanyTeamsByCompanyIdAverageTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_TITLES_ASC",
  LgCompanyTeamsByCompanyIdAverageTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_TITLES_DESC",
  LgCompanyTeamsByCompanyIdAverageYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdAverageYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdAverageYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdAverageYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_AVERAGE_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdCountAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_COUNT_ASC",
  LgCompanyTeamsByCompanyIdCountDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_COUNT_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_PAST_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_PAST_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLES_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLES_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdDistinctCountYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdDistinctCountYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdMaxCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdMaxCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdMaxCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdMaxCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdMaxDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_DELETED_ASC",
  LgCompanyTeamsByCompanyIdMaxDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_DELETED_DESC",
  LgCompanyTeamsByCompanyIdMaxIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_ID_ASC",
  LgCompanyTeamsByCompanyIdMaxIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_ID_DESC",
  LgCompanyTeamsByCompanyIdMaxIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdMaxIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdMaxMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdMaxMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdMaxPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_PAST_ASC",
  LgCompanyTeamsByCompanyIdMaxPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_PAST_DESC",
  LgCompanyTeamsByCompanyIdMaxTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_TITLES_ASC",
  LgCompanyTeamsByCompanyIdMaxTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_TITLES_DESC",
  LgCompanyTeamsByCompanyIdMaxYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdMaxYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdMaxYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdMaxYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MAX_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdMinCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdMinCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdMinCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdMinCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdMinDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_DELETED_ASC",
  LgCompanyTeamsByCompanyIdMinDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_DELETED_DESC",
  LgCompanyTeamsByCompanyIdMinIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_ID_ASC",
  LgCompanyTeamsByCompanyIdMinIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_ID_DESC",
  LgCompanyTeamsByCompanyIdMinIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdMinIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdMinMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdMinMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdMinPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_PAST_ASC",
  LgCompanyTeamsByCompanyIdMinPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_PAST_DESC",
  LgCompanyTeamsByCompanyIdMinTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_TITLES_ASC",
  LgCompanyTeamsByCompanyIdMinTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_TITLES_DESC",
  LgCompanyTeamsByCompanyIdMinYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdMinYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdMinYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdMinYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_MIN_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_PAST_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_PAST_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLES_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLES_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdStddevPopulationYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdStddevPopulationYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdStddevSamplePastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_PAST_ASC",
  LgCompanyTeamsByCompanyIdStddevSamplePastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_PAST_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLES_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLES_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdStddevSampleYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdStddevSampleYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdSumCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdSumCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdSumCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdSumCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdSumDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_DELETED_ASC",
  LgCompanyTeamsByCompanyIdSumDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_DELETED_DESC",
  LgCompanyTeamsByCompanyIdSumIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_ID_ASC",
  LgCompanyTeamsByCompanyIdSumIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_ID_DESC",
  LgCompanyTeamsByCompanyIdSumIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdSumIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdSumMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdSumMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdSumPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_PAST_ASC",
  LgCompanyTeamsByCompanyIdSumPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_PAST_DESC",
  LgCompanyTeamsByCompanyIdSumTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_TITLES_ASC",
  LgCompanyTeamsByCompanyIdSumTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_TITLES_DESC",
  LgCompanyTeamsByCompanyIdSumYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdSumYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdSumYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdSumYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_SUM_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationPastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_PAST_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationPastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_PAST_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLES_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLES_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdVariancePopulationYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdVariancePopulationYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_START_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleCompanyIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleCompanyIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleCompositeIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleCompositeIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleDeletedAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleDeletedDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleIsFounderAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FOUNDER_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleIsFounderDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FOUNDER_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleMemberIdAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleMemberIdDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  LgCompanyTeamsByCompanyIdVarianceSamplePastAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAST_ASC",
  LgCompanyTeamsByCompanyIdVarianceSamplePastDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAST_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleTitlesAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLES_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleTitlesDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLES_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleYearEndAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_END_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleYearEndDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_END_DESC",
  LgCompanyTeamsByCompanyIdVarianceSampleYearStartAsc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_START_ASC",
  LgCompanyTeamsByCompanyIdVarianceSampleYearStartDesc = "LG_COMPANY_TEAMS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_START_DESC",
  LgFundingRoundsByCompanyIdAverageAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdAverageAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdAverageAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdAverageAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdAverageAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdAverageAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdAverageAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdAverageAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdAverageCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdAverageCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdAverageCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdAverageCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdAverageIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  LgFundingRoundsByCompanyIdAverageIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  LgFundingRoundsByCompanyIdAverageIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdAverageIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdAverageMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_MONTH_ASC",
  LgFundingRoundsByCompanyIdAverageMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_MONTH_DESC",
  LgFundingRoundsByCompanyIdAverageRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ROUND_ASC",
  LgFundingRoundsByCompanyIdAverageRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_ROUND_DESC",
  LgFundingRoundsByCompanyIdAverageValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_ASC",
  LgFundingRoundsByCompanyIdAverageValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_DESC",
  LgFundingRoundsByCompanyIdAverageValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdAverageValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdAverageValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdAverageValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdAverageYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_YEAR_ASC",
  LgFundingRoundsByCompanyIdAverageYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_AVERAGE_YEAR_DESC",
  LgFundingRoundsByCompanyIdCountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_COUNT_ASC",
  LgFundingRoundsByCompanyIdCountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_COUNT_DESC",
  LgFundingRoundsByCompanyIdDistinctCountAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdDistinctCountAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdDistinctCountAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdDistinctCountAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdDistinctCountAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdDistinctCountAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdDistinctCountAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdDistinctCountAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdDistinctCountCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdDistinctCountCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdDistinctCountCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdDistinctCountCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdDistinctCountIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  LgFundingRoundsByCompanyIdDistinctCountIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  LgFundingRoundsByCompanyIdDistinctCountIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdDistinctCountIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdDistinctCountMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MONTH_ASC",
  LgFundingRoundsByCompanyIdDistinctCountMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MONTH_DESC",
  LgFundingRoundsByCompanyIdDistinctCountRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ROUND_ASC",
  LgFundingRoundsByCompanyIdDistinctCountRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ROUND_DESC",
  LgFundingRoundsByCompanyIdDistinctCountValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_ASC",
  LgFundingRoundsByCompanyIdDistinctCountValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_DESC",
  LgFundingRoundsByCompanyIdDistinctCountValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdDistinctCountValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdDistinctCountValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdDistinctCountValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdDistinctCountYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_ASC",
  LgFundingRoundsByCompanyIdDistinctCountYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_DESC",
  LgFundingRoundsByCompanyIdMaxAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdMaxAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdMaxAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdMaxAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdMaxAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdMaxAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdMaxAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdMaxAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdMaxCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdMaxCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdMaxCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdMaxCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdMaxIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ID_ASC",
  LgFundingRoundsByCompanyIdMaxIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ID_DESC",
  LgFundingRoundsByCompanyIdMaxIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdMaxIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdMaxMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_MONTH_ASC",
  LgFundingRoundsByCompanyIdMaxMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_MONTH_DESC",
  LgFundingRoundsByCompanyIdMaxRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ROUND_ASC",
  LgFundingRoundsByCompanyIdMaxRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_ROUND_DESC",
  LgFundingRoundsByCompanyIdMaxValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_ASC",
  LgFundingRoundsByCompanyIdMaxValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_DESC",
  LgFundingRoundsByCompanyIdMaxValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdMaxValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdMaxValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdMaxValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdMaxYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_YEAR_ASC",
  LgFundingRoundsByCompanyIdMaxYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MAX_YEAR_DESC",
  LgFundingRoundsByCompanyIdMinAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdMinAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdMinAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdMinAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdMinAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdMinAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdMinAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdMinAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdMinCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdMinCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdMinCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdMinCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdMinIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ID_ASC",
  LgFundingRoundsByCompanyIdMinIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ID_DESC",
  LgFundingRoundsByCompanyIdMinIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdMinIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdMinMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_MONTH_ASC",
  LgFundingRoundsByCompanyIdMinMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_MONTH_DESC",
  LgFundingRoundsByCompanyIdMinRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ROUND_ASC",
  LgFundingRoundsByCompanyIdMinRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_ROUND_DESC",
  LgFundingRoundsByCompanyIdMinValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_ASC",
  LgFundingRoundsByCompanyIdMinValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_DESC",
  LgFundingRoundsByCompanyIdMinValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdMinValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdMinValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdMinValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdMinYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_YEAR_ASC",
  LgFundingRoundsByCompanyIdMinYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_MIN_YEAR_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MONTH_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MONTH_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ROUND_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ROUND_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdStddevPopulationYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_ASC",
  LgFundingRoundsByCompanyIdStddevPopulationYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_DESC",
  LgFundingRoundsByCompanyIdStddevSampleAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdStddevSampleAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdStddevSampleAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdStddevSampleAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdStddevSampleAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdStddevSampleAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdStddevSampleAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdStddevSampleAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdStddevSampleCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdStddevSampleCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdStddevSampleCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdStddevSampleCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdStddevSampleIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  LgFundingRoundsByCompanyIdStddevSampleIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  LgFundingRoundsByCompanyIdStddevSampleIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdStddevSampleIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdStddevSampleMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MONTH_ASC",
  LgFundingRoundsByCompanyIdStddevSampleMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MONTH_DESC",
  LgFundingRoundsByCompanyIdStddevSampleRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ROUND_ASC",
  LgFundingRoundsByCompanyIdStddevSampleRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ROUND_DESC",
  LgFundingRoundsByCompanyIdStddevSampleValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_ASC",
  LgFundingRoundsByCompanyIdStddevSampleValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_DESC",
  LgFundingRoundsByCompanyIdStddevSampleValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdStddevSampleValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdStddevSampleValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdStddevSampleValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdStddevSampleYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_ASC",
  LgFundingRoundsByCompanyIdStddevSampleYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_DESC",
  LgFundingRoundsByCompanyIdSumAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdSumAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdSumAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdSumAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdSumAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdSumAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdSumAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdSumAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdSumCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdSumCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdSumCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdSumCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdSumIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ID_ASC",
  LgFundingRoundsByCompanyIdSumIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ID_DESC",
  LgFundingRoundsByCompanyIdSumIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdSumIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdSumMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_MONTH_ASC",
  LgFundingRoundsByCompanyIdSumMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_MONTH_DESC",
  LgFundingRoundsByCompanyIdSumRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ROUND_ASC",
  LgFundingRoundsByCompanyIdSumRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_ROUND_DESC",
  LgFundingRoundsByCompanyIdSumValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_ASC",
  LgFundingRoundsByCompanyIdSumValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_DESC",
  LgFundingRoundsByCompanyIdSumValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdSumValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdSumValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdSumValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdSumYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_YEAR_ASC",
  LgFundingRoundsByCompanyIdSumYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_SUM_YEAR_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MONTH_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MONTH_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ROUND_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ROUND_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdVariancePopulationYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_ASC",
  LgFundingRoundsByCompanyIdVariancePopulationYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountEurMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_EUR_MILLION_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountEurMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_EUR_MILLION_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountSourceAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_SOURCE_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountSourceDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_SOURCE_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountUsdMillionAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_USD_MILLION_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleAmountUsdMillionDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_AMOUNT_USD_MILLION_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleCompanyIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleCompanyIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleCurrencyAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENCY_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleCurrencyDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENCY_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleIdAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleIdDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleIsVerifiedAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_VERIFIED_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleIsVerifiedDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_VERIFIED_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleMonthAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MONTH_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleMonthDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MONTH_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleRoundAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ROUND_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleRoundDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ROUND_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMaxAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MAX_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMaxDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MAX_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMinAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MIN_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleValuationGeneratedMinDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALUATION_GENERATED_MIN_DESC",
  LgFundingRoundsByCompanyIdVarianceSampleYearAsc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_ASC",
  LgFundingRoundsByCompanyIdVarianceSampleYearDesc = "LG_FUNDING_ROUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_DESC",
  LgPipelineAsc = "LG_PIPELINE_ASC",
  LgPipelineDesc = "LG_PIPELINE_DESC",
  LgSortAsc = "LG_SORT_ASC",
  LgSortDesc = "LG_SORT_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  LtPipelineAsc = "LT_PIPELINE_ASC",
  LtPipelineDesc = "LT_PIPELINE_DESC",
  MonthsFromLastFundingAsc = "MONTHS_FROM_LAST_FUNDING_ASC",
  MonthsFromLastFundingDesc = "MONTHS_FROM_LAST_FUNDING_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdAveragePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdAveragePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdAverageYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdAverageYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_AVERAGE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdCountAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_COUNT_ASC",
  MtpCompanyfundsByMtpCompanyIdCountDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_COUNT_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdDistinctCountYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_DISTINCT_COUNT_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdMaxYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdMaxYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MAX_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdMinBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdMinBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdMinCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdMinCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdMinCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdMinCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdMinCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdMinCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdMinEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdMinEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdMinFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdMinFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdMinFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdMinFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdMinFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdMinFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdMinFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdMinFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdMinIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMinIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMinMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdMinMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdMinMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMinMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMinMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMinMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMinMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdMinMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdMinNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdMinNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdMinPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdMinPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdMinRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdMinRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdMinRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdMinRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdMinYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdMinYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_MIN_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevPopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdStddevSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_STDDEV_SAMPLE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdSumBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdSumBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdSumCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdSumCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdSumCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdSumCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdSumCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdSumCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdSumEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdSumEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdSumFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdSumFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdSumFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdSumFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdSumFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdSumFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdSumFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdSumFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdSumIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdSumIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdSumMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdSumMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdSumMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdSumMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdSumMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdSumMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdSumMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdSumMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdSumNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdSumNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdSumPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdSumPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdSumRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdSumRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdSumRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdSumRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdSumYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdSumYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_SUM_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdVariancePopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpCompanyIdVarianceSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_COMPANY_ID_VARIANCE_SAMPLE_YOY_GROWTH_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  NameFuzzyAsc = "NAME_FUZZY_ASC",
  NameFuzzyDesc = "NAME_FUZZY_DESC",
  Natural = "NATURAL",
  NotesAsc = "NOTES_ASC",
  NotesDesc = "NOTES_DESC",
  NzrOpportunityEmailCandidatesAverageDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesAverageDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesAverageDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesAverageDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesAverageEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesAverageEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesAverageIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_ID_ASC",
  NzrOpportunityEmailCandidatesAverageIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_ID_DESC",
  NzrOpportunityEmailCandidatesAverageLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesAverageLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesAverageNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_NAME_ASC",
  NzrOpportunityEmailCandidatesAverageNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_NAME_DESC",
  NzrOpportunityEmailCandidatesAverageUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_URL_ASC",
  NzrOpportunityEmailCandidatesAverageUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_AVERAGE_URL_DESC",
  NzrOpportunityEmailCandidatesCountAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_COUNT_ASC",
  NzrOpportunityEmailCandidatesCountDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_COUNT_DESC",
  NzrOpportunityEmailCandidatesDistinctCountDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesDistinctCountDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesDistinctCountEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesDistinctCountLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesDistinctCountNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_NAME_ASC",
  NzrOpportunityEmailCandidatesDistinctCountNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_NAME_DESC",
  NzrOpportunityEmailCandidatesDistinctCountUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_URL_ASC",
  NzrOpportunityEmailCandidatesDistinctCountUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_DISTINCT_COUNT_URL_DESC",
  NzrOpportunityEmailCandidatesMaxDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMaxDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMaxDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesMaxDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesMaxEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesMaxEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesMaxIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_ID_ASC",
  NzrOpportunityEmailCandidatesMaxIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_ID_DESC",
  NzrOpportunityEmailCandidatesMaxLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMaxLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMaxNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_NAME_ASC",
  NzrOpportunityEmailCandidatesMaxNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_NAME_DESC",
  NzrOpportunityEmailCandidatesMaxUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_URL_ASC",
  NzrOpportunityEmailCandidatesMaxUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MAX_URL_DESC",
  NzrOpportunityEmailCandidatesMinDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMinDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMinDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesMinDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesMinEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesMinEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesMinIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_ID_ASC",
  NzrOpportunityEmailCandidatesMinIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_ID_DESC",
  NzrOpportunityEmailCandidatesMinLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesMinLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesMinNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_NAME_ASC",
  NzrOpportunityEmailCandidatesMinNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_NAME_DESC",
  NzrOpportunityEmailCandidatesMinUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_URL_ASC",
  NzrOpportunityEmailCandidatesMinUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_MIN_URL_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesStddevPopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesStddevPopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesStddevSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesStddevSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesStddevSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesStddevSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesStddevSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesStddevSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesStddevSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesStddevSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_STDDEV_SAMPLE_URL_DESC",
  NzrOpportunityEmailCandidatesSumDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesSumDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesSumDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesSumDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesSumEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesSumEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesSumIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_ID_ASC",
  NzrOpportunityEmailCandidatesSumIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_ID_DESC",
  NzrOpportunityEmailCandidatesSumLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesSumLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesSumNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_NAME_ASC",
  NzrOpportunityEmailCandidatesSumNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_NAME_DESC",
  NzrOpportunityEmailCandidatesSumUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_URL_ASC",
  NzrOpportunityEmailCandidatesSumUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_SUM_URL_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesVariancePopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesVariancePopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesVarianceSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesVarianceSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_VARIANCE_SAMPLE_URL_DESC",
  NzrSentimentFormsByCompanyIdAverageAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdAverageAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdAverageCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdAverageCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdAverageCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdAverageCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdAverageCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdAverageCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdAverageCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdAverageCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdAverageCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdAverageCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdAverageDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_DELETED_ASC",
  NzrSentimentFormsByCompanyIdAverageDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_DELETED_DESC",
  NzrSentimentFormsByCompanyIdAverageExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdAverageExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdAverageFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdAverageFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdAverageFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdAverageFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdAverageFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdAverageFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdAverageIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  NzrSentimentFormsByCompanyIdAverageIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  NzrSentimentFormsByCompanyIdAverageMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_METADATA_ASC",
  NzrSentimentFormsByCompanyIdAverageMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_METADATA_DESC",
  NzrSentimentFormsByCompanyIdAverageResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdAverageResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdAverageSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdAverageSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdAverageStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_STATUS_ASC",
  NzrSentimentFormsByCompanyIdAverageStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_STATUS_DESC",
  NzrSentimentFormsByCompanyIdAverageTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TITLE_ASC",
  NzrSentimentFormsByCompanyIdAverageTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TITLE_DESC",
  NzrSentimentFormsByCompanyIdAverageTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdAverageTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdAverageTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TYPE_ASC",
  NzrSentimentFormsByCompanyIdAverageTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_AVERAGE_TYPE_DESC",
  NzrSentimentFormsByCompanyIdCountAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_COUNT_ASC",
  NzrSentimentFormsByCompanyIdCountDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_COUNT_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_METADATA_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_METADATA_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdDistinctCountTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_ASC",
  NzrSentimentFormsByCompanyIdDistinctCountTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_DESC",
  NzrSentimentFormsByCompanyIdMaxAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdMaxAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdMaxCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdMaxCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdMaxCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdMaxCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdMaxCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdMaxCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdMaxCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdMaxCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdMaxCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdMaxCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdMaxDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_DELETED_ASC",
  NzrSentimentFormsByCompanyIdMaxDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_DELETED_DESC",
  NzrSentimentFormsByCompanyIdMaxExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdMaxExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdMaxFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdMaxFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdMaxFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdMaxFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdMaxFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdMaxFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdMaxIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_ID_ASC",
  NzrSentimentFormsByCompanyIdMaxIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_ID_DESC",
  NzrSentimentFormsByCompanyIdMaxMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_METADATA_ASC",
  NzrSentimentFormsByCompanyIdMaxMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_METADATA_DESC",
  NzrSentimentFormsByCompanyIdMaxResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdMaxResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdMaxSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdMaxSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdMaxStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_STATUS_ASC",
  NzrSentimentFormsByCompanyIdMaxStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_STATUS_DESC",
  NzrSentimentFormsByCompanyIdMaxTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TITLE_ASC",
  NzrSentimentFormsByCompanyIdMaxTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TITLE_DESC",
  NzrSentimentFormsByCompanyIdMaxTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdMaxTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdMaxTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TYPE_ASC",
  NzrSentimentFormsByCompanyIdMaxTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MAX_TYPE_DESC",
  NzrSentimentFormsByCompanyIdMinAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdMinAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdMinCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdMinCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdMinCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdMinCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdMinCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdMinCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdMinCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdMinCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdMinCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdMinCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdMinDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_DELETED_ASC",
  NzrSentimentFormsByCompanyIdMinDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_DELETED_DESC",
  NzrSentimentFormsByCompanyIdMinExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdMinExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdMinFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdMinFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdMinFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdMinFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdMinFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdMinFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdMinIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_ID_ASC",
  NzrSentimentFormsByCompanyIdMinIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_ID_DESC",
  NzrSentimentFormsByCompanyIdMinMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_METADATA_ASC",
  NzrSentimentFormsByCompanyIdMinMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_METADATA_DESC",
  NzrSentimentFormsByCompanyIdMinResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdMinResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdMinSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdMinSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdMinStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_STATUS_ASC",
  NzrSentimentFormsByCompanyIdMinStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_STATUS_DESC",
  NzrSentimentFormsByCompanyIdMinTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TITLE_ASC",
  NzrSentimentFormsByCompanyIdMinTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TITLE_DESC",
  NzrSentimentFormsByCompanyIdMinTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdMinTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdMinTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TYPE_ASC",
  NzrSentimentFormsByCompanyIdMinTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_MIN_TYPE_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_METADATA_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_METADATA_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevPopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_ASC",
  NzrSentimentFormsByCompanyIdStddevPopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdStddevSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByCompanyIdStddevSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_DESC",
  NzrSentimentFormsByCompanyIdSumAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdSumAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdSumCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdSumCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdSumCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdSumCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdSumCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdSumCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdSumCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdSumCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdSumCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdSumCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdSumDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_DELETED_ASC",
  NzrSentimentFormsByCompanyIdSumDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_DELETED_DESC",
  NzrSentimentFormsByCompanyIdSumExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdSumExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdSumFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdSumFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdSumFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdSumFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdSumFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdSumFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdSumIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_ID_ASC",
  NzrSentimentFormsByCompanyIdSumIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_ID_DESC",
  NzrSentimentFormsByCompanyIdSumMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_METADATA_ASC",
  NzrSentimentFormsByCompanyIdSumMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_METADATA_DESC",
  NzrSentimentFormsByCompanyIdSumResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdSumResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdSumSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdSumSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdSumStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdSumStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdSumTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TITLE_ASC",
  NzrSentimentFormsByCompanyIdSumTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TITLE_DESC",
  NzrSentimentFormsByCompanyIdSumTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdSumTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdSumTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TYPE_ASC",
  NzrSentimentFormsByCompanyIdSumTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_SUM_TYPE_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_METADATA_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_METADATA_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdVariancePopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_ASC",
  NzrSentimentFormsByCompanyIdVariancePopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByCompanyIdVarianceSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByCompanyIdVarianceSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_DESC",
  NzrUserCompanyTagsByCompanyIdAverageCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdAverageCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdAverageIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  NzrUserCompanyTagsByCompanyIdAverageIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  NzrUserCompanyTagsByCompanyIdAverageTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdAverageTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdAverageUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdAverageUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_AVERAGE_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdCountAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_COUNT_ASC",
  NzrUserCompanyTagsByCompanyIdCountDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_COUNT_DESC",
  NzrUserCompanyTagsByCompanyIdDistinctCountCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdDistinctCountCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdDistinctCountIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  NzrUserCompanyTagsByCompanyIdDistinctCountIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  NzrUserCompanyTagsByCompanyIdDistinctCountTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdDistinctCountTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdDistinctCountUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdDistinctCountUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_DISTINCT_COUNT_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMaxCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMaxCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMaxIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMaxIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMaxTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdMaxTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdMaxUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMaxUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MAX_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMinCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMinCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMinIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMinIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_ID_DESC",
  NzrUserCompanyTagsByCompanyIdMinTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdMinTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdMinUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdMinUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_MIN_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevPopulationUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_POPULATION_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevSampleCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevSampleCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevSampleIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevSampleIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  NzrUserCompanyTagsByCompanyIdStddevSampleTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdStddevSampleTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdStddevSampleUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdStddevSampleUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_STDDEV_SAMPLE_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdSumCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdSumCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdSumIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_ID_ASC",
  NzrUserCompanyTagsByCompanyIdSumIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_ID_DESC",
  NzrUserCompanyTagsByCompanyIdSumTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdSumTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdSumUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdSumUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_SUM_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVariancePopulationUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_POPULATION_USER_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleTagsAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_TAGS_ASC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleTagsDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_TAGS_DESC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_USER_ID_ASC",
  NzrUserCompanyTagsByCompanyIdVarianceSampleUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_COMPANY_ID_VARIANCE_SAMPLE_USER_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RelativeRunwayAsc = "RELATIVE_RUNWAY_ASC",
  RelativeRunwayDesc = "RELATIVE_RUNWAY_DESC",
  RoundAsc = "ROUND_ASC",
  RoundDesc = "ROUND_DESC",
  RunwayEndDateAsc = "RUNWAY_END_DATE_ASC",
  RunwayEndDateDesc = "RUNWAY_END_DATE_DESC",
  RunwayLastUpdateAsc = "RUNWAY_LAST_UPDATE_ASC",
  RunwayLastUpdateDesc = "RUNWAY_LAST_UPDATE_DESC",
  SharingAllowedAsc = "SHARING_ALLOWED_ASC",
  SharingAllowedDesc = "SHARING_ALLOWED_DESC",
  SignalsByCompanyIdAverageCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  SignalsByCompanyIdAverageCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  SignalsByCompanyIdAverageCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_CREATED_UTC_ASC",
  SignalsByCompanyIdAverageCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_CREATED_UTC_DESC",
  SignalsByCompanyIdAverageDeletedAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_DELETED_ASC",
  SignalsByCompanyIdAverageDeletedDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_DELETED_DESC",
  SignalsByCompanyIdAverageIdAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  SignalsByCompanyIdAverageIdDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  SignalsByCompanyIdAverageOutputAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_OUTPUT_ASC",
  SignalsByCompanyIdAverageOutputDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_OUTPUT_DESC",
  SignalsByCompanyIdAverageRuleIdAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_RULE_ID_ASC",
  SignalsByCompanyIdAverageRuleIdDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_RULE_ID_DESC",
  SignalsByCompanyIdAverageValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_AVERAGE_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdAverageValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_AVERAGE_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdCountAsc = "SIGNALS_BY_COMPANY_ID_COUNT_ASC",
  SignalsByCompanyIdCountDesc = "SIGNALS_BY_COMPANY_ID_COUNT_DESC",
  SignalsByCompanyIdDistinctCountCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  SignalsByCompanyIdDistinctCountCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  SignalsByCompanyIdDistinctCountCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SignalsByCompanyIdDistinctCountCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SignalsByCompanyIdDistinctCountDeletedAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_ASC",
  SignalsByCompanyIdDistinctCountDeletedDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_DELETED_DESC",
  SignalsByCompanyIdDistinctCountIdAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  SignalsByCompanyIdDistinctCountIdDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  SignalsByCompanyIdDistinctCountOutputAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_OUTPUT_ASC",
  SignalsByCompanyIdDistinctCountOutputDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_OUTPUT_DESC",
  SignalsByCompanyIdDistinctCountRuleIdAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_RULE_ID_ASC",
  SignalsByCompanyIdDistinctCountRuleIdDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_RULE_ID_DESC",
  SignalsByCompanyIdDistinctCountValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdDistinctCountValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_DISTINCT_COUNT_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdMaxCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  SignalsByCompanyIdMaxCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  SignalsByCompanyIdMaxCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_MAX_CREATED_UTC_ASC",
  SignalsByCompanyIdMaxCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_MAX_CREATED_UTC_DESC",
  SignalsByCompanyIdMaxDeletedAsc = "SIGNALS_BY_COMPANY_ID_MAX_DELETED_ASC",
  SignalsByCompanyIdMaxDeletedDesc = "SIGNALS_BY_COMPANY_ID_MAX_DELETED_DESC",
  SignalsByCompanyIdMaxIdAsc = "SIGNALS_BY_COMPANY_ID_MAX_ID_ASC",
  SignalsByCompanyIdMaxIdDesc = "SIGNALS_BY_COMPANY_ID_MAX_ID_DESC",
  SignalsByCompanyIdMaxOutputAsc = "SIGNALS_BY_COMPANY_ID_MAX_OUTPUT_ASC",
  SignalsByCompanyIdMaxOutputDesc = "SIGNALS_BY_COMPANY_ID_MAX_OUTPUT_DESC",
  SignalsByCompanyIdMaxRuleIdAsc = "SIGNALS_BY_COMPANY_ID_MAX_RULE_ID_ASC",
  SignalsByCompanyIdMaxRuleIdDesc = "SIGNALS_BY_COMPANY_ID_MAX_RULE_ID_DESC",
  SignalsByCompanyIdMaxValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_MAX_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdMaxValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_MAX_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdMinCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  SignalsByCompanyIdMinCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  SignalsByCompanyIdMinCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_MIN_CREATED_UTC_ASC",
  SignalsByCompanyIdMinCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_MIN_CREATED_UTC_DESC",
  SignalsByCompanyIdMinDeletedAsc = "SIGNALS_BY_COMPANY_ID_MIN_DELETED_ASC",
  SignalsByCompanyIdMinDeletedDesc = "SIGNALS_BY_COMPANY_ID_MIN_DELETED_DESC",
  SignalsByCompanyIdMinIdAsc = "SIGNALS_BY_COMPANY_ID_MIN_ID_ASC",
  SignalsByCompanyIdMinIdDesc = "SIGNALS_BY_COMPANY_ID_MIN_ID_DESC",
  SignalsByCompanyIdMinOutputAsc = "SIGNALS_BY_COMPANY_ID_MIN_OUTPUT_ASC",
  SignalsByCompanyIdMinOutputDesc = "SIGNALS_BY_COMPANY_ID_MIN_OUTPUT_DESC",
  SignalsByCompanyIdMinRuleIdAsc = "SIGNALS_BY_COMPANY_ID_MIN_RULE_ID_ASC",
  SignalsByCompanyIdMinRuleIdDesc = "SIGNALS_BY_COMPANY_ID_MIN_RULE_ID_DESC",
  SignalsByCompanyIdMinValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_MIN_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdMinValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_MIN_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdStddevPopulationCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  SignalsByCompanyIdStddevPopulationCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  SignalsByCompanyIdStddevPopulationCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SignalsByCompanyIdStddevPopulationCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SignalsByCompanyIdStddevPopulationDeletedAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_ASC",
  SignalsByCompanyIdStddevPopulationDeletedDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_DELETED_DESC",
  SignalsByCompanyIdStddevPopulationIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  SignalsByCompanyIdStddevPopulationIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  SignalsByCompanyIdStddevPopulationOutputAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_OUTPUT_ASC",
  SignalsByCompanyIdStddevPopulationOutputDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_OUTPUT_DESC",
  SignalsByCompanyIdStddevPopulationRuleIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_RULE_ID_ASC",
  SignalsByCompanyIdStddevPopulationRuleIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_RULE_ID_DESC",
  SignalsByCompanyIdStddevPopulationValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdStddevPopulationValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_POPULATION_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdStddevSampleCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  SignalsByCompanyIdStddevSampleCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  SignalsByCompanyIdStddevSampleCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SignalsByCompanyIdStddevSampleCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SignalsByCompanyIdStddevSampleDeletedAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_ASC",
  SignalsByCompanyIdStddevSampleDeletedDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_DELETED_DESC",
  SignalsByCompanyIdStddevSampleIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  SignalsByCompanyIdStddevSampleIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  SignalsByCompanyIdStddevSampleOutputAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_OUTPUT_ASC",
  SignalsByCompanyIdStddevSampleOutputDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_OUTPUT_DESC",
  SignalsByCompanyIdStddevSampleRuleIdAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_RULE_ID_ASC",
  SignalsByCompanyIdStddevSampleRuleIdDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_RULE_ID_DESC",
  SignalsByCompanyIdStddevSampleValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdStddevSampleValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_STDDEV_SAMPLE_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdSumCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  SignalsByCompanyIdSumCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  SignalsByCompanyIdSumCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_SUM_CREATED_UTC_ASC",
  SignalsByCompanyIdSumCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_SUM_CREATED_UTC_DESC",
  SignalsByCompanyIdSumDeletedAsc = "SIGNALS_BY_COMPANY_ID_SUM_DELETED_ASC",
  SignalsByCompanyIdSumDeletedDesc = "SIGNALS_BY_COMPANY_ID_SUM_DELETED_DESC",
  SignalsByCompanyIdSumIdAsc = "SIGNALS_BY_COMPANY_ID_SUM_ID_ASC",
  SignalsByCompanyIdSumIdDesc = "SIGNALS_BY_COMPANY_ID_SUM_ID_DESC",
  SignalsByCompanyIdSumOutputAsc = "SIGNALS_BY_COMPANY_ID_SUM_OUTPUT_ASC",
  SignalsByCompanyIdSumOutputDesc = "SIGNALS_BY_COMPANY_ID_SUM_OUTPUT_DESC",
  SignalsByCompanyIdSumRuleIdAsc = "SIGNALS_BY_COMPANY_ID_SUM_RULE_ID_ASC",
  SignalsByCompanyIdSumRuleIdDesc = "SIGNALS_BY_COMPANY_ID_SUM_RULE_ID_DESC",
  SignalsByCompanyIdSumValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_SUM_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdSumValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_SUM_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdVariancePopulationCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  SignalsByCompanyIdVariancePopulationCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  SignalsByCompanyIdVariancePopulationCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SignalsByCompanyIdVariancePopulationCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SignalsByCompanyIdVariancePopulationDeletedAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_ASC",
  SignalsByCompanyIdVariancePopulationDeletedDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_DELETED_DESC",
  SignalsByCompanyIdVariancePopulationIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  SignalsByCompanyIdVariancePopulationIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  SignalsByCompanyIdVariancePopulationOutputAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_OUTPUT_ASC",
  SignalsByCompanyIdVariancePopulationOutputDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_OUTPUT_DESC",
  SignalsByCompanyIdVariancePopulationRuleIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_RULE_ID_ASC",
  SignalsByCompanyIdVariancePopulationRuleIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_RULE_ID_DESC",
  SignalsByCompanyIdVariancePopulationValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdVariancePopulationValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_POPULATION_VALID_UNTIL_UTC_DESC",
  SignalsByCompanyIdVarianceSampleCompanyIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  SignalsByCompanyIdVarianceSampleCompanyIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  SignalsByCompanyIdVarianceSampleCreatedUtcAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SignalsByCompanyIdVarianceSampleCreatedUtcDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SignalsByCompanyIdVarianceSampleDeletedAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_ASC",
  SignalsByCompanyIdVarianceSampleDeletedDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_DELETED_DESC",
  SignalsByCompanyIdVarianceSampleIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  SignalsByCompanyIdVarianceSampleIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  SignalsByCompanyIdVarianceSampleOutputAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_OUTPUT_ASC",
  SignalsByCompanyIdVarianceSampleOutputDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_OUTPUT_DESC",
  SignalsByCompanyIdVarianceSampleRuleIdAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_RULE_ID_ASC",
  SignalsByCompanyIdVarianceSampleRuleIdDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_RULE_ID_DESC",
  SignalsByCompanyIdVarianceSampleValidUntilUtcAsc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALID_UNTIL_UTC_ASC",
  SignalsByCompanyIdVarianceSampleValidUntilUtcDesc = "SIGNALS_BY_COMPANY_ID_VARIANCE_SAMPLE_VALID_UNTIL_UTC_DESC",
  SrcCompaniesByCompanyIdAverageCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdAverageCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdAverageCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdAverageCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdAverageIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_ID_ASC",
  SrcCompaniesByCompanyIdAverageIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_ID_DESC",
  SrcCompaniesByCompanyIdAverageLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdAverageLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdAverageSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdAverageSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdAverageSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdAverageSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdCountAsc = "SRC_COMPANIES_BY_COMPANY_ID_COUNT_ASC",
  SrcCompaniesByCompanyIdCountDesc = "SRC_COMPANIES_BY_COMPANY_ID_COUNT_DESC",
  SrcCompaniesByCompanyIdDistinctCountCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdDistinctCountCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdDistinctCountCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdDistinctCountCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdDistinctCountIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  SrcCompaniesByCompanyIdDistinctCountIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  SrcCompaniesByCompanyIdDistinctCountLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdDistinctCountLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdDistinctCountSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdDistinctCountSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdDistinctCountSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdDistinctCountSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdMaxCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdMaxCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdMaxCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdMaxCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdMaxIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_ID_ASC",
  SrcCompaniesByCompanyIdMaxIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_ID_DESC",
  SrcCompaniesByCompanyIdMaxLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdMaxLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdMaxSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdMaxSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdMaxSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdMaxSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_MAX_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdMinCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdMinCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdMinCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdMinCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdMinIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_ID_ASC",
  SrcCompaniesByCompanyIdMinIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_ID_DESC",
  SrcCompaniesByCompanyIdMinLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdMinLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdMinSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdMinSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdMinSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdMinSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_MIN_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdStddevPopulationCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdStddevPopulationCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdStddevPopulationCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdStddevPopulationCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdStddevPopulationIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  SrcCompaniesByCompanyIdStddevPopulationIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  SrcCompaniesByCompanyIdStddevPopulationLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdStddevPopulationLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdStddevPopulationSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdStddevPopulationSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdStddevPopulationSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdStddevPopulationSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdStddevSampleCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdStddevSampleCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdStddevSampleCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdStddevSampleCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdStddevSampleIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcCompaniesByCompanyIdStddevSampleIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcCompaniesByCompanyIdStddevSampleLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdStddevSampleLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdStddevSampleSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdStddevSampleSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdStddevSampleSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdStddevSampleSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdSumCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdSumCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdSumCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdSumCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdSumIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_ID_ASC",
  SrcCompaniesByCompanyIdSumIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_ID_DESC",
  SrcCompaniesByCompanyIdSumLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdSumLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdSumSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdSumSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdSumSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdSumSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_SUM_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdVariancePopulationCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdVariancePopulationCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdVariancePopulationCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdVariancePopulationCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdVariancePopulationIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcCompaniesByCompanyIdVariancePopulationIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcCompaniesByCompanyIdVariancePopulationLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdVariancePopulationLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdVariancePopulationSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdVariancePopulationSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdVariancePopulationSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdVariancePopulationSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcCompaniesByCompanyIdVarianceSampleCompanyIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  SrcCompaniesByCompanyIdVarianceSampleCompanyIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  SrcCompaniesByCompanyIdVarianceSampleCreatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcCompaniesByCompanyIdVarianceSampleCreatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcCompaniesByCompanyIdVarianceSampleIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcCompaniesByCompanyIdVarianceSampleIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcCompaniesByCompanyIdVarianceSampleLastUpdatedUtcAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompaniesByCompanyIdVarianceSampleLastUpdatedUtcDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompaniesByCompanyIdVarianceSampleSourceIdAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcCompaniesByCompanyIdVarianceSampleSourceIdDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcCompaniesByCompanyIdVarianceSampleSourceTypeAsc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompaniesByCompanyIdVarianceSampleSourceTypeDesc = "SRC_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  SrPipelineAsc = "SR_PIPELINE_ASC",
  SrPipelineDesc = "SR_PIPELINE_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TargetFundAsc = "TARGET_FUND_ASC",
  TargetFundDesc = "TARGET_FUND_DESC",
  TargetSortAsc = "TARGET_SORT_ASC",
  TargetSortDesc = "TARGET_SORT_DESC",
  TeamAsc = "TEAM_ASC",
  TeamDesc = "TEAM_DESC",
  TechnologiesAsc = "TECHNOLOGIES_ASC",
  TechnologiesDesc = "TECHNOLOGIES_DESC",
  TechStackPredictleadsAsc = "TECH_STACK_PREDICTLEADS_ASC",
  TechStackPredictleadsDesc = "TECH_STACK_PREDICTLEADS_DESC",
  TimeFromLastFundingAsc = "TIME_FROM_LAST_FUNDING_ASC",
  TimeFromLastFundingDesc = "TIME_FROM_LAST_FUNDING_DESC",
  TotalFundingAsc = "TOTAL_FUNDING_ASC",
  TotalFundingDesc = "TOTAL_FUNDING_DESC",
  TotalFundingSourceAsc = "TOTAL_FUNDING_SOURCE_ASC",
  TotalFundingSourceDesc = "TOTAL_FUNDING_SOURCE_DESC",
  TrelloCardAsc = "TRELLO_CARD_ASC",
  TrelloCardDesc = "TRELLO_CARD_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  WebsiteDomainAsc = "WEBSITE_DOMAIN_ASC",
  WebsiteDomainDesc = "WEBSITE_DOMAIN_DESC",
  WebsiteDomainFuzzyAsc = "WEBSITE_DOMAIN_FUZZY_ASC",
  WebsiteDomainFuzzyDesc = "WEBSITE_DOMAIN_FUZZY_DESC",
  WebsiteTrafficChartAsc = "WEBSITE_TRAFFIC_CHART_ASC",
  WebsiteTrafficChartDesc = "WEBSITE_TRAFFIC_CHART_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type LgCompany = Node & {
  __typename?: "LgCompany";
  about: Scalars["String"]["output"];
  address: Scalars["String"]["output"];
  amount?: Maybe<Scalars["String"]["output"]>;
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  badges?: Maybe<Scalars["JSON"]["output"]>;
  calculationStatus: Scalars["String"]["output"];
  cardLastActivity?: Maybe<Scalars["Datetime"]["output"]>;
  cardName?: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  companyStatus: Scalars["String"]["output"];
  continent: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  currency: Scalars["String"]["output"];
  customOrder?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `DealroomCompany`. */
  dealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyId: LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyConnection;
  dealroomPath?: Maybe<Scalars["String"]["output"]>;
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  dealroomUrl?: Maybe<Scalars["String"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  draft?: Maybe<Scalars["Boolean"]["output"]>;
  employees?: Maybe<Scalars["String"]["output"]>;
  employeesChart?: Maybe<Scalars["JSON"]["output"]>;
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  fundingDate?: Maybe<Scalars["String"]["output"]>;
  fundingRounds?: Maybe<Scalars["JSON"]["output"]>;
  growthChart?: Maybe<Scalars["JSON"]["output"]>;
  hasPromisingFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasStrongFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasSuperFounder?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["UUID"]["output"];
  image: Scalars["String"]["output"];
  industries?: Maybe<Scalars["JSON"]["output"]>;
  investors?: Maybe<Scalars["JSON"]["output"]>;
  isActivelyFundraising: Scalars["Boolean"]["output"];
  isBasecampFunded?: Maybe<Scalars["Boolean"]["output"]>;
  isFoundingLocation?: Maybe<Scalars["Boolean"]["output"]>;
  isNpa?: Maybe<Scalars["Boolean"]["output"]>;
  isOpCompany: Scalars["Boolean"]["output"];
  isTrCompany: Scalars["Boolean"]["output"];
  last3Sentiment?: Maybe<Scalars["JSON"]["output"]>;
  lastCalculatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  lastFundingDate?: Maybe<Scalars["Datetime"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  latestExcitementDate?: Maybe<Scalars["Datetime"]["output"]>;
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyComment`. */
  lgCompanyCommentsByCompanyId: LgCompanyCommentsConnection;
  /** Reads and enables pagination through a set of `LgCompanyFile`. */
  lgCompanyFilesByCompanyId: LgCompanyFilesConnection;
  /** Reads and enables pagination through a set of `LgCompanyFlag`. */
  lgCompanyFlagsByCompanyId: LgCompanyFlagsConnection;
  /** Reads and enables pagination through a set of `LgCompanyInvestor`. */
  lgCompanyInvestorsByCompanyId: LgCompanyInvestorsConnection;
  /** Reads and enables pagination through a set of `LgCompanyMetric`. */
  lgCompanyMetricsByCompanyId: LgCompanyMetricsConnection;
  /** Reads and enables pagination through a set of `LgCompanyNote`. */
  lgCompanyNotesByCompanyId: LgCompanyNotesConnection;
  /** Reads and enables pagination through a set of `LgCompanyTeam`. */
  lgCompanyTeamsByCompanyId: LgCompanyTeamsConnection;
  /** Reads and enables pagination through a set of `LgFile`. */
  lgFilesByLgCompanyFileCompanyIdAndFileId: LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgFounder`. */
  lgFoundersByLgCompanyTeamCompanyIdAndMemberId: LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgFounder`. */
  lgFoundersByNzrSentimentFormCompanyIdAndFounderId: LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgFundingRound`. */
  lgFundingRoundsByCompanyId: LgFundingRoundsConnection;
  /** Reads and enables pagination through a set of `LgInvestor`. */
  lgInvestorsByLgCompanyInvestorCompanyIdAndInvestorId: LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByLgCompanyCommentCompanyIdAndCreatedBy: LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByLgCompanyFlagCompanyIdAndCreatedBy: LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByLgCompanyNoteCompanyIdAndCreatedBy: LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrSentimentFormCompanyIdAndCreatedById: LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrUserCompanyTagCompanyIdAndUserId: LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyConnection;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpCompanyId: MtpCompanyfundsConnection;
  name: Scalars["String"]["output"];
  /** Fuzzy-text search ranking when filtered by `name`. */
  nameFuzzyScore?: Maybe<Scalars["Float"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
  /** Reads and enables pagination through a set of `NzrOpportunityEmail`. */
  nzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailId: LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyConnection;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCompanyId: NzrSentimentFormsConnection;
  /** Reads and enables pagination through a set of `NzrUserCompanyTag`. */
  nzrUserCompanyTagsByCompanyId: NzrUserCompanyTagsConnection;
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyId: LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundId: LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyConnection;
  relativeRunway?: Maybe<Scalars["String"]["output"]>;
  round?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `Rule`. */
  rulesBySignalCompanyIdAndRuleId: LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyConnection;
  runwayEndDate?: Maybe<Scalars["Date"]["output"]>;
  runwayLastUpdate?: Maybe<Scalars["Date"]["output"]>;
  sharingAllowed?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsByCompanyId: SignalsConnection;
  /** Reads and enables pagination through a set of `SrcCompany`. */
  srcCompaniesByCompanyId: SrcCompaniesConnection;
  tagline: Scalars["String"]["output"];
  tags?: Maybe<Scalars["JSON"]["output"]>;
  targetFund: Scalars["String"]["output"];
  team?: Maybe<Scalars["JSON"]["output"]>;
  techStackPredictleads?: Maybe<Scalars["JSON"]["output"]>;
  technologies?: Maybe<Scalars["JSON"]["output"]>;
  timeFromLastFunding?: Maybe<Interval>;
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  totalFundingSource?: Maybe<Scalars["String"]["output"]>;
  trelloCard?: Maybe<Scalars["JSON"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  websiteDomain?: Maybe<Scalars["String"]["output"]>;
  /** Fuzzy-text search ranking when filtered by `websiteDomain`. */
  websiteDomainFuzzyScore?: Maybe<Scalars["Float"]["output"]>;
  websiteTrafficChart?: Maybe<Scalars["JSON"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyCondition>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompaniesOrderBy>>;
};

export type LgCompanyLgCompanyCommentsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCommentCondition>;
  filter?: InputMaybe<LgCompanyCommentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCommentsOrderBy>>;
};

export type LgCompanyLgCompanyFilesByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFileCondition>;
  filter?: InputMaybe<LgCompanyFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFilesOrderBy>>;
};

export type LgCompanyLgCompanyFlagsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFlagCondition>;
  filter?: InputMaybe<LgCompanyFlagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFlagsOrderBy>>;
};

export type LgCompanyLgCompanyInvestorsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyInvestorCondition>;
  filter?: InputMaybe<LgCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyInvestorsOrderBy>>;
};

export type LgCompanyLgCompanyMetricsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyMetricCondition>;
  filter?: InputMaybe<LgCompanyMetricFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyMetricsOrderBy>>;
};

export type LgCompanyLgCompanyNotesByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyNoteCondition>;
  filter?: InputMaybe<LgCompanyNoteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyNotesOrderBy>>;
};

export type LgCompanyLgCompanyTeamsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyTeamCondition>;
  filter?: InputMaybe<LgCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyTeamsOrderBy>>;
};

export type LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFileCondition>;
  filter?: InputMaybe<LgFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFilesOrderBy>>;
};

export type LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFounderCondition>;
  filter?: InputMaybe<LgFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFoundersOrderBy>>;
};

export type LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFounderCondition>;
  filter?: InputMaybe<LgFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFoundersOrderBy>>;
};

export type LgCompanyLgFundingRoundsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundCondition>;
  filter?: InputMaybe<LgFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundsOrderBy>>;
};

export type LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgInvestorCondition>;
  filter?: InputMaybe<LgInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgInvestorsOrderBy>>;
};

export type LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgCompanyMtpCompanyfundsByMtpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

export type LgCompanyNzrOpportunityEmailCandidatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
  filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
};

export type LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCondition>;
  filter?: InputMaybe<NzrOpportunityEmailFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailsOrderBy>>;
};

export type LgCompanyNzrSentimentFormsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

export type LgCompanyNzrUserCompanyTagsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrUserCompanyTagCondition>;
  filter?: InputMaybe<NzrUserCompanyTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrUserCompanyTagsOrderBy>>;
};

export type LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type LgCompanyRulesBySignalCompanyIdAndRuleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RuleCondition>;
  filter?: InputMaybe<RuleFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RulesOrderBy>>;
};

export type LgCompanySignalsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type LgCompanySrcCompaniesByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type LgCompanyAggregates = {
  __typename?: "LgCompanyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyVarianceSampleAggregates>;
};

export type LgCompanyAverageAggregates = {
  __typename?: "LgCompanyAverageAggregates";
  /** Mean average of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgCompanyCategory` values. */
export type LgCompanyCategoriesConnection = {
  __typename?: "LgCompanyCategoriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyCategoryAggregates>;
  /** A list of edges which contains the `LgCompanyCategory` and cursor to aid in pagination. */
  edges: Array<LgCompanyCategoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyCategoryAggregates>>;
  /** A list of `LgCompanyCategory` objects. */
  nodes: Array<LgCompanyCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyCategory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyCategory` values. */
export type LgCompanyCategoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyCategoryGroupBy>;
  having?: InputMaybe<LgCompanyCategoryHavingInput>;
};

/** A `LgCompanyCategory` edge in the connection. */
export type LgCompanyCategoriesEdge = {
  __typename?: "LgCompanyCategoriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyCategory` at the end of the edge. */
  node: LgCompanyCategory;
};

/** Methods to use when ordering `LgCompanyCategory`. */
export enum LgCompanyCategoriesOrderBy {
  CategoriesAsc = "CATEGORIES_ASC",
  CategoriesDesc = "CATEGORIES_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
}

export type LgCompanyCategory = {
  __typename?: "LgCompanyCategory";
  categories?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type LgCompanyCategoryAggregates = {
  __typename?: "LgCompanyCategoryAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyCategoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `LgCompanyCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyCategoryCondition = {
  /** Checks for equality with the object’s `categories` field. */
  categories?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgCompanyCategoryDistinctCountAggregates = {
  __typename?: "LgCompanyCategoryDistinctCountAggregates";
  /** Distinct count of categories across the matching connection */
  categories?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyCategory` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyCategoryFilter>>;
  /** Filter by the object’s `categories` field. */
  categories?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyCategoryFilter>>;
};

/** Grouping methods for `LgCompanyCategory` for usage during aggregation. */
export enum LgCompanyCategoryGroupBy {
  Categories = "CATEGORIES",
  Name = "NAME",
}

/** Conditions for `LgCompanyCategory` aggregates. */
export type LgCompanyCategoryHavingInput = {
  AND?: InputMaybe<Array<LgCompanyCategoryHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyCategoryHavingInput>>;
};

export type LgCompanyComment = Node & {
  __typename?: "LgCompanyComment";
  /** Reads a single `LgCompany` that is related to this `LgCompanyComment`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  createdBy?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `LgCompanyComment`. */
  lgMemberByCreatedBy?: Maybe<LgMember>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  text: Scalars["String"]["output"];
};

export type LgCompanyCommentAggregates = {
  __typename?: "LgCompanyCommentAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyCommentDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgCompanyComment` object types. */
export type LgCompanyCommentAggregatesFilter = {
  /** Distinct count aggregate over matching `LgCompanyComment` objects. */
  distinctCount?: InputMaybe<LgCompanyCommentDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyComment` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyCommentFilter>;
};

/**
 * A condition to be used against `LgCompanyComment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyCommentCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `text` field. */
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgCompanyCommentDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  createdBy?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  metadata?: InputMaybe<BigIntFilter>;
  text?: InputMaybe<BigIntFilter>;
};

export type LgCompanyCommentDistinctCountAggregates = {
  __typename?: "LgCompanyCommentDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of text across the matching connection */
  text?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyComment` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyCommentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyCommentFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgMemberByCreatedBy` relation. */
  lgMemberByCreatedBy?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberByCreatedBy` exists. */
  lgMemberByCreatedByExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyCommentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyCommentFilter>>;
  /** Filter by the object’s `text` field. */
  text?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgCompanyComment` for usage during aggregation. */
export enum LgCompanyCommentGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedBy = "CREATED_BY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  Metadata = "METADATA",
  Text = "TEXT",
}

export type LgCompanyCommentHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgCompanyComment` aggregates. */
export type LgCompanyCommentHavingInput = {
  AND?: InputMaybe<Array<LgCompanyCommentHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyCommentHavingInput>>;
  average?: InputMaybe<LgCompanyCommentHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyCommentHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyCommentHavingMaxInput>;
  min?: InputMaybe<LgCompanyCommentHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyCommentHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyCommentHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyCommentHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyCommentHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyCommentHavingVarianceSampleInput>;
};

export type LgCompanyCommentHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyCommentHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompanyComment` values. */
export type LgCompanyCommentsConnection = {
  __typename?: "LgCompanyCommentsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyCommentAggregates>;
  /** A list of edges which contains the `LgCompanyComment` and cursor to aid in pagination. */
  edges: Array<LgCompanyCommentsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyCommentAggregates>>;
  /** A list of `LgCompanyComment` objects. */
  nodes: Array<LgCompanyComment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyComment` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyComment` values. */
export type LgCompanyCommentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyCommentGroupBy>;
  having?: InputMaybe<LgCompanyCommentHavingInput>;
};

/** A `LgCompanyComment` edge in the connection. */
export type LgCompanyCommentsEdge = {
  __typename?: "LgCompanyCommentsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyComment` at the end of the edge. */
  node: LgCompanyComment;
};

/** Methods to use when ordering `LgCompanyComment`. */
export enum LgCompanyCommentsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TextAsc = "TEXT_ASC",
  TextDesc = "TEXT_DESC",
}

/**
 * A condition to be used against `LgCompany` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LgCompanyCondition = {
  /** Checks for equality with the object’s `about` field. */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `calculationStatus` field. */
  calculationStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cardLastActivity` field. */
  cardLastActivity?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `cardName` field. */
  cardName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `continent` field. */
  continent?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealroomPath` field. */
  dealroomPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalCompleteness` field. */
  dealroomSignalCompleteness?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalGrowthRate` field. */
  dealroomSignalGrowthRate?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalRating` field. */
  dealroomSignalRating?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalTeamStrength` field. */
  dealroomSignalTeamStrength?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalTiming` field. */
  dealroomSignalTiming?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomUrl` field. */
  dealroomUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `draft` field. */
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `employees` field. */
  employees?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `fundingDate` field. */
  fundingDate?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fundingRounds` field. */
  fundingRounds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `growthChart` field. */
  growthChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `industries` field. */
  industries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investors` field. */
  investors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `isActivelyFundraising` field. */
  isActivelyFundraising?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isBasecampFunded` field. */
  isBasecampFunded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isFoundingLocation` field. */
  isFoundingLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isNpa` field. */
  isNpa?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isOpCompany` field. */
  isOpCompany?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isTrCompany` field. */
  isTrCompany?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `last3Sentiment` field. */
  last3Sentiment?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `lastCalculatedUtc` field. */
  lastCalculatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `latestExcitement` field. */
  latestExcitement?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `latestExcitementDate` field. */
  latestExcitementDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `launchYear` field. */
  launchYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `monthsFromLastFunding` field. */
  monthsFromLastFunding?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `relativeRunway` field. */
  relativeRunway?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `round` field. */
  round?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `runwayEndDate` field. */
  runwayEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `runwayLastUpdate` field. */
  runwayLastUpdate?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `sharingAllowed` field. */
  sharingAllowed?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `targetFund` field. */
  targetFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `team` field. */
  team?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `technologies` field. */
  technologies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `timeFromLastFunding` field. */
  timeFromLastFunding?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloCard` field. */
  trelloCard?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteDomain` field. */
  websiteDomain?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTrafficChart` field. */
  websiteTrafficChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `LgCompanyCopy` values. */
export type LgCompanyCopiesConnection = {
  __typename?: "LgCompanyCopiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyCopyAggregates>;
  /** A list of edges which contains the `LgCompanyCopy` and cursor to aid in pagination. */
  edges: Array<LgCompanyCopiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyCopyAggregates>>;
  /** A list of `LgCompanyCopy` objects. */
  nodes: Array<LgCompanyCopy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyCopy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyCopy` values. */
export type LgCompanyCopiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyCopyGroupBy>;
  having?: InputMaybe<LgCompanyCopyHavingInput>;
};

/** A `LgCompanyCopy` edge in the connection. */
export type LgCompanyCopiesEdge = {
  __typename?: "LgCompanyCopiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyCopy` at the end of the edge. */
  node: LgCompanyCopy;
};

/** Methods to use when ordering `LgCompanyCopy`. */
export enum LgCompanyCopiesOrderBy {
  AboutAsc = "ABOUT_ASC",
  AboutDesc = "ABOUT_DESC",
  AddressAsc = "ADDRESS_ASC",
  AddressDesc = "ADDRESS_DESC",
  AmountAsc = "AMOUNT_ASC",
  AmountDesc = "AMOUNT_DESC",
  AmountUsdMillionAsc = "AMOUNT_USD_MILLION_ASC",
  AmountUsdMillionDesc = "AMOUNT_USD_MILLION_DESC",
  CalculationStatusAsc = "CALCULATION_STATUS_ASC",
  CalculationStatusDesc = "CALCULATION_STATUS_DESC",
  CardLastActivityAsc = "CARD_LAST_ACTIVITY_ASC",
  CardLastActivityDesc = "CARD_LAST_ACTIVITY_DESC",
  CardNameAsc = "CARD_NAME_ASC",
  CardNameDesc = "CARD_NAME_DESC",
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyStatusAsc = "COMPANY_STATUS_ASC",
  CompanyStatusDesc = "COMPANY_STATUS_DESC",
  ContinentAsc = "CONTINENT_ASC",
  ContinentDesc = "CONTINENT_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  DealroomPathAsc = "DEALROOM_PATH_ASC",
  DealroomPathDesc = "DEALROOM_PATH_DESC",
  DealroomSignalCompletenessAsc = "DEALROOM_SIGNAL_COMPLETENESS_ASC",
  DealroomSignalCompletenessDesc = "DEALROOM_SIGNAL_COMPLETENESS_DESC",
  DealroomSignalGrowthRateAsc = "DEALROOM_SIGNAL_GROWTH_RATE_ASC",
  DealroomSignalGrowthRateDesc = "DEALROOM_SIGNAL_GROWTH_RATE_DESC",
  DealroomSignalRatingAsc = "DEALROOM_SIGNAL_RATING_ASC",
  DealroomSignalRatingDesc = "DEALROOM_SIGNAL_RATING_DESC",
  DealroomSignalTeamStrengthAsc = "DEALROOM_SIGNAL_TEAM_STRENGTH_ASC",
  DealroomSignalTeamStrengthDesc = "DEALROOM_SIGNAL_TEAM_STRENGTH_DESC",
  DealroomSignalTimingAsc = "DEALROOM_SIGNAL_TIMING_ASC",
  DealroomSignalTimingDesc = "DEALROOM_SIGNAL_TIMING_DESC",
  DealroomUrlAsc = "DEALROOM_URL_ASC",
  DealroomUrlDesc = "DEALROOM_URL_DESC",
  EmployeesAsc = "EMPLOYEES_ASC",
  EmployeesChartAsc = "EMPLOYEES_CHART_ASC",
  EmployeesChartDesc = "EMPLOYEES_CHART_DESC",
  EmployeesDesc = "EMPLOYEES_DESC",
  EmployeesLatestAsc = "EMPLOYEES_LATEST_ASC",
  EmployeesLatestDesc = "EMPLOYEES_LATEST_DESC",
  FundingDateAsc = "FUNDING_DATE_ASC",
  FundingDateDesc = "FUNDING_DATE_DESC",
  FundingRoundsAsc = "FUNDING_ROUNDS_ASC",
  FundingRoundsDesc = "FUNDING_ROUNDS_DESC",
  GrowthChartAsc = "GROWTH_CHART_ASC",
  GrowthChartDesc = "GROWTH_CHART_DESC",
  HasPromisingFounderAsc = "HAS_PROMISING_FOUNDER_ASC",
  HasPromisingFounderDesc = "HAS_PROMISING_FOUNDER_DESC",
  HasStrongFounderAsc = "HAS_STRONG_FOUNDER_ASC",
  HasStrongFounderDesc = "HAS_STRONG_FOUNDER_DESC",
  HasSuperFounderAsc = "HAS_SUPER_FOUNDER_ASC",
  HasSuperFounderDesc = "HAS_SUPER_FOUNDER_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IndustriesAsc = "INDUSTRIES_ASC",
  IndustriesDesc = "INDUSTRIES_DESC",
  InvestorsAsc = "INVESTORS_ASC",
  InvestorsDesc = "INVESTORS_DESC",
  IsActivelyFundraisingAsc = "IS_ACTIVELY_FUNDRAISING_ASC",
  IsActivelyFundraisingDesc = "IS_ACTIVELY_FUNDRAISING_DESC",
  IsBasecampFundedAsc = "IS_BASECAMP_FUNDED_ASC",
  IsBasecampFundedDesc = "IS_BASECAMP_FUNDED_DESC",
  IsFoundingLocationAsc = "IS_FOUNDING_LOCATION_ASC",
  IsFoundingLocationDesc = "IS_FOUNDING_LOCATION_DESC",
  IsNpaAsc = "IS_NPA_ASC",
  IsNpaDesc = "IS_NPA_DESC",
  IsOpCompanyAsc = "IS_OP_COMPANY_ASC",
  IsOpCompanyDesc = "IS_OP_COMPANY_DESC",
  IsTrCompanyAsc = "IS_TR_COMPANY_ASC",
  IsTrCompanyDesc = "IS_TR_COMPANY_DESC",
  LastCalculatedUtcAsc = "LAST_CALCULATED_UTC_ASC",
  LastCalculatedUtcDesc = "LAST_CALCULATED_UTC_DESC",
  LastFundingDateAsc = "LAST_FUNDING_DATE_ASC",
  LastFundingDateDesc = "LAST_FUNDING_DATE_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LaunchYearAsc = "LAUNCH_YEAR_ASC",
  LaunchYearDesc = "LAUNCH_YEAR_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  MonthsFromLastFundingAsc = "MONTHS_FROM_LAST_FUNDING_ASC",
  MonthsFromLastFundingDesc = "MONTHS_FROM_LAST_FUNDING_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NotesAsc = "NOTES_ASC",
  NotesDesc = "NOTES_DESC",
  RoundAsc = "ROUND_ASC",
  RoundDesc = "ROUND_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TargetFundAsc = "TARGET_FUND_ASC",
  TargetFundDesc = "TARGET_FUND_DESC",
  TeamAsc = "TEAM_ASC",
  TeamDesc = "TEAM_DESC",
  TechnologiesAsc = "TECHNOLOGIES_ASC",
  TechnologiesDesc = "TECHNOLOGIES_DESC",
  TechStackPredictleadsAsc = "TECH_STACK_PREDICTLEADS_ASC",
  TechStackPredictleadsDesc = "TECH_STACK_PREDICTLEADS_DESC",
  TimeFromLastFundingAsc = "TIME_FROM_LAST_FUNDING_ASC",
  TimeFromLastFundingDesc = "TIME_FROM_LAST_FUNDING_DESC",
  TotalFundingAsc = "TOTAL_FUNDING_ASC",
  TotalFundingDesc = "TOTAL_FUNDING_DESC",
  TotalFundingSourceAsc = "TOTAL_FUNDING_SOURCE_ASC",
  TotalFundingSourceDesc = "TOTAL_FUNDING_SOURCE_DESC",
  TrelloCardAsc = "TRELLO_CARD_ASC",
  TrelloCardDesc = "TRELLO_CARD_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  WebsiteDomainAsc = "WEBSITE_DOMAIN_ASC",
  WebsiteDomainDesc = "WEBSITE_DOMAIN_DESC",
  WebsiteTrafficChartAsc = "WEBSITE_TRAFFIC_CHART_ASC",
  WebsiteTrafficChartDesc = "WEBSITE_TRAFFIC_CHART_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type LgCompanyCopy = {
  __typename?: "LgCompanyCopy";
  about?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  calculationStatus?: Maybe<Scalars["String"]["output"]>;
  cardLastActivity?: Maybe<Scalars["Datetime"]["output"]>;
  cardName?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  companyStatus?: Maybe<Scalars["String"]["output"]>;
  continent?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dealroomPath?: Maybe<Scalars["String"]["output"]>;
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  dealroomUrl?: Maybe<Scalars["String"]["output"]>;
  employees?: Maybe<Scalars["String"]["output"]>;
  employeesChart?: Maybe<Scalars["JSON"]["output"]>;
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  fundingDate?: Maybe<Scalars["String"]["output"]>;
  fundingRounds?: Maybe<Scalars["JSON"]["output"]>;
  growthChart?: Maybe<Scalars["JSON"]["output"]>;
  hasPromisingFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasStrongFounder?: Maybe<Scalars["Boolean"]["output"]>;
  hasSuperFounder?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["UUID"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  industries?: Maybe<Scalars["JSON"]["output"]>;
  investors?: Maybe<Scalars["JSON"]["output"]>;
  isActivelyFundraising?: Maybe<Scalars["Boolean"]["output"]>;
  isBasecampFunded?: Maybe<Scalars["Boolean"]["output"]>;
  isFoundingLocation?: Maybe<Scalars["Boolean"]["output"]>;
  isNpa?: Maybe<Scalars["Boolean"]["output"]>;
  isOpCompany?: Maybe<Scalars["Boolean"]["output"]>;
  isTrCompany?: Maybe<Scalars["Boolean"]["output"]>;
  lastCalculatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  lastFundingDate?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["JSON"]["output"]>;
  round?: Maybe<Scalars["String"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
  targetFund?: Maybe<Scalars["String"]["output"]>;
  team?: Maybe<Scalars["JSON"]["output"]>;
  techStackPredictleads?: Maybe<Scalars["JSON"]["output"]>;
  technologies?: Maybe<Scalars["JSON"]["output"]>;
  timeFromLastFunding?: Maybe<Interval>;
  totalFunding?: Maybe<Scalars["String"]["output"]>;
  totalFundingSource?: Maybe<Scalars["String"]["output"]>;
  trelloCard?: Maybe<Scalars["JSON"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  websiteDomain?: Maybe<Scalars["String"]["output"]>;
  websiteTrafficChart?: Maybe<Scalars["JSON"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type LgCompanyCopyAggregates = {
  __typename?: "LgCompanyCopyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyCopyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyCopyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyCopyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyCopyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyCopyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyCopyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanyCopySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyCopyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyCopyVarianceSampleAggregates>;
};

export type LgCompanyCopyAverageAggregates = {
  __typename?: "LgCompanyCopyAverageAggregates";
  /** Mean average of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgCompanyCopy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyCopyCondition = {
  /** Checks for equality with the object’s `about` field. */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `calculationStatus` field. */
  calculationStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cardLastActivity` field. */
  cardLastActivity?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `cardName` field. */
  cardName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `continent` field. */
  continent?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealroomPath` field. */
  dealroomPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalCompleteness` field. */
  dealroomSignalCompleteness?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalGrowthRate` field. */
  dealroomSignalGrowthRate?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalRating` field. */
  dealroomSignalRating?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalTeamStrength` field. */
  dealroomSignalTeamStrength?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomSignalTiming` field. */
  dealroomSignalTiming?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `dealroomUrl` field. */
  dealroomUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employees` field. */
  employees?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `fundingDate` field. */
  fundingDate?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fundingRounds` field. */
  fundingRounds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `growthChart` field. */
  growthChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `industries` field. */
  industries?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investors` field. */
  investors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `isActivelyFundraising` field. */
  isActivelyFundraising?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isBasecampFunded` field. */
  isBasecampFunded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isFoundingLocation` field. */
  isFoundingLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isNpa` field. */
  isNpa?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isOpCompany` field. */
  isOpCompany?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isTrCompany` field. */
  isTrCompany?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastCalculatedUtc` field. */
  lastCalculatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `launchYear` field. */
  launchYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `monthsFromLastFunding` field. */
  monthsFromLastFunding?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `round` field. */
  round?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `targetFund` field. */
  targetFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `team` field. */
  team?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `technologies` field. */
  technologies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `timeFromLastFunding` field. */
  timeFromLastFunding?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloCard` field. */
  trelloCard?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteDomain` field. */
  websiteDomain?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteTrafficChart` field. */
  websiteTrafficChart?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgCompanyCopyDistinctCountAggregates = {
  __typename?: "LgCompanyCopyDistinctCountAggregates";
  /** Distinct count of about across the matching connection */
  about?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of address across the matching connection */
  address?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of calculationStatus across the matching connection */
  calculationStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardLastActivity across the matching connection */
  cardLastActivity?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardName across the matching connection */
  cardName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyStatus across the matching connection */
  companyStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of continent across the matching connection */
  continent?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currency across the matching connection */
  currency?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomPath across the matching connection */
  dealroomPath?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomUrl across the matching connection */
  dealroomUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employees across the matching connection */
  employees?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesChart across the matching connection */
  employeesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingDate across the matching connection */
  fundingDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingRounds across the matching connection */
  fundingRounds?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of growthChart across the matching connection */
  growthChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasPromisingFounder across the matching connection */
  hasPromisingFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasStrongFounder across the matching connection */
  hasStrongFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasSuperFounder across the matching connection */
  hasSuperFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of industries across the matching connection */
  industries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investors across the matching connection */
  investors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isActivelyFundraising across the matching connection */
  isActivelyFundraising?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isBasecampFunded across the matching connection */
  isBasecampFunded?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFoundingLocation across the matching connection */
  isFoundingLocation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isNpa across the matching connection */
  isNpa?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isOpCompany across the matching connection */
  isOpCompany?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isTrCompany across the matching connection */
  isTrCompany?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastCalculatedUtc across the matching connection */
  lastCalculatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingDate across the matching connection */
  lastFundingDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of round across the matching connection */
  round?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of targetFund across the matching connection */
  targetFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of team across the matching connection */
  team?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of techStackPredictleads across the matching connection */
  techStackPredictleads?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of technologies across the matching connection */
  technologies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of timeFromLastFunding across the matching connection */
  timeFromLastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloCard across the matching connection */
  trelloCard?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteDomain across the matching connection */
  websiteDomain?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTrafficChart across the matching connection */
  websiteTrafficChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyCopy` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyCopyFilter = {
  /** Filter by the object’s `about` field. */
  about?: InputMaybe<StringFilter>;
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<StringFilter>;
  /** Filter by the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyCopyFilter>>;
  /** Filter by the object’s `calculationStatus` field. */
  calculationStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cardLastActivity` field. */
  cardLastActivity?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cardName` field. */
  cardName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `continent` field. */
  continent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealroomPath` field. */
  dealroomPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealroomSignalCompleteness` field. */
  dealroomSignalCompleteness?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalGrowthRate` field. */
  dealroomSignalGrowthRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalRating` field. */
  dealroomSignalRating?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalTeamStrength` field. */
  dealroomSignalTeamStrength?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalTiming` field. */
  dealroomSignalTiming?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomUrl` field. */
  dealroomUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employees` field. */
  employees?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fundingDate` field. */
  fundingDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fundingRounds` field. */
  fundingRounds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `growthChart` field. */
  growthChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `industries` field. */
  industries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investors` field. */
  investors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isActivelyFundraising` field. */
  isActivelyFundraising?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isBasecampFunded` field. */
  isBasecampFunded?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isFoundingLocation` field. */
  isFoundingLocation?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNpa` field. */
  isNpa?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOpCompany` field. */
  isOpCompany?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTrCompany` field. */
  isTrCompany?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastCalculatedUtc` field. */
  lastCalculatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `launchYear` field. */
  launchYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `monthsFromLastFunding` field. */
  monthsFromLastFunding?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyCopyFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<JsonFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyCopyFilter>>;
  /** Filter by the object’s `round` field. */
  round?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `targetFund` field. */
  targetFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `team` field. */
  team?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `technologies` field. */
  technologies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `timeFromLastFunding` field. */
  timeFromLastFunding?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloCard` field. */
  trelloCard?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteDomain` field. */
  websiteDomain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTrafficChart` field. */
  websiteTrafficChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgCompanyCopy` for usage during aggregation. */
export enum LgCompanyCopyGroupBy {
  About = "ABOUT",
  Address = "ADDRESS",
  Amount = "AMOUNT",
  AmountUsdMillion = "AMOUNT_USD_MILLION",
  CalculationStatus = "CALCULATION_STATUS",
  CardLastActivity = "CARD_LAST_ACTIVITY",
  CardLastActivityTruncatedToDay = "CARD_LAST_ACTIVITY_TRUNCATED_TO_DAY",
  CardLastActivityTruncatedToHour = "CARD_LAST_ACTIVITY_TRUNCATED_TO_HOUR",
  CardName = "CARD_NAME",
  City = "CITY",
  CompanyStatus = "COMPANY_STATUS",
  Continent = "CONTINENT",
  Country = "COUNTRY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Currency = "CURRENCY",
  DealroomPath = "DEALROOM_PATH",
  DealroomSignalCompleteness = "DEALROOM_SIGNAL_COMPLETENESS",
  DealroomSignalGrowthRate = "DEALROOM_SIGNAL_GROWTH_RATE",
  DealroomSignalRating = "DEALROOM_SIGNAL_RATING",
  DealroomSignalTeamStrength = "DEALROOM_SIGNAL_TEAM_STRENGTH",
  DealroomSignalTiming = "DEALROOM_SIGNAL_TIMING",
  DealroomUrl = "DEALROOM_URL",
  Employees = "EMPLOYEES",
  EmployeesChart = "EMPLOYEES_CHART",
  EmployeesLatest = "EMPLOYEES_LATEST",
  FundingDate = "FUNDING_DATE",
  FundingRounds = "FUNDING_ROUNDS",
  GrowthChart = "GROWTH_CHART",
  HasPromisingFounder = "HAS_PROMISING_FOUNDER",
  HasStrongFounder = "HAS_STRONG_FOUNDER",
  HasSuperFounder = "HAS_SUPER_FOUNDER",
  Id = "ID",
  Image = "IMAGE",
  Industries = "INDUSTRIES",
  Investors = "INVESTORS",
  IsActivelyFundraising = "IS_ACTIVELY_FUNDRAISING",
  IsBasecampFunded = "IS_BASECAMP_FUNDED",
  IsFoundingLocation = "IS_FOUNDING_LOCATION",
  IsNpa = "IS_NPA",
  IsOpCompany = "IS_OP_COMPANY",
  IsTrCompany = "IS_TR_COMPANY",
  LastCalculatedUtc = "LAST_CALCULATED_UTC",
  LastCalculatedUtcTruncatedToDay = "LAST_CALCULATED_UTC_TRUNCATED_TO_DAY",
  LastCalculatedUtcTruncatedToHour = "LAST_CALCULATED_UTC_TRUNCATED_TO_HOUR",
  LastFundingDate = "LAST_FUNDING_DATE",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LaunchYear = "LAUNCH_YEAR",
  LinkedinUrl = "LINKEDIN_URL",
  MonthsFromLastFunding = "MONTHS_FROM_LAST_FUNDING",
  Name = "NAME",
  Notes = "NOTES",
  Round = "ROUND",
  Tagline = "TAGLINE",
  Tags = "TAGS",
  TargetFund = "TARGET_FUND",
  Team = "TEAM",
  Technologies = "TECHNOLOGIES",
  TechStackPredictleads = "TECH_STACK_PREDICTLEADS",
  TimeFromLastFunding = "TIME_FROM_LAST_FUNDING",
  TotalFunding = "TOTAL_FUNDING",
  TotalFundingSource = "TOTAL_FUNDING_SOURCE",
  TrelloCard = "TRELLO_CARD",
  TwitterUrl = "TWITTER_URL",
  WebsiteDomain = "WEBSITE_DOMAIN",
  WebsiteTrafficChart = "WEBSITE_TRAFFIC_CHART",
  WebsiteUrl = "WEBSITE_URL",
}

export type LgCompanyCopyHavingAverageInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingDistinctCountInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgCompanyCopy` aggregates. */
export type LgCompanyCopyHavingInput = {
  AND?: InputMaybe<Array<LgCompanyCopyHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyCopyHavingInput>>;
  average?: InputMaybe<LgCompanyCopyHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyCopyHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyCopyHavingMaxInput>;
  min?: InputMaybe<LgCompanyCopyHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyCopyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyCopyHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyCopyHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyCopyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyCopyHavingVarianceSampleInput>;
};

export type LgCompanyCopyHavingMaxInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingMinInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingStddevPopulationInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingStddevSampleInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingSumInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingVariancePopulationInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyHavingVarianceSampleInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyCopyMaxAggregates = {
  __typename?: "LgCompanyCopyMaxAggregates";
  /** Maximum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyCopyMinAggregates = {
  __typename?: "LgCompanyCopyMinAggregates";
  /** Minimum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyCopyStddevPopulationAggregates = {
  __typename?: "LgCompanyCopyStddevPopulationAggregates";
  /** Population standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyCopyStddevSampleAggregates = {
  __typename?: "LgCompanyCopyStddevSampleAggregates";
  /** Sample standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyCopySumAggregates = {
  __typename?: "LgCompanyCopySumAggregates";
  /** Sum of amountUsdMillion across the matching connection */
  amountUsdMillion: Scalars["Float"]["output"];
  /** Sum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness: Scalars["Float"]["output"];
  /** Sum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate: Scalars["Float"]["output"];
  /** Sum of dealroomSignalRating across the matching connection */
  dealroomSignalRating: Scalars["Float"]["output"];
  /** Sum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength: Scalars["Float"]["output"];
  /** Sum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming: Scalars["Float"]["output"];
  /** Sum of employeesLatest across the matching connection */
  employeesLatest: Scalars["BigInt"]["output"];
  /** Sum of launchYear across the matching connection */
  launchYear: Scalars["BigInt"]["output"];
  /** Sum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding: Scalars["BigInt"]["output"];
};

export type LgCompanyCopyVariancePopulationAggregates = {
  __typename?: "LgCompanyCopyVariancePopulationAggregates";
  /** Population variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyCopyVarianceSampleAggregates = {
  __typename?: "LgCompanyCopyVarianceSampleAggregates";
  /** Sample variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DealroomCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyConnection =
  {
    __typename?: "LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<DealroomCompanyAggregates>;
    /** A list of edges which contains the `DealroomCompany`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
    edges: Array<LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<DealroomCompanyAggregates>>;
    /** A list of `DealroomCompany` objects. */
    nodes: Array<DealroomCompany>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `DealroomCompany` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `DealroomCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomCompanyGroupBy>;
    having?: InputMaybe<DealroomCompanyHavingInput>;
  };

/** A `DealroomCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyEdge = {
  __typename?: "LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DealroomCompany` at the end of the edge. */
  node: DealroomCompany;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
};

/** A `DealroomCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyDealroomCompaniesByNzrOpportunityEmailCandidateLgCompanyIdAndDealroomCompanyIdManyToManyEdgeNzrOpportunityEmailCandidatesArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

export type LgCompanyDistinctCountAggregates = {
  __typename?: "LgCompanyDistinctCountAggregates";
  /** Distinct count of about across the matching connection */
  about?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of address across the matching connection */
  address?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  badges?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of calculationStatus across the matching connection */
  calculationStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardLastActivity across the matching connection */
  cardLastActivity?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardName across the matching connection */
  cardName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyStatus across the matching connection */
  companyStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of continent across the matching connection */
  continent?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currency across the matching connection */
  currency?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomPath across the matching connection */
  dealroomPath?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealroomUrl across the matching connection */
  dealroomUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of draft across the matching connection */
  draft?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employees across the matching connection */
  employees?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesChart across the matching connection */
  employeesChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingDate across the matching connection */
  fundingDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingRounds across the matching connection */
  fundingRounds?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of growthChart across the matching connection */
  growthChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasPromisingFounder across the matching connection */
  hasPromisingFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasStrongFounder across the matching connection */
  hasStrongFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hasSuperFounder across the matching connection */
  hasSuperFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of industries across the matching connection */
  industries?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investors across the matching connection */
  investors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isActivelyFundraising across the matching connection */
  isActivelyFundraising?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isBasecampFunded across the matching connection */
  isBasecampFunded?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFoundingLocation across the matching connection */
  isFoundingLocation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isNpa across the matching connection */
  isNpa?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isOpCompany across the matching connection */
  isOpCompany?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isTrCompany across the matching connection */
  isTrCompany?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of last3Sentiment across the matching connection */
  last3Sentiment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastCalculatedUtc across the matching connection */
  lastCalculatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingDate across the matching connection */
  lastFundingDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestExcitementDate across the matching connection */
  latestExcitementDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of relativeRunway across the matching connection */
  relativeRunway?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of round across the matching connection */
  round?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runwayEndDate across the matching connection */
  runwayEndDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runwayLastUpdate across the matching connection */
  runwayLastUpdate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sharingAllowed across the matching connection */
  sharingAllowed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of targetFund across the matching connection */
  targetFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of team across the matching connection */
  team?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of techStackPredictleads across the matching connection */
  techStackPredictleads?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of technologies across the matching connection */
  technologies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of timeFromLastFunding across the matching connection */
  timeFromLastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloCard across the matching connection */
  trelloCard?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteDomain across the matching connection */
  websiteDomain?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteTrafficChart across the matching connection */
  websiteTrafficChart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

export type LgCompanyFile = Node & {
  __typename?: "LgCompanyFile";
  /** Reads a single `LgCompany` that is related to this `LgCompanyFile`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  /** Reads a single `LgFile` that is related to this `LgCompanyFile`. */
  file?: Maybe<LgFile>;
  fileId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type LgCompanyFileAggregates = {
  __typename?: "LgCompanyFileAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyFileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyFileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyFileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyFileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyFileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyFileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanyFileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyFileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyFileVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgCompanyFile` object types. */
export type LgCompanyFileAggregatesFilter = {
  /** Mean average aggregate over matching `LgCompanyFile` objects. */
  average?: InputMaybe<LgCompanyFileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgCompanyFile` objects. */
  distinctCount?: InputMaybe<LgCompanyFileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyFile` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyFileFilter>;
  /** Maximum aggregate over matching `LgCompanyFile` objects. */
  max?: InputMaybe<LgCompanyFileMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgCompanyFile` objects. */
  min?: InputMaybe<LgCompanyFileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgCompanyFile` objects. */
  stddevPopulation?: InputMaybe<LgCompanyFileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgCompanyFile` objects. */
  stddevSample?: InputMaybe<LgCompanyFileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgCompanyFile` objects. */
  sum?: InputMaybe<LgCompanyFileSumAggregateFilter>;
  /** Population variance aggregate over matching `LgCompanyFile` objects. */
  variancePopulation?: InputMaybe<LgCompanyFileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgCompanyFile` objects. */
  varianceSample?: InputMaybe<LgCompanyFileVarianceSampleAggregateFilter>;
};

export type LgCompanyFileAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyFileAverageAggregates = {
  __typename?: "LgCompanyFileAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgCompanyFile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyFileCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LgCompanyFileDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  fileId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
};

export type LgCompanyFileDistinctCountAggregates = {
  __typename?: "LgCompanyFileDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fileId across the matching connection */
  fileId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyFile` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyFileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyFileFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `file` relation. */
  file?: InputMaybe<LgFileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyFileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyFileFilter>>;
};

/** Grouping methods for `LgCompanyFile` for usage during aggregation. */
export enum LgCompanyFileGroupBy {
  CompanyId = "COMPANY_ID",
  FileId = "FILE_ID",
}

export type LgCompanyFileHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgCompanyFile` aggregates. */
export type LgCompanyFileHavingInput = {
  AND?: InputMaybe<Array<LgCompanyFileHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyFileHavingInput>>;
  average?: InputMaybe<LgCompanyFileHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyFileHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyFileHavingMaxInput>;
  min?: InputMaybe<LgCompanyFileHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyFileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyFileHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyFileHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyFileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyFileHavingVarianceSampleInput>;
};

export type LgCompanyFileHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyFileMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgCompanyFileMaxAggregates = {
  __typename?: "LgCompanyFileMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyFileMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgCompanyFileMinAggregates = {
  __typename?: "LgCompanyFileMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyFileStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyFileStddevPopulationAggregates = {
  __typename?: "LgCompanyFileStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyFileStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyFileStddevSampleAggregates = {
  __typename?: "LgCompanyFileStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyFileSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type LgCompanyFileSumAggregates = {
  __typename?: "LgCompanyFileSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type LgCompanyFileVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyFileVariancePopulationAggregates = {
  __typename?: "LgCompanyFileVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyFileVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyFileVarianceSampleAggregates = {
  __typename?: "LgCompanyFileVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgCompanyFile` values. */
export type LgCompanyFilesConnection = {
  __typename?: "LgCompanyFilesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyFileAggregates>;
  /** A list of edges which contains the `LgCompanyFile` and cursor to aid in pagination. */
  edges: Array<LgCompanyFilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyFileAggregates>>;
  /** A list of `LgCompanyFile` objects. */
  nodes: Array<LgCompanyFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyFile` values. */
export type LgCompanyFilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyFileGroupBy>;
  having?: InputMaybe<LgCompanyFileHavingInput>;
};

/** A `LgCompanyFile` edge in the connection. */
export type LgCompanyFilesEdge = {
  __typename?: "LgCompanyFilesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyFile` at the end of the edge. */
  node: LgCompanyFile;
};

/** Methods to use when ordering `LgCompanyFile`. */
export enum LgCompanyFilesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A filter to be used against `LgCompany` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyFilter = {
  /** Filter by the object’s `about` field. */
  about?: InputMaybe<StringFilter>;
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<StringFilter>;
  /** Filter by the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyFilter>>;
  /** Filter by the object’s `badges` field. */
  badges?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `calculationStatus` field. */
  calculationStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cardLastActivity` field. */
  cardLastActivity?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cardName` field. */
  cardName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyStatus` field. */
  companyStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `continent` field. */
  continent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<StringFilter>;
  customOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `dealroomPath` field. */
  dealroomPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealroomSignalCompleteness` field. */
  dealroomSignalCompleteness?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalGrowthRate` field. */
  dealroomSignalGrowthRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalRating` field. */
  dealroomSignalRating?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalTeamStrength` field. */
  dealroomSignalTeamStrength?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomSignalTiming` field. */
  dealroomSignalTiming?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealroomUrl` field. */
  dealroomUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `draft` field. */
  draft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `employees` field. */
  employees?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeesChart` field. */
  employeesChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fundingDate` field. */
  fundingDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fundingRounds` field. */
  fundingRounds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `growthChart` field. */
  growthChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `hasPromisingFounder` field. */
  hasPromisingFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasStrongFounder` field. */
  hasStrongFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasSuperFounder` field. */
  hasSuperFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `industries` field. */
  industries?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investors` field. */
  investors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isActivelyFundraising` field. */
  isActivelyFundraising?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isBasecampFunded` field. */
  isBasecampFunded?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isFoundingLocation` field. */
  isFoundingLocation?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNpa` field. */
  isNpa?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOpCompany` field. */
  isOpCompany?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTrCompany` field. */
  isTrCompany?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `last3Sentiment` field. */
  last3Sentiment?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lastCalculatedUtc` field. */
  lastCalculatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastFundingDate` field. */
  lastFundingDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestExcitement` field. */
  latestExcitement?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `latestExcitementDate` field. */
  latestExcitementDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `launchYear` field. */
  launchYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lgCompanyCommentsByCompanyId` relation. */
  lgCompanyCommentsByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyCommentFilter>;
  /** Some related `lgCompanyCommentsByCompanyId` exist. */
  lgCompanyCommentsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyFilesByCompanyId` relation. */
  lgCompanyFilesByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyFileFilter>;
  /** Some related `lgCompanyFilesByCompanyId` exist. */
  lgCompanyFilesByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyFlagsByCompanyId` relation. */
  lgCompanyFlagsByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyFlagFilter>;
  /** Some related `lgCompanyFlagsByCompanyId` exist. */
  lgCompanyFlagsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyInvestorsByCompanyId` relation. */
  lgCompanyInvestorsByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyInvestorFilter>;
  /** Some related `lgCompanyInvestorsByCompanyId` exist. */
  lgCompanyInvestorsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyMetricsByCompanyId` relation. */
  lgCompanyMetricsByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyMetricFilter>;
  /** Some related `lgCompanyMetricsByCompanyId` exist. */
  lgCompanyMetricsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyNotesByCompanyId` relation. */
  lgCompanyNotesByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyNoteFilter>;
  /** Some related `lgCompanyNotesByCompanyId` exist. */
  lgCompanyNotesByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyTeamsByCompanyId` relation. */
  lgCompanyTeamsByCompanyId?: InputMaybe<LgCompanyToManyLgCompanyTeamFilter>;
  /** Some related `lgCompanyTeamsByCompanyId` exist. */
  lgCompanyTeamsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgFundingRoundsByCompanyId` relation. */
  lgFundingRoundsByCompanyId?: InputMaybe<LgCompanyToManyLgFundingRoundFilter>;
  /** Some related `lgFundingRoundsByCompanyId` exist. */
  lgFundingRoundsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `monthsFromLastFunding` field. */
  monthsFromLastFunding?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mtpCompanyfundsByMtpCompanyId` relation. */
  mtpCompanyfundsByMtpCompanyId?: InputMaybe<LgCompanyToManyMtpCompanyfundFilter>;
  /** Some related `mtpCompanyfundsByMtpCompanyId` exist. */
  mtpCompanyfundsByMtpCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nzrOpportunityEmailCandidates` relation. */
  nzrOpportunityEmailCandidates?: InputMaybe<LgCompanyToManyNzrOpportunityEmailCandidateFilter>;
  /** Some related `nzrOpportunityEmailCandidates` exist. */
  nzrOpportunityEmailCandidatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrSentimentFormsByCompanyId` relation. */
  nzrSentimentFormsByCompanyId?: InputMaybe<LgCompanyToManyNzrSentimentFormFilter>;
  /** Some related `nzrSentimentFormsByCompanyId` exist. */
  nzrSentimentFormsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrUserCompanyTagsByCompanyId` relation. */
  nzrUserCompanyTagsByCompanyId?: InputMaybe<LgCompanyToManyNzrUserCompanyTagFilter>;
  /** Some related `nzrUserCompanyTagsByCompanyId` exist. */
  nzrUserCompanyTagsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyFilter>>;
  /** Filter by the object’s `relativeRunway` field. */
  relativeRunway?: InputMaybe<StringFilter>;
  /** Filter by the object’s `round` field. */
  round?: InputMaybe<StringFilter>;
  /** Filter by the object’s `runwayEndDate` field. */
  runwayEndDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `runwayLastUpdate` field. */
  runwayLastUpdate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `sharingAllowed` field. */
  sharingAllowed?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `signalsByCompanyId` relation. */
  signalsByCompanyId?: InputMaybe<LgCompanyToManySignalFilter>;
  /** Some related `signalsByCompanyId` exist. */
  signalsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `srcCompaniesByCompanyId` relation. */
  srcCompaniesByCompanyId?: InputMaybe<LgCompanyToManySrcCompanyFilter>;
  /** Some related `srcCompaniesByCompanyId` exist. */
  srcCompaniesByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `targetFund` field. */
  targetFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `team` field. */
  team?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `techStackPredictleads` field. */
  techStackPredictleads?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `technologies` field. */
  technologies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `timeFromLastFunding` field. */
  timeFromLastFunding?: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloCard` field. */
  trelloCard?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteDomain` field. */
  websiteDomain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteTrafficChart` field. */
  websiteTrafficChart?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

export type LgCompanyFlag = Node & {
  __typename?: "LgCompanyFlag";
  /** Reads a single `LgCompany` that is related to this `LgCompanyFlag`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  createdBy: Scalars["UUID"]["output"];
  deletedAt?: Maybe<Scalars["Datetime"]["output"]>;
  flag: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `LgMember` that is related to this `LgCompanyFlag`. */
  lgMemberByCreatedBy?: Maybe<LgMember>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt?: Maybe<Scalars["Datetime"]["output"]>;
};

export type LgCompanyFlagAggregates = {
  __typename?: "LgCompanyFlagAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyFlagDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgCompanyFlag` object types. */
export type LgCompanyFlagAggregatesFilter = {
  /** Distinct count aggregate over matching `LgCompanyFlag` objects. */
  distinctCount?: InputMaybe<LgCompanyFlagDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyFlag` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyFlagFilter>;
};

/**
 * A condition to be used against `LgCompanyFlag` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyFlagCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `flag` field. */
  flag?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type LgCompanyFlagDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdBy?: InputMaybe<BigIntFilter>;
  deletedAt?: InputMaybe<BigIntFilter>;
  flag?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type LgCompanyFlagDistinctCountAggregates = {
  __typename?: "LgCompanyFlagDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of flag across the matching connection */
  flag?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyFlag` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyFlagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyFlagFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `flag` field. */
  flag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lgMemberByCreatedBy` relation. */
  lgMemberByCreatedBy?: InputMaybe<LgMemberFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyFlagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyFlagFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `LgCompanyFlag` values. */
export type LgCompanyFlagsConnection = {
  __typename?: "LgCompanyFlagsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyFlagAggregates>;
  /** A list of edges which contains the `LgCompanyFlag` and cursor to aid in pagination. */
  edges: Array<LgCompanyFlagsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyFlagAggregates>>;
  /** A list of `LgCompanyFlag` objects. */
  nodes: Array<LgCompanyFlag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyFlag` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyFlag` values. */
export type LgCompanyFlagsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyFlagsGroupBy>;
  having?: InputMaybe<LgCompanyFlagsHavingInput>;
};

/** A `LgCompanyFlag` edge in the connection. */
export type LgCompanyFlagsEdge = {
  __typename?: "LgCompanyFlagsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyFlag` at the end of the edge. */
  node: LgCompanyFlag;
};

/** Grouping methods for `LgCompanyFlag` for usage during aggregation. */
export enum LgCompanyFlagsGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedBy = "CREATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedAtTruncatedToDay = "DELETED_AT_TRUNCATED_TO_DAY",
  DeletedAtTruncatedToHour = "DELETED_AT_TRUNCATED_TO_HOUR",
  Flag = "FLAG",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type LgCompanyFlagsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgCompanyFlag` aggregates. */
export type LgCompanyFlagsHavingInput = {
  AND?: InputMaybe<Array<LgCompanyFlagsHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyFlagsHavingInput>>;
  average?: InputMaybe<LgCompanyFlagsHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyFlagsHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyFlagsHavingMaxInput>;
  min?: InputMaybe<LgCompanyFlagsHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyFlagsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyFlagsHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyFlagsHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyFlagsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyFlagsHavingVarianceSampleInput>;
};

export type LgCompanyFlagsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyFlagsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `LgCompanyFlag`. */
export enum LgCompanyFlagsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  FlagAsc = "FLAG_ASC",
  FlagDesc = "FLAG_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

/** Grouping methods for `LgCompany` for usage during aggregation. */
export enum LgCompanyGroupBy {
  About = "ABOUT",
  Address = "ADDRESS",
  Amount = "AMOUNT",
  AmountUsdMillion = "AMOUNT_USD_MILLION",
  CalculationStatus = "CALCULATION_STATUS",
  CardLastActivity = "CARD_LAST_ACTIVITY",
  CardLastActivityTruncatedToDay = "CARD_LAST_ACTIVITY_TRUNCATED_TO_DAY",
  CardLastActivityTruncatedToHour = "CARD_LAST_ACTIVITY_TRUNCATED_TO_HOUR",
  CardName = "CARD_NAME",
  City = "CITY",
  CompanyStatus = "COMPANY_STATUS",
  Continent = "CONTINENT",
  Country = "COUNTRY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Currency = "CURRENCY",
  DealroomPath = "DEALROOM_PATH",
  DealroomSignalCompleteness = "DEALROOM_SIGNAL_COMPLETENESS",
  DealroomSignalGrowthRate = "DEALROOM_SIGNAL_GROWTH_RATE",
  DealroomSignalRating = "DEALROOM_SIGNAL_RATING",
  DealroomSignalTeamStrength = "DEALROOM_SIGNAL_TEAM_STRENGTH",
  DealroomSignalTiming = "DEALROOM_SIGNAL_TIMING",
  DealroomUrl = "DEALROOM_URL",
  Deleted = "DELETED",
  Draft = "DRAFT",
  Employees = "EMPLOYEES",
  EmployeesChart = "EMPLOYEES_CHART",
  EmployeesLatest = "EMPLOYEES_LATEST",
  FundingDate = "FUNDING_DATE",
  FundingRounds = "FUNDING_ROUNDS",
  GrowthChart = "GROWTH_CHART",
  HasPromisingFounder = "HAS_PROMISING_FOUNDER",
  HasStrongFounder = "HAS_STRONG_FOUNDER",
  HasSuperFounder = "HAS_SUPER_FOUNDER",
  Image = "IMAGE",
  Industries = "INDUSTRIES",
  Investors = "INVESTORS",
  IsActivelyFundraising = "IS_ACTIVELY_FUNDRAISING",
  IsBasecampFunded = "IS_BASECAMP_FUNDED",
  IsFoundingLocation = "IS_FOUNDING_LOCATION",
  IsNpa = "IS_NPA",
  IsOpCompany = "IS_OP_COMPANY",
  IsTrCompany = "IS_TR_COMPANY",
  Last_3Sentiment = "LAST_3_SENTIMENT",
  LastCalculatedUtc = "LAST_CALCULATED_UTC",
  LastCalculatedUtcTruncatedToDay = "LAST_CALCULATED_UTC_TRUNCATED_TO_DAY",
  LastCalculatedUtcTruncatedToHour = "LAST_CALCULATED_UTC_TRUNCATED_TO_HOUR",
  LastFundingDate = "LAST_FUNDING_DATE",
  LastFundingDateTruncatedToDay = "LAST_FUNDING_DATE_TRUNCATED_TO_DAY",
  LastFundingDateTruncatedToHour = "LAST_FUNDING_DATE_TRUNCATED_TO_HOUR",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LatestExcitement = "LATEST_EXCITEMENT",
  LatestExcitementDate = "LATEST_EXCITEMENT_DATE",
  LatestExcitementDateTruncatedToDay = "LATEST_EXCITEMENT_DATE_TRUNCATED_TO_DAY",
  LatestExcitementDateTruncatedToHour = "LATEST_EXCITEMENT_DATE_TRUNCATED_TO_HOUR",
  LaunchYear = "LAUNCH_YEAR",
  LinkedinUrl = "LINKEDIN_URL",
  MonthsFromLastFunding = "MONTHS_FROM_LAST_FUNDING",
  Name = "NAME",
  Notes = "NOTES",
  RelativeRunway = "RELATIVE_RUNWAY",
  Round = "ROUND",
  RunwayEndDate = "RUNWAY_END_DATE",
  RunwayLastUpdate = "RUNWAY_LAST_UPDATE",
  SharingAllowed = "SHARING_ALLOWED",
  Tagline = "TAGLINE",
  Tags = "TAGS",
  TargetFund = "TARGET_FUND",
  Team = "TEAM",
  Technologies = "TECHNOLOGIES",
  TechStackPredictleads = "TECH_STACK_PREDICTLEADS",
  TimeFromLastFunding = "TIME_FROM_LAST_FUNDING",
  TotalFunding = "TOTAL_FUNDING",
  TotalFundingSource = "TOTAL_FUNDING_SOURCE",
  TrelloCard = "TRELLO_CARD",
  TwitterUrl = "TWITTER_URL",
  WebsiteDomain = "WEBSITE_DOMAIN",
  WebsiteTrafficChart = "WEBSITE_TRAFFIC_CHART",
  WebsiteUrl = "WEBSITE_URL",
}

export type LgCompanyHavingAverageInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingDistinctCountInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

/** Conditions for `LgCompany` aggregates. */
export type LgCompanyHavingInput = {
  AND?: InputMaybe<Array<LgCompanyHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyHavingInput>>;
  average?: InputMaybe<LgCompanyHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyHavingMaxInput>;
  min?: InputMaybe<LgCompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyHavingVarianceSampleInput>;
};

export type LgCompanyHavingMaxInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingMinInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingStddevPopulationInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingStddevSampleInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingSumInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingVariancePopulationInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyHavingVarianceSampleInput = {
  amountUsdMillion?: InputMaybe<HavingFloatFilter>;
  cardLastActivity?: InputMaybe<HavingDatetimeFilter>;
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dealroomSignalCompleteness?: InputMaybe<HavingFloatFilter>;
  dealroomSignalGrowthRate?: InputMaybe<HavingFloatFilter>;
  dealroomSignalRating?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTeamStrength?: InputMaybe<HavingFloatFilter>;
  dealroomSignalTiming?: InputMaybe<HavingFloatFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  lastCalculatedUtc?: InputMaybe<HavingDatetimeFilter>;
  lastFundingDate?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  latestExcitement?: InputMaybe<HavingFloatFilter>;
  latestExcitementDate?: InputMaybe<HavingDatetimeFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  monthsFromLastFunding?: InputMaybe<HavingIntFilter>;
  runwayEndDate?: InputMaybe<HavingDatetimeFilter>;
  runwayLastUpdate?: InputMaybe<HavingDatetimeFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
};

export type LgCompanyInvestor = Node & {
  __typename?: "LgCompanyInvestor";
  /** Reads a single `LgCompany` that is related to this `LgCompanyInvestor`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  compositeId: Scalars["String"]["output"];
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  exited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Reads a single `LgInvestor` that is related to this `LgCompanyInvestor`. */
  investor?: Maybe<LgInvestor>;
  investorId: Scalars["UUID"]["output"];
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `SrcCompanyInvestor`. */
  srcCompanyInvestorsByEntityId: SrcCompanyInvestorsConnection;
};

export type LgCompanyInvestorSrcCompanyInvestorsByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyInvestorCondition>;
  filter?: InputMaybe<SrcCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyInvestorsOrderBy>>;
};

export type LgCompanyInvestorAggregates = {
  __typename?: "LgCompanyInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanyInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgCompanyInvestor` object types. */
export type LgCompanyInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `LgCompanyInvestor` objects. */
  average?: InputMaybe<LgCompanyInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgCompanyInvestor` objects. */
  distinctCount?: InputMaybe<LgCompanyInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyInvestorFilter>;
  /** Maximum aggregate over matching `LgCompanyInvestor` objects. */
  max?: InputMaybe<LgCompanyInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgCompanyInvestor` objects. */
  min?: InputMaybe<LgCompanyInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgCompanyInvestor` objects. */
  stddevPopulation?: InputMaybe<LgCompanyInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgCompanyInvestor` objects. */
  stddevSample?: InputMaybe<LgCompanyInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgCompanyInvestor` objects. */
  sum?: InputMaybe<LgCompanyInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `LgCompanyInvestor` objects. */
  variancePopulation?: InputMaybe<LgCompanyInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgCompanyInvestor` objects. */
  varianceSample?: InputMaybe<LgCompanyInvestorVarianceSampleAggregateFilter>;
};

export type LgCompanyInvestorAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyInvestorAverageAggregates = {
  __typename?: "LgCompanyInvestorAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgCompanyInvestor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyInvestorCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `exited` field. */
  exited?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `investorId` field. */
  investorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LgCompanyInvestorDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  compositeId?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  exited?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
  isEditorial?: InputMaybe<BigIntFilter>;
};

export type LgCompanyInvestorDistinctCountAggregates = {
  __typename?: "LgCompanyInvestorDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of exited across the matching connection */
  exited?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isEditorial across the matching connection */
  isEditorial?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyInvestorFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `exited` field. */
  exited?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `investor` relation. */
  investor?: InputMaybe<LgInvestorFilter>;
  /** Filter by the object’s `investorId` field. */
  investorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyInvestorFilter>>;
  /** Filter by the object’s `srcCompanyInvestorsByEntityId` relation. */
  srcCompanyInvestorsByEntityId?: InputMaybe<LgCompanyInvestorToManySrcCompanyInvestorFilter>;
  /** Some related `srcCompanyInvestorsByEntityId` exist. */
  srcCompanyInvestorsByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LgCompanyInvestorMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgCompanyInvestorMaxAggregates = {
  __typename?: "LgCompanyInvestorMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyInvestorMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgCompanyInvestorMinAggregates = {
  __typename?: "LgCompanyInvestorMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyInvestorStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyInvestorStddevPopulationAggregates = {
  __typename?: "LgCompanyInvestorStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyInvestorStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyInvestorStddevSampleAggregates = {
  __typename?: "LgCompanyInvestorStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyInvestorSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type LgCompanyInvestorSumAggregates = {
  __typename?: "LgCompanyInvestorSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `SrcCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyInvestorToManySrcCompanyInvestorFilter = {
  /** Aggregates across related `SrcCompanyInvestor` match the filter criteria. */
  aggregates?: InputMaybe<SrcCompanyInvestorAggregatesFilter>;
  /** Every related `SrcCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcCompanyInvestorFilter>;
  /** No related `SrcCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcCompanyInvestorFilter>;
  /** Some related `SrcCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcCompanyInvestorFilter>;
};

export type LgCompanyInvestorVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyInvestorVariancePopulationAggregates = {
  __typename?: "LgCompanyInvestorVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyInvestorVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyInvestorVarianceSampleAggregates = {
  __typename?: "LgCompanyInvestorVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgCompanyInvestor` values. */
export type LgCompanyInvestorsConnection = {
  __typename?: "LgCompanyInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyInvestorAggregates>;
  /** A list of edges which contains the `LgCompanyInvestor` and cursor to aid in pagination. */
  edges: Array<LgCompanyInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyInvestorAggregates>>;
  /** A list of `LgCompanyInvestor` objects. */
  nodes: Array<LgCompanyInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyInvestor` values. */
export type LgCompanyInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyInvestorsGroupBy>;
  having?: InputMaybe<LgCompanyInvestorsHavingInput>;
};

/** A `LgCompanyInvestor` edge in the connection. */
export type LgCompanyInvestorsEdge = {
  __typename?: "LgCompanyInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyInvestor` at the end of the edge. */
  node: LgCompanyInvestor;
};

/** Grouping methods for `LgCompanyInvestor` for usage during aggregation. */
export enum LgCompanyInvestorsGroupBy {
  CompanyId = "COMPANY_ID",
  Deleted = "DELETED",
  Exited = "EXITED",
  InvestorId = "INVESTOR_ID",
  IsEditorial = "IS_EDITORIAL",
}

export type LgCompanyInvestorsHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgCompanyInvestor` aggregates. */
export type LgCompanyInvestorsHavingInput = {
  AND?: InputMaybe<Array<LgCompanyInvestorsHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyInvestorsHavingInput>>;
  average?: InputMaybe<LgCompanyInvestorsHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyInvestorsHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyInvestorsHavingMaxInput>;
  min?: InputMaybe<LgCompanyInvestorsHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyInvestorsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyInvestorsHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyInvestorsHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyInvestorsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyInvestorsHavingVarianceSampleInput>;
};

export type LgCompanyInvestorsHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyInvestorsHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LgCompanyInvestor`. */
export enum LgCompanyInvestorsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  ExitedAsc = "EXITED_ASC",
  ExitedDesc = "EXITED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvestorIdAsc = "INVESTOR_ID_ASC",
  InvestorIdDesc = "INVESTOR_ID_DESC",
  IsEditorialAsc = "IS_EDITORIAL_ASC",
  IsEditorialDesc = "IS_EDITORIAL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SrcCompanyInvestorsByEntityIdAverageCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdAverageCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdAverageEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdAverageEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdAverageIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcCompanyInvestorsByEntityIdAverageIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcCompanyInvestorsByEntityIdAverageLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdAverageLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdAverageSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdAverageSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdAverageSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdAverageSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdCountAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_COUNT_ASC",
  SrcCompanyInvestorsByEntityIdCountDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_COUNT_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdDistinctCountSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdDistinctCountSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdMaxCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdMaxCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdMaxEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdMaxEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdMaxIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcCompanyInvestorsByEntityIdMaxIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcCompanyInvestorsByEntityIdMaxLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdMaxLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdMaxSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdMaxSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdMaxSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdMaxSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdMinCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdMinCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdMinEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdMinEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdMinIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcCompanyInvestorsByEntityIdMinIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcCompanyInvestorsByEntityIdMinLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdMinLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdMinSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdMinSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdMinSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdMinSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevPopulationSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdStddevPopulationSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdStddevSampleSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdStddevSampleSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdSumCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdSumCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdSumEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdSumEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdSumIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcCompanyInvestorsByEntityIdSumIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcCompanyInvestorsByEntityIdSumLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdSumLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdSumSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdSumSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdSumSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdSumSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdVariancePopulationSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdVariancePopulationSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleCreatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleCreatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleEntityIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleEntityIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleSourceIdAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleSourceIdDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcCompanyInvestorsByEntityIdVarianceSampleSourceTypeAsc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompanyInvestorsByEntityIdVarianceSampleSourceTypeDesc = "SRC_COMPANY_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
}

/** A connection to a list of `LgFile` values, with data from `LgCompanyFile`. */
export type LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyConnection = {
  __typename?: "LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFileAggregates>;
  /** A list of edges which contains the `LgFile`, info from the `LgCompanyFile`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFileAggregates>>;
  /** A list of `LgFile` objects. */
  nodes: Array<LgFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFile` values, with data from `LgCompanyFile`. */
export type LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFileGroupBy>;
  having?: InputMaybe<LgFileHavingInput>;
};

/** A `LgFile` edge in the connection, with data from `LgCompanyFile`. */
export type LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyEdge = {
  __typename?: "LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyFile`. */
  lgCompanyFilesByFileId: LgCompanyFilesConnection;
  /** The `LgFile` at the end of the edge. */
  node: LgFile;
};

/** A `LgFile` edge in the connection, with data from `LgCompanyFile`. */
export type LgCompanyLgFilesByLgCompanyFileCompanyIdAndFileIdManyToManyEdgeLgCompanyFilesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFileCondition>;
  filter?: InputMaybe<LgCompanyFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFilesOrderBy>>;
};

/** A connection to a list of `LgFounder` values, with data from `LgCompanyTeam`. */
export type LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyConnection = {
  __typename?: "LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFounderAggregates>;
  /** A list of edges which contains the `LgFounder`, info from the `LgCompanyTeam`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFounderAggregates>>;
  /** A list of `LgFounder` objects. */
  nodes: Array<LgFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFounder` values, with data from `LgCompanyTeam`. */
export type LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFounderGroupBy>;
  having?: InputMaybe<LgFounderHavingInput>;
};

/** A `LgFounder` edge in the connection, with data from `LgCompanyTeam`. */
export type LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyEdge = {
  __typename?: "LgCompanyLgFoundersByLgCompanyTeamCompanyIdAndMemberIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgFounder` at the end of the edge. */
  node: LgFounder;
  past?: Maybe<Scalars["Boolean"]["output"]>;
  titles?: Maybe<Scalars["JSON"]["output"]>;
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

/** A connection to a list of `LgFounder` values, with data from `NzrSentimentForm`. */
export type LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyConnection = {
  __typename?: "LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFounderAggregates>;
  /** A list of edges which contains the `LgFounder`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFounderAggregates>>;
  /** A list of `LgFounder` objects. */
  nodes: Array<LgFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFounder` values, with data from `NzrSentimentForm`. */
export type LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFounderGroupBy>;
  having?: InputMaybe<LgFounderHavingInput>;
};

/** A `LgFounder` edge in the connection, with data from `NzrSentimentForm`. */
export type LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyEdge = {
  __typename?: "LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFounder` at the end of the edge. */
  node: LgFounder;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByFounderId: NzrSentimentFormsConnection;
};

/** A `LgFounder` edge in the connection, with data from `NzrSentimentForm`. */
export type LgCompanyLgFoundersByNzrSentimentFormCompanyIdAndFounderIdManyToManyEdgeNzrSentimentFormsByFounderIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

/** A connection to a list of `LgInvestor` values, with data from `LgCompanyInvestor`. */
export type LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection = {
  __typename?: "LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgInvestorAggregates>;
  /** A list of edges which contains the `LgInvestor`, info from the `LgCompanyInvestor`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgInvestorAggregates>>;
  /** A list of `LgInvestor` objects. */
  nodes: Array<LgInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgInvestor` values, with data from `LgCompanyInvestor`. */
export type LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgInvestorGroupBy>;
  having?: InputMaybe<LgInvestorHavingInput>;
};

/** A `LgInvestor` edge in the connection, with data from `LgCompanyInvestor`. */
export type LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge = {
  __typename?: "LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  exited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgInvestor` at the end of the edge. */
  node: LgInvestor;
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyComment`. */
export type LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyConnection = {
  __typename?: "LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `LgCompanyComment`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyComment`. */
export type LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyComment`. */
export type LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyEdge = {
  __typename?: "LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyComment`. */
  lgCompanyCommentsByCreatedBy: LgCompanyCommentsConnection;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyComment`. */
export type LgCompanyLgMembersByLgCompanyCommentCompanyIdAndCreatedByManyToManyEdgeLgCompanyCommentsByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCommentCondition>;
  filter?: InputMaybe<LgCompanyCommentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCommentsOrderBy>>;
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyFlag`. */
export type LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyConnection = {
  __typename?: "LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `LgCompanyFlag`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyFlag`. */
export type LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyFlag`. */
export type LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyEdge = {
  __typename?: "LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyFlag`. */
  lgCompanyFlagsByCreatedBy: LgCompanyFlagsConnection;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyFlag`. */
export type LgCompanyLgMembersByLgCompanyFlagCompanyIdAndCreatedByManyToManyEdgeLgCompanyFlagsByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFlagCondition>;
  filter?: InputMaybe<LgCompanyFlagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFlagsOrderBy>>;
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyNote`. */
export type LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyConnection = {
  __typename?: "LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `LgCompanyNote`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `LgCompanyNote`. */
export type LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyNote`. */
export type LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyEdge = {
  __typename?: "LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyNote`. */
  lgCompanyNotesByCreatedBy: LgCompanyNotesConnection;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
};

/** A `LgMember` edge in the connection, with data from `LgCompanyNote`. */
export type LgCompanyLgMembersByLgCompanyNoteCompanyIdAndCreatedByManyToManyEdgeLgCompanyNotesByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyNoteCondition>;
  filter?: InputMaybe<LgCompanyNoteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyNotesOrderBy>>;
};

/** A connection to a list of `LgMember` values, with data from `NzrSentimentForm`. */
export type LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyConnection = {
  __typename?: "LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrSentimentForm`. */
export type LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrSentimentForm`. */
export type LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyEdge = {
  __typename?: "LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCreatedById: NzrSentimentFormsConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrSentimentForm`. */
export type LgCompanyLgMembersByNzrSentimentFormCompanyIdAndCreatedByIdManyToManyEdgeNzrSentimentFormsByCreatedByIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormCondition>;
    filter?: InputMaybe<NzrSentimentFormFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    includeDeleted?: InputMaybe<IncludeDeletedOption>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
  };

/** A connection to a list of `LgMember` values, with data from `NzrUserCompanyTag`. */
export type LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyConnection = {
  __typename?: "LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrUserCompanyTag`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrUserCompanyTag`. */
export type LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrUserCompanyTag`. */
export type LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyEdge = {
  __typename?: "LgCompanyLgMembersByNzrUserCompanyTagCompanyIdAndUserIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  tags: Scalars["JSON"]["output"];
};

export type LgCompanyMaxAggregates = {
  __typename?: "LgCompanyMaxAggregates";
  /** Maximum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyMetric = Node & {
  __typename?: "LgCompanyMetric";
  arr?: Maybe<Scalars["Float"]["output"]>;
  arrGrowthNotes?: Maybe<Scalars["String"]["output"]>;
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  arrNotes?: Maybe<Scalars["String"]["output"]>;
  asAtDate?: Maybe<Scalars["Datetime"]["output"]>;
  buyRecommendation?: Maybe<Scalars["String"]["output"]>;
  cashRunwayMonths?: Maybe<Scalars["Int"]["output"]>;
  cashRunwayNotes?: Maybe<Scalars["String"]["output"]>;
  cashflowPositive?: Maybe<Scalars["Boolean"]["output"]>;
  closeEnough?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `LgCompany` that is related to this `LgCompanyMetric`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  data?: Maybe<Scalars["JSON"]["output"]>;
  discountToValuationQ1?: Maybe<Scalars["String"]["output"]>;
  discountToValuationQ4?: Maybe<Scalars["String"]["output"]>;
  enoughEyes?: Maybe<Scalars["String"]["output"]>;
  fte?: Maybe<Scalars["Int"]["output"]>;
  fteNotes?: Maybe<Scalars["String"]["output"]>;
  fullyFundedToBreakeven?: Maybe<Scalars["Boolean"]["output"]>;
  grossMarginNotes?: Maybe<Scalars["String"]["output"]>;
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  howCanWeSupport?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  impliedArrNotes?: Maybe<Scalars["String"]["output"]>;
  isHoldingCorrectValue?: Maybe<Scalars["String"]["output"]>;
  lastUpatedBy?: Maybe<Scalars["String"]["output"]>;
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  latestPostMoneyNotes?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  operatingMarginNotes?: Maybe<Scalars["String"]["output"]>;
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  pathToBreakeven?: Maybe<Scalars["Boolean"]["output"]>;
  pathToBrekevenNotes?: Maybe<Scalars["String"]["output"]>;
  potentialValueDriver?: Maybe<Scalars["String"]["output"]>;
  reviewedBy?: Maybe<Scalars["UUID"]["output"]>;
  sellRecommendation?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["Datetime"]["output"]>;
};

export type LgCompanyMetricAggregates = {
  __typename?: "LgCompanyMetricAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyMetricAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyMetricDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyMetricMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyMetricMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyMetricStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyMetricStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanyMetricSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyMetricVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyMetricVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgCompanyMetric` object types. */
export type LgCompanyMetricAggregatesFilter = {
  /** Mean average aggregate over matching `LgCompanyMetric` objects. */
  average?: InputMaybe<LgCompanyMetricAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgCompanyMetric` objects. */
  distinctCount?: InputMaybe<LgCompanyMetricDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyMetric` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyMetricFilter>;
  /** Maximum aggregate over matching `LgCompanyMetric` objects. */
  max?: InputMaybe<LgCompanyMetricMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgCompanyMetric` objects. */
  min?: InputMaybe<LgCompanyMetricMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgCompanyMetric` objects. */
  stddevPopulation?: InputMaybe<LgCompanyMetricStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgCompanyMetric` objects. */
  stddevSample?: InputMaybe<LgCompanyMetricStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgCompanyMetric` objects. */
  sum?: InputMaybe<LgCompanyMetricSumAggregateFilter>;
  /** Population variance aggregate over matching `LgCompanyMetric` objects. */
  variancePopulation?: InputMaybe<LgCompanyMetricVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgCompanyMetric` objects. */
  varianceSample?: InputMaybe<LgCompanyMetricVarianceSampleAggregateFilter>;
};

export type LgCompanyMetricAverageAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigFloatFilter>;
  fte?: InputMaybe<BigFloatFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricAverageAggregates = {
  __typename?: "LgCompanyMetricAverageAggregates";
  /** Mean average of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of fte across the matching connection */
  fte?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `LgCompanyMetric` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyMetricCondition = {
  /** Checks for equality with the object’s `arr` field. */
  arr?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `arrGrowthNotes` field. */
  arrGrowthNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `arrGrowthPercentage` field. */
  arrGrowthPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `arrNotes` field. */
  arrNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `asAtDate` field. */
  asAtDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `buyRecommendation` field. */
  buyRecommendation?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cashRunwayMonths` field. */
  cashRunwayMonths?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `cashRunwayNotes` field. */
  cashRunwayNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cashflowPositive` field. */
  cashflowPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `closeEnough` field. */
  closeEnough?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `discountToValuationQ1` field. */
  discountToValuationQ1?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `discountToValuationQ4` field. */
  discountToValuationQ4?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `enoughEyes` field. */
  enoughEyes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fte` field. */
  fte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `fteNotes` field. */
  fteNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fullyFundedToBreakeven` field. */
  fullyFundedToBreakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `grossMarginNotes` field. */
  grossMarginNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `grossMarginPercentage` field. */
  grossMarginPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `howCanWeSupport` field. */
  howCanWeSupport?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `impliedArr` field. */
  impliedArr?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `impliedArrNotes` field. */
  impliedArrNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isHoldingCorrectValue` field. */
  isHoldingCorrectValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastUpatedBy` field. */
  lastUpatedBy?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `latestPostMoney` field. */
  latestPostMoney?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `latestPostMoneyNotes` field. */
  latestPostMoneyNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `operatingMarginNotes` field. */
  operatingMarginNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `operatingMarginPercentage` field. */
  operatingMarginPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `pathToBreakeven` field. */
  pathToBreakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `pathToBrekevenNotes` field. */
  pathToBrekevenNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `potentialValueDriver` field. */
  potentialValueDriver?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `sellRecommendation` field. */
  sellRecommendation?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type LgCompanyMetricDistinctCountAggregateFilter = {
  arr?: InputMaybe<BigIntFilter>;
  arrGrowthNotes?: InputMaybe<BigIntFilter>;
  arrGrowthPercentage?: InputMaybe<BigIntFilter>;
  arrNotes?: InputMaybe<BigIntFilter>;
  asAtDate?: InputMaybe<BigIntFilter>;
  buyRecommendation?: InputMaybe<BigIntFilter>;
  cashRunwayMonths?: InputMaybe<BigIntFilter>;
  cashRunwayNotes?: InputMaybe<BigIntFilter>;
  cashflowPositive?: InputMaybe<BigIntFilter>;
  closeEnough?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  data?: InputMaybe<BigIntFilter>;
  discountToValuationQ1?: InputMaybe<BigIntFilter>;
  discountToValuationQ4?: InputMaybe<BigIntFilter>;
  enoughEyes?: InputMaybe<BigIntFilter>;
  fte?: InputMaybe<BigIntFilter>;
  fteNotes?: InputMaybe<BigIntFilter>;
  fullyFundedToBreakeven?: InputMaybe<BigIntFilter>;
  grossMarginNotes?: InputMaybe<BigIntFilter>;
  grossMarginPercentage?: InputMaybe<BigIntFilter>;
  howCanWeSupport?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  impliedArr?: InputMaybe<BigIntFilter>;
  impliedArrNotes?: InputMaybe<BigIntFilter>;
  isHoldingCorrectValue?: InputMaybe<BigIntFilter>;
  lastUpatedBy?: InputMaybe<BigIntFilter>;
  latestPostMoney?: InputMaybe<BigIntFilter>;
  latestPostMoneyNotes?: InputMaybe<BigIntFilter>;
  operatingMarginNotes?: InputMaybe<BigIntFilter>;
  operatingMarginPercentage?: InputMaybe<BigIntFilter>;
  pathToBreakeven?: InputMaybe<BigIntFilter>;
  pathToBrekevenNotes?: InputMaybe<BigIntFilter>;
  potentialValueDriver?: InputMaybe<BigIntFilter>;
  reviewedBy?: InputMaybe<BigIntFilter>;
  sellRecommendation?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type LgCompanyMetricDistinctCountAggregates = {
  __typename?: "LgCompanyMetricDistinctCountAggregates";
  /** Distinct count of arr across the matching connection */
  arr?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of arrGrowthNotes across the matching connection */
  arrGrowthNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of arrNotes across the matching connection */
  arrNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of asAtDate across the matching connection */
  asAtDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of buyRecommendation across the matching connection */
  buyRecommendation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashRunwayNotes across the matching connection */
  cashRunwayNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashflowPositive across the matching connection */
  cashflowPositive?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of closeEnough across the matching connection */
  closeEnough?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of discountToValuationQ1 across the matching connection */
  discountToValuationQ1?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of discountToValuationQ4 across the matching connection */
  discountToValuationQ4?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of enoughEyes across the matching connection */
  enoughEyes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fte across the matching connection */
  fte?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fteNotes across the matching connection */
  fteNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fullyFundedToBreakeven across the matching connection */
  fullyFundedToBreakeven?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of grossMarginNotes across the matching connection */
  grossMarginNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of howCanWeSupport across the matching connection */
  howCanWeSupport?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of impliedArrNotes across the matching connection */
  impliedArrNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isHoldingCorrectValue across the matching connection */
  isHoldingCorrectValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpatedBy across the matching connection */
  lastUpatedBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestPostMoneyNotes across the matching connection */
  latestPostMoneyNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of operatingMarginNotes across the matching connection */
  operatingMarginNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pathToBreakeven across the matching connection */
  pathToBreakeven?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pathToBrekevenNotes across the matching connection */
  pathToBrekevenNotes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialValueDriver across the matching connection */
  potentialValueDriver?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of reviewedBy across the matching connection */
  reviewedBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sellRecommendation across the matching connection */
  sellRecommendation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyMetric` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyMetricFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyMetricFilter>>;
  /** Filter by the object’s `arr` field. */
  arr?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `arrGrowthNotes` field. */
  arrGrowthNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `arrGrowthPercentage` field. */
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `arrNotes` field. */
  arrNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `asAtDate` field. */
  asAtDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `buyRecommendation` field. */
  buyRecommendation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cashRunwayMonths` field. */
  cashRunwayMonths?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cashRunwayNotes` field. */
  cashRunwayNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cashflowPositive` field. */
  cashflowPositive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `closeEnough` field. */
  closeEnough?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `discountToValuationQ1` field. */
  discountToValuationQ1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `discountToValuationQ4` field. */
  discountToValuationQ4?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enoughEyes` field. */
  enoughEyes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fte` field. */
  fte?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fteNotes` field. */
  fteNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullyFundedToBreakeven` field. */
  fullyFundedToBreakeven?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `grossMarginNotes` field. */
  grossMarginNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `grossMarginPercentage` field. */
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `howCanWeSupport` field. */
  howCanWeSupport?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `impliedArr` field. */
  impliedArr?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `impliedArrNotes` field. */
  impliedArrNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isHoldingCorrectValue` field. */
  isHoldingCorrectValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastUpatedBy` field. */
  lastUpatedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `latestPostMoney` field. */
  latestPostMoney?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `latestPostMoneyNotes` field. */
  latestPostMoneyNotes?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyMetricFilter>;
  /** Filter by the object’s `operatingMarginNotes` field. */
  operatingMarginNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `operatingMarginPercentage` field. */
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyMetricFilter>>;
  /** Filter by the object’s `pathToBreakeven` field. */
  pathToBreakeven?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `pathToBrekevenNotes` field. */
  pathToBrekevenNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `potentialValueDriver` field. */
  potentialValueDriver?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sellRecommendation` field. */
  sellRecommendation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type LgCompanyMetricMaxAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<IntFilter>;
  fte?: InputMaybe<IntFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricMaxAggregates = {
  __typename?: "LgCompanyMetricMaxAggregates";
  /** Maximum of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of fte across the matching connection */
  fte?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

export type LgCompanyMetricMinAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<IntFilter>;
  fte?: InputMaybe<IntFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricMinAggregates = {
  __typename?: "LgCompanyMetricMinAggregates";
  /** Minimum of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of fte across the matching connection */
  fte?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

export type LgCompanyMetricStddevPopulationAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigFloatFilter>;
  fte?: InputMaybe<BigFloatFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricStddevPopulationAggregates = {
  __typename?: "LgCompanyMetricStddevPopulationAggregates";
  /** Population standard deviation of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of fte across the matching connection */
  fte?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

export type LgCompanyMetricStddevSampleAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigFloatFilter>;
  fte?: InputMaybe<BigFloatFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricStddevSampleAggregates = {
  __typename?: "LgCompanyMetricStddevSampleAggregates";
  /** Sample standard deviation of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of fte across the matching connection */
  fte?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

export type LgCompanyMetricSumAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigIntFilter>;
  fte?: InputMaybe<BigIntFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricSumAggregates = {
  __typename?: "LgCompanyMetricSumAggregates";
  /** Sum of arr across the matching connection */
  arr: Scalars["Float"]["output"];
  /** Sum of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage: Scalars["Float"]["output"];
  /** Sum of cashRunwayMonths across the matching connection */
  cashRunwayMonths: Scalars["BigInt"]["output"];
  /** Sum of fte across the matching connection */
  fte: Scalars["BigInt"]["output"];
  /** Sum of grossMarginPercentage across the matching connection */
  grossMarginPercentage: Scalars["Float"]["output"];
  /** Sum of impliedArr across the matching connection */
  impliedArr: Scalars["Float"]["output"];
  /** Sum of latestPostMoney across the matching connection */
  latestPostMoney: Scalars["Float"]["output"];
  /** Sum of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage: Scalars["Float"]["output"];
};

export type LgCompanyMetricVariancePopulationAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigFloatFilter>;
  fte?: InputMaybe<BigFloatFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricVariancePopulationAggregates = {
  __typename?: "LgCompanyMetricVariancePopulationAggregates";
  /** Population variance of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of fte across the matching connection */
  fte?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

export type LgCompanyMetricVarianceSampleAggregateFilter = {
  arr?: InputMaybe<FloatFilter>;
  arrGrowthPercentage?: InputMaybe<FloatFilter>;
  cashRunwayMonths?: InputMaybe<BigFloatFilter>;
  fte?: InputMaybe<BigFloatFilter>;
  grossMarginPercentage?: InputMaybe<FloatFilter>;
  impliedArr?: InputMaybe<FloatFilter>;
  latestPostMoney?: InputMaybe<FloatFilter>;
  operatingMarginPercentage?: InputMaybe<FloatFilter>;
};

export type LgCompanyMetricVarianceSampleAggregates = {
  __typename?: "LgCompanyMetricVarianceSampleAggregates";
  /** Sample variance of arr across the matching connection */
  arr?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of arrGrowthPercentage across the matching connection */
  arrGrowthPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of cashRunwayMonths across the matching connection */
  cashRunwayMonths?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of fte across the matching connection */
  fte?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of grossMarginPercentage across the matching connection */
  grossMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of impliedArr across the matching connection */
  impliedArr?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of latestPostMoney across the matching connection */
  latestPostMoney?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of operatingMarginPercentage across the matching connection */
  operatingMarginPercentage?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `LgCompanyMetric` values. */
export type LgCompanyMetricsConnection = {
  __typename?: "LgCompanyMetricsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyMetricAggregates>;
  /** A list of edges which contains the `LgCompanyMetric` and cursor to aid in pagination. */
  edges: Array<LgCompanyMetricsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyMetricAggregates>>;
  /** A list of `LgCompanyMetric` objects. */
  nodes: Array<LgCompanyMetric>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyMetric` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyMetric` values. */
export type LgCompanyMetricsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyMetricsGroupBy>;
  having?: InputMaybe<LgCompanyMetricsHavingInput>;
};

/** A `LgCompanyMetric` edge in the connection. */
export type LgCompanyMetricsEdge = {
  __typename?: "LgCompanyMetricsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyMetric` at the end of the edge. */
  node: LgCompanyMetric;
};

/** Grouping methods for `LgCompanyMetric` for usage during aggregation. */
export enum LgCompanyMetricsGroupBy {
  Arr = "ARR",
  ArrGrowthNotes = "ARR_GROWTH_NOTES",
  ArrGrowthPercentage = "ARR_GROWTH_PERCENTAGE",
  ArrNotes = "ARR_NOTES",
  AsAtDate = "AS_AT_DATE",
  AsAtDateTruncatedToDay = "AS_AT_DATE_TRUNCATED_TO_DAY",
  AsAtDateTruncatedToHour = "AS_AT_DATE_TRUNCATED_TO_HOUR",
  BuyRecommendation = "BUY_RECOMMENDATION",
  CashflowPositive = "CASHFLOW_POSITIVE",
  CashRunwayMonths = "CASH_RUNWAY_MONTHS",
  CashRunwayNotes = "CASH_RUNWAY_NOTES",
  CloseEnough = "CLOSE_ENOUGH",
  CompanyId = "COMPANY_ID",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Data = "DATA",
  DiscountToValuationQ1 = "DISCOUNT_TO_VALUATION_Q1",
  DiscountToValuationQ4 = "DISCOUNT_TO_VALUATION_Q4",
  EnoughEyes = "ENOUGH_EYES",
  Fte = "FTE",
  FteNotes = "FTE_NOTES",
  FullyFundedToBreakeven = "FULLY_FUNDED_TO_BREAKEVEN",
  GrossMarginNotes = "GROSS_MARGIN_NOTES",
  GrossMarginPercentage = "GROSS_MARGIN_PERCENTAGE",
  HowCanWeSupport = "HOW_CAN_WE_SUPPORT",
  ImpliedArr = "IMPLIED_ARR",
  ImpliedArrNotes = "IMPLIED_ARR_NOTES",
  IsHoldingCorrectValue = "IS_HOLDING_CORRECT_VALUE",
  LastUpatedBy = "LAST_UPATED_BY",
  LatestPostMoney = "LATEST_POST_MONEY",
  LatestPostMoneyNotes = "LATEST_POST_MONEY_NOTES",
  OperatingMarginNotes = "OPERATING_MARGIN_NOTES",
  OperatingMarginPercentage = "OPERATING_MARGIN_PERCENTAGE",
  PathToBreakeven = "PATH_TO_BREAKEVEN",
  PathToBrekevenNotes = "PATH_TO_BREKEVEN_NOTES",
  PotentialValueDriver = "POTENTIAL_VALUE_DRIVER",
  ReviewedBy = "REVIEWED_BY",
  SellRecommendation = "SELL_RECOMMENDATION",
  Status = "STATUS",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type LgCompanyMetricsHavingAverageInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingDistinctCountInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgCompanyMetric` aggregates. */
export type LgCompanyMetricsHavingInput = {
  AND?: InputMaybe<Array<LgCompanyMetricsHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyMetricsHavingInput>>;
  average?: InputMaybe<LgCompanyMetricsHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyMetricsHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyMetricsHavingMaxInput>;
  min?: InputMaybe<LgCompanyMetricsHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyMetricsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyMetricsHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyMetricsHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyMetricsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyMetricsHavingVarianceSampleInput>;
};

export type LgCompanyMetricsHavingMaxInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingMinInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingStddevPopulationInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingStddevSampleInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingSumInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingVariancePopulationInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsHavingVarianceSampleInput = {
  arr?: InputMaybe<HavingFloatFilter>;
  arrGrowthPercentage?: InputMaybe<HavingFloatFilter>;
  asAtDate?: InputMaybe<HavingDatetimeFilter>;
  cashRunwayMonths?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fte?: InputMaybe<HavingIntFilter>;
  grossMarginPercentage?: InputMaybe<HavingFloatFilter>;
  impliedArr?: InputMaybe<HavingFloatFilter>;
  latestPostMoney?: InputMaybe<HavingFloatFilter>;
  operatingMarginPercentage?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyMetricsInput = {
  arr?: InputMaybe<Scalars["Float"]["input"]>;
  arrGrowthNotes?: InputMaybe<Scalars["String"]["input"]>;
  arrGrowthPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  arrNotes?: InputMaybe<Scalars["String"]["input"]>;
  asAtDate: Scalars["String"]["input"];
  buyRecommendation?: InputMaybe<Scalars["String"]["input"]>;
  cashRunwayMonths?: InputMaybe<Scalars["Int"]["input"]>;
  cashRunwayNotes?: InputMaybe<Scalars["String"]["input"]>;
  cashflowPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  closeEnough?: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["ID"]["input"];
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  data?: InputMaybe<Scalars["String"]["input"]>;
  discountToValuationQ1?: InputMaybe<Scalars["String"]["input"]>;
  discountToValuationQ4?: InputMaybe<Scalars["String"]["input"]>;
  enoughEyes?: InputMaybe<Scalars["String"]["input"]>;
  fte?: InputMaybe<Scalars["Int"]["input"]>;
  fteNotes?: InputMaybe<Scalars["String"]["input"]>;
  fullyFundedToBreakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  grossMarginNotes?: InputMaybe<Scalars["String"]["input"]>;
  grossMarginPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  howCanWeSupport?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  impliedArr?: InputMaybe<Scalars["Float"]["input"]>;
  impliedArrNotes?: InputMaybe<Scalars["String"]["input"]>;
  isHoldingCorrectValue?: InputMaybe<Scalars["String"]["input"]>;
  latestPostMoney?: InputMaybe<Scalars["Float"]["input"]>;
  latestPostMoneyNotes?: InputMaybe<Scalars["String"]["input"]>;
  operatingMarginNotes?: InputMaybe<Scalars["String"]["input"]>;
  operatingMarginPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  pathToBreakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  pathToBrekevenNotes?: InputMaybe<Scalars["String"]["input"]>;
  potentialValueDriver?: InputMaybe<Scalars["String"]["input"]>;
  reviewedBy?: InputMaybe<Scalars["ID"]["input"]>;
  sellRecommendation?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

/** Methods to use when ordering `LgCompanyMetric`. */
export enum LgCompanyMetricsOrderBy {
  ArrAsc = "ARR_ASC",
  ArrDesc = "ARR_DESC",
  ArrGrowthNotesAsc = "ARR_GROWTH_NOTES_ASC",
  ArrGrowthNotesDesc = "ARR_GROWTH_NOTES_DESC",
  ArrGrowthPercentageAsc = "ARR_GROWTH_PERCENTAGE_ASC",
  ArrGrowthPercentageDesc = "ARR_GROWTH_PERCENTAGE_DESC",
  ArrNotesAsc = "ARR_NOTES_ASC",
  ArrNotesDesc = "ARR_NOTES_DESC",
  AsAtDateAsc = "AS_AT_DATE_ASC",
  AsAtDateDesc = "AS_AT_DATE_DESC",
  BuyRecommendationAsc = "BUY_RECOMMENDATION_ASC",
  BuyRecommendationDesc = "BUY_RECOMMENDATION_DESC",
  CashflowPositiveAsc = "CASHFLOW_POSITIVE_ASC",
  CashflowPositiveDesc = "CASHFLOW_POSITIVE_DESC",
  CashRunwayMonthsAsc = "CASH_RUNWAY_MONTHS_ASC",
  CashRunwayMonthsDesc = "CASH_RUNWAY_MONTHS_DESC",
  CashRunwayNotesAsc = "CASH_RUNWAY_NOTES_ASC",
  CashRunwayNotesDesc = "CASH_RUNWAY_NOTES_DESC",
  CloseEnoughAsc = "CLOSE_ENOUGH_ASC",
  CloseEnoughDesc = "CLOSE_ENOUGH_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  DiscountToValuationQ1Asc = "DISCOUNT_TO_VALUATION_Q1_ASC",
  DiscountToValuationQ1Desc = "DISCOUNT_TO_VALUATION_Q1_DESC",
  DiscountToValuationQ4Asc = "DISCOUNT_TO_VALUATION_Q4_ASC",
  DiscountToValuationQ4Desc = "DISCOUNT_TO_VALUATION_Q4_DESC",
  EnoughEyesAsc = "ENOUGH_EYES_ASC",
  EnoughEyesDesc = "ENOUGH_EYES_DESC",
  FteAsc = "FTE_ASC",
  FteDesc = "FTE_DESC",
  FteNotesAsc = "FTE_NOTES_ASC",
  FteNotesDesc = "FTE_NOTES_DESC",
  FullyFundedToBreakevenAsc = "FULLY_FUNDED_TO_BREAKEVEN_ASC",
  FullyFundedToBreakevenDesc = "FULLY_FUNDED_TO_BREAKEVEN_DESC",
  GrossMarginNotesAsc = "GROSS_MARGIN_NOTES_ASC",
  GrossMarginNotesDesc = "GROSS_MARGIN_NOTES_DESC",
  GrossMarginPercentageAsc = "GROSS_MARGIN_PERCENTAGE_ASC",
  GrossMarginPercentageDesc = "GROSS_MARGIN_PERCENTAGE_DESC",
  HowCanWeSupportAsc = "HOW_CAN_WE_SUPPORT_ASC",
  HowCanWeSupportDesc = "HOW_CAN_WE_SUPPORT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImpliedArrAsc = "IMPLIED_ARR_ASC",
  ImpliedArrDesc = "IMPLIED_ARR_DESC",
  ImpliedArrNotesAsc = "IMPLIED_ARR_NOTES_ASC",
  ImpliedArrNotesDesc = "IMPLIED_ARR_NOTES_DESC",
  IsHoldingCorrectValueAsc = "IS_HOLDING_CORRECT_VALUE_ASC",
  IsHoldingCorrectValueDesc = "IS_HOLDING_CORRECT_VALUE_DESC",
  LastUpatedByAsc = "LAST_UPATED_BY_ASC",
  LastUpatedByDesc = "LAST_UPATED_BY_DESC",
  LatestPostMoneyAsc = "LATEST_POST_MONEY_ASC",
  LatestPostMoneyDesc = "LATEST_POST_MONEY_DESC",
  LatestPostMoneyNotesAsc = "LATEST_POST_MONEY_NOTES_ASC",
  LatestPostMoneyNotesDesc = "LATEST_POST_MONEY_NOTES_DESC",
  Natural = "NATURAL",
  OperatingMarginNotesAsc = "OPERATING_MARGIN_NOTES_ASC",
  OperatingMarginNotesDesc = "OPERATING_MARGIN_NOTES_DESC",
  OperatingMarginPercentageAsc = "OPERATING_MARGIN_PERCENTAGE_ASC",
  OperatingMarginPercentageDesc = "OPERATING_MARGIN_PERCENTAGE_DESC",
  PathToBreakevenAsc = "PATH_TO_BREAKEVEN_ASC",
  PathToBreakevenDesc = "PATH_TO_BREAKEVEN_DESC",
  PathToBrekevenNotesAsc = "PATH_TO_BREKEVEN_NOTES_ASC",
  PathToBrekevenNotesDesc = "PATH_TO_BREKEVEN_NOTES_DESC",
  PotentialValueDriverAsc = "POTENTIAL_VALUE_DRIVER_ASC",
  PotentialValueDriverDesc = "POTENTIAL_VALUE_DRIVER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReviewedByAsc = "REVIEWED_BY_ASC",
  ReviewedByDesc = "REVIEWED_BY_DESC",
  SellRecommendationAsc = "SELL_RECOMMENDATION_ASC",
  SellRecommendationDesc = "SELL_RECOMMENDATION_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type LgCompanyMinAggregates = {
  __typename?: "LgCompanyMinAggregates";
  /** Minimum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyNote = Node & {
  __typename?: "LgCompanyNote";
  /** Reads a single `LgCompany` that is related to this `LgCompanyNote`. */
  company?: Maybe<LgCompany>;
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  createdBy?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  data: Scalars["JSON"]["output"];
  id: Scalars["UUID"]["output"];
  lastModifiedUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgMember` that is related to this `LgCompanyNote`. */
  lgMemberByCreatedBy?: Maybe<LgMember>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  type: Scalars["String"]["output"];
};

export type LgCompanyNoteAggregates = {
  __typename?: "LgCompanyNoteAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyNoteDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgCompanyNote` object types. */
export type LgCompanyNoteAggregatesFilter = {
  /** Distinct count aggregate over matching `LgCompanyNote` objects. */
  distinctCount?: InputMaybe<LgCompanyNoteDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyNote` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyNoteFilter>;
};

/**
 * A condition to be used against `LgCompanyNote` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyNoteCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgCompanyNoteDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  createdBy?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  data?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastModifiedUtc?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
};

export type LgCompanyNoteDistinctCountAggregates = {
  __typename?: "LgCompanyNoteDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastModifiedUtc across the matching connection */
  lastModifiedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyNote` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyNoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyNoteFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** A related `company` exists. */
  companyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgMemberByCreatedBy` relation. */
  lgMemberByCreatedBy?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberByCreatedBy` exists. */
  lgMemberByCreatedByExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyNoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyNoteFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgCompanyNote` for usage during aggregation. */
export enum LgCompanyNoteGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedBy = "CREATED_BY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Data = "DATA",
  LastModifiedUtc = "LAST_MODIFIED_UTC",
  LastModifiedUtcTruncatedToDay = "LAST_MODIFIED_UTC_TRUNCATED_TO_DAY",
  LastModifiedUtcTruncatedToHour = "LAST_MODIFIED_UTC_TRUNCATED_TO_HOUR",
  Type = "TYPE",
}

export type LgCompanyNoteHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgCompanyNote` aggregates. */
export type LgCompanyNoteHavingInput = {
  AND?: InputMaybe<Array<LgCompanyNoteHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyNoteHavingInput>>;
  average?: InputMaybe<LgCompanyNoteHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyNoteHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyNoteHavingMaxInput>;
  min?: InputMaybe<LgCompanyNoteHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyNoteHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyNoteHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyNoteHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyNoteHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyNoteHavingVarianceSampleInput>;
};

export type LgCompanyNoteHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgCompanyNoteHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompanyNote` values. */
export type LgCompanyNotesConnection = {
  __typename?: "LgCompanyNotesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyNoteAggregates>;
  /** A list of edges which contains the `LgCompanyNote` and cursor to aid in pagination. */
  edges: Array<LgCompanyNotesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyNoteAggregates>>;
  /** A list of `LgCompanyNote` objects. */
  nodes: Array<LgCompanyNote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyNote` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyNote` values. */
export type LgCompanyNotesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyNoteGroupBy>;
  having?: InputMaybe<LgCompanyNoteHavingInput>;
};

/** A `LgCompanyNote` edge in the connection. */
export type LgCompanyNotesEdge = {
  __typename?: "LgCompanyNotesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyNote` at the end of the edge. */
  node: LgCompanyNote;
};

/** Methods to use when ordering `LgCompanyNote`. */
export enum LgCompanyNotesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastModifiedUtcAsc = "LAST_MODIFIED_UTC_ASC",
  LastModifiedUtcDesc = "LAST_MODIFIED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
}

/** A connection to a list of `NzrOpportunityEmail` values, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyConnection = {
  __typename?: "LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrOpportunityEmailAggregates>;
  /** A list of edges which contains the `NzrOpportunityEmail`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrOpportunityEmailAggregates>>;
  /** A list of `NzrOpportunityEmail` objects. */
  nodes: Array<NzrOpportunityEmail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrOpportunityEmail` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrOpportunityEmail` values, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<NzrOpportunityEmailGroupBy>;
    having?: InputMaybe<NzrOpportunityEmailHavingInput>;
  };

/** A `NzrOpportunityEmail` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyEdge = {
  __typename?: "LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrOpportunityEmail` at the end of the edge. */
  node: NzrOpportunityEmail;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidatesByEmailId: NzrOpportunityEmailCandidatesConnection;
};

/** A `NzrOpportunityEmail` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type LgCompanyNzrOpportunityEmailsByNzrOpportunityEmailCandidateLgCompanyIdAndEmailIdManyToManyEdgeNzrOpportunityEmailCandidatesByEmailIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

/** A connection to a list of `OpsCompany` values, with data from `MtpCompanyfund`. */
export type LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyConnection = {
  __typename?: "LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values, with data from `MtpCompanyfund`. */
export type LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<OpsCompanyGroupBy>;
    having?: InputMaybe<OpsCompanyHavingInput>;
  };

/** A `OpsCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyEdge = {
  __typename?: "LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpOpCompanyId: MtpCompanyfundsConnection;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
};

/** A `OpsCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type LgCompanyOpsCompaniesByMtpCompanyfundMtpCompanyIdAndMtpOpCompanyIdManyToManyEdgeMtpCompanyfundsByMtpOpCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<MtpCompanyfundCondition>;
    filter?: InputMaybe<MtpCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsFund` values, with data from `MtpCompanyfund`. */
export type LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyConnection = {
  __typename?: "LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values, with data from `MtpCompanyfund`. */
export type LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection, with data from `MtpCompanyfund`. */
export type LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyEdge = {
  __typename?: "LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpFundId: MtpCompanyfundsConnection;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
};

/** A `OpsFund` edge in the connection, with data from `MtpCompanyfund`. */
export type LgCompanyOpsFundsByMtpCompanyfundMtpCompanyIdAndMtpFundIdManyToManyEdgeMtpCompanyfundsByMtpFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

/** A connection to a list of `Rule` values, with data from `Signal`. */
export type LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyConnection = {
  __typename?: "LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RuleAggregates>;
  /** A list of edges which contains the `Rule`, info from the `Signal`, and the cursor to aid in pagination. */
  edges: Array<LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RuleAggregates>>;
  /** A list of `Rule` objects. */
  nodes: Array<Rule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Rule` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Rule` values, with data from `Signal`. */
export type LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<RuleGroupBy>;
  having?: InputMaybe<RuleHavingInput>;
};

/** A `Rule` edge in the connection, with data from `Signal`. */
export type LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyEdge = {
  __typename?: "LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Rule` at the end of the edge. */
  node: Rule;
  /** Reads and enables pagination through a set of `Signal`. */
  signals: SignalsConnection;
};

/** A `Rule` edge in the connection, with data from `Signal`. */
export type LgCompanyRulesBySignalCompanyIdAndRuleIdManyToManyEdgeSignalsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type LgCompanyStddevPopulationAggregates = {
  __typename?: "LgCompanyStddevPopulationAggregates";
  /** Population standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyStddevSampleAggregates = {
  __typename?: "LgCompanyStddevSampleAggregates";
  /** Sample standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanySumAggregates = {
  __typename?: "LgCompanySumAggregates";
  /** Sum of amountUsdMillion across the matching connection */
  amountUsdMillion: Scalars["Float"]["output"];
  /** Sum of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness: Scalars["Float"]["output"];
  /** Sum of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate: Scalars["Float"]["output"];
  /** Sum of dealroomSignalRating across the matching connection */
  dealroomSignalRating: Scalars["Float"]["output"];
  /** Sum of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength: Scalars["Float"]["output"];
  /** Sum of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming: Scalars["Float"]["output"];
  /** Sum of employeesLatest across the matching connection */
  employeesLatest: Scalars["BigInt"]["output"];
  /** Sum of latestExcitement across the matching connection */
  latestExcitement: Scalars["Float"]["output"];
  /** Sum of launchYear across the matching connection */
  launchYear: Scalars["BigInt"]["output"];
  /** Sum of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding: Scalars["BigInt"]["output"];
  /** Sum of totalFunding across the matching connection */
  totalFunding: Scalars["BigFloat"]["output"];
};

export type LgCompanyTeam = Node & {
  __typename?: "LgCompanyTeam";
  /** Reads a single `LgCompany` that is related to this `LgCompanyTeam`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  compositeId: Scalars["String"]["output"];
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `LgFounder` that is related to this `LgCompanyTeam`. */
  member?: Maybe<LgFounder>;
  memberId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  past?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads and enables pagination through a set of `SrcCompanyTeam`. */
  srcCompanyTeamsByEntityId: SrcCompanyTeamsConnection;
  titles?: Maybe<Scalars["JSON"]["output"]>;
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyTeamSrcCompanyTeamsByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyTeamCondition>;
  filter?: InputMaybe<SrcCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyTeamsOrderBy>>;
};

export type LgCompanyTeamAggregates = {
  __typename?: "LgCompanyTeamAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgCompanyTeamAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgCompanyTeamDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgCompanyTeamMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgCompanyTeamMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgCompanyTeamStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgCompanyTeamStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgCompanyTeamSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgCompanyTeamVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgCompanyTeamVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgCompanyTeam` object types. */
export type LgCompanyTeamAggregatesFilter = {
  /** Mean average aggregate over matching `LgCompanyTeam` objects. */
  average?: InputMaybe<LgCompanyTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgCompanyTeam` objects. */
  distinctCount?: InputMaybe<LgCompanyTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgCompanyTeam` object to be included within the aggregate. */
  filter?: InputMaybe<LgCompanyTeamFilter>;
  /** Maximum aggregate over matching `LgCompanyTeam` objects. */
  max?: InputMaybe<LgCompanyTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgCompanyTeam` objects. */
  min?: InputMaybe<LgCompanyTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgCompanyTeam` objects. */
  stddevPopulation?: InputMaybe<LgCompanyTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgCompanyTeam` objects. */
  stddevSample?: InputMaybe<LgCompanyTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgCompanyTeam` objects. */
  sum?: InputMaybe<LgCompanyTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `LgCompanyTeam` objects. */
  variancePopulation?: InputMaybe<LgCompanyTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgCompanyTeam` objects. */
  varianceSample?: InputMaybe<LgCompanyTeamVarianceSampleAggregateFilter>;
};

export type LgCompanyTeamAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyTeamAverageAggregates = {
  __typename?: "LgCompanyTeamAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgCompanyTeam` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgCompanyTeamCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `isFounder` field. */
  isFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `past` field. */
  past?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `titles` field. */
  titles?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `yearEnd` field. */
  yearEnd?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `yearStart` field. */
  yearStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LgCompanyTeamDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  compositeId?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isFounder?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  past?: InputMaybe<BigIntFilter>;
  titles?: InputMaybe<BigIntFilter>;
  yearEnd?: InputMaybe<BigIntFilter>;
  yearStart?: InputMaybe<BigIntFilter>;
};

export type LgCompanyTeamDistinctCountAggregates = {
  __typename?: "LgCompanyTeamDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFounder across the matching connection */
  isFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of past across the matching connection */
  past?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of titles across the matching connection */
  titles?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyTeamFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgCompanyTeamFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isFounder` field. */
  isFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<LgFounderFilter>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgCompanyTeamFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgCompanyTeamFilter>>;
  /** Filter by the object’s `past` field. */
  past?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `srcCompanyTeamsByEntityId` relation. */
  srcCompanyTeamsByEntityId?: InputMaybe<LgCompanyTeamToManySrcCompanyTeamFilter>;
  /** Some related `srcCompanyTeamsByEntityId` exist. */
  srcCompanyTeamsByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `titles` field. */
  titles?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `yearEnd` field. */
  yearEnd?: InputMaybe<IntFilter>;
  /** Filter by the object’s `yearStart` field. */
  yearStart?: InputMaybe<IntFilter>;
};

/** Grouping methods for `LgCompanyTeam` for usage during aggregation. */
export enum LgCompanyTeamGroupBy {
  CompanyId = "COMPANY_ID",
  Deleted = "DELETED",
  IsFounder = "IS_FOUNDER",
  MemberId = "MEMBER_ID",
  Past = "PAST",
  Titles = "TITLES",
  YearEnd = "YEAR_END",
  YearStart = "YEAR_START",
}

export type LgCompanyTeamHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgCompanyTeam` aggregates. */
export type LgCompanyTeamHavingInput = {
  AND?: InputMaybe<Array<LgCompanyTeamHavingInput>>;
  OR?: InputMaybe<Array<LgCompanyTeamHavingInput>>;
  average?: InputMaybe<LgCompanyTeamHavingAverageInput>;
  distinctCount?: InputMaybe<LgCompanyTeamHavingDistinctCountInput>;
  max?: InputMaybe<LgCompanyTeamHavingMaxInput>;
  min?: InputMaybe<LgCompanyTeamHavingMinInput>;
  stddevPopulation?: InputMaybe<LgCompanyTeamHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgCompanyTeamHavingStddevSampleInput>;
  sum?: InputMaybe<LgCompanyTeamHavingSumInput>;
  variancePopulation?: InputMaybe<LgCompanyTeamHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgCompanyTeamHavingVarianceSampleInput>;
};

export type LgCompanyTeamHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  yearEnd?: InputMaybe<HavingIntFilter>;
  yearStart?: InputMaybe<HavingIntFilter>;
};

export type LgCompanyTeamMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
  yearEnd?: InputMaybe<IntFilter>;
  yearStart?: InputMaybe<IntFilter>;
};

export type LgCompanyTeamMaxAggregates = {
  __typename?: "LgCompanyTeamMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyTeamMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
  yearEnd?: InputMaybe<IntFilter>;
  yearStart?: InputMaybe<IntFilter>;
};

export type LgCompanyTeamMinAggregates = {
  __typename?: "LgCompanyTeamMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

export type LgCompanyTeamStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyTeamStddevPopulationAggregates = {
  __typename?: "LgCompanyTeamStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyTeamStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyTeamStddevSampleAggregates = {
  __typename?: "LgCompanyTeamStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyTeamSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
  yearEnd?: InputMaybe<BigIntFilter>;
  yearStart?: InputMaybe<BigIntFilter>;
};

export type LgCompanyTeamSumAggregates = {
  __typename?: "LgCompanyTeamSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of yearEnd across the matching connection */
  yearEnd: Scalars["BigInt"]["output"];
  /** Sum of yearStart across the matching connection */
  yearStart: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `SrcCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyTeamToManySrcCompanyTeamFilter = {
  /** Aggregates across related `SrcCompanyTeam` match the filter criteria. */
  aggregates?: InputMaybe<SrcCompanyTeamAggregatesFilter>;
  /** Every related `SrcCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcCompanyTeamFilter>;
  /** No related `SrcCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcCompanyTeamFilter>;
  /** Some related `SrcCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcCompanyTeamFilter>;
};

export type LgCompanyTeamVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyTeamVariancePopulationAggregates = {
  __typename?: "LgCompanyTeamVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyTeamVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  yearEnd?: InputMaybe<BigFloatFilter>;
  yearStart?: InputMaybe<BigFloatFilter>;
};

export type LgCompanyTeamVarianceSampleAggregates = {
  __typename?: "LgCompanyTeamVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of yearEnd across the matching connection */
  yearEnd?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of yearStart across the matching connection */
  yearStart?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgCompanyTeam` values. */
export type LgCompanyTeamsConnection = {
  __typename?: "LgCompanyTeamsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyTeamAggregates>;
  /** A list of edges which contains the `LgCompanyTeam` and cursor to aid in pagination. */
  edges: Array<LgCompanyTeamsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyTeamAggregates>>;
  /** A list of `LgCompanyTeam` objects. */
  nodes: Array<LgCompanyTeam>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompanyTeam` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompanyTeam` values. */
export type LgCompanyTeamsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyTeamGroupBy>;
  having?: InputMaybe<LgCompanyTeamHavingInput>;
};

/** A `LgCompanyTeam` edge in the connection. */
export type LgCompanyTeamsEdge = {
  __typename?: "LgCompanyTeamsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompanyTeam` at the end of the edge. */
  node: LgCompanyTeam;
};

/** Methods to use when ordering `LgCompanyTeam`. */
export enum LgCompanyTeamsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFounderAsc = "IS_FOUNDER_ASC",
  IsFounderDesc = "IS_FOUNDER_DESC",
  MemberIdAsc = "MEMBER_ID_ASC",
  MemberIdDesc = "MEMBER_ID_DESC",
  Natural = "NATURAL",
  PastAsc = "PAST_ASC",
  PastDesc = "PAST_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SrcCompanyTeamsByEntityIdAverageCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdAverageCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdAverageEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdAverageEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdAverageIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcCompanyTeamsByEntityIdAverageIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcCompanyTeamsByEntityIdAverageLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdAverageLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdAverageSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdAverageSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdAverageSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdAverageSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdCountAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_COUNT_ASC",
  SrcCompanyTeamsByEntityIdCountDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_COUNT_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdDistinctCountSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdDistinctCountSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdMaxCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdMaxCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdMaxEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdMaxEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdMaxIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcCompanyTeamsByEntityIdMaxIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcCompanyTeamsByEntityIdMaxLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdMaxLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdMaxSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdMaxSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdMaxSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdMaxSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdMinCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdMinCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdMinEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdMinEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdMinIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcCompanyTeamsByEntityIdMinIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcCompanyTeamsByEntityIdMinLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdMinLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdMinSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdMinSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdMinSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdMinSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevPopulationSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdStddevPopulationSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdStddevSampleSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdStddevSampleSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdSumCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdSumCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdSumEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdSumEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdSumIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcCompanyTeamsByEntityIdSumIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcCompanyTeamsByEntityIdSumLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdSumLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdSumSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdSumSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdSumSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdSumSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdVariancePopulationSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdVariancePopulationSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleCreatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleCreatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleEntityIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleEntityIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleSourceIdAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleSourceIdDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcCompanyTeamsByEntityIdVarianceSampleSourceTypeAsc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcCompanyTeamsByEntityIdVarianceSampleSourceTypeDesc = "SRC_COMPANY_TEAMS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  TitlesAsc = "TITLES_ASC",
  TitlesDesc = "TITLES_DESC",
  YearEndAsc = "YEAR_END_ASC",
  YearEndDesc = "YEAR_END_DESC",
  YearStartAsc = "YEAR_START_ASC",
  YearStartDesc = "YEAR_START_DESC",
}

/** A filter to be used against many `LgCompanyComment` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyCommentFilter = {
  /** Aggregates across related `LgCompanyComment` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyCommentAggregatesFilter>;
  /** Every related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyCommentFilter>;
  /** No related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyCommentFilter>;
  /** Some related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyCommentFilter>;
};

/** A filter to be used against many `LgCompanyFile` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyFileFilter = {
  /** Aggregates across related `LgCompanyFile` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyFileAggregatesFilter>;
  /** Every related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyFileFilter>;
  /** No related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyFileFilter>;
  /** Some related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyFileFilter>;
};

/** A filter to be used against many `LgCompanyFlag` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyFlagFilter = {
  /** Aggregates across related `LgCompanyFlag` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyFlagAggregatesFilter>;
  /** Every related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyFlagFilter>;
  /** No related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyFlagFilter>;
  /** Some related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyFlagFilter>;
};

/** A filter to be used against many `LgCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyInvestorFilter = {
  /** Aggregates across related `LgCompanyInvestor` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyInvestorAggregatesFilter>;
  /** Every related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyInvestorFilter>;
  /** No related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyInvestorFilter>;
  /** Some related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyInvestorFilter>;
};

/** A filter to be used against many `LgCompanyMetric` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyMetricFilter = {
  /** Aggregates across related `LgCompanyMetric` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyMetricAggregatesFilter>;
  /** Every related `LgCompanyMetric` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyMetricFilter>;
  /** No related `LgCompanyMetric` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyMetricFilter>;
  /** Some related `LgCompanyMetric` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyMetricFilter>;
};

/** A filter to be used against many `LgCompanyNote` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyNoteFilter = {
  /** Aggregates across related `LgCompanyNote` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyNoteAggregatesFilter>;
  /** Every related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyNoteFilter>;
  /** No related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyNoteFilter>;
  /** Some related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyNoteFilter>;
};

/** A filter to be used against many `LgCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgCompanyTeamFilter = {
  /** Aggregates across related `LgCompanyTeam` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyTeamAggregatesFilter>;
  /** Every related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyTeamFilter>;
  /** No related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyTeamFilter>;
  /** Some related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyTeamFilter>;
};

/** A filter to be used against many `LgFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyLgFundingRoundFilter = {
  /** Aggregates across related `LgFundingRound` match the filter criteria. */
  aggregates?: InputMaybe<LgFundingRoundAggregatesFilter>;
  /** Every related `LgFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgFundingRoundFilter>;
  /** No related `LgFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgFundingRoundFilter>;
  /** Some related `LgFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgFundingRoundFilter>;
};

/** A filter to be used against many `MtpCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyMtpCompanyfundFilter = {
  /** Aggregates across related `MtpCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<MtpCompanyfundAggregatesFilter>;
  /** Every related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MtpCompanyfundFilter>;
  /** No related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MtpCompanyfundFilter>;
  /** Some related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MtpCompanyfundFilter>;
};

/** A filter to be used against many `NzrOpportunityEmailCandidate` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyNzrOpportunityEmailCandidateFilter = {
  /** Aggregates across related `NzrOpportunityEmailCandidate` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityEmailCandidateAggregatesFilter>;
  /** Every related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** No related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** Some related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
};

/** A filter to be used against many `NzrSentimentForm` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyNzrSentimentFormFilter = {
  /** Aggregates across related `NzrSentimentForm` match the filter criteria. */
  aggregates?: InputMaybe<NzrSentimentFormAggregatesFilter>;
  /** Every related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrSentimentFormFilter>;
  /** No related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrSentimentFormFilter>;
  /** Some related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrSentimentFormFilter>;
};

/** A filter to be used against many `NzrUserCompanyTag` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManyNzrUserCompanyTagFilter = {
  /** Aggregates across related `NzrUserCompanyTag` match the filter criteria. */
  aggregates?: InputMaybe<NzrUserCompanyTagAggregatesFilter>;
  /** Every related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrUserCompanyTagFilter>;
  /** No related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrUserCompanyTagFilter>;
  /** Some related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrUserCompanyTagFilter>;
};

/** A filter to be used against many `Signal` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManySignalFilter = {
  /** Aggregates across related `Signal` match the filter criteria. */
  aggregates?: InputMaybe<SignalAggregatesFilter>;
  /** Every related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SignalFilter>;
  /** No related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SignalFilter>;
  /** Some related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SignalFilter>;
};

/** A filter to be used against many `SrcCompany` object types. All fields are combined with a logical ‘and.’ */
export type LgCompanyToManySrcCompanyFilter = {
  /** Aggregates across related `SrcCompany` match the filter criteria. */
  aggregates?: InputMaybe<SrcCompanyAggregatesFilter>;
  /** Every related `SrcCompany` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcCompanyFilter>;
  /** No related `SrcCompany` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcCompanyFilter>;
  /** Some related `SrcCompany` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcCompanyFilter>;
};

export type LgCompanyVariancePopulationAggregates = {
  __typename?: "LgCompanyVariancePopulationAggregates";
  /** Population variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgCompanyVarianceSampleAggregates = {
  __typename?: "LgCompanyVarianceSampleAggregates";
  /** Sample variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalCompleteness across the matching connection */
  dealroomSignalCompleteness?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalGrowthRate across the matching connection */
  dealroomSignalGrowthRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalRating across the matching connection */
  dealroomSignalRating?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalTeamStrength across the matching connection */
  dealroomSignalTeamStrength?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of dealroomSignalTiming across the matching connection */
  dealroomSignalTiming?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of latestExcitement across the matching connection */
  latestExcitement?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of monthsFromLastFunding across the matching connection */
  monthsFromLastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFile = Node & {
  __typename?: "LgFile";
  createdBy?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyFileFileIdAndCompanyId: LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompanyFile`. */
  lgCompanyFilesByFileId: LgCompanyFilesConnection;
  /** Reads a single `LgMember` that is related to this `LgFile`. */
  lgMemberByCreatedBy?: Maybe<LgMember>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  path: Scalars["String"]["output"];
  source: LgFileSource;
  status: Scalars["String"]["output"];
};

export type LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgFileLgCompanyFilesByFileIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFileCondition>;
  filter?: InputMaybe<LgCompanyFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFilesOrderBy>>;
};

export type LgFileAggregates = {
  __typename?: "LgFileAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgFileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgFile` object types. */
export type LgFileAggregatesFilter = {
  /** Distinct count aggregate over matching `LgFile` objects. */
  distinctCount?: InputMaybe<LgFileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgFile` object to be included within the aggregate. */
  filter?: InputMaybe<LgFileFilter>;
};

/** A condition to be used against `LgFile` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LgFileCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<LgFileSource>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgFileDistinctCountAggregateFilter = {
  createdBy?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  metadata?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  path?: InputMaybe<BigIntFilter>;
  source?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
};

export type LgFileDistinctCountAggregates = {
  __typename?: "LgFileDistinctCountAggregates";
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgFile` object types. All fields are combined with a logical ‘and.’ */
export type LgFileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgFileFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lgCompanyFilesByFileId` relation. */
  lgCompanyFilesByFileId?: InputMaybe<LgFileToManyLgCompanyFileFilter>;
  /** Some related `lgCompanyFilesByFileId` exist. */
  lgCompanyFilesByFileIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgMemberByCreatedBy` relation. */
  lgMemberByCreatedBy?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberByCreatedBy` exists. */
  lgMemberByCreatedByExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgFileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgFileFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<LgFileSourceFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgFile` for usage during aggregation. */
export enum LgFileGroupBy {
  CreatedBy = "CREATED_BY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Metadata = "METADATA",
  Name = "NAME",
  Path = "PATH",
  Source = "SOURCE",
  Status = "STATUS",
}

export type LgFileHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgFile` aggregates. */
export type LgFileHavingInput = {
  AND?: InputMaybe<Array<LgFileHavingInput>>;
  OR?: InputMaybe<Array<LgFileHavingInput>>;
  average?: InputMaybe<LgFileHavingAverageInput>;
  distinctCount?: InputMaybe<LgFileHavingDistinctCountInput>;
  max?: InputMaybe<LgFileHavingMaxInput>;
  min?: InputMaybe<LgFileHavingMinInput>;
  stddevPopulation?: InputMaybe<LgFileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgFileHavingStddevSampleInput>;
  sum?: InputMaybe<LgFileHavingSumInput>;
  variancePopulation?: InputMaybe<LgFileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgFileHavingVarianceSampleInput>;
};

export type LgFileHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFileHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyFile`. */
export type LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyFile`, and the cursor to aid in pagination. */
  edges: Array<LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyFile`. */
export type LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyFile`. */
export type LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyFile`. */
  lgCompanyFilesByCompanyId: LgCompanyFilesConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyFile`. */
export type LgFileLgCompaniesByLgCompanyFileFileIdAndCompanyIdManyToManyEdgeLgCompanyFilesByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFileCondition>;
  filter?: InputMaybe<LgCompanyFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFilesOrderBy>>;
};

export enum LgFileSource {
  Nazare = "NAZARE",
  Trello = "TRELLO",
}

/** A filter to be used against LgFileSource fields. All fields are combined with a logical ‘and.’ */
export type LgFileSourceFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LgFileSource>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LgFileSource>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LgFileSource>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LgFileSource>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LgFileSource>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LgFileSource>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LgFileSource>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LgFileSource>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LgFileSource>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LgFileSource>>;
};

/** A filter to be used against many `LgCompanyFile` object types. All fields are combined with a logical ‘and.’ */
export type LgFileToManyLgCompanyFileFilter = {
  /** Aggregates across related `LgCompanyFile` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyFileAggregatesFilter>;
  /** Every related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyFileFilter>;
  /** No related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyFileFilter>;
  /** Some related `LgCompanyFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyFileFilter>;
};

/** A connection to a list of `LgFile` values. */
export type LgFilesConnection = {
  __typename?: "LgFilesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFileAggregates>;
  /** A list of edges which contains the `LgFile` and cursor to aid in pagination. */
  edges: Array<LgFilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFileAggregates>>;
  /** A list of `LgFile` objects. */
  nodes: Array<LgFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFile` values. */
export type LgFilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFileGroupBy>;
  having?: InputMaybe<LgFileHavingInput>;
};

/** A `LgFile` edge in the connection. */
export type LgFilesEdge = {
  __typename?: "LgFilesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFile` at the end of the edge. */
  node: LgFile;
};

/** Methods to use when ordering `LgFile`. */
export enum LgFilesOrderBy {
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LgCompanyFilesByFileIdAverageCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdAverageCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdAverageFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_FILE_ID_ASC",
  LgCompanyFilesByFileIdAverageFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_FILE_ID_DESC",
  LgCompanyFilesByFileIdAverageIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_ID_ASC",
  LgCompanyFilesByFileIdAverageIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_AVERAGE_ID_DESC",
  LgCompanyFilesByFileIdCountAsc = "LG_COMPANY_FILES_BY_FILE_ID_COUNT_ASC",
  LgCompanyFilesByFileIdCountDesc = "LG_COMPANY_FILES_BY_FILE_ID_COUNT_DESC",
  LgCompanyFilesByFileIdDistinctCountCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdDistinctCountCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdDistinctCountFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_FILE_ID_ASC",
  LgCompanyFilesByFileIdDistinctCountFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_FILE_ID_DESC",
  LgCompanyFilesByFileIdDistinctCountIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyFilesByFileIdDistinctCountIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyFilesByFileIdMaxCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdMaxCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdMaxFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_FILE_ID_ASC",
  LgCompanyFilesByFileIdMaxFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_FILE_ID_DESC",
  LgCompanyFilesByFileIdMaxIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_ID_ASC",
  LgCompanyFilesByFileIdMaxIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MAX_ID_DESC",
  LgCompanyFilesByFileIdMinCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdMinCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdMinFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_FILE_ID_ASC",
  LgCompanyFilesByFileIdMinFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_FILE_ID_DESC",
  LgCompanyFilesByFileIdMinIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_ID_ASC",
  LgCompanyFilesByFileIdMinIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_MIN_ID_DESC",
  LgCompanyFilesByFileIdStddevPopulationCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdStddevPopulationCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdStddevPopulationFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_FILE_ID_ASC",
  LgCompanyFilesByFileIdStddevPopulationFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_FILE_ID_DESC",
  LgCompanyFilesByFileIdStddevPopulationIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyFilesByFileIdStddevPopulationIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyFilesByFileIdStddevSampleCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdStddevSampleCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdStddevSampleFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_FILE_ID_ASC",
  LgCompanyFilesByFileIdStddevSampleFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_FILE_ID_DESC",
  LgCompanyFilesByFileIdStddevSampleIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyFilesByFileIdStddevSampleIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyFilesByFileIdSumCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdSumCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdSumFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_FILE_ID_ASC",
  LgCompanyFilesByFileIdSumFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_FILE_ID_DESC",
  LgCompanyFilesByFileIdSumIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_ID_ASC",
  LgCompanyFilesByFileIdSumIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_SUM_ID_DESC",
  LgCompanyFilesByFileIdVariancePopulationCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdVariancePopulationCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdVariancePopulationFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_FILE_ID_ASC",
  LgCompanyFilesByFileIdVariancePopulationFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_FILE_ID_DESC",
  LgCompanyFilesByFileIdVariancePopulationIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyFilesByFileIdVariancePopulationIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyFilesByFileIdVarianceSampleCompanyIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFilesByFileIdVarianceSampleCompanyIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFilesByFileIdVarianceSampleFileIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_FILE_ID_ASC",
  LgCompanyFilesByFileIdVarianceSampleFileIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_FILE_ID_DESC",
  LgCompanyFilesByFileIdVarianceSampleIdAsc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyFilesByFileIdVarianceSampleIdDesc = "LG_COMPANY_FILES_BY_FILE_ID_VARIANCE_SAMPLE_ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
}

export type LgFounder = Node & {
  __typename?: "LgFounder";
  angellistUrl?: Maybe<Scalars["String"]["output"]>;
  appstoreAppId?: Maybe<Scalars["String"]["output"]>;
  backgrounds?: Maybe<Scalars["JSON"]["output"]>;
  companies?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  crunchbaseUrl?: Maybe<Scalars["String"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  founderScore?: Maybe<Scalars["Int"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  googleUrl?: Maybe<Scalars["String"]["output"]>;
  hqLocations?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isPromisingFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isSerialFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isStrongFounder?: Maybe<Scalars["Boolean"]["output"]>;
  isSuperFounder?: Maybe<Scalars["Boolean"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyTeamMemberIdAndCompanyId: LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByNzrSentimentFormFounderIdAndCompanyId: LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompanyTeam`. */
  lgCompanyTeamsByMemberId: LgCompanyTeamsConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrSentimentFormFounderIdAndCreatedById: LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyConnection;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByFounderId: NzrSentimentFormsConnection;
  path?: Maybe<Scalars["String"]["output"]>;
  playmarketAppId?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `SrcFounder`. */
  srcFoundersByEntityId: SrcFoundersConnection;
  tagline?: Maybe<Scalars["String"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  universities?: Maybe<Scalars["JSON"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgFounderLgCompanyTeamsByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyTeamCondition>;
  filter?: InputMaybe<LgCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyTeamsOrderBy>>;
};

export type LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgFounderNzrSentimentFormsByFounderIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

export type LgFounderSrcFoundersByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFounderCondition>;
  filter?: InputMaybe<SrcFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFoundersOrderBy>>;
};

export type LgFounderAggregates = {
  __typename?: "LgFounderAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgFounderAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgFounderDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgFounderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgFounderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgFounderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgFounderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgFounderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgFounderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgFounderVarianceSampleAggregates>;
};

export type LgFounderAverageAggregates = {
  __typename?: "LgFounderAverageAggregates";
  /** Mean average of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgFounder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LgFounderCondition = {
  /** Checks for equality with the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `backgrounds` field. */
  backgrounds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companies` field. */
  companies?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `foundedCompaniesTotalFunding` field. */
  foundedCompaniesTotalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `founderScore` field. */
  founderScore?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `gender` field. */
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isFounder` field. */
  isFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isPromisingFounder` field. */
  isPromisingFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isSerialFounder` field. */
  isSerialFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isStrongFounder` field. */
  isStrongFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isSuperFounder` field. */
  isSuperFounder?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `universities` field. */
  universities?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgFounderDistinctCountAggregates = {
  __typename?: "LgFounderDistinctCountAggregates";
  /** Distinct count of angellistUrl across the matching connection */
  angellistUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appstoreAppId across the matching connection */
  appstoreAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of backgrounds across the matching connection */
  backgrounds?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companies across the matching connection */
  companies?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of gender across the matching connection */
  gender?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleUrl across the matching connection */
  googleUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqLocations across the matching connection */
  hqLocations?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFounder across the matching connection */
  isFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isPromisingFounder across the matching connection */
  isPromisingFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isSerialFounder across the matching connection */
  isSerialFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isStrongFounder across the matching connection */
  isStrongFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isSuperFounder across the matching connection */
  isSuperFounder?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of playmarketAppId across the matching connection */
  playmarketAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of universities across the matching connection */
  universities?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgFounder` object types. All fields are combined with a logical ‘and.’ */
export type LgFounderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgFounderFilter>>;
  /** Filter by the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `backgrounds` field. */
  backgrounds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `companies` field. */
  companies?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `foundedCompaniesTotalFunding` field. */
  foundedCompaniesTotalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `founderScore` field. */
  founderScore?: InputMaybe<IntFilter>;
  /** Filter by the object’s `gender` field. */
  gender?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isFounder` field. */
  isFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPromisingFounder` field. */
  isPromisingFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSerialFounder` field. */
  isSerialFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isStrongFounder` field. */
  isStrongFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSuperFounder` field. */
  isSuperFounder?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgCompanyTeamsByMemberId` relation. */
  lgCompanyTeamsByMemberId?: InputMaybe<LgFounderToManyLgCompanyTeamFilter>;
  /** Some related `lgCompanyTeamsByMemberId` exist. */
  lgCompanyTeamsByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgFounderFilter>;
  /** Filter by the object’s `nzrSentimentFormsByFounderId` relation. */
  nzrSentimentFormsByFounderId?: InputMaybe<LgFounderToManyNzrSentimentFormFilter>;
  /** Some related `nzrSentimentFormsByFounderId` exist. */
  nzrSentimentFormsByFounderIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgFounderFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `srcFoundersByEntityId` relation. */
  srcFoundersByEntityId?: InputMaybe<LgFounderToManySrcFounderFilter>;
  /** Some related `srcFoundersByEntityId` exist. */
  srcFoundersByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `universities` field. */
  universities?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgFounder` for usage during aggregation. */
export enum LgFounderGroupBy {
  AngellistUrl = "ANGELLIST_URL",
  AppstoreAppId = "APPSTORE_APP_ID",
  Backgrounds = "BACKGROUNDS",
  Companies = "COMPANIES",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  CrunchbaseUrl = "CRUNCHBASE_URL",
  Deleted = "DELETED",
  FacebookUrl = "FACEBOOK_URL",
  FoundedCompaniesTotalFunding = "FOUNDED_COMPANIES_TOTAL_FUNDING",
  FounderScore = "FOUNDER_SCORE",
  Gender = "GENDER",
  GoogleUrl = "GOOGLE_URL",
  HqLocations = "HQ_LOCATIONS",
  Image = "IMAGE",
  IsFounder = "IS_FOUNDER",
  IsPromisingFounder = "IS_PROMISING_FOUNDER",
  IsSerialFounder = "IS_SERIAL_FOUNDER",
  IsStrongFounder = "IS_STRONG_FOUNDER",
  IsSuperFounder = "IS_SUPER_FOUNDER",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LinkedinUrl = "LINKEDIN_URL",
  Name = "NAME",
  Path = "PATH",
  PlaymarketAppId = "PLAYMARKET_APP_ID",
  Tagline = "TAGLINE",
  TwitterUrl = "TWITTER_URL",
  Type = "TYPE",
  Universities = "UNIVERSITIES",
  Url = "URL",
  WebsiteUrl = "WEBSITE_URL",
}

export type LgFounderHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgFounder` aggregates. */
export type LgFounderHavingInput = {
  AND?: InputMaybe<Array<LgFounderHavingInput>>;
  OR?: InputMaybe<Array<LgFounderHavingInput>>;
  average?: InputMaybe<LgFounderHavingAverageInput>;
  distinctCount?: InputMaybe<LgFounderHavingDistinctCountInput>;
  max?: InputMaybe<LgFounderHavingMaxInput>;
  min?: InputMaybe<LgFounderHavingMinInput>;
  stddevPopulation?: InputMaybe<LgFounderHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgFounderHavingStddevSampleInput>;
  sum?: InputMaybe<LgFounderHavingSumInput>;
  variancePopulation?: InputMaybe<LgFounderHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgFounderHavingVarianceSampleInput>;
};

export type LgFounderHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgFounderHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  foundedCompaniesTotalFunding?: InputMaybe<HavingBigfloatFilter>;
  founderScore?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyTeam`. */
export type LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyTeam`, and the cursor to aid in pagination. */
  edges: Array<LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyTeam`. */
export type LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyTeam`. */
export type LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgFounderLgCompaniesByLgCompanyTeamMemberIdAndCompanyIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isFounder?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  past?: Maybe<Scalars["Boolean"]["output"]>;
  titles?: Maybe<Scalars["JSON"]["output"]>;
  yearEnd?: Maybe<Scalars["Int"]["output"]>;
  yearStart?: Maybe<Scalars["Int"]["output"]>;
};

/** A connection to a list of `LgCompany` values, with data from `NzrSentimentForm`. */
export type LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `NzrSentimentForm`. */
export type LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `NzrSentimentForm`. */
export type LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCompanyId: NzrSentimentFormsConnection;
};

/** A `LgCompany` edge in the connection, with data from `NzrSentimentForm`. */
export type LgFounderLgCompaniesByNzrSentimentFormFounderIdAndCompanyIdManyToManyEdgeNzrSentimentFormsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormCondition>;
    filter?: InputMaybe<NzrSentimentFormFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    includeDeleted?: InputMaybe<IncludeDeletedOption>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
  };

/** A connection to a list of `LgMember` values, with data from `NzrSentimentForm`. */
export type LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyConnection = {
  __typename?: "LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrSentimentForm`. */
export type LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrSentimentForm`. */
export type LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyEdge = {
  __typename?: "LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCreatedById: NzrSentimentFormsConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrSentimentForm`. */
export type LgFounderLgMembersByNzrSentimentFormFounderIdAndCreatedByIdManyToManyEdgeNzrSentimentFormsByCreatedByIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormCondition>;
    filter?: InputMaybe<NzrSentimentFormFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    includeDeleted?: InputMaybe<IncludeDeletedOption>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
  };

export type LgFounderMaxAggregates = {
  __typename?: "LgFounderMaxAggregates";
  /** Maximum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFounderMinAggregates = {
  __typename?: "LgFounderMinAggregates";
  /** Minimum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFounderStddevPopulationAggregates = {
  __typename?: "LgFounderStddevPopulationAggregates";
  /** Population standard deviation of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFounderStddevSampleAggregates = {
  __typename?: "LgFounderStddevSampleAggregates";
  /** Sample standard deviation of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFounderSumAggregates = {
  __typename?: "LgFounderSumAggregates";
  /** Sum of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding: Scalars["BigFloat"]["output"];
  /** Sum of founderScore across the matching connection */
  founderScore: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `LgCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type LgFounderToManyLgCompanyTeamFilter = {
  /** Aggregates across related `LgCompanyTeam` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyTeamAggregatesFilter>;
  /** Every related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyTeamFilter>;
  /** No related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyTeamFilter>;
  /** Some related `LgCompanyTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyTeamFilter>;
};

/** A filter to be used against many `NzrSentimentForm` object types. All fields are combined with a logical ‘and.’ */
export type LgFounderToManyNzrSentimentFormFilter = {
  /** Aggregates across related `NzrSentimentForm` match the filter criteria. */
  aggregates?: InputMaybe<NzrSentimentFormAggregatesFilter>;
  /** Every related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrSentimentFormFilter>;
  /** No related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrSentimentFormFilter>;
  /** Some related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrSentimentFormFilter>;
};

/** A filter to be used against many `SrcFounder` object types. All fields are combined with a logical ‘and.’ */
export type LgFounderToManySrcFounderFilter = {
  /** Aggregates across related `SrcFounder` match the filter criteria. */
  aggregates?: InputMaybe<SrcFounderAggregatesFilter>;
  /** Every related `SrcFounder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcFounderFilter>;
  /** No related `SrcFounder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcFounderFilter>;
  /** Some related `SrcFounder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcFounderFilter>;
};

export type LgFounderVariancePopulationAggregates = {
  __typename?: "LgFounderVariancePopulationAggregates";
  /** Population variance of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFounderVarianceSampleAggregates = {
  __typename?: "LgFounderVarianceSampleAggregates";
  /** Sample variance of foundedCompaniesTotalFunding across the matching connection */
  foundedCompaniesTotalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of founderScore across the matching connection */
  founderScore?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgFounder` values. */
export type LgFoundersConnection = {
  __typename?: "LgFoundersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFounderAggregates>;
  /** A list of edges which contains the `LgFounder` and cursor to aid in pagination. */
  edges: Array<LgFoundersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFounderAggregates>>;
  /** A list of `LgFounder` objects. */
  nodes: Array<LgFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFounder` values. */
export type LgFoundersConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFounderGroupBy>;
  having?: InputMaybe<LgFounderHavingInput>;
};

/** A `LgFounder` edge in the connection. */
export type LgFoundersEdge = {
  __typename?: "LgFoundersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFounder` at the end of the edge. */
  node: LgFounder;
};

/** Methods to use when ordering `LgFounder`. */
export enum LgFoundersOrderBy {
  AngellistUrlAsc = "ANGELLIST_URL_ASC",
  AngellistUrlDesc = "ANGELLIST_URL_DESC",
  AppstoreAppIdAsc = "APPSTORE_APP_ID_ASC",
  AppstoreAppIdDesc = "APPSTORE_APP_ID_DESC",
  BackgroundsAsc = "BACKGROUNDS_ASC",
  BackgroundsDesc = "BACKGROUNDS_DESC",
  CompaniesAsc = "COMPANIES_ASC",
  CompaniesDesc = "COMPANIES_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CrunchbaseUrlAsc = "CRUNCHBASE_URL_ASC",
  CrunchbaseUrlDesc = "CRUNCHBASE_URL_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  FacebookUrlAsc = "FACEBOOK_URL_ASC",
  FacebookUrlDesc = "FACEBOOK_URL_DESC",
  FoundedCompaniesTotalFundingAsc = "FOUNDED_COMPANIES_TOTAL_FUNDING_ASC",
  FoundedCompaniesTotalFundingDesc = "FOUNDED_COMPANIES_TOTAL_FUNDING_DESC",
  FounderScoreAsc = "FOUNDER_SCORE_ASC",
  FounderScoreDesc = "FOUNDER_SCORE_DESC",
  GenderAsc = "GENDER_ASC",
  GenderDesc = "GENDER_DESC",
  GoogleUrlAsc = "GOOGLE_URL_ASC",
  GoogleUrlDesc = "GOOGLE_URL_DESC",
  HqLocationsAsc = "HQ_LOCATIONS_ASC",
  HqLocationsDesc = "HQ_LOCATIONS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IsFounderAsc = "IS_FOUNDER_ASC",
  IsFounderDesc = "IS_FOUNDER_DESC",
  IsPromisingFounderAsc = "IS_PROMISING_FOUNDER_ASC",
  IsPromisingFounderDesc = "IS_PROMISING_FOUNDER_DESC",
  IsSerialFounderAsc = "IS_SERIAL_FOUNDER_ASC",
  IsSerialFounderDesc = "IS_SERIAL_FOUNDER_DESC",
  IsStrongFounderAsc = "IS_STRONG_FOUNDER_ASC",
  IsStrongFounderDesc = "IS_STRONG_FOUNDER_DESC",
  IsSuperFounderAsc = "IS_SUPER_FOUNDER_ASC",
  IsSuperFounderDesc = "IS_SUPER_FOUNDER_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LgCompanyTeamsByMemberIdAverageCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdAverageCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdAverageCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdAverageCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdAverageDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_DELETED_ASC",
  LgCompanyTeamsByMemberIdAverageDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_DELETED_DESC",
  LgCompanyTeamsByMemberIdAverageIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_ID_ASC",
  LgCompanyTeamsByMemberIdAverageIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_ID_DESC",
  LgCompanyTeamsByMemberIdAverageIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdAverageIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdAverageMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdAverageMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdAveragePastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_PAST_ASC",
  LgCompanyTeamsByMemberIdAveragePastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_PAST_DESC",
  LgCompanyTeamsByMemberIdAverageTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_TITLES_ASC",
  LgCompanyTeamsByMemberIdAverageTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_TITLES_DESC",
  LgCompanyTeamsByMemberIdAverageYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdAverageYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdAverageYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdAverageYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_AVERAGE_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdCountAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_COUNT_ASC",
  LgCompanyTeamsByMemberIdCountDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_COUNT_DESC",
  LgCompanyTeamsByMemberIdDistinctCountCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdDistinctCountCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdDistinctCountCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdDistinctCountCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdDistinctCountDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_DELETED_ASC",
  LgCompanyTeamsByMemberIdDistinctCountDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_DELETED_DESC",
  LgCompanyTeamsByMemberIdDistinctCountIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyTeamsByMemberIdDistinctCountIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyTeamsByMemberIdDistinctCountIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdDistinctCountIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdDistinctCountMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdDistinctCountMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdDistinctCountPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_PAST_ASC",
  LgCompanyTeamsByMemberIdDistinctCountPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_PAST_DESC",
  LgCompanyTeamsByMemberIdDistinctCountTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_TITLES_ASC",
  LgCompanyTeamsByMemberIdDistinctCountTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_TITLES_DESC",
  LgCompanyTeamsByMemberIdDistinctCountYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdDistinctCountYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdDistinctCountYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdDistinctCountYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_DISTINCT_COUNT_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdMaxCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdMaxCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdMaxCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdMaxCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdMaxDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_DELETED_ASC",
  LgCompanyTeamsByMemberIdMaxDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_DELETED_DESC",
  LgCompanyTeamsByMemberIdMaxIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_ID_ASC",
  LgCompanyTeamsByMemberIdMaxIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_ID_DESC",
  LgCompanyTeamsByMemberIdMaxIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdMaxIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdMaxMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdMaxMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdMaxPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_PAST_ASC",
  LgCompanyTeamsByMemberIdMaxPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_PAST_DESC",
  LgCompanyTeamsByMemberIdMaxTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_TITLES_ASC",
  LgCompanyTeamsByMemberIdMaxTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_TITLES_DESC",
  LgCompanyTeamsByMemberIdMaxYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdMaxYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdMaxYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdMaxYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MAX_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdMinCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdMinCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdMinCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdMinCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdMinDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_DELETED_ASC",
  LgCompanyTeamsByMemberIdMinDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_DELETED_DESC",
  LgCompanyTeamsByMemberIdMinIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_ID_ASC",
  LgCompanyTeamsByMemberIdMinIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_ID_DESC",
  LgCompanyTeamsByMemberIdMinIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdMinIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdMinMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdMinMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdMinPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_PAST_ASC",
  LgCompanyTeamsByMemberIdMinPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_PAST_DESC",
  LgCompanyTeamsByMemberIdMinTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_TITLES_ASC",
  LgCompanyTeamsByMemberIdMinTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_TITLES_DESC",
  LgCompanyTeamsByMemberIdMinYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdMinYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdMinYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdMinYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_MIN_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_DELETED_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_DELETED_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_PAST_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_PAST_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_TITLES_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_TITLES_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdStddevPopulationYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdStddevPopulationYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_POPULATION_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdStddevSampleCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdStddevSampleCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdStddevSampleCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdStddevSampleCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdStddevSampleDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgCompanyTeamsByMemberIdStddevSampleDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgCompanyTeamsByMemberIdStddevSampleIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyTeamsByMemberIdStddevSampleIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyTeamsByMemberIdStddevSampleIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdStddevSampleIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdStddevSampleMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdStddevSampleMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdStddevSamplePastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_PAST_ASC",
  LgCompanyTeamsByMemberIdStddevSamplePastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_PAST_DESC",
  LgCompanyTeamsByMemberIdStddevSampleTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_TITLES_ASC",
  LgCompanyTeamsByMemberIdStddevSampleTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_TITLES_DESC",
  LgCompanyTeamsByMemberIdStddevSampleYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdStddevSampleYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdStddevSampleYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdStddevSampleYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_STDDEV_SAMPLE_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdSumCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdSumCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdSumCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdSumCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdSumDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_DELETED_ASC",
  LgCompanyTeamsByMemberIdSumDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_DELETED_DESC",
  LgCompanyTeamsByMemberIdSumIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_ID_ASC",
  LgCompanyTeamsByMemberIdSumIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_ID_DESC",
  LgCompanyTeamsByMemberIdSumIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdSumIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdSumMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdSumMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdSumPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_PAST_ASC",
  LgCompanyTeamsByMemberIdSumPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_PAST_DESC",
  LgCompanyTeamsByMemberIdSumTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_TITLES_ASC",
  LgCompanyTeamsByMemberIdSumTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_TITLES_DESC",
  LgCompanyTeamsByMemberIdSumYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdSumYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdSumYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdSumYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_SUM_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationPastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_PAST_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationPastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_PAST_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_TITLES_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_TITLES_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdVariancePopulationYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdVariancePopulationYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_POPULATION_YEAR_START_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleCompanyIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleCompanyIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleCompositeIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleCompositeIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleDeletedAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleDeletedDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleIsFounderAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_FOUNDER_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleIsFounderDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_FOUNDER_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleMemberIdAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleMemberIdDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  LgCompanyTeamsByMemberIdVarianceSamplePastAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_PAST_ASC",
  LgCompanyTeamsByMemberIdVarianceSamplePastDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_PAST_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleTitlesAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_TITLES_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleTitlesDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_TITLES_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleYearEndAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_END_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleYearEndDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_END_DESC",
  LgCompanyTeamsByMemberIdVarianceSampleYearStartAsc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_START_ASC",
  LgCompanyTeamsByMemberIdVarianceSampleYearStartDesc = "LG_COMPANY_TEAMS_BY_MEMBER_ID_VARIANCE_SAMPLE_YEAR_START_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NzrSentimentFormsByFounderIdAverageAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdAverageAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdAverageCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdAverageCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdAverageCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdAverageCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdAverageCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdAverageCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdAverageCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdAverageCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdAverageCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdAverageCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdAverageDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_DELETED_ASC",
  NzrSentimentFormsByFounderIdAverageDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_DELETED_DESC",
  NzrSentimentFormsByFounderIdAverageExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdAverageExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdAverageFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FIELDS_ASC",
  NzrSentimentFormsByFounderIdAverageFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FIELDS_DESC",
  NzrSentimentFormsByFounderIdAverageFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdAverageFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdAverageFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdAverageFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdAverageIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_ID_ASC",
  NzrSentimentFormsByFounderIdAverageIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_ID_DESC",
  NzrSentimentFormsByFounderIdAverageMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_METADATA_ASC",
  NzrSentimentFormsByFounderIdAverageMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_METADATA_DESC",
  NzrSentimentFormsByFounderIdAverageResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdAverageResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdAverageSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdAverageSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdAverageStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_STATUS_ASC",
  NzrSentimentFormsByFounderIdAverageStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_STATUS_DESC",
  NzrSentimentFormsByFounderIdAverageTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TITLE_ASC",
  NzrSentimentFormsByFounderIdAverageTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TITLE_DESC",
  NzrSentimentFormsByFounderIdAverageTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdAverageTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdAverageTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TYPE_ASC",
  NzrSentimentFormsByFounderIdAverageTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_AVERAGE_TYPE_DESC",
  NzrSentimentFormsByFounderIdCountAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_COUNT_ASC",
  NzrSentimentFormsByFounderIdCountDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_COUNT_DESC",
  NzrSentimentFormsByFounderIdDistinctCountAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdDistinctCountAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdDistinctCountCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdDistinctCountCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdDistinctCountCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdDistinctCountCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdDistinctCountCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdDistinctCountCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdDistinctCountCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdDistinctCountCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdDistinctCountCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdDistinctCountCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdDistinctCountDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_DELETED_ASC",
  NzrSentimentFormsByFounderIdDistinctCountDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_DELETED_DESC",
  NzrSentimentFormsByFounderIdDistinctCountExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdDistinctCountExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdDistinctCountFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FIELDS_ASC",
  NzrSentimentFormsByFounderIdDistinctCountFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FIELDS_DESC",
  NzrSentimentFormsByFounderIdDistinctCountFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdDistinctCountFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdDistinctCountFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdDistinctCountFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdDistinctCountIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_ID_ASC",
  NzrSentimentFormsByFounderIdDistinctCountIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_ID_DESC",
  NzrSentimentFormsByFounderIdDistinctCountMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_METADATA_ASC",
  NzrSentimentFormsByFounderIdDistinctCountMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_METADATA_DESC",
  NzrSentimentFormsByFounderIdDistinctCountResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdDistinctCountResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdDistinctCountSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdDistinctCountSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdDistinctCountStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_STATUS_ASC",
  NzrSentimentFormsByFounderIdDistinctCountStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_STATUS_DESC",
  NzrSentimentFormsByFounderIdDistinctCountTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TITLE_ASC",
  NzrSentimentFormsByFounderIdDistinctCountTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TITLE_DESC",
  NzrSentimentFormsByFounderIdDistinctCountTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdDistinctCountTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdDistinctCountTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TYPE_ASC",
  NzrSentimentFormsByFounderIdDistinctCountTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_DISTINCT_COUNT_TYPE_DESC",
  NzrSentimentFormsByFounderIdMaxAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdMaxAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdMaxCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdMaxCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdMaxCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdMaxCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdMaxCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdMaxCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdMaxCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdMaxCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdMaxCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdMaxCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdMaxDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_DELETED_ASC",
  NzrSentimentFormsByFounderIdMaxDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_DELETED_DESC",
  NzrSentimentFormsByFounderIdMaxExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdMaxExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdMaxFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FIELDS_ASC",
  NzrSentimentFormsByFounderIdMaxFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FIELDS_DESC",
  NzrSentimentFormsByFounderIdMaxFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdMaxFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdMaxFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdMaxFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdMaxIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_ID_ASC",
  NzrSentimentFormsByFounderIdMaxIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_ID_DESC",
  NzrSentimentFormsByFounderIdMaxMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_METADATA_ASC",
  NzrSentimentFormsByFounderIdMaxMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_METADATA_DESC",
  NzrSentimentFormsByFounderIdMaxResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdMaxResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdMaxSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdMaxSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdMaxStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_STATUS_ASC",
  NzrSentimentFormsByFounderIdMaxStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_STATUS_DESC",
  NzrSentimentFormsByFounderIdMaxTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TITLE_ASC",
  NzrSentimentFormsByFounderIdMaxTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TITLE_DESC",
  NzrSentimentFormsByFounderIdMaxTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdMaxTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdMaxTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TYPE_ASC",
  NzrSentimentFormsByFounderIdMaxTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MAX_TYPE_DESC",
  NzrSentimentFormsByFounderIdMinAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdMinAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdMinCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdMinCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdMinCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdMinCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdMinCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdMinCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdMinCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdMinCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdMinCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdMinCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdMinDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_DELETED_ASC",
  NzrSentimentFormsByFounderIdMinDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_DELETED_DESC",
  NzrSentimentFormsByFounderIdMinExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdMinExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdMinFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FIELDS_ASC",
  NzrSentimentFormsByFounderIdMinFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FIELDS_DESC",
  NzrSentimentFormsByFounderIdMinFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdMinFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdMinFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdMinFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdMinIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_ID_ASC",
  NzrSentimentFormsByFounderIdMinIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_ID_DESC",
  NzrSentimentFormsByFounderIdMinMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_METADATA_ASC",
  NzrSentimentFormsByFounderIdMinMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_METADATA_DESC",
  NzrSentimentFormsByFounderIdMinResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdMinResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdMinSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdMinSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdMinStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_STATUS_ASC",
  NzrSentimentFormsByFounderIdMinStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_STATUS_DESC",
  NzrSentimentFormsByFounderIdMinTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TITLE_ASC",
  NzrSentimentFormsByFounderIdMinTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TITLE_DESC",
  NzrSentimentFormsByFounderIdMinTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdMinTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdMinTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TYPE_ASC",
  NzrSentimentFormsByFounderIdMinTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_MIN_TYPE_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_DELETED_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_DELETED_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_ID_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_ID_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_METADATA_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_METADATA_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_STATUS_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_STATUS_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TITLE_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TITLE_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdStddevPopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TYPE_ASC",
  NzrSentimentFormsByFounderIdStddevPopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_POPULATION_TYPE_DESC",
  NzrSentimentFormsByFounderIdStddevSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdStddevSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdStddevSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdStddevSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdStddevSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdStddevSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdStddevSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdStddevSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdStddevSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdStddevSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdStddevSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdStddevSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdStddevSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByFounderIdStddevSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByFounderIdStddevSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdStddevSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdStddevSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByFounderIdStddevSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByFounderIdStddevSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdStddevSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdStddevSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdStddevSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdStddevSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_ID_ASC",
  NzrSentimentFormsByFounderIdStddevSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_ID_DESC",
  NzrSentimentFormsByFounderIdStddevSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByFounderIdStddevSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByFounderIdStddevSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdStddevSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdStddevSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdStddevSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdStddevSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByFounderIdStddevSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByFounderIdStddevSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByFounderIdStddevSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByFounderIdStddevSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdStddevSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdStddevSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByFounderIdStddevSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_STDDEV_SAMPLE_TYPE_DESC",
  NzrSentimentFormsByFounderIdSumAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdSumAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdSumCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdSumCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdSumCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdSumCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdSumCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdSumCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdSumCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdSumCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdSumCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdSumCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdSumDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_DELETED_ASC",
  NzrSentimentFormsByFounderIdSumDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_DELETED_DESC",
  NzrSentimentFormsByFounderIdSumExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdSumExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdSumFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FIELDS_ASC",
  NzrSentimentFormsByFounderIdSumFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FIELDS_DESC",
  NzrSentimentFormsByFounderIdSumFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdSumFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdSumFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdSumFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdSumIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_ID_ASC",
  NzrSentimentFormsByFounderIdSumIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_ID_DESC",
  NzrSentimentFormsByFounderIdSumMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_METADATA_ASC",
  NzrSentimentFormsByFounderIdSumMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_METADATA_DESC",
  NzrSentimentFormsByFounderIdSumResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdSumResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdSumSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdSumSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdSumStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_STATUS_ASC",
  NzrSentimentFormsByFounderIdSumStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_STATUS_DESC",
  NzrSentimentFormsByFounderIdSumTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TITLE_ASC",
  NzrSentimentFormsByFounderIdSumTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TITLE_DESC",
  NzrSentimentFormsByFounderIdSumTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdSumTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdSumTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TYPE_ASC",
  NzrSentimentFormsByFounderIdSumTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_SUM_TYPE_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_DELETED_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_DELETED_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_ID_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_ID_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_METADATA_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_METADATA_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_STATUS_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_STATUS_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TITLE_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TITLE_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdVariancePopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TYPE_ASC",
  NzrSentimentFormsByFounderIdVariancePopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_POPULATION_TYPE_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByFounderIdVarianceSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByFounderIdVarianceSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_FOUNDER_ID_VARIANCE_SAMPLE_TYPE_DESC",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PlaymarketAppIdAsc = "PLAYMARKET_APP_ID_ASC",
  PlaymarketAppIdDesc = "PLAYMARKET_APP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SrcFoundersByEntityIdAverageCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcFoundersByEntityIdAverageCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcFoundersByEntityIdAverageEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcFoundersByEntityIdAverageEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcFoundersByEntityIdAverageIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcFoundersByEntityIdAverageIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcFoundersByEntityIdAverageLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdAverageLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdAverageSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcFoundersByEntityIdAverageSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcFoundersByEntityIdAverageSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdAverageSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdCountAsc = "SRC_FOUNDERS_BY_ENTITY_ID_COUNT_ASC",
  SrcFoundersByEntityIdCountDesc = "SRC_FOUNDERS_BY_ENTITY_ID_COUNT_DESC",
  SrcFoundersByEntityIdDistinctCountCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcFoundersByEntityIdDistinctCountCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcFoundersByEntityIdDistinctCountEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcFoundersByEntityIdDistinctCountEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcFoundersByEntityIdDistinctCountIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcFoundersByEntityIdDistinctCountIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcFoundersByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdDistinctCountSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcFoundersByEntityIdDistinctCountSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcFoundersByEntityIdDistinctCountSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdDistinctCountSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdMaxCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcFoundersByEntityIdMaxCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcFoundersByEntityIdMaxEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcFoundersByEntityIdMaxEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcFoundersByEntityIdMaxIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcFoundersByEntityIdMaxIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcFoundersByEntityIdMaxLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdMaxLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdMaxSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcFoundersByEntityIdMaxSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcFoundersByEntityIdMaxSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdMaxSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdMinCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcFoundersByEntityIdMinCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcFoundersByEntityIdMinEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcFoundersByEntityIdMinEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcFoundersByEntityIdMinIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcFoundersByEntityIdMinIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcFoundersByEntityIdMinLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdMinLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdMinSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcFoundersByEntityIdMinSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcFoundersByEntityIdMinSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdMinSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdStddevPopulationCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcFoundersByEntityIdStddevPopulationCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcFoundersByEntityIdStddevPopulationEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcFoundersByEntityIdStddevPopulationEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcFoundersByEntityIdStddevPopulationIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcFoundersByEntityIdStddevPopulationIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcFoundersByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdStddevPopulationSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcFoundersByEntityIdStddevPopulationSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcFoundersByEntityIdStddevPopulationSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdStddevPopulationSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdStddevSampleCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcFoundersByEntityIdStddevSampleCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcFoundersByEntityIdStddevSampleEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcFoundersByEntityIdStddevSampleEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcFoundersByEntityIdStddevSampleIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcFoundersByEntityIdStddevSampleIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcFoundersByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdStddevSampleSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcFoundersByEntityIdStddevSampleSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcFoundersByEntityIdStddevSampleSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdStddevSampleSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdSumCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcFoundersByEntityIdSumCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcFoundersByEntityIdSumEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcFoundersByEntityIdSumEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcFoundersByEntityIdSumIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcFoundersByEntityIdSumIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcFoundersByEntityIdSumLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdSumLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdSumSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcFoundersByEntityIdSumSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcFoundersByEntityIdSumSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdSumSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdVariancePopulationCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcFoundersByEntityIdVariancePopulationCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcFoundersByEntityIdVariancePopulationEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcFoundersByEntityIdVariancePopulationEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcFoundersByEntityIdVariancePopulationIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcFoundersByEntityIdVariancePopulationIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcFoundersByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdVariancePopulationSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcFoundersByEntityIdVariancePopulationSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcFoundersByEntityIdVariancePopulationSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdVariancePopulationSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcFoundersByEntityIdVarianceSampleCreatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcFoundersByEntityIdVarianceSampleCreatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcFoundersByEntityIdVarianceSampleEntityIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcFoundersByEntityIdVarianceSampleEntityIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcFoundersByEntityIdVarianceSampleIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcFoundersByEntityIdVarianceSampleIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcFoundersByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFoundersByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFoundersByEntityIdVarianceSampleSourceIdAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcFoundersByEntityIdVarianceSampleSourceIdDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcFoundersByEntityIdVarianceSampleSourceTypeAsc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcFoundersByEntityIdVarianceSampleSourceTypeDesc = "SRC_FOUNDERS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UniversitiesAsc = "UNIVERSITIES_ASC",
  UniversitiesDesc = "UNIVERSITIES_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type LgFundingRound = Node & {
  __typename?: "LgFundingRound";
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Reads a single `LgCompany` that is related to this `LgFundingRound`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  currency?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  isVerified?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads and enables pagination through a set of `LgFundingRoundInvestor`. */
  lgFundingRoundInvestorsByFundingRoundId: LgFundingRoundInvestorsConnection;
  /** Reads and enables pagination through a set of `LgInvestor`. */
  lgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorId: LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection;
  month?: Maybe<Scalars["Int"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  round?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `SrcFundingRound`. */
  srcFundingRoundsByEntityId: SrcFundingRoundsConnection;
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFundingRoundLgFundingRoundInvestorsByFundingRoundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundInvestorCondition>;
  filter?: InputMaybe<LgFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundInvestorsOrderBy>>;
};

export type LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgInvestorCondition>;
  filter?: InputMaybe<LgInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgInvestorsOrderBy>>;
};

export type LgFundingRoundSrcFundingRoundsByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFundingRoundCondition>;
  filter?: InputMaybe<SrcFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFundingRoundsOrderBy>>;
};

export type LgFundingRoundAggregates = {
  __typename?: "LgFundingRoundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgFundingRoundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgFundingRoundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgFundingRoundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgFundingRoundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgFundingRoundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgFundingRoundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgFundingRoundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgFundingRoundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgFundingRoundVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgFundingRound` object types. */
export type LgFundingRoundAggregatesFilter = {
  /** Mean average aggregate over matching `LgFundingRound` objects. */
  average?: InputMaybe<LgFundingRoundAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgFundingRound` objects. */
  distinctCount?: InputMaybe<LgFundingRoundDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgFundingRound` object to be included within the aggregate. */
  filter?: InputMaybe<LgFundingRoundFilter>;
  /** Maximum aggregate over matching `LgFundingRound` objects. */
  max?: InputMaybe<LgFundingRoundMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgFundingRound` objects. */
  min?: InputMaybe<LgFundingRoundMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgFundingRound` objects. */
  stddevPopulation?: InputMaybe<LgFundingRoundStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgFundingRound` objects. */
  stddevSample?: InputMaybe<LgFundingRoundStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgFundingRound` objects. */
  sum?: InputMaybe<LgFundingRoundSumAggregateFilter>;
  /** Population variance aggregate over matching `LgFundingRound` objects. */
  variancePopulation?: InputMaybe<LgFundingRoundVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgFundingRound` objects. */
  varianceSample?: InputMaybe<LgFundingRoundVarianceSampleAggregateFilter>;
};

export type LgFundingRoundAverageAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundAverageAggregates = {
  __typename?: "LgFundingRoundAverageAggregates";
  /** Mean average of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgFundingRound` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgFundingRoundCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountEurMillion` field. */
  amountEurMillion?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountSource` field. */
  amountSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `month` field. */
  month?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `round` field. */
  round?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `valuation` field. */
  valuation?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `valuationGeneratedMax` field. */
  valuationGeneratedMax?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `valuationGeneratedMin` field. */
  valuationGeneratedMin?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `year` field. */
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LgFundingRoundDistinctCountAggregateFilter = {
  amount?: InputMaybe<BigIntFilter>;
  amountEurMillion?: InputMaybe<BigIntFilter>;
  amountSource?: InputMaybe<BigIntFilter>;
  amountUsdMillion?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  currency?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isVerified?: InputMaybe<BigIntFilter>;
  month?: InputMaybe<BigIntFilter>;
  round?: InputMaybe<BigIntFilter>;
  valuation?: InputMaybe<BigIntFilter>;
  valuationGeneratedMax?: InputMaybe<BigIntFilter>;
  valuationGeneratedMin?: InputMaybe<BigIntFilter>;
  year?: InputMaybe<BigIntFilter>;
};

export type LgFundingRoundDistinctCountAggregates = {
  __typename?: "LgFundingRoundDistinctCountAggregates";
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currency across the matching connection */
  currency?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isVerified across the matching connection */
  isVerified?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of month across the matching connection */
  month?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of round across the matching connection */
  round?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type LgFundingRoundFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountEurMillion` field. */
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountSource` field. */
  amountSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountUsdMillion` field. */
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgFundingRoundFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lgFundingRoundInvestorsByFundingRoundId` relation. */
  lgFundingRoundInvestorsByFundingRoundId?: InputMaybe<LgFundingRoundToManyLgFundingRoundInvestorFilter>;
  /** Some related `lgFundingRoundInvestorsByFundingRoundId` exist. */
  lgFundingRoundInvestorsByFundingRoundIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `month` field. */
  month?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgFundingRoundFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgFundingRoundFilter>>;
  /** Filter by the object’s `round` field. */
  round?: InputMaybe<StringFilter>;
  /** Filter by the object’s `srcFundingRoundsByEntityId` relation. */
  srcFundingRoundsByEntityId?: InputMaybe<LgFundingRoundToManySrcFundingRoundFilter>;
  /** Some related `srcFundingRoundsByEntityId` exist. */
  srcFundingRoundsByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `valuation` field. */
  valuation?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `valuationGeneratedMax` field. */
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `valuationGeneratedMin` field. */
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `year` field. */
  year?: InputMaybe<IntFilter>;
};

/** Grouping methods for `LgFundingRound` for usage during aggregation. */
export enum LgFundingRoundGroupBy {
  Amount = "AMOUNT",
  AmountEurMillion = "AMOUNT_EUR_MILLION",
  AmountSource = "AMOUNT_SOURCE",
  AmountUsdMillion = "AMOUNT_USD_MILLION",
  CompanyId = "COMPANY_ID",
  Currency = "CURRENCY",
  IsVerified = "IS_VERIFIED",
  Month = "MONTH",
  Round = "ROUND",
  Valuation = "VALUATION",
  ValuationGeneratedMax = "VALUATION_GENERATED_MAX",
  ValuationGeneratedMin = "VALUATION_GENERATED_MIN",
  Year = "YEAR",
}

export type LgFundingRoundHavingAverageInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingDistinctCountInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgFundingRound` aggregates. */
export type LgFundingRoundHavingInput = {
  AND?: InputMaybe<Array<LgFundingRoundHavingInput>>;
  OR?: InputMaybe<Array<LgFundingRoundHavingInput>>;
  average?: InputMaybe<LgFundingRoundHavingAverageInput>;
  distinctCount?: InputMaybe<LgFundingRoundHavingDistinctCountInput>;
  max?: InputMaybe<LgFundingRoundHavingMaxInput>;
  min?: InputMaybe<LgFundingRoundHavingMinInput>;
  stddevPopulation?: InputMaybe<LgFundingRoundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgFundingRoundHavingStddevSampleInput>;
  sum?: InputMaybe<LgFundingRoundHavingSumInput>;
  variancePopulation?: InputMaybe<LgFundingRoundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgFundingRoundHavingVarianceSampleInput>;
};

export type LgFundingRoundHavingMaxInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingMinInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingStddevPopulationInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingStddevSampleInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingSumInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingVariancePopulationInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundHavingVarianceSampleInput = {
  amount?: InputMaybe<HavingBigfloatFilter>;
  amountEurMillion?: InputMaybe<HavingBigfloatFilter>;
  amountSource?: InputMaybe<HavingBigfloatFilter>;
  amountUsdMillion?: InputMaybe<HavingBigfloatFilter>;
  month?: InputMaybe<HavingIntFilter>;
  valuation?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMax?: InputMaybe<HavingBigfloatFilter>;
  valuationGeneratedMin?: InputMaybe<HavingBigfloatFilter>;
  year?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestor = Node & {
  __typename?: "LgFundingRoundInvestor";
  compositeId: Scalars["String"]["output"];
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `LgFundingRound` that is related to this `LgFundingRoundInvestor`. */
  fundingRound?: Maybe<LgFundingRound>;
  fundingRoundId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  /** Reads a single `LgInvestor` that is related to this `LgFundingRoundInvestor`. */
  investor?: Maybe<LgInvestor>;
  investorId: Scalars["UUID"]["output"];
  lead?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `SrcFundingRoundInvestor`. */
  srcFundingRoundInvestorsByEntityId: SrcFundingRoundInvestorsConnection;
};

export type LgFundingRoundInvestorSrcFundingRoundInvestorsByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFundingRoundInvestorCondition>;
  filter?: InputMaybe<SrcFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFundingRoundInvestorsOrderBy>>;
};

export type LgFundingRoundInvestorAggregates = {
  __typename?: "LgFundingRoundInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgFundingRoundInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgFundingRoundInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgFundingRoundInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgFundingRoundInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgFundingRoundInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgFundingRoundInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgFundingRoundInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgFundingRoundInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgFundingRoundInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgFundingRoundInvestor` object types. */
export type LgFundingRoundInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `LgFundingRoundInvestor` objects. */
  average?: InputMaybe<LgFundingRoundInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgFundingRoundInvestor` objects. */
  distinctCount?: InputMaybe<LgFundingRoundInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgFundingRoundInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** Maximum aggregate over matching `LgFundingRoundInvestor` objects. */
  max?: InputMaybe<LgFundingRoundInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgFundingRoundInvestor` objects. */
  min?: InputMaybe<LgFundingRoundInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgFundingRoundInvestor` objects. */
  stddevPopulation?: InputMaybe<LgFundingRoundInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgFundingRoundInvestor` objects. */
  stddevSample?: InputMaybe<LgFundingRoundInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgFundingRoundInvestor` objects. */
  sum?: InputMaybe<LgFundingRoundInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `LgFundingRoundInvestor` objects. */
  variancePopulation?: InputMaybe<LgFundingRoundInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgFundingRoundInvestor` objects. */
  varianceSample?: InputMaybe<LgFundingRoundInvestorVarianceSampleAggregateFilter>;
};

export type LgFundingRoundInvestorAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundInvestorAverageAggregates = {
  __typename?: "LgFundingRoundInvestorAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgFundingRoundInvestor` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LgFundingRoundInvestorCondition = {
  /** Checks for equality with the object’s `compositeId` field. */
  compositeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `investorId` field. */
  investorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lead` field. */
  lead?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LgFundingRoundInvestorDistinctCountAggregateFilter = {
  compositeId?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  fundingRoundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  investorId?: InputMaybe<BigIntFilter>;
  lead?: InputMaybe<BigIntFilter>;
};

export type LgFundingRoundInvestorDistinctCountAggregates = {
  __typename?: "LgFundingRoundInvestorDistinctCountAggregates";
  /** Distinct count of compositeId across the matching connection */
  compositeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundingRoundId across the matching connection */
  fundingRoundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorId across the matching connection */
  investorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lead across the matching connection */
  lead?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgFundingRoundInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgFundingRoundInvestorFilter>>;
  /** Filter by the object’s `compositeId` field. */
  compositeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fundingRound` relation. */
  fundingRound?: InputMaybe<LgFundingRoundFilter>;
  /** Filter by the object’s `fundingRoundId` field. */
  fundingRoundId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `investor` relation. */
  investor?: InputMaybe<LgInvestorFilter>;
  /** Filter by the object’s `investorId` field. */
  investorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lead` field. */
  lead?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgFundingRoundInvestorFilter>>;
  /** Filter by the object’s `srcFundingRoundInvestorsByEntityId` relation. */
  srcFundingRoundInvestorsByEntityId?: InputMaybe<LgFundingRoundInvestorToManySrcFundingRoundInvestorFilter>;
  /** Some related `srcFundingRoundInvestorsByEntityId` exist. */
  srcFundingRoundInvestorsByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LgFundingRoundInvestorMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgFundingRoundInvestorMaxAggregates = {
  __typename?: "LgFundingRoundInvestorMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFundingRoundInvestorMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type LgFundingRoundInvestorMinAggregates = {
  __typename?: "LgFundingRoundInvestorMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFundingRoundInvestorStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundInvestorStddevPopulationAggregates = {
  __typename?: "LgFundingRoundInvestorStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundInvestorStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundInvestorStddevSampleAggregates = {
  __typename?: "LgFundingRoundInvestorStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundInvestorSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type LgFundingRoundInvestorSumAggregates = {
  __typename?: "LgFundingRoundInvestorSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `SrcFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgFundingRoundInvestorToManySrcFundingRoundInvestorFilter = {
  /** Aggregates across related `SrcFundingRoundInvestor` match the filter criteria. */
  aggregates?: InputMaybe<SrcFundingRoundInvestorAggregatesFilter>;
  /** Every related `SrcFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcFundingRoundInvestorFilter>;
  /** No related `SrcFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcFundingRoundInvestorFilter>;
  /** Some related `SrcFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcFundingRoundInvestorFilter>;
};

export type LgFundingRoundInvestorVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundInvestorVariancePopulationAggregates = {
  __typename?: "LgFundingRoundInvestorVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundInvestorVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundInvestorVarianceSampleAggregates = {
  __typename?: "LgFundingRoundInvestorVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgFundingRoundInvestor` values. */
export type LgFundingRoundInvestorsConnection = {
  __typename?: "LgFundingRoundInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFundingRoundInvestorAggregates>;
  /** A list of edges which contains the `LgFundingRoundInvestor` and cursor to aid in pagination. */
  edges: Array<LgFundingRoundInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFundingRoundInvestorAggregates>>;
  /** A list of `LgFundingRoundInvestor` objects. */
  nodes: Array<LgFundingRoundInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFundingRoundInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFundingRoundInvestor` values. */
export type LgFundingRoundInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFundingRoundInvestorsGroupBy>;
  having?: InputMaybe<LgFundingRoundInvestorsHavingInput>;
};

/** A `LgFundingRoundInvestor` edge in the connection. */
export type LgFundingRoundInvestorsEdge = {
  __typename?: "LgFundingRoundInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFundingRoundInvestor` at the end of the edge. */
  node: LgFundingRoundInvestor;
};

/** Grouping methods for `LgFundingRoundInvestor` for usage during aggregation. */
export enum LgFundingRoundInvestorsGroupBy {
  Deleted = "DELETED",
  FundingRoundId = "FUNDING_ROUND_ID",
  InvestorId = "INVESTOR_ID",
  Lead = "LEAD",
}

export type LgFundingRoundInvestorsHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgFundingRoundInvestor` aggregates. */
export type LgFundingRoundInvestorsHavingInput = {
  AND?: InputMaybe<Array<LgFundingRoundInvestorsHavingInput>>;
  OR?: InputMaybe<Array<LgFundingRoundInvestorsHavingInput>>;
  average?: InputMaybe<LgFundingRoundInvestorsHavingAverageInput>;
  distinctCount?: InputMaybe<LgFundingRoundInvestorsHavingDistinctCountInput>;
  max?: InputMaybe<LgFundingRoundInvestorsHavingMaxInput>;
  min?: InputMaybe<LgFundingRoundInvestorsHavingMinInput>;
  stddevPopulation?: InputMaybe<LgFundingRoundInvestorsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgFundingRoundInvestorsHavingStddevSampleInput>;
  sum?: InputMaybe<LgFundingRoundInvestorsHavingSumInput>;
  variancePopulation?: InputMaybe<LgFundingRoundInvestorsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgFundingRoundInvestorsHavingVarianceSampleInput>;
};

export type LgFundingRoundInvestorsHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type LgFundingRoundInvestorsHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LgFundingRoundInvestor`. */
export enum LgFundingRoundInvestorsOrderBy {
  CompositeIdAsc = "COMPOSITE_ID_ASC",
  CompositeIdDesc = "COMPOSITE_ID_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  FundingRoundIdAsc = "FUNDING_ROUND_ID_ASC",
  FundingRoundIdDesc = "FUNDING_ROUND_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvestorIdAsc = "INVESTOR_ID_ASC",
  InvestorIdDesc = "INVESTOR_ID_DESC",
  LeadAsc = "LEAD_ASC",
  LeadDesc = "LEAD_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdAverageSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdAverageSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdCountAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_COUNT_ASC",
  SrcFundingRoundInvestorsByEntityIdCountDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_COUNT_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdDistinctCountSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMaxSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdMaxSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdMinCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdMinCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdMinEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMinEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMinIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMinIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMinLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdMinLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdMinSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdMinSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdMinSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdMinSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevPopulationSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdStddevSampleSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdSumCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdSumCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdSumEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdSumEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdSumIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdSumIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdSumLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdSumLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdSumSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdSumSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdSumSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdSumSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdVariancePopulationSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleCreatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleCreatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleEntityIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleEntityIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleSourceIdAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleSourceIdDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleSourceTypeAsc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcFundingRoundInvestorsByEntityIdVarianceSampleSourceTypeDesc = "SRC_FUNDING_ROUND_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
}

/** A connection to a list of `LgInvestor` values, with data from `LgFundingRoundInvestor`. */
export type LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection = {
  __typename?: "LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgInvestorAggregates>;
  /** A list of edges which contains the `LgInvestor`, info from the `LgFundingRoundInvestor`, and the cursor to aid in pagination. */
  edges: Array<LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgInvestorAggregates>>;
  /** A list of `LgInvestor` objects. */
  nodes: Array<LgInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgInvestor` values, with data from `LgFundingRoundInvestor`. */
export type LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgInvestorGroupBy>;
    having?: InputMaybe<LgInvestorHavingInput>;
  };

/** A `LgInvestor` edge in the connection, with data from `LgFundingRoundInvestor`. */
export type LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge = {
  __typename?: "LgFundingRoundLgInvestorsByLgFundingRoundInvestorFundingRoundIdAndInvestorIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  lead?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgInvestor` at the end of the edge. */
  node: LgInvestor;
};

export type LgFundingRoundMaxAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<IntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<IntFilter>;
};

export type LgFundingRoundMaxAggregates = {
  __typename?: "LgFundingRoundMaxAggregates";
  /** Maximum of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of month across the matching connection */
  month?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFundingRoundMinAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<IntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<IntFilter>;
};

export type LgFundingRoundMinAggregates = {
  __typename?: "LgFundingRoundMinAggregates";
  /** Minimum of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of month across the matching connection */
  month?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type LgFundingRoundStddevPopulationAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundStddevPopulationAggregates = {
  __typename?: "LgFundingRoundStddevPopulationAggregates";
  /** Population standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundStddevSampleAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundStddevSampleAggregates = {
  __typename?: "LgFundingRoundStddevSampleAggregates";
  /** Sample standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundSumAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigIntFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigIntFilter>;
};

export type LgFundingRoundSumAggregates = {
  __typename?: "LgFundingRoundSumAggregates";
  /** Sum of amount across the matching connection */
  amount: Scalars["BigFloat"]["output"];
  /** Sum of amountEurMillion across the matching connection */
  amountEurMillion: Scalars["BigFloat"]["output"];
  /** Sum of amountSource across the matching connection */
  amountSource: Scalars["BigFloat"]["output"];
  /** Sum of amountUsdMillion across the matching connection */
  amountUsdMillion: Scalars["BigFloat"]["output"];
  /** Sum of month across the matching connection */
  month: Scalars["BigInt"]["output"];
  /** Sum of valuation across the matching connection */
  valuation: Scalars["BigFloat"]["output"];
  /** Sum of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax: Scalars["BigFloat"]["output"];
  /** Sum of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin: Scalars["BigFloat"]["output"];
  /** Sum of year across the matching connection */
  year: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `LgFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgFundingRoundToManyLgFundingRoundInvestorFilter = {
  /** Aggregates across related `LgFundingRoundInvestor` match the filter criteria. */
  aggregates?: InputMaybe<LgFundingRoundInvestorAggregatesFilter>;
  /** Every related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** No related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** Some related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgFundingRoundInvestorFilter>;
};

/** A filter to be used against many `SrcFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type LgFundingRoundToManySrcFundingRoundFilter = {
  /** Aggregates across related `SrcFundingRound` match the filter criteria. */
  aggregates?: InputMaybe<SrcFundingRoundAggregatesFilter>;
  /** Every related `SrcFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcFundingRoundFilter>;
  /** No related `SrcFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcFundingRoundFilter>;
  /** Some related `SrcFundingRound` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcFundingRoundFilter>;
};

export type LgFundingRoundVariancePopulationAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundVariancePopulationAggregates = {
  __typename?: "LgFundingRoundVariancePopulationAggregates";
  /** Population variance of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgFundingRoundVarianceSampleAggregateFilter = {
  amount?: InputMaybe<BigFloatFilter>;
  amountEurMillion?: InputMaybe<BigFloatFilter>;
  amountSource?: InputMaybe<BigFloatFilter>;
  amountUsdMillion?: InputMaybe<BigFloatFilter>;
  month?: InputMaybe<BigFloatFilter>;
  valuation?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMax?: InputMaybe<BigFloatFilter>;
  valuationGeneratedMin?: InputMaybe<BigFloatFilter>;
  year?: InputMaybe<BigFloatFilter>;
};

export type LgFundingRoundVarianceSampleAggregates = {
  __typename?: "LgFundingRoundVarianceSampleAggregates";
  /** Sample variance of amount across the matching connection */
  amount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountEurMillion across the matching connection */
  amountEurMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountSource across the matching connection */
  amountSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of amountUsdMillion across the matching connection */
  amountUsdMillion?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of month across the matching connection */
  month?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuation across the matching connection */
  valuation?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuationGeneratedMax across the matching connection */
  valuationGeneratedMax?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of valuationGeneratedMin across the matching connection */
  valuationGeneratedMin?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgFundingRound` values. */
export type LgFundingRoundsConnection = {
  __typename?: "LgFundingRoundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFundingRoundAggregates>;
  /** A list of edges which contains the `LgFundingRound` and cursor to aid in pagination. */
  edges: Array<LgFundingRoundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFundingRoundAggregates>>;
  /** A list of `LgFundingRound` objects. */
  nodes: Array<LgFundingRound>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFundingRound` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFundingRound` values. */
export type LgFundingRoundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFundingRoundGroupBy>;
  having?: InputMaybe<LgFundingRoundHavingInput>;
};

/** A `LgFundingRound` edge in the connection. */
export type LgFundingRoundsEdge = {
  __typename?: "LgFundingRoundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFundingRound` at the end of the edge. */
  node: LgFundingRound;
};

/** Methods to use when ordering `LgFundingRound`. */
export enum LgFundingRoundsOrderBy {
  AmountAsc = "AMOUNT_ASC",
  AmountDesc = "AMOUNT_DESC",
  AmountEurMillionAsc = "AMOUNT_EUR_MILLION_ASC",
  AmountEurMillionDesc = "AMOUNT_EUR_MILLION_DESC",
  AmountSourceAsc = "AMOUNT_SOURCE_ASC",
  AmountSourceDesc = "AMOUNT_SOURCE_DESC",
  AmountUsdMillionAsc = "AMOUNT_USD_MILLION_ASC",
  AmountUsdMillionDesc = "AMOUNT_USD_MILLION_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsVerifiedAsc = "IS_VERIFIED_ASC",
  IsVerifiedDesc = "IS_VERIFIED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdAverageLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdAverageLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_AVERAGE_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdCountAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_COUNT_ASC",
  LgFundingRoundInvestorsByFundingRoundIdCountDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_COUNT_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdDistinctCountLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_DISTINCT_COUNT_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMaxLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMaxLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MAX_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdMinLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdMinLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_MIN_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevPopulationLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_POPULATION_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdStddevSampleLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_STDDEV_SAMPLE_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdSumLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdSumLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_SUM_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVariancePopulationLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_POPULATION_LEAD_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_LEAD_ASC",
  LgFundingRoundInvestorsByFundingRoundIdVarianceSampleLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_FUNDING_ROUND_ID_VARIANCE_SAMPLE_LEAD_DESC",
  MonthAsc = "MONTH_ASC",
  MonthDesc = "MONTH_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RoundAsc = "ROUND_ASC",
  RoundDesc = "ROUND_DESC",
  SrcFundingRoundsByEntityIdAverageCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdAverageCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdAverageEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdAverageEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdAverageIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcFundingRoundsByEntityIdAverageIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcFundingRoundsByEntityIdAverageLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdAverageLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdAverageSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdAverageSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdAverageSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdAverageSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdCountAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_COUNT_ASC",
  SrcFundingRoundsByEntityIdCountDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_COUNT_DESC",
  SrcFundingRoundsByEntityIdDistinctCountCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdDistinctCountCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdDistinctCountEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdDistinctCountEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdDistinctCountIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcFundingRoundsByEntityIdDistinctCountIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcFundingRoundsByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdDistinctCountSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdDistinctCountSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdDistinctCountSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdDistinctCountSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdMaxCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdMaxCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdMaxEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdMaxEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdMaxIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcFundingRoundsByEntityIdMaxIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcFundingRoundsByEntityIdMaxLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdMaxLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdMaxSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdMaxSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdMaxSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdMaxSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdMinCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdMinCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdMinEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdMinEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdMinIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcFundingRoundsByEntityIdMinIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcFundingRoundsByEntityIdMinLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdMinLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdMinSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdMinSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdMinSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdMinSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdStddevPopulationSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdStddevPopulationSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdStddevSampleCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdStddevSampleCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdStddevSampleEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdStddevSampleEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdStddevSampleIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcFundingRoundsByEntityIdStddevSampleIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcFundingRoundsByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdStddevSampleSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdStddevSampleSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdStddevSampleSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdStddevSampleSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdSumCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdSumCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdSumEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdSumEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdSumIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcFundingRoundsByEntityIdSumIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcFundingRoundsByEntityIdSumLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdSumLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdSumSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdSumSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdSumSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdSumSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdVariancePopulationSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdVariancePopulationSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleCreatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleCreatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleEntityIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleEntityIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleSourceIdAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleSourceIdDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcFundingRoundsByEntityIdVarianceSampleSourceTypeAsc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcFundingRoundsByEntityIdVarianceSampleSourceTypeDesc = "SRC_FUNDING_ROUNDS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  ValuationAsc = "VALUATION_ASC",
  ValuationDesc = "VALUATION_DESC",
  ValuationGeneratedMaxAsc = "VALUATION_GENERATED_MAX_ASC",
  ValuationGeneratedMaxDesc = "VALUATION_GENERATED_MAX_DESC",
  ValuationGeneratedMinAsc = "VALUATION_GENERATED_MIN_ASC",
  ValuationGeneratedMinDesc = "VALUATION_GENERATED_MIN_DESC",
  YearAsc = "YEAR_ASC",
  YearDesc = "YEAR_DESC",
}

export type LgInvestor = Node & {
  __typename?: "LgInvestor";
  about?: Maybe<Scalars["String"]["output"]>;
  angellistUrl?: Maybe<Scalars["String"]["output"]>;
  appstoreAppId?: Maybe<Scalars["String"]["output"]>;
  clientFocus?: Maybe<Scalars["JSON"]["output"]>;
  coInvestors?: Maybe<Scalars["JSON"]["output"]>;
  countryExperience?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  crunchbaseUrl?: Maybe<Scalars["String"]["output"]>;
  dealSize?: Maybe<Scalars["String"]["output"]>;
  dealStructure?: Maybe<Scalars["JSON"]["output"]>;
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  employees?: Maybe<Scalars["String"]["output"]>;
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  fundings?: Maybe<Scalars["JSON"]["output"]>;
  fundsUnderManagement?: Maybe<Scalars["JSON"]["output"]>;
  googleUrl?: Maybe<Scalars["String"]["output"]>;
  hqLocations?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  industryExperience?: Maybe<Scalars["JSON"]["output"]>;
  investmentStages?: Maybe<Scalars["JSON"]["output"]>;
  investments?: Maybe<Scalars["JSON"]["output"]>;
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  investorType?: Maybe<Scalars["String"]["output"]>;
  isBasecamp: Scalars["Boolean"]["output"];
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  isTrusted?: Maybe<Scalars["Boolean"]["output"]>;
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyInvestorInvestorIdAndCompanyId: LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompanyInvestor`. */
  lgCompanyInvestorsByInvestorId: LgCompanyInvestorsConnection;
  /** Reads and enables pagination through a set of `LgFundingRoundInvestor`. */
  lgFundingRoundInvestorsByInvestorId: LgFundingRoundInvestorsConnection;
  /** Reads and enables pagination through a set of `LgFundingRound`. */
  lgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundId: LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection;
  /** Reads a single `LgMember` that is related to this `LgInvestor`. */
  lgMemberByRefFundLead?: Maybe<LgMember>;
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  path?: Maybe<Scalars["String"]["output"]>;
  playmarketAppId?: Maybe<Scalars["String"]["output"]>;
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  refFundLead?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `SrcInvestor`. */
  srcInvestorsByEntityId: SrcInvestorsConnection;
  subTypes?: Maybe<Scalars["String"]["output"]>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
  team?: Maybe<Scalars["JSON"]["output"]>;
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  twitterUrl?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

export type LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgInvestorLgCompanyInvestorsByInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyInvestorCondition>;
  filter?: InputMaybe<LgCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyInvestorsOrderBy>>;
};

export type LgInvestorLgFundingRoundInvestorsByInvestorIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundInvestorCondition>;
  filter?: InputMaybe<LgFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundInvestorsOrderBy>>;
};

export type LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundCondition>;
  filter?: InputMaybe<LgFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundsOrderBy>>;
};

export type LgInvestorSrcInvestorsByEntityIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcInvestorCondition>;
  filter?: InputMaybe<SrcInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcInvestorsOrderBy>>;
};

export type LgInvestorAggregates = {
  __typename?: "LgInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgInvestor` object types. */
export type LgInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `LgInvestor` objects. */
  average?: InputMaybe<LgInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgInvestor` objects. */
  distinctCount?: InputMaybe<LgInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<LgInvestorFilter>;
  /** Maximum aggregate over matching `LgInvestor` objects. */
  max?: InputMaybe<LgInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgInvestor` objects. */
  min?: InputMaybe<LgInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgInvestor` objects. */
  stddevPopulation?: InputMaybe<LgInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgInvestor` objects. */
  stddevSample?: InputMaybe<LgInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgInvestor` objects. */
  sum?: InputMaybe<LgInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `LgInvestor` objects. */
  variancePopulation?: InputMaybe<LgInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgInvestor` objects. */
  varianceSample?: InputMaybe<LgInvestorVarianceSampleAggregateFilter>;
};

export type LgInvestorAverageAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigFloatFilter>;
  employeesLatest?: InputMaybe<BigFloatFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigFloatFilter>;
  launchYear?: InputMaybe<BigFloatFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigFloatFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorAverageAggregates = {
  __typename?: "LgInvestorAverageAggregates";
  /** Mean average of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgInvestor` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LgInvestorCondition = {
  /** Checks for equality with the object’s `about` field. */
  about?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `countryExperience` field. */
  countryExperience?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealSize` field. */
  dealSize?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `dealStructure` field. */
  dealStructure?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `dutchInvestmentsNum` field. */
  dutchInvestmentsNum?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `employees` field. */
  employees?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fundings` field. */
  fundings?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `fundsUnderManagement` field. */
  fundsUnderManagement?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `industryExperience` field. */
  industryExperience?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investmentStages` field. */
  investmentStages?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investments` field. */
  investments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `investmentsNum` field. */
  investmentsNum?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `investorType` field. */
  investorType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isBasecamp` field. */
  isBasecamp?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isTrusted` field. */
  isTrusted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `launchYear` field. */
  launchYear?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lgRanking` field. */
  lgRanking?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `recentFunding` field. */
  recentFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `recentFundingSource` field. */
  recentFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `refFundLead` field. */
  refFundLead?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `subTypes` field. */
  subTypes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `team` field. */
  team?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `topInvestmentsNum` field. */
  topInvestmentsNum?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgInvestorDistinctCountAggregateFilter = {
  about?: InputMaybe<BigIntFilter>;
  angellistUrl?: InputMaybe<BigIntFilter>;
  appstoreAppId?: InputMaybe<BigIntFilter>;
  clientFocus?: InputMaybe<BigIntFilter>;
  coInvestors?: InputMaybe<BigIntFilter>;
  countryExperience?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  crunchbaseUrl?: InputMaybe<BigIntFilter>;
  dealSize?: InputMaybe<BigIntFilter>;
  dealStructure?: InputMaybe<BigIntFilter>;
  dutchInvestmentsNum?: InputMaybe<BigIntFilter>;
  employees?: InputMaybe<BigIntFilter>;
  employeesLatest?: InputMaybe<BigIntFilter>;
  facebookUrl?: InputMaybe<BigIntFilter>;
  fundings?: InputMaybe<BigIntFilter>;
  fundsUnderManagement?: InputMaybe<BigIntFilter>;
  googleUrl?: InputMaybe<BigIntFilter>;
  hqLocations?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<BigIntFilter>;
  industryExperience?: InputMaybe<BigIntFilter>;
  investmentStages?: InputMaybe<BigIntFilter>;
  investments?: InputMaybe<BigIntFilter>;
  investmentsNum?: InputMaybe<BigIntFilter>;
  investorType?: InputMaybe<BigIntFilter>;
  isBasecamp?: InputMaybe<BigIntFilter>;
  isEditorial?: InputMaybe<BigIntFilter>;
  isTrusted?: InputMaybe<BigIntFilter>;
  lastFunding?: InputMaybe<BigIntFilter>;
  lastFundingSource?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  launchMonth?: InputMaybe<BigIntFilter>;
  launchYear?: InputMaybe<BigIntFilter>;
  lgRanking?: InputMaybe<BigIntFilter>;
  linkedinUrl?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  path?: InputMaybe<BigIntFilter>;
  playmarketAppId?: InputMaybe<BigIntFilter>;
  recentFunding?: InputMaybe<BigIntFilter>;
  recentFundingSource?: InputMaybe<BigIntFilter>;
  refFundLead?: InputMaybe<BigIntFilter>;
  subTypes?: InputMaybe<BigIntFilter>;
  tagline?: InputMaybe<BigIntFilter>;
  tags?: InputMaybe<BigIntFilter>;
  team?: InputMaybe<BigIntFilter>;
  topInvestmentsNum?: InputMaybe<BigIntFilter>;
  totalFunding?: InputMaybe<BigIntFilter>;
  totalFundingSource?: InputMaybe<BigIntFilter>;
  twitterUrl?: InputMaybe<BigIntFilter>;
  url?: InputMaybe<BigIntFilter>;
  websiteUrl?: InputMaybe<BigIntFilter>;
};

export type LgInvestorDistinctCountAggregates = {
  __typename?: "LgInvestorDistinctCountAggregates";
  /** Distinct count of about across the matching connection */
  about?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of angellistUrl across the matching connection */
  angellistUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of appstoreAppId across the matching connection */
  appstoreAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of clientFocus across the matching connection */
  clientFocus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of coInvestors across the matching connection */
  coInvestors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of countryExperience across the matching connection */
  countryExperience?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealSize across the matching connection */
  dealSize?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dealStructure across the matching connection */
  dealStructure?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employees across the matching connection */
  employees?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundings across the matching connection */
  fundings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundsUnderManagement across the matching connection */
  fundsUnderManagement?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleUrl across the matching connection */
  googleUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqLocations across the matching connection */
  hqLocations?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of image across the matching connection */
  image?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of industryExperience across the matching connection */
  industryExperience?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentStages across the matching connection */
  investmentStages?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investments across the matching connection */
  investments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investorType across the matching connection */
  investorType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isBasecamp across the matching connection */
  isBasecamp?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isEditorial across the matching connection */
  isEditorial?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isTrusted across the matching connection */
  isTrusted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of playmarketAppId across the matching connection */
  playmarketAppId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of refFundLead across the matching connection */
  refFundLead?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subTypes across the matching connection */
  subTypes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tagline across the matching connection */
  tagline?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of team across the matching connection */
  team?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgInvestorFilter = {
  /** Filter by the object’s `about` field. */
  about?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgInvestorFilter>>;
  /** Filter by the object’s `angellistUrl` field. */
  angellistUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `appstoreAppId` field. */
  appstoreAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientFocus` field. */
  clientFocus?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `countryExperience` field. */
  countryExperience?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealSize` field. */
  dealSize?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dealStructure` field. */
  dealStructure?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dutchInvestmentsNum` field. */
  dutchInvestmentsNum?: InputMaybe<IntFilter>;
  /** Filter by the object’s `employees` field. */
  employees?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeesLatest` field. */
  employeesLatest?: InputMaybe<IntFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fundings` field. */
  fundings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `fundsUnderManagement` field. */
  fundsUnderManagement?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `googleUrl` field. */
  googleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hqLocations` field. */
  hqLocations?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<StringFilter>;
  /** Filter by the object’s `industryExperience` field. */
  industryExperience?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investmentStages` field. */
  investmentStages?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investments` field. */
  investments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `investmentsNum` field. */
  investmentsNum?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `investorType` field. */
  investorType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isBasecamp` field. */
  isBasecamp?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEditorial` field. */
  isEditorial?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTrusted` field. */
  isTrusted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastFunding` field. */
  lastFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastFundingSource` field. */
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `launchMonth` field. */
  launchMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `launchYear` field. */
  launchYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lgCompanyInvestorsByInvestorId` relation. */
  lgCompanyInvestorsByInvestorId?: InputMaybe<LgInvestorToManyLgCompanyInvestorFilter>;
  /** Some related `lgCompanyInvestorsByInvestorId` exist. */
  lgCompanyInvestorsByInvestorIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgFundingRoundInvestorsByInvestorId` relation. */
  lgFundingRoundInvestorsByInvestorId?: InputMaybe<LgInvestorToManyLgFundingRoundInvestorFilter>;
  /** Some related `lgFundingRoundInvestorsByInvestorId` exist. */
  lgFundingRoundInvestorsByInvestorIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgMemberByRefFundLead` relation. */
  lgMemberByRefFundLead?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberByRefFundLead` exists. */
  lgMemberByRefFundLeadExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgRanking` field. */
  lgRanking?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgInvestorFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playmarketAppId` field. */
  playmarketAppId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recentFunding` field. */
  recentFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `recentFundingSource` field. */
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `refFundLead` field. */
  refFundLead?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `srcInvestorsByEntityId` relation. */
  srcInvestorsByEntityId?: InputMaybe<LgInvestorToManySrcInvestorFilter>;
  /** Some related `srcInvestorsByEntityId` exist. */
  srcInvestorsByEntityIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `subTypes` field. */
  subTypes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `team` field. */
  team?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `topInvestmentsNum` field. */
  topInvestmentsNum?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFunding` field. */
  totalFunding?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFundingSource` field. */
  totalFundingSource?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgInvestor` for usage during aggregation. */
export enum LgInvestorGroupBy {
  About = "ABOUT",
  AngellistUrl = "ANGELLIST_URL",
  AppstoreAppId = "APPSTORE_APP_ID",
  ClientFocus = "CLIENT_FOCUS",
  CountryExperience = "COUNTRY_EXPERIENCE",
  CoInvestors = "CO_INVESTORS",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  CrunchbaseUrl = "CRUNCHBASE_URL",
  DealSize = "DEAL_SIZE",
  DealStructure = "DEAL_STRUCTURE",
  DutchInvestmentsNum = "DUTCH_INVESTMENTS_NUM",
  Employees = "EMPLOYEES",
  EmployeesLatest = "EMPLOYEES_LATEST",
  FacebookUrl = "FACEBOOK_URL",
  Fundings = "FUNDINGS",
  FundsUnderManagement = "FUNDS_UNDER_MANAGEMENT",
  GoogleUrl = "GOOGLE_URL",
  HqLocations = "HQ_LOCATIONS",
  Image = "IMAGE",
  IndustryExperience = "INDUSTRY_EXPERIENCE",
  Investments = "INVESTMENTS",
  InvestmentsNum = "INVESTMENTS_NUM",
  InvestmentStages = "INVESTMENT_STAGES",
  InvestorType = "INVESTOR_TYPE",
  IsBasecamp = "IS_BASECAMP",
  IsEditorial = "IS_EDITORIAL",
  IsTrusted = "IS_TRUSTED",
  LastFunding = "LAST_FUNDING",
  LastFundingSource = "LAST_FUNDING_SOURCE",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  LaunchMonth = "LAUNCH_MONTH",
  LaunchYear = "LAUNCH_YEAR",
  LgRanking = "LG_RANKING",
  LinkedinUrl = "LINKEDIN_URL",
  Name = "NAME",
  Path = "PATH",
  PlaymarketAppId = "PLAYMARKET_APP_ID",
  RecentFunding = "RECENT_FUNDING",
  RecentFundingSource = "RECENT_FUNDING_SOURCE",
  RefFundLead = "REF_FUND_LEAD",
  SubTypes = "SUB_TYPES",
  Tagline = "TAGLINE",
  Tags = "TAGS",
  Team = "TEAM",
  TopInvestmentsNum = "TOP_INVESTMENTS_NUM",
  TotalFunding = "TOTAL_FUNDING",
  TotalFundingSource = "TOTAL_FUNDING_SOURCE",
  TwitterUrl = "TWITTER_URL",
  Url = "URL",
  WebsiteUrl = "WEBSITE_URL",
}

export type LgInvestorHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

/** Conditions for `LgInvestor` aggregates. */
export type LgInvestorHavingInput = {
  AND?: InputMaybe<Array<LgInvestorHavingInput>>;
  OR?: InputMaybe<Array<LgInvestorHavingInput>>;
  average?: InputMaybe<LgInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<LgInvestorHavingDistinctCountInput>;
  max?: InputMaybe<LgInvestorHavingMaxInput>;
  min?: InputMaybe<LgInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<LgInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<LgInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<LgInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgInvestorHavingVarianceSampleInput>;
};

export type LgInvestorHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

export type LgInvestorHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  dutchInvestmentsNum?: InputMaybe<HavingIntFilter>;
  employeesLatest?: InputMaybe<HavingIntFilter>;
  investmentsNum?: InputMaybe<HavingBigintFilter>;
  lastFunding?: InputMaybe<HavingBigfloatFilter>;
  lastFundingSource?: InputMaybe<HavingBigfloatFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  launchMonth?: InputMaybe<HavingIntFilter>;
  launchYear?: InputMaybe<HavingIntFilter>;
  lgRanking?: InputMaybe<HavingBigfloatFilter>;
  recentFunding?: InputMaybe<HavingBigfloatFilter>;
  recentFundingSource?: InputMaybe<HavingBigfloatFilter>;
  topInvestmentsNum?: InputMaybe<HavingIntFilter>;
  totalFunding?: InputMaybe<HavingBigfloatFilter>;
  totalFundingSource?: InputMaybe<HavingBigfloatFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyInvestor`. */
export type LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyInvestor`, and the cursor to aid in pagination. */
  edges: Array<LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyInvestor`. */
export type LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyInvestor`. */
export type LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgInvestorLgCompaniesByLgCompanyInvestorInvestorIdAndCompanyIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  exited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isEditorial?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A connection to a list of `LgFundingRound` values, with data from `LgFundingRoundInvestor`. */
export type LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection = {
  __typename?: "LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFundingRoundAggregates>;
  /** A list of edges which contains the `LgFundingRound`, info from the `LgFundingRoundInvestor`, and the cursor to aid in pagination. */
  edges: Array<LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFundingRoundAggregates>>;
  /** A list of `LgFundingRound` objects. */
  nodes: Array<LgFundingRound>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFundingRound` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFundingRound` values, with data from `LgFundingRoundInvestor`. */
export type LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgFundingRoundGroupBy>;
    having?: InputMaybe<LgFundingRoundHavingInput>;
  };

/** A `LgFundingRound` edge in the connection, with data from `LgFundingRoundInvestor`. */
export type LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge = {
  __typename?: "LgInvestorLgFundingRoundsByLgFundingRoundInvestorInvestorIdAndFundingRoundIdManyToManyEdge";
  compositeId: Scalars["String"]["output"];
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  lead?: Maybe<Scalars["Boolean"]["output"]>;
  /** The `LgFundingRound` at the end of the edge. */
  node: LgFundingRound;
};

export type LgInvestorMaxAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<IntFilter>;
  employeesLatest?: InputMaybe<IntFilter>;
  investmentsNum?: InputMaybe<BigIntFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<IntFilter>;
  launchYear?: InputMaybe<IntFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<IntFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorMaxAggregates = {
  __typename?: "LgInvestorMaxAggregates";
  /** Maximum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgInvestorMinAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<IntFilter>;
  employeesLatest?: InputMaybe<IntFilter>;
  investmentsNum?: InputMaybe<BigIntFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<IntFilter>;
  launchYear?: InputMaybe<IntFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<IntFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorMinAggregates = {
  __typename?: "LgInvestorMinAggregates";
  /** Minimum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgInvestorStddevPopulationAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigFloatFilter>;
  employeesLatest?: InputMaybe<BigFloatFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigFloatFilter>;
  launchYear?: InputMaybe<BigFloatFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigFloatFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorStddevPopulationAggregates = {
  __typename?: "LgInvestorStddevPopulationAggregates";
  /** Population standard deviation of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgInvestorStddevSampleAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigFloatFilter>;
  employeesLatest?: InputMaybe<BigFloatFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigFloatFilter>;
  launchYear?: InputMaybe<BigFloatFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigFloatFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorStddevSampleAggregates = {
  __typename?: "LgInvestorStddevSampleAggregates";
  /** Sample standard deviation of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgInvestorSumAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigIntFilter>;
  employeesLatest?: InputMaybe<BigIntFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigIntFilter>;
  launchYear?: InputMaybe<BigIntFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigIntFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorSumAggregates = {
  __typename?: "LgInvestorSumAggregates";
  /** Sum of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum: Scalars["BigInt"]["output"];
  /** Sum of employeesLatest across the matching connection */
  employeesLatest: Scalars["BigInt"]["output"];
  /** Sum of investmentsNum across the matching connection */
  investmentsNum: Scalars["BigFloat"]["output"];
  /** Sum of lastFunding across the matching connection */
  lastFunding: Scalars["BigFloat"]["output"];
  /** Sum of lastFundingSource across the matching connection */
  lastFundingSource: Scalars["BigFloat"]["output"];
  /** Sum of launchMonth across the matching connection */
  launchMonth: Scalars["BigInt"]["output"];
  /** Sum of launchYear across the matching connection */
  launchYear: Scalars["BigInt"]["output"];
  /** Sum of lgRanking across the matching connection */
  lgRanking: Scalars["BigFloat"]["output"];
  /** Sum of recentFunding across the matching connection */
  recentFunding: Scalars["BigFloat"]["output"];
  /** Sum of recentFundingSource across the matching connection */
  recentFundingSource: Scalars["BigFloat"]["output"];
  /** Sum of topInvestmentsNum across the matching connection */
  topInvestmentsNum: Scalars["BigInt"]["output"];
  /** Sum of totalFunding across the matching connection */
  totalFunding: Scalars["BigFloat"]["output"];
  /** Sum of totalFundingSource across the matching connection */
  totalFundingSource: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `LgCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgInvestorToManyLgCompanyInvestorFilter = {
  /** Aggregates across related `LgCompanyInvestor` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyInvestorAggregatesFilter>;
  /** Every related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyInvestorFilter>;
  /** No related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyInvestorFilter>;
  /** Some related `LgCompanyInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyInvestorFilter>;
};

/** A filter to be used against many `LgFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgInvestorToManyLgFundingRoundInvestorFilter = {
  /** Aggregates across related `LgFundingRoundInvestor` match the filter criteria. */
  aggregates?: InputMaybe<LgFundingRoundInvestorAggregatesFilter>;
  /** Every related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** No related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** Some related `LgFundingRoundInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgFundingRoundInvestorFilter>;
};

/** A filter to be used against many `SrcInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgInvestorToManySrcInvestorFilter = {
  /** Aggregates across related `SrcInvestor` match the filter criteria. */
  aggregates?: InputMaybe<SrcInvestorAggregatesFilter>;
  /** Every related `SrcInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SrcInvestorFilter>;
  /** No related `SrcInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SrcInvestorFilter>;
  /** Some related `SrcInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SrcInvestorFilter>;
};

export type LgInvestorVariancePopulationAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigFloatFilter>;
  employeesLatest?: InputMaybe<BigFloatFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigFloatFilter>;
  launchYear?: InputMaybe<BigFloatFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigFloatFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorVariancePopulationAggregates = {
  __typename?: "LgInvestorVariancePopulationAggregates";
  /** Population variance of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgInvestorVarianceSampleAggregateFilter = {
  dutchInvestmentsNum?: InputMaybe<BigFloatFilter>;
  employeesLatest?: InputMaybe<BigFloatFilter>;
  investmentsNum?: InputMaybe<BigFloatFilter>;
  lastFunding?: InputMaybe<BigFloatFilter>;
  lastFundingSource?: InputMaybe<BigFloatFilter>;
  launchMonth?: InputMaybe<BigFloatFilter>;
  launchYear?: InputMaybe<BigFloatFilter>;
  lgRanking?: InputMaybe<BigFloatFilter>;
  recentFunding?: InputMaybe<BigFloatFilter>;
  recentFundingSource?: InputMaybe<BigFloatFilter>;
  topInvestmentsNum?: InputMaybe<BigFloatFilter>;
  totalFunding?: InputMaybe<BigFloatFilter>;
  totalFundingSource?: InputMaybe<BigFloatFilter>;
};

export type LgInvestorVarianceSampleAggregates = {
  __typename?: "LgInvestorVarianceSampleAggregates";
  /** Sample variance of dutchInvestmentsNum across the matching connection */
  dutchInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of employeesLatest across the matching connection */
  employeesLatest?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of investmentsNum across the matching connection */
  investmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFunding across the matching connection */
  lastFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lastFundingSource across the matching connection */
  lastFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchMonth across the matching connection */
  launchMonth?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of launchYear across the matching connection */
  launchYear?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of lgRanking across the matching connection */
  lgRanking?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of recentFunding across the matching connection */
  recentFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of recentFundingSource across the matching connection */
  recentFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of topInvestmentsNum across the matching connection */
  topInvestmentsNum?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFunding across the matching connection */
  totalFunding?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalFundingSource across the matching connection */
  totalFundingSource?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgInvestor` values. */
export type LgInvestorsConnection = {
  __typename?: "LgInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgInvestorAggregates>;
  /** A list of edges which contains the `LgInvestor` and cursor to aid in pagination. */
  edges: Array<LgInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgInvestorAggregates>>;
  /** A list of `LgInvestor` objects. */
  nodes: Array<LgInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgInvestor` values. */
export type LgInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgInvestorGroupBy>;
  having?: InputMaybe<LgInvestorHavingInput>;
};

/** A `LgInvestor` edge in the connection. */
export type LgInvestorsEdge = {
  __typename?: "LgInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgInvestor` at the end of the edge. */
  node: LgInvestor;
};

/** Methods to use when ordering `LgInvestor`. */
export enum LgInvestorsOrderBy {
  AboutAsc = "ABOUT_ASC",
  AboutDesc = "ABOUT_DESC",
  AngellistUrlAsc = "ANGELLIST_URL_ASC",
  AngellistUrlDesc = "ANGELLIST_URL_DESC",
  AppstoreAppIdAsc = "APPSTORE_APP_ID_ASC",
  AppstoreAppIdDesc = "APPSTORE_APP_ID_DESC",
  ClientFocusAsc = "CLIENT_FOCUS_ASC",
  ClientFocusDesc = "CLIENT_FOCUS_DESC",
  CountryExperienceAsc = "COUNTRY_EXPERIENCE_ASC",
  CountryExperienceDesc = "COUNTRY_EXPERIENCE_DESC",
  CoInvestorsAsc = "CO_INVESTORS_ASC",
  CoInvestorsDesc = "CO_INVESTORS_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  CrunchbaseUrlAsc = "CRUNCHBASE_URL_ASC",
  CrunchbaseUrlDesc = "CRUNCHBASE_URL_DESC",
  DealSizeAsc = "DEAL_SIZE_ASC",
  DealSizeDesc = "DEAL_SIZE_DESC",
  DealStructureAsc = "DEAL_STRUCTURE_ASC",
  DealStructureDesc = "DEAL_STRUCTURE_DESC",
  DutchInvestmentsNumAsc = "DUTCH_INVESTMENTS_NUM_ASC",
  DutchInvestmentsNumDesc = "DUTCH_INVESTMENTS_NUM_DESC",
  EmployeesAsc = "EMPLOYEES_ASC",
  EmployeesDesc = "EMPLOYEES_DESC",
  EmployeesLatestAsc = "EMPLOYEES_LATEST_ASC",
  EmployeesLatestDesc = "EMPLOYEES_LATEST_DESC",
  FacebookUrlAsc = "FACEBOOK_URL_ASC",
  FacebookUrlDesc = "FACEBOOK_URL_DESC",
  FundingsAsc = "FUNDINGS_ASC",
  FundingsDesc = "FUNDINGS_DESC",
  FundsUnderManagementAsc = "FUNDS_UNDER_MANAGEMENT_ASC",
  FundsUnderManagementDesc = "FUNDS_UNDER_MANAGEMENT_DESC",
  GoogleUrlAsc = "GOOGLE_URL_ASC",
  GoogleUrlDesc = "GOOGLE_URL_DESC",
  HqLocationsAsc = "HQ_LOCATIONS_ASC",
  HqLocationsDesc = "HQ_LOCATIONS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ImageAsc = "IMAGE_ASC",
  ImageDesc = "IMAGE_DESC",
  IndustryExperienceAsc = "INDUSTRY_EXPERIENCE_ASC",
  IndustryExperienceDesc = "INDUSTRY_EXPERIENCE_DESC",
  InvestmentsAsc = "INVESTMENTS_ASC",
  InvestmentsDesc = "INVESTMENTS_DESC",
  InvestmentsNumAsc = "INVESTMENTS_NUM_ASC",
  InvestmentsNumDesc = "INVESTMENTS_NUM_DESC",
  InvestmentStagesAsc = "INVESTMENT_STAGES_ASC",
  InvestmentStagesDesc = "INVESTMENT_STAGES_DESC",
  InvestorTypeAsc = "INVESTOR_TYPE_ASC",
  InvestorTypeDesc = "INVESTOR_TYPE_DESC",
  IsBasecampAsc = "IS_BASECAMP_ASC",
  IsBasecampDesc = "IS_BASECAMP_DESC",
  IsEditorialAsc = "IS_EDITORIAL_ASC",
  IsEditorialDesc = "IS_EDITORIAL_DESC",
  IsTrustedAsc = "IS_TRUSTED_ASC",
  IsTrustedDesc = "IS_TRUSTED_DESC",
  LastFundingAsc = "LAST_FUNDING_ASC",
  LastFundingDesc = "LAST_FUNDING_DESC",
  LastFundingSourceAsc = "LAST_FUNDING_SOURCE_ASC",
  LastFundingSourceDesc = "LAST_FUNDING_SOURCE_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  LaunchMonthAsc = "LAUNCH_MONTH_ASC",
  LaunchMonthDesc = "LAUNCH_MONTH_DESC",
  LaunchYearAsc = "LAUNCH_YEAR_ASC",
  LaunchYearDesc = "LAUNCH_YEAR_DESC",
  LgCompanyInvestorsByInvestorIdAverageCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdAverageCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdAverageCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdAverageCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdAverageDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdAverageDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdAverageExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdAverageExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdAverageIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_ASC",
  LgCompanyInvestorsByInvestorIdAverageIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_DESC",
  LgCompanyInvestorsByInvestorIdAverageInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdAverageInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdAverageIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdAverageIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_AVERAGE_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdCountAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_COUNT_ASC",
  LgCompanyInvestorsByInvestorIdCountDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_COUNT_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdDistinctCountIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdDistinctCountIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdMaxCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdMaxCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdMaxCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdMaxCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdMaxDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdMaxDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdMaxExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdMaxExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdMaxIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_ID_ASC",
  LgCompanyInvestorsByInvestorIdMaxIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_ID_DESC",
  LgCompanyInvestorsByInvestorIdMaxInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdMaxInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdMaxIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdMaxIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MAX_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdMinCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdMinCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdMinCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdMinCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdMinDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdMinDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdMinExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdMinExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdMinIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_ID_ASC",
  LgCompanyInvestorsByInvestorIdMinIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_ID_DESC",
  LgCompanyInvestorsByInvestorIdMinInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdMinInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdMinIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdMinIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_MIN_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevPopulationIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdStddevPopulationIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdStddevSampleIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdStddevSampleIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdSumCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdSumCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdSumCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdSumCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdSumDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdSumDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdSumExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdSumExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdSumIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_ID_ASC",
  LgCompanyInvestorsByInvestorIdSumIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_ID_DESC",
  LgCompanyInvestorsByInvestorIdSumInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdSumInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdSumIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdSumIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_SUM_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdVariancePopulationIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdVariancePopulationIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_IS_EDITORIAL_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleCompanyIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleCompanyIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleCompositeIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleCompositeIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleDeletedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleDeletedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleExitedAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_EXITED_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleExitedDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_EXITED_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleInvestorIdAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleInvestorIdDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  LgCompanyInvestorsByInvestorIdVarianceSampleIsEditorialAsc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_IS_EDITORIAL_ASC",
  LgCompanyInvestorsByInvestorIdVarianceSampleIsEditorialDesc = "LG_COMPANY_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_IS_EDITORIAL_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdAverageLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdAverageLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_AVERAGE_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdCountAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_COUNT_ASC",
  LgFundingRoundInvestorsByInvestorIdCountDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_COUNT_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdDistinctCountLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_DISTINCT_COUNT_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMaxLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdMaxLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MAX_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdMinCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMinCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMinDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdMinDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdMinFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMinFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMinIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMinIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMinInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdMinInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdMinLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdMinLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_MIN_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevPopulationLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_POPULATION_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdStddevSampleLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_STDDEV_SAMPLE_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdSumCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdSumCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdSumDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdSumDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdSumFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdSumFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdSumIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdSumIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdSumInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdSumInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdSumLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdSumLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_SUM_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdVariancePopulationLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_POPULATION_LEAD_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleCompositeIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleCompositeIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_COMPOSITE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleDeletedAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_DELETED_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleDeletedDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_DELETED_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleFundingRoundIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleFundingRoundIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_FUNDING_ROUND_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleInvestorIdAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleInvestorIdDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_INVESTOR_ID_DESC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleLeadAsc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_LEAD_ASC",
  LgFundingRoundInvestorsByInvestorIdVarianceSampleLeadDesc = "LG_FUNDING_ROUND_INVESTORS_BY_INVESTOR_ID_VARIANCE_SAMPLE_LEAD_DESC",
  LgRankingAsc = "LG_RANKING_ASC",
  LgRankingDesc = "LG_RANKING_DESC",
  LinkedinUrlAsc = "LINKEDIN_URL_ASC",
  LinkedinUrlDesc = "LINKEDIN_URL_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PathAsc = "PATH_ASC",
  PathDesc = "PATH_DESC",
  PlaymarketAppIdAsc = "PLAYMARKET_APP_ID_ASC",
  PlaymarketAppIdDesc = "PLAYMARKET_APP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RecentFundingAsc = "RECENT_FUNDING_ASC",
  RecentFundingDesc = "RECENT_FUNDING_DESC",
  RecentFundingSourceAsc = "RECENT_FUNDING_SOURCE_ASC",
  RecentFundingSourceDesc = "RECENT_FUNDING_SOURCE_DESC",
  RefFundLeadAsc = "REF_FUND_LEAD_ASC",
  RefFundLeadDesc = "REF_FUND_LEAD_DESC",
  SrcInvestorsByEntityIdAverageCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdAverageCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdAverageEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdAverageEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdAverageIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_ASC",
  SrcInvestorsByEntityIdAverageIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_ID_DESC",
  SrcInvestorsByEntityIdAverageLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdAverageLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdAverageSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdAverageSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdAverageSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdAverageSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_AVERAGE_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdCountAsc = "SRC_INVESTORS_BY_ENTITY_ID_COUNT_ASC",
  SrcInvestorsByEntityIdCountDesc = "SRC_INVESTORS_BY_ENTITY_ID_COUNT_DESC",
  SrcInvestorsByEntityIdDistinctCountCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdDistinctCountCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdDistinctCountEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdDistinctCountEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdDistinctCountIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_ASC",
  SrcInvestorsByEntityIdDistinctCountIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_ID_DESC",
  SrcInvestorsByEntityIdDistinctCountLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdDistinctCountLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdDistinctCountSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdDistinctCountSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdDistinctCountSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdDistinctCountSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdMaxCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdMaxCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdMaxEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdMaxEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdMaxIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_ID_ASC",
  SrcInvestorsByEntityIdMaxIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_ID_DESC",
  SrcInvestorsByEntityIdMaxLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdMaxLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdMaxSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdMaxSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdMaxSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdMaxSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_MAX_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdMinCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdMinCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdMinEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdMinEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdMinIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_ID_ASC",
  SrcInvestorsByEntityIdMinIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_ID_DESC",
  SrcInvestorsByEntityIdMinLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdMinLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdMinSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdMinSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdMinSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdMinSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_MIN_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdStddevPopulationCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdStddevPopulationCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdStddevPopulationEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdStddevPopulationEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdStddevPopulationIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_ASC",
  SrcInvestorsByEntityIdStddevPopulationIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_ID_DESC",
  SrcInvestorsByEntityIdStddevPopulationLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdStddevPopulationLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdStddevPopulationSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdStddevPopulationSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdStddevPopulationSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdStddevPopulationSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdStddevSampleCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdStddevSampleCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdStddevSampleEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdStddevSampleEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdStddevSampleIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_ASC",
  SrcInvestorsByEntityIdStddevSampleIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_ID_DESC",
  SrcInvestorsByEntityIdStddevSampleLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdStddevSampleLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdStddevSampleSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdStddevSampleSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdStddevSampleSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdStddevSampleSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdSumCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdSumCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdSumEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdSumEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdSumIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_ID_ASC",
  SrcInvestorsByEntityIdSumIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_ID_DESC",
  SrcInvestorsByEntityIdSumLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdSumLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdSumSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdSumSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdSumSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdSumSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_SUM_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdVariancePopulationCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdVariancePopulationCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdVariancePopulationEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdVariancePopulationEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdVariancePopulationIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_ASC",
  SrcInvestorsByEntityIdVariancePopulationIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_ID_DESC",
  SrcInvestorsByEntityIdVariancePopulationLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdVariancePopulationLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdVariancePopulationSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdVariancePopulationSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdVariancePopulationSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdVariancePopulationSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  SrcInvestorsByEntityIdVarianceSampleCreatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SrcInvestorsByEntityIdVarianceSampleCreatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SrcInvestorsByEntityIdVarianceSampleEntityIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_ASC",
  SrcInvestorsByEntityIdVarianceSampleEntityIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ENTITY_ID_DESC",
  SrcInvestorsByEntityIdVarianceSampleIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_ASC",
  SrcInvestorsByEntityIdVarianceSampleIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_ID_DESC",
  SrcInvestorsByEntityIdVarianceSampleLastUpdatedUtcAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  SrcInvestorsByEntityIdVarianceSampleLastUpdatedUtcDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  SrcInvestorsByEntityIdVarianceSampleSourceIdAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_ASC",
  SrcInvestorsByEntityIdVarianceSampleSourceIdDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_ID_DESC",
  SrcInvestorsByEntityIdVarianceSampleSourceTypeAsc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  SrcInvestorsByEntityIdVarianceSampleSourceTypeDesc = "SRC_INVESTORS_BY_ENTITY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  SubTypesAsc = "SUB_TYPES_ASC",
  SubTypesDesc = "SUB_TYPES_DESC",
  TaglineAsc = "TAGLINE_ASC",
  TaglineDesc = "TAGLINE_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TeamAsc = "TEAM_ASC",
  TeamDesc = "TEAM_DESC",
  TopInvestmentsNumAsc = "TOP_INVESTMENTS_NUM_ASC",
  TopInvestmentsNumDesc = "TOP_INVESTMENTS_NUM_DESC",
  TotalFundingAsc = "TOTAL_FUNDING_ASC",
  TotalFundingDesc = "TOTAL_FUNDING_DESC",
  TotalFundingSourceAsc = "TOTAL_FUNDING_SOURCE_ASC",
  TotalFundingSourceDesc = "TOTAL_FUNDING_SOURCE_DESC",
  TwitterUrlAsc = "TWITTER_URL_ASC",
  TwitterUrlDesc = "TWITTER_URL_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
  WebsiteUrlAsc = "WEBSITE_URL_ASC",
  WebsiteUrlDesc = "WEBSITE_URL_DESC",
}

export type LgMember = Node & {
  __typename?: "LgMember";
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  initials: Scalars["String"]["output"];
  isInvestor: Scalars["Boolean"]["output"];
  lastAgendaUtc?: Maybe<Scalars["Datetime"]["output"]>;
  lastModifiedUtc: Scalars["Datetime"]["output"];
  lastName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyCommentCreatedByAndCompanyId: LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyFlagCreatedByAndCompanyId: LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByLgCompanyNoteCreatedByAndCompanyId: LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByNzrSentimentFormCreatedByIdAndCompanyId: LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByNzrUserCompanyTagUserIdAndCompanyId: LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgCompanyComment`. */
  lgCompanyCommentsByCreatedBy: LgCompanyCommentsConnection;
  /** Reads and enables pagination through a set of `LgCompanyFlag`. */
  lgCompanyFlagsByCreatedBy: LgCompanyFlagsConnection;
  /** Reads and enables pagination through a set of `LgCompanyNote`. */
  lgCompanyNotesByCreatedBy: LgCompanyNotesConnection;
  /** Reads and enables pagination through a set of `LgFile`. */
  lgFilesByCreatedBy: LgFilesConnection;
  /** Reads and enables pagination through a set of `LgFounder`. */
  lgFoundersByNzrSentimentFormCreatedByIdAndFounderId: LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LgInvestor`. */
  lgInvestorsByRefFundLead: LgInvestorsConnection;
  /** Reads and enables pagination through a set of `LgMemberSetting`. */
  lgMemberSettingsByUserId: LgMemberSettingsConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrOpportunityAssigneeAndSharedFrom: LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrOpportunitySharedFromAndAssignee: LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyConnection;
  /** Reads and enables pagination through a set of `LgUserFeedback`. */
  lgUserFeedbacksByCreatedBy: LgUserFeedbacksConnection;
  /** Reads and enables pagination through a set of `MnEntry`. */
  mnEntriesByMemberId: MnEntriesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `NzrNotification`. */
  nzrNotificationsByAssignee: NzrNotificationsConnection;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesByAssignee: NzrOpportunitiesConnection;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesBySharedFrom: NzrOpportunitiesConnection;
  /** Reads and enables pagination through a set of `NzrOpportunityEmail`. */
  nzrOpportunityEmailsByMemberId: NzrOpportunityEmailsConnection;
  /** Reads and enables pagination through a set of `NzrSentimentFormAssignee`. */
  nzrSentimentFormAssigneesByMemberId: NzrSentimentFormAssigneesConnection;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCreatedById: NzrSentimentFormsConnection;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormId: LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `NzrUserCompanyTag`. */
  nzrUserCompanyTagsByUserId: NzrUserCompanyTagsConnection;
  sectors: Scalars["JSON"]["output"];
  settings?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsByNzrOpportunityAssigneeAndSignalId: LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsByNzrOpportunitySharedFromAndSignalId: LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyConnection;
  title?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `TrelloMember` that is related to this `LgMember`. */
  trelloMember?: Maybe<TrelloMember>;
  trelloMemberId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
  /** Reads and enables pagination through a set of `UserReport`. */
  userReportsByUserId: UserReportsConnection;
  username: Scalars["String"]["output"];
};

export type LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type LgMemberLgCompanyCommentsByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCommentCondition>;
  filter?: InputMaybe<LgCompanyCommentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCommentsOrderBy>>;
};

export type LgMemberLgCompanyFlagsByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFlagCondition>;
  filter?: InputMaybe<LgCompanyFlagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFlagsOrderBy>>;
};

export type LgMemberLgCompanyNotesByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyNoteCondition>;
  filter?: InputMaybe<LgCompanyNoteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyNotesOrderBy>>;
};

export type LgMemberLgFilesByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFileCondition>;
  filter?: InputMaybe<LgFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFilesOrderBy>>;
};

export type LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFounderCondition>;
  filter?: InputMaybe<LgFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFoundersOrderBy>>;
};

export type LgMemberLgInvestorsByRefFundLeadArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgInvestorCondition>;
  filter?: InputMaybe<LgInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgInvestorsOrderBy>>;
};

export type LgMemberLgMemberSettingsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberSettingCondition>;
  filter?: InputMaybe<LgMemberSettingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMemberSettingsOrderBy>>;
};

export type LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type LgMemberLgUserFeedbacksByCreatedByArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgUserFeedbackCondition>;
  filter?: InputMaybe<LgUserFeedbackFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgUserFeedbacksOrderBy>>;
};

export type LgMemberMnEntriesByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MnEntryCondition>;
  filter?: InputMaybe<MnEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MnEntriesOrderBy>>;
};

export type LgMemberNzrNotificationsByAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrNotificationCondition>;
  filter?: InputMaybe<NzrNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy>>;
};

export type LgMemberNzrOpportunitiesByAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type LgMemberNzrOpportunitiesBySharedFromArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type LgMemberNzrOpportunityEmailsByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCondition>;
  filter?: InputMaybe<NzrOpportunityEmailFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailsOrderBy>>;
};

export type LgMemberNzrSentimentFormAssigneesByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormAssigneeCondition>;
  filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormAssigneesOrderBy>>;
};

export type LgMemberNzrSentimentFormsByCreatedByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

export type LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

export type LgMemberNzrUserCompanyTagsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrUserCompanyTagCondition>;
  filter?: InputMaybe<NzrUserCompanyTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrUserCompanyTagsOrderBy>>;
};

export type LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type LgMemberUserReportsByUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserReportCondition>;
  filter?: InputMaybe<UserReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserReportsOrderBy>>;
};

export type LgMemberAggregates = {
  __typename?: "LgMemberAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LgMemberAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgMemberDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LgMemberMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LgMemberMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LgMemberStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LgMemberStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LgMemberSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LgMemberVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LgMemberVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LgMember` object types. */
export type LgMemberAggregatesFilter = {
  /** Mean average aggregate over matching `LgMember` objects. */
  average?: InputMaybe<LgMemberAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LgMember` objects. */
  distinctCount?: InputMaybe<LgMemberDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgMember` object to be included within the aggregate. */
  filter?: InputMaybe<LgMemberFilter>;
  /** Maximum aggregate over matching `LgMember` objects. */
  max?: InputMaybe<LgMemberMaxAggregateFilter>;
  /** Minimum aggregate over matching `LgMember` objects. */
  min?: InputMaybe<LgMemberMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LgMember` objects. */
  stddevPopulation?: InputMaybe<LgMemberStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LgMember` objects. */
  stddevSample?: InputMaybe<LgMemberStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LgMember` objects. */
  sum?: InputMaybe<LgMemberSumAggregateFilter>;
  /** Population variance aggregate over matching `LgMember` objects. */
  variancePopulation?: InputMaybe<LgMemberVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LgMember` objects. */
  varianceSample?: InputMaybe<LgMemberVarianceSampleAggregateFilter>;
};

export type LgMemberAverageAggregateFilter = {
  userId?: InputMaybe<BigFloatFilter>;
};

export type LgMemberAverageAggregates = {
  __typename?: "LgMemberAverageAggregates";
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `LgMember` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LgMemberCondition = {
  /** Checks for equality with the object’s `avatar` field. */
  avatar?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `initials` field. */
  initials?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isInvestor` field. */
  isInvestor?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastAgendaUtc` field. */
  lastAgendaUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sectors` field. */
  sectors?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `settings` field. */
  settings?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloMemberId` field. */
  trelloMemberId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgMemberDistinctCountAggregateFilter = {
  avatar?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  email?: InputMaybe<BigIntFilter>;
  firstName?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  initials?: InputMaybe<BigIntFilter>;
  isInvestor?: InputMaybe<BigIntFilter>;
  lastAgendaUtc?: InputMaybe<BigIntFilter>;
  lastModifiedUtc?: InputMaybe<BigIntFilter>;
  lastName?: InputMaybe<BigIntFilter>;
  sectors?: InputMaybe<BigIntFilter>;
  settings?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  trelloMemberId?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
  username?: InputMaybe<BigIntFilter>;
};

export type LgMemberDistinctCountAggregates = {
  __typename?: "LgMemberDistinctCountAggregates";
  /** Distinct count of avatar across the matching connection */
  avatar?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initials across the matching connection */
  initials?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isInvestor across the matching connection */
  isInvestor?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastAgendaUtc across the matching connection */
  lastAgendaUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastModifiedUtc across the matching connection */
  lastModifiedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sectors across the matching connection */
  sectors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of settings across the matching connection */
  settings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloMemberId across the matching connection */
  trelloMemberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgMember` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgMemberFilter>>;
  /** Filter by the object’s `avatar` field. */
  avatar?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `initials` field. */
  initials?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isInvestor` field. */
  isInvestor?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAgendaUtc` field. */
  lastAgendaUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lgCompanyCommentsByCreatedBy` relation. */
  lgCompanyCommentsByCreatedBy?: InputMaybe<LgMemberToManyLgCompanyCommentFilter>;
  /** Some related `lgCompanyCommentsByCreatedBy` exist. */
  lgCompanyCommentsByCreatedByExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyFlagsByCreatedBy` relation. */
  lgCompanyFlagsByCreatedBy?: InputMaybe<LgMemberToManyLgCompanyFlagFilter>;
  /** Some related `lgCompanyFlagsByCreatedBy` exist. */
  lgCompanyFlagsByCreatedByExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyNotesByCreatedBy` relation. */
  lgCompanyNotesByCreatedBy?: InputMaybe<LgMemberToManyLgCompanyNoteFilter>;
  /** Some related `lgCompanyNotesByCreatedBy` exist. */
  lgCompanyNotesByCreatedByExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgFilesByCreatedBy` relation. */
  lgFilesByCreatedBy?: InputMaybe<LgMemberToManyLgFileFilter>;
  /** Some related `lgFilesByCreatedBy` exist. */
  lgFilesByCreatedByExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgInvestorsByRefFundLead` relation. */
  lgInvestorsByRefFundLead?: InputMaybe<LgMemberToManyLgInvestorFilter>;
  /** Some related `lgInvestorsByRefFundLead` exist. */
  lgInvestorsByRefFundLeadExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgMemberSettingsByUserId` relation. */
  lgMemberSettingsByUserId?: InputMaybe<LgMemberToManyLgMemberSettingFilter>;
  /** Some related `lgMemberSettingsByUserId` exist. */
  lgMemberSettingsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgUserFeedbacksByCreatedBy` relation. */
  lgUserFeedbacksByCreatedBy?: InputMaybe<LgMemberToManyLgUserFeedbackFilter>;
  /** Some related `lgUserFeedbacksByCreatedBy` exist. */
  lgUserFeedbacksByCreatedByExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `mnEntriesByMemberId` relation. */
  mnEntriesByMemberId?: InputMaybe<LgMemberToManyMnEntryFilter>;
  /** Some related `mnEntriesByMemberId` exist. */
  mnEntriesByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `nzrNotificationsByAssignee` relation. */
  nzrNotificationsByAssignee?: InputMaybe<LgMemberToManyNzrNotificationFilter>;
  /** Some related `nzrNotificationsByAssignee` exist. */
  nzrNotificationsByAssigneeExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrOpportunitiesByAssignee` relation. */
  nzrOpportunitiesByAssignee?: InputMaybe<LgMemberToManyNzrOpportunityFilter>;
  /** Some related `nzrOpportunitiesByAssignee` exist. */
  nzrOpportunitiesByAssigneeExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrOpportunitiesBySharedFrom` relation. */
  nzrOpportunitiesBySharedFrom?: InputMaybe<LgMemberToManyNzrOpportunityFilter>;
  /** Some related `nzrOpportunitiesBySharedFrom` exist. */
  nzrOpportunitiesBySharedFromExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrOpportunityEmailsByMemberId` relation. */
  nzrOpportunityEmailsByMemberId?: InputMaybe<LgMemberToManyNzrOpportunityEmailFilter>;
  /** Some related `nzrOpportunityEmailsByMemberId` exist. */
  nzrOpportunityEmailsByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrSentimentFormAssigneesByMemberId` relation. */
  nzrSentimentFormAssigneesByMemberId?: InputMaybe<LgMemberToManyNzrSentimentFormAssigneeFilter>;
  /** Some related `nzrSentimentFormAssigneesByMemberId` exist. */
  nzrSentimentFormAssigneesByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrSentimentFormsByCreatedById` relation. */
  nzrSentimentFormsByCreatedById?: InputMaybe<LgMemberToManyNzrSentimentFormFilter>;
  /** Some related `nzrSentimentFormsByCreatedById` exist. */
  nzrSentimentFormsByCreatedByIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrUserCompanyTagsByUserId` relation. */
  nzrUserCompanyTagsByUserId?: InputMaybe<LgMemberToManyNzrUserCompanyTagFilter>;
  /** Some related `nzrUserCompanyTagsByUserId` exist. */
  nzrUserCompanyTagsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgMemberFilter>>;
  /** Filter by the object’s `sectors` field. */
  sectors?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `settings` field. */
  settings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloMember` relation. */
  trelloMember?: InputMaybe<TrelloMemberFilter>;
  /** A related `trelloMember` exists. */
  trelloMemberExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `trelloMemberId` field. */
  trelloMemberId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userReportsByUserId` relation. */
  userReportsByUserId?: InputMaybe<LgMemberToManyUserReportFilter>;
  /** Some related `userReportsByUserId` exist. */
  userReportsByUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgMember` for usage during aggregation. */
export enum LgMemberGroupBy {
  Avatar = "AVATAR",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Email = "EMAIL",
  FirstName = "FIRST_NAME",
  Initials = "INITIALS",
  IsInvestor = "IS_INVESTOR",
  LastAgendaUtc = "LAST_AGENDA_UTC",
  LastAgendaUtcTruncatedToDay = "LAST_AGENDA_UTC_TRUNCATED_TO_DAY",
  LastAgendaUtcTruncatedToHour = "LAST_AGENDA_UTC_TRUNCATED_TO_HOUR",
  LastModifiedUtc = "LAST_MODIFIED_UTC",
  LastModifiedUtcTruncatedToDay = "LAST_MODIFIED_UTC_TRUNCATED_TO_DAY",
  LastModifiedUtcTruncatedToHour = "LAST_MODIFIED_UTC_TRUNCATED_TO_HOUR",
  LastName = "LAST_NAME",
  Sectors = "SECTORS",
  Settings = "SETTINGS",
  Title = "TITLE",
  TrelloMemberId = "TRELLO_MEMBER_ID",
  Username = "USERNAME",
  UserId = "USER_ID",
}

export type LgMemberHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LgMember` aggregates. */
export type LgMemberHavingInput = {
  AND?: InputMaybe<Array<LgMemberHavingInput>>;
  OR?: InputMaybe<Array<LgMemberHavingInput>>;
  average?: InputMaybe<LgMemberHavingAverageInput>;
  distinctCount?: InputMaybe<LgMemberHavingDistinctCountInput>;
  max?: InputMaybe<LgMemberHavingMaxInput>;
  min?: InputMaybe<LgMemberHavingMinInput>;
  stddevPopulation?: InputMaybe<LgMemberHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgMemberHavingStddevSampleInput>;
  sum?: InputMaybe<LgMemberHavingSumInput>;
  variancePopulation?: InputMaybe<LgMemberHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgMemberHavingVarianceSampleInput>;
};

export type LgMemberHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

export type LgMemberHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastAgendaUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyComment`. */
export type LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyConnection = {
  __typename?: "LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyComment`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyComment`. */
export type LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyComment`. */
export type LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyEdge = {
  __typename?: "LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyComment`. */
  lgCompanyCommentsByCompanyId: LgCompanyCommentsConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyComment`. */
export type LgMemberLgCompaniesByLgCompanyCommentCreatedByAndCompanyIdManyToManyEdgeLgCompanyCommentsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCommentCondition>;
  filter?: InputMaybe<LgCompanyCommentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCommentsOrderBy>>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyFlag`. */
export type LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyConnection = {
  __typename?: "LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyFlag`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyFlag`. */
export type LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyFlag`. */
export type LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyEdge = {
  __typename?: "LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyFlag`. */
  lgCompanyFlagsByCompanyId: LgCompanyFlagsConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyFlag`. */
export type LgMemberLgCompaniesByLgCompanyFlagCreatedByAndCompanyIdManyToManyEdgeLgCompanyFlagsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFlagCondition>;
  filter?: InputMaybe<LgCompanyFlagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFlagsOrderBy>>;
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyNote`. */
export type LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyConnection = {
  __typename?: "LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `LgCompanyNote`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `LgCompanyNote`. */
export type LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyNote`. */
export type LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyEdge = {
  __typename?: "LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompanyNote`. */
  lgCompanyNotesByCompanyId: LgCompanyNotesConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `LgCompanyNote`. */
export type LgMemberLgCompaniesByLgCompanyNoteCreatedByAndCompanyIdManyToManyEdgeLgCompanyNotesByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyNoteCondition>;
  filter?: InputMaybe<LgCompanyNoteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyNotesOrderBy>>;
};

/** A connection to a list of `LgCompany` values, with data from `NzrSentimentForm`. */
export type LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `NzrSentimentForm`. */
export type LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `NzrSentimentForm`. */
export type LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByCompanyId: NzrSentimentFormsConnection;
};

/** A `LgCompany` edge in the connection, with data from `NzrSentimentForm`. */
export type LgMemberLgCompaniesByNzrSentimentFormCreatedByIdAndCompanyIdManyToManyEdgeNzrSentimentFormsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormCondition>;
    filter?: InputMaybe<NzrSentimentFormFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    includeDeleted?: InputMaybe<IncludeDeletedOption>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
  };

/** A connection to a list of `LgCompany` values, with data from `NzrUserCompanyTag`. */
export type LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyConnection = {
  __typename?: "LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `NzrUserCompanyTag`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `NzrUserCompanyTag`. */
export type LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `NzrUserCompanyTag`. */
export type LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyEdge = {
  __typename?: "LgMemberLgCompaniesByNzrUserCompanyTagUserIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  tags: Scalars["JSON"]["output"];
};

/** A connection to a list of `LgFounder` values, with data from `NzrSentimentForm`. */
export type LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyConnection = {
  __typename?: "LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgFounderAggregates>;
  /** A list of edges which contains the `LgFounder`, info from the `NzrSentimentForm`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgFounderAggregates>>;
  /** A list of `LgFounder` objects. */
  nodes: Array<LgFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgFounder` values, with data from `NzrSentimentForm`. */
export type LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgFounderGroupBy>;
  having?: InputMaybe<LgFounderHavingInput>;
};

/** A `LgFounder` edge in the connection, with data from `NzrSentimentForm`. */
export type LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyEdge = {
  __typename?: "LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgFounder` at the end of the edge. */
  node: LgFounder;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentFormsByFounderId: NzrSentimentFormsConnection;
};

/** A `LgFounder` edge in the connection, with data from `NzrSentimentForm`. */
export type LgMemberLgFoundersByNzrSentimentFormCreatedByIdAndFounderIdManyToManyEdgeNzrSentimentFormsByFounderIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormCondition>;
    filter?: InputMaybe<NzrSentimentFormFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    includeDeleted?: InputMaybe<IncludeDeletedOption>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
  };

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyConnection = {
  __typename?: "LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyEdge = {
  __typename?: "LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesBySharedFrom: NzrOpportunitiesConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunityAssigneeAndSharedFromManyToManyEdgeNzrOpportunitiesBySharedFromArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyConnection = {
  __typename?: "LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyEdge = {
  __typename?: "LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesByAssignee: NzrOpportunitiesConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberLgMembersByNzrOpportunitySharedFromAndAssigneeManyToManyEdgeNzrOpportunitiesByAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type LgMemberMaxAggregateFilter = {
  userId?: InputMaybe<IntFilter>;
};

export type LgMemberMaxAggregates = {
  __typename?: "LgMemberMaxAggregates";
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type LgMemberMinAggregateFilter = {
  userId?: InputMaybe<IntFilter>;
};

export type LgMemberMinAggregates = {
  __typename?: "LgMemberMinAggregates";
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars["Int"]["output"]>;
};

/** A connection to a list of `NzrSentimentForm` values, with data from `NzrSentimentFormAssignee`. */
export type LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection = {
  __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrSentimentFormAggregates>;
  /** A list of edges which contains the `NzrSentimentForm`, info from the `NzrSentimentFormAssignee`, and the cursor to aid in pagination. */
  edges: Array<LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrSentimentFormAggregates>>;
  /** A list of `NzrSentimentForm` objects. */
  nodes: Array<NzrSentimentForm>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrSentimentForm` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrSentimentForm` values, with data from `NzrSentimentFormAssignee`. */
export type LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<NzrSentimentFormGroupBy>;
    having?: InputMaybe<NzrSentimentFormHavingInput>;
  };

/** A `NzrSentimentForm` edge in the connection, with data from `NzrSentimentFormAssignee`. */
export type LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyEdge = {
  __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrSentimentForm` at the end of the edge. */
  node: NzrSentimentForm;
  /** Reads and enables pagination through a set of `NzrSentimentFormAssignee`. */
  nzrSentimentFormAssignees: NzrSentimentFormAssigneesConnection;
};

/** A `NzrSentimentForm` edge in the connection, with data from `NzrSentimentFormAssignee`. */
export type LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyEdgeNzrSentimentFormAssigneesArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormAssigneeCondition>;
    filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormAssigneesOrderBy>>;
  };

export type LgMemberSetting = Node & {
  __typename?: "LgMemberSetting";
  feedbackEmailTemplate?: Maybe<Scalars["JSON"]["output"]>;
  filters?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `LgMember` that is related to this `LgMemberSetting`. */
  user?: Maybe<LgMember>;
  userId: Scalars["UUID"]["output"];
};

export type LgMemberSettingAggregates = {
  __typename?: "LgMemberSettingAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgMemberSettingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgMemberSetting` object types. */
export type LgMemberSettingAggregatesFilter = {
  /** Distinct count aggregate over matching `LgMemberSetting` objects. */
  distinctCount?: InputMaybe<LgMemberSettingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgMemberSetting` object to be included within the aggregate. */
  filter?: InputMaybe<LgMemberSettingFilter>;
};

/**
 * A condition to be used against `LgMemberSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgMemberSettingCondition = {
  /** Checks for equality with the object’s `feedbackEmailTemplate` field. */
  feedbackEmailTemplate?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `filters` field. */
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type LgMemberSettingDistinctCountAggregateFilter = {
  feedbackEmailTemplate?: InputMaybe<BigIntFilter>;
  filters?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type LgMemberSettingDistinctCountAggregates = {
  __typename?: "LgMemberSettingDistinctCountAggregates";
  /** Distinct count of feedbackEmailTemplate across the matching connection */
  feedbackEmailTemplate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of filters across the matching connection */
  filters?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgMemberSetting` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgMemberSettingFilter>>;
  /** Filter by the object’s `feedbackEmailTemplate` field. */
  feedbackEmailTemplate?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `filters` field. */
  filters?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LgMemberSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgMemberSettingFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `LgMemberSetting` values. */
export type LgMemberSettingsConnection = {
  __typename?: "LgMemberSettingsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberSettingAggregates>;
  /** A list of edges which contains the `LgMemberSetting` and cursor to aid in pagination. */
  edges: Array<LgMemberSettingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberSettingAggregates>>;
  /** A list of `LgMemberSetting` objects. */
  nodes: Array<LgMemberSetting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMemberSetting` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMemberSetting` values. */
export type LgMemberSettingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberSettingsGroupBy>;
  having?: InputMaybe<LgMemberSettingsHavingInput>;
};

/** A `LgMemberSetting` edge in the connection. */
export type LgMemberSettingsEdge = {
  __typename?: "LgMemberSettingsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMemberSetting` at the end of the edge. */
  node: LgMemberSetting;
};

/** Grouping methods for `LgMemberSetting` for usage during aggregation. */
export enum LgMemberSettingsGroupBy {
  FeedbackEmailTemplate = "FEEDBACK_EMAIL_TEMPLATE",
  Filters = "FILTERS",
  UserId = "USER_ID",
}

/** Conditions for `LgMemberSetting` aggregates. */
export type LgMemberSettingsHavingInput = {
  AND?: InputMaybe<Array<LgMemberSettingsHavingInput>>;
  OR?: InputMaybe<Array<LgMemberSettingsHavingInput>>;
};

/** Methods to use when ordering `LgMemberSetting`. */
export enum LgMemberSettingsOrderBy {
  FeedbackEmailTemplateAsc = "FEEDBACK_EMAIL_TEMPLATE_ASC",
  FeedbackEmailTemplateDesc = "FEEDBACK_EMAIL_TEMPLATE_DESC",
  FiltersAsc = "FILTERS_ASC",
  FiltersDesc = "FILTERS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** A connection to a list of `Signal` values, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyConnection = {
  __typename?: "LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SignalAggregates>;
  /** A list of edges which contains the `Signal`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SignalAggregates>>;
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Signal` values, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SignalGroupBy>;
  having?: InputMaybe<SignalHavingInput>;
};

/** A `Signal` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyEdge = {
  __typename?: "LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunities: NzrOpportunitiesConnection;
};

/** A `Signal` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunityAssigneeAndSignalIdManyToManyEdgeNzrOpportunitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

/** A connection to a list of `Signal` values, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyConnection = {
  __typename?: "LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SignalAggregates>;
  /** A list of edges which contains the `Signal`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SignalAggregates>>;
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Signal` values, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SignalGroupBy>;
  having?: InputMaybe<SignalHavingInput>;
};

/** A `Signal` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyEdge = {
  __typename?: "LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunities: NzrOpportunitiesConnection;
};

/** A `Signal` edge in the connection, with data from `NzrOpportunity`. */
export type LgMemberSignalsByNzrOpportunitySharedFromAndSignalIdManyToManyEdgeNzrOpportunitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type LgMemberStddevPopulationAggregateFilter = {
  userId?: InputMaybe<BigFloatFilter>;
};

export type LgMemberStddevPopulationAggregates = {
  __typename?: "LgMemberStddevPopulationAggregates";
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgMemberStddevSampleAggregateFilter = {
  userId?: InputMaybe<BigFloatFilter>;
};

export type LgMemberStddevSampleAggregates = {
  __typename?: "LgMemberStddevSampleAggregates";
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgMemberSumAggregateFilter = {
  userId?: InputMaybe<BigIntFilter>;
};

export type LgMemberSumAggregates = {
  __typename?: "LgMemberSumAggregates";
  /** Sum of userId across the matching connection */
  userId: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `LgCompanyComment` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgCompanyCommentFilter = {
  /** Aggregates across related `LgCompanyComment` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyCommentAggregatesFilter>;
  /** Every related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyCommentFilter>;
  /** No related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyCommentFilter>;
  /** Some related `LgCompanyComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyCommentFilter>;
};

/** A filter to be used against many `LgCompanyFlag` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgCompanyFlagFilter = {
  /** Aggregates across related `LgCompanyFlag` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyFlagAggregatesFilter>;
  /** Every related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyFlagFilter>;
  /** No related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyFlagFilter>;
  /** Some related `LgCompanyFlag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyFlagFilter>;
};

/** A filter to be used against many `LgCompanyNote` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgCompanyNoteFilter = {
  /** Aggregates across related `LgCompanyNote` match the filter criteria. */
  aggregates?: InputMaybe<LgCompanyNoteAggregatesFilter>;
  /** Every related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgCompanyNoteFilter>;
  /** No related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgCompanyNoteFilter>;
  /** Some related `LgCompanyNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgCompanyNoteFilter>;
};

/** A filter to be used against many `LgFile` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgFileFilter = {
  /** Aggregates across related `LgFile` match the filter criteria. */
  aggregates?: InputMaybe<LgFileAggregatesFilter>;
  /** Every related `LgFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgFileFilter>;
  /** No related `LgFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgFileFilter>;
  /** Some related `LgFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgFileFilter>;
};

/** A filter to be used against many `LgInvestor` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgInvestorFilter = {
  /** Aggregates across related `LgInvestor` match the filter criteria. */
  aggregates?: InputMaybe<LgInvestorAggregatesFilter>;
  /** Every related `LgInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgInvestorFilter>;
  /** No related `LgInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgInvestorFilter>;
  /** Some related `LgInvestor` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgInvestorFilter>;
};

/** A filter to be used against many `LgMemberSetting` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgMemberSettingFilter = {
  /** Aggregates across related `LgMemberSetting` match the filter criteria. */
  aggregates?: InputMaybe<LgMemberSettingAggregatesFilter>;
  /** Every related `LgMemberSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgMemberSettingFilter>;
  /** No related `LgMemberSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgMemberSettingFilter>;
  /** Some related `LgMemberSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgMemberSettingFilter>;
};

/** A filter to be used against many `LgUserFeedback` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyLgUserFeedbackFilter = {
  /** Aggregates across related `LgUserFeedback` match the filter criteria. */
  aggregates?: InputMaybe<LgUserFeedbackAggregatesFilter>;
  /** Every related `LgUserFeedback` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgUserFeedbackFilter>;
  /** No related `LgUserFeedback` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgUserFeedbackFilter>;
  /** Some related `LgUserFeedback` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgUserFeedbackFilter>;
};

/** A filter to be used against many `MnEntry` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyMnEntryFilter = {
  /** Aggregates across related `MnEntry` match the filter criteria. */
  aggregates?: InputMaybe<MnEntryAggregatesFilter>;
  /** Every related `MnEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MnEntryFilter>;
  /** No related `MnEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MnEntryFilter>;
  /** Some related `MnEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MnEntryFilter>;
};

/** A filter to be used against many `NzrNotification` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrNotificationFilter = {
  /** Aggregates across related `NzrNotification` match the filter criteria. */
  aggregates?: InputMaybe<NzrNotificationAggregatesFilter>;
  /** Every related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrNotificationFilter>;
  /** No related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrNotificationFilter>;
  /** Some related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrNotificationFilter>;
};

/** A filter to be used against many `NzrOpportunityEmail` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrOpportunityEmailFilter = {
  /** Aggregates across related `NzrOpportunityEmail` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityEmailAggregatesFilter>;
  /** Every related `NzrOpportunityEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityEmailFilter>;
  /** No related `NzrOpportunityEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityEmailFilter>;
  /** Some related `NzrOpportunityEmail` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityEmailFilter>;
};

/** A filter to be used against many `NzrOpportunity` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrOpportunityFilter = {
  /** Aggregates across related `NzrOpportunity` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityAggregatesFilter>;
  /** Every related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityFilter>;
  /** No related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityFilter>;
  /** Some related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityFilter>;
};

/** A filter to be used against many `NzrSentimentFormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrSentimentFormAssigneeFilter = {
  /** Aggregates across related `NzrSentimentFormAssignee` match the filter criteria. */
  aggregates?: InputMaybe<NzrSentimentFormAssigneeAggregatesFilter>;
  /** Every related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  /** No related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  /** Some related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrSentimentFormAssigneeFilter>;
};

/** A filter to be used against many `NzrSentimentForm` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrSentimentFormFilter = {
  /** Aggregates across related `NzrSentimentForm` match the filter criteria. */
  aggregates?: InputMaybe<NzrSentimentFormAggregatesFilter>;
  /** Every related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrSentimentFormFilter>;
  /** No related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrSentimentFormFilter>;
  /** Some related `NzrSentimentForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrSentimentFormFilter>;
};

/** A filter to be used against many `NzrUserCompanyTag` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyNzrUserCompanyTagFilter = {
  /** Aggregates across related `NzrUserCompanyTag` match the filter criteria. */
  aggregates?: InputMaybe<NzrUserCompanyTagAggregatesFilter>;
  /** Every related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrUserCompanyTagFilter>;
  /** No related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrUserCompanyTagFilter>;
  /** Some related `NzrUserCompanyTag` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrUserCompanyTagFilter>;
};

/** A filter to be used against many `UserReport` object types. All fields are combined with a logical ‘and.’ */
export type LgMemberToManyUserReportFilter = {
  /** Aggregates across related `UserReport` match the filter criteria. */
  aggregates?: InputMaybe<UserReportAggregatesFilter>;
  /** Every related `UserReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserReportFilter>;
  /** No related `UserReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserReportFilter>;
  /** Some related `UserReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserReportFilter>;
};

export type LgMemberVariancePopulationAggregateFilter = {
  userId?: InputMaybe<BigFloatFilter>;
};

export type LgMemberVariancePopulationAggregates = {
  __typename?: "LgMemberVariancePopulationAggregates";
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type LgMemberVarianceSampleAggregateFilter = {
  userId?: InputMaybe<BigFloatFilter>;
};

export type LgMemberVarianceSampleAggregates = {
  __typename?: "LgMemberVarianceSampleAggregates";
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `LgMember` values. */
export type LgMembersConnection = {
  __typename?: "LgMembersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember` and cursor to aid in pagination. */
  edges: Array<LgMembersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values. */
export type LgMembersConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection. */
export type LgMembersEdge = {
  __typename?: "LgMembersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
};

/** Methods to use when ordering `LgMember`. */
export enum LgMembersOrderBy {
  AvatarAsc = "AVATAR_ASC",
  AvatarDesc = "AVATAR_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InitialsAsc = "INITIALS_ASC",
  InitialsDesc = "INITIALS_DESC",
  IsInvestorAsc = "IS_INVESTOR_ASC",
  IsInvestorDesc = "IS_INVESTOR_DESC",
  LastAgendaUtcAsc = "LAST_AGENDA_UTC_ASC",
  LastAgendaUtcDesc = "LAST_AGENDA_UTC_DESC",
  LastModifiedUtcAsc = "LAST_MODIFIED_UTC_ASC",
  LastModifiedUtcDesc = "LAST_MODIFIED_UTC_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  LgCompanyCommentsByCreatedByAverageCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByAverageCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByAverageCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByAverageCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByAverageCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByAverageCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByAverageIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_ID_ASC",
  LgCompanyCommentsByCreatedByAverageIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_ID_DESC",
  LgCompanyCommentsByCreatedByAverageLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByAverageLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByAverageMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_METADATA_ASC",
  LgCompanyCommentsByCreatedByAverageMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_METADATA_DESC",
  LgCompanyCommentsByCreatedByAverageTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_TEXT_ASC",
  LgCompanyCommentsByCreatedByAverageTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_AVERAGE_TEXT_DESC",
  LgCompanyCommentsByCreatedByCountAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_COUNT_ASC",
  LgCompanyCommentsByCreatedByCountDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_COUNT_DESC",
  LgCompanyCommentsByCreatedByDistinctCountCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByDistinctCountCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByDistinctCountCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByDistinctCountCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByDistinctCountCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByDistinctCountCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByDistinctCountIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_ID_ASC",
  LgCompanyCommentsByCreatedByDistinctCountIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_ID_DESC",
  LgCompanyCommentsByCreatedByDistinctCountLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByDistinctCountLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByDistinctCountMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_METADATA_ASC",
  LgCompanyCommentsByCreatedByDistinctCountMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_METADATA_DESC",
  LgCompanyCommentsByCreatedByDistinctCountTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_TEXT_ASC",
  LgCompanyCommentsByCreatedByDistinctCountTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_DISTINCT_COUNT_TEXT_DESC",
  LgCompanyCommentsByCreatedByMaxCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByMaxCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByMaxCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByMaxCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByMaxCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByMaxCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByMaxIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_ID_ASC",
  LgCompanyCommentsByCreatedByMaxIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_ID_DESC",
  LgCompanyCommentsByCreatedByMaxLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByMaxLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByMaxMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_METADATA_ASC",
  LgCompanyCommentsByCreatedByMaxMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_METADATA_DESC",
  LgCompanyCommentsByCreatedByMaxTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_TEXT_ASC",
  LgCompanyCommentsByCreatedByMaxTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MAX_TEXT_DESC",
  LgCompanyCommentsByCreatedByMinCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByMinCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByMinCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByMinCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByMinCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByMinCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByMinIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_ID_ASC",
  LgCompanyCommentsByCreatedByMinIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_ID_DESC",
  LgCompanyCommentsByCreatedByMinLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByMinLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByMinMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_METADATA_ASC",
  LgCompanyCommentsByCreatedByMinMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_METADATA_DESC",
  LgCompanyCommentsByCreatedByMinTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_TEXT_ASC",
  LgCompanyCommentsByCreatedByMinTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_MIN_TEXT_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_ID_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_ID_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_METADATA_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_METADATA_DESC",
  LgCompanyCommentsByCreatedByStddevPopulationTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_TEXT_ASC",
  LgCompanyCommentsByCreatedByStddevPopulationTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_POPULATION_TEXT_DESC",
  LgCompanyCommentsByCreatedByStddevSampleCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByStddevSampleCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByStddevSampleCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByStddevSampleCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByStddevSampleCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByStddevSampleCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByStddevSampleIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_ID_ASC",
  LgCompanyCommentsByCreatedByStddevSampleIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_ID_DESC",
  LgCompanyCommentsByCreatedByStddevSampleLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByStddevSampleLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByStddevSampleMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_METADATA_ASC",
  LgCompanyCommentsByCreatedByStddevSampleMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_METADATA_DESC",
  LgCompanyCommentsByCreatedByStddevSampleTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_TEXT_ASC",
  LgCompanyCommentsByCreatedByStddevSampleTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_STDDEV_SAMPLE_TEXT_DESC",
  LgCompanyCommentsByCreatedBySumCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedBySumCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedBySumCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedBySumCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedBySumCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedBySumCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedBySumIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_ID_ASC",
  LgCompanyCommentsByCreatedBySumIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_ID_DESC",
  LgCompanyCommentsByCreatedBySumLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedBySumLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedBySumMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_METADATA_ASC",
  LgCompanyCommentsByCreatedBySumMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_METADATA_DESC",
  LgCompanyCommentsByCreatedBySumTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_TEXT_ASC",
  LgCompanyCommentsByCreatedBySumTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_SUM_TEXT_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_ID_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_ID_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_METADATA_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_METADATA_DESC",
  LgCompanyCommentsByCreatedByVariancePopulationTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_TEXT_ASC",
  LgCompanyCommentsByCreatedByVariancePopulationTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_POPULATION_TEXT_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleCompanyIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleCompanyIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleCreatedByAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleCreatedByDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleCreatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleCreatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleIdAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleIdDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleLastUpdatedUtcAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleLastUpdatedUtcDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleMetadataAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_METADATA_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleMetadataDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_METADATA_DESC",
  LgCompanyCommentsByCreatedByVarianceSampleTextAsc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_TEXT_ASC",
  LgCompanyCommentsByCreatedByVarianceSampleTextDesc = "LG_COMPANY_COMMENTS_BY_CREATED_BY_VARIANCE_SAMPLE_TEXT_DESC",
  LgCompanyFlagsByCreatedByAverageCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByAverageCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByAverageCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByAverageCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByAverageCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByAverageCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByAverageDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByAverageDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByAverageFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_FLAG_ASC",
  LgCompanyFlagsByCreatedByAverageFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_FLAG_DESC",
  LgCompanyFlagsByCreatedByAverageIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_ID_ASC",
  LgCompanyFlagsByCreatedByAverageIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_ID_DESC",
  LgCompanyFlagsByCreatedByAverageUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByAverageUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_AVERAGE_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByCountAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_COUNT_ASC",
  LgCompanyFlagsByCreatedByCountDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_COUNT_DESC",
  LgCompanyFlagsByCreatedByDistinctCountCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByDistinctCountCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByDistinctCountCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByDistinctCountCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByDistinctCountCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByDistinctCountCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByDistinctCountDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByDistinctCountDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByDistinctCountFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_FLAG_ASC",
  LgCompanyFlagsByCreatedByDistinctCountFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_FLAG_DESC",
  LgCompanyFlagsByCreatedByDistinctCountIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_ID_ASC",
  LgCompanyFlagsByCreatedByDistinctCountIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_ID_DESC",
  LgCompanyFlagsByCreatedByDistinctCountUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByDistinctCountUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_DISTINCT_COUNT_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByMaxCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByMaxCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByMaxCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByMaxCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByMaxCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByMaxCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByMaxDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByMaxDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByMaxFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_FLAG_ASC",
  LgCompanyFlagsByCreatedByMaxFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_FLAG_DESC",
  LgCompanyFlagsByCreatedByMaxIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_ID_ASC",
  LgCompanyFlagsByCreatedByMaxIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_ID_DESC",
  LgCompanyFlagsByCreatedByMaxUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByMaxUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MAX_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByMinCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByMinCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByMinCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByMinCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByMinCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByMinCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByMinDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByMinDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByMinFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_FLAG_ASC",
  LgCompanyFlagsByCreatedByMinFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_FLAG_DESC",
  LgCompanyFlagsByCreatedByMinIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_ID_ASC",
  LgCompanyFlagsByCreatedByMinIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_ID_DESC",
  LgCompanyFlagsByCreatedByMinUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByMinUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_MIN_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_FLAG_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_FLAG_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_ID_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_ID_DESC",
  LgCompanyFlagsByCreatedByStddevPopulationUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevPopulationUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_POPULATION_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevSampleCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByStddevSampleCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByStddevSampleCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevSampleCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevSampleCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByStddevSampleCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByStddevSampleDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevSampleDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByStddevSampleFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_FLAG_ASC",
  LgCompanyFlagsByCreatedByStddevSampleFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_FLAG_DESC",
  LgCompanyFlagsByCreatedByStddevSampleIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_ID_ASC",
  LgCompanyFlagsByCreatedByStddevSampleIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_ID_DESC",
  LgCompanyFlagsByCreatedByStddevSampleUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByStddevSampleUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_STDDEV_SAMPLE_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedBySumCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedBySumCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedBySumCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedBySumCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedBySumCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedBySumCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedBySumDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedBySumDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedBySumFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_FLAG_ASC",
  LgCompanyFlagsByCreatedBySumFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_FLAG_DESC",
  LgCompanyFlagsByCreatedBySumIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_ID_ASC",
  LgCompanyFlagsByCreatedBySumIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_ID_DESC",
  LgCompanyFlagsByCreatedBySumUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedBySumUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_SUM_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_FLAG_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_FLAG_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_ID_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_ID_DESC",
  LgCompanyFlagsByCreatedByVariancePopulationUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByVariancePopulationUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_POPULATION_UPDATED_AT_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleCompanyIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleCompanyIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleCreatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_AT_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleCreatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_AT_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleCreatedByAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleCreatedByDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleDeletedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_DELETED_AT_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleDeletedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_DELETED_AT_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleFlagAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_FLAG_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleFlagDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_FLAG_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleIdAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleIdDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyFlagsByCreatedByVarianceSampleUpdatedAtAsc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  LgCompanyFlagsByCreatedByVarianceSampleUpdatedAtDesc = "LG_COMPANY_FLAGS_BY_CREATED_BY_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  LgCompanyNotesByCreatedByAverageCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByAverageCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByAverageCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByAverageCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByAverageCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByAverageCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByAverageDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_DATA_ASC",
  LgCompanyNotesByCreatedByAverageDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_DATA_DESC",
  LgCompanyNotesByCreatedByAverageIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_ID_ASC",
  LgCompanyNotesByCreatedByAverageIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_ID_DESC",
  LgCompanyNotesByCreatedByAverageLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByAverageLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByAverageTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_TYPE_ASC",
  LgCompanyNotesByCreatedByAverageTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_AVERAGE_TYPE_DESC",
  LgCompanyNotesByCreatedByCountAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_COUNT_ASC",
  LgCompanyNotesByCreatedByCountDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_COUNT_DESC",
  LgCompanyNotesByCreatedByDistinctCountCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByDistinctCountCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByDistinctCountCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByDistinctCountCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByDistinctCountCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByDistinctCountCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByDistinctCountDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_DATA_ASC",
  LgCompanyNotesByCreatedByDistinctCountDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_DATA_DESC",
  LgCompanyNotesByCreatedByDistinctCountIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_ID_ASC",
  LgCompanyNotesByCreatedByDistinctCountIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_ID_DESC",
  LgCompanyNotesByCreatedByDistinctCountLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByDistinctCountLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByDistinctCountTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_TYPE_ASC",
  LgCompanyNotesByCreatedByDistinctCountTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_DISTINCT_COUNT_TYPE_DESC",
  LgCompanyNotesByCreatedByMaxCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByMaxCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByMaxCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByMaxCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByMaxCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByMaxCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByMaxDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_DATA_ASC",
  LgCompanyNotesByCreatedByMaxDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_DATA_DESC",
  LgCompanyNotesByCreatedByMaxIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_ID_ASC",
  LgCompanyNotesByCreatedByMaxIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_ID_DESC",
  LgCompanyNotesByCreatedByMaxLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByMaxLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByMaxTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_TYPE_ASC",
  LgCompanyNotesByCreatedByMaxTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MAX_TYPE_DESC",
  LgCompanyNotesByCreatedByMinCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByMinCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByMinCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByMinCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByMinCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByMinCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByMinDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_DATA_ASC",
  LgCompanyNotesByCreatedByMinDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_DATA_DESC",
  LgCompanyNotesByCreatedByMinIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_ID_ASC",
  LgCompanyNotesByCreatedByMinIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_ID_DESC",
  LgCompanyNotesByCreatedByMinLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByMinLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByMinTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_TYPE_ASC",
  LgCompanyNotesByCreatedByMinTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_MIN_TYPE_DESC",
  LgCompanyNotesByCreatedByStddevPopulationCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByStddevPopulationCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByStddevPopulationCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByStddevPopulationCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByStddevPopulationCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByStddevPopulationCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByStddevPopulationDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_DATA_ASC",
  LgCompanyNotesByCreatedByStddevPopulationDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_DATA_DESC",
  LgCompanyNotesByCreatedByStddevPopulationIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_ID_ASC",
  LgCompanyNotesByCreatedByStddevPopulationIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_ID_DESC",
  LgCompanyNotesByCreatedByStddevPopulationLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByStddevPopulationLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByStddevPopulationTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_TYPE_ASC",
  LgCompanyNotesByCreatedByStddevPopulationTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_POPULATION_TYPE_DESC",
  LgCompanyNotesByCreatedByStddevSampleCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByStddevSampleCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByStddevSampleCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByStddevSampleCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByStddevSampleCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByStddevSampleCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByStddevSampleDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_DATA_ASC",
  LgCompanyNotesByCreatedByStddevSampleDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_DATA_DESC",
  LgCompanyNotesByCreatedByStddevSampleIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_ID_ASC",
  LgCompanyNotesByCreatedByStddevSampleIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_ID_DESC",
  LgCompanyNotesByCreatedByStddevSampleLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByStddevSampleLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByStddevSampleTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_TYPE_ASC",
  LgCompanyNotesByCreatedByStddevSampleTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_STDDEV_SAMPLE_TYPE_DESC",
  LgCompanyNotesByCreatedBySumCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedBySumCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedBySumCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_CREATED_BY_ASC",
  LgCompanyNotesByCreatedBySumCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_CREATED_BY_DESC",
  LgCompanyNotesByCreatedBySumCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedBySumCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedBySumDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_DATA_ASC",
  LgCompanyNotesByCreatedBySumDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_DATA_DESC",
  LgCompanyNotesByCreatedBySumIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_ID_ASC",
  LgCompanyNotesByCreatedBySumIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_ID_DESC",
  LgCompanyNotesByCreatedBySumLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedBySumLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedBySumTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_TYPE_ASC",
  LgCompanyNotesByCreatedBySumTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_SUM_TYPE_DESC",
  LgCompanyNotesByCreatedByVariancePopulationCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByVariancePopulationCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByVariancePopulationCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByVariancePopulationCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByVariancePopulationCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByVariancePopulationCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByVariancePopulationDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_DATA_ASC",
  LgCompanyNotesByCreatedByVariancePopulationDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_DATA_DESC",
  LgCompanyNotesByCreatedByVariancePopulationIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_ID_ASC",
  LgCompanyNotesByCreatedByVariancePopulationIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_ID_DESC",
  LgCompanyNotesByCreatedByVariancePopulationLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByVariancePopulationLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByVariancePopulationTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_TYPE_ASC",
  LgCompanyNotesByCreatedByVariancePopulationTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_POPULATION_TYPE_DESC",
  LgCompanyNotesByCreatedByVarianceSampleCompanyIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  LgCompanyNotesByCreatedByVarianceSampleCompanyIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  LgCompanyNotesByCreatedByVarianceSampleCreatedByAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgCompanyNotesByCreatedByVarianceSampleCreatedByDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgCompanyNotesByCreatedByVarianceSampleCreatedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgCompanyNotesByCreatedByVarianceSampleCreatedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgCompanyNotesByCreatedByVarianceSampleDataAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_DATA_ASC",
  LgCompanyNotesByCreatedByVarianceSampleDataDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_DATA_DESC",
  LgCompanyNotesByCreatedByVarianceSampleIdAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_ID_ASC",
  LgCompanyNotesByCreatedByVarianceSampleIdDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_ID_DESC",
  LgCompanyNotesByCreatedByVarianceSampleLastModifiedUtcAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgCompanyNotesByCreatedByVarianceSampleLastModifiedUtcDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgCompanyNotesByCreatedByVarianceSampleTypeAsc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_TYPE_ASC",
  LgCompanyNotesByCreatedByVarianceSampleTypeDesc = "LG_COMPANY_NOTES_BY_CREATED_BY_VARIANCE_SAMPLE_TYPE_DESC",
  LgFilesByCreatedByAverageCreatedByAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_CREATED_BY_ASC",
  LgFilesByCreatedByAverageCreatedByDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_CREATED_BY_DESC",
  LgFilesByCreatedByAverageCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_CREATED_UTC_ASC",
  LgFilesByCreatedByAverageCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_CREATED_UTC_DESC",
  LgFilesByCreatedByAverageIdAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_ID_ASC",
  LgFilesByCreatedByAverageIdDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_ID_DESC",
  LgFilesByCreatedByAverageMetadataAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_METADATA_ASC",
  LgFilesByCreatedByAverageMetadataDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_METADATA_DESC",
  LgFilesByCreatedByAverageNameAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_NAME_ASC",
  LgFilesByCreatedByAverageNameDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_NAME_DESC",
  LgFilesByCreatedByAveragePathAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_PATH_ASC",
  LgFilesByCreatedByAveragePathDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_PATH_DESC",
  LgFilesByCreatedByAverageSourceAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_SOURCE_ASC",
  LgFilesByCreatedByAverageSourceDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_SOURCE_DESC",
  LgFilesByCreatedByAverageStatusAsc = "LG_FILES_BY_CREATED_BY_AVERAGE_STATUS_ASC",
  LgFilesByCreatedByAverageStatusDesc = "LG_FILES_BY_CREATED_BY_AVERAGE_STATUS_DESC",
  LgFilesByCreatedByCountAsc = "LG_FILES_BY_CREATED_BY_COUNT_ASC",
  LgFilesByCreatedByCountDesc = "LG_FILES_BY_CREATED_BY_COUNT_DESC",
  LgFilesByCreatedByDistinctCountCreatedByAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_ASC",
  LgFilesByCreatedByDistinctCountCreatedByDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_DESC",
  LgFilesByCreatedByDistinctCountCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgFilesByCreatedByDistinctCountCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgFilesByCreatedByDistinctCountIdAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_ID_ASC",
  LgFilesByCreatedByDistinctCountIdDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_ID_DESC",
  LgFilesByCreatedByDistinctCountMetadataAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_METADATA_ASC",
  LgFilesByCreatedByDistinctCountMetadataDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_METADATA_DESC",
  LgFilesByCreatedByDistinctCountNameAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_NAME_ASC",
  LgFilesByCreatedByDistinctCountNameDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_NAME_DESC",
  LgFilesByCreatedByDistinctCountPathAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_PATH_ASC",
  LgFilesByCreatedByDistinctCountPathDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_PATH_DESC",
  LgFilesByCreatedByDistinctCountSourceAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_SOURCE_ASC",
  LgFilesByCreatedByDistinctCountSourceDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_SOURCE_DESC",
  LgFilesByCreatedByDistinctCountStatusAsc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_STATUS_ASC",
  LgFilesByCreatedByDistinctCountStatusDesc = "LG_FILES_BY_CREATED_BY_DISTINCT_COUNT_STATUS_DESC",
  LgFilesByCreatedByMaxCreatedByAsc = "LG_FILES_BY_CREATED_BY_MAX_CREATED_BY_ASC",
  LgFilesByCreatedByMaxCreatedByDesc = "LG_FILES_BY_CREATED_BY_MAX_CREATED_BY_DESC",
  LgFilesByCreatedByMaxCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_MAX_CREATED_UTC_ASC",
  LgFilesByCreatedByMaxCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_MAX_CREATED_UTC_DESC",
  LgFilesByCreatedByMaxIdAsc = "LG_FILES_BY_CREATED_BY_MAX_ID_ASC",
  LgFilesByCreatedByMaxIdDesc = "LG_FILES_BY_CREATED_BY_MAX_ID_DESC",
  LgFilesByCreatedByMaxMetadataAsc = "LG_FILES_BY_CREATED_BY_MAX_METADATA_ASC",
  LgFilesByCreatedByMaxMetadataDesc = "LG_FILES_BY_CREATED_BY_MAX_METADATA_DESC",
  LgFilesByCreatedByMaxNameAsc = "LG_FILES_BY_CREATED_BY_MAX_NAME_ASC",
  LgFilesByCreatedByMaxNameDesc = "LG_FILES_BY_CREATED_BY_MAX_NAME_DESC",
  LgFilesByCreatedByMaxPathAsc = "LG_FILES_BY_CREATED_BY_MAX_PATH_ASC",
  LgFilesByCreatedByMaxPathDesc = "LG_FILES_BY_CREATED_BY_MAX_PATH_DESC",
  LgFilesByCreatedByMaxSourceAsc = "LG_FILES_BY_CREATED_BY_MAX_SOURCE_ASC",
  LgFilesByCreatedByMaxSourceDesc = "LG_FILES_BY_CREATED_BY_MAX_SOURCE_DESC",
  LgFilesByCreatedByMaxStatusAsc = "LG_FILES_BY_CREATED_BY_MAX_STATUS_ASC",
  LgFilesByCreatedByMaxStatusDesc = "LG_FILES_BY_CREATED_BY_MAX_STATUS_DESC",
  LgFilesByCreatedByMinCreatedByAsc = "LG_FILES_BY_CREATED_BY_MIN_CREATED_BY_ASC",
  LgFilesByCreatedByMinCreatedByDesc = "LG_FILES_BY_CREATED_BY_MIN_CREATED_BY_DESC",
  LgFilesByCreatedByMinCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_MIN_CREATED_UTC_ASC",
  LgFilesByCreatedByMinCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_MIN_CREATED_UTC_DESC",
  LgFilesByCreatedByMinIdAsc = "LG_FILES_BY_CREATED_BY_MIN_ID_ASC",
  LgFilesByCreatedByMinIdDesc = "LG_FILES_BY_CREATED_BY_MIN_ID_DESC",
  LgFilesByCreatedByMinMetadataAsc = "LG_FILES_BY_CREATED_BY_MIN_METADATA_ASC",
  LgFilesByCreatedByMinMetadataDesc = "LG_FILES_BY_CREATED_BY_MIN_METADATA_DESC",
  LgFilesByCreatedByMinNameAsc = "LG_FILES_BY_CREATED_BY_MIN_NAME_ASC",
  LgFilesByCreatedByMinNameDesc = "LG_FILES_BY_CREATED_BY_MIN_NAME_DESC",
  LgFilesByCreatedByMinPathAsc = "LG_FILES_BY_CREATED_BY_MIN_PATH_ASC",
  LgFilesByCreatedByMinPathDesc = "LG_FILES_BY_CREATED_BY_MIN_PATH_DESC",
  LgFilesByCreatedByMinSourceAsc = "LG_FILES_BY_CREATED_BY_MIN_SOURCE_ASC",
  LgFilesByCreatedByMinSourceDesc = "LG_FILES_BY_CREATED_BY_MIN_SOURCE_DESC",
  LgFilesByCreatedByMinStatusAsc = "LG_FILES_BY_CREATED_BY_MIN_STATUS_ASC",
  LgFilesByCreatedByMinStatusDesc = "LG_FILES_BY_CREATED_BY_MIN_STATUS_DESC",
  LgFilesByCreatedByStddevPopulationCreatedByAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_ASC",
  LgFilesByCreatedByStddevPopulationCreatedByDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_DESC",
  LgFilesByCreatedByStddevPopulationCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgFilesByCreatedByStddevPopulationCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgFilesByCreatedByStddevPopulationIdAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_ID_ASC",
  LgFilesByCreatedByStddevPopulationIdDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_ID_DESC",
  LgFilesByCreatedByStddevPopulationMetadataAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_METADATA_ASC",
  LgFilesByCreatedByStddevPopulationMetadataDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_METADATA_DESC",
  LgFilesByCreatedByStddevPopulationNameAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_NAME_ASC",
  LgFilesByCreatedByStddevPopulationNameDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_NAME_DESC",
  LgFilesByCreatedByStddevPopulationPathAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_PATH_ASC",
  LgFilesByCreatedByStddevPopulationPathDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_PATH_DESC",
  LgFilesByCreatedByStddevPopulationSourceAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_SOURCE_ASC",
  LgFilesByCreatedByStddevPopulationSourceDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_SOURCE_DESC",
  LgFilesByCreatedByStddevPopulationStatusAsc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_STATUS_ASC",
  LgFilesByCreatedByStddevPopulationStatusDesc = "LG_FILES_BY_CREATED_BY_STDDEV_POPULATION_STATUS_DESC",
  LgFilesByCreatedByStddevSampleCreatedByAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgFilesByCreatedByStddevSampleCreatedByDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgFilesByCreatedByStddevSampleCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgFilesByCreatedByStddevSampleCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgFilesByCreatedByStddevSampleIdAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_ID_ASC",
  LgFilesByCreatedByStddevSampleIdDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_ID_DESC",
  LgFilesByCreatedByStddevSampleMetadataAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_METADATA_ASC",
  LgFilesByCreatedByStddevSampleMetadataDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_METADATA_DESC",
  LgFilesByCreatedByStddevSampleNameAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_NAME_ASC",
  LgFilesByCreatedByStddevSampleNameDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_NAME_DESC",
  LgFilesByCreatedByStddevSamplePathAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_PATH_ASC",
  LgFilesByCreatedByStddevSamplePathDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_PATH_DESC",
  LgFilesByCreatedByStddevSampleSourceAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_SOURCE_ASC",
  LgFilesByCreatedByStddevSampleSourceDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_SOURCE_DESC",
  LgFilesByCreatedByStddevSampleStatusAsc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_STATUS_ASC",
  LgFilesByCreatedByStddevSampleStatusDesc = "LG_FILES_BY_CREATED_BY_STDDEV_SAMPLE_STATUS_DESC",
  LgFilesByCreatedBySumCreatedByAsc = "LG_FILES_BY_CREATED_BY_SUM_CREATED_BY_ASC",
  LgFilesByCreatedBySumCreatedByDesc = "LG_FILES_BY_CREATED_BY_SUM_CREATED_BY_DESC",
  LgFilesByCreatedBySumCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_SUM_CREATED_UTC_ASC",
  LgFilesByCreatedBySumCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_SUM_CREATED_UTC_DESC",
  LgFilesByCreatedBySumIdAsc = "LG_FILES_BY_CREATED_BY_SUM_ID_ASC",
  LgFilesByCreatedBySumIdDesc = "LG_FILES_BY_CREATED_BY_SUM_ID_DESC",
  LgFilesByCreatedBySumMetadataAsc = "LG_FILES_BY_CREATED_BY_SUM_METADATA_ASC",
  LgFilesByCreatedBySumMetadataDesc = "LG_FILES_BY_CREATED_BY_SUM_METADATA_DESC",
  LgFilesByCreatedBySumNameAsc = "LG_FILES_BY_CREATED_BY_SUM_NAME_ASC",
  LgFilesByCreatedBySumNameDesc = "LG_FILES_BY_CREATED_BY_SUM_NAME_DESC",
  LgFilesByCreatedBySumPathAsc = "LG_FILES_BY_CREATED_BY_SUM_PATH_ASC",
  LgFilesByCreatedBySumPathDesc = "LG_FILES_BY_CREATED_BY_SUM_PATH_DESC",
  LgFilesByCreatedBySumSourceAsc = "LG_FILES_BY_CREATED_BY_SUM_SOURCE_ASC",
  LgFilesByCreatedBySumSourceDesc = "LG_FILES_BY_CREATED_BY_SUM_SOURCE_DESC",
  LgFilesByCreatedBySumStatusAsc = "LG_FILES_BY_CREATED_BY_SUM_STATUS_ASC",
  LgFilesByCreatedBySumStatusDesc = "LG_FILES_BY_CREATED_BY_SUM_STATUS_DESC",
  LgFilesByCreatedByVariancePopulationCreatedByAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgFilesByCreatedByVariancePopulationCreatedByDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgFilesByCreatedByVariancePopulationCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgFilesByCreatedByVariancePopulationCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgFilesByCreatedByVariancePopulationIdAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_ID_ASC",
  LgFilesByCreatedByVariancePopulationIdDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_ID_DESC",
  LgFilesByCreatedByVariancePopulationMetadataAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_METADATA_ASC",
  LgFilesByCreatedByVariancePopulationMetadataDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_METADATA_DESC",
  LgFilesByCreatedByVariancePopulationNameAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_NAME_ASC",
  LgFilesByCreatedByVariancePopulationNameDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_NAME_DESC",
  LgFilesByCreatedByVariancePopulationPathAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_PATH_ASC",
  LgFilesByCreatedByVariancePopulationPathDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_PATH_DESC",
  LgFilesByCreatedByVariancePopulationSourceAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_SOURCE_ASC",
  LgFilesByCreatedByVariancePopulationSourceDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_SOURCE_DESC",
  LgFilesByCreatedByVariancePopulationStatusAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_STATUS_ASC",
  LgFilesByCreatedByVariancePopulationStatusDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_POPULATION_STATUS_DESC",
  LgFilesByCreatedByVarianceSampleCreatedByAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgFilesByCreatedByVarianceSampleCreatedByDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgFilesByCreatedByVarianceSampleCreatedUtcAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgFilesByCreatedByVarianceSampleCreatedUtcDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgFilesByCreatedByVarianceSampleIdAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_ID_ASC",
  LgFilesByCreatedByVarianceSampleIdDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_ID_DESC",
  LgFilesByCreatedByVarianceSampleMetadataAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_METADATA_ASC",
  LgFilesByCreatedByVarianceSampleMetadataDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_METADATA_DESC",
  LgFilesByCreatedByVarianceSampleNameAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_NAME_ASC",
  LgFilesByCreatedByVarianceSampleNameDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_NAME_DESC",
  LgFilesByCreatedByVarianceSamplePathAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_PATH_ASC",
  LgFilesByCreatedByVarianceSamplePathDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_PATH_DESC",
  LgFilesByCreatedByVarianceSampleSourceAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_SOURCE_ASC",
  LgFilesByCreatedByVarianceSampleSourceDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_SOURCE_DESC",
  LgFilesByCreatedByVarianceSampleStatusAsc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_STATUS_ASC",
  LgFilesByCreatedByVarianceSampleStatusDesc = "LG_FILES_BY_CREATED_BY_VARIANCE_SAMPLE_STATUS_DESC",
  LgInvestorsByRefFundLeadAverageAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ABOUT_ASC",
  LgInvestorsByRefFundLeadAverageAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ABOUT_DESC",
  LgInvestorsByRefFundLeadAverageAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadAverageAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadAverageAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadAverageAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadAverageClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadAverageClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadAverageCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadAverageCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadAverageCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadAverageCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadAverageCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadAverageCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadAverageCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadAverageCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadAverageDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadAverageDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadAverageDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadAverageDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadAverageDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadAverageDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadAverageEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadAverageEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadAverageEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadAverageEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadAverageFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadAverageFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadAverageFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadAverageFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadAverageFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadAverageFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadAverageGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadAverageGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadAverageHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadAverageHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadAverageIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ID_ASC",
  LgInvestorsByRefFundLeadAverageIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_ID_DESC",
  LgInvestorsByRefFundLeadAverageImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IMAGE_ASC",
  LgInvestorsByRefFundLeadAverageImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IMAGE_DESC",
  LgInvestorsByRefFundLeadAverageIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadAverageIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadAverageInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadAverageInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadAverageInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadAverageInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadAverageInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadAverageInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadAverageInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadAverageInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadAverageIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadAverageIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadAverageIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadAverageIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadAverageIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadAverageIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadAverageLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadAverageLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadAverageLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadAverageLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadAverageLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadAverageLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadAverageLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadAverageLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadAverageLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadAverageLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadAverageLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadAverageLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadAverageLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadAverageLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadAverageNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_NAME_ASC",
  LgInvestorsByRefFundLeadAverageNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_NAME_DESC",
  LgInvestorsByRefFundLeadAveragePathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_PATH_ASC",
  LgInvestorsByRefFundLeadAveragePathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_PATH_DESC",
  LgInvestorsByRefFundLeadAveragePlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadAveragePlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadAverageRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadAverageRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadAverageRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadAverageRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadAverageRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadAverageRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadAverageSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadAverageSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadAverageTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TAGLINE_ASC",
  LgInvestorsByRefFundLeadAverageTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TAGLINE_DESC",
  LgInvestorsByRefFundLeadAverageTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TAGS_ASC",
  LgInvestorsByRefFundLeadAverageTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TAGS_DESC",
  LgInvestorsByRefFundLeadAverageTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TEAM_ASC",
  LgInvestorsByRefFundLeadAverageTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TEAM_DESC",
  LgInvestorsByRefFundLeadAverageTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadAverageTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadAverageTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadAverageTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadAverageTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadAverageTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadAverageTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadAverageTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadAverageUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_URL_ASC",
  LgInvestorsByRefFundLeadAverageUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_URL_DESC",
  LgInvestorsByRefFundLeadAverageWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadAverageWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_AVERAGE_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadCountAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_COUNT_ASC",
  LgInvestorsByRefFundLeadCountDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_COUNT_DESC",
  LgInvestorsByRefFundLeadDistinctCountAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ABOUT_ASC",
  LgInvestorsByRefFundLeadDistinctCountAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ABOUT_DESC",
  LgInvestorsByRefFundLeadDistinctCountAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadDistinctCountAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadDistinctCountClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadDistinctCountClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadDistinctCountCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadDistinctCountCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadDistinctCountCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadDistinctCountCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadDistinctCountCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadDistinctCountCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadDistinctCountCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadDistinctCountDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadDistinctCountDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadDistinctCountDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadDistinctCountDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadDistinctCountDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadDistinctCountEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadDistinctCountEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadDistinctCountEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadDistinctCountEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadDistinctCountFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadDistinctCountFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadDistinctCountFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadDistinctCountFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadDistinctCountGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadDistinctCountHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadDistinctCountIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ID_ASC",
  LgInvestorsByRefFundLeadDistinctCountIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_ID_DESC",
  LgInvestorsByRefFundLeadDistinctCountImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IMAGE_ASC",
  LgInvestorsByRefFundLeadDistinctCountImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IMAGE_DESC",
  LgInvestorsByRefFundLeadDistinctCountIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadDistinctCountIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadDistinctCountInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadDistinctCountInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadDistinctCountInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadDistinctCountIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadDistinctCountIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadDistinctCountIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadDistinctCountIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadDistinctCountIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadDistinctCountIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadDistinctCountLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadDistinctCountLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadDistinctCountLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadDistinctCountLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadDistinctCountLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadDistinctCountLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadDistinctCountLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadDistinctCountLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadDistinctCountLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadDistinctCountLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadDistinctCountLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadDistinctCountLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadDistinctCountLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_NAME_ASC",
  LgInvestorsByRefFundLeadDistinctCountNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_NAME_DESC",
  LgInvestorsByRefFundLeadDistinctCountPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_PATH_ASC",
  LgInvestorsByRefFundLeadDistinctCountPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_PATH_DESC",
  LgInvestorsByRefFundLeadDistinctCountPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadDistinctCountPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadDistinctCountRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadDistinctCountRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadDistinctCountRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadDistinctCountRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadDistinctCountRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadDistinctCountRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadDistinctCountSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadDistinctCountSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadDistinctCountTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TAGLINE_ASC",
  LgInvestorsByRefFundLeadDistinctCountTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TAGLINE_DESC",
  LgInvestorsByRefFundLeadDistinctCountTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TAGS_ASC",
  LgInvestorsByRefFundLeadDistinctCountTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TAGS_DESC",
  LgInvestorsByRefFundLeadDistinctCountTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TEAM_ASC",
  LgInvestorsByRefFundLeadDistinctCountTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TEAM_DESC",
  LgInvestorsByRefFundLeadDistinctCountTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadDistinctCountTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadDistinctCountTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadDistinctCountTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadDistinctCountTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadDistinctCountTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadDistinctCountTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_URL_DESC",
  LgInvestorsByRefFundLeadDistinctCountWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadDistinctCountWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_DISTINCT_COUNT_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadMaxAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ABOUT_ASC",
  LgInvestorsByRefFundLeadMaxAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ABOUT_DESC",
  LgInvestorsByRefFundLeadMaxAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadMaxAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadMaxAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadMaxAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadMaxClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadMaxClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadMaxCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadMaxCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadMaxCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadMaxCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadMaxCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadMaxCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadMaxCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadMaxCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadMaxDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadMaxDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadMaxDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadMaxDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadMaxDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMaxDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMaxEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadMaxEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadMaxEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadMaxEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadMaxFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadMaxFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadMaxFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadMaxFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadMaxFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadMaxFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadMaxGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadMaxGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadMaxHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadMaxHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadMaxIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ID_ASC",
  LgInvestorsByRefFundLeadMaxIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_ID_DESC",
  LgInvestorsByRefFundLeadMaxImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IMAGE_ASC",
  LgInvestorsByRefFundLeadMaxImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IMAGE_DESC",
  LgInvestorsByRefFundLeadMaxIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadMaxIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadMaxInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadMaxInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadMaxInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMaxInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMaxInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadMaxInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadMaxInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadMaxInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadMaxIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadMaxIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadMaxIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadMaxIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadMaxIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadMaxIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadMaxLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadMaxLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadMaxLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMaxLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMaxLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadMaxLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadMaxLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadMaxLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadMaxLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadMaxLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadMaxLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadMaxLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadMaxLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadMaxLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadMaxNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_NAME_ASC",
  LgInvestorsByRefFundLeadMaxNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_NAME_DESC",
  LgInvestorsByRefFundLeadMaxPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_PATH_ASC",
  LgInvestorsByRefFundLeadMaxPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_PATH_DESC",
  LgInvestorsByRefFundLeadMaxPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadMaxPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadMaxRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadMaxRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadMaxRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMaxRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMaxRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadMaxRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadMaxSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadMaxSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadMaxTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TAGLINE_ASC",
  LgInvestorsByRefFundLeadMaxTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TAGLINE_DESC",
  LgInvestorsByRefFundLeadMaxTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TAGS_ASC",
  LgInvestorsByRefFundLeadMaxTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TAGS_DESC",
  LgInvestorsByRefFundLeadMaxTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TEAM_ASC",
  LgInvestorsByRefFundLeadMaxTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TEAM_DESC",
  LgInvestorsByRefFundLeadMaxTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMaxTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMaxTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadMaxTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadMaxTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMaxTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMaxTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadMaxTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadMaxUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_URL_ASC",
  LgInvestorsByRefFundLeadMaxUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_URL_DESC",
  LgInvestorsByRefFundLeadMaxWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadMaxWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MAX_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadMinAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ABOUT_ASC",
  LgInvestorsByRefFundLeadMinAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ABOUT_DESC",
  LgInvestorsByRefFundLeadMinAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadMinAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadMinAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadMinAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadMinClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadMinClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadMinCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadMinCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadMinCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadMinCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadMinCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadMinCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadMinCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadMinCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadMinDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadMinDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadMinDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadMinDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadMinDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMinDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMinEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadMinEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadMinEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadMinEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadMinFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadMinFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadMinFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadMinFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadMinFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadMinFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadMinGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadMinGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadMinHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadMinHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadMinIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ID_ASC",
  LgInvestorsByRefFundLeadMinIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_ID_DESC",
  LgInvestorsByRefFundLeadMinImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IMAGE_ASC",
  LgInvestorsByRefFundLeadMinImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IMAGE_DESC",
  LgInvestorsByRefFundLeadMinIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadMinIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadMinInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadMinInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadMinInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMinInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMinInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadMinInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadMinInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadMinInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadMinIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadMinIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadMinIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadMinIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadMinIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadMinIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadMinLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadMinLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadMinLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMinLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMinLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadMinLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadMinLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadMinLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadMinLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadMinLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadMinLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadMinLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadMinLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadMinLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadMinNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_NAME_ASC",
  LgInvestorsByRefFundLeadMinNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_NAME_DESC",
  LgInvestorsByRefFundLeadMinPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_PATH_ASC",
  LgInvestorsByRefFundLeadMinPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_PATH_DESC",
  LgInvestorsByRefFundLeadMinPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadMinPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadMinRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadMinRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadMinRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMinRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMinRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadMinRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadMinSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadMinSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadMinTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TAGLINE_ASC",
  LgInvestorsByRefFundLeadMinTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TAGLINE_DESC",
  LgInvestorsByRefFundLeadMinTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TAGS_ASC",
  LgInvestorsByRefFundLeadMinTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TAGS_DESC",
  LgInvestorsByRefFundLeadMinTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TEAM_ASC",
  LgInvestorsByRefFundLeadMinTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TEAM_DESC",
  LgInvestorsByRefFundLeadMinTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadMinTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadMinTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadMinTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadMinTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadMinTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadMinTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadMinTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadMinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_URL_ASC",
  LgInvestorsByRefFundLeadMinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_URL_DESC",
  LgInvestorsByRefFundLeadMinWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadMinWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_MIN_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ABOUT_ASC",
  LgInvestorsByRefFundLeadStddevPopulationAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ABOUT_DESC",
  LgInvestorsByRefFundLeadStddevPopulationAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadStddevPopulationAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadStddevPopulationClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadStddevPopulationCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadStddevPopulationCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevPopulationDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevPopulationEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadStddevPopulationEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadStddevPopulationEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadStddevPopulationEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadStddevPopulationFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadStddevPopulationFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadStddevPopulationGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ID_ASC",
  LgInvestorsByRefFundLeadStddevPopulationIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_ID_DESC",
  LgInvestorsByRefFundLeadStddevPopulationImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IMAGE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IMAGE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadStddevPopulationInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadStddevPopulationInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadStddevPopulationIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadStddevPopulationIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadStddevPopulationIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadStddevPopulationLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_NAME_ASC",
  LgInvestorsByRefFundLeadStddevPopulationNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_NAME_DESC",
  LgInvestorsByRefFundLeadStddevPopulationPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_PATH_ASC",
  LgInvestorsByRefFundLeadStddevPopulationPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_PATH_DESC",
  LgInvestorsByRefFundLeadStddevPopulationPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadStddevPopulationPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadStddevPopulationRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevPopulationRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevPopulationRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadStddevPopulationRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadStddevPopulationSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadStddevPopulationSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TAGLINE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TAGLINE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TAGS_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TAGS_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TEAM_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TEAM_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevPopulationTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_URL_DESC",
  LgInvestorsByRefFundLeadStddevPopulationWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadStddevPopulationWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_POPULATION_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ABOUT_ASC",
  LgInvestorsByRefFundLeadStddevSampleAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ABOUT_DESC",
  LgInvestorsByRefFundLeadStddevSampleAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadStddevSampleAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadStddevSampleClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadStddevSampleClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadStddevSampleCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadStddevSampleCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadStddevSampleCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadStddevSampleCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadStddevSampleCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadStddevSampleCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadStddevSampleCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadStddevSampleDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadStddevSampleDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadStddevSampleDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadStddevSampleDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevSampleDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevSampleEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadStddevSampleEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadStddevSampleEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadStddevSampleEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadStddevSampleFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadStddevSampleFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadStddevSampleFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadStddevSampleFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadStddevSampleGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadStddevSampleHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadStddevSampleIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ID_ASC",
  LgInvestorsByRefFundLeadStddevSampleIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_ID_DESC",
  LgInvestorsByRefFundLeadStddevSampleImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IMAGE_ASC",
  LgInvestorsByRefFundLeadStddevSampleImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IMAGE_DESC",
  LgInvestorsByRefFundLeadStddevSampleIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadStddevSampleIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadStddevSampleInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadStddevSampleInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadStddevSampleInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadStddevSampleIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadStddevSampleIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadStddevSampleIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadStddevSampleIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadStddevSampleIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadStddevSampleIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadStddevSampleLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevSampleLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevSampleLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevSampleLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevSampleLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadStddevSampleLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadStddevSampleLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadStddevSampleLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadStddevSampleLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadStddevSampleLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadStddevSampleLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadStddevSampleLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadStddevSampleLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_NAME_ASC",
  LgInvestorsByRefFundLeadStddevSampleNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_NAME_DESC",
  LgInvestorsByRefFundLeadStddevSamplePathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_PATH_ASC",
  LgInvestorsByRefFundLeadStddevSamplePathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_PATH_DESC",
  LgInvestorsByRefFundLeadStddevSamplePlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadStddevSamplePlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadStddevSampleRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevSampleRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevSampleRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevSampleRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevSampleRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadStddevSampleRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadStddevSampleSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadStddevSampleSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadStddevSampleTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TAGLINE_ASC",
  LgInvestorsByRefFundLeadStddevSampleTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TAGLINE_DESC",
  LgInvestorsByRefFundLeadStddevSampleTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TAGS_ASC",
  LgInvestorsByRefFundLeadStddevSampleTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TAGS_DESC",
  LgInvestorsByRefFundLeadStddevSampleTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TEAM_ASC",
  LgInvestorsByRefFundLeadStddevSampleTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TEAM_DESC",
  LgInvestorsByRefFundLeadStddevSampleTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadStddevSampleTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadStddevSampleTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadStddevSampleTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadStddevSampleTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadStddevSampleTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadStddevSampleTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_URL_DESC",
  LgInvestorsByRefFundLeadStddevSampleWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadStddevSampleWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_STDDEV_SAMPLE_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadSumAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ABOUT_ASC",
  LgInvestorsByRefFundLeadSumAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ABOUT_DESC",
  LgInvestorsByRefFundLeadSumAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadSumAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadSumAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadSumAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadSumClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadSumClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadSumCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadSumCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadSumCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadSumCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadSumCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadSumCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadSumCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadSumCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadSumDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadSumDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadSumDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadSumDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadSumDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadSumDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadSumEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadSumEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadSumEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadSumEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadSumFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadSumFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadSumFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadSumFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadSumFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadSumFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadSumGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadSumGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadSumHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadSumHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadSumIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ID_ASC",
  LgInvestorsByRefFundLeadSumIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_ID_DESC",
  LgInvestorsByRefFundLeadSumImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IMAGE_ASC",
  LgInvestorsByRefFundLeadSumImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IMAGE_DESC",
  LgInvestorsByRefFundLeadSumIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadSumIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadSumInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadSumInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadSumInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadSumInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadSumInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadSumInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadSumInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadSumInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadSumIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadSumIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadSumIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadSumIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadSumIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadSumIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadSumLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadSumLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadSumLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadSumLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadSumLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadSumLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadSumLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadSumLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadSumLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadSumLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadSumLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadSumLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadSumLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadSumLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadSumNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_NAME_ASC",
  LgInvestorsByRefFundLeadSumNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_NAME_DESC",
  LgInvestorsByRefFundLeadSumPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_PATH_ASC",
  LgInvestorsByRefFundLeadSumPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_PATH_DESC",
  LgInvestorsByRefFundLeadSumPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadSumPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadSumRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadSumRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadSumRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadSumRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadSumRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadSumRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadSumSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadSumSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadSumTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TAGLINE_ASC",
  LgInvestorsByRefFundLeadSumTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TAGLINE_DESC",
  LgInvestorsByRefFundLeadSumTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TAGS_ASC",
  LgInvestorsByRefFundLeadSumTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TAGS_DESC",
  LgInvestorsByRefFundLeadSumTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TEAM_ASC",
  LgInvestorsByRefFundLeadSumTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TEAM_DESC",
  LgInvestorsByRefFundLeadSumTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadSumTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadSumTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadSumTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadSumTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadSumTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadSumTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadSumTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadSumUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_URL_ASC",
  LgInvestorsByRefFundLeadSumUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_URL_DESC",
  LgInvestorsByRefFundLeadSumWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadSumWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_SUM_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ABOUT_ASC",
  LgInvestorsByRefFundLeadVariancePopulationAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ABOUT_DESC",
  LgInvestorsByRefFundLeadVariancePopulationAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadVariancePopulationAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadVariancePopulationClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadVariancePopulationCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadVariancePopulationCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVariancePopulationDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVariancePopulationEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadVariancePopulationEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadVariancePopulationEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadVariancePopulationEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadVariancePopulationFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadVariancePopulationFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadVariancePopulationGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ID_ASC",
  LgInvestorsByRefFundLeadVariancePopulationIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_ID_DESC",
  LgInvestorsByRefFundLeadVariancePopulationImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IMAGE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IMAGE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadVariancePopulationInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadVariancePopulationInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadVariancePopulationIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadVariancePopulationIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadVariancePopulationIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadVariancePopulationLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_NAME_ASC",
  LgInvestorsByRefFundLeadVariancePopulationNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_NAME_DESC",
  LgInvestorsByRefFundLeadVariancePopulationPathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_PATH_ASC",
  LgInvestorsByRefFundLeadVariancePopulationPathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_PATH_DESC",
  LgInvestorsByRefFundLeadVariancePopulationPlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadVariancePopulationPlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadVariancePopulationRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadVariancePopulationRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadVariancePopulationRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadVariancePopulationRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadVariancePopulationSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadVariancePopulationSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TAGLINE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TAGLINE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TAGS_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TAGS_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TEAM_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TEAM_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVariancePopulationTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_URL_DESC",
  LgInvestorsByRefFundLeadVariancePopulationWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadVariancePopulationWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_POPULATION_WEBSITE_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleAboutAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ABOUT_ASC",
  LgInvestorsByRefFundLeadVarianceSampleAboutDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ABOUT_DESC",
  LgInvestorsByRefFundLeadVarianceSampleAngellistUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ANGELLIST_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleAngellistUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ANGELLIST_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleAppstoreAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_APPSTORE_APP_ID_ASC",
  LgInvestorsByRefFundLeadVarianceSampleAppstoreAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_APPSTORE_APP_ID_DESC",
  LgInvestorsByRefFundLeadVarianceSampleClientFocusAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CLIENT_FOCUS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleClientFocusDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CLIENT_FOCUS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleCountryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_COUNTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleCountryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_COUNTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleCoInvestorsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CO_INVESTORS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleCoInvestorsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CO_INVESTORS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleCreatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgInvestorsByRefFundLeadVarianceSampleCreatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgInvestorsByRefFundLeadVarianceSampleCrunchbaseUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CRUNCHBASE_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleCrunchbaseUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_CRUNCHBASE_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleDealSizeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DEAL_SIZE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleDealSizeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DEAL_SIZE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleDealStructureAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DEAL_STRUCTURE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleDealStructureDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DEAL_STRUCTURE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleDutchInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DUTCH_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVarianceSampleDutchInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_DUTCH_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVarianceSampleEmployeesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_EMPLOYEES_ASC",
  LgInvestorsByRefFundLeadVarianceSampleEmployeesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_EMPLOYEES_DESC",
  LgInvestorsByRefFundLeadVarianceSampleEmployeesLatestAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_EMPLOYEES_LATEST_ASC",
  LgInvestorsByRefFundLeadVarianceSampleEmployeesLatestDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_EMPLOYEES_LATEST_DESC",
  LgInvestorsByRefFundLeadVarianceSampleFacebookUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FACEBOOK_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleFacebookUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FACEBOOK_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleFundingsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FUNDINGS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleFundingsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FUNDINGS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleFundsUnderManagementAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FUNDS_UNDER_MANAGEMENT_ASC",
  LgInvestorsByRefFundLeadVarianceSampleFundsUnderManagementDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_FUNDS_UNDER_MANAGEMENT_DESC",
  LgInvestorsByRefFundLeadVarianceSampleGoogleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_GOOGLE_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleGoogleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_GOOGLE_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleHqLocationsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_HQ_LOCATIONS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleHqLocationsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_HQ_LOCATIONS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ID_ASC",
  LgInvestorsByRefFundLeadVarianceSampleIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_ID_DESC",
  LgInvestorsByRefFundLeadVarianceSampleImageAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IMAGE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleImageDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IMAGE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleIndustryExperienceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INDUSTRY_EXPERIENCE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleIndustryExperienceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INDUSTRY_EXPERIENCE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENTS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENTS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentStagesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENT_STAGES_ASC",
  LgInvestorsByRefFundLeadVarianceSampleInvestmentStagesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTMENT_STAGES_DESC",
  LgInvestorsByRefFundLeadVarianceSampleInvestorTypeAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTOR_TYPE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleInvestorTypeDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_INVESTOR_TYPE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleIsBasecampAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_BASECAMP_ASC",
  LgInvestorsByRefFundLeadVarianceSampleIsBasecampDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_BASECAMP_DESC",
  LgInvestorsByRefFundLeadVarianceSampleIsEditorialAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_EDITORIAL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleIsEditorialDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_EDITORIAL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleIsTrustedAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_TRUSTED_ASC",
  LgInvestorsByRefFundLeadVarianceSampleIsTrustedDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_IS_TRUSTED_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLastFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_FUNDING_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLastFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_FUNDING_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLastFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLastFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLastUpdatedUtcAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLastUpdatedUtcDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLaunchMonthAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAUNCH_MONTH_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLaunchMonthDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAUNCH_MONTH_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLaunchYearAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAUNCH_YEAR_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLaunchYearDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LAUNCH_YEAR_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLgRankingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LG_RANKING_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLgRankingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LG_RANKING_DESC",
  LgInvestorsByRefFundLeadVarianceSampleLinkedinUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LINKEDIN_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleLinkedinUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_LINKEDIN_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleNameAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_NAME_ASC",
  LgInvestorsByRefFundLeadVarianceSampleNameDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_NAME_DESC",
  LgInvestorsByRefFundLeadVarianceSamplePathAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_PATH_ASC",
  LgInvestorsByRefFundLeadVarianceSamplePathDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_PATH_DESC",
  LgInvestorsByRefFundLeadVarianceSamplePlaymarketAppIdAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_PLAYMARKET_APP_ID_ASC",
  LgInvestorsByRefFundLeadVarianceSamplePlaymarketAppIdDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_PLAYMARKET_APP_ID_DESC",
  LgInvestorsByRefFundLeadVarianceSampleRecentFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_RECENT_FUNDING_ASC",
  LgInvestorsByRefFundLeadVarianceSampleRecentFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_RECENT_FUNDING_DESC",
  LgInvestorsByRefFundLeadVarianceSampleRecentFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_RECENT_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleRecentFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_RECENT_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleRefFundLeadAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_REF_FUND_LEAD_ASC",
  LgInvestorsByRefFundLeadVarianceSampleRefFundLeadDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_REF_FUND_LEAD_DESC",
  LgInvestorsByRefFundLeadVarianceSampleSubTypesAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_SUB_TYPES_ASC",
  LgInvestorsByRefFundLeadVarianceSampleSubTypesDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_SUB_TYPES_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTaglineAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TAGLINE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTaglineDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TAGLINE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTagsAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TAGS_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTagsDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TAGS_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTeamAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TEAM_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTeamDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TEAM_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTopInvestmentsNumAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOP_INVESTMENTS_NUM_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTopInvestmentsNumDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOP_INVESTMENTS_NUM_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTotalFundingAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOTAL_FUNDING_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTotalFundingDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOTAL_FUNDING_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTotalFundingSourceAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOTAL_FUNDING_SOURCE_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTotalFundingSourceDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TOTAL_FUNDING_SOURCE_DESC",
  LgInvestorsByRefFundLeadVarianceSampleTwitterUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TWITTER_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleTwitterUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_TWITTER_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_URL_DESC",
  LgInvestorsByRefFundLeadVarianceSampleWebsiteUrlAsc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_WEBSITE_URL_ASC",
  LgInvestorsByRefFundLeadVarianceSampleWebsiteUrlDesc = "LG_INVESTORS_BY_REF_FUND_LEAD_VARIANCE_SAMPLE_WEBSITE_URL_DESC",
  LgMemberSettingsByUserIdAverageFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdAverageFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdAverageFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_FILTERS_ASC",
  LgMemberSettingsByUserIdAverageFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_FILTERS_DESC",
  LgMemberSettingsByUserIdAverageIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_ID_ASC",
  LgMemberSettingsByUserIdAverageIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_ID_DESC",
  LgMemberSettingsByUserIdAverageUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_USER_ID_ASC",
  LgMemberSettingsByUserIdAverageUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_AVERAGE_USER_ID_DESC",
  LgMemberSettingsByUserIdCountAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_COUNT_ASC",
  LgMemberSettingsByUserIdCountDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_COUNT_DESC",
  LgMemberSettingsByUserIdDistinctCountFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdDistinctCountFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdDistinctCountFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_FILTERS_ASC",
  LgMemberSettingsByUserIdDistinctCountFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_FILTERS_DESC",
  LgMemberSettingsByUserIdDistinctCountIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_ID_ASC",
  LgMemberSettingsByUserIdDistinctCountIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_ID_DESC",
  LgMemberSettingsByUserIdDistinctCountUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_USER_ID_ASC",
  LgMemberSettingsByUserIdDistinctCountUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_DISTINCT_COUNT_USER_ID_DESC",
  LgMemberSettingsByUserIdMaxFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdMaxFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdMaxFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_FILTERS_ASC",
  LgMemberSettingsByUserIdMaxFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_FILTERS_DESC",
  LgMemberSettingsByUserIdMaxIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_ID_ASC",
  LgMemberSettingsByUserIdMaxIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_ID_DESC",
  LgMemberSettingsByUserIdMaxUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_USER_ID_ASC",
  LgMemberSettingsByUserIdMaxUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MAX_USER_ID_DESC",
  LgMemberSettingsByUserIdMinFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdMinFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdMinFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_FILTERS_ASC",
  LgMemberSettingsByUserIdMinFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_FILTERS_DESC",
  LgMemberSettingsByUserIdMinIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_ID_ASC",
  LgMemberSettingsByUserIdMinIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_ID_DESC",
  LgMemberSettingsByUserIdMinUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_USER_ID_ASC",
  LgMemberSettingsByUserIdMinUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_MIN_USER_ID_DESC",
  LgMemberSettingsByUserIdStddevPopulationFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdStddevPopulationFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdStddevPopulationFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_FILTERS_ASC",
  LgMemberSettingsByUserIdStddevPopulationFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_FILTERS_DESC",
  LgMemberSettingsByUserIdStddevPopulationIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_ID_ASC",
  LgMemberSettingsByUserIdStddevPopulationIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_ID_DESC",
  LgMemberSettingsByUserIdStddevPopulationUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_USER_ID_ASC",
  LgMemberSettingsByUserIdStddevPopulationUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_POPULATION_USER_ID_DESC",
  LgMemberSettingsByUserIdStddevSampleFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdStddevSampleFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdStddevSampleFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_FILTERS_ASC",
  LgMemberSettingsByUserIdStddevSampleFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_FILTERS_DESC",
  LgMemberSettingsByUserIdStddevSampleIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_ID_ASC",
  LgMemberSettingsByUserIdStddevSampleIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_ID_DESC",
  LgMemberSettingsByUserIdStddevSampleUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_ASC",
  LgMemberSettingsByUserIdStddevSampleUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_DESC",
  LgMemberSettingsByUserIdSumFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdSumFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdSumFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_FILTERS_ASC",
  LgMemberSettingsByUserIdSumFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_FILTERS_DESC",
  LgMemberSettingsByUserIdSumIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_ID_ASC",
  LgMemberSettingsByUserIdSumIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_ID_DESC",
  LgMemberSettingsByUserIdSumUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_USER_ID_ASC",
  LgMemberSettingsByUserIdSumUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_SUM_USER_ID_DESC",
  LgMemberSettingsByUserIdVariancePopulationFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdVariancePopulationFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdVariancePopulationFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_FILTERS_ASC",
  LgMemberSettingsByUserIdVariancePopulationFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_FILTERS_DESC",
  LgMemberSettingsByUserIdVariancePopulationIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_ID_ASC",
  LgMemberSettingsByUserIdVariancePopulationIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_ID_DESC",
  LgMemberSettingsByUserIdVariancePopulationUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_ASC",
  LgMemberSettingsByUserIdVariancePopulationUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_DESC",
  LgMemberSettingsByUserIdVarianceSampleFeedbackEmailTemplateAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_FEEDBACK_EMAIL_TEMPLATE_ASC",
  LgMemberSettingsByUserIdVarianceSampleFeedbackEmailTemplateDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_FEEDBACK_EMAIL_TEMPLATE_DESC",
  LgMemberSettingsByUserIdVarianceSampleFiltersAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_FILTERS_ASC",
  LgMemberSettingsByUserIdVarianceSampleFiltersDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_FILTERS_DESC",
  LgMemberSettingsByUserIdVarianceSampleIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_ID_ASC",
  LgMemberSettingsByUserIdVarianceSampleIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_ID_DESC",
  LgMemberSettingsByUserIdVarianceSampleUserIdAsc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_ASC",
  LgMemberSettingsByUserIdVarianceSampleUserIdDesc = "LG_MEMBER_SETTINGS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_DESC",
  LgUserFeedbacksByCreatedByAverageCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByAverageCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByAverageCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByAverageCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByAverageFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByAverageFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByAverageIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_ID_ASC",
  LgUserFeedbacksByCreatedByAverageIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_ID_DESC",
  LgUserFeedbacksByCreatedByAverageLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByAverageLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByAverageStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_STATUS_ASC",
  LgUserFeedbacksByCreatedByAverageStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_AVERAGE_STATUS_DESC",
  LgUserFeedbacksByCreatedByCountAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_COUNT_ASC",
  LgUserFeedbacksByCreatedByCountDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_COUNT_DESC",
  LgUserFeedbacksByCreatedByDistinctCountCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByDistinctCountCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByDistinctCountCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByDistinctCountCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByDistinctCountFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByDistinctCountFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByDistinctCountIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_ID_ASC",
  LgUserFeedbacksByCreatedByDistinctCountIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_ID_DESC",
  LgUserFeedbacksByCreatedByDistinctCountLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByDistinctCountLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByDistinctCountStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_STATUS_ASC",
  LgUserFeedbacksByCreatedByDistinctCountStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_DISTINCT_COUNT_STATUS_DESC",
  LgUserFeedbacksByCreatedByMaxCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByMaxCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByMaxCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByMaxCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByMaxFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByMaxFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByMaxIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_ID_ASC",
  LgUserFeedbacksByCreatedByMaxIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_ID_DESC",
  LgUserFeedbacksByCreatedByMaxLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByMaxLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByMaxStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_STATUS_ASC",
  LgUserFeedbacksByCreatedByMaxStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MAX_STATUS_DESC",
  LgUserFeedbacksByCreatedByMinCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByMinCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByMinCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByMinCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByMinFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByMinFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByMinIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_ID_ASC",
  LgUserFeedbacksByCreatedByMinIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_ID_DESC",
  LgUserFeedbacksByCreatedByMinLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByMinLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByMinStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_STATUS_ASC",
  LgUserFeedbacksByCreatedByMinStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_MIN_STATUS_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_ID_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_ID_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByStddevPopulationStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_STATUS_ASC",
  LgUserFeedbacksByCreatedByStddevPopulationStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_POPULATION_STATUS_DESC",
  LgUserFeedbacksByCreatedByStddevSampleCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByStddevSampleCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByStddevSampleCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByStddevSampleCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByStddevSampleFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByStddevSampleFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByStddevSampleIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_ID_ASC",
  LgUserFeedbacksByCreatedByStddevSampleIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_ID_DESC",
  LgUserFeedbacksByCreatedByStddevSampleLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByStddevSampleLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByStddevSampleStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_STATUS_ASC",
  LgUserFeedbacksByCreatedByStddevSampleStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_STDDEV_SAMPLE_STATUS_DESC",
  LgUserFeedbacksByCreatedBySumCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedBySumCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedBySumCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedBySumCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedBySumFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedBySumFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedBySumIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_ID_ASC",
  LgUserFeedbacksByCreatedBySumIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_ID_DESC",
  LgUserFeedbacksByCreatedBySumLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedBySumLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedBySumStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_STATUS_ASC",
  LgUserFeedbacksByCreatedBySumStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_SUM_STATUS_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_ID_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_ID_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByVariancePopulationStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_STATUS_ASC",
  LgUserFeedbacksByCreatedByVariancePopulationStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_POPULATION_STATUS_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleCreatedByAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleCreatedByDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_BY_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleCreatedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleCreatedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleFeedbackAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_FEEDBACK_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleFeedbackDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_FEEDBACK_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleIdAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleIdDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_ID_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleLastModifiedUtcAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleLastModifiedUtcDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgUserFeedbacksByCreatedByVarianceSampleStatusAsc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_STATUS_ASC",
  LgUserFeedbacksByCreatedByVarianceSampleStatusDesc = "LG_USER_FEEDBACKS_BY_CREATED_BY_VARIANCE_SAMPLE_STATUS_DESC",
  MnEntriesByMemberIdAverageCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_CREATED_UTC_ASC",
  MnEntriesByMemberIdAverageCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_CREATED_UTC_DESC",
  MnEntriesByMemberIdAverageDataAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_DATA_ASC",
  MnEntriesByMemberIdAverageDataDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_DATA_DESC",
  MnEntriesByMemberIdAverageIdAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_ID_ASC",
  MnEntriesByMemberIdAverageIdDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_ID_DESC",
  MnEntriesByMemberIdAverageLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdAverageLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdAverageMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  MnEntriesByMemberIdAverageMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  MnEntriesByMemberIdAverageTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_TABLE_NAME_ASC",
  MnEntriesByMemberIdAverageTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_AVERAGE_TABLE_NAME_DESC",
  MnEntriesByMemberIdCountAsc = "MN_ENTRIES_BY_MEMBER_ID_COUNT_ASC",
  MnEntriesByMemberIdCountDesc = "MN_ENTRIES_BY_MEMBER_ID_COUNT_DESC",
  MnEntriesByMemberIdDistinctCountCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_UTC_ASC",
  MnEntriesByMemberIdDistinctCountCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_UTC_DESC",
  MnEntriesByMemberIdDistinctCountDataAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_DATA_ASC",
  MnEntriesByMemberIdDistinctCountDataDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_DATA_DESC",
  MnEntriesByMemberIdDistinctCountIdAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  MnEntriesByMemberIdDistinctCountIdDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  MnEntriesByMemberIdDistinctCountLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdDistinctCountLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdDistinctCountMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  MnEntriesByMemberIdDistinctCountMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  MnEntriesByMemberIdDistinctCountTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_TABLE_NAME_ASC",
  MnEntriesByMemberIdDistinctCountTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_DISTINCT_COUNT_TABLE_NAME_DESC",
  MnEntriesByMemberIdMaxCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_CREATED_UTC_ASC",
  MnEntriesByMemberIdMaxCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_CREATED_UTC_DESC",
  MnEntriesByMemberIdMaxDataAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_DATA_ASC",
  MnEntriesByMemberIdMaxDataDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_DATA_DESC",
  MnEntriesByMemberIdMaxIdAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_ID_ASC",
  MnEntriesByMemberIdMaxIdDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_ID_DESC",
  MnEntriesByMemberIdMaxLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdMaxLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdMaxMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  MnEntriesByMemberIdMaxMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  MnEntriesByMemberIdMaxTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_MAX_TABLE_NAME_ASC",
  MnEntriesByMemberIdMaxTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_MAX_TABLE_NAME_DESC",
  MnEntriesByMemberIdMinCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_CREATED_UTC_ASC",
  MnEntriesByMemberIdMinCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_CREATED_UTC_DESC",
  MnEntriesByMemberIdMinDataAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_DATA_ASC",
  MnEntriesByMemberIdMinDataDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_DATA_DESC",
  MnEntriesByMemberIdMinIdAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_ID_ASC",
  MnEntriesByMemberIdMinIdDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_ID_DESC",
  MnEntriesByMemberIdMinLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdMinLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdMinMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  MnEntriesByMemberIdMinMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  MnEntriesByMemberIdMinTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_MIN_TABLE_NAME_ASC",
  MnEntriesByMemberIdMinTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_MIN_TABLE_NAME_DESC",
  MnEntriesByMemberIdStddevPopulationCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_UTC_ASC",
  MnEntriesByMemberIdStddevPopulationCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_UTC_DESC",
  MnEntriesByMemberIdStddevPopulationDataAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_DATA_ASC",
  MnEntriesByMemberIdStddevPopulationDataDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_DATA_DESC",
  MnEntriesByMemberIdStddevPopulationIdAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  MnEntriesByMemberIdStddevPopulationIdDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  MnEntriesByMemberIdStddevPopulationLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdStddevPopulationLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdStddevPopulationMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  MnEntriesByMemberIdStddevPopulationMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  MnEntriesByMemberIdStddevPopulationTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_TABLE_NAME_ASC",
  MnEntriesByMemberIdStddevPopulationTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_POPULATION_TABLE_NAME_DESC",
  MnEntriesByMemberIdStddevSampleCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_UTC_ASC",
  MnEntriesByMemberIdStddevSampleCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_UTC_DESC",
  MnEntriesByMemberIdStddevSampleDataAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_DATA_ASC",
  MnEntriesByMemberIdStddevSampleDataDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_DATA_DESC",
  MnEntriesByMemberIdStddevSampleIdAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  MnEntriesByMemberIdStddevSampleIdDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  MnEntriesByMemberIdStddevSampleLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdStddevSampleLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdStddevSampleMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  MnEntriesByMemberIdStddevSampleMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  MnEntriesByMemberIdStddevSampleTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_TABLE_NAME_ASC",
  MnEntriesByMemberIdStddevSampleTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_STDDEV_SAMPLE_TABLE_NAME_DESC",
  MnEntriesByMemberIdSumCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_CREATED_UTC_ASC",
  MnEntriesByMemberIdSumCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_CREATED_UTC_DESC",
  MnEntriesByMemberIdSumDataAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_DATA_ASC",
  MnEntriesByMemberIdSumDataDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_DATA_DESC",
  MnEntriesByMemberIdSumIdAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_ID_ASC",
  MnEntriesByMemberIdSumIdDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_ID_DESC",
  MnEntriesByMemberIdSumLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdSumLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdSumMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  MnEntriesByMemberIdSumMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  MnEntriesByMemberIdSumTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_SUM_TABLE_NAME_ASC",
  MnEntriesByMemberIdSumTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_SUM_TABLE_NAME_DESC",
  MnEntriesByMemberIdVariancePopulationCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_UTC_ASC",
  MnEntriesByMemberIdVariancePopulationCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_UTC_DESC",
  MnEntriesByMemberIdVariancePopulationDataAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_DATA_ASC",
  MnEntriesByMemberIdVariancePopulationDataDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_DATA_DESC",
  MnEntriesByMemberIdVariancePopulationIdAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  MnEntriesByMemberIdVariancePopulationIdDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  MnEntriesByMemberIdVariancePopulationLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdVariancePopulationLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdVariancePopulationMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  MnEntriesByMemberIdVariancePopulationMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  MnEntriesByMemberIdVariancePopulationTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_TABLE_NAME_ASC",
  MnEntriesByMemberIdVariancePopulationTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_POPULATION_TABLE_NAME_DESC",
  MnEntriesByMemberIdVarianceSampleCreatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  MnEntriesByMemberIdVarianceSampleCreatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  MnEntriesByMemberIdVarianceSampleDataAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_DATA_ASC",
  MnEntriesByMemberIdVarianceSampleDataDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_DATA_DESC",
  MnEntriesByMemberIdVarianceSampleIdAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  MnEntriesByMemberIdVarianceSampleIdDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  MnEntriesByMemberIdVarianceSampleLastUpdatedUtcAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  MnEntriesByMemberIdVarianceSampleLastUpdatedUtcDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  MnEntriesByMemberIdVarianceSampleMemberIdAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  MnEntriesByMemberIdVarianceSampleMemberIdDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  MnEntriesByMemberIdVarianceSampleTableNameAsc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_TABLE_NAME_ASC",
  MnEntriesByMemberIdVarianceSampleTableNameDesc = "MN_ENTRIES_BY_MEMBER_ID_VARIANCE_SAMPLE_TABLE_NAME_DESC",
  Natural = "NATURAL",
  NzrNotificationsByAssigneeAverageAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeAverageAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeAverageCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeAverageCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeAverageIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_ID_ASC",
  NzrNotificationsByAssigneeAverageIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_ID_DESC",
  NzrNotificationsByAssigneeAverageLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeAverageLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeAveragePayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_PAYLOAD_ASC",
  NzrNotificationsByAssigneeAveragePayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_PAYLOAD_DESC",
  NzrNotificationsByAssigneeAverageReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeAverageReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeAverageReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeAverageReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeAverageStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_STATUS_ASC",
  NzrNotificationsByAssigneeAverageStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_STATUS_DESC",
  NzrNotificationsByAssigneeAverageTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_TYPE_ASC",
  NzrNotificationsByAssigneeAverageTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_TYPE_DESC",
  NzrNotificationsByAssigneeAverageValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_VALIDITY_ASC",
  NzrNotificationsByAssigneeAverageValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_AVERAGE_VALIDITY_DESC",
  NzrNotificationsByAssigneeCountAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_COUNT_ASC",
  NzrNotificationsByAssigneeCountDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_COUNT_DESC",
  NzrNotificationsByAssigneeDistinctCountAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeDistinctCountAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeDistinctCountCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeDistinctCountCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeDistinctCountIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_ID_ASC",
  NzrNotificationsByAssigneeDistinctCountIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_ID_DESC",
  NzrNotificationsByAssigneeDistinctCountLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeDistinctCountLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeDistinctCountPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_PAYLOAD_ASC",
  NzrNotificationsByAssigneeDistinctCountPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_PAYLOAD_DESC",
  NzrNotificationsByAssigneeDistinctCountReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeDistinctCountReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeDistinctCountReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeDistinctCountReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeDistinctCountStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_STATUS_ASC",
  NzrNotificationsByAssigneeDistinctCountStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_STATUS_DESC",
  NzrNotificationsByAssigneeDistinctCountTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_TYPE_ASC",
  NzrNotificationsByAssigneeDistinctCountTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_TYPE_DESC",
  NzrNotificationsByAssigneeDistinctCountValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_VALIDITY_ASC",
  NzrNotificationsByAssigneeDistinctCountValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_DISTINCT_COUNT_VALIDITY_DESC",
  NzrNotificationsByAssigneeMaxAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeMaxAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeMaxCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeMaxCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeMaxIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_ID_ASC",
  NzrNotificationsByAssigneeMaxIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_ID_DESC",
  NzrNotificationsByAssigneeMaxLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeMaxLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeMaxPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_PAYLOAD_ASC",
  NzrNotificationsByAssigneeMaxPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_PAYLOAD_DESC",
  NzrNotificationsByAssigneeMaxReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeMaxReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeMaxReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeMaxReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeMaxStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_STATUS_ASC",
  NzrNotificationsByAssigneeMaxStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_STATUS_DESC",
  NzrNotificationsByAssigneeMaxTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_TYPE_ASC",
  NzrNotificationsByAssigneeMaxTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_TYPE_DESC",
  NzrNotificationsByAssigneeMaxValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_VALIDITY_ASC",
  NzrNotificationsByAssigneeMaxValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MAX_VALIDITY_DESC",
  NzrNotificationsByAssigneeMinAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeMinAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeMinCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeMinCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeMinIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_ID_ASC",
  NzrNotificationsByAssigneeMinIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_ID_DESC",
  NzrNotificationsByAssigneeMinLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeMinLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeMinPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_PAYLOAD_ASC",
  NzrNotificationsByAssigneeMinPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_PAYLOAD_DESC",
  NzrNotificationsByAssigneeMinReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeMinReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeMinReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeMinReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeMinStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_STATUS_ASC",
  NzrNotificationsByAssigneeMinStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_STATUS_DESC",
  NzrNotificationsByAssigneeMinTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_TYPE_ASC",
  NzrNotificationsByAssigneeMinTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_TYPE_DESC",
  NzrNotificationsByAssigneeMinValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_VALIDITY_ASC",
  NzrNotificationsByAssigneeMinValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_MIN_VALIDITY_DESC",
  NzrNotificationsByAssigneeStddevPopulationAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeStddevPopulationAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeStddevPopulationCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeStddevPopulationCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeStddevPopulationIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_ID_ASC",
  NzrNotificationsByAssigneeStddevPopulationIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_ID_DESC",
  NzrNotificationsByAssigneeStddevPopulationLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeStddevPopulationLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeStddevPopulationPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_PAYLOAD_ASC",
  NzrNotificationsByAssigneeStddevPopulationPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_PAYLOAD_DESC",
  NzrNotificationsByAssigneeStddevPopulationReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeStddevPopulationReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeStddevPopulationReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeStddevPopulationReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeStddevPopulationStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_STATUS_ASC",
  NzrNotificationsByAssigneeStddevPopulationStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_STATUS_DESC",
  NzrNotificationsByAssigneeStddevPopulationTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_TYPE_ASC",
  NzrNotificationsByAssigneeStddevPopulationTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_TYPE_DESC",
  NzrNotificationsByAssigneeStddevPopulationValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_VALIDITY_ASC",
  NzrNotificationsByAssigneeStddevPopulationValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_POPULATION_VALIDITY_DESC",
  NzrNotificationsByAssigneeStddevSampleAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeStddevSampleAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeStddevSampleCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeStddevSampleCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeStddevSampleIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_ID_ASC",
  NzrNotificationsByAssigneeStddevSampleIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_ID_DESC",
  NzrNotificationsByAssigneeStddevSampleLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeStddevSampleLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeStddevSamplePayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_PAYLOAD_ASC",
  NzrNotificationsByAssigneeStddevSamplePayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_PAYLOAD_DESC",
  NzrNotificationsByAssigneeStddevSampleReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeStddevSampleReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeStddevSampleReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeStddevSampleReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeStddevSampleStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_STATUS_ASC",
  NzrNotificationsByAssigneeStddevSampleStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_STATUS_DESC",
  NzrNotificationsByAssigneeStddevSampleTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_TYPE_ASC",
  NzrNotificationsByAssigneeStddevSampleTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_TYPE_DESC",
  NzrNotificationsByAssigneeStddevSampleValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_VALIDITY_ASC",
  NzrNotificationsByAssigneeStddevSampleValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_STDDEV_SAMPLE_VALIDITY_DESC",
  NzrNotificationsByAssigneeSumAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeSumAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeSumCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeSumCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeSumIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_ID_ASC",
  NzrNotificationsByAssigneeSumIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_ID_DESC",
  NzrNotificationsByAssigneeSumLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeSumLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeSumPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_PAYLOAD_ASC",
  NzrNotificationsByAssigneeSumPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_PAYLOAD_DESC",
  NzrNotificationsByAssigneeSumReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeSumReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeSumReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeSumReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeSumStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_STATUS_ASC",
  NzrNotificationsByAssigneeSumStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_STATUS_DESC",
  NzrNotificationsByAssigneeSumTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_TYPE_ASC",
  NzrNotificationsByAssigneeSumTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_TYPE_DESC",
  NzrNotificationsByAssigneeSumValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_VALIDITY_ASC",
  NzrNotificationsByAssigneeSumValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_SUM_VALIDITY_DESC",
  NzrNotificationsByAssigneeVariancePopulationAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeVariancePopulationAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeVariancePopulationCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeVariancePopulationCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeVariancePopulationIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_ID_ASC",
  NzrNotificationsByAssigneeVariancePopulationIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_ID_DESC",
  NzrNotificationsByAssigneeVariancePopulationLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeVariancePopulationLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeVariancePopulationPayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_PAYLOAD_ASC",
  NzrNotificationsByAssigneeVariancePopulationPayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_PAYLOAD_DESC",
  NzrNotificationsByAssigneeVariancePopulationReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeVariancePopulationReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeVariancePopulationReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeVariancePopulationReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeVariancePopulationStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_STATUS_ASC",
  NzrNotificationsByAssigneeVariancePopulationStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_STATUS_DESC",
  NzrNotificationsByAssigneeVariancePopulationTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_TYPE_ASC",
  NzrNotificationsByAssigneeVariancePopulationTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_TYPE_DESC",
  NzrNotificationsByAssigneeVariancePopulationValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_VALIDITY_ASC",
  NzrNotificationsByAssigneeVariancePopulationValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_POPULATION_VALIDITY_DESC",
  NzrNotificationsByAssigneeVarianceSampleAssigneeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_ASSIGNEE_ASC",
  NzrNotificationsByAssigneeVarianceSampleAssigneeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_ASSIGNEE_DESC",
  NzrNotificationsByAssigneeVarianceSampleCreatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  NzrNotificationsByAssigneeVarianceSampleCreatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  NzrNotificationsByAssigneeVarianceSampleIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_ID_ASC",
  NzrNotificationsByAssigneeVarianceSampleIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_ID_DESC",
  NzrNotificationsByAssigneeVarianceSampleLastUpdatedUtcAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrNotificationsByAssigneeVarianceSampleLastUpdatedUtcDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrNotificationsByAssigneeVarianceSamplePayloadAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_PAYLOAD_ASC",
  NzrNotificationsByAssigneeVarianceSamplePayloadDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_PAYLOAD_DESC",
  NzrNotificationsByAssigneeVarianceSampleReferenceIdAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_REFERENCE_ID_ASC",
  NzrNotificationsByAssigneeVarianceSampleReferenceIdDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_REFERENCE_ID_DESC",
  NzrNotificationsByAssigneeVarianceSampleReferenceTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_REFERENCE_TYPE_ASC",
  NzrNotificationsByAssigneeVarianceSampleReferenceTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_REFERENCE_TYPE_DESC",
  NzrNotificationsByAssigneeVarianceSampleStatusAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_STATUS_ASC",
  NzrNotificationsByAssigneeVarianceSampleStatusDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_STATUS_DESC",
  NzrNotificationsByAssigneeVarianceSampleTypeAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_TYPE_ASC",
  NzrNotificationsByAssigneeVarianceSampleTypeDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_TYPE_DESC",
  NzrNotificationsByAssigneeVarianceSampleValidityAsc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_VALIDITY_ASC",
  NzrNotificationsByAssigneeVarianceSampleValidityDesc = "NZR_NOTIFICATIONS_BY_ASSIGNEE_VARIANCE_SAMPLE_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeAverageAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeAverageAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeAverageCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeAverageCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeAverageIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_ID_ASC",
  NzrOpportunitiesByAssigneeAverageIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_ID_DESC",
  NzrOpportunitiesByAssigneeAverageLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeAverageLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeAverageMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_METADATA_ASC",
  NzrOpportunitiesByAssigneeAverageMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_METADATA_DESC",
  NzrOpportunitiesByAssigneeAverageSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeAverageSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeAverageSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeAverageSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeAverageStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_STATUS_ASC",
  NzrOpportunitiesByAssigneeAverageStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_STATUS_DESC",
  NzrOpportunitiesByAssigneeAverageValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeAverageValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_AVERAGE_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeCountAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_COUNT_ASC",
  NzrOpportunitiesByAssigneeCountDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_COUNT_DESC",
  NzrOpportunitiesByAssigneeDistinctCountAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeDistinctCountAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeDistinctCountCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeDistinctCountCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeDistinctCountIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_ID_ASC",
  NzrOpportunitiesByAssigneeDistinctCountIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_ID_DESC",
  NzrOpportunitiesByAssigneeDistinctCountLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeDistinctCountLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeDistinctCountMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_METADATA_ASC",
  NzrOpportunitiesByAssigneeDistinctCountMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_METADATA_DESC",
  NzrOpportunitiesByAssigneeDistinctCountSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeDistinctCountSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeDistinctCountSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeDistinctCountSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeDistinctCountStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_STATUS_ASC",
  NzrOpportunitiesByAssigneeDistinctCountStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_STATUS_DESC",
  NzrOpportunitiesByAssigneeDistinctCountValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeDistinctCountValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_DISTINCT_COUNT_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeMaxAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeMaxAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeMaxCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeMaxCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeMaxIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_ID_ASC",
  NzrOpportunitiesByAssigneeMaxIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_ID_DESC",
  NzrOpportunitiesByAssigneeMaxLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeMaxLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeMaxMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_METADATA_ASC",
  NzrOpportunitiesByAssigneeMaxMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_METADATA_DESC",
  NzrOpportunitiesByAssigneeMaxSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeMaxSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeMaxSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeMaxSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeMaxStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_STATUS_ASC",
  NzrOpportunitiesByAssigneeMaxStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_STATUS_DESC",
  NzrOpportunitiesByAssigneeMaxValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeMaxValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MAX_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeMinAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeMinAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeMinCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeMinCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeMinIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_ID_ASC",
  NzrOpportunitiesByAssigneeMinIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_ID_DESC",
  NzrOpportunitiesByAssigneeMinLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeMinLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeMinMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_METADATA_ASC",
  NzrOpportunitiesByAssigneeMinMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_METADATA_DESC",
  NzrOpportunitiesByAssigneeMinSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeMinSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeMinSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeMinSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeMinStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_STATUS_ASC",
  NzrOpportunitiesByAssigneeMinStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_STATUS_DESC",
  NzrOpportunitiesByAssigneeMinValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeMinValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_MIN_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_ID_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_ID_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_METADATA_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_METADATA_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_STATUS_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_STATUS_DESC",
  NzrOpportunitiesByAssigneeStddevPopulationValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeStddevPopulationValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeStddevSampleAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeStddevSampleAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeStddevSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeStddevSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeStddevSampleIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunitiesByAssigneeStddevSampleIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunitiesByAssigneeStddevSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeStddevSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeStddevSampleMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_METADATA_ASC",
  NzrOpportunitiesByAssigneeStddevSampleMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_METADATA_DESC",
  NzrOpportunitiesByAssigneeStddevSampleSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeStddevSampleSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeStddevSampleSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeStddevSampleSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeStddevSampleStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_STATUS_ASC",
  NzrOpportunitiesByAssigneeStddevSampleStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_STATUS_DESC",
  NzrOpportunitiesByAssigneeStddevSampleValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeStddevSampleValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_STDDEV_SAMPLE_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeSumAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeSumAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeSumCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeSumCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeSumIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_ID_ASC",
  NzrOpportunitiesByAssigneeSumIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_ID_DESC",
  NzrOpportunitiesByAssigneeSumLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeSumLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeSumMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_METADATA_ASC",
  NzrOpportunitiesByAssigneeSumMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_METADATA_DESC",
  NzrOpportunitiesByAssigneeSumSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeSumSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeSumSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeSumSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeSumStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_STATUS_ASC",
  NzrOpportunitiesByAssigneeSumStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_STATUS_DESC",
  NzrOpportunitiesByAssigneeSumValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeSumValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_SUM_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_METADATA_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_METADATA_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_STATUS_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_STATUS_DESC",
  NzrOpportunitiesByAssigneeVariancePopulationValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeVariancePopulationValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleAssigneeAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleAssigneeDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleMetadataAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_METADATA_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleMetadataDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_METADATA_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleSharedFromAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleSharedFromDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleSignalIdAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleSignalIdDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleStatusAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_STATUS_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleStatusDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_STATUS_DESC",
  NzrOpportunitiesByAssigneeVarianceSampleValidityAsc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesByAssigneeVarianceSampleValidityDesc = "NZR_OPPORTUNITIES_BY_ASSIGNEE_VARIANCE_SAMPLE_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromAverageAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromAverageAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromAverageCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromAverageCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromAverageIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_ID_ASC",
  NzrOpportunitiesBySharedFromAverageIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_ID_DESC",
  NzrOpportunitiesBySharedFromAverageLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromAverageLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromAverageMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_METADATA_ASC",
  NzrOpportunitiesBySharedFromAverageMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_METADATA_DESC",
  NzrOpportunitiesBySharedFromAverageSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromAverageSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromAverageSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromAverageSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromAverageStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_STATUS_ASC",
  NzrOpportunitiesBySharedFromAverageStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_STATUS_DESC",
  NzrOpportunitiesBySharedFromAverageValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromAverageValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_AVERAGE_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromCountAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_COUNT_ASC",
  NzrOpportunitiesBySharedFromCountDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_COUNT_DESC",
  NzrOpportunitiesBySharedFromDistinctCountAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromDistinctCountAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromDistinctCountCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromDistinctCountCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromDistinctCountIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_ID_ASC",
  NzrOpportunitiesBySharedFromDistinctCountIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_ID_DESC",
  NzrOpportunitiesBySharedFromDistinctCountLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromDistinctCountLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromDistinctCountMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_METADATA_ASC",
  NzrOpportunitiesBySharedFromDistinctCountMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_METADATA_DESC",
  NzrOpportunitiesBySharedFromDistinctCountSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromDistinctCountSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromDistinctCountSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromDistinctCountSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromDistinctCountStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_STATUS_ASC",
  NzrOpportunitiesBySharedFromDistinctCountStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_STATUS_DESC",
  NzrOpportunitiesBySharedFromDistinctCountValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromDistinctCountValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_DISTINCT_COUNT_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromMaxAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromMaxAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromMaxCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromMaxCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromMaxIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_ID_ASC",
  NzrOpportunitiesBySharedFromMaxIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_ID_DESC",
  NzrOpportunitiesBySharedFromMaxLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromMaxLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromMaxMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_METADATA_ASC",
  NzrOpportunitiesBySharedFromMaxMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_METADATA_DESC",
  NzrOpportunitiesBySharedFromMaxSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromMaxSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromMaxSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromMaxSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromMaxStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_STATUS_ASC",
  NzrOpportunitiesBySharedFromMaxStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_STATUS_DESC",
  NzrOpportunitiesBySharedFromMaxValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromMaxValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MAX_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromMinAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromMinAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromMinCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromMinCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromMinIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_ID_ASC",
  NzrOpportunitiesBySharedFromMinIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_ID_DESC",
  NzrOpportunitiesBySharedFromMinLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromMinLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromMinMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_METADATA_ASC",
  NzrOpportunitiesBySharedFromMinMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_METADATA_DESC",
  NzrOpportunitiesBySharedFromMinSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromMinSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromMinSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromMinSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromMinStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_STATUS_ASC",
  NzrOpportunitiesBySharedFromMinStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_STATUS_DESC",
  NzrOpportunitiesBySharedFromMinValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromMinValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_MIN_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_ID_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_ID_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_METADATA_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_METADATA_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_STATUS_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_STATUS_DESC",
  NzrOpportunitiesBySharedFromStddevPopulationValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromStddevPopulationValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromStddevSampleAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromStddevSampleAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromStddevSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromStddevSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromStddevSampleIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunitiesBySharedFromStddevSampleIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunitiesBySharedFromStddevSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromStddevSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromStddevSampleMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_METADATA_ASC",
  NzrOpportunitiesBySharedFromStddevSampleMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_METADATA_DESC",
  NzrOpportunitiesBySharedFromStddevSampleSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromStddevSampleSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromStddevSampleSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromStddevSampleSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromStddevSampleStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_STATUS_ASC",
  NzrOpportunitiesBySharedFromStddevSampleStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_STATUS_DESC",
  NzrOpportunitiesBySharedFromStddevSampleValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromStddevSampleValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_STDDEV_SAMPLE_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromSumAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromSumAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromSumCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromSumCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromSumIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_ID_ASC",
  NzrOpportunitiesBySharedFromSumIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_ID_DESC",
  NzrOpportunitiesBySharedFromSumLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromSumLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromSumMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_METADATA_ASC",
  NzrOpportunitiesBySharedFromSumMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_METADATA_DESC",
  NzrOpportunitiesBySharedFromSumSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromSumSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromSumSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromSumSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromSumStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_STATUS_ASC",
  NzrOpportunitiesBySharedFromSumStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_STATUS_DESC",
  NzrOpportunitiesBySharedFromSumValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromSumValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_SUM_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_METADATA_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_METADATA_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_STATUS_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_STATUS_DESC",
  NzrOpportunitiesBySharedFromVariancePopulationValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromVariancePopulationValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleAssigneeAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleAssigneeDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleMetadataAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_METADATA_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleMetadataDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_METADATA_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleSharedFromAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleSharedFromDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleSignalIdAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleSignalIdDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleStatusAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_STATUS_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleStatusDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_STATUS_DESC",
  NzrOpportunitiesBySharedFromVarianceSampleValidityAsc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesBySharedFromVarianceSampleValidityDesc = "NZR_OPPORTUNITIES_BY_SHARED_FROM_VARIANCE_SAMPLE_VALIDITY_DESC",
  NzrOpportunityEmailsByMemberIdAverageAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdAverageAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdAverageBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_BODY_ASC",
  NzrOpportunityEmailsByMemberIdAverageBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_BODY_DESC",
  NzrOpportunityEmailsByMemberIdAverageCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdAverageCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdAverageFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_FROM_ASC",
  NzrOpportunityEmailsByMemberIdAverageFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_FROM_DESC",
  NzrOpportunityEmailsByMemberIdAverageGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdAverageGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdAverageHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdAverageHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdAverageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdAverageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdAverageIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdAverageIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdAverageIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdAverageIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdAverageLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdAverageLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdAverageMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdAverageMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdAverageMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdAverageMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdAverageParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdAverageParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdAverageSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdAverageSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdAverageSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdAverageSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdAverageStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdAverageStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdAverageSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdAverageSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdAverageThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdAverageThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdAverageToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_TO_ASC",
  NzrOpportunityEmailsByMemberIdAverageToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_AVERAGE_TO_DESC",
  NzrOpportunityEmailsByMemberIdCountAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_COUNT_ASC",
  NzrOpportunityEmailsByMemberIdCountDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_COUNT_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_BODY_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_BODY_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_FROM_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_FROM_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdDistinctCountToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_TO_ASC",
  NzrOpportunityEmailsByMemberIdDistinctCountToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_DISTINCT_COUNT_TO_DESC",
  NzrOpportunityEmailsByMemberIdMaxAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdMaxAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdMaxBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_BODY_ASC",
  NzrOpportunityEmailsByMemberIdMaxBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_BODY_DESC",
  NzrOpportunityEmailsByMemberIdMaxCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdMaxCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdMaxFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_FROM_ASC",
  NzrOpportunityEmailsByMemberIdMaxFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_FROM_DESC",
  NzrOpportunityEmailsByMemberIdMaxGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdMaxGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdMaxHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdMaxHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdMaxIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_ID_ASC",
  NzrOpportunityEmailsByMemberIdMaxIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_ID_DESC",
  NzrOpportunityEmailsByMemberIdMaxIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdMaxIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdMaxIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdMaxIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdMaxLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdMaxLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdMaxMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdMaxMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdMaxMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdMaxMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdMaxParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdMaxParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdMaxSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdMaxSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdMaxSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdMaxSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdMaxStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdMaxStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdMaxSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdMaxSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdMaxThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdMaxThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdMaxToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_TO_ASC",
  NzrOpportunityEmailsByMemberIdMaxToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MAX_TO_DESC",
  NzrOpportunityEmailsByMemberIdMinAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdMinAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdMinBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_BODY_ASC",
  NzrOpportunityEmailsByMemberIdMinBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_BODY_DESC",
  NzrOpportunityEmailsByMemberIdMinCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdMinCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdMinFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_FROM_ASC",
  NzrOpportunityEmailsByMemberIdMinFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_FROM_DESC",
  NzrOpportunityEmailsByMemberIdMinGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdMinGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdMinHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdMinHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdMinIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_ID_ASC",
  NzrOpportunityEmailsByMemberIdMinIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_ID_DESC",
  NzrOpportunityEmailsByMemberIdMinIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdMinIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdMinIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdMinIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdMinLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdMinLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdMinMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdMinMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdMinMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdMinMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdMinParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdMinParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdMinSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdMinSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdMinSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdMinSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdMinStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdMinStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdMinSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdMinSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdMinThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdMinThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdMinToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_TO_ASC",
  NzrOpportunityEmailsByMemberIdMinToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_MIN_TO_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_BODY_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_BODY_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_FROM_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_FROM_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevPopulationToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_TO_ASC",
  NzrOpportunityEmailsByMemberIdStddevPopulationToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_POPULATION_TO_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_BODY_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_BODY_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_FROM_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_FROM_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdStddevSampleToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_TO_ASC",
  NzrOpportunityEmailsByMemberIdStddevSampleToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_STDDEV_SAMPLE_TO_DESC",
  NzrOpportunityEmailsByMemberIdSumAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdSumAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdSumBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_BODY_ASC",
  NzrOpportunityEmailsByMemberIdSumBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_BODY_DESC",
  NzrOpportunityEmailsByMemberIdSumCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdSumCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdSumFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_FROM_ASC",
  NzrOpportunityEmailsByMemberIdSumFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_FROM_DESC",
  NzrOpportunityEmailsByMemberIdSumGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdSumGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdSumHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdSumHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdSumIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_ID_ASC",
  NzrOpportunityEmailsByMemberIdSumIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_ID_DESC",
  NzrOpportunityEmailsByMemberIdSumIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdSumIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdSumIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdSumIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdSumLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdSumLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdSumMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdSumMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdSumMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdSumMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdSumParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdSumParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdSumSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdSumSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdSumSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdSumSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdSumStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdSumStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdSumSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdSumSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdSumThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdSumThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdSumToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_TO_ASC",
  NzrOpportunityEmailsByMemberIdSumToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_SUM_TO_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_BODY_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_BODY_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_FROM_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_FROM_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdVariancePopulationToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_TO_ASC",
  NzrOpportunityEmailsByMemberIdVariancePopulationToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_POPULATION_TO_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleAttachmentsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_ATTACHMENTS_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleAttachmentsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_ATTACHMENTS_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleBodyAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_BODY_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleBodyDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_BODY_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleCreatedAtAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleCreatedAtDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleFromAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_FROM_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleFromDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_FROM_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleGoogleMessageIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_GOOGLE_MESSAGE_ID_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleGoogleMessageIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_GOOGLE_MESSAGE_ID_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleHistoryIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_HISTORY_ID_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleHistoryIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_HISTORY_ID_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIsCompletedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_COMPLETED_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIsCompletedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_COMPLETED_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIsParsedAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_PARSED_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleIsParsedDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_IS_PARSED_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleLabelsAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_LABELS_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleLabelsDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_LABELS_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleMemberIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleMemberIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleMetadataAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_METADATA_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleMetadataDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_METADATA_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleParserAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_PARSER_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleParserDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_PARSER_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSenderEmailAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SENDER_EMAIL_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSenderEmailDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SENDER_EMAIL_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSourceAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SOURCE_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSourceDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SOURCE_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleStatusAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleStatusDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSubjectAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SUBJECT_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleSubjectDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_SUBJECT_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleThreadIdAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_THREAD_ID_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleThreadIdDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_THREAD_ID_DESC",
  NzrOpportunityEmailsByMemberIdVarianceSampleToAsc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_TO_ASC",
  NzrOpportunityEmailsByMemberIdVarianceSampleToDesc = "NZR_OPPORTUNITY_EMAILS_BY_MEMBER_ID_VARIANCE_SAMPLE_TO_DESC",
  NzrSentimentFormsByCreatedByIdAverageAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdAverageAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdAverageCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdAverageCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdAverageCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdAverageCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdAverageCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdAverageCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdAverageCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdAverageCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdAverageCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdAverageCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdAverageDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdAverageDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdAverageExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdAverageExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdAverageFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdAverageFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdAverageFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdAverageFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdAverageFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdAverageFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdAverageIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_ID_ASC",
  NzrSentimentFormsByCreatedByIdAverageIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_ID_DESC",
  NzrSentimentFormsByCreatedByIdAverageMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdAverageMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdAverageResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdAverageResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdAverageSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdAverageSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdAverageStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdAverageStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdAverageTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdAverageTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdAverageTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdAverageTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdAverageTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdAverageTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_AVERAGE_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdCountAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_COUNT_ASC",
  NzrSentimentFormsByCreatedByIdCountDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_COUNT_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_ID_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_ID_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdDistinctCountTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdDistinctCountTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_DISTINCT_COUNT_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdMaxAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdMaxAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdMaxCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdMaxCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdMaxCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdMaxCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdMaxCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdMaxCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdMaxCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdMaxCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdMaxCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdMaxCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdMaxDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdMaxDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdMaxExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdMaxExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdMaxFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdMaxFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdMaxFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdMaxFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdMaxFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdMaxFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdMaxIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_ID_ASC",
  NzrSentimentFormsByCreatedByIdMaxIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_ID_DESC",
  NzrSentimentFormsByCreatedByIdMaxMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdMaxMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdMaxResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdMaxResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdMaxSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdMaxSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdMaxStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdMaxStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdMaxTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdMaxTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdMaxTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdMaxTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdMaxTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdMaxTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MAX_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdMinAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdMinAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdMinCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdMinCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdMinCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdMinCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdMinCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdMinCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdMinCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdMinCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdMinCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdMinCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdMinDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdMinDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdMinExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdMinExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdMinFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdMinFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdMinFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdMinFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdMinFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdMinFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdMinIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_ID_ASC",
  NzrSentimentFormsByCreatedByIdMinIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_ID_DESC",
  NzrSentimentFormsByCreatedByIdMinMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdMinMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdMinResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdMinResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdMinSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdMinSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdMinStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdMinStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdMinTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdMinTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdMinTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdMinTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdMinTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdMinTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_MIN_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdStddevPopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_POPULATION_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdStddevSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdStddevSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdSumAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdSumAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdSumCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdSumCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdSumCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdSumCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdSumCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdSumCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdSumCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdSumCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdSumCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdSumCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdSumDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdSumDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdSumExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdSumExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdSumFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdSumFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdSumFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdSumFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdSumFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdSumFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdSumIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_ID_ASC",
  NzrSentimentFormsByCreatedByIdSumIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_ID_DESC",
  NzrSentimentFormsByCreatedByIdSumMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdSumMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdSumResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdSumResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdSumSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdSumSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdSumStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdSumStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdSumTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdSumTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdSumTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdSumTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdSumTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdSumTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_SUM_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ID_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ID_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdVariancePopulationTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TYPE_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleAnalyticsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ANALYTICS_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleAnalyticsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ANALYTICS_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCategoryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CATEGORY_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCategoryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CATEGORY_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCompanyIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCompanyIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCompanyNameAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCompanyNameDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCreatedAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCreatedAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCreatedByIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleCreatedByIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleDeletedAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_DELETED_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleDeletedDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_DELETED_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleExpiresAtAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_EXPIRES_AT_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleExpiresAtDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_EXPIRES_AT_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFieldsAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FIELDS_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFieldsDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FIELDS_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFormStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FORM_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFormStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FORM_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFounderIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FOUNDER_ID_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleFounderIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_FOUNDER_ID_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleMetadataAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_METADATA_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleMetadataDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_METADATA_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleResponsesAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_RESPONSES_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleResponsesDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_RESPONSES_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleSentimentSummaryAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleSentimentSummaryDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SENTIMENT_SUMMARY_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleStatusAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_STATUS_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleStatusDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_STATUS_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTitleAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TITLE_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTitleDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TITLE_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTrelloIdAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTrelloIdDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTypeAsc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TYPE_ASC",
  NzrSentimentFormsByCreatedByIdVarianceSampleTypeDesc = "NZR_SENTIMENT_FORMS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TYPE_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdAverageStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdAverageStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_AVERAGE_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdCountAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_COUNT_ASC",
  NzrSentimentFormAssigneesByMemberIdCountDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_COUNT_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdDistinctCountStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_DISTINCT_COUNT_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMaxStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdMaxStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MAX_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdMinActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdMinActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdMinAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdMinAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdMinAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdMinAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdMinCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdMinCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdMinIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMinIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMinMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMinMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMinNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdMinNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdMinStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdMinStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_MIN_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevPopulationStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_POPULATION_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdStddevSampleStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_STDDEV_SAMPLE_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdSumActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdSumActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdSumAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdSumAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdSumAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdSumAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdSumCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdSumCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdSumIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdSumIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdSumMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdSumMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdSumNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdSumNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdSumStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdSumStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_SUM_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdVariancePopulationStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_POPULATION_STATE_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ANON_USER_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ANON_USER_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPLETED_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_COMPLETED_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_STATE_ASC",
  NzrSentimentFormAssigneesByMemberIdVarianceSampleStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_BY_MEMBER_ID_VARIANCE_SAMPLE_STATE_DESC",
  NzrUserCompanyTagsByUserIdAverageCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdAverageCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdAverageIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_ID_ASC",
  NzrUserCompanyTagsByUserIdAverageIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_ID_DESC",
  NzrUserCompanyTagsByUserIdAverageTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_TAGS_ASC",
  NzrUserCompanyTagsByUserIdAverageTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_TAGS_DESC",
  NzrUserCompanyTagsByUserIdAverageUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdAverageUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_AVERAGE_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdCountAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_COUNT_ASC",
  NzrUserCompanyTagsByUserIdCountDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_COUNT_DESC",
  NzrUserCompanyTagsByUserIdDistinctCountCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdDistinctCountCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdDistinctCountIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_ID_ASC",
  NzrUserCompanyTagsByUserIdDistinctCountIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_ID_DESC",
  NzrUserCompanyTagsByUserIdDistinctCountTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_TAGS_ASC",
  NzrUserCompanyTagsByUserIdDistinctCountTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_TAGS_DESC",
  NzrUserCompanyTagsByUserIdDistinctCountUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdDistinctCountUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_DISTINCT_COUNT_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdMaxCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdMaxCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdMaxIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_ID_ASC",
  NzrUserCompanyTagsByUserIdMaxIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_ID_DESC",
  NzrUserCompanyTagsByUserIdMaxTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_TAGS_ASC",
  NzrUserCompanyTagsByUserIdMaxTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_TAGS_DESC",
  NzrUserCompanyTagsByUserIdMaxUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdMaxUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MAX_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdMinCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdMinCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdMinIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_ID_ASC",
  NzrUserCompanyTagsByUserIdMinIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_ID_DESC",
  NzrUserCompanyTagsByUserIdMinTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_TAGS_ASC",
  NzrUserCompanyTagsByUserIdMinTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_TAGS_DESC",
  NzrUserCompanyTagsByUserIdMinUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdMinUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_MIN_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevPopulationCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevPopulationCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevPopulationIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevPopulationIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevPopulationTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_TAGS_ASC",
  NzrUserCompanyTagsByUserIdStddevPopulationTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_TAGS_DESC",
  NzrUserCompanyTagsByUserIdStddevPopulationUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevPopulationUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_POPULATION_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevSampleCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevSampleCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevSampleIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevSampleIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_ID_DESC",
  NzrUserCompanyTagsByUserIdStddevSampleTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_TAGS_ASC",
  NzrUserCompanyTagsByUserIdStddevSampleTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_TAGS_DESC",
  NzrUserCompanyTagsByUserIdStddevSampleUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdStddevSampleUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdSumCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdSumCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdSumIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_ID_ASC",
  NzrUserCompanyTagsByUserIdSumIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_ID_DESC",
  NzrUserCompanyTagsByUserIdSumTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_TAGS_ASC",
  NzrUserCompanyTagsByUserIdSumTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_TAGS_DESC",
  NzrUserCompanyTagsByUserIdSumUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdSumUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_SUM_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdVariancePopulationCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdVariancePopulationCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdVariancePopulationIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_ID_ASC",
  NzrUserCompanyTagsByUserIdVariancePopulationIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_ID_DESC",
  NzrUserCompanyTagsByUserIdVariancePopulationTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_TAGS_ASC",
  NzrUserCompanyTagsByUserIdVariancePopulationTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_TAGS_DESC",
  NzrUserCompanyTagsByUserIdVariancePopulationUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdVariancePopulationUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_DESC",
  NzrUserCompanyTagsByUserIdVarianceSampleCompanyIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  NzrUserCompanyTagsByUserIdVarianceSampleCompanyIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  NzrUserCompanyTagsByUserIdVarianceSampleIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrUserCompanyTagsByUserIdVarianceSampleIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrUserCompanyTagsByUserIdVarianceSampleTagsAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_TAGS_ASC",
  NzrUserCompanyTagsByUserIdVarianceSampleTagsDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_TAGS_DESC",
  NzrUserCompanyTagsByUserIdVarianceSampleUserIdAsc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_ASC",
  NzrUserCompanyTagsByUserIdVarianceSampleUserIdDesc = "NZR_USER_COMPANY_TAGS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SectorsAsc = "SECTORS_ASC",
  SectorsDesc = "SECTORS_DESC",
  SettingsAsc = "SETTINGS_ASC",
  SettingsDesc = "SETTINGS_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
  TrelloMemberIdAsc = "TRELLO_MEMBER_ID_ASC",
  TrelloMemberIdDesc = "TRELLO_MEMBER_ID_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  UserReportsByUserIdAverageCreatedAtAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_CREATED_AT_ASC",
  UserReportsByUserIdAverageCreatedAtDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_CREATED_AT_DESC",
  UserReportsByUserIdAverageIdAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_ID_ASC",
  UserReportsByUserIdAverageIdDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_ID_DESC",
  UserReportsByUserIdAveragePeriodAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_PERIOD_ASC",
  UserReportsByUserIdAveragePeriodDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_PERIOD_DESC",
  UserReportsByUserIdAverageReportAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_REPORT_ASC",
  UserReportsByUserIdAverageReportDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_REPORT_DESC",
  UserReportsByUserIdAverageUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_UPDATED_AT_ASC",
  UserReportsByUserIdAverageUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_UPDATED_AT_DESC",
  UserReportsByUserIdAverageUserIdAsc = "USER_REPORTS_BY_USER_ID_AVERAGE_USER_ID_ASC",
  UserReportsByUserIdAverageUserIdDesc = "USER_REPORTS_BY_USER_ID_AVERAGE_USER_ID_DESC",
  UserReportsByUserIdCountAsc = "USER_REPORTS_BY_USER_ID_COUNT_ASC",
  UserReportsByUserIdCountDesc = "USER_REPORTS_BY_USER_ID_COUNT_DESC",
  UserReportsByUserIdDistinctCountCreatedAtAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  UserReportsByUserIdDistinctCountCreatedAtDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  UserReportsByUserIdDistinctCountIdAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_ID_ASC",
  UserReportsByUserIdDistinctCountIdDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_ID_DESC",
  UserReportsByUserIdDistinctCountPeriodAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_PERIOD_ASC",
  UserReportsByUserIdDistinctCountPeriodDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_PERIOD_DESC",
  UserReportsByUserIdDistinctCountReportAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_REPORT_ASC",
  UserReportsByUserIdDistinctCountReportDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_REPORT_DESC",
  UserReportsByUserIdDistinctCountUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  UserReportsByUserIdDistinctCountUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  UserReportsByUserIdDistinctCountUserIdAsc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_USER_ID_ASC",
  UserReportsByUserIdDistinctCountUserIdDesc = "USER_REPORTS_BY_USER_ID_DISTINCT_COUNT_USER_ID_DESC",
  UserReportsByUserIdMaxCreatedAtAsc = "USER_REPORTS_BY_USER_ID_MAX_CREATED_AT_ASC",
  UserReportsByUserIdMaxCreatedAtDesc = "USER_REPORTS_BY_USER_ID_MAX_CREATED_AT_DESC",
  UserReportsByUserIdMaxIdAsc = "USER_REPORTS_BY_USER_ID_MAX_ID_ASC",
  UserReportsByUserIdMaxIdDesc = "USER_REPORTS_BY_USER_ID_MAX_ID_DESC",
  UserReportsByUserIdMaxPeriodAsc = "USER_REPORTS_BY_USER_ID_MAX_PERIOD_ASC",
  UserReportsByUserIdMaxPeriodDesc = "USER_REPORTS_BY_USER_ID_MAX_PERIOD_DESC",
  UserReportsByUserIdMaxReportAsc = "USER_REPORTS_BY_USER_ID_MAX_REPORT_ASC",
  UserReportsByUserIdMaxReportDesc = "USER_REPORTS_BY_USER_ID_MAX_REPORT_DESC",
  UserReportsByUserIdMaxUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_MAX_UPDATED_AT_ASC",
  UserReportsByUserIdMaxUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_MAX_UPDATED_AT_DESC",
  UserReportsByUserIdMaxUserIdAsc = "USER_REPORTS_BY_USER_ID_MAX_USER_ID_ASC",
  UserReportsByUserIdMaxUserIdDesc = "USER_REPORTS_BY_USER_ID_MAX_USER_ID_DESC",
  UserReportsByUserIdMinCreatedAtAsc = "USER_REPORTS_BY_USER_ID_MIN_CREATED_AT_ASC",
  UserReportsByUserIdMinCreatedAtDesc = "USER_REPORTS_BY_USER_ID_MIN_CREATED_AT_DESC",
  UserReportsByUserIdMinIdAsc = "USER_REPORTS_BY_USER_ID_MIN_ID_ASC",
  UserReportsByUserIdMinIdDesc = "USER_REPORTS_BY_USER_ID_MIN_ID_DESC",
  UserReportsByUserIdMinPeriodAsc = "USER_REPORTS_BY_USER_ID_MIN_PERIOD_ASC",
  UserReportsByUserIdMinPeriodDesc = "USER_REPORTS_BY_USER_ID_MIN_PERIOD_DESC",
  UserReportsByUserIdMinReportAsc = "USER_REPORTS_BY_USER_ID_MIN_REPORT_ASC",
  UserReportsByUserIdMinReportDesc = "USER_REPORTS_BY_USER_ID_MIN_REPORT_DESC",
  UserReportsByUserIdMinUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_MIN_UPDATED_AT_ASC",
  UserReportsByUserIdMinUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_MIN_UPDATED_AT_DESC",
  UserReportsByUserIdMinUserIdAsc = "USER_REPORTS_BY_USER_ID_MIN_USER_ID_ASC",
  UserReportsByUserIdMinUserIdDesc = "USER_REPORTS_BY_USER_ID_MIN_USER_ID_DESC",
  UserReportsByUserIdStddevPopulationCreatedAtAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  UserReportsByUserIdStddevPopulationCreatedAtDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  UserReportsByUserIdStddevPopulationIdAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_ID_ASC",
  UserReportsByUserIdStddevPopulationIdDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_ID_DESC",
  UserReportsByUserIdStddevPopulationPeriodAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_PERIOD_ASC",
  UserReportsByUserIdStddevPopulationPeriodDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_PERIOD_DESC",
  UserReportsByUserIdStddevPopulationReportAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_REPORT_ASC",
  UserReportsByUserIdStddevPopulationReportDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_REPORT_DESC",
  UserReportsByUserIdStddevPopulationUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  UserReportsByUserIdStddevPopulationUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  UserReportsByUserIdStddevPopulationUserIdAsc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_USER_ID_ASC",
  UserReportsByUserIdStddevPopulationUserIdDesc = "USER_REPORTS_BY_USER_ID_STDDEV_POPULATION_USER_ID_DESC",
  UserReportsByUserIdStddevSampleCreatedAtAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  UserReportsByUserIdStddevSampleCreatedAtDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  UserReportsByUserIdStddevSampleIdAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_ID_ASC",
  UserReportsByUserIdStddevSampleIdDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_ID_DESC",
  UserReportsByUserIdStddevSamplePeriodAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_PERIOD_ASC",
  UserReportsByUserIdStddevSamplePeriodDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_PERIOD_DESC",
  UserReportsByUserIdStddevSampleReportAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_REPORT_ASC",
  UserReportsByUserIdStddevSampleReportDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_REPORT_DESC",
  UserReportsByUserIdStddevSampleUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  UserReportsByUserIdStddevSampleUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  UserReportsByUserIdStddevSampleUserIdAsc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_ASC",
  UserReportsByUserIdStddevSampleUserIdDesc = "USER_REPORTS_BY_USER_ID_STDDEV_SAMPLE_USER_ID_DESC",
  UserReportsByUserIdSumCreatedAtAsc = "USER_REPORTS_BY_USER_ID_SUM_CREATED_AT_ASC",
  UserReportsByUserIdSumCreatedAtDesc = "USER_REPORTS_BY_USER_ID_SUM_CREATED_AT_DESC",
  UserReportsByUserIdSumIdAsc = "USER_REPORTS_BY_USER_ID_SUM_ID_ASC",
  UserReportsByUserIdSumIdDesc = "USER_REPORTS_BY_USER_ID_SUM_ID_DESC",
  UserReportsByUserIdSumPeriodAsc = "USER_REPORTS_BY_USER_ID_SUM_PERIOD_ASC",
  UserReportsByUserIdSumPeriodDesc = "USER_REPORTS_BY_USER_ID_SUM_PERIOD_DESC",
  UserReportsByUserIdSumReportAsc = "USER_REPORTS_BY_USER_ID_SUM_REPORT_ASC",
  UserReportsByUserIdSumReportDesc = "USER_REPORTS_BY_USER_ID_SUM_REPORT_DESC",
  UserReportsByUserIdSumUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_SUM_UPDATED_AT_ASC",
  UserReportsByUserIdSumUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_SUM_UPDATED_AT_DESC",
  UserReportsByUserIdSumUserIdAsc = "USER_REPORTS_BY_USER_ID_SUM_USER_ID_ASC",
  UserReportsByUserIdSumUserIdDesc = "USER_REPORTS_BY_USER_ID_SUM_USER_ID_DESC",
  UserReportsByUserIdVariancePopulationCreatedAtAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  UserReportsByUserIdVariancePopulationCreatedAtDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  UserReportsByUserIdVariancePopulationIdAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_ID_ASC",
  UserReportsByUserIdVariancePopulationIdDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_ID_DESC",
  UserReportsByUserIdVariancePopulationPeriodAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_PERIOD_ASC",
  UserReportsByUserIdVariancePopulationPeriodDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_PERIOD_DESC",
  UserReportsByUserIdVariancePopulationReportAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_REPORT_ASC",
  UserReportsByUserIdVariancePopulationReportDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_REPORT_DESC",
  UserReportsByUserIdVariancePopulationUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  UserReportsByUserIdVariancePopulationUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  UserReportsByUserIdVariancePopulationUserIdAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_ASC",
  UserReportsByUserIdVariancePopulationUserIdDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_POPULATION_USER_ID_DESC",
  UserReportsByUserIdVarianceSampleCreatedAtAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  UserReportsByUserIdVarianceSampleCreatedAtDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  UserReportsByUserIdVarianceSampleIdAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_ID_ASC",
  UserReportsByUserIdVarianceSampleIdDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_ID_DESC",
  UserReportsByUserIdVarianceSamplePeriodAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_PERIOD_ASC",
  UserReportsByUserIdVarianceSamplePeriodDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_PERIOD_DESC",
  UserReportsByUserIdVarianceSampleReportAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_REPORT_ASC",
  UserReportsByUserIdVarianceSampleReportDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_REPORT_DESC",
  UserReportsByUserIdVarianceSampleUpdatedAtAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  UserReportsByUserIdVarianceSampleUpdatedAtDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UserReportsByUserIdVarianceSampleUserIdAsc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_ASC",
  UserReportsByUserIdVarianceSampleUserIdDesc = "USER_REPORTS_BY_USER_ID_VARIANCE_SAMPLE_USER_ID_DESC",
}

export type LgUserFeedback = Node & {
  __typename?: "LgUserFeedback";
  createdBy?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  feedback?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  lastModifiedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `LgUserFeedback`. */
  lgMemberByCreatedBy?: Maybe<LgMember>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
};

export type LgUserFeedbackAggregates = {
  __typename?: "LgUserFeedbackAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LgUserFeedbackDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `LgUserFeedback` object types. */
export type LgUserFeedbackAggregatesFilter = {
  /** Distinct count aggregate over matching `LgUserFeedback` objects. */
  distinctCount?: InputMaybe<LgUserFeedbackDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LgUserFeedback` object to be included within the aggregate. */
  filter?: InputMaybe<LgUserFeedbackFilter>;
};

/**
 * A condition to be used against `LgUserFeedback` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LgUserFeedbackCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `feedback` field. */
  feedback?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LgUserFeedbackDistinctCountAggregateFilter = {
  createdBy?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  feedback?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastModifiedUtc?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
};

export type LgUserFeedbackDistinctCountAggregates = {
  __typename?: "LgUserFeedbackDistinctCountAggregates";
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of feedback across the matching connection */
  feedback?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastModifiedUtc across the matching connection */
  lastModifiedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `LgUserFeedback` object types. All fields are combined with a logical ‘and.’ */
export type LgUserFeedbackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LgUserFeedbackFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedback` field. */
  feedback?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgMemberByCreatedBy` relation. */
  lgMemberByCreatedBy?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberByCreatedBy` exists. */
  lgMemberByCreatedByExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<LgUserFeedbackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LgUserFeedbackFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LgUserFeedback` for usage during aggregation. */
export enum LgUserFeedbackGroupBy {
  CreatedBy = "CREATED_BY",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Feedback = "FEEDBACK",
  LastModifiedUtc = "LAST_MODIFIED_UTC",
  LastModifiedUtcTruncatedToDay = "LAST_MODIFIED_UTC_TRUNCATED_TO_DAY",
  LastModifiedUtcTruncatedToHour = "LAST_MODIFIED_UTC_TRUNCATED_TO_HOUR",
  Status = "STATUS",
}

export type LgUserFeedbackHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LgUserFeedback` aggregates. */
export type LgUserFeedbackHavingInput = {
  AND?: InputMaybe<Array<LgUserFeedbackHavingInput>>;
  OR?: InputMaybe<Array<LgUserFeedbackHavingInput>>;
  average?: InputMaybe<LgUserFeedbackHavingAverageInput>;
  distinctCount?: InputMaybe<LgUserFeedbackHavingDistinctCountInput>;
  max?: InputMaybe<LgUserFeedbackHavingMaxInput>;
  min?: InputMaybe<LgUserFeedbackHavingMinInput>;
  stddevPopulation?: InputMaybe<LgUserFeedbackHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LgUserFeedbackHavingStddevSampleInput>;
  sum?: InputMaybe<LgUserFeedbackHavingSumInput>;
  variancePopulation?: InputMaybe<LgUserFeedbackHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LgUserFeedbackHavingVarianceSampleInput>;
};

export type LgUserFeedbackHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type LgUserFeedbackHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgUserFeedback` values. */
export type LgUserFeedbacksConnection = {
  __typename?: "LgUserFeedbacksConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgUserFeedbackAggregates>;
  /** A list of edges which contains the `LgUserFeedback` and cursor to aid in pagination. */
  edges: Array<LgUserFeedbacksEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgUserFeedbackAggregates>>;
  /** A list of `LgUserFeedback` objects. */
  nodes: Array<LgUserFeedback>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgUserFeedback` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgUserFeedback` values. */
export type LgUserFeedbacksConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgUserFeedbackGroupBy>;
  having?: InputMaybe<LgUserFeedbackHavingInput>;
};

/** A `LgUserFeedback` edge in the connection. */
export type LgUserFeedbacksEdge = {
  __typename?: "LgUserFeedbacksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgUserFeedback` at the end of the edge. */
  node: LgUserFeedback;
};

/** Methods to use when ordering `LgUserFeedback`. */
export enum LgUserFeedbacksOrderBy {
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  FeedbackAsc = "FEEDBACK_ASC",
  FeedbackDesc = "FEEDBACK_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastModifiedUtcAsc = "LAST_MODIFIED_UTC_ASC",
  LastModifiedUtcDesc = "LAST_MODIFIED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
}

export type ListenPayload = {
  __typename?: "ListenPayload";
  /** Our root query field type. Allows us to run any query from our subscription payload. */
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars["ID"]["output"]>;
};

export type ManualOpportunityInput = {
  about?: InputMaybe<Scalars["String"]["input"]>;
  assignees: Array<InputMaybe<Scalars["ID"]["input"]>>;
  autoAccept?: InputMaybe<Scalars["String"]["input"]>;
  ballInControl?: InputMaybe<Scalars["Boolean"]["input"]>;
  canIntro?: InputMaybe<Scalars["Boolean"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  dealroomCompany?: InputMaybe<Scalars["String"]["input"]>;
  founders?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  highPriority?: InputMaybe<Scalars["Boolean"]["input"]>;
  industries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  investmentType?: InputMaybe<Scalars["String"]["input"]>;
  isActivelyFundraising?: InputMaybe<Scalars["Boolean"]["input"]>;
  lgCompany?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  noteToAssignees?: InputMaybe<Scalars["String"]["input"]>;
  round?: InputMaybe<Scalars["String"]["input"]>;
  sharingAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  targetFund?: InputMaybe<Scalars["String"]["input"]>;
  websiteUrl: Scalars["String"]["input"];
};

/** A connection to a list of `MnEntry` values. */
export type MnEntriesConnection = {
  __typename?: "MnEntriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MnEntryAggregates>;
  /** A list of edges which contains the `MnEntry` and cursor to aid in pagination. */
  edges: Array<MnEntriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MnEntryAggregates>>;
  /** A list of `MnEntry` objects. */
  nodes: Array<MnEntry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MnEntry` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MnEntry` values. */
export type MnEntriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MnEntryGroupBy>;
  having?: InputMaybe<MnEntryHavingInput>;
};

/** A `MnEntry` edge in the connection. */
export type MnEntriesEdge = {
  __typename?: "MnEntriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MnEntry` at the end of the edge. */
  node: MnEntry;
};

/** Methods to use when ordering `MnEntry`. */
export enum MnEntriesOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DataAsc = "DATA_ASC",
  DataDesc = "DATA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  MemberIdAsc = "MEMBER_ID_ASC",
  MemberIdDesc = "MEMBER_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TableNameAsc = "TABLE_NAME_ASC",
  TableNameDesc = "TABLE_NAME_DESC",
}

export type MnEntry = Node & {
  __typename?: "MnEntry";
  createdUtc: Scalars["Datetime"]["output"];
  data: Scalars["JSON"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgMember` that is related to this `MnEntry`. */
  member?: Maybe<LgMember>;
  memberId?: Maybe<Scalars["UUID"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads through a `SrcCompany`. */
  srcCompany?: Maybe<SrcCompany>;
  tableName?: Maybe<Scalars["String"]["output"]>;
};

export type MnEntrySrcCompanyArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type MnEntryAggregates = {
  __typename?: "MnEntryAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MnEntryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MnEntryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MnEntryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MnEntryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MnEntryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MnEntryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MnEntrySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MnEntryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MnEntryVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `MnEntry` object types. */
export type MnEntryAggregatesFilter = {
  /** Mean average aggregate over matching `MnEntry` objects. */
  average?: InputMaybe<MnEntryAverageAggregateFilter>;
  /** Distinct count aggregate over matching `MnEntry` objects. */
  distinctCount?: InputMaybe<MnEntryDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MnEntry` object to be included within the aggregate. */
  filter?: InputMaybe<MnEntryFilter>;
  /** Maximum aggregate over matching `MnEntry` objects. */
  max?: InputMaybe<MnEntryMaxAggregateFilter>;
  /** Minimum aggregate over matching `MnEntry` objects. */
  min?: InputMaybe<MnEntryMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `MnEntry` objects. */
  stddevPopulation?: InputMaybe<MnEntryStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `MnEntry` objects. */
  stddevSample?: InputMaybe<MnEntryStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `MnEntry` objects. */
  sum?: InputMaybe<MnEntrySumAggregateFilter>;
  /** Population variance aggregate over matching `MnEntry` objects. */
  variancePopulation?: InputMaybe<MnEntryVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `MnEntry` objects. */
  varianceSample?: InputMaybe<MnEntryVarianceSampleAggregateFilter>;
};

export type MnEntryAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type MnEntryAverageAggregates = {
  __typename?: "MnEntryAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A condition to be used against `MnEntry` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MnEntryCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tableName` field. */
  tableName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MnEntryDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  data?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  tableName?: InputMaybe<BigIntFilter>;
};

export type MnEntryDistinctCountAggregates = {
  __typename?: "MnEntryDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tableName across the matching connection */
  tableName?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `MnEntry` object types. All fields are combined with a logical ‘and.’ */
export type MnEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MnEntryFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `data` field. */
  data?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<LgMemberFilter>;
  /** A related `member` exists. */
  memberExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MnEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MnEntryFilter>>;
  /** Filter by the object’s `srcCompany` polymorphic relation. */
  srcCompany?: InputMaybe<SrcCompanyFilter>;
  /** Filter for if the object’s `srcCompany`               polymorphic relation exist. */
  srcCompanyExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `tableName` field. */
  tableName?: InputMaybe<StringFilter>;
};

/** Grouping methods for `MnEntry` for usage during aggregation. */
export enum MnEntryGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Data = "DATA",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  MemberId = "MEMBER_ID",
  TableName = "TABLE_NAME",
}

export type MnEntryHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MnEntry` aggregates. */
export type MnEntryHavingInput = {
  AND?: InputMaybe<Array<MnEntryHavingInput>>;
  OR?: InputMaybe<Array<MnEntryHavingInput>>;
  average?: InputMaybe<MnEntryHavingAverageInput>;
  distinctCount?: InputMaybe<MnEntryHavingDistinctCountInput>;
  max?: InputMaybe<MnEntryHavingMaxInput>;
  min?: InputMaybe<MnEntryHavingMinInput>;
  stddevPopulation?: InputMaybe<MnEntryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MnEntryHavingStddevSampleInput>;
  sum?: InputMaybe<MnEntryHavingSumInput>;
  variancePopulation?: InputMaybe<MnEntryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MnEntryHavingVarianceSampleInput>;
};

export type MnEntryHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type MnEntryMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type MnEntryMaxAggregates = {
  __typename?: "MnEntryMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type MnEntryMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type MnEntryMinAggregates = {
  __typename?: "MnEntryMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type MnEntryStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type MnEntryStddevPopulationAggregates = {
  __typename?: "MnEntryStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type MnEntryStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type MnEntryStddevSampleAggregates = {
  __typename?: "MnEntryStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type MnEntrySumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type MnEntrySumAggregates = {
  __typename?: "MnEntrySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type MnEntryVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type MnEntryVariancePopulationAggregates = {
  __typename?: "MnEntryVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type MnEntryVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type MnEntryVarianceSampleAggregates = {
  __typename?: "MnEntryVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type MtpBasecampFund = {
  __typename?: "MtpBasecampFund";
  activeSourcing?: Maybe<Scalars["String"]["output"]>;
  bc1?: Maybe<Scalars["String"]["output"]>;
  bcComunity?: Maybe<Scalars["String"]["output"]>;
  commitment?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  geo?: Maybe<Scalars["String"]["output"]>;
  gpFund?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  relationship?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  stage?: Maybe<Scalars["String"]["output"]>;
  unknown?: Maybe<Scalars["String"]["output"]>;
  vertical?: Maybe<Scalars["String"]["output"]>;
};

export type MtpBasecampFundAggregates = {
  __typename?: "MtpBasecampFundAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MtpBasecampFundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `MtpBasecampFund` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MtpBasecampFundCondition = {
  /** Checks for equality with the object’s `activeSourcing` field. */
  activeSourcing?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `bc1` field. */
  bc1?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `bcComunity` field. */
  bcComunity?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `commitment` field. */
  commitment?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `geo` field. */
  geo?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `gpFund` field. */
  gpFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `relationship` field. */
  relationship?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `secondary` field. */
  secondary?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `stage` field. */
  stage?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `unknown` field. */
  unknown?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `vertical` field. */
  vertical?: InputMaybe<Scalars["String"]["input"]>;
};

export type MtpBasecampFundDistinctCountAggregates = {
  __typename?: "MtpBasecampFundDistinctCountAggregates";
  /** Distinct count of activeSourcing across the matching connection */
  activeSourcing?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of bc1 across the matching connection */
  bc1?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of bcComunity across the matching connection */
  bcComunity?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of commitment across the matching connection */
  commitment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currency across the matching connection */
  currency?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of geo across the matching connection */
  geo?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of gpFund across the matching connection */
  gpFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of relationship across the matching connection */
  relationship?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of secondary across the matching connection */
  secondary?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of stage across the matching connection */
  stage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unknown across the matching connection */
  unknown?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of vertical across the matching connection */
  vertical?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `MtpBasecampFund` object types. All fields are combined with a logical ‘and.’ */
export type MtpBasecampFundFilter = {
  /** Filter by the object’s `activeSourcing` field. */
  activeSourcing?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MtpBasecampFundFilter>>;
  /** Filter by the object’s `bc1` field. */
  bc1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcComunity` field. */
  bcComunity?: InputMaybe<StringFilter>;
  /** Filter by the object’s `commitment` field. */
  commitment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<StringFilter>;
  /** Filter by the object’s `geo` field. */
  geo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gpFund` field. */
  gpFund?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MtpBasecampFundFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MtpBasecampFundFilter>>;
  /** Filter by the object’s `relationship` field. */
  relationship?: InputMaybe<StringFilter>;
  /** Filter by the object’s `secondary` field. */
  secondary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stage` field. */
  stage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unknown` field. */
  unknown?: InputMaybe<StringFilter>;
  /** Filter by the object’s `vertical` field. */
  vertical?: InputMaybe<StringFilter>;
};

/** A connection to a list of `MtpBasecampFund` values. */
export type MtpBasecampFundsConnection = {
  __typename?: "MtpBasecampFundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MtpBasecampFundAggregates>;
  /** A list of edges which contains the `MtpBasecampFund` and cursor to aid in pagination. */
  edges: Array<MtpBasecampFundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MtpBasecampFundAggregates>>;
  /** A list of `MtpBasecampFund` objects. */
  nodes: Array<MtpBasecampFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MtpBasecampFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MtpBasecampFund` values. */
export type MtpBasecampFundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MtpBasecampFundsGroupBy>;
  having?: InputMaybe<MtpBasecampFundsHavingInput>;
};

/** A `MtpBasecampFund` edge in the connection. */
export type MtpBasecampFundsEdge = {
  __typename?: "MtpBasecampFundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MtpBasecampFund` at the end of the edge. */
  node: MtpBasecampFund;
};

/** Grouping methods for `MtpBasecampFund` for usage during aggregation. */
export enum MtpBasecampFundsGroupBy {
  ActiveSourcing = "ACTIVE_SOURCING",
  Bc_1 = "BC_1",
  BcComunity = "BC_COMUNITY",
  Commitment = "COMMITMENT",
  Currency = "CURRENCY",
  Geo = "GEO",
  GpFund = "GP_FUND",
  Notes = "NOTES",
  Relationship = "RELATIONSHIP",
  Secondary = "SECONDARY",
  Stage = "STAGE",
  Unknown = "UNKNOWN",
  Vertical = "VERTICAL",
}

/** Conditions for `MtpBasecampFund` aggregates. */
export type MtpBasecampFundsHavingInput = {
  AND?: InputMaybe<Array<MtpBasecampFundsHavingInput>>;
  OR?: InputMaybe<Array<MtpBasecampFundsHavingInput>>;
};

/** Methods to use when ordering `MtpBasecampFund`. */
export enum MtpBasecampFundsOrderBy {
  ActiveSourcingAsc = "ACTIVE_SOURCING_ASC",
  ActiveSourcingDesc = "ACTIVE_SOURCING_DESC",
  Bc_1Asc = "BC_1_ASC",
  Bc_1Desc = "BC_1_DESC",
  BcComunityAsc = "BC_COMUNITY_ASC",
  BcComunityDesc = "BC_COMUNITY_DESC",
  CommitmentAsc = "COMMITMENT_ASC",
  CommitmentDesc = "COMMITMENT_DESC",
  CurrencyAsc = "CURRENCY_ASC",
  CurrencyDesc = "CURRENCY_DESC",
  GeoAsc = "GEO_ASC",
  GeoDesc = "GEO_DESC",
  GpFundAsc = "GP_FUND_ASC",
  GpFundDesc = "GP_FUND_DESC",
  Natural = "NATURAL",
  NotesAsc = "NOTES_ASC",
  NotesDesc = "NOTES_DESC",
  RelationshipAsc = "RELATIONSHIP_ASC",
  RelationshipDesc = "RELATIONSHIP_DESC",
  SecondaryAsc = "SECONDARY_ASC",
  SecondaryDesc = "SECONDARY_DESC",
  StageAsc = "STAGE_ASC",
  StageDesc = "STAGE_DESC",
  UnknownAsc = "UNKNOWN_ASC",
  UnknownDesc = "UNKNOWN_DESC",
  VerticalAsc = "VERTICAL_ASC",
  VerticalDesc = "VERTICAL_DESC",
}

export type MtpCompanyfund = Node & {
  __typename?: "MtpCompanyfund";
  breakeven?: Maybe<Scalars["Boolean"]["output"]>;
  capitalConcentration?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  companyType?: Maybe<Scalars["String"]["output"]>;
  evReturnFund?: Maybe<Scalars["String"]["output"]>;
  followOnCapital?: Maybe<Scalars["String"]["output"]>;
  freeCashFlow?: Maybe<Scalars["Boolean"]["output"]>;
  fte?: Maybe<Scalars["String"]["output"]>;
  fund?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  margin?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `LgCompany` that is related to this `MtpCompanyfund`. */
  mtpCompany?: Maybe<LgCompany>;
  mtpCompanyId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `OpsFund` that is related to this `MtpCompanyfund`. */
  mtpFund?: Maybe<OpsFund>;
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Reads a single `OpsCompany` that is related to this `MtpCompanyfund`. */
  mtpOpCompany?: Maybe<OpsCompany>;
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  personResponsible?: Maybe<Scalars["String"]["output"]>;
  revenue?: Maybe<Scalars["String"]["output"]>;
  runway?: Maybe<Scalars["String"]["output"]>;
  yoyGrowth?: Maybe<Scalars["String"]["output"]>;
};

export type MtpCompanyfundAggregates = {
  __typename?: "MtpCompanyfundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MtpCompanyfundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MtpCompanyfundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MtpCompanyfundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MtpCompanyfundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MtpCompanyfundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MtpCompanyfundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MtpCompanyfundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MtpCompanyfundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MtpCompanyfundVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `MtpCompanyfund` object types. */
export type MtpCompanyfundAggregatesFilter = {
  /** Mean average aggregate over matching `MtpCompanyfund` objects. */
  average?: InputMaybe<MtpCompanyfundAverageAggregateFilter>;
  /** Distinct count aggregate over matching `MtpCompanyfund` objects. */
  distinctCount?: InputMaybe<MtpCompanyfundDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MtpCompanyfund` object to be included within the aggregate. */
  filter?: InputMaybe<MtpCompanyfundFilter>;
  /** Maximum aggregate over matching `MtpCompanyfund` objects. */
  max?: InputMaybe<MtpCompanyfundMaxAggregateFilter>;
  /** Minimum aggregate over matching `MtpCompanyfund` objects. */
  min?: InputMaybe<MtpCompanyfundMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `MtpCompanyfund` objects. */
  stddevPopulation?: InputMaybe<MtpCompanyfundStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `MtpCompanyfund` objects. */
  stddevSample?: InputMaybe<MtpCompanyfundStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `MtpCompanyfund` objects. */
  sum?: InputMaybe<MtpCompanyfundSumAggregateFilter>;
  /** Population variance aggregate over matching `MtpCompanyfund` objects. */
  variancePopulation?: InputMaybe<MtpCompanyfundVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `MtpCompanyfund` objects. */
  varianceSample?: InputMaybe<MtpCompanyfundVarianceSampleAggregateFilter>;
};

export type MtpCompanyfundAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundAverageAggregates = {
  __typename?: "MtpCompanyfundAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `MtpCompanyfund` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MtpCompanyfundCondition = {
  /** Checks for equality with the object’s `breakeven` field. */
  breakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `capitalConcentration` field. */
  capitalConcentration?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyType` field. */
  companyType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `evReturnFund` field. */
  evReturnFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `followOnCapital` field. */
  followOnCapital?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `freeCashFlow` field. */
  freeCashFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `fte` field. */
  fte?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fund` field. */
  fund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `margin` field. */
  margin?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mtpCompanyId` field. */
  mtpCompanyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `mtpFundId` field. */
  mtpFundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `mtpOpCompanyId` field. */
  mtpOpCompanyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `navFundSize` field. */
  navFundSize?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `personResponsible` field. */
  personResponsible?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `runway` field. */
  runway?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `yoyGrowth` field. */
  yoyGrowth?: InputMaybe<Scalars["String"]["input"]>;
};

export type MtpCompanyfundDistinctCountAggregateFilter = {
  breakeven?: InputMaybe<BigIntFilter>;
  capitalConcentration?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<BigIntFilter>;
  companyType?: InputMaybe<BigIntFilter>;
  evReturnFund?: InputMaybe<BigIntFilter>;
  followOnCapital?: InputMaybe<BigIntFilter>;
  freeCashFlow?: InputMaybe<BigIntFilter>;
  fte?: InputMaybe<BigIntFilter>;
  fund?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  margin?: InputMaybe<BigIntFilter>;
  mtpCompanyId?: InputMaybe<BigIntFilter>;
  mtpFundId?: InputMaybe<BigIntFilter>;
  mtpOpCompanyId?: InputMaybe<BigIntFilter>;
  navFundSize?: InputMaybe<BigIntFilter>;
  personResponsible?: InputMaybe<BigIntFilter>;
  revenue?: InputMaybe<BigIntFilter>;
  runway?: InputMaybe<BigIntFilter>;
  yoyGrowth?: InputMaybe<BigIntFilter>;
};

export type MtpCompanyfundDistinctCountAggregates = {
  __typename?: "MtpCompanyfundDistinctCountAggregates";
  /** Distinct count of breakeven across the matching connection */
  breakeven?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of capitalConcentration across the matching connection */
  capitalConcentration?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyType across the matching connection */
  companyType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of evReturnFund across the matching connection */
  evReturnFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of followOnCapital across the matching connection */
  followOnCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of freeCashFlow across the matching connection */
  freeCashFlow?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fte across the matching connection */
  fte?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fund across the matching connection */
  fund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of margin across the matching connection */
  margin?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpCompanyId across the matching connection */
  mtpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of personResponsible across the matching connection */
  personResponsible?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revenue across the matching connection */
  revenue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runway across the matching connection */
  runway?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yoyGrowth across the matching connection */
  yoyGrowth?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `MtpCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type MtpCompanyfundFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MtpCompanyfundFilter>>;
  /** Filter by the object’s `breakeven` field. */
  breakeven?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `capitalConcentration` field. */
  capitalConcentration?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyType` field. */
  companyType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `evReturnFund` field. */
  evReturnFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followOnCapital` field. */
  followOnCapital?: InputMaybe<StringFilter>;
  /** Filter by the object’s `freeCashFlow` field. */
  freeCashFlow?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fte` field. */
  fte?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fund` field. */
  fund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `margin` field. */
  margin?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mtpCompany` relation. */
  mtpCompany?: InputMaybe<LgCompanyFilter>;
  /** A related `mtpCompany` exists. */
  mtpCompanyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `mtpCompanyId` field. */
  mtpCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `mtpFund` relation. */
  mtpFund?: InputMaybe<OpsFundFilter>;
  /** A related `mtpFund` exists. */
  mtpFundExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `mtpFundId` field. */
  mtpFundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mtpOpCompany` relation. */
  mtpOpCompany?: InputMaybe<OpsCompanyFilter>;
  /** A related `mtpOpCompany` exists. */
  mtpOpCompanyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `mtpOpCompanyId` field. */
  mtpOpCompanyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `navFundSize` field. */
  navFundSize?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MtpCompanyfundFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MtpCompanyfundFilter>>;
  /** Filter by the object’s `personResponsible` field. */
  personResponsible?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `runway` field. */
  runway?: InputMaybe<StringFilter>;
  /** Filter by the object’s `yoyGrowth` field. */
  yoyGrowth?: InputMaybe<StringFilter>;
};

export type MtpCompanyfundMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
  mtpFundId?: InputMaybe<BigIntFilter>;
  mtpOpCompanyId?: InputMaybe<BigIntFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundMaxAggregates = {
  __typename?: "MtpCompanyfundMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
  mtpFundId?: InputMaybe<BigIntFilter>;
  mtpOpCompanyId?: InputMaybe<BigIntFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundMinAggregates = {
  __typename?: "MtpCompanyfundMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundStddevPopulationAggregates = {
  __typename?: "MtpCompanyfundStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundStddevSampleAggregates = {
  __typename?: "MtpCompanyfundStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundSumAggregates = {
  __typename?: "MtpCompanyfundSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of mtpFundId across the matching connection */
  mtpFundId: Scalars["BigFloat"]["output"];
  /** Sum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId: Scalars["BigFloat"]["output"];
  /** Sum of navFundSize across the matching connection */
  navFundSize: Scalars["Float"]["output"];
};

export type MtpCompanyfundVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundVariancePopulationAggregates = {
  __typename?: "MtpCompanyfundVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
  mtpFundId?: InputMaybe<BigFloatFilter>;
  mtpOpCompanyId?: InputMaybe<BigFloatFilter>;
  navFundSize?: InputMaybe<FloatFilter>;
};

export type MtpCompanyfundVarianceSampleAggregates = {
  __typename?: "MtpCompanyfundVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `MtpCompanyfund` values. */
export type MtpCompanyfundsConnection = {
  __typename?: "MtpCompanyfundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MtpCompanyfundAggregates>;
  /** A list of edges which contains the `MtpCompanyfund` and cursor to aid in pagination. */
  edges: Array<MtpCompanyfundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MtpCompanyfundAggregates>>;
  /** A list of `MtpCompanyfund` objects. */
  nodes: Array<MtpCompanyfund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MtpCompanyfund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MtpCompanyfund` values. */
export type MtpCompanyfundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MtpCompanyfundsGroupBy>;
  having?: InputMaybe<MtpCompanyfundsHavingInput>;
};

/** A connection to a list of `MtpCompanyfundsCpy` values. */
export type MtpCompanyfundsCpiesConnection = {
  __typename?: "MtpCompanyfundsCpiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MtpCompanyfundsCpyAggregates>;
  /** A list of edges which contains the `MtpCompanyfundsCpy` and cursor to aid in pagination. */
  edges: Array<MtpCompanyfundsCpiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MtpCompanyfundsCpyAggregates>>;
  /** A list of `MtpCompanyfundsCpy` objects. */
  nodes: Array<MtpCompanyfundsCpy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MtpCompanyfundsCpy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MtpCompanyfundsCpy` values. */
export type MtpCompanyfundsCpiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MtpCompanyfundsCpyGroupBy>;
  having?: InputMaybe<MtpCompanyfundsCpyHavingInput>;
};

/** A `MtpCompanyfundsCpy` edge in the connection. */
export type MtpCompanyfundsCpiesEdge = {
  __typename?: "MtpCompanyfundsCpiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MtpCompanyfundsCpy` at the end of the edge. */
  node: MtpCompanyfundsCpy;
};

/** Methods to use when ordering `MtpCompanyfundsCpy`. */
export enum MtpCompanyfundsCpiesOrderBy {
  BreakevenAsc = "BREAKEVEN_ASC",
  BreakevenDesc = "BREAKEVEN_DESC",
  CapitalConcentrationAsc = "CAPITAL_CONCENTRATION_ASC",
  CapitalConcentrationDesc = "CAPITAL_CONCENTRATION_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CompanyTypeAsc = "COMPANY_TYPE_ASC",
  CompanyTypeDesc = "COMPANY_TYPE_DESC",
  EvReturnFundAsc = "EV_RETURN_FUND_ASC",
  EvReturnFundDesc = "EV_RETURN_FUND_DESC",
  FollowOnCapitalAsc = "FOLLOW_ON_CAPITAL_ASC",
  FollowOnCapitalDesc = "FOLLOW_ON_CAPITAL_DESC",
  FreeCashFlowAsc = "FREE_CASH_FLOW_ASC",
  FreeCashFlowDesc = "FREE_CASH_FLOW_DESC",
  FteAsc = "FTE_ASC",
  FteDesc = "FTE_DESC",
  FundAsc = "FUND_ASC",
  FundDesc = "FUND_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MarginAsc = "MARGIN_ASC",
  MarginDesc = "MARGIN_DESC",
  MtpCompanyIdAsc = "MTP_COMPANY_ID_ASC",
  MtpCompanyIdDesc = "MTP_COMPANY_ID_DESC",
  MtpFundIdAsc = "MTP_FUND_ID_ASC",
  MtpFundIdDesc = "MTP_FUND_ID_DESC",
  MtpOpCompanyIdAsc = "MTP_OP_COMPANY_ID_ASC",
  MtpOpCompanyIdDesc = "MTP_OP_COMPANY_ID_DESC",
  Natural = "NATURAL",
  NavFundSizeAsc = "NAV_FUND_SIZE_ASC",
  NavFundSizeDesc = "NAV_FUND_SIZE_DESC",
  PersonResponsibleAsc = "PERSON_RESPONSIBLE_ASC",
  PersonResponsibleDesc = "PERSON_RESPONSIBLE_DESC",
  RevenueAsc = "REVENUE_ASC",
  RevenueDesc = "REVENUE_DESC",
  RunwayAsc = "RUNWAY_ASC",
  RunwayDesc = "RUNWAY_DESC",
  YoyGrowthAsc = "YOY_GROWTH_ASC",
  YoyGrowthDesc = "YOY_GROWTH_DESC",
}

export type MtpCompanyfundsCpy = {
  __typename?: "MtpCompanyfundsCpy";
  breakeven?: Maybe<Scalars["Boolean"]["output"]>;
  capitalConcentration?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  companyType?: Maybe<Scalars["String"]["output"]>;
  evReturnFund?: Maybe<Scalars["String"]["output"]>;
  followOnCapital?: Maybe<Scalars["String"]["output"]>;
  freeCashFlow?: Maybe<Scalars["Boolean"]["output"]>;
  fte?: Maybe<Scalars["String"]["output"]>;
  fund?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  margin?: Maybe<Scalars["String"]["output"]>;
  mtpCompanyId?: Maybe<Scalars["UUID"]["output"]>;
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
  personResponsible?: Maybe<Scalars["String"]["output"]>;
  revenue?: Maybe<Scalars["String"]["output"]>;
  runway?: Maybe<Scalars["String"]["output"]>;
  yoyGrowth?: Maybe<Scalars["String"]["output"]>;
};

export type MtpCompanyfundsCpyAggregates = {
  __typename?: "MtpCompanyfundsCpyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MtpCompanyfundsCpyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MtpCompanyfundsCpyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MtpCompanyfundsCpyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MtpCompanyfundsCpyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MtpCompanyfundsCpyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MtpCompanyfundsCpyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MtpCompanyfundsCpySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MtpCompanyfundsCpyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MtpCompanyfundsCpyVarianceSampleAggregates>;
};

export type MtpCompanyfundsCpyAverageAggregates = {
  __typename?: "MtpCompanyfundsCpyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `MtpCompanyfundsCpy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MtpCompanyfundsCpyCondition = {
  /** Checks for equality with the object’s `breakeven` field. */
  breakeven?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `capitalConcentration` field. */
  capitalConcentration?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyType` field. */
  companyType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `evReturnFund` field. */
  evReturnFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `followOnCapital` field. */
  followOnCapital?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `freeCashFlow` field. */
  freeCashFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `fte` field. */
  fte?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fund` field. */
  fund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `margin` field. */
  margin?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mtpCompanyId` field. */
  mtpCompanyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `mtpFundId` field. */
  mtpFundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `mtpOpCompanyId` field. */
  mtpOpCompanyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `navFundSize` field. */
  navFundSize?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `personResponsible` field. */
  personResponsible?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `runway` field. */
  runway?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `yoyGrowth` field. */
  yoyGrowth?: InputMaybe<Scalars["String"]["input"]>;
};

export type MtpCompanyfundsCpyDistinctCountAggregates = {
  __typename?: "MtpCompanyfundsCpyDistinctCountAggregates";
  /** Distinct count of breakeven across the matching connection */
  breakeven?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of capitalConcentration across the matching connection */
  capitalConcentration?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyType across the matching connection */
  companyType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of evReturnFund across the matching connection */
  evReturnFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of followOnCapital across the matching connection */
  followOnCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of freeCashFlow across the matching connection */
  freeCashFlow?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fte across the matching connection */
  fte?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fund across the matching connection */
  fund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of margin across the matching connection */
  margin?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpCompanyId across the matching connection */
  mtpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of personResponsible across the matching connection */
  personResponsible?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revenue across the matching connection */
  revenue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runway across the matching connection */
  runway?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of yoyGrowth across the matching connection */
  yoyGrowth?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `MtpCompanyfundsCpy` object types. All fields are combined with a logical ‘and.’ */
export type MtpCompanyfundsCpyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MtpCompanyfundsCpyFilter>>;
  /** Filter by the object’s `breakeven` field. */
  breakeven?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `capitalConcentration` field. */
  capitalConcentration?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyType` field. */
  companyType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `evReturnFund` field. */
  evReturnFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followOnCapital` field. */
  followOnCapital?: InputMaybe<StringFilter>;
  /** Filter by the object’s `freeCashFlow` field. */
  freeCashFlow?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fte` field. */
  fte?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fund` field. */
  fund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `margin` field. */
  margin?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mtpCompanyId` field. */
  mtpCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `mtpFundId` field. */
  mtpFundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mtpOpCompanyId` field. */
  mtpOpCompanyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `navFundSize` field. */
  navFundSize?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MtpCompanyfundsCpyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MtpCompanyfundsCpyFilter>>;
  /** Filter by the object’s `personResponsible` field. */
  personResponsible?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `runway` field. */
  runway?: InputMaybe<StringFilter>;
  /** Filter by the object’s `yoyGrowth` field. */
  yoyGrowth?: InputMaybe<StringFilter>;
};

/** Grouping methods for `MtpCompanyfundsCpy` for usage during aggregation. */
export enum MtpCompanyfundsCpyGroupBy {
  Breakeven = "BREAKEVEN",
  CapitalConcentration = "CAPITAL_CONCENTRATION",
  CompanyName = "COMPANY_NAME",
  CompanyType = "COMPANY_TYPE",
  EvReturnFund = "EV_RETURN_FUND",
  FollowOnCapital = "FOLLOW_ON_CAPITAL",
  FreeCashFlow = "FREE_CASH_FLOW",
  Fte = "FTE",
  Fund = "FUND",
  Id = "ID",
  Margin = "MARGIN",
  MtpCompanyId = "MTP_COMPANY_ID",
  MtpFundId = "MTP_FUND_ID",
  MtpOpCompanyId = "MTP_OP_COMPANY_ID",
  NavFundSize = "NAV_FUND_SIZE",
  PersonResponsible = "PERSON_RESPONSIBLE",
  Revenue = "REVENUE",
  Runway = "RUNWAY",
  YoyGrowth = "YOY_GROWTH",
}

export type MtpCompanyfundsCpyHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `MtpCompanyfundsCpy` aggregates. */
export type MtpCompanyfundsCpyHavingInput = {
  AND?: InputMaybe<Array<MtpCompanyfundsCpyHavingInput>>;
  OR?: InputMaybe<Array<MtpCompanyfundsCpyHavingInput>>;
  average?: InputMaybe<MtpCompanyfundsCpyHavingAverageInput>;
  distinctCount?: InputMaybe<MtpCompanyfundsCpyHavingDistinctCountInput>;
  max?: InputMaybe<MtpCompanyfundsCpyHavingMaxInput>;
  min?: InputMaybe<MtpCompanyfundsCpyHavingMinInput>;
  stddevPopulation?: InputMaybe<MtpCompanyfundsCpyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MtpCompanyfundsCpyHavingStddevSampleInput>;
  sum?: InputMaybe<MtpCompanyfundsCpyHavingSumInput>;
  variancePopulation?: InputMaybe<MtpCompanyfundsCpyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MtpCompanyfundsCpyHavingVarianceSampleInput>;
};

export type MtpCompanyfundsCpyHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsCpyMaxAggregates = {
  __typename?: "MtpCompanyfundsCpyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundsCpyMinAggregates = {
  __typename?: "MtpCompanyfundsCpyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundsCpyStddevPopulationAggregates = {
  __typename?: "MtpCompanyfundsCpyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundsCpyStddevSampleAggregates = {
  __typename?: "MtpCompanyfundsCpyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundsCpySumAggregates = {
  __typename?: "MtpCompanyfundsCpySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
  /** Sum of mtpFundId across the matching connection */
  mtpFundId: Scalars["BigFloat"]["output"];
  /** Sum of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId: Scalars["BigFloat"]["output"];
  /** Sum of navFundSize across the matching connection */
  navFundSize: Scalars["Float"]["output"];
};

export type MtpCompanyfundsCpyVariancePopulationAggregates = {
  __typename?: "MtpCompanyfundsCpyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

export type MtpCompanyfundsCpyVarianceSampleAggregates = {
  __typename?: "MtpCompanyfundsCpyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of mtpFundId across the matching connection */
  mtpFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of mtpOpCompanyId across the matching connection */
  mtpOpCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of navFundSize across the matching connection */
  navFundSize?: Maybe<Scalars["Float"]["output"]>;
};

/** A `MtpCompanyfund` edge in the connection. */
export type MtpCompanyfundsEdge = {
  __typename?: "MtpCompanyfundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MtpCompanyfund` at the end of the edge. */
  node: MtpCompanyfund;
};

/** Grouping methods for `MtpCompanyfund` for usage during aggregation. */
export enum MtpCompanyfundsGroupBy {
  Breakeven = "BREAKEVEN",
  CapitalConcentration = "CAPITAL_CONCENTRATION",
  CompanyName = "COMPANY_NAME",
  CompanyType = "COMPANY_TYPE",
  EvReturnFund = "EV_RETURN_FUND",
  FollowOnCapital = "FOLLOW_ON_CAPITAL",
  FreeCashFlow = "FREE_CASH_FLOW",
  Fte = "FTE",
  Fund = "FUND",
  Margin = "MARGIN",
  MtpCompanyId = "MTP_COMPANY_ID",
  MtpFundId = "MTP_FUND_ID",
  MtpOpCompanyId = "MTP_OP_COMPANY_ID",
  NavFundSize = "NAV_FUND_SIZE",
  PersonResponsible = "PERSON_RESPONSIBLE",
  Revenue = "REVENUE",
  Runway = "RUNWAY",
  YoyGrowth = "YOY_GROWTH",
}

export type MtpCompanyfundsHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `MtpCompanyfund` aggregates. */
export type MtpCompanyfundsHavingInput = {
  AND?: InputMaybe<Array<MtpCompanyfundsHavingInput>>;
  OR?: InputMaybe<Array<MtpCompanyfundsHavingInput>>;
  average?: InputMaybe<MtpCompanyfundsHavingAverageInput>;
  distinctCount?: InputMaybe<MtpCompanyfundsHavingDistinctCountInput>;
  max?: InputMaybe<MtpCompanyfundsHavingMaxInput>;
  min?: InputMaybe<MtpCompanyfundsHavingMinInput>;
  stddevPopulation?: InputMaybe<MtpCompanyfundsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MtpCompanyfundsHavingStddevSampleInput>;
  sum?: InputMaybe<MtpCompanyfundsHavingSumInput>;
  variancePopulation?: InputMaybe<MtpCompanyfundsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MtpCompanyfundsHavingVarianceSampleInput>;
};

export type MtpCompanyfundsHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

export type MtpCompanyfundsHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
  mtpFundId?: InputMaybe<HavingBigintFilter>;
  mtpOpCompanyId?: InputMaybe<HavingBigintFilter>;
  navFundSize?: InputMaybe<HavingFloatFilter>;
};

/** Methods to use when ordering `MtpCompanyfund`. */
export enum MtpCompanyfundsOrderBy {
  BreakevenAsc = "BREAKEVEN_ASC",
  BreakevenDesc = "BREAKEVEN_DESC",
  CapitalConcentrationAsc = "CAPITAL_CONCENTRATION_ASC",
  CapitalConcentrationDesc = "CAPITAL_CONCENTRATION_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CompanyTypeAsc = "COMPANY_TYPE_ASC",
  CompanyTypeDesc = "COMPANY_TYPE_DESC",
  EvReturnFundAsc = "EV_RETURN_FUND_ASC",
  EvReturnFundDesc = "EV_RETURN_FUND_DESC",
  FollowOnCapitalAsc = "FOLLOW_ON_CAPITAL_ASC",
  FollowOnCapitalDesc = "FOLLOW_ON_CAPITAL_DESC",
  FreeCashFlowAsc = "FREE_CASH_FLOW_ASC",
  FreeCashFlowDesc = "FREE_CASH_FLOW_DESC",
  FteAsc = "FTE_ASC",
  FteDesc = "FTE_DESC",
  FundAsc = "FUND_ASC",
  FundDesc = "FUND_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MarginAsc = "MARGIN_ASC",
  MarginDesc = "MARGIN_DESC",
  MtpCompanyIdAsc = "MTP_COMPANY_ID_ASC",
  MtpCompanyIdDesc = "MTP_COMPANY_ID_DESC",
  MtpFundIdAsc = "MTP_FUND_ID_ASC",
  MtpFundIdDesc = "MTP_FUND_ID_DESC",
  MtpOpCompanyIdAsc = "MTP_OP_COMPANY_ID_ASC",
  MtpOpCompanyIdDesc = "MTP_OP_COMPANY_ID_DESC",
  Natural = "NATURAL",
  NavFundSizeAsc = "NAV_FUND_SIZE_ASC",
  NavFundSizeDesc = "NAV_FUND_SIZE_DESC",
  PersonResponsibleAsc = "PERSON_RESPONSIBLE_ASC",
  PersonResponsibleDesc = "PERSON_RESPONSIBLE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RevenueAsc = "REVENUE_ASC",
  RevenueDesc = "REVENUE_DESC",
  RunwayAsc = "RUNWAY_ASC",
  RunwayDesc = "RUNWAY_DESC",
  YoyGrowthAsc = "YOY_GROWTH_ASC",
  YoyGrowthDesc = "YOY_GROWTH_DESC",
}

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Add Comment
   *
   * Equivalent to POST /opportunity/comment
   */
  addCompanyComment?: Maybe<Scalars["JSON"]["output"]>;
  calculateValuation?: Maybe<CalculateValuationPayload>;
  /**
   * Company Attachment Delete
   *
   * Equivalent to DELETE /opportunity/attachment
   */
  companyAttachmentDelete?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Company Attachment Upload
   *
   * Equivalent to POST /opportunity/attachment/upload
   */
  companyAttachmentUpload?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Tag
   *
   * Equivalent to POST /opportunity/flags
   */
  companyFlags?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Delete Comment
   *
   * Equivalent to DELETE /opportunity/comment
   */
  deleteCompanyComment?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Edit Comment
   *
   * Equivalent to PATCH /opportunity/comment
   */
  editCompanyComment?: Maybe<Scalars["JSON"]["output"]>;
  importAllLinkedEntities?: Maybe<ImportAllLinkedEntitiesPayload>;
  isFuturecorn?: Maybe<IsFuturecornPayload>;
  /**
   * Auth
   *
   * Equivalent to POST /auth/google/
   */
  loginWithGoogle?: Maybe<AuthResponse>;
  /** A viewer that wraps operations for all available authentication mechanisms */
  mutationViewerAnyAuth?: Maybe<MutationViewerAnyAuth>;
  /** A viewer that wraps all operations authenticated via security scheme 'APIKeyHeader', which is of type 'apiKey' */
  mutationViewerApiKey?: Maybe<MutationViewerApiKey>;
  /**
   * Action
   *
   * Equivalent to POST /opportunity/{entity}/{action}
   */
  opportunityAction?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Email
   *
   * Equivalent to POST /opportunity/email
   */
  opportunityEmail?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * New
   *
   * Equivalent to POST /opportunity/new
   */
  opportunityNew?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Update
   *
   * Equivalent to POST /opportunity/update
   */
  opportunityUpdate?: Maybe<Scalars["JSON"]["output"]>;
  refreshCompanyFromSources?: Maybe<RefreshCompanyFromSourcesPayload>;
  /**
   * Trello Webhook
   *
   * Equivalent to POST /webhook/refreshMTP
   */
  refreshMTP?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Refresh
   *
   * Equivalent to POST /auth/refresh
   */
  refreshToken?: Maybe<AuthResponse>;
  /**
   * Check
   *
   * Equivalent to POST /sentiment/check/{form_id}
   */
  sentimentCheck?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Decline
   *
   * Equivalent to POST /sentiment/decline
   */
  sentimentDecline?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Fill
   *
   * Equivalent to POST /sentiment/fill
   */
  sentimentFill?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * New
   *
   * Equivalent to POST /sentiment/request
   */
  sentimentRequest?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Submit
   *
   * Equivalent to POST /feedback/submit
   */
  submitFeedback?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Summarise
   *
   * Equivalent to POST /sentiment/summarise
   */
  summariseSentimentSummarisePost?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Trello Webhook
   *
   * Equivalent to POST /webhook/trello
   */
  trelloWebhook?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Trello Webhook
   *
   * Equivalent to HEAD /webhook/trello
   */
  trelloWebhookWebhookTrelloHead?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Signals
   *
   * Equivalent to POST /opportunity/signals
   */
  unAssignUser?: Maybe<Scalars["JSON"]["output"]>;
  updateLinks?: Maybe<UpdateLinksPayload>;
  /** Updates a single `NzrNotification` using a unique key and a patch. */
  updateNzrNotification?: Maybe<UpdateNzrNotificationPayload>;
  /** Updates a single `NzrNotification` using its globally unique id and a patch. */
  updateNzrNotificationByNodeId?: Maybe<UpdateNzrNotificationPayload>;
  /**
   * Update Settings
   *
   * Equivalent to POST /user/settings
   */
  updateUserSettings?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Upsert Metrics Extraction
   *
   * Equivalent to POST /metrics/metrics
   */
  upsertMetricsExtraction?: Maybe<Scalars["JSON"]["output"]>;
  /**
   * Tag
   *
   * Equivalent to POST /opportunity/tags
   */
  userCompanyTags?: Maybe<Scalars["JSON"]["output"]>;
};

export type MutationAddCompanyCommentArgs = {
  commentInput: CommentInput;
};

export type MutationCalculateValuationArgs = {
  input: CalculateValuationInput;
};

export type MutationCompanyAttachmentDeleteArgs = {
  companyAttachmentDeleteInput: CompanyAttachmentDeleteInput;
};

export type MutationCompanyAttachmentUploadArgs = {
  companyAttachmentInput: CompanyAttachmentInput;
};

export type MutationCompanyFlagsArgs = {
  flagCompanyInput: FlagCompanyInput;
};

export type MutationDeleteCompanyCommentArgs = {
  commentDeleteInput: CommentDeleteInput;
};

export type MutationEditCompanyCommentArgs = {
  commentUpdateInput: CommentUpdateInput;
};

export type MutationImportAllLinkedEntitiesArgs = {
  input: ImportAllLinkedEntitiesInput;
};

export type MutationIsFuturecornArgs = {
  input: IsFuturecornInput;
};

export type MutationLoginWithGoogleArgs = {
  googleAuthCodeInput?: InputMaybe<GoogleAuthCodeInput>;
};

export type MutationMutationViewerAnyAuthArgs = {
  aPIKeyHeader?: InputMaybe<ApiKeyHeaderInput>;
};

export type MutationMutationViewerApiKeyArgs = {
  apiKey: Scalars["String"]["input"];
};

export type MutationOpportunityActionArgs = {
  action: Action;
  actionPayloadInput?: InputMaybe<ActionPayloadInput>;
  entity: Entity;
};

export type MutationOpportunityEmailArgs = {
  emailOpportunityInput: EmailOpportunityInput;
};

export type MutationOpportunityNewArgs = {
  manualOpportunityInput: ManualOpportunityInput;
};

export type MutationOpportunityUpdateArgs = {
  opportunityId: Scalars["String"]["input"];
  updateCompanyInput: UpdateCompanyInput;
};

export type MutationRefreshCompanyFromSourcesArgs = {
  input: RefreshCompanyFromSourcesInput;
};

export type MutationRefreshMtpArgs = {
  payloadInput?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationRefreshTokenArgs = {
  refreshRequestInput?: InputMaybe<RefreshRequestInput>;
};

export type MutationSentimentCheckArgs = {
  formId: Scalars["String"]["input"];
};

export type MutationSentimentDeclineArgs = {
  declineSentimentInput?: InputMaybe<DeclineSentimentInput>;
};

export type MutationSentimentFillArgs = {
  fillSentimentInput?: InputMaybe<FillSentimentInput>;
};

export type MutationSentimentRequestArgs = {
  newSentimentInput?: InputMaybe<NewSentimentInput>;
};

export type MutationSubmitFeedbackArgs = {
  userFeedbackInput: UserFeedbackInput;
};

export type MutationSummariseSentimentSummarisePostArgs = {
  summariseSentimentInput: SummariseSentimentInput;
};

export type MutationTrelloWebhookArgs = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  payloadInput?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationTrelloWebhookWebhookTrelloHeadArgs = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  payloadInput?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationUnAssignUserArgs = {
  unAssignPayloadInput: UnAssignPayloadInput;
};

export type MutationUpdateLinksArgs = {
  input: UpdateLinksInput;
};

export type MutationUpdateNzrNotificationArgs = {
  input: UpdateNzrNotificationInput;
};

export type MutationUpdateNzrNotificationByNodeIdArgs = {
  input: UpdateNzrNotificationByNodeIdInput;
};

export type MutationUpdateUserSettingsArgs = {
  userSettingsPayloadInput?: InputMaybe<UserSettingsPayloadInput>;
};

export type MutationUpsertMetricsExtractionArgs = {
  lgCompanyMetricsInput?: InputMaybe<LgCompanyMetricsInput>;
};

export type MutationUserCompanyTagsArgs = {
  tagCompanyInput: TagCompanyInput;
};

/** Warning: Not every request will work with this viewer type */
export type MutationViewerAnyAuth = {
  __typename?: "MutationViewerAnyAuth";
  /**
   * Update File Status
   *
   * Equivalent to PATCH /webhook/on-file-upload
   */
  updateFileStatusWebhookOnFileUploadPatch?: Maybe<Scalars["JSON"]["output"]>;
};

/** Warning: Not every request will work with this viewer type */
export type MutationViewerAnyAuthUpdateFileStatusWebhookOnFileUploadPatchArgs = {
  onFileUploadRequestInput: OnFileUploadRequestInput;
};

/** A viewer for security scheme 'APIKeyHeader' */
export type MutationViewerApiKey = {
  __typename?: "MutationViewerApiKey";
  /**
   * Update File Status
   *
   * Equivalent to PATCH /webhook/on-file-upload
   */
  updateFileStatusWebhookOnFileUploadPatch?: Maybe<Scalars["JSON"]["output"]>;
};

/** A viewer for security scheme 'APIKeyHeader' */
export type MutationViewerApiKeyUpdateFileStatusWebhookOnFileUploadPatchArgs = {
  onFileUploadRequestInput: OnFileUploadRequestInput;
};

export type NewSentimentInput = {
  assignees?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category: Scalars["String"]["input"];
  company: Scalars["JSON"]["input"];
  founder?: InputMaybe<Scalars["JSON"]["input"]>;
  meetingDate: Scalars["String"]["input"];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type NzrNotification = Node & {
  __typename?: "NzrNotification";
  assignee: Scalars["UUID"]["output"];
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["UUID"]["output"];
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `NzrNotification`. */
  lgMemberByAssignee?: Maybe<LgMember>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads through a `NzrOpportunityEmail`. */
  nzrOpportunityEmailAsReference?: Maybe<NzrOpportunityEmail>;
  /** Reads through a `NzrSentimentForm`. */
  nzrSentimentFormAsReference?: Maybe<NzrSentimentForm>;
  payload: Scalars["JSON"]["output"];
  referenceId?: Maybe<Scalars["String"]["output"]>;
  referenceType?: Maybe<NzrNotificationReferenceType>;
  status: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  validity?: Maybe<Scalars["Datetime"]["output"]>;
};

export type NzrNotificationAggregates = {
  __typename?: "NzrNotificationAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrNotificationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrNotification` object types. */
export type NzrNotificationAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrNotification` objects. */
  distinctCount?: InputMaybe<NzrNotificationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrNotification` object to be included within the aggregate. */
  filter?: InputMaybe<NzrNotificationFilter>;
};

/**
 * A condition to be used against `NzrNotification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NzrNotificationCondition = {
  /** Checks for equality with the object’s `assignee` field. */
  assignee?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `referenceId` field. */
  referenceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `referenceType` field. */
  referenceType?: InputMaybe<NzrNotificationReferenceType>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validity` field. */
  validity?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type NzrNotificationDistinctCountAggregateFilter = {
  assignee?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  payload?: InputMaybe<BigIntFilter>;
  referenceId?: InputMaybe<BigIntFilter>;
  referenceType?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
  validity?: InputMaybe<BigIntFilter>;
};

export type NzrNotificationDistinctCountAggregates = {
  __typename?: "NzrNotificationDistinctCountAggregates";
  /** Distinct count of assignee across the matching connection */
  assignee?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of payload across the matching connection */
  payload?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of referenceId across the matching connection */
  referenceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of referenceType across the matching connection */
  referenceType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of validity across the matching connection */
  validity?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrNotification` object types. All fields are combined with a logical ‘and.’ */
export type NzrNotificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrNotificationFilter>>;
  /** Filter by the object’s `assignee` field. */
  assignee?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgMemberByAssignee` relation. */
  lgMemberByAssignee?: InputMaybe<LgMemberFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrNotificationFilter>;
  /** Filter by the object’s `nzrOpportunityEmailAsReference` polymorphic relation. */
  nzrOpportunityEmailAsReference?: InputMaybe<NzrOpportunityEmailFilter>;
  /** Filter by the object’s `nzrSentimentFormAsReference` polymorphic relation. */
  nzrSentimentFormAsReference?: InputMaybe<NzrSentimentFormFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrNotificationFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `referenceId` field. */
  referenceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `referenceType` field. */
  referenceType?: InputMaybe<NzrNotificationReferenceTypeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validity` field. */
  validity?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `NzrNotification` for usage during aggregation. */
export enum NzrNotificationGroupBy {
  Assignee = "ASSIGNEE",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  Payload = "PAYLOAD",
  ReferenceId = "REFERENCE_ID",
  ReferenceType = "REFERENCE_TYPE",
  Status = "STATUS",
  Type = "TYPE",
  Validity = "VALIDITY",
  ValidityTruncatedToDay = "VALIDITY_TRUNCATED_TO_DAY",
  ValidityTruncatedToHour = "VALIDITY_TRUNCATED_TO_HOUR",
}

export type NzrNotificationHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrNotification` aggregates. */
export type NzrNotificationHavingInput = {
  AND?: InputMaybe<Array<NzrNotificationHavingInput>>;
  OR?: InputMaybe<Array<NzrNotificationHavingInput>>;
  average?: InputMaybe<NzrNotificationHavingAverageInput>;
  distinctCount?: InputMaybe<NzrNotificationHavingDistinctCountInput>;
  max?: InputMaybe<NzrNotificationHavingMaxInput>;
  min?: InputMaybe<NzrNotificationHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrNotificationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrNotificationHavingStddevSampleInput>;
  sum?: InputMaybe<NzrNotificationHavingSumInput>;
  variancePopulation?: InputMaybe<NzrNotificationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrNotificationHavingVarianceSampleInput>;
};

export type NzrNotificationHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrNotificationHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

/** Represents an update to a `NzrNotification`. Fields that are set will be updated. */
export type NzrNotificationPatch = {
  assignee?: InputMaybe<Scalars["UUID"]["input"]>;
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  payload?: InputMaybe<Scalars["JSON"]["input"]>;
  referenceId?: InputMaybe<Scalars["String"]["input"]>;
  referenceType?: InputMaybe<NzrNotificationReferenceType>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  validity?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export enum NzrNotificationReferenceType {
  NzrOpportunityEmail = "NZR_OPPORTUNITY_EMAIL",
  NzrSentimentForm = "NZR_SENTIMENT_FORM",
}

/** A filter to be used against NzrNotificationReferenceType fields. All fields are combined with a logical ‘and.’ */
export type NzrNotificationReferenceTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NzrNotificationReferenceType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NzrNotificationReferenceType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NzrNotificationReferenceType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NzrNotificationReferenceType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NzrNotificationReferenceType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NzrNotificationReferenceType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NzrNotificationReferenceType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NzrNotificationReferenceType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NzrNotificationReferenceType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NzrNotificationReferenceType>>;
};

/** A connection to a list of `NzrNotification` values. */
export type NzrNotificationsConnection = {
  __typename?: "NzrNotificationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrNotificationAggregates>;
  /** A list of edges which contains the `NzrNotification` and cursor to aid in pagination. */
  edges: Array<NzrNotificationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrNotificationAggregates>>;
  /** A list of `NzrNotification` objects. */
  nodes: Array<NzrNotification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrNotification` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrNotification` values. */
export type NzrNotificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrNotificationGroupBy>;
  having?: InputMaybe<NzrNotificationHavingInput>;
};

/** A `NzrNotification` edge in the connection. */
export type NzrNotificationsEdge = {
  __typename?: "NzrNotificationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrNotification` at the end of the edge. */
  node: NzrNotification;
};

/** Methods to use when ordering `NzrNotification`. */
export enum NzrNotificationsOrderBy {
  AssigneeAsc = "ASSIGNEE_ASC",
  AssigneeDesc = "ASSIGNEE_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PayloadAsc = "PAYLOAD_ASC",
  PayloadDesc = "PAYLOAD_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReferenceIdAsc = "REFERENCE_ID_ASC",
  ReferenceIdDesc = "REFERENCE_ID_DESC",
  ReferenceTypeAsc = "REFERENCE_TYPE_ASC",
  ReferenceTypeDesc = "REFERENCE_TYPE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  ValidityAsc = "VALIDITY_ASC",
  ValidityDesc = "VALIDITY_DESC",
}

/** A connection to a list of `NzrOpportunity` values. */
export type NzrOpportunitiesConnection = {
  __typename?: "NzrOpportunitiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrOpportunityAggregates>;
  /** A list of edges which contains the `NzrOpportunity` and cursor to aid in pagination. */
  edges: Array<NzrOpportunitiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrOpportunityAggregates>>;
  /** A list of `NzrOpportunity` objects. */
  nodes: Array<NzrOpportunity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrOpportunity` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrOpportunity` values. */
export type NzrOpportunitiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrOpportunityGroupBy>;
  having?: InputMaybe<NzrOpportunityHavingInput>;
};

/** A `NzrOpportunity` edge in the connection. */
export type NzrOpportunitiesEdge = {
  __typename?: "NzrOpportunitiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrOpportunity` at the end of the edge. */
  node: NzrOpportunity;
};

/** Methods to use when ordering `NzrOpportunity`. */
export enum NzrOpportunitiesOrderBy {
  AssigneeAsc = "ASSIGNEE_ASC",
  AssigneeDesc = "ASSIGNEE_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SharedFromAsc = "SHARED_FROM_ASC",
  SharedFromDesc = "SHARED_FROM_DESC",
  SignalIdAsc = "SIGNAL_ID_ASC",
  SignalIdDesc = "SIGNAL_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  ValidityAsc = "VALIDITY_ASC",
  ValidityDesc = "VALIDITY_DESC",
}

export type NzrOpportunity = Node & {
  __typename?: "NzrOpportunity";
  assignee: Scalars["UUID"]["output"];
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["UUID"]["output"];
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `NzrOpportunity`. */
  lgMemberByAssignee?: Maybe<LgMember>;
  /** Reads a single `LgMember` that is related to this `NzrOpportunity`. */
  lgMemberBySharedFrom?: Maybe<LgMember>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sharedFrom?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `Signal` that is related to this `NzrOpportunity`. */
  signal?: Maybe<Signal>;
  signalId: Scalars["UUID"]["output"];
  status: Scalars["String"]["output"];
  validity?: Maybe<Scalars["Datetime"]["output"]>;
};

export type NzrOpportunityAggregates = {
  __typename?: "NzrOpportunityAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrOpportunityDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrOpportunity` object types. */
export type NzrOpportunityAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrOpportunity` objects. */
  distinctCount?: InputMaybe<NzrOpportunityDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrOpportunity` object to be included within the aggregate. */
  filter?: InputMaybe<NzrOpportunityFilter>;
};

/**
 * A condition to be used against `NzrOpportunity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NzrOpportunityCondition = {
  /** Checks for equality with the object’s `assignee` field. */
  assignee?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `sharedFrom` field. */
  sharedFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `signalId` field. */
  signalId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `validity` field. */
  validity?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type NzrOpportunityDistinctCountAggregateFilter = {
  assignee?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  metadata?: InputMaybe<BigIntFilter>;
  sharedFrom?: InputMaybe<BigIntFilter>;
  signalId?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  validity?: InputMaybe<BigIntFilter>;
};

export type NzrOpportunityDistinctCountAggregates = {
  __typename?: "NzrOpportunityDistinctCountAggregates";
  /** Distinct count of assignee across the matching connection */
  assignee?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sharedFrom across the matching connection */
  sharedFrom?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of signalId across the matching connection */
  signalId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of validity across the matching connection */
  validity?: Maybe<Scalars["BigInt"]["output"]>;
};

export type NzrOpportunityEmail = Node & {
  __typename?: "NzrOpportunityEmail";
  attachments?: Maybe<Scalars["JSON"]["output"]>;
  body: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `DealroomCompany`. */
  dealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyId: NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyConnection;
  from?: Maybe<Scalars["String"]["output"]>;
  googleMessageId: Scalars["String"]["output"];
  historyId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  isCompleted: Scalars["Boolean"]["output"];
  isParsed: Scalars["Boolean"]["output"];
  labels?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyId: NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyConnection;
  /** Reads a single `LgMember` that is related to this `NzrOpportunityEmail`. */
  member?: Maybe<LgMember>;
  memberId: Scalars["UUID"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads through a `NzrNotification`. */
  nzrNotifications: NzrNotificationsConnection;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidatesByEmailId: NzrOpportunityEmailCandidatesConnection;
  parser?: Maybe<Scalars["String"]["output"]>;
  senderEmail: Scalars["String"]["output"];
  source?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  subject: Scalars["String"]["output"];
  threadId?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyCondition>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompaniesOrderBy>>;
};

export type NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type NzrOpportunityEmailNzrNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrNotificationCondition>;
  filter?: InputMaybe<NzrNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy>>;
};

export type NzrOpportunityEmailNzrOpportunityEmailCandidatesByEmailIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
  filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
};

export type NzrOpportunityEmailAggregates = {
  __typename?: "NzrOpportunityEmailAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrOpportunityEmailDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrOpportunityEmail` object types. */
export type NzrOpportunityEmailAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrOpportunityEmail` objects. */
  distinctCount?: InputMaybe<NzrOpportunityEmailDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrOpportunityEmail` object to be included within the aggregate. */
  filter?: InputMaybe<NzrOpportunityEmailFilter>;
};

export type NzrOpportunityEmailCandidate = Node & {
  __typename?: "NzrOpportunityEmailCandidate";
  /** Reads a single `DealroomCompany` that is related to this `NzrOpportunityEmailCandidate`. */
  dealroomCompany?: Maybe<DealroomCompany>;
  dealroomCompanyId?: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  /** Reads a single `NzrOpportunityEmail` that is related to this `NzrOpportunityEmailCandidate`. */
  email?: Maybe<NzrOpportunityEmail>;
  emailId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `LgCompany` that is related to this `NzrOpportunityEmailCandidate`. */
  lgCompany?: Maybe<LgCompany>;
  lgCompanyId?: Maybe<Scalars["UUID"]["output"]>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type NzrOpportunityEmailCandidateAggregates = {
  __typename?: "NzrOpportunityEmailCandidateAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<NzrOpportunityEmailCandidateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrOpportunityEmailCandidateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<NzrOpportunityEmailCandidateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<NzrOpportunityEmailCandidateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<NzrOpportunityEmailCandidateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<NzrOpportunityEmailCandidateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<NzrOpportunityEmailCandidateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<NzrOpportunityEmailCandidateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<NzrOpportunityEmailCandidateVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `NzrOpportunityEmailCandidate` object types. */
export type NzrOpportunityEmailCandidateAggregatesFilter = {
  /** Mean average aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  average?: InputMaybe<NzrOpportunityEmailCandidateAverageAggregateFilter>;
  /** Distinct count aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  distinctCount?: InputMaybe<NzrOpportunityEmailCandidateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrOpportunityEmailCandidate` object to be included within the aggregate. */
  filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** Maximum aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  max?: InputMaybe<NzrOpportunityEmailCandidateMaxAggregateFilter>;
  /** Minimum aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  min?: InputMaybe<NzrOpportunityEmailCandidateMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  stddevPopulation?: InputMaybe<NzrOpportunityEmailCandidateStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  stddevSample?: InputMaybe<NzrOpportunityEmailCandidateStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  sum?: InputMaybe<NzrOpportunityEmailCandidateSumAggregateFilter>;
  /** Population variance aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  variancePopulation?: InputMaybe<NzrOpportunityEmailCandidateVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `NzrOpportunityEmailCandidate` objects. */
  varianceSample?: InputMaybe<NzrOpportunityEmailCandidateVarianceSampleAggregateFilter>;
};

export type NzrOpportunityEmailCandidateAverageAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigFloatFilter>;
};

export type NzrOpportunityEmailCandidateAverageAggregates = {
  __typename?: "NzrOpportunityEmailCandidateAverageAggregates";
  /** Mean average of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `NzrOpportunityEmailCandidate` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type NzrOpportunityEmailCandidateCondition = {
  /** Checks for equality with the object’s `dealroomCompanyId` field. */
  dealroomCompanyId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `emailId` field. */
  emailId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lgCompanyId` field. */
  lgCompanyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type NzrOpportunityEmailCandidateDistinctCountAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigIntFilter>;
  description?: InputMaybe<BigIntFilter>;
  emailId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lgCompanyId?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  url?: InputMaybe<BigIntFilter>;
};

export type NzrOpportunityEmailCandidateDistinctCountAggregates = {
  __typename?: "NzrOpportunityEmailCandidateDistinctCountAggregates";
  /** Distinct count of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emailId across the matching connection */
  emailId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lgCompanyId across the matching connection */
  lgCompanyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrOpportunityEmailCandidate` object types. All fields are combined with a logical ‘and.’ */
export type NzrOpportunityEmailCandidateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrOpportunityEmailCandidateFilter>>;
  /** Filter by the object’s `dealroomCompany` relation. */
  dealroomCompany?: InputMaybe<DealroomCompanyFilter>;
  /** A related `dealroomCompany` exists. */
  dealroomCompanyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `dealroomCompanyId` field. */
  dealroomCompanyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` relation. */
  email?: InputMaybe<NzrOpportunityEmailFilter>;
  /** Filter by the object’s `emailId` field. */
  emailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lgCompany` relation. */
  lgCompany?: InputMaybe<LgCompanyFilter>;
  /** A related `lgCompany` exists. */
  lgCompanyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `lgCompanyId` field. */
  lgCompanyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrOpportunityEmailCandidateFilter>>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** Grouping methods for `NzrOpportunityEmailCandidate` for usage during aggregation. */
export enum NzrOpportunityEmailCandidateGroupBy {
  DealroomCompanyId = "DEALROOM_COMPANY_ID",
  Description = "DESCRIPTION",
  EmailId = "EMAIL_ID",
  LgCompanyId = "LG_COMPANY_ID",
  Name = "NAME",
  Url = "URL",
}

export type NzrOpportunityEmailCandidateHavingAverageInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingDistinctCountInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `NzrOpportunityEmailCandidate` aggregates. */
export type NzrOpportunityEmailCandidateHavingInput = {
  AND?: InputMaybe<Array<NzrOpportunityEmailCandidateHavingInput>>;
  OR?: InputMaybe<Array<NzrOpportunityEmailCandidateHavingInput>>;
  average?: InputMaybe<NzrOpportunityEmailCandidateHavingAverageInput>;
  distinctCount?: InputMaybe<NzrOpportunityEmailCandidateHavingDistinctCountInput>;
  max?: InputMaybe<NzrOpportunityEmailCandidateHavingMaxInput>;
  min?: InputMaybe<NzrOpportunityEmailCandidateHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrOpportunityEmailCandidateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrOpportunityEmailCandidateHavingStddevSampleInput>;
  sum?: InputMaybe<NzrOpportunityEmailCandidateHavingSumInput>;
  variancePopulation?: InputMaybe<NzrOpportunityEmailCandidateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrOpportunityEmailCandidateHavingVarianceSampleInput>;
};

export type NzrOpportunityEmailCandidateHavingMaxInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingMinInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingStddevPopulationInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingStddevSampleInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingSumInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingVariancePopulationInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateHavingVarianceSampleInput = {
  dealroomCompanyId?: InputMaybe<HavingIntFilter>;
};

export type NzrOpportunityEmailCandidateMaxAggregateFilter = {
  dealroomCompanyId?: InputMaybe<IntFilter>;
};

export type NzrOpportunityEmailCandidateMaxAggregates = {
  __typename?: "NzrOpportunityEmailCandidateMaxAggregates";
  /** Maximum of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["Int"]["output"]>;
};

export type NzrOpportunityEmailCandidateMinAggregateFilter = {
  dealroomCompanyId?: InputMaybe<IntFilter>;
};

export type NzrOpportunityEmailCandidateMinAggregates = {
  __typename?: "NzrOpportunityEmailCandidateMinAggregates";
  /** Minimum of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["Int"]["output"]>;
};

export type NzrOpportunityEmailCandidateStddevPopulationAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigFloatFilter>;
};

export type NzrOpportunityEmailCandidateStddevPopulationAggregates = {
  __typename?: "NzrOpportunityEmailCandidateStddevPopulationAggregates";
  /** Population standard deviation of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type NzrOpportunityEmailCandidateStddevSampleAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigFloatFilter>;
};

export type NzrOpportunityEmailCandidateStddevSampleAggregates = {
  __typename?: "NzrOpportunityEmailCandidateStddevSampleAggregates";
  /** Sample standard deviation of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type NzrOpportunityEmailCandidateSumAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigIntFilter>;
};

export type NzrOpportunityEmailCandidateSumAggregates = {
  __typename?: "NzrOpportunityEmailCandidateSumAggregates";
  /** Sum of dealroomCompanyId across the matching connection */
  dealroomCompanyId: Scalars["BigInt"]["output"];
};

export type NzrOpportunityEmailCandidateVariancePopulationAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigFloatFilter>;
};

export type NzrOpportunityEmailCandidateVariancePopulationAggregates = {
  __typename?: "NzrOpportunityEmailCandidateVariancePopulationAggregates";
  /** Population variance of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type NzrOpportunityEmailCandidateVarianceSampleAggregateFilter = {
  dealroomCompanyId?: InputMaybe<BigFloatFilter>;
};

export type NzrOpportunityEmailCandidateVarianceSampleAggregates = {
  __typename?: "NzrOpportunityEmailCandidateVarianceSampleAggregates";
  /** Sample variance of dealroomCompanyId across the matching connection */
  dealroomCompanyId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `NzrOpportunityEmailCandidate` values. */
export type NzrOpportunityEmailCandidatesConnection = {
  __typename?: "NzrOpportunityEmailCandidatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrOpportunityEmailCandidateAggregates>;
  /** A list of edges which contains the `NzrOpportunityEmailCandidate` and cursor to aid in pagination. */
  edges: Array<NzrOpportunityEmailCandidatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrOpportunityEmailCandidateAggregates>>;
  /** A list of `NzrOpportunityEmailCandidate` objects. */
  nodes: Array<NzrOpportunityEmailCandidate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrOpportunityEmailCandidate` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrOpportunityEmailCandidate` values. */
export type NzrOpportunityEmailCandidatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrOpportunityEmailCandidateGroupBy>;
  having?: InputMaybe<NzrOpportunityEmailCandidateHavingInput>;
};

/** A `NzrOpportunityEmailCandidate` edge in the connection. */
export type NzrOpportunityEmailCandidatesEdge = {
  __typename?: "NzrOpportunityEmailCandidatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrOpportunityEmailCandidate` at the end of the edge. */
  node: NzrOpportunityEmailCandidate;
};

/** Methods to use when ordering `NzrOpportunityEmailCandidate`. */
export enum NzrOpportunityEmailCandidatesOrderBy {
  DealroomCompanyIdAsc = "DEALROOM_COMPANY_ID_ASC",
  DealroomCompanyIdDesc = "DEALROOM_COMPANY_ID_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  EmailIdAsc = "EMAIL_ID_ASC",
  EmailIdDesc = "EMAIL_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LgCompanyIdAsc = "LG_COMPANY_ID_ASC",
  LgCompanyIdDesc = "LG_COMPANY_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

/**
 * A condition to be used against `NzrOpportunityEmail` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type NzrOpportunityEmailCondition = {
  /** Checks for equality with the object’s `attachments` field. */
  attachments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `from` field. */
  from?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `googleMessageId` field. */
  googleMessageId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `historyId` field. */
  historyId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isParsed` field. */
  isParsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `labels` field. */
  labels?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `parser` field. */
  parser?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderEmail` field. */
  senderEmail?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `threadId` field. */
  threadId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `to` field. */
  to?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `DealroomCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyConnection =
  {
    __typename?: "NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyConnection";
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<DealroomCompanyAggregates>;
    /** A list of edges which contains the `DealroomCompany`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
    edges: Array<NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<DealroomCompanyAggregates>>;
    /** A list of `DealroomCompany` objects. */
    nodes: Array<DealroomCompany>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `DealroomCompany` you could get from the connection. */
    totalCount: Scalars["Int"]["output"];
  };

/** A connection to a list of `DealroomCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<DealroomCompanyGroupBy>;
    having?: InputMaybe<DealroomCompanyHavingInput>;
  };

/** A `DealroomCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyEdge =
  {
    __typename?: "NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyEdge";
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars["Cursor"]["output"]>;
    /** The `DealroomCompany` at the end of the edge. */
    node: DealroomCompany;
    /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
    nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
  };

/** A `DealroomCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailDealroomCompaniesByNzrOpportunityEmailCandidateEmailIdAndDealroomCompanyIdManyToManyEdgeNzrOpportunityEmailCandidatesArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

export type NzrOpportunityEmailDistinctCountAggregateFilter = {
  attachments?: InputMaybe<BigIntFilter>;
  body?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  from?: InputMaybe<BigIntFilter>;
  googleMessageId?: InputMaybe<BigIntFilter>;
  historyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isCompleted?: InputMaybe<BigIntFilter>;
  isParsed?: InputMaybe<BigIntFilter>;
  labels?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  metadata?: InputMaybe<BigIntFilter>;
  parser?: InputMaybe<BigIntFilter>;
  senderEmail?: InputMaybe<BigIntFilter>;
  source?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  subject?: InputMaybe<BigIntFilter>;
  threadId?: InputMaybe<BigIntFilter>;
  to?: InputMaybe<BigIntFilter>;
};

export type NzrOpportunityEmailDistinctCountAggregates = {
  __typename?: "NzrOpportunityEmailDistinctCountAggregates";
  /** Distinct count of attachments across the matching connection */
  attachments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of body across the matching connection */
  body?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of from across the matching connection */
  from?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of googleMessageId across the matching connection */
  googleMessageId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of historyId across the matching connection */
  historyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isParsed across the matching connection */
  isParsed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of labels across the matching connection */
  labels?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of parser across the matching connection */
  parser?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of senderEmail across the matching connection */
  senderEmail?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of threadId across the matching connection */
  threadId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of to across the matching connection */
  to?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrOpportunityEmail` object types. All fields are combined with a logical ‘and.’ */
export type NzrOpportunityEmailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrOpportunityEmailFilter>>;
  /** Filter by the object’s `attachments` field. */
  attachments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `from` field. */
  from?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googleMessageId` field. */
  googleMessageId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `historyId` field. */
  historyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isParsed` field. */
  isParsed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `labels` field. */
  labels?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrOpportunityEmailFilter>;
  /** Filter by the object’s `nzrNotifications` polymorphic relation. */
  nzrNotifications?: InputMaybe<NzrOpportunityEmailToManyNzrNotificationFilterPoly>;
  /** Filter for if the object’s `nzrNotifications`               polymorphic relation exist. */
  nzrNotificationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrOpportunityEmailCandidatesByEmailId` relation. */
  nzrOpportunityEmailCandidatesByEmailId?: InputMaybe<NzrOpportunityEmailToManyNzrOpportunityEmailCandidateFilter>;
  /** Some related `nzrOpportunityEmailCandidatesByEmailId` exist. */
  nzrOpportunityEmailCandidatesByEmailIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrOpportunityEmailFilter>>;
  /** Filter by the object’s `parser` field. */
  parser?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderEmail` field. */
  senderEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `threadId` field. */
  threadId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `to` field. */
  to?: InputMaybe<StringFilter>;
};

/** Grouping methods for `NzrOpportunityEmail` for usage during aggregation. */
export enum NzrOpportunityEmailGroupBy {
  Attachments = "ATTACHMENTS",
  Body = "BODY",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  From = "FROM",
  HistoryId = "HISTORY_ID",
  IsCompleted = "IS_COMPLETED",
  IsParsed = "IS_PARSED",
  Labels = "LABELS",
  MemberId = "MEMBER_ID",
  Metadata = "METADATA",
  Parser = "PARSER",
  SenderEmail = "SENDER_EMAIL",
  Source = "SOURCE",
  Status = "STATUS",
  Subject = "SUBJECT",
  ThreadId = "THREAD_ID",
  To = "TO",
}

export type NzrOpportunityEmailHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrOpportunityEmail` aggregates. */
export type NzrOpportunityEmailHavingInput = {
  AND?: InputMaybe<Array<NzrOpportunityEmailHavingInput>>;
  OR?: InputMaybe<Array<NzrOpportunityEmailHavingInput>>;
  average?: InputMaybe<NzrOpportunityEmailHavingAverageInput>;
  distinctCount?: InputMaybe<NzrOpportunityEmailHavingDistinctCountInput>;
  max?: InputMaybe<NzrOpportunityEmailHavingMaxInput>;
  min?: InputMaybe<NzrOpportunityEmailHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrOpportunityEmailHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrOpportunityEmailHavingStddevSampleInput>;
  sum?: InputMaybe<NzrOpportunityEmailHavingSumInput>;
  variancePopulation?: InputMaybe<NzrOpportunityEmailHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrOpportunityEmailHavingVarianceSampleInput>;
};

export type NzrOpportunityEmailHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityEmailHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyConnection = {
  __typename?: "NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `NzrOpportunityEmailCandidate`, and the cursor to aid in pagination. */
  edges: Array<NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgCompanyGroupBy>;
    having?: InputMaybe<LgCompanyHavingInput>;
  };

/** A `LgCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyEdge = {
  __typename?: "NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates: NzrOpportunityEmailCandidatesConnection;
};

/** A `LgCompany` edge in the connection, with data from `NzrOpportunityEmailCandidate`. */
export type NzrOpportunityEmailLgCompaniesByNzrOpportunityEmailCandidateEmailIdAndLgCompanyIdManyToManyEdgeNzrOpportunityEmailCandidatesArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
    filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
  };

/**
 * A filter to be used against many `NzrNotification` object
 *                  through polymorphic types. All fields are combined with a logical ‘and.’
 */
export type NzrOpportunityEmailToManyNzrNotificationFilterPoly = {
  /** Every related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrNotificationFilter>;
  /** No related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘none.’ */
  none?: InputMaybe<NzrNotificationFilter>;
  /** Some related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘any.’ */
  some?: InputMaybe<NzrNotificationFilter>;
};

/** A filter to be used against many `NzrOpportunityEmailCandidate` object types. All fields are combined with a logical ‘and.’ */
export type NzrOpportunityEmailToManyNzrOpportunityEmailCandidateFilter = {
  /** Aggregates across related `NzrOpportunityEmailCandidate` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityEmailCandidateAggregatesFilter>;
  /** Every related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** No related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  /** Some related `NzrOpportunityEmailCandidate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
};

/** A connection to a list of `NzrOpportunityEmail` values. */
export type NzrOpportunityEmailsConnection = {
  __typename?: "NzrOpportunityEmailsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrOpportunityEmailAggregates>;
  /** A list of edges which contains the `NzrOpportunityEmail` and cursor to aid in pagination. */
  edges: Array<NzrOpportunityEmailsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrOpportunityEmailAggregates>>;
  /** A list of `NzrOpportunityEmail` objects. */
  nodes: Array<NzrOpportunityEmail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrOpportunityEmail` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrOpportunityEmail` values. */
export type NzrOpportunityEmailsConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrOpportunityEmailGroupBy>;
  having?: InputMaybe<NzrOpportunityEmailHavingInput>;
};

/** A `NzrOpportunityEmail` edge in the connection. */
export type NzrOpportunityEmailsEdge = {
  __typename?: "NzrOpportunityEmailsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrOpportunityEmail` at the end of the edge. */
  node: NzrOpportunityEmail;
};

/** Methods to use when ordering `NzrOpportunityEmail`. */
export enum NzrOpportunityEmailsOrderBy {
  AttachmentsAsc = "ATTACHMENTS_ASC",
  AttachmentsDesc = "ATTACHMENTS_DESC",
  BodyAsc = "BODY_ASC",
  BodyDesc = "BODY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FromAsc = "FROM_ASC",
  FromDesc = "FROM_DESC",
  GoogleMessageIdAsc = "GOOGLE_MESSAGE_ID_ASC",
  GoogleMessageIdDesc = "GOOGLE_MESSAGE_ID_DESC",
  HistoryIdAsc = "HISTORY_ID_ASC",
  HistoryIdDesc = "HISTORY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsCompletedAsc = "IS_COMPLETED_ASC",
  IsCompletedDesc = "IS_COMPLETED_DESC",
  IsParsedAsc = "IS_PARSED_ASC",
  IsParsedDesc = "IS_PARSED_DESC",
  LabelsAsc = "LABELS_ASC",
  LabelsDesc = "LABELS_DESC",
  MemberIdAsc = "MEMBER_ID_ASC",
  MemberIdDesc = "MEMBER_ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  NzrOpportunityEmailCandidatesByEmailIdAverageDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdAverageUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdAverageUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_AVERAGE_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdCountAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_COUNT_ASC",
  NzrOpportunityEmailCandidatesByEmailIdCountDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_COUNT_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdDistinctCountUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_DISTINCT_COUNT_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMaxUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMaxUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MAX_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdMinUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdMinUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_MIN_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevPopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdStddevSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_STDDEV_SAMPLE_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdSumUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdSumUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_SUM_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVariancePopulationUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_POPULATION_URL_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleDealroomCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleDealroomCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_DEALROOM_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleDescriptionAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleDescriptionDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleEmailIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleEmailIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleLgCompanyIdAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_LG_COMPANY_ID_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleLgCompanyIdDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_LG_COMPANY_ID_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleNameAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_NAME_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleNameDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_NAME_DESC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleUrlAsc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_URL_ASC",
  NzrOpportunityEmailCandidatesByEmailIdVarianceSampleUrlDesc = "NZR_OPPORTUNITY_EMAIL_CANDIDATES_BY_EMAIL_ID_VARIANCE_SAMPLE_URL_DESC",
  ParserAsc = "PARSER_ASC",
  ParserDesc = "PARSER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SenderEmailAsc = "SENDER_EMAIL_ASC",
  SenderEmailDesc = "SENDER_EMAIL_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  SubjectAsc = "SUBJECT_ASC",
  SubjectDesc = "SUBJECT_DESC",
  ThreadIdAsc = "THREAD_ID_ASC",
  ThreadIdDesc = "THREAD_ID_DESC",
  ToAsc = "TO_ASC",
  ToDesc = "TO_DESC",
}

/** A filter to be used against `NzrOpportunity` object types. All fields are combined with a logical ‘and.’ */
export type NzrOpportunityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrOpportunityFilter>>;
  /** Filter by the object’s `assignee` field. */
  assignee?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lgMemberByAssignee` relation. */
  lgMemberByAssignee?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `lgMemberBySharedFrom` relation. */
  lgMemberBySharedFrom?: InputMaybe<LgMemberFilter>;
  /** A related `lgMemberBySharedFrom` exists. */
  lgMemberBySharedFromExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrOpportunityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrOpportunityFilter>>;
  /** Filter by the object’s `sharedFrom` field. */
  sharedFrom?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `signal` relation. */
  signal?: InputMaybe<SignalFilter>;
  /** Filter by the object’s `signalId` field. */
  signalId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validity` field. */
  validity?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `NzrOpportunity` for usage during aggregation. */
export enum NzrOpportunityGroupBy {
  Assignee = "ASSIGNEE",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  Metadata = "METADATA",
  SharedFrom = "SHARED_FROM",
  SignalId = "SIGNAL_ID",
  Status = "STATUS",
  Validity = "VALIDITY",
  ValidityTruncatedToDay = "VALIDITY_TRUNCATED_TO_DAY",
  ValidityTruncatedToHour = "VALIDITY_TRUNCATED_TO_HOUR",
}

export type NzrOpportunityHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrOpportunity` aggregates. */
export type NzrOpportunityHavingInput = {
  AND?: InputMaybe<Array<NzrOpportunityHavingInput>>;
  OR?: InputMaybe<Array<NzrOpportunityHavingInput>>;
  average?: InputMaybe<NzrOpportunityHavingAverageInput>;
  distinctCount?: InputMaybe<NzrOpportunityHavingDistinctCountInput>;
  max?: InputMaybe<NzrOpportunityHavingMaxInput>;
  min?: InputMaybe<NzrOpportunityHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrOpportunityHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrOpportunityHavingStddevSampleInput>;
  sum?: InputMaybe<NzrOpportunityHavingSumInput>;
  variancePopulation?: InputMaybe<NzrOpportunityHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrOpportunityHavingVarianceSampleInput>;
};

export type NzrOpportunityHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrOpportunityHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
  validity?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `NzrSentimentCpy` values. */
export type NzrSentimentCpiesConnection = {
  __typename?: "NzrSentimentCpiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrSentimentCpyAggregates>;
  /** A list of edges which contains the `NzrSentimentCpy` and cursor to aid in pagination. */
  edges: Array<NzrSentimentCpiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrSentimentCpyAggregates>>;
  /** A list of `NzrSentimentCpy` objects. */
  nodes: Array<NzrSentimentCpy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrSentimentCpy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrSentimentCpy` values. */
export type NzrSentimentCpiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrSentimentCpyGroupBy>;
  having?: InputMaybe<NzrSentimentCpyHavingInput>;
};

/** A `NzrSentimentCpy` edge in the connection. */
export type NzrSentimentCpiesEdge = {
  __typename?: "NzrSentimentCpiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrSentimentCpy` at the end of the edge. */
  node: NzrSentimentCpy;
};

/** Methods to use when ordering `NzrSentimentCpy`. */
export enum NzrSentimentCpiesOrderBy {
  AnalyticsAsc = "ANALYTICS_ASC",
  AnalyticsDesc = "ANALYTICS_DESC",
  CategoryAsc = "CATEGORY_ASC",
  CategoryDesc = "CATEGORY_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByIdAsc = "CREATED_BY_ID_ASC",
  CreatedByIdDesc = "CREATED_BY_ID_DESC",
  ExpiresAtAsc = "EXPIRES_AT_ASC",
  ExpiresAtDesc = "EXPIRES_AT_DESC",
  FieldsAsc = "FIELDS_ASC",
  FieldsDesc = "FIELDS_DESC",
  FormStatusAsc = "FORM_STATUS_ASC",
  FormStatusDesc = "FORM_STATUS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  ResponsesAsc = "RESPONSES_ASC",
  ResponsesDesc = "RESPONSES_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
}

export type NzrSentimentCpy = {
  __typename?: "NzrSentimentCpy";
  analytics?: Maybe<Scalars["JSON"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  createdById?: Maybe<Scalars["UUID"]["output"]>;
  expiresAt?: Maybe<Scalars["Datetime"]["output"]>;
  fields?: Maybe<Scalars["JSON"]["output"]>;
  formStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  responses?: Maybe<Scalars["JSON"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  trelloId?: Maybe<Scalars["String"]["output"]>;
};

export type NzrSentimentCpyAggregates = {
  __typename?: "NzrSentimentCpyAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrSentimentCpyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `NzrSentimentCpy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NzrSentimentCpyCondition = {
  /** Checks for equality with the object’s `analytics` field. */
  analytics?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fields` field. */
  fields?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `formStatus` field. */
  formStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `responses` field. */
  responses?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
};

export type NzrSentimentCpyDistinctCountAggregates = {
  __typename?: "NzrSentimentCpyDistinctCountAggregates";
  /** Distinct count of analytics across the matching connection */
  analytics?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdById across the matching connection */
  createdById?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of expiresAt across the matching connection */
  expiresAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fields across the matching connection */
  fields?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of formStatus across the matching connection */
  formStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responses across the matching connection */
  responses?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrSentimentCpy` object types. All fields are combined with a logical ‘and.’ */
export type NzrSentimentCpyFilter = {
  /** Filter by the object’s `analytics` field. */
  analytics?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrSentimentCpyFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fields` field. */
  fields?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `formStatus` field. */
  formStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrSentimentCpyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrSentimentCpyFilter>>;
  /** Filter by the object’s `responses` field. */
  responses?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `NzrSentimentCpy` for usage during aggregation. */
export enum NzrSentimentCpyGroupBy {
  Analytics = "ANALYTICS",
  Category = "CATEGORY",
  CompanyId = "COMPANY_ID",
  CompanyName = "COMPANY_NAME",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedById = "CREATED_BY_ID",
  ExpiresAt = "EXPIRES_AT",
  ExpiresAtTruncatedToDay = "EXPIRES_AT_TRUNCATED_TO_DAY",
  ExpiresAtTruncatedToHour = "EXPIRES_AT_TRUNCATED_TO_HOUR",
  Fields = "FIELDS",
  FormStatus = "FORM_STATUS",
  Id = "ID",
  Responses = "RESPONSES",
  Status = "STATUS",
  Title = "TITLE",
  TrelloId = "TRELLO_ID",
}

export type NzrSentimentCpyHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrSentimentCpy` aggregates. */
export type NzrSentimentCpyHavingInput = {
  AND?: InputMaybe<Array<NzrSentimentCpyHavingInput>>;
  OR?: InputMaybe<Array<NzrSentimentCpyHavingInput>>;
  average?: InputMaybe<NzrSentimentCpyHavingAverageInput>;
  distinctCount?: InputMaybe<NzrSentimentCpyHavingDistinctCountInput>;
  max?: InputMaybe<NzrSentimentCpyHavingMaxInput>;
  min?: InputMaybe<NzrSentimentCpyHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrSentimentCpyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrSentimentCpyHavingStddevSampleInput>;
  sum?: InputMaybe<NzrSentimentCpyHavingSumInput>;
  variancePopulation?: InputMaybe<NzrSentimentCpyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrSentimentCpyHavingVarianceSampleInput>;
};

export type NzrSentimentCpyHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentCpyHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentForm = Node & {
  __typename?: "NzrSentimentForm";
  analytics?: Maybe<Scalars["JSON"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `LgCompany` that is related to this `NzrSentimentForm`. */
  company?: Maybe<LgCompany>;
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `NzrSentimentForm`. */
  createdBy?: Maybe<LgMember>;
  createdById?: Maybe<Scalars["UUID"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  expiresAt?: Maybe<Scalars["Datetime"]["output"]>;
  fields?: Maybe<Scalars["JSON"]["output"]>;
  formStatus?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `LgFounder` that is related to this `NzrSentimentForm`. */
  founder?: Maybe<LgFounder>;
  founderId?: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberId: NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyConnection;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads through a `NzrNotification`. */
  nzrNotifications: NzrNotificationsConnection;
  /** Reads and enables pagination through a set of `NzrSentimentFormAssignee`. */
  nzrSentimentFormAssignees: NzrSentimentFormAssigneesConnection;
  responses?: Maybe<Scalars["JSON"]["output"]>;
  sentimentSummary?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  trelloId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type NzrSentimentFormNzrNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrNotificationCondition>;
  filter?: InputMaybe<NzrNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy>>;
};

export type NzrSentimentFormNzrSentimentFormAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormAssigneeCondition>;
  filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormAssigneesOrderBy>>;
};

export type NzrSentimentFormAggregates = {
  __typename?: "NzrSentimentFormAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrSentimentFormDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrSentimentForm` object types. */
export type NzrSentimentFormAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrSentimentForm` objects. */
  distinctCount?: InputMaybe<NzrSentimentFormDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrSentimentForm` object to be included within the aggregate. */
  filter?: InputMaybe<NzrSentimentFormFilter>;
};

export type NzrSentimentFormAssignee = Node & {
  __typename?: "NzrSentimentFormAssignee";
  actionedAt?: Maybe<Scalars["Datetime"]["output"]>;
  anonUser?: Maybe<Scalars["String"]["output"]>;
  assignedAt?: Maybe<Scalars["Datetime"]["output"]>;
  completed: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `LgMember` that is related to this `NzrSentimentFormAssignee`. */
  member?: Maybe<LgMember>;
  memberId?: Maybe<Scalars["UUID"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `NzrSentimentForm` that is related to this `NzrSentimentFormAssignee`. */
  nzrSentimentForm?: Maybe<NzrSentimentForm>;
  nzrSentimentFormId: Scalars["String"]["output"];
  state?: Maybe<Scalars["JSON"]["output"]>;
};

export type NzrSentimentFormAssigneeAggregates = {
  __typename?: "NzrSentimentFormAssigneeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrSentimentFormAssigneeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrSentimentFormAssignee` object types. */
export type NzrSentimentFormAssigneeAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrSentimentFormAssignee` objects. */
  distinctCount?: InputMaybe<NzrSentimentFormAssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrSentimentFormAssignee` object to be included within the aggregate. */
  filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
};

/**
 * A condition to be used against `NzrSentimentFormAssignee` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type NzrSentimentFormAssigneeCondition = {
  /** Checks for equality with the object’s `actionedAt` field. */
  actionedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `anonUser` field. */
  anonUser?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `assignedAt` field. */
  assignedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `completed` field. */
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `nzrSentimentFormId` field. */
  nzrSentimentFormId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type NzrSentimentFormAssigneeDistinctCountAggregateFilter = {
  actionedAt?: InputMaybe<BigIntFilter>;
  anonUser?: InputMaybe<BigIntFilter>;
  assignedAt?: InputMaybe<BigIntFilter>;
  completed?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  nzrSentimentFormId?: InputMaybe<BigIntFilter>;
  state?: InputMaybe<BigIntFilter>;
};

export type NzrSentimentFormAssigneeDistinctCountAggregates = {
  __typename?: "NzrSentimentFormAssigneeDistinctCountAggregates";
  /** Distinct count of actionedAt across the matching connection */
  actionedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of anonUser across the matching connection */
  anonUser?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of assignedAt across the matching connection */
  assignedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of completed across the matching connection */
  completed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of nzrSentimentFormId across the matching connection */
  nzrSentimentFormId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrSentimentFormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type NzrSentimentFormAssigneeFilter = {
  /** Filter by the object’s `actionedAt` field. */
  actionedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrSentimentFormAssigneeFilter>>;
  /** Filter by the object’s `anonUser` field. */
  anonUser?: InputMaybe<StringFilter>;
  /** Filter by the object’s `assignedAt` field. */
  assignedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `completed` field. */
  completed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<LgMemberFilter>;
  /** A related `member` exists. */
  memberExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  /** Filter by the object’s `nzrSentimentForm` relation. */
  nzrSentimentForm?: InputMaybe<NzrSentimentFormFilter>;
  /** Filter by the object’s `nzrSentimentFormId` field. */
  nzrSentimentFormId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrSentimentFormAssigneeFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<JsonFilter>;
};

/** Grouping methods for `NzrSentimentFormAssignee` for usage during aggregation. */
export enum NzrSentimentFormAssigneeGroupBy {
  ActionedAt = "ACTIONED_AT",
  ActionedAtTruncatedToDay = "ACTIONED_AT_TRUNCATED_TO_DAY",
  ActionedAtTruncatedToHour = "ACTIONED_AT_TRUNCATED_TO_HOUR",
  AnonUser = "ANON_USER",
  AssignedAt = "ASSIGNED_AT",
  AssignedAtTruncatedToDay = "ASSIGNED_AT_TRUNCATED_TO_DAY",
  AssignedAtTruncatedToHour = "ASSIGNED_AT_TRUNCATED_TO_HOUR",
  Completed = "COMPLETED",
  MemberId = "MEMBER_ID",
  NzrSentimentFormId = "NZR_SENTIMENT_FORM_ID",
  State = "STATE",
}

export type NzrSentimentFormAssigneeHavingAverageInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingDistinctCountInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrSentimentFormAssignee` aggregates. */
export type NzrSentimentFormAssigneeHavingInput = {
  AND?: InputMaybe<Array<NzrSentimentFormAssigneeHavingInput>>;
  OR?: InputMaybe<Array<NzrSentimentFormAssigneeHavingInput>>;
  average?: InputMaybe<NzrSentimentFormAssigneeHavingAverageInput>;
  distinctCount?: InputMaybe<NzrSentimentFormAssigneeHavingDistinctCountInput>;
  max?: InputMaybe<NzrSentimentFormAssigneeHavingMaxInput>;
  min?: InputMaybe<NzrSentimentFormAssigneeHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrSentimentFormAssigneeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrSentimentFormAssigneeHavingStddevSampleInput>;
  sum?: InputMaybe<NzrSentimentFormAssigneeHavingSumInput>;
  variancePopulation?: InputMaybe<NzrSentimentFormAssigneeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrSentimentFormAssigneeHavingVarianceSampleInput>;
};

export type NzrSentimentFormAssigneeHavingMaxInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingMinInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingStddevPopulationInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingStddevSampleInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingSumInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingVariancePopulationInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormAssigneeHavingVarianceSampleInput = {
  actionedAt?: InputMaybe<HavingDatetimeFilter>;
  assignedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `NzrSentimentFormAssignee` values. */
export type NzrSentimentFormAssigneesConnection = {
  __typename?: "NzrSentimentFormAssigneesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrSentimentFormAssigneeAggregates>;
  /** A list of edges which contains the `NzrSentimentFormAssignee` and cursor to aid in pagination. */
  edges: Array<NzrSentimentFormAssigneesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrSentimentFormAssigneeAggregates>>;
  /** A list of `NzrSentimentFormAssignee` objects. */
  nodes: Array<NzrSentimentFormAssignee>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrSentimentFormAssignee` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrSentimentFormAssignee` values. */
export type NzrSentimentFormAssigneesConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrSentimentFormAssigneeGroupBy>;
  having?: InputMaybe<NzrSentimentFormAssigneeHavingInput>;
};

/** A `NzrSentimentFormAssignee` edge in the connection. */
export type NzrSentimentFormAssigneesEdge = {
  __typename?: "NzrSentimentFormAssigneesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrSentimentFormAssignee` at the end of the edge. */
  node: NzrSentimentFormAssignee;
};

/** Methods to use when ordering `NzrSentimentFormAssignee`. */
export enum NzrSentimentFormAssigneesOrderBy {
  ActionedAtAsc = "ACTIONED_AT_ASC",
  ActionedAtDesc = "ACTIONED_AT_DESC",
  AnonUserAsc = "ANON_USER_ASC",
  AnonUserDesc = "ANON_USER_DESC",
  AssignedAtAsc = "ASSIGNED_AT_ASC",
  AssignedAtDesc = "ASSIGNED_AT_DESC",
  CompletedAsc = "COMPLETED_ASC",
  CompletedDesc = "COMPLETED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MemberIdAsc = "MEMBER_ID_ASC",
  MemberIdDesc = "MEMBER_ID_DESC",
  Natural = "NATURAL",
  NzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StateAsc = "STATE_ASC",
  StateDesc = "STATE_DESC",
}

/**
 * A condition to be used against `NzrSentimentForm` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NzrSentimentFormCondition = {
  /** Checks for equality with the object’s `analytics` field. */
  analytics?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fields` field. */
  fields?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `formStatus` field. */
  formStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `founderId` field. */
  founderId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `responses` field. */
  responses?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `sentimentSummary` field. */
  sentimentSummary?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type NzrSentimentFormDistinctCountAggregateFilter = {
  analytics?: InputMaybe<BigIntFilter>;
  category?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdById?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  expiresAt?: InputMaybe<BigIntFilter>;
  fields?: InputMaybe<BigIntFilter>;
  formStatus?: InputMaybe<BigIntFilter>;
  founderId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  metadata?: InputMaybe<BigIntFilter>;
  responses?: InputMaybe<BigIntFilter>;
  sentimentSummary?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  trelloId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
};

export type NzrSentimentFormDistinctCountAggregates = {
  __typename?: "NzrSentimentFormDistinctCountAggregates";
  /** Distinct count of analytics across the matching connection */
  analytics?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdById across the matching connection */
  createdById?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of expiresAt across the matching connection */
  expiresAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fields across the matching connection */
  fields?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of formStatus across the matching connection */
  formStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of founderId across the matching connection */
  founderId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responses across the matching connection */
  responses?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sentimentSummary across the matching connection */
  sentimentSummary?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrSentimentForm` object types. All fields are combined with a logical ‘and.’ */
export type NzrSentimentFormFilter = {
  /** Filter by the object’s `analytics` field. */
  analytics?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrSentimentFormFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** A related `company` exists. */
  companyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` relation. */
  createdBy?: InputMaybe<LgMemberFilter>;
  /** A related `createdBy` exists. */
  createdByExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdById` field. */
  createdById?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fields` field. */
  fields?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `formStatus` field. */
  formStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `founder` relation. */
  founder?: InputMaybe<LgFounderFilter>;
  /** A related `founder` exists. */
  founderExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `founderId` field. */
  founderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrSentimentFormFilter>;
  /** Filter by the object’s `nzrNotifications` polymorphic relation. */
  nzrNotifications?: InputMaybe<NzrSentimentFormToManyNzrNotificationFilterPoly>;
  /** Filter for if the object’s `nzrNotifications`               polymorphic relation exist. */
  nzrNotificationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `nzrSentimentFormAssignees` relation. */
  nzrSentimentFormAssignees?: InputMaybe<NzrSentimentFormToManyNzrSentimentFormAssigneeFilter>;
  /** Some related `nzrSentimentFormAssignees` exist. */
  nzrSentimentFormAssigneesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrSentimentFormFilter>>;
  /** Filter by the object’s `responses` field. */
  responses?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `sentimentSummary` field. */
  sentimentSummary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** Grouping methods for `NzrSentimentForm` for usage during aggregation. */
export enum NzrSentimentFormGroupBy {
  Analytics = "ANALYTICS",
  Category = "CATEGORY",
  CompanyId = "COMPANY_ID",
  CompanyName = "COMPANY_NAME",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedById = "CREATED_BY_ID",
  Deleted = "DELETED",
  ExpiresAt = "EXPIRES_AT",
  ExpiresAtTruncatedToDay = "EXPIRES_AT_TRUNCATED_TO_DAY",
  ExpiresAtTruncatedToHour = "EXPIRES_AT_TRUNCATED_TO_HOUR",
  Fields = "FIELDS",
  FormStatus = "FORM_STATUS",
  FounderId = "FOUNDER_ID",
  Metadata = "METADATA",
  Responses = "RESPONSES",
  SentimentSummary = "SENTIMENT_SUMMARY",
  Status = "STATUS",
  Title = "TITLE",
  TrelloId = "TRELLO_ID",
  Type = "TYPE",
}

export type NzrSentimentFormHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NzrSentimentForm` aggregates. */
export type NzrSentimentFormHavingInput = {
  AND?: InputMaybe<Array<NzrSentimentFormHavingInput>>;
  OR?: InputMaybe<Array<NzrSentimentFormHavingInput>>;
  average?: InputMaybe<NzrSentimentFormHavingAverageInput>;
  distinctCount?: InputMaybe<NzrSentimentFormHavingDistinctCountInput>;
  max?: InputMaybe<NzrSentimentFormHavingMaxInput>;
  min?: InputMaybe<NzrSentimentFormHavingMinInput>;
  stddevPopulation?: InputMaybe<NzrSentimentFormHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NzrSentimentFormHavingStddevSampleInput>;
  sum?: InputMaybe<NzrSentimentFormHavingSumInput>;
  variancePopulation?: InputMaybe<NzrSentimentFormHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NzrSentimentFormHavingVarianceSampleInput>;
};

export type NzrSentimentFormHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NzrSentimentFormHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgMember` values, with data from `NzrSentimentFormAssignee`. */
export type NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyConnection = {
  __typename?: "NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrSentimentFormAssignee`, and the cursor to aid in pagination. */
  edges: Array<NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrSentimentFormAssignee`. */
export type NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgMemberGroupBy>;
    having?: InputMaybe<LgMemberHavingInput>;
  };

/** A `LgMember` edge in the connection, with data from `NzrSentimentFormAssignee`. */
export type NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyEdge = {
  __typename?: "NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrSentimentFormAssignee`. */
  nzrSentimentFormAssigneesByMemberId: NzrSentimentFormAssigneesConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrSentimentFormAssignee`. */
export type NzrSentimentFormLgMembersByNzrSentimentFormAssigneeNzrSentimentFormIdAndMemberIdManyToManyEdgeNzrSentimentFormAssigneesByMemberIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<NzrSentimentFormAssigneeCondition>;
    filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<NzrSentimentFormAssigneesOrderBy>>;
  };

/**
 * A filter to be used against many `NzrNotification` object
 *                  through polymorphic types. All fields are combined with a logical ‘and.’
 */
export type NzrSentimentFormToManyNzrNotificationFilterPoly = {
  /** Every related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrNotificationFilter>;
  /** No related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘none.’ */
  none?: InputMaybe<NzrNotificationFilter>;
  /** Some related `NzrNotification` matches the filter criteria. All fields are combined with a logical ‘any.’ */
  some?: InputMaybe<NzrNotificationFilter>;
};

/** A filter to be used against many `NzrSentimentFormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type NzrSentimentFormToManyNzrSentimentFormAssigneeFilter = {
  /** Aggregates across related `NzrSentimentFormAssignee` match the filter criteria. */
  aggregates?: InputMaybe<NzrSentimentFormAssigneeAggregatesFilter>;
  /** Every related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  /** No related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  /** Some related `NzrSentimentFormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrSentimentFormAssigneeFilter>;
};

/** A connection to a list of `NzrSentimentForm` values. */
export type NzrSentimentFormsConnection = {
  __typename?: "NzrSentimentFormsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrSentimentFormAggregates>;
  /** A list of edges which contains the `NzrSentimentForm` and cursor to aid in pagination. */
  edges: Array<NzrSentimentFormsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrSentimentFormAggregates>>;
  /** A list of `NzrSentimentForm` objects. */
  nodes: Array<NzrSentimentForm>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrSentimentForm` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrSentimentForm` values. */
export type NzrSentimentFormsConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrSentimentFormGroupBy>;
  having?: InputMaybe<NzrSentimentFormHavingInput>;
};

/** A `NzrSentimentForm` edge in the connection. */
export type NzrSentimentFormsEdge = {
  __typename?: "NzrSentimentFormsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrSentimentForm` at the end of the edge. */
  node: NzrSentimentForm;
};

/** Methods to use when ordering `NzrSentimentForm`. */
export enum NzrSentimentFormsOrderBy {
  AnalyticsAsc = "ANALYTICS_ASC",
  AnalyticsDesc = "ANALYTICS_DESC",
  CategoryAsc = "CATEGORY_ASC",
  CategoryDesc = "CATEGORY_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByIdAsc = "CREATED_BY_ID_ASC",
  CreatedByIdDesc = "CREATED_BY_ID_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  ExpiresAtAsc = "EXPIRES_AT_ASC",
  ExpiresAtDesc = "EXPIRES_AT_DESC",
  FieldsAsc = "FIELDS_ASC",
  FieldsDesc = "FIELDS_DESC",
  FormStatusAsc = "FORM_STATUS_ASC",
  FormStatusDesc = "FORM_STATUS_DESC",
  FounderIdAsc = "FOUNDER_ID_ASC",
  FounderIdDesc = "FOUNDER_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  NzrSentimentFormAssigneesAverageActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesAverageActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesAverageAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ANON_USER_ASC",
  NzrSentimentFormAssigneesAverageAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ANON_USER_DESC",
  NzrSentimentFormAssigneesAverageAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesAverageAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesAverageCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_COMPLETED_ASC",
  NzrSentimentFormAssigneesAverageCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_COMPLETED_DESC",
  NzrSentimentFormAssigneesAverageIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ID_ASC",
  NzrSentimentFormAssigneesAverageIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_ID_DESC",
  NzrSentimentFormAssigneesAverageMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesAverageMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesAverageNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesAverageNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesAverageStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_STATE_ASC",
  NzrSentimentFormAssigneesAverageStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_AVERAGE_STATE_DESC",
  NzrSentimentFormAssigneesCountAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_COUNT_ASC",
  NzrSentimentFormAssigneesCountDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_COUNT_DESC",
  NzrSentimentFormAssigneesDistinctCountActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesDistinctCountActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesDistinctCountAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ANON_USER_ASC",
  NzrSentimentFormAssigneesDistinctCountAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ANON_USER_DESC",
  NzrSentimentFormAssigneesDistinctCountAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesDistinctCountAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesDistinctCountCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_COMPLETED_ASC",
  NzrSentimentFormAssigneesDistinctCountCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_COMPLETED_DESC",
  NzrSentimentFormAssigneesDistinctCountIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ID_ASC",
  NzrSentimentFormAssigneesDistinctCountIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_ID_DESC",
  NzrSentimentFormAssigneesDistinctCountMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesDistinctCountMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesDistinctCountNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesDistinctCountNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesDistinctCountStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_STATE_ASC",
  NzrSentimentFormAssigneesDistinctCountStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_DISTINCT_COUNT_STATE_DESC",
  NzrSentimentFormAssigneesMaxActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesMaxActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesMaxAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ANON_USER_ASC",
  NzrSentimentFormAssigneesMaxAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ANON_USER_DESC",
  NzrSentimentFormAssigneesMaxAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesMaxAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesMaxCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_COMPLETED_ASC",
  NzrSentimentFormAssigneesMaxCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_COMPLETED_DESC",
  NzrSentimentFormAssigneesMaxIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ID_ASC",
  NzrSentimentFormAssigneesMaxIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_ID_DESC",
  NzrSentimentFormAssigneesMaxMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesMaxMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesMaxNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesMaxNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesMaxStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_STATE_ASC",
  NzrSentimentFormAssigneesMaxStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MAX_STATE_DESC",
  NzrSentimentFormAssigneesMinActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesMinActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesMinAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ANON_USER_ASC",
  NzrSentimentFormAssigneesMinAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ANON_USER_DESC",
  NzrSentimentFormAssigneesMinAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesMinAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesMinCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_COMPLETED_ASC",
  NzrSentimentFormAssigneesMinCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_COMPLETED_DESC",
  NzrSentimentFormAssigneesMinIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ID_ASC",
  NzrSentimentFormAssigneesMinIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_ID_DESC",
  NzrSentimentFormAssigneesMinMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesMinMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesMinNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesMinNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesMinStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_STATE_ASC",
  NzrSentimentFormAssigneesMinStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_MIN_STATE_DESC",
  NzrSentimentFormAssigneesStddevPopulationActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesStddevPopulationActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesStddevPopulationAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ANON_USER_ASC",
  NzrSentimentFormAssigneesStddevPopulationAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ANON_USER_DESC",
  NzrSentimentFormAssigneesStddevPopulationAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesStddevPopulationAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesStddevPopulationCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_COMPLETED_ASC",
  NzrSentimentFormAssigneesStddevPopulationCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_COMPLETED_DESC",
  NzrSentimentFormAssigneesStddevPopulationIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ID_ASC",
  NzrSentimentFormAssigneesStddevPopulationIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_ID_DESC",
  NzrSentimentFormAssigneesStddevPopulationMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesStddevPopulationMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesStddevPopulationNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesStddevPopulationNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesStddevPopulationStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_STATE_ASC",
  NzrSentimentFormAssigneesStddevPopulationStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_POPULATION_STATE_DESC",
  NzrSentimentFormAssigneesStddevSampleActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesStddevSampleActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesStddevSampleAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ANON_USER_ASC",
  NzrSentimentFormAssigneesStddevSampleAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ANON_USER_DESC",
  NzrSentimentFormAssigneesStddevSampleAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesStddevSampleAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesStddevSampleCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_COMPLETED_ASC",
  NzrSentimentFormAssigneesStddevSampleCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_COMPLETED_DESC",
  NzrSentimentFormAssigneesStddevSampleIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ID_ASC",
  NzrSentimentFormAssigneesStddevSampleIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_ID_DESC",
  NzrSentimentFormAssigneesStddevSampleMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesStddevSampleMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesStddevSampleNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesStddevSampleNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesStddevSampleStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_STATE_ASC",
  NzrSentimentFormAssigneesStddevSampleStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_STDDEV_SAMPLE_STATE_DESC",
  NzrSentimentFormAssigneesSumActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesSumActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesSumAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ANON_USER_ASC",
  NzrSentimentFormAssigneesSumAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ANON_USER_DESC",
  NzrSentimentFormAssigneesSumAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesSumAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesSumCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_COMPLETED_ASC",
  NzrSentimentFormAssigneesSumCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_COMPLETED_DESC",
  NzrSentimentFormAssigneesSumIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ID_ASC",
  NzrSentimentFormAssigneesSumIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_ID_DESC",
  NzrSentimentFormAssigneesSumMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesSumMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesSumNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesSumNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesSumStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_STATE_ASC",
  NzrSentimentFormAssigneesSumStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_SUM_STATE_DESC",
  NzrSentimentFormAssigneesVariancePopulationActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesVariancePopulationActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesVariancePopulationAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ANON_USER_ASC",
  NzrSentimentFormAssigneesVariancePopulationAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ANON_USER_DESC",
  NzrSentimentFormAssigneesVariancePopulationAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesVariancePopulationAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesVariancePopulationCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_COMPLETED_ASC",
  NzrSentimentFormAssigneesVariancePopulationCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_COMPLETED_DESC",
  NzrSentimentFormAssigneesVariancePopulationIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ID_ASC",
  NzrSentimentFormAssigneesVariancePopulationIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_ID_DESC",
  NzrSentimentFormAssigneesVariancePopulationMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesVariancePopulationMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesVariancePopulationNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesVariancePopulationNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesVariancePopulationStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_STATE_ASC",
  NzrSentimentFormAssigneesVariancePopulationStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_POPULATION_STATE_DESC",
  NzrSentimentFormAssigneesVarianceSampleActionedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ACTIONED_AT_ASC",
  NzrSentimentFormAssigneesVarianceSampleActionedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ACTIONED_AT_DESC",
  NzrSentimentFormAssigneesVarianceSampleAnonUserAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ANON_USER_ASC",
  NzrSentimentFormAssigneesVarianceSampleAnonUserDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ANON_USER_DESC",
  NzrSentimentFormAssigneesVarianceSampleAssignedAtAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ASSIGNED_AT_ASC",
  NzrSentimentFormAssigneesVarianceSampleAssignedAtDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ASSIGNED_AT_DESC",
  NzrSentimentFormAssigneesVarianceSampleCompletedAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_COMPLETED_ASC",
  NzrSentimentFormAssigneesVarianceSampleCompletedDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_COMPLETED_DESC",
  NzrSentimentFormAssigneesVarianceSampleIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC",
  NzrSentimentFormAssigneesVarianceSampleIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC",
  NzrSentimentFormAssigneesVarianceSampleMemberIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  NzrSentimentFormAssigneesVarianceSampleMemberIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  NzrSentimentFormAssigneesVarianceSampleNzrSentimentFormIdAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_NZR_SENTIMENT_FORM_ID_ASC",
  NzrSentimentFormAssigneesVarianceSampleNzrSentimentFormIdDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_NZR_SENTIMENT_FORM_ID_DESC",
  NzrSentimentFormAssigneesVarianceSampleStateAsc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_STATE_ASC",
  NzrSentimentFormAssigneesVarianceSampleStateDesc = "NZR_SENTIMENT_FORM_ASSIGNEES_VARIANCE_SAMPLE_STATE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResponsesAsc = "RESPONSES_ASC",
  ResponsesDesc = "RESPONSES_DESC",
  SentimentSummaryAsc = "SENTIMENT_SUMMARY_ASC",
  SentimentSummaryDesc = "SENTIMENT_SUMMARY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
}

export type NzrUserCompanyTag = Node & {
  __typename?: "NzrUserCompanyTag";
  /** Reads a single `LgCompany` that is related to this `NzrUserCompanyTag`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  tags: Scalars["JSON"]["output"];
  /** Reads a single `LgMember` that is related to this `NzrUserCompanyTag`. */
  user?: Maybe<LgMember>;
  userId: Scalars["UUID"]["output"];
};

export type NzrUserCompanyTagAggregates = {
  __typename?: "NzrUserCompanyTagAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NzrUserCompanyTagDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NzrUserCompanyTag` object types. */
export type NzrUserCompanyTagAggregatesFilter = {
  /** Distinct count aggregate over matching `NzrUserCompanyTag` objects. */
  distinctCount?: InputMaybe<NzrUserCompanyTagDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NzrUserCompanyTag` object to be included within the aggregate. */
  filter?: InputMaybe<NzrUserCompanyTagFilter>;
};

/**
 * A condition to be used against `NzrUserCompanyTag` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NzrUserCompanyTagCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type NzrUserCompanyTagDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  tags?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type NzrUserCompanyTagDistinctCountAggregates = {
  __typename?: "NzrUserCompanyTagDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NzrUserCompanyTag` object types. All fields are combined with a logical ‘and.’ */
export type NzrUserCompanyTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NzrUserCompanyTagFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NzrUserCompanyTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NzrUserCompanyTagFilter>>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `NzrUserCompanyTag` values. */
export type NzrUserCompanyTagsConnection = {
  __typename?: "NzrUserCompanyTagsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NzrUserCompanyTagAggregates>;
  /** A list of edges which contains the `NzrUserCompanyTag` and cursor to aid in pagination. */
  edges: Array<NzrUserCompanyTagsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NzrUserCompanyTagAggregates>>;
  /** A list of `NzrUserCompanyTag` objects. */
  nodes: Array<NzrUserCompanyTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NzrUserCompanyTag` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NzrUserCompanyTag` values. */
export type NzrUserCompanyTagsConnectionGroupedAggregatesArgs = {
  groupBy: Array<NzrUserCompanyTagsGroupBy>;
  having?: InputMaybe<NzrUserCompanyTagsHavingInput>;
};

/** A `NzrUserCompanyTag` edge in the connection. */
export type NzrUserCompanyTagsEdge = {
  __typename?: "NzrUserCompanyTagsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NzrUserCompanyTag` at the end of the edge. */
  node: NzrUserCompanyTag;
};

/** Grouping methods for `NzrUserCompanyTag` for usage during aggregation. */
export enum NzrUserCompanyTagsGroupBy {
  CompanyId = "COMPANY_ID",
  Tags = "TAGS",
  UserId = "USER_ID",
}

/** Conditions for `NzrUserCompanyTag` aggregates. */
export type NzrUserCompanyTagsHavingInput = {
  AND?: InputMaybe<Array<NzrUserCompanyTagsHavingInput>>;
  OR?: InputMaybe<Array<NzrUserCompanyTagsHavingInput>>;
};

/** Methods to use when ordering `NzrUserCompanyTag`. */
export enum NzrUserCompanyTagsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type OnFileUploadRequestInput = {
  path: Scalars["String"]["input"];
};

/** A connection to a list of `OpFundCpy` values. */
export type OpFundCpiesConnection = {
  __typename?: "OpFundCpiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpFundCpyAggregates>;
  /** A list of edges which contains the `OpFundCpy` and cursor to aid in pagination. */
  edges: Array<OpFundCpiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpFundCpyAggregates>>;
  /** A list of `OpFundCpy` objects. */
  nodes: Array<OpFundCpy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpFundCpy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpFundCpy` values. */
export type OpFundCpiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpFundCpyGroupBy>;
  having?: InputMaybe<OpFundCpyHavingInput>;
};

/** A `OpFundCpy` edge in the connection. */
export type OpFundCpiesEdge = {
  __typename?: "OpFundCpiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpFundCpy` at the end of the edge. */
  node: OpFundCpy;
};

/** Methods to use when ordering `OpFundCpy`. */
export enum OpFundCpiesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ModifiedAtAsc = "MODIFIED_AT_ASC",
  ModifiedAtDesc = "MODIFIED_AT_DESC",
  MtpAsc = "MTP_ASC",
  MtpDesc = "MTP_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  ParentFundAsc = "PARENT_FUND_ASC",
  ParentFundDesc = "PARENT_FUND_DESC",
  VintageAsc = "VINTAGE_ASC",
  VintageDesc = "VINTAGE_DESC",
}

export type OpFundCpy = {
  __typename?: "OpFundCpy";
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  id?: Maybe<Scalars["BigInt"]["output"]>;
  modifiedAt?: Maybe<Scalars["Datetime"]["output"]>;
  mtp?: Maybe<Scalars["JSON"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parentFund?: Maybe<Scalars["String"]["output"]>;
  vintage?: Maybe<Scalars["Datetime"]["output"]>;
};

export type OpFundCpyAggregates = {
  __typename?: "OpFundCpyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpFundCpyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpFundCpyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpFundCpyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpFundCpyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpFundCpyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpFundCpyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpFundCpySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpFundCpyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpFundCpyVarianceSampleAggregates>;
};

export type OpFundCpyAverageAggregates = {
  __typename?: "OpFundCpyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `OpFundCpy` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpFundCpyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `mtp` field. */
  mtp?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `parentFund` field. */
  parentFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `vintage` field. */
  vintage?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type OpFundCpyDistinctCountAggregates = {
  __typename?: "OpFundCpyDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtp across the matching connection */
  mtp?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of parentFund across the matching connection */
  parentFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of vintage across the matching connection */
  vintage?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpFundCpy` object types. All fields are combined with a logical ‘and.’ */
export type OpFundCpyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpFundCpyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `mtp` field. */
  mtp?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpFundCpyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpFundCpyFilter>>;
  /** Filter by the object’s `parentFund` field. */
  parentFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `vintage` field. */
  vintage?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `OpFundCpy` for usage during aggregation. */
export enum OpFundCpyGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Id = "ID",
  ModifiedAt = "MODIFIED_AT",
  ModifiedAtTruncatedToDay = "MODIFIED_AT_TRUNCATED_TO_DAY",
  ModifiedAtTruncatedToHour = "MODIFIED_AT_TRUNCATED_TO_HOUR",
  Mtp = "MTP",
  Name = "NAME",
  ParentFund = "PARENT_FUND",
  Vintage = "VINTAGE",
  VintageTruncatedToDay = "VINTAGE_TRUNCATED_TO_DAY",
  VintageTruncatedToHour = "VINTAGE_TRUNCATED_TO_HOUR",
}

export type OpFundCpyHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OpFundCpy` aggregates. */
export type OpFundCpyHavingInput = {
  AND?: InputMaybe<Array<OpFundCpyHavingInput>>;
  OR?: InputMaybe<Array<OpFundCpyHavingInput>>;
  average?: InputMaybe<OpFundCpyHavingAverageInput>;
  distinctCount?: InputMaybe<OpFundCpyHavingDistinctCountInput>;
  max?: InputMaybe<OpFundCpyHavingMaxInput>;
  min?: InputMaybe<OpFundCpyHavingMinInput>;
  stddevPopulation?: InputMaybe<OpFundCpyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpFundCpyHavingStddevSampleInput>;
  sum?: InputMaybe<OpFundCpyHavingSumInput>;
  variancePopulation?: InputMaybe<OpFundCpyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpFundCpyHavingVarianceSampleInput>;
};

export type OpFundCpyHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpFundCpyMaxAggregates = {
  __typename?: "OpFundCpyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type OpFundCpyMinAggregates = {
  __typename?: "OpFundCpyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type OpFundCpyStddevPopulationAggregates = {
  __typename?: "OpFundCpyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpFundCpyStddevSampleAggregates = {
  __typename?: "OpFundCpyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpFundCpySumAggregates = {
  __typename?: "OpFundCpySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
};

export type OpFundCpyVariancePopulationAggregates = {
  __typename?: "OpFundCpyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpFundCpyVarianceSampleAggregates = {
  __typename?: "OpFundCpyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `OpsCompany` values. */
export type OpsCompaniesConnection = {
  __typename?: "OpsCompaniesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany` and cursor to aid in pagination. */
  edges: Array<OpsCompaniesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values. */
export type OpsCompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyGroupBy>;
  having?: InputMaybe<OpsCompanyHavingInput>;
};

/** A `OpsCompany` edge in the connection. */
export type OpsCompaniesEdge = {
  __typename?: "OpsCompaniesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
};

/** Methods to use when ordering `OpsCompany`. */
export enum OpsCompaniesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  HqCityAsc = "HQ_CITY_ASC",
  HqCityDesc = "HQ_CITY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InitialInvestmentAsc = "INITIAL_INVESTMENT_ASC",
  InitialInvestmentDesc = "INITIAL_INVESTMENT_DESC",
  InitialInvestmentRatioAsc = "INITIAL_INVESTMENT_RATIO_ASC",
  InitialInvestmentRatioDesc = "INITIAL_INVESTMENT_RATIO_DESC",
  InvestmentTypeAsc = "INVESTMENT_TYPE_ASC",
  InvestmentTypeDesc = "INVESTMENT_TYPE_DESC",
  IsFocusAsc = "IS_FOCUS_ASC",
  IsFocusDesc = "IS_FOCUS_DESC",
  IsStaleAsc = "IS_STALE_ASC",
  IsStaleDesc = "IS_STALE_DESC",
  ModifiedAtAsc = "MODIFIED_AT_ASC",
  ModifiedAtDesc = "MODIFIED_AT_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAveragePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAveragePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdAverageYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdAverageYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_AVERAGE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdCountAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_COUNT_ASC",
  MtpCompanyfundsByMtpOpCompanyIdCountDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_COUNT_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdDistinctCountYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_DISTINCT_COUNT_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMaxYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMaxYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MAX_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdMinYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdMinYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_MIN_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevPopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdStddevSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_STDDEV_SAMPLE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdSumYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdSumYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_SUM_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVariancePopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpOpCompanyIdVarianceSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_OP_COMPANY_ID_VARIANCE_SAMPLE_YOY_GROWTH_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OpsCompanyfundsByCompanyIdAverageAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdAverageAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdAverageBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdAverageBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdAverageCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdAverageCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdAverageCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdAverageCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdAverageCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdAverageCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdAverageCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdAverageCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdAverageCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdAverageCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdAverageDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdAverageDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdAverageEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdAverageEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdAverageFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdAverageFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdAverageFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdAverageGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdAverageGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdAverageGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdAverageIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdAverageInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdAverageInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdAverageInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdAverageInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdAverageInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdAverageInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdAverageInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdAverageLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdAverageLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdAverageLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdAverageLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdAverageMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdAverageMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdAverageMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdAverageMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdAverageRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdAverageRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdAverageResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdAverageRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdAverageSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdAverageSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdAverageSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdAverageSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdAverageStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_STATUS_ASC",
  OpsCompanyfundsByCompanyIdAverageStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_STATUS_DESC",
  OpsCompanyfundsByCompanyIdAverageTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdAverageTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdAverageTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdAverageTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdAverageTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdAverageTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdAverageUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdAverageUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_AVERAGE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdCountAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_COUNT_ASC",
  OpsCompanyfundsByCompanyIdCountDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_COUNT_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdDistinctCountUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdDistinctCountUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_DISTINCT_COUNT_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdMaxAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdMaxAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdMaxBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdMaxBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdMaxCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdMaxCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdMaxCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdMaxCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdMaxCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdMaxCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdMaxCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdMaxCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdMaxCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdMaxCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdMaxDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdMaxDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdMaxEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdMaxEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdMaxFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdMaxFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdMaxFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdMaxGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdMaxGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdMaxGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdMaxIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdMaxInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdMaxInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdMaxInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdMaxInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdMaxInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdMaxInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdMaxInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdMaxLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdMaxLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdMaxLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdMaxLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdMaxMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdMaxMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdMaxMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdMaxMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdMaxRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdMaxRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdMaxResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdMaxRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdMaxSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdMaxSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdMaxSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdMaxSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdMaxStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_STATUS_ASC",
  OpsCompanyfundsByCompanyIdMaxStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_STATUS_DESC",
  OpsCompanyfundsByCompanyIdMaxTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdMaxTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdMaxTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdMaxTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdMaxTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdMaxTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdMaxUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdMaxUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MAX_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdMinAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdMinAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdMinBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdMinBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdMinCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdMinCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdMinCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdMinCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdMinCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdMinCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdMinCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdMinCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdMinCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdMinCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdMinCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdMinCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdMinDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdMinDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdMinEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdMinEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdMinFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdMinFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdMinFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdMinFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdMinGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdMinGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdMinGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdMinGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdMinIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_ID_ASC",
  OpsCompanyfundsByCompanyIdMinIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_ID_DESC",
  OpsCompanyfundsByCompanyIdMinInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdMinInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdMinInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdMinInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdMinInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdMinInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdMinInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdMinInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdMinLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdMinLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdMinLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdMinLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdMinMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdMinMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdMinMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdMinMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdMinRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdMinRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdMinResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdMinResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdMinRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdMinRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdMinSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdMinSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdMinSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdMinSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdMinSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdMinSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdMinStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_STATUS_ASC",
  OpsCompanyfundsByCompanyIdMinStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_STATUS_DESC",
  OpsCompanyfundsByCompanyIdMinTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdMinTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdMinTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdMinTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdMinTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdMinTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdMinUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdMinUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_MIN_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdStddevPopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdStddevPopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdStddevSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdStddevSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_STDDEV_SAMPLE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdSumAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdSumAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdSumBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdSumBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdSumCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdSumCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdSumCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdSumCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdSumCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdSumCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdSumCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdSumCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdSumCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdSumCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdSumCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdSumCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdSumDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdSumDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdSumEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdSumEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdSumFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdSumFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdSumFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdSumFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdSumGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdSumGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdSumGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdSumGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdSumIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_ID_ASC",
  OpsCompanyfundsByCompanyIdSumIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_ID_DESC",
  OpsCompanyfundsByCompanyIdSumInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdSumInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdSumInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdSumInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdSumInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdSumInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdSumInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdSumInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdSumLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdSumLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdSumLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdSumLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdSumMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdSumMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdSumMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdSumMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdSumRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdSumRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdSumResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdSumResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdSumRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdSumRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdSumSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdSumSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdSumSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdSumSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdSumSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdSumSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdSumStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_STATUS_ASC",
  OpsCompanyfundsByCompanyIdSumStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_STATUS_DESC",
  OpsCompanyfundsByCompanyIdSumTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdSumTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdSumTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdSumTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdSumTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdSumTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdSumUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdSumUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_SUM_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdVariancePopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdVariancePopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleSourceAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleSourceDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleStatusAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleStatusDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByCompanyIdVarianceSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByCompanyIdVarianceSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_COMPANY_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TotalInvestedCapitalAsc = "TOTAL_INVESTED_CAPITAL_ASC",
  TotalInvestedCapitalDesc = "TOTAL_INVESTED_CAPITAL_DESC",
  TotalNavAsc = "TOTAL_NAV_ASC",
  TotalNavDesc = "TOTAL_NAV_DESC",
}

export type OpsCompany = Node & {
  __typename?: "OpsCompany";
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  hqCity: Scalars["String"]["output"];
  id: Scalars["BigInt"]["output"];
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  investmentType?: Maybe<Scalars["String"]["output"]>;
  isFocus?: Maybe<Scalars["Boolean"]["output"]>;
  isStale?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyId: OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyConnection;
  modifiedAt?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpOpCompanyId: MtpCompanyfundsConnection;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByCompanyId: OpsCompanyfundsConnection;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundId: OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFundsByOpsCompanyfundCompanyIdAndFundId: OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundCompanyIdAndResponsibleId: OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleId: OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyConnection;
  /** Reads through a `SrcCompany`. */
  srcCompany?: Maybe<SrcCompany>;
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type OpsCompanyMtpCompanyfundsByMtpOpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

export type OpsCompanyOpsCompanyfundsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

export type OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsCompanySrcCompanyArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type OpsCompanyAggregates = {
  __typename?: "OpsCompanyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsCompanyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsCompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsCompanyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsCompanyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsCompanyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsCompanyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsCompanySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsCompanyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsCompanyVarianceSampleAggregates>;
};

export type OpsCompanyAverageAggregates = {
  __typename?: "OpsCompanyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `OpsCompany` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsCompanyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `hqCity` field. */
  hqCity?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `initialInvestmentRatio` field. */
  initialInvestmentRatio?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `investmentType` field. */
  investmentType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isFocus` field. */
  isFocus?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isStale` field. */
  isStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `totalNav` field. */
  totalNav?: InputMaybe<Scalars["Float"]["input"]>;
};

export type OpsCompanyDistinctCountAggregates = {
  __typename?: "OpsCompanyDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of hqCity across the matching connection */
  hqCity?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentType across the matching connection */
  investmentType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isFocus across the matching connection */
  isFocus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isStale across the matching connection */
  isStale?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsCompany` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsCompanyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hqCity` field. */
  hqCity?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `initialInvestmentRatio` field. */
  initialInvestmentRatio?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `investmentType` field. */
  investmentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isFocus` field. */
  isFocus?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isStale` field. */
  isStale?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `mtpCompanyfundsByMtpOpCompanyId` relation. */
  mtpCompanyfundsByMtpOpCompanyId?: InputMaybe<OpsCompanyToManyMtpCompanyfundFilter>;
  /** Some related `mtpCompanyfundsByMtpOpCompanyId` exist. */
  mtpCompanyfundsByMtpOpCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsCompanyFilter>;
  /** Filter by the object’s `opsCompanyfundsByCompanyId` relation. */
  opsCompanyfundsByCompanyId?: InputMaybe<OpsCompanyToManyOpsCompanyfundFilter>;
  /** Some related `opsCompanyfundsByCompanyId` exist. */
  opsCompanyfundsByCompanyIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsCompanyFilter>>;
  /** Filter by the object’s `srcCompany` polymorphic relation. */
  srcCompany?: InputMaybe<SrcCompanyFilter>;
  /** Filter for if the object’s `srcCompany`               polymorphic relation exist. */
  srcCompanyExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalNav` field. */
  totalNav?: InputMaybe<FloatFilter>;
};

/** Grouping methods for `OpsCompany` for usage during aggregation. */
export enum OpsCompanyGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Deleted = "DELETED",
  HqCity = "HQ_CITY",
  InitialInvestment = "INITIAL_INVESTMENT",
  InitialInvestmentRatio = "INITIAL_INVESTMENT_RATIO",
  InvestmentType = "INVESTMENT_TYPE",
  IsFocus = "IS_FOCUS",
  IsStale = "IS_STALE",
  ModifiedAt = "MODIFIED_AT",
  ModifiedAtTruncatedToDay = "MODIFIED_AT_TRUNCATED_TO_DAY",
  ModifiedAtTruncatedToHour = "MODIFIED_AT_TRUNCATED_TO_HOUR",
  Name = "NAME",
  TotalInvestedCapital = "TOTAL_INVESTED_CAPITAL",
  TotalNav = "TOTAL_NAV",
}

export type OpsCompanyHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `OpsCompany` aggregates. */
export type OpsCompanyHavingInput = {
  AND?: InputMaybe<Array<OpsCompanyHavingInput>>;
  OR?: InputMaybe<Array<OpsCompanyHavingInput>>;
  average?: InputMaybe<OpsCompanyHavingAverageInput>;
  distinctCount?: InputMaybe<OpsCompanyHavingDistinctCountInput>;
  max?: InputMaybe<OpsCompanyHavingMaxInput>;
  min?: InputMaybe<OpsCompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsCompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsCompanyHavingStddevSampleInput>;
  sum?: InputMaybe<OpsCompanyHavingSumInput>;
  variancePopulation?: InputMaybe<OpsCompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsCompanyHavingVarianceSampleInput>;
};

export type OpsCompanyHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  initialInvestmentRatio?: InputMaybe<HavingFloatFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  totalNav?: InputMaybe<HavingFloatFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `MtpCompanyfund`. */
export type OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyConnection = {
  __typename?: "OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `MtpCompanyfund`. */
export type OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<LgCompanyGroupBy>;
    having?: InputMaybe<LgCompanyHavingInput>;
  };

/** A `LgCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyEdge = {
  __typename?: "OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpCompanyId: MtpCompanyfundsConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsCompanyLgCompaniesByMtpCompanyfundMtpOpCompanyIdAndMtpCompanyIdManyToManyEdgeMtpCompanyfundsByMtpCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<MtpCompanyfundCondition>;
    filter?: InputMaybe<MtpCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
  };

export type OpsCompanyMaxAggregates = {
  __typename?: "OpsCompanyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyMinAggregates = {
  __typename?: "OpsCompanyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `OpsFund` values, with data from `MtpCompanyfund`. */
export type OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyConnection = {
  __typename?: "OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values, with data from `MtpCompanyfund`. */
export type OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyEdge = {
  __typename?: "OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpFundId: MtpCompanyfundsConnection;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
};

/** A `OpsFund` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsCompanyOpsFundsByMtpCompanyfundMtpOpCompanyIdAndMtpFundIdManyToManyEdgeMtpCompanyfundsByMtpFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyConnection = {
  __typename?: "OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyEdge = {
  __typename?: "OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByFundId: OpsCompanyfundsConnection;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsFundsByOpsCompanyfundCompanyIdAndFundIdManyToManyEdgeOpsCompanyfundsByFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyConnection = {
  __typename?: "OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsPersonGroupBy>;
  having?: InputMaybe<OpsPersonHavingInput>;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyEdge = {
  __typename?: "OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndResponsibleIdManyToManyEdgeOpsCompanyfundsByResponsibleIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyConnection = {
  __typename?: "OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<OpsPersonGroupBy>;
    having?: InputMaybe<OpsPersonHavingInput>;
  };

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyEdge = {
  __typename?: "OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsBySecondResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsCompanyOpsPeopleByOpsCompanyfundCompanyIdAndSecondResponsibleIdManyToManyEdgeOpsCompanyfundsBySecondResponsibleIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

export type OpsCompanyStddevPopulationAggregates = {
  __typename?: "OpsCompanyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyStddevSampleAggregates = {
  __typename?: "OpsCompanyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanySumAggregates = {
  __typename?: "OpsCompanySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of initialInvestment across the matching connection */
  initialInvestment: Scalars["Float"]["output"];
  /** Sum of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio: Scalars["Float"]["output"];
  /** Sum of totalInvestedCapital across the matching connection */
  totalInvestedCapital: Scalars["Float"]["output"];
  /** Sum of totalNav across the matching connection */
  totalNav: Scalars["Float"]["output"];
};

/** A filter to be used against many `MtpCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyToManyMtpCompanyfundFilter = {
  /** Aggregates across related `MtpCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<MtpCompanyfundAggregatesFilter>;
  /** Every related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MtpCompanyfundFilter>;
  /** No related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MtpCompanyfundFilter>;
  /** Some related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MtpCompanyfundFilter>;
};

/** A filter to be used against many `OpsCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyToManyOpsCompanyfundFilter = {
  /** Aggregates across related `OpsCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<OpsCompanyfundAggregatesFilter>;
  /** Every related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OpsCompanyfundFilter>;
  /** No related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OpsCompanyfundFilter>;
  /** Some related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OpsCompanyfundFilter>;
};

export type OpsCompanyVariancePopulationAggregates = {
  __typename?: "OpsCompanyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyVarianceSampleAggregates = {
  __typename?: "OpsCompanyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of initialInvestmentRatio across the matching connection */
  initialInvestmentRatio?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of totalNav across the matching connection */
  totalNav?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfund = Node & {
  __typename?: "OpsCompanyfund";
  announced: Scalars["Boolean"]["output"];
  braggy: Scalars["String"]["output"];
  cashoutDate?: Maybe<Scalars["Datetime"]["output"]>;
  cashoutGroup: Scalars["String"]["output"];
  coInvestors: Scalars["String"]["output"];
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `OpsCompany` that is related to this `OpsCompanyfund`. */
  company?: Maybe<OpsCompany>;
  companyId: Scalars["BigInt"]["output"];
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  firstInvestmentDate?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `OpsFund` that is related to this `OpsCompanyfund`. */
  fund?: Maybe<OpsFund>;
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["BigInt"]["output"];
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  initialStage: Scalars["String"]["output"];
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  investmentType: Scalars["String"]["output"];
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  mostRecentRound: Scalars["String"]["output"];
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `OpsCompanyfundcapital`. */
  opsCompanyfundcapitalsByCompanyFundId: OpsCompanyfundcapitalsConnection;
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `OpsPerson` that is related to this `OpsCompanyfund`. */
  responsible?: Maybe<OpsPerson>;
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `OpsPerson` that is related to this `OpsCompanyfund`. */
  secondResponsible?: Maybe<OpsPerson>;
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  source: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  trelloId: Scalars["String"]["output"];
  tsSigned?: Maybe<Scalars["Datetime"]["output"]>;
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundOpsCompanyfundcapitalsByCompanyFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundcapitalCondition>;
  filter?: InputMaybe<OpsCompanyfundcapitalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundcapitalsOrderBy>>;
};

export type OpsCompanyfundAggregates = {
  __typename?: "OpsCompanyfundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsCompanyfundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsCompanyfundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsCompanyfundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsCompanyfundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsCompanyfundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsCompanyfundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsCompanyfundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsCompanyfundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsCompanyfundVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `OpsCompanyfund` object types. */
export type OpsCompanyfundAggregatesFilter = {
  /** Mean average aggregate over matching `OpsCompanyfund` objects. */
  average?: InputMaybe<OpsCompanyfundAverageAggregateFilter>;
  /** Distinct count aggregate over matching `OpsCompanyfund` objects. */
  distinctCount?: InputMaybe<OpsCompanyfundDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `OpsCompanyfund` object to be included within the aggregate. */
  filter?: InputMaybe<OpsCompanyfundFilter>;
  /** Maximum aggregate over matching `OpsCompanyfund` objects. */
  max?: InputMaybe<OpsCompanyfundMaxAggregateFilter>;
  /** Minimum aggregate over matching `OpsCompanyfund` objects. */
  min?: InputMaybe<OpsCompanyfundMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `OpsCompanyfund` objects. */
  stddevPopulation?: InputMaybe<OpsCompanyfundStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `OpsCompanyfund` objects. */
  stddevSample?: InputMaybe<OpsCompanyfundStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `OpsCompanyfund` objects. */
  sum?: InputMaybe<OpsCompanyfundSumAggregateFilter>;
  /** Population variance aggregate over matching `OpsCompanyfund` objects. */
  variancePopulation?: InputMaybe<OpsCompanyfundVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `OpsCompanyfund` objects. */
  varianceSample?: InputMaybe<OpsCompanyfundVarianceSampleAggregateFilter>;
};

export type OpsCompanyfundAverageAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundAverageAggregates = {
  __typename?: "OpsCompanyfundAverageAggregates";
  /** Mean average of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `OpsCompanyfund` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsCompanyfundCondition = {
  /** Checks for equality with the object’s `announced` field. */
  announced?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `braggy` field. */
  braggy?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cashoutDate` field. */
  cashoutDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `cashoutGroup` field. */
  cashoutGroup?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cocMultiple` field. */
  cocMultiple?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `currentEv` field. */
  currentEv?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentMultiple` field. */
  currentMultiple?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentNav` field. */
  currentNav?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentNavFromFund` field. */
  currentNavFromFund?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentOwned` field. */
  currentOwned?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentPostValuation` field. */
  currentPostValuation?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `desiredFollowonCapital` field. */
  desiredFollowonCapital?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `evAsFundReturner` field. */
  evAsFundReturner?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `firstInvestmentDate` field. */
  firstInvestmentDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fundId` field. */
  fundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `goingInOwnership` field. */
  goingInOwnership?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `grossMargins` field. */
  grossMargins?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `initialStage` field. */
  initialStage?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investedCapitalFromFund` field. */
  investedCapitalFromFund?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `investmentType` field. */
  investmentType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastRound` field. */
  lastRound?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `latestExcitementScore` field. */
  latestExcitementScore?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `mostRecentRound` field. */
  mostRecentRound?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mtpEv` field. */
  mtpEv?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `realisedValue` field. */
  realisedValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `responsibleId` field. */
  responsibleId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `secondResponsibleId` field. */
  secondResponsibleId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tsSigned` field. */
  tsSigned?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `unrealisedValue` field. */
  unrealisedValue?: InputMaybe<Scalars["Float"]["input"]>;
};

/** A connection to a list of `OpsCompanyfundCopy` values. */
export type OpsCompanyfundCopiesConnection = {
  __typename?: "OpsCompanyfundCopiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyfundCopyAggregates>;
  /** A list of edges which contains the `OpsCompanyfundCopy` and cursor to aid in pagination. */
  edges: Array<OpsCompanyfundCopiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyfundCopyAggregates>>;
  /** A list of `OpsCompanyfundCopy` objects. */
  nodes: Array<OpsCompanyfundCopy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompanyfundCopy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompanyfundCopy` values. */
export type OpsCompanyfundCopiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyfundCopyGroupBy>;
  having?: InputMaybe<OpsCompanyfundCopyHavingInput>;
};

/** A `OpsCompanyfundCopy` edge in the connection. */
export type OpsCompanyfundCopiesEdge = {
  __typename?: "OpsCompanyfundCopiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompanyfundCopy` at the end of the edge. */
  node: OpsCompanyfundCopy;
};

/** Methods to use when ordering `OpsCompanyfundCopy`. */
export enum OpsCompanyfundCopiesOrderBy {
  AnnouncedAsc = "ANNOUNCED_ASC",
  AnnouncedDesc = "ANNOUNCED_DESC",
  BraggyAsc = "BRAGGY_ASC",
  BraggyDesc = "BRAGGY_DESC",
  CashoutDateAsc = "CASHOUT_DATE_ASC",
  CashoutDateDesc = "CASHOUT_DATE_DESC",
  CashoutGroupAsc = "CASHOUT_GROUP_ASC",
  CashoutGroupDesc = "CASHOUT_GROUP_DESC",
  CocMultipleAsc = "COC_MULTIPLE_ASC",
  CocMultipleDesc = "COC_MULTIPLE_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CoInvestorsAsc = "CO_INVESTORS_ASC",
  CoInvestorsDesc = "CO_INVESTORS_DESC",
  CurrentEvAsc = "CURRENT_EV_ASC",
  CurrentEvDesc = "CURRENT_EV_DESC",
  CurrentMultipleAsc = "CURRENT_MULTIPLE_ASC",
  CurrentMultipleDesc = "CURRENT_MULTIPLE_DESC",
  CurrentNavAsc = "CURRENT_NAV_ASC",
  CurrentNavDesc = "CURRENT_NAV_DESC",
  CurrentNavFromFundAsc = "CURRENT_NAV_FROM_FUND_ASC",
  CurrentNavFromFundDesc = "CURRENT_NAV_FROM_FUND_DESC",
  CurrentOwnedAsc = "CURRENT_OWNED_ASC",
  CurrentOwnedDesc = "CURRENT_OWNED_DESC",
  CurrentPostValuationAsc = "CURRENT_POST_VALUATION_ASC",
  CurrentPostValuationDesc = "CURRENT_POST_VALUATION_DESC",
  DesiredFollowonCapitalAsc = "DESIRED_FOLLOWON_CAPITAL_ASC",
  DesiredFollowonCapitalDesc = "DESIRED_FOLLOWON_CAPITAL_DESC",
  EvAsFundReturnerAsc = "EV_AS_FUND_RETURNER_ASC",
  EvAsFundReturnerDesc = "EV_AS_FUND_RETURNER_DESC",
  FirstInvestmentDateAsc = "FIRST_INVESTMENT_DATE_ASC",
  FirstInvestmentDateDesc = "FIRST_INVESTMENT_DATE_DESC",
  FundIdAsc = "FUND_ID_ASC",
  FundIdDesc = "FUND_ID_DESC",
  GoingInOwnershipAsc = "GOING_IN_OWNERSHIP_ASC",
  GoingInOwnershipDesc = "GOING_IN_OWNERSHIP_DESC",
  GrossMarginsAsc = "GROSS_MARGINS_ASC",
  GrossMarginsDesc = "GROSS_MARGINS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InitialInvestmentAsc = "INITIAL_INVESTMENT_ASC",
  InitialInvestmentDesc = "INITIAL_INVESTMENT_DESC",
  InitialStageAsc = "INITIAL_STAGE_ASC",
  InitialStageDesc = "INITIAL_STAGE_DESC",
  InvestedCapitalFromFundAsc = "INVESTED_CAPITAL_FROM_FUND_ASC",
  InvestedCapitalFromFundDesc = "INVESTED_CAPITAL_FROM_FUND_DESC",
  InvestmentTypeAsc = "INVESTMENT_TYPE_ASC",
  InvestmentTypeDesc = "INVESTMENT_TYPE_DESC",
  LastRoundAsc = "LAST_ROUND_ASC",
  LastRoundDesc = "LAST_ROUND_DESC",
  LatestExcitementScoreAsc = "LATEST_EXCITEMENT_SCORE_ASC",
  LatestExcitementScoreDesc = "LATEST_EXCITEMENT_SCORE_DESC",
  MostRecentRoundAsc = "MOST_RECENT_ROUND_ASC",
  MostRecentRoundDesc = "MOST_RECENT_ROUND_DESC",
  MtpEvAsc = "MTP_EV_ASC",
  MtpEvDesc = "MTP_EV_DESC",
  Natural = "NATURAL",
  RealisedValueAsc = "REALISED_VALUE_ASC",
  RealisedValueDesc = "REALISED_VALUE_DESC",
  ResponsibleIdAsc = "RESPONSIBLE_ID_ASC",
  ResponsibleIdDesc = "RESPONSIBLE_ID_DESC",
  RevenueAsc = "REVENUE_ASC",
  RevenueDesc = "REVENUE_DESC",
  SecondResponsibleIdAsc = "SECOND_RESPONSIBLE_ID_ASC",
  SecondResponsibleIdDesc = "SECOND_RESPONSIBLE_ID_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TotalInvestedCapitalAsc = "TOTAL_INVESTED_CAPITAL_ASC",
  TotalInvestedCapitalDesc = "TOTAL_INVESTED_CAPITAL_DESC",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
  TsSignedAsc = "TS_SIGNED_ASC",
  TsSignedDesc = "TS_SIGNED_DESC",
  UnrealisedValueAsc = "UNREALISED_VALUE_ASC",
  UnrealisedValueDesc = "UNREALISED_VALUE_DESC",
}

export type OpsCompanyfundCopy = {
  __typename?: "OpsCompanyfundCopy";
  announced?: Maybe<Scalars["Boolean"]["output"]>;
  braggy?: Maybe<Scalars["String"]["output"]>;
  cashoutDate?: Maybe<Scalars["Datetime"]["output"]>;
  cashoutGroup?: Maybe<Scalars["String"]["output"]>;
  coInvestors?: Maybe<Scalars["String"]["output"]>;
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  firstInvestmentDate?: Maybe<Scalars["Datetime"]["output"]>;
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["BigInt"]["output"]>;
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  initialStage?: Maybe<Scalars["String"]["output"]>;
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  investmentType?: Maybe<Scalars["String"]["output"]>;
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  mostRecentRound?: Maybe<Scalars["String"]["output"]>;
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  revenue?: Maybe<Scalars["Float"]["output"]>;
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  sourceType?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  trelloId?: Maybe<Scalars["String"]["output"]>;
  tsSigned?: Maybe<Scalars["Datetime"]["output"]>;
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopyAggregates = {
  __typename?: "OpsCompanyfundCopyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsCompanyfundCopyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsCompanyfundCopyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsCompanyfundCopyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsCompanyfundCopyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsCompanyfundCopyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsCompanyfundCopyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsCompanyfundCopySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsCompanyfundCopyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsCompanyfundCopyVarianceSampleAggregates>;
};

export type OpsCompanyfundCopyAverageAggregates = {
  __typename?: "OpsCompanyfundCopyAverageAggregates";
  /** Mean average of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * A condition to be used against `OpsCompanyfundCopy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OpsCompanyfundCopyCondition = {
  /** Checks for equality with the object’s `announced` field. */
  announced?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `braggy` field. */
  braggy?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cashoutDate` field. */
  cashoutDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `cashoutGroup` field. */
  cashoutGroup?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cocMultiple` field. */
  cocMultiple?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `currentEv` field. */
  currentEv?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentMultiple` field. */
  currentMultiple?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentNav` field. */
  currentNav?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentNavFromFund` field. */
  currentNavFromFund?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentOwned` field. */
  currentOwned?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `currentPostValuation` field. */
  currentPostValuation?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `desiredFollowonCapital` field. */
  desiredFollowonCapital?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `evAsFundReturner` field. */
  evAsFundReturner?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `firstInvestmentDate` field. */
  firstInvestmentDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fundId` field. */
  fundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `goingInOwnership` field. */
  goingInOwnership?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `grossMargins` field. */
  grossMargins?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `initialStage` field. */
  initialStage?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `investedCapitalFromFund` field. */
  investedCapitalFromFund?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `investmentType` field. */
  investmentType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastRound` field. */
  lastRound?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `latestExcitementScore` field. */
  latestExcitementScore?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `mostRecentRound` field. */
  mostRecentRound?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mtpEv` field. */
  mtpEv?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `realisedValue` field. */
  realisedValue?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `responsibleId` field. */
  responsibleId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `secondResponsibleId` field. */
  secondResponsibleId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tsSigned` field. */
  tsSigned?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `unrealisedValue` field. */
  unrealisedValue?: InputMaybe<Scalars["Float"]["input"]>;
};

export type OpsCompanyfundCopyDistinctCountAggregates = {
  __typename?: "OpsCompanyfundCopyDistinctCountAggregates";
  /** Distinct count of announced across the matching connection */
  announced?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of braggy across the matching connection */
  braggy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashoutDate across the matching connection */
  cashoutDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashoutGroup across the matching connection */
  cashoutGroup?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of coInvestors across the matching connection */
  coInvestors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of firstInvestmentDate across the matching connection */
  firstInvestmentDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialStage across the matching connection */
  initialStage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentType across the matching connection */
  investmentType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mostRecentRound across the matching connection */
  mostRecentRound?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revenue across the matching connection */
  revenue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tsSigned across the matching connection */
  tsSigned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsCompanyfundCopy` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyfundCopyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsCompanyfundCopyFilter>>;
  /** Filter by the object’s `announced` field. */
  announced?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `braggy` field. */
  braggy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cashoutDate` field. */
  cashoutDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cashoutGroup` field. */
  cashoutGroup?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cocMultiple` field. */
  cocMultiple?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `currentEv` field. */
  currentEv?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentMultiple` field. */
  currentMultiple?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentNav` field. */
  currentNav?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentNavFromFund` field. */
  currentNavFromFund?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentOwned` field. */
  currentOwned?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentPostValuation` field. */
  currentPostValuation?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `desiredFollowonCapital` field. */
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `evAsFundReturner` field. */
  evAsFundReturner?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `firstInvestmentDate` field. */
  firstInvestmentDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fundId` field. */
  fundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `goingInOwnership` field. */
  goingInOwnership?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `grossMargins` field. */
  grossMargins?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `initialStage` field. */
  initialStage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investedCapitalFromFund` field. */
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `investmentType` field. */
  investmentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastRound` field. */
  lastRound?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `latestExcitementScore` field. */
  latestExcitementScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `mostRecentRound` field. */
  mostRecentRound?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mtpEv` field. */
  mtpEv?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsCompanyfundCopyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsCompanyfundCopyFilter>>;
  /** Filter by the object’s `realisedValue` field. */
  realisedValue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `responsibleId` field. */
  responsibleId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `secondResponsibleId` field. */
  secondResponsibleId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tsSigned` field. */
  tsSigned?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `unrealisedValue` field. */
  unrealisedValue?: InputMaybe<FloatFilter>;
};

/** Grouping methods for `OpsCompanyfundCopy` for usage during aggregation. */
export enum OpsCompanyfundCopyGroupBy {
  Announced = "ANNOUNCED",
  Braggy = "BRAGGY",
  CashoutDate = "CASHOUT_DATE",
  CashoutDateTruncatedToDay = "CASHOUT_DATE_TRUNCATED_TO_DAY",
  CashoutDateTruncatedToHour = "CASHOUT_DATE_TRUNCATED_TO_HOUR",
  CashoutGroup = "CASHOUT_GROUP",
  CocMultiple = "COC_MULTIPLE",
  CompanyId = "COMPANY_ID",
  CoInvestors = "CO_INVESTORS",
  CurrentEv = "CURRENT_EV",
  CurrentMultiple = "CURRENT_MULTIPLE",
  CurrentNav = "CURRENT_NAV",
  CurrentNavFromFund = "CURRENT_NAV_FROM_FUND",
  CurrentOwned = "CURRENT_OWNED",
  CurrentPostValuation = "CURRENT_POST_VALUATION",
  DesiredFollowonCapital = "DESIRED_FOLLOWON_CAPITAL",
  EvAsFundReturner = "EV_AS_FUND_RETURNER",
  FirstInvestmentDate = "FIRST_INVESTMENT_DATE",
  FirstInvestmentDateTruncatedToDay = "FIRST_INVESTMENT_DATE_TRUNCATED_TO_DAY",
  FirstInvestmentDateTruncatedToHour = "FIRST_INVESTMENT_DATE_TRUNCATED_TO_HOUR",
  FundId = "FUND_ID",
  GoingInOwnership = "GOING_IN_OWNERSHIP",
  GrossMargins = "GROSS_MARGINS",
  Id = "ID",
  InitialInvestment = "INITIAL_INVESTMENT",
  InitialStage = "INITIAL_STAGE",
  InvestedCapitalFromFund = "INVESTED_CAPITAL_FROM_FUND",
  InvestmentType = "INVESTMENT_TYPE",
  LastRound = "LAST_ROUND",
  LatestExcitementScore = "LATEST_EXCITEMENT_SCORE",
  MostRecentRound = "MOST_RECENT_ROUND",
  MtpEv = "MTP_EV",
  RealisedValue = "REALISED_VALUE",
  ResponsibleId = "RESPONSIBLE_ID",
  Revenue = "REVENUE",
  SecondResponsibleId = "SECOND_RESPONSIBLE_ID",
  Source = "SOURCE",
  SourceType = "SOURCE_TYPE",
  Status = "STATUS",
  TotalInvestedCapital = "TOTAL_INVESTED_CAPITAL",
  TrelloId = "TRELLO_ID",
  TsSigned = "TS_SIGNED",
  TsSignedTruncatedToDay = "TS_SIGNED_TRUNCATED_TO_DAY",
  TsSignedTruncatedToHour = "TS_SIGNED_TRUNCATED_TO_HOUR",
  UnrealisedValue = "UNREALISED_VALUE",
}

export type OpsCompanyfundCopyHavingAverageInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingDistinctCountInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `OpsCompanyfundCopy` aggregates. */
export type OpsCompanyfundCopyHavingInput = {
  AND?: InputMaybe<Array<OpsCompanyfundCopyHavingInput>>;
  OR?: InputMaybe<Array<OpsCompanyfundCopyHavingInput>>;
  average?: InputMaybe<OpsCompanyfundCopyHavingAverageInput>;
  distinctCount?: InputMaybe<OpsCompanyfundCopyHavingDistinctCountInput>;
  max?: InputMaybe<OpsCompanyfundCopyHavingMaxInput>;
  min?: InputMaybe<OpsCompanyfundCopyHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsCompanyfundCopyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsCompanyfundCopyHavingStddevSampleInput>;
  sum?: InputMaybe<OpsCompanyfundCopyHavingSumInput>;
  variancePopulation?: InputMaybe<OpsCompanyfundCopyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsCompanyfundCopyHavingVarianceSampleInput>;
};

export type OpsCompanyfundCopyHavingMaxInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingMinInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingStddevPopulationInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingStddevSampleInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingSumInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingVariancePopulationInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyHavingVarianceSampleInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundCopyMaxAggregates = {
  __typename?: "OpsCompanyfundCopyMaxAggregates";
  /** Maximum of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopyMinAggregates = {
  __typename?: "OpsCompanyfundCopyMinAggregates";
  /** Minimum of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopyStddevPopulationAggregates = {
  __typename?: "OpsCompanyfundCopyStddevPopulationAggregates";
  /** Population standard deviation of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopyStddevSampleAggregates = {
  __typename?: "OpsCompanyfundCopyStddevSampleAggregates";
  /** Sample standard deviation of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopySumAggregates = {
  __typename?: "OpsCompanyfundCopySumAggregates";
  /** Sum of cocMultiple across the matching connection */
  cocMultiple: Scalars["Float"]["output"];
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigFloat"]["output"];
  /** Sum of currentEv across the matching connection */
  currentEv: Scalars["Float"]["output"];
  /** Sum of currentMultiple across the matching connection */
  currentMultiple: Scalars["Float"]["output"];
  /** Sum of currentNav across the matching connection */
  currentNav: Scalars["Float"]["output"];
  /** Sum of currentNavFromFund across the matching connection */
  currentNavFromFund: Scalars["Float"]["output"];
  /** Sum of currentOwned across the matching connection */
  currentOwned: Scalars["Float"]["output"];
  /** Sum of currentPostValuation across the matching connection */
  currentPostValuation: Scalars["Float"]["output"];
  /** Sum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital: Scalars["Float"]["output"];
  /** Sum of evAsFundReturner across the matching connection */
  evAsFundReturner: Scalars["Float"]["output"];
  /** Sum of fundId across the matching connection */
  fundId: Scalars["BigFloat"]["output"];
  /** Sum of goingInOwnership across the matching connection */
  goingInOwnership: Scalars["Float"]["output"];
  /** Sum of grossMargins across the matching connection */
  grossMargins: Scalars["Float"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of initialInvestment across the matching connection */
  initialInvestment: Scalars["Float"]["output"];
  /** Sum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund: Scalars["Float"]["output"];
  /** Sum of lastRound across the matching connection */
  lastRound: Scalars["Float"]["output"];
  /** Sum of latestExcitementScore across the matching connection */
  latestExcitementScore: Scalars["Float"]["output"];
  /** Sum of mtpEv across the matching connection */
  mtpEv: Scalars["Float"]["output"];
  /** Sum of realisedValue across the matching connection */
  realisedValue: Scalars["Float"]["output"];
  /** Sum of responsibleId across the matching connection */
  responsibleId: Scalars["BigFloat"]["output"];
  /** Sum of revenue across the matching connection */
  revenue: Scalars["Float"]["output"];
  /** Sum of secondResponsibleId across the matching connection */
  secondResponsibleId: Scalars["BigFloat"]["output"];
  /** Sum of totalInvestedCapital across the matching connection */
  totalInvestedCapital: Scalars["Float"]["output"];
  /** Sum of unrealisedValue across the matching connection */
  unrealisedValue: Scalars["Float"]["output"];
};

export type OpsCompanyfundCopyVariancePopulationAggregates = {
  __typename?: "OpsCompanyfundCopyVariancePopulationAggregates";
  /** Population variance of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundCopyVarianceSampleAggregates = {
  __typename?: "OpsCompanyfundCopyVarianceSampleAggregates";
  /** Sample variance of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundDistinctCountAggregateFilter = {
  announced?: InputMaybe<BigIntFilter>;
  braggy?: InputMaybe<BigIntFilter>;
  cashoutDate?: InputMaybe<BigIntFilter>;
  cashoutGroup?: InputMaybe<BigIntFilter>;
  coInvestors?: InputMaybe<BigIntFilter>;
  cocMultiple?: InputMaybe<BigIntFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  currentEv?: InputMaybe<BigIntFilter>;
  currentMultiple?: InputMaybe<BigIntFilter>;
  currentNav?: InputMaybe<BigIntFilter>;
  currentNavFromFund?: InputMaybe<BigIntFilter>;
  currentOwned?: InputMaybe<BigIntFilter>;
  currentPostValuation?: InputMaybe<BigIntFilter>;
  desiredFollowonCapital?: InputMaybe<BigIntFilter>;
  evAsFundReturner?: InputMaybe<BigIntFilter>;
  firstInvestmentDate?: InputMaybe<BigIntFilter>;
  fundId?: InputMaybe<BigIntFilter>;
  goingInOwnership?: InputMaybe<BigIntFilter>;
  grossMargins?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  initialInvestment?: InputMaybe<BigIntFilter>;
  initialStage?: InputMaybe<BigIntFilter>;
  investedCapitalFromFund?: InputMaybe<BigIntFilter>;
  investmentType?: InputMaybe<BigIntFilter>;
  lastRound?: InputMaybe<BigIntFilter>;
  latestExcitementScore?: InputMaybe<BigIntFilter>;
  mostRecentRound?: InputMaybe<BigIntFilter>;
  mtpEv?: InputMaybe<BigIntFilter>;
  realisedValue?: InputMaybe<BigIntFilter>;
  responsibleId?: InputMaybe<BigIntFilter>;
  revenue?: InputMaybe<BigIntFilter>;
  secondResponsibleId?: InputMaybe<BigIntFilter>;
  source?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  totalInvestedCapital?: InputMaybe<BigIntFilter>;
  trelloId?: InputMaybe<BigIntFilter>;
  tsSigned?: InputMaybe<BigIntFilter>;
  unrealisedValue?: InputMaybe<BigIntFilter>;
};

export type OpsCompanyfundDistinctCountAggregates = {
  __typename?: "OpsCompanyfundDistinctCountAggregates";
  /** Distinct count of announced across the matching connection */
  announced?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of braggy across the matching connection */
  braggy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashoutDate across the matching connection */
  cashoutDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cashoutGroup across the matching connection */
  cashoutGroup?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of coInvestors across the matching connection */
  coInvestors?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of firstInvestmentDate across the matching connection */
  firstInvestmentDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initialStage across the matching connection */
  initialStage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of investmentType across the matching connection */
  investmentType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mostRecentRound across the matching connection */
  mostRecentRound?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revenue across the matching connection */
  revenue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tsSigned across the matching connection */
  tsSigned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyfundFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsCompanyfundFilter>>;
  /** Filter by the object’s `announced` field. */
  announced?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `braggy` field. */
  braggy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cashoutDate` field. */
  cashoutDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cashoutGroup` field. */
  cashoutGroup?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coInvestors` field. */
  coInvestors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cocMultiple` field. */
  cocMultiple?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<OpsCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `currentEv` field. */
  currentEv?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentMultiple` field. */
  currentMultiple?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentNav` field. */
  currentNav?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentNavFromFund` field. */
  currentNavFromFund?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentOwned` field. */
  currentOwned?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currentPostValuation` field. */
  currentPostValuation?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `desiredFollowonCapital` field. */
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `evAsFundReturner` field. */
  evAsFundReturner?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `firstInvestmentDate` field. */
  firstInvestmentDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fund` relation. */
  fund?: InputMaybe<OpsFundFilter>;
  /** A related `fund` exists. */
  fundExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `fundId` field. */
  fundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `goingInOwnership` field. */
  goingInOwnership?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `grossMargins` field. */
  grossMargins?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialInvestment` field. */
  initialInvestment?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `initialStage` field. */
  initialStage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `investedCapitalFromFund` field. */
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `investmentType` field. */
  investmentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastRound` field. */
  lastRound?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `latestExcitementScore` field. */
  latestExcitementScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `mostRecentRound` field. */
  mostRecentRound?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mtpEv` field. */
  mtpEv?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsCompanyfundFilter>;
  /** Filter by the object’s `opsCompanyfundcapitalsByCompanyFundId` relation. */
  opsCompanyfundcapitalsByCompanyFundId?: InputMaybe<OpsCompanyfundToManyOpsCompanyfundcapitalFilter>;
  /** Some related `opsCompanyfundcapitalsByCompanyFundId` exist. */
  opsCompanyfundcapitalsByCompanyFundIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsCompanyfundFilter>>;
  /** Filter by the object’s `realisedValue` field. */
  realisedValue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `responsible` relation. */
  responsible?: InputMaybe<OpsPersonFilter>;
  /** A related `responsible` exists. */
  responsibleExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `responsibleId` field. */
  responsibleId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `secondResponsible` relation. */
  secondResponsible?: InputMaybe<OpsPersonFilter>;
  /** A related `secondResponsible` exists. */
  secondResponsibleExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `secondResponsibleId` field. */
  secondResponsibleId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalInvestedCapital` field. */
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tsSigned` field. */
  tsSigned?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `unrealisedValue` field. */
  unrealisedValue?: InputMaybe<FloatFilter>;
};

/** Grouping methods for `OpsCompanyfund` for usage during aggregation. */
export enum OpsCompanyfundGroupBy {
  Announced = "ANNOUNCED",
  Braggy = "BRAGGY",
  CashoutDate = "CASHOUT_DATE",
  CashoutDateTruncatedToDay = "CASHOUT_DATE_TRUNCATED_TO_DAY",
  CashoutDateTruncatedToHour = "CASHOUT_DATE_TRUNCATED_TO_HOUR",
  CashoutGroup = "CASHOUT_GROUP",
  CocMultiple = "COC_MULTIPLE",
  CompanyId = "COMPANY_ID",
  CoInvestors = "CO_INVESTORS",
  CurrentEv = "CURRENT_EV",
  CurrentMultiple = "CURRENT_MULTIPLE",
  CurrentNav = "CURRENT_NAV",
  CurrentNavFromFund = "CURRENT_NAV_FROM_FUND",
  CurrentOwned = "CURRENT_OWNED",
  CurrentPostValuation = "CURRENT_POST_VALUATION",
  DesiredFollowonCapital = "DESIRED_FOLLOWON_CAPITAL",
  EvAsFundReturner = "EV_AS_FUND_RETURNER",
  FirstInvestmentDate = "FIRST_INVESTMENT_DATE",
  FirstInvestmentDateTruncatedToDay = "FIRST_INVESTMENT_DATE_TRUNCATED_TO_DAY",
  FirstInvestmentDateTruncatedToHour = "FIRST_INVESTMENT_DATE_TRUNCATED_TO_HOUR",
  FundId = "FUND_ID",
  GoingInOwnership = "GOING_IN_OWNERSHIP",
  GrossMargins = "GROSS_MARGINS",
  InitialInvestment = "INITIAL_INVESTMENT",
  InitialStage = "INITIAL_STAGE",
  InvestedCapitalFromFund = "INVESTED_CAPITAL_FROM_FUND",
  InvestmentType = "INVESTMENT_TYPE",
  LastRound = "LAST_ROUND",
  LatestExcitementScore = "LATEST_EXCITEMENT_SCORE",
  MostRecentRound = "MOST_RECENT_ROUND",
  MtpEv = "MTP_EV",
  RealisedValue = "REALISED_VALUE",
  ResponsibleId = "RESPONSIBLE_ID",
  Revenue = "REVENUE",
  SecondResponsibleId = "SECOND_RESPONSIBLE_ID",
  Source = "SOURCE",
  SourceType = "SOURCE_TYPE",
  Status = "STATUS",
  TotalInvestedCapital = "TOTAL_INVESTED_CAPITAL",
  TrelloId = "TRELLO_ID",
  TsSigned = "TS_SIGNED",
  TsSignedTruncatedToDay = "TS_SIGNED_TRUNCATED_TO_DAY",
  TsSignedTruncatedToHour = "TS_SIGNED_TRUNCATED_TO_HOUR",
  UnrealisedValue = "UNREALISED_VALUE",
}

export type OpsCompanyfundHavingAverageInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingDistinctCountInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `OpsCompanyfund` aggregates. */
export type OpsCompanyfundHavingInput = {
  AND?: InputMaybe<Array<OpsCompanyfundHavingInput>>;
  OR?: InputMaybe<Array<OpsCompanyfundHavingInput>>;
  average?: InputMaybe<OpsCompanyfundHavingAverageInput>;
  distinctCount?: InputMaybe<OpsCompanyfundHavingDistinctCountInput>;
  max?: InputMaybe<OpsCompanyfundHavingMaxInput>;
  min?: InputMaybe<OpsCompanyfundHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsCompanyfundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsCompanyfundHavingStddevSampleInput>;
  sum?: InputMaybe<OpsCompanyfundHavingSumInput>;
  variancePopulation?: InputMaybe<OpsCompanyfundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsCompanyfundHavingVarianceSampleInput>;
};

export type OpsCompanyfundHavingMaxInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingMinInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingStddevPopulationInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingStddevSampleInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingSumInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingVariancePopulationInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundHavingVarianceSampleInput = {
  cashoutDate?: InputMaybe<HavingDatetimeFilter>;
  cocMultiple?: InputMaybe<HavingFloatFilter>;
  companyId?: InputMaybe<HavingBigintFilter>;
  currentEv?: InputMaybe<HavingFloatFilter>;
  currentMultiple?: InputMaybe<HavingFloatFilter>;
  currentNav?: InputMaybe<HavingFloatFilter>;
  currentNavFromFund?: InputMaybe<HavingFloatFilter>;
  currentOwned?: InputMaybe<HavingFloatFilter>;
  currentPostValuation?: InputMaybe<HavingFloatFilter>;
  desiredFollowonCapital?: InputMaybe<HavingFloatFilter>;
  evAsFundReturner?: InputMaybe<HavingFloatFilter>;
  firstInvestmentDate?: InputMaybe<HavingDatetimeFilter>;
  fundId?: InputMaybe<HavingBigintFilter>;
  goingInOwnership?: InputMaybe<HavingFloatFilter>;
  grossMargins?: InputMaybe<HavingFloatFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  initialInvestment?: InputMaybe<HavingFloatFilter>;
  investedCapitalFromFund?: InputMaybe<HavingFloatFilter>;
  lastRound?: InputMaybe<HavingFloatFilter>;
  latestExcitementScore?: InputMaybe<HavingFloatFilter>;
  mtpEv?: InputMaybe<HavingFloatFilter>;
  realisedValue?: InputMaybe<HavingFloatFilter>;
  responsibleId?: InputMaybe<HavingBigintFilter>;
  revenue?: InputMaybe<HavingFloatFilter>;
  secondResponsibleId?: InputMaybe<HavingBigintFilter>;
  totalInvestedCapital?: InputMaybe<HavingFloatFilter>;
  tsSigned?: InputMaybe<HavingDatetimeFilter>;
  unrealisedValue?: InputMaybe<HavingFloatFilter>;
};

export type OpsCompanyfundMaxAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigIntFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigIntFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigIntFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundMaxAggregates = {
  __typename?: "OpsCompanyfundMaxAggregates";
  /** Maximum of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundMinAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigIntFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigIntFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigIntFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigIntFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundMinAggregates = {
  __typename?: "OpsCompanyfundMinAggregates";
  /** Minimum of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundStddevPopulationAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundStddevPopulationAggregates = {
  __typename?: "OpsCompanyfundStddevPopulationAggregates";
  /** Population standard deviation of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundStddevSampleAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundStddevSampleAggregates = {
  __typename?: "OpsCompanyfundStddevSampleAggregates";
  /** Sample standard deviation of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundSumAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundSumAggregates = {
  __typename?: "OpsCompanyfundSumAggregates";
  /** Sum of cocMultiple across the matching connection */
  cocMultiple: Scalars["Float"]["output"];
  /** Sum of companyId across the matching connection */
  companyId: Scalars["BigFloat"]["output"];
  /** Sum of currentEv across the matching connection */
  currentEv: Scalars["Float"]["output"];
  /** Sum of currentMultiple across the matching connection */
  currentMultiple: Scalars["Float"]["output"];
  /** Sum of currentNav across the matching connection */
  currentNav: Scalars["Float"]["output"];
  /** Sum of currentNavFromFund across the matching connection */
  currentNavFromFund: Scalars["Float"]["output"];
  /** Sum of currentOwned across the matching connection */
  currentOwned: Scalars["Float"]["output"];
  /** Sum of currentPostValuation across the matching connection */
  currentPostValuation: Scalars["Float"]["output"];
  /** Sum of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital: Scalars["Float"]["output"];
  /** Sum of evAsFundReturner across the matching connection */
  evAsFundReturner: Scalars["Float"]["output"];
  /** Sum of fundId across the matching connection */
  fundId: Scalars["BigFloat"]["output"];
  /** Sum of goingInOwnership across the matching connection */
  goingInOwnership: Scalars["Float"]["output"];
  /** Sum of grossMargins across the matching connection */
  grossMargins: Scalars["Float"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of initialInvestment across the matching connection */
  initialInvestment: Scalars["Float"]["output"];
  /** Sum of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund: Scalars["Float"]["output"];
  /** Sum of lastRound across the matching connection */
  lastRound: Scalars["Float"]["output"];
  /** Sum of latestExcitementScore across the matching connection */
  latestExcitementScore: Scalars["Float"]["output"];
  /** Sum of mtpEv across the matching connection */
  mtpEv: Scalars["Float"]["output"];
  /** Sum of realisedValue across the matching connection */
  realisedValue: Scalars["Float"]["output"];
  /** Sum of responsibleId across the matching connection */
  responsibleId: Scalars["BigFloat"]["output"];
  /** Sum of revenue across the matching connection */
  revenue: Scalars["Float"]["output"];
  /** Sum of secondResponsibleId across the matching connection */
  secondResponsibleId: Scalars["BigFloat"]["output"];
  /** Sum of totalInvestedCapital across the matching connection */
  totalInvestedCapital: Scalars["Float"]["output"];
  /** Sum of unrealisedValue across the matching connection */
  unrealisedValue: Scalars["Float"]["output"];
};

/** A filter to be used against many `OpsCompanyfundcapital` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyfundToManyOpsCompanyfundcapitalFilter = {
  /** Aggregates across related `OpsCompanyfundcapital` match the filter criteria. */
  aggregates?: InputMaybe<OpsCompanyfundcapitalAggregatesFilter>;
  /** Every related `OpsCompanyfundcapital` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OpsCompanyfundcapitalFilter>;
  /** No related `OpsCompanyfundcapital` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OpsCompanyfundcapitalFilter>;
  /** Some related `OpsCompanyfundcapital` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OpsCompanyfundcapitalFilter>;
};

export type OpsCompanyfundVariancePopulationAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundVariancePopulationAggregates = {
  __typename?: "OpsCompanyfundVariancePopulationAggregates";
  /** Population variance of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundVarianceSampleAggregateFilter = {
  cocMultiple?: InputMaybe<FloatFilter>;
  companyId?: InputMaybe<BigFloatFilter>;
  currentEv?: InputMaybe<FloatFilter>;
  currentMultiple?: InputMaybe<FloatFilter>;
  currentNav?: InputMaybe<FloatFilter>;
  currentNavFromFund?: InputMaybe<FloatFilter>;
  currentOwned?: InputMaybe<FloatFilter>;
  currentPostValuation?: InputMaybe<FloatFilter>;
  desiredFollowonCapital?: InputMaybe<FloatFilter>;
  evAsFundReturner?: InputMaybe<FloatFilter>;
  fundId?: InputMaybe<BigFloatFilter>;
  goingInOwnership?: InputMaybe<FloatFilter>;
  grossMargins?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  initialInvestment?: InputMaybe<FloatFilter>;
  investedCapitalFromFund?: InputMaybe<FloatFilter>;
  lastRound?: InputMaybe<FloatFilter>;
  latestExcitementScore?: InputMaybe<FloatFilter>;
  mtpEv?: InputMaybe<FloatFilter>;
  realisedValue?: InputMaybe<FloatFilter>;
  responsibleId?: InputMaybe<BigFloatFilter>;
  revenue?: InputMaybe<FloatFilter>;
  secondResponsibleId?: InputMaybe<BigFloatFilter>;
  totalInvestedCapital?: InputMaybe<FloatFilter>;
  unrealisedValue?: InputMaybe<FloatFilter>;
};

export type OpsCompanyfundVarianceSampleAggregates = {
  __typename?: "OpsCompanyfundVarianceSampleAggregates";
  /** Sample variance of cocMultiple across the matching connection */
  cocMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of currentEv across the matching connection */
  currentEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentMultiple across the matching connection */
  currentMultiple?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentNav across the matching connection */
  currentNav?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentNavFromFund across the matching connection */
  currentNavFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentOwned across the matching connection */
  currentOwned?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of currentPostValuation across the matching connection */
  currentPostValuation?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of desiredFollowonCapital across the matching connection */
  desiredFollowonCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of evAsFundReturner across the matching connection */
  evAsFundReturner?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of fundId across the matching connection */
  fundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of goingInOwnership across the matching connection */
  goingInOwnership?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of grossMargins across the matching connection */
  grossMargins?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of initialInvestment across the matching connection */
  initialInvestment?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of investedCapitalFromFund across the matching connection */
  investedCapitalFromFund?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of lastRound across the matching connection */
  lastRound?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of latestExcitementScore across the matching connection */
  latestExcitementScore?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of mtpEv across the matching connection */
  mtpEv?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of realisedValue across the matching connection */
  realisedValue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of responsibleId across the matching connection */
  responsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of revenue across the matching connection */
  revenue?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of secondResponsibleId across the matching connection */
  secondResponsibleId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalInvestedCapital across the matching connection */
  totalInvestedCapital?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of unrealisedValue across the matching connection */
  unrealisedValue?: Maybe<Scalars["Float"]["output"]>;
};

export type OpsCompanyfundcapital = Node & {
  __typename?: "OpsCompanyfundcapital";
  amount: Scalars["Float"]["output"];
  /** Reads a single `OpsCompanyfund` that is related to this `OpsCompanyfundcapital`. */
  companyFund?: Maybe<OpsCompanyfund>;
  companyFundId: Scalars["BigInt"]["output"];
  id: Scalars["BigInt"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sequenceNo: Scalars["Int"]["output"];
};

export type OpsCompanyfundcapitalAggregates = {
  __typename?: "OpsCompanyfundcapitalAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsCompanyfundcapitalAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsCompanyfundcapitalDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsCompanyfundcapitalMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsCompanyfundcapitalMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsCompanyfundcapitalStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsCompanyfundcapitalStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsCompanyfundcapitalSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsCompanyfundcapitalVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsCompanyfundcapitalVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `OpsCompanyfundcapital` object types. */
export type OpsCompanyfundcapitalAggregatesFilter = {
  /** Mean average aggregate over matching `OpsCompanyfundcapital` objects. */
  average?: InputMaybe<OpsCompanyfundcapitalAverageAggregateFilter>;
  /** Distinct count aggregate over matching `OpsCompanyfundcapital` objects. */
  distinctCount?: InputMaybe<OpsCompanyfundcapitalDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `OpsCompanyfundcapital` object to be included within the aggregate. */
  filter?: InputMaybe<OpsCompanyfundcapitalFilter>;
  /** Maximum aggregate over matching `OpsCompanyfundcapital` objects. */
  max?: InputMaybe<OpsCompanyfundcapitalMaxAggregateFilter>;
  /** Minimum aggregate over matching `OpsCompanyfundcapital` objects. */
  min?: InputMaybe<OpsCompanyfundcapitalMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `OpsCompanyfundcapital` objects. */
  stddevPopulation?: InputMaybe<OpsCompanyfundcapitalStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `OpsCompanyfundcapital` objects. */
  stddevSample?: InputMaybe<OpsCompanyfundcapitalStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `OpsCompanyfundcapital` objects. */
  sum?: InputMaybe<OpsCompanyfundcapitalSumAggregateFilter>;
  /** Population variance aggregate over matching `OpsCompanyfundcapital` objects. */
  variancePopulation?: InputMaybe<OpsCompanyfundcapitalVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `OpsCompanyfundcapital` objects. */
  varianceSample?: InputMaybe<OpsCompanyfundcapitalVarianceSampleAggregateFilter>;
};

export type OpsCompanyfundcapitalAverageAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigFloatFilter>;
};

export type OpsCompanyfundcapitalAverageAggregates = {
  __typename?: "OpsCompanyfundcapitalAverageAggregates";
  /** Mean average of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `OpsCompanyfundcapital` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OpsCompanyfundcapitalCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `companyFundId` field. */
  companyFundId?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `sequenceNo` field. */
  sequenceNo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OpsCompanyfundcapitalDistinctCountAggregateFilter = {
  amount?: InputMaybe<BigIntFilter>;
  companyFundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  sequenceNo?: InputMaybe<BigIntFilter>;
};

export type OpsCompanyfundcapitalDistinctCountAggregates = {
  __typename?: "OpsCompanyfundcapitalDistinctCountAggregates";
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsCompanyfundcapital` object types. All fields are combined with a logical ‘and.’ */
export type OpsCompanyfundcapitalFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsCompanyfundcapitalFilter>>;
  /** Filter by the object’s `companyFund` relation. */
  companyFund?: InputMaybe<OpsCompanyfundFilter>;
  /** Filter by the object’s `companyFundId` field. */
  companyFundId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsCompanyfundcapitalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsCompanyfundcapitalFilter>>;
  /** Filter by the object’s `sequenceNo` field. */
  sequenceNo?: InputMaybe<IntFilter>;
};

/** Grouping methods for `OpsCompanyfundcapital` for usage during aggregation. */
export enum OpsCompanyfundcapitalGroupBy {
  Amount = "AMOUNT",
  CompanyFundId = "COMPANY_FUND_ID",
  SequenceNo = "SEQUENCE_NO",
}

export type OpsCompanyfundcapitalHavingAverageInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingDistinctCountInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `OpsCompanyfundcapital` aggregates. */
export type OpsCompanyfundcapitalHavingInput = {
  AND?: InputMaybe<Array<OpsCompanyfundcapitalHavingInput>>;
  OR?: InputMaybe<Array<OpsCompanyfundcapitalHavingInput>>;
  average?: InputMaybe<OpsCompanyfundcapitalHavingAverageInput>;
  distinctCount?: InputMaybe<OpsCompanyfundcapitalHavingDistinctCountInput>;
  max?: InputMaybe<OpsCompanyfundcapitalHavingMaxInput>;
  min?: InputMaybe<OpsCompanyfundcapitalHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsCompanyfundcapitalHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsCompanyfundcapitalHavingStddevSampleInput>;
  sum?: InputMaybe<OpsCompanyfundcapitalHavingSumInput>;
  variancePopulation?: InputMaybe<OpsCompanyfundcapitalHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsCompanyfundcapitalHavingVarianceSampleInput>;
};

export type OpsCompanyfundcapitalHavingMaxInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingMinInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingStddevPopulationInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingStddevSampleInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingSumInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingVariancePopulationInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalHavingVarianceSampleInput = {
  amount?: InputMaybe<HavingFloatFilter>;
  companyFundId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  sequenceNo?: InputMaybe<HavingIntFilter>;
};

export type OpsCompanyfundcapitalMaxAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  sequenceNo?: InputMaybe<IntFilter>;
};

export type OpsCompanyfundcapitalMaxAggregates = {
  __typename?: "OpsCompanyfundcapitalMaxAggregates";
  /** Maximum of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Maximum of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["Int"]["output"]>;
};

export type OpsCompanyfundcapitalMinAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  sequenceNo?: InputMaybe<IntFilter>;
};

export type OpsCompanyfundcapitalMinAggregates = {
  __typename?: "OpsCompanyfundcapitalMinAggregates";
  /** Minimum of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Minimum of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["Int"]["output"]>;
};

export type OpsCompanyfundcapitalStddevPopulationAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigFloatFilter>;
};

export type OpsCompanyfundcapitalStddevPopulationAggregates = {
  __typename?: "OpsCompanyfundcapitalStddevPopulationAggregates";
  /** Population standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsCompanyfundcapitalStddevSampleAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigFloatFilter>;
};

export type OpsCompanyfundcapitalStddevSampleAggregates = {
  __typename?: "OpsCompanyfundcapitalStddevSampleAggregates";
  /** Sample standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsCompanyfundcapitalSumAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigIntFilter>;
};

export type OpsCompanyfundcapitalSumAggregates = {
  __typename?: "OpsCompanyfundcapitalSumAggregates";
  /** Sum of amount across the matching connection */
  amount: Scalars["Float"]["output"];
  /** Sum of companyFundId across the matching connection */
  companyFundId: Scalars["BigFloat"]["output"];
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
  /** Sum of sequenceNo across the matching connection */
  sequenceNo: Scalars["BigInt"]["output"];
};

export type OpsCompanyfundcapitalVariancePopulationAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigFloatFilter>;
};

export type OpsCompanyfundcapitalVariancePopulationAggregates = {
  __typename?: "OpsCompanyfundcapitalVariancePopulationAggregates";
  /** Population variance of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsCompanyfundcapitalVarianceSampleAggregateFilter = {
  amount?: InputMaybe<FloatFilter>;
  companyFundId?: InputMaybe<BigFloatFilter>;
  id?: InputMaybe<BigFloatFilter>;
  sequenceNo?: InputMaybe<BigFloatFilter>;
};

export type OpsCompanyfundcapitalVarianceSampleAggregates = {
  __typename?: "OpsCompanyfundcapitalVarianceSampleAggregates";
  /** Sample variance of amount across the matching connection */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of companyFundId across the matching connection */
  companyFundId?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of sequenceNo across the matching connection */
  sequenceNo?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `OpsCompanyfundcapital` values. */
export type OpsCompanyfundcapitalsConnection = {
  __typename?: "OpsCompanyfundcapitalsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyfundcapitalAggregates>;
  /** A list of edges which contains the `OpsCompanyfundcapital` and cursor to aid in pagination. */
  edges: Array<OpsCompanyfundcapitalsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyfundcapitalAggregates>>;
  /** A list of `OpsCompanyfundcapital` objects. */
  nodes: Array<OpsCompanyfundcapital>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompanyfundcapital` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompanyfundcapital` values. */
export type OpsCompanyfundcapitalsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyfundcapitalGroupBy>;
  having?: InputMaybe<OpsCompanyfundcapitalHavingInput>;
};

/** A `OpsCompanyfundcapital` edge in the connection. */
export type OpsCompanyfundcapitalsEdge = {
  __typename?: "OpsCompanyfundcapitalsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompanyfundcapital` at the end of the edge. */
  node: OpsCompanyfundcapital;
};

/** Methods to use when ordering `OpsCompanyfundcapital`. */
export enum OpsCompanyfundcapitalsOrderBy {
  AmountAsc = "AMOUNT_ASC",
  AmountDesc = "AMOUNT_DESC",
  CompanyFundIdAsc = "COMPANY_FUND_ID_ASC",
  CompanyFundIdDesc = "COMPANY_FUND_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SequenceNoAsc = "SEQUENCE_NO_ASC",
  SequenceNoDesc = "SEQUENCE_NO_DESC",
}

/** A connection to a list of `OpsCompanyfund` values. */
export type OpsCompanyfundsConnection = {
  __typename?: "OpsCompanyfundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyfundAggregates>;
  /** A list of edges which contains the `OpsCompanyfund` and cursor to aid in pagination. */
  edges: Array<OpsCompanyfundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyfundAggregates>>;
  /** A list of `OpsCompanyfund` objects. */
  nodes: Array<OpsCompanyfund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompanyfund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompanyfund` values. */
export type OpsCompanyfundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyfundGroupBy>;
  having?: InputMaybe<OpsCompanyfundHavingInput>;
};

/** A `OpsCompanyfund` edge in the connection. */
export type OpsCompanyfundsEdge = {
  __typename?: "OpsCompanyfundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompanyfund` at the end of the edge. */
  node: OpsCompanyfund;
};

/** Methods to use when ordering `OpsCompanyfund`. */
export enum OpsCompanyfundsOrderBy {
  AnnouncedAsc = "ANNOUNCED_ASC",
  AnnouncedDesc = "ANNOUNCED_DESC",
  BraggyAsc = "BRAGGY_ASC",
  BraggyDesc = "BRAGGY_DESC",
  CashoutDateAsc = "CASHOUT_DATE_ASC",
  CashoutDateDesc = "CASHOUT_DATE_DESC",
  CashoutGroupAsc = "CASHOUT_GROUP_ASC",
  CashoutGroupDesc = "CASHOUT_GROUP_DESC",
  CocMultipleAsc = "COC_MULTIPLE_ASC",
  CocMultipleDesc = "COC_MULTIPLE_DESC",
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CoInvestorsAsc = "CO_INVESTORS_ASC",
  CoInvestorsDesc = "CO_INVESTORS_DESC",
  CurrentEvAsc = "CURRENT_EV_ASC",
  CurrentEvDesc = "CURRENT_EV_DESC",
  CurrentMultipleAsc = "CURRENT_MULTIPLE_ASC",
  CurrentMultipleDesc = "CURRENT_MULTIPLE_DESC",
  CurrentNavAsc = "CURRENT_NAV_ASC",
  CurrentNavDesc = "CURRENT_NAV_DESC",
  CurrentNavFromFundAsc = "CURRENT_NAV_FROM_FUND_ASC",
  CurrentNavFromFundDesc = "CURRENT_NAV_FROM_FUND_DESC",
  CurrentOwnedAsc = "CURRENT_OWNED_ASC",
  CurrentOwnedDesc = "CURRENT_OWNED_DESC",
  CurrentPostValuationAsc = "CURRENT_POST_VALUATION_ASC",
  CurrentPostValuationDesc = "CURRENT_POST_VALUATION_DESC",
  DesiredFollowonCapitalAsc = "DESIRED_FOLLOWON_CAPITAL_ASC",
  DesiredFollowonCapitalDesc = "DESIRED_FOLLOWON_CAPITAL_DESC",
  EvAsFundReturnerAsc = "EV_AS_FUND_RETURNER_ASC",
  EvAsFundReturnerDesc = "EV_AS_FUND_RETURNER_DESC",
  FirstInvestmentDateAsc = "FIRST_INVESTMENT_DATE_ASC",
  FirstInvestmentDateDesc = "FIRST_INVESTMENT_DATE_DESC",
  FundIdAsc = "FUND_ID_ASC",
  FundIdDesc = "FUND_ID_DESC",
  GoingInOwnershipAsc = "GOING_IN_OWNERSHIP_ASC",
  GoingInOwnershipDesc = "GOING_IN_OWNERSHIP_DESC",
  GrossMarginsAsc = "GROSS_MARGINS_ASC",
  GrossMarginsDesc = "GROSS_MARGINS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InitialInvestmentAsc = "INITIAL_INVESTMENT_ASC",
  InitialInvestmentDesc = "INITIAL_INVESTMENT_DESC",
  InitialStageAsc = "INITIAL_STAGE_ASC",
  InitialStageDesc = "INITIAL_STAGE_DESC",
  InvestedCapitalFromFundAsc = "INVESTED_CAPITAL_FROM_FUND_ASC",
  InvestedCapitalFromFundDesc = "INVESTED_CAPITAL_FROM_FUND_DESC",
  InvestmentTypeAsc = "INVESTMENT_TYPE_ASC",
  InvestmentTypeDesc = "INVESTMENT_TYPE_DESC",
  LastRoundAsc = "LAST_ROUND_ASC",
  LastRoundDesc = "LAST_ROUND_DESC",
  LatestExcitementScoreAsc = "LATEST_EXCITEMENT_SCORE_ASC",
  LatestExcitementScoreDesc = "LATEST_EXCITEMENT_SCORE_DESC",
  MostRecentRoundAsc = "MOST_RECENT_ROUND_ASC",
  MostRecentRoundDesc = "MOST_RECENT_ROUND_DESC",
  MtpEvAsc = "MTP_EV_ASC",
  MtpEvDesc = "MTP_EV_DESC",
  Natural = "NATURAL",
  OpsCompanyfundcapitalsByCompanyFundIdAverageAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdAverageSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_AVERAGE_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdCountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_COUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdCountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_COUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdDistinctCountSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_DISTINCT_COUNT_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMaxSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MAX_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMinAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMinAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMinCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMinCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMinIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMinIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdMinSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdMinSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_MIN_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevPopulationSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_POPULATION_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdStddevSampleSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_STDDEV_SAMPLE_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdSumAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdSumAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdSumCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdSumCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdSumIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdSumIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdSumSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdSumSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_SUM_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVariancePopulationSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_POPULATION_SEQUENCE_NO_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleAmountAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_AMOUNT_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleAmountDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_AMOUNT_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleCompanyFundIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_COMPANY_FUND_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleCompanyFundIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_COMPANY_FUND_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleIdAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_ID_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleIdDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_ID_DESC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleSequenceNoAsc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_SEQUENCE_NO_ASC",
  OpsCompanyfundcapitalsByCompanyFundIdVarianceSampleSequenceNoDesc = "OPS_COMPANYFUNDCAPITALS_BY_COMPANY_FUND_ID_VARIANCE_SAMPLE_SEQUENCE_NO_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RealisedValueAsc = "REALISED_VALUE_ASC",
  RealisedValueDesc = "REALISED_VALUE_DESC",
  ResponsibleIdAsc = "RESPONSIBLE_ID_ASC",
  ResponsibleIdDesc = "RESPONSIBLE_ID_DESC",
  RevenueAsc = "REVENUE_ASC",
  RevenueDesc = "REVENUE_DESC",
  SecondResponsibleIdAsc = "SECOND_RESPONSIBLE_ID_ASC",
  SecondResponsibleIdDesc = "SECOND_RESPONSIBLE_ID_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TotalInvestedCapitalAsc = "TOTAL_INVESTED_CAPITAL_ASC",
  TotalInvestedCapitalDesc = "TOTAL_INVESTED_CAPITAL_DESC",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
  TsSignedAsc = "TS_SIGNED_ASC",
  TsSignedDesc = "TS_SIGNED_DESC",
  UnrealisedValueAsc = "UNREALISED_VALUE_ASC",
  UnrealisedValueDesc = "UNREALISED_VALUE_DESC",
}

export type OpsFund = Node & {
  __typename?: "OpsFund";
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["BigInt"]["output"];
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyId: OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyConnection;
  modifiedAt: Scalars["Datetime"]["output"];
  mtp?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpFundId: MtpCompanyfundsConnection;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyId: OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompaniesByOpsCompanyfundFundIdAndCompanyId: OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByFundId: OpsCompanyfundsConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundFundIdAndResponsibleId: OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundFundIdAndSecondResponsibleId: OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyConnection;
  parentFund: Scalars["String"]["output"];
  vintage?: Maybe<Scalars["Datetime"]["output"]>;
};

export type OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type OpsFundMtpCompanyfundsByMtpFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

export type OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type OpsFundOpsCompanyfundsByFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsFundAggregates = {
  __typename?: "OpsFundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsFundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsFundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsFundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsFundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsFundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsFundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsFundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsFundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsFundVarianceSampleAggregates>;
};

export type OpsFundAverageAggregates = {
  __typename?: "OpsFundAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A condition to be used against `OpsFund` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OpsFundCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `mtp` field. */
  mtp?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `parentFund` field. */
  parentFund?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `vintage` field. */
  vintage?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type OpsFundDistinctCountAggregates = {
  __typename?: "OpsFundDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mtp across the matching connection */
  mtp?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of parentFund across the matching connection */
  parentFund?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of vintage across the matching connection */
  vintage?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsFund` object types. All fields are combined with a logical ‘and.’ */
export type OpsFundFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsFundFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `mtp` field. */
  mtp?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mtpCompanyfundsByMtpFundId` relation. */
  mtpCompanyfundsByMtpFundId?: InputMaybe<OpsFundToManyMtpCompanyfundFilter>;
  /** Some related `mtpCompanyfundsByMtpFundId` exist. */
  mtpCompanyfundsByMtpFundIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsFundFilter>;
  /** Filter by the object’s `opsCompanyfundsByFundId` relation. */
  opsCompanyfundsByFundId?: InputMaybe<OpsFundToManyOpsCompanyfundFilter>;
  /** Some related `opsCompanyfundsByFundId` exist. */
  opsCompanyfundsByFundIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsFundFilter>>;
  /** Filter by the object’s `parentFund` field. */
  parentFund?: InputMaybe<StringFilter>;
  /** Filter by the object’s `vintage` field. */
  vintage?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `OpsFund` for usage during aggregation. */
export enum OpsFundGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  ModifiedAt = "MODIFIED_AT",
  ModifiedAtTruncatedToDay = "MODIFIED_AT_TRUNCATED_TO_DAY",
  ModifiedAtTruncatedToHour = "MODIFIED_AT_TRUNCATED_TO_HOUR",
  Mtp = "MTP",
  Name = "NAME",
  ParentFund = "PARENT_FUND",
  Vintage = "VINTAGE",
  VintageTruncatedToDay = "VINTAGE_TRUNCATED_TO_DAY",
  VintageTruncatedToHour = "VINTAGE_TRUNCATED_TO_HOUR",
}

export type OpsFundHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OpsFund` aggregates. */
export type OpsFundHavingInput = {
  AND?: InputMaybe<Array<OpsFundHavingInput>>;
  OR?: InputMaybe<Array<OpsFundHavingInput>>;
  average?: InputMaybe<OpsFundHavingAverageInput>;
  distinctCount?: InputMaybe<OpsFundHavingDistinctCountInput>;
  max?: InputMaybe<OpsFundHavingMaxInput>;
  min?: InputMaybe<OpsFundHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsFundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsFundHavingStddevSampleInput>;
  sum?: InputMaybe<OpsFundHavingSumInput>;
  variancePopulation?: InputMaybe<OpsFundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsFundHavingVarianceSampleInput>;
};

export type OpsFundHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

export type OpsFundHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  modifiedAt?: InputMaybe<HavingDatetimeFilter>;
  vintage?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `MtpCompanyfund`. */
export type OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyConnection = {
  __typename?: "OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `MtpCompanyfund`. */
export type OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyEdge = {
  __typename?: "OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpCompanyId: MtpCompanyfundsConnection;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
};

/** A `LgCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsFundLgCompaniesByMtpCompanyfundMtpFundIdAndMtpCompanyIdManyToManyEdgeMtpCompanyfundsByMtpCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<MtpCompanyfundCondition>;
    filter?: InputMaybe<MtpCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
  };

export type OpsFundMaxAggregates = {
  __typename?: "OpsFundMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type OpsFundMinAggregates = {
  __typename?: "OpsFundMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A connection to a list of `OpsCompany` values, with data from `MtpCompanyfund`. */
export type OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyConnection = {
  __typename?: "OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany`, info from the `MtpCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values, with data from `MtpCompanyfund`. */
export type OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyGroupBy>;
  having?: InputMaybe<OpsCompanyHavingInput>;
};

/** A `OpsCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyEdge = {
  __typename?: "OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfundsByMtpOpCompanyId: MtpCompanyfundsConnection;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
};

/** A `OpsCompany` edge in the connection, with data from `MtpCompanyfund`. */
export type OpsFundOpsCompaniesByMtpCompanyfundMtpFundIdAndMtpOpCompanyIdManyToManyEdgeMtpCompanyfundsByMtpOpCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<MtpCompanyfundCondition>;
    filter?: InputMaybe<MtpCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyConnection = {
  __typename?: "OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyGroupBy>;
  having?: InputMaybe<OpsCompanyHavingInput>;
};

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyEdge = {
  __typename?: "OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByCompanyId: OpsCompanyfundsConnection;
};

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsCompaniesByOpsCompanyfundFundIdAndCompanyIdManyToManyEdgeOpsCompanyfundsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyConnection = {
  __typename?: "OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsPersonGroupBy>;
  having?: InputMaybe<OpsPersonHavingInput>;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyEdge = {
  __typename?: "OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndResponsibleIdManyToManyEdgeOpsCompanyfundsByResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyConnection = {
  __typename?: "OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsPersonGroupBy>;
  having?: InputMaybe<OpsPersonHavingInput>;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyEdge = {
  __typename?: "OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsBySecondResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsFundOpsPeopleByOpsCompanyfundFundIdAndSecondResponsibleIdManyToManyEdgeOpsCompanyfundsBySecondResponsibleIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

export type OpsFundStddevPopulationAggregates = {
  __typename?: "OpsFundStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsFundStddevSampleAggregates = {
  __typename?: "OpsFundStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsFundSumAggregates = {
  __typename?: "OpsFundSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `MtpCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsFundToManyMtpCompanyfundFilter = {
  /** Aggregates across related `MtpCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<MtpCompanyfundAggregatesFilter>;
  /** Every related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MtpCompanyfundFilter>;
  /** No related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MtpCompanyfundFilter>;
  /** Some related `MtpCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MtpCompanyfundFilter>;
};

/** A filter to be used against many `OpsCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsFundToManyOpsCompanyfundFilter = {
  /** Aggregates across related `OpsCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<OpsCompanyfundAggregatesFilter>;
  /** Every related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OpsCompanyfundFilter>;
  /** No related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OpsCompanyfundFilter>;
  /** Some related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OpsCompanyfundFilter>;
};

export type OpsFundVariancePopulationAggregates = {
  __typename?: "OpsFundVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsFundVarianceSampleAggregates = {
  __typename?: "OpsFundVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `OpsFund` values. */
export type OpsFundsConnection = {
  __typename?: "OpsFundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund` and cursor to aid in pagination. */
  edges: Array<OpsFundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values. */
export type OpsFundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection. */
export type OpsFundsEdge = {
  __typename?: "OpsFundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
};

/** Methods to use when ordering `OpsFund`. */
export enum OpsFundsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ModifiedAtAsc = "MODIFIED_AT_ASC",
  ModifiedAtDesc = "MODIFIED_AT_DESC",
  MtpAsc = "MTP_ASC",
  MtpCompanyfundsByMtpFundIdAverageBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdAverageBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdAverageCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdAverageCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdAverageCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdAverageCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdAverageCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdAverageCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdAverageEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdAverageEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdAverageFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdAverageFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdAverageFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdAverageFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdAverageFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FTE_ASC",
  MtpCompanyfundsByMtpFundIdAverageFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FTE_DESC",
  MtpCompanyfundsByMtpFundIdAverageFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FUND_ASC",
  MtpCompanyfundsByMtpFundIdAverageFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_FUND_DESC",
  MtpCompanyfundsByMtpFundIdAverageIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_ID_ASC",
  MtpCompanyfundsByMtpFundIdAverageIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_ID_DESC",
  MtpCompanyfundsByMtpFundIdAverageMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdAverageMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdAverageMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdAverageMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdAverageMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdAverageMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdAverageMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdAverageMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdAverageNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdAverageNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdAveragePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdAveragePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdAverageRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdAverageRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdAverageRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdAverageRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdAverageYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdAverageYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_AVERAGE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdCountAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_COUNT_ASC",
  MtpCompanyfundsByMtpFundIdCountDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_COUNT_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FTE_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FTE_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FUND_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_FUND_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_ID_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_ID_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdDistinctCountYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdDistinctCountYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_DISTINCT_COUNT_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdMaxBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdMaxBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdMaxCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdMaxCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdMaxCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdMaxCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdMaxCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdMaxCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdMaxEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdMaxEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdMaxFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdMaxFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdMaxFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdMaxFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdMaxFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FTE_ASC",
  MtpCompanyfundsByMtpFundIdMaxFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FTE_DESC",
  MtpCompanyfundsByMtpFundIdMaxFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FUND_ASC",
  MtpCompanyfundsByMtpFundIdMaxFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_FUND_DESC",
  MtpCompanyfundsByMtpFundIdMaxIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_ID_ASC",
  MtpCompanyfundsByMtpFundIdMaxIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_ID_DESC",
  MtpCompanyfundsByMtpFundIdMaxMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdMaxMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdMaxMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdMaxMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdMaxMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdMaxMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdMaxMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdMaxMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdMaxNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdMaxNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdMaxPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdMaxPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdMaxRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdMaxRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdMaxRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdMaxRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdMaxYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdMaxYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MAX_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdMinBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdMinBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdMinCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdMinCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdMinCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdMinCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdMinCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdMinCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdMinEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdMinEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdMinFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdMinFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdMinFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdMinFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdMinFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FTE_ASC",
  MtpCompanyfundsByMtpFundIdMinFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FTE_DESC",
  MtpCompanyfundsByMtpFundIdMinFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdMinFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdMinIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_ID_ASC",
  MtpCompanyfundsByMtpFundIdMinIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_ID_DESC",
  MtpCompanyfundsByMtpFundIdMinMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdMinMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdMinMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdMinMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdMinMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdMinMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdMinMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdMinMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdMinNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdMinNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdMinPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdMinPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdMinRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdMinRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdMinRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdMinRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdMinYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdMinYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_MIN_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdStddevPopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdStddevPopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdStddevSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdStddevSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdStddevSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdStddevSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_STDDEV_SAMPLE_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdSumBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdSumBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdSumCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdSumCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdSumCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdSumCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdSumCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdSumCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdSumEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdSumEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdSumFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdSumFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdSumFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdSumFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdSumFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FTE_ASC",
  MtpCompanyfundsByMtpFundIdSumFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FTE_DESC",
  MtpCompanyfundsByMtpFundIdSumFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FUND_ASC",
  MtpCompanyfundsByMtpFundIdSumFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_FUND_DESC",
  MtpCompanyfundsByMtpFundIdSumIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_ID_ASC",
  MtpCompanyfundsByMtpFundIdSumIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_ID_DESC",
  MtpCompanyfundsByMtpFundIdSumMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdSumMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdSumMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdSumMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdSumMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdSumMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdSumMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdSumMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdSumNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdSumNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdSumPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdSumPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdSumRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdSumRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdSumRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdSumRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdSumYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdSumYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_SUM_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FTE_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FTE_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FUND_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_FUND_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_ID_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_ID_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationPersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationPersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdVariancePopulationYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdVariancePopulationYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_POPULATION_YOY_GROWTH_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleBreakevenAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_BREAKEVEN_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleBreakevenDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_BREAKEVEN_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCapitalConcentrationAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCapitalConcentrationDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_CAPITAL_CONCENTRATION_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCompanyNameAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCompanyNameDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCompanyTypeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_COMPANY_TYPE_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleCompanyTypeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_COMPANY_TYPE_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleEvReturnFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleEvReturnFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_EV_RETURN_FUND_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFollowOnCapitalAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFollowOnCapitalDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FOLLOW_ON_CAPITAL_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFreeCashFlowAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFreeCashFlowDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FREE_CASH_FLOW_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFteAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FTE_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFteDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FTE_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFundAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FUND_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleFundDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_FUND_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_ID_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_ID_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMarginAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MARGIN_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMarginDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MARGIN_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpFundIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_FUND_ID_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpFundIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_FUND_ID_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpOpCompanyIdAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleMtpOpCompanyIdDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_MTP_OP_COMPANY_ID_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleNavFundSizeAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleNavFundSizeDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_NAV_FUND_SIZE_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSamplePersonResponsibleAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSamplePersonResponsibleDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_PERSON_RESPONSIBLE_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleRevenueAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleRevenueDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleRunwayAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_RUNWAY_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleRunwayDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_RUNWAY_DESC",
  MtpCompanyfundsByMtpFundIdVarianceSampleYoyGrowthAsc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_YOY_GROWTH_ASC",
  MtpCompanyfundsByMtpFundIdVarianceSampleYoyGrowthDesc = "MTP_COMPANYFUNDS_BY_MTP_FUND_ID_VARIANCE_SAMPLE_YOY_GROWTH_DESC",
  MtpDesc = "MTP_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OpsCompanyfundsByFundIdAverageAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdAverageAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdAverageBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_BRAGGY_ASC",
  OpsCompanyfundsByFundIdAverageBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_BRAGGY_DESC",
  OpsCompanyfundsByFundIdAverageCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdAverageCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdAverageCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdAverageCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdAverageCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdAverageCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdAverageCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdAverageCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdAverageCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdAverageCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdAverageCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdAverageCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdAverageCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdAverageCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdAverageCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdAverageCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdAverageCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdAverageCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdAverageCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdAverageCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdAverageCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdAverageCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdAverageDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdAverageDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdAverageEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdAverageEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdAverageFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdAverageFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdAverageFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_FUND_ID_ASC",
  OpsCompanyfundsByFundIdAverageFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_FUND_ID_DESC",
  OpsCompanyfundsByFundIdAverageGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdAverageGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdAverageGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdAverageGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdAverageIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_ID_ASC",
  OpsCompanyfundsByFundIdAverageIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_ID_DESC",
  OpsCompanyfundsByFundIdAverageInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdAverageInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdAverageInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdAverageInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdAverageInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdAverageInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdAverageInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdAverageInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdAverageLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdAverageLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdAverageLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdAverageLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdAverageMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdAverageMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdAverageMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_MTP_EV_ASC",
  OpsCompanyfundsByFundIdAverageMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_MTP_EV_DESC",
  OpsCompanyfundsByFundIdAverageRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdAverageRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdAverageResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdAverageResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdAverageRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_REVENUE_ASC",
  OpsCompanyfundsByFundIdAverageRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_REVENUE_DESC",
  OpsCompanyfundsByFundIdAverageSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdAverageSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdAverageSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SOURCE_ASC",
  OpsCompanyfundsByFundIdAverageSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SOURCE_DESC",
  OpsCompanyfundsByFundIdAverageSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdAverageSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdAverageStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_STATUS_ASC",
  OpsCompanyfundsByFundIdAverageStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_STATUS_DESC",
  OpsCompanyfundsByFundIdAverageTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdAverageTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdAverageTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdAverageTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdAverageTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdAverageTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdAverageUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdAverageUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_AVERAGE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdCountAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_COUNT_ASC",
  OpsCompanyfundsByFundIdCountDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_COUNT_DESC",
  OpsCompanyfundsByFundIdDistinctCountAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdDistinctCountAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdDistinctCountBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_BRAGGY_ASC",
  OpsCompanyfundsByFundIdDistinctCountBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_BRAGGY_DESC",
  OpsCompanyfundsByFundIdDistinctCountCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdDistinctCountCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdDistinctCountCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdDistinctCountCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdDistinctCountCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdDistinctCountCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdDistinctCountCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdDistinctCountCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdDistinctCountCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdDistinctCountCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdDistinctCountDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdDistinctCountDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdDistinctCountEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdDistinctCountEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdDistinctCountFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdDistinctCountFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdDistinctCountFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_FUND_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_FUND_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdDistinctCountGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdDistinctCountGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdDistinctCountGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdDistinctCountIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdDistinctCountInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdDistinctCountInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdDistinctCountInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdDistinctCountInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdDistinctCountInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdDistinctCountInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdDistinctCountInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdDistinctCountLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdDistinctCountLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdDistinctCountLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdDistinctCountLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdDistinctCountMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdDistinctCountMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdDistinctCountMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_MTP_EV_ASC",
  OpsCompanyfundsByFundIdDistinctCountMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_MTP_EV_DESC",
  OpsCompanyfundsByFundIdDistinctCountRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdDistinctCountRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdDistinctCountResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_REVENUE_ASC",
  OpsCompanyfundsByFundIdDistinctCountRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_REVENUE_DESC",
  OpsCompanyfundsByFundIdDistinctCountSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SOURCE_ASC",
  OpsCompanyfundsByFundIdDistinctCountSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SOURCE_DESC",
  OpsCompanyfundsByFundIdDistinctCountSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdDistinctCountSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdDistinctCountStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_STATUS_ASC",
  OpsCompanyfundsByFundIdDistinctCountStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_STATUS_DESC",
  OpsCompanyfundsByFundIdDistinctCountTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdDistinctCountTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdDistinctCountTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdDistinctCountTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdDistinctCountTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdDistinctCountTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdDistinctCountUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdDistinctCountUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_DISTINCT_COUNT_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdMaxAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdMaxAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdMaxBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_BRAGGY_ASC",
  OpsCompanyfundsByFundIdMaxBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_BRAGGY_DESC",
  OpsCompanyfundsByFundIdMaxCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdMaxCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdMaxCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdMaxCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdMaxCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdMaxCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdMaxCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdMaxCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdMaxCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdMaxCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdMaxCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdMaxCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdMaxCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdMaxCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdMaxCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdMaxCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdMaxCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdMaxCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdMaxCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdMaxCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdMaxCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdMaxCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdMaxDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdMaxDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdMaxEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdMaxEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdMaxFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdMaxFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdMaxFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_FUND_ID_ASC",
  OpsCompanyfundsByFundIdMaxFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_FUND_ID_DESC",
  OpsCompanyfundsByFundIdMaxGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdMaxGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdMaxGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdMaxGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdMaxIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_ID_ASC",
  OpsCompanyfundsByFundIdMaxIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_ID_DESC",
  OpsCompanyfundsByFundIdMaxInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdMaxInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdMaxInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdMaxInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdMaxInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdMaxInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdMaxInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdMaxInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdMaxLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdMaxLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdMaxLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdMaxLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdMaxMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdMaxMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdMaxMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_MTP_EV_ASC",
  OpsCompanyfundsByFundIdMaxMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_MTP_EV_DESC",
  OpsCompanyfundsByFundIdMaxRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdMaxRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdMaxResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdMaxResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdMaxRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_REVENUE_ASC",
  OpsCompanyfundsByFundIdMaxRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_REVENUE_DESC",
  OpsCompanyfundsByFundIdMaxSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdMaxSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdMaxSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SOURCE_ASC",
  OpsCompanyfundsByFundIdMaxSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SOURCE_DESC",
  OpsCompanyfundsByFundIdMaxSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdMaxSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdMaxStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_STATUS_ASC",
  OpsCompanyfundsByFundIdMaxStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_STATUS_DESC",
  OpsCompanyfundsByFundIdMaxTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdMaxTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdMaxTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdMaxTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdMaxTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdMaxTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdMaxUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdMaxUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MAX_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdMinAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdMinAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdMinBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_BRAGGY_ASC",
  OpsCompanyfundsByFundIdMinBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_BRAGGY_DESC",
  OpsCompanyfundsByFundIdMinCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdMinCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdMinCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdMinCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdMinCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdMinCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdMinCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdMinCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdMinCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdMinCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdMinCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdMinCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdMinCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdMinCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdMinCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdMinCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdMinCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdMinCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdMinCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdMinCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdMinCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdMinCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdMinDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdMinDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdMinEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdMinEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdMinFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdMinFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdMinFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_FUND_ID_ASC",
  OpsCompanyfundsByFundIdMinFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_FUND_ID_DESC",
  OpsCompanyfundsByFundIdMinGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdMinGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdMinGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdMinGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdMinIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_ID_ASC",
  OpsCompanyfundsByFundIdMinIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_ID_DESC",
  OpsCompanyfundsByFundIdMinInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdMinInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdMinInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdMinInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdMinInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdMinInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdMinInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdMinInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdMinLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdMinLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdMinLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdMinLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdMinMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdMinMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdMinMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_MTP_EV_ASC",
  OpsCompanyfundsByFundIdMinMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_MTP_EV_DESC",
  OpsCompanyfundsByFundIdMinRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdMinRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdMinResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdMinResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdMinRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_REVENUE_ASC",
  OpsCompanyfundsByFundIdMinRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_REVENUE_DESC",
  OpsCompanyfundsByFundIdMinSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdMinSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdMinSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SOURCE_ASC",
  OpsCompanyfundsByFundIdMinSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SOURCE_DESC",
  OpsCompanyfundsByFundIdMinSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdMinSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdMinStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_STATUS_ASC",
  OpsCompanyfundsByFundIdMinStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_STATUS_DESC",
  OpsCompanyfundsByFundIdMinTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdMinTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdMinTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdMinTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdMinTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdMinTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdMinUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdMinUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_MIN_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdStddevPopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdStddevPopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByFundIdStddevPopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdStddevPopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdStddevPopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdStddevPopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdStddevPopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdStddevPopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdStddevPopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdStddevPopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdStddevPopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdStddevPopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdStddevPopulationIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdStddevPopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdStddevPopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdStddevPopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdStddevPopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdStddevPopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdStddevPopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdStddevPopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdStddevPopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByFundIdStddevPopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByFundIdStddevPopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdStddevPopulationStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_STATUS_ASC",
  OpsCompanyfundsByFundIdStddevPopulationStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_STATUS_DESC",
  OpsCompanyfundsByFundIdStddevPopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdStddevPopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdStddevPopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdStddevPopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdStddevPopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdStddevPopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdStddevPopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdStddevPopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdStddevSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdStddevSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdStddevSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByFundIdStddevSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByFundIdStddevSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdStddevSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdStddevSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdStddevSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdStddevSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdStddevSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdStddevSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdStddevSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdStddevSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdStddevSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdStddevSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdStddevSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdStddevSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdStddevSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdStddevSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdStddevSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdStddevSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdStddevSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdStddevSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdStddevSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdStddevSampleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdStddevSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdStddevSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdStddevSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdStddevSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdStddevSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdStddevSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdStddevSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdStddevSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdStddevSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdStddevSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdStddevSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdStddevSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdStddevSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdStddevSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByFundIdStddevSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByFundIdStddevSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdStddevSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdStddevSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByFundIdStddevSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByFundIdStddevSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByFundIdStddevSampleSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByFundIdStddevSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdStddevSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdStddevSampleStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByFundIdStddevSampleStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByFundIdStddevSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdStddevSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdStddevSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdStddevSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdStddevSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdStddevSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdStddevSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdStddevSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_STDDEV_SAMPLE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdSumAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdSumAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdSumBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_BRAGGY_ASC",
  OpsCompanyfundsByFundIdSumBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_BRAGGY_DESC",
  OpsCompanyfundsByFundIdSumCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdSumCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdSumCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdSumCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdSumCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdSumCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdSumCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdSumCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdSumCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdSumCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdSumCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdSumCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdSumCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdSumCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdSumCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdSumCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdSumCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdSumCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdSumCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdSumCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdSumCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdSumCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdSumDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdSumDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdSumEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdSumEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdSumFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdSumFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdSumFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_FUND_ID_ASC",
  OpsCompanyfundsByFundIdSumFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_FUND_ID_DESC",
  OpsCompanyfundsByFundIdSumGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdSumGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdSumGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdSumGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdSumIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_ID_ASC",
  OpsCompanyfundsByFundIdSumIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_ID_DESC",
  OpsCompanyfundsByFundIdSumInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdSumInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdSumInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdSumInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdSumInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdSumInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdSumInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdSumInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdSumLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdSumLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdSumLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdSumLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdSumMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdSumMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdSumMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_MTP_EV_ASC",
  OpsCompanyfundsByFundIdSumMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_MTP_EV_DESC",
  OpsCompanyfundsByFundIdSumRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdSumRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdSumResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdSumResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdSumRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_REVENUE_ASC",
  OpsCompanyfundsByFundIdSumRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_REVENUE_DESC",
  OpsCompanyfundsByFundIdSumSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdSumSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdSumSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SOURCE_ASC",
  OpsCompanyfundsByFundIdSumSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SOURCE_DESC",
  OpsCompanyfundsByFundIdSumSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdSumSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdSumStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_STATUS_ASC",
  OpsCompanyfundsByFundIdSumStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_STATUS_DESC",
  OpsCompanyfundsByFundIdSumTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdSumTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdSumTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdSumTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdSumTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdSumTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdSumUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdSumUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_SUM_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdVariancePopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdVariancePopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByFundIdVariancePopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdVariancePopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdVariancePopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdVariancePopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdVariancePopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdVariancePopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdVariancePopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdVariancePopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdVariancePopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdVariancePopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdVariancePopulationIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdVariancePopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdVariancePopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdVariancePopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdVariancePopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdVariancePopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdVariancePopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdVariancePopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdVariancePopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByFundIdVariancePopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByFundIdVariancePopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdVariancePopulationStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_STATUS_ASC",
  OpsCompanyfundsByFundIdVariancePopulationStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_STATUS_DESC",
  OpsCompanyfundsByFundIdVariancePopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdVariancePopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdVariancePopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdVariancePopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdVariancePopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdVariancePopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdVariancePopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdVariancePopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByFundIdVarianceSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByFundIdVarianceSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByFundIdVarianceSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByFundIdVarianceSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByFundIdVarianceSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByFundIdVarianceSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByFundIdVarianceSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByFundIdVarianceSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByFundIdVarianceSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByFundIdVarianceSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByFundIdVarianceSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByFundIdVarianceSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByFundIdVarianceSampleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByFundIdVarianceSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByFundIdVarianceSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByFundIdVarianceSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByFundIdVarianceSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByFundIdVarianceSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByFundIdVarianceSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByFundIdVarianceSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByFundIdVarianceSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByFundIdVarianceSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByFundIdVarianceSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleSourceAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleSourceDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByFundIdVarianceSampleStatusAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByFundIdVarianceSampleStatusDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByFundIdVarianceSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByFundIdVarianceSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByFundIdVarianceSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByFundIdVarianceSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByFundIdVarianceSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByFundIdVarianceSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByFundIdVarianceSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByFundIdVarianceSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_FUND_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_DESC",
  ParentFundAsc = "PARENT_FUND_ASC",
  ParentFundDesc = "PARENT_FUND_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  VintageAsc = "VINTAGE_ASC",
  VintageDesc = "VINTAGE_DESC",
}

/** A connection to a list of `OpsPerson` values. */
export type OpsPeopleConnection = {
  __typename?: "OpsPeopleConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson` and cursor to aid in pagination. */
  edges: Array<OpsPeopleEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values. */
export type OpsPeopleConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsPersonGroupBy>;
  having?: InputMaybe<OpsPersonHavingInput>;
};

/** A `OpsPerson` edge in the connection. */
export type OpsPeopleEdge = {
  __typename?: "OpsPeopleEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
};

/** Methods to use when ordering `OpsPerson`. */
export enum OpsPeopleOrderBy {
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InitialsAsc = "INITIALS_ASC",
  InitialsDesc = "INITIALS_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OpsCompanyfundsByResponsibleIdAverageAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdAverageAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdAverageBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdAverageBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdAverageCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdAverageCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdAverageCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdAverageCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdAverageCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdAverageCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdAverageCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdAverageCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdAverageCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdAverageCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdAverageDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdAverageDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdAverageEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdAverageEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdAverageFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdAverageFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdAverageFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdAverageGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdAverageGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdAverageGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdAverageIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdAverageInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdAverageInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdAverageInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdAverageInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdAverageInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdAverageInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdAverageInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdAverageLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdAverageLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdAverageLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdAverageLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdAverageMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdAverageMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdAverageMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdAverageMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdAverageRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdAverageRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdAverageResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdAverageRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdAverageSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdAverageSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdAverageSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdAverageSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdAverageStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdAverageStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdAverageTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdAverageTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdAverageTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdAverageTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdAverageTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdAverageTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdAverageUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdAverageUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_AVERAGE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdCountAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_COUNT_ASC",
  OpsCompanyfundsByResponsibleIdCountDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_COUNT_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdDistinctCountUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdDistinctCountUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_DISTINCT_COUNT_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdMaxAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdMaxAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdMaxBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdMaxBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdMaxCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdMaxCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdMaxCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdMaxCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdMaxCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdMaxCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdMaxCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdMaxCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdMaxCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdMaxCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdMaxDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdMaxDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdMaxEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdMaxEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdMaxFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdMaxFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdMaxFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdMaxGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdMaxGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdMaxGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdMaxIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdMaxInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdMaxInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdMaxInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdMaxInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdMaxInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdMaxInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdMaxInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdMaxLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdMaxLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdMaxLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdMaxLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdMaxMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdMaxMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdMaxMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdMaxMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdMaxRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdMaxRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdMaxResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdMaxRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdMaxSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdMaxSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdMaxSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdMaxSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdMaxStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdMaxStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdMaxTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdMaxTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdMaxTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdMaxTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdMaxTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdMaxTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdMaxUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdMaxUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MAX_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdMinAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdMinAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdMinBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdMinBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdMinCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdMinCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdMinCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdMinCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdMinCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdMinCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdMinCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdMinCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdMinCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdMinCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdMinDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdMinDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdMinEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdMinEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdMinFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdMinFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdMinFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdMinGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdMinGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdMinGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdMinIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdMinInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdMinInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdMinInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdMinInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdMinInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdMinInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdMinInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdMinLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdMinLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdMinLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdMinLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdMinMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdMinMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdMinMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdMinMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdMinRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdMinRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdMinResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdMinRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdMinSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdMinSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdMinSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdMinSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdMinStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdMinStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdMinTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdMinTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdMinTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdMinTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdMinTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdMinTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdMinUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdMinUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_MIN_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdStddevPopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevPopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdStddevSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdStddevSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_STDDEV_SAMPLE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdSumAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdSumAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdSumBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdSumBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdSumCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdSumCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdSumCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdSumCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdSumCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdSumCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdSumCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdSumCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdSumCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdSumCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdSumDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdSumDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdSumEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdSumEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdSumFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdSumFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdSumFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdSumGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdSumGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdSumGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdSumIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdSumInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdSumInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdSumInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdSumInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdSumInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdSumInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdSumInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdSumLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdSumLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdSumLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdSumLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdSumMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdSumMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdSumMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdSumMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdSumRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdSumRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdSumResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdSumRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdSumSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdSumSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdSumSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdSumSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdSumStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdSumStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdSumTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdSumTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdSumTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdSumTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdSumTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdSumTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdSumUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdSumUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_SUM_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdVariancePopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdVariancePopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSourceAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSourceDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleStatusAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_STATUS_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleStatusDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_STATUS_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsByResponsibleIdVarianceSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsByResponsibleIdVarianceSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_RESPONSIBLE_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdAverageUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdAverageUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_AVERAGE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdCountAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_COUNT_ASC",
  OpsCompanyfundsBySecondResponsibleIdCountDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_COUNT_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdDistinctCountUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_DISTINCT_COUNT_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMaxUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMaxUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MAX_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdMinUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdMinUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_MIN_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevPopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdStddevSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_STDDEV_SAMPLE_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdSumUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdSumUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_SUM_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVariancePopulationUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_POPULATION_UNREALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleAnnouncedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_ANNOUNCED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleAnnouncedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_ANNOUNCED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleBraggyAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_BRAGGY_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleBraggyDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_BRAGGY_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCashoutDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCashoutDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCashoutGroupAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCashoutGroupDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CASHOUT_GROUP_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCocMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_COC_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCocMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_COC_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCompanyIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCompanyIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCoInvestorsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CO_INVESTORS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCoInvestorsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CO_INVESTORS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentMultipleAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentMultipleDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_MULTIPLE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentNavAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentNavDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentNavFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentNavFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_NAV_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentOwnedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_OWNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentOwnedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_OWNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentPostValuationAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleCurrentPostValuationDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_CURRENT_POST_VALUATION_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleDesiredFollowonCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleDesiredFollowonCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_DESIRED_FOLLOWON_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleEvAsFundReturnerAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleEvAsFundReturnerDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_EV_AS_FUND_RETURNER_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleFirstInvestmentDateAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleFirstInvestmentDateDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_FIRST_INVESTMENT_DATE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleFundIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_FUND_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleFundIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_FUND_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleGoingInOwnershipAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleGoingInOwnershipDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_GOING_IN_OWNERSHIP_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleGrossMarginsAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_GROSS_MARGINS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleGrossMarginsDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_GROSS_MARGINS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInitialInvestmentAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInitialInvestmentDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_INVESTMENT_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInitialStageAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_STAGE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInitialStageDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INITIAL_STAGE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInvestedCapitalFromFundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInvestedCapitalFromFundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTED_CAPITAL_FROM_FUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInvestmentTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleInvestmentTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_INVESTMENT_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleLastRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_LAST_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleLastRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_LAST_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleLatestExcitementScoreAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleLatestExcitementScoreDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_LATEST_EXCITEMENT_SCORE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleMostRecentRoundAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleMostRecentRoundDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_MOST_RECENT_ROUND_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleMtpEvAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_MTP_EV_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleMtpEvDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_MTP_EV_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleRealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_REALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleRealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_REALISED_VALUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleRevenueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_REVENUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleRevenueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_REVENUE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSecondResponsibleIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSecondResponsibleIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SECOND_RESPONSIBLE_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSourceAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSourceDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSourceTypeAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_TYPE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleSourceTypeDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_SOURCE_TYPE_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleStatusAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_STATUS_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleStatusDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_STATUS_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTotalInvestedCapitalAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTotalInvestedCapitalDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TOTAL_INVESTED_CAPITAL_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTrelloIdAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTrelloIdDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTsSignedAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TS_SIGNED_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleTsSignedDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_TS_SIGNED_DESC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleUnrealisedValueAsc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_ASC",
  OpsCompanyfundsBySecondResponsibleIdVarianceSampleUnrealisedValueDesc = "OPS_COMPANYFUNDS_BY_SECOND_RESPONSIBLE_ID_VARIANCE_SAMPLE_UNREALISED_VALUE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type OpsPerson = Node & {
  __typename?: "OpsPerson";
  email: Scalars["String"]["output"];
  id: Scalars["BigInt"]["output"];
  initials: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompaniesByOpsCompanyfundResponsibleIdAndCompanyId: OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyId: OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByResponsibleId: OpsCompanyfundsConnection;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsBySecondResponsibleId: OpsCompanyfundsConnection;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFundsByOpsCompanyfundResponsibleIdAndFundId: OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFundsByOpsCompanyfundSecondResponsibleIdAndFundId: OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleId: OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyConnection;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleId: OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyConnection;
};

export type OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type OpsPersonOpsCompanyfundsByResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

export type OpsPersonOpsCompanyfundsBySecondResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

export type OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type OpsPersonAggregates = {
  __typename?: "OpsPersonAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OpsPersonAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OpsPersonDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OpsPersonMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OpsPersonMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OpsPersonStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OpsPersonStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OpsPersonSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OpsPersonVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OpsPersonVarianceSampleAggregates>;
};

export type OpsPersonAverageAggregates = {
  __typename?: "OpsPersonAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `OpsPerson` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OpsPersonCondition = {
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `initials` field. */
  initials?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type OpsPersonDistinctCountAggregates = {
  __typename?: "OpsPersonDistinctCountAggregates";
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of initials across the matching connection */
  initials?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `OpsPerson` object types. All fields are combined with a logical ‘and.’ */
export type OpsPersonFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OpsPersonFilter>>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initials` field. */
  initials?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OpsPersonFilter>;
  /** Filter by the object’s `opsCompanyfundsByResponsibleId` relation. */
  opsCompanyfundsByResponsibleId?: InputMaybe<OpsPersonToManyOpsCompanyfundFilter>;
  /** Some related `opsCompanyfundsByResponsibleId` exist. */
  opsCompanyfundsByResponsibleIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `opsCompanyfundsBySecondResponsibleId` relation. */
  opsCompanyfundsBySecondResponsibleId?: InputMaybe<OpsPersonToManyOpsCompanyfundFilter>;
  /** Some related `opsCompanyfundsBySecondResponsibleId` exist. */
  opsCompanyfundsBySecondResponsibleIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OpsPersonFilter>>;
};

/** Grouping methods for `OpsPerson` for usage during aggregation. */
export enum OpsPersonGroupBy {
  Email = "EMAIL",
  Initials = "INITIALS",
  Name = "NAME",
}

export type OpsPersonHavingAverageInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingDistinctCountInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `OpsPerson` aggregates. */
export type OpsPersonHavingInput = {
  AND?: InputMaybe<Array<OpsPersonHavingInput>>;
  OR?: InputMaybe<Array<OpsPersonHavingInput>>;
  average?: InputMaybe<OpsPersonHavingAverageInput>;
  distinctCount?: InputMaybe<OpsPersonHavingDistinctCountInput>;
  max?: InputMaybe<OpsPersonHavingMaxInput>;
  min?: InputMaybe<OpsPersonHavingMinInput>;
  stddevPopulation?: InputMaybe<OpsPersonHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OpsPersonHavingStddevSampleInput>;
  sum?: InputMaybe<OpsPersonHavingSumInput>;
  variancePopulation?: InputMaybe<OpsPersonHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OpsPersonHavingVarianceSampleInput>;
};

export type OpsPersonHavingMaxInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingMinInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingStddevPopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingStddevSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingSumInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingVariancePopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonHavingVarianceSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type OpsPersonMaxAggregates = {
  __typename?: "OpsPersonMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export type OpsPersonMinAggregates = {
  __typename?: "OpsPersonMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyConnection = {
  __typename?: "OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsCompanyGroupBy>;
  having?: InputMaybe<OpsCompanyHavingInput>;
};

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyEdge = {
  __typename?: "OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByCompanyId: OpsCompanyfundsConnection;
};

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundResponsibleIdAndCompanyIdManyToManyEdgeOpsCompanyfundsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyConnection = {
  __typename?: "OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsCompanyAggregates>;
  /** A list of edges which contains the `OpsCompany`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsCompanyAggregates>>;
  /** A list of `OpsCompany` objects. */
  nodes: Array<OpsCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsCompany` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<OpsCompanyGroupBy>;
    having?: InputMaybe<OpsCompanyHavingInput>;
  };

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyEdge = {
  __typename?: "OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsCompany` at the end of the edge. */
  node: OpsCompany;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByCompanyId: OpsCompanyfundsConnection;
};

/** A `OpsCompany` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsCompaniesByOpsCompanyfundSecondResponsibleIdAndCompanyIdManyToManyEdgeOpsCompanyfundsByCompanyIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyConnection = {
  __typename?: "OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyEdge = {
  __typename?: "OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByFundId: OpsCompanyfundsConnection;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundResponsibleIdAndFundIdManyToManyEdgeOpsCompanyfundsByFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyConnection = {
  __typename?: "OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsFundAggregates>;
  /** A list of edges which contains the `OpsFund`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsFundAggregates>>;
  /** A list of `OpsFund` objects. */
  nodes: Array<OpsFund>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsFund` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsFund` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<OpsFundGroupBy>;
  having?: InputMaybe<OpsFundHavingInput>;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyEdge = {
  __typename?: "OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsFund` at the end of the edge. */
  node: OpsFund;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByFundId: OpsCompanyfundsConnection;
};

/** A `OpsFund` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsFundsByOpsCompanyfundSecondResponsibleIdAndFundIdManyToManyEdgeOpsCompanyfundsByFundIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyConnection = {
  __typename?: "OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<OpsPersonGroupBy>;
    having?: InputMaybe<OpsPersonHavingInput>;
  };

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyEdge = {
  __typename?: "OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsBySecondResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundResponsibleIdAndSecondResponsibleIdManyToManyEdgeOpsCompanyfundsBySecondResponsibleIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyConnection = {
  __typename?: "OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OpsPersonAggregates>;
  /** A list of edges which contains the `OpsPerson`, info from the `OpsCompanyfund`, and the cursor to aid in pagination. */
  edges: Array<OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OpsPersonAggregates>>;
  /** A list of `OpsPerson` objects. */
  nodes: Array<OpsPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OpsPerson` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OpsPerson` values, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<OpsPersonGroupBy>;
    having?: InputMaybe<OpsPersonHavingInput>;
  };

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyEdge = {
  __typename?: "OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OpsPerson` at the end of the edge. */
  node: OpsPerson;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfundsByResponsibleId: OpsCompanyfundsConnection;
};

/** A `OpsPerson` edge in the connection, with data from `OpsCompanyfund`. */
export type OpsPersonOpsPeopleByOpsCompanyfundSecondResponsibleIdAndResponsibleIdManyToManyEdgeOpsCompanyfundsByResponsibleIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<OpsCompanyfundCondition>;
    filter?: InputMaybe<OpsCompanyfundFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
  };

export type OpsPersonStddevPopulationAggregates = {
  __typename?: "OpsPersonStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsPersonStddevSampleAggregates = {
  __typename?: "OpsPersonStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsPersonSumAggregates = {
  __typename?: "OpsPersonSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `OpsCompanyfund` object types. All fields are combined with a logical ‘and.’ */
export type OpsPersonToManyOpsCompanyfundFilter = {
  /** Aggregates across related `OpsCompanyfund` match the filter criteria. */
  aggregates?: InputMaybe<OpsCompanyfundAggregatesFilter>;
  /** Every related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OpsCompanyfundFilter>;
  /** No related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OpsCompanyfundFilter>;
  /** Some related `OpsCompanyfund` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OpsCompanyfundFilter>;
};

export type OpsPersonVariancePopulationAggregates = {
  __typename?: "OpsPersonVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type OpsPersonVarianceSampleAggregates = {
  __typename?: "OpsPersonVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]["output"]>;
};

export type Query = Node & {
  __typename?: "Query";
  /**
   * Jwks Get
   *
   * Equivalent to GET /auth/.jwks
   */
  authJwks?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads and enables pagination through a set of `BasecampFundsRelationship`. */
  basecampFundsRelationships?: Maybe<BasecampFundsRelationshipsConnection>;
  /** Reads and enables pagination through a set of `Country`. */
  countries?: Maybe<CountriesConnection>;
  country?: Maybe<Country>;
  /** Reads a single `Country` using its globally unique `ID`. */
  countryByNodeId?: Maybe<Country>;
  /** Reads and enables pagination through a set of `DealroomCompany`. */
  dealroomCompanies?: Maybe<DealroomCompaniesConnection>;
  dealroomCompany?: Maybe<DealroomCompany>;
  /** Reads a single `DealroomCompany` using its globally unique `ID`. */
  dealroomCompanyByNodeId?: Maybe<DealroomCompany>;
  dealroomCompanyInvestor?: Maybe<DealroomCompanyInvestor>;
  dealroomCompanyInvestorByCompositeId?: Maybe<DealroomCompanyInvestor>;
  /** Reads a single `DealroomCompanyInvestor` using its globally unique `ID`. */
  dealroomCompanyInvestorByNodeId?: Maybe<DealroomCompanyInvestor>;
  /** Reads and enables pagination through a set of `DealroomCompanyInvestor`. */
  dealroomCompanyInvestors?: Maybe<DealroomCompanyInvestorsConnection>;
  dealroomCompanyTeam?: Maybe<DealroomCompanyTeam>;
  dealroomCompanyTeamByCompositeId?: Maybe<DealroomCompanyTeam>;
  /** Reads a single `DealroomCompanyTeam` using its globally unique `ID`. */
  dealroomCompanyTeamByNodeId?: Maybe<DealroomCompanyTeam>;
  /** Reads and enables pagination through a set of `DealroomCompanyTeam`. */
  dealroomCompanyTeams?: Maybe<DealroomCompanyTeamsConnection>;
  dealroomFounder?: Maybe<DealroomFounder>;
  /** Reads a single `DealroomFounder` using its globally unique `ID`. */
  dealroomFounderByNodeId?: Maybe<DealroomFounder>;
  /** Reads and enables pagination through a set of `DealroomFounder`. */
  dealroomFounders?: Maybe<DealroomFoundersConnection>;
  dealroomFundingRound?: Maybe<DealroomFundingRound>;
  /** Reads a single `DealroomFundingRound` using its globally unique `ID`. */
  dealroomFundingRoundByNodeId?: Maybe<DealroomFundingRound>;
  dealroomFundingRoundInvestor?: Maybe<DealroomFundingRoundInvestor>;
  dealroomFundingRoundInvestorByCompositeId?: Maybe<DealroomFundingRoundInvestor>;
  /** Reads a single `DealroomFundingRoundInvestor` using its globally unique `ID`. */
  dealroomFundingRoundInvestorByNodeId?: Maybe<DealroomFundingRoundInvestor>;
  /** Reads and enables pagination through a set of `DealroomFundingRoundInvestor`. */
  dealroomFundingRoundInvestors?: Maybe<DealroomFundingRoundInvestorsConnection>;
  /** Reads and enables pagination through a set of `DealroomFundingRound`. */
  dealroomFundingRounds?: Maybe<DealroomFundingRoundsConnection>;
  dealroomInvestor?: Maybe<DealroomInvestor>;
  /** Reads a single `DealroomInvestor` using its globally unique `ID`. */
  dealroomInvestorByNodeId?: Maybe<DealroomInvestor>;
  /** Reads and enables pagination through a set of `DealroomInvestor`. */
  dealroomInvestors?: Maybe<DealroomInvestorsConnection>;
  dealroomNews?: Maybe<DealroomNews>;
  /** Reads a single `DealroomNews` using its globally unique `ID`. */
  dealroomNewsByNodeId?: Maybe<DealroomNews>;
  /** Reads and enables pagination through a set of `DealroomNewsCopy`. */
  dealroomNewsCopies?: Maybe<DealroomNewsCopiesConnection>;
  /** Reads and enables pagination through a set of `DealroomNews`. */
  dealroomNewses?: Maybe<DealroomNewsConnection>;
  /** Reads and enables pagination through a set of `Gpt4Category`. */
  gpt4Categories?: Maybe<Gpt4CategoriesConnection>;
  /** Reads and enables pagination through a set of `Gpt4CategoriesTmp1`. */
  gpt4CategoriesTmp1s?: Maybe<Gpt4CategoriesTmp1sConnection>;
  /** Reads and enables pagination through a set of `Gpt4CategoriesTmp`. */
  gpt4CategoriesTmps?: Maybe<Gpt4CategoriesTmpsConnection>;
  /** Reads and enables pagination through a set of `Gpt4Etl1`. */
  gpt4Etl1s?: Maybe<Gpt4Etl1sConnection>;
  /** Reads and enables pagination through a set of `Gpt41CatFinal`. */
  gpt41CatFinals?: Maybe<Gpt41CatFinalsConnection>;
  /** Reads and enables pagination through a set of `Gpt41Category`. */
  gpt41Categories?: Maybe<Gpt41CategoriesConnection>;
  gpt41Category?: Maybe<Gpt41Category>;
  /** Reads a single `Gpt41Category` using its globally unique `ID`. */
  gpt41CategoryByNodeId?: Maybe<Gpt41Category>;
  /**
   * Health
   *
   * Equivalent to GET /health/
   */
  health?: Maybe<Health>;
  /** Reads and enables pagination through a set of `LgCategory`. */
  lgCategories?: Maybe<LgCategoriesConnection>;
  lgCategory?: Maybe<LgCategory>;
  lgCategoryByName?: Maybe<LgCategory>;
  /** Reads a single `LgCategory` using its globally unique `ID`. */
  lgCategoryByNodeId?: Maybe<LgCategory>;
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompanies?: Maybe<LgCompaniesConnection>;
  lgCompany?: Maybe<LgCompany>;
  /** Reads a single `LgCompany` using its globally unique `ID`. */
  lgCompanyByNodeId?: Maybe<LgCompany>;
  /** Reads and enables pagination through a set of `LgCompanyCategory`. */
  lgCompanyCategories?: Maybe<LgCompanyCategoriesConnection>;
  lgCompanyCategoryById?: Maybe<LgCompanyCategory>;
  lgCompanyComment?: Maybe<LgCompanyComment>;
  /** Reads a single `LgCompanyComment` using its globally unique `ID`. */
  lgCompanyCommentByNodeId?: Maybe<LgCompanyComment>;
  /** Reads and enables pagination through a set of `LgCompanyComment`. */
  lgCompanyComments?: Maybe<LgCompanyCommentsConnection>;
  /** Reads and enables pagination through a set of `LgCompanyCopy`. */
  lgCompanyCopies?: Maybe<LgCompanyCopiesConnection>;
  lgCompanyFile?: Maybe<LgCompanyFile>;
  /** Reads a single `LgCompanyFile` using its globally unique `ID`. */
  lgCompanyFileByNodeId?: Maybe<LgCompanyFile>;
  /** Reads and enables pagination through a set of `LgCompanyFile`. */
  lgCompanyFiles?: Maybe<LgCompanyFilesConnection>;
  lgCompanyFlag?: Maybe<LgCompanyFlag>;
  lgCompanyFlagByCompanyIdAndFlag?: Maybe<LgCompanyFlag>;
  /** Reads a single `LgCompanyFlag` using its globally unique `ID`. */
  lgCompanyFlagByNodeId?: Maybe<LgCompanyFlag>;
  /** Reads and enables pagination through a set of `LgCompanyFlag`. */
  lgCompanyFlags?: Maybe<LgCompanyFlagsConnection>;
  lgCompanyInvestor?: Maybe<LgCompanyInvestor>;
  lgCompanyInvestorByCompanyIdAndInvestorId?: Maybe<LgCompanyInvestor>;
  lgCompanyInvestorByCompositeId?: Maybe<LgCompanyInvestor>;
  /** Reads a single `LgCompanyInvestor` using its globally unique `ID`. */
  lgCompanyInvestorByNodeId?: Maybe<LgCompanyInvestor>;
  /** Reads and enables pagination through a set of `LgCompanyInvestor`. */
  lgCompanyInvestors?: Maybe<LgCompanyInvestorsConnection>;
  lgCompanyMetric?: Maybe<LgCompanyMetric>;
  /** Reads a single `LgCompanyMetric` using its globally unique `ID`. */
  lgCompanyMetricByNodeId?: Maybe<LgCompanyMetric>;
  /** Reads and enables pagination through a set of `LgCompanyMetric`. */
  lgCompanyMetrics?: Maybe<LgCompanyMetricsConnection>;
  lgCompanyNote?: Maybe<LgCompanyNote>;
  /** Reads a single `LgCompanyNote` using its globally unique `ID`. */
  lgCompanyNoteByNodeId?: Maybe<LgCompanyNote>;
  /** Reads and enables pagination through a set of `LgCompanyNote`. */
  lgCompanyNotes?: Maybe<LgCompanyNotesConnection>;
  lgCompanyTeam?: Maybe<LgCompanyTeam>;
  lgCompanyTeamByCompanyIdAndMemberId?: Maybe<LgCompanyTeam>;
  lgCompanyTeamByCompositeId?: Maybe<LgCompanyTeam>;
  /** Reads a single `LgCompanyTeam` using its globally unique `ID`. */
  lgCompanyTeamByNodeId?: Maybe<LgCompanyTeam>;
  /** Reads and enables pagination through a set of `LgCompanyTeam`. */
  lgCompanyTeams?: Maybe<LgCompanyTeamsConnection>;
  lgFile?: Maybe<LgFile>;
  /** Reads a single `LgFile` using its globally unique `ID`. */
  lgFileByNodeId?: Maybe<LgFile>;
  /** Reads and enables pagination through a set of `LgFile`. */
  lgFiles?: Maybe<LgFilesConnection>;
  lgFounder?: Maybe<LgFounder>;
  /** Reads a single `LgFounder` using its globally unique `ID`. */
  lgFounderByNodeId?: Maybe<LgFounder>;
  /** Reads and enables pagination through a set of `LgFounder`. */
  lgFounders?: Maybe<LgFoundersConnection>;
  lgFundingRound?: Maybe<LgFundingRound>;
  /** Reads a single `LgFundingRound` using its globally unique `ID`. */
  lgFundingRoundByNodeId?: Maybe<LgFundingRound>;
  lgFundingRoundInvestor?: Maybe<LgFundingRoundInvestor>;
  lgFundingRoundInvestorByCompositeId?: Maybe<LgFundingRoundInvestor>;
  lgFundingRoundInvestorByFundingRoundIdAndInvestorId?: Maybe<LgFundingRoundInvestor>;
  /** Reads a single `LgFundingRoundInvestor` using its globally unique `ID`. */
  lgFundingRoundInvestorByNodeId?: Maybe<LgFundingRoundInvestor>;
  /** Reads and enables pagination through a set of `LgFundingRoundInvestor`. */
  lgFundingRoundInvestors?: Maybe<LgFundingRoundInvestorsConnection>;
  /** Reads and enables pagination through a set of `LgFundingRound`. */
  lgFundingRounds?: Maybe<LgFundingRoundsConnection>;
  lgInvestor?: Maybe<LgInvestor>;
  /** Reads a single `LgInvestor` using its globally unique `ID`. */
  lgInvestorByNodeId?: Maybe<LgInvestor>;
  /** Reads and enables pagination through a set of `LgInvestor`. */
  lgInvestors?: Maybe<LgInvestorsConnection>;
  lgMember?: Maybe<LgMember>;
  /** Reads a single `LgMember` using its globally unique `ID`. */
  lgMemberByNodeId?: Maybe<LgMember>;
  lgMemberSetting?: Maybe<LgMemberSetting>;
  /** Reads a single `LgMemberSetting` using its globally unique `ID`. */
  lgMemberSettingByNodeId?: Maybe<LgMemberSetting>;
  /** Reads and enables pagination through a set of `LgMemberSetting`. */
  lgMemberSettings?: Maybe<LgMemberSettingsConnection>;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembers?: Maybe<LgMembersConnection>;
  lgUserFeedback?: Maybe<LgUserFeedback>;
  /** Reads a single `LgUserFeedback` using its globally unique `ID`. */
  lgUserFeedbackByNodeId?: Maybe<LgUserFeedback>;
  /** Reads and enables pagination through a set of `LgUserFeedback`. */
  lgUserFeedbacks?: Maybe<LgUserFeedbacksConnection>;
  /** Reads and enables pagination through a set of `MnEntry`. */
  mnEntries?: Maybe<MnEntriesConnection>;
  mnEntry?: Maybe<MnEntry>;
  /** Reads a single `MnEntry` using its globally unique `ID`. */
  mnEntryByNodeId?: Maybe<MnEntry>;
  /** Reads and enables pagination through a set of `MtpBasecampFund`. */
  mtpBasecampFunds?: Maybe<MtpBasecampFundsConnection>;
  mtpCompanyfund?: Maybe<MtpCompanyfund>;
  /** Reads a single `MtpCompanyfund` using its globally unique `ID`. */
  mtpCompanyfundByNodeId?: Maybe<MtpCompanyfund>;
  /** Reads and enables pagination through a set of `MtpCompanyfund`. */
  mtpCompanyfunds?: Maybe<MtpCompanyfundsConnection>;
  /** Reads and enables pagination through a set of `MtpCompanyfundsCpy`. */
  mtpCompanyfundsCpies?: Maybe<MtpCompanyfundsCpiesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars["ID"]["output"];
  nzrNotification?: Maybe<NzrNotification>;
  /** Reads a single `NzrNotification` using its globally unique `ID`. */
  nzrNotificationByNodeId?: Maybe<NzrNotification>;
  /** Reads and enables pagination through a set of `NzrNotification`. */
  nzrNotifications?: Maybe<NzrNotificationsConnection>;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunities?: Maybe<NzrOpportunitiesConnection>;
  nzrOpportunity?: Maybe<NzrOpportunity>;
  /** Reads a single `NzrOpportunity` using its globally unique `ID`. */
  nzrOpportunityByNodeId?: Maybe<NzrOpportunity>;
  nzrOpportunityEmail?: Maybe<NzrOpportunityEmail>;
  nzrOpportunityEmailByGoogleMessageId?: Maybe<NzrOpportunityEmail>;
  /** Reads a single `NzrOpportunityEmail` using its globally unique `ID`. */
  nzrOpportunityEmailByNodeId?: Maybe<NzrOpportunityEmail>;
  nzrOpportunityEmailCandidate?: Maybe<NzrOpportunityEmailCandidate>;
  /** Reads a single `NzrOpportunityEmailCandidate` using its globally unique `ID`. */
  nzrOpportunityEmailCandidateByNodeId?: Maybe<NzrOpportunityEmailCandidate>;
  /** Reads and enables pagination through a set of `NzrOpportunityEmailCandidate`. */
  nzrOpportunityEmailCandidates?: Maybe<NzrOpportunityEmailCandidatesConnection>;
  /** Reads and enables pagination through a set of `NzrOpportunityEmail`. */
  nzrOpportunityEmails?: Maybe<NzrOpportunityEmailsConnection>;
  /** Reads and enables pagination through a set of `NzrSentimentCpy`. */
  nzrSentimentCpies?: Maybe<NzrSentimentCpiesConnection>;
  nzrSentimentForm?: Maybe<NzrSentimentForm>;
  nzrSentimentFormAssignee?: Maybe<NzrSentimentFormAssignee>;
  /** Reads a single `NzrSentimentFormAssignee` using its globally unique `ID`. */
  nzrSentimentFormAssigneeByNodeId?: Maybe<NzrSentimentFormAssignee>;
  /** Reads and enables pagination through a set of `NzrSentimentFormAssignee`. */
  nzrSentimentFormAssignees?: Maybe<NzrSentimentFormAssigneesConnection>;
  /** Reads a single `NzrSentimentForm` using its globally unique `ID`. */
  nzrSentimentFormByNodeId?: Maybe<NzrSentimentForm>;
  /** Reads and enables pagination through a set of `NzrSentimentForm`. */
  nzrSentimentForms?: Maybe<NzrSentimentFormsConnection>;
  nzrUserCompanyTag?: Maybe<NzrUserCompanyTag>;
  /** Reads a single `NzrUserCompanyTag` using its globally unique `ID`. */
  nzrUserCompanyTagByNodeId?: Maybe<NzrUserCompanyTag>;
  nzrUserCompanyTagByUserIdAndCompanyId?: Maybe<NzrUserCompanyTag>;
  /** Reads and enables pagination through a set of `NzrUserCompanyTag`. */
  nzrUserCompanyTags?: Maybe<NzrUserCompanyTagsConnection>;
  /** Reads and enables pagination through a set of `OpFundCpy`. */
  opFundCpies?: Maybe<OpFundCpiesConnection>;
  /** Reads and enables pagination through a set of `OpsCompany`. */
  opsCompanies?: Maybe<OpsCompaniesConnection>;
  opsCompany?: Maybe<OpsCompany>;
  /** Reads a single `OpsCompany` using its globally unique `ID`. */
  opsCompanyByNodeId?: Maybe<OpsCompany>;
  opsCompanyfund?: Maybe<OpsCompanyfund>;
  /** Reads a single `OpsCompanyfund` using its globally unique `ID`. */
  opsCompanyfundByNodeId?: Maybe<OpsCompanyfund>;
  /** Reads and enables pagination through a set of `OpsCompanyfundCopy`. */
  opsCompanyfundCopies?: Maybe<OpsCompanyfundCopiesConnection>;
  opsCompanyfundcapital?: Maybe<OpsCompanyfundcapital>;
  /** Reads a single `OpsCompanyfundcapital` using its globally unique `ID`. */
  opsCompanyfundcapitalByNodeId?: Maybe<OpsCompanyfundcapital>;
  /** Reads and enables pagination through a set of `OpsCompanyfundcapital`. */
  opsCompanyfundcapitals?: Maybe<OpsCompanyfundcapitalsConnection>;
  /** Reads and enables pagination through a set of `OpsCompanyfund`. */
  opsCompanyfunds?: Maybe<OpsCompanyfundsConnection>;
  opsFund?: Maybe<OpsFund>;
  /** Reads a single `OpsFund` using its globally unique `ID`. */
  opsFundByNodeId?: Maybe<OpsFund>;
  /** Reads and enables pagination through a set of `OpsFund`. */
  opsFunds?: Maybe<OpsFundsConnection>;
  /** Reads and enables pagination through a set of `OpsPerson`. */
  opsPeople?: Maybe<OpsPeopleConnection>;
  opsPerson?: Maybe<OpsPerson>;
  /** Reads a single `OpsPerson` using its globally unique `ID`. */
  opsPersonByNodeId?: Maybe<OpsPerson>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /**
   * Get File Presigned Url
   *
   * Equivalent to GET /file/get
   */
  responseGetFilePresignedUrl?: Maybe<Scalars["String"]["output"]>;
  rule?: Maybe<Rule>;
  /** Reads a single `Rule` using its globally unique `ID`. */
  ruleByNodeId?: Maybe<Rule>;
  /** Reads and enables pagination through a set of `Rule`. */
  rules?: Maybe<RulesConnection>;
  signal?: Maybe<Signal>;
  /** Reads a single `Signal` using its globally unique `ID`. */
  signalByNodeId?: Maybe<Signal>;
  /** Reads and enables pagination through a set of `Signal`. */
  signals?: Maybe<SignalsConnection>;
  /** Reads and enables pagination through a set of `SignalsCopy`. */
  signalsCopies?: Maybe<SignalsCopiesConnection>;
  /** Reads and enables pagination through a set of `SrcCompany`. */
  srcCompanies?: Maybe<SrcCompaniesConnection>;
  srcCompany?: Maybe<SrcCompany>;
  /** Reads a single `SrcCompany` using its globally unique `ID`. */
  srcCompanyByNodeId?: Maybe<SrcCompany>;
  srcCompanyBySourceTypeAndSourceId?: Maybe<SrcCompany>;
  /** Reads and enables pagination through a set of `SrcCompanyCopy`. */
  srcCompanyCopies?: Maybe<SrcCompanyCopiesConnection>;
  /** Reads and enables pagination through a set of `SrcCompanyCpy`. */
  srcCompanyCpies?: Maybe<SrcCompanyCpiesConnection>;
  srcCompanyInvestor?: Maybe<SrcCompanyInvestor>;
  /** Reads a single `SrcCompanyInvestor` using its globally unique `ID`. */
  srcCompanyInvestorByNodeId?: Maybe<SrcCompanyInvestor>;
  srcCompanyInvestorBySourceTypeAndSourceId?: Maybe<SrcCompanyInvestor>;
  /** Reads and enables pagination through a set of `SrcCompanyInvestor`. */
  srcCompanyInvestors?: Maybe<SrcCompanyInvestorsConnection>;
  srcCompanyTeam?: Maybe<SrcCompanyTeam>;
  /** Reads a single `SrcCompanyTeam` using its globally unique `ID`. */
  srcCompanyTeamByNodeId?: Maybe<SrcCompanyTeam>;
  srcCompanyTeamBySourceTypeAndSourceId?: Maybe<SrcCompanyTeam>;
  /** Reads and enables pagination through a set of `SrcCompanyTeam`. */
  srcCompanyTeams?: Maybe<SrcCompanyTeamsConnection>;
  srcConfig?: Maybe<SrcConfig>;
  /** Reads a single `SrcConfig` using its globally unique `ID`. */
  srcConfigByNodeId?: Maybe<SrcConfig>;
  /** Reads and enables pagination through a set of `SrcConfig`. */
  srcConfigs?: Maybe<SrcConfigsConnection>;
  srcFounder?: Maybe<SrcFounder>;
  /** Reads a single `SrcFounder` using its globally unique `ID`. */
  srcFounderByNodeId?: Maybe<SrcFounder>;
  srcFounderBySourceTypeAndSourceId?: Maybe<SrcFounder>;
  /** Reads and enables pagination through a set of `SrcFounder`. */
  srcFounders?: Maybe<SrcFoundersConnection>;
  srcFundingRound?: Maybe<SrcFundingRound>;
  /** Reads a single `SrcFundingRound` using its globally unique `ID`. */
  srcFundingRoundByNodeId?: Maybe<SrcFundingRound>;
  srcFundingRoundBySourceTypeAndSourceId?: Maybe<SrcFundingRound>;
  srcFundingRoundInvestor?: Maybe<SrcFundingRoundInvestor>;
  /** Reads a single `SrcFundingRoundInvestor` using its globally unique `ID`. */
  srcFundingRoundInvestorByNodeId?: Maybe<SrcFundingRoundInvestor>;
  srcFundingRoundInvestorBySourceTypeAndSourceId?: Maybe<SrcFundingRoundInvestor>;
  /** Reads and enables pagination through a set of `SrcFundingRoundInvestor`. */
  srcFundingRoundInvestors?: Maybe<SrcFundingRoundInvestorsConnection>;
  /** Reads and enables pagination through a set of `SrcFundingRound`. */
  srcFundingRounds?: Maybe<SrcFundingRoundsConnection>;
  srcInvestor?: Maybe<SrcInvestor>;
  /** Reads a single `SrcInvestor` using its globally unique `ID`. */
  srcInvestorByNodeId?: Maybe<SrcInvestor>;
  srcInvestorBySourceTypeAndSourceId?: Maybe<SrcInvestor>;
  /** Reads and enables pagination through a set of `SrcInvestor`. */
  srcInvestors?: Maybe<SrcInvestorsConnection>;
  tfForm?: Maybe<TfForm>;
  /** Reads a single `TfForm` using its globally unique `ID`. */
  tfFormByNodeId?: Maybe<TfForm>;
  /** Reads and enables pagination through a set of `TfForm`. */
  tfForms?: Maybe<TfFormsConnection>;
  tfWorkspace?: Maybe<TfWorkspace>;
  /** Reads a single `TfWorkspace` using its globally unique `ID`. */
  tfWorkspaceByNodeId?: Maybe<TfWorkspace>;
  /** Reads and enables pagination through a set of `TfWorkspace`. */
  tfWorkspaces?: Maybe<TfWorkspacesConnection>;
  trelloBoard?: Maybe<TrelloBoard>;
  /** Reads a single `TrelloBoard` using its globally unique `ID`. */
  trelloBoardByNodeId?: Maybe<TrelloBoard>;
  /** Reads and enables pagination through a set of `TrelloBoard`. */
  trelloBoards?: Maybe<TrelloBoardsConnection>;
  trelloCard?: Maybe<TrelloCard>;
  /** Reads a single `TrelloCard` using its globally unique `ID`. */
  trelloCardByNodeId?: Maybe<TrelloCard>;
  trelloCardByShortLinkHash?: Maybe<TrelloCard>;
  /** Reads and enables pagination through a set of `TrelloCardLgCategory`. */
  trelloCardLgCategories?: Maybe<TrelloCardLgCategoriesConnection>;
  /** Reads and enables pagination through a set of `TrelloCard`. */
  trelloCards?: Maybe<TrelloCardsConnection>;
  trelloLabel?: Maybe<TrelloLabel>;
  /** Reads a single `TrelloLabel` using its globally unique `ID`. */
  trelloLabelByNodeId?: Maybe<TrelloLabel>;
  /** Reads and enables pagination through a set of `TrelloLabel`. */
  trelloLabels?: Maybe<TrelloLabelsConnection>;
  trelloList?: Maybe<TrelloList>;
  /** Reads a single `TrelloList` using its globally unique `ID`. */
  trelloListByNodeId?: Maybe<TrelloList>;
  /** Reads and enables pagination through a set of `TrelloList`. */
  trelloLists?: Maybe<TrelloListsConnection>;
  trelloMember?: Maybe<TrelloMember>;
  /** Reads a single `TrelloMember` using its globally unique `ID`. */
  trelloMemberByNodeId?: Maybe<TrelloMember>;
  /** Reads and enables pagination through a set of `TrelloMember`. */
  trelloMembers?: Maybe<TrelloMembersConnection>;
  userReport?: Maybe<UserReport>;
  /** Reads a single `UserReport` using its globally unique `ID`. */
  userReportByNodeId?: Maybe<UserReport>;
  userReportByUserIdAndPeriod?: Maybe<UserReport>;
  /** Reads and enables pagination through a set of `UserReport`. */
  userReports?: Maybe<UserReportsConnection>;
};

export type QueryBasecampFundsRelationshipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BasecampFundsRelationshipCondition>;
  filter?: InputMaybe<BasecampFundsRelationshipFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BasecampFundsRelationshipsOrderBy>>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<CountryCondition>;
  filter?: InputMaybe<CountryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};

export type QueryCountryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryCountryByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomCompaniesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyCondition>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompaniesOrderBy>>;
};

export type QueryDealroomCompanyArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryDealroomCompanyByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomCompanyInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDealroomCompanyInvestorByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryDealroomCompanyInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomCompanyInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyInvestorCondition>;
  filter?: InputMaybe<DealroomCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyInvestorsOrderBy>>;
};

export type QueryDealroomCompanyTeamArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDealroomCompanyTeamByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryDealroomCompanyTeamByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomCompanyTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomCompanyTeamCondition>;
  filter?: InputMaybe<DealroomCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomCompanyTeamsOrderBy>>;
};

export type QueryDealroomFounderArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryDealroomFounderByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomFoundersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFounderCondition>;
  filter?: InputMaybe<DealroomFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFoundersOrderBy>>;
};

export type QueryDealroomFundingRoundArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDealroomFundingRoundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomFundingRoundInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDealroomFundingRoundInvestorByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryDealroomFundingRoundInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomFundingRoundInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundInvestorCondition>;
  filter?: InputMaybe<DealroomFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundInvestorsOrderBy>>;
};

export type QueryDealroomFundingRoundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomFundingRoundCondition>;
  filter?: InputMaybe<DealroomFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomFundingRoundsOrderBy>>;
};

export type QueryDealroomInvestorArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryDealroomInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomInvestorCondition>;
  filter?: InputMaybe<DealroomInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomInvestorsOrderBy>>;
};

export type QueryDealroomNewsArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDealroomNewsByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryDealroomNewsCopiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomNewsCopyCondition>;
  filter?: InputMaybe<DealroomNewsCopyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomNewsCopiesOrderBy>>;
};

export type QueryDealroomNewsesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DealroomNewsCondition>;
  filter?: InputMaybe<DealroomNewsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DealroomNewsOrderBy>>;
};

export type QueryGpt4CategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt4CategoryCondition>;
  filter?: InputMaybe<Gpt4CategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt4CategoriesOrderBy>>;
};

export type QueryGpt4CategoriesTmp1sArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt4CategoriesTmp1Condition>;
  filter?: InputMaybe<Gpt4CategoriesTmp1Filter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt4CategoriesTmp1sOrderBy>>;
};

export type QueryGpt4CategoriesTmpsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt4CategoriesTmpCondition>;
  filter?: InputMaybe<Gpt4CategoriesTmpFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt4CategoriesTmpsOrderBy>>;
};

export type QueryGpt4Etl1sArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt4Etl1Condition>;
  filter?: InputMaybe<Gpt4Etl1Filter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt4Etl1sOrderBy>>;
};

export type QueryGpt41CatFinalsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt41CatFinalCondition>;
  filter?: InputMaybe<Gpt41CatFinalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt41CatFinalsOrderBy>>;
};

export type QueryGpt41CategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<Gpt41CategoryCondition>;
  filter?: InputMaybe<Gpt41CategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<Gpt41CategoriesOrderBy>>;
};

export type QueryGpt41CategoryArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGpt41CategoryByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCategoryCondition>;
  filter?: InputMaybe<LgCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCategoriesOrderBy>>;
};

export type QueryLgCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLgCategoryByNameArgs = {
  name: Scalars["String"]["input"];
};

export type QueryLgCategoryByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompaniesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type QueryLgCompanyArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCategoryCondition>;
  filter?: InputMaybe<LgCompanyCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCategoriesOrderBy>>;
};

export type QueryLgCompanyCategoryByIdArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyCommentArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyCommentByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyCommentsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCommentCondition>;
  filter?: InputMaybe<LgCompanyCommentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCommentsOrderBy>>;
};

export type QueryLgCompanyCopiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCopyCondition>;
  filter?: InputMaybe<LgCompanyCopyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyCopiesOrderBy>>;
};

export type QueryLgCompanyFileArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLgCompanyFileByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFileCondition>;
  filter?: InputMaybe<LgCompanyFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFilesOrderBy>>;
};

export type QueryLgCompanyFlagArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyFlagByCompanyIdAndFlagArgs = {
  companyId: Scalars["UUID"]["input"];
  flag: Scalars["String"]["input"];
};

export type QueryLgCompanyFlagByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyFlagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyFlagCondition>;
  filter?: InputMaybe<LgCompanyFlagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyFlagsOrderBy>>;
};

export type QueryLgCompanyInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLgCompanyInvestorByCompanyIdAndInvestorIdArgs = {
  companyId: Scalars["UUID"]["input"];
  investorId: Scalars["UUID"]["input"];
};

export type QueryLgCompanyInvestorByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryLgCompanyInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyInvestorCondition>;
  filter?: InputMaybe<LgCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyInvestorsOrderBy>>;
};

export type QueryLgCompanyMetricArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyMetricByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyMetricsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyMetricCondition>;
  filter?: InputMaybe<LgCompanyMetricFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyMetricsOrderBy>>;
};

export type QueryLgCompanyNoteArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgCompanyNoteByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyNotesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyNoteCondition>;
  filter?: InputMaybe<LgCompanyNoteFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyNotesOrderBy>>;
};

export type QueryLgCompanyTeamArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLgCompanyTeamByCompanyIdAndMemberIdArgs = {
  companyId: Scalars["UUID"]["input"];
  memberId: Scalars["UUID"]["input"];
};

export type QueryLgCompanyTeamByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryLgCompanyTeamByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgCompanyTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyTeamCondition>;
  filter?: InputMaybe<LgCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompanyTeamsOrderBy>>;
};

export type QueryLgFileArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgFileByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFileCondition>;
  filter?: InputMaybe<LgFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFilesOrderBy>>;
};

export type QueryLgFounderArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgFounderByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgFoundersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFounderCondition>;
  filter?: InputMaybe<LgFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFoundersOrderBy>>;
};

export type QueryLgFundingRoundArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgFundingRoundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgFundingRoundInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryLgFundingRoundInvestorByCompositeIdArgs = {
  compositeId: Scalars["String"]["input"];
};

export type QueryLgFundingRoundInvestorByFundingRoundIdAndInvestorIdArgs = {
  fundingRoundId: Scalars["UUID"]["input"];
  investorId: Scalars["UUID"]["input"];
};

export type QueryLgFundingRoundInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgFundingRoundInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundInvestorCondition>;
  filter?: InputMaybe<LgFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundInvestorsOrderBy>>;
};

export type QueryLgFundingRoundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgFundingRoundCondition>;
  filter?: InputMaybe<LgFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgFundingRoundsOrderBy>>;
};

export type QueryLgInvestorArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgInvestorCondition>;
  filter?: InputMaybe<LgInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgInvestorsOrderBy>>;
};

export type QueryLgMemberArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgMemberByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgMemberSettingArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgMemberSettingByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgMemberSettingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberSettingCondition>;
  filter?: InputMaybe<LgMemberSettingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMemberSettingsOrderBy>>;
};

export type QueryLgMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type QueryLgUserFeedbackArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryLgUserFeedbackByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryLgUserFeedbacksArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgUserFeedbackCondition>;
  filter?: InputMaybe<LgUserFeedbackFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgUserFeedbacksOrderBy>>;
};

export type QueryMnEntriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MnEntryCondition>;
  filter?: InputMaybe<MnEntryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MnEntriesOrderBy>>;
};

export type QueryMnEntryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryMnEntryByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryMtpBasecampFundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpBasecampFundCondition>;
  filter?: InputMaybe<MtpBasecampFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpBasecampFundsOrderBy>>;
};

export type QueryMtpCompanyfundArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryMtpCompanyfundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryMtpCompanyfundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundCondition>;
  filter?: InputMaybe<MtpCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsOrderBy>>;
};

export type QueryMtpCompanyfundsCpiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MtpCompanyfundsCpyCondition>;
  filter?: InputMaybe<MtpCompanyfundsCpyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MtpCompanyfundsCpiesOrderBy>>;
};

export type QueryNodeArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrNotificationArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrNotificationByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrNotificationCondition>;
  filter?: InputMaybe<NzrNotificationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy>>;
};

export type QueryNzrOpportunitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type QueryNzrOpportunityArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrOpportunityByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrOpportunityEmailArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrOpportunityEmailByGoogleMessageIdArgs = {
  googleMessageId: Scalars["String"]["input"];
};

export type QueryNzrOpportunityEmailByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrOpportunityEmailCandidateArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrOpportunityEmailCandidateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrOpportunityEmailCandidatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCandidateCondition>;
  filter?: InputMaybe<NzrOpportunityEmailCandidateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailCandidatesOrderBy>>;
};

export type QueryNzrOpportunityEmailsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityEmailCondition>;
  filter?: InputMaybe<NzrOpportunityEmailFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunityEmailsOrderBy>>;
};

export type QueryNzrSentimentCpiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentCpyCondition>;
  filter?: InputMaybe<NzrSentimentCpyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentCpiesOrderBy>>;
};

export type QueryNzrSentimentFormArgs = {
  id: Scalars["String"]["input"];
};

export type QueryNzrSentimentFormAssigneeArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrSentimentFormAssigneeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrSentimentFormAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormAssigneeCondition>;
  filter?: InputMaybe<NzrSentimentFormAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormAssigneesOrderBy>>;
};

export type QueryNzrSentimentFormByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrSentimentFormsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrSentimentFormCondition>;
  filter?: InputMaybe<NzrSentimentFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrSentimentFormsOrderBy>>;
};

export type QueryNzrUserCompanyTagArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNzrUserCompanyTagByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryNzrUserCompanyTagByUserIdAndCompanyIdArgs = {
  companyId: Scalars["UUID"]["input"];
  userId: Scalars["UUID"]["input"];
};

export type QueryNzrUserCompanyTagsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrUserCompanyTagCondition>;
  filter?: InputMaybe<NzrUserCompanyTagFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrUserCompanyTagsOrderBy>>;
};

export type QueryOpFundCpiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpFundCpyCondition>;
  filter?: InputMaybe<OpFundCpyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpFundCpiesOrderBy>>;
};

export type QueryOpsCompaniesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyCondition>;
  filter?: InputMaybe<OpsCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompaniesOrderBy>>;
};

export type QueryOpsCompanyArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryOpsCompanyByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryOpsCompanyfundArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryOpsCompanyfundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryOpsCompanyfundCopiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCopyCondition>;
  filter?: InputMaybe<OpsCompanyfundCopyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundCopiesOrderBy>>;
};

export type QueryOpsCompanyfundcapitalArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryOpsCompanyfundcapitalByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryOpsCompanyfundcapitalsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundcapitalCondition>;
  filter?: InputMaybe<OpsCompanyfundcapitalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundcapitalsOrderBy>>;
};

export type QueryOpsCompanyfundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsCompanyfundCondition>;
  filter?: InputMaybe<OpsCompanyfundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy>>;
};

export type QueryOpsFundArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryOpsFundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryOpsFundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsFundCondition>;
  filter?: InputMaybe<OpsFundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsFundsOrderBy>>;
};

export type QueryOpsPeopleArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OpsPersonCondition>;
  filter?: InputMaybe<OpsPersonFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OpsPeopleOrderBy>>;
};

export type QueryOpsPersonArgs = {
  id: Scalars["BigInt"]["input"];
};

export type QueryOpsPersonByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryResponseGetFilePresignedUrlArgs = {
  file: Scalars["String"]["input"];
};

export type QueryRuleArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryRuleByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryRulesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<RuleCondition>;
  filter?: InputMaybe<RuleFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RulesOrderBy>>;
};

export type QuerySignalArgs = {
  id: Scalars["UUID"]["input"];
};

export type QuerySignalByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySignalsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type QuerySignalsCopiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalsCopyCondition>;
  filter?: InputMaybe<SignalsCopyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsCopiesOrderBy>>;
};

export type QuerySrcCompaniesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type QuerySrcCompanyArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcCompanyByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcCompanyBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcCompanyCopiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCopyCondition>;
  filter?: InputMaybe<SrcCompanyCopyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyCopiesOrderBy>>;
};

export type QuerySrcCompanyCpiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCpyCondition>;
  filter?: InputMaybe<SrcCompanyCpyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyCpiesOrderBy>>;
};

export type QuerySrcCompanyInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcCompanyInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcCompanyInvestorBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcCompanyInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyInvestorCondition>;
  filter?: InputMaybe<SrcCompanyInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyInvestorsOrderBy>>;
};

export type QuerySrcCompanyTeamArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcCompanyTeamByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcCompanyTeamBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcCompanyTeamsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyTeamCondition>;
  filter?: InputMaybe<SrcCompanyTeamFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompanyTeamsOrderBy>>;
};

export type QuerySrcConfigArgs = {
  id: Scalars["String"]["input"];
};

export type QuerySrcConfigByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcConfigCondition>;
  filter?: InputMaybe<SrcConfigFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcConfigsOrderBy>>;
};

export type QuerySrcFounderArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcFounderByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcFounderBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcFoundersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFounderCondition>;
  filter?: InputMaybe<SrcFounderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFoundersOrderBy>>;
};

export type QuerySrcFundingRoundArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcFundingRoundByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcFundingRoundBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcFundingRoundInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcFundingRoundInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcFundingRoundInvestorBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcFundingRoundInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFundingRoundInvestorCondition>;
  filter?: InputMaybe<SrcFundingRoundInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFundingRoundInvestorsOrderBy>>;
};

export type QuerySrcFundingRoundsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcFundingRoundCondition>;
  filter?: InputMaybe<SrcFundingRoundFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcFundingRoundsOrderBy>>;
};

export type QuerySrcInvestorArgs = {
  id: Scalars["Int"]["input"];
};

export type QuerySrcInvestorByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QuerySrcInvestorBySourceTypeAndSourceIdArgs = {
  sourceId: Scalars["String"]["input"];
  sourceType: Scalars["String"]["input"];
};

export type QuerySrcInvestorsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcInvestorCondition>;
  filter?: InputMaybe<SrcInvestorFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcInvestorsOrderBy>>;
};

export type QueryTfFormArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTfFormByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTfFormsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TfFormCondition>;
  filter?: InputMaybe<TfFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TfFormsOrderBy>>;
};

export type QueryTfWorkspaceArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTfWorkspaceByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTfWorkspacesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TfWorkspaceCondition>;
  filter?: InputMaybe<TfWorkspaceFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TfWorkspacesOrderBy>>;
};

export type QueryTrelloBoardArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrelloBoardByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTrelloBoardsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloBoardCondition>;
  filter?: InputMaybe<TrelloBoardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloBoardsOrderBy>>;
};

export type QueryTrelloCardArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrelloCardByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTrelloCardByShortLinkHashArgs = {
  shortLinkHash: Scalars["String"]["input"];
};

export type QueryTrelloCardLgCategoriesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardLgCategoryCondition>;
  filter?: InputMaybe<TrelloCardLgCategoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardLgCategoriesOrderBy>>;
};

export type QueryTrelloCardsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardCondition>;
  filter?: InputMaybe<TrelloCardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardsOrderBy>>;
};

export type QueryTrelloLabelArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrelloLabelByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTrelloLabelsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloLabelCondition>;
  filter?: InputMaybe<TrelloLabelFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloLabelsOrderBy>>;
};

export type QueryTrelloListArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrelloListByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTrelloListsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloListCondition>;
  filter?: InputMaybe<TrelloListFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloListsOrderBy>>;
};

export type QueryTrelloMemberArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrelloMemberByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryTrelloMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloMemberCondition>;
  filter?: InputMaybe<TrelloMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloMembersOrderBy>>;
};

export type QueryUserReportArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryUserReportByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type QueryUserReportByUserIdAndPeriodArgs = {
  period: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
};

export type QueryUserReportsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserReportCondition>;
  filter?: InputMaybe<UserReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserReportsOrderBy>>;
};

/** All input for the `refreshCompanyFromSources` mutation. */
export type RefreshCompanyFromSourcesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  companyIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
};

/** The output of our `refreshCompanyFromSources` mutation. */
export type RefreshCompanyFromSourcesPayload = {
  __typename?: "RefreshCompanyFromSourcesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  json?: Maybe<Scalars["JSON"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type RefreshRequestInput = {
  refreshToken: Scalars["String"]["input"];
};

export type Rule = Node & {
  __typename?: "Rule";
  config: Scalars["JSON"]["output"];
  createdUtc: Scalars["Datetime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `LgCompany`. */
  lgCompaniesBySignalRuleIdAndCompanyId: RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyConnection;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  runner: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `Signal`. */
  signals: SignalsConnection;
};

export type RuleLgCompaniesBySignalRuleIdAndCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgCompanyCondition>;
  filter?: InputMaybe<LgCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy>>;
};

export type RuleSignalsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

export type RuleAggregates = {
  __typename?: "RuleAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RuleDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A condition to be used against `Rule` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RuleCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `runner` field. */
  runner?: InputMaybe<Scalars["String"]["input"]>;
};

export type RuleDistinctCountAggregates = {
  __typename?: "RuleDistinctCountAggregates";
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runner across the matching connection */
  runner?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Rule` object types. All fields are combined with a logical ‘and.’ */
export type RuleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RuleFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RuleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RuleFilter>>;
  /** Filter by the object’s `runner` field. */
  runner?: InputMaybe<StringFilter>;
  /** Filter by the object’s `signals` relation. */
  signals?: InputMaybe<RuleToManySignalFilter>;
  /** Some related `signals` exist. */
  signalsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Grouping methods for `Rule` for usage during aggregation. */
export enum RuleGroupBy {
  Config = "CONFIG",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Description = "DESCRIPTION",
  Enabled = "ENABLED",
  Name = "NAME",
  Runner = "RUNNER",
}

export type RuleHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Rule` aggregates. */
export type RuleHavingInput = {
  AND?: InputMaybe<Array<RuleHavingInput>>;
  OR?: InputMaybe<Array<RuleHavingInput>>;
  average?: InputMaybe<RuleHavingAverageInput>;
  distinctCount?: InputMaybe<RuleHavingDistinctCountInput>;
  max?: InputMaybe<RuleHavingMaxInput>;
  min?: InputMaybe<RuleHavingMinInput>;
  stddevPopulation?: InputMaybe<RuleHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RuleHavingStddevSampleInput>;
  sum?: InputMaybe<RuleHavingSumInput>;
  variancePopulation?: InputMaybe<RuleHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RuleHavingVarianceSampleInput>;
};

export type RuleHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type RuleHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgCompany` values, with data from `Signal`. */
export type RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyConnection = {
  __typename?: "RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgCompanyAggregates>;
  /** A list of edges which contains the `LgCompany`, info from the `Signal`, and the cursor to aid in pagination. */
  edges: Array<RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgCompanyAggregates>>;
  /** A list of `LgCompany` objects. */
  nodes: Array<LgCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgCompany` values, with data from `Signal`. */
export type RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgCompanyGroupBy>;
  having?: InputMaybe<LgCompanyHavingInput>;
};

/** A `LgCompany` edge in the connection, with data from `Signal`. */
export type RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyEdge = {
  __typename?: "RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgCompany` at the end of the edge. */
  node: LgCompany;
  /** Reads and enables pagination through a set of `Signal`. */
  signalsByCompanyId: SignalsConnection;
};

/** A `LgCompany` edge in the connection, with data from `Signal`. */
export type RuleLgCompaniesBySignalRuleIdAndCompanyIdManyToManyEdgeSignalsByCompanyIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SignalCondition>;
  filter?: InputMaybe<SignalFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SignalsOrderBy>>;
};

/** A filter to be used against many `Signal` object types. All fields are combined with a logical ‘and.’ */
export type RuleToManySignalFilter = {
  /** Aggregates across related `Signal` match the filter criteria. */
  aggregates?: InputMaybe<SignalAggregatesFilter>;
  /** Every related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SignalFilter>;
  /** No related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SignalFilter>;
  /** Some related `Signal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SignalFilter>;
};

/** A connection to a list of `Rule` values. */
export type RulesConnection = {
  __typename?: "RulesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RuleAggregates>;
  /** A list of edges which contains the `Rule` and cursor to aid in pagination. */
  edges: Array<RulesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RuleAggregates>>;
  /** A list of `Rule` objects. */
  nodes: Array<Rule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Rule` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Rule` values. */
export type RulesConnectionGroupedAggregatesArgs = {
  groupBy: Array<RuleGroupBy>;
  having?: InputMaybe<RuleHavingInput>;
};

/** A `Rule` edge in the connection. */
export type RulesEdge = {
  __typename?: "RulesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Rule` at the end of the edge. */
  node: Rule;
};

/** Methods to use when ordering `Rule`. */
export enum RulesOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  EnabledAsc = "ENABLED_ASC",
  EnabledDesc = "ENABLED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RunnerAsc = "RUNNER_ASC",
  RunnerDesc = "RUNNER_DESC",
  SignalsAverageCompanyIdAsc = "SIGNALS_AVERAGE_COMPANY_ID_ASC",
  SignalsAverageCompanyIdDesc = "SIGNALS_AVERAGE_COMPANY_ID_DESC",
  SignalsAverageCreatedUtcAsc = "SIGNALS_AVERAGE_CREATED_UTC_ASC",
  SignalsAverageCreatedUtcDesc = "SIGNALS_AVERAGE_CREATED_UTC_DESC",
  SignalsAverageDeletedAsc = "SIGNALS_AVERAGE_DELETED_ASC",
  SignalsAverageDeletedDesc = "SIGNALS_AVERAGE_DELETED_DESC",
  SignalsAverageIdAsc = "SIGNALS_AVERAGE_ID_ASC",
  SignalsAverageIdDesc = "SIGNALS_AVERAGE_ID_DESC",
  SignalsAverageOutputAsc = "SIGNALS_AVERAGE_OUTPUT_ASC",
  SignalsAverageOutputDesc = "SIGNALS_AVERAGE_OUTPUT_DESC",
  SignalsAverageRuleIdAsc = "SIGNALS_AVERAGE_RULE_ID_ASC",
  SignalsAverageRuleIdDesc = "SIGNALS_AVERAGE_RULE_ID_DESC",
  SignalsAverageValidUntilUtcAsc = "SIGNALS_AVERAGE_VALID_UNTIL_UTC_ASC",
  SignalsAverageValidUntilUtcDesc = "SIGNALS_AVERAGE_VALID_UNTIL_UTC_DESC",
  SignalsCountAsc = "SIGNALS_COUNT_ASC",
  SignalsCountDesc = "SIGNALS_COUNT_DESC",
  SignalsDistinctCountCompanyIdAsc = "SIGNALS_DISTINCT_COUNT_COMPANY_ID_ASC",
  SignalsDistinctCountCompanyIdDesc = "SIGNALS_DISTINCT_COUNT_COMPANY_ID_DESC",
  SignalsDistinctCountCreatedUtcAsc = "SIGNALS_DISTINCT_COUNT_CREATED_UTC_ASC",
  SignalsDistinctCountCreatedUtcDesc = "SIGNALS_DISTINCT_COUNT_CREATED_UTC_DESC",
  SignalsDistinctCountDeletedAsc = "SIGNALS_DISTINCT_COUNT_DELETED_ASC",
  SignalsDistinctCountDeletedDesc = "SIGNALS_DISTINCT_COUNT_DELETED_DESC",
  SignalsDistinctCountIdAsc = "SIGNALS_DISTINCT_COUNT_ID_ASC",
  SignalsDistinctCountIdDesc = "SIGNALS_DISTINCT_COUNT_ID_DESC",
  SignalsDistinctCountOutputAsc = "SIGNALS_DISTINCT_COUNT_OUTPUT_ASC",
  SignalsDistinctCountOutputDesc = "SIGNALS_DISTINCT_COUNT_OUTPUT_DESC",
  SignalsDistinctCountRuleIdAsc = "SIGNALS_DISTINCT_COUNT_RULE_ID_ASC",
  SignalsDistinctCountRuleIdDesc = "SIGNALS_DISTINCT_COUNT_RULE_ID_DESC",
  SignalsDistinctCountValidUntilUtcAsc = "SIGNALS_DISTINCT_COUNT_VALID_UNTIL_UTC_ASC",
  SignalsDistinctCountValidUntilUtcDesc = "SIGNALS_DISTINCT_COUNT_VALID_UNTIL_UTC_DESC",
  SignalsMaxCompanyIdAsc = "SIGNALS_MAX_COMPANY_ID_ASC",
  SignalsMaxCompanyIdDesc = "SIGNALS_MAX_COMPANY_ID_DESC",
  SignalsMaxCreatedUtcAsc = "SIGNALS_MAX_CREATED_UTC_ASC",
  SignalsMaxCreatedUtcDesc = "SIGNALS_MAX_CREATED_UTC_DESC",
  SignalsMaxDeletedAsc = "SIGNALS_MAX_DELETED_ASC",
  SignalsMaxDeletedDesc = "SIGNALS_MAX_DELETED_DESC",
  SignalsMaxIdAsc = "SIGNALS_MAX_ID_ASC",
  SignalsMaxIdDesc = "SIGNALS_MAX_ID_DESC",
  SignalsMaxOutputAsc = "SIGNALS_MAX_OUTPUT_ASC",
  SignalsMaxOutputDesc = "SIGNALS_MAX_OUTPUT_DESC",
  SignalsMaxRuleIdAsc = "SIGNALS_MAX_RULE_ID_ASC",
  SignalsMaxRuleIdDesc = "SIGNALS_MAX_RULE_ID_DESC",
  SignalsMaxValidUntilUtcAsc = "SIGNALS_MAX_VALID_UNTIL_UTC_ASC",
  SignalsMaxValidUntilUtcDesc = "SIGNALS_MAX_VALID_UNTIL_UTC_DESC",
  SignalsMinCompanyIdAsc = "SIGNALS_MIN_COMPANY_ID_ASC",
  SignalsMinCompanyIdDesc = "SIGNALS_MIN_COMPANY_ID_DESC",
  SignalsMinCreatedUtcAsc = "SIGNALS_MIN_CREATED_UTC_ASC",
  SignalsMinCreatedUtcDesc = "SIGNALS_MIN_CREATED_UTC_DESC",
  SignalsMinDeletedAsc = "SIGNALS_MIN_DELETED_ASC",
  SignalsMinDeletedDesc = "SIGNALS_MIN_DELETED_DESC",
  SignalsMinIdAsc = "SIGNALS_MIN_ID_ASC",
  SignalsMinIdDesc = "SIGNALS_MIN_ID_DESC",
  SignalsMinOutputAsc = "SIGNALS_MIN_OUTPUT_ASC",
  SignalsMinOutputDesc = "SIGNALS_MIN_OUTPUT_DESC",
  SignalsMinRuleIdAsc = "SIGNALS_MIN_RULE_ID_ASC",
  SignalsMinRuleIdDesc = "SIGNALS_MIN_RULE_ID_DESC",
  SignalsMinValidUntilUtcAsc = "SIGNALS_MIN_VALID_UNTIL_UTC_ASC",
  SignalsMinValidUntilUtcDesc = "SIGNALS_MIN_VALID_UNTIL_UTC_DESC",
  SignalsStddevPopulationCompanyIdAsc = "SIGNALS_STDDEV_POPULATION_COMPANY_ID_ASC",
  SignalsStddevPopulationCompanyIdDesc = "SIGNALS_STDDEV_POPULATION_COMPANY_ID_DESC",
  SignalsStddevPopulationCreatedUtcAsc = "SIGNALS_STDDEV_POPULATION_CREATED_UTC_ASC",
  SignalsStddevPopulationCreatedUtcDesc = "SIGNALS_STDDEV_POPULATION_CREATED_UTC_DESC",
  SignalsStddevPopulationDeletedAsc = "SIGNALS_STDDEV_POPULATION_DELETED_ASC",
  SignalsStddevPopulationDeletedDesc = "SIGNALS_STDDEV_POPULATION_DELETED_DESC",
  SignalsStddevPopulationIdAsc = "SIGNALS_STDDEV_POPULATION_ID_ASC",
  SignalsStddevPopulationIdDesc = "SIGNALS_STDDEV_POPULATION_ID_DESC",
  SignalsStddevPopulationOutputAsc = "SIGNALS_STDDEV_POPULATION_OUTPUT_ASC",
  SignalsStddevPopulationOutputDesc = "SIGNALS_STDDEV_POPULATION_OUTPUT_DESC",
  SignalsStddevPopulationRuleIdAsc = "SIGNALS_STDDEV_POPULATION_RULE_ID_ASC",
  SignalsStddevPopulationRuleIdDesc = "SIGNALS_STDDEV_POPULATION_RULE_ID_DESC",
  SignalsStddevPopulationValidUntilUtcAsc = "SIGNALS_STDDEV_POPULATION_VALID_UNTIL_UTC_ASC",
  SignalsStddevPopulationValidUntilUtcDesc = "SIGNALS_STDDEV_POPULATION_VALID_UNTIL_UTC_DESC",
  SignalsStddevSampleCompanyIdAsc = "SIGNALS_STDDEV_SAMPLE_COMPANY_ID_ASC",
  SignalsStddevSampleCompanyIdDesc = "SIGNALS_STDDEV_SAMPLE_COMPANY_ID_DESC",
  SignalsStddevSampleCreatedUtcAsc = "SIGNALS_STDDEV_SAMPLE_CREATED_UTC_ASC",
  SignalsStddevSampleCreatedUtcDesc = "SIGNALS_STDDEV_SAMPLE_CREATED_UTC_DESC",
  SignalsStddevSampleDeletedAsc = "SIGNALS_STDDEV_SAMPLE_DELETED_ASC",
  SignalsStddevSampleDeletedDesc = "SIGNALS_STDDEV_SAMPLE_DELETED_DESC",
  SignalsStddevSampleIdAsc = "SIGNALS_STDDEV_SAMPLE_ID_ASC",
  SignalsStddevSampleIdDesc = "SIGNALS_STDDEV_SAMPLE_ID_DESC",
  SignalsStddevSampleOutputAsc = "SIGNALS_STDDEV_SAMPLE_OUTPUT_ASC",
  SignalsStddevSampleOutputDesc = "SIGNALS_STDDEV_SAMPLE_OUTPUT_DESC",
  SignalsStddevSampleRuleIdAsc = "SIGNALS_STDDEV_SAMPLE_RULE_ID_ASC",
  SignalsStddevSampleRuleIdDesc = "SIGNALS_STDDEV_SAMPLE_RULE_ID_DESC",
  SignalsStddevSampleValidUntilUtcAsc = "SIGNALS_STDDEV_SAMPLE_VALID_UNTIL_UTC_ASC",
  SignalsStddevSampleValidUntilUtcDesc = "SIGNALS_STDDEV_SAMPLE_VALID_UNTIL_UTC_DESC",
  SignalsSumCompanyIdAsc = "SIGNALS_SUM_COMPANY_ID_ASC",
  SignalsSumCompanyIdDesc = "SIGNALS_SUM_COMPANY_ID_DESC",
  SignalsSumCreatedUtcAsc = "SIGNALS_SUM_CREATED_UTC_ASC",
  SignalsSumCreatedUtcDesc = "SIGNALS_SUM_CREATED_UTC_DESC",
  SignalsSumDeletedAsc = "SIGNALS_SUM_DELETED_ASC",
  SignalsSumDeletedDesc = "SIGNALS_SUM_DELETED_DESC",
  SignalsSumIdAsc = "SIGNALS_SUM_ID_ASC",
  SignalsSumIdDesc = "SIGNALS_SUM_ID_DESC",
  SignalsSumOutputAsc = "SIGNALS_SUM_OUTPUT_ASC",
  SignalsSumOutputDesc = "SIGNALS_SUM_OUTPUT_DESC",
  SignalsSumRuleIdAsc = "SIGNALS_SUM_RULE_ID_ASC",
  SignalsSumRuleIdDesc = "SIGNALS_SUM_RULE_ID_DESC",
  SignalsSumValidUntilUtcAsc = "SIGNALS_SUM_VALID_UNTIL_UTC_ASC",
  SignalsSumValidUntilUtcDesc = "SIGNALS_SUM_VALID_UNTIL_UTC_DESC",
  SignalsVariancePopulationCompanyIdAsc = "SIGNALS_VARIANCE_POPULATION_COMPANY_ID_ASC",
  SignalsVariancePopulationCompanyIdDesc = "SIGNALS_VARIANCE_POPULATION_COMPANY_ID_DESC",
  SignalsVariancePopulationCreatedUtcAsc = "SIGNALS_VARIANCE_POPULATION_CREATED_UTC_ASC",
  SignalsVariancePopulationCreatedUtcDesc = "SIGNALS_VARIANCE_POPULATION_CREATED_UTC_DESC",
  SignalsVariancePopulationDeletedAsc = "SIGNALS_VARIANCE_POPULATION_DELETED_ASC",
  SignalsVariancePopulationDeletedDesc = "SIGNALS_VARIANCE_POPULATION_DELETED_DESC",
  SignalsVariancePopulationIdAsc = "SIGNALS_VARIANCE_POPULATION_ID_ASC",
  SignalsVariancePopulationIdDesc = "SIGNALS_VARIANCE_POPULATION_ID_DESC",
  SignalsVariancePopulationOutputAsc = "SIGNALS_VARIANCE_POPULATION_OUTPUT_ASC",
  SignalsVariancePopulationOutputDesc = "SIGNALS_VARIANCE_POPULATION_OUTPUT_DESC",
  SignalsVariancePopulationRuleIdAsc = "SIGNALS_VARIANCE_POPULATION_RULE_ID_ASC",
  SignalsVariancePopulationRuleIdDesc = "SIGNALS_VARIANCE_POPULATION_RULE_ID_DESC",
  SignalsVariancePopulationValidUntilUtcAsc = "SIGNALS_VARIANCE_POPULATION_VALID_UNTIL_UTC_ASC",
  SignalsVariancePopulationValidUntilUtcDesc = "SIGNALS_VARIANCE_POPULATION_VALID_UNTIL_UTC_DESC",
  SignalsVarianceSampleCompanyIdAsc = "SIGNALS_VARIANCE_SAMPLE_COMPANY_ID_ASC",
  SignalsVarianceSampleCompanyIdDesc = "SIGNALS_VARIANCE_SAMPLE_COMPANY_ID_DESC",
  SignalsVarianceSampleCreatedUtcAsc = "SIGNALS_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  SignalsVarianceSampleCreatedUtcDesc = "SIGNALS_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  SignalsVarianceSampleDeletedAsc = "SIGNALS_VARIANCE_SAMPLE_DELETED_ASC",
  SignalsVarianceSampleDeletedDesc = "SIGNALS_VARIANCE_SAMPLE_DELETED_DESC",
  SignalsVarianceSampleIdAsc = "SIGNALS_VARIANCE_SAMPLE_ID_ASC",
  SignalsVarianceSampleIdDesc = "SIGNALS_VARIANCE_SAMPLE_ID_DESC",
  SignalsVarianceSampleOutputAsc = "SIGNALS_VARIANCE_SAMPLE_OUTPUT_ASC",
  SignalsVarianceSampleOutputDesc = "SIGNALS_VARIANCE_SAMPLE_OUTPUT_DESC",
  SignalsVarianceSampleRuleIdAsc = "SIGNALS_VARIANCE_SAMPLE_RULE_ID_ASC",
  SignalsVarianceSampleRuleIdDesc = "SIGNALS_VARIANCE_SAMPLE_RULE_ID_DESC",
  SignalsVarianceSampleValidUntilUtcAsc = "SIGNALS_VARIANCE_SAMPLE_VALID_UNTIL_UTC_ASC",
  SignalsVarianceSampleValidUntilUtcDesc = "SIGNALS_VARIANCE_SAMPLE_VALID_UNTIL_UTC_DESC",
}

export type SettingsInput = {
  feedbackEmailTemplate?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type Signal = Node & {
  __typename?: "Signal";
  /** Reads a single `LgCompany` that is related to this `Signal`. */
  company?: Maybe<LgCompany>;
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrOpportunitySignalIdAndAssignee: SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection;
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembersByNzrOpportunitySignalIdAndSharedFrom: SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunities: NzrOpportunitiesConnection;
  output?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `Rule` that is related to this `Signal`. */
  rule?: Maybe<Rule>;
  ruleId: Scalars["UUID"]["output"];
  validUntilUtc?: Maybe<Scalars["Datetime"]["output"]>;
};

export type SignalLgMembersByNzrOpportunitySignalIdAndAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type SignalLgMembersByNzrOpportunitySignalIdAndSharedFromArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type SignalNzrOpportunitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

export type SignalAggregates = {
  __typename?: "SignalAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SignalDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `Signal` object types. */
export type SignalAggregatesFilter = {
  /** Distinct count aggregate over matching `Signal` objects. */
  distinctCount?: InputMaybe<SignalDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Signal` object to be included within the aggregate. */
  filter?: InputMaybe<SignalFilter>;
};

/** A condition to be used against `Signal` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SignalCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `output` field. */
  output?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `ruleId` field. */
  ruleId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `validUntilUtc` field. */
  validUntilUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type SignalDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  output?: InputMaybe<BigIntFilter>;
  ruleId?: InputMaybe<BigIntFilter>;
  validUntilUtc?: InputMaybe<BigIntFilter>;
};

export type SignalDistinctCountAggregates = {
  __typename?: "SignalDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of output across the matching connection */
  output?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ruleId across the matching connection */
  ruleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of validUntilUtc across the matching connection */
  validUntilUtc?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Signal` object types. All fields are combined with a logical ‘and.’ */
export type SignalFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SignalFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** A related `company` exists. */
  companyExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SignalFilter>;
  /** Filter by the object’s `nzrOpportunities` relation. */
  nzrOpportunities?: InputMaybe<SignalToManyNzrOpportunityFilter>;
  /** Some related `nzrOpportunities` exist. */
  nzrOpportunitiesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SignalFilter>>;
  /** Filter by the object’s `output` field. */
  output?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rule` relation. */
  rule?: InputMaybe<RuleFilter>;
  /** Filter by the object’s `ruleId` field. */
  ruleId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `validUntilUtc` field. */
  validUntilUtc?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Signal` for usage during aggregation. */
export enum SignalGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Deleted = "DELETED",
  Output = "OUTPUT",
  RuleId = "RULE_ID",
  ValidUntilUtc = "VALID_UNTIL_UTC",
  ValidUntilUtcTruncatedToDay = "VALID_UNTIL_UTC_TRUNCATED_TO_DAY",
  ValidUntilUtcTruncatedToHour = "VALID_UNTIL_UTC_TRUNCATED_TO_HOUR",
}

export type SignalHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Signal` aggregates. */
export type SignalHavingInput = {
  AND?: InputMaybe<Array<SignalHavingInput>>;
  OR?: InputMaybe<Array<SignalHavingInput>>;
  average?: InputMaybe<SignalHavingAverageInput>;
  distinctCount?: InputMaybe<SignalHavingDistinctCountInput>;
  max?: InputMaybe<SignalHavingMaxInput>;
  min?: InputMaybe<SignalHavingMinInput>;
  stddevPopulation?: InputMaybe<SignalHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SignalHavingStddevSampleInput>;
  sum?: InputMaybe<SignalHavingSumInput>;
  variancePopulation?: InputMaybe<SignalHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SignalHavingVarianceSampleInput>;
};

export type SignalHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection = {
  __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyEdge = {
  __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesByAssignee: NzrOpportunitiesConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyEdgeNzrOpportunitiesByAssigneeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyConnection = {
  __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LgMemberAggregates>;
  /** A list of edges which contains the `LgMember`, info from the `NzrOpportunity`, and the cursor to aid in pagination. */
  edges: Array<SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LgMemberAggregates>>;
  /** A list of `LgMember` objects. */
  nodes: Array<LgMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LgMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `LgMember` values, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LgMemberGroupBy>;
  having?: InputMaybe<LgMemberHavingInput>;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyEdge = {
  __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `LgMember` at the end of the edge. */
  node: LgMember;
  /** Reads and enables pagination through a set of `NzrOpportunity`. */
  nzrOpportunitiesBySharedFrom: NzrOpportunitiesConnection;
};

/** A `LgMember` edge in the connection, with data from `NzrOpportunity`. */
export type SignalLgMembersByNzrOpportunitySignalIdAndSharedFromManyToManyEdgeNzrOpportunitiesBySharedFromArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NzrOpportunityCondition>;
  filter?: InputMaybe<NzrOpportunityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NzrOpportunitiesOrderBy>>;
};

/** A filter to be used against many `NzrOpportunity` object types. All fields are combined with a logical ‘and.’ */
export type SignalToManyNzrOpportunityFilter = {
  /** Aggregates across related `NzrOpportunity` match the filter criteria. */
  aggregates?: InputMaybe<NzrOpportunityAggregatesFilter>;
  /** Every related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NzrOpportunityFilter>;
  /** No related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NzrOpportunityFilter>;
  /** Some related `NzrOpportunity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NzrOpportunityFilter>;
};

/** A connection to a list of `Signal` values. */
export type SignalsConnection = {
  __typename?: "SignalsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SignalAggregates>;
  /** A list of edges which contains the `Signal` and cursor to aid in pagination. */
  edges: Array<SignalsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SignalAggregates>>;
  /** A list of `Signal` objects. */
  nodes: Array<Signal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Signal` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Signal` values. */
export type SignalsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SignalGroupBy>;
  having?: InputMaybe<SignalHavingInput>;
};

/** A connection to a list of `SignalsCopy` values. */
export type SignalsCopiesConnection = {
  __typename?: "SignalsCopiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SignalsCopyAggregates>;
  /** A list of edges which contains the `SignalsCopy` and cursor to aid in pagination. */
  edges: Array<SignalsCopiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SignalsCopyAggregates>>;
  /** A list of `SignalsCopy` objects. */
  nodes: Array<SignalsCopy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignalsCopy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SignalsCopy` values. */
export type SignalsCopiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SignalsCopyGroupBy>;
  having?: InputMaybe<SignalsCopyHavingInput>;
};

/** A `SignalsCopy` edge in the connection. */
export type SignalsCopiesEdge = {
  __typename?: "SignalsCopiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SignalsCopy` at the end of the edge. */
  node: SignalsCopy;
};

/** Methods to use when ordering `SignalsCopy`. */
export enum SignalsCopiesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OutputAsc = "OUTPUT_ASC",
  OutputDesc = "OUTPUT_DESC",
  RuleIdAsc = "RULE_ID_ASC",
  RuleIdDesc = "RULE_ID_DESC",
  ValidUntilUtcAsc = "VALID_UNTIL_UTC_ASC",
  ValidUntilUtcDesc = "VALID_UNTIL_UTC_DESC",
}

export type SignalsCopy = {
  __typename?: "SignalsCopy";
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["UUID"]["output"]>;
  output?: Maybe<Scalars["JSON"]["output"]>;
  ruleId?: Maybe<Scalars["UUID"]["output"]>;
  validUntilUtc?: Maybe<Scalars["Datetime"]["output"]>;
};

export type SignalsCopyAggregates = {
  __typename?: "SignalsCopyAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SignalsCopyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `SignalsCopy` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SignalsCopyCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `output` field. */
  output?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `ruleId` field. */
  ruleId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `validUntilUtc` field. */
  validUntilUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type SignalsCopyDistinctCountAggregates = {
  __typename?: "SignalsCopyDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of output across the matching connection */
  output?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ruleId across the matching connection */
  ruleId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of validUntilUtc across the matching connection */
  validUntilUtc?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SignalsCopy` object types. All fields are combined with a logical ‘and.’ */
export type SignalsCopyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SignalsCopyFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SignalsCopyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SignalsCopyFilter>>;
  /** Filter by the object’s `output` field. */
  output?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `ruleId` field. */
  ruleId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `validUntilUtc` field. */
  validUntilUtc?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `SignalsCopy` for usage during aggregation. */
export enum SignalsCopyGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Deleted = "DELETED",
  Id = "ID",
  Output = "OUTPUT",
  RuleId = "RULE_ID",
  ValidUntilUtc = "VALID_UNTIL_UTC",
  ValidUntilUtcTruncatedToDay = "VALID_UNTIL_UTC_TRUNCATED_TO_DAY",
  ValidUntilUtcTruncatedToHour = "VALID_UNTIL_UTC_TRUNCATED_TO_HOUR",
}

export type SignalsCopyHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SignalsCopy` aggregates. */
export type SignalsCopyHavingInput = {
  AND?: InputMaybe<Array<SignalsCopyHavingInput>>;
  OR?: InputMaybe<Array<SignalsCopyHavingInput>>;
  average?: InputMaybe<SignalsCopyHavingAverageInput>;
  distinctCount?: InputMaybe<SignalsCopyHavingDistinctCountInput>;
  max?: InputMaybe<SignalsCopyHavingMaxInput>;
  min?: InputMaybe<SignalsCopyHavingMinInput>;
  stddevPopulation?: InputMaybe<SignalsCopyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SignalsCopyHavingStddevSampleInput>;
  sum?: InputMaybe<SignalsCopyHavingSumInput>;
  variancePopulation?: InputMaybe<SignalsCopyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SignalsCopyHavingVarianceSampleInput>;
};

export type SignalsCopyHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SignalsCopyHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  validUntilUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A `Signal` edge in the connection. */
export type SignalsEdge = {
  __typename?: "SignalsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Signal` at the end of the edge. */
  node: Signal;
};

/** Methods to use when ordering `Signal`. */
export enum SignalsOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  NzrOpportunitiesAverageAssigneeAsc = "NZR_OPPORTUNITIES_AVERAGE_ASSIGNEE_ASC",
  NzrOpportunitiesAverageAssigneeDesc = "NZR_OPPORTUNITIES_AVERAGE_ASSIGNEE_DESC",
  NzrOpportunitiesAverageCreatedUtcAsc = "NZR_OPPORTUNITIES_AVERAGE_CREATED_UTC_ASC",
  NzrOpportunitiesAverageCreatedUtcDesc = "NZR_OPPORTUNITIES_AVERAGE_CREATED_UTC_DESC",
  NzrOpportunitiesAverageIdAsc = "NZR_OPPORTUNITIES_AVERAGE_ID_ASC",
  NzrOpportunitiesAverageIdDesc = "NZR_OPPORTUNITIES_AVERAGE_ID_DESC",
  NzrOpportunitiesAverageLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_AVERAGE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesAverageLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_AVERAGE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesAverageMetadataAsc = "NZR_OPPORTUNITIES_AVERAGE_METADATA_ASC",
  NzrOpportunitiesAverageMetadataDesc = "NZR_OPPORTUNITIES_AVERAGE_METADATA_DESC",
  NzrOpportunitiesAverageSharedFromAsc = "NZR_OPPORTUNITIES_AVERAGE_SHARED_FROM_ASC",
  NzrOpportunitiesAverageSharedFromDesc = "NZR_OPPORTUNITIES_AVERAGE_SHARED_FROM_DESC",
  NzrOpportunitiesAverageSignalIdAsc = "NZR_OPPORTUNITIES_AVERAGE_SIGNAL_ID_ASC",
  NzrOpportunitiesAverageSignalIdDesc = "NZR_OPPORTUNITIES_AVERAGE_SIGNAL_ID_DESC",
  NzrOpportunitiesAverageStatusAsc = "NZR_OPPORTUNITIES_AVERAGE_STATUS_ASC",
  NzrOpportunitiesAverageStatusDesc = "NZR_OPPORTUNITIES_AVERAGE_STATUS_DESC",
  NzrOpportunitiesAverageValidityAsc = "NZR_OPPORTUNITIES_AVERAGE_VALIDITY_ASC",
  NzrOpportunitiesAverageValidityDesc = "NZR_OPPORTUNITIES_AVERAGE_VALIDITY_DESC",
  NzrOpportunitiesCountAsc = "NZR_OPPORTUNITIES_COUNT_ASC",
  NzrOpportunitiesCountDesc = "NZR_OPPORTUNITIES_COUNT_DESC",
  NzrOpportunitiesDistinctCountAssigneeAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_ASSIGNEE_ASC",
  NzrOpportunitiesDistinctCountAssigneeDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_ASSIGNEE_DESC",
  NzrOpportunitiesDistinctCountCreatedUtcAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_CREATED_UTC_ASC",
  NzrOpportunitiesDistinctCountCreatedUtcDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_CREATED_UTC_DESC",
  NzrOpportunitiesDistinctCountIdAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_ID_ASC",
  NzrOpportunitiesDistinctCountIdDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_ID_DESC",
  NzrOpportunitiesDistinctCountLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesDistinctCountLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesDistinctCountMetadataAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_METADATA_ASC",
  NzrOpportunitiesDistinctCountMetadataDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_METADATA_DESC",
  NzrOpportunitiesDistinctCountSharedFromAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_SHARED_FROM_ASC",
  NzrOpportunitiesDistinctCountSharedFromDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_SHARED_FROM_DESC",
  NzrOpportunitiesDistinctCountSignalIdAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_SIGNAL_ID_ASC",
  NzrOpportunitiesDistinctCountSignalIdDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_SIGNAL_ID_DESC",
  NzrOpportunitiesDistinctCountStatusAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_STATUS_ASC",
  NzrOpportunitiesDistinctCountStatusDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_STATUS_DESC",
  NzrOpportunitiesDistinctCountValidityAsc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_VALIDITY_ASC",
  NzrOpportunitiesDistinctCountValidityDesc = "NZR_OPPORTUNITIES_DISTINCT_COUNT_VALIDITY_DESC",
  NzrOpportunitiesMaxAssigneeAsc = "NZR_OPPORTUNITIES_MAX_ASSIGNEE_ASC",
  NzrOpportunitiesMaxAssigneeDesc = "NZR_OPPORTUNITIES_MAX_ASSIGNEE_DESC",
  NzrOpportunitiesMaxCreatedUtcAsc = "NZR_OPPORTUNITIES_MAX_CREATED_UTC_ASC",
  NzrOpportunitiesMaxCreatedUtcDesc = "NZR_OPPORTUNITIES_MAX_CREATED_UTC_DESC",
  NzrOpportunitiesMaxIdAsc = "NZR_OPPORTUNITIES_MAX_ID_ASC",
  NzrOpportunitiesMaxIdDesc = "NZR_OPPORTUNITIES_MAX_ID_DESC",
  NzrOpportunitiesMaxLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_MAX_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesMaxLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_MAX_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesMaxMetadataAsc = "NZR_OPPORTUNITIES_MAX_METADATA_ASC",
  NzrOpportunitiesMaxMetadataDesc = "NZR_OPPORTUNITIES_MAX_METADATA_DESC",
  NzrOpportunitiesMaxSharedFromAsc = "NZR_OPPORTUNITIES_MAX_SHARED_FROM_ASC",
  NzrOpportunitiesMaxSharedFromDesc = "NZR_OPPORTUNITIES_MAX_SHARED_FROM_DESC",
  NzrOpportunitiesMaxSignalIdAsc = "NZR_OPPORTUNITIES_MAX_SIGNAL_ID_ASC",
  NzrOpportunitiesMaxSignalIdDesc = "NZR_OPPORTUNITIES_MAX_SIGNAL_ID_DESC",
  NzrOpportunitiesMaxStatusAsc = "NZR_OPPORTUNITIES_MAX_STATUS_ASC",
  NzrOpportunitiesMaxStatusDesc = "NZR_OPPORTUNITIES_MAX_STATUS_DESC",
  NzrOpportunitiesMaxValidityAsc = "NZR_OPPORTUNITIES_MAX_VALIDITY_ASC",
  NzrOpportunitiesMaxValidityDesc = "NZR_OPPORTUNITIES_MAX_VALIDITY_DESC",
  NzrOpportunitiesMinAssigneeAsc = "NZR_OPPORTUNITIES_MIN_ASSIGNEE_ASC",
  NzrOpportunitiesMinAssigneeDesc = "NZR_OPPORTUNITIES_MIN_ASSIGNEE_DESC",
  NzrOpportunitiesMinCreatedUtcAsc = "NZR_OPPORTUNITIES_MIN_CREATED_UTC_ASC",
  NzrOpportunitiesMinCreatedUtcDesc = "NZR_OPPORTUNITIES_MIN_CREATED_UTC_DESC",
  NzrOpportunitiesMinIdAsc = "NZR_OPPORTUNITIES_MIN_ID_ASC",
  NzrOpportunitiesMinIdDesc = "NZR_OPPORTUNITIES_MIN_ID_DESC",
  NzrOpportunitiesMinLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_MIN_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesMinLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_MIN_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesMinMetadataAsc = "NZR_OPPORTUNITIES_MIN_METADATA_ASC",
  NzrOpportunitiesMinMetadataDesc = "NZR_OPPORTUNITIES_MIN_METADATA_DESC",
  NzrOpportunitiesMinSharedFromAsc = "NZR_OPPORTUNITIES_MIN_SHARED_FROM_ASC",
  NzrOpportunitiesMinSharedFromDesc = "NZR_OPPORTUNITIES_MIN_SHARED_FROM_DESC",
  NzrOpportunitiesMinSignalIdAsc = "NZR_OPPORTUNITIES_MIN_SIGNAL_ID_ASC",
  NzrOpportunitiesMinSignalIdDesc = "NZR_OPPORTUNITIES_MIN_SIGNAL_ID_DESC",
  NzrOpportunitiesMinStatusAsc = "NZR_OPPORTUNITIES_MIN_STATUS_ASC",
  NzrOpportunitiesMinStatusDesc = "NZR_OPPORTUNITIES_MIN_STATUS_DESC",
  NzrOpportunitiesMinValidityAsc = "NZR_OPPORTUNITIES_MIN_VALIDITY_ASC",
  NzrOpportunitiesMinValidityDesc = "NZR_OPPORTUNITIES_MIN_VALIDITY_DESC",
  NzrOpportunitiesStddevPopulationAssigneeAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesStddevPopulationAssigneeDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesStddevPopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesStddevPopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesStddevPopulationIdAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_ID_ASC",
  NzrOpportunitiesStddevPopulationIdDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_ID_DESC",
  NzrOpportunitiesStddevPopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesStddevPopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesStddevPopulationMetadataAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_METADATA_ASC",
  NzrOpportunitiesStddevPopulationMetadataDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_METADATA_DESC",
  NzrOpportunitiesStddevPopulationSharedFromAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesStddevPopulationSharedFromDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesStddevPopulationSignalIdAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesStddevPopulationSignalIdDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesStddevPopulationStatusAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_STATUS_ASC",
  NzrOpportunitiesStddevPopulationStatusDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_STATUS_DESC",
  NzrOpportunitiesStddevPopulationValidityAsc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesStddevPopulationValidityDesc = "NZR_OPPORTUNITIES_STDDEV_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesStddevSampleAssigneeAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesStddevSampleAssigneeDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesStddevSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesStddevSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesStddevSampleIdAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_ID_ASC",
  NzrOpportunitiesStddevSampleIdDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_ID_DESC",
  NzrOpportunitiesStddevSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesStddevSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesStddevSampleMetadataAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_METADATA_ASC",
  NzrOpportunitiesStddevSampleMetadataDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_METADATA_DESC",
  NzrOpportunitiesStddevSampleSharedFromAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesStddevSampleSharedFromDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesStddevSampleSignalIdAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesStddevSampleSignalIdDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesStddevSampleStatusAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_STATUS_ASC",
  NzrOpportunitiesStddevSampleStatusDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_STATUS_DESC",
  NzrOpportunitiesStddevSampleValidityAsc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesStddevSampleValidityDesc = "NZR_OPPORTUNITIES_STDDEV_SAMPLE_VALIDITY_DESC",
  NzrOpportunitiesSumAssigneeAsc = "NZR_OPPORTUNITIES_SUM_ASSIGNEE_ASC",
  NzrOpportunitiesSumAssigneeDesc = "NZR_OPPORTUNITIES_SUM_ASSIGNEE_DESC",
  NzrOpportunitiesSumCreatedUtcAsc = "NZR_OPPORTUNITIES_SUM_CREATED_UTC_ASC",
  NzrOpportunitiesSumCreatedUtcDesc = "NZR_OPPORTUNITIES_SUM_CREATED_UTC_DESC",
  NzrOpportunitiesSumIdAsc = "NZR_OPPORTUNITIES_SUM_ID_ASC",
  NzrOpportunitiesSumIdDesc = "NZR_OPPORTUNITIES_SUM_ID_DESC",
  NzrOpportunitiesSumLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_SUM_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesSumLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_SUM_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesSumMetadataAsc = "NZR_OPPORTUNITIES_SUM_METADATA_ASC",
  NzrOpportunitiesSumMetadataDesc = "NZR_OPPORTUNITIES_SUM_METADATA_DESC",
  NzrOpportunitiesSumSharedFromAsc = "NZR_OPPORTUNITIES_SUM_SHARED_FROM_ASC",
  NzrOpportunitiesSumSharedFromDesc = "NZR_OPPORTUNITIES_SUM_SHARED_FROM_DESC",
  NzrOpportunitiesSumSignalIdAsc = "NZR_OPPORTUNITIES_SUM_SIGNAL_ID_ASC",
  NzrOpportunitiesSumSignalIdDesc = "NZR_OPPORTUNITIES_SUM_SIGNAL_ID_DESC",
  NzrOpportunitiesSumStatusAsc = "NZR_OPPORTUNITIES_SUM_STATUS_ASC",
  NzrOpportunitiesSumStatusDesc = "NZR_OPPORTUNITIES_SUM_STATUS_DESC",
  NzrOpportunitiesSumValidityAsc = "NZR_OPPORTUNITIES_SUM_VALIDITY_ASC",
  NzrOpportunitiesSumValidityDesc = "NZR_OPPORTUNITIES_SUM_VALIDITY_DESC",
  NzrOpportunitiesVariancePopulationAssigneeAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_ASSIGNEE_ASC",
  NzrOpportunitiesVariancePopulationAssigneeDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_ASSIGNEE_DESC",
  NzrOpportunitiesVariancePopulationCreatedUtcAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_CREATED_UTC_ASC",
  NzrOpportunitiesVariancePopulationCreatedUtcDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_CREATED_UTC_DESC",
  NzrOpportunitiesVariancePopulationIdAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_ID_ASC",
  NzrOpportunitiesVariancePopulationIdDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_ID_DESC",
  NzrOpportunitiesVariancePopulationLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesVariancePopulationLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesVariancePopulationMetadataAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_METADATA_ASC",
  NzrOpportunitiesVariancePopulationMetadataDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_METADATA_DESC",
  NzrOpportunitiesVariancePopulationSharedFromAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_SHARED_FROM_ASC",
  NzrOpportunitiesVariancePopulationSharedFromDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_SHARED_FROM_DESC",
  NzrOpportunitiesVariancePopulationSignalIdAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_SIGNAL_ID_ASC",
  NzrOpportunitiesVariancePopulationSignalIdDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_SIGNAL_ID_DESC",
  NzrOpportunitiesVariancePopulationStatusAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_STATUS_ASC",
  NzrOpportunitiesVariancePopulationStatusDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_STATUS_DESC",
  NzrOpportunitiesVariancePopulationValidityAsc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_VALIDITY_ASC",
  NzrOpportunitiesVariancePopulationValidityDesc = "NZR_OPPORTUNITIES_VARIANCE_POPULATION_VALIDITY_DESC",
  NzrOpportunitiesVarianceSampleAssigneeAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_ASSIGNEE_ASC",
  NzrOpportunitiesVarianceSampleAssigneeDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_ASSIGNEE_DESC",
  NzrOpportunitiesVarianceSampleCreatedUtcAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  NzrOpportunitiesVarianceSampleCreatedUtcDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  NzrOpportunitiesVarianceSampleIdAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_ID_ASC",
  NzrOpportunitiesVarianceSampleIdDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_ID_DESC",
  NzrOpportunitiesVarianceSampleLastUpdatedUtcAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_LAST_UPDATED_UTC_ASC",
  NzrOpportunitiesVarianceSampleLastUpdatedUtcDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_LAST_UPDATED_UTC_DESC",
  NzrOpportunitiesVarianceSampleMetadataAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_METADATA_ASC",
  NzrOpportunitiesVarianceSampleMetadataDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_METADATA_DESC",
  NzrOpportunitiesVarianceSampleSharedFromAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_SHARED_FROM_ASC",
  NzrOpportunitiesVarianceSampleSharedFromDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_SHARED_FROM_DESC",
  NzrOpportunitiesVarianceSampleSignalIdAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_SIGNAL_ID_ASC",
  NzrOpportunitiesVarianceSampleSignalIdDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_SIGNAL_ID_DESC",
  NzrOpportunitiesVarianceSampleStatusAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_STATUS_ASC",
  NzrOpportunitiesVarianceSampleStatusDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_STATUS_DESC",
  NzrOpportunitiesVarianceSampleValidityAsc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_VALIDITY_ASC",
  NzrOpportunitiesVarianceSampleValidityDesc = "NZR_OPPORTUNITIES_VARIANCE_SAMPLE_VALIDITY_DESC",
  OutputAsc = "OUTPUT_ASC",
  OutputDesc = "OUTPUT_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RuleIdAsc = "RULE_ID_ASC",
  RuleIdDesc = "RULE_ID_DESC",
  ValidUntilUtcAsc = "VALID_UNTIL_UTC_ASC",
  ValidUntilUtcDesc = "VALID_UNTIL_UTC_DESC",
}

/** A connection to a list of `SrcCompany` values. */
export type SrcCompaniesConnection = {
  __typename?: "SrcCompaniesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcCompanyAggregates>;
  /** A list of edges which contains the `SrcCompany` and cursor to aid in pagination. */
  edges: Array<SrcCompaniesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcCompanyAggregates>>;
  /** A list of `SrcCompany` objects. */
  nodes: Array<SrcCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcCompany` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcCompany` values. */
export type SrcCompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcCompanyGroupBy>;
  having?: InputMaybe<SrcCompanyHavingInput>;
};

/** A `SrcCompany` edge in the connection. */
export type SrcCompaniesEdge = {
  __typename?: "SrcCompaniesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcCompany` at the end of the edge. */
  node: SrcCompany;
};

/** Methods to use when ordering `SrcCompany`. */
export enum SrcCompaniesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

/** This is the source table for all the relationships for Company entity */
export type SrcCompany = Node & {
  __typename?: "SrcCompany";
  /** Reads a single `LgCompany` that is related to this `SrcCompany`. */
  company?: Maybe<LgCompany>;
  companyId: Scalars["UUID"]["output"];
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads through a `DealroomCompany`. */
  dealroomCompanyAsSource?: Maybe<DealroomCompany>;
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** Reads through a `MnEntry`. */
  mnEntryAsSource?: Maybe<MnEntry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads through a `OpsCompany`. */
  opsCompanyAsSource?: Maybe<OpsCompany>;
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
  /** Reads through a `TrelloCard`. */
  trelloCardAsSource?: Maybe<TrelloCard>;
};

export type SrcCompanyAggregates = {
  __typename?: "SrcCompanyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcCompanyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcCompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcCompanyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcCompanyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcCompanyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcCompanyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcCompanySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcCompanyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcCompanyVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcCompany` object types. */
export type SrcCompanyAggregatesFilter = {
  /** Mean average aggregate over matching `SrcCompany` objects. */
  average?: InputMaybe<SrcCompanyAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcCompany` objects. */
  distinctCount?: InputMaybe<SrcCompanyDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcCompany` object to be included within the aggregate. */
  filter?: InputMaybe<SrcCompanyFilter>;
  /** Maximum aggregate over matching `SrcCompany` objects. */
  max?: InputMaybe<SrcCompanyMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcCompany` objects. */
  min?: InputMaybe<SrcCompanyMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcCompany` objects. */
  stddevPopulation?: InputMaybe<SrcCompanyStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcCompany` objects. */
  stddevSample?: InputMaybe<SrcCompanyStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcCompany` objects. */
  sum?: InputMaybe<SrcCompanySumAggregateFilter>;
  /** Population variance aggregate over matching `SrcCompany` objects. */
  variancePopulation?: InputMaybe<SrcCompanyVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcCompany` objects. */
  varianceSample?: InputMaybe<SrcCompanyVarianceSampleAggregateFilter>;
};

export type SrcCompanyAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyAverageAggregates = {
  __typename?: "SrcCompanyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcCompany` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SrcCompanyCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

/** A connection to a list of `SrcCompanyCopy` values. */
export type SrcCompanyCopiesConnection = {
  __typename?: "SrcCompanyCopiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcCompanyCopyAggregates>;
  /** A list of edges which contains the `SrcCompanyCopy` and cursor to aid in pagination. */
  edges: Array<SrcCompanyCopiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcCompanyCopyAggregates>>;
  /** A list of `SrcCompanyCopy` objects. */
  nodes: Array<SrcCompanyCopy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcCompanyCopy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcCompanyCopy` values. */
export type SrcCompanyCopiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcCompanyCopyGroupBy>;
  having?: InputMaybe<SrcCompanyCopyHavingInput>;
};

/** A `SrcCompanyCopy` edge in the connection. */
export type SrcCompanyCopiesEdge = {
  __typename?: "SrcCompanyCopiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcCompanyCopy` at the end of the edge. */
  node: SrcCompanyCopy;
};

/** Methods to use when ordering `SrcCompanyCopy`. */
export enum SrcCompanyCopiesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

export type SrcCompanyCopy = {
  __typename?: "SrcCompanyCopy";
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  sourceId?: Maybe<Scalars["String"]["output"]>;
  sourceType?: Maybe<Scalars["String"]["output"]>;
};

export type SrcCompanyCopyAggregates = {
  __typename?: "SrcCompanyCopyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcCompanyCopyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcCompanyCopyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcCompanyCopyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcCompanyCopyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcCompanyCopyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcCompanyCopyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcCompanyCopySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcCompanyCopyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcCompanyCopyVarianceSampleAggregates>;
};

export type SrcCompanyCopyAverageAggregates = {
  __typename?: "SrcCompanyCopyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcCompanyCopy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SrcCompanyCopyCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcCompanyCopyDistinctCountAggregates = {
  __typename?: "SrcCompanyCopyDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcCompanyCopy` object types. All fields are combined with a logical ‘and.’ */
export type SrcCompanyCopyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcCompanyCopyFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcCompanyCopyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcCompanyCopyFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcCompanyCopy` for usage during aggregation. */
export enum SrcCompanyCopyGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Id = "ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcCompanyCopyHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcCompanyCopy` aggregates. */
export type SrcCompanyCopyHavingInput = {
  AND?: InputMaybe<Array<SrcCompanyCopyHavingInput>>;
  OR?: InputMaybe<Array<SrcCompanyCopyHavingInput>>;
  average?: InputMaybe<SrcCompanyCopyHavingAverageInput>;
  distinctCount?: InputMaybe<SrcCompanyCopyHavingDistinctCountInput>;
  max?: InputMaybe<SrcCompanyCopyHavingMaxInput>;
  min?: InputMaybe<SrcCompanyCopyHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcCompanyCopyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcCompanyCopyHavingStddevSampleInput>;
  sum?: InputMaybe<SrcCompanyCopyHavingSumInput>;
  variancePopulation?: InputMaybe<SrcCompanyCopyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcCompanyCopyHavingVarianceSampleInput>;
};

export type SrcCompanyCopyHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCopyMaxAggregates = {
  __typename?: "SrcCompanyCopyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyCopyMinAggregates = {
  __typename?: "SrcCompanyCopyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyCopyStddevPopulationAggregates = {
  __typename?: "SrcCompanyCopyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCopyStddevSampleAggregates = {
  __typename?: "SrcCompanyCopyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCopySumAggregates = {
  __typename?: "SrcCompanyCopySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcCompanyCopyVariancePopulationAggregates = {
  __typename?: "SrcCompanyCopyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCopyVarianceSampleAggregates = {
  __typename?: "SrcCompanyCopyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcCompanyCpy` values. */
export type SrcCompanyCpiesConnection = {
  __typename?: "SrcCompanyCpiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcCompanyCpyAggregates>;
  /** A list of edges which contains the `SrcCompanyCpy` and cursor to aid in pagination. */
  edges: Array<SrcCompanyCpiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcCompanyCpyAggregates>>;
  /** A list of `SrcCompanyCpy` objects. */
  nodes: Array<SrcCompanyCpy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcCompanyCpy` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcCompanyCpy` values. */
export type SrcCompanyCpiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcCompanyCpyGroupBy>;
  having?: InputMaybe<SrcCompanyCpyHavingInput>;
};

/** A `SrcCompanyCpy` edge in the connection. */
export type SrcCompanyCpiesEdge = {
  __typename?: "SrcCompanyCpiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcCompanyCpy` at the end of the edge. */
  node: SrcCompanyCpy;
};

/** Methods to use when ordering `SrcCompanyCpy`. */
export enum SrcCompanyCpiesOrderBy {
  CompanyIdAsc = "COMPANY_ID_ASC",
  CompanyIdDesc = "COMPANY_ID_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

export type SrcCompanyCpy = {
  __typename?: "SrcCompanyCpy";
  companyId?: Maybe<Scalars["UUID"]["output"]>;
  createdUtc?: Maybe<Scalars["Datetime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdatedUtc?: Maybe<Scalars["Datetime"]["output"]>;
  sourceId?: Maybe<Scalars["String"]["output"]>;
  sourceType?: Maybe<Scalars["String"]["output"]>;
};

export type SrcCompanyCpyAggregates = {
  __typename?: "SrcCompanyCpyAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcCompanyCpyAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcCompanyCpyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcCompanyCpyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcCompanyCpyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcCompanyCpyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcCompanyCpyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcCompanyCpySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcCompanyCpyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcCompanyCpyVarianceSampleAggregates>;
};

export type SrcCompanyCpyAverageAggregates = {
  __typename?: "SrcCompanyCpyAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcCompanyCpy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SrcCompanyCpyCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcCompanyCpyDistinctCountAggregates = {
  __typename?: "SrcCompanyCpyDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcCompanyCpy` object types. All fields are combined with a logical ‘and.’ */
export type SrcCompanyCpyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcCompanyCpyFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcCompanyCpyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcCompanyCpyFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcCompanyCpy` for usage during aggregation. */
export enum SrcCompanyCpyGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Id = "ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcCompanyCpyHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcCompanyCpy` aggregates. */
export type SrcCompanyCpyHavingInput = {
  AND?: InputMaybe<Array<SrcCompanyCpyHavingInput>>;
  OR?: InputMaybe<Array<SrcCompanyCpyHavingInput>>;
  average?: InputMaybe<SrcCompanyCpyHavingAverageInput>;
  distinctCount?: InputMaybe<SrcCompanyCpyHavingDistinctCountInput>;
  max?: InputMaybe<SrcCompanyCpyHavingMaxInput>;
  min?: InputMaybe<SrcCompanyCpyHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcCompanyCpyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcCompanyCpyHavingStddevSampleInput>;
  sum?: InputMaybe<SrcCompanyCpyHavingSumInput>;
  variancePopulation?: InputMaybe<SrcCompanyCpyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcCompanyCpyHavingVarianceSampleInput>;
};

export type SrcCompanyCpyHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyCpyMaxAggregates = {
  __typename?: "SrcCompanyCpyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyCpyMinAggregates = {
  __typename?: "SrcCompanyCpyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyCpyStddevPopulationAggregates = {
  __typename?: "SrcCompanyCpyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCpyStddevSampleAggregates = {
  __typename?: "SrcCompanyCpyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCpySumAggregates = {
  __typename?: "SrcCompanyCpySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcCompanyCpyVariancePopulationAggregates = {
  __typename?: "SrcCompanyCpyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyCpyVarianceSampleAggregates = {
  __typename?: "SrcCompanyCpyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyDistinctCountAggregateFilter = {
  companyId?: InputMaybe<BigIntFilter>;
  createdUtc?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcCompanyDistinctCountAggregates = {
  __typename?: "SrcCompanyDistinctCountAggregates";
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcCompany` object types. All fields are combined with a logical ‘and.’ */
export type SrcCompanyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcCompanyFilter>>;
  /** Filter by the object’s `company` relation. */
  company?: InputMaybe<LgCompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dealroomCompanyAsSource` polymorphic relation. */
  dealroomCompanyAsSource?: InputMaybe<DealroomCompanyFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `mnEntryAsSource` polymorphic relation. */
  mnEntryAsSource?: InputMaybe<MnEntryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcCompanyFilter>;
  /** Filter by the object’s `opsCompanyAsSource` polymorphic relation. */
  opsCompanyAsSource?: InputMaybe<OpsCompanyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcCompanyFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloCardAsSource` polymorphic relation. */
  trelloCardAsSource?: InputMaybe<TrelloCardFilter>;
};

/** Grouping methods for `SrcCompany` for usage during aggregation. */
export enum SrcCompanyGroupBy {
  CompanyId = "COMPANY_ID",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcCompanyHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcCompany` aggregates. */
export type SrcCompanyHavingInput = {
  AND?: InputMaybe<Array<SrcCompanyHavingInput>>;
  OR?: InputMaybe<Array<SrcCompanyHavingInput>>;
  average?: InputMaybe<SrcCompanyHavingAverageInput>;
  distinctCount?: InputMaybe<SrcCompanyHavingDistinctCountInput>;
  max?: InputMaybe<SrcCompanyHavingMaxInput>;
  min?: InputMaybe<SrcCompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcCompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcCompanyHavingStddevSampleInput>;
  sum?: InputMaybe<SrcCompanyHavingSumInput>;
  variancePopulation?: InputMaybe<SrcCompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcCompanyHavingVarianceSampleInput>;
};

export type SrcCompanyHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** This is the source table for all the relationships for Company entity */
export type SrcCompanyInvestor = Node & {
  __typename?: "SrcCompanyInvestor";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgCompanyInvestor` that is related to this `SrcCompanyInvestor`. */
  entity?: Maybe<LgCompanyInvestor>;
  entityId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcCompanyInvestorAggregates = {
  __typename?: "SrcCompanyInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcCompanyInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcCompanyInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcCompanyInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcCompanyInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcCompanyInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcCompanyInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcCompanyInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcCompanyInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcCompanyInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcCompanyInvestor` object types. */
export type SrcCompanyInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `SrcCompanyInvestor` objects. */
  average?: InputMaybe<SrcCompanyInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcCompanyInvestor` objects. */
  distinctCount?: InputMaybe<SrcCompanyInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcCompanyInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<SrcCompanyInvestorFilter>;
  /** Maximum aggregate over matching `SrcCompanyInvestor` objects. */
  max?: InputMaybe<SrcCompanyInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcCompanyInvestor` objects. */
  min?: InputMaybe<SrcCompanyInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcCompanyInvestor` objects. */
  stddevPopulation?: InputMaybe<SrcCompanyInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcCompanyInvestor` objects. */
  stddevSample?: InputMaybe<SrcCompanyInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcCompanyInvestor` objects. */
  sum?: InputMaybe<SrcCompanyInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcCompanyInvestor` objects. */
  variancePopulation?: InputMaybe<SrcCompanyInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcCompanyInvestor` objects. */
  varianceSample?: InputMaybe<SrcCompanyInvestorVarianceSampleAggregateFilter>;
};

export type SrcCompanyInvestorAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyInvestorAverageAggregates = {
  __typename?: "SrcCompanyInvestorAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcCompanyInvestor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SrcCompanyInvestorCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcCompanyInvestorDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcCompanyInvestorDistinctCountAggregates = {
  __typename?: "SrcCompanyInvestorDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcCompanyInvestor` object types. All fields are combined with a logical ‘and.’ */
export type SrcCompanyInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcCompanyInvestorFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgCompanyInvestorFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcCompanyInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcCompanyInvestorFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcCompanyInvestor` for usage during aggregation. */
export enum SrcCompanyInvestorGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcCompanyInvestorHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcCompanyInvestor` aggregates. */
export type SrcCompanyInvestorHavingInput = {
  AND?: InputMaybe<Array<SrcCompanyInvestorHavingInput>>;
  OR?: InputMaybe<Array<SrcCompanyInvestorHavingInput>>;
  average?: InputMaybe<SrcCompanyInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<SrcCompanyInvestorHavingDistinctCountInput>;
  max?: InputMaybe<SrcCompanyInvestorHavingMaxInput>;
  min?: InputMaybe<SrcCompanyInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcCompanyInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcCompanyInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<SrcCompanyInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<SrcCompanyInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcCompanyInvestorHavingVarianceSampleInput>;
};

export type SrcCompanyInvestorHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyInvestorMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyInvestorMaxAggregates = {
  __typename?: "SrcCompanyInvestorMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyInvestorMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyInvestorMinAggregates = {
  __typename?: "SrcCompanyInvestorMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyInvestorStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyInvestorStddevPopulationAggregates = {
  __typename?: "SrcCompanyInvestorStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyInvestorStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyInvestorStddevSampleAggregates = {
  __typename?: "SrcCompanyInvestorStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyInvestorSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcCompanyInvestorSumAggregates = {
  __typename?: "SrcCompanyInvestorSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcCompanyInvestorVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyInvestorVariancePopulationAggregates = {
  __typename?: "SrcCompanyInvestorVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyInvestorVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyInvestorVarianceSampleAggregates = {
  __typename?: "SrcCompanyInvestorVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcCompanyInvestor` values. */
export type SrcCompanyInvestorsConnection = {
  __typename?: "SrcCompanyInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcCompanyInvestorAggregates>;
  /** A list of edges which contains the `SrcCompanyInvestor` and cursor to aid in pagination. */
  edges: Array<SrcCompanyInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcCompanyInvestorAggregates>>;
  /** A list of `SrcCompanyInvestor` objects. */
  nodes: Array<SrcCompanyInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcCompanyInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcCompanyInvestor` values. */
export type SrcCompanyInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcCompanyInvestorGroupBy>;
  having?: InputMaybe<SrcCompanyInvestorHavingInput>;
};

/** A `SrcCompanyInvestor` edge in the connection. */
export type SrcCompanyInvestorsEdge = {
  __typename?: "SrcCompanyInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcCompanyInvestor` at the end of the edge. */
  node: SrcCompanyInvestor;
};

/** Methods to use when ordering `SrcCompanyInvestor`. */
export enum SrcCompanyInvestorsOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

export type SrcCompanyMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyMaxAggregates = {
  __typename?: "SrcCompanyMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyMinAggregates = {
  __typename?: "SrcCompanyMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyStddevPopulationAggregates = {
  __typename?: "SrcCompanyStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyStddevSampleAggregates = {
  __typename?: "SrcCompanyStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanySumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcCompanySumAggregates = {
  __typename?: "SrcCompanySumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

/** This is the source table for all the relationships for Company entity */
export type SrcCompanyTeam = Node & {
  __typename?: "SrcCompanyTeam";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgCompanyTeam` that is related to this `SrcCompanyTeam`. */
  entity?: Maybe<LgCompanyTeam>;
  entityId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcCompanyTeamAggregates = {
  __typename?: "SrcCompanyTeamAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcCompanyTeamAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcCompanyTeamDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcCompanyTeamMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcCompanyTeamMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcCompanyTeamStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcCompanyTeamStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcCompanyTeamSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcCompanyTeamVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcCompanyTeamVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcCompanyTeam` object types. */
export type SrcCompanyTeamAggregatesFilter = {
  /** Mean average aggregate over matching `SrcCompanyTeam` objects. */
  average?: InputMaybe<SrcCompanyTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcCompanyTeam` objects. */
  distinctCount?: InputMaybe<SrcCompanyTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcCompanyTeam` object to be included within the aggregate. */
  filter?: InputMaybe<SrcCompanyTeamFilter>;
  /** Maximum aggregate over matching `SrcCompanyTeam` objects. */
  max?: InputMaybe<SrcCompanyTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcCompanyTeam` objects. */
  min?: InputMaybe<SrcCompanyTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcCompanyTeam` objects. */
  stddevPopulation?: InputMaybe<SrcCompanyTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcCompanyTeam` objects. */
  stddevSample?: InputMaybe<SrcCompanyTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcCompanyTeam` objects. */
  sum?: InputMaybe<SrcCompanyTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcCompanyTeam` objects. */
  variancePopulation?: InputMaybe<SrcCompanyTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcCompanyTeam` objects. */
  varianceSample?: InputMaybe<SrcCompanyTeamVarianceSampleAggregateFilter>;
};

export type SrcCompanyTeamAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyTeamAverageAggregates = {
  __typename?: "SrcCompanyTeamAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcCompanyTeam` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SrcCompanyTeamCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcCompanyTeamDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcCompanyTeamDistinctCountAggregates = {
  __typename?: "SrcCompanyTeamDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcCompanyTeam` object types. All fields are combined with a logical ‘and.’ */
export type SrcCompanyTeamFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcCompanyTeamFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgCompanyTeamFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcCompanyTeamFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcCompanyTeamFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcCompanyTeam` for usage during aggregation. */
export enum SrcCompanyTeamGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcCompanyTeamHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcCompanyTeam` aggregates. */
export type SrcCompanyTeamHavingInput = {
  AND?: InputMaybe<Array<SrcCompanyTeamHavingInput>>;
  OR?: InputMaybe<Array<SrcCompanyTeamHavingInput>>;
  average?: InputMaybe<SrcCompanyTeamHavingAverageInput>;
  distinctCount?: InputMaybe<SrcCompanyTeamHavingDistinctCountInput>;
  max?: InputMaybe<SrcCompanyTeamHavingMaxInput>;
  min?: InputMaybe<SrcCompanyTeamHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcCompanyTeamHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcCompanyTeamHavingStddevSampleInput>;
  sum?: InputMaybe<SrcCompanyTeamHavingSumInput>;
  variancePopulation?: InputMaybe<SrcCompanyTeamHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcCompanyTeamHavingVarianceSampleInput>;
};

export type SrcCompanyTeamHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcCompanyTeamMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyTeamMaxAggregates = {
  __typename?: "SrcCompanyTeamMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyTeamMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcCompanyTeamMinAggregates = {
  __typename?: "SrcCompanyTeamMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcCompanyTeamStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyTeamStddevPopulationAggregates = {
  __typename?: "SrcCompanyTeamStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyTeamStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyTeamStddevSampleAggregates = {
  __typename?: "SrcCompanyTeamStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyTeamSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcCompanyTeamSumAggregates = {
  __typename?: "SrcCompanyTeamSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcCompanyTeamVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyTeamVariancePopulationAggregates = {
  __typename?: "SrcCompanyTeamVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyTeamVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyTeamVarianceSampleAggregates = {
  __typename?: "SrcCompanyTeamVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcCompanyTeam` values. */
export type SrcCompanyTeamsConnection = {
  __typename?: "SrcCompanyTeamsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcCompanyTeamAggregates>;
  /** A list of edges which contains the `SrcCompanyTeam` and cursor to aid in pagination. */
  edges: Array<SrcCompanyTeamsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcCompanyTeamAggregates>>;
  /** A list of `SrcCompanyTeam` objects. */
  nodes: Array<SrcCompanyTeam>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcCompanyTeam` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcCompanyTeam` values. */
export type SrcCompanyTeamsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcCompanyTeamGroupBy>;
  having?: InputMaybe<SrcCompanyTeamHavingInput>;
};

/** A `SrcCompanyTeam` edge in the connection. */
export type SrcCompanyTeamsEdge = {
  __typename?: "SrcCompanyTeamsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcCompanyTeam` at the end of the edge. */
  node: SrcCompanyTeam;
};

/** Methods to use when ordering `SrcCompanyTeam`. */
export enum SrcCompanyTeamsOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

export type SrcCompanyVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyVariancePopulationAggregates = {
  __typename?: "SrcCompanyVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcCompanyVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcCompanyVarianceSampleAggregates = {
  __typename?: "SrcCompanyVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcConfig = Node & {
  __typename?: "SrcConfig";
  config?: Maybe<Scalars["JSON"]["output"]>;
  createdUtc: Scalars["Datetime"]["output"];
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  lastModifiedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  tableName?: Maybe<Scalars["String"]["output"]>;
};

export type SrcConfigAggregates = {
  __typename?: "SrcConfigAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcConfigDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `SrcConfig` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SrcConfigCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `disabled` field. */
  disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `tableName` field. */
  tableName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcConfigDistinctCountAggregates = {
  __typename?: "SrcConfigDistinctCountAggregates";
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of disabled across the matching connection */
  disabled?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastModifiedUtc across the matching connection */
  lastModifiedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tableName across the matching connection */
  tableName?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcConfig` object types. All fields are combined with a logical ‘and.’ */
export type SrcConfigFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcConfigFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `disabled` field. */
  disabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastModifiedUtc` field. */
  lastModifiedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcConfigFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcConfigFilter>>;
  /** Filter by the object’s `tableName` field. */
  tableName?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcConfig` for usage during aggregation. */
export enum SrcConfigGroupBy {
  Config = "CONFIG",
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  Disabled = "DISABLED",
  LastModifiedUtc = "LAST_MODIFIED_UTC",
  LastModifiedUtcTruncatedToDay = "LAST_MODIFIED_UTC_TRUNCATED_TO_DAY",
  LastModifiedUtcTruncatedToHour = "LAST_MODIFIED_UTC_TRUNCATED_TO_HOUR",
  TableName = "TABLE_NAME",
}

export type SrcConfigHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcConfig` aggregates. */
export type SrcConfigHavingInput = {
  AND?: InputMaybe<Array<SrcConfigHavingInput>>;
  OR?: InputMaybe<Array<SrcConfigHavingInput>>;
  average?: InputMaybe<SrcConfigHavingAverageInput>;
  distinctCount?: InputMaybe<SrcConfigHavingDistinctCountInput>;
  max?: InputMaybe<SrcConfigHavingMaxInput>;
  min?: InputMaybe<SrcConfigHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcConfigHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcConfigHavingStddevSampleInput>;
  sum?: InputMaybe<SrcConfigHavingSumInput>;
  variancePopulation?: InputMaybe<SrcConfigHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcConfigHavingVarianceSampleInput>;
};

export type SrcConfigHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcConfigHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  lastModifiedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `SrcConfig` values. */
export type SrcConfigsConnection = {
  __typename?: "SrcConfigsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcConfigAggregates>;
  /** A list of edges which contains the `SrcConfig` and cursor to aid in pagination. */
  edges: Array<SrcConfigsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcConfigAggregates>>;
  /** A list of `SrcConfig` objects. */
  nodes: Array<SrcConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcConfig` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcConfig` values. */
export type SrcConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcConfigGroupBy>;
  having?: InputMaybe<SrcConfigHavingInput>;
};

/** A `SrcConfig` edge in the connection. */
export type SrcConfigsEdge = {
  __typename?: "SrcConfigsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcConfig` at the end of the edge. */
  node: SrcConfig;
};

/** Methods to use when ordering `SrcConfig`. */
export enum SrcConfigsOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  DisabledAsc = "DISABLED_ASC",
  DisabledDesc = "DISABLED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastModifiedUtcAsc = "LAST_MODIFIED_UTC_ASC",
  LastModifiedUtcDesc = "LAST_MODIFIED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TableNameAsc = "TABLE_NAME_ASC",
  TableNameDesc = "TABLE_NAME_DESC",
}

/** This is the source table for all the relationships for Company entity */
export type SrcFounder = Node & {
  __typename?: "SrcFounder";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgFounder` that is related to this `SrcFounder`. */
  entity?: Maybe<LgFounder>;
  entityId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcFounderAggregates = {
  __typename?: "SrcFounderAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcFounderAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcFounderDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcFounderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcFounderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcFounderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcFounderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcFounderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcFounderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcFounderVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcFounder` object types. */
export type SrcFounderAggregatesFilter = {
  /** Mean average aggregate over matching `SrcFounder` objects. */
  average?: InputMaybe<SrcFounderAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcFounder` objects. */
  distinctCount?: InputMaybe<SrcFounderDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcFounder` object to be included within the aggregate. */
  filter?: InputMaybe<SrcFounderFilter>;
  /** Maximum aggregate over matching `SrcFounder` objects. */
  max?: InputMaybe<SrcFounderMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcFounder` objects. */
  min?: InputMaybe<SrcFounderMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcFounder` objects. */
  stddevPopulation?: InputMaybe<SrcFounderStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcFounder` objects. */
  stddevSample?: InputMaybe<SrcFounderStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcFounder` objects. */
  sum?: InputMaybe<SrcFounderSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcFounder` objects. */
  variancePopulation?: InputMaybe<SrcFounderVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcFounder` objects. */
  varianceSample?: InputMaybe<SrcFounderVarianceSampleAggregateFilter>;
};

export type SrcFounderAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFounderAverageAggregates = {
  __typename?: "SrcFounderAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcFounder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SrcFounderCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcFounderDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcFounderDistinctCountAggregates = {
  __typename?: "SrcFounderDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcFounder` object types. All fields are combined with a logical ‘and.’ */
export type SrcFounderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcFounderFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgFounderFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcFounderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcFounderFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcFounder` for usage during aggregation. */
export enum SrcFounderGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcFounderHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcFounder` aggregates. */
export type SrcFounderHavingInput = {
  AND?: InputMaybe<Array<SrcFounderHavingInput>>;
  OR?: InputMaybe<Array<SrcFounderHavingInput>>;
  average?: InputMaybe<SrcFounderHavingAverageInput>;
  distinctCount?: InputMaybe<SrcFounderHavingDistinctCountInput>;
  max?: InputMaybe<SrcFounderHavingMaxInput>;
  min?: InputMaybe<SrcFounderHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcFounderHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcFounderHavingStddevSampleInput>;
  sum?: InputMaybe<SrcFounderHavingSumInput>;
  variancePopulation?: InputMaybe<SrcFounderHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcFounderHavingVarianceSampleInput>;
};

export type SrcFounderHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFounderMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFounderMaxAggregates = {
  __typename?: "SrcFounderMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFounderMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFounderMinAggregates = {
  __typename?: "SrcFounderMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFounderStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFounderStddevPopulationAggregates = {
  __typename?: "SrcFounderStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFounderStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFounderStddevSampleAggregates = {
  __typename?: "SrcFounderStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFounderSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcFounderSumAggregates = {
  __typename?: "SrcFounderSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcFounderVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFounderVariancePopulationAggregates = {
  __typename?: "SrcFounderVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFounderVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFounderVarianceSampleAggregates = {
  __typename?: "SrcFounderVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcFounder` values. */
export type SrcFoundersConnection = {
  __typename?: "SrcFoundersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcFounderAggregates>;
  /** A list of edges which contains the `SrcFounder` and cursor to aid in pagination. */
  edges: Array<SrcFoundersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcFounderAggregates>>;
  /** A list of `SrcFounder` objects. */
  nodes: Array<SrcFounder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcFounder` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcFounder` values. */
export type SrcFoundersConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcFounderGroupBy>;
  having?: InputMaybe<SrcFounderHavingInput>;
};

/** A `SrcFounder` edge in the connection. */
export type SrcFoundersEdge = {
  __typename?: "SrcFoundersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcFounder` at the end of the edge. */
  node: SrcFounder;
};

/** Methods to use when ordering `SrcFounder`. */
export enum SrcFoundersOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

/** This is the source table for all the relationships for Company entity */
export type SrcFundingRound = Node & {
  __typename?: "SrcFundingRound";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgFundingRound` that is related to this `SrcFundingRound`. */
  entity?: Maybe<LgFundingRound>;
  entityId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcFundingRoundAggregates = {
  __typename?: "SrcFundingRoundAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcFundingRoundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcFundingRoundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcFundingRoundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcFundingRoundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcFundingRoundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcFundingRoundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcFundingRoundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcFundingRoundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcFundingRoundVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcFundingRound` object types. */
export type SrcFundingRoundAggregatesFilter = {
  /** Mean average aggregate over matching `SrcFundingRound` objects. */
  average?: InputMaybe<SrcFundingRoundAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcFundingRound` objects. */
  distinctCount?: InputMaybe<SrcFundingRoundDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcFundingRound` object to be included within the aggregate. */
  filter?: InputMaybe<SrcFundingRoundFilter>;
  /** Maximum aggregate over matching `SrcFundingRound` objects. */
  max?: InputMaybe<SrcFundingRoundMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcFundingRound` objects. */
  min?: InputMaybe<SrcFundingRoundMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcFundingRound` objects. */
  stddevPopulation?: InputMaybe<SrcFundingRoundStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcFundingRound` objects. */
  stddevSample?: InputMaybe<SrcFundingRoundStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcFundingRound` objects. */
  sum?: InputMaybe<SrcFundingRoundSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcFundingRound` objects. */
  variancePopulation?: InputMaybe<SrcFundingRoundVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcFundingRound` objects. */
  varianceSample?: InputMaybe<SrcFundingRoundVarianceSampleAggregateFilter>;
};

export type SrcFundingRoundAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundAverageAggregates = {
  __typename?: "SrcFundingRoundAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcFundingRound` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SrcFundingRoundCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcFundingRoundDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcFundingRoundDistinctCountAggregates = {
  __typename?: "SrcFundingRoundDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcFundingRound` object types. All fields are combined with a logical ‘and.’ */
export type SrcFundingRoundFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcFundingRoundFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgFundingRoundFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcFundingRoundFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcFundingRoundFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcFundingRound` for usage during aggregation. */
export enum SrcFundingRoundGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcFundingRoundHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcFundingRound` aggregates. */
export type SrcFundingRoundHavingInput = {
  AND?: InputMaybe<Array<SrcFundingRoundHavingInput>>;
  OR?: InputMaybe<Array<SrcFundingRoundHavingInput>>;
  average?: InputMaybe<SrcFundingRoundHavingAverageInput>;
  distinctCount?: InputMaybe<SrcFundingRoundHavingDistinctCountInput>;
  max?: InputMaybe<SrcFundingRoundHavingMaxInput>;
  min?: InputMaybe<SrcFundingRoundHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcFundingRoundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcFundingRoundHavingStddevSampleInput>;
  sum?: InputMaybe<SrcFundingRoundHavingSumInput>;
  variancePopulation?: InputMaybe<SrcFundingRoundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcFundingRoundHavingVarianceSampleInput>;
};

export type SrcFundingRoundHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** This is the source table for all the relationships for Company entity */
export type SrcFundingRoundInvestor = Node & {
  __typename?: "SrcFundingRoundInvestor";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgFundingRoundInvestor` that is related to this `SrcFundingRoundInvestor`. */
  entity?: Maybe<LgFundingRoundInvestor>;
  entityId: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcFundingRoundInvestorAggregates = {
  __typename?: "SrcFundingRoundInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcFundingRoundInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcFundingRoundInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcFundingRoundInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcFundingRoundInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcFundingRoundInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcFundingRoundInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcFundingRoundInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcFundingRoundInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcFundingRoundInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcFundingRoundInvestor` object types. */
export type SrcFundingRoundInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `SrcFundingRoundInvestor` objects. */
  average?: InputMaybe<SrcFundingRoundInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcFundingRoundInvestor` objects. */
  distinctCount?: InputMaybe<SrcFundingRoundInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcFundingRoundInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<SrcFundingRoundInvestorFilter>;
  /** Maximum aggregate over matching `SrcFundingRoundInvestor` objects. */
  max?: InputMaybe<SrcFundingRoundInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcFundingRoundInvestor` objects. */
  min?: InputMaybe<SrcFundingRoundInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcFundingRoundInvestor` objects. */
  stddevPopulation?: InputMaybe<SrcFundingRoundInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcFundingRoundInvestor` objects. */
  stddevSample?: InputMaybe<SrcFundingRoundInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcFundingRoundInvestor` objects. */
  sum?: InputMaybe<SrcFundingRoundInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcFundingRoundInvestor` objects. */
  variancePopulation?: InputMaybe<SrcFundingRoundInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcFundingRoundInvestor` objects. */
  varianceSample?: InputMaybe<SrcFundingRoundInvestorVarianceSampleAggregateFilter>;
};

export type SrcFundingRoundInvestorAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundInvestorAverageAggregates = {
  __typename?: "SrcFundingRoundInvestorAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcFundingRoundInvestor` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SrcFundingRoundInvestorCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcFundingRoundInvestorDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcFundingRoundInvestorDistinctCountAggregates = {
  __typename?: "SrcFundingRoundInvestorDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcFundingRoundInvestor` object types. All fields are combined with a logical ‘and.’ */
export type SrcFundingRoundInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcFundingRoundInvestorFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgFundingRoundInvestorFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcFundingRoundInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcFundingRoundInvestorFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcFundingRoundInvestor` for usage during aggregation. */
export enum SrcFundingRoundInvestorGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcFundingRoundInvestorHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcFundingRoundInvestor` aggregates. */
export type SrcFundingRoundInvestorHavingInput = {
  AND?: InputMaybe<Array<SrcFundingRoundInvestorHavingInput>>;
  OR?: InputMaybe<Array<SrcFundingRoundInvestorHavingInput>>;
  average?: InputMaybe<SrcFundingRoundInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<SrcFundingRoundInvestorHavingDistinctCountInput>;
  max?: InputMaybe<SrcFundingRoundInvestorHavingMaxInput>;
  min?: InputMaybe<SrcFundingRoundInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcFundingRoundInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcFundingRoundInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<SrcFundingRoundInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<SrcFundingRoundInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcFundingRoundInvestorHavingVarianceSampleInput>;
};

export type SrcFundingRoundInvestorHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcFundingRoundInvestorMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFundingRoundInvestorMaxAggregates = {
  __typename?: "SrcFundingRoundInvestorMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFundingRoundInvestorMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFundingRoundInvestorMinAggregates = {
  __typename?: "SrcFundingRoundInvestorMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFundingRoundInvestorStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundInvestorStddevPopulationAggregates = {
  __typename?: "SrcFundingRoundInvestorStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundInvestorStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundInvestorStddevSampleAggregates = {
  __typename?: "SrcFundingRoundInvestorStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundInvestorSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcFundingRoundInvestorSumAggregates = {
  __typename?: "SrcFundingRoundInvestorSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcFundingRoundInvestorVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundInvestorVariancePopulationAggregates = {
  __typename?: "SrcFundingRoundInvestorVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundInvestorVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundInvestorVarianceSampleAggregates = {
  __typename?: "SrcFundingRoundInvestorVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcFundingRoundInvestor` values. */
export type SrcFundingRoundInvestorsConnection = {
  __typename?: "SrcFundingRoundInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcFundingRoundInvestorAggregates>;
  /** A list of edges which contains the `SrcFundingRoundInvestor` and cursor to aid in pagination. */
  edges: Array<SrcFundingRoundInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcFundingRoundInvestorAggregates>>;
  /** A list of `SrcFundingRoundInvestor` objects. */
  nodes: Array<SrcFundingRoundInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcFundingRoundInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcFundingRoundInvestor` values. */
export type SrcFundingRoundInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcFundingRoundInvestorGroupBy>;
  having?: InputMaybe<SrcFundingRoundInvestorHavingInput>;
};

/** A `SrcFundingRoundInvestor` edge in the connection. */
export type SrcFundingRoundInvestorsEdge = {
  __typename?: "SrcFundingRoundInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcFundingRoundInvestor` at the end of the edge. */
  node: SrcFundingRoundInvestor;
};

/** Methods to use when ordering `SrcFundingRoundInvestor`. */
export enum SrcFundingRoundInvestorsOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

export type SrcFundingRoundMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFundingRoundMaxAggregates = {
  __typename?: "SrcFundingRoundMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFundingRoundMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcFundingRoundMinAggregates = {
  __typename?: "SrcFundingRoundMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcFundingRoundStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundStddevPopulationAggregates = {
  __typename?: "SrcFundingRoundStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundStddevSampleAggregates = {
  __typename?: "SrcFundingRoundStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcFundingRoundSumAggregates = {
  __typename?: "SrcFundingRoundSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcFundingRoundVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundVariancePopulationAggregates = {
  __typename?: "SrcFundingRoundVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcFundingRoundVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcFundingRoundVarianceSampleAggregates = {
  __typename?: "SrcFundingRoundVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcFundingRound` values. */
export type SrcFundingRoundsConnection = {
  __typename?: "SrcFundingRoundsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcFundingRoundAggregates>;
  /** A list of edges which contains the `SrcFundingRound` and cursor to aid in pagination. */
  edges: Array<SrcFundingRoundsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcFundingRoundAggregates>>;
  /** A list of `SrcFundingRound` objects. */
  nodes: Array<SrcFundingRound>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcFundingRound` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcFundingRound` values. */
export type SrcFundingRoundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcFundingRoundGroupBy>;
  having?: InputMaybe<SrcFundingRoundHavingInput>;
};

/** A `SrcFundingRound` edge in the connection. */
export type SrcFundingRoundsEdge = {
  __typename?: "SrcFundingRoundsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcFundingRound` at the end of the edge. */
  node: SrcFundingRound;
};

/** Methods to use when ordering `SrcFundingRound`. */
export enum SrcFundingRoundsOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

/** This is the source table for all the relationships for Company entity */
export type SrcInvestor = Node & {
  __typename?: "SrcInvestor";
  createdUtc: Scalars["Datetime"]["output"];
  /** Reads a single `LgInvestor` that is related to this `SrcInvestor`. */
  entity?: Maybe<LgInvestor>;
  entityId: Scalars["UUID"]["output"];
  id: Scalars["Int"]["output"];
  lastUpdatedUtc: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  sourceId: Scalars["String"]["output"];
  sourceType: Scalars["String"]["output"];
};

export type SrcInvestorAggregates = {
  __typename?: "SrcInvestorAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SrcInvestorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SrcInvestorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SrcInvestorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SrcInvestorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SrcInvestorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SrcInvestorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SrcInvestorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SrcInvestorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SrcInvestorVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SrcInvestor` object types. */
export type SrcInvestorAggregatesFilter = {
  /** Mean average aggregate over matching `SrcInvestor` objects. */
  average?: InputMaybe<SrcInvestorAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SrcInvestor` objects. */
  distinctCount?: InputMaybe<SrcInvestorDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SrcInvestor` object to be included within the aggregate. */
  filter?: InputMaybe<SrcInvestorFilter>;
  /** Maximum aggregate over matching `SrcInvestor` objects. */
  max?: InputMaybe<SrcInvestorMaxAggregateFilter>;
  /** Minimum aggregate over matching `SrcInvestor` objects. */
  min?: InputMaybe<SrcInvestorMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SrcInvestor` objects. */
  stddevPopulation?: InputMaybe<SrcInvestorStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SrcInvestor` objects. */
  stddevSample?: InputMaybe<SrcInvestorStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SrcInvestor` objects. */
  sum?: InputMaybe<SrcInvestorSumAggregateFilter>;
  /** Population variance aggregate over matching `SrcInvestor` objects. */
  variancePopulation?: InputMaybe<SrcInvestorVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SrcInvestor` objects. */
  varianceSample?: InputMaybe<SrcInvestorVarianceSampleAggregateFilter>;
};

export type SrcInvestorAverageAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcInvestorAverageAggregates = {
  __typename?: "SrcInvestorAverageAggregates";
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `SrcInvestor` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SrcInvestorCondition = {
  /** Checks for equality with the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `entityId` field. */
  entityId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `sourceId` field. */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `sourceType` field. */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SrcInvestorDistinctCountAggregateFilter = {
  createdUtc?: InputMaybe<BigIntFilter>;
  entityId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedUtc?: InputMaybe<BigIntFilter>;
  sourceId?: InputMaybe<BigIntFilter>;
  sourceType?: InputMaybe<BigIntFilter>;
};

export type SrcInvestorDistinctCountAggregates = {
  __typename?: "SrcInvestorDistinctCountAggregates";
  /** Distinct count of createdUtc across the matching connection */
  createdUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of entityId across the matching connection */
  entityId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedUtc across the matching connection */
  lastUpdatedUtc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceId across the matching connection */
  sourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of sourceType across the matching connection */
  sourceType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `SrcInvestor` object types. All fields are combined with a logical ‘and.’ */
export type SrcInvestorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SrcInvestorFilter>>;
  /** Filter by the object’s `createdUtc` field. */
  createdUtc?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entity` relation. */
  entity?: InputMaybe<LgInvestorFilter>;
  /** Filter by the object’s `entityId` field. */
  entityId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastUpdatedUtc` field. */
  lastUpdatedUtc?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SrcInvestorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SrcInvestorFilter>>;
  /** Filter by the object’s `sourceId` field. */
  sourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sourceType` field. */
  sourceType?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SrcInvestor` for usage during aggregation. */
export enum SrcInvestorGroupBy {
  CreatedUtc = "CREATED_UTC",
  CreatedUtcTruncatedToDay = "CREATED_UTC_TRUNCATED_TO_DAY",
  CreatedUtcTruncatedToHour = "CREATED_UTC_TRUNCATED_TO_HOUR",
  EntityId = "ENTITY_ID",
  LastUpdatedUtc = "LAST_UPDATED_UTC",
  LastUpdatedUtcTruncatedToDay = "LAST_UPDATED_UTC_TRUNCATED_TO_DAY",
  LastUpdatedUtcTruncatedToHour = "LAST_UPDATED_UTC_TRUNCATED_TO_HOUR",
  SourceId = "SOURCE_ID",
  SourceType = "SOURCE_TYPE",
}

export type SrcInvestorHavingAverageInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingDistinctCountInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SrcInvestor` aggregates. */
export type SrcInvestorHavingInput = {
  AND?: InputMaybe<Array<SrcInvestorHavingInput>>;
  OR?: InputMaybe<Array<SrcInvestorHavingInput>>;
  average?: InputMaybe<SrcInvestorHavingAverageInput>;
  distinctCount?: InputMaybe<SrcInvestorHavingDistinctCountInput>;
  max?: InputMaybe<SrcInvestorHavingMaxInput>;
  min?: InputMaybe<SrcInvestorHavingMinInput>;
  stddevPopulation?: InputMaybe<SrcInvestorHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SrcInvestorHavingStddevSampleInput>;
  sum?: InputMaybe<SrcInvestorHavingSumInput>;
  variancePopulation?: InputMaybe<SrcInvestorHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SrcInvestorHavingVarianceSampleInput>;
};

export type SrcInvestorHavingMaxInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingMinInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingStddevPopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingStddevSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingSumInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingVariancePopulationInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorHavingVarianceSampleInput = {
  createdUtc?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lastUpdatedUtc?: InputMaybe<HavingDatetimeFilter>;
};

export type SrcInvestorMaxAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcInvestorMaxAggregates = {
  __typename?: "SrcInvestorMaxAggregates";
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcInvestorMinAggregateFilter = {
  id?: InputMaybe<IntFilter>;
};

export type SrcInvestorMinAggregates = {
  __typename?: "SrcInvestorMinAggregates";
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SrcInvestorStddevPopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcInvestorStddevPopulationAggregates = {
  __typename?: "SrcInvestorStddevPopulationAggregates";
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcInvestorStddevSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcInvestorStddevSampleAggregates = {
  __typename?: "SrcInvestorStddevSampleAggregates";
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcInvestorSumAggregateFilter = {
  id?: InputMaybe<BigIntFilter>;
};

export type SrcInvestorSumAggregates = {
  __typename?: "SrcInvestorSumAggregates";
  /** Sum of id across the matching connection */
  id: Scalars["BigInt"]["output"];
};

export type SrcInvestorVariancePopulationAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcInvestorVariancePopulationAggregates = {
  __typename?: "SrcInvestorVariancePopulationAggregates";
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type SrcInvestorVarianceSampleAggregateFilter = {
  id?: InputMaybe<BigFloatFilter>;
};

export type SrcInvestorVarianceSampleAggregates = {
  __typename?: "SrcInvestorVarianceSampleAggregates";
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `SrcInvestor` values. */
export type SrcInvestorsConnection = {
  __typename?: "SrcInvestorsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SrcInvestorAggregates>;
  /** A list of edges which contains the `SrcInvestor` and cursor to aid in pagination. */
  edges: Array<SrcInvestorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SrcInvestorAggregates>>;
  /** A list of `SrcInvestor` objects. */
  nodes: Array<SrcInvestor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SrcInvestor` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `SrcInvestor` values. */
export type SrcInvestorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SrcInvestorGroupBy>;
  having?: InputMaybe<SrcInvestorHavingInput>;
};

/** A `SrcInvestor` edge in the connection. */
export type SrcInvestorsEdge = {
  __typename?: "SrcInvestorsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `SrcInvestor` at the end of the edge. */
  node: SrcInvestor;
};

/** Methods to use when ordering `SrcInvestor`. */
export enum SrcInvestorsOrderBy {
  CreatedUtcAsc = "CREATED_UTC_ASC",
  CreatedUtcDesc = "CREATED_UTC_DESC",
  EntityIdAsc = "ENTITY_ID_ASC",
  EntityIdDesc = "ENTITY_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedUtcAsc = "LAST_UPDATED_UTC_ASC",
  LastUpdatedUtcDesc = "LAST_UPDATED_UTC_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceIdAsc = "SOURCE_ID_ASC",
  SourceIdDesc = "SOURCE_ID_DESC",
  SourceTypeAsc = "SOURCE_TYPE_ASC",
  SourceTypeDesc = "SOURCE_TYPE_DESC",
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Performs a fuzzy text search on the field. */
  fuzzy?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]["input"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: "Subscription";
  listen: ListenPayload;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionListenArgs = {
  topic: Scalars["String"]["input"];
};

export type SummariseSentimentInput = {
  companyId: Scalars["String"]["input"];
  forceRecreate?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** An enumeration. */
  summaryType?: InputMaybe<SummaryType>;
};

export enum SummaryType {
  Comments = "COMMENTS",
  Excitement = "EXCITEMENT",
}

export type TagCompanyInput = {
  companyId: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  /** An enumeration. */
  tag: UserTag;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TfForm = Node & {
  __typename?: "TfForm";
  companyName: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  fields: Scalars["JSON"]["output"];
  formStatus: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastUpdatedAt: Scalars["Datetime"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  publishedAt?: Maybe<Scalars["Datetime"]["output"]>;
  responseAverage?: Maybe<Scalars["JSON"]["output"]>;
  responses?: Maybe<Scalars["JSON"]["output"]>;
  settings: Scalars["JSON"]["output"];
  title: Scalars["String"]["output"];
  trelloId: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  /** Reads a single `TfWorkspace` that is related to this `TfForm`. */
  workspace?: Maybe<TfWorkspace>;
  workspaceId: Scalars["String"]["output"];
};

export type TfFormAggregates = {
  __typename?: "TfFormAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TfFormDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `TfForm` object types. */
export type TfFormAggregatesFilter = {
  /** Distinct count aggregate over matching `TfForm` objects. */
  distinctCount?: InputMaybe<TfFormDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TfForm` object to be included within the aggregate. */
  filter?: InputMaybe<TfFormFilter>;
};

/** A condition to be used against `TfForm` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TfFormCondition = {
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fields` field. */
  fields?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `formStatus` field. */
  formStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastUpdatedAt` field. */
  lastUpdatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `responseAverage` field. */
  responseAverage?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `responses` field. */
  responses?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `settings` field. */
  settings?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `trelloId` field. */
  trelloId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TfFormDistinctCountAggregateFilter = {
  companyName?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  fields?: InputMaybe<BigIntFilter>;
  formStatus?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdatedAt?: InputMaybe<BigIntFilter>;
  publishedAt?: InputMaybe<BigIntFilter>;
  responseAverage?: InputMaybe<BigIntFilter>;
  responses?: InputMaybe<BigIntFilter>;
  settings?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  trelloId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
  workspaceId?: InputMaybe<BigIntFilter>;
};

export type TfFormDistinctCountAggregates = {
  __typename?: "TfFormDistinctCountAggregates";
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fields across the matching connection */
  fields?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of formStatus across the matching connection */
  formStatus?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdatedAt across the matching connection */
  lastUpdatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of publishedAt across the matching connection */
  publishedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responseAverage across the matching connection */
  responseAverage?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responses across the matching connection */
  responses?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of settings across the matching connection */
  settings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloId across the matching connection */
  trelloId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TfForm` object types. All fields are combined with a logical ‘and.’ */
export type TfFormFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TfFormFilter>>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fields` field. */
  fields?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `formStatus` field. */
  formStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastUpdatedAt` field. */
  lastUpdatedAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TfFormFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TfFormFilter>>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `responseAverage` field. */
  responseAverage?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `responses` field. */
  responses?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `settings` field. */
  settings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trelloId` field. */
  trelloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workspace` relation. */
  workspace?: InputMaybe<TfWorkspaceFilter>;
  /** Filter by the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TfForm` for usage during aggregation. */
export enum TfFormGroupBy {
  CompanyName = "COMPANY_NAME",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Fields = "FIELDS",
  FormStatus = "FORM_STATUS",
  LastUpdatedAt = "LAST_UPDATED_AT",
  LastUpdatedAtTruncatedToDay = "LAST_UPDATED_AT_TRUNCATED_TO_DAY",
  LastUpdatedAtTruncatedToHour = "LAST_UPDATED_AT_TRUNCATED_TO_HOUR",
  PublishedAt = "PUBLISHED_AT",
  PublishedAtTruncatedToDay = "PUBLISHED_AT_TRUNCATED_TO_DAY",
  PublishedAtTruncatedToHour = "PUBLISHED_AT_TRUNCATED_TO_HOUR",
  Responses = "RESPONSES",
  ResponseAverage = "RESPONSE_AVERAGE",
  Settings = "SETTINGS",
  Title = "TITLE",
  TrelloId = "TRELLO_ID",
  Type = "TYPE",
  WorkspaceId = "WORKSPACE_ID",
}

export type TfFormHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `TfForm` aggregates. */
export type TfFormHavingInput = {
  AND?: InputMaybe<Array<TfFormHavingInput>>;
  OR?: InputMaybe<Array<TfFormHavingInput>>;
  average?: InputMaybe<TfFormHavingAverageInput>;
  distinctCount?: InputMaybe<TfFormHavingDistinctCountInput>;
  max?: InputMaybe<TfFormHavingMaxInput>;
  min?: InputMaybe<TfFormHavingMinInput>;
  stddevPopulation?: InputMaybe<TfFormHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TfFormHavingStddevSampleInput>;
  sum?: InputMaybe<TfFormHavingSumInput>;
  variancePopulation?: InputMaybe<TfFormHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TfFormHavingVarianceSampleInput>;
};

export type TfFormHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TfFormHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  publishedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `TfForm` values. */
export type TfFormsConnection = {
  __typename?: "TfFormsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TfFormAggregates>;
  /** A list of edges which contains the `TfForm` and cursor to aid in pagination. */
  edges: Array<TfFormsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TfFormAggregates>>;
  /** A list of `TfForm` objects. */
  nodes: Array<TfForm>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TfForm` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TfForm` values. */
export type TfFormsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TfFormGroupBy>;
  having?: InputMaybe<TfFormHavingInput>;
};

/** A `TfForm` edge in the connection. */
export type TfFormsEdge = {
  __typename?: "TfFormsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TfForm` at the end of the edge. */
  node: TfForm;
};

/** Methods to use when ordering `TfForm`. */
export enum TfFormsOrderBy {
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FieldsAsc = "FIELDS_ASC",
  FieldsDesc = "FIELDS_DESC",
  FormStatusAsc = "FORM_STATUS_ASC",
  FormStatusDesc = "FORM_STATUS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastUpdatedAtAsc = "LAST_UPDATED_AT_ASC",
  LastUpdatedAtDesc = "LAST_UPDATED_AT_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublishedAtAsc = "PUBLISHED_AT_ASC",
  PublishedAtDesc = "PUBLISHED_AT_DESC",
  ResponsesAsc = "RESPONSES_ASC",
  ResponsesDesc = "RESPONSES_DESC",
  ResponseAverageAsc = "RESPONSE_AVERAGE_ASC",
  ResponseAverageDesc = "RESPONSE_AVERAGE_DESC",
  SettingsAsc = "SETTINGS_ASC",
  SettingsDesc = "SETTINGS_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
  TrelloIdAsc = "TRELLO_ID_ASC",
  TrelloIdDesc = "TRELLO_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  WorkspaceIdAsc = "WORKSPACE_ID_ASC",
  WorkspaceIdDesc = "WORKSPACE_ID_DESC",
}

export type TfWorkspace = Node & {
  __typename?: "TfWorkspace";
  id: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `TfForm`. */
  tfFormsByWorkspaceId: TfFormsConnection;
  title: Scalars["String"]["output"];
};

export type TfWorkspaceTfFormsByWorkspaceIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TfFormCondition>;
  filter?: InputMaybe<TfFormFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TfFormsOrderBy>>;
};

export type TfWorkspaceAggregates = {
  __typename?: "TfWorkspaceAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TfWorkspaceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `TfWorkspace` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TfWorkspaceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type TfWorkspaceDistinctCountAggregates = {
  __typename?: "TfWorkspaceDistinctCountAggregates";
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TfWorkspace` object types. All fields are combined with a logical ‘and.’ */
export type TfWorkspaceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TfWorkspaceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TfWorkspaceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TfWorkspaceFilter>>;
  /** Filter by the object’s `tfFormsByWorkspaceId` relation. */
  tfFormsByWorkspaceId?: InputMaybe<TfWorkspaceToManyTfFormFilter>;
  /** Some related `tfFormsByWorkspaceId` exist. */
  tfFormsByWorkspaceIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TfWorkspace` for usage during aggregation. */
export enum TfWorkspaceGroupBy {
  Title = "TITLE",
}

/** Conditions for `TfWorkspace` aggregates. */
export type TfWorkspaceHavingInput = {
  AND?: InputMaybe<Array<TfWorkspaceHavingInput>>;
  OR?: InputMaybe<Array<TfWorkspaceHavingInput>>;
};

/** A filter to be used against many `TfForm` object types. All fields are combined with a logical ‘and.’ */
export type TfWorkspaceToManyTfFormFilter = {
  /** Aggregates across related `TfForm` match the filter criteria. */
  aggregates?: InputMaybe<TfFormAggregatesFilter>;
  /** Every related `TfForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TfFormFilter>;
  /** No related `TfForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TfFormFilter>;
  /** Some related `TfForm` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TfFormFilter>;
};

/** A connection to a list of `TfWorkspace` values. */
export type TfWorkspacesConnection = {
  __typename?: "TfWorkspacesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TfWorkspaceAggregates>;
  /** A list of edges which contains the `TfWorkspace` and cursor to aid in pagination. */
  edges: Array<TfWorkspacesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TfWorkspaceAggregates>>;
  /** A list of `TfWorkspace` objects. */
  nodes: Array<TfWorkspace>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TfWorkspace` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TfWorkspace` values. */
export type TfWorkspacesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TfWorkspaceGroupBy>;
  having?: InputMaybe<TfWorkspaceHavingInput>;
};

/** A `TfWorkspace` edge in the connection. */
export type TfWorkspacesEdge = {
  __typename?: "TfWorkspacesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TfWorkspace` at the end of the edge. */
  node: TfWorkspace;
};

/** Methods to use when ordering `TfWorkspace`. */
export enum TfWorkspacesOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TfFormsByWorkspaceIdAverageCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdAverageCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdAverageCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_CREATED_AT_ASC",
  TfFormsByWorkspaceIdAverageCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_CREATED_AT_DESC",
  TfFormsByWorkspaceIdAverageFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_FIELDS_ASC",
  TfFormsByWorkspaceIdAverageFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_FIELDS_DESC",
  TfFormsByWorkspaceIdAverageFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdAverageFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdAverageIdAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_ID_ASC",
  TfFormsByWorkspaceIdAverageIdDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_ID_DESC",
  TfFormsByWorkspaceIdAverageLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdAverageLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdAveragePublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdAveragePublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdAverageResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_RESPONSES_ASC",
  TfFormsByWorkspaceIdAverageResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_RESPONSES_DESC",
  TfFormsByWorkspaceIdAverageResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdAverageResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdAverageSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_SETTINGS_ASC",
  TfFormsByWorkspaceIdAverageSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_SETTINGS_DESC",
  TfFormsByWorkspaceIdAverageTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TITLE_ASC",
  TfFormsByWorkspaceIdAverageTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TITLE_DESC",
  TfFormsByWorkspaceIdAverageTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdAverageTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdAverageTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TYPE_ASC",
  TfFormsByWorkspaceIdAverageTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_TYPE_DESC",
  TfFormsByWorkspaceIdAverageWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdAverageWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_AVERAGE_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdCountAsc = "TF_FORMS_BY_WORKSPACE_ID_COUNT_ASC",
  TfFormsByWorkspaceIdCountDesc = "TF_FORMS_BY_WORKSPACE_ID_COUNT_DESC",
  TfFormsByWorkspaceIdDistinctCountCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdDistinctCountCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdDistinctCountCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  TfFormsByWorkspaceIdDistinctCountCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  TfFormsByWorkspaceIdDistinctCountFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_FIELDS_ASC",
  TfFormsByWorkspaceIdDistinctCountFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_FIELDS_DESC",
  TfFormsByWorkspaceIdDistinctCountFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdDistinctCountFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdDistinctCountIdAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_ID_ASC",
  TfFormsByWorkspaceIdDistinctCountIdDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_ID_DESC",
  TfFormsByWorkspaceIdDistinctCountLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdDistinctCountLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdDistinctCountPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdDistinctCountPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdDistinctCountResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_RESPONSES_ASC",
  TfFormsByWorkspaceIdDistinctCountResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_RESPONSES_DESC",
  TfFormsByWorkspaceIdDistinctCountResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdDistinctCountResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdDistinctCountSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_SETTINGS_ASC",
  TfFormsByWorkspaceIdDistinctCountSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_SETTINGS_DESC",
  TfFormsByWorkspaceIdDistinctCountTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TITLE_ASC",
  TfFormsByWorkspaceIdDistinctCountTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TITLE_DESC",
  TfFormsByWorkspaceIdDistinctCountTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdDistinctCountTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdDistinctCountTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TYPE_ASC",
  TfFormsByWorkspaceIdDistinctCountTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_TYPE_DESC",
  TfFormsByWorkspaceIdDistinctCountWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdDistinctCountWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_DISTINCT_COUNT_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdMaxCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdMaxCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdMaxCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_CREATED_AT_ASC",
  TfFormsByWorkspaceIdMaxCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_CREATED_AT_DESC",
  TfFormsByWorkspaceIdMaxFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_FIELDS_ASC",
  TfFormsByWorkspaceIdMaxFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_FIELDS_DESC",
  TfFormsByWorkspaceIdMaxFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdMaxFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdMaxIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_ID_ASC",
  TfFormsByWorkspaceIdMaxIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_ID_DESC",
  TfFormsByWorkspaceIdMaxLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdMaxLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdMaxPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdMaxPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdMaxResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_RESPONSES_ASC",
  TfFormsByWorkspaceIdMaxResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_RESPONSES_DESC",
  TfFormsByWorkspaceIdMaxResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdMaxResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdMaxSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_SETTINGS_ASC",
  TfFormsByWorkspaceIdMaxSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_SETTINGS_DESC",
  TfFormsByWorkspaceIdMaxTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TITLE_ASC",
  TfFormsByWorkspaceIdMaxTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TITLE_DESC",
  TfFormsByWorkspaceIdMaxTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdMaxTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdMaxTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TYPE_ASC",
  TfFormsByWorkspaceIdMaxTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_TYPE_DESC",
  TfFormsByWorkspaceIdMaxWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MAX_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdMaxWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MAX_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdMinCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdMinCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdMinCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_CREATED_AT_ASC",
  TfFormsByWorkspaceIdMinCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_CREATED_AT_DESC",
  TfFormsByWorkspaceIdMinFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_FIELDS_ASC",
  TfFormsByWorkspaceIdMinFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_FIELDS_DESC",
  TfFormsByWorkspaceIdMinFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdMinFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdMinIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_ID_ASC",
  TfFormsByWorkspaceIdMinIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_ID_DESC",
  TfFormsByWorkspaceIdMinLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdMinLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdMinPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdMinPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdMinResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_RESPONSES_ASC",
  TfFormsByWorkspaceIdMinResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_RESPONSES_DESC",
  TfFormsByWorkspaceIdMinResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdMinResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdMinSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_SETTINGS_ASC",
  TfFormsByWorkspaceIdMinSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_SETTINGS_DESC",
  TfFormsByWorkspaceIdMinTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TITLE_ASC",
  TfFormsByWorkspaceIdMinTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TITLE_DESC",
  TfFormsByWorkspaceIdMinTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdMinTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdMinTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TYPE_ASC",
  TfFormsByWorkspaceIdMinTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_TYPE_DESC",
  TfFormsByWorkspaceIdMinWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_MIN_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdMinWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_MIN_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdStddevPopulationCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdStddevPopulationCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdStddevPopulationCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  TfFormsByWorkspaceIdStddevPopulationCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  TfFormsByWorkspaceIdStddevPopulationFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_FIELDS_ASC",
  TfFormsByWorkspaceIdStddevPopulationFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_FIELDS_DESC",
  TfFormsByWorkspaceIdStddevPopulationFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdStddevPopulationFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdStddevPopulationIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_ID_ASC",
  TfFormsByWorkspaceIdStddevPopulationIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_ID_DESC",
  TfFormsByWorkspaceIdStddevPopulationLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdStddevPopulationLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdStddevPopulationPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdStddevPopulationPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdStddevPopulationResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_RESPONSES_ASC",
  TfFormsByWorkspaceIdStddevPopulationResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_RESPONSES_DESC",
  TfFormsByWorkspaceIdStddevPopulationResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdStddevPopulationResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdStddevPopulationSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_SETTINGS_ASC",
  TfFormsByWorkspaceIdStddevPopulationSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_SETTINGS_DESC",
  TfFormsByWorkspaceIdStddevPopulationTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TITLE_ASC",
  TfFormsByWorkspaceIdStddevPopulationTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TITLE_DESC",
  TfFormsByWorkspaceIdStddevPopulationTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdStddevPopulationTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdStddevPopulationTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TYPE_ASC",
  TfFormsByWorkspaceIdStddevPopulationTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_TYPE_DESC",
  TfFormsByWorkspaceIdStddevPopulationWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdStddevPopulationWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_POPULATION_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdStddevSampleCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdStddevSampleCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdStddevSampleCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  TfFormsByWorkspaceIdStddevSampleCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  TfFormsByWorkspaceIdStddevSampleFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_FIELDS_ASC",
  TfFormsByWorkspaceIdStddevSampleFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_FIELDS_DESC",
  TfFormsByWorkspaceIdStddevSampleFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdStddevSampleFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdStddevSampleIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_ID_ASC",
  TfFormsByWorkspaceIdStddevSampleIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_ID_DESC",
  TfFormsByWorkspaceIdStddevSampleLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdStddevSampleLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdStddevSamplePublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdStddevSamplePublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdStddevSampleResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_RESPONSES_ASC",
  TfFormsByWorkspaceIdStddevSampleResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_RESPONSES_DESC",
  TfFormsByWorkspaceIdStddevSampleResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdStddevSampleResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdStddevSampleSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_SETTINGS_ASC",
  TfFormsByWorkspaceIdStddevSampleSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_SETTINGS_DESC",
  TfFormsByWorkspaceIdStddevSampleTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TITLE_ASC",
  TfFormsByWorkspaceIdStddevSampleTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TITLE_DESC",
  TfFormsByWorkspaceIdStddevSampleTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdStddevSampleTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdStddevSampleTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TYPE_ASC",
  TfFormsByWorkspaceIdStddevSampleTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_TYPE_DESC",
  TfFormsByWorkspaceIdStddevSampleWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdStddevSampleWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_STDDEV_SAMPLE_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdSumCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdSumCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdSumCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_CREATED_AT_ASC",
  TfFormsByWorkspaceIdSumCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_CREATED_AT_DESC",
  TfFormsByWorkspaceIdSumFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_FIELDS_ASC",
  TfFormsByWorkspaceIdSumFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_FIELDS_DESC",
  TfFormsByWorkspaceIdSumFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdSumFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdSumIdAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_ID_ASC",
  TfFormsByWorkspaceIdSumIdDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_ID_DESC",
  TfFormsByWorkspaceIdSumLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdSumLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdSumPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdSumPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdSumResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_RESPONSES_ASC",
  TfFormsByWorkspaceIdSumResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_RESPONSES_DESC",
  TfFormsByWorkspaceIdSumResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdSumResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdSumSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_SETTINGS_ASC",
  TfFormsByWorkspaceIdSumSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_SETTINGS_DESC",
  TfFormsByWorkspaceIdSumTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TITLE_ASC",
  TfFormsByWorkspaceIdSumTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TITLE_DESC",
  TfFormsByWorkspaceIdSumTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdSumTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdSumTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TYPE_ASC",
  TfFormsByWorkspaceIdSumTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_TYPE_DESC",
  TfFormsByWorkspaceIdSumWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_SUM_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdSumWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_SUM_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdVariancePopulationCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdVariancePopulationCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdVariancePopulationCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  TfFormsByWorkspaceIdVariancePopulationCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  TfFormsByWorkspaceIdVariancePopulationFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_FIELDS_ASC",
  TfFormsByWorkspaceIdVariancePopulationFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_FIELDS_DESC",
  TfFormsByWorkspaceIdVariancePopulationFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdVariancePopulationFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdVariancePopulationIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_ID_ASC",
  TfFormsByWorkspaceIdVariancePopulationIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_ID_DESC",
  TfFormsByWorkspaceIdVariancePopulationLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdVariancePopulationLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdVariancePopulationPublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdVariancePopulationPublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdVariancePopulationResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_RESPONSES_ASC",
  TfFormsByWorkspaceIdVariancePopulationResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_RESPONSES_DESC",
  TfFormsByWorkspaceIdVariancePopulationResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdVariancePopulationResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdVariancePopulationSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_SETTINGS_ASC",
  TfFormsByWorkspaceIdVariancePopulationSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_SETTINGS_DESC",
  TfFormsByWorkspaceIdVariancePopulationTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TITLE_ASC",
  TfFormsByWorkspaceIdVariancePopulationTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TITLE_DESC",
  TfFormsByWorkspaceIdVariancePopulationTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdVariancePopulationTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdVariancePopulationTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TYPE_ASC",
  TfFormsByWorkspaceIdVariancePopulationTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_TYPE_DESC",
  TfFormsByWorkspaceIdVariancePopulationWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdVariancePopulationWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_POPULATION_WORKSPACE_ID_DESC",
  TfFormsByWorkspaceIdVarianceSampleCompanyNameAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  TfFormsByWorkspaceIdVarianceSampleCompanyNameDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  TfFormsByWorkspaceIdVarianceSampleCreatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  TfFormsByWorkspaceIdVarianceSampleCreatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  TfFormsByWorkspaceIdVarianceSampleFieldsAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_FIELDS_ASC",
  TfFormsByWorkspaceIdVarianceSampleFieldsDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_FIELDS_DESC",
  TfFormsByWorkspaceIdVarianceSampleFormStatusAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_FORM_STATUS_ASC",
  TfFormsByWorkspaceIdVarianceSampleFormStatusDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_FORM_STATUS_DESC",
  TfFormsByWorkspaceIdVarianceSampleIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_ID_ASC",
  TfFormsByWorkspaceIdVarianceSampleIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_ID_DESC",
  TfFormsByWorkspaceIdVarianceSampleLastUpdatedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_LAST_UPDATED_AT_ASC",
  TfFormsByWorkspaceIdVarianceSampleLastUpdatedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_LAST_UPDATED_AT_DESC",
  TfFormsByWorkspaceIdVarianceSamplePublishedAtAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_PUBLISHED_AT_ASC",
  TfFormsByWorkspaceIdVarianceSamplePublishedAtDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_PUBLISHED_AT_DESC",
  TfFormsByWorkspaceIdVarianceSampleResponsesAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_RESPONSES_ASC",
  TfFormsByWorkspaceIdVarianceSampleResponsesDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_RESPONSES_DESC",
  TfFormsByWorkspaceIdVarianceSampleResponseAverageAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_RESPONSE_AVERAGE_ASC",
  TfFormsByWorkspaceIdVarianceSampleResponseAverageDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_RESPONSE_AVERAGE_DESC",
  TfFormsByWorkspaceIdVarianceSampleSettingsAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_SETTINGS_ASC",
  TfFormsByWorkspaceIdVarianceSampleSettingsDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_SETTINGS_DESC",
  TfFormsByWorkspaceIdVarianceSampleTitleAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TITLE_ASC",
  TfFormsByWorkspaceIdVarianceSampleTitleDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TITLE_DESC",
  TfFormsByWorkspaceIdVarianceSampleTrelloIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TRELLO_ID_ASC",
  TfFormsByWorkspaceIdVarianceSampleTrelloIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TRELLO_ID_DESC",
  TfFormsByWorkspaceIdVarianceSampleTypeAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TYPE_ASC",
  TfFormsByWorkspaceIdVarianceSampleTypeDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_TYPE_DESC",
  TfFormsByWorkspaceIdVarianceSampleWorkspaceIdAsc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_WORKSPACE_ID_ASC",
  TfFormsByWorkspaceIdVarianceSampleWorkspaceIdDesc = "TF_FORMS_BY_WORKSPACE_ID_VARIANCE_SAMPLE_WORKSPACE_ID_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
}

export type TrelloBoard = Node & {
  __typename?: "TrelloBoard";
  closed: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads and enables pagination through a set of `TrelloCard`. */
  trelloCardsByBoardId: TrelloCardsConnection;
  /** Reads and enables pagination through a set of `TrelloLabel`. */
  trelloLabelsByBoardId: TrelloLabelsConnection;
  /** Reads and enables pagination through a set of `TrelloList`. */
  trelloListsByBoardId: TrelloListsConnection;
  /** Reads and enables pagination through a set of `TrelloList`. */
  trelloListsByTrelloCardBoardIdAndListId: TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyConnection;
  url: Scalars["String"]["output"];
};

export type TrelloBoardTrelloCardsByBoardIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardCondition>;
  filter?: InputMaybe<TrelloCardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardsOrderBy>>;
};

export type TrelloBoardTrelloLabelsByBoardIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloLabelCondition>;
  filter?: InputMaybe<TrelloLabelFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloLabelsOrderBy>>;
};

export type TrelloBoardTrelloListsByBoardIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloListCondition>;
  filter?: InputMaybe<TrelloListFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloListsOrderBy>>;
};

export type TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloListCondition>;
  filter?: InputMaybe<TrelloListFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloListsOrderBy>>;
};

export type TrelloBoardAggregates = {
  __typename?: "TrelloBoardAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloBoardDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `TrelloBoard` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TrelloBoardCondition = {
  /** Checks for equality with the object’s `closed` field. */
  closed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrelloBoardDistinctCountAggregates = {
  __typename?: "TrelloBoardDistinctCountAggregates";
  /** Distinct count of closed across the matching connection */
  closed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloBoard` object types. All fields are combined with a logical ‘and.’ */
export type TrelloBoardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloBoardFilter>>;
  /** Filter by the object’s `closed` field. */
  closed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloBoardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloBoardFilter>>;
  /** Filter by the object’s `trelloCardsByBoardId` relation. */
  trelloCardsByBoardId?: InputMaybe<TrelloBoardToManyTrelloCardFilter>;
  /** Some related `trelloCardsByBoardId` exist. */
  trelloCardsByBoardIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `trelloLabelsByBoardId` relation. */
  trelloLabelsByBoardId?: InputMaybe<TrelloBoardToManyTrelloLabelFilter>;
  /** Some related `trelloLabelsByBoardId` exist. */
  trelloLabelsByBoardIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `trelloListsByBoardId` relation. */
  trelloListsByBoardId?: InputMaybe<TrelloBoardToManyTrelloListFilter>;
  /** Some related `trelloListsByBoardId` exist. */
  trelloListsByBoardIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TrelloBoard` for usage during aggregation. */
export enum TrelloBoardGroupBy {
  Closed = "CLOSED",
  Description = "DESCRIPTION",
  Name = "NAME",
  Url = "URL",
}

/** Conditions for `TrelloBoard` aggregates. */
export type TrelloBoardHavingInput = {
  AND?: InputMaybe<Array<TrelloBoardHavingInput>>;
  OR?: InputMaybe<Array<TrelloBoardHavingInput>>;
};

/** A filter to be used against many `TrelloCard` object types. All fields are combined with a logical ‘and.’ */
export type TrelloBoardToManyTrelloCardFilter = {
  /** Aggregates across related `TrelloCard` match the filter criteria. */
  aggregates?: InputMaybe<TrelloCardAggregatesFilter>;
  /** Every related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrelloCardFilter>;
  /** No related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrelloCardFilter>;
  /** Some related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrelloCardFilter>;
};

/** A filter to be used against many `TrelloLabel` object types. All fields are combined with a logical ‘and.’ */
export type TrelloBoardToManyTrelloLabelFilter = {
  /** Aggregates across related `TrelloLabel` match the filter criteria. */
  aggregates?: InputMaybe<TrelloLabelAggregatesFilter>;
  /** Every related `TrelloLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrelloLabelFilter>;
  /** No related `TrelloLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrelloLabelFilter>;
  /** Some related `TrelloLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrelloLabelFilter>;
};

/** A filter to be used against many `TrelloList` object types. All fields are combined with a logical ‘and.’ */
export type TrelloBoardToManyTrelloListFilter = {
  /** Aggregates across related `TrelloList` match the filter criteria. */
  aggregates?: InputMaybe<TrelloListAggregatesFilter>;
  /** Every related `TrelloList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrelloListFilter>;
  /** No related `TrelloList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrelloListFilter>;
  /** Some related `TrelloList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrelloListFilter>;
};

/** A connection to a list of `TrelloList` values, with data from `TrelloCard`. */
export type TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyConnection = {
  __typename?: "TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloListAggregates>;
  /** A list of edges which contains the `TrelloList`, info from the `TrelloCard`, and the cursor to aid in pagination. */
  edges: Array<TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloListAggregates>>;
  /** A list of `TrelloList` objects. */
  nodes: Array<TrelloList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloList` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloList` values, with data from `TrelloCard`. */
export type TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloListGroupBy>;
  having?: InputMaybe<TrelloListHavingInput>;
};

/** A `TrelloList` edge in the connection, with data from `TrelloCard`. */
export type TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyEdge = {
  __typename?: "TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloList` at the end of the edge. */
  node: TrelloList;
  /** Reads and enables pagination through a set of `TrelloCard`. */
  trelloCardsByListId: TrelloCardsConnection;
};

/** A `TrelloList` edge in the connection, with data from `TrelloCard`. */
export type TrelloBoardTrelloListsByTrelloCardBoardIdAndListIdManyToManyEdgeTrelloCardsByListIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardCondition>;
  filter?: InputMaybe<TrelloCardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardsOrderBy>>;
};

/** A connection to a list of `TrelloBoard` values. */
export type TrelloBoardsConnection = {
  __typename?: "TrelloBoardsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloBoardAggregates>;
  /** A list of edges which contains the `TrelloBoard` and cursor to aid in pagination. */
  edges: Array<TrelloBoardsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloBoardAggregates>>;
  /** A list of `TrelloBoard` objects. */
  nodes: Array<TrelloBoard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloBoard` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloBoard` values. */
export type TrelloBoardsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloBoardGroupBy>;
  having?: InputMaybe<TrelloBoardHavingInput>;
};

/** A `TrelloBoard` edge in the connection. */
export type TrelloBoardsEdge = {
  __typename?: "TrelloBoardsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloBoard` at the end of the edge. */
  node: TrelloBoard;
};

/** Methods to use when ordering `TrelloBoard`. */
export enum TrelloBoardsOrderBy {
  ClosedAsc = "CLOSED_ASC",
  ClosedDesc = "CLOSED_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TrelloCardsByBoardIdAverageAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdAverageAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdAverageBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_BADGES_ASC",
  TrelloCardsByBoardIdAverageBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_BADGES_DESC",
  TrelloCardsByBoardIdAverageBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_BOARD_ID_ASC",
  TrelloCardsByBoardIdAverageBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_BOARD_ID_DESC",
  TrelloCardsByBoardIdAverageCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdAverageCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdAverageCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_MOVED_ASC",
  TrelloCardsByBoardIdAverageCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_MOVED_DESC",
  TrelloCardsByBoardIdAverageCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_ROLE_ASC",
  TrelloCardsByBoardIdAverageCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CARD_ROLE_DESC",
  TrelloCardsByBoardIdAverageChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CHECKLISTS_ASC",
  TrelloCardsByBoardIdAverageChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CHECKLISTS_DESC",
  TrelloCardsByBoardIdAverageClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CLOSED_ASC",
  TrelloCardsByBoardIdAverageClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_CLOSED_DESC",
  TrelloCardsByBoardIdAverageCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COMMENTS_ASC",
  TrelloCardsByBoardIdAverageCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COMMENTS_DESC",
  TrelloCardsByBoardIdAverageCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdAverageCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdAverageCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COVER_ASC",
  TrelloCardsByBoardIdAverageCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_COVER_DESC",
  TrelloCardsByBoardIdAverageDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdAverageDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdAverageDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_DESCRIPTION_ASC",
  TrelloCardsByBoardIdAverageDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_DESCRIPTION_DESC",
  TrelloCardsByBoardIdAverageEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_EMAILS_ASC",
  TrelloCardsByBoardIdAverageEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_EMAILS_DESC",
  TrelloCardsByBoardIdAverageIdAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_ID_ASC",
  TrelloCardsByBoardIdAverageIdDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_ID_DESC",
  TrelloCardsByBoardIdAverageIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdAverageIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdAverageLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LABELS_ASC",
  TrelloCardsByBoardIdAverageLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LABELS_DESC",
  TrelloCardsByBoardIdAverageListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LIST_ID_ASC",
  TrelloCardsByBoardIdAverageListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LIST_ID_DESC",
  TrelloCardsByBoardIdAverageLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdAverageLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdAverageMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdAverageMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdAverageNameAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_NAME_ASC",
  TrelloCardsByBoardIdAverageNameDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_NAME_DESC",
  TrelloCardsByBoardIdAverageShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_ID_ASC",
  TrelloCardsByBoardIdAverageShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_ID_DESC",
  TrelloCardsByBoardIdAverageShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdAverageShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdAverageShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_URL_ASC",
  TrelloCardsByBoardIdAverageShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_SHORT_URL_DESC",
  TrelloCardsByBoardIdAverageUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_URL_ASC",
  TrelloCardsByBoardIdAverageUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_AVERAGE_URL_DESC",
  TrelloCardsByBoardIdCountAsc = "TRELLO_CARDS_BY_BOARD_ID_COUNT_ASC",
  TrelloCardsByBoardIdCountDesc = "TRELLO_CARDS_BY_BOARD_ID_COUNT_DESC",
  TrelloCardsByBoardIdDistinctCountAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdDistinctCountAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdDistinctCountBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_BADGES_ASC",
  TrelloCardsByBoardIdDistinctCountBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_BADGES_DESC",
  TrelloCardsByBoardIdDistinctCountBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_ASC",
  TrelloCardsByBoardIdDistinctCountBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_DESC",
  TrelloCardsByBoardIdDistinctCountCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdDistinctCountCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdDistinctCountCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_MOVED_ASC",
  TrelloCardsByBoardIdDistinctCountCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_MOVED_DESC",
  TrelloCardsByBoardIdDistinctCountCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_ROLE_ASC",
  TrelloCardsByBoardIdDistinctCountCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CARD_ROLE_DESC",
  TrelloCardsByBoardIdDistinctCountChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CHECKLISTS_ASC",
  TrelloCardsByBoardIdDistinctCountChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CHECKLISTS_DESC",
  TrelloCardsByBoardIdDistinctCountClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CLOSED_ASC",
  TrelloCardsByBoardIdDistinctCountClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_CLOSED_DESC",
  TrelloCardsByBoardIdDistinctCountCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COMMENTS_ASC",
  TrelloCardsByBoardIdDistinctCountCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COMMENTS_DESC",
  TrelloCardsByBoardIdDistinctCountCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdDistinctCountCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdDistinctCountCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COVER_ASC",
  TrelloCardsByBoardIdDistinctCountCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_COVER_DESC",
  TrelloCardsByBoardIdDistinctCountDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdDistinctCountDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdDistinctCountDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_DESCRIPTION_ASC",
  TrelloCardsByBoardIdDistinctCountDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_DESCRIPTION_DESC",
  TrelloCardsByBoardIdDistinctCountEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_EMAILS_ASC",
  TrelloCardsByBoardIdDistinctCountEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_EMAILS_DESC",
  TrelloCardsByBoardIdDistinctCountIdAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_ID_ASC",
  TrelloCardsByBoardIdDistinctCountIdDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_ID_DESC",
  TrelloCardsByBoardIdDistinctCountIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdDistinctCountIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdDistinctCountLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LABELS_ASC",
  TrelloCardsByBoardIdDistinctCountLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LABELS_DESC",
  TrelloCardsByBoardIdDistinctCountListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LIST_ID_ASC",
  TrelloCardsByBoardIdDistinctCountListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LIST_ID_DESC",
  TrelloCardsByBoardIdDistinctCountLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdDistinctCountLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdDistinctCountMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdDistinctCountMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdDistinctCountNameAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_NAME_ASC",
  TrelloCardsByBoardIdDistinctCountNameDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_NAME_DESC",
  TrelloCardsByBoardIdDistinctCountShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_ID_ASC",
  TrelloCardsByBoardIdDistinctCountShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_ID_DESC",
  TrelloCardsByBoardIdDistinctCountShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdDistinctCountShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdDistinctCountShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_URL_ASC",
  TrelloCardsByBoardIdDistinctCountShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_SHORT_URL_DESC",
  TrelloCardsByBoardIdDistinctCountUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_URL_ASC",
  TrelloCardsByBoardIdDistinctCountUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_DISTINCT_COUNT_URL_DESC",
  TrelloCardsByBoardIdMaxAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdMaxAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdMaxBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_BADGES_ASC",
  TrelloCardsByBoardIdMaxBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_BADGES_DESC",
  TrelloCardsByBoardIdMaxBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_BOARD_ID_ASC",
  TrelloCardsByBoardIdMaxBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_BOARD_ID_DESC",
  TrelloCardsByBoardIdMaxCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdMaxCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdMaxCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_MOVED_ASC",
  TrelloCardsByBoardIdMaxCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_MOVED_DESC",
  TrelloCardsByBoardIdMaxCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_ROLE_ASC",
  TrelloCardsByBoardIdMaxCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CARD_ROLE_DESC",
  TrelloCardsByBoardIdMaxChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CHECKLISTS_ASC",
  TrelloCardsByBoardIdMaxChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CHECKLISTS_DESC",
  TrelloCardsByBoardIdMaxClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CLOSED_ASC",
  TrelloCardsByBoardIdMaxClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_CLOSED_DESC",
  TrelloCardsByBoardIdMaxCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COMMENTS_ASC",
  TrelloCardsByBoardIdMaxCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COMMENTS_DESC",
  TrelloCardsByBoardIdMaxCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdMaxCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdMaxCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COVER_ASC",
  TrelloCardsByBoardIdMaxCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_COVER_DESC",
  TrelloCardsByBoardIdMaxDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdMaxDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdMaxDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_DESCRIPTION_ASC",
  TrelloCardsByBoardIdMaxDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_DESCRIPTION_DESC",
  TrelloCardsByBoardIdMaxEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_EMAILS_ASC",
  TrelloCardsByBoardIdMaxEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_EMAILS_DESC",
  TrelloCardsByBoardIdMaxIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_ID_ASC",
  TrelloCardsByBoardIdMaxIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_ID_DESC",
  TrelloCardsByBoardIdMaxIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdMaxIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdMaxLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LABELS_ASC",
  TrelloCardsByBoardIdMaxLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LABELS_DESC",
  TrelloCardsByBoardIdMaxListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LIST_ID_ASC",
  TrelloCardsByBoardIdMaxListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LIST_ID_DESC",
  TrelloCardsByBoardIdMaxLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdMaxLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdMaxMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdMaxMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdMaxNameAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_NAME_ASC",
  TrelloCardsByBoardIdMaxNameDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_NAME_DESC",
  TrelloCardsByBoardIdMaxShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_ID_ASC",
  TrelloCardsByBoardIdMaxShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_ID_DESC",
  TrelloCardsByBoardIdMaxShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdMaxShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdMaxShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_URL_ASC",
  TrelloCardsByBoardIdMaxShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_SHORT_URL_DESC",
  TrelloCardsByBoardIdMaxUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_MAX_URL_ASC",
  TrelloCardsByBoardIdMaxUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_MAX_URL_DESC",
  TrelloCardsByBoardIdMinAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdMinAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdMinBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_BADGES_ASC",
  TrelloCardsByBoardIdMinBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_BADGES_DESC",
  TrelloCardsByBoardIdMinBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_BOARD_ID_ASC",
  TrelloCardsByBoardIdMinBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_BOARD_ID_DESC",
  TrelloCardsByBoardIdMinCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdMinCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdMinCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_MOVED_ASC",
  TrelloCardsByBoardIdMinCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_MOVED_DESC",
  TrelloCardsByBoardIdMinCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_ROLE_ASC",
  TrelloCardsByBoardIdMinCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CARD_ROLE_DESC",
  TrelloCardsByBoardIdMinChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CHECKLISTS_ASC",
  TrelloCardsByBoardIdMinChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CHECKLISTS_DESC",
  TrelloCardsByBoardIdMinClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CLOSED_ASC",
  TrelloCardsByBoardIdMinClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_CLOSED_DESC",
  TrelloCardsByBoardIdMinCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COMMENTS_ASC",
  TrelloCardsByBoardIdMinCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COMMENTS_DESC",
  TrelloCardsByBoardIdMinCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdMinCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdMinCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COVER_ASC",
  TrelloCardsByBoardIdMinCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_COVER_DESC",
  TrelloCardsByBoardIdMinDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdMinDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdMinDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_DESCRIPTION_ASC",
  TrelloCardsByBoardIdMinDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_DESCRIPTION_DESC",
  TrelloCardsByBoardIdMinEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_EMAILS_ASC",
  TrelloCardsByBoardIdMinEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_EMAILS_DESC",
  TrelloCardsByBoardIdMinIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_ID_ASC",
  TrelloCardsByBoardIdMinIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_ID_DESC",
  TrelloCardsByBoardIdMinIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdMinIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdMinLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LABELS_ASC",
  TrelloCardsByBoardIdMinLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LABELS_DESC",
  TrelloCardsByBoardIdMinListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LIST_ID_ASC",
  TrelloCardsByBoardIdMinListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LIST_ID_DESC",
  TrelloCardsByBoardIdMinLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdMinLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdMinMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdMinMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdMinNameAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_NAME_ASC",
  TrelloCardsByBoardIdMinNameDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_NAME_DESC",
  TrelloCardsByBoardIdMinShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_ID_ASC",
  TrelloCardsByBoardIdMinShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_ID_DESC",
  TrelloCardsByBoardIdMinShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdMinShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdMinShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_URL_ASC",
  TrelloCardsByBoardIdMinShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_SHORT_URL_DESC",
  TrelloCardsByBoardIdMinUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_MIN_URL_ASC",
  TrelloCardsByBoardIdMinUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_MIN_URL_DESC",
  TrelloCardsByBoardIdStddevPopulationAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdStddevPopulationAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdStddevPopulationBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_BADGES_ASC",
  TrelloCardsByBoardIdStddevPopulationBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_BADGES_DESC",
  TrelloCardsByBoardIdStddevPopulationBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_ASC",
  TrelloCardsByBoardIdStddevPopulationBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_DESC",
  TrelloCardsByBoardIdStddevPopulationCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdStddevPopulationCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdStddevPopulationCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_MOVED_ASC",
  TrelloCardsByBoardIdStddevPopulationCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_MOVED_DESC",
  TrelloCardsByBoardIdStddevPopulationCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_ROLE_ASC",
  TrelloCardsByBoardIdStddevPopulationCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CARD_ROLE_DESC",
  TrelloCardsByBoardIdStddevPopulationChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CHECKLISTS_ASC",
  TrelloCardsByBoardIdStddevPopulationChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CHECKLISTS_DESC",
  TrelloCardsByBoardIdStddevPopulationClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CLOSED_ASC",
  TrelloCardsByBoardIdStddevPopulationClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_CLOSED_DESC",
  TrelloCardsByBoardIdStddevPopulationCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COMMENTS_ASC",
  TrelloCardsByBoardIdStddevPopulationCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COMMENTS_DESC",
  TrelloCardsByBoardIdStddevPopulationCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdStddevPopulationCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdStddevPopulationCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COVER_ASC",
  TrelloCardsByBoardIdStddevPopulationCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_COVER_DESC",
  TrelloCardsByBoardIdStddevPopulationDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdStddevPopulationDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdStddevPopulationDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_DESCRIPTION_ASC",
  TrelloCardsByBoardIdStddevPopulationDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_DESCRIPTION_DESC",
  TrelloCardsByBoardIdStddevPopulationEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_EMAILS_ASC",
  TrelloCardsByBoardIdStddevPopulationEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_EMAILS_DESC",
  TrelloCardsByBoardIdStddevPopulationIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_ID_ASC",
  TrelloCardsByBoardIdStddevPopulationIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_ID_DESC",
  TrelloCardsByBoardIdStddevPopulationIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdStddevPopulationIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdStddevPopulationLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LABELS_ASC",
  TrelloCardsByBoardIdStddevPopulationLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LABELS_DESC",
  TrelloCardsByBoardIdStddevPopulationListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LIST_ID_ASC",
  TrelloCardsByBoardIdStddevPopulationListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LIST_ID_DESC",
  TrelloCardsByBoardIdStddevPopulationLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdStddevPopulationLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdStddevPopulationMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdStddevPopulationMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdStddevPopulationNameAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_NAME_ASC",
  TrelloCardsByBoardIdStddevPopulationNameDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_NAME_DESC",
  TrelloCardsByBoardIdStddevPopulationShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_ID_ASC",
  TrelloCardsByBoardIdStddevPopulationShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_ID_DESC",
  TrelloCardsByBoardIdStddevPopulationShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdStddevPopulationShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdStddevPopulationShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_URL_ASC",
  TrelloCardsByBoardIdStddevPopulationShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_SHORT_URL_DESC",
  TrelloCardsByBoardIdStddevPopulationUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_URL_ASC",
  TrelloCardsByBoardIdStddevPopulationUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_POPULATION_URL_DESC",
  TrelloCardsByBoardIdStddevSampleAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdStddevSampleAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdStddevSampleBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_BADGES_ASC",
  TrelloCardsByBoardIdStddevSampleBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_BADGES_DESC",
  TrelloCardsByBoardIdStddevSampleBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_ASC",
  TrelloCardsByBoardIdStddevSampleBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_DESC",
  TrelloCardsByBoardIdStddevSampleCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdStddevSampleCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdStddevSampleCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_MOVED_ASC",
  TrelloCardsByBoardIdStddevSampleCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_MOVED_DESC",
  TrelloCardsByBoardIdStddevSampleCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_ROLE_ASC",
  TrelloCardsByBoardIdStddevSampleCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CARD_ROLE_DESC",
  TrelloCardsByBoardIdStddevSampleChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CHECKLISTS_ASC",
  TrelloCardsByBoardIdStddevSampleChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CHECKLISTS_DESC",
  TrelloCardsByBoardIdStddevSampleClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CLOSED_ASC",
  TrelloCardsByBoardIdStddevSampleClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_CLOSED_DESC",
  TrelloCardsByBoardIdStddevSampleCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COMMENTS_ASC",
  TrelloCardsByBoardIdStddevSampleCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COMMENTS_DESC",
  TrelloCardsByBoardIdStddevSampleCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdStddevSampleCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdStddevSampleCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COVER_ASC",
  TrelloCardsByBoardIdStddevSampleCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_COVER_DESC",
  TrelloCardsByBoardIdStddevSampleDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdStddevSampleDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdStddevSampleDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_DESCRIPTION_ASC",
  TrelloCardsByBoardIdStddevSampleDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_DESCRIPTION_DESC",
  TrelloCardsByBoardIdStddevSampleEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_EMAILS_ASC",
  TrelloCardsByBoardIdStddevSampleEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_EMAILS_DESC",
  TrelloCardsByBoardIdStddevSampleIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_ID_ASC",
  TrelloCardsByBoardIdStddevSampleIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_ID_DESC",
  TrelloCardsByBoardIdStddevSampleIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdStddevSampleIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdStddevSampleLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LABELS_ASC",
  TrelloCardsByBoardIdStddevSampleLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LABELS_DESC",
  TrelloCardsByBoardIdStddevSampleListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LIST_ID_ASC",
  TrelloCardsByBoardIdStddevSampleListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LIST_ID_DESC",
  TrelloCardsByBoardIdStddevSampleLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdStddevSampleLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdStddevSampleMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdStddevSampleMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdStddevSampleNameAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_ASC",
  TrelloCardsByBoardIdStddevSampleNameDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_DESC",
  TrelloCardsByBoardIdStddevSampleShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_ID_ASC",
  TrelloCardsByBoardIdStddevSampleShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_ID_DESC",
  TrelloCardsByBoardIdStddevSampleShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdStddevSampleShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdStddevSampleShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_URL_ASC",
  TrelloCardsByBoardIdStddevSampleShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_SHORT_URL_DESC",
  TrelloCardsByBoardIdStddevSampleUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_URL_ASC",
  TrelloCardsByBoardIdStddevSampleUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_STDDEV_SAMPLE_URL_DESC",
  TrelloCardsByBoardIdSumAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdSumAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdSumBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_BADGES_ASC",
  TrelloCardsByBoardIdSumBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_BADGES_DESC",
  TrelloCardsByBoardIdSumBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_BOARD_ID_ASC",
  TrelloCardsByBoardIdSumBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_BOARD_ID_DESC",
  TrelloCardsByBoardIdSumCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdSumCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdSumCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_MOVED_ASC",
  TrelloCardsByBoardIdSumCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_MOVED_DESC",
  TrelloCardsByBoardIdSumCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_ROLE_ASC",
  TrelloCardsByBoardIdSumCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CARD_ROLE_DESC",
  TrelloCardsByBoardIdSumChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CHECKLISTS_ASC",
  TrelloCardsByBoardIdSumChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CHECKLISTS_DESC",
  TrelloCardsByBoardIdSumClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CLOSED_ASC",
  TrelloCardsByBoardIdSumClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_CLOSED_DESC",
  TrelloCardsByBoardIdSumCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COMMENTS_ASC",
  TrelloCardsByBoardIdSumCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COMMENTS_DESC",
  TrelloCardsByBoardIdSumCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdSumCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdSumCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COVER_ASC",
  TrelloCardsByBoardIdSumCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_COVER_DESC",
  TrelloCardsByBoardIdSumDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdSumDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdSumDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdSumDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdSumEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_EMAILS_ASC",
  TrelloCardsByBoardIdSumEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_EMAILS_DESC",
  TrelloCardsByBoardIdSumIdAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_ID_ASC",
  TrelloCardsByBoardIdSumIdDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_ID_DESC",
  TrelloCardsByBoardIdSumIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdSumIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdSumLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LABELS_ASC",
  TrelloCardsByBoardIdSumLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LABELS_DESC",
  TrelloCardsByBoardIdSumListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LIST_ID_ASC",
  TrelloCardsByBoardIdSumListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LIST_ID_DESC",
  TrelloCardsByBoardIdSumLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdSumLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdSumMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdSumMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdSumNameAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_NAME_ASC",
  TrelloCardsByBoardIdSumNameDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_NAME_DESC",
  TrelloCardsByBoardIdSumShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_ID_ASC",
  TrelloCardsByBoardIdSumShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_ID_DESC",
  TrelloCardsByBoardIdSumShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdSumShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdSumShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_URL_ASC",
  TrelloCardsByBoardIdSumShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_SHORT_URL_DESC",
  TrelloCardsByBoardIdSumUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_SUM_URL_ASC",
  TrelloCardsByBoardIdSumUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_SUM_URL_DESC",
  TrelloCardsByBoardIdVariancePopulationAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdVariancePopulationAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdVariancePopulationBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_BADGES_ASC",
  TrelloCardsByBoardIdVariancePopulationBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_BADGES_DESC",
  TrelloCardsByBoardIdVariancePopulationBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_ASC",
  TrelloCardsByBoardIdVariancePopulationBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_DESC",
  TrelloCardsByBoardIdVariancePopulationCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdVariancePopulationCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdVariancePopulationCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_MOVED_ASC",
  TrelloCardsByBoardIdVariancePopulationCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_MOVED_DESC",
  TrelloCardsByBoardIdVariancePopulationCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_ROLE_ASC",
  TrelloCardsByBoardIdVariancePopulationCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CARD_ROLE_DESC",
  TrelloCardsByBoardIdVariancePopulationChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CHECKLISTS_ASC",
  TrelloCardsByBoardIdVariancePopulationChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CHECKLISTS_DESC",
  TrelloCardsByBoardIdVariancePopulationClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CLOSED_ASC",
  TrelloCardsByBoardIdVariancePopulationClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_CLOSED_DESC",
  TrelloCardsByBoardIdVariancePopulationCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COMMENTS_ASC",
  TrelloCardsByBoardIdVariancePopulationCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COMMENTS_DESC",
  TrelloCardsByBoardIdVariancePopulationCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdVariancePopulationCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdVariancePopulationCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COVER_ASC",
  TrelloCardsByBoardIdVariancePopulationCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_COVER_DESC",
  TrelloCardsByBoardIdVariancePopulationDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdVariancePopulationDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdVariancePopulationDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_DESCRIPTION_ASC",
  TrelloCardsByBoardIdVariancePopulationDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_DESCRIPTION_DESC",
  TrelloCardsByBoardIdVariancePopulationEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_EMAILS_ASC",
  TrelloCardsByBoardIdVariancePopulationEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_EMAILS_DESC",
  TrelloCardsByBoardIdVariancePopulationIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_ID_ASC",
  TrelloCardsByBoardIdVariancePopulationIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_ID_DESC",
  TrelloCardsByBoardIdVariancePopulationIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdVariancePopulationIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdVariancePopulationLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LABELS_ASC",
  TrelloCardsByBoardIdVariancePopulationLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LABELS_DESC",
  TrelloCardsByBoardIdVariancePopulationListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LIST_ID_ASC",
  TrelloCardsByBoardIdVariancePopulationListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LIST_ID_DESC",
  TrelloCardsByBoardIdVariancePopulationLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdVariancePopulationLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdVariancePopulationMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdVariancePopulationMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdVariancePopulationNameAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_ASC",
  TrelloCardsByBoardIdVariancePopulationNameDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_DESC",
  TrelloCardsByBoardIdVariancePopulationShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_ID_ASC",
  TrelloCardsByBoardIdVariancePopulationShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_ID_DESC",
  TrelloCardsByBoardIdVariancePopulationShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdVariancePopulationShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdVariancePopulationShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_URL_ASC",
  TrelloCardsByBoardIdVariancePopulationShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_SHORT_URL_DESC",
  TrelloCardsByBoardIdVariancePopulationUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_URL_ASC",
  TrelloCardsByBoardIdVariancePopulationUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_POPULATION_URL_DESC",
  TrelloCardsByBoardIdVarianceSampleAttachmentsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_ATTACHMENTS_ASC",
  TrelloCardsByBoardIdVarianceSampleAttachmentsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_ATTACHMENTS_DESC",
  TrelloCardsByBoardIdVarianceSampleBadgesAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_BADGES_ASC",
  TrelloCardsByBoardIdVarianceSampleBadgesDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_BADGES_DESC",
  TrelloCardsByBoardIdVarianceSampleBoardIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_ASC",
  TrelloCardsByBoardIdVarianceSampleBoardIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_DESC",
  TrelloCardsByBoardIdVarianceSampleCardCreatedDateAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_CREATED_DATE_ASC",
  TrelloCardsByBoardIdVarianceSampleCardCreatedDateDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_CREATED_DATE_DESC",
  TrelloCardsByBoardIdVarianceSampleCardMovedAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_MOVED_ASC",
  TrelloCardsByBoardIdVarianceSampleCardMovedDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_MOVED_DESC",
  TrelloCardsByBoardIdVarianceSampleCardRoleAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_ROLE_ASC",
  TrelloCardsByBoardIdVarianceSampleCardRoleDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CARD_ROLE_DESC",
  TrelloCardsByBoardIdVarianceSampleChecklistsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CHECKLISTS_ASC",
  TrelloCardsByBoardIdVarianceSampleChecklistsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CHECKLISTS_DESC",
  TrelloCardsByBoardIdVarianceSampleClosedAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CLOSED_ASC",
  TrelloCardsByBoardIdVarianceSampleClosedDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_CLOSED_DESC",
  TrelloCardsByBoardIdVarianceSampleCommentsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COMMENTS_ASC",
  TrelloCardsByBoardIdVarianceSampleCommentsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COMMENTS_DESC",
  TrelloCardsByBoardIdVarianceSampleCompanyNameAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  TrelloCardsByBoardIdVarianceSampleCompanyNameDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  TrelloCardsByBoardIdVarianceSampleCoverAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COVER_ASC",
  TrelloCardsByBoardIdVarianceSampleCoverDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_COVER_DESC",
  TrelloCardsByBoardIdVarianceSampleDateLastActivityAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByBoardIdVarianceSampleDateLastActivityDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByBoardIdVarianceSampleDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  TrelloCardsByBoardIdVarianceSampleDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  TrelloCardsByBoardIdVarianceSampleEmailsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_EMAILS_ASC",
  TrelloCardsByBoardIdVarianceSampleEmailsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_EMAILS_DESC",
  TrelloCardsByBoardIdVarianceSampleIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_ASC",
  TrelloCardsByBoardIdVarianceSampleIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_DESC",
  TrelloCardsByBoardIdVarianceSampleIsTemplateAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_IS_TEMPLATE_ASC",
  TrelloCardsByBoardIdVarianceSampleIsTemplateDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_IS_TEMPLATE_DESC",
  TrelloCardsByBoardIdVarianceSampleLabelsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LABELS_ASC",
  TrelloCardsByBoardIdVarianceSampleLabelsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LABELS_DESC",
  TrelloCardsByBoardIdVarianceSampleListIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LIST_ID_ASC",
  TrelloCardsByBoardIdVarianceSampleListIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LIST_ID_DESC",
  TrelloCardsByBoardIdVarianceSampleLlmDescriptionAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LLM_DESCRIPTION_ASC",
  TrelloCardsByBoardIdVarianceSampleLlmDescriptionDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_LLM_DESCRIPTION_DESC",
  TrelloCardsByBoardIdVarianceSampleMemberIdsAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_MEMBER_IDS_ASC",
  TrelloCardsByBoardIdVarianceSampleMemberIdsDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_MEMBER_IDS_DESC",
  TrelloCardsByBoardIdVarianceSampleNameAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_ASC",
  TrelloCardsByBoardIdVarianceSampleNameDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_DESC",
  TrelloCardsByBoardIdVarianceSampleShortIdAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_ID_ASC",
  TrelloCardsByBoardIdVarianceSampleShortIdDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_ID_DESC",
  TrelloCardsByBoardIdVarianceSampleShortLinkHashAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_LINK_HASH_ASC",
  TrelloCardsByBoardIdVarianceSampleShortLinkHashDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_LINK_HASH_DESC",
  TrelloCardsByBoardIdVarianceSampleShortUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_URL_ASC",
  TrelloCardsByBoardIdVarianceSampleShortUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_SHORT_URL_DESC",
  TrelloCardsByBoardIdVarianceSampleUrlAsc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_URL_ASC",
  TrelloCardsByBoardIdVarianceSampleUrlDesc = "TRELLO_CARDS_BY_BOARD_ID_VARIANCE_SAMPLE_URL_DESC",
  TrelloLabelsByBoardIdAverageBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_BOARD_ID_ASC",
  TrelloLabelsByBoardIdAverageBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_BOARD_ID_DESC",
  TrelloLabelsByBoardIdAverageColorAsc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_COLOR_ASC",
  TrelloLabelsByBoardIdAverageColorDesc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_COLOR_DESC",
  TrelloLabelsByBoardIdAverageIdAsc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_ID_ASC",
  TrelloLabelsByBoardIdAverageIdDesc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_ID_DESC",
  TrelloLabelsByBoardIdAverageNameAsc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_NAME_ASC",
  TrelloLabelsByBoardIdAverageNameDesc = "TRELLO_LABELS_BY_BOARD_ID_AVERAGE_NAME_DESC",
  TrelloLabelsByBoardIdCountAsc = "TRELLO_LABELS_BY_BOARD_ID_COUNT_ASC",
  TrelloLabelsByBoardIdCountDesc = "TRELLO_LABELS_BY_BOARD_ID_COUNT_DESC",
  TrelloLabelsByBoardIdDistinctCountBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_ASC",
  TrelloLabelsByBoardIdDistinctCountBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_DESC",
  TrelloLabelsByBoardIdDistinctCountColorAsc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_COLOR_ASC",
  TrelloLabelsByBoardIdDistinctCountColorDesc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_COLOR_DESC",
  TrelloLabelsByBoardIdDistinctCountIdAsc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_ID_ASC",
  TrelloLabelsByBoardIdDistinctCountIdDesc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_ID_DESC",
  TrelloLabelsByBoardIdDistinctCountNameAsc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_NAME_ASC",
  TrelloLabelsByBoardIdDistinctCountNameDesc = "TRELLO_LABELS_BY_BOARD_ID_DISTINCT_COUNT_NAME_DESC",
  TrelloLabelsByBoardIdMaxBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_MAX_BOARD_ID_ASC",
  TrelloLabelsByBoardIdMaxBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_MAX_BOARD_ID_DESC",
  TrelloLabelsByBoardIdMaxColorAsc = "TRELLO_LABELS_BY_BOARD_ID_MAX_COLOR_ASC",
  TrelloLabelsByBoardIdMaxColorDesc = "TRELLO_LABELS_BY_BOARD_ID_MAX_COLOR_DESC",
  TrelloLabelsByBoardIdMaxIdAsc = "TRELLO_LABELS_BY_BOARD_ID_MAX_ID_ASC",
  TrelloLabelsByBoardIdMaxIdDesc = "TRELLO_LABELS_BY_BOARD_ID_MAX_ID_DESC",
  TrelloLabelsByBoardIdMaxNameAsc = "TRELLO_LABELS_BY_BOARD_ID_MAX_NAME_ASC",
  TrelloLabelsByBoardIdMaxNameDesc = "TRELLO_LABELS_BY_BOARD_ID_MAX_NAME_DESC",
  TrelloLabelsByBoardIdMinBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_MIN_BOARD_ID_ASC",
  TrelloLabelsByBoardIdMinBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_MIN_BOARD_ID_DESC",
  TrelloLabelsByBoardIdMinColorAsc = "TRELLO_LABELS_BY_BOARD_ID_MIN_COLOR_ASC",
  TrelloLabelsByBoardIdMinColorDesc = "TRELLO_LABELS_BY_BOARD_ID_MIN_COLOR_DESC",
  TrelloLabelsByBoardIdMinIdAsc = "TRELLO_LABELS_BY_BOARD_ID_MIN_ID_ASC",
  TrelloLabelsByBoardIdMinIdDesc = "TRELLO_LABELS_BY_BOARD_ID_MIN_ID_DESC",
  TrelloLabelsByBoardIdMinNameAsc = "TRELLO_LABELS_BY_BOARD_ID_MIN_NAME_ASC",
  TrelloLabelsByBoardIdMinNameDesc = "TRELLO_LABELS_BY_BOARD_ID_MIN_NAME_DESC",
  TrelloLabelsByBoardIdStddevPopulationBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_ASC",
  TrelloLabelsByBoardIdStddevPopulationBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_DESC",
  TrelloLabelsByBoardIdStddevPopulationColorAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_COLOR_ASC",
  TrelloLabelsByBoardIdStddevPopulationColorDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_COLOR_DESC",
  TrelloLabelsByBoardIdStddevPopulationIdAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_ID_ASC",
  TrelloLabelsByBoardIdStddevPopulationIdDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_ID_DESC",
  TrelloLabelsByBoardIdStddevPopulationNameAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_NAME_ASC",
  TrelloLabelsByBoardIdStddevPopulationNameDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_POPULATION_NAME_DESC",
  TrelloLabelsByBoardIdStddevSampleBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_ASC",
  TrelloLabelsByBoardIdStddevSampleBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_DESC",
  TrelloLabelsByBoardIdStddevSampleColorAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_COLOR_ASC",
  TrelloLabelsByBoardIdStddevSampleColorDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_COLOR_DESC",
  TrelloLabelsByBoardIdStddevSampleIdAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_ID_ASC",
  TrelloLabelsByBoardIdStddevSampleIdDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_ID_DESC",
  TrelloLabelsByBoardIdStddevSampleNameAsc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_ASC",
  TrelloLabelsByBoardIdStddevSampleNameDesc = "TRELLO_LABELS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_DESC",
  TrelloLabelsByBoardIdSumBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_SUM_BOARD_ID_ASC",
  TrelloLabelsByBoardIdSumBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_SUM_BOARD_ID_DESC",
  TrelloLabelsByBoardIdSumColorAsc = "TRELLO_LABELS_BY_BOARD_ID_SUM_COLOR_ASC",
  TrelloLabelsByBoardIdSumColorDesc = "TRELLO_LABELS_BY_BOARD_ID_SUM_COLOR_DESC",
  TrelloLabelsByBoardIdSumIdAsc = "TRELLO_LABELS_BY_BOARD_ID_SUM_ID_ASC",
  TrelloLabelsByBoardIdSumIdDesc = "TRELLO_LABELS_BY_BOARD_ID_SUM_ID_DESC",
  TrelloLabelsByBoardIdSumNameAsc = "TRELLO_LABELS_BY_BOARD_ID_SUM_NAME_ASC",
  TrelloLabelsByBoardIdSumNameDesc = "TRELLO_LABELS_BY_BOARD_ID_SUM_NAME_DESC",
  TrelloLabelsByBoardIdVariancePopulationBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_ASC",
  TrelloLabelsByBoardIdVariancePopulationBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_DESC",
  TrelloLabelsByBoardIdVariancePopulationColorAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_COLOR_ASC",
  TrelloLabelsByBoardIdVariancePopulationColorDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_COLOR_DESC",
  TrelloLabelsByBoardIdVariancePopulationIdAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_ID_ASC",
  TrelloLabelsByBoardIdVariancePopulationIdDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_ID_DESC",
  TrelloLabelsByBoardIdVariancePopulationNameAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_ASC",
  TrelloLabelsByBoardIdVariancePopulationNameDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_DESC",
  TrelloLabelsByBoardIdVarianceSampleBoardIdAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_ASC",
  TrelloLabelsByBoardIdVarianceSampleBoardIdDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_DESC",
  TrelloLabelsByBoardIdVarianceSampleColorAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_COLOR_ASC",
  TrelloLabelsByBoardIdVarianceSampleColorDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_COLOR_DESC",
  TrelloLabelsByBoardIdVarianceSampleIdAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_ASC",
  TrelloLabelsByBoardIdVarianceSampleIdDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_DESC",
  TrelloLabelsByBoardIdVarianceSampleNameAsc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_ASC",
  TrelloLabelsByBoardIdVarianceSampleNameDesc = "TRELLO_LABELS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_DESC",
  TrelloListsByBoardIdAverageBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_BOARD_ID_ASC",
  TrelloListsByBoardIdAverageBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_BOARD_ID_DESC",
  TrelloListsByBoardIdAverageClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_CLOSED_ASC",
  TrelloListsByBoardIdAverageClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_CLOSED_DESC",
  TrelloListsByBoardIdAverageIdAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_ID_ASC",
  TrelloListsByBoardIdAverageIdDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_ID_DESC",
  TrelloListsByBoardIdAverageNameAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_NAME_ASC",
  TrelloListsByBoardIdAverageNameDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_NAME_DESC",
  TrelloListsByBoardIdAverageStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_STATUS_ASC",
  TrelloListsByBoardIdAverageStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_STATUS_DESC",
  TrelloListsByBoardIdAverageSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_SUBSCRIBED_ASC",
  TrelloListsByBoardIdAverageSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_AVERAGE_SUBSCRIBED_DESC",
  TrelloListsByBoardIdCountAsc = "TRELLO_LISTS_BY_BOARD_ID_COUNT_ASC",
  TrelloListsByBoardIdCountDesc = "TRELLO_LISTS_BY_BOARD_ID_COUNT_DESC",
  TrelloListsByBoardIdDistinctCountBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_ASC",
  TrelloListsByBoardIdDistinctCountBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_BOARD_ID_DESC",
  TrelloListsByBoardIdDistinctCountClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_CLOSED_ASC",
  TrelloListsByBoardIdDistinctCountClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_CLOSED_DESC",
  TrelloListsByBoardIdDistinctCountIdAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_ID_ASC",
  TrelloListsByBoardIdDistinctCountIdDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_ID_DESC",
  TrelloListsByBoardIdDistinctCountNameAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_NAME_ASC",
  TrelloListsByBoardIdDistinctCountNameDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_NAME_DESC",
  TrelloListsByBoardIdDistinctCountStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_STATUS_ASC",
  TrelloListsByBoardIdDistinctCountStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_STATUS_DESC",
  TrelloListsByBoardIdDistinctCountSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_SUBSCRIBED_ASC",
  TrelloListsByBoardIdDistinctCountSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_DISTINCT_COUNT_SUBSCRIBED_DESC",
  TrelloListsByBoardIdMaxBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_BOARD_ID_ASC",
  TrelloListsByBoardIdMaxBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_BOARD_ID_DESC",
  TrelloListsByBoardIdMaxClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_CLOSED_ASC",
  TrelloListsByBoardIdMaxClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_CLOSED_DESC",
  TrelloListsByBoardIdMaxIdAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_ID_ASC",
  TrelloListsByBoardIdMaxIdDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_ID_DESC",
  TrelloListsByBoardIdMaxNameAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_NAME_ASC",
  TrelloListsByBoardIdMaxNameDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_NAME_DESC",
  TrelloListsByBoardIdMaxStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_STATUS_ASC",
  TrelloListsByBoardIdMaxStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_STATUS_DESC",
  TrelloListsByBoardIdMaxSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_MAX_SUBSCRIBED_ASC",
  TrelloListsByBoardIdMaxSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_MAX_SUBSCRIBED_DESC",
  TrelloListsByBoardIdMinBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_BOARD_ID_ASC",
  TrelloListsByBoardIdMinBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_BOARD_ID_DESC",
  TrelloListsByBoardIdMinClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_CLOSED_ASC",
  TrelloListsByBoardIdMinClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_CLOSED_DESC",
  TrelloListsByBoardIdMinIdAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_ID_ASC",
  TrelloListsByBoardIdMinIdDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_ID_DESC",
  TrelloListsByBoardIdMinNameAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_NAME_ASC",
  TrelloListsByBoardIdMinNameDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_NAME_DESC",
  TrelloListsByBoardIdMinStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_STATUS_ASC",
  TrelloListsByBoardIdMinStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_STATUS_DESC",
  TrelloListsByBoardIdMinSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_MIN_SUBSCRIBED_ASC",
  TrelloListsByBoardIdMinSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_MIN_SUBSCRIBED_DESC",
  TrelloListsByBoardIdStddevPopulationBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_ASC",
  TrelloListsByBoardIdStddevPopulationBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_BOARD_ID_DESC",
  TrelloListsByBoardIdStddevPopulationClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_CLOSED_ASC",
  TrelloListsByBoardIdStddevPopulationClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_CLOSED_DESC",
  TrelloListsByBoardIdStddevPopulationIdAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_ID_ASC",
  TrelloListsByBoardIdStddevPopulationIdDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_ID_DESC",
  TrelloListsByBoardIdStddevPopulationNameAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_NAME_ASC",
  TrelloListsByBoardIdStddevPopulationNameDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_NAME_DESC",
  TrelloListsByBoardIdStddevPopulationStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_STATUS_ASC",
  TrelloListsByBoardIdStddevPopulationStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_STATUS_DESC",
  TrelloListsByBoardIdStddevPopulationSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_SUBSCRIBED_ASC",
  TrelloListsByBoardIdStddevPopulationSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_POPULATION_SUBSCRIBED_DESC",
  TrelloListsByBoardIdStddevSampleBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_ASC",
  TrelloListsByBoardIdStddevSampleBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_BOARD_ID_DESC",
  TrelloListsByBoardIdStddevSampleClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_CLOSED_ASC",
  TrelloListsByBoardIdStddevSampleClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_CLOSED_DESC",
  TrelloListsByBoardIdStddevSampleIdAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_ID_ASC",
  TrelloListsByBoardIdStddevSampleIdDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_ID_DESC",
  TrelloListsByBoardIdStddevSampleNameAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_ASC",
  TrelloListsByBoardIdStddevSampleNameDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_NAME_DESC",
  TrelloListsByBoardIdStddevSampleStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_STATUS_ASC",
  TrelloListsByBoardIdStddevSampleStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_STATUS_DESC",
  TrelloListsByBoardIdStddevSampleSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_SUBSCRIBED_ASC",
  TrelloListsByBoardIdStddevSampleSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_STDDEV_SAMPLE_SUBSCRIBED_DESC",
  TrelloListsByBoardIdSumBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_BOARD_ID_ASC",
  TrelloListsByBoardIdSumBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_BOARD_ID_DESC",
  TrelloListsByBoardIdSumClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_CLOSED_ASC",
  TrelloListsByBoardIdSumClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_CLOSED_DESC",
  TrelloListsByBoardIdSumIdAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_ID_ASC",
  TrelloListsByBoardIdSumIdDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_ID_DESC",
  TrelloListsByBoardIdSumNameAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_NAME_ASC",
  TrelloListsByBoardIdSumNameDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_NAME_DESC",
  TrelloListsByBoardIdSumStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_STATUS_ASC",
  TrelloListsByBoardIdSumStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_STATUS_DESC",
  TrelloListsByBoardIdSumSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_SUM_SUBSCRIBED_ASC",
  TrelloListsByBoardIdSumSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_SUM_SUBSCRIBED_DESC",
  TrelloListsByBoardIdVariancePopulationBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_ASC",
  TrelloListsByBoardIdVariancePopulationBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_BOARD_ID_DESC",
  TrelloListsByBoardIdVariancePopulationClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_CLOSED_ASC",
  TrelloListsByBoardIdVariancePopulationClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_CLOSED_DESC",
  TrelloListsByBoardIdVariancePopulationIdAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_ID_ASC",
  TrelloListsByBoardIdVariancePopulationIdDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_ID_DESC",
  TrelloListsByBoardIdVariancePopulationNameAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_ASC",
  TrelloListsByBoardIdVariancePopulationNameDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_NAME_DESC",
  TrelloListsByBoardIdVariancePopulationStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_STATUS_ASC",
  TrelloListsByBoardIdVariancePopulationStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_STATUS_DESC",
  TrelloListsByBoardIdVariancePopulationSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_SUBSCRIBED_ASC",
  TrelloListsByBoardIdVariancePopulationSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_POPULATION_SUBSCRIBED_DESC",
  TrelloListsByBoardIdVarianceSampleBoardIdAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_ASC",
  TrelloListsByBoardIdVarianceSampleBoardIdDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_BOARD_ID_DESC",
  TrelloListsByBoardIdVarianceSampleClosedAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_CLOSED_ASC",
  TrelloListsByBoardIdVarianceSampleClosedDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_CLOSED_DESC",
  TrelloListsByBoardIdVarianceSampleIdAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_ASC",
  TrelloListsByBoardIdVarianceSampleIdDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_ID_DESC",
  TrelloListsByBoardIdVarianceSampleNameAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_ASC",
  TrelloListsByBoardIdVarianceSampleNameDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_NAME_DESC",
  TrelloListsByBoardIdVarianceSampleStatusAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_STATUS_ASC",
  TrelloListsByBoardIdVarianceSampleStatusDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_STATUS_DESC",
  TrelloListsByBoardIdVarianceSampleSubscribedAsc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_SUBSCRIBED_ASC",
  TrelloListsByBoardIdVarianceSampleSubscribedDesc = "TRELLO_LISTS_BY_BOARD_ID_VARIANCE_SAMPLE_SUBSCRIBED_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

export type TrelloCard = Node & {
  __typename?: "TrelloCard";
  attachments?: Maybe<Scalars["JSON"]["output"]>;
  badges: Scalars["JSON"]["output"];
  /** Reads a single `TrelloBoard` that is related to this `TrelloCard`. */
  board?: Maybe<TrelloBoard>;
  boardId: Scalars["String"]["output"];
  cardCreatedDate?: Maybe<Scalars["Datetime"]["output"]>;
  cardMoved?: Maybe<Scalars["JSON"]["output"]>;
  cardRole: Scalars["String"]["output"];
  checklists?: Maybe<Scalars["JSON"]["output"]>;
  closed: Scalars["Boolean"]["output"];
  comments?: Maybe<Scalars["JSON"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  cover?: Maybe<Scalars["JSON"]["output"]>;
  dateLastActivity?: Maybe<Scalars["Datetime"]["output"]>;
  description: Scalars["String"]["output"];
  emails?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["String"]["output"];
  isTemplate?: Maybe<Scalars["Boolean"]["output"]>;
  labels?: Maybe<Scalars["JSON"]["output"]>;
  /** Reads a single `TrelloList` that is related to this `TrelloCard`. */
  list?: Maybe<TrelloList>;
  listId: Scalars["String"]["output"];
  llmDescription?: Maybe<Scalars["String"]["output"]>;
  memberIds?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  shortId: Scalars["String"]["output"];
  shortLinkHash: Scalars["String"]["output"];
  shortUrl: Scalars["String"]["output"];
  /** Reads through a `SrcCompany`. */
  srcCompany?: Maybe<SrcCompany>;
  url: Scalars["String"]["output"];
};

export type TrelloCardSrcCompanyArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<SrcCompanyCondition>;
  filter?: InputMaybe<SrcCompanyFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SrcCompaniesOrderBy>>;
};

export type TrelloCardAggregates = {
  __typename?: "TrelloCardAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloCardDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `TrelloCard` object types. */
export type TrelloCardAggregatesFilter = {
  /** Distinct count aggregate over matching `TrelloCard` objects. */
  distinctCount?: InputMaybe<TrelloCardDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TrelloCard` object to be included within the aggregate. */
  filter?: InputMaybe<TrelloCardFilter>;
};

/**
 * A condition to be used against `TrelloCard` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TrelloCardCondition = {
  /** Checks for equality with the object’s `attachments` field. */
  attachments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `badges` field. */
  badges?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `boardId` field. */
  boardId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cardCreatedDate` field. */
  cardCreatedDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `cardMoved` field. */
  cardMoved?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `cardRole` field. */
  cardRole?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `checklists` field. */
  checklists?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `closed` field. */
  closed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `comments` field. */
  comments?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `cover` field. */
  cover?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `dateLastActivity` field. */
  dateLastActivity?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `emails` field. */
  emails?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `labels` field. */
  labels?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `listId` field. */
  listId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `llmDescription` field. */
  llmDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `memberIds` field. */
  memberIds?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `shortId` field. */
  shortId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `shortLinkHash` field. */
  shortLinkHash?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `shortUrl` field. */
  shortUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrelloCardDistinctCountAggregateFilter = {
  attachments?: InputMaybe<BigIntFilter>;
  badges?: InputMaybe<BigIntFilter>;
  boardId?: InputMaybe<BigIntFilter>;
  cardCreatedDate?: InputMaybe<BigIntFilter>;
  cardMoved?: InputMaybe<BigIntFilter>;
  cardRole?: InputMaybe<BigIntFilter>;
  checklists?: InputMaybe<BigIntFilter>;
  closed?: InputMaybe<BigIntFilter>;
  comments?: InputMaybe<BigIntFilter>;
  companyName?: InputMaybe<BigIntFilter>;
  cover?: InputMaybe<BigIntFilter>;
  dateLastActivity?: InputMaybe<BigIntFilter>;
  description?: InputMaybe<BigIntFilter>;
  emails?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isTemplate?: InputMaybe<BigIntFilter>;
  labels?: InputMaybe<BigIntFilter>;
  listId?: InputMaybe<BigIntFilter>;
  llmDescription?: InputMaybe<BigIntFilter>;
  memberIds?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  shortId?: InputMaybe<BigIntFilter>;
  shortLinkHash?: InputMaybe<BigIntFilter>;
  shortUrl?: InputMaybe<BigIntFilter>;
  url?: InputMaybe<BigIntFilter>;
};

export type TrelloCardDistinctCountAggregates = {
  __typename?: "TrelloCardDistinctCountAggregates";
  /** Distinct count of attachments across the matching connection */
  attachments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of badges across the matching connection */
  badges?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of boardId across the matching connection */
  boardId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardCreatedDate across the matching connection */
  cardCreatedDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardMoved across the matching connection */
  cardMoved?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cardRole across the matching connection */
  cardRole?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of checklists across the matching connection */
  checklists?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of closed across the matching connection */
  closed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of comments across the matching connection */
  comments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of cover across the matching connection */
  cover?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateLastActivity across the matching connection */
  dateLastActivity?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of emails across the matching connection */
  emails?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of labels across the matching connection */
  labels?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of listId across the matching connection */
  listId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of llmDescription across the matching connection */
  llmDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberIds across the matching connection */
  memberIds?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of shortId across the matching connection */
  shortId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of shortLinkHash across the matching connection */
  shortLinkHash?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of shortUrl across the matching connection */
  shortUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloCard` object types. All fields are combined with a logical ‘and.’ */
export type TrelloCardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloCardFilter>>;
  /** Filter by the object’s `attachments` field. */
  attachments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `badges` field. */
  badges?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `board` relation. */
  board?: InputMaybe<TrelloBoardFilter>;
  /** Filter by the object’s `boardId` field. */
  boardId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cardCreatedDate` field. */
  cardCreatedDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cardMoved` field. */
  cardMoved?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `cardRole` field. */
  cardRole?: InputMaybe<StringFilter>;
  /** Filter by the object’s `checklists` field. */
  checklists?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `closed` field. */
  closed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `comments` field. */
  comments?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cover` field. */
  cover?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dateLastActivity` field. */
  dateLastActivity?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emails` field. */
  emails?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `labels` field. */
  labels?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `list` relation. */
  list?: InputMaybe<TrelloListFilter>;
  /** Filter by the object’s `listId` field. */
  listId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `llmDescription` field. */
  llmDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `memberIds` field. */
  memberIds?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloCardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloCardFilter>>;
  /** Filter by the object’s `shortId` field. */
  shortId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `shortLinkHash` field. */
  shortLinkHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `shortUrl` field. */
  shortUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `srcCompany` polymorphic relation. */
  srcCompany?: InputMaybe<SrcCompanyFilter>;
  /** Filter for if the object’s `srcCompany`               polymorphic relation exist. */
  srcCompanyExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TrelloCard` for usage during aggregation. */
export enum TrelloCardGroupBy {
  Attachments = "ATTACHMENTS",
  Badges = "BADGES",
  BoardId = "BOARD_ID",
  CardCreatedDate = "CARD_CREATED_DATE",
  CardCreatedDateTruncatedToDay = "CARD_CREATED_DATE_TRUNCATED_TO_DAY",
  CardCreatedDateTruncatedToHour = "CARD_CREATED_DATE_TRUNCATED_TO_HOUR",
  CardMoved = "CARD_MOVED",
  CardRole = "CARD_ROLE",
  Checklists = "CHECKLISTS",
  Closed = "CLOSED",
  Comments = "COMMENTS",
  CompanyName = "COMPANY_NAME",
  Cover = "COVER",
  DateLastActivity = "DATE_LAST_ACTIVITY",
  DateLastActivityTruncatedToDay = "DATE_LAST_ACTIVITY_TRUNCATED_TO_DAY",
  DateLastActivityTruncatedToHour = "DATE_LAST_ACTIVITY_TRUNCATED_TO_HOUR",
  Description = "DESCRIPTION",
  Emails = "EMAILS",
  IsTemplate = "IS_TEMPLATE",
  Labels = "LABELS",
  ListId = "LIST_ID",
  LlmDescription = "LLM_DESCRIPTION",
  MemberIds = "MEMBER_IDS",
  Name = "NAME",
  ShortId = "SHORT_ID",
  ShortUrl = "SHORT_URL",
  Url = "URL",
}

export type TrelloCardHavingAverageInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingDistinctCountInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `TrelloCard` aggregates. */
export type TrelloCardHavingInput = {
  AND?: InputMaybe<Array<TrelloCardHavingInput>>;
  OR?: InputMaybe<Array<TrelloCardHavingInput>>;
  average?: InputMaybe<TrelloCardHavingAverageInput>;
  distinctCount?: InputMaybe<TrelloCardHavingDistinctCountInput>;
  max?: InputMaybe<TrelloCardHavingMaxInput>;
  min?: InputMaybe<TrelloCardHavingMinInput>;
  stddevPopulation?: InputMaybe<TrelloCardHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TrelloCardHavingStddevSampleInput>;
  sum?: InputMaybe<TrelloCardHavingSumInput>;
  variancePopulation?: InputMaybe<TrelloCardHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TrelloCardHavingVarianceSampleInput>;
};

export type TrelloCardHavingMaxInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingMinInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingStddevPopulationInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingStddevSampleInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingSumInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingVariancePopulationInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

export type TrelloCardHavingVarianceSampleInput = {
  cardCreatedDate?: InputMaybe<HavingDatetimeFilter>;
  dateLastActivity?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `TrelloCardLgCategory` values. */
export type TrelloCardLgCategoriesConnection = {
  __typename?: "TrelloCardLgCategoriesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloCardLgCategoryAggregates>;
  /** A list of edges which contains the `TrelloCardLgCategory` and cursor to aid in pagination. */
  edges: Array<TrelloCardLgCategoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloCardLgCategoryAggregates>>;
  /** A list of `TrelloCardLgCategory` objects. */
  nodes: Array<TrelloCardLgCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloCardLgCategory` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloCardLgCategory` values. */
export type TrelloCardLgCategoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloCardLgCategoryGroupBy>;
  having?: InputMaybe<TrelloCardLgCategoryHavingInput>;
};

/** A `TrelloCardLgCategory` edge in the connection. */
export type TrelloCardLgCategoriesEdge = {
  __typename?: "TrelloCardLgCategoriesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloCardLgCategory` at the end of the edge. */
  node: TrelloCardLgCategory;
};

/** Methods to use when ordering `TrelloCardLgCategory`. */
export enum TrelloCardLgCategoriesOrderBy {
  LgCategoryIdAsc = "LG_CATEGORY_ID_ASC",
  LgCategoryIdDesc = "LG_CATEGORY_ID_DESC",
  MainAsc = "MAIN_ASC",
  MainDesc = "MAIN_DESC",
  Natural = "NATURAL",
  TrelloCardIdAsc = "TRELLO_CARD_ID_ASC",
  TrelloCardIdDesc = "TRELLO_CARD_ID_DESC",
}

export type TrelloCardLgCategory = {
  __typename?: "TrelloCardLgCategory";
  lgCategoryId?: Maybe<Scalars["Int"]["output"]>;
  main: Scalars["Boolean"]["output"];
  trelloCardId?: Maybe<Scalars["String"]["output"]>;
};

export type TrelloCardLgCategoryAggregates = {
  __typename?: "TrelloCardLgCategoryAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TrelloCardLgCategoryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloCardLgCategoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TrelloCardLgCategoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TrelloCardLgCategoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TrelloCardLgCategoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TrelloCardLgCategoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TrelloCardLgCategorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TrelloCardLgCategoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TrelloCardLgCategoryVarianceSampleAggregates>;
};

export type TrelloCardLgCategoryAverageAggregates = {
  __typename?: "TrelloCardLgCategoryAverageAggregates";
  /** Mean average of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `TrelloCardLgCategory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TrelloCardLgCategoryCondition = {
  /** Checks for equality with the object’s `lgCategoryId` field. */
  lgCategoryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `main` field. */
  main?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `trelloCardId` field. */
  trelloCardId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrelloCardLgCategoryDistinctCountAggregates = {
  __typename?: "TrelloCardLgCategoryDistinctCountAggregates";
  /** Distinct count of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of main across the matching connection */
  main?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of trelloCardId across the matching connection */
  trelloCardId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloCardLgCategory` object types. All fields are combined with a logical ‘and.’ */
export type TrelloCardLgCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloCardLgCategoryFilter>>;
  /** Filter by the object’s `lgCategoryId` field. */
  lgCategoryId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `main` field. */
  main?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloCardLgCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloCardLgCategoryFilter>>;
  /** Filter by the object’s `trelloCardId` field. */
  trelloCardId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TrelloCardLgCategory` for usage during aggregation. */
export enum TrelloCardLgCategoryGroupBy {
  LgCategoryId = "LG_CATEGORY_ID",
  Main = "MAIN",
  TrelloCardId = "TRELLO_CARD_ID",
}

export type TrelloCardLgCategoryHavingAverageInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingDistinctCountInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `TrelloCardLgCategory` aggregates. */
export type TrelloCardLgCategoryHavingInput = {
  AND?: InputMaybe<Array<TrelloCardLgCategoryHavingInput>>;
  OR?: InputMaybe<Array<TrelloCardLgCategoryHavingInput>>;
  average?: InputMaybe<TrelloCardLgCategoryHavingAverageInput>;
  distinctCount?: InputMaybe<TrelloCardLgCategoryHavingDistinctCountInput>;
  max?: InputMaybe<TrelloCardLgCategoryHavingMaxInput>;
  min?: InputMaybe<TrelloCardLgCategoryHavingMinInput>;
  stddevPopulation?: InputMaybe<TrelloCardLgCategoryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TrelloCardLgCategoryHavingStddevSampleInput>;
  sum?: InputMaybe<TrelloCardLgCategoryHavingSumInput>;
  variancePopulation?: InputMaybe<TrelloCardLgCategoryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TrelloCardLgCategoryHavingVarianceSampleInput>;
};

export type TrelloCardLgCategoryHavingMaxInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingMinInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingStddevPopulationInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingStddevSampleInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingSumInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingVariancePopulationInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryHavingVarianceSampleInput = {
  lgCategoryId?: InputMaybe<HavingIntFilter>;
};

export type TrelloCardLgCategoryMaxAggregates = {
  __typename?: "TrelloCardLgCategoryMaxAggregates";
  /** Maximum of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type TrelloCardLgCategoryMinAggregates = {
  __typename?: "TrelloCardLgCategoryMinAggregates";
  /** Minimum of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["Int"]["output"]>;
};

export type TrelloCardLgCategoryStddevPopulationAggregates = {
  __typename?: "TrelloCardLgCategoryStddevPopulationAggregates";
  /** Population standard deviation of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type TrelloCardLgCategoryStddevSampleAggregates = {
  __typename?: "TrelloCardLgCategoryStddevSampleAggregates";
  /** Sample standard deviation of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type TrelloCardLgCategorySumAggregates = {
  __typename?: "TrelloCardLgCategorySumAggregates";
  /** Sum of lgCategoryId across the matching connection */
  lgCategoryId: Scalars["BigInt"]["output"];
};

export type TrelloCardLgCategoryVariancePopulationAggregates = {
  __typename?: "TrelloCardLgCategoryVariancePopulationAggregates";
  /** Population variance of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type TrelloCardLgCategoryVarianceSampleAggregates = {
  __typename?: "TrelloCardLgCategoryVarianceSampleAggregates";
  /** Sample variance of lgCategoryId across the matching connection */
  lgCategoryId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `TrelloCard` values. */
export type TrelloCardsConnection = {
  __typename?: "TrelloCardsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloCardAggregates>;
  /** A list of edges which contains the `TrelloCard` and cursor to aid in pagination. */
  edges: Array<TrelloCardsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloCardAggregates>>;
  /** A list of `TrelloCard` objects. */
  nodes: Array<TrelloCard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloCard` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloCard` values. */
export type TrelloCardsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloCardGroupBy>;
  having?: InputMaybe<TrelloCardHavingInput>;
};

/** A `TrelloCard` edge in the connection. */
export type TrelloCardsEdge = {
  __typename?: "TrelloCardsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloCard` at the end of the edge. */
  node: TrelloCard;
};

/** Methods to use when ordering `TrelloCard`. */
export enum TrelloCardsOrderBy {
  AttachmentsAsc = "ATTACHMENTS_ASC",
  AttachmentsDesc = "ATTACHMENTS_DESC",
  BadgesAsc = "BADGES_ASC",
  BadgesDesc = "BADGES_DESC",
  BoardIdAsc = "BOARD_ID_ASC",
  BoardIdDesc = "BOARD_ID_DESC",
  CardCreatedDateAsc = "CARD_CREATED_DATE_ASC",
  CardCreatedDateDesc = "CARD_CREATED_DATE_DESC",
  CardMovedAsc = "CARD_MOVED_ASC",
  CardMovedDesc = "CARD_MOVED_DESC",
  CardRoleAsc = "CARD_ROLE_ASC",
  CardRoleDesc = "CARD_ROLE_DESC",
  ChecklistsAsc = "CHECKLISTS_ASC",
  ChecklistsDesc = "CHECKLISTS_DESC",
  ClosedAsc = "CLOSED_ASC",
  ClosedDesc = "CLOSED_DESC",
  CommentsAsc = "COMMENTS_ASC",
  CommentsDesc = "COMMENTS_DESC",
  CompanyNameAsc = "COMPANY_NAME_ASC",
  CompanyNameDesc = "COMPANY_NAME_DESC",
  CoverAsc = "COVER_ASC",
  CoverDesc = "COVER_DESC",
  DateLastActivityAsc = "DATE_LAST_ACTIVITY_ASC",
  DateLastActivityDesc = "DATE_LAST_ACTIVITY_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  EmailsAsc = "EMAILS_ASC",
  EmailsDesc = "EMAILS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsTemplateAsc = "IS_TEMPLATE_ASC",
  IsTemplateDesc = "IS_TEMPLATE_DESC",
  LabelsAsc = "LABELS_ASC",
  LabelsDesc = "LABELS_DESC",
  ListIdAsc = "LIST_ID_ASC",
  ListIdDesc = "LIST_ID_DESC",
  LlmDescriptionAsc = "LLM_DESCRIPTION_ASC",
  LlmDescriptionDesc = "LLM_DESCRIPTION_DESC",
  MemberIdsAsc = "MEMBER_IDS_ASC",
  MemberIdsDesc = "MEMBER_IDS_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ShortIdAsc = "SHORT_ID_ASC",
  ShortIdDesc = "SHORT_ID_DESC",
  ShortLinkHashAsc = "SHORT_LINK_HASH_ASC",
  ShortLinkHashDesc = "SHORT_LINK_HASH_DESC",
  ShortUrlAsc = "SHORT_URL_ASC",
  ShortUrlDesc = "SHORT_URL_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

export type TrelloLabel = Node & {
  __typename?: "TrelloLabel";
  /** Reads a single `TrelloBoard` that is related to this `TrelloLabel`. */
  board?: Maybe<TrelloBoard>;
  boardId?: Maybe<Scalars["String"]["output"]>;
  color: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type TrelloLabelAggregates = {
  __typename?: "TrelloLabelAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloLabelDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `TrelloLabel` object types. */
export type TrelloLabelAggregatesFilter = {
  /** Distinct count aggregate over matching `TrelloLabel` objects. */
  distinctCount?: InputMaybe<TrelloLabelDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TrelloLabel` object to be included within the aggregate. */
  filter?: InputMaybe<TrelloLabelFilter>;
};

/**
 * A condition to be used against `TrelloLabel` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TrelloLabelCondition = {
  /** Checks for equality with the object’s `boardId` field. */
  boardId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrelloLabelDistinctCountAggregateFilter = {
  boardId?: InputMaybe<BigIntFilter>;
  color?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
};

export type TrelloLabelDistinctCountAggregates = {
  __typename?: "TrelloLabelDistinctCountAggregates";
  /** Distinct count of boardId across the matching connection */
  boardId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of color across the matching connection */
  color?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloLabel` object types. All fields are combined with a logical ‘and.’ */
export type TrelloLabelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloLabelFilter>>;
  /** Filter by the object’s `board` relation. */
  board?: InputMaybe<TrelloBoardFilter>;
  /** A related `board` exists. */
  boardExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `boardId` field. */
  boardId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloLabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloLabelFilter>>;
};

/** Grouping methods for `TrelloLabel` for usage during aggregation. */
export enum TrelloLabelGroupBy {
  BoardId = "BOARD_ID",
  Color = "COLOR",
  Name = "NAME",
}

/** Conditions for `TrelloLabel` aggregates. */
export type TrelloLabelHavingInput = {
  AND?: InputMaybe<Array<TrelloLabelHavingInput>>;
  OR?: InputMaybe<Array<TrelloLabelHavingInput>>;
};

/** A connection to a list of `TrelloLabel` values. */
export type TrelloLabelsConnection = {
  __typename?: "TrelloLabelsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloLabelAggregates>;
  /** A list of edges which contains the `TrelloLabel` and cursor to aid in pagination. */
  edges: Array<TrelloLabelsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloLabelAggregates>>;
  /** A list of `TrelloLabel` objects. */
  nodes: Array<TrelloLabel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloLabel` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloLabel` values. */
export type TrelloLabelsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloLabelGroupBy>;
  having?: InputMaybe<TrelloLabelHavingInput>;
};

/** A `TrelloLabel` edge in the connection. */
export type TrelloLabelsEdge = {
  __typename?: "TrelloLabelsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloLabel` at the end of the edge. */
  node: TrelloLabel;
};

/** Methods to use when ordering `TrelloLabel`. */
export enum TrelloLabelsOrderBy {
  BoardIdAsc = "BOARD_ID_ASC",
  BoardIdDesc = "BOARD_ID_DESC",
  ColorAsc = "COLOR_ASC",
  ColorDesc = "COLOR_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type TrelloList = Node & {
  __typename?: "TrelloList";
  /** Reads a single `TrelloBoard` that is related to this `TrelloList`. */
  board?: Maybe<TrelloBoard>;
  boardId: Scalars["String"]["output"];
  closed: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  status: Scalars["String"]["output"];
  subscribed: Scalars["Boolean"]["output"];
  /** Reads and enables pagination through a set of `TrelloBoard`. */
  trelloBoardsByTrelloCardListIdAndBoardId: TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TrelloCard`. */
  trelloCardsByListId: TrelloCardsConnection;
};

export type TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloBoardCondition>;
  filter?: InputMaybe<TrelloBoardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloBoardsOrderBy>>;
};

export type TrelloListTrelloCardsByListIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardCondition>;
  filter?: InputMaybe<TrelloCardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardsOrderBy>>;
};

export type TrelloListAggregates = {
  __typename?: "TrelloListAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloListDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `TrelloList` object types. */
export type TrelloListAggregatesFilter = {
  /** Distinct count aggregate over matching `TrelloList` objects. */
  distinctCount?: InputMaybe<TrelloListDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TrelloList` object to be included within the aggregate. */
  filter?: InputMaybe<TrelloListFilter>;
};

/**
 * A condition to be used against `TrelloList` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TrelloListCondition = {
  /** Checks for equality with the object’s `boardId` field. */
  boardId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `closed` field. */
  closed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `subscribed` field. */
  subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TrelloListDistinctCountAggregateFilter = {
  boardId?: InputMaybe<BigIntFilter>;
  closed?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  subscribed?: InputMaybe<BigIntFilter>;
};

export type TrelloListDistinctCountAggregates = {
  __typename?: "TrelloListDistinctCountAggregates";
  /** Distinct count of boardId across the matching connection */
  boardId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of closed across the matching connection */
  closed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subscribed across the matching connection */
  subscribed?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloList` object types. All fields are combined with a logical ‘and.’ */
export type TrelloListFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloListFilter>>;
  /** Filter by the object’s `board` relation. */
  board?: InputMaybe<TrelloBoardFilter>;
  /** Filter by the object’s `boardId` field. */
  boardId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `closed` field. */
  closed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloListFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloListFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscribed` field. */
  subscribed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `trelloCardsByListId` relation. */
  trelloCardsByListId?: InputMaybe<TrelloListToManyTrelloCardFilter>;
  /** Some related `trelloCardsByListId` exist. */
  trelloCardsByListIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Grouping methods for `TrelloList` for usage during aggregation. */
export enum TrelloListGroupBy {
  BoardId = "BOARD_ID",
  Closed = "CLOSED",
  Name = "NAME",
  Status = "STATUS",
  Subscribed = "SUBSCRIBED",
}

/** Conditions for `TrelloList` aggregates. */
export type TrelloListHavingInput = {
  AND?: InputMaybe<Array<TrelloListHavingInput>>;
  OR?: InputMaybe<Array<TrelloListHavingInput>>;
};

/** A filter to be used against many `TrelloCard` object types. All fields are combined with a logical ‘and.’ */
export type TrelloListToManyTrelloCardFilter = {
  /** Aggregates across related `TrelloCard` match the filter criteria. */
  aggregates?: InputMaybe<TrelloCardAggregatesFilter>;
  /** Every related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrelloCardFilter>;
  /** No related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrelloCardFilter>;
  /** Some related `TrelloCard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrelloCardFilter>;
};

/** A connection to a list of `TrelloBoard` values, with data from `TrelloCard`. */
export type TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyConnection = {
  __typename?: "TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloBoardAggregates>;
  /** A list of edges which contains the `TrelloBoard`, info from the `TrelloCard`, and the cursor to aid in pagination. */
  edges: Array<TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloBoardAggregates>>;
  /** A list of `TrelloBoard` objects. */
  nodes: Array<TrelloBoard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloBoard` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloBoard` values, with data from `TrelloCard`. */
export type TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloBoardGroupBy>;
  having?: InputMaybe<TrelloBoardHavingInput>;
};

/** A `TrelloBoard` edge in the connection, with data from `TrelloCard`. */
export type TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyEdge = {
  __typename?: "TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloBoard` at the end of the edge. */
  node: TrelloBoard;
  /** Reads and enables pagination through a set of `TrelloCard`. */
  trelloCardsByBoardId: TrelloCardsConnection;
};

/** A `TrelloBoard` edge in the connection, with data from `TrelloCard`. */
export type TrelloListTrelloBoardsByTrelloCardListIdAndBoardIdManyToManyEdgeTrelloCardsByBoardIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<TrelloCardCondition>;
  filter?: InputMaybe<TrelloCardFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TrelloCardsOrderBy>>;
};

/** A connection to a list of `TrelloList` values. */
export type TrelloListsConnection = {
  __typename?: "TrelloListsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloListAggregates>;
  /** A list of edges which contains the `TrelloList` and cursor to aid in pagination. */
  edges: Array<TrelloListsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloListAggregates>>;
  /** A list of `TrelloList` objects. */
  nodes: Array<TrelloList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloList` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloList` values. */
export type TrelloListsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloListGroupBy>;
  having?: InputMaybe<TrelloListHavingInput>;
};

/** A `TrelloList` edge in the connection. */
export type TrelloListsEdge = {
  __typename?: "TrelloListsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloList` at the end of the edge. */
  node: TrelloList;
};

/** Methods to use when ordering `TrelloList`. */
export enum TrelloListsOrderBy {
  BoardIdAsc = "BOARD_ID_ASC",
  BoardIdDesc = "BOARD_ID_DESC",
  ClosedAsc = "CLOSED_ASC",
  ClosedDesc = "CLOSED_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  SubscribedAsc = "SUBSCRIBED_ASC",
  SubscribedDesc = "SUBSCRIBED_DESC",
  TrelloCardsByListIdAverageAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_ATTACHMENTS_ASC",
  TrelloCardsByListIdAverageAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_ATTACHMENTS_DESC",
  TrelloCardsByListIdAverageBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_BADGES_ASC",
  TrelloCardsByListIdAverageBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_BADGES_DESC",
  TrelloCardsByListIdAverageBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_BOARD_ID_ASC",
  TrelloCardsByListIdAverageBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_BOARD_ID_DESC",
  TrelloCardsByListIdAverageCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdAverageCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdAverageCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_MOVED_ASC",
  TrelloCardsByListIdAverageCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_MOVED_DESC",
  TrelloCardsByListIdAverageCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_ROLE_ASC",
  TrelloCardsByListIdAverageCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CARD_ROLE_DESC",
  TrelloCardsByListIdAverageChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CHECKLISTS_ASC",
  TrelloCardsByListIdAverageChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CHECKLISTS_DESC",
  TrelloCardsByListIdAverageClosedAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CLOSED_ASC",
  TrelloCardsByListIdAverageClosedDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_CLOSED_DESC",
  TrelloCardsByListIdAverageCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COMMENTS_ASC",
  TrelloCardsByListIdAverageCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COMMENTS_DESC",
  TrelloCardsByListIdAverageCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COMPANY_NAME_ASC",
  TrelloCardsByListIdAverageCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COMPANY_NAME_DESC",
  TrelloCardsByListIdAverageCoverAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COVER_ASC",
  TrelloCardsByListIdAverageCoverDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_COVER_DESC",
  TrelloCardsByListIdAverageDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdAverageDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdAverageDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_DESCRIPTION_ASC",
  TrelloCardsByListIdAverageDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_DESCRIPTION_DESC",
  TrelloCardsByListIdAverageEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_EMAILS_ASC",
  TrelloCardsByListIdAverageEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_EMAILS_DESC",
  TrelloCardsByListIdAverageIdAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_ID_ASC",
  TrelloCardsByListIdAverageIdDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_ID_DESC",
  TrelloCardsByListIdAverageIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_IS_TEMPLATE_ASC",
  TrelloCardsByListIdAverageIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_IS_TEMPLATE_DESC",
  TrelloCardsByListIdAverageLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LABELS_ASC",
  TrelloCardsByListIdAverageLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LABELS_DESC",
  TrelloCardsByListIdAverageListIdAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LIST_ID_ASC",
  TrelloCardsByListIdAverageListIdDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LIST_ID_DESC",
  TrelloCardsByListIdAverageLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdAverageLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdAverageMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_MEMBER_IDS_ASC",
  TrelloCardsByListIdAverageMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_MEMBER_IDS_DESC",
  TrelloCardsByListIdAverageNameAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_NAME_ASC",
  TrelloCardsByListIdAverageNameDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_NAME_DESC",
  TrelloCardsByListIdAverageShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_ID_ASC",
  TrelloCardsByListIdAverageShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_ID_DESC",
  TrelloCardsByListIdAverageShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdAverageShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdAverageShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_URL_ASC",
  TrelloCardsByListIdAverageShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_SHORT_URL_DESC",
  TrelloCardsByListIdAverageUrlAsc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_URL_ASC",
  TrelloCardsByListIdAverageUrlDesc = "TRELLO_CARDS_BY_LIST_ID_AVERAGE_URL_DESC",
  TrelloCardsByListIdCountAsc = "TRELLO_CARDS_BY_LIST_ID_COUNT_ASC",
  TrelloCardsByListIdCountDesc = "TRELLO_CARDS_BY_LIST_ID_COUNT_DESC",
  TrelloCardsByListIdDistinctCountAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_ATTACHMENTS_ASC",
  TrelloCardsByListIdDistinctCountAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_ATTACHMENTS_DESC",
  TrelloCardsByListIdDistinctCountBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_BADGES_ASC",
  TrelloCardsByListIdDistinctCountBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_BADGES_DESC",
  TrelloCardsByListIdDistinctCountBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_BOARD_ID_ASC",
  TrelloCardsByListIdDistinctCountBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_BOARD_ID_DESC",
  TrelloCardsByListIdDistinctCountCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdDistinctCountCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdDistinctCountCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_MOVED_ASC",
  TrelloCardsByListIdDistinctCountCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_MOVED_DESC",
  TrelloCardsByListIdDistinctCountCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_ROLE_ASC",
  TrelloCardsByListIdDistinctCountCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CARD_ROLE_DESC",
  TrelloCardsByListIdDistinctCountChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CHECKLISTS_ASC",
  TrelloCardsByListIdDistinctCountChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CHECKLISTS_DESC",
  TrelloCardsByListIdDistinctCountClosedAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CLOSED_ASC",
  TrelloCardsByListIdDistinctCountClosedDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_CLOSED_DESC",
  TrelloCardsByListIdDistinctCountCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COMMENTS_ASC",
  TrelloCardsByListIdDistinctCountCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COMMENTS_DESC",
  TrelloCardsByListIdDistinctCountCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COMPANY_NAME_ASC",
  TrelloCardsByListIdDistinctCountCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COMPANY_NAME_DESC",
  TrelloCardsByListIdDistinctCountCoverAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COVER_ASC",
  TrelloCardsByListIdDistinctCountCoverDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_COVER_DESC",
  TrelloCardsByListIdDistinctCountDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdDistinctCountDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdDistinctCountDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_DESCRIPTION_ASC",
  TrelloCardsByListIdDistinctCountDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_DESCRIPTION_DESC",
  TrelloCardsByListIdDistinctCountEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_EMAILS_ASC",
  TrelloCardsByListIdDistinctCountEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_EMAILS_DESC",
  TrelloCardsByListIdDistinctCountIdAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_ID_ASC",
  TrelloCardsByListIdDistinctCountIdDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_ID_DESC",
  TrelloCardsByListIdDistinctCountIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_IS_TEMPLATE_ASC",
  TrelloCardsByListIdDistinctCountIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_IS_TEMPLATE_DESC",
  TrelloCardsByListIdDistinctCountLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LABELS_ASC",
  TrelloCardsByListIdDistinctCountLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LABELS_DESC",
  TrelloCardsByListIdDistinctCountListIdAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LIST_ID_ASC",
  TrelloCardsByListIdDistinctCountListIdDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LIST_ID_DESC",
  TrelloCardsByListIdDistinctCountLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdDistinctCountLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdDistinctCountMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_MEMBER_IDS_ASC",
  TrelloCardsByListIdDistinctCountMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_MEMBER_IDS_DESC",
  TrelloCardsByListIdDistinctCountNameAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_NAME_ASC",
  TrelloCardsByListIdDistinctCountNameDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_NAME_DESC",
  TrelloCardsByListIdDistinctCountShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_ID_ASC",
  TrelloCardsByListIdDistinctCountShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_ID_DESC",
  TrelloCardsByListIdDistinctCountShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdDistinctCountShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdDistinctCountShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_URL_ASC",
  TrelloCardsByListIdDistinctCountShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_SHORT_URL_DESC",
  TrelloCardsByListIdDistinctCountUrlAsc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_URL_ASC",
  TrelloCardsByListIdDistinctCountUrlDesc = "TRELLO_CARDS_BY_LIST_ID_DISTINCT_COUNT_URL_DESC",
  TrelloCardsByListIdMaxAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_ATTACHMENTS_ASC",
  TrelloCardsByListIdMaxAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_ATTACHMENTS_DESC",
  TrelloCardsByListIdMaxBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_BADGES_ASC",
  TrelloCardsByListIdMaxBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_BADGES_DESC",
  TrelloCardsByListIdMaxBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_BOARD_ID_ASC",
  TrelloCardsByListIdMaxBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_BOARD_ID_DESC",
  TrelloCardsByListIdMaxCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdMaxCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdMaxCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_MOVED_ASC",
  TrelloCardsByListIdMaxCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_MOVED_DESC",
  TrelloCardsByListIdMaxCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_ROLE_ASC",
  TrelloCardsByListIdMaxCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_CARD_ROLE_DESC",
  TrelloCardsByListIdMaxChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_CHECKLISTS_ASC",
  TrelloCardsByListIdMaxChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_CHECKLISTS_DESC",
  TrelloCardsByListIdMaxClosedAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_CLOSED_ASC",
  TrelloCardsByListIdMaxClosedDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_CLOSED_DESC",
  TrelloCardsByListIdMaxCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_COMMENTS_ASC",
  TrelloCardsByListIdMaxCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_COMMENTS_DESC",
  TrelloCardsByListIdMaxCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_COMPANY_NAME_ASC",
  TrelloCardsByListIdMaxCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_COMPANY_NAME_DESC",
  TrelloCardsByListIdMaxCoverAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_COVER_ASC",
  TrelloCardsByListIdMaxCoverDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_COVER_DESC",
  TrelloCardsByListIdMaxDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdMaxDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdMaxDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_DESCRIPTION_ASC",
  TrelloCardsByListIdMaxDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_DESCRIPTION_DESC",
  TrelloCardsByListIdMaxEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_EMAILS_ASC",
  TrelloCardsByListIdMaxEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_EMAILS_DESC",
  TrelloCardsByListIdMaxIdAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_ID_ASC",
  TrelloCardsByListIdMaxIdDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_ID_DESC",
  TrelloCardsByListIdMaxIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_IS_TEMPLATE_ASC",
  TrelloCardsByListIdMaxIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_IS_TEMPLATE_DESC",
  TrelloCardsByListIdMaxLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_LABELS_ASC",
  TrelloCardsByListIdMaxLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_LABELS_DESC",
  TrelloCardsByListIdMaxListIdAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_LIST_ID_ASC",
  TrelloCardsByListIdMaxListIdDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_LIST_ID_DESC",
  TrelloCardsByListIdMaxLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdMaxLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdMaxMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_MEMBER_IDS_ASC",
  TrelloCardsByListIdMaxMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_MEMBER_IDS_DESC",
  TrelloCardsByListIdMaxNameAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_NAME_ASC",
  TrelloCardsByListIdMaxNameDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_NAME_DESC",
  TrelloCardsByListIdMaxShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_ID_ASC",
  TrelloCardsByListIdMaxShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_ID_DESC",
  TrelloCardsByListIdMaxShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdMaxShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdMaxShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_URL_ASC",
  TrelloCardsByListIdMaxShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_SHORT_URL_DESC",
  TrelloCardsByListIdMaxUrlAsc = "TRELLO_CARDS_BY_LIST_ID_MAX_URL_ASC",
  TrelloCardsByListIdMaxUrlDesc = "TRELLO_CARDS_BY_LIST_ID_MAX_URL_DESC",
  TrelloCardsByListIdMinAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_ATTACHMENTS_ASC",
  TrelloCardsByListIdMinAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_ATTACHMENTS_DESC",
  TrelloCardsByListIdMinBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_BADGES_ASC",
  TrelloCardsByListIdMinBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_BADGES_DESC",
  TrelloCardsByListIdMinBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_BOARD_ID_ASC",
  TrelloCardsByListIdMinBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_BOARD_ID_DESC",
  TrelloCardsByListIdMinCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdMinCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdMinCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_MOVED_ASC",
  TrelloCardsByListIdMinCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_MOVED_DESC",
  TrelloCardsByListIdMinCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_ROLE_ASC",
  TrelloCardsByListIdMinCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_CARD_ROLE_DESC",
  TrelloCardsByListIdMinChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_CHECKLISTS_ASC",
  TrelloCardsByListIdMinChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_CHECKLISTS_DESC",
  TrelloCardsByListIdMinClosedAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_CLOSED_ASC",
  TrelloCardsByListIdMinClosedDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_CLOSED_DESC",
  TrelloCardsByListIdMinCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_COMMENTS_ASC",
  TrelloCardsByListIdMinCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_COMMENTS_DESC",
  TrelloCardsByListIdMinCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_COMPANY_NAME_ASC",
  TrelloCardsByListIdMinCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_COMPANY_NAME_DESC",
  TrelloCardsByListIdMinCoverAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_COVER_ASC",
  TrelloCardsByListIdMinCoverDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_COVER_DESC",
  TrelloCardsByListIdMinDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdMinDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdMinDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_DESCRIPTION_ASC",
  TrelloCardsByListIdMinDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_DESCRIPTION_DESC",
  TrelloCardsByListIdMinEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_EMAILS_ASC",
  TrelloCardsByListIdMinEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_EMAILS_DESC",
  TrelloCardsByListIdMinIdAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_ID_ASC",
  TrelloCardsByListIdMinIdDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_ID_DESC",
  TrelloCardsByListIdMinIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_IS_TEMPLATE_ASC",
  TrelloCardsByListIdMinIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_IS_TEMPLATE_DESC",
  TrelloCardsByListIdMinLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_LABELS_ASC",
  TrelloCardsByListIdMinLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_LABELS_DESC",
  TrelloCardsByListIdMinListIdAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_LIST_ID_ASC",
  TrelloCardsByListIdMinListIdDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_LIST_ID_DESC",
  TrelloCardsByListIdMinLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdMinLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdMinMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_MEMBER_IDS_ASC",
  TrelloCardsByListIdMinMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_MEMBER_IDS_DESC",
  TrelloCardsByListIdMinNameAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_NAME_ASC",
  TrelloCardsByListIdMinNameDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_NAME_DESC",
  TrelloCardsByListIdMinShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_ID_ASC",
  TrelloCardsByListIdMinShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_ID_DESC",
  TrelloCardsByListIdMinShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdMinShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdMinShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_URL_ASC",
  TrelloCardsByListIdMinShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_SHORT_URL_DESC",
  TrelloCardsByListIdMinUrlAsc = "TRELLO_CARDS_BY_LIST_ID_MIN_URL_ASC",
  TrelloCardsByListIdMinUrlDesc = "TRELLO_CARDS_BY_LIST_ID_MIN_URL_DESC",
  TrelloCardsByListIdStddevPopulationAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_ATTACHMENTS_ASC",
  TrelloCardsByListIdStddevPopulationAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_ATTACHMENTS_DESC",
  TrelloCardsByListIdStddevPopulationBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_BADGES_ASC",
  TrelloCardsByListIdStddevPopulationBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_BADGES_DESC",
  TrelloCardsByListIdStddevPopulationBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_BOARD_ID_ASC",
  TrelloCardsByListIdStddevPopulationBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_BOARD_ID_DESC",
  TrelloCardsByListIdStddevPopulationCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdStddevPopulationCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdStddevPopulationCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_MOVED_ASC",
  TrelloCardsByListIdStddevPopulationCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_MOVED_DESC",
  TrelloCardsByListIdStddevPopulationCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_ROLE_ASC",
  TrelloCardsByListIdStddevPopulationCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CARD_ROLE_DESC",
  TrelloCardsByListIdStddevPopulationChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CHECKLISTS_ASC",
  TrelloCardsByListIdStddevPopulationChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CHECKLISTS_DESC",
  TrelloCardsByListIdStddevPopulationClosedAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CLOSED_ASC",
  TrelloCardsByListIdStddevPopulationClosedDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_CLOSED_DESC",
  TrelloCardsByListIdStddevPopulationCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COMMENTS_ASC",
  TrelloCardsByListIdStddevPopulationCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COMMENTS_DESC",
  TrelloCardsByListIdStddevPopulationCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COMPANY_NAME_ASC",
  TrelloCardsByListIdStddevPopulationCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COMPANY_NAME_DESC",
  TrelloCardsByListIdStddevPopulationCoverAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COVER_ASC",
  TrelloCardsByListIdStddevPopulationCoverDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_COVER_DESC",
  TrelloCardsByListIdStddevPopulationDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdStddevPopulationDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdStddevPopulationDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_DESCRIPTION_ASC",
  TrelloCardsByListIdStddevPopulationDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_DESCRIPTION_DESC",
  TrelloCardsByListIdStddevPopulationEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_EMAILS_ASC",
  TrelloCardsByListIdStddevPopulationEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_EMAILS_DESC",
  TrelloCardsByListIdStddevPopulationIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_ID_ASC",
  TrelloCardsByListIdStddevPopulationIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_ID_DESC",
  TrelloCardsByListIdStddevPopulationIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_IS_TEMPLATE_ASC",
  TrelloCardsByListIdStddevPopulationIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_IS_TEMPLATE_DESC",
  TrelloCardsByListIdStddevPopulationLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LABELS_ASC",
  TrelloCardsByListIdStddevPopulationLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LABELS_DESC",
  TrelloCardsByListIdStddevPopulationListIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LIST_ID_ASC",
  TrelloCardsByListIdStddevPopulationListIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LIST_ID_DESC",
  TrelloCardsByListIdStddevPopulationLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdStddevPopulationLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdStddevPopulationMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_MEMBER_IDS_ASC",
  TrelloCardsByListIdStddevPopulationMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_MEMBER_IDS_DESC",
  TrelloCardsByListIdStddevPopulationNameAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_NAME_ASC",
  TrelloCardsByListIdStddevPopulationNameDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_NAME_DESC",
  TrelloCardsByListIdStddevPopulationShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_ID_ASC",
  TrelloCardsByListIdStddevPopulationShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_ID_DESC",
  TrelloCardsByListIdStddevPopulationShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdStddevPopulationShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdStddevPopulationShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_URL_ASC",
  TrelloCardsByListIdStddevPopulationShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_SHORT_URL_DESC",
  TrelloCardsByListIdStddevPopulationUrlAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_URL_ASC",
  TrelloCardsByListIdStddevPopulationUrlDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_POPULATION_URL_DESC",
  TrelloCardsByListIdStddevSampleAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_ATTACHMENTS_ASC",
  TrelloCardsByListIdStddevSampleAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_ATTACHMENTS_DESC",
  TrelloCardsByListIdStddevSampleBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_BADGES_ASC",
  TrelloCardsByListIdStddevSampleBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_BADGES_DESC",
  TrelloCardsByListIdStddevSampleBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_BOARD_ID_ASC",
  TrelloCardsByListIdStddevSampleBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_BOARD_ID_DESC",
  TrelloCardsByListIdStddevSampleCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdStddevSampleCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdStddevSampleCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_MOVED_ASC",
  TrelloCardsByListIdStddevSampleCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_MOVED_DESC",
  TrelloCardsByListIdStddevSampleCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_ROLE_ASC",
  TrelloCardsByListIdStddevSampleCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CARD_ROLE_DESC",
  TrelloCardsByListIdStddevSampleChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CHECKLISTS_ASC",
  TrelloCardsByListIdStddevSampleChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CHECKLISTS_DESC",
  TrelloCardsByListIdStddevSampleClosedAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CLOSED_ASC",
  TrelloCardsByListIdStddevSampleClosedDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_CLOSED_DESC",
  TrelloCardsByListIdStddevSampleCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COMMENTS_ASC",
  TrelloCardsByListIdStddevSampleCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COMMENTS_DESC",
  TrelloCardsByListIdStddevSampleCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC",
  TrelloCardsByListIdStddevSampleCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC",
  TrelloCardsByListIdStddevSampleCoverAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COVER_ASC",
  TrelloCardsByListIdStddevSampleCoverDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_COVER_DESC",
  TrelloCardsByListIdStddevSampleDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdStddevSampleDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdStddevSampleDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_DESCRIPTION_ASC",
  TrelloCardsByListIdStddevSampleDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_DESCRIPTION_DESC",
  TrelloCardsByListIdStddevSampleEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_EMAILS_ASC",
  TrelloCardsByListIdStddevSampleEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_EMAILS_DESC",
  TrelloCardsByListIdStddevSampleIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_ID_ASC",
  TrelloCardsByListIdStddevSampleIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_ID_DESC",
  TrelloCardsByListIdStddevSampleIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_IS_TEMPLATE_ASC",
  TrelloCardsByListIdStddevSampleIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_IS_TEMPLATE_DESC",
  TrelloCardsByListIdStddevSampleLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LABELS_ASC",
  TrelloCardsByListIdStddevSampleLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LABELS_DESC",
  TrelloCardsByListIdStddevSampleListIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LIST_ID_ASC",
  TrelloCardsByListIdStddevSampleListIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LIST_ID_DESC",
  TrelloCardsByListIdStddevSampleLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdStddevSampleLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdStddevSampleMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_MEMBER_IDS_ASC",
  TrelloCardsByListIdStddevSampleMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_MEMBER_IDS_DESC",
  TrelloCardsByListIdStddevSampleNameAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_NAME_ASC",
  TrelloCardsByListIdStddevSampleNameDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_NAME_DESC",
  TrelloCardsByListIdStddevSampleShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_ID_ASC",
  TrelloCardsByListIdStddevSampleShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_ID_DESC",
  TrelloCardsByListIdStddevSampleShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdStddevSampleShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdStddevSampleShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_URL_ASC",
  TrelloCardsByListIdStddevSampleShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_SHORT_URL_DESC",
  TrelloCardsByListIdStddevSampleUrlAsc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_URL_ASC",
  TrelloCardsByListIdStddevSampleUrlDesc = "TRELLO_CARDS_BY_LIST_ID_STDDEV_SAMPLE_URL_DESC",
  TrelloCardsByListIdSumAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_ATTACHMENTS_ASC",
  TrelloCardsByListIdSumAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_ATTACHMENTS_DESC",
  TrelloCardsByListIdSumBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_BADGES_ASC",
  TrelloCardsByListIdSumBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_BADGES_DESC",
  TrelloCardsByListIdSumBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_BOARD_ID_ASC",
  TrelloCardsByListIdSumBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_BOARD_ID_DESC",
  TrelloCardsByListIdSumCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdSumCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdSumCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_MOVED_ASC",
  TrelloCardsByListIdSumCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_MOVED_DESC",
  TrelloCardsByListIdSumCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_ROLE_ASC",
  TrelloCardsByListIdSumCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_CARD_ROLE_DESC",
  TrelloCardsByListIdSumChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_CHECKLISTS_ASC",
  TrelloCardsByListIdSumChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_CHECKLISTS_DESC",
  TrelloCardsByListIdSumClosedAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_CLOSED_ASC",
  TrelloCardsByListIdSumClosedDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_CLOSED_DESC",
  TrelloCardsByListIdSumCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_COMMENTS_ASC",
  TrelloCardsByListIdSumCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_COMMENTS_DESC",
  TrelloCardsByListIdSumCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_COMPANY_NAME_ASC",
  TrelloCardsByListIdSumCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_COMPANY_NAME_DESC",
  TrelloCardsByListIdSumCoverAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_COVER_ASC",
  TrelloCardsByListIdSumCoverDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_COVER_DESC",
  TrelloCardsByListIdSumDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdSumDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdSumDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_DESCRIPTION_ASC",
  TrelloCardsByListIdSumDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_DESCRIPTION_DESC",
  TrelloCardsByListIdSumEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_EMAILS_ASC",
  TrelloCardsByListIdSumEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_EMAILS_DESC",
  TrelloCardsByListIdSumIdAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_ID_ASC",
  TrelloCardsByListIdSumIdDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_ID_DESC",
  TrelloCardsByListIdSumIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_IS_TEMPLATE_ASC",
  TrelloCardsByListIdSumIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_IS_TEMPLATE_DESC",
  TrelloCardsByListIdSumLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_LABELS_ASC",
  TrelloCardsByListIdSumLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_LABELS_DESC",
  TrelloCardsByListIdSumListIdAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_LIST_ID_ASC",
  TrelloCardsByListIdSumListIdDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_LIST_ID_DESC",
  TrelloCardsByListIdSumLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdSumLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdSumMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_MEMBER_IDS_ASC",
  TrelloCardsByListIdSumMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_MEMBER_IDS_DESC",
  TrelloCardsByListIdSumNameAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_NAME_ASC",
  TrelloCardsByListIdSumNameDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_NAME_DESC",
  TrelloCardsByListIdSumShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_ID_ASC",
  TrelloCardsByListIdSumShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_ID_DESC",
  TrelloCardsByListIdSumShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdSumShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdSumShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_URL_ASC",
  TrelloCardsByListIdSumShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_SHORT_URL_DESC",
  TrelloCardsByListIdSumUrlAsc = "TRELLO_CARDS_BY_LIST_ID_SUM_URL_ASC",
  TrelloCardsByListIdSumUrlDesc = "TRELLO_CARDS_BY_LIST_ID_SUM_URL_DESC",
  TrelloCardsByListIdVariancePopulationAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_ATTACHMENTS_ASC",
  TrelloCardsByListIdVariancePopulationAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_ATTACHMENTS_DESC",
  TrelloCardsByListIdVariancePopulationBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_BADGES_ASC",
  TrelloCardsByListIdVariancePopulationBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_BADGES_DESC",
  TrelloCardsByListIdVariancePopulationBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_BOARD_ID_ASC",
  TrelloCardsByListIdVariancePopulationBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_BOARD_ID_DESC",
  TrelloCardsByListIdVariancePopulationCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdVariancePopulationCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdVariancePopulationCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_MOVED_ASC",
  TrelloCardsByListIdVariancePopulationCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_MOVED_DESC",
  TrelloCardsByListIdVariancePopulationCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_ROLE_ASC",
  TrelloCardsByListIdVariancePopulationCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CARD_ROLE_DESC",
  TrelloCardsByListIdVariancePopulationChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CHECKLISTS_ASC",
  TrelloCardsByListIdVariancePopulationChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CHECKLISTS_DESC",
  TrelloCardsByListIdVariancePopulationClosedAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CLOSED_ASC",
  TrelloCardsByListIdVariancePopulationClosedDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_CLOSED_DESC",
  TrelloCardsByListIdVariancePopulationCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COMMENTS_ASC",
  TrelloCardsByListIdVariancePopulationCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COMMENTS_DESC",
  TrelloCardsByListIdVariancePopulationCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC",
  TrelloCardsByListIdVariancePopulationCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC",
  TrelloCardsByListIdVariancePopulationCoverAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COVER_ASC",
  TrelloCardsByListIdVariancePopulationCoverDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_COVER_DESC",
  TrelloCardsByListIdVariancePopulationDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdVariancePopulationDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdVariancePopulationDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_DESCRIPTION_ASC",
  TrelloCardsByListIdVariancePopulationDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_DESCRIPTION_DESC",
  TrelloCardsByListIdVariancePopulationEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_EMAILS_ASC",
  TrelloCardsByListIdVariancePopulationEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_EMAILS_DESC",
  TrelloCardsByListIdVariancePopulationIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_ID_ASC",
  TrelloCardsByListIdVariancePopulationIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_ID_DESC",
  TrelloCardsByListIdVariancePopulationIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_IS_TEMPLATE_ASC",
  TrelloCardsByListIdVariancePopulationIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_IS_TEMPLATE_DESC",
  TrelloCardsByListIdVariancePopulationLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LABELS_ASC",
  TrelloCardsByListIdVariancePopulationLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LABELS_DESC",
  TrelloCardsByListIdVariancePopulationListIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LIST_ID_ASC",
  TrelloCardsByListIdVariancePopulationListIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LIST_ID_DESC",
  TrelloCardsByListIdVariancePopulationLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdVariancePopulationLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdVariancePopulationMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_MEMBER_IDS_ASC",
  TrelloCardsByListIdVariancePopulationMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_MEMBER_IDS_DESC",
  TrelloCardsByListIdVariancePopulationNameAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_NAME_ASC",
  TrelloCardsByListIdVariancePopulationNameDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_NAME_DESC",
  TrelloCardsByListIdVariancePopulationShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_ID_ASC",
  TrelloCardsByListIdVariancePopulationShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_ID_DESC",
  TrelloCardsByListIdVariancePopulationShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdVariancePopulationShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdVariancePopulationShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_URL_ASC",
  TrelloCardsByListIdVariancePopulationShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_SHORT_URL_DESC",
  TrelloCardsByListIdVariancePopulationUrlAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_URL_ASC",
  TrelloCardsByListIdVariancePopulationUrlDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_POPULATION_URL_DESC",
  TrelloCardsByListIdVarianceSampleAttachmentsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_ATTACHMENTS_ASC",
  TrelloCardsByListIdVarianceSampleAttachmentsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_ATTACHMENTS_DESC",
  TrelloCardsByListIdVarianceSampleBadgesAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_BADGES_ASC",
  TrelloCardsByListIdVarianceSampleBadgesDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_BADGES_DESC",
  TrelloCardsByListIdVarianceSampleBoardIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_BOARD_ID_ASC",
  TrelloCardsByListIdVarianceSampleBoardIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_BOARD_ID_DESC",
  TrelloCardsByListIdVarianceSampleCardCreatedDateAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_CREATED_DATE_ASC",
  TrelloCardsByListIdVarianceSampleCardCreatedDateDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_CREATED_DATE_DESC",
  TrelloCardsByListIdVarianceSampleCardMovedAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_MOVED_ASC",
  TrelloCardsByListIdVarianceSampleCardMovedDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_MOVED_DESC",
  TrelloCardsByListIdVarianceSampleCardRoleAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_ROLE_ASC",
  TrelloCardsByListIdVarianceSampleCardRoleDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CARD_ROLE_DESC",
  TrelloCardsByListIdVarianceSampleChecklistsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CHECKLISTS_ASC",
  TrelloCardsByListIdVarianceSampleChecklistsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CHECKLISTS_DESC",
  TrelloCardsByListIdVarianceSampleClosedAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CLOSED_ASC",
  TrelloCardsByListIdVarianceSampleClosedDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_CLOSED_DESC",
  TrelloCardsByListIdVarianceSampleCommentsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COMMENTS_ASC",
  TrelloCardsByListIdVarianceSampleCommentsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COMMENTS_DESC",
  TrelloCardsByListIdVarianceSampleCompanyNameAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC",
  TrelloCardsByListIdVarianceSampleCompanyNameDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC",
  TrelloCardsByListIdVarianceSampleCoverAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COVER_ASC",
  TrelloCardsByListIdVarianceSampleCoverDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_COVER_DESC",
  TrelloCardsByListIdVarianceSampleDateLastActivityAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_ASC",
  TrelloCardsByListIdVarianceSampleDateLastActivityDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_DATE_LAST_ACTIVITY_DESC",
  TrelloCardsByListIdVarianceSampleDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  TrelloCardsByListIdVarianceSampleDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  TrelloCardsByListIdVarianceSampleEmailsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_EMAILS_ASC",
  TrelloCardsByListIdVarianceSampleEmailsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_EMAILS_DESC",
  TrelloCardsByListIdVarianceSampleIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_ID_ASC",
  TrelloCardsByListIdVarianceSampleIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_ID_DESC",
  TrelloCardsByListIdVarianceSampleIsTemplateAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_IS_TEMPLATE_ASC",
  TrelloCardsByListIdVarianceSampleIsTemplateDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_IS_TEMPLATE_DESC",
  TrelloCardsByListIdVarianceSampleLabelsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LABELS_ASC",
  TrelloCardsByListIdVarianceSampleLabelsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LABELS_DESC",
  TrelloCardsByListIdVarianceSampleListIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LIST_ID_ASC",
  TrelloCardsByListIdVarianceSampleListIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LIST_ID_DESC",
  TrelloCardsByListIdVarianceSampleLlmDescriptionAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LLM_DESCRIPTION_ASC",
  TrelloCardsByListIdVarianceSampleLlmDescriptionDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_LLM_DESCRIPTION_DESC",
  TrelloCardsByListIdVarianceSampleMemberIdsAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_MEMBER_IDS_ASC",
  TrelloCardsByListIdVarianceSampleMemberIdsDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_MEMBER_IDS_DESC",
  TrelloCardsByListIdVarianceSampleNameAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_NAME_ASC",
  TrelloCardsByListIdVarianceSampleNameDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_NAME_DESC",
  TrelloCardsByListIdVarianceSampleShortIdAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_ID_ASC",
  TrelloCardsByListIdVarianceSampleShortIdDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_ID_DESC",
  TrelloCardsByListIdVarianceSampleShortLinkHashAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_LINK_HASH_ASC",
  TrelloCardsByListIdVarianceSampleShortLinkHashDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_LINK_HASH_DESC",
  TrelloCardsByListIdVarianceSampleShortUrlAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_URL_ASC",
  TrelloCardsByListIdVarianceSampleShortUrlDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_SHORT_URL_DESC",
  TrelloCardsByListIdVarianceSampleUrlAsc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_URL_ASC",
  TrelloCardsByListIdVarianceSampleUrlDesc = "TRELLO_CARDS_BY_LIST_ID_VARIANCE_SAMPLE_URL_DESC",
}

export type TrelloMember = Node & {
  __typename?: "TrelloMember";
  bio: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  fullName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `LgMember`. */
  lgMembers: LgMembersConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  username: Scalars["String"]["output"];
};

export type TrelloMemberLgMembersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy>>;
};

export type TrelloMemberAggregates = {
  __typename?: "TrelloMemberAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrelloMemberDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `TrelloMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TrelloMemberCondition = {
  /** Checks for equality with the object’s `bio` field. */
  bio?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrelloMemberDistinctCountAggregates = {
  __typename?: "TrelloMemberDistinctCountAggregates";
  /** Distinct count of bio across the matching connection */
  bio?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fullName across the matching connection */
  fullName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `TrelloMember` object types. All fields are combined with a logical ‘and.’ */
export type TrelloMemberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrelloMemberFilter>>;
  /** Filter by the object’s `bio` field. */
  bio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lgMembers` relation. */
  lgMembers?: InputMaybe<TrelloMemberToManyLgMemberFilter>;
  /** Some related `lgMembers` exist. */
  lgMembersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<TrelloMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrelloMemberFilter>>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TrelloMember` for usage during aggregation. */
export enum TrelloMemberGroupBy {
  Bio = "BIO",
  Email = "EMAIL",
  FullName = "FULL_NAME",
  Username = "USERNAME",
}

/** Conditions for `TrelloMember` aggregates. */
export type TrelloMemberHavingInput = {
  AND?: InputMaybe<Array<TrelloMemberHavingInput>>;
  OR?: InputMaybe<Array<TrelloMemberHavingInput>>;
};

/** A filter to be used against many `LgMember` object types. All fields are combined with a logical ‘and.’ */
export type TrelloMemberToManyLgMemberFilter = {
  /** Aggregates across related `LgMember` match the filter criteria. */
  aggregates?: InputMaybe<LgMemberAggregatesFilter>;
  /** Every related `LgMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LgMemberFilter>;
  /** No related `LgMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LgMemberFilter>;
  /** Some related `LgMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LgMemberFilter>;
};

/** A connection to a list of `TrelloMember` values. */
export type TrelloMembersConnection = {
  __typename?: "TrelloMembersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrelloMemberAggregates>;
  /** A list of edges which contains the `TrelloMember` and cursor to aid in pagination. */
  edges: Array<TrelloMembersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrelloMemberAggregates>>;
  /** A list of `TrelloMember` objects. */
  nodes: Array<TrelloMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TrelloMember` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `TrelloMember` values. */
export type TrelloMembersConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrelloMemberGroupBy>;
  having?: InputMaybe<TrelloMemberHavingInput>;
};

/** A `TrelloMember` edge in the connection. */
export type TrelloMembersEdge = {
  __typename?: "TrelloMembersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `TrelloMember` at the end of the edge. */
  node: TrelloMember;
};

/** Methods to use when ordering `TrelloMember`. */
export enum TrelloMembersOrderBy {
  BioAsc = "BIO_ASC",
  BioDesc = "BIO_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FullNameAsc = "FULL_NAME_ASC",
  FullNameDesc = "FULL_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LgMembersAverageAvatarAsc = "LG_MEMBERS_AVERAGE_AVATAR_ASC",
  LgMembersAverageAvatarDesc = "LG_MEMBERS_AVERAGE_AVATAR_DESC",
  LgMembersAverageCreatedUtcAsc = "LG_MEMBERS_AVERAGE_CREATED_UTC_ASC",
  LgMembersAverageCreatedUtcDesc = "LG_MEMBERS_AVERAGE_CREATED_UTC_DESC",
  LgMembersAverageEmailAsc = "LG_MEMBERS_AVERAGE_EMAIL_ASC",
  LgMembersAverageEmailDesc = "LG_MEMBERS_AVERAGE_EMAIL_DESC",
  LgMembersAverageFirstNameAsc = "LG_MEMBERS_AVERAGE_FIRST_NAME_ASC",
  LgMembersAverageFirstNameDesc = "LG_MEMBERS_AVERAGE_FIRST_NAME_DESC",
  LgMembersAverageIdAsc = "LG_MEMBERS_AVERAGE_ID_ASC",
  LgMembersAverageIdDesc = "LG_MEMBERS_AVERAGE_ID_DESC",
  LgMembersAverageInitialsAsc = "LG_MEMBERS_AVERAGE_INITIALS_ASC",
  LgMembersAverageInitialsDesc = "LG_MEMBERS_AVERAGE_INITIALS_DESC",
  LgMembersAverageIsInvestorAsc = "LG_MEMBERS_AVERAGE_IS_INVESTOR_ASC",
  LgMembersAverageIsInvestorDesc = "LG_MEMBERS_AVERAGE_IS_INVESTOR_DESC",
  LgMembersAverageLastAgendaUtcAsc = "LG_MEMBERS_AVERAGE_LAST_AGENDA_UTC_ASC",
  LgMembersAverageLastAgendaUtcDesc = "LG_MEMBERS_AVERAGE_LAST_AGENDA_UTC_DESC",
  LgMembersAverageLastModifiedUtcAsc = "LG_MEMBERS_AVERAGE_LAST_MODIFIED_UTC_ASC",
  LgMembersAverageLastModifiedUtcDesc = "LG_MEMBERS_AVERAGE_LAST_MODIFIED_UTC_DESC",
  LgMembersAverageLastNameAsc = "LG_MEMBERS_AVERAGE_LAST_NAME_ASC",
  LgMembersAverageLastNameDesc = "LG_MEMBERS_AVERAGE_LAST_NAME_DESC",
  LgMembersAverageSectorsAsc = "LG_MEMBERS_AVERAGE_SECTORS_ASC",
  LgMembersAverageSectorsDesc = "LG_MEMBERS_AVERAGE_SECTORS_DESC",
  LgMembersAverageSettingsAsc = "LG_MEMBERS_AVERAGE_SETTINGS_ASC",
  LgMembersAverageSettingsDesc = "LG_MEMBERS_AVERAGE_SETTINGS_DESC",
  LgMembersAverageTitleAsc = "LG_MEMBERS_AVERAGE_TITLE_ASC",
  LgMembersAverageTitleDesc = "LG_MEMBERS_AVERAGE_TITLE_DESC",
  LgMembersAverageTrelloMemberIdAsc = "LG_MEMBERS_AVERAGE_TRELLO_MEMBER_ID_ASC",
  LgMembersAverageTrelloMemberIdDesc = "LG_MEMBERS_AVERAGE_TRELLO_MEMBER_ID_DESC",
  LgMembersAverageUsernameAsc = "LG_MEMBERS_AVERAGE_USERNAME_ASC",
  LgMembersAverageUsernameDesc = "LG_MEMBERS_AVERAGE_USERNAME_DESC",
  LgMembersAverageUserIdAsc = "LG_MEMBERS_AVERAGE_USER_ID_ASC",
  LgMembersAverageUserIdDesc = "LG_MEMBERS_AVERAGE_USER_ID_DESC",
  LgMembersCountAsc = "LG_MEMBERS_COUNT_ASC",
  LgMembersCountDesc = "LG_MEMBERS_COUNT_DESC",
  LgMembersDistinctCountAvatarAsc = "LG_MEMBERS_DISTINCT_COUNT_AVATAR_ASC",
  LgMembersDistinctCountAvatarDesc = "LG_MEMBERS_DISTINCT_COUNT_AVATAR_DESC",
  LgMembersDistinctCountCreatedUtcAsc = "LG_MEMBERS_DISTINCT_COUNT_CREATED_UTC_ASC",
  LgMembersDistinctCountCreatedUtcDesc = "LG_MEMBERS_DISTINCT_COUNT_CREATED_UTC_DESC",
  LgMembersDistinctCountEmailAsc = "LG_MEMBERS_DISTINCT_COUNT_EMAIL_ASC",
  LgMembersDistinctCountEmailDesc = "LG_MEMBERS_DISTINCT_COUNT_EMAIL_DESC",
  LgMembersDistinctCountFirstNameAsc = "LG_MEMBERS_DISTINCT_COUNT_FIRST_NAME_ASC",
  LgMembersDistinctCountFirstNameDesc = "LG_MEMBERS_DISTINCT_COUNT_FIRST_NAME_DESC",
  LgMembersDistinctCountIdAsc = "LG_MEMBERS_DISTINCT_COUNT_ID_ASC",
  LgMembersDistinctCountIdDesc = "LG_MEMBERS_DISTINCT_COUNT_ID_DESC",
  LgMembersDistinctCountInitialsAsc = "LG_MEMBERS_DISTINCT_COUNT_INITIALS_ASC",
  LgMembersDistinctCountInitialsDesc = "LG_MEMBERS_DISTINCT_COUNT_INITIALS_DESC",
  LgMembersDistinctCountIsInvestorAsc = "LG_MEMBERS_DISTINCT_COUNT_IS_INVESTOR_ASC",
  LgMembersDistinctCountIsInvestorDesc = "LG_MEMBERS_DISTINCT_COUNT_IS_INVESTOR_DESC",
  LgMembersDistinctCountLastAgendaUtcAsc = "LG_MEMBERS_DISTINCT_COUNT_LAST_AGENDA_UTC_ASC",
  LgMembersDistinctCountLastAgendaUtcDesc = "LG_MEMBERS_DISTINCT_COUNT_LAST_AGENDA_UTC_DESC",
  LgMembersDistinctCountLastModifiedUtcAsc = "LG_MEMBERS_DISTINCT_COUNT_LAST_MODIFIED_UTC_ASC",
  LgMembersDistinctCountLastModifiedUtcDesc = "LG_MEMBERS_DISTINCT_COUNT_LAST_MODIFIED_UTC_DESC",
  LgMembersDistinctCountLastNameAsc = "LG_MEMBERS_DISTINCT_COUNT_LAST_NAME_ASC",
  LgMembersDistinctCountLastNameDesc = "LG_MEMBERS_DISTINCT_COUNT_LAST_NAME_DESC",
  LgMembersDistinctCountSectorsAsc = "LG_MEMBERS_DISTINCT_COUNT_SECTORS_ASC",
  LgMembersDistinctCountSectorsDesc = "LG_MEMBERS_DISTINCT_COUNT_SECTORS_DESC",
  LgMembersDistinctCountSettingsAsc = "LG_MEMBERS_DISTINCT_COUNT_SETTINGS_ASC",
  LgMembersDistinctCountSettingsDesc = "LG_MEMBERS_DISTINCT_COUNT_SETTINGS_DESC",
  LgMembersDistinctCountTitleAsc = "LG_MEMBERS_DISTINCT_COUNT_TITLE_ASC",
  LgMembersDistinctCountTitleDesc = "LG_MEMBERS_DISTINCT_COUNT_TITLE_DESC",
  LgMembersDistinctCountTrelloMemberIdAsc = "LG_MEMBERS_DISTINCT_COUNT_TRELLO_MEMBER_ID_ASC",
  LgMembersDistinctCountTrelloMemberIdDesc = "LG_MEMBERS_DISTINCT_COUNT_TRELLO_MEMBER_ID_DESC",
  LgMembersDistinctCountUsernameAsc = "LG_MEMBERS_DISTINCT_COUNT_USERNAME_ASC",
  LgMembersDistinctCountUsernameDesc = "LG_MEMBERS_DISTINCT_COUNT_USERNAME_DESC",
  LgMembersDistinctCountUserIdAsc = "LG_MEMBERS_DISTINCT_COUNT_USER_ID_ASC",
  LgMembersDistinctCountUserIdDesc = "LG_MEMBERS_DISTINCT_COUNT_USER_ID_DESC",
  LgMembersMaxAvatarAsc = "LG_MEMBERS_MAX_AVATAR_ASC",
  LgMembersMaxAvatarDesc = "LG_MEMBERS_MAX_AVATAR_DESC",
  LgMembersMaxCreatedUtcAsc = "LG_MEMBERS_MAX_CREATED_UTC_ASC",
  LgMembersMaxCreatedUtcDesc = "LG_MEMBERS_MAX_CREATED_UTC_DESC",
  LgMembersMaxEmailAsc = "LG_MEMBERS_MAX_EMAIL_ASC",
  LgMembersMaxEmailDesc = "LG_MEMBERS_MAX_EMAIL_DESC",
  LgMembersMaxFirstNameAsc = "LG_MEMBERS_MAX_FIRST_NAME_ASC",
  LgMembersMaxFirstNameDesc = "LG_MEMBERS_MAX_FIRST_NAME_DESC",
  LgMembersMaxIdAsc = "LG_MEMBERS_MAX_ID_ASC",
  LgMembersMaxIdDesc = "LG_MEMBERS_MAX_ID_DESC",
  LgMembersMaxInitialsAsc = "LG_MEMBERS_MAX_INITIALS_ASC",
  LgMembersMaxInitialsDesc = "LG_MEMBERS_MAX_INITIALS_DESC",
  LgMembersMaxIsInvestorAsc = "LG_MEMBERS_MAX_IS_INVESTOR_ASC",
  LgMembersMaxIsInvestorDesc = "LG_MEMBERS_MAX_IS_INVESTOR_DESC",
  LgMembersMaxLastAgendaUtcAsc = "LG_MEMBERS_MAX_LAST_AGENDA_UTC_ASC",
  LgMembersMaxLastAgendaUtcDesc = "LG_MEMBERS_MAX_LAST_AGENDA_UTC_DESC",
  LgMembersMaxLastModifiedUtcAsc = "LG_MEMBERS_MAX_LAST_MODIFIED_UTC_ASC",
  LgMembersMaxLastModifiedUtcDesc = "LG_MEMBERS_MAX_LAST_MODIFIED_UTC_DESC",
  LgMembersMaxLastNameAsc = "LG_MEMBERS_MAX_LAST_NAME_ASC",
  LgMembersMaxLastNameDesc = "LG_MEMBERS_MAX_LAST_NAME_DESC",
  LgMembersMaxSectorsAsc = "LG_MEMBERS_MAX_SECTORS_ASC",
  LgMembersMaxSectorsDesc = "LG_MEMBERS_MAX_SECTORS_DESC",
  LgMembersMaxSettingsAsc = "LG_MEMBERS_MAX_SETTINGS_ASC",
  LgMembersMaxSettingsDesc = "LG_MEMBERS_MAX_SETTINGS_DESC",
  LgMembersMaxTitleAsc = "LG_MEMBERS_MAX_TITLE_ASC",
  LgMembersMaxTitleDesc = "LG_MEMBERS_MAX_TITLE_DESC",
  LgMembersMaxTrelloMemberIdAsc = "LG_MEMBERS_MAX_TRELLO_MEMBER_ID_ASC",
  LgMembersMaxTrelloMemberIdDesc = "LG_MEMBERS_MAX_TRELLO_MEMBER_ID_DESC",
  LgMembersMaxUsernameAsc = "LG_MEMBERS_MAX_USERNAME_ASC",
  LgMembersMaxUsernameDesc = "LG_MEMBERS_MAX_USERNAME_DESC",
  LgMembersMaxUserIdAsc = "LG_MEMBERS_MAX_USER_ID_ASC",
  LgMembersMaxUserIdDesc = "LG_MEMBERS_MAX_USER_ID_DESC",
  LgMembersMinAvatarAsc = "LG_MEMBERS_MIN_AVATAR_ASC",
  LgMembersMinAvatarDesc = "LG_MEMBERS_MIN_AVATAR_DESC",
  LgMembersMinCreatedUtcAsc = "LG_MEMBERS_MIN_CREATED_UTC_ASC",
  LgMembersMinCreatedUtcDesc = "LG_MEMBERS_MIN_CREATED_UTC_DESC",
  LgMembersMinEmailAsc = "LG_MEMBERS_MIN_EMAIL_ASC",
  LgMembersMinEmailDesc = "LG_MEMBERS_MIN_EMAIL_DESC",
  LgMembersMinFirstNameAsc = "LG_MEMBERS_MIN_FIRST_NAME_ASC",
  LgMembersMinFirstNameDesc = "LG_MEMBERS_MIN_FIRST_NAME_DESC",
  LgMembersMinIdAsc = "LG_MEMBERS_MIN_ID_ASC",
  LgMembersMinIdDesc = "LG_MEMBERS_MIN_ID_DESC",
  LgMembersMinInitialsAsc = "LG_MEMBERS_MIN_INITIALS_ASC",
  LgMembersMinInitialsDesc = "LG_MEMBERS_MIN_INITIALS_DESC",
  LgMembersMinIsInvestorAsc = "LG_MEMBERS_MIN_IS_INVESTOR_ASC",
  LgMembersMinIsInvestorDesc = "LG_MEMBERS_MIN_IS_INVESTOR_DESC",
  LgMembersMinLastAgendaUtcAsc = "LG_MEMBERS_MIN_LAST_AGENDA_UTC_ASC",
  LgMembersMinLastAgendaUtcDesc = "LG_MEMBERS_MIN_LAST_AGENDA_UTC_DESC",
  LgMembersMinLastModifiedUtcAsc = "LG_MEMBERS_MIN_LAST_MODIFIED_UTC_ASC",
  LgMembersMinLastModifiedUtcDesc = "LG_MEMBERS_MIN_LAST_MODIFIED_UTC_DESC",
  LgMembersMinLastNameAsc = "LG_MEMBERS_MIN_LAST_NAME_ASC",
  LgMembersMinLastNameDesc = "LG_MEMBERS_MIN_LAST_NAME_DESC",
  LgMembersMinSectorsAsc = "LG_MEMBERS_MIN_SECTORS_ASC",
  LgMembersMinSectorsDesc = "LG_MEMBERS_MIN_SECTORS_DESC",
  LgMembersMinSettingsAsc = "LG_MEMBERS_MIN_SETTINGS_ASC",
  LgMembersMinSettingsDesc = "LG_MEMBERS_MIN_SETTINGS_DESC",
  LgMembersMinTitleAsc = "LG_MEMBERS_MIN_TITLE_ASC",
  LgMembersMinTitleDesc = "LG_MEMBERS_MIN_TITLE_DESC",
  LgMembersMinTrelloMemberIdAsc = "LG_MEMBERS_MIN_TRELLO_MEMBER_ID_ASC",
  LgMembersMinTrelloMemberIdDesc = "LG_MEMBERS_MIN_TRELLO_MEMBER_ID_DESC",
  LgMembersMinUsernameAsc = "LG_MEMBERS_MIN_USERNAME_ASC",
  LgMembersMinUsernameDesc = "LG_MEMBERS_MIN_USERNAME_DESC",
  LgMembersMinUserIdAsc = "LG_MEMBERS_MIN_USER_ID_ASC",
  LgMembersMinUserIdDesc = "LG_MEMBERS_MIN_USER_ID_DESC",
  LgMembersStddevPopulationAvatarAsc = "LG_MEMBERS_STDDEV_POPULATION_AVATAR_ASC",
  LgMembersStddevPopulationAvatarDesc = "LG_MEMBERS_STDDEV_POPULATION_AVATAR_DESC",
  LgMembersStddevPopulationCreatedUtcAsc = "LG_MEMBERS_STDDEV_POPULATION_CREATED_UTC_ASC",
  LgMembersStddevPopulationCreatedUtcDesc = "LG_MEMBERS_STDDEV_POPULATION_CREATED_UTC_DESC",
  LgMembersStddevPopulationEmailAsc = "LG_MEMBERS_STDDEV_POPULATION_EMAIL_ASC",
  LgMembersStddevPopulationEmailDesc = "LG_MEMBERS_STDDEV_POPULATION_EMAIL_DESC",
  LgMembersStddevPopulationFirstNameAsc = "LG_MEMBERS_STDDEV_POPULATION_FIRST_NAME_ASC",
  LgMembersStddevPopulationFirstNameDesc = "LG_MEMBERS_STDDEV_POPULATION_FIRST_NAME_DESC",
  LgMembersStddevPopulationIdAsc = "LG_MEMBERS_STDDEV_POPULATION_ID_ASC",
  LgMembersStddevPopulationIdDesc = "LG_MEMBERS_STDDEV_POPULATION_ID_DESC",
  LgMembersStddevPopulationInitialsAsc = "LG_MEMBERS_STDDEV_POPULATION_INITIALS_ASC",
  LgMembersStddevPopulationInitialsDesc = "LG_MEMBERS_STDDEV_POPULATION_INITIALS_DESC",
  LgMembersStddevPopulationIsInvestorAsc = "LG_MEMBERS_STDDEV_POPULATION_IS_INVESTOR_ASC",
  LgMembersStddevPopulationIsInvestorDesc = "LG_MEMBERS_STDDEV_POPULATION_IS_INVESTOR_DESC",
  LgMembersStddevPopulationLastAgendaUtcAsc = "LG_MEMBERS_STDDEV_POPULATION_LAST_AGENDA_UTC_ASC",
  LgMembersStddevPopulationLastAgendaUtcDesc = "LG_MEMBERS_STDDEV_POPULATION_LAST_AGENDA_UTC_DESC",
  LgMembersStddevPopulationLastModifiedUtcAsc = "LG_MEMBERS_STDDEV_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgMembersStddevPopulationLastModifiedUtcDesc = "LG_MEMBERS_STDDEV_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgMembersStddevPopulationLastNameAsc = "LG_MEMBERS_STDDEV_POPULATION_LAST_NAME_ASC",
  LgMembersStddevPopulationLastNameDesc = "LG_MEMBERS_STDDEV_POPULATION_LAST_NAME_DESC",
  LgMembersStddevPopulationSectorsAsc = "LG_MEMBERS_STDDEV_POPULATION_SECTORS_ASC",
  LgMembersStddevPopulationSectorsDesc = "LG_MEMBERS_STDDEV_POPULATION_SECTORS_DESC",
  LgMembersStddevPopulationSettingsAsc = "LG_MEMBERS_STDDEV_POPULATION_SETTINGS_ASC",
  LgMembersStddevPopulationSettingsDesc = "LG_MEMBERS_STDDEV_POPULATION_SETTINGS_DESC",
  LgMembersStddevPopulationTitleAsc = "LG_MEMBERS_STDDEV_POPULATION_TITLE_ASC",
  LgMembersStddevPopulationTitleDesc = "LG_MEMBERS_STDDEV_POPULATION_TITLE_DESC",
  LgMembersStddevPopulationTrelloMemberIdAsc = "LG_MEMBERS_STDDEV_POPULATION_TRELLO_MEMBER_ID_ASC",
  LgMembersStddevPopulationTrelloMemberIdDesc = "LG_MEMBERS_STDDEV_POPULATION_TRELLO_MEMBER_ID_DESC",
  LgMembersStddevPopulationUsernameAsc = "LG_MEMBERS_STDDEV_POPULATION_USERNAME_ASC",
  LgMembersStddevPopulationUsernameDesc = "LG_MEMBERS_STDDEV_POPULATION_USERNAME_DESC",
  LgMembersStddevPopulationUserIdAsc = "LG_MEMBERS_STDDEV_POPULATION_USER_ID_ASC",
  LgMembersStddevPopulationUserIdDesc = "LG_MEMBERS_STDDEV_POPULATION_USER_ID_DESC",
  LgMembersStddevSampleAvatarAsc = "LG_MEMBERS_STDDEV_SAMPLE_AVATAR_ASC",
  LgMembersStddevSampleAvatarDesc = "LG_MEMBERS_STDDEV_SAMPLE_AVATAR_DESC",
  LgMembersStddevSampleCreatedUtcAsc = "LG_MEMBERS_STDDEV_SAMPLE_CREATED_UTC_ASC",
  LgMembersStddevSampleCreatedUtcDesc = "LG_MEMBERS_STDDEV_SAMPLE_CREATED_UTC_DESC",
  LgMembersStddevSampleEmailAsc = "LG_MEMBERS_STDDEV_SAMPLE_EMAIL_ASC",
  LgMembersStddevSampleEmailDesc = "LG_MEMBERS_STDDEV_SAMPLE_EMAIL_DESC",
  LgMembersStddevSampleFirstNameAsc = "LG_MEMBERS_STDDEV_SAMPLE_FIRST_NAME_ASC",
  LgMembersStddevSampleFirstNameDesc = "LG_MEMBERS_STDDEV_SAMPLE_FIRST_NAME_DESC",
  LgMembersStddevSampleIdAsc = "LG_MEMBERS_STDDEV_SAMPLE_ID_ASC",
  LgMembersStddevSampleIdDesc = "LG_MEMBERS_STDDEV_SAMPLE_ID_DESC",
  LgMembersStddevSampleInitialsAsc = "LG_MEMBERS_STDDEV_SAMPLE_INITIALS_ASC",
  LgMembersStddevSampleInitialsDesc = "LG_MEMBERS_STDDEV_SAMPLE_INITIALS_DESC",
  LgMembersStddevSampleIsInvestorAsc = "LG_MEMBERS_STDDEV_SAMPLE_IS_INVESTOR_ASC",
  LgMembersStddevSampleIsInvestorDesc = "LG_MEMBERS_STDDEV_SAMPLE_IS_INVESTOR_DESC",
  LgMembersStddevSampleLastAgendaUtcAsc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_AGENDA_UTC_ASC",
  LgMembersStddevSampleLastAgendaUtcDesc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_AGENDA_UTC_DESC",
  LgMembersStddevSampleLastModifiedUtcAsc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgMembersStddevSampleLastModifiedUtcDesc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgMembersStddevSampleLastNameAsc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_NAME_ASC",
  LgMembersStddevSampleLastNameDesc = "LG_MEMBERS_STDDEV_SAMPLE_LAST_NAME_DESC",
  LgMembersStddevSampleSectorsAsc = "LG_MEMBERS_STDDEV_SAMPLE_SECTORS_ASC",
  LgMembersStddevSampleSectorsDesc = "LG_MEMBERS_STDDEV_SAMPLE_SECTORS_DESC",
  LgMembersStddevSampleSettingsAsc = "LG_MEMBERS_STDDEV_SAMPLE_SETTINGS_ASC",
  LgMembersStddevSampleSettingsDesc = "LG_MEMBERS_STDDEV_SAMPLE_SETTINGS_DESC",
  LgMembersStddevSampleTitleAsc = "LG_MEMBERS_STDDEV_SAMPLE_TITLE_ASC",
  LgMembersStddevSampleTitleDesc = "LG_MEMBERS_STDDEV_SAMPLE_TITLE_DESC",
  LgMembersStddevSampleTrelloMemberIdAsc = "LG_MEMBERS_STDDEV_SAMPLE_TRELLO_MEMBER_ID_ASC",
  LgMembersStddevSampleTrelloMemberIdDesc = "LG_MEMBERS_STDDEV_SAMPLE_TRELLO_MEMBER_ID_DESC",
  LgMembersStddevSampleUsernameAsc = "LG_MEMBERS_STDDEV_SAMPLE_USERNAME_ASC",
  LgMembersStddevSampleUsernameDesc = "LG_MEMBERS_STDDEV_SAMPLE_USERNAME_DESC",
  LgMembersStddevSampleUserIdAsc = "LG_MEMBERS_STDDEV_SAMPLE_USER_ID_ASC",
  LgMembersStddevSampleUserIdDesc = "LG_MEMBERS_STDDEV_SAMPLE_USER_ID_DESC",
  LgMembersSumAvatarAsc = "LG_MEMBERS_SUM_AVATAR_ASC",
  LgMembersSumAvatarDesc = "LG_MEMBERS_SUM_AVATAR_DESC",
  LgMembersSumCreatedUtcAsc = "LG_MEMBERS_SUM_CREATED_UTC_ASC",
  LgMembersSumCreatedUtcDesc = "LG_MEMBERS_SUM_CREATED_UTC_DESC",
  LgMembersSumEmailAsc = "LG_MEMBERS_SUM_EMAIL_ASC",
  LgMembersSumEmailDesc = "LG_MEMBERS_SUM_EMAIL_DESC",
  LgMembersSumFirstNameAsc = "LG_MEMBERS_SUM_FIRST_NAME_ASC",
  LgMembersSumFirstNameDesc = "LG_MEMBERS_SUM_FIRST_NAME_DESC",
  LgMembersSumIdAsc = "LG_MEMBERS_SUM_ID_ASC",
  LgMembersSumIdDesc = "LG_MEMBERS_SUM_ID_DESC",
  LgMembersSumInitialsAsc = "LG_MEMBERS_SUM_INITIALS_ASC",
  LgMembersSumInitialsDesc = "LG_MEMBERS_SUM_INITIALS_DESC",
  LgMembersSumIsInvestorAsc = "LG_MEMBERS_SUM_IS_INVESTOR_ASC",
  LgMembersSumIsInvestorDesc = "LG_MEMBERS_SUM_IS_INVESTOR_DESC",
  LgMembersSumLastAgendaUtcAsc = "LG_MEMBERS_SUM_LAST_AGENDA_UTC_ASC",
  LgMembersSumLastAgendaUtcDesc = "LG_MEMBERS_SUM_LAST_AGENDA_UTC_DESC",
  LgMembersSumLastModifiedUtcAsc = "LG_MEMBERS_SUM_LAST_MODIFIED_UTC_ASC",
  LgMembersSumLastModifiedUtcDesc = "LG_MEMBERS_SUM_LAST_MODIFIED_UTC_DESC",
  LgMembersSumLastNameAsc = "LG_MEMBERS_SUM_LAST_NAME_ASC",
  LgMembersSumLastNameDesc = "LG_MEMBERS_SUM_LAST_NAME_DESC",
  LgMembersSumSectorsAsc = "LG_MEMBERS_SUM_SECTORS_ASC",
  LgMembersSumSectorsDesc = "LG_MEMBERS_SUM_SECTORS_DESC",
  LgMembersSumSettingsAsc = "LG_MEMBERS_SUM_SETTINGS_ASC",
  LgMembersSumSettingsDesc = "LG_MEMBERS_SUM_SETTINGS_DESC",
  LgMembersSumTitleAsc = "LG_MEMBERS_SUM_TITLE_ASC",
  LgMembersSumTitleDesc = "LG_MEMBERS_SUM_TITLE_DESC",
  LgMembersSumTrelloMemberIdAsc = "LG_MEMBERS_SUM_TRELLO_MEMBER_ID_ASC",
  LgMembersSumTrelloMemberIdDesc = "LG_MEMBERS_SUM_TRELLO_MEMBER_ID_DESC",
  LgMembersSumUsernameAsc = "LG_MEMBERS_SUM_USERNAME_ASC",
  LgMembersSumUsernameDesc = "LG_MEMBERS_SUM_USERNAME_DESC",
  LgMembersSumUserIdAsc = "LG_MEMBERS_SUM_USER_ID_ASC",
  LgMembersSumUserIdDesc = "LG_MEMBERS_SUM_USER_ID_DESC",
  LgMembersVariancePopulationAvatarAsc = "LG_MEMBERS_VARIANCE_POPULATION_AVATAR_ASC",
  LgMembersVariancePopulationAvatarDesc = "LG_MEMBERS_VARIANCE_POPULATION_AVATAR_DESC",
  LgMembersVariancePopulationCreatedUtcAsc = "LG_MEMBERS_VARIANCE_POPULATION_CREATED_UTC_ASC",
  LgMembersVariancePopulationCreatedUtcDesc = "LG_MEMBERS_VARIANCE_POPULATION_CREATED_UTC_DESC",
  LgMembersVariancePopulationEmailAsc = "LG_MEMBERS_VARIANCE_POPULATION_EMAIL_ASC",
  LgMembersVariancePopulationEmailDesc = "LG_MEMBERS_VARIANCE_POPULATION_EMAIL_DESC",
  LgMembersVariancePopulationFirstNameAsc = "LG_MEMBERS_VARIANCE_POPULATION_FIRST_NAME_ASC",
  LgMembersVariancePopulationFirstNameDesc = "LG_MEMBERS_VARIANCE_POPULATION_FIRST_NAME_DESC",
  LgMembersVariancePopulationIdAsc = "LG_MEMBERS_VARIANCE_POPULATION_ID_ASC",
  LgMembersVariancePopulationIdDesc = "LG_MEMBERS_VARIANCE_POPULATION_ID_DESC",
  LgMembersVariancePopulationInitialsAsc = "LG_MEMBERS_VARIANCE_POPULATION_INITIALS_ASC",
  LgMembersVariancePopulationInitialsDesc = "LG_MEMBERS_VARIANCE_POPULATION_INITIALS_DESC",
  LgMembersVariancePopulationIsInvestorAsc = "LG_MEMBERS_VARIANCE_POPULATION_IS_INVESTOR_ASC",
  LgMembersVariancePopulationIsInvestorDesc = "LG_MEMBERS_VARIANCE_POPULATION_IS_INVESTOR_DESC",
  LgMembersVariancePopulationLastAgendaUtcAsc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_AGENDA_UTC_ASC",
  LgMembersVariancePopulationLastAgendaUtcDesc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_AGENDA_UTC_DESC",
  LgMembersVariancePopulationLastModifiedUtcAsc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_MODIFIED_UTC_ASC",
  LgMembersVariancePopulationLastModifiedUtcDesc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_MODIFIED_UTC_DESC",
  LgMembersVariancePopulationLastNameAsc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_NAME_ASC",
  LgMembersVariancePopulationLastNameDesc = "LG_MEMBERS_VARIANCE_POPULATION_LAST_NAME_DESC",
  LgMembersVariancePopulationSectorsAsc = "LG_MEMBERS_VARIANCE_POPULATION_SECTORS_ASC",
  LgMembersVariancePopulationSectorsDesc = "LG_MEMBERS_VARIANCE_POPULATION_SECTORS_DESC",
  LgMembersVariancePopulationSettingsAsc = "LG_MEMBERS_VARIANCE_POPULATION_SETTINGS_ASC",
  LgMembersVariancePopulationSettingsDesc = "LG_MEMBERS_VARIANCE_POPULATION_SETTINGS_DESC",
  LgMembersVariancePopulationTitleAsc = "LG_MEMBERS_VARIANCE_POPULATION_TITLE_ASC",
  LgMembersVariancePopulationTitleDesc = "LG_MEMBERS_VARIANCE_POPULATION_TITLE_DESC",
  LgMembersVariancePopulationTrelloMemberIdAsc = "LG_MEMBERS_VARIANCE_POPULATION_TRELLO_MEMBER_ID_ASC",
  LgMembersVariancePopulationTrelloMemberIdDesc = "LG_MEMBERS_VARIANCE_POPULATION_TRELLO_MEMBER_ID_DESC",
  LgMembersVariancePopulationUsernameAsc = "LG_MEMBERS_VARIANCE_POPULATION_USERNAME_ASC",
  LgMembersVariancePopulationUsernameDesc = "LG_MEMBERS_VARIANCE_POPULATION_USERNAME_DESC",
  LgMembersVariancePopulationUserIdAsc = "LG_MEMBERS_VARIANCE_POPULATION_USER_ID_ASC",
  LgMembersVariancePopulationUserIdDesc = "LG_MEMBERS_VARIANCE_POPULATION_USER_ID_DESC",
  LgMembersVarianceSampleAvatarAsc = "LG_MEMBERS_VARIANCE_SAMPLE_AVATAR_ASC",
  LgMembersVarianceSampleAvatarDesc = "LG_MEMBERS_VARIANCE_SAMPLE_AVATAR_DESC",
  LgMembersVarianceSampleCreatedUtcAsc = "LG_MEMBERS_VARIANCE_SAMPLE_CREATED_UTC_ASC",
  LgMembersVarianceSampleCreatedUtcDesc = "LG_MEMBERS_VARIANCE_SAMPLE_CREATED_UTC_DESC",
  LgMembersVarianceSampleEmailAsc = "LG_MEMBERS_VARIANCE_SAMPLE_EMAIL_ASC",
  LgMembersVarianceSampleEmailDesc = "LG_MEMBERS_VARIANCE_SAMPLE_EMAIL_DESC",
  LgMembersVarianceSampleFirstNameAsc = "LG_MEMBERS_VARIANCE_SAMPLE_FIRST_NAME_ASC",
  LgMembersVarianceSampleFirstNameDesc = "LG_MEMBERS_VARIANCE_SAMPLE_FIRST_NAME_DESC",
  LgMembersVarianceSampleIdAsc = "LG_MEMBERS_VARIANCE_SAMPLE_ID_ASC",
  LgMembersVarianceSampleIdDesc = "LG_MEMBERS_VARIANCE_SAMPLE_ID_DESC",
  LgMembersVarianceSampleInitialsAsc = "LG_MEMBERS_VARIANCE_SAMPLE_INITIALS_ASC",
  LgMembersVarianceSampleInitialsDesc = "LG_MEMBERS_VARIANCE_SAMPLE_INITIALS_DESC",
  LgMembersVarianceSampleIsInvestorAsc = "LG_MEMBERS_VARIANCE_SAMPLE_IS_INVESTOR_ASC",
  LgMembersVarianceSampleIsInvestorDesc = "LG_MEMBERS_VARIANCE_SAMPLE_IS_INVESTOR_DESC",
  LgMembersVarianceSampleLastAgendaUtcAsc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_AGENDA_UTC_ASC",
  LgMembersVarianceSampleLastAgendaUtcDesc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_AGENDA_UTC_DESC",
  LgMembersVarianceSampleLastModifiedUtcAsc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_ASC",
  LgMembersVarianceSampleLastModifiedUtcDesc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_MODIFIED_UTC_DESC",
  LgMembersVarianceSampleLastNameAsc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_NAME_ASC",
  LgMembersVarianceSampleLastNameDesc = "LG_MEMBERS_VARIANCE_SAMPLE_LAST_NAME_DESC",
  LgMembersVarianceSampleSectorsAsc = "LG_MEMBERS_VARIANCE_SAMPLE_SECTORS_ASC",
  LgMembersVarianceSampleSectorsDesc = "LG_MEMBERS_VARIANCE_SAMPLE_SECTORS_DESC",
  LgMembersVarianceSampleSettingsAsc = "LG_MEMBERS_VARIANCE_SAMPLE_SETTINGS_ASC",
  LgMembersVarianceSampleSettingsDesc = "LG_MEMBERS_VARIANCE_SAMPLE_SETTINGS_DESC",
  LgMembersVarianceSampleTitleAsc = "LG_MEMBERS_VARIANCE_SAMPLE_TITLE_ASC",
  LgMembersVarianceSampleTitleDesc = "LG_MEMBERS_VARIANCE_SAMPLE_TITLE_DESC",
  LgMembersVarianceSampleTrelloMemberIdAsc = "LG_MEMBERS_VARIANCE_SAMPLE_TRELLO_MEMBER_ID_ASC",
  LgMembersVarianceSampleTrelloMemberIdDesc = "LG_MEMBERS_VARIANCE_SAMPLE_TRELLO_MEMBER_ID_DESC",
  LgMembersVarianceSampleUsernameAsc = "LG_MEMBERS_VARIANCE_SAMPLE_USERNAME_ASC",
  LgMembersVarianceSampleUsernameDesc = "LG_MEMBERS_VARIANCE_SAMPLE_USERNAME_DESC",
  LgMembersVarianceSampleUserIdAsc = "LG_MEMBERS_VARIANCE_SAMPLE_USER_ID_ASC",
  LgMembersVarianceSampleUserIdDesc = "LG_MEMBERS_VARIANCE_SAMPLE_USER_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type UnAssignPayloadInput = {
  companyId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type UpdateCompanyInput = {
  isActivelyFundraising?: InputMaybe<Scalars["Boolean"]["input"]>;
  sharingAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** All input for the `updateLinks` mutation. */
export type UpdateLinksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  pCommit?: InputMaybe<Scalars["Boolean"]["input"]>;
  pCompanyId?: InputMaybe<Scalars["UUID"]["input"]>;
  pDealroomId?: InputMaybe<Scalars["Int"]["input"]>;
  pEmail?: InputMaybe<Scalars["String"]["input"]>;
  pOpsId?: InputMaybe<Scalars["Int"]["input"]>;
  pTask?: InputMaybe<Scalars["BigInt"]["input"]>;
  pTrelloId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `updateLinks` mutation. */
export type UpdateLinksPayload = {
  __typename?: "UpdateLinksPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  json?: Maybe<Scalars["JSON"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateNzrNotificationByNodeId` mutation. */
export type UpdateNzrNotificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `NzrNotification` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `NzrNotification` being updated. */
  patch: NzrNotificationPatch;
};

/** All input for the `updateNzrNotification` mutation. */
export type UpdateNzrNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `NzrNotification` being updated. */
  patch: NzrNotificationPatch;
};

/** The output of our update `NzrNotification` mutation. */
export type UpdateNzrNotificationPayload = {
  __typename?: "UpdateNzrNotificationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `LgMember` that is related to this `NzrNotification`. */
  lgMemberByAssignee?: Maybe<LgMember>;
  /** The `NzrNotification` that was updated by this mutation. */
  nzrNotification?: Maybe<NzrNotification>;
  /** An edge for our `NzrNotification`. May be used by Relay 1. */
  nzrNotificationEdge?: Maybe<NzrNotificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `NzrNotification` mutation. */
export type UpdateNzrNotificationPayloadNzrNotificationEdgeArgs = {
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy>>;
};

export type UserFeedbackInput = {
  feedback: Scalars["JSON"]["input"];
};

export type UserReport = Node & {
  __typename?: "UserReport";
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  period: Scalars["String"]["output"];
  report: Scalars["JSON"]["output"];
  updatedAt?: Maybe<Scalars["Datetime"]["output"]>;
  /** Reads a single `LgMember` that is related to this `UserReport`. */
  user?: Maybe<LgMember>;
  userId: Scalars["UUID"]["output"];
};

export type UserReportAggregates = {
  __typename?: "UserReportAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserReportDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `UserReport` object types. */
export type UserReportAggregatesFilter = {
  /** Distinct count aggregate over matching `UserReport` objects. */
  distinctCount?: InputMaybe<UserReportDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserReport` object to be included within the aggregate. */
  filter?: InputMaybe<UserReportFilter>;
};

/**
 * A condition to be used against `UserReport` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserReportCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `report` field. */
  report?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type UserReportDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  period?: InputMaybe<BigIntFilter>;
  report?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserReportDistinctCountAggregates = {
  __typename?: "UserReportDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of period across the matching connection */
  period?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of report across the matching connection */
  report?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `UserReport` object types. All fields are combined with a logical ‘and.’ */
export type UserReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserReportFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserReportFilter>>;
  /** Filter by the object’s `period` field. */
  period?: InputMaybe<StringFilter>;
  /** Filter by the object’s `report` field. */
  report?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<LgMemberFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `UserReport` for usage during aggregation. */
export enum UserReportGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Period = "PERIOD",
  Report = "REPORT",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UserId = "USER_ID",
}

export type UserReportHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserReport` aggregates. */
export type UserReportHavingInput = {
  AND?: InputMaybe<Array<UserReportHavingInput>>;
  OR?: InputMaybe<Array<UserReportHavingInput>>;
  average?: InputMaybe<UserReportHavingAverageInput>;
  distinctCount?: InputMaybe<UserReportHavingDistinctCountInput>;
  max?: InputMaybe<UserReportHavingMaxInput>;
  min?: InputMaybe<UserReportHavingMinInput>;
  stddevPopulation?: InputMaybe<UserReportHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserReportHavingStddevSampleInput>;
  sum?: InputMaybe<UserReportHavingSumInput>;
  variancePopulation?: InputMaybe<UserReportHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserReportHavingVarianceSampleInput>;
};

export type UserReportHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserReportHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `UserReport` values. */
export type UserReportsConnection = {
  __typename?: "UserReportsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserReportAggregates>;
  /** A list of edges which contains the `UserReport` and cursor to aid in pagination. */
  edges: Array<UserReportsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserReportAggregates>>;
  /** A list of `UserReport` objects. */
  nodes: Array<UserReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserReport` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `UserReport` values. */
export type UserReportsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserReportGroupBy>;
  having?: InputMaybe<UserReportHavingInput>;
};

/** A `UserReport` edge in the connection. */
export type UserReportsEdge = {
  __typename?: "UserReportsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserReport` at the end of the edge. */
  node: UserReport;
};

/** Methods to use when ordering `UserReport`. */
export enum UserReportsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PeriodAsc = "PERIOD_ASC",
  PeriodDesc = "PERIOD_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReportAsc = "REPORT_ASC",
  ReportDesc = "REPORT_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export type UserSettingsPayloadInput = {
  general?: InputMaybe<GeneralSettingsInput>;
  settings?: InputMaybe<SettingsInput>;
};

export enum UserTag {
  Follow = "FOLLOW",
  Hide = "HIDE",
}

export type AuthResponseFragmentFragment = {
  __typename?: "AuthResponse";
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
  user?: any | null;
} & { " $fragmentName"?: "AuthResponseFragmentFragment" };

export type LoginMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  loginWithGoogle?:
    | ({ __typename?: "AuthResponse" } & {
        " $fragmentRefs"?: { AuthResponseFragmentFragment: AuthResponseFragmentFragment };
      })
    | null;
};

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken?:
    | ({ __typename?: "AuthResponse" } & {
        " $fragmentRefs"?: { AuthResponseFragmentFragment: AuthResponseFragmentFragment };
      })
    | null;
};

export type GetNotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<NzrNotificationFilter>;
  orderBy?: InputMaybe<Array<NzrNotificationsOrderBy> | NzrNotificationsOrderBy>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetNotificationsQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      node: { __typename?: "NzrNotification" } & {
        " $fragmentRefs"?: { NzrNotificationFragmentFragment: NzrNotificationFragmentFragment };
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type SetNotificationStatusMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
  status: Scalars["String"]["input"];
}>;

export type SetNotificationStatusMutation = {
  __typename?: "Mutation";
  updateNzrNotification?: {
    __typename?: "UpdateNzrNotificationPayload";
    nzrNotification?:
      | ({ __typename?: "NzrNotification" } & {
          " $fragmentRefs"?: { NzrNotificationFragmentFragment: NzrNotificationFragmentFragment };
        })
      | null;
  } | null;
};

export type NzrNotificationFragmentFragment = {
  __typename?: "NzrNotification";
  id: any;
  assignee: any;
  status: string;
  type: string;
  validity?: any | null;
  payload: any;
  createdUtc?: any | null;
} & { " $fragmentName"?: "NzrNotificationFragmentFragment" };

export type GetOpportunityEmailQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetOpportunityEmailQuery = {
  __typename?: "Query";
  nzrOpportunityEmail?: {
    __typename?: "NzrOpportunityEmail";
    id: any;
    from?: string | null;
    to?: string | null;
    labels?: any | null;
    subject: string;
    source?: string | null;
    isParsed: boolean;
    isCompleted: boolean;
    status?: string | null;
    parser?: string | null;
    metadata?: any | null;
    senderEmail: string;
    body: string;
    memberId: any;
    nzrOpportunityEmailCandidatesByEmailId: {
      __typename?: "NzrOpportunityEmailCandidatesConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "NzrOpportunityEmailCandidate";
        name: string;
        description: string;
        url: string;
        dealroomCompany?: {
          __typename?: "DealroomCompany";
          id: any;
          name?: string | null;
          path?: string | null;
          websiteUrl?: string | null;
          image?: string | null;
          websiteDomain?: string | null;
        } | null;
        lgCompany?: {
          __typename?: "LgCompany";
          id: any;
          name: string;
          websiteUrl?: string | null;
          websiteDomain?: string | null;
          image: string;
        } | null;
      }>;
    };
  } | null;
};

export type SetOpportunityEmailMutationVariables = Exact<{
  input: EmailOpportunityInput;
}>;

export type SetOpportunityEmailMutation = { __typename?: "Mutation"; opportunityEmail?: any | null };

export type RefreshMtpMutationVariables = Exact<{
  input?: InputMaybe<Scalars["JSON"]["input"]>;
}>;

export type RefreshMtpMutation = { __typename?: "Mutation"; refreshMTP?: any | null };

export type CompanyAttachmentsQueryVariables = Exact<{
  companyId: Scalars["UUID"]["input"];
}>;

export type CompanyAttachmentsQuery = {
  __typename?: "Query";
  lgCompanyFiles?: {
    __typename?: "LgCompanyFilesConnection";
    nodes: Array<{
      __typename?: "LgCompanyFile";
      file?: {
        __typename?: "LgFile";
        id: any;
        name: string;
        path: string;
        status: string;
        createdUtc: any;
        source: LgFileSource;
        metadata?: any | null;
        lgMemberByCreatedBy?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          username: string;
          avatar?: string | null;
          initials: string;
        } | null;
      } | null;
    }>;
  } | null;
};

export type DeleteCompanyAttachmentMutationVariables = Exact<{
  fileId: Scalars["ID"]["input"];
}>;

export type DeleteCompanyAttachmentMutation = { __typename?: "Mutation"; companyAttachmentDelete?: any | null };

export type GetCompanyCommentsQueryVariables = Exact<{
  companyId: Scalars["UUID"]["input"];
}>;

export type GetCompanyCommentsQuery = {
  __typename?: "Query";
  lgCompanyComments?: {
    __typename?: "LgCompanyCommentsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LgCompanyComment";
      id: any;
      text: string;
      createdUtc: any;
      lastUpdatedUtc?: any | null;
      metadata?: any | null;
      lgMemberByCreatedBy?: {
        __typename?: "LgMember";
        id: any;
        initials: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
    }>;
  } | null;
};

export type AddCompanyCommentMutationVariables = Exact<{
  companyId: Scalars["ID"]["input"];
  text: Scalars["String"]["input"];
  metadata?: InputMaybe<CommentMetadataInput>;
}>;

export type AddCompanyCommentMutation = { __typename?: "Mutation"; addCompanyComment?: any | null };

export type UpdateCompanyCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  text: Scalars["String"]["input"];
  metadata?: InputMaybe<CommentMetadataInput>;
}>;

export type UpdateCompanyCommentMutation = { __typename?: "Mutation"; editCompanyComment?: any | null };

export type DeleteCompanyCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCompanyCommentMutation = { __typename?: "Mutation"; deleteCompanyComment?: any | null };

export type GetForwardedCompaniesQueryVariables = Exact<{
  assignee: Scalars["UUID"]["input"];
  pageSize: Scalars["Int"]["input"];
  statuses?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetForwardedCompaniesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrNotification";
        id: any;
        status: string;
        createdUtc?: any | null;
        payload: any;
        nzrOpportunityEmailAsReference?: {
          __typename?: "NzrOpportunityEmail";
          id: any;
          subject: string;
          senderEmail: string;
          body: string;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetCompanyUpdatesQueryVariables = Exact<{
  assignee: Scalars["UUID"]["input"];
  pageSize: Scalars["Int"]["input"];
  statuses?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetCompanyUpdatesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrNotification";
        id: any;
        status: string;
        createdUtc?: any | null;
        payload: any;
        nzrOpportunityEmailAsReference?: {
          __typename?: "NzrOpportunityEmail";
          id: any;
          subject: string;
          senderEmail: string;
          from?: string | null;
          to?: string | null;
          body: string;
          source?: string | null;
          attachments?: any | null;
          metadata?: any | null;
          parser?: string | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetSentimentFormRequestsQueryVariables = Exact<{
  assignee: Scalars["UUID"]["input"];
  completed: Scalars["Boolean"]["input"];
  pageSize: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetSentimentFormRequestsQuery = {
  __typename?: "Query";
  nzrSentimentForms?: {
    __typename?: "NzrSentimentFormsConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
    edges: Array<{
      __typename?: "NzrSentimentFormsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrSentimentForm";
        id: string;
        type?: string | null;
        title?: string | null;
        status?: string | null;
        formStatus?: string | null;
        createdAt?: any | null;
        expiresAt?: any | null;
        createdBy?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          avatar?: string | null;
        } | null;
        nzrSentimentFormAssignees: {
          __typename?: "NzrSentimentFormAssigneesConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "NzrSentimentFormAssignee";
            id: any;
            state?: any | null;
            member?: {
              __typename?: "LgMember";
              id: any;
              firstName: string;
              lastName: string;
              avatar?: string | null;
            } | null;
          }>;
        };
        founder?: { __typename?: "LgFounder"; name?: string | null; linkedinUrl?: string | null } | null;
        company?: { __typename?: "LgCompany"; id: any; name: string; image: string } | null;
        nzrNotifications: {
          __typename?: "NzrNotificationsConnection";
          nodes: Array<{ __typename?: "NzrNotification"; id: any }>;
        };
      };
    }>;
  } | null;
};

export type GetFeedbackTemplatesQueryVariables = Exact<{
  assignee: Scalars["UUID"]["input"];
  statuses?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  pageSize: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetFeedbackTemplatesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: { __typename?: "NzrNotification"; id: any; status: string; createdUtc?: any | null; payload: any };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetDashboardCompaniesQueryVariables = Exact<{
  pageSize: Scalars["Int"]["input"];
  filter?: InputMaybe<LgCompanyFilter>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy> | LgCompaniesOrderBy>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetDashboardCompaniesQuery = {
  __typename?: "Query";
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LgCompaniesEdge";
      node: {
        __typename?: "LgCompany";
        id: any;
        image: string;
        name: string;
        tagline: string;
        tags?: any | null;
        badges?: any | null;
        city: string;
        country: string;
        isOpCompany: boolean;
        isTrCompany: boolean;
        userTags: {
          __typename?: "NzrUserCompanyTagsConnection";
          nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
        };
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetUserHighlightsQueryVariables = Exact<{
  userId: Scalars["UUID"]["input"];
  thisWeek: Scalars["Datetime"]["input"];
  previousWeek: Scalars["Datetime"]["input"];
  statuses?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type GetUserHighlightsQuery = {
  __typename?: "Query";
  lgMember?: {
    __typename?: "LgMember";
    currentAdded: { __typename?: "MnEntriesConnection"; totalCount: number };
    previousAdded: { __typename?: "MnEntriesConnection"; totalCount: number };
    currentCompanyUpdates: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    previousCompanyUpdates: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    pendingFeedback: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    pendingSentimentForms: {
      __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection";
      totalCount: number;
    };
    competedSentimentForms: {
      __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection";
      totalCount: number;
    };
    follows: { __typename?: "NzrUserCompanyTagsConnection"; totalCount: number };
    inReview: { __typename?: "NzrOpportunitiesConnection"; totalCount: number };
  } | null;
};

export type NzrOpportunityEmailQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type NzrOpportunityEmailQuery = {
  __typename?: "Query";
  nzrOpportunityEmail?: {
    __typename?: "NzrOpportunityEmail";
    id: any;
    googleMessageId: string;
    isCompleted: boolean;
    isParsed: boolean;
    status?: string | null;
    metadata?: any | null;
    attachments?: any | null;
    body: string;
    subject: string;
    createdAt: any;
  } | null;
};

export type GetFileUrlQueryVariables = Exact<{
  filePath: Scalars["String"]["input"];
}>;

export type GetFileUrlQuery = { __typename?: "Query"; responseGetFilePresignedUrl?: string | null };

export type LgCompanyFragmentFragment = {
  __typename?: "LgCompany";
  about: string;
  address: string;
  amount?: string | null;
  amountUsdMillion?: number | null;
  badges?: any | null;
  calculationStatus: string;
  cardLastActivity?: any | null;
  cardName?: string | null;
  city: string;
  companyStatus: string;
  continent: string;
  country: string;
  createdUtc?: any | null;
  currency: string;
  customOrder?: number | null;
  dealroomSignalCompleteness?: number | null;
  dealroomSignalGrowthRate?: number | null;
  dealroomSignalRating?: number | null;
  dealroomSignalTeamStrength?: number | null;
  dealroomSignalTiming?: number | null;
  employees?: string | null;
  employeesChart?: any | null;
  employeesLatest?: number | null;
  fundingDate?: string | null;
  growthChart?: any | null;
  hasPromisingFounder?: boolean | null;
  hasStrongFounder?: boolean | null;
  hasSuperFounder?: boolean | null;
  id: any;
  image: string;
  industries?: any | null;
  isActivelyFundraising: boolean;
  isBasecampFunded?: boolean | null;
  isFoundingLocation?: boolean | null;
  isNpa?: boolean | null;
  isOpCompany: boolean;
  isTrCompany: boolean;
  lastCalculatedUtc?: any | null;
  lastFundingDate?: any | null;
  lastUpdatedUtc?: any | null;
  launchYear?: number | null;
  linkedinUrl?: string | null;
  monthsFromLastFunding?: number | null;
  name: string;
  nodeId: string;
  relativeRunway?: string | null;
  runwayLastUpdate?: any | null;
  runwayEndDate?: any | null;
  tagline: string;
  tags?: any | null;
  targetFund: string;
  techStackPredictleads?: any | null;
  technologies?: any | null;
  totalFunding?: any | null;
  totalFundingSource?: string | null;
  twitterUrl?: string | null;
  websiteTrafficChart?: any | null;
  websiteUrl?: string | null;
  latestExcitement?: number | null;
  latestExcitementDate?: any | null;
} & { " $fragmentName"?: "LgCompanyFragmentFragment" };

export type SignalCompanyFragmentFragment = {
  __typename?: "Signal";
  id: any;
  createdUtc: any;
  output?: any | null;
  rule?: { __typename?: "Rule"; name: string; runner: string } | null;
} & { " $fragmentName"?: "SignalCompanyFragmentFragment" };

export type CompanyFlagFragmentFragment = {
  __typename?: "LgCompanyFlag";
  id: any;
  createdAt: any;
  updatedAt?: any | null;
  createdBy: any;
  companyId: any;
  flag: string;
} & { " $fragmentName"?: "CompanyFlagFragmentFragment" };

export type CompanySourceFragmentFragment = {
  __typename?: "SrcCompany";
  sourceType: string;
  sourceId: string;
  lastUpdatedUtc: any;
  dealroomCompanyAsSource?: {
    __typename?: "DealroomCompany";
    id: any;
    name?: string | null;
    about?: string | null;
    tagline?: string | null;
    websiteUrl?: string | null;
    path?: string | null;
    crunchbaseUrl?: string | null;
    url?: string | null;
    lastUpdatedUtc?: any | null;
    dealroomNewsByCompanyId: {
      __typename?: "DealroomNewsConnection";
      news: Array<{
        __typename?: "DealroomNews";
        author?: any | null;
        pubDate?: any | null;
        title?: string | null;
        summary?: string | null;
        image?: string | null;
        link?: string | null;
      }>;
    };
  } | null;
  trelloCardAsSource?: {
    __typename?: "TrelloCard";
    id: string;
    name: string;
    url: string;
    shortLinkHash: string;
    listId: string;
    boardId: string;
    comments?: any | null;
    attachments?: any | null;
    memberIds?: any | null;
    dateLastActivity?: any | null;
    list?: { __typename?: "TrelloList"; id: string; name: string } | null;
  } | null;
  opsCompanyAsSource?: {
    __typename?: "OpsCompany";
    id: any;
    name: string;
    opsCompanyfundsByCompanyId: {
      __typename?: "OpsCompanyfundsConnection";
      nodes: Array<{
        __typename?: "OpsCompanyfund";
        currentEv?: number | null;
        currentNav?: number | null;
        currentOwned?: number | null;
        cashoutGroup: string;
        currentMultiple?: number | null;
        unrealisedValue?: number | null;
        fund?: { __typename?: "OpsFund"; name: string } | null;
      }>;
    };
  } | null;
  mnEntryAsSource?: {
    __typename?: "MnEntry";
    id: number;
    data: any;
    lastUpdatedUtc: any;
    member?: {
      __typename?: "LgMember";
      id: any;
      initials: string;
      firstName: string;
      lastName: string;
      trelloMemberId?: string | null;
    } | null;
  } | null;
} & { " $fragmentName"?: "CompanySourceFragmentFragment" };

export type NzrSentimentFormFragFragment = {
  __typename?: "NzrSentimentForm";
  id: string;
  analytics?: any | null;
  category?: string | null;
  companyId?: any | null;
  companyName?: string | null;
  createdAt?: any | null;
  createdById?: any | null;
  expiresAt?: any | null;
  fields?: any | null;
  formStatus?: string | null;
  responses?: any | null;
  sentimentSummary?: string | null;
} & { " $fragmentName"?: "NzrSentimentFormFragFragment" };

export type LgFundingRoundFragmentFragment = {
  __typename?: "LgFundingRound";
  id: any;
  amount?: any | null;
  companyId: any;
  currency?: string | null;
  isVerified?: boolean | null;
  amountEurMillion?: any | null;
  amountSource?: any | null;
  amountUsdMillion?: any | null;
  year?: number | null;
  month?: number | null;
  round?: string | null;
  valuation?: any | null;
} & { " $fragmentName"?: "LgFundingRoundFragmentFragment" };

export type LgFundingRoundInvestorFragmentFragment = {
  __typename?: "LgFundingRoundInvestor";
  lead?: boolean | null;
  investor?:
    | ({ __typename?: "LgInvestor" } & {
        " $fragmentRefs"?: { LgInvestorFragmentFragment: LgInvestorFragmentFragment };
      })
    | null;
} & { " $fragmentName"?: "LgFundingRoundInvestorFragmentFragment" };

export type LgInvestorFragmentFragment = {
  __typename?: "LgInvestor";
  id: any;
  name?: string | null;
  isBasecamp: boolean;
  about?: string | null;
  image?: string | null;
  fundLead?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string; avatar?: string | null } | null;
} & { " $fragmentName"?: "LgInvestorFragmentFragment" };

export type LgTeamFragmentFragment = {
  __typename?: "LgCompanyTeam";
  id: number;
  titles?: any | null;
  isFounder?: boolean | null;
  yearEnd?: number | null;
  yearStart?: number | null;
  companyTeams: {
    __typename?: "SrcCompanyTeamsConnection";
    nodes: Array<{ __typename?: "SrcCompanyTeam"; sourceType: string; lastUpdatedUtc: any }>;
  };
  member?: {
    __typename?: "LgFounder";
    id: any;
    name?: string | null;
    image?: string | null;
    linkedinUrl?: string | null;
    lastUpdatedUtc?: any | null;
    forms: {
      __typename?: "NzrSentimentFormsConnection";
      nodes: Array<
        { __typename?: "NzrSentimentForm" } & {
          " $fragmentRefs"?: { NzrSentimentFormFragFragment: NzrSentimentFormFragFragment };
        }
      >;
    };
  } | null;
} & { " $fragmentName"?: "LgTeamFragmentFragment" };

export type FundFragment = {
  __typename?: "OpsCompanyfund";
  announced: boolean;
  braggy: string;
  cashoutDate?: any | null;
  cashoutGroup: string;
  coInvestors: string;
  cocMultiple?: number | null;
  companyId: any;
  id: any;
  currentEv?: number | null;
  currentMultiple?: number | null;
  currentNav?: number | null;
  currentNavFromFund?: number | null;
  currentOwned?: number | null;
  fundId?: any | null;
  goingInOwnership?: number | null;
  investmentType: string;
  latestExcitementScore?: number | null;
  initialInvestment?: number | null;
  totalInvestedCapital?: number | null;
  evAsFundReturner?: number | null;
  mtpEv?: number | null;
  investedCapitalFromFund?: number | null;
  unrealisedValue?: number | null;
  mostRecentRound: string;
  grossMargins?: number | null;
  lastRound?: number | null;
  revenue?: number | null;
  company?: {
    __typename?: "OpsCompany";
    name: string;
    id: any;
    totalNav?: number | null;
    initialInvestment?: number | null;
    totalInvestedCapital?: number | null;
    initialInvestmentRatio?: number | null;
    isFocus?: boolean | null;
    srcCompany?: {
      __typename?: "SrcCompany";
      company?: {
        __typename?: "LgCompany";
        image: string;
        name: string;
        tagline: string;
        id: any;
        latestExcitement?: number | null;
        latestExcitementDate?: any | null;
      } | null;
    } | null;
  } | null;
  fund?: { __typename?: "OpsFund"; name: string; parentFund: string } | null;
} & { " $fragmentName"?: "FundFragment" };

export type LgMembersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<LgMemberCondition>;
  filter?: InputMaybe<LgMemberFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LgMembersOrderBy> | LgMembersOrderBy>;
}>;

export type LgMembersQuery = {
  __typename?: "Query";
  lgMembers?: {
    __typename?: "LgMembersConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LgMember";
      avatar?: string | null;
      createdUtc: any;
      email: string;
      firstName: string;
      id: any;
      initials: string;
      isInvestor: boolean;
      lastModifiedUtc: any;
      lastName: string;
      trelloMemberId?: string | null;
    }>;
  } | null;
};

export type LgMemberQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type LgMemberQuery = {
  __typename?: "Query";
  lgMember?: {
    __typename?: "LgMember";
    id: any;
    email: string;
    firstName: string;
    isInvestor: boolean;
    lastName: string;
    avatar?: string | null;
    lgMemberSettingsByUserId: {
      __typename?: "LgMemberSettingsConnection";
      nodes: Array<{ __typename?: "LgMemberSetting"; feedbackEmailTemplate?: any | null }>;
    };
  } | null;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UserSettingsPayloadInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: "Mutation"; updateUserSettings?: any | null };

export type UpsertMetricsExtractionMutationVariables = Exact<{
  lgCompanyMetricsInput?: InputMaybe<LgCompanyMetricsInput>;
}>;

export type UpsertMetricsExtractionMutation = { __typename?: "Mutation"; upsertMetricsExtraction?: any | null };

export type CompanyDetailsMetricsQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type CompanyDetailsMetricsQuery = {
  __typename?: "Query";
  lgCompany?: {
    __typename?: "LgCompany";
    id: any;
    name: string;
    image: string;
    websiteUrl?: string | null;
    srcCompaniesByCompanyId: {
      __typename?: "SrcCompaniesConnection";
      nodes: Array<{
        __typename?: "SrcCompany";
        trelloCardAsSource?: {
          __typename?: "TrelloCard";
          name: string;
          list?: { __typename?: "TrelloList"; name: string } | null;
        } | null;
      }>;
    };
  } | null;
};

export type GetCompanyQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetCompanyQuery = {
  __typename?: "Query";
  lgCompany?:
    | ({
        __typename?: "LgCompany";
        companiesSources: {
          __typename?: "SrcCompaniesConnection";
          items: Array<
            { __typename?: "SrcCompany" } & {
              " $fragmentRefs"?: { CompanySourceFragmentFragment: CompanySourceFragmentFragment };
            }
          >;
        };
        signals: {
          __typename?: "SignalsConnection";
          items: Array<
            {
              __typename?: "Signal";
              asignees: {
                __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection";
                nodes: Array<{
                  __typename?: "LgMember";
                  id: any;
                  firstName: string;
                  lastName: string;
                  initials: string;
                  avatar?: string | null;
                  email: string;
                }>;
              };
              nzrOpportunities: {
                __typename?: "NzrOpportunitiesConnection";
                nodes: Array<{
                  __typename?: "NzrOpportunity";
                  status: string;
                  lgMemberByAssignee?: {
                    __typename?: "LgMember";
                    id: any;
                    firstName: string;
                    email: string;
                    lastName: string;
                  } | null;
                }>;
              };
            } & { " $fragmentRefs"?: { SignalCompanyFragmentFragment: SignalCompanyFragmentFragment } }
          >;
        };
        fundingRounds: {
          __typename?: "LgFundingRoundsConnection";
          items: Array<
            {
              __typename?: "LgFundingRound";
              investors: {
                __typename?: "LgFundingRoundInvestorsConnection";
                items: Array<
                  { __typename?: "LgFundingRoundInvestor" } & {
                    " $fragmentRefs"?: {
                      LgFundingRoundInvestorFragmentFragment: LgFundingRoundInvestorFragmentFragment;
                    };
                  }
                >;
              };
            } & { " $fragmentRefs"?: { LgFundingRoundFragmentFragment: LgFundingRoundFragmentFragment } }
          >;
        };
        sentiment: {
          __typename?: "NzrSentimentFormsConnection";
          nodes: Array<
            {
              __typename?: "NzrSentimentForm";
              filled: {
                __typename?: "NzrSentimentFormAssigneesConnection";
                nodes: Array<{
                  __typename?: "NzrSentimentFormAssignee";
                  id: any;
                  member?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string } | null;
                }>;
              };
              notFilled: {
                __typename?: "NzrSentimentFormAssigneesConnection";
                nodes: Array<{
                  __typename?: "NzrSentimentFormAssignee";
                  id: any;
                  member?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string } | null;
                }>;
              };
            } & { " $fragmentRefs"?: { NzrSentimentFormFragFragment: NzrSentimentFormFragFragment } }
          >;
        };
        team: {
          __typename?: "LgCompanyTeamsConnection";
          items: Array<
            { __typename?: "LgCompanyTeam" } & { " $fragmentRefs"?: { LgTeamFragmentFragment: LgTeamFragmentFragment } }
          >;
        };
        investors: {
          __typename?: "LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection";
          items: Array<
            { __typename?: "LgInvestor" } & {
              " $fragmentRefs"?: { LgInvestorFragmentFragment: LgInvestorFragmentFragment };
            }
          >;
        };
        metrics: {
          __typename?: "LgCompanyMetricsConnection";
          nodes: Array<{ __typename?: "LgCompanyMetric"; asAtDate?: any | null; data?: any | null }>;
        };
        pcgOverview: {
          __typename?: "SrcCompaniesConnection";
          items: Array<{
            __typename?: "SrcCompany";
            sourceType: string;
            lastUpdatedUtc: any;
            opsCompanyAsSource?: {
              __typename?: "OpsCompany";
              id: any;
              initialInvestment?: number | null;
              initialInvestmentRatio?: number | null;
              investmentType?: string | null;
              isStale?: boolean | null;
              name: string;
              hqCity: string;
              companyFunds: {
                __typename?: "OpsCompanyfundsConnection";
                nodes: Array<{ __typename?: "OpsCompanyfund" } & { " $fragmentRefs"?: { FundFragment: FundFragment } }>;
              };
            } | null;
          }>;
        };
        mtpCompanyfundsByMtpCompanyId: {
          __typename?: "MtpCompanyfundsConnection";
          nodes: Array<{
            __typename?: "MtpCompanyfund";
            id: number;
            fund?: string | null;
            capitalConcentration?: string | null;
            runway?: string | null;
            revenue?: string | null;
            yoyGrowth?: string | null;
            margin?: string | null;
            fte?: string | null;
            evReturnFund?: string | null;
          }>;
        };
        userTags: {
          __typename?: "NzrUserCompanyTagsConnection";
          nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
        };
        companyFlags: {
          __typename?: "LgCompanyFlagsConnection";
          items: Array<{
            __typename?: "LgCompanyFlag";
            createdAt: any;
            updatedAt?: any | null;
            createdBy: any;
            companyId: any;
            flag: string;
          }>;
        };
      } & { " $fragmentRefs"?: { LgCompanyFragmentFragment: LgCompanyFragmentFragment } })
    | null;
};

export type GetCompaniesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<LgCompanyFilter>;
  orderBy?: InputMaybe<Array<LgCompaniesOrderBy> | LgCompaniesOrderBy>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetCompaniesQuery = {
  __typename?: "Query";
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LgCompaniesEdge";
      node: {
        __typename?: "LgCompany";
        badges?: any | null;
        companiesSources: {
          __typename?: "SrcCompaniesConnection";
          items: Array<
            { __typename?: "SrcCompany" } & {
              " $fragmentRefs"?: { CompanySourceFragmentFragment: CompanySourceFragmentFragment };
            }
          >;
        };
        signals: {
          __typename?: "SignalsConnection";
          items: Array<
            {
              __typename?: "Signal";
              asignees: {
                __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection";
                nodes: Array<{
                  __typename?: "LgMember";
                  id: any;
                  firstName: string;
                  lastName: string;
                  initials: string;
                  avatar?: string | null;
                  email: string;
                }>;
              };
            } & { " $fragmentRefs"?: { SignalCompanyFragmentFragment: SignalCompanyFragmentFragment } }
          >;
        };
        sentiment: {
          __typename?: "NzrSentimentFormsConnection";
          nodes: Array<
            {
              __typename?: "NzrSentimentForm";
              filled: {
                __typename?: "NzrSentimentFormAssigneesConnection";
                nodes: Array<{
                  __typename?: "NzrSentimentFormAssignee";
                  id: any;
                  member?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string } | null;
                }>;
              };
              notFilled: {
                __typename?: "NzrSentimentFormAssigneesConnection";
                nodes: Array<{
                  __typename?: "NzrSentimentFormAssignee";
                  id: any;
                  member?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string } | null;
                }>;
              };
            } & { " $fragmentRefs"?: { NzrSentimentFormFragFragment: NzrSentimentFormFragFragment } }
          >;
        };
        fundingRounds: {
          __typename?: "LgFundingRoundsConnection";
          items: Array<
            { __typename?: "LgFundingRound" } & {
              " $fragmentRefs"?: { LgFundingRoundFragmentFragment: LgFundingRoundFragmentFragment };
            }
          >;
        };
        userTags: {
          __typename?: "NzrUserCompanyTagsConnection";
          nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
        };
      } & { " $fragmentRefs"?: { LgCompanyFragmentFragment: LgCompanyFragmentFragment } };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type SearchCompaniesGlobalQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  dealroomFilter?: InputMaybe<DealroomCompanyFilter>;
  lgFilter?: InputMaybe<LgCompanyFilter>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesGlobalQuery = {
  __typename?: "Query";
  dealroomCompanies?: {
    __typename?: "DealroomCompaniesConnection";
    nodes: Array<{
      __typename?: "DealroomCompany";
      id: any;
      image?: string | null;
      path?: string | null;
      industries?: any | null;
      name?: string | null;
      about?: string | null;
      tagline?: string | null;
      websiteUrl?: string | null;
      hqLocations?: any | null;
      team?: any | null;
    }>;
  } | null;
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    nodes: Array<
      { __typename?: "LgCompany" } & { " $fragmentRefs"?: { LgCompanyFragmentFragment: LgCompanyFragmentFragment } }
    >;
  } | null;
};

export type SearchCompaniesLocalGlobeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<LgCompanyFilter>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesLocalGlobeQuery = {
  __typename?: "Query";
  results?: {
    __typename?: "LgCompaniesConnection";
    nodes: Array<
      { __typename?: "LgCompany" } & { " $fragmentRefs"?: { LgCompanyFragmentFragment: LgCompanyFragmentFragment } }
    >;
  } | null;
};

export type SearchCompaniesDealroomQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<DealroomCompanyFilter>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesDealroomQuery = {
  __typename?: "Query";
  results?: {
    __typename?: "DealroomCompaniesConnection";
    nodes: Array<{
      __typename?: "DealroomCompany";
      id: any;
      image?: string | null;
      path?: string | null;
      industries?: any | null;
      name?: string | null;
      about?: string | null;
      tagline?: string | null;
      websiteUrl?: string | null;
      hqLocations?: any | null;
      team?: any | null;
    }>;
  } | null;
};

export type MeetThePortfolioQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<OpsFundFilter>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type MeetThePortfolioQuery = {
  __typename?: "Query";
  opsFunds?: {
    __typename?: "OpsFundsConnection";
    nodes: Array<{
      __typename?: "OpsFund";
      name: string;
      id: any;
      mtp?: any | null;
      mtpCompanyfundsByMtpFundId: {
        __typename?: "MtpCompanyfundsConnection";
        nodes: Array<{
          __typename?: "MtpCompanyfund";
          breakeven?: boolean | null;
          capitalConcentration?: string | null;
          companyName?: string | null;
          companyType?: string | null;
          navFundSize?: number | null;
          evReturnFund?: string | null;
          revenue?: string | null;
          yoyGrowth?: string | null;
          margin?: string | null;
          followOnCapital?: string | null;
          freeCashFlow?: boolean | null;
          fte?: string | null;
          runway?: string | null;
          fund?: string | null;
          id: number;
          mtpCompany?: {
            __typename?: "LgCompany";
            id: any;
            badges?: any | null;
            round?: string | null;
            industries?: any | null;
            city: string;
            country: string;
            address: string;
            name: string;
            image: string;
            tags?: any | null;
            rating?: number | null;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type OpsCompaniesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  filter?: InputMaybe<OpsCompanyFilter>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type OpsCompaniesQuery = {
  __typename?: "Query";
  opsCompanies?: {
    __typename?: "OpsCompaniesConnection";
    edges: Array<{
      __typename?: "OpsCompaniesEdge";
      node: {
        __typename?: "OpsCompany";
        srcCompany?: {
          __typename?: "SrcCompany";
          opsCompanyAsSource?: {
            __typename?: "OpsCompany";
            opsCompanyfundsByCompanyId: {
              __typename?: "OpsCompanyfundsConnection";
              nodes: Array<{ __typename?: "OpsCompanyfund" } & { " $fragmentRefs"?: { FundFragment: FundFragment } }>;
            };
          } | null;
        } | null;
      };
    }>;
    nodes: Array<{
      __typename?: "OpsCompany";
      id: any;
      name: string;
      totalInvestedCapital?: number | null;
      totalNav?: number | null;
      investmentType?: string | null;
      initialInvestmentRatio?: number | null;
      initialInvestment?: number | null;
    }>;
  } | null;
};

export type OpsFundsQueryVariables = Exact<{
  filter?: InputMaybe<OpsCompanyfundFilter>;
  orderBy?: InputMaybe<Array<OpsCompanyfundsOrderBy> | OpsCompanyfundsOrderBy>;
}>;

export type OpsFundsQuery = {
  __typename?: "Query";
  opsCompanyfunds?: {
    __typename?: "OpsCompanyfundsConnection";
    edges: Array<{
      __typename?: "OpsCompanyfundsEdge";
      node: { __typename?: "OpsCompanyfund" } & { " $fragmentRefs"?: { FundFragment: FundFragment } };
    }>;
  } | null;
};

export type OpportunityActionMutationVariables = Exact<{
  action: Action;
  actionPayloadInput?: InputMaybe<ActionPayloadInput>;
  entity: Entity;
}>;

export type OpportunityActionMutation = { __typename?: "Mutation"; opportunityAction?: any | null };

export type AddOpportunityMutationVariables = Exact<{
  input: ManualOpportunityInput;
}>;

export type AddOpportunityMutation = { __typename?: "Mutation"; opportunityNew?: any | null };

export type UpdateOpportunityMutationVariables = Exact<{
  opportunityId: Scalars["String"]["input"];
  input: UpdateCompanyInput;
}>;

export type UpdateOpportunityMutation = { __typename?: "Mutation"; opportunityUpdate?: any | null };

export type FlagCompanyMutationVariables = Exact<{
  input: FlagCompanyInput;
}>;

export type FlagCompanyMutation = { __typename?: "Mutation"; companyFlags?: any | null };

export type EmailOpportunityMutationVariables = Exact<{
  input: EmailOpportunityInput;
}>;

export type EmailOpportunityMutation = { __typename?: "Mutation"; opportunityEmail?: any | null };

export type UnAssignUserActionMutationVariables = Exact<{
  input: UnAssignPayloadInput;
}>;

export type UnAssignUserActionMutation = { __typename?: "Mutation"; unAssignUser?: any | null };

export type TopInvestorsQueryVariables = Exact<{ [key: string]: never }>;

export type TopInvestorsQuery = {
  __typename?: "Query";
  lgInvestors?: {
    __typename?: "LgInvestorsConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "LgInvestor"; id: any; name?: string | null; lgRanking?: any | null }>;
  } | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: UserFeedbackInput;
}>;

export type SubmitFeedbackMutation = { __typename?: "Mutation"; submitFeedback?: any | null };

export type TagUserCompanyMutationVariables = Exact<{
  input: TagCompanyInput;
}>;

export type TagUserCompanyMutation = { __typename?: "Mutation"; userCompanyTags?: any | null };

export type UploadCompanyAttachmentMutationVariables = Exact<{
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
}>;

export type UploadCompanyAttachmentMutation = { __typename?: "Mutation"; companyAttachmentUpload?: any | null };

export type SentimentQueryGetQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  userId: Scalars["UUID"]["input"];
}>;

export type SentimentQueryGetQuery = {
  __typename?: "Query";
  nzrSentimentForm?:
    | ({
        __typename?: "NzrSentimentForm";
        nzrSentimentFormAssignees: {
          __typename?: "NzrSentimentFormAssigneesConnection";
          nodes: Array<{ __typename?: "NzrSentimentFormAssignee"; id: any }>;
        };
        company?: {
          __typename?: "LgCompany";
          id: any;
          isOpCompany: boolean;
          companyFlags: {
            __typename?: "LgCompanyFlagsConnection";
            items: Array<
              { __typename?: "LgCompanyFlag" } & {
                " $fragmentRefs"?: { CompanyFlagFragmentFragment: CompanyFlagFragmentFragment };
              }
            >;
          };
        } | null;
      } & { " $fragmentRefs"?: { NzrSentimentFormFragFragment: NzrSentimentFormFragFragment } })
    | null;
};

export type RequestSentimentFormMutationVariables = Exact<{
  input?: InputMaybe<NewSentimentInput>;
}>;

export type RequestSentimentFormMutation = { __typename?: "Mutation"; sentimentRequest?: any | null };

export type DeclineSentimentFormMutationVariables = Exact<{
  input?: InputMaybe<DeclineSentimentInput>;
}>;

export type DeclineSentimentFormMutation = { __typename?: "Mutation"; sentimentDecline?: any | null };

export type FillSentimentFormMutationVariables = Exact<{
  input?: InputMaybe<FillSentimentInput>;
}>;

export type FillSentimentFormMutation = { __typename?: "Mutation"; sentimentFill?: any | null };

export const AuthResponseFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthResponseFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AuthResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accessToken" } },
          { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "user" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthResponseFragmentFragment, unknown>;
export const NzrNotificationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NzrNotificationFragmentFragment, unknown>;
export const LgCompanyFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgCompanyFragmentFragment, unknown>;
export const SignalCompanyFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Signal" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rule" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "runner" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "output" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignalCompanyFragmentFragment, unknown>;
export const CompanyFlagFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanyFlagFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFlag" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdBy" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "flag" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyFlagFragmentFragment, unknown>;
export const CompanySourceFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanySourceFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SrcCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "sourceType" } },
          { kind: "Field", name: { kind: "Name", value: "sourceId" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "about" } },
                { kind: "Field", name: { kind: "Name", value: "tagline" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "crunchbaseUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealroomNewsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "news" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "author" } },
                            { kind: "Field", name: { kind: "Name", value: "pubDate" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "summary" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "link" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trelloCardAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "shortLinkHash" } },
                { kind: "Field", name: { kind: "Name", value: "listId" } },
                { kind: "Field", name: { kind: "Name", value: "boardId" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "list" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "dateLastActivity" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "currentEv" } },
                            { kind: "Field", name: { kind: "Name", value: "currentNav" } },
                            { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
                            { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
                            { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fund" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mnEntryAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "data" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "member" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanySourceFragmentFragment, unknown>;
export const LgFundingRoundFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          { kind: "Field", name: { kind: "Name", value: "amountEurMillion" } },
          { kind: "Field", name: { kind: "Name", value: "amountSource" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "month" } },
          { kind: "Field", name: { kind: "Name", value: "round" } },
          { kind: "Field", name: { kind: "Name", value: "valuation" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgFundingRoundFragmentFragment, unknown>;
export const LgInvestorFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecamp" } },
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fundLead" },
            name: { kind: "Name", value: "lgMemberByRefFundLead" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgInvestorFragmentFragment, unknown>;
export const LgFundingRoundInvestorFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRoundInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "lead" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "investor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgInvestorFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecamp" } },
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fundLead" },
            name: { kind: "Name", value: "lgMemberByRefFundLead" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgFundingRoundInvestorFragmentFragment, unknown>;
export const NzrSentimentFormFragFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NzrSentimentFormFragFragment, unknown>;
export const LgTeamFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgTeamFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyTeam" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "titles" } },
          { kind: "Field", name: { kind: "Name", value: "isFounder" } },
          { kind: "Field", name: { kind: "Name", value: "yearEnd" } },
          { kind: "Field", name: { kind: "Name", value: "yearStart" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "companyTeams" },
            name: { kind: "Name", value: "srcCompanyTeamsByEntityId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "member" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "forms" },
                  name: { kind: "Name", value: "nzrSentimentFormsByFounderId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgTeamFragmentFragment, unknown>;
export const FundFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundFragment, unknown>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginWithGoogle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "googleAuthCodeInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "code" },
                      value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AuthResponseFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthResponseFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AuthResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accessToken" } },
          { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "user" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RefreshToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "refreshRequestInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "refreshToken" },
                      value: { kind: "Variable", name: { kind: "Name", value: "token" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AuthResponseFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthResponseFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AuthResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accessToken" } },
          { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "user" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const GetNotificationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNotifications" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "NzrNotificationFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "NzrNotificationsOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrNotificationFragment" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const SetNotificationStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetNotificationStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "status" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateNzrNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "patch" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: { kind: "Variable", name: { kind: "Name", value: "status" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrNotification" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "NzrNotificationFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetNotificationStatusMutation, SetNotificationStatusMutationVariables>;
export const GetOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrOpportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "from" } },
                { kind: "Field", name: { kind: "Name", value: "to" } },
                { kind: "Field", name: { kind: "Name", value: "labels" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "isParsed" } },
                { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "parser" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "memberId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrOpportunityEmailCandidatesByEmailId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dealroomCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "path" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "websiteDomain" },
                                    name: { kind: "Name", value: "websiteDomainFull" },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lgCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteDomain" } },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>;
export const SetOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EmailOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetOpportunityEmailMutation, SetOpportunityEmailMutationVariables>;
export const RefreshMtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RefreshMTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshMTP" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "payloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshMtpMutation, RefreshMtpMutationVariables>;
export const CompanyAttachmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CompanyAttachments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanyFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "file" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "path" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "source" } },
                            { kind: "Field", name: { kind: "Name", value: "metadata" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lgMemberByCreatedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                  { kind: "Field", name: { kind: "Name", value: "username" } },
                                  { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                  { kind: "Field", name: { kind: "Name", value: "initials" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>;
export const DeleteCompanyAttachmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCompanyAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "fileId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyAttachmentDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyAttachmentDeleteInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fileId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "fileId" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCompanyAttachmentMutation, DeleteCompanyAttachmentMutationVariables>;
export const GetCompanyCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanyComments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "metadata" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lgMemberByCreatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "initials" } },
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                            { kind: "Field", name: { kind: "Name", value: "avatar" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>;
export const AddCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "text" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CommentMetadataInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: { kind: "Variable", name: { kind: "Name", value: "text" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "metadata" },
                      value: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCompanyCommentMutation, AddCompanyCommentMutationVariables>;
export const UpdateCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "text" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CommentMetadataInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentUpdateInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "commentId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: { kind: "Variable", name: { kind: "Name", value: "text" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "metadata" },
                      value: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyCommentMutation, UpdateCompanyCommentMutationVariables>;
export const DeleteCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentDeleteInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "commentId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCompanyCommentMutation, DeleteCompanyCommentMutationVariables>;
export const GetForwardedCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetForwardedCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "StringValue", value: "email_opportunity", block: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "parser" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "OPPORTUNITY_EMAIL", block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "subject" } },
                                  { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                                  { kind: "Field", name: { kind: "Name", value: "body" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>;
export const GetCompanyUpdatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyUpdates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "parser" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "in" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                      { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "subject" } },
                                  { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                                  { kind: "Field", name: { kind: "Name", value: "from" } },
                                  { kind: "Field", name: { kind: "Name", value: "to" } },
                                  { kind: "Field", name: { kind: "Name", value: "body" } },
                                  { kind: "Field", name: { kind: "Name", value: "source" } },
                                  { kind: "Field", name: { kind: "Name", value: "attachments" } },
                                  { kind: "Field", name: { kind: "Name", value: "metadata" } },
                                  { kind: "Field", name: { kind: "Name", value: "parser" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>;
export const GetSentimentFormRequestsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSentimentFormRequests" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "completed" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrSentimentForms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_AT_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "some" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "memberId" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "equalTo" },
                                        value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "completed" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "equalTo" },
                                        value: { kind: "Variable", name: { kind: "Name", value: "completed" } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "formStatus" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                  { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "state" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "member" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                              { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "founder" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrNotifications" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "assignee" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>;
export const GetFeedbackTemplatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFeedbackTemplates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "StringValue", value: "feedback_email", block: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>;
export const GetDashboardCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDashboardCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgCompaniesOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "badges" } },
                            { kind: "Field", name: { kind: "Name", value: "city" } },
                            { kind: "Field", name: { kind: "Name", value: "country" } },
                            { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
                            { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "userTags" },
                              name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                        { kind: "Field", name: { kind: "Name", value: "userId" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>;
export const GetUserHighlightsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserHighlights" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Datetime" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Datetime" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentAdded" },
                  name: { kind: "Name", value: "mnEntriesByMemberId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tableName" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "lg_company", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "previousAdded" },
                  name: { kind: "Name", value: "mnEntriesByMemberId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tableName" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "lg_company", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "lessThan" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentCompanyUpdates" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "assignee" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "parser" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "in" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                            { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "previousCompanyUpdates" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "assignee" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "lessThan" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "parser" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "in" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                            { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pendingFeedback" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "feedback_email", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "in" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pendingSentimentForms" },
                  name: {
                    kind: "Name",
                    value: "nzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormId",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "some" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "memberId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: false },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "competedSentimentForms" },
                  name: {
                    kind: "Name",
                    value: "nzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormId",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "some" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "memberId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: true },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "actionedAt" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "follows" },
                  name: { kind: "Name", value: "nzrUserCompanyTagsByUserId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tags" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "contains" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "follow" },
                                        value: { kind: "StringValue", value: "1", block: false },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "inReview" },
                  name: { kind: "Name", value: "nzrOpportunitiesByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "CREATED", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "signal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "company" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "isOpCompany" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: false },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "none" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "trelloCardAsSource" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "listId" },
                                                          value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: { kind: "Name", value: "in" },
                                                                value: {
                                                                  kind: "ListValue",
                                                                  values: [
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "558c7d47afd9cbf4ccc4c969",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bda26969461ac8bbed1d0f8",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "637646faa0bfe00221715461",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "59372e2427706549525b33d7",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bda298f418a8f6cd8e10d12",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "62c6c4f042096b8c33418d79",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "61559487ab1c8d67d4e06a6d",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5626767dccf2aa74870b6bab",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bdae47c6c0728111d122923",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "63764680c2233703ffa09dd2",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "561fe5725d03faeba0a20b5e",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5d4bfb8eb30b468aa3ada3ff",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "619b69465c658d509f7de1b0",
                                                                      block: false,
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "none" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "userId" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "equalTo" },
                                                          value: {
                                                            kind: "Variable",
                                                            name: { kind: "Name", value: "userId" },
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "tags" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "contains" },
                                                          value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: { kind: "Name", value: "hide" },
                                                                value: {
                                                                  kind: "StringValue",
                                                                  value: "1",
                                                                  block: false,
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>;
export const NzrOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NzrOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrOpportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "googleMessageId" } },
                { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                { kind: "Field", name: { kind: "Name", value: "isParsed" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NzrOpportunityEmailQuery, NzrOpportunityEmailQueryVariables>;
export const GetFileUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFileUrl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filePath" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "responseGetFilePresignedUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "file" },
                value: { kind: "Variable", name: { kind: "Name", value: "filePath" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFileUrlQuery, GetFileUrlQueryVariables>;
export const LgMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LgMembers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "before" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "condition" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgMemberCondition" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgMemberFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "first" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgMembersOrderBy" } },
            },
          },
          defaultValue: { kind: "ListValue", values: [{ kind: "EnumValue", value: "PRIMARY_KEY_ASC" }] },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMembers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: { kind: "Variable", name: { kind: "Name", value: "before" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: { kind: "Variable", name: { kind: "Name", value: "condition" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "first" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: { kind: "Variable", name: { kind: "Name", value: "last" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "avatar" } },
                      { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "isInvestor" } },
                      { kind: "Field", name: { kind: "Name", value: "lastModifiedUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgMembersQuery, LgMembersQueryVariables>;
export const LgMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LgMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lgMemberSettingsByUserId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "feedbackEmailTemplate" } }],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "isInvestor" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LgMemberQuery, LgMemberQueryVariables>;
export const UpdateUserSettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserSettings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UserSettingsPayloadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserSettings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userSettingsPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const UpsertMetricsExtractionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsertMetricsExtraction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lgCompanyMetricsInput" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyMetricsInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertMetricsExtraction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "lgCompanyMetricsInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "lgCompanyMetricsInput" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertMetricsExtractionMutation, UpsertMetricsExtractionMutationVariables>;
export const CompanyDetailsMetricsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "companyDetailsMetrics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trelloCardAsSource" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "list" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyDetailsMetricsQuery, CompanyDetailsMetricsQueryVariables>;
export const GetCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "companiesSources" },
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "CompanySourceFragment" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "signals" },
                  name: { kind: "Name", value: "signalsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }] },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "SignalCompanyFragment" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "asignees" },
                              name: { kind: "Name", value: "lgMembersByNzrOpportunitySignalIdAndAssignee" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                        { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                        { kind: "Field", name: { kind: "Name", value: "initials" } },
                                        { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunities" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "lgMemberByAssignee" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "email" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "status" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "fundingRounds" },
                  name: { kind: "Name", value: "lgFundingRoundsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "YEAR_DESC" },
                          { kind: "EnumValue", value: "MONTH_DESC" },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "LgFundingRoundFragment" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "investors" },
                              name: { kind: "Name", value: "lgFundingRoundInvestorsByFundingRoundId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "LgFundingRoundInvestorFragment" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "sentiment" },
                  name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "founderExists" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "filled" },
                              name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: true },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "member" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "notFilled" },
                              name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "distinctFrom" },
                                              value: { kind: "BooleanValue", value: true },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "member" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "team" },
                  name: { kind: "Name", value: "lgCompanyTeamsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgTeamFragment" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "investors" },
                  name: { kind: "Name", value: "lgInvestorsByLgCompanyInvestorCompanyIdAndInvestorId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgInvestorFragment" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "metrics" },
                  name: { kind: "Name", value: "lgCompanyMetricsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "asAtDate" } },
                            { kind: "Field", name: { kind: "Name", value: "data" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pcgOverview" },
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                            { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "opsCompanyAsSource" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                                  { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                                  { kind: "Field", name: { kind: "Name", value: "investmentType" } },
                                  { kind: "Field", name: { kind: "Name", value: "isStale" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "hqCity" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "companyFunds" },
                                    name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "nodes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mtpCompanyfundsByMtpCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fund" } },
                            { kind: "Field", name: { kind: "Name", value: "capitalConcentration" } },
                            { kind: "Field", name: { kind: "Name", value: "runway" } },
                            { kind: "Field", name: { kind: "Name", value: "revenue" } },
                            { kind: "Field", name: { kind: "Name", value: "yoyGrowth" } },
                            { kind: "Field", name: { kind: "Name", value: "margin" } },
                            { kind: "Field", name: { kind: "Name", value: "fte" } },
                            { kind: "Field", name: { kind: "Name", value: "runway" } },
                            { kind: "Field", name: { kind: "Name", value: "evReturnFund" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "userTags" },
                  name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "userId" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "companyFlags" },
                  name: { kind: "Name", value: "lgCompanyFlagsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deletedAt" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "isNull" },
                                  value: { kind: "BooleanValue", value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                            { kind: "Field", name: { kind: "Name", value: "companyId" } },
                            { kind: "Field", name: { kind: "Name", value: "flag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecamp" } },
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fundLead" },
            name: { kind: "Name", value: "lgMemberByRefFundLead" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanySourceFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SrcCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "sourceType" } },
          { kind: "Field", name: { kind: "Name", value: "sourceId" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "about" } },
                { kind: "Field", name: { kind: "Name", value: "tagline" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "crunchbaseUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealroomNewsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "news" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "author" } },
                            { kind: "Field", name: { kind: "Name", value: "pubDate" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "summary" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "link" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trelloCardAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "shortLinkHash" } },
                { kind: "Field", name: { kind: "Name", value: "listId" } },
                { kind: "Field", name: { kind: "Name", value: "boardId" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "list" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "dateLastActivity" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "currentEv" } },
                            { kind: "Field", name: { kind: "Name", value: "currentNav" } },
                            { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
                            { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
                            { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fund" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mnEntryAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "data" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "member" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Signal" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rule" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "runner" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "output" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          { kind: "Field", name: { kind: "Name", value: "amountEurMillion" } },
          { kind: "Field", name: { kind: "Name", value: "amountSource" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "month" } },
          { kind: "Field", name: { kind: "Name", value: "round" } },
          { kind: "Field", name: { kind: "Name", value: "valuation" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRoundInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "lead" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "investor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgInvestorFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgTeamFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyTeam" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "titles" } },
          { kind: "Field", name: { kind: "Name", value: "isFounder" } },
          { kind: "Field", name: { kind: "Name", value: "yearEnd" } },
          { kind: "Field", name: { kind: "Name", value: "yearStart" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "companyTeams" },
            name: { kind: "Name", value: "srcCompanyTeamsByEntityId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "member" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "forms" },
                  name: { kind: "Name", value: "nzrSentimentFormsByFounderId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgCompaniesOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } },
                            { kind: "Field", name: { kind: "Name", value: "badges" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "companiesSources" },
                              name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "CompanySourceFragment" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "signals" },
                              name: { kind: "Name", value: "signalsByCompanyId" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "first" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "SignalCompanyFragment" },
                                        },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "asignees" },
                                          name: { kind: "Name", value: "lgMembersByNzrOpportunitySignalIdAndAssignee" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "nodes" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                                    { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                                    { kind: "Field", name: { kind: "Name", value: "initials" } },
                                                    { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                                    { kind: "Field", name: { kind: "Name", value: "email" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "sentiment" },
                              name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "founderExists" },
                                        value: { kind: "BooleanValue", value: false },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "NzrSentimentFormFrag" },
                                        },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "filled" },
                                          name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "filter" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "completed" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "equalTo" },
                                                          value: { kind: "BooleanValue", value: true },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "nodes" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "member" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "id" } },
                                                          { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                                          { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "notFilled" },
                                          name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "filter" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "completed" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "distinctFrom" },
                                                          value: { kind: "BooleanValue", value: true },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "nodes" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "member" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "id" } },
                                                          { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                                          { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "fundingRounds" },
                              name: { kind: "Name", value: "lgFundingRoundsByCompanyId" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "EnumValue", value: "YEAR_DESC" },
                                      { kind: "EnumValue", value: "MONTH_DESC" },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "first" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "LgFundingRoundFragment" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "userTags" },
                              name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                        { kind: "Field", name: { kind: "Name", value: "userId" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanySourceFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SrcCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "sourceType" } },
          { kind: "Field", name: { kind: "Name", value: "sourceId" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "about" } },
                { kind: "Field", name: { kind: "Name", value: "tagline" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "crunchbaseUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealroomNewsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "news" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "author" } },
                            { kind: "Field", name: { kind: "Name", value: "pubDate" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "summary" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "link" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trelloCardAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "shortLinkHash" } },
                { kind: "Field", name: { kind: "Name", value: "listId" } },
                { kind: "Field", name: { kind: "Name", value: "boardId" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "list" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "dateLastActivity" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "currentEv" } },
                            { kind: "Field", name: { kind: "Name", value: "currentNav" } },
                            { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
                            { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
                            { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fund" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mnEntryAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "data" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "member" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Signal" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rule" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "runner" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "output" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          { kind: "Field", name: { kind: "Name", value: "amountEurMillion" } },
          { kind: "Field", name: { kind: "Name", value: "amountSource" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "month" } },
          { kind: "Field", name: { kind: "Name", value: "round" } },
          { kind: "Field", name: { kind: "Name", value: "valuation" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const SearchCompaniesGlobalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesGlobal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DealroomCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "industries" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "about" } },
                      { kind: "Field", name: { kind: "Name", value: "tagline" } },
                      { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "hqLocations" } },
                      { kind: "Field", name: { kind: "Name", value: "team" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>;
export const SearchCompaniesLocalGlobeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesLocalGlobe" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "results" },
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>;
export const SearchCompaniesDealroomDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesDealroom" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DealroomCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "results" },
            name: { kind: "Name", value: "dealroomCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "industries" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "about" } },
                      { kind: "Field", name: { kind: "Name", value: "tagline" } },
                      { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "hqLocations" } },
                      { kind: "Field", name: { kind: "Name", value: "team" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>;
export const MeetThePortfolioDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MeetThePortfolio" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsFundFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsFunds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "mtp" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mtpCompanyfundsByMtpFundId" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "breakeven" } },
                                  { kind: "Field", name: { kind: "Name", value: "capitalConcentration" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyName" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyType" } },
                                  { kind: "Field", name: { kind: "Name", value: "navFundSize" } },
                                  { kind: "Field", name: { kind: "Name", value: "evReturnFund" } },
                                  { kind: "Field", name: { kind: "Name", value: "revenue" } },
                                  { kind: "Field", name: { kind: "Name", value: "yoyGrowth" } },
                                  { kind: "Field", name: { kind: "Name", value: "margin" } },
                                  { kind: "Field", name: { kind: "Name", value: "followOnCapital" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCashFlow" } },
                                  { kind: "Field", name: { kind: "Name", value: "fte" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCashFlow" } },
                                  { kind: "Field", name: { kind: "Name", value: "runway" } },
                                  { kind: "Field", name: { kind: "Name", value: "fund" } },
                                  { kind: "Field", name: { kind: "Name", value: "followOnCapital" } },
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "margin" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mtpCompany" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "badges" } },
                                        { kind: "Field", name: { kind: "Name", value: "round" } },
                                        { kind: "Field", name: { kind: "Name", value: "industries" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "country" } },
                                        { kind: "Field", name: { kind: "Name", value: "address" } },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "rating" },
                                          name: { kind: "Name", value: "dealroomSignalRating" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "image" } },
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>;
export const OpsCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpsCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "srcCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "opsCompanyAsSource" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "nodes" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                      { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                      { kind: "Field", name: { kind: "Name", value: "investmentType" } },
                      { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                      { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpsCompaniesQuery, OpsCompaniesQueryVariables>;
export const OpsFundsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpsFunds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfundFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfundsOrderBy" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyfunds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpsFundsQuery, OpsFundsQueryVariables>;
export const OpportunityActionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OpportunityAction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "action" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Action" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "actionPayloadInput" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ActionPayloadInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "entity" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Entity" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityAction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "action" },
                value: { kind: "Variable", name: { kind: "Name", value: "action" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "actionPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "actionPayloadInput" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "entity" },
                value: { kind: "Variable", name: { kind: "Name", value: "entity" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpportunityActionMutation, OpportunityActionMutationVariables>;
export const AddOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ManualOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityNew" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "manualOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddOpportunityMutation, AddOpportunityMutationVariables>;
export const UpdateOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "opportunityId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateCompanyInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "opportunityId" },
                value: { kind: "Variable", name: { kind: "Name", value: "opportunityId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>;
export const FlagCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FlagCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "FlagCompanyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyFlags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "flagCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlagCompanyMutation, FlagCompanyMutationVariables>;
export const EmailOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EmailOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EmailOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailOpportunityMutation, EmailOpportunityMutationVariables>;
export const UnAssignUserActionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnAssignUserAction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UnAssignPayloadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unAssignUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "unAssignPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnAssignUserActionMutation, UnAssignUserActionMutationVariables>;
export const TopInvestorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TopInvestors" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgInvestors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lgRanking" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "isNull" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "LG_RANKING_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "lgRanking" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopInvestorsQuery, TopInvestorsQueryVariables>;
export const SubmitFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UserFeedbackInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userFeedbackInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;
export const TagUserCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TagUserCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "TagCompanyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userCompanyTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TagUserCompanyMutation, TagUserCompanyMutationVariables>;
export const UploadCompanyAttachmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadCompanyAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "path" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyAttachmentUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyAttachmentInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: { kind: "Variable", name: { kind: "Name", value: "name" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "filePath" },
                      value: { kind: "Variable", name: { kind: "Name", value: "path" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadCompanyAttachmentMutation, UploadCompanyAttachmentMutationVariables>;
export const SentimentQueryGetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SentimentQueryGet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrSentimentForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "memberId" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "state" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "notEqualTo" },
                                  value: { kind: "StringValue", value: "COMPLETED", block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "companyFlags" },
                        name: { kind: "Name", value: "lgCompanyFlagsByCompanyId" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "deletedAt" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "isNull" },
                                        value: { kind: "BooleanValue", value: true },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "items" },
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "CompanyFlagFragment" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanyFlagFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFlag" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdBy" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "flag" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentimentQueryGetQuery, SentimentQueryGetQueryVariables>;
export const RequestSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RequestSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "NewSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "newSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestSentimentFormMutation, RequestSentimentFormMutationVariables>;
export const DeclineSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DeclineSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentDecline" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "declineSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeclineSentimentFormMutation, DeclineSentimentFormMutationVariables>;
export const FillSentimentFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FillSentimentForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FillSentimentInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sentimentFill" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fillSentimentInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FillSentimentFormMutation, FillSentimentFormMutationVariables>;
