import { Fragment } from "react";

import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Icon, Text, Button } from "@/app/components";
import { tabsDesktop } from "@/app/components/navbar/header.store";
import { IconMenu } from "@/app/components/navbar/icon-menu";
import { AppVersion } from "@/app/router/app-version";
import { AddNewResponsiveModal } from "@/app/screens/modal/add-new";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { Search } from "@/app/screens/modal/search";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { OpportunitiesTab, OpportunitiesTabType } from "@/app/screens/opportunities/opportunities.store.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import modalStore from "@/app/stores/modal.store";

export const VerticalNavbar = observer(() => {
  const navigate = useNavigate();
  const { isSmallLaptop } = useBreakpoints();
  const { isOpen } = globalModalStore.navbarState;
  const { pathname } = useLocation();

  const { user } = authStore;

  return (
    <>
      {/*WEB*/}
      <motion.div
        className={`z-30 hidden rounded-r-xl lg:flex lg:h-screen`}
        variants={{
          open: {
            width: isSmallLaptop ? "16rem" : "14rem",
            minWidth: isSmallLaptop ? "16rem" : "14rem",
          },
          closed: {
            width: "5rem",
            minWidth: "5rem",
          },
        }}
        initial={isOpen}
        animate={isOpen ? "open" : "closed"}
        transition={{ duration: 0.1, easeInOut: true }}
      >
        <motion.div
          variants={{
            open: {
              width: isSmallLaptop ? "16rem" : "14rem",
              borderBottomRightRadius: "32px",
              borderTopRightRadius: "32px",
            },
            closed: { width: "5rem" },
          }}
          initial={isOpen}
          animate={isOpen ? "open" : "closed"}
          transition={{ duration: 0.1, easeInOut: true }}
          className="fixed inset-y-0 w-fit items-center rounded-r-xl bg-white shadow-[12px_0px_24px_0px_rgba(0,0,0,0.01)] lg:flex lg:flex-col lg:px-5 lg:py-1"
        >
          <div
            className={`mt-2.5 flex p-2 hover:cursor-pointer ${
              !isOpen ? "w-fit items-center justify-center" : "w-full items-end justify-end"
            }`}
          >
            {isOpen ? (
              <ChevronLeftIcon
                className={"size-8 rounded-full p-1 text-neutral-400 2xl:size-9"}
                onClick={() => {
                  globalModalStore.toggleNavBar();
                }}
              />
            ) : (
              <ChevronRightIcon
                className={"size-8 self-end rounded-full p-1 text-neutral-400 2xl:size-9"}
                onClick={() => {
                  globalModalStore.toggleNavBar();
                }}
              />
            )}
          </div>

          <div
            className={cn(
              "my-8 flex h-fit items-center justify-center transition-all duration-100 ease-in-out",
              isOpen ? "opacity-100" : "opacity-0",
            )}
          >
            <Icon type={"Logo"} className={"xl:w-24 "} />
          </div>

          <div className={"w-full space-y-5"}>
            <Button
              className={cn(
                "flex w-full min-w-[0px] justify-center rounded-sm hover:opacity-90 active:opacity-75",
                isOpen ? "" : "p-2.5",
              )}
              onClick={() => {
                modalStore.open(AddNewResponsiveModal);
              }}
              variant="gradient"
            >
              <Icon type={"Add"} className={cn("size-4.5 text-white 2xl:size-5", isOpen ? "lg:mr-4 2xl:mr-5" : "")} />
              {isOpen && (
                <Text text={"Add New..."} className={"mr-auto lg:text-[12px] 2xl:text-[14px]"} weight={"medium"} />
              )}
            </Button>
            <Button
              className={cn(
                "flex w-full min-w-[0px] justify-center rounded-sm",
                // overriding button styles according to design
                "bg-neutral-100 hover:bg-neutral-200",
                isOpen ? "" : "p-2.5",
              )}
              onClick={() => modalStore.open(Search)}
              variant="secondary"
            >
              <Icon
                type={"Search"}
                className={cn("size-4.5 text-neutral-400 2xl:size-5", isOpen ? "mr-5" : "")}
                height={20}
                width={20}
              />
              {isOpen && (
                <>
                  <Text
                    text={"Search..."}
                    weight={"medium"}
                    className={"mr-auto text-neutral-400 lg:text-[12px] 2xl:text-[14px]"}
                  />
                  <Icon type="Cmd" height={20} width={20} className={"mr-1 size-4.5 text-neutral-400 2xl:size-5"} />
                  <Icon type="CmdKey" height={20} width={20} className={"size-4.5 text-neutral-400 2xl:size-5"} />
                </>
              )}
            </Button>
          </div>
          <nav className="flex size-full flex-1 flex-col justify-between py-5">
            <ul role="list" className={cn("flex w-full flex-col space-y-1", isOpen ? "items-start" : "items-center")}>
              {tabsDesktop.map((group, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <div className={cn("w-full py-4")}>
                      <div className={"border-t border-t-gray-200"} />
                    </div>
                  )}
                  {group.map(({ name, Icon, path, disabled }) => (
                    <Fragment key={name}>
                      <motion.li
                        transition={{ duration: 0.05, easeInOut: true }}
                        onClick={() => {
                          if (disabled) {
                            return;
                          }
                          if (path in OpportunitiesTab) {
                            opportunitiesStore.setState({
                              currentTab: {
                                path: path as OpportunitiesTabType,
                                quickView: "all",
                              },
                            });
                          }
                          navigate(path);
                        }}
                        className={cn(
                          `group select-none hover:cursor-pointer hover:rounded-xl xl:py-1 2xl:py-2`,
                          isOpen ? "pl-2 pr-3" : "px-2",
                          disabled ? "text-neutral-400" : "text-neutral-600",
                        )}
                      >
                        <Link
                          to={path}
                          className={cn(
                            pathname === `/${path}` ? "font-bold" : "font-semibold",
                            "flex w-full items-center gap-x-4.5 p-2 leading-6 text-white",
                          )}
                        >
                          <Icon
                            className={cn(
                              pathname === `/${path}`
                                ? "text-secondary"
                                : "text-neutral-400 group-hover:text-neutral-600",
                              "size-4.5 2xl:size-5",
                            )}
                          />
                          <Text
                            className={cn(
                              `
                              lg:text-[12px] 2xl:text-[14px]
                              ${isOpen ? "flex" : "hidden"}`,
                              pathname === `/${path}` ? "" : "group-hover:text-neutral-600",
                            )}
                            text={name}
                            color={pathname === `/${path}` ? "text-black" : "text-neutral-400"}
                            weight={pathname === `/${path}` ? "normal" : "medium"}
                          />

                          <div
                            className={cn(
                              "absolute right-0 h-10 rotate-180 rounded-r-md bg-gradient-to-t from-primary to-secondary transition-all ease-in-out",
                              isOpen ? "w-1.5" : "w-1",
                              pathname === `/${path}`
                                ? ""
                                : "hidden group-hover:flex group-hover:bg-gradient-to-l group-hover:from-neutral-100 group-hover:to-neutral-200",
                            )}
                          />
                          {!isOpen && (
                            <div
                              className={cn(
                                "absolute left-20 hidden w-24 translate-x-2 rounded-xs bg-white px-1.5 py-0.5 text-center text-sm font-normal text-black shadow-sm group-hover:block",
                                "before:absolute before:-left-1 before:top-1/2 before:-translate-y-1/2 before:z-[-1] before:size-2 before:rotate-45 before:bg-white",
                              )}
                            >
                              {name}
                            </div>
                          )}
                        </Link>
                      </motion.li>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </ul>

            <motion.div
              className={cn(`flex flex-col items-center justify-center lg:gap-y-1 2xl:gap-y-2`)}
              transition={{
                ease: "easeInOut",
                duration: 2,
              }}
            >
              <IconMenu user={user} />

              <div className="flex flex-col items-center ">
                <Text
                  text={`${user?.firstName} ${isOpen ? user?.lastName : ""}`}
                  color={"text-black"}
                  weight={"medium"}
                  className={"text-[12px] 2xl:text-[14px]"}
                />
                <AppVersion className={"lg:text-xss 2xl:text-[14px]"} />
              </div>
            </motion.div>
          </nav>
        </motion.div>
      </motion.div>
    </>
  );
});
