import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils.ts";

import { useTable, useTableDensity } from "../table.hooks.ts";

import { DefaultHeaderCell } from "./table-header-cell.tsx";
import { Row } from "./table-row.tsx";

const DefaultHeader = ({ className }: { className?: string }) => {
  const tableProps = useTable();
  const { config } = useTableDensity();

  return (
    <thead className={cn(className, config.header)}>
      {tableProps.table?.getHeaderGroups().map((headerGroup) => (
        <Row key={headerGroup.id} className={"w-full"}>
          {headerGroup.headers.map((header) => (
            <DefaultHeaderCell key={header.id} header={header} />
          ))}
        </Row>
      ))}
    </thead>
  );
};

export const Header = (props: PropsWithChildren<{ className?: string }>) => {
  const { config } = useTableDensity();

  if ("children" in props) {
    return <thead className={cn(config.header, props.className)}>{props.children}</thead>;
  }

  return <DefaultHeader className={props.className} />;
};
