import { FieldValues, UseFormReturn } from "react-hook-form";

import { SearchCompaniesGlobalQuery } from "@/app/service/opportunities.gql.ts";

export type CompanyStepComponentProps<TForm extends FieldValues> = {
  form: UseFormReturn<TForm>;
};

export type CompanyResult = LgCompanyResult | DealroomCompanyResult;
export type LgCompanyResult = NonNullable<SearchCompaniesGlobalQuery["lgCompanies"]>["nodes"][number];
export type DealroomCompanyResult = NonNullable<SearchCompaniesGlobalQuery["dealroomCompanies"]>["nodes"][number];
export type NewCompany = {
  name: string;
  image: null;
  websiteUrl: null;
  about: null;
  isNewCompany: true;
};

export const isLgCompany = (company: CompanyResult | NewCompany): company is LgCompanyResult => {
  return "__typename" in company && company.__typename === "LgCompany";
};

export const isNewCompany = (company: CompanyResult | NewCompany): company is NewCompany => {
  return "isNewCompany" in company && company.isNewCompany;
};

export const isDealroomCompany = (company: CompanyResult | NewCompany): company is DealroomCompanyResult => {
  return "__typename" in company && company.__typename === "DealroomCompany";
};
