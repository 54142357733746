import { MemberCard, Text, Popover, Icon } from "@/app/components";
import { ConfirmModal } from "@/app/screens/modal";
import { authStore } from "@/app/stores/auth.store";
import membersStore, { LGMember } from "@/app/stores/members.store";
import modalStore from "@/app/stores/modal.store";

export interface AssigneesViewProps {
  title?: string;
  assignees: LGMember[];
  inPipeline?: boolean;
  handleAddAssignee: () => void;
  handleRemoveAssignee: (id: string) => void;
}

export const Assignees = ({
  assignees,
  title = "Assigned for Review",
  inPipeline = false,
  handleAddAssignee,
  handleRemoveAssignee,
}: AssigneesViewProps) => {
  return (
    <div>
      <Text type="subtitle" className="font-[500] text-neutral-900">
        {title}
      </Text>
      <div className="my-1 flex w-full items-start">
        <div className="flex max-w-[217px] flex-wrap">
          {assignees.map(({ id, firstName, lastName, avatar, email }) => {
            const trelloUser = membersStore.getByEmail(email);
            return (
              <Popover
                key={id}
                childrenClassName="mt-2"
                childrenContent={
                  <Text className="text-xss font-[500]">
                    {firstName} {lastName}
                  </Text>
                }
              >
                <div className="group relative">
                  <MemberCard
                    key={id}
                    firstname={firstName}
                    lastname={lastName}
                    image={trelloUser?.avatar}
                    includeName={false}
                    imgClassName={"w-[28px] h-[28px] rounded-[7px] border-white mr-2 mb-2"}
                    className={"flex justify-start"}
                  />
                  {!inPipeline && (
                    <Icon
                      type="CloseCircle"
                      className="absolute -top-2 right-0 hidden size-4 overflow-hidden rounded-[50%] bg-white group-hover:block"
                      onClick={() =>
                        modalStore.open(ConfirmModal, {
                          props: {
                            title:
                              authStore.userId === id
                                ? "Are you sure this Company is not relevant to you?"
                                : `Remove ${firstName} ${lastName}?`,
                            subTitle:
                              authStore.userId === id
                                ? "By confirming, you will be unassigned from the company"
                                : `Are you sure you want to remove ${firstName} ${lastName} from reviewing this company?`,
                            icon:
                              authStore.userId !== id ? (
                                <div className="flex w-full justify-center">
                                  <MemberCard
                                    key={id}
                                    firstname={firstName}
                                    lastname={lastName}
                                    image={avatar}
                                    includeName={false}
                                    imgClassName={"w-[68px] h-[68px] rounded-[17px] border-white mb-4"}
                                    className={"flex justify-start"}
                                  />
                                </div>
                              ) : undefined,
                            handleConfirm: () => handleRemoveAssignee(id),
                          },
                        })
                      }
                    />
                  )}
                </div>
              </Popover>
            );
          })}
        </div>
        <Popover childrenClassName="mt-2" childrenContent={<Text className="text-xss font-[500]">Add</Text>}>
          <button
            className="flex size-7 items-center justify-center rounded-[7px] border-white bg-neutral-300"
            onClick={handleAddAssignee}
          >
            <Icon type="Add" className="size-4" />
          </button>
        </Popover>
      </div>
    </div>
  );
};
