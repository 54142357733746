import { MetricType } from "@/app/screens/metrics-extraction/metrics-extraction.store.ts";

export type MetricLabelType = { label: string; value: { type: MetricType; format?: string; formatInfo?: any } };

export const metricTypes: Array<MetricLabelType> = [
  { label: "Text", value: { type: "string" } },
  { label: "Number", value: { type: "number" } },
  { label: "Date", value: { type: "date", format: "date" } },
  { label: "Yes/No", value: { type: "boolean", format: "boolean" } },
  { label: "GBP", value: { type: "number", format: "currency", formatInfo: { currencySymbol: "GBP" } } },
  { label: "USD", value: { type: "number", format: "currency", formatInfo: { currencySymbol: "USD" } } },
  { label: "Percentage", value: { type: "number", format: "percentage" } },
];

export const getMetricType = (item) => {
  if (item.format === "currency") return item.formatInfo.currencySymbol;
  if (item.format === "percentage") return "Percentage";
  if (item.format === "boolean") return "Yes/No";
  if (item.type === "date") return "Date";
  if (item.type === "number") return "Number";

  return "Text";
};

export function escapeRegExp(text) {
  return text?.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
}
