import { cn } from "@/lib/utils";

import { MemberCard, MemberCardProps, Popover, Tag, Text } from "@/app/components";
import { useSplitList } from "@/app/hooks/use-split-list";
import { LGMember } from "@/app/stores/members.store";

export interface MemberListProps extends Omit<MemberCardProps, "firstname" | "lastname"> {
  members: Pick<LGMember, "firstName" | "lastName" | "avatar">[];
  containerClassName?: string;
  split?: number;
  withTooltip?: boolean;
  label?: string;
}

export const MemberList = ({
  members,
  containerClassName,
  includeName = true,
  withTooltip = false,
  imgClassName,
  label,
  split,
  labelClassName,
}: MemberListProps) => {
  const { main, other } = useSplitList(members, split);

  return (
    <Popover
      containerClassName={cn("flex w-auto justify-start", containerClassName)}
      placement="bottom"
      childrenContent={
        withTooltip && (
          <div className="grid grid-cols-1 gap-1">
            {[...main, ...other].map(({ firstName, lastName, avatar, id }) => (
              <MemberCard
                key={id}
                firstname={firstName}
                lastname={lastName}
                image={avatar}
                includeName={true}
                imgClassName={imgClassName}
                className={"flex justify-start"}
                labelClassName="ml-1 font-[500]"
              />
            ))}
          </div>
        )
      }
    >
      {label && <Text text={label} className={cn("mr-1", labelClassName)} />}
      {main.map(({ firstName, lastName, avatar, id }) => (
        <MemberCard
          key={id}
          firstname={firstName}
          lastname={lastName}
          image={avatar}
          includeName={includeName}
          imgClassName={imgClassName}
          className="mr-1 size-6"
        />
      ))}
      {other.length > 0 && (
        <Tag label={`+${other.length}`} className={imgClassName} tagClassName="bg-transparent !px-0" />
      )}
    </Popover>
  );
};
