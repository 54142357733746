export type AttachmentsCommentsData = {
  title: [string, string];
  lastCommentDate: string;
  commentNo: number;
  lastAttachmentsDate: string;
  attachmentsNo: number;
};

export const useAttachmentsComments = (
  attachments: any,
  comments: any
): AttachmentsCommentsData | null => {
  return attachments?.length || comments?.length
    ? {
        title: ["Attachments", "Comments"],
        attachmentsNo: attachments?.length,
        lastAttachmentsDate: attachments?.at(-1)?.date,
        commentNo: comments?.length,
        lastCommentDate: comments?.at(-1)?.date,
      }
    : null;
};
