import { useState } from "react";
import {
  StepType,
  OpportunitiesStep,
  opportunitiesSteps,
} from "@/app/screens/modal/opportunities";
import { useAddOpportunityMutation } from "@/app/service/opportunities.gql.ts";
import modalStore from "@/app/stores/modal.store";
import { SuccessModal } from "@/app/screens/modal/success/main";

export const useAddOpportunity = (form, platform: "web" | "mobile" = "web") => {
  const [{ step, index }, setStep] = useState<{
    step: StepType;
    index: number;
  }>({ step: "name", index: 0 });
  const { Component, title, primaryButton, secondaryButton } =
    opportunitiesSteps(step, platform) as OpportunitiesStep;

  const [addOpportunity, { loading: addCompanyLoading }] =
    useAddOpportunityMutation();

  const primaryAction = () => {
    if (step === "signal") {
      const companyData = form.values();
      addOpportunity({
        variables: {
          input: {
            about: companyData.about || "",
            assignees: Array.from(companyData.assignees).map(
              ({ value }: any) => value
            ),
            canIntro: Boolean(companyData.can_intro),
            city: companyData.location.city || "",
            country: companyData.location.country || "",
            founders: companyData.founders || [],
            highPriority: Boolean(companyData.high_priority),
            industries: (companyData?.industries as any).map(
              ({ value }) => value
            ),
            isActivelyFundraising: companyData.is_actively_fundraising,
            name: companyData.name || "Stealth Company",
            note: companyData.notes || "",
            noteToAssignees: companyData.note_to_assignees,
            round: companyData.round.value || "",
            source: companyData.source || "",
            sourceType: companyData.source_type[0]?.value,
            investmentType: companyData.investment_type,
            ballInControl: companyData.ball_in_our_control
              ? Boolean(companyData.ball_in_our_control)
              : undefined,
            targetFund: companyData.target_fund.value || "",
            websiteUrl: companyData.website_url || "",
          },
        },
      })
        .then(() => {
          modalStore.navigate(SuccessModal);
        })
        .catch((error) => {
          console.error("ERROR", { error });
        });
      return;
    }

    if (primaryActionValidation()) return;
    setStep((prevState) => {
      const steps = opportunitiesSteps();
      return {
        ...prevState,
        step: Object.keys(steps)[index + 1] as StepType,
        index: steps[prevState.index + 1].multi
          ? prevState.index
          : prevState.index + 1,
      };
    });
  };

  const secondaryAction = () => {
    setStep((prevState) => {
      return {
        ...prevState,
        step: Object.keys(opportunitiesSteps())[index - 1] as StepType,
        index: prevState.index - 1,
      };
    });
  };

  const primaryActionValidation = () => {
    if (step === "name") return false;

    if (step === "founders") {
      if (!form.$("name").value) {
        return !(
          form.$("founders").value?.[0]?.url.length &&
          form.$("founders").value?.[0]?.name.length &&
          form
            .$("founders")
            .value?.every(({ url, name }) =>
              /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www.)/g.test(
                url
              )
            )
        );
      }

      return form
        .$("founders")
        .value.some(({ url, name }) => url.length || name.length)
        ? form
            .$("founders")
            .value?.every(
              ({ url, name }) =>
                url.length &&
                name.length &&
                /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www.)/g.test(
                  url
                )
            )
        : false;
    }

    if (step === "company_details") {
      return (
        !form.$("location").value?.country ||
        !(form.$("about").value?.length < 500)
      );
    }

    if (step === "additional_details") {
      return Boolean(
        form.$("industries").value?.length === 0 ||
          form.$("round").value?.length === 0 ||
          form.$("target_fund").value?.length === 0 ||
          !form.$("investment_type").value
      );
    }

    if (step === "assignees_notes") {
      return !(form.$("notes").value?.length < 500);
    }

    if (step === "signal") {
      return Boolean(
        !form.$("source_type").value ||
          !form.$("source").value ||
          form.$("assignees").value.length === 0
      );
    }
  };

  return {
    index,
    title,
    setStep,
    Component,
    primaryButton,
    primaryAction,
    addOpportunity,
    secondaryButton,
    secondaryAction,
    addCompanyLoading,
    primaryActionValidation,
  };
};
