import React from "react";

import { observer } from "mobx-react";

import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";

import { CardList } from "./card-list";
import { TableList } from "./table-list/table";

export const WebOpportunitiesList = observer(() => {
  const { listType, currentTab } = opportunitiesStore.state;

  if (!opportunitiesStore.allOpportunities.length) {
    return null;
  }

  return (
    <div id={"web_opportunities_list"} className="w-full pt-1.5">
      {listType === "card" ? (
        <CardList opportunities={opportunitiesStore.allOpportunities} currentTab={currentTab.path} />
      ) : (
        <div className="size-full">
          <TableList opportunities={opportunitiesStore.allOpportunities} />
        </div>
      )}
    </div>
  );
});
