import { Chart } from "react-chartjs-2";
import { capitalize } from "@/lib/utils.ts";
import React from "react";

export const SentimentRadarChart = ({
  radarChartData,
  activeTooltip,
  tooltipChartData,
}) => (
  <Chart
    type="radar"
    plugins={[
      {
        id: "2d",
        beforeDraw: function (chart) {
          //HERE TO ADD GRADIENT
          // const ctx = chart.ctx;
          // const canvas = chart.canvas;
          // const chartArea = chart.chartArea;
          //
          // const gradientBack = canvas
          //   ?.getContext("2d")
          //   .createLinearGradient(0, 250, 0, 0);
          //
          // gradientBack.addColorStop(0, "rgba(213,235,248,1)");
          // gradientBack.addColorStop(1, "rgba(251,221,221,1)");
          //
          // ctx.fillStyle = gradientBack;
          // ctx.fillRect(
          //   chartArea.left,
          //   chartArea.bottom,
          //   chartArea.right - chartArea.left,
          //   chartArea.top - chartArea.bottom
          // );
        },
      },
    ]}
    data={{
      labels: radarChartData.map((item) => capitalize(item?.label)),
      datasets: [
        ...(activeTooltip
          ? [
              {
                label: "Overall Sentiment",
                data: tooltipChartData?.map((item) => item.value),
                borderColor: "rgb(204,34,34)",
                backgroundColor: "rgba(253,166,176,0.3)",
                borderWidth: 1,
                spanGaps: true,
              },
            ]
          : []),
        {
          label: "Overall Sentiment",
          data: radarChartData.map((item) => item.value),
          borderColor: "rgb(255,0,0)",
          backgroundColor: "rgba(143,20,33,0.3)",
          borderWidth: 1,
          spanGaps: true,
        },
      ],
    }}
    options={{
      animation: false,
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Sentiment Regarding Company",
        },
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          suggestedMin: 1,
          suggestedMax: 10,
        },
      },
    }}
  />
);
