import React from "react";

import { BarChart2Icon, ChevronDown, TypeIcon } from "lucide-react";
import { Chart } from "react-chartjs-2";

const IconMap = {
  numeric: () => <BarChart2Icon className="mr-2 size-4 lg:size-6" />,
  opinion_scale: () => <BarChart2Icon className="mr-2 size-4 lg:size-6" />,
  short_text: () => <TypeIcon className="mr-2 mt-0.5 size-4 lg:size-6" />,
  multiple_choice: () => <ChevronDown className="mr-2 size-4 lg:size-6" />,
  dropdown: () => <ChevronDown className="mr-2 size-4 lg:size-6" />,
};
export const SentimentAnswer = ({ form, field }) => {
  const IconComp = IconMap[field.type];

  const fieldAnswers = form.responses.flatMap((response) =>
    response.answers
      .filter((answer) => answer.field_id === field.field_id)
      .map((answer) => ({ ...answer, person: response.person })),
  );

  if (fieldAnswers.length === 0) {
    return;
  }

  return (
    <div className="shadow-none">
      <div className="flex flex-1">
        <div className="flex flex-row">
          <div>{IconComp && IconComp()}</div>
          <div className="text-lg font-semibold leading-5 tracking-tight">{field.name}</div>
        </div>
      </div>
      <div className="mt-2 grid gap-8">
        {(field.type === "opinion_scale" || field.type === "rating") && (
          <div className="flex h-40 justify-center lg:h-[220px] ">
            <Chart
              type="bar"
              options={{
                animation: false,
                responsive: true,
                resizeDelay: 0,
                maintainAspectRatio: false,
                scales: { x: { display: true }, y: { display: false } },

                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      footer: (context) => {
                        const item = context?.[0];
                        const people = fieldAnswers
                          .filter((e) => String(e.value) === String(item?.label))
                          .map((item) => {
                            if (typeof item?.person === "string") {
                              return item?.person;
                            }
                            return item?.person?.other;
                          })
                          .join("\n");
                        return `People:\n${people}`;
                      },
                    },
                  },
                },
              }}
              data={{
                labels: [...Array(11).keys()].map((item) => item.toString()),
                datasets: [
                  {
                    type: "bar",
                    label: field.name,
                    data: Object.values(
                      fieldAnswers
                        .map((answer) => answer.value)
                        .reduce(
                          (prev, curr) => {
                            prev[curr] += 1;
                            return prev;
                          },
                          Object.fromEntries([...Array(11).keys()].map((item) => [item, 0])),
                        ),
                    ),
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        )}
        {field.type === "short_text" && (
          <div className="text-md justify-start gap-2 text-left lg:text-lg">
            {form.responses.flatMap((response) =>
              response.answers
                .filter((answer) => answer.field_id === field.field_id)
                .map((answer, index) => (
                  <pre key={index} className="whitespace-pre-wrap" style={{ overflowWrap: "anywhere" }}>
                    <b>{response.person}</b>
                    {"\n"}
                    {answer.value.toString()}
                    {"\n\n"}
                  </pre>
                )),
            )}
          </div>
        )}
        {field.type === "multiple_choice" && (
          <div className="flex h-40 justify-center lg:h-[220px] ">
            <Chart
              type="bar"
              options={{
                indexAxis: field.group_id !== "return" ? ("y" as const) : undefined,
                animation: false,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      footer: (context) => {
                        const item = context?.[0];
                        const people = fieldAnswers
                          .filter((e) => String(e.value) === String(item?.label))
                          .map((item) => {
                            if (typeof item?.person === "string") {
                              return item?.person;
                            }
                            return item?.person?.other;
                          })
                          .join("\n");
                        return `People:\n${people}`;
                      },
                    },
                  },
                },
                scales: {
                  x: { ticks: { autoSkip: false } },
                  y: { ticks: { autoSkip: false } },
                },
              }}
              data={{
                labels: field.properties.choices.map((choice) => choice.label),
                datasets: [
                  {
                    type: "bar",
                    label: field.name,
                    data: Object.values(
                      form.responses
                        .flatMap((response) =>
                          response.answers
                            .filter((answer) => answer.field_id === field.field_id)
                            .map((answer) => answer.value),
                        )
                        .reduce(
                          (prev, curr) => {
                            prev[curr] += 1;
                            return prev;
                          },
                          Object.fromEntries(field.properties.choices.map((choice) => [choice.label, 0])),
                        ),
                    ),
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
