import { withResponsiveRendering } from "@/app/screens/modal/modal-responsive.hoc";

import { Modal } from "./modal";
import { NativeModal } from "./native-modal";

export * from "./modal";
export * from "./native-modal";
export * from "./types";

export const ResponsiveModal = withResponsiveRendering(Modal, NativeModal);
