import { parseISO, formatDistance } from "date-fns";

import { CompanyFlag } from "@/app/types";

export const getFlagStatus = (companyFlag?: CompanyFlag | null) => {
  const flaggedAt = companyFlag ? companyFlag.updatedAt || companyFlag.createdAt : undefined;

  const userId = companyFlag ? companyFlag.createdBy : undefined;
  let distance: string = "";

  if (flaggedAt) {
    const flaggedAtDt = parseISO(flaggedAt);
    distance = formatDistance(flaggedAtDt, new Date(), { addSuffix: true });
  }

  return {
    isFlagged: !!flaggedAt,
    distance,
    userId,
  };
};
