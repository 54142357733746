import { Text } from "@/app/components";
import { shared } from "./styles";
import { EmptyState } from "../../../../../components/label";
import { Badges } from "@/app/types";

export interface IntroProps {
  intro?: Badges;
  className?: string;
}

export const IntroInfo = ({
  intro,
  className = "flex flex-col justify-start gap-y-3 py-6",
}: IntroProps) => {
  return (
    <div className={`${className}`}>
      <Text
        text={"Intros Available"}
        weight={"semibold"}
        className={shared.subTitleText}
      />
      {intro ? (
        <Text
          text={`${intro.extra} on ${intro.date}`}
          weight={"light"}
          className={shared.body}
        />
      ) : (
        <EmptyState className={shared.body} />
      )}
    </div>
  );
};
