import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetForwardedCompaniesQueryVariables = Types.Exact<{
  assignee: Types.Scalars["UUID"]["input"];
  pageSize: Types.Scalars["Int"]["input"];
  statuses?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetForwardedCompaniesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrNotification";
        id: any;
        status: string;
        createdUtc?: any | null;
        payload: any;
        nzrOpportunityEmailAsReference?: {
          __typename?: "NzrOpportunityEmail";
          id: any;
          subject: string;
          senderEmail: string;
          body: string;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetCompanyUpdatesQueryVariables = Types.Exact<{
  assignee: Types.Scalars["UUID"]["input"];
  pageSize: Types.Scalars["Int"]["input"];
  statuses?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetCompanyUpdatesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrNotification";
        id: any;
        status: string;
        createdUtc?: any | null;
        payload: any;
        nzrOpportunityEmailAsReference?: {
          __typename?: "NzrOpportunityEmail";
          id: any;
          subject: string;
          senderEmail: string;
          from?: string | null;
          to?: string | null;
          body: string;
          source?: string | null;
          attachments?: any | null;
          metadata?: any | null;
          parser?: string | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetSentimentFormRequestsQueryVariables = Types.Exact<{
  assignee: Types.Scalars["UUID"]["input"];
  completed: Types.Scalars["Boolean"]["input"];
  pageSize: Types.Scalars["Int"]["input"];
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetSentimentFormRequestsQuery = {
  __typename?: "Query";
  nzrSentimentForms?: {
    __typename?: "NzrSentimentFormsConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
    edges: Array<{
      __typename?: "NzrSentimentFormsEdge";
      cursor?: any | null;
      node: {
        __typename?: "NzrSentimentForm";
        id: string;
        type?: string | null;
        title?: string | null;
        status?: string | null;
        formStatus?: string | null;
        createdAt?: any | null;
        expiresAt?: any | null;
        createdBy?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          avatar?: string | null;
        } | null;
        nzrSentimentFormAssignees: {
          __typename?: "NzrSentimentFormAssigneesConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "NzrSentimentFormAssignee";
            id: any;
            state?: any | null;
            member?: {
              __typename?: "LgMember";
              id: any;
              firstName: string;
              lastName: string;
              avatar?: string | null;
            } | null;
          }>;
        };
        founder?: { __typename?: "LgFounder"; name?: string | null; linkedinUrl?: string | null } | null;
        company?: { __typename?: "LgCompany"; id: any; name: string; image: string } | null;
        nzrNotifications: {
          __typename?: "NzrNotificationsConnection";
          nodes: Array<{ __typename?: "NzrNotification"; id: any }>;
        };
      };
    }>;
  } | null;
};

export type GetFeedbackTemplatesQueryVariables = Types.Exact<{
  assignee: Types.Scalars["UUID"]["input"];
  statuses?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
  pageSize: Types.Scalars["Int"]["input"];
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetFeedbackTemplatesQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      cursor?: any | null;
      node: { __typename?: "NzrNotification"; id: any; status: string; createdUtc?: any | null; payload: any };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetDashboardCompaniesQueryVariables = Types.Exact<{
  pageSize: Types.Scalars["Int"]["input"];
  filter?: Types.InputMaybe<Types.LgCompanyFilter>;
  orderBy?: Types.InputMaybe<Array<Types.LgCompaniesOrderBy> | Types.LgCompaniesOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetDashboardCompaniesQuery = {
  __typename?: "Query";
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LgCompaniesEdge";
      node: {
        __typename?: "LgCompany";
        id: any;
        image: string;
        name: string;
        tagline: string;
        tags?: any | null;
        badges?: any | null;
        city: string;
        country: string;
        isOpCompany: boolean;
        isTrCompany: boolean;
        userTags: {
          __typename?: "NzrUserCompanyTagsConnection";
          nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
        };
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type GetUserHighlightsQueryVariables = Types.Exact<{
  userId: Types.Scalars["UUID"]["input"];
  thisWeek: Types.Scalars["Datetime"]["input"];
  previousWeek: Types.Scalars["Datetime"]["input"];
  statuses?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
}>;

export type GetUserHighlightsQuery = {
  __typename?: "Query";
  lgMember?: {
    __typename?: "LgMember";
    currentAdded: { __typename?: "MnEntriesConnection"; totalCount: number };
    previousAdded: { __typename?: "MnEntriesConnection"; totalCount: number };
    currentCompanyUpdates: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    previousCompanyUpdates: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    pendingFeedback: { __typename?: "NzrNotificationsConnection"; totalCount: number };
    pendingSentimentForms: {
      __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection";
      totalCount: number;
    };
    competedSentimentForms: {
      __typename?: "LgMemberNzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormIdManyToManyConnection";
      totalCount: number;
    };
    follows: { __typename?: "NzrUserCompanyTagsConnection"; totalCount: number };
    inReview: { __typename?: "NzrOpportunitiesConnection"; totalCount: number };
  } | null;
};

export const GetForwardedCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetForwardedCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "StringValue", value: "email_opportunity", block: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "parser" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "OPPORTUNITY_EMAIL", block: false },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "subject" } },
                                  { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                                  { kind: "Field", name: { kind: "Name", value: "body" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetForwardedCompaniesQuery__
 *
 * To run a query within a React component, call `useGetForwardedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForwardedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForwardedCompaniesQuery({
 *   variables: {
 *      assignee: // value for 'assignee'
 *      pageSize: // value for 'pageSize'
 *      statuses: // value for 'statuses'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetForwardedCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables> &
    ({ variables: GetForwardedCompaniesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>(
    GetForwardedCompaniesDocument,
    options,
  );
}
export function useGetForwardedCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>(
    GetForwardedCompaniesDocument,
    options,
  );
}
export function useGetForwardedCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetForwardedCompaniesQuery, GetForwardedCompaniesQueryVariables>(
    GetForwardedCompaniesDocument,
    options,
  );
}
export type GetForwardedCompaniesQueryHookResult = ReturnType<typeof useGetForwardedCompaniesQuery>;
export type GetForwardedCompaniesLazyQueryHookResult = ReturnType<typeof useGetForwardedCompaniesLazyQuery>;
export type GetForwardedCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetForwardedCompaniesSuspenseQuery>;
export type GetForwardedCompaniesQueryResult = Apollo.QueryResult<
  GetForwardedCompaniesQuery,
  GetForwardedCompaniesQueryVariables
>;
export const GetCompanyUpdatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyUpdates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "parser" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "in" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                      { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "subject" } },
                                  { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                                  { kind: "Field", name: { kind: "Name", value: "from" } },
                                  { kind: "Field", name: { kind: "Name", value: "to" } },
                                  { kind: "Field", name: { kind: "Name", value: "body" } },
                                  { kind: "Field", name: { kind: "Name", value: "source" } },
                                  { kind: "Field", name: { kind: "Name", value: "attachments" } },
                                  { kind: "Field", name: { kind: "Name", value: "metadata" } },
                                  { kind: "Field", name: { kind: "Name", value: "parser" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyUpdatesQuery__
 *
 * To run a query within a React component, call `useGetCompanyUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUpdatesQuery({
 *   variables: {
 *      assignee: // value for 'assignee'
 *      pageSize: // value for 'pageSize'
 *      statuses: // value for 'statuses'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCompanyUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables> &
    ({ variables: GetCompanyUpdatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>(GetCompanyUpdatesDocument, options);
}
export function useGetCompanyUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>(
    GetCompanyUpdatesDocument,
    options,
  );
}
export function useGetCompanyUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>(
    GetCompanyUpdatesDocument,
    options,
  );
}
export type GetCompanyUpdatesQueryHookResult = ReturnType<typeof useGetCompanyUpdatesQuery>;
export type GetCompanyUpdatesLazyQueryHookResult = ReturnType<typeof useGetCompanyUpdatesLazyQuery>;
export type GetCompanyUpdatesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyUpdatesSuspenseQuery>;
export type GetCompanyUpdatesQueryResult = Apollo.QueryResult<GetCompanyUpdatesQuery, GetCompanyUpdatesQueryVariables>;
export const GetSentimentFormRequestsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSentimentFormRequests" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "completed" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrSentimentForms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_AT_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "some" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "memberId" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "equalTo" },
                                        value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "completed" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "equalTo" },
                                        value: { kind: "Variable", name: { kind: "Name", value: "completed" } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "formStatus" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                  { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "state" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "member" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                              { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "founder" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "company" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrNotifications" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "assignee" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetSentimentFormRequestsQuery__
 *
 * To run a query within a React component, call `useGetSentimentFormRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentimentFormRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentimentFormRequestsQuery({
 *   variables: {
 *      assignee: // value for 'assignee'
 *      completed: // value for 'completed'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSentimentFormRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables> &
    ({ variables: GetSentimentFormRequestsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>(
    GetSentimentFormRequestsDocument,
    options,
  );
}
export function useGetSentimentFormRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>(
    GetSentimentFormRequestsDocument,
    options,
  );
}
export function useGetSentimentFormRequestsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSentimentFormRequestsQuery, GetSentimentFormRequestsQueryVariables>(
    GetSentimentFormRequestsDocument,
    options,
  );
}
export type GetSentimentFormRequestsQueryHookResult = ReturnType<typeof useGetSentimentFormRequestsQuery>;
export type GetSentimentFormRequestsLazyQueryHookResult = ReturnType<typeof useGetSentimentFormRequestsLazyQuery>;
export type GetSentimentFormRequestsSuspenseQueryHookResult = ReturnType<
  typeof useGetSentimentFormRequestsSuspenseQuery
>;
export type GetSentimentFormRequestsQueryResult = Apollo.QueryResult<
  GetSentimentFormRequestsQuery,
  GetSentimentFormRequestsQueryVariables
>;
export const GetFeedbackTemplatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFeedbackTemplates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_UTC_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignee" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "Variable", name: { kind: "Name", value: "assignee" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "StringValue", value: "feedback_email", block: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cursor" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "payload" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetFeedbackTemplatesQuery__
 *
 * To run a query within a React component, call `useGetFeedbackTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackTemplatesQuery({
 *   variables: {
 *      assignee: // value for 'assignee'
 *      statuses: // value for 'statuses'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetFeedbackTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables> &
    ({ variables: GetFeedbackTemplatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>(
    GetFeedbackTemplatesDocument,
    options,
  );
}
export function useGetFeedbackTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>(
    GetFeedbackTemplatesDocument,
    options,
  );
}
export function useGetFeedbackTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFeedbackTemplatesQuery, GetFeedbackTemplatesQueryVariables>(
    GetFeedbackTemplatesDocument,
    options,
  );
}
export type GetFeedbackTemplatesQueryHookResult = ReturnType<typeof useGetFeedbackTemplatesQuery>;
export type GetFeedbackTemplatesLazyQueryHookResult = ReturnType<typeof useGetFeedbackTemplatesLazyQuery>;
export type GetFeedbackTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetFeedbackTemplatesSuspenseQuery>;
export type GetFeedbackTemplatesQueryResult = Apollo.QueryResult<
  GetFeedbackTemplatesQuery,
  GetFeedbackTemplatesQueryVariables
>;
export const GetDashboardCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDashboardCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgCompaniesOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "badges" } },
                            { kind: "Field", name: { kind: "Name", value: "city" } },
                            { kind: "Field", name: { kind: "Name", value: "country" } },
                            { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
                            { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "userTags" },
                              name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                        { kind: "Field", name: { kind: "Name", value: "userId" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetDashboardCompaniesQuery__
 *
 * To run a query within a React component, call `useGetDashboardCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCompaniesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDashboardCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables> &
    ({ variables: GetDashboardCompaniesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>(
    GetDashboardCompaniesDocument,
    options,
  );
}
export function useGetDashboardCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>(
    GetDashboardCompaniesDocument,
    options,
  );
}
export function useGetDashboardCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDashboardCompaniesQuery, GetDashboardCompaniesQueryVariables>(
    GetDashboardCompaniesDocument,
    options,
  );
}
export type GetDashboardCompaniesQueryHookResult = ReturnType<typeof useGetDashboardCompaniesQuery>;
export type GetDashboardCompaniesLazyQueryHookResult = ReturnType<typeof useGetDashboardCompaniesLazyQuery>;
export type GetDashboardCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetDashboardCompaniesSuspenseQuery>;
export type GetDashboardCompaniesQueryResult = Apollo.QueryResult<
  GetDashboardCompaniesQuery,
  GetDashboardCompaniesQueryVariables
>;
export const GetUserHighlightsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserHighlights" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Datetime" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Datetime" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentAdded" },
                  name: { kind: "Name", value: "mnEntriesByMemberId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tableName" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "lg_company", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "previousAdded" },
                  name: { kind: "Name", value: "mnEntriesByMemberId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tableName" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "lg_company", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "lessThan" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentCompanyUpdates" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "assignee" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "parser" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "in" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                            { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "previousCompanyUpdates" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "assignee" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdUtc" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "previousWeek" } },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "lessThan" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrOpportunityEmailAsReference" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "parser" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "in" },
                                        value: {
                                          kind: "ListValue",
                                          values: [
                                            { kind: "StringValue", value: "COMPANY_UPDATES", block: false },
                                            { kind: "StringValue", value: "METRICS_EXTRACTION", block: false },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pendingFeedback" },
                  name: { kind: "Name", value: "nzrNotificationsByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "feedback_email", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "in" },
                                  value: { kind: "Variable", name: { kind: "Name", value: "statuses" } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pendingSentimentForms" },
                  name: {
                    kind: "Name",
                    value: "nzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormId",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "some" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "memberId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: false },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "competedSentimentForms" },
                  name: {
                    kind: "Name",
                    value: "nzrSentimentFormsByNzrSentimentFormAssigneeMemberIdAndNzrSentimentFormId",
                  },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "some" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "memberId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "completed" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: true },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "actionedAt" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "greaterThanOrEqualTo" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "thisWeek" } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "follows" },
                  name: { kind: "Name", value: "nzrUserCompanyTagsByUserId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tags" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "contains" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "follow" },
                                        value: { kind: "StringValue", value: "1", block: false },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "inReview" },
                  name: { kind: "Name", value: "nzrOpportunitiesByAssignee" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: { kind: "StringValue", value: "CREATED", block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "signal" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "company" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "isOpCompany" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "equalTo" },
                                              value: { kind: "BooleanValue", value: false },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "none" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "trelloCardAsSource" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "listId" },
                                                          value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: { kind: "Name", value: "in" },
                                                                value: {
                                                                  kind: "ListValue",
                                                                  values: [
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "558c7d47afd9cbf4ccc4c969",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bda26969461ac8bbed1d0f8",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "637646faa0bfe00221715461",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "59372e2427706549525b33d7",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bda298f418a8f6cd8e10d12",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "62c6c4f042096b8c33418d79",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "61559487ab1c8d67d4e06a6d",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5626767dccf2aa74870b6bab",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5bdae47c6c0728111d122923",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "63764680c2233703ffa09dd2",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "561fe5725d03faeba0a20b5e",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "5d4bfb8eb30b468aa3ada3ff",
                                                                      block: false,
                                                                    },
                                                                    {
                                                                      kind: "StringValue",
                                                                      value: "619b69465c658d509f7de1b0",
                                                                      block: false,
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: { kind: "Name", value: "none" },
                                              value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "userId" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "equalTo" },
                                                          value: {
                                                            kind: "Variable",
                                                            name: { kind: "Name", value: "userId" },
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  {
                                                    kind: "ObjectField",
                                                    name: { kind: "Name", value: "tags" },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: { kind: "Name", value: "contains" },
                                                          value: {
                                                            kind: "ObjectValue",
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: { kind: "Name", value: "hide" },
                                                                value: {
                                                                  kind: "StringValue",
                                                                  value: "1",
                                                                  block: false,
                                                                },
                                                              },
                                                            ],
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserHighlightsQuery__
 *
 * To run a query within a React component, call `useGetUserHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserHighlightsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      thisWeek: // value for 'thisWeek'
 *      previousWeek: // value for 'previousWeek'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetUserHighlightsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserHighlightsQuery, GetUserHighlightsQueryVariables> &
    ({ variables: GetUserHighlightsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>(GetUserHighlightsDocument, options);
}
export function useGetUserHighlightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>(
    GetUserHighlightsDocument,
    options,
  );
}
export function useGetUserHighlightsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>(
    GetUserHighlightsDocument,
    options,
  );
}
export type GetUserHighlightsQueryHookResult = ReturnType<typeof useGetUserHighlightsQuery>;
export type GetUserHighlightsLazyQueryHookResult = ReturnType<typeof useGetUserHighlightsLazyQuery>;
export type GetUserHighlightsSuspenseQueryHookResult = ReturnType<typeof useGetUserHighlightsSuspenseQuery>;
export type GetUserHighlightsQueryResult = Apollo.QueryResult<GetUserHighlightsQuery, GetUserHighlightsQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
