import { GetCompanyCommentsQuery } from "@/app/service/company-comments.gql.ts";
import { CommentDisplayData, isTrelloCommentMetadata } from "./comments.types.ts";
type CommentResult = NonNullable<GetCompanyCommentsQuery["lgCompanyComments"]>["nodes"][number];

const getCommentAuthor = (
  comment: CommentResult,
): {
  id?: string;
  avatar?: string | null;
  initials?: string;
  fullName: string;
} | null => {
  if (comment.lgMemberByCreatedBy) {
    return {
      id: comment.lgMemberByCreatedBy.id,
      avatar: comment.lgMemberByCreatedBy.avatar,
      initials: comment.lgMemberByCreatedBy.initials,
      fullName:
        [comment.lgMemberByCreatedBy.firstName, comment.lgMemberByCreatedBy.lastName].filter(Boolean).join(" ") ||
        "Unknown Author",
    };
  }

  // get from metadata for trello comments
  if (isTrelloCommentMetadata(comment.metadata)) {
    const author = comment.metadata.data.memberCreator;

    return {
      avatar: author.avatarUrl,
      initials: author.initials,
      fullName: author.fullName ? `${author.fullName}` : "Unknown Author",
    };
  }

  return null;
};

export const mapComments = (commentsData?: GetCompanyCommentsQuery): CommentDisplayData[] => {
  if (!commentsData?.lgCompanyComments?.nodes) {
    return [];
  }

  return commentsData.lgCompanyComments.nodes.map((comment) => {
    return {
      id: comment.id,
      author: getCommentAuthor(comment),
      date: comment.createdUtc,
      text: comment.text,
      dateLastEdited: comment.lastUpdatedUtc,
      metadata: comment.metadata,
    };
  });
};
