import React from "react";

import { CompanyAssigneesField } from "@/app/screens/opportunities/add-new/shared/components/company-assignees-field.tsx";
import { CompanyAttributeFields } from "@/app/screens/opportunities/add-new/shared/components/company-attribute-fields.tsx";
import { CompanySourceField } from "@/app/screens/opportunities/add-new/shared/components/company-source-field.tsx";
import { CompanyAttributesFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-attributes-form.ts";

import { CompanyStepComponentProps } from "../../add-company-new.types.ts";

export const CompanySourceStep = ({ form }: CompanyStepComponentProps<CompanyAttributesFormData>) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CompanySourceField control={control} name={"source"} />
        <CompanyAttributeFields name={"attributes"} control={control} />
        <div>
          <CompanyAssigneesField control={control} name={"assignees"} />
        </div>
      </form>
    </div>
  );
};
