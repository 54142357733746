interface ModalWebFrameProps {
  children: React.ReactNode;
  containerClassName?: string;
}

export const ModalWebFrame = ({
  containerClassName,
  children,
  ...props
}: ModalWebFrameProps) => {
  return (
    <div
      className={`relative flex flex-1 flex-col items-center justify-between space-y-4 rounded-lg bg-background lg:p-12 ${containerClassName}`}
      {...props}
    >
      {children}
    </div>
  );
};
