import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";

export const MainCompanyDataSchema = z.object({
  name: z.string().min(1, "Please enter company name"),
  type: z
    .string()
    .refine((value) => value !== "LgCompany")
    .optional(),
});

export type MainCompanyDataForm = z.infer<typeof MainCompanyDataSchema>;

export const useMainCompanyDataForm = () => {
  return useForm<MainCompanyDataForm>({
    resolver: zodResolver(MainCompanyDataSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });
};
