import React from "react";

import { cn } from "@/lib/utils.ts";

import { EmptySection } from "../empty-section.tsx";

import { Founder } from "./founder.tsx";
import { FoundersData } from "./founders.types.ts";

export const Founders = ({ founders }: { founders: FoundersData }) => {
  if (!founders.length) {
    return <EmptySection text={"No Founders Listed"} />;
  }

  return (
    <section>
      <div className={cn("grid w-full grid-cols-1 flex-wrap items-center gap-4 md:grid-cols-2")}>
        {founders.map((founder) => (
          <Founder key={founder.linkedinUrl} founder={founder} />
        ))}
      </div>
    </section>
  );
};
