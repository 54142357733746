import { Icon, Text } from "@/app/components";
import { titleCase } from "@/app/misc/helpers.ts";
import ReactCountryFlag from "react-country-flag";
import { countriesMap } from "@/app/misc/filters.ts";
import { useBreakpoints } from "@/lib/hooks";

type TagsProps = {
  country?: string;
  round?: string;
  industry?: string;
  showIcon?: boolean;
  containerClassName?: string;
};

export function Tags({
  round = "",
  country = "",
  showIcon = true,
  industry = "",
  containerClassName = "",
}: TagsProps) {
  const { isBigTablet } = useBreakpoints();
  if (!round && !country && !industry) return null;
  return (
    <div
      id={"Location | Stage | Industry"}
      className={`flex items-center ${containerClassName}`}
    >
      {countriesMap[country] && (
        <div className={"flex items-center justify-center"}>
          {countriesMap[country] && (
            <ReactCountryFlag
              countryCode={countriesMap[country]}
              svg
              style={{
                height: 20,
                width: 20,
                borderRadius: 5,
                marginRight: 5,
              }}
            />
          )}
          <Text
            text={countriesMap[country]}
            {...(showIcon ? {} : { weight: "semibold" })}
            type={isBigTablet ? "body" : "message"}
          />
        </div>
      )}
      {round && (
        <>
          {countriesMap[country] && (
            <Text text={"•"} className={"mx-1.5 text-primary"} />
          )}
          <div className={"flex"}>
            {showIcon && (
              <Icon
                type={"Star"}
                width={isBigTablet ? 23 : 18}
                height={isBigTablet ? 23 : 18}
                className={"mr-2"}
              />
            )}
            <Text
              text={titleCase(round)}
              type={isBigTablet ? "body" : "message"}
              {...(showIcon ? {} : { weight: "semibold" })}
            />
          </div>
        </>
      )}
      {industry && (
        <>
          {(country || round) && (
            <Text text={"•"} className={"mx-2 text-primary"} />
          )}
          <div className={"flex items-center"}>
            {showIcon && (
              <Icon
                type={"Suitcase"}
                width={isBigTablet ? 23 : 18}
                height={isBigTablet ? 23 : 18}
                className={"mr-2 text-primary-200"}
              />
            )}
            <Text
              text={titleCase(industry)}
              noOfLines={1}
              {...(showIcon ? {} : { weight: "semibold" })}
              type={isBigTablet ? "body" : "message"}
            />
          </div>
        </>
      )}
    </div>
  );
}
