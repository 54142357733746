import { Text } from "@/app/components";
import { EmptyState } from "@/app/components/label";
import { LGMember } from "@/app/stores/members.store";

import { MemberList } from "./member-list";
import { shared } from "./styles";

interface LeadMemberProps {
  assignees: LGMember[];
  className?: string;
  titleClassName?: string;
  detailsClassName?: string;
}

export const LeadMembers = ({
  assignees,
  className = "",
  titleClassName = "justify-between",
  detailsClassName = "grid-cols-2 justify-between gap-y-4 rounded-md border border-neutral-200",
}: LeadMemberProps) => {
  return (
    <div className={`flex flex-col  ${className}`}>
      <div className={`flex ${titleClassName}`}>
        <Text text={"Lead PCG Members"} weight={"semibold"} className={shared.subTitleText} />
        <Text
          text={`${assignees.length}`}
          type={"subtitle"}
          color={"text-primary"}
          className={`ml-2 ${shared.titleText}`}
        />
      </div>
      {assignees.length ? (
        <MemberList members={assignees} containerClassName={detailsClassName} imgClassName="w-12 rounded-xs" />
      ) : (
        <EmptyState className={"text-left"} />
      )}
    </div>
  );
};
