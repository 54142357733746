import { cn } from "@/lib/utils";
import React from "react";

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

export const Container = ({ children, className = "", id }: ContainerProps) => {
  return (
    <section id={id} className={cn(`${className}`)}>
      {children}
    </section>
  );
};
