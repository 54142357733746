import Form from "@/app/components/forms";
import { FormSelect, TextArea } from "@/app/components/input";
import { pcgFunds } from "@/app/misc/filters";

export const ConvertToPortfolioForm = ({ form }) => {
  return (
    <Form.Root form={form}>
      <Form.Field
        fieldId="fund"
        render={({ onChange }) => (
          <div className="mb-4">
            <Form.Label />
            <FormSelect
              onChange={([option]) => option && onChange(option.value)}
              options={pcgFunds.map(({ name, id }) => ({ value: `${id}`, label: name }))}
            />
          </div>
        )}
      />
      <Form.Field
        fieldId="note"
        render={({ onchange, value }) => (
          <div>
            <Form.Label />
            <TextArea onChange={onchange} value={value} className="mb-0" />
          </div>
        )}
      />
    </Form.Root>
  );
};
