import { addDays, addMonths, addWeeks, addYears } from "date-fns";
import { SelectOption } from "../input";

export function dateAdd({ value, type }: SelectOption) {
  const date = new Date();

  switch (type) {
    case "days":
      return addDays(date, Number(value));
    case "weeks":
      return addWeeks(date, Number(value));
    case "months":
      return addMonths(date, Number(value));
    case "years":
      return addYears(date, Number(value));
    default:
      return date;
  }
}
