import { NativeModal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";
import { ModalContent } from "./modal-content";
import { ActionButtons } from "./components/action-buttons";
import { useSelectModal } from "./hooks";
import { ModalHeader } from "../components";

export const AddNewNativeModal = ({ company, sentimentOnly }) => {
  const { selected, handleSelect, handleNext } = useSelectModal({ company });

  return (
    <NativeModal
      isOpen={true}
      handleClose={modalStore.close}
      detent={"content-height"}
      headerContainerClassName="p-4"
      header={
        <ModalHeader
          title={"Add New..."}
          subtitle="Please choose an option and then proceed by clicking on 'Next'."
        />
      }
      footer={
        <ActionButtons
          disabled={!selected}
          actions={{
            handlePrimaryAction: handleNext,
            handleCancel: modalStore.close,
          }}
        />
      }
    >
      <ModalContent sentimentOnly={sentimentOnly} onSelect={handleSelect} />
    </NativeModal>
  );
};
