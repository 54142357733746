export function SearchPrimary() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="url(#a)"
        d="M19.756 18.577l-4.975-4.974a8.347 8.347 0 10-1.178 1.178l4.974 4.975a.833.833 0 001.179-1.179M8.333 15A6.667 6.667 0 1115 8.333 6.674 6.674 0 018.333 15"
      ></path>
      <defs>
        <linearGradient id="a" x1="-0.027" x2="19.045" y1="1.975" y2="2.276" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9366B"></stop>
          <stop offset="1" stopColor="#EE712A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
