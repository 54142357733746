import { withResponsiveRendering } from "@/app/screens/modal/modal-responsive.hoc";

import { ActionsMenu } from "./action-menu";
import { NativeActionMenu } from "./mobile-action-menu";

export const ResponsiveActionMenu = withResponsiveRendering(ActionsMenu, NativeActionMenu);

export * from "./action-menu";
export * from "./mobile-action-menu";
export * from "./types";
