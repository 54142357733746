import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

type CardBodyProps = {
  className?: string;
};

export const CardBody = ({ children, className }: PropsWithChildren<CardBodyProps>) => {
  return <div className={cn("relative w-full", className)}>{children}</div>;
};
