import { RadioGroup } from "@headlessui/react";
import { Icon } from "..";
import { cn } from "@/lib/utils";
import { useState } from "react";

export type OptionType = string | boolean | number;

export interface RadioOption<T extends OptionType> {
  value: T;
  label: string;
  icon?: string;
  checkedIcon?: string;
}

export interface RadioGroupProps<T extends OptionType> {
  className?: string;
  optionClassName?: string;
  optionContainerClassName?: string;
  iconClassName?: string;
  checkedClassName?: string;
  labelClassName?: string;
  options: RadioOption<T>[];
  withCheck?: boolean;
  defaultValue?: T;
  checkIconContainer?: string;
  checkIconClassName?: string;
  onSelected: (option?: RadioOption<T>) => void;
}

export function RadioGroups<T extends OptionType = string>({
  className,
  optionClassName,
  optionContainerClassName,
  checkedClassName,
  labelClassName,
  iconClassName,
  options,
  withCheck,
  checkIconContainer,
  checkIconClassName,
  defaultValue,
  onSelected,
}: RadioGroupProps<T>) {
  const [selected, setSelected] = useState<T | string | undefined>(
    defaultValue
  );

  const handleSelect = (value: T) => {
    const selectedValue = selected !== value ? value : "";
    setSelected(selectedValue);
    onSelected(options.find(({ value: val }) => val === selectedValue));
  };

  return (
    <RadioGroup
      value={selected}
      defaultValue={defaultValue}
      className={className}
    >
      {options.map((option) => (
        <RadioGroup.Option
          key={String(option.value)}
          value={option.value}
          className={optionContainerClassName}
          onClick={() => handleSelect(option.value)}
        >
          {({ checked }) => (
            <>
              <div
                className={cn(
                  "transition-all",
                  optionClassName,
                  checked ? checkedClassName : ""
                )}
              >
                {option.icon && (
                  <Icon
                    type={
                      checked ? option.checkedIcon || option.icon : option.icon
                    }
                    className={iconClassName}
                  />
                )}
                <p className={labelClassName}>{option.label}</p>
                {withCheck && (
                  <div
                    className={cn(
                      "mx-auto mt-6 flex h-6 w-6 items-center justify-center rounded-xxl border-2 bg-white",
                      checkIconContainer
                    )}
                  >
                    {checked && (
                      <div
                        className={cn(
                          "h-4 w-4 rounded-xxl bg-gradient-to-r from-primary-400 to-secondary-400",
                          checkIconClassName
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}
