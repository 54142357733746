import React from "react";

import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { Card, Icon, Toast } from "@/app/components";
import { Routes } from "@/app/constants";
import {
  EmailMetadata,
  isProcessUpdateEmailSuccessData,
  NotificationPayload,
  NotificationStatus,
} from "@/app/screens/dashboard/dashboard.types.ts";
import { ConfirmModal } from "@/app/screens/modal";
import {
  useSetNotificationStatusMutation,
  useSetOpportunityEmailMutation,
} from "@/app/screens/notifications/notifications.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { CompanyLink } from "../../components/company-link.tsx";
import { DateColumn } from "../../components/email-opportunity/date-column.tsx";
import { DetailsColumn } from "../../components/email-opportunity/details-column.tsx";
import { EmailAuthorColumn } from "../../components/email-opportunity/email-author-column.tsx";
import { EmailOpportunityActions } from "../../components/email-opportunity/email-opportunity-actions.tsx";

import { CompanyUpdatesDetailsModal } from "./company-updates-details-modal.tsx";
import { completedStatuses, useCompanyUpdates } from "./use-company-updates.ts";

type CompanyUpdateItemData = ReturnType<typeof useCompanyUpdates>["items"][number];

const getCompanyDataFromEmail = (
  email: CompanyUpdateItemData["nzrOpportunityEmailAsReference"],
): {
  id: string;
  name: string;
  image: string;
  totalMetrics: number;
} | null => {
  if (!email?.metadata) {
    return null;
  }

  const emailMetadata: EmailMetadata = email.metadata;

  return isProcessUpdateEmailSuccessData(emailMetadata?.process_update_email)
    ? {
        id: emailMetadata.process_update_email.data.company_id,
        name: emailMetadata.process_update_email.data.company_name,
        image: emailMetadata.process_update_email.data.company_image,
        totalMetrics: (emailMetadata.process_metrics_extraction_update?.data?.body?.metrics || []).length,
      }
    : null;
};

export const CompanyUpdatesItem = ({
  notification,
  className,
}: {
  notification: CompanyUpdateItemData;
  className?: string;
}) => {
  const navigate = useNavigate();
  const [updateCompanyEmail, { loading: setOpportunityEmailLoading }] = useSetOpportunityEmailMutation();
  const [setNotificationStatus, { loading: setNotificationStatusLoading }] = useSetNotificationStatusMutation({
    refetchQueries: ["GetUserHighlights", "GetCompanyUpdates"],
  });

  const title = (notification.payload as NotificationPayload).email.subject ?? "";
  const type = notification.nzrOpportunityEmailAsReference?.parser === "METRICS_EXTRACTION" ? "Metrics" : "Updates";
  const author =
    (notification.payload as NotificationPayload).email.source ??
    notification.nzrOpportunityEmailAsReference?.senderEmail ??
    "";
  const date = notification.createdUtc;

  const company = getCompanyDataFromEmail(notification.nzrOpportunityEmailAsReference);

  const handleOpen = () => {
    if (type === "Updates") {
      modalStore.open(CompanyUpdatesDetailsModal, {
        props: {
          company,
          status: notification.status as NotificationStatus,
          email: notification.nzrOpportunityEmailAsReference,
          emailId: notification.nzrOpportunityEmailAsReference!.id,
        },
      });
    }

    if (type === "Metrics") {
      navigate(`${Routes.metricsExtraction}/${notification.payload.email.id}`);
    }
  };

  const handleMarkAsDone = () => {
    setNotificationStatus({
      variables: { id: notification.id, status: NotificationStatus.COMPLETED },
      // todo directly remove item from the cache, but this needs proper testing
      // update: (cache) => {
      //   cache.modify({
      //     fields: {
      //       nzrNotifications(existing, { readField }) {
      //         return {
      //           totalCount: existing?.totalCount ? existing?.totalCount - 1 : 0,
      //           edges: existing?.edges.filter((itemRef) => readField("id", itemRef.node) !== notification.id) ?? [],
      //           pageInfo: existing?.pageInfo,
      //         };
      //       },
      //     },
      //   });
      // },
    }).then(() => {
      toast.custom((t) => (
        <Toast
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          visible={t.visible}
          title="Success"
          subTitle="Successfully marked as done"
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
    });
  };

  const handleRemove = () => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will decline this notification`,
        handleConfirm: () => {
          const emailId = notification.nzrOpportunityEmailAsReference?.id;

          if (!emailId) {
            // todo shouldnt happen
            return;
          }
          setNotificationStatus({
            variables: { id: notification.id, status: NotificationStatus.DECLINED },
          }).catch();
          updateCompanyEmail({
            variables: {
              input: {
                action: EmailOpportunityAction.Decline,
                emailOpportunityId: emailId,
              },
            },
          }).then(() => {
            toast.success("Company update has been declined");
          });
        },
      },
    });
  };

  const isCompleted = completedStatuses.includes(notification.status as NotificationStatus);

  return (
    <Card className={cn("flex items-start justify-between gap-4 md:flex-row md:items-center", className)}>
      <Card.Body className="grid grid-cols-2 gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4 md:items-center">
          <EmailAuthorColumn
            author={author}
            label={title}
            styles={{
              labelStyle: "text-black text-sm font-[600] xl:min-w-[270px] line-clamp-1",
            }}
          />
          <DateColumn
            date={date}
            styles={{ container: "text-xs md:hidden min-w-[80px]", labelStyle: "w-full text-end" }}
          />
        </div>
        <DateColumn label="Date" date={date} styles={{ container: "hidden md:flex col-span-2", content: "h-full" }} />
        <DetailsColumn
          styles={{
            container: "col-span-1 md:col-span-3 xl:col-span-2",
            content: "flex items-center h-full",
            labelStyle: "mb-0",
          }}
        >
          {company !== null ? (
            <CompanyLink id={company.id} name={company.name} titleClassName="text-sm">
              <Card.Image src={company.image} className="mr-2" />
            </CompanyLink>
          ) : (
            <span
              onClick={!isCompleted ? handleOpen : undefined}
              className="cursor-pointer rounded-xs bg-red-100 px-1.5 py-1 text-sm text-red"
            >
              Company not linked
            </span>
          )}
        </DetailsColumn>
        <DetailsColumn
          label="Data Extracted"
          styles={{
            container: "col-span-1 md:col-span-3 xl:col-span-2",
            content: "",
          }}
        >
          <span>
            {/*TODO: Commenting this out until adding an additional information from the server to only display Standard Metrics*/}
            {/*{type === "Metrics" && (*/}
            {/*  <span>*/}
            {/*    {company?.totalMetrics} Metrics <span className="mx-0.5 text-neutral-300">•</span>{" "}*/}
            {/*  </span>*/}
            {/*)}*/}
            {notification.nzrOpportunityEmailAsReference?.attachments?.length || 0} Attachments
          </span>
        </DetailsColumn>
        <EmailOpportunityActions
          className="col-span-2 items-center md:col-span-3 md:w-auto md:justify-start xl:col-span-2 xl:justify-end"
          actions={{
            primary: {
              action: handleOpen,
              title: isCompleted ? "Viewed" : type === "Metrics" ? "Submit" : "Open",
            },
            secondary: !isCompleted
              ? {
                  action: company && type !== "Metrics" ? handleMarkAsDone : handleRemove,
                  title: company && type !== "Metrics" ? "Archive" : "Remove",
                  loading: company && type !== "Metrics" ? setNotificationStatusLoading : setOpportunityEmailLoading,
                }
              : undefined,
          }}
        />
      </Card.Body>
    </Card>
  );
};
