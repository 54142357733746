import { createContext, PropsWithChildren, useContext, useState } from "react";

import { updateCompanyAdditionalDetailsForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-additional-details-form.ts";
import { updateCompanyDetailsForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-details-form.ts";
import { updateFoundersForm } from "@/app/screens/opportunities/add-new/shared/forms/use-company-founders-form.ts";

import { CompanyResult } from "./add-company-new.types.ts";
import { NewCompany } from "./components/select-company.tsx";
import { useAddCompanyForms } from "./hooks/use-add-company-forms.ts";
import { useAddCompanySteps } from "./hooks/use-add-company-steps.ts";
import { mapCompanyData, useSubmitNewCompany } from "./hooks/use-submit-new-company.ts";

type SelectedCompany = CompanyResult | NewCompany | null;

type AddCompanyNewContextData = {
  selectedCompany: SelectedCompany;
  selectCompany: (company: SelectedCompany) => void;

  submit: () => void;
  isSubmitting?: boolean;

  steps?: ReturnType<typeof useAddCompanySteps>;
};

const AddCompanyNewContext = createContext<AddCompanyNewContextData>({
  selectedCompany: null,
  selectCompany: () => {},
  submit: () => {},
});

export const AddCompanyNewContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompany>(null);
  const { submitOpportunity, loading: isSubmitting } = useSubmitNewCompany();

  const forms = useAddCompanyForms();
  const steps = useAddCompanySteps(forms);

  const handleSelectCompany = (company: SelectedCompany) => {
    setSelectedCompany(company);

    if (company) {
      updateFoundersForm(forms.foundersForm, company);
      updateCompanyDetailsForm(forms.detailsForm, company);
      updateCompanyAdditionalDetailsForm(forms.additionalDetailsForm, company);
    } else {
      // reset all forms if selected company has been removed
      Object.values(forms).forEach((form) => form.reset());
    }
  };

  const submitData = () => {
    const data = mapCompanyData(forms);

    submitOpportunity(data);
  };

  const value = {
    selectedCompany,
    selectCompany: handleSelectCompany,
    submit: submitData,
    isSubmitting,
    steps,
  };

  return <AddCompanyNewContext.Provider value={value}>{children}</AddCompanyNewContext.Provider>;
};

export const useAddCompanyContext = () => {
  const context = useContext(AddCompanyNewContext);
  if (!context) {
    throw new Error("Should be inside AddCompanyNewContextProvider");
  }

  return context;
};
