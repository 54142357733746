import Sheet from "react-modal-sheet";
import { Button, IosTransitionEnter } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { trelloColumns } from "@/data/data.tsx";

export const OpportunityActionListDialog = ({ company }) => {
  const inPipeline = trelloColumns.some(
    (col) => col.value === company?.trello_card?.list_id
  );
  const inTrello = company?.is_tr_company;

  return (
    <IosTransitionEnter type="static">
      <div
        className={"flex h-full flex-grow flex-col items-center rounded-t-md"}
      >
        <div className="p-4">
          <div className="h-[3px] w-[24px] rounded-lg bg-neutral-500" />
        </div>
        <Sheet.Content
          className={"mx-5 flex h-full w-full justify-between rounded-t-md"}
        >
          <div className={"mb-2 ml-5 flex w-full flex-col"}>
            <div className="flex flex-col justify-start">
              {!inTrello && (
                <>
                  <Button
                    text={"Add to Trello"}
                    variant={"tertiary"}
                    iconRight={"Trello"}
                    className="!justify-start"
                    size={"lg"}
                    onClick={() => {
                      globalModalStore.navigate({
                        modalType: "OpportunityAddTrello",
                        navState: {
                          label: "Add Trello",
                        },
                      });
                    }}
                  />
                  <Button
                    text={"Share"}
                    size={"lg"}
                    variant={"tertiary"}
                    className="!justify-start"
                    iconRight={"Share"}
                    onClick={() =>
                      globalModalStore.navigate({
                        modalType: "OpportunityShare",
                        navState: {
                          label: "Share with",
                        },
                      })
                    }
                  />
                  <Button
                    text={"Move to Pipeline"}
                    size={"lg"}
                    variant={"tertiary"}
                    className="!justify-start"
                    iconRight={"Snooze"}
                    onClick={() =>
                      globalModalStore.navigate({
                        modalType: "OpportunitySnooze",
                        navState: {
                          label: "Snooze",
                        },
                      })
                    }
                  />
                </>
              )}
              <Button
                text={"Sentiment"}
                size={"lg"}
                variant={"tertiary"}
                className="!justify-start"
                iconRight={"Add"}
                onClick={() =>
                  globalModalStore.navigate({
                    modalType: "AddNew",
                    navState: { useCustomLoader: true, sentimentOnly: true },
                  })
                }
              />
            </div>
          </div>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
};
