import * as React from "react";
const SvgLatitude = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 337.1 400", style: {
  enableBackground: "new 0 0 337.1 400"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n"), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 168.5295, y1: 22.5991, x2: 168.5295, y2: 384.2388, gradientTransform: "matrix(1 0 0 -1 0 400)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0.125, style: {
  stopColor: "#FFDD00"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.75, style: {
  stopColor: "#008A37"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#008A37"
} })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M246.9,254.9l-57.1-34.4v-150c0-34.4,11.6-57.6,29-57.6c10.7,0,14.7,7.1,14.7,27.2v3.6L233,57.1 c0,32.6,13.8,46.4,54.5,54.9c-5.8,1.8-10.3,2.7-12.9,3.1c-38.4,8.5-50.5,19.2-50.5,44.6c0,20.5,7.1,29,36.2,43.8 c31.2,15.6,41.5,25.4,41.5,38.8c0,12.9-12,24.1-25.9,24.1C267,266.5,264.7,265.6,246.9,254.9 M184.4,46.4c-7.1-12.1-10.3-17-17-22.8 C152.2,10.3,125.9,2.2,97.3,2.2C39.3,2.2,0,35.7,0,85.3c0,29,15.6,51.8,46.9,66.1c9.8,4.9,13.4,8,13.4,13.8 c0,8.5-7.6,13.8-33.5,22.8v4c42-9.8,66.5-36.2,66.5-71c0-21.4-7.6-33.9-32.1-52.2L47.8,58.5C37.1,50.9,33,45.1,33,37.9 c0-13.4,14.7-24.6,33-24.6c12.9,0,24.6,6.2,31.2,16.5c9.8,14.7,13.4,34.4,13.8,78.1l0.9,87.9c-5.4-0.4-9.4-0.4-12.9-0.4 c-35.3,0-62.1,33.5-65.2,82.1h4c2.7-18.3,9.4-27.2,20.5-27.2c8.9,0,27.2,6.2,55.8,19.2l1.3,10.3c1.8,12.9,3.1,23.7,4.5,32.6 c4.9,31.3,7.6,42.4,18.7,77.7l3.6,9.8h7.6c6.2-14.7,7.6-19.2,14.7-40.6c10.7-31.3,13.4-42.4,17.9-63.8c17.9,8.9,29,12.5,38.4,12.5 c17,0,34.4-7.1,54.5-22.8c36.2-28.6,59.8-70.1,59.8-106.3c0-17.9-8.5-28.6-28.1-37.1c-22.3-9.4-25.9-12.1-25.9-16.5 c0-6.3,8.9-9.4,28.1-10.7l21.4-1.8l6.3-0.9v-4.9c-8.5,1.3-11.2,1.3-15.6,1.3c-13.8,0-18.8-4.5-18.8-17.4c0-3.1,0.4-6.7,1.3-9.8 l2.7-11.2c2.2-8.5,3.1-15.6,3.1-22.8c0-29.5-23.2-47.3-62.1-47.3C212.1,0,195.1,12.9,184.4,46.4" }));
export default SvgLatitude;
