import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import React from "react";

import { AttachmentsCommentsData } from "@/app/screens/opportunities/opportunity/components/highlights/attachments-comments/use-attachments-comments.ts";
import { Summary } from "@/app/screens/opportunities/opportunity/components/summary.tsx";

const DateBadge = ({ date }: { date?: string }) => {
  return date ? (
    <HighlightsCardLastUpdated date={date} />
  ) : (
    // need this one to align labels
    <div className="h-7"></div>
  );
};

export const AttachmentsCommentsCard = ({
  data: attachmentsComments,
}: {
  data: AttachmentsCommentsData | null;
}) => {
  return (
    <HighlightsCard type={"Engagement"}>
      {attachmentsComments && (
        <div
          className={
            "flex justify-between gap-x-3 lg:gap-x-5 xl:gap-x-12 2xl:gap-x-20 h-full"
          }
        >
          <Summary summaryType={"comments"} />

          <div className={"flex flex-col items-center justify-between gap-y-3"}>
            <HighlightsCardTitle text={String(attachmentsComments.title[0])} />
            <a href={"#attachments"}>
              <HighlightsCardNumber
                value={attachmentsComments.attachmentsNo}
                className={"cursor-pointer py-2.5"}
              />
            </a>
            <DateBadge date={attachmentsComments.lastAttachmentsDate} />
          </div>
          <div className={"flex flex-col items-center justify-between gap-y-3"}>
            <HighlightsCardTitle text={String(attachmentsComments.title[1])} />
            <a href={"#comments"}>
              <HighlightsCardNumber
                value={attachmentsComments.commentNo}
                className={"cursor-pointer py-2.5"}
              />
            </a>
            <DateBadge date={attachmentsComments.lastCommentDate} />
          </div>
        </div>
      )}
      {!attachmentsComments && (
        <HighlightsCardEmptyState
          text={"No Comments and Attachments"}
        />
      )}
    </HighlightsCard>
  );
};
