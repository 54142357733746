import { createContext, PropsWithChildren, useContext, useState } from "react";

import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router";

import { useCompanyDetails } from "@/app/hooks/use-company-details";
import { GetCompanyQuery } from "@/app/service/opportunities.gql.ts";
import { LGMember } from "@/app/stores/members.store";
import { CompanyUserTags } from "@/app/types";

export type OpportunityCompany = GetCompanyQuery["lgCompany"];

// need this as some of the screens cannot display gradations of grey properly and everything blends into a grey mass
export type CompanyViewMode = "normal" | "contrast";

type OpportunityContextData = {
  company: OpportunityCompany;
  signalAssignees: LGMember[];
  pcgLeads: LGMember[];
  userTags: CompanyUserTags;
  badges?: any;
  portCo?: any;
  tagline?: string;
  name?: string;
  image?: string;
  trelloColumn?: string;
  refresh?: () => void;
  loading?: boolean;

  viewMode: CompanyViewMode;
  toggleViewMode: (mode: CompanyViewMode) => void;
};

const OpportunityContext = createContext<OpportunityContextData>({
  company: null,
  signalAssignees: [],
  pcgLeads: [],
  userTags: {},
  viewMode: "normal",
  toggleViewMode: () => {},
});

export const OpportunityProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams();

  const {
    company,
    signalAssignees,
    assignees,
    badges,
    tagline,
    trelloColumn,
    image,
    name,
    portCo,
    userTags,
    refetch,
    loading,
  } = useCompanyDetails(id!);

  const [viewMode, setViewMode] = useState<CompanyViewMode>("normal");

  const refresh = async () => {
    if (id) {
      await refetch({ id });
      toast.success("Data has been refreshed", { duration: 1000 });
    }
  };

  const value = {
    company,
    signalAssignees,
    pcgLeads: assignees,
    badges,
    tagline,
    trelloColumn,
    image,
    name,
    portCo,
    userTags,
    refresh,
    loading,
    viewMode,
    toggleViewMode: setViewMode,
  };

  useHotkeys("mod+j", () => setViewMode((prevState) => (prevState === "normal" ? "contrast" : "normal")));

  return <OpportunityContext.Provider value={value}>{children}</OpportunityContext.Provider>;
};

export const useOpportunityContext = (): OpportunityContextData => {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error("Must be used inside OpportunityProvider");
  }

  return context;
};
