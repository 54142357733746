import { observer } from "mobx-react";

import { Modal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ActionButtons, ActionModalHeader } from "../components";

import { useSnoozeOptions } from "./hooks";
import { SnoozeModalProps } from "./types";

export const SnoozeWeb = observer((props: SnoozeModalProps) => {
  const { snoozeLoading, step, setStep, RenderForm } = useSnoozeOptions(props);

  return (
    <Modal
      showX
      isOpen
      handleClose={modalStore.close}
      header={<ActionModalHeader title={step.title} />}
      bodyClassName={"lg:max-w-[760px] p-8"}
      footer={
        <ActionButtons
          disabled={step.valid}
          isLoading={snoozeLoading}
          handleCancel={step.secondaryActionFn}
          primaryBtnTitle={step.mainAction}
          primaryBtnVariant={step.primaryBtnVariant}
          handlePrimaryAction={step.mainActionFn}
          secondaryBtnTitle={step.secondaryAction}
          backAction={() => setStep((prevState) => prevState - 1)}
          containerClassName={"bottom flex w-full flex-1 items-end justify-end space-x-4"}
        />
      }
    >
      {RenderForm}
    </Modal>
  );
});
