import { InputWithPills } from "@/app/screens/modal/components/input-with-pills.tsx";

import { Combobox } from "@headlessui/react";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";
import { CheckIcon } from "lucide-react";
import { combineTailwindStyles } from "@/app/misc/helpers.ts";
import React, { useRef, useState } from "react";
import { Icon, IconType, Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";

type WebSelectProps = {
  label?: string;
  autoFocus?: boolean;
  multiple?: boolean;
  className?: string;
  inputClassName?: string;
  resultsIcons?: boolean;
  required?: boolean;
  placeholder?: string;
  action?: string;
  iconRight?: IconType | null;
  iconLeft?: IconType;
  allOptions: OptionsType[];
  value?: OptionsType[];
  defaultSelected?: OptionsType[];
  storeCallback?: (value: OptionsType[], query?: string) => void;
  setValue?: (value: OptionsType[], query?: string) => void;
  onChange?: (...args) => void;
  containerClassName?: string;
  optionsClassName?: string;
  optionsDirection?: "bottom" | "top";
  disableInputFocus?: boolean;
  loading?: boolean;
  by?: string;
  canDelete?: boolean;
  disabled?: boolean;
};

export const WebSelect = React.memo(
  ({
    label = "",
    iconLeft,
    className = "",
    allOptions = [],
    defaultSelected = [],
    onChange,
    storeCallback,
    autoFocus = false,
    iconRight = "",
    multiple = true,
    canDelete = true,
    placeholder = "",
    required = false,
    resultsIcons = false,
    containerClassName = "",
    optionsDirection = "bottom",
    optionsClassName = "",
    disableInputFocus = false,
    inputClassName = "",
    loading = false,
    by = "label",
    disabled = false,
  }: WebSelectProps) => {
    const queryRef = useRef("");
    const inputRef = useRef(null);
    const [query, setQuery] = useState<string>("");
    const selectedOption = defaultSelected;

    const setSelectedOption = (updater) => {
      if (updater instanceof Function) {
        updater = updater(selectedOption);
      }

      storeCallback?.(updater);
    };

    const filteredValues: Array<OptionsType> =
      query === ""
        ? allOptions
        : allOptions.filter((person: OptionsType) => {
            return person.label.toLowerCase().includes(query.toLowerCase());
          });

    return (
      <div
        className={`flex w-full max-w-[100%] flex-col items-start ${containerClassName}`}
      >
        {label && (
          <div className={"mb-2 flex"}>
            <Text
              text={label}
              color={"text-neutral-600"}
              type={"message"}
              weight="light"
            />
            {required && (
              <Text
                text={"*"}
                color={"text-red"}
                type={"message"}
                weight="light"
                className={"ml-1"}
              />
            )}
          </div>
        )}
        <Combobox
          as="div"
          nullable
          by={by as any}
          multiple={multiple as any}
          disabled={disabled}
          value={selectedOption}
          onChange={(value) => {
            setQuery("");
            setSelectedOption((prevState) => {
              if (multiple) return value;
              if (!Array.isArray(value)) return [value];
              return value?.filter(
                (e) => !prevState.find((el) => el.label === e.label)
              );
            });
          }}
          className={`w-full ${className}`}
        >
          <div className="relative w-full rounded-tremor-default bg-white">
            <InputWithPills
              ref={inputRef}
              canDelete={canDelete}
              iconLeft={loading ? "Loader" : iconLeft}
              disableFocus={disableInputFocus}
              selectedOption={selectedOption}
              disabled={disabled}
              placeholder={placeholder}
              setSelectedOption={setSelectedOption}
            >
              <Combobox.Input
                className={cn(
                  `relative flex text-[14px] text-neutral-600 ${inputClassName}`
                )}
                onChange={(event) => {
                  if (onChange) onChange(event);
                  setQuery(event.target.value);
                }}
                ref={inputRef}
                autoFocus={autoFocus}
                value={query}
                onKeyUpCapture={(e) => {
                  e.preventDefault();
                  if (
                    e.code === "Backspace" &&
                    query.length === 0 &&
                    queryRef.current.length !== 1 &&
                    selectedOption.length
                  ) {
                    setSelectedOption((prevState) =>
                      prevState.slice(0, prevState.length - 1)
                    );
                  }
                  queryRef.current = e.currentTarget.value;
                }}
                displayValue={(person: OptionsType) => person?.label}
              />
            </InputWithPills>
            {iconRight !== null && (
              <Combobox.Button className={cn("absolute inset-y-0 flex w-full max-w-[100%] items-center justify-end rounded-r-md px-4", disabled && "opacity-30")}>
                <Icon
                  type={
                    optionsDirection === "bottom"
                      ? "Filter Chevron Down"
                      : "Filter Chevron Up"
                  }
                  className="h-5 w-5 text-black"
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}

            {filteredValues.length > 0 && (
              <Combobox.Options
                className={cn(
                  `text-md absolute z-20 mt-3 max-h-[40vh] w-full divide-y divide-neutral-200 overflow-auto rounded-md bg-white py-1 pt-1 shadow-sm`,
                  { "bottom-14": optionsDirection === "top" },
                  optionsClassName
                )}
              >
                {filteredValues
                  .sort((a) => {
                    if (!multiple) return 0;
                    return selectedOption.find((e) => e.value === a.value)
                      ? -1
                      : 1;
                  })
                  .map((person, index) => (
                    <Combobox.Option
                      key={person.label + index}
                      value={person}
                      className={({ active }) =>
                        combineTailwindStyles(
                          "relative select-none px-3 py-4  hover:cursor-pointer",
                          active ? "bg-neutral-200" : ""
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <div className="flex items-center">
                            {resultsIcons && (
                              <img
                                src={person.image || ""}
                                alt=""
                                className="h-6 w-6 flex-shrink-0 rounded-full"
                              />
                            )}
                            <span
                              className={combineTailwindStyles(
                                "ml-3 truncate",
                                selected && "text-primary"
                              )}
                            >
                              {person.label}
                            </span>
                          </div>

                          {selected && canDelete && (
                            <span
                              className={combineTailwindStyles(
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                active ? "text-primary" : "text-primary"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>
    );
  }
);
