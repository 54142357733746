import { CSSProperties, PropsWithChildren } from "react";

import { Column, flexRender, Header } from "@tanstack/react-table";
import { SortDirection } from "@tanstack/table-core";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { TableRowData } from "@/app/components/table-new";

import { getColumnStyles, useTableDensity } from "../table.hooks.ts";

export const HeaderCell = ({
  children,
  className,
  colSpan,
  extraStyles,
}: PropsWithChildren<{
  className?: string;
  width?: number;
  colSpan?: number;
  extraStyles?: CSSProperties;
}>) => {
  const { config } = useTableDensity();

  return (
    <th
      className={cn("font-medium text-neutral", config.headerCell, className)}
      colSpan={colSpan}
      style={{ ...extraStyles }}
    >
      {children}
    </th>
  );
};

const getIconName = (sort: SortDirection | false) => {
  switch (sort) {
    case "asc":
      return "UpDownSort";
    case "desc":
      return "DownUpSort";
    default:
      return "TableSort";
  }
};

export const HeaderSortIndicator = ({
  className,
  isSorted,
}: {
  className?: string;
  isSorted: false | SortDirection;
}) => {
  const { density } = useTableDensity();

  return (
    <div className={className}>
      <Icon
        type={getIconName(isSorted)}
        className={cn(density === "normal" && "size-3.5", density === "dense" && "size-3")}
      />
    </div>
  );
};

export const DefaultHeaderCell = <TData extends TableRowData, TValue>({
  header,
  children,
}: PropsWithChildren<{
  header: Header<TData, TValue>;
}>) => {
  const { density } = useTableDensity();

  // this is needed to customize header cell content. For instance for adding totals
  const isCustomHeaderCell = typeof header.column.columnDef.header === "function";
  const columnMeta = header.column.columnDef.meta;

  return (
    <HeaderCell
      colSpan={header.colSpan}
      // this is a hack for having a column with auto width
      className={header.column.columnDef.meta?.className}
      extraStyles={getColumnStyles(header)}
    >
      {header.isPlaceholder ? null : (
        <div
          className={cn("flex size-full select-none flex-col", header.column.getCanSort() && "cursor-pointer")}
          onClick={header.column.getToggleSortingHandler()}
        >
          {children}
          {isCustomHeaderCell ? (
            <>{flexRender(header.column.columnDef.header, header.getContext())}</>
          ) : (
            <div
              className={cn(
                "mt-auto flex items-center gap-1 py-2.5",
                density === "dense" && "justify-center",
                (columnMeta as any)?.align === "center" && "justify-center",
              )}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() && <HeaderSortIndicator isSorted={header.column.getIsSorted()} />}
            </div>
          )}
        </div>
      )}
    </HeaderCell>
  );
};
