import * as Sentry from "@sentry/react";
import { ErrorResponse } from "@apollo/client/link/error";

const captureGraphqlErrors = ({
  graphQLErrors,
  operation,
  networkError,
}: Pick<ErrorResponse, "graphQLErrors" | "operation" | "networkError">) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext("Graphql Operation", {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    });

    graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: "error",
        fingerprint: ["{{ default }}", "{{ transaction }}"],
        contexts: {
          ["Graphql Error"]: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      });
    });

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: "error",
        contexts: {
          ["Graphql Network Error"]: {
            error: networkError,
          },
        },
      });
    }
  });
};

const init = () => {
  Sentry.init({
    enabled: import.meta.env.MODE !== "development",
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          "staging.projectnazare.vc",
          "projectnazare.vc",
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Disable session replays for now
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
    normalizeDepth: 5,
  });
};

export default { init, captureGraphqlErrors };
