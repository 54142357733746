import { cn } from "@/lib/utils";

import { PopoverPlacement } from "./popover";

export interface DirectionProps {
  placement: PopoverPlacement;
  className?: string;
}

function getDirectionStyles(placement: PopoverPlacement) {
  switch (placement) {
    case "bottom":
      return "left-[50%] -top-2 border-t-0 border-l-0 -translate-x-1/2 translate-y-1/2";
    case "bottom-start":
      return "left-3 top-0 border-b-0 border-r-0 -translate-y-1/2";
    case "bottom-end":
      return "right-3 top-0 border-b-0 border-r-0 -translate-y-1/2";
    case "left-start":
      return "right-0 top-3 border-b-0 border-l-0 translate-x-1/2";
    case "right":
      return "left-0 top-[50%] border-b-0 border-l-0 -translate-y-1/2 -translate-x-1/2";
    case "top":
      return "left-[50%] bottom-0 border-t-0 border-l-0 -translate-x-1/2 translate-y-1/2";
    default:
      return "h-0 w-0";
  }
}

export const Direction = ({ placement, className }: DirectionProps) => {
  return (
    <div
      className={cn(
        "absolute z-10 size-3 -translate-y-1/2 rotate-45 bg-white",
        getDirectionStyles(placement),
        className,
      )}
    />
  );
};
