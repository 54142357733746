import { SyntheticEvent, useState } from "react";

export interface ActionWrapperOptions {
  event?: SyntheticEvent;
  action: (e?: SyntheticEvent) => void;
  label: string;
  isAsync?: boolean;
}

export const useActionWrapper = () => {
  const [loading, toggleLoading] = useState<Record<string, boolean>>({});

  const wrapAction = async ({ isAsync, label, action, event }: ActionWrapperOptions) => {
    if (isAsync) {
      try {
        toggleLoading({ [label]: true });
        await action(event);
      } finally {
        toggleLoading({});
      }
    }
    if (!isAsync) action(event);
  };

  return {
    loading,
    wrapAction,
  };
};
