import { PropsWithChildren } from "react";

import { Link } from "react-router-dom";

import { cn } from "@/lib/utils";

type CompanyLinkProps = {
  id: string;
  name: string;
  subTitle?: string;
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
};

export const CompanyLink = ({
  id,
  name,
  subTitle,
  children,
  className,
  titleClassName,
  subTitleClassName,
}: PropsWithChildren<CompanyLinkProps>) => {
  return (
    <Link to={`/company/${id}`} target={"_blank"}>
      <div className={cn("flex items-center", className)}>
        {children}
        <div className="flex flex-col items-start">
          <span
            className={cn("line-clamp-1 text-base capitalize  hover:text-neutral-800 hover:underline", titleClassName)}
          >
            {name}
          </span>
          {subTitle && <span className={cn("text-xs capitalize text-neutral-400", subTitleClassName)}>{subTitle}</span>}
        </div>
      </div>
    </Link>
  );
};
