import { Suspense, useState } from "react";

import { observer } from "mobx-react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Loader, Text } from "@/app/components";
import { notificationStore } from "@/app/screens/notifications";
import { NotificationsList } from "@/app/screens/notifications/components";

export const NotificationsDashboard = observer(() => {
  const { isBigTablet } = useBreakpoints();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  return (
    <div
      className={`flex w-full flex-col items-center justify-start overflow-auto bg-background p-4 pt-[10dvh] lg:pt-4`}
    >
      <div id={"header"} className={"mb-4 flex w-full items-center justify-between lg:w-4/5"}>
        <div className={"flex items-baseline"}>
          <Text text={"Inbox"} type={isBigTablet ? "h4" : "h5"} />
          &nbsp;
          <Text
            text={`(${notificationStore.notificationNumber})`}
            type={isBigTablet ? "title" : "subtitle"}
            color={"text-neutral-400"}
          />
        </div>
        <div className={"flex space-x-2 pr-6 md:space-x-3"}>
          {[
            ["sentiment_request", "Sentiment"],
            ["shared_opportunity", "Company"],
            ["email_opportunity", "Mail"],
          ].map(([type, icon]) => (
            <Text
              key={type}
              text={icon}
              className={cn("cursor-pointer rounded-sm p-2 text-black", {
                "bg-neutral-900 text-white hover:text-white": selectedTypes.some((item) => item === type),
              })}
              onClick={() => setSelectedTypes(selectedTypes[0] === type ? [] : [type])}
            />
          ))}
        </div>
      </div>
      <div id={"body"} className={"flex h-fit w-full justify-center"}>
        <Suspense fallback={<Loader className="relative bg-background pt-20" />}>
          <NotificationsList containerClassName={"flex w-full lg:w-[80%] rounded-md"} selectedTypes={selectedTypes} />
        </Suspense>
      </div>
    </div>
  );
});
