import { useState } from "react";
import modalStore from "@/app/stores/modal.store";
import { RequestSentimentModal } from "@/app/screens/modal"
import { OptionValue } from "../modal-content";
import {  AddCompanyNewModal } from "@/app/screens/modal/actions/add-company-new/add-company-new-modal.tsx";

export const useSelectModal = ({ company }) => {
  const [selected, handleSelect] = useState<OptionValue | undefined>();

  const handleNext = () => {
    if (selected === "opportunity") {
      modalStore.progress(AddCompanyNewModal)
    } else if (selected) {
      modalStore.progress(RequestSentimentModal, { props: { type: selected, company }});
    }
  }

  return {
    selected,
    handleNext,
    handleSelect,
  };
};
