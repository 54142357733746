import {
  CompanyAdditionalDetails,
  CompanyDetails,
  CompanyName,
  Founders,
  Note,
  Signal,
} from "./add-opportunity-web-components.tsx";
import { CalendarProps, IconType } from "@/app/components";
import {
  CompanyDetailsMobile,
  CompanySignalMobile,
} from "@/app/screens/modal/opportunities/components/add-opportunity-mobile-components.tsx";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { FlowRouteType } from "@/app/screens/modal/global-native-modal.tsx";

export type StepType =
  | "name"
  | "founders"
  | "company_details"
  | "additional_details"
  | "assignees_notes"
  | "signal";

export type OpportunitiesStep = {
  Component: (...props) => JSX.Element;
  title: string;
  icon: IconType;
  primaryButton: { text: string; icon?: IconType };
  secondaryButton: { text: string; icon?: IconType };
  required: string[];
};
export type OpportunitiesSteps = Record<StepType, OpportunitiesStep>;

export interface NavigateToModalParams {
  fieldId: string;
  configId: FlowRouteType;
  flowId: string;
  label?: string;
  value?: unknown;
  rangeSelect?: boolean;
  multiSelect?: boolean;
  calendarProps?: Partial<CalendarProps>;
}

export const opportunitiesSteps = (
  step?: StepType,
  platform?: "web" | "mobile"
): OpportunitiesStep | OpportunitiesSteps => {
  const steps = {
    name: {
      Component: CompanyName,
      icon: "Opportunity",
      title: "Company Name",
      primaryButton: { text: "Next" },
      secondaryButton: { text: "Back" },
      required: ["name"],
    },
    founders: {
      Component: Founders,
      icon: "Linkedin",
      title: "Founders",
      primaryButton: { text: "Next" },
      secondaryButton: { text: "Back" },
      required: ["founders"],
    },
    company_details: {
      Component: platform === "web" ? CompanyDetails : CompanyDetailsMobile,
      icon: "BadgeInfo",
      title: "Additional Information",
      primaryButton: { text: "Next" },
      secondaryButton: { text: "Back" },
      required: ["about"],
    },
    additional_details: {
      Component: CompanyAdditionalDetails,
      icon: "BadgeInfo2",
      title: "Additional Information",
      primaryButton: { text: "Next" },
      secondaryButton: { text: "Back" },
      multi: true,
      required: [],
    },
    assignees_notes: {
      Component: Note,
      icon: "StickyNote",
      title: "Company Note",
      primaryButton: { text: "Next" },
      secondaryButton: { text: "Back" },
      required: [],
    },
    signal: {
      Component: platform === "web" ? Signal : CompanySignalMobile,
      icon: "Signal",
      title: "Source & Assignees",
      primaryButton: { text: "Add Opportunity", icon: "Add" },
      secondaryButton: { text: "Back" },
      required: [],
    },
  };
  if (!step) return steps;
  return steps[step];
};

export function navigateToSelect(
  fieldId: string,
  configId: FlowRouteType,
  goBackState?: Record<string, any>
) {
  globalModalStore.progress(
    {
      label: configId,
      fieldId,
      multiSelect: true,
      withButtons: {
        buttonText: `${fieldId}`,
        onClick: () => globalModalStore.goBack(goBackState),
      },
    },
    { flowId: "SelectComponent", configId }
  );
}

export function navigateToModal({
  label,
  fieldId,
  flowId,
  configId,
  value,
  rangeSelect,
  multiSelect = true,
  calendarProps = {},
}: NavigateToModalParams) {
  globalModalStore.progress(
    {
      label,
      fieldId,
      multiSelect,
      value,
      rangeSelect,
      calendarProps,
      withButtons: {
        buttonText: `${fieldId}`,
        onClick: () => globalModalStore.goBack(),
      },
    },
    { flowId, configId }
  );
}
