import posthog from "posthog-js";
import browserStorage from "store";

import { service } from "@/lib/service/index.ts";

import { authStore } from "@/app/stores/auth.store.tsx";

export const TOKEN_EXPIRY_LOCAL_KEY = "tokenExpiration";
export const REFRESH_TOKEN_EXPIRY_LOCAL_KEY = "refreshTokenExpiration";
export const IMPERSONATE_MEMBER_ID_KEY = "impersonateId";

export const loginWithGoogle = async (code) => {
  const response = await service.post("/auth/google/", {
    code,
  });
  return response.data;
};

export const getUserInfo = async () => {
  const response = await service.get(`/auth/user/`);
  return response.data;
};

export interface IRefreshResponse {
  access_token: string;
  access_expiration: string;
}

export const refreshAccessTokenFn = async () => {
  const response = await service.post<IRefreshResponse>("auth/token/refresh/");
  authStore.tokenExpiration = response.data.access_expiration;

  return response.data;
};

service.interceptors.request.use(async function (config) {
  const tokenExpiry = authStore.tokenExpiration;
  const refreshTokenExpiry = authStore.refreshTokenExpiration;

  const tokenExpiryDate = new Date(tokenExpiry!);
  const refreshTokenExpiryDate = new Date(refreshTokenExpiry!);
  const impersonateJson = browserStorage.get(IMPERSONATE_MEMBER_ID_KEY);
  const impersonate = impersonateJson ? JSON.parse(impersonateJson) : null;

  const now = new Date();
  const isExpired = tokenExpiryDate < now;
  const isRefreshExpired = refreshTokenExpiryDate < now;

  if (config.url == "/auth/google/" || config.url == "auth/token/refresh/") {
    return config;
  }

  if (!tokenExpiry || !refreshTokenExpiry) {
    window.location.href = "/login";
  }

  if (isRefreshExpired) {
    window.location.href = "/login";
  }

  if (isExpired) {
    await refreshAccessTokenFn();
  }

  if (impersonate) {
    config.headers["X-Impersonate"] = impersonate.id;
  }

  return config;
});

service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status == 401) {
      window.location.href = "/login";
      posthog?.reset();
    }
    throw error;
  },
);
