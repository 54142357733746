export type InvestmentBasecampFundsData = {
  title: string;
  fundsTotal: number;
  funds: { name: string; image?: string }[];
};

export const useInvestedBasecampFunds = (
  basecamp: any
): InvestmentBasecampFundsData | null => {
  if (!basecamp?.extra) {
    return null;
  }

  const funds = basecamp?.extra as Array<{ name: string; image: string }>;

  return { title: "Basecamp Funds", funds, fundsTotal: funds.length };
};
