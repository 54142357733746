import { useNavigate } from "react-router";

import { LgCompany } from "@/gql/types.ts";

import { Button, ImageWithErrorFallback, Text } from "@/app/components";
import { timeAgo } from "@/app/misc/helpers.ts";
import { AssignModalResponsive } from "@/app/screens/modal";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import { Signal, Tags } from "@/app/screens/opportunities/components";
import modalStore from "@/app/stores/modal.store.tsx";

type MobileCardContainerProps = {
  opportunities: LgCompany[];
  currentTab: string;
};

// todo looks like not used and can be removed
export function MobileCardContainer({ opportunities, currentTab }: MobileCardContainerProps) {
  const navigate = useNavigate();
  return (
    <>
      {opportunities.length ? (
        <ul role="list" className="m-4 grid w-full auto-cols-fr auto-rows-fr grid-cols-1 gap-4 px-6 md:grid-cols-2">
          {opportunities.map(({ id, country, badges, createdUtc, name, image, fundingRounds, industries, tagline }) => {
            const round = fundingRounds?.items?.[0]?.round;
            const signal = badges?.[0];
            return (
              <li
                key={id}
                className="col-span-1 flex w-full flex-col justify-between rounded-lg bg-white p-5 shadow-md"
                onClick={() => navigate(`/company/${id}`)}
              >
                <div className={"mb-2 flex flex-col"}>
                  <div className="flex w-full">
                    <ImageWithErrorFallback
                      alt={"smt"}
                      src={image}
                      iconClassName={"bg-background rounded-sm"}
                      className={"size-12 rounded-sm p-0.5 text-primary"}
                    />

                    <div className={"ml-4 flex w-full flex-col justify-center"}>
                      <Text text={name} type={"title"} />
                      {createdUtc && <Text text={timeAgo(createdUtc)} type={"small"} color={"text-neutral"} />}
                    </div>
                  </div>
                  <div className={"h-fit"}>
                    <Tags
                      country={country}
                      industry={industries?.[0]}
                      round={round}
                      containerClassName={"my-3 mx-0.5"}
                    />
                    <Text text={tagline} noOfLines={3} type={"body"} weight={"light"} />
                  </div>
                </div>
                {currentTab === "live" && signal && <Signal containerClassName={"my-2 h-7"} signal={signal} />}
                <div className={`mt-3.5 flex w-full justify-end pl-[33%]`}>
                  {currentTab === "live" && (
                    <Button
                      text={"Snooze"}
                      variant={"outline"}
                      iconRight={"Snooze"}
                      size={"sm"}
                      iconRightClassName={"text-primary"}
                      onClick={(e) => {
                        e.stopPropagation();
                        opportunityModal({
                          id,
                          platform: "mobile",
                          action: "Snooze",
                        });
                      }}
                      className={"mx-2 w-[50%]"}
                    />
                  )}
                  <Button
                    text={"Share"}
                    variant={"outline"}
                    onClick={(e) => {
                      e.stopPropagation();
                      modalStore.open(AssignModalResponsive, {
                        props: { id },
                      });
                    }}
                    size={"sm"}
                    iconRight={"Share"}
                    className={`${signal ? "mx-2" : ""}  w-[50%]`}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
}
