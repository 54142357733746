import { observer } from "mobx-react";

import { NativeModal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ActionButtons, ActionModalHeader } from "../components";
import { useSnoozeOptions } from "./hooks";
import { SnoozeModalProps } from "./types";

export const SnoozeMobile = observer((props: SnoozeModalProps) => {
  const { step, snoozeLoading, handleSubmit, RenderForm } = useSnoozeOptions(props);

  return (
    <NativeModal
      isOpen
      handleClose={modalStore.close}
      bodyContentClassName="p-6"
      headerContainerClassName="p-6"
      header={<ActionModalHeader title={step.title} />}
      footer={
        <ActionButtons
          primaryBtnTitle={step.title}
          secondaryBtnTitle="Cancel"
          isLoading={snoozeLoading}
          disabled={step.valid}
          btnCommonStyle="w-full"
          primaryBtnStyle="order-first mb-2"
          handleCancel={modalStore.close}
          handlePrimaryAction={handleSubmit}
          containerClassName={"mb-8 flex flex-col w-full px-6"}
        />
      }
    >
      {RenderForm}
    </NativeModal>
  );
});
