import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Mention, MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionProps } from "@tiptap/suggestion";
import tippy from "tippy.js";

import { cn } from "@/lib/utils.ts";

import { ImageWithErrorFallback } from "@/app/components";
import membersStore from "@/app/stores/members.store.tsx";

type MentionItem = {
  id: string;
  name: string;
  initials: string;
  avatar?: string | null;
};

const MentionList = forwardRef(function MentionList(props: SuggestionProps<MentionItem>, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="flex flex-col gap-1 overflow-y-auto rounded-sm border bg-white max-h-[400px] shadow-sm">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={cn("flex items-center gap-2 px-2 py-1", index === selectedIndex && "bg-neutral-200")}
            key={item.id}
            onClick={() => selectItem(index)}
          >
            <ImageWithErrorFallback alt={item.initials} src={item.avatar} className="size-8 rounded-xs" />
            {item.name}
          </button>
        ))
      ) : (
        <div className="p-1">No result</div>
      )}
    </div>
  );
});

const useMembers = () => {
  const members = [...membersStore.members].sort(
    (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName),
  );

  const search = (query: string) => {
    if (!query) {
      return members;
    }
    return members.filter(
      (member) =>
        member.firstName.toLowerCase().startsWith(query.toLowerCase()) ||
        member.lastName.toLowerCase().startsWith(query.toLowerCase()),
    );
  };

  return {
    search,
  };
};

export const useMentions = (): Partial<MentionOptions<MentionItem>> => {
  const members = useMembers();

  return {
    suggestion: {
      items: ({ query }) => {
        return members
          .search(query)
          .map<MentionItem>((member) => ({
            id: member.id,
            name: [member.firstName, member.lastName].filter(Boolean).join(" "),
            initials: member.initials,
            avatar: member.avatar,
          }));
      },

      render: () => {
        let component;
        let popup;

        return {
          onStart: (props) => {
            component = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              return;
            }

            popup = tippy("body", {
              getReferenceClientRect: props.clientRect as () => DOMRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start",
            });
          },

          onUpdate(props) {
            component.updateProps(props);

            if (!props.clientRect) {
              return;
            }

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },

          onKeyDown(props) {
            if (props.event.key === "Escape") {
              popup[0].hide();

              return true;
            }

            return component.ref?.onKeyDown(props);
          },

          onExit() {
            popup[0].destroy();
            component.destroy();
          },
        };
      },
    },
  };
};

export const useMentionsExtension = () => {
  const { suggestion } = useMentions();

  return Mention.configure({
    HTMLAttributes: {
      class: "text-primary bg-neutral-100 rounded-xs px-1 py-0.5",
    },
    suggestion,
    renderHTML({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id.name}`;
    },
  });
};
