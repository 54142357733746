import React from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, Popover, Text, TooltipInfo } from "@/app/components";
import { ChangeIndicator } from "@/app/components/change-indicator/change-indicator.tsx";
import { ExcitementScoreData } from "@/app/screens/opportunities/opportunity/components/highlights/excitement-score/use-excitement-score.ts";
import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";
import { Summary } from "@/app/screens/opportunities/opportunity/components/summary.tsx";

export const ExcitementScoreCard = ({ data: excitementScore }: { data: ExcitementScoreData | null }) => {
  const changeStats = {
    increase: !!excitementScore?.increase && !isNaN(+excitementScore.increase) ? +excitementScore.increase : undefined,
    decrease: !!excitementScore?.decrease && !isNaN(+excitementScore.decrease) ? +excitementScore.decrease : undefined,
  };

  return (
    <HighlightsCard type={"Insight"} className={"group"}>
      {excitementScore && (
        <>
          <Summary summaryType={"excitement"} />
          <HighlightsCardTitle text={String(excitementScore.title)} />
          <div className={"flex items-baseline justify-center py-2"}>
            <Popover
              containerClassName="flex flex-col items-baseline"
              childrenContent={
                <div className="w-[250px] max-w-[250px]">
                  {excitementScore.members?.map(({ name, score }) => (
                    <div key={name} className="mb-1 flex w-full items-center justify-between px-1">
                      <Text text={name} className="text-xs text-black" />
                      <Text text={score.toString()} className="text-xs text-black" />
                    </div>
                  ))}
                  <TooltipInfo
                    renderText={excitementScore.infoText!}
                    alert={!!excitementScore.decrease}
                    success={!!excitementScore.increase}
                    info={excitementScore.isOld}
                  />
                </div>
              }
            >
              <HighlightsCardNumber value={excitementScore.number} options={{ decimals: 1 }} />
              <ChangeIndicator
                value={changeStats}
                options={{ text: { className: "text-sm" }, countUp: { decimals: 1 } }}
              />
            </Popover>
          </div>
          <HighlightsCardLastUpdated date={excitementScore.date} />
        </>
      )}
      {!excitementScore && <HighlightsCardEmptyState text={"No Sentiment Forms Filled"} />}
    </HighlightsCard>
  );
};
