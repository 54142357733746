import { useEffect, useMemo, useRef, useState } from "react";

import { AnimatePresence } from "framer-motion";
import Sheet, { SheetRef } from "react-modal-sheet";

import { cn } from "@/lib/utils.ts";

import { NativeModalProps } from "./types";

export const NativeModal = ({
  children,
  isOpen,
  snap,
  headerContainerClassName,
  containerClassName,
  bodyContainerClassName,
  bodyContentClassName,
  bodyClassName,
  sheetParams = {},
  detent,
  footer,
  header,
  handleClose,
}: NativeModalProps) => {
  const ref = useRef<SheetRef>();
  const [open, toggle] = useState(false);

  const content = useMemo(() => <AnimatePresence>{children}</AnimatePresence>, [children]);

  useEffect(() => {
    toggle(isOpen);
  }, [isOpen]);

  return (
    <Sheet
      ref={ref}
      rootId={"root"}
      detent={detent}
      isOpen={open}
      onClose={handleClose}
      initialSnap={snap}
      className={containerClassName}
      {...sheetParams}
    >
      <Sheet.Container className={bodyContainerClassName}>
        <Sheet.Header className={headerContainerClassName}>{header}</Sheet.Header>
        <Sheet.Content className={cn("mb-4 px-4 pt-4", bodyClassName)}>
          <Sheet.Scroller className={bodyContentClassName}>{content}</Sheet.Scroller>
          {footer}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
};
