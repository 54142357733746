import * as Types from "../../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetNotificationsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.NzrNotificationFilter>;
  orderBy?: Types.InputMaybe<Array<Types.NzrNotificationsOrderBy> | Types.NzrNotificationsOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetNotificationsQuery = {
  __typename?: "Query";
  nzrNotifications?: {
    __typename?: "NzrNotificationsConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "NzrNotificationsEdge";
      node: {
        __typename?: "NzrNotification";
        id: any;
        assignee: any;
        status: string;
        type: string;
        validity?: any | null;
        payload: any;
        createdUtc?: any | null;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type SetNotificationStatusMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
  status: Types.Scalars["String"]["input"];
}>;

export type SetNotificationStatusMutation = {
  __typename?: "Mutation";
  updateNzrNotification?: {
    __typename?: "UpdateNzrNotificationPayload";
    nzrNotification?: {
      __typename?: "NzrNotification";
      id: any;
      assignee: any;
      status: string;
      type: string;
      validity?: any | null;
      payload: any;
      createdUtc?: any | null;
    } | null;
  } | null;
};

export type NzrNotificationFragmentFragment = {
  __typename?: "NzrNotification";
  id: any;
  assignee: any;
  status: string;
  type: string;
  validity?: any | null;
  payload: any;
  createdUtc?: any | null;
};

export type GetOpportunityEmailQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type GetOpportunityEmailQuery = {
  __typename?: "Query";
  nzrOpportunityEmail?: {
    __typename?: "NzrOpportunityEmail";
    id: any;
    from?: string | null;
    to?: string | null;
    labels?: any | null;
    subject: string;
    source?: string | null;
    isParsed: boolean;
    isCompleted: boolean;
    status?: string | null;
    parser?: string | null;
    metadata?: any | null;
    senderEmail: string;
    body: string;
    memberId: any;
    nzrOpportunityEmailCandidatesByEmailId: {
      __typename?: "NzrOpportunityEmailCandidatesConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "NzrOpportunityEmailCandidate";
        name: string;
        description: string;
        url: string;
        dealroomCompany?: {
          __typename?: "DealroomCompany";
          id: any;
          name?: string | null;
          path?: string | null;
          websiteUrl?: string | null;
          image?: string | null;
          websiteDomain?: string | null;
        } | null;
        lgCompany?: {
          __typename?: "LgCompany";
          id: any;
          name: string;
          websiteUrl?: string | null;
          websiteDomain?: string | null;
          image: string;
        } | null;
      }>;
    };
  } | null;
};

export type SetOpportunityEmailMutationVariables = Types.Exact<{
  input: Types.EmailOpportunityInput;
}>;

export type SetOpportunityEmailMutation = { __typename?: "Mutation"; opportunityEmail?: any | null };

export const NzrNotificationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const GetNotificationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNotifications" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "NzrNotificationFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "NzrNotificationsOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrNotificationFragment" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetNotificationsSuspenseQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const SetNotificationStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetNotificationStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "status" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateNzrNotification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "patch" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: { kind: "Variable", name: { kind: "Name", value: "status" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrNotification" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "NzrNotificationFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrNotificationFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrNotification" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "assignee" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "validity" } },
          { kind: "Field", name: { kind: "Name", value: "payload" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SetNotificationStatusMutationFn = Apollo.MutationFunction<
  SetNotificationStatusMutation,
  SetNotificationStatusMutationVariables
>;

/**
 * __useSetNotificationStatusMutation__
 *
 * To run a mutation, you first call `useSetNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationStatusMutation, { data, loading, error }] = useSetNotificationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetNotificationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetNotificationStatusMutation, SetNotificationStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetNotificationStatusMutation, SetNotificationStatusMutationVariables>(
    SetNotificationStatusDocument,
    options,
  );
}
export type SetNotificationStatusMutationHookResult = ReturnType<typeof useSetNotificationStatusMutation>;
export type SetNotificationStatusMutationResult = Apollo.MutationResult<SetNotificationStatusMutation>;
export type SetNotificationStatusMutationOptions = Apollo.BaseMutationOptions<
  SetNotificationStatusMutation,
  SetNotificationStatusMutationVariables
>;
export const GetOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "nzrOpportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "from" } },
                { kind: "Field", name: { kind: "Name", value: "to" } },
                { kind: "Field", name: { kind: "Name", value: "labels" } },
                { kind: "Field", name: { kind: "Name", value: "subject" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "isParsed" } },
                { kind: "Field", name: { kind: "Name", value: "isCompleted" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "parser" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "senderEmail" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "memberId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nzrOpportunityEmailCandidatesByEmailId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dealroomCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "path" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "websiteDomain" },
                                    name: { kind: "Name", value: "websiteDomainFull" },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lgCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                                  { kind: "Field", name: { kind: "Name", value: "websiteDomain" } },
                                  { kind: "Field", name: { kind: "Name", value: "image" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetOpportunityEmailQuery__
 *
 * To run a query within a React component, call `useGetOpportunityEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOpportunityEmailQuery(
  baseOptions: Apollo.QueryHookOptions<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables> &
    ({ variables: GetOpportunityEmailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>(
    GetOpportunityEmailDocument,
    options,
  );
}
export function useGetOpportunityEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>(
    GetOpportunityEmailDocument,
    options,
  );
}
export function useGetOpportunityEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOpportunityEmailQuery, GetOpportunityEmailQueryVariables>(
    GetOpportunityEmailDocument,
    options,
  );
}
export type GetOpportunityEmailQueryHookResult = ReturnType<typeof useGetOpportunityEmailQuery>;
export type GetOpportunityEmailLazyQueryHookResult = ReturnType<typeof useGetOpportunityEmailLazyQuery>;
export type GetOpportunityEmailSuspenseQueryHookResult = ReturnType<typeof useGetOpportunityEmailSuspenseQuery>;
export type GetOpportunityEmailQueryResult = Apollo.QueryResult<
  GetOpportunityEmailQuery,
  GetOpportunityEmailQueryVariables
>;
export const SetOpportunityEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetOpportunityEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EmailOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SetOpportunityEmailMutationFn = Apollo.MutationFunction<
  SetOpportunityEmailMutation,
  SetOpportunityEmailMutationVariables
>;

/**
 * __useSetOpportunityEmailMutation__
 *
 * To run a mutation, you first call `useSetOpportunityEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpportunityEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpportunityEmailMutation, { data, loading, error }] = useSetOpportunityEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOpportunityEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOpportunityEmailMutation, SetOpportunityEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOpportunityEmailMutation, SetOpportunityEmailMutationVariables>(
    SetOpportunityEmailDocument,
    options,
  );
}
export type SetOpportunityEmailMutationHookResult = ReturnType<typeof useSetOpportunityEmailMutation>;
export type SetOpportunityEmailMutationResult = Apollo.MutationResult<SetOpportunityEmailMutation>;
export type SetOpportunityEmailMutationOptions = Apollo.BaseMutationOptions<
  SetOpportunityEmailMutation,
  SetOpportunityEmailMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyNote",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
    ],
  },
};
export default result;
