import React from "react";

import { ArrayPath, Control, Controller, FieldValues, useFieldArray } from "react-hook-form";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Button, IconButton, Input } from "@/app/components";
import { FoundersFormData } from "@/app/screens/opportunities/add-new/shared/forms/use-company-founders-form.ts";

interface FoundersFieldValues extends FieldValues {
  [key: string]: FoundersFormData | FoundersFieldValues | FoundersFieldValues[];
}

export const CompanyFoundersFields = <
  TFieldValues extends FoundersFieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>({
  name,
  control,
}: {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  const { isBigTablet } = useBreakpoints();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
    rules: { minLength: 1 },
  });

  const handleAddNew = () => {
    append(
      {
        name: "",
        url: "",
      },
      {
        shouldFocus: true,
      },
    );
  };

  return (
    <div className="h-full">
      <div className="max-h-[400px] overflow-y-auto pr-2">
        {fields.map((fieldArrayItem, index, array) => (
          <Controller
            key={fieldArrayItem.id}
            control={control}
            name={`${name}.${index}`}
            render={({ field, fieldState }) => (
              <div
                key={fieldArrayItem.id}
                className={cn("flex min-w-[250px] flex-col overflow-hidden lg:flex-row lg:items-end lg:gap-3")}
              >
                <Input
                  iconLeft={"Linkedin"}
                  placeholder={"linkedin.com/in/user-name"}
                  label={isBigTablet && index === 0 ? "LinkedIn URL" : ""}
                  required
                  value={field.value.url}
                  onChange={(e) => field.onChange({ ...field.value, url: e.target.value })}
                  containerClassName="grow relative mb-4"
                  error={fieldState.error?.url?.message}
                  errorClassName="absolute -bottom-4 font-normal"
                />
                <Input
                  iconLeft={"User"}
                  placeholder={"Name"}
                  label={isBigTablet && index === 0 ? "Name" : ""}
                  required
                  value={field.value.name}
                  onChange={(e) => field.onChange({ ...field.value, name: e.target.value })}
                  containerClassName="grow relative mb-4"
                  error={fieldState.error?.name?.message}
                  errorClassName="absolute -bottom-4 font-normal"
                />
                {array.length > 1 && (
                  <IconButton icon={"CloseCircle"} className="mx-auto mb-7 size-5" onClick={() => remove(index)} />
                )}
              </div>
            )}
          />
        ))}
      </div>
      <Button
        type={"button"}
        text={"Add Founder"}
        iconRight={"AddGradient"}
        size={"md"}
        variant="outline"
        className="rounded-md mt-2"
        onClick={handleAddNew}
      />
    </div>
  );
};
