import React, { useMemo, useState } from "react";
import { CompanyHistory, getOptions } from "@/app/components/charts/company-history.tsx";
import { TabBar } from "@/app/components";
import { ChartOptions } from "chart.js";
import { EmptySection } from "@/app/screens/opportunities/opportunity/components/empty-section.tsx";
import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";
import { cn } from "@/lib/utils.ts";
import { useCustomBreakpoints } from "@/lib/hooks";

const useTabs = (company) => {
  // need a custom media query here to prevent button overlapping
  const largerThanPhone = useCustomBreakpoints({ min: 450 });
  const tabs = [
    {
      label: "Headcount",
      value: "perf",
      show: company?.fundingRounds?.items?.length > 0 || company?.employeesChart?.length > 0,
      icon: "Users",
    },
    {
      label: "Web Traffic",
      value: "visibility",
      show: company?.websiteTrafficChart?.length > 0,
      icon: "Continent",
    },
  ];

  return largerThanPhone ? tabs.map(({ icon, ...tab }) => tab) : tabs;
};

export const PerformanceChart = ({ company }) => {
  const [selected, setSelected] = useState("perf");

  const { showColumns } = useMemo(() => {
    let showColumns: string[] = [];
    if (selected === "perf") {
      showColumns = ["employee", "funding_round"];
    }
    if (selected === "visibility") {
      showColumns = ["website"];
    }
    return { showColumns };
  }, [selected]);

  const tabs = useTabs(company);

  const { datasets, labels, options } = useMemo(() => {
    const employeesChart = showColumns.includes("employee")
      ? (company?.employeesChart || []).reduce((prev, curr) => {
          prev[curr.date.split("-").slice(0, -1).join("-")] = curr.value;
          return prev;
        }, {})
      : {};
    const fundingRoundsChart = showColumns.includes("funding_round")
      ? (company?.fundingRounds?.items || []).reduce((prev, curr) => {
          prev[`${curr.year}-${(curr.month || 1).toString().padStart(2, 0)}`] = curr.amountUsdMillion;
          return prev;
        }, {})
      : {};
    const websiteGrowthChart = showColumns.includes("website")
      ? (company?.websiteTrafficChart || []).reduce((prev, curr) => {
          prev[curr.date.split("-").slice(0, -1).join("-")] = curr.value;
          return prev;
        }, {})
      : {};

    const employeesChartExists = Object.keys(employeesChart).length > 0 || Object.keys(fundingRoundsChart).length > 0;
    const websiteChartExists = Object.keys(websiteGrowthChart).length > 0;

    const labels = Array.from(
      new Set([...Object.keys(fundingRoundsChart), ...Object.keys(employeesChart), ...Object.keys(websiteGrowthChart)]),
    );
    labels.sort();

    const datasets: any[] = [];
    if (showColumns.includes("employee") && employeesChartExists) {
      datasets.push({
        type: "bar" as const,
        label: "Funding (M)",
        data: labels.map((label) => fundingRoundsChart[label]),
        borderColor: "rgb(99,255,107)",
        backgroundColor: "rgba(99,255,130,0.5)",
        yAxisID: "employee",
      });
    }
    if (showColumns.includes("funding_round") && employeesChartExists) {
      datasets.push({
        type: "line" as const,
        label: "Employees",
        data: labels.map((label) => employeesChart[label]),
        borderColor: "#fd5003",
        backgroundColor: "rgb(236,18,18, 0.5)",
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        yAxisID: "funding_round",
      });
    }
    if (showColumns.includes("website") && websiteChartExists) {
      datasets.push({
        type: "line" as const,
        label: "Website Traffic",
        data: labels.map((label) => websiteGrowthChart[label]),
        borderColor: "rgb(168,53,235)",
        backgroundColor: "rgb(168,43,243)",
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        yAxisID: "website",
      });
    }

    const options = getOptions(showColumns) as ChartOptions;

    return { datasets, options, labels };
  }, [showColumns, company]);

  if (!tabs.some((item) => item.show)) {
    return <EmptySection text={"No Performance History Available"} />;
  }

  return (
    tabs.some((item) => item.show) && (
      <section>
        <div className={cn("bg-white p-3 lg:p-6 lg:pt-4", sectionBorderRadiusClassName)}>
          <div className="relative flex min-h-[440px] w-full">
            <TabBar
              containerClassName={"absolute top-0 right-0"}
              tabs={tabs.filter((item) => item.show)}
              selectedTab={selected}
              onClick={(idx) => setSelected(tabs[idx].value)}
              elementProps={{
                icon: {
                  className: "h-9 w-9 bg-neutral-100 p-1 rounded-sm mx-0",
                },
              }}
            />
            <CompanyHistory datasets={datasets} options={options} labels={labels} />
          </div>
        </div>
      </section>
    )
  );
};
