import posthog from "posthog-js";

const appVersion = import.meta.env.VITE_APP_VERSION;
const environment = import.meta.env.MODE;

export type FeedbackMetadata = {
  appVersion: string;
  environment: string;
  currentUrl: string;
  posthog?: object;
};

export const useFeedbackFormMetadata = (): FeedbackMetadata => {
  return {
    appVersion,
    environment,
    currentUrl: window.location.href,
    posthog: {
      sessionId: posthog?.get_session_id(),
      sessionReplayUrl: posthog?.get_session_replay_url(),
    },
  };
};
