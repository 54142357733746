import React from "react";

import { useFormContext } from "react-hook-form";

import { CompanyFoundersFields } from "@/app/screens/opportunities/add-new/shared/components/company-founders-fields.tsx";
import { CompaniesFromEmailForm } from "@/app/screens/opportunities/add-new/via-email/use-add-company-from-email-form.ts";

export const CompanyFoundersSection = ({ index }: { index: number }) => {
  const { control } = useFormContext<CompaniesFromEmailForm>();

  return <CompanyFoundersFields control={control} name={`companies.${index}.company.founders.founders` as const} />;
};
