import { ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Text } from "@/app/components";

type HeaderWithTotalsProps = {
  label: string;
  value: ReactNode;
};

export const TableCellTotals = ({ label, value }: HeaderWithTotalsProps) => {
  return (
    <div className={cn("w-full")}>
      <div className={cn("w-full justify-center bg-neutral-200 text-center")}>
        <Text type={"small"} text={label} weight={"medium"} className="leading-4" />
      </div>
      {value}
    </div>
  );
};
