import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { Button } from "@/app/components";

export type FeedbackToggleBtnRef = {
  setSuccess: () => void;
};
type FeedbackToggleBtnProps = {
  onClick: () => void;
  isOpen: boolean;
};

export const FeedbackToggleBtn = forwardRef<FeedbackToggleBtnRef, FeedbackToggleBtnProps>(({ onClick }, ref) => {
  const [status, setStatus] = useState<"pending" | "success">("pending");

  useImperativeHandle(
    ref,
    () => {
      return {
        setSuccess: () => {
          setStatus("success");
        },
      };
    },
    [],
  );

  useEffect(() => {
    let timeout: number;
    if (status === "success") {
      timeout = setTimeout(() => {
        setStatus("pending");
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  const buttonText = status === "success" ? "Thank You!" : "Report Issue";
  return (
    <Button
      className="absolute -left-2 top-1/2 h-4 -translate-x-1/2 -translate-y-1/2 -rotate-90 flex-col rounded-b-none rounded-t-md bg-opacity-80 hover:bg-opacity-100 lg:-left-3 lg:h-6"
      onClick={onClick}
      variant={"black"}
      size={"sm"}
    >
      <AnimatePresence mode={"popLayout"}>
        <motion.div
          key={buttonText}
          initial={{ y: 24, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -24, opacity: 0 }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          {buttonText}
        </motion.div>
      </AnimatePresence>
    </Button>
  );
});
