import { SyntheticEvent } from "react";

import { useNavigate } from "react-router";

import { cn } from "@/lib/utils";

import { Card, Text, Button } from "@/app/components";
import { ActionNames, useActionWrapper, useOpportunityActions } from "@/app/hooks";

import { Tags } from "../../opportunities/components/card-list/tags";
import { getAvailableOpportunityActionNames } from "../../opportunities/utils";
import { DashboardCompany } from "../dashboard.types";

type CompanyCardProps = {
  company: DashboardCompany;
  primaryAction?: ActionNames;
  defaultActions?: ActionNames[];
};

export const CompanyCard = ({ company, primaryAction = "follow", defaultActions = [] }: CompanyCardProps) => {
  const navigate = useNavigate();
  const actions = useOpportunityActions({ company }) || {};
  const primary = actions[primaryAction];
  const { loading, wrapAction } = useActionWrapper();

  const availableActionNames = getAvailableOpportunityActionNames({
    company,
    defaultActions,
    skip: ["convertToPortfolio", "convertToProspect", "moveStage", "moveToPipeline", "removeProspect", primaryAction],
  });

  const onPrimaryToggle = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (primary) {
      wrapAction({ ...primary, isAsync: true });
    }
  };

  return (
    <Card
      key={company.id}
      className="group cursor-pointer hover:scale-100"
      onClick={() => navigate(`/company/${company.id}`)}
    >
      <Card.Header>
        <Card.Image src={company.image} className="mr-3 rounded-sm" />
        <Card.Title title={company.name} />
        {actions && (
          <Card.Actions containerClassName="ml-auto" actions={actions} availableActionNames={availableActionNames}>
            {primary && (
              <Button
                text={primary.label}
                size="sm"
                variant={"secondary"}
                className={cn(
                  "min-w-[70px] rounded-xs border border-neutral-300 py-1 font-bold hover:bg-neutral-100 lg:h-6 lg:px-2",
                )}
                labelClassName="lg:text-[12px]"
                loading={loading[primary.label]}
                onClick={onPrimaryToggle}
              />
            )}
          </Card.Actions>
        )}
      </Card.Header>
      <Card.Body className="mb-6">
        <Text text={company.tagline} className="min-h-10.5 text-[14px] font-[500] text-neutral-700" noOfLines={2} />
      </Card.Body>
      <Card.Footer className="mt-auto">
        <Tags city={company.city} country={company.country} badges={company.badges} />
      </Card.Footer>
    </Card>
  );
};
