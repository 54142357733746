import { toast } from "react-hot-toast";
import z from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";

import { SuccessModal } from "@/app/screens/modal/success/main.tsx";
import { useAddOpportunityMutation } from "@/app/service/opportunities.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { useAddCompanyForms } from "./use-add-company-forms";

/**
 * request data when creating a new company
 * should match ManualOpportunityInput
 */
export const addCompanySchema = z.object({
  name: z.string().min(1, "Name is required"),

  about: z.string(),

  // autoAccept?
  canIntro: z.boolean(),
  isActivelyFundraising: z.boolean(),
  highPriority: z.boolean(),
  ballInControl: z.boolean(),

  assignees: z.array(z.string()).nonempty("Set at least one person to assign this"),
  noteToAssignees: z.string(),

  city: z.string(),
  country: z.string(),

  founders: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        url: z.string().regex(linkedinProfileUrlRegex, "Please enter a valid url"),
      }),
    )
    .nonempty(),

  industries: z.array(z.string()).nonempty(),

  note: z.string(),

  investmentType: z.string().min(1, "Investment type is required"),

  round: z.string().min(1, "Round is required"),
  targetFund: z.string().min(1, "Target fund is required"),
  websiteUrl: z.string(),

  source: z.string().min(1, "Source name is required"),
  sourceType: z.string().min(1, "Source type is required"),
});

export type AddCompanyRequestData = z.infer<typeof addCompanySchema>;

export const useSubmitNewCompany = () => {
  const [submit, { loading }] = useAddOpportunityMutation({
    refetchQueries: ["GetCompaniesDocument", "GetCompanyDocument"],
  });

  const submitOpportunity = (data: AddCompanyRequestData) => {
    const { error, success } = addCompanySchema.safeParse(data);

    if (success) {
      return submit({ variables: { input: data } }).then(() => {
        modalStore.navigate(SuccessModal);
      });
    } else {
      console.log(error);
      toast.error("Failed to create company. Invalid data");
    }
  };

  return { submitOpportunity, loading };
};

export const mapCompanyData = (forms: ReturnType<typeof useAddCompanyForms>): AddCompanyRequestData => {
  const { mainDataForm, foundersForm, detailsForm, additionalDetailsForm, attributesForm, noteForm } = forms;

  const name = mainDataForm.getValues("name") ?? "";

  const founders = foundersForm.getValues("founders") ?? [];

  const { about, websiteUrl, location } = detailsForm.getValues();

  const { investmentType, industries, round, fund } = additionalDetailsForm.getValues();

  const { note } = noteForm.getValues();

  const { source, attributes, assignees } = attributesForm.getValues();

  return {
    name,

    founders,

    about: about ?? "",
    websiteUrl: websiteUrl ?? "",
    country: location?.country ?? "",
    city: location?.city ?? "",

    round: round ?? "",
    targetFund: fund ?? "",
    industries: industries ?? [],
    investmentType: investmentType ?? "",

    canIntro: attributes.introAvailable ?? false,
    isActivelyFundraising: attributes.activelyRaising ?? false,
    highPriority: attributes.highPriority ?? false,
    ballInControl: attributes.ballInOurControl ?? false,

    assignees: (assignees.people.map((assignee) => assignee.id) as [string, ...string[]]) ?? [],
    noteToAssignees: assignees.note ?? "",

    note: note ?? "",

    source: source.name ?? "",
    sourceType: source.type ?? "",
  };
};
