import React, { useEffect } from "react";
import { Stepper, Step } from "@material-tailwind/react";
import { IconType, Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";

type StepType = {
  text: string;
  icon?: IconType;
  disabled?: boolean;
  multi?: boolean;
};

export function StepperWithContent({
  containerClassName = "",
  steps,
  activeStep,
  setStep,
}: {
  containerClassName?: string;
  activeStep: number;
  steps: StepType[];
  setStep: (index: number) => void;
}) {
  const maxReached = React.useRef(0);

  useEffect(() => {
    if (activeStep > maxReached.current) maxReached.current = activeStep;
  }, [maxReached, activeStep]);

  if (!steps.length) return null;

  return (
    <div className={cn(containerClassName)}>
      <Stepper
        activeStep={activeStep}
        activeLineClassName="bg-neutral-200"
        lineClassName={"bg-neutral-200"}
      >
        {steps
          .filter(({ multi }) => !multi)
          .map(({ text, icon, disabled }, index) => (
            <Step
              key={`${icon}_${index}`}
              onClick={() => {
                if (maxReached.current < index || disabled) return;
                setStep(index);
              }}
              className="cursor-pointer bg-white px-1"
              activeClassName="bg-white"
              completedClassName={"bg-white"}
            >
              <div className="flex items-center bg-white px-1">
                <div
                  className={cn(
                    "flex h-6 w-6 items-center justify-center rounded-xs bg-neutral-200",
                    activeStep === index
                      ? "bg-gradient-to-r from-primary-400 to-secondary-400 text-white shadow-sm"
                      : "",
                    activeStep > index ? "bg-black text-white" : ""
                  )}
                >
                  <span
                    className={cn(
                      "text-[8px] font-semibold",
                      index > activeStep ? "text-neutral-400" : "text-white"
                    )}
                  >
                    {index + 1}
                  </span>
                </div>
              </div>
            </Step>
          ))}
      </Stepper>
    </div>
  );
}
