import React from "react";

import { Text } from "@/app/components";
import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardTitle,
} from "@/app/screens/opportunities/opportunity/components/highlights/highlights-card.tsx";

export const CapitalConcentration = ({ company }) => {
  const cashProfile = company?.mtpCompanyfundsByMtpCompanyId?.nodes?.[0]?.capitalConcentration;
  return (
    <HighlightsCard type={"Insight"} className={"group"}>
      <div className={"flex w-full flex-col items-center justify-between"}>
        <HighlightsCardTitle text={"Capital Concentration"} />
        <div className={"flex h-28 items-center justify-center"}>
          {cashProfile ? (
            <Text text={cashProfile} type={"h4"} />
          ) : (
            <HighlightsCardEmptyState text={"No Concentration Available"} />
          )}
        </div>
        <HighlightsCardFooter>
          <HighlightsCardLastUpdated date={new Date().toISOString()} />
        </HighlightsCardFooter>
      </div>
    </HighlightsCard>
  );
};
