import { cn } from "@/lib/utils.ts";

import { NativeModal, Button, Icon } from "@/app/components";
import { useActionWrapper, ActionWrapperOptions } from "@/app/hooks";
import modalStore from "@/app/stores/modal.store";

import { ActionsMenuProps } from "./types";

const ActionsModal = ({ options, asyncActionLabels = [], className }: ActionsMenuProps) => {
  const { loading, wrapAction } = useActionWrapper();

  const handleClick = (params: ActionWrapperOptions) => {
    wrapAction(params);

    if (asyncActionLabels.includes(params.label)) {
      modalStore.close();
    }
  };

  return (
    <NativeModal isOpen={true} handleClose={modalStore.close} detent={"content-height"} bodyClassName="p-6">
      <div className={cn("flex flex-col items-center gap-2", className)}>
        {options.map(({ action, label, variant }) => (
          <Button
            key={label}
            size={"lg"}
            variant={variant}
            className={cn("w-full")}
            text={label}
            onClick={(e) => handleClick({ label, event: e, action, isAsync: asyncActionLabels.includes(label) })}
            loading={loading[label]}
          />
        ))}
      </div>
    </NativeModal>
  );
};

export const NativeActionMenu = ({ className, ...props }: ActionsMenuProps) => {
  const handleOpen = () => {
    modalStore.open(ActionsModal, { props });
  };

  return (
    <button
      className={cn(
        "flex size-8 items-center justify-center rounded-sm bg-white px-2 text-center hover:bg-neutral-200 active:outline-none",
        className,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <Icon type={"MoreVertical"} className={"size-3.5 2xl:size-4"} onClick={handleOpen} />
    </button>
  );
};
