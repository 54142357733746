import { Card, Text } from "@/app/components";

export const EmptySection = ({ message }: { message: string }) => {
  return (
    <Card className="hover:scale-100">
      <Card.Body className="flex justify-center py-8">
        <Text text={message} className="text-[18px] font-[500] text-neutral-300" />
      </Card.Body>
    </Card>
  );
};
