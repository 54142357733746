import { CommentDisplayData } from "@/app/screens/opportunities/opportunity/components/comments/comments.types.ts";
import modalStore from "@/app/stores/modal.store.tsx";
import { ConfirmModal } from "@/app/screens/modal";
import { toast } from "react-hot-toast";
import { GetCompanyCommentsDocument, useDeleteCompanyCommentMutation } from "@/app/service/company-comments.gql.ts";

export const useDeleteComment = () => {
  const [deleteComment, { loading: isDeleting }] = useDeleteCompanyCommentMutation({
    refetchQueries: [GetCompanyCommentsDocument],
  });

  const handleDelete = (comment: CommentDisplayData) => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure you want to delete this comment?",
        subTitle: "By confirming, you will remove comment from Nazare",
        handleConfirm: () => {
          deleteComment({
            variables: {
              id: comment.id,
            },
          }).then(() => {
            toast.success("Comment has been deleted");
            modalStore.close();
          });
        },
      },
    });
  };

  return {
    handleDelete,
    isDeleting,
  };
};
