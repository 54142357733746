import { useMemo } from "react";
import Form from "mobx-react-form";
import { FormType, InitialValues, createForm } from "../stores/form";

export const useForm = (
  formId: FormType,
  { initial, form: existingForm }: { initial?: InitialValues; form?: Form } = {}
) => {
  const form = useMemo(() => {
    if (existingForm) return existingForm;

    return createForm({ formId, initial });
  }, [formId, initial, existingForm]);

  return form;
};
