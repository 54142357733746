import Sheet from "react-modal-sheet";
import { IosTransitionEnter } from "@/app/components";
import { CloseModal, ModalHeader } from "@/app/screens/modal/components";
import React from "react";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { observer } from "mobx-react";
import { extractFormCategory } from "@/lib/utils.ts";
import { DateTime } from "luxon";

import {
  SentimentAnswer
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-modal-content.tsx";

const MODAL_STATE = "sentiment_details";

export const SentimentDialogMobile = observer(() => {
  const form = opportunitiesStore.getModalState(MODAL_STATE, "form");

  return (
    <IosTransitionEnter type={"static"}>
      <div className={"flex h-full flex-grow flex-col bg-background"}>
        <CloseModal />
        <ModalHeader
          title={extractFormCategory(form)}
          subtitle={
            form?.created_at &&
            DateTime.fromISO(form.created_at).toLocaleString()
          }
        />
        <Sheet.Content className={"mx-5 flex h-full justify-between"}>
          <Sheet.Scroller>
            <div className={"flex w-full flex-col space-y-6 pb-14"}>
              {form?.fields
                ?.filter(
                  (field) =>
                    field.group_id !== "name" &&
                    field.group_id !== "form_category"
                )
                .map((field, idx) => (
                  <SentimentAnswer
                    key={`${field.field_hash}_${idx}`}
                    form={form}
                    field={field}
                  />
                ))}
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </div>
    </IosTransitionEnter>
  );
});
