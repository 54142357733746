export type Funds = "LocalGlobe" | "Latitude" | "Solar";

export const parentFund: Record<Funds, string> = {
  LocalGlobe: "LG",
  Latitude: "LT",
  Solar: "SL",
};

export const funds: Record<string, Funds> = {
  LG5: "LocalGlobe",
  LT1: "Latitude",
  LG8: "LocalGlobe",
  LT2: "Latitude",
  LG7: "LocalGlobe",
  LG9: "LocalGlobe",
  "LT-X": "Latitude",
  "LT-SC via LTX": "Latitude",
  LGX: "LocalGlobe",
  "LT-SC": "Latitude",
  LG11: "LocalGlobe",
  STLP: "Solar",
  LG2: "LocalGlobe",
  LG9B: "LocalGlobe",
  LG4: "LocalGlobe",
  LG9a: "LocalGlobe",
  LT3: "Latitude",
  "LT-TES": "Latitude",
  LG12: "LocalGlobe",
  TAG: "LocalGlobe",
  LG6: "LocalGlobe",
  LGO: "LocalGlobe",
  "LT-Raisin": "Latitude",
  "LT-SC via LT-Tessian": "Latitude",
  LG1: "LocalGlobe",
  SF1: "Solar",
  "SX-Co": "Solar",
};
