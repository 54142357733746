import { useState } from "react";

import { ApolloError } from "@apollo/client";
import axios, { AxiosRequestConfig } from "axios";

/**
 * upload function should return pre-signed url
 */
export type FileUploadFn = (filePath: string) => Promise<string>;

export type FileUploadFnParam = [FileUploadFn, { loading: boolean; error?: ApolloError }];

type UseFileUploadProps = {
  uploadFn: FileUploadFnParam;
  onSuccess?: () => void;
  prefix?: string;
};

export const useFileUpload = ({ uploadFn, onSuccess, prefix }: UseFileUploadProps) => {
  const [getFileUrl, { loading, error }] = uploadFn;
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const getPresignedUrl = async (filePath: string) => {
    return await getFileUrl(filePath);
  };

  const uploadToPresignedUrl = async (presignedUrl: string, file: File) => {
    setUploadProgress(0);
    return axios
      .put(presignedUrl, file, {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total && progressEvent.loaded) {
            const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(percentCompleted);
          }
        },
      } as AxiosRequestConfig)
      .finally(() => {
        setTimeout(() => {
          setUploadProgress(null);
          onSuccess?.();
        }, 1000);
      });
  };

  // Function to orchestrate the upload process
  const handleUpload = async (selectedFile: File | null) => {
    try {
      // Ensure a file is selected
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }

      const presignedUrl = await getPresignedUrl([prefix, selectedFile.name].filter(Boolean).join("/"));

      if (!presignedUrl) {
        return;
      }

      return uploadToPresignedUrl(presignedUrl, selectedFile);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  return { handleUpload, loading, error, uploadProgress };
};
