import { Button, Text } from "@/app/components";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { useLocation } from "react-router-dom";
import { titleCase } from "@/app/misc/helpers.ts";

export const UnderConstructionMobile = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onPress, text } = globalModalStore.state.navState;
  const path = titleCase(pathname?.split("/").filter(Boolean).at(0) || "");
  return (
    <div
      className={
        "flex min-h-[25dvh] flex-1 flex-col items-center justify-between px-4 pt-8"
      }
    >
      <div className={"flex flex-col items-center"}>
        <Text text={"Under Construction"} type={"title"} />
        <Text
          text={"🚧 Coming Soon! 🚧"}
          type={"subtitle"}
          weight={"light"}
          color={"text-neutral-400"}
          className={"mb-4 mt-2.5"}
        />
        <Text
          text={
            text ||
            `${path} is not yet available on mobile. Stay tuned for updates!`
          }
          className={"text-center"}
          weight={"light"}
        />
      </div>
      <Button
        text={"I Understand"}
        variant={"gradient"}
        className={"my-10 w-full rounded-sm"}
        onClick={() => {
          if (onPress) {
            onPress();
          } else {
            navigate(-1);
          }
          globalModalStore.toggleModal();
        }}
      />
    </div>
  );
});
