import toast from "react-hot-toast";

import { Toast, Icon } from "@/app/components";
import { useUnAssignUserActionMutation } from "@/app/service/opportunities.gql.ts";

export const useUnAssignUser = () => {
  const [onSubmit, { loading }] = useUnAssignUserActionMutation({
    refetchQueries: ["GetCompany", "GetCompanies", "GetDashboardCompanies", "GetUserHighlights"],
    fetchPolicy: "network-only",
    onCompleted() {
      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          subTitle={"Successfully UnAssigned from company"}
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
    },
  });

  return {
    onSubmit,
    loading,
  };
};
