import { X } from "lucide-react";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { Button, Text } from "@/app/components";
import { cn } from "@/lib/utils.ts";

export const ModalTitle = ({
  title,
  className,
  secondaryAction = { text: "", onClick: () => null },
}: {
  title: string;
  className?: string;
  secondaryAction?: { text: string; onClick: () => void };
}) => {
  return (
    <div className={cn("flex w-full items-center justify-between", className)}>
      <X
        className={"rounded-full text-neutral-500"}
        width={24}
        height={24}
        onClick={() => {
          globalModalStore.toggleModal();
        }}
      />
      <Text type={"subtitle"} text={title} className={"ml-10 self-center"} />
      <Button
        text={secondaryAction.text}
        className={"-mr-10 active:bg-transparent"}
        variant={"text"}
        onClick={secondaryAction.onClick}
      />
    </div>
  );
};
