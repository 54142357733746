import { type ClassValue, clsx } from "clsx";
import { formatDistance, parseISO } from "date-fns";
import { twMerge } from "tailwind-merge";

import membersStore from "@/app/stores/members.store.tsx";
import { Signal, SignalExtra, SignalSource } from "@/app/types";

import { CompanyPerson } from "./service/opportunity.types";
import { TrelloAttachment, TrelloComment } from "./service/trello.types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const textGradient = (
  gradientDirection: `${"t" | "b" | "r" | "l"}` | `${"t" | "b" | "r" | "l"}${"t" | "b" | "r" | "l"}`,
) => `bg-gradient-to-${gradientDirection} from-primary to-secondary gradient-text text-transparent`;
export const capitalize = (str, shouldLower = false) =>
  str ? str.charAt(0).toUpperCase() + (shouldLower ? str.toLowerCase() : str).slice(1) : null;

export const capitalizeWords = (str, shouldLower = false, delimiter = " ") =>
  str
    ?.split(delimiter)
    .map((word) => capitalize(word, shouldLower))
    .join(" ");

export const extractFormCategory = (form) =>
  form ? Object.keys(form?.analytics?.form_category_dropdown || { [form.formStatus]: null })[0] : "";

export const calculateAnalyticsAverage = (analytics) => {
  const item = Object.keys(analytics)
    .filter((item) => item.endsWith("opinion_scale"))
    .map((item) => analytics[item].mean);
  return item.length > 0 ? item.mean().toFixed(2) : null;
};

export const pluralize = (n: number, word: string): string => {
  return n === 1 ? word : word + "s";
};

export function debounce(fn: (...args: any[]) => void, ms: number) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, ms);
  };
}

export function formatNumber(num: string | number) {
  if (typeof num === "number") num = String(num);
  const parts = num.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const formatFundingMil = (valueString: string | number, decimal = 2, currency?: "$" | "£") => {
  const value = parseFloat(valueString.toString());

  const num = currency ? `${currency} ${value.toFixed(decimal)}m` : `${value.toFixed(decimal)}m`;

  return formatNumber(num);
};

if (!Array.prototype.mean) {
  Array.prototype.mean = function () {
    return this.reduce((a, b) => a + b, 0) / this.length;
  };
}

export function getInitials(name: string): string {
  return name
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
}

export function windowOpen(...args: Parameters<typeof window.open>) {
  const [url, name, specs] = args;

  let newUrl = url;
  if (!newUrl?.toString()?.match(/^https?:\/\//i)) newUrl = "http://" + newUrl?.toString();

  return window.open(newUrl, name, specs);
}

export function mergeDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === "object";

  return objects.reduce((prev, obj) => {
    if (!obj) return prev;
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export function getFileExtension(filename: string): string {
  const parts = filename.split(".");
  if (parts.length === 1) return "url";
  return parts[parts.length - 1];
}

export function toJSON(object) {
  return JSON.parse(JSON.stringify(object));
}

export function getCompanyFounders(company: any) {
  () => {
    const teamMembers = company?.team?.items || [];
    const filtered = teamMembers
      ?.filter((a) => a.isFounder)
      .sort((a, _b) => (a.isFounder ? -1 : a.member?.image ? -1 : 1));

    const uniqueByLinkedin: CompanyPerson[] = Object.values(
      filtered.reduce((prev, curr: any) => {
        prev[curr?.member?.linkedinUrl ? new URL(curr.member.linkedinUrl).pathname : curr.member.name] = {
          ...curr,
          ...curr.member,
        };
        return prev;
      }, {}),
    );
    const uniqueByName: CompanyPerson[] = Object.values(
      uniqueByLinkedin.reduce((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {}),
    );

    return uniqueByName;
  };
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function parseUrl(url?: string | null) {
  if (url && url.startsWith("http")) return url;

  if (url) return `https://${url}`;

  return null;
}

export function parseCompanyDetails(company) {
  const { badges, fundingRounds, sentiment, companiesSources, signals } = company;
  const round = fundingRounds?.items?.[0]?.round;
  const portCo = badges?.find(({ id }) => id === "portfolio_company");
  const badgeSignals = badges?.filter(({ group }) => group === "signal");
  const previouslyMet = badges?.find(({ id }) => id === "previously_met");
  const sentiments = sentiment?.nodes ? sentiment.nodes : [];

  const trelloSource = companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")?.trelloCardAsSource;

  const { attachments = [], comments = [] }: { attachments?: TrelloAttachment[]; comments?: TrelloComment[] } =
    trelloSource || {};

  const assignees = (trelloSource?.memberIds || [])?.map(membersStore.getByTrelloId).filter(Boolean);

  const basecamp = badges ? badges.find(({ id }) => id === "basecamp_investment") : undefined;

  return {
    ...company,
    round,
    portCo,
    badgeSignals,
    previouslyMet,
    sentiments,
    attachments,
    comments,
    basecamp,
    assignees,
    trelloSource,
  };
}

export const formatSignals = ({
  name,
  created_by,
  created_utc,
  source,
  is_actively_fundraising,
  can_source_intro,
  high_priority,
}: SignalExtra) => {
  const created = `<b>${name}</b> was added ${formatDistance(
    created_utc ? parseISO(created_utc) : new Date(),
    new Date(),
    { addSuffix: true },
  )} by <b>${created_by}</b>`;

  if (source && can_source_intro && is_actively_fundraising && high_priority) {
    return `${created}, sourced from  <b>${source}</b>.`;
  }

  if (source && can_source_intro && !is_actively_fundraising) {
    return `${created}, sourced from  <b>${source}</b>. An Introduction to the founders is available. Although not currently fundraising, it's an opportunity to monitor for future engagement without priority`;
  }

  if (source && !can_source_intro && is_actively_fundraising && high_priority) {
    return `${created}, sourced from  <b>${source}</b>. An Introduction is not available, but the company's active fundraising status makes it a priority as per the source's recommendation`;
  }

  if (source && !can_source_intro && !is_actively_fundraising && !high_priority) {
    return `${created}, sourced from  <b>${source}</b>. No introduction is available through this source, alternative connections must be explored. with the company not actively seeking funds, it is lower in priority`;
  }

  if (source && !can_source_intro && is_actively_fundraising && high_priority) {
    return `${created}, sourced from  <b>${source}</b>. An Introduction is not available, but the company's active fundraising status makes it a priority as per the source's recommendation`;
  }

  if (!source && can_source_intro && !is_actively_fundraising && !high_priority) {
    return `${created}, without a disclosed source. Potential for founder introduction exists. Not actively fundraising, company should be kept under review without prioritisation`;
  }

  if (!source && !can_source_intro && is_actively_fundraising && !high_priority) {
    return `${created} with no specified source. Introduction to the founders isn't available through current channels. It's actively fundraising activity merits attention but not immediate priority`;
  }

  if (!source && !can_source_intro && !is_actively_fundraising && !high_priority) {
    return `${created}, without a specific source mentioned. Introduction to the founders is not available through current channels, and as the company isn't actively fundraising, it ranks lower on the priority list.`;
  }

  return created;
};

export const reduceSignalsToFormattedText = (signals: Signal[] = []) => {
  return signals?.reduce<string>((accumulator, signal) => {
    if (signal?.id === "badge") {
      const formattedSignal = formatSignals(signal.extra);

      return formattedSignal;
    }

    return accumulator;
  }, "Introduction to the founders isn't available through current channels.");
};

export const getSignalSource = (signal: Signal): SignalSource => {
  if (signal?.extra)
    return {
      name: signal.extra?.source,
      date: signal.extra.created_utc,
    };

  return { name: "", date: "" };
};

export const getFullName = (...parts: (string | undefined | null)[]): string => {
  return parts.filter(Boolean).join(" ");
};
