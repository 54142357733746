import { useCallback } from "react";

import Form from "mobx-react-form";

import { NativePickerProps, NativePicker, CalendarProps } from "../components";
import { MobileSelect, OptionsType } from "../components/select-controls";
import modalStore from "../stores/modal.store";

export interface SelectOptionParams {
  label: string;
  options: OptionsType[];
  defaultSelected?: OptionsType[];
  fieldId?: string;
  multiSelect?: boolean;
  onChange?: (value: OptionsType[]) => void;
  formatValue?: (value: OptionsType[]) => any;
}

export interface SelectDateParams {
  value: Date | undefined;
  maxDate?: Date;
  calendarProps?: Partial<CalendarProps>;
  onChange?: (date: Date) => void;
}

export interface UseSelectOptionParams {
  form?: Form;
}

export const useSelectOption = ({ form }: UseSelectOptionParams) => {
  const selectOption = useCallback(
    (params: SelectOptionParams) =>
      modalStore.progress(MobileSelect, {
        props: {
          ...params,
          goBack: modalStore.goBack,
          handleClose: modalStore.close,
        },
      }),
    [form],
  );

  return { selectOption };
};

export const useSelectDate = () => {
  const selectDate = useCallback(
    (params: SelectDateParams) =>
      modalStore.progress<NativePickerProps>(NativePicker, {
        props: {
          ...params,
          goBack: modalStore.goBack,
          handleClose: modalStore.close,
        },
      }),
    [],
  );

  return { selectDate };
};
