import { Modal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ModalHeader } from "../components";

import { ActionButtons } from "./components/action-buttons";
import { useSelectModal } from "./hooks";
import { ModalContent } from "./modal-content";

export const AddNewModal = ({ sentimentOnly, company, isOpen = true }) => {
  const { selected, handleSelect, handleNext } = useSelectModal({ company });

  return (
    <Modal
      isOpen={isOpen}
      handleClose={modalStore.close}
      bodyClassName="max-w-[655px] p-10"
      bodyContentClassName="p-0"
      footerContainerClassName="px-0 mb-0 pt-10"
      header={
        <ModalHeader
          title={"Add New..."}
          subtitle="Please choose an option and then proceed by clicking on 'Next'."
          containerClassName="lg:mt-2 xl:mb-8"
        />
      }
      footer={
        <ActionButtons
          disabled={!selected}
          actions={{
            handlePrimaryAction: handleNext,
            handleCancel: modalStore.close,
          }}
        />
      }
    >
      <ModalContent onSelect={handleSelect} sentimentOnly={sentimentOnly} />
    </Modal>
  );
};
