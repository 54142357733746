import React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { Input } from "@/app/components";
import { WebSelect } from "@/app/components/input/web-select.tsx";

import { AddCompanyFormSection, AddCompanySectionEmptyState } from "./section.tsx";
import { OptionsType } from "@/app/screens/opportunities";

const CompanySourceSchema = z.object({
  name: z.string().min(1, "Source name is required"),
  type: z.string().min(1, "Source Type is required"),
});

type CompanySourceFormData = z.infer<typeof CompanySourceSchema>;

export const useCompanySourceForm = () => {
  return useForm<CompanySourceFormData>({
    resolver: zodResolver(CompanySourceSchema),

    defaultValues: {
      name: "",
      type: "",
    },
  });
};

const sourceOptions: OptionsType[] = [
  { value: "angel", label: "Angel" },
  { value: "investor", label: "Investor" },
  { value: "operator", label: "Operator" },
  { value: "portfolio_founder", label: "Portfolio Founder" },
  {
    value: "none_portfolio_founder",
    label: "Non Portfolio Founder",
  },
  { value: "service_provider", label: "Service Provider" },
  { value: "other", label: "Other" },
];

const CompanySourceView = ({ name, type }: { name: string; type: string }) => {
  if (!name && !type) {
    return <AddCompanySectionEmptyState>No Source</AddCompanySectionEmptyState>;
  }

  return (
    <div className="flex gap-2">
      <span>{sourceOptions.find((option) => option.value === type)?.label ?? (type || "No Type")}</span>-
      <span>{name || "No Name"}</span>
    </div>
  );
};

export const CompanySourceForm = ({ form }: { form: UseFormReturn<CompanySourceFormData> }) => {
  const { control, handleSubmit } = form;

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AddCompanyFormSection title="Source">
      {(editMode) =>
        editMode ? (
          <form className="flex gap-2" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <WebSelect
                  styles={{ containerClassName: "max-w-[500px]" }}
                  options={sourceOptions}
                  defaultOptions={sourceOptions.filter((option) => option.value === field.value)}
                  multiSelect={false}
                  onChange={(value) => field.onChange(value?.[0]?.value)}
                  placeholder="Select source type"
                />
              )}
            ></Controller>

            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input placeholder={"Name"} {...field} />}
            />
          </form>
        ) : (
          <CompanySourceView name={form.getValues("name")} type={form.getValues("type")} />
        )
      }
    </AddCompanyFormSection>
  );
};
