import React, { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import z from "zod";

import { CompanyImg, Input, TextArea } from "@/app/components";
import { EditableField } from "@/app/screens/add-company/components/editable-field.tsx";
import { LocationInput } from "@/app/screens/opportunities/components";

import {
  CompanyResult,
  NewCompany,
  SelectCompany,
} from "@/app/screens/opportunities/add-new/via-wizard/components/select-company.tsx";

const MainCompanyDataSchema = z.object({
  name: z.string().min(1, "Please enter company name"),
  websiteUrl: z.string().optional().nullable(),
  location: z
    .object({
      country: z.string(),
      city: z.string(),
    })
    .required(),
  about: z.string().min(1, "Please enter a company description"),
});

type MainCompanyDataForm = z.infer<typeof MainCompanyDataSchema>;

export const useMainCompanyDataForm = () => {
  return useForm<MainCompanyDataForm>({
    resolver: zodResolver(MainCompanyDataSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      websiteUrl: "",
      location: {
        country: "",
      },
      about: "",
    },
  });
};

export const MainCompanyData = ({
  form,
  onSelectCompany,
  className,
}: {
  form: UseFormReturn<MainCompanyDataForm>;
  onSelectCompany: (company: CompanyResult | NewCompany | null) => void;
  className?: string;
}) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyResult | NewCompany | null>(null);

  const { control, setValue, reset, resetField, handleSubmit } = form;

  const updateCompanyFormData = (company: CompanyResult | NewCompany | null) => {
    if (company == null) {
      reset();
      return;
    }

    setValue("websiteUrl", company.websiteUrl);
    setValue("about", company.about ?? "", { shouldDirty: true });

    if ("country" in company && "city" in company) {
      setValue(
        "location",
        {
          country: company.country,
          city: company.city,
        },
        { shouldDirty: true },
      );
    } else if ("hqLocations" in company) {
      const [hqLocation] = company.hqLocations ?? [];

      const city = (hqLocation?.city?.length && hqLocation?.city[0].name) ?? "";
      const country = (hqLocation?.country?.length && hqLocation?.country[0].name) ?? "";

      setValue("location", { country, city }, { shouldDirty: true });
    } else {
      resetField("location");
    }
  };

  const handleSelectCompany = (company: CompanyResult | NewCompany | null) => {
    updateCompanyFormData(company);
    setSelectedCompany(company);
    onSelectCompany(company);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <div>
        <div className="flex min-w-[300px] gap-2">
          <CompanyImg className="!size-20" src={selectedCompany?.image} />
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <EditableField
                  value={<div className="text-[24px] text-neutral-800">{field.value || "No Company Name"}</div>}
                >
                  <SelectCompany
                    onSelect={(company) => {
                      handleSelectCompany(company);
                      field.onChange(company?.name ?? "");
                    }}
                  />
                </EditableField>
              )}
            />

            <Controller
              name="websiteUrl"
              control={control}
              render={({ field, fieldState }) => (
                <EditableField
                  value={<div className="text-sm text-neutral-800">{field.value || "No Website"}</div>}
                  error={fieldState.error?.message}
                >
                  <Input iconLeft={"Continent"} placeholder={"Website"} {...field} />
                </EditableField>
              )}
            />

            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <EditableField
                  value={
                    <div className="text-sm text-neutral-800">
                      {Object.values(field.value).filter(Boolean).join(", ") || "No Location Provided"}
                    </div>
                  }
                >
                  <LocationInput
                    required={true}
                    defaultValue={
                      field.value.country
                        ? {
                            value: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                            label: `${field.value.city ? field.value.city + ", " : ""}${field.value.country}`,
                          }
                        : undefined
                    }
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                  />
                </EditableField>
              )}
            />
          </div>
        </div>
        <div className="mt-2 w-full">
          <Controller
            name="about"
            control={control}
            render={({ field }) => (
              <EditableField
                className="w-full"
                value={<div className="text-neutral-800">{field.value || "No Description"}</div>}
              >
                <TextArea
                  containerClassName="w-full"
                  label={"Description"}
                  placeholder={"Description"}
                  onChange={field.onChange}
                  value={field.value}
                />
              </EditableField>
            )}
          />
        </div>
      </div>
    </form>
  );
};
