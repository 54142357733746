import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction, SyntheticEvent, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { IconButton } from "@/app/components";

type AddCompanyFormSectionProps = {
  title?: string;
  className?: string;
  children: ((editMode: boolean, setEditMode: Dispatch<SetStateAction<boolean>>) => ReactNode) | ReactNode;
  elementProps?: {
    children?: {
      className?: string;
    };
  };
};

export const AddCompanyFormSection = ({ title, className, children, elementProps }: AddCompanyFormSectionProps) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEdit = (e?: SyntheticEvent) => {
    e?.preventDefault();
    setEditMode(!editMode);
  };

  return (
    <section className={cn("flex flex-col gap-2", className)}>
      <div className="flex items-center gap-1 text-lg">
        {title}
        <IconButton
          icon={editMode ? "Check" : "Pencil"}
          onClick={toggleEdit}
          containerClassName="size-7 !bg-transparent p-1.5 hover:!bg-neutral-200 hover:rounded-sm"
        />
      </div>
      <div className={cn("rounded-lg bg-white p-4", elementProps?.children?.className)}>
        {typeof children === "function" ? children(editMode, setEditMode) : children}
      </div>
    </section>
  );
};

export const AddCompanySectionEmptyState = ({ children }: PropsWithChildren) => {
  return <div className="text-[20px] font-semibold text-neutral-300 text-center">{children}</div>;
};
