import React, { useMemo, useState } from "react";

import { CompanyImg, SearchBar, Text } from "@/app/components";
import { SearchCompaniesGlobalQuery, useSearchCompaniesGlobalQuery } from "@/app/service/opportunities.gql.ts";

const useSearchResults = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useSearchCompaniesGlobalQuery({
    variables: {
      limit: 12,
      offset: 0,
      lgFilter: { name: { fuzzy: searchTerm } },
      dealroomFilter: { name: { fuzzy: searchTerm } },
    },
    skip: !searchTerm,
  });

  const search = (value: string) => {
    setSearchTerm(value);
  };

  const results = useMemo(() => {
    return [...(data?.lgCompanies?.nodes ?? []), ...(data?.dealroomCompanies?.nodes ?? [])];
  }, [data]);

  return { results, search, loading, searchTerm };
};

export type CompanyResult = LgCompanyResult | DealroomCompanyResult;
export type LgCompanyResult = NonNullable<SearchCompaniesGlobalQuery["lgCompanies"]>["nodes"][number];
export type DealroomCompanyResult = NonNullable<SearchCompaniesGlobalQuery["dealroomCompanies"]>["nodes"][number];
export type NewCompany = {
  name: string;
  image: null;
  websiteUrl: null;
  about: null;
  isNewCompany: true;
};

export const SelectCompany = ({ onSelect }: { onSelect: (company: CompanyResult | NewCompany | null) => void }) => {
  const { results, loading, search, searchTerm } = useSearchResults();
  const [isOpen, setIsOpen] = useState(false);

  const handleQuery = (value: string) => {
    search(value);
    setIsOpen(true);
  };

  const handleReset = () => {
    onSelect(null);
    setIsOpen(false);
  };

  const handleSelectCompany = (company: CompanyResult) => {
    onSelect(company);
    setIsOpen(false);
  };

  const handleCreateNew = () => {
    setIsOpen(false);
    onSelect({
      name: searchTerm,
      image: null,
      websiteUrl: null,
      about: null,
      isNewCompany: true,
    });
  };

  return (
    <div className="relative">
      <SearchBar
        containerClassName="w-full"
        className="!h-9 rounded-md"
        loading={loading}
        onQueryChange={handleQuery}
        onReset={handleReset}
      />
      {isOpen && results.length > 0 && (
        <div className="absolute z-20 max-h-96 w-full overflow-y-auto rounded-md bg-white">
          <button
            onClick={handleCreateNew}
            className="mt-1 w-full cursor-pointer rounded-md border border-neutral-200 bg-white py-5"
          >
            <Text text={`Create company "${searchTerm}"`} className={"text-primary"} />
          </button>
          {results.map((company) => (
            <div
              key={company.id ?? company.name}
              className="flex cursor-pointer items-center gap-2 p-1 hover:bg-neutral-100"
              onClick={() => handleSelectCompany(company)}
            >
              <CompanyImg src={company.image} />
              <span>{company.name}</span>
              <span className="text-xs text-neutral-400">{company.websiteUrl}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
