import { X } from "lucide-react";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

type CloseModalProps = {
  onClosing?: () => void;
};
export const CloseModal = ({ onClosing }: CloseModalProps) => {
  return (
    <X
      className={
        "absolute left-5 mt-6 h-6 w-6 rounded-full p-0.5 text-neutral-500"
      }
      onClick={() => {
        globalModalStore.toggleModal();
        onClosing?.();
      }}
    />
  );
};
