import { CSSProperties, PropsWithChildren } from "react";

import { cn } from "@/lib/utils.ts";

import { useTableDensity } from "@/app/components/table-new/table.hooks.ts";

export const Cell = ({
  children,
  className,
  colSpan,
  extraStyles = {},
}: PropsWithChildren<{
  className?: string;
  colSpan?: number;
  extraStyles?: CSSProperties;
}>) => {
  const { config } = useTableDensity();

  return (
    <td className={cn("text-start font-medium", config.cell, className)} colSpan={colSpan} style={{ ...extraStyles }}>
      {children}
    </td>
  );
};
