import { cn } from "@/lib/utils";

export interface AvatarProps {
  firstname?: string;
  lastname?: string;
  className?: string;
}

const isEmoji = (value: string) => {
  return /\p{Extended_Pictographic}/u.test(value);
};

export const Avatar = ({ firstname = "", lastname = "", className = "" }: AvatarProps) => {
  return (
    <div
      className={cn(
        "flex aspect-square w-full items-center justify-center rounded-xs bg-white text-title font-bold uppercase text-neutral-700",
        className,
      )}
    >
      <div className={"text-[.6em] leading-none"}>
        <span>{isEmoji(firstname) ? firstname : firstname[0]}</span>
        <span>{isEmoji(lastname) ? lastname : lastname[0]}</span>
      </div>
    </div>
  );
};
