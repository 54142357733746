import { JSONContent } from "@tiptap/core";

import { TrelloComment as TrelloCommentData } from "@/lib/service/trello.types.ts";

type TrelloCommentMetadata = {
  source: "trello";
  data: TrelloCommentData;
};

type TiptapCommentMetadata = {
  source: "tiptap";
  data: JSONContent;
  html: string; // html markup
};

export type CommentDisplayData = {
  id: string;
  author?: {
    id?: string;
    avatar?: string | null;
    initials?: string;
    fullName: string;
  } | null;
  text: string;
  date: string;
  dateLastEdited: string;

  metadata?: unknown | null;
};

type TrelloComment = CommentDisplayData & {
  metadata: TrelloCommentMetadata;
};

type NazareComment = CommentDisplayData & {
  metadata: TiptapCommentMetadata;
};

export const isTrelloCommentMetadata = (
  metadata: CommentDisplayData["metadata"],
): metadata is TrelloCommentMetadata => {
  return !!metadata && typeof metadata === "object" && "source" in metadata && metadata.source === "trello";
};

export const isTrelloComment = (comment: CommentDisplayData): comment is TrelloComment => {
  return isTrelloCommentMetadata(comment.metadata);
};

export const isNazareComment = (comment: CommentDisplayData): comment is NazareComment => {
  return "metadata" in comment && (comment.metadata as TiptapCommentMetadata)?.source === "tiptap";
};
