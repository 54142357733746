import React, { useState } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "@/app/components";

type ImageProps = {
  src?: string | null;
  alt: string;
  loading?: boolean;
  className?: string;
  iconClassName?: string;
  icon?: IconType;
  onClick?: (...arg) => void;
};

export const ImageWithErrorFallback = ({
  src,
  alt,
  loading,
  className,
  iconClassName,
  icon = "Company",
  onClick = () => null,
}: ImageProps) => {
  const [error, setError] = useState(false);
  if (error && icon === "Company") {
    return (
      <div className={cn(className, "overflow-hidden")} onClick={onClick}>
        <Icon type={icon} className={cn("size-full border-neutral bg-background p-1", iconClassName)} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={cn(className, "overflow-hidden")} onClick={onClick}>
        <Icon type={"Loader"} className={cn("size-full border-neutral bg-background p-1", iconClassName)} />
      </div>
    );
  }

  return error || !src ? (
    <div className={cn(className, "overflow-hidden")} onClick={onClick}>
      <Icon type={icon} className={cn("size-full border-neutral bg-background p-1", iconClassName)} />
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      className={className}
      onClick={onClick}
      onError={() => {
        setError(true);
      }}
    />
  );
};

export const ImageWithErrorProfile = ({
  src,
  alt,
  onClick,
  className = "",
  labelClassName = "",
}: {
  src?: string | null;
  alt: string;
  onClick?: () => void;
  className?: string;
  labelClassName?: string;
}) => {
  const [error, setError] = useState(false);

  return error || !src ? (
    <div className={cn("flex items-center justify-center ", className)} onClick={onClick}>
      <p className={cn("text-semibold", labelClassName)}>{alt}</p>
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      onClick={onClick}
      className={cn("bg-transparent object-cover", className)}
      onError={() => setError(true)}
    />
  );
};
